import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { Button } from '@progress/kendo-buttons-react-wrapper';
import { DateTimePicker } from '@progress/kendo-dateinputs-react-wrapper';


class ConferenceListModal extends Component {

	constructor(props) {
		super(props);

    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.changeType = this.changeType.bind(this);

    this.state = { showModal: false };
  }

  handleClose() {
    this.setState({ showModal: false });
  }

  handleShow() {
    this.setState({ showModal: true });
  }

  changeType(event){
    this.setState({typeValue: event.target.value})
  }

	componentDidMount() {		
	}

  render() {
    return (
      <div className="setting">
        <span onClick={this.handleShow}> 
          <span className="name">Conference List</span>
          <a className="icon btn"><svg width="14" height="14" viewBox="0 0 24 24"><path d="m163 440l-91-91 251-250 90 90z m309-352l-48-48c-12-11-32-11-45 2l-45 45 91 91 45-45c13-13 13-33 2-45z m-408 275l-32 117 117-32z" transform="scale(0.046875 0.046875)"></path></svg></a>
        </span>

        <Modal dialogClassName="conferenceList" show={this.state.showModal} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Conference List</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <p>Add, delete, and manage individual conferences, as well as download conference recordings.</p>
              <a className="no-btn">Download Microsoft Outlook Add-in</a>
              <hr />
              <table className="table">
                <thead>
                  <tr>
                    <th className="check"><input type="checkbox" /></th>
                    <th>Name</th>
                    <th>Title</th>
                    <th>Bridge</th>
                    <th>Status</th>
                    <th>Type</th>
                    <th>Start Time</th>
                    <th>Recording</th>
                    <th>Moderate</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="check"><input type="checkbox" /></td>
                    <td>Name</td>
                    <td>Title</td>
                    <td>Bridge</td>
                    <td>Status</td>
                    <td>Type</td>
                    <td>Start Time</td>
                    <td><a className="no-btn" data-toggle="collapse" data-target="#rc1">Recordings</a></td>
                    <td><a className="no-btn" data-toggle="collapse" data-target="#md1">Moderate</a></td>
                    <td align="right"><a className="icon btn" data-toggle="collapse" data-target="#ar1"><svg width="14" height="14" viewBox="0 0 24 24"><path d="m163 440l-91-91 251-250 90 90z m309-352l-48-48c-12-11-32-11-45 2l-45 45 91 91 45-45c13-13 13-33 2-45z m-408 275l-32 117 117-32z" transform="scale(0.046875 0.046875)"></path></svg></a></td>
                  </tr>
                  <tr className="collapse" id="rc1">
                    <td colSpan="10">
                      <div className="table-edit">
                        <table className="table">
                          <thead>
                            <tr>
                              <th></th>
                              <th>Name</th>
                              <th>Title</th>
                              <th>Bridge</th>
                              <th>Start Time</th>
                              <th>File Size</th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td className="check"><input type="checkbox" /></td>
                              <td>test</td>
                              <td>test</td>
                              <td>Meet-Me CNF 1249194</td>
                              <td>2018-02-05T16:08:32:752-0600</td>
                              <td>413484</td>
                              <td>
                                <a className="btn">Download</a>
                              </td>
                            </tr>
                            <tr>
                              <td className="check"><input type="checkbox" /></td>
                              <td>test</td>
                              <td>test</td>
                              <td>Meet-Me CNF 1249194</td>
                              <td>2018-02-05T16:08:32:752-0600</td>
                              <td>413484</td>
                              <td>
                                <a className="btn">Download</a>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </td>
                  </tr>
                  <tr className="collapse" id="md1">
                    <td colSpan="10">
                      <div className="table-edit">
                        <p>For security, please enter your account password and click Launch Moderate Conference button to launch the moderator portal.</p>

                        <div className="form-group">
                          <label>Password</label>
                          <input type="type" className="form-control" defaultValue="" />
                        </div>
                        <div className="form-group">
                          <a className="btn">Launch Moderator Tool</a>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr className="collapse" id="ar1">
                    <td colSpan="10">
                      <div className="table-edit">
                        <div className="form-group">
                          <label>Conference Bridge</label>
                          <select className="form-control">
                            <option>Conference Bridge 1</option>
                            <option>Conference Bridge 2</option>
                            <option>Conference Bridge 3</option>
                            <option>Conference Bridge 4</option>
                          </select>
                        </div>
                        <div className="form-group">
                          <label>Title</label>
                          <input type="type" className="form-control" defaultValue="" />
                        </div>
                        <div className="form-group">
                          <label>Estimated # of Participants</label>
                          <input type="type" className="form-control" defaultValue="" />
                        </div>
                        <div className="form-group">
                          <label>Max Participants</label>
                          <input type="type" className="form-control" defaultValue="" />
                        </div>
                        <div className="form-group">
                          <label>Account Code</label>
                          <input type="type" className="form-control" defaultValue="" />
                        </div>
                        <div className="form-group">
                          <label className="right switch">
                            <input type="checkbox" />
                            <span className="slider round"></span>
                          </label>
                          Mute Participants on Entry
                        </div>
                        <div className="form-group">
                          <label className="right switch">
                            <input type="checkbox" />
                            <span className="slider round"></span>
                          </label>
                          End conference when moderator leaves
                        </div>
                        <div className="form-group">
                          <label className="right switch">
                            <input type="checkbox" />
                            <span className="slider round"></span>
                          </label>
                          Moderator required to start
                        </div>
                        <div className="form-group">
                          <label >When participant joins</label>
                          <select className="form-control">
                            <option>Play tone</option>
                            <option>Play recorded name</option>
                            <option>No notification</option>
                          </select>
                        </div>
                        <div className="form-group">
                          <label >When participant joins</label>
                          <select className="form-control">
                            <option>Play tone</option>
                            <option>Play recorded name</option>
                            <option>No notification</option>
                          </select>
                        </div>
                        <div className="form-group">
                          <label >Conference Type</label>
                          <select className="form-control" onChange={this.changeType} defaultValue={this.state.typeValue}>
                            <option defaultValue="onetime">One time</option>
                            <option defaultValue="recurring">Recurring</option>
                            <option defaultValue="reservationless">Reservationless</option>
                          </select>
                          <div className="form-group">
                            {this.getConferenceType()}
                          </div>
                        </div>
                        <hr />
                        <div className="btns">
                          <a href="" className="btn">Save</a>
                          <a href="" className="btn">Cancel</a>
                        </div>
                      </div>
                    </td>
                  </tr><tr>
                    <td className="check"><input type="checkbox" /></td>
                    <td>Name</td>
                    <td>Title</td>
                    <td>Bridge</td>
                    <td>Status</td>
                    <td>Type</td>
                    <td>Start Time</td>
                    <td><a className="no-btn" data-toggle="collapse" data-target="#rc1">Recordings</a></td>
                    <td><a className="no-btn" data-toggle="collapse" data-target="#md1">Moderate</a></td>
                    <td align="right"><a className="icon btn" data-toggle="collapse" data-target="#ar1"><svg width="14" height="14" viewBox="0 0 24 24"><path d="m163 440l-91-91 251-250 90 90z m309-352l-48-48c-12-11-32-11-45 2l-45 45 91 91 45-45c13-13 13-33 2-45z m-408 275l-32 117 117-32z" transform="scale(0.046875 0.046875)"></path></svg></a></td>
                  </tr>
                  <tr className="collapse" id="rc1">
                    <td colSpan="10">
                      <div className="table-edit">
                        <table className="table">
                          <thead>
                            <tr>
                              <th></th>
                              <th>Name</th>
                              <th>Title</th>
                              <th>Bridge</th>
                              <th>Start Time</th>
                              <th>File Size</th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td className="check"><input type="checkbox" /></td>
                              <td>test</td>
                              <td>test</td>
                              <td>Meet-Me CNF 1249194</td>
                              <td>2018-02-05T16:08:32:752-0600</td>
                              <td>413484</td>
                              <td>
                                <a className="btn">Download</a>
                              </td>
                            </tr>
                            <tr>
                              <td className="check"><input type="checkbox" /></td>
                              <td>test</td>
                              <td>test</td>
                              <td>Meet-Me CNF 1249194</td>
                              <td>2018-02-05T16:08:32:752-0600</td>
                              <td>413484</td>
                              <td>
                                <a className="btn">Download</a>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </td>
                  </tr>
                  <tr className="collapse" id="md1">
                    <td colSpan="10">
                      <div className="table-edit">
                        <p>For security, please enter your account password and click Launch Moderate Conference button to launch the moderator portal.</p>

                        <div className="form-group">
                          <label>Password</label>
                          <input type="type" className="form-control" defaultValue="" />
                        </div>
                        <div className="form-group">
                          <a className="btn">Launch Moderator Tool</a>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr className="collapse" id="ar1">
                    <td colSpan="10">
                      <div className="table-edit">
                        <div className="form-group">
                          <label>Conference Bridge</label>
                          <select className="form-control">
                            <option>Conference Bridge 1</option>
                            <option>Conference Bridge 2</option>
                            <option>Conference Bridge 3</option>
                            <option>Conference Bridge 4</option>
                          </select>
                        </div>
                        <div className="form-group">
                          <label>Title</label>
                          <input type="type" className="form-control" defaultValue="" />
                        </div>
                        <div className="form-group">
                          <label>Estimated # of Participants</label>
                          <input type="type" className="form-control" defaultValue="" />
                        </div>
                        <div className="form-group">
                          <label>Max Participants</label>
                          <input type="type" className="form-control" defaultValue="" />
                        </div>
                        <div className="form-group">
                          <label>Account Code</label>
                          <input type="type" className="form-control" defaultValue="" />
                        </div>
                        <div className="form-group">
                          <label className="right switch">
                            <input type="checkbox" />
                            <span className="slider round"></span>
                          </label>
                          Mute Participants on Entry
                        </div>
                        <div className="form-group">
                          <label className="right switch">
                            <input type="checkbox" />
                            <span className="slider round"></span>
                          </label>
                          End conference when moderator leaves
                        </div>
                        <div className="form-group">
                          <label className="right switch">
                            <input type="checkbox" />
                            <span className="slider round"></span>
                          </label>
                          Moderator required to start
                        </div>
                        <div className="form-group">
                          <label >When participant joins</label>
                          <select className="form-control">
                            <option>Play tone</option>
                            <option>Play recorded name</option>
                            <option>No notification</option>
                          </select>
                        </div>
                        <div className="form-group">
                          <label >When participant joins</label>
                          <select className="form-control">
                            <option>Play tone</option>
                            <option>Play recorded name</option>
                            <option>No notification</option>
                          </select>
                        </div>
                        <div className="form-group">
                          <label >Conference Type</label>
                          <select className="form-control" onChange={this.changeType} defaultValue={this.state.typeValue}>
                            <option defaultValue="onetime">One time</option>
                            <option defaultValue="recurring">Recurring</option>
                            <option defaultValue="reservationless">Reservationless</option>
                          </select>
                          <div className="form-group">
                            {this.getConferenceType()}
                          </div>
                        </div>
                        <hr />
                        <div className="btns">
                          <a href="" className="btn">Save</a>
                          <a href="" className="btn">Cancel</a>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="10">
                      <div className="btns">
                        <Button className="btn" click={this.handleClose}>Add Conference List</Button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Modal.Body>
          <Modal.Footer>
              <hr />
              <div className="btns">
                <Button className="btn" data-toggle="collapse" data-target="#ar2">Close</Button>
              </div>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }

  getConferenceType(){
    switch(this.state.typeValue){
      case "onetime":
      return (
        <div>
          <div className="halves">
            <div className="half">
              <div className="form-group">
                <label>Start Date Time</label>
                <DateTimePicker
                  min={new Date(2017, 9, 1)}
                  max={new Date(2017, 10, 1)}
                  value={new Date(2017, 9, 15)}  
                  format="yyyy/MM/dd hh:mm"
                  change={(e) => {console.log("value changed to:" + e.sender.value())}}
                />
              </div>
            </div>
            <div className="half">
              <div className="form-group">
                <label>End Date Time</label>
                <DateTimePicker
                  min={new Date(2017, 9, 1)}
                  max={new Date(2017, 10, 1)}
                  value={new Date(2017, 9, 15)}  
                  format="yyyy/MM/dd hh:mm"
                  change={(e) => {console.log("value changed to:" + e.sender.value())}}
                />
              </div>
            </div>
          </div>
        </div>
      );
      case "recurring":
      return (
        <div>
          <div className="halves">
            <div className="half">
              <div className="form-group">
                <label>Start Date Time</label>
                <DateTimePicker
                  min={new Date(2017, 9, 1)}
                  max={new Date(2017, 10, 1)}
                  value={new Date(2017, 9, 15)}  
                  format="yyyy/MM/dd hh:mm"
                  change={(e) => {console.log("value changed to:" + e.sender.value())}}
                />
              </div>
            </div>
            <div className="half">
              <div className="form-group">
                <label>End Date Time</label>
                <DateTimePicker
                  min={new Date(2017, 9, 1)}
                  max={new Date(2017, 10, 1)}
                  value={new Date(2017, 9, 15)}  
                  format="yyyy/MM/dd hh:mm"
                  change={(e) => {console.log("value changed to:" + e.sender.value())}}
                />
              </div>
            </div>
          </div>
        </div>
      );     
      case "reservationless":
      return (
        <div>
          <div className="halves">
            <div className="half">
              <div className="form-group">
                <label>Start Date Time</label>
                <DateTimePicker
                  min={new Date(2017, 9, 1)}
                  max={new Date(2017, 10, 1)}
                  value={new Date(2017, 9, 15)}  
                  format="yyyy/MM/dd hh:mm"
                  change={(e) => {console.log("value changed to:" + e.sender.value())}}
                />
              </div>
            </div>
            <div className="half">
              <div className="form-group">
                <label>End Date Time</label>
                <DateTimePicker
                  min={new Date(2017, 9, 1)}
                  max={new Date(2017, 10, 1)}
                  value={new Date(2017, 9, 15)}  
                  format="yyyy/MM/dd hh:mm"
                  change={(e) => {console.log("value changed to:" + e.sender.value())}}
                />
              </div>
            </div>
          </div>
        </div>
      ); 
    }
  }
};


export default ConferenceListModal;
