import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { Button } from '@progress/kendo-buttons-react-wrapper';
import { DataLoadingSpinner } from '../../../components/spinners';
import { fetchData, post } from '../../../requests/api_calls';
import { Input } from '@progress/kendo-react-inputs';
import { connect } from 'react-redux';

class EmergencyCallNotificationModal extends Component {

	constructor(props) {
		super(props);

		this.handleShow = this.handleShow.bind(this);
		this.handleClose = this.handleClose.bind(this);
		this.state = {
			showModal: false,
			loading: false,
			emergencyCallNotificationSettings: null,
      errorMessage: '',
      successMessage: '',
		};

    this.sendTestEmail = this.sendTestEmail.bind(this);
    this.updateEmergencyCallNotificationSettings = this.updateEmergencyCallNotificationSettings.bind(this);
	}

	handleClose() {
		this.setState({ showModal: false });
	}

	async handleShow() {
		await this.setState({ showModal: true, loading: true });
		const data = await this.fetchEmergencyCallNotificationSettings();
    // const locations = await this.fetchLocations();
		await this.setState({ emergencyCallNotificationSettings: data, loading: false, })
	}  
	
  fetchEmergencyCallNotificationSettings = async () => {
    return await fetchData('admin/enterprise/GetEmergencyCallNotificationSettings').then(notificationsData => notificationsData);
  }

  // API to get Location details in case it's necessary to fill in the details for sendTestEmail
  // fetchLocations = async () => {
  //   return await fetchData('admin/Locations').then(data => data);
  // }

  sendTestEmail = async () => {
    const { emergencyCallNotificationSettings } = this.state;
    let apiPath = 'admin/locations/SendTestEmailNotification';
    let postData = {
        LocationAccountNumber: "",
        LocationName: "",
        LocationAddress: "",
        EmailAddress: emergencyCallNotificationSettings.Email
    };

    try {
      const response = await post(apiPath, 'POST', JSON.stringify(postData));
      if(response.ok) {
        this.setState({ successMessage: 'Success! An Email should be received by the email address specified!', errorMessage: ''})
      }
    } catch (error) {
      this.setState({errorMessage: 'An error occurred generating an emergency notification test email to the designated email address!', successMessage: ''});
      console.error(error)
    }
  }

	componentDidMount() {		
	}

  updateEmergencyCallNotificationSettings = async () => {
    //hunter.sherouse@momentumtelecom.com
    const { emergencyCallNotificationSettings } = this.state;
    const path = 'admin/enterprise/UpdateEmergencyCallNotificationSettings';
    this.setState({ loading: true });
    try {
      const data = {...emergencyCallNotificationSettings}
      const response = await post(path, "POST", JSON.stringify(data));
      if (response.ok) {
        await this.setState({ loading: false })
      } else {
        console.error(`${path}: failed: ${response.message}`);
        this.setState({ loading: false });
      }
    } catch (error) {
      console.error(`${path}: failed: ${error}`);
      this.setState({ loading: false });
    }
  }

  render() {
    let modalContent;
    const { emergencyCallNotificationSettings, loading, errorMessage, successMessage } = this.state;

    if(loading) {
      modalContent = (
        <div className='content'>
          <DataLoadingSpinner className='spinner' style={{ margin: '10px auto 0', height: '40px' }} />
        </div>
      );
    } else if(emergencyCallNotificationSettings && Object.keys(emergencyCallNotificationSettings).length > 0){ 
      modalContent = (
        <>
          <div style={{ display: "flex", textAlign: "center", alignItems: "center", gap: "10px"}}>
            <span>Emergency Call Notification Email: </span>
            <Input 
              style={{ flex: 1 }}
              type="email"
              placeholder='example@momentumtelecom.com'
              value={emergencyCallNotificationSettings.Email}
              onChange={(e) => this.setState({ emergencyCallNotificationSettings: {...emergencyCallNotificationSettings, Email: e.target.value} })}/>
            <Button style={{ margin: 0 }} click={this.sendTestEmail}>Test Email</Button>
          </div>
          {errorMessage && (<div><span>{errorMessage}</span></div>)}
          {successMessage && (<div><span>{successMessage}</span></div>)}
          <div>
            <p>
              <input
                type="checkbox"
                id="override-email" 
                checked={emergencyCallNotificationSettings.OverrideEmailForGroups} 
                onChange={(e) => {
                  this.setState({ 
                    emergencyCallNotificationSettings: {
                      ...emergencyCallNotificationSettings, 
                      OverrideEmailForGroups: e.target.checked
                    }
                  })}
                }
              />
              <label htmlFor='override-email'>Override Email for Groups/Locations</label>
            </p>
          </div>
          <>
            <Button click={this.updateEmergencyCallNotificationSettings} style={{ margin: '0 5px 0 0 !important' }} className='btn'>Save</Button>
            <Button className="btn" click={this.handleClose}>Cancel</Button>
          </>

        </>
      )
    } else {
      modalContent = <></>;
    };
    
    return (
      <div className="setting">
        <span onClick={this.handleShow}> 
          <span className="name">Emergency Call Notification Email</span>
          <a className="icon btn"><svg width="14" height="14" viewBox="0 0 24 24"><path d="m163 440l-91-91 251-250 90 90z m309-352l-48-48c-12-11-32-11-45 2l-45 45 91 91 45-45c13-13 13-33 2-45z m-408 275l-32 117 117-32z" transform="scale(0.046875 0.046875)"></path></svg></a>
        </span>
        <Modal dialogClassName="enterpriseDirectoryModal" show={this.state.showModal} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Emergency Call Notification Email</Modal.Title>
          </Modal.Header>
          <Modal.Body>
          	{modalContent}
          </Modal.Body>
        </Modal>
      </div>
    );
  }
};

// export default EmergencyCallNotificationModal;

const mapStateToProps = (state) => {
  return {
    AccountNumber: state.reducer.AccountNumber,
  }
}

export default connect(mapStateToProps)(EmergencyCallNotificationModal);