export const getErrorMessage = (errorResponse) => {
  const pattern = /(?:\(\):|failed:) \[Error \d+\] (.+?)\.?(?=( UserId=|, Name=|$))/;
  const match = errorResponse.match(pattern);
  const errorMessage = match ? match[1].trim() + "." : errorResponse;

  return errorMessage;
};

export const formatDateToISOString = (date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}T00:00:00`;
};
