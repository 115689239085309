import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { Button } from '@progress/kendo-buttons-react-wrapper';

// <ExampleModal />

class ServicesResetSettingsModal extends Component {

  constructor(props) {
    super(props);

    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);

    this.state = { showModal: false };
  }

  handleClose() {
    this.setState({ showModal: false });
  }

  handleShow() {
    this.setState({ showModal: true });
  }

  componentDidMount() {   
  }

  render() {
    return (
      <span>
        <Button click={this.handleShow}>Reset Settings</Button>

        <Modal show={this.state.showModal} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Are you sure you want to reset the service settings back to default for this user?</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
            </div>
          </Modal.Body>
          <Modal.Footer>
              <hr />
              <div className="btns">
              <Button>Yes</Button>
              <Button className="btn" click={this.handleClose}>No</Button>
              </div>
          </Modal.Footer>
        </Modal>
      </span>
    );
  }
};

export default ServicesResetSettingsModal;
