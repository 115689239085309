import React from 'react';
import LocationEditModal from './modals/locationEditModal';
import LocationAccountCodesModal from './modals/locationAccountCodesModal';
import LocationAdvancedHuntingModal from './modals/locationAdvancedHuntingModal';
import LocationAnnouncementRepositoryModal from './modals/locationAnnouncementRepositoryModal';
import LocationAutoAttendantModal from './modals/locationAutoAttendantModal';
import LocationAutoAttendantTreeModal from './modals/locationAutoAttendantTreeModal';
import LocationCallParkModal from './modals/locationCallParkModal';
import LocationCallPickupModal from './modals/locationCallPickupModal';
import LocationConferenceBridgeListModal from './modals/locationConferenceBridgeListModal';
import LocationInstantConferenceModal from './modals/locationInstantConferenceModal';
import LocationMusicOnHoldModal from './modals/locationMusicOnHoldModal';
import LocationSeriesCompletionModal from './modals/locationSeriesCompletionModal';
import LocationManageLinesModal from './modals/locationManageLinesModal';
import LocationCustomContactDirectoryModal from './modals/locationCustomContactDirectoryModal';
import LocationManageUsersModal from './modals/locationManageUsersModal';
import LocationManageGroupCallingLineIDModal from './modals/locationManageGroupCallingLineIDModal';
import ScheduleModal from './../modals/scheduleModal';
import DepartmentModal from  './../modals/departmentModal';
import EnterpriseDirectoryModal from './../modals/enterpriseDirectoryModal';
import GroupFqdnModal from './modals/groupFqdnModal';
import GroupVlanModal from './modals/groupVlanModal';

const locations = () => (

  <div className="container full-card">
    <div className="location-settings card">
      <div className="card-header">
        <h1>Location Feature Settings</h1>
        <div className="card-actions">
          <LocationEditModal />
        </div>
      </div>

      <div className="settings-row">
        <LocationAccountCodesModal />
        <LocationAdvancedHuntingModal />
        <LocationAnnouncementRepositoryModal />
        <LocationAutoAttendantModal />
        <LocationAutoAttendantTreeModal />
        <LocationCallParkModal />
        <LocationCallPickupModal />
        <LocationConferenceBridgeListModal />
        <LocationCustomContactDirectoryModal />
        <DepartmentModal />
        <EnterpriseDirectoryModal />
        <GroupFqdnModal />
        <LocationInstantConferenceModal />
        <LocationManageGroupCallingLineIDModal />
        <LocationManageLinesModal />
        <LocationManageUsersModal />
        <LocationMusicOnHoldModal />
        <ScheduleModal type="BUSINESS_HOURS"/>
        <ScheduleModal type="HOLIDAY_SCHEDULE"/>
        <LocationSeriesCompletionModal />
        <GroupVlanModal />
      </div>
    </div>
  </div>

)

export default locations;