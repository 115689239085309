import React, { Component } from 'react';
import '@progress/kendo-ui';
import Popup from "reactjs-popup";
import * as helper from '../../../scripts/helper';


class ValidationMessagePopup extends Component {

  constructor(props) {
      super(props);
      this.state = {
          ValidationMessage: helper.StringReplaceAll(props.ValidationMessage, '</br>', '', false)
      };    
  }

  componentDidMount() {

  }

  componentWillReceiveProps(props) {
    if (helper.StringReplaceAll(props.ValidationMessage, '</br>', '', false) !== this.state.ValidationMessage)
      this.setState({ValidationMessage: helper.StringReplaceAll(props.ValidationMessage, '</br>', '', false)});
  }

  render() {
      const validationMessage = this.state.ValidationMessage;
    return (
      <Popup
        trigger={open => (
          <img src={require("../../../images/warn-icon.png")} alt="" />
        )}
        position={this.props.position || 'bottom center'}
        closeOnDocumentClick
        contentStyle={{padding: '20px', border: 'unset', height: this.state.popupHeight + 'px', left: "0"}}
      >
        <span>{validationMessage}</span>
      </Popup>
    )
  }

}

export default ValidationMessagePopup;