import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { Button } from '@progress/kendo-buttons-react-wrapper';
import { MultiSelect } from '@progress/kendo-dropdowns-react-wrapper';
import AudioUpload from './../../components/audioUpload';


class AcdHolidayModal extends Component {

	constructor(props) {
		super(props);

    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.changeAction = this.changeAction.bind(this);

    this.state = { showModal: false };
  }

  handleClose() {
    this.setState({ showModal: false });
  }

  handleShow() {
    this.setState({ showModal: true });
  }

  changeAction(event){
    this.setState({actionValue: event.target.value})
  }

	componentDidMount() {		
	}

  render() {
    return (
      <div className="setting">
        <span onClick={this.handleShow}> 
          <span className="name">ACD - Holiday Service</span>
          <a className="icon btn"><svg width="14" height="14" viewBox="0 0 24 24"><path d="m163 440l-91-91 251-250 90 90z m309-352l-48-48c-12-11-32-11-45 2l-45 45 91 91 45-45c13-13 13-33 2-45z m-408 275l-32 117 117-32z" transform="scale(0.046875 0.046875)"></path></svg></a>
        </span>
        <Modal show={this.state.showModal} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>ACD - Holiday Service</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <p>Configure the contact center to route calls differently during holidays. The URLs/files for audio video will be played in order they are listed.</p>
              
              <div className="form-group">
                <label>Action</label>
                <div className="form-control">
                  <label className="custom-control">
                  <input id="radio1" name="radio" type="radio" onChange={this.changeAction} />
                    <span >None</span>
                  </label> 
                  <label className="custom-control">
                    <input id="radio1" name="radio" type="radio" onChange={this.changeAction} />
                    <span>Perform busy treatment</span>
                  </label>
                  <label className="custom-control">
                    <input id="radio1" name="radio" type="radio" onChange={this.changeAction} defaultValue='sip' />
                    <span>Transfer to phone number</span>
                  </label>
                </div>
              </div>
              <div className="form-group">
                {this.getInputs()}
              </div>

              <div className="form-group">
                <label >Holiday Schedule</label>
                <select className="form-control">
                  <option>Schedule 1</option>
                  <option>Schedule 2</option>
                  <option>Schedule 3</option>
                </select>
              </div>
              <div className="form-group">
                <label className="right switch">
                  <input type="checkbox" />
                  <span className="slider round"></span>
                </label>
                Play announcement before holiday service action
              </div>


              <AudioUpload label="Audio" />

            </div>
          </Modal.Body>
          <Modal.Footer>
              <hr />
              <div className="btns">
                <Button className="btn">Save</Button>
                <Button className="btn" click={this.handleClose}>Close</Button>
              </div>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }

  getInputs() {
    switch(this.state.actionValue){
      case "sip":
        return (
            <div className="hidden-group">
              <label>Phone Number</label>
              <input type="type" className="form-control" defaultValue="" />
            </div>
        );
      default:
        return (<div></div>)
    }
  }
};

export default AcdHolidayModal;
