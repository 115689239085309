import React, { useEffect, useState } from "react";
import { Button } from "@progress/kendo-buttons-react-wrapper";
import { post } from "../../../../requests/api_calls";
import { DataLoadingSpinner } from "../../../../components/spinners";

export const UnavailableCodesSectionItem = ({ code, onUpdate, onDelete, onItemChange }) => {
  const [item, setItem] = useState({ ...code });
  const [showItemDetail, setShowItemDetail] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    onItemChange(item);
  }, [item]);

  if (loading) {
    return (
      <tr>
        <td colSpan="5">
          <div className="content">
            <DataLoadingSpinner
              className="spinner"
              style={{ margin: "10px auto 0", height: "40px" }}
            />
          </div>
        </td>
      </tr>
    );
  }
  return (
    <>
      <tr>
        <td className="check">
          <label className="">
            <input
              type="checkbox"
              checked={item.IsActive}
              onChange={(e) => {
                setItem({ ...item, IsActive: e.target.checked });
              }}
            />
          </label>
        </td>
        <td>{item.Code}</td>
        <td>{item.Description}</td>
        <td>
          <a className="icon btn" onClick={() => setShowItemDetail(!showItemDetail)}>
            <svg width="14" height="14" viewBox="0 0 24 24">
              <path
                d="m163 440l-91-91 251-250 90 90z m309-352l-48-48c-12-11-32-11-45 2l-45 45 91 91 45-45c13-13 13-33 2-45z m-408 275l-32 117 117-32z"
                transform="scale(0.046875 0.046875)"
              ></path>
            </svg>
          </a>
        </td>
      </tr>
      {showItemDetail && (
        <UnavailableCodesItem item={item} onUpdate={onUpdate} onDelete={onDelete} />
      )}
    </>
  );
};

const UnavailableCodesItem = ({ item, onUpdate, onDelete }) => {
  const [itemData, setItemData] = useState({ ...item });
  const [loading, setLoading] = useState(false);

  const updateAgentUnavailableCode = async () => {
    setLoading(true);
    const path = "admin/enterprise/UpdateAgentUnavailableCode";
    try {
      const response = await post(path, "POST", JSON.stringify(itemData));
      if (response.ok) {
        setLoading(false);
        await onUpdate();
      } else {
        console.error(`${path}: failed: ${response.message}`);
        setLoading(false);
      }
    } catch (error) {
      console.error(`${path}: failed: ${error}`);
      setLoading(false);
    }
  };

  const deleteAgentUnavailableCode = async () => {
    setLoading(true);
    const path = `admin/enterprise/DeleteAgentUnavailableCode?code=${itemData.Code}`;
    try {
      const response = await post(path, "DELETE", {});
      if (response.ok) {
        setLoading(false);
        await onDelete();
      } else {
        console.error(`${path}: failed: ${response.message}`);
        setLoading(false);
      }
    } catch (error) {
      console.error(`${path}: failed: ${error}`);
      setLoading(false);
    }
  };
  if (loading) {
    return (
      <tr>
        <td colSpan="5">
          <div className="content">
            <DataLoadingSpinner
              className="spinner"
              style={{ margin: "10px auto 0", height: "40px" }}
            />
          </div>
        </td>
      </tr>
    );
  }
  return (
    <tr className="">
      <td colSpan="5">
        <div className="table-edit">
          <a
            className="red delete"
            onClick={() => {
              deleteAgentUnavailableCode();
            }}
          >
            Delete Code
          </a>
          <div className="form-group">
            <label className="switch right">
              <input
                type="checkbox"
                checked={itemData.IsActive}
                onChange={(e) => {
                  setItemData({ ...itemData, IsActive: e.target.checked });
                }}
              />
              <span className="slider round"></span>
            </label>
            Activate Code
          </div>
          <div className="form-group">
            <label>Code</label>
            <input type="text" className="form-control" value={itemData.Code} disabled />
          </div>
          <div className="form-group">
            <label>Description</label>
            <input
              type="text"
              className="form-control"
              value={itemData.Description}
              onChange={(e) => setItemData({ ...itemData, Description: e.target.value })}
            />
          </div>
          <div className="btns">
            <Button
              className="btn"
              click={() => {
                updateAgentUnavailableCode();
              }}
            >
              Save
            </Button>
          </div>
        </div>
      </td>
    </tr>
  );
};
