import React, { Component } from 'react';
import CallLogsFrame from './call_logs_frame';

export default class IPCallLogsLocation extends Component {

  getApiUrl = () => {
    let apiUrl = `usr/GetIpTrunkingCallLogs?LocationAccountNumber={LocationAccountNumber}&TrunkID=0`;
    return apiUrl;
  }

  render() {
    return <CallLogsFrame
      cardTitle={'Location Call Logs'}
      apiPath={this.getApiUrl()}
      trunkId={'0'}
      isLocation={true}
    />
  }
};