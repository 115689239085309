import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { Button } from '@progress/kendo-buttons-react-wrapper';
import _ from 'lodash';
import { Grid, GridColumn as Column, GridCell } from '@progress/kendo-react-grid';
import * as apiCalls from '../../../requests/api_calls';
import { orderBy } from '@progress/kendo-data-query';
import { filterBy } from '@progress/kendo-data-query';
import * as helper from '../../../scripts/helper';
import * as macdHelper from '../components/macd_helper';
import { AutoComplete } from '@progress/kendo-dropdowns-react-wrapper';
import { DataLoadingSpinner } from '../../../components/spinners';
import MACDSaveStatus from '../modals/orderResultModal';
import ValidationCell from '../components/validationCell';
import MACDSearch from '../components/macdSearch';

export default class VirtualTab extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isCancelDialogOpen: false,
      gridHeight: props.GridHeight,
      MomentumAccountNumber: props.MomentumAccountNumber,
      LocationName: props.LocationName,
      VirtualTermServices: [],
      OriginalListings: [],
      Listings: [],
      loading: false,
      saving: false,
      error: false,
      Sort: [{ field: "ServiceNumber", dir: "asc" }],
      ValidationErrorMessages: []
    };

    const updateTerminatingNumber = this.updateTerminatingNumber.bind(this);

    class AutoCompleteCell extends GridCell {
      render() {
        if (this.props.field === "TerminatingNumber") {
          let termArray = this.props.dataItem.TermNumberList ? this.props.dataItem.TermNumberList : [];
          const value = this.props.dataItem[this.props.field];
          const productItem = this.props.dataItem;
          const enableVal = productItem.EditTermNumber;
          let autoCompleteDOM = (
            <AutoComplete dataSource={enableVal ? termArray : []} placeholder={"Enter Term. Number"} style={{ cursor: enableVal ? "pointer" : "not-allowed" }}
              value={value} change={(e) => { updateTerminatingNumber(productItem, e, enableVal); }}
            />
          );

          if (this.props.dataItem.EditTermNumber) return <td>{autoCompleteDOM}</td>
          else return <td><label style={{ textAlign: "center" }}>N/A</label></td>
        }
      }
    }
    
    this.ValidationCell = ValidationCell;
    this.InputAutoCompleteField = AutoCompleteCell;
  }

  componentDidMount() {
    if (this.state.MomentumAccountNumber !== '')
      this.fetchListings();
  }

  async componentWillReceiveProps(props) {
    if (props) {
      if (props.MomentumAccountNumber !== this.state.MomentumAccountNumber) {
        await this.setState({ MomentumAccountNumber: props.MomentumAccountNumber, LocationName: props.LocationName });
        this.fetchListings();
      }
      if (props.GridHeight && props.GridHeight !== this.state.gridHeight)
        this.setState({ gridHeight: props.GridHeight });
    }
  }

  handleSaveSuccess = async () => {
    await this.setState({ loading: true, ValidationErrorMessages: [], statusMessage: 'Your order has been saved and submitted successfully.' });
    this.props.TabHasChanges(false);
    this.fetchListings();
  }

  showErrorAndResetModal = (msg, ValidationErrorMessages = []) => {
    this.setState({ error: true, statusMessage: msg, ValidationErrorMessages: ValidationErrorMessages });
  }

  closeSaveMessage = () => {
    let serviceListings = this.state.VirtualTermServices.slice();
    let gridListings = this.state.Listings.slice();
    let validationErrors = this.state.ValidationErrorMessages.slice();
    validationErrors.map((itm, idx) => {
      let itmInListings = serviceListings.filter(resp => resp.CustomerProductId === itm.CustomerProductId);
      let indexOfListing = _.indexOf(serviceListings, itmInListings[0]);
      serviceListings[indexOfListing].ValidationMessage = itm.ValidationMessage;
      itmInListings = gridListings.filter(resp => resp.CustomerProductId === itm.CustomerProductId);
      indexOfListing = _.indexOf(gridListings, itmInListings[0]);
      gridListings[indexOfListing].ValidationMessage = itm.ValidationMessage;
    });
    this.setState({ error: false, statusMessage: '', Listings: gridListings, VirtualTermServices: serviceListings });
  }

  handleCancel = () => {
    //display confirmation message
    let changedItems = this.state.VirtualTermServices.filter(res => res.HasChanges === true);
    if (!changedItems || changedItems.length === 0) return;
    this.setState({ isCancelDialogOpen: true });
  }

  closeCancelDialog = () => {
    this.setState({ isCancelDialogOpen: false });
  }

  CancelYesAction = async () => {
    //clear all editable fields
    await this.fetchListings();
    this.props.TabHasChanges(false);
    this.setState({ isCancelDialogOpen: false, ValidationErrorMessages: [] });

  }

  handleSave = async () => {
    let changedItems = this.state.VirtualTermServices.filter(res => res.HasChanges === true);
    if (!changedItems || changedItems.length === 0) return;
    await this.setState({ saving: true });
    let apiPath = macdHelper.apiSubmitVTN();
    let originalListings = this.state.OriginalListings;

    let submittedItems = changedItems.map((itm, idx) => {
      let newTermNumber = itm.FullTermList.filter(list => list.ServiceNumber === itm.TerminatingNumber)[0];
      let oldService = originalListings.filter(res => res.CustomerProductId === itm.CustomerProductId)[0];
      return {
        OldService: oldService,
        NewTermNumber: newTermNumber,
      }
    });
    let reqBody = {
      LocationAccountNumber: this.state.MomentumAccountNumber,
      ServiceList: submittedItems
    };
    await apiCalls.post(apiPath, 'POST', JSON.stringify(reqBody)).then((res) => {
      let validationErrors = [];
      if (res.ok === true) {
        this.handleSaveSuccess();
      } else {
        let responseObject = JSON.parse(res.message);
        // RESPONSE ARRARY
        if (responseObject.length && responseObject.length > 0) {
          validationErrors = responseObject.map((itm, idx) => {
            if (itm.CustomerProductId && itm.CustomerProductId !== '')
              return {
                CustomerProductId: parseInt(itm.CustomerProductId),
                ValidationMessage: itm.ErrorMessage
              }
            else {
              throw [helper.StringReplaceAll(itm.ErrorMessage, '</br>', '', false)];
            }
          });
        } else {
          // RESPONSE OBJECT
          if (responseObject.MPOStatus && responseObject.MPOStatus.MPOErrorList && responseObject.MPOStatus.MPOErrorList.length > 0) {
            let ErrorList = responseObject.MPOStatus.MPOErrorList[0];
            let ErrorMessages = ErrorList.MPOResultText;
            validationErrors = ErrorMessages.map((itm, idx) => {
              if (itm.CustomerProductId && itm.CustomerProductId !== '')
                return {
                  CustomerProductId: parseInt(itm.CustomerProductId),
                  ValidationMessage: itm.ErrorMessage
                }
              else {
                throw [helper.StringReplaceAll(itm.ErrorMessage, '</br>', '', false)];
              }
            });
          }
        }
        if (validationErrors) {
          this.showErrorAndResetModal('Your order could not be completed due to entry errors indicated in the grid.', validationErrors);
        }
      }
    }).catch((msg) => {
      this.showErrorAndResetModal(msg);
    }).finally(() => {
      this.setState({ saving: false });
    });
  }

  updateTerminatingNumber = (productItem, e, enableVal) => {
    let virtualTermServices = this.state.VirtualTermServices;
    let gridListings = this.state.Listings;
    let originalItem = this.state.OriginalListings.filter(res => res.CustomerProductId === productItem.CustomerProductId)[0]; // non-mutated

    let newVal = '';
    if (enableVal) {
      if (e.sender.element[0].value === '' || isNaN(e.sender.element[0].value)) {
        newVal = '';
        e.sender.element[0].value = '';
      } else {
        newVal = e.sender.element[0].value;
      }
      productItem.TerminatingNumber = newVal;
      if (productItem['TerminatingNumber'] !== originalItem['ServiceNumber'] && productItem['TerminatingNumber'] !== '') {
        productItem['HasChanges'] = true;
      } else {
        productItem['HasChanges'] = false;
      }
      // GRID ITEM
      let gridItm = gridListings.filter(itm => itm.CustomerProductId === productItem.CustomerProductId);
      let idx = _.indexOf(gridListings, gridItm);
      gridListings[idx] = productItem;
      // VTN SERVICES' ITEM
      let vtnItm = virtualTermServices.filter(itm => itm.CustomerProductId === productItem.CustomerProductId);
      let idx2 = _.indexOf(virtualTermServices, vtnItm);
      virtualTermServices[idx2] = productItem;

      if (virtualTermServices && virtualTermServices.filter(res => res.HasChanges === true).length === 0) {
        this.props.TabHasChanges(false);
      } else {
        this.props.TabHasChanges(true);
      };
      this.setState({ VirtualTermServices: virtualTermServices, Listings: gridListings });
    }
  }
  
  fetchListings = async () => {
    await this.setState({ loading: true });
    let locationName = this.state.LocationName;
    let apiPath = macdHelper.apiGetVTNList(this.state.MomentumAccountNumber);
    let [responseData, unformattedResponse] = await apiCalls.fetchData(apiPath).then((data) => {
      if (data) {
        let termNumberList = data.TermNumberList ? data.TermNumberList.map(itm => itm.ServiceNumber) : [];

        let formattedData = data.ServiceList.map((itm, idx) => {
          let newItm = {
            AddonCatalogTicker: itm.AddonCatalogTicker,
            AddonCustomerProductId: itm.AddonCustomerProductId,
            AddonPartDescription: itm.AddonPartDescription,
            AddonPartNumber: itm.AddonPartNumber,
            AddonServiceNumber: itm.AddonServiceNumber,
            AddonUserPackageId: itm.AddonUserPackageId,

            TerminatingNumber: itm.ServiceNumber,
            TermNumberList: termNumberList,
            FullTermList: data.TermNumberList,
            EditTermNumber: itm.EditTermNumber,

            CustomerProductId: itm.CustomerProductId,
            CatalogItemId: itm.CatalogItemId,
            PartDescription: itm.PartDescription,
            PartNum: itm.PartNum,
            Extension: itm.Extension,
            isExtensionOnly: itm.isExtensionOnly,
            Location: locationName,
            LineName: itm.LineName,
            HasChanges: false,
            ValidationMessage: '',
          }
          return newItm;
        });
        return [formattedData, data.ServiceList];
      } else {
        return [[], []];
      }
    });
    await this.setState({ Listings: responseData, VirtualTermServices: responseData, OriginalListings: unformattedResponse, loading: false });
  }

  sortListings(sort, Listings = null) {
    let returnObj = [];
    if (this.state.Listings) {
      if (Listings == null) Listings = this.state.Listings.slice();
      let sortedSet = orderBy(Listings, sort);
      returnObj = sortedSet;
      if (sort.length) {
        returnObj = helper.blanksAfterZ(sortedSet, sort);
      }
    }
    return returnObj;
  }

  sortChange = (event) => {
    this.setState({
      Listings: this.sortListings(event.sort),
      Sort: event.sort
    });
  }

  searchListings = (event) => {
    let searchText = event.target.value.trim();
    //console.log("Search Text: " + searchText);
    let fullDetails = this.state.VirtualTermServices;
    if (searchText !== '') {
      searchText = event.target.value;
      let services = filterBy(fullDetails, {
        logic: 'or',
        filters: [
          { field: 'AddonServiceNumber', operator: 'contains', value: searchText, ignoreCase: true },
          { field: 'LineName', operator: 'contains', value: searchText, ignoreCase: true },
          { field: 'AddonPartDescription', operator: 'contains', value: searchText, ignoreCase: true }
        ]
      });

      this.setState({ Listings: services })
    }
    else {
      this.setState({ Listings: fullDetails });
    }

  }

  gridContent = () => {
    if (this.state.MomentumAccountNumber == '') {
      return macdHelper.locationSelectLabel(this.state.MomentumAccountNumber);
    }
    
    if (this.state.loading) return <DataLoadingSpinner className="load spinner relative" />
    else if(!this.state.loading && this.state.Listings.length === 0) return <p>There are no applicable changes available for services at this location. Please review your search criteria.</p>
    else if (!this.state.loading && this.state.Listings && this.state.Listings.length > 0) {
      return (
        <Grid
          style={{ height: this.state.gridHeight + 'px' }}
          data={this.state.Listings}
          sortable={{ allowUnsort: true, mode: 'single' }}
          sort={this.state.Sort}
          onSortChange={this.sortChange}
        >
          {this.state.ValidationErrorMessages && this.state.ValidationErrorMessages.length > 0 ? <Column field='ValidationMessage' title=" " width={'50px'} cell={this.ValidationCell} sortable={false} /> : null}
          <Column width="400px" field='AddonPartDescription' title='Product' />
          <Column width="280px" field='AddonServiceNumber' title='Service ID' />
          <Column width="200px" field='LineName' title='Line Name' />
          <Column width="200px" field='Location' title='Location' />
          <Column width="200px" field='TerminatingNumber' cell={this.InputAutoCompleteField} title='Terminating Number' sortable={false} />
          <Column field="spacer" title=" " sortable={false} filterable={false} />
        </Grid>
      )
    }
  }

  render() {

    return (
      <div>
        <div style={{ "textAlign": "right", "marginBottom": "5px", "marginTop": "5px", "display": this.state.loading || this.state.MomentumAccountNumber === '' ? "none" : "block" }}>
          <div className="search">
            <MACDSearch SearchFunction={this.searchListings} />
            <Button click={this.handleCancel}>Cancel</Button>
            <Button click={this.handleSave}>Save</Button>
          </div>
        </div>
        <hr />
        <Modal dialogClassName="confirm-cancel-macd" show={this.state.isCancelDialogOpen} onHide={this.closeCancelDialog}>
          <Modal.Header closeButton>
            <Modal.Title>Cancel Order?</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div><p><span style={{ fontSize: "medium" }}>Are you sure you wish to cancel this order?</span></p></div>
          </Modal.Body>
          <Modal.Footer>
            <Button click={(evt) => { this.CancelYesAction() }}>Yes</Button>
            <Button click={(evt) => { this.closeCancelDialog() }}>No</Button>
          </Modal.Footer>
        </Modal>
        <MACDSaveStatus
          saving={this.state.saving}
          statusMessage={this.state.statusMessage}
          error={this.state.error}
          parentCallBack={this.closeSaveMessage}
        />
        {this.gridContent()}
      </div>
    );
  }
};