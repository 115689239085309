import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { toBoolean } from './../../scripts/helper';

const showTests = () => toBoolean(process.env.REACT_APP_SHOW_TESTS);

class TestResultModal extends Component {

  constructor(props) {
    super(props);

    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.state = { showModal: false };
  }

  handleClose() {
    this.setState({ showModal: false });
  }

  handleShow() {
    this.setState({ showModal: true });
  }

  componentDidMount() {

  }

  render() {
    if (showTests()) {
      return (
        <span>
          <input id='show-test-connector' onChange={this.handleShow} />
          <Modal id='test-outcome-modal' show={this.state.showModal} onHide={this.handleClose}>
            <Modal.Header closeButton>
              <Modal.Title><b id='outcome'></b></Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <h4 className='text-center' id='centered-message'></h4>
              <h4 id='message'></h4>
            </Modal.Body>
          </Modal>
        </span>
      )
    } else return null
  }

};

export default TestResultModal;
