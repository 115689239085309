import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { Button } from '@progress/kendo-buttons-react-wrapper';
import { MultiSelect } from '@progress/kendo-dropdowns-react-wrapper';
import { TabStrip, TabStripTab, PanelBar, PanelBarItem } from '@progress/kendo-react-layout';
import AudioUpload from './../../components/audioUpload';


class DNISModal extends Component {

	constructor(props) {
		super(props);

    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);

    this.state = { showModal: false, selected: 0 };
  }

  handleClose() {
    this.setState({ showModal: false });
  }

  handleShow() {
    this.setState({ showModal: true });
  }

	componentDidMount() {		
	}

  handleSelect = (e) => {
    this.setState({selected: e.selected});
  }

  render() {
    return (
      <div className="setting">
        <span onClick={this.handleShow}> 
          <span className="name">DNIS</span>
          <a className="icon btn"><svg width="14" height="14" viewBox="0 0 24 24"><path d="m163 440l-91-91 251-250 90 90z m309-352l-48-48c-12-11-32-11-45 2l-45 45 91 91 45-45c13-13 13-33 2-45z m-408 275l-32 117 117-32z" transform="scale(0.046875 0.046875)"></path></svg></a>
        </span>
        <Modal dialogClassName="dnisModal" show={this.state.showModal} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>DNIS</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <p>Configure the Contact Center DNIS to distribute calls to agents. </p>
              
              <div className="form-group">
                <label className="right switch">
                  <input type="checkbox" />
                  <span className="slider round"></span>
                </label>
                Display DNIS Number to agent when presenting call instead of calling number
              </div>
              <div className="form-group">
                <label className="right switch">
                  <input type="checkbox" />
                  <span className="slider round"></span>
                </label>
                Display DNIS name to agent when presenting call instead of calling name
              </div>
              <div className="form-group">
                <label className="right switch">
                  <input type="checkbox" data-toggle="collapse" data-target="#hide1" />
                  <span className="slider round"></span>
                </label>
                Automatically promotes calls with Priority 1 to Priority 0 after waiting
                <div className="hidden-group collapse" id="hide1">
                  <label >How many seconds</label>
                  <input type="text" className="form-control" />
                </div>
              </div>
              <div className="form-group">
                <label className="right switch">
                  <input type="checkbox" data-toggle="collapse" data-target="#hide2" />
                  <span className="slider round"></span>
                </label>
                Automatically promotes calls with Priority 2 to Priority 1 after waiting
                <div className="hidden-group collapse" id="hide2">
                  <label >How many seconds</label>
                  <input type="text" className="form-control" />
                </div>
              </div>
              <div className="form-group">
                <label className="right switch">
                  <input type="checkbox" data-toggle="collapse" data-target="#hide3" />
                  <span className="slider round"></span>
                </label>
                Automatically promotes calls with Priority 3 to Priority 2 after waiting 
                <div className="hidden-group collapse" id="hide3">
                  <label >How many seconds</label>
                  <input type="text" className="form-control" />
                </div>
              </div>
              <table className="table">
                <thead>
                  <tr>
                    <th className="check"><input type="checkbox" /></th>
                    <th>Name</th>
                    <th>Phone Number</th>
                    <th>Extension</th>
                    <th>Priority</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td><input type="checkbox" /></td>
                    <td>Call Center 1249192(Primary)</td>
                    <td>123 123 1234</td>
                    <td>7000</td>
                    <td>Highest</td>
                    <td align="right"><a className="icon btn"  data-toggle="collapse" data-target="#ar1" ><svg width="14" height="14" viewBox="0 0 24 24"><path d="m163 440l-91-91 251-250 90 90z m309-352l-48-48c-12-11-32-11-45 2l-45 45 91 91 45-45c13-13 13-33 2-45z m-408 275l-32 117 117-32z" transform="scale(0.046875 0.046875)"></path></svg></a></td>
                  </tr>
                  <tr className="collapse" id="ar1">
                    <td colSpan="6">
                      {this.getConfig()}
                    </td>
                  </tr>
                  <tr>
                    <td><input type="checkbox" /></td>
                    <td>Call Center 1249192(Primary)</td>
                    <td>123 123 1234</td>
                    <td>7000</td>
                    <td>Highest</td>
                    <td align="right"><a className="icon btn"  data-toggle="collapse" data-target="#ar2" ><svg width="14" height="14" viewBox="0 0 24 24"><path d="m163 440l-91-91 251-250 90 90z m309-352l-48-48c-12-11-32-11-45 2l-45 45 91 91 45-45c13-13 13-33 2-45z m-408 275l-32 117 117-32z" transform="scale(0.046875 0.046875)"></path></svg></a></td>
                  </tr>
                  <tr className="collapse" id="ar2">
                    <td colSpan="6">
                      {this.getConfig()}
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="6">
                      <div className="btns">
                        <a className="btn" data-toggle="collapse" data-target="#ar2">Add DNIS</a>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Modal.Body>
          <Modal.Footer>
              <hr />
              <div className="btns">
              <Button className="btn" click={this.handleClose}>Close</Button>
              </div>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }

  getConfig() {
    return (
      <div className="">
        <PanelBar onSelect={this.handleSelect} expandMode="single">
          <PanelBarItem title="Configure DNIS" expanded focused selected>
            <div className="form-group">
              <label>DNIS Name</label>
              <input type="type" className="form-control" defaultValue="" />
            </div>
            <div className="form-group">
              <label>Phone Number</label>
              <select className="form-control">
                <option>123 123 1234</option>
              </select>
            </div>
            <div className="form-group">
              <label>Extension</label>
              <input type="type" className="form-control" defaultValue="" />
            </div>
            <div className="form-group">
              <label>Priority</label>
              <select className="form-control">
                <option>Highest</option>
                <option>High</option>
                <option>Medium</option>
                <option>Low</option>
              </select>
            </div>
            <div className="form-group">
              <label>Calling Line ID</label>
              <input type="type" className="form-control" defaultValue="" />
            </div>
            <div className="form-group">
              <label className="right switch">
                <input type="checkbox"  data-toggle="collapse" data-target="#hide4" />
                <span className="slider round"></span>
              </label>
              Use custom calling line name settings
              <div className="collapse" id="hide4">
                <div className="form-group">
                  <label >Calling Line ID First Name</label>
                  <input type="text" className="form-control" />
                </div>
                <div className="form-group">
                  <label >Calling Line ID Last Name</label>
                  <input type="text" className="form-control" />
                </div>
              </div>
            </div>
            <div className="form-group">
              <label className="right switch">
                <input type="checkbox" />
                <span className="slider round"></span>
              </label>
              Use custom DNIS announcement settings
            </div>
            <div className="form-group">
              <label className="right switch">
                <input type="checkbox" />
                <span className="slider round"></span>
              </label>
              Allow outgoing Contact Center call
            </div>
            <hr />
            <div className="btns">
              <a href="" className="btn">Save</a>
              <a href="" className="btn">Cancel</a>
            </div>
          </PanelBarItem>
          <PanelBarItem title={"Entrance Message"}>
            <div className="form-group">
              <label className="right switch">
                <input type="checkbox" />
                <span className="slider round"></span>
              </label>
              Play entrance message
            </div>
            <div className="form-group">
              <label className="right switch">
                <input type="checkbox" />
                <span className="slider round"></span>
              </label>
              Entrance message is mandatory when played
            </div>
            <AudioUpload label="Audio" />
            <hr />
            <div className="btns">
              <a href="" className="btn">Save</a>
              <a href="" className="btn">Cancel</a>
            </div>
          </PanelBarItem>
          <PanelBarItem title={"Estimated Wait Message"}>
            <div className="form-group">
              <label className="right switch">
                <input type="checkbox" />
                <span className="slider round"></span>
              </label>
              Enable estimates message for queued calls
            </div>
            <div className="form-group">
              <div className="form-control">
                <label className="custom-control">
                <input id="radio1" name="radio" type="radio" data-toggle="collapse" data-target="#callers" />
                  <span >Announce queue position</span>
                </label> 
                <label className="custom-control">
                  <input id="radio1" name="radio" type="radio" data-toggle="collapse" data-target="#minutes" />
                  <span>Announce wait time</span>
                </label>
              </div>
            </div>


            <div id="callers" className="collapse">
              <div className="form-group">
                <label>Play message for callers in queue positions</label>
                <input type="type" className="form-control" defaultValue="" placeholder=""/>
              </div>
              <div className="form-group">
                <label className="right switch">
                  <input type="checkbox" />
                  <span className="slider round"></span>
                </label>
                Play high volume message
              </div>
            </div>

            <div id="minutes" className="collapse">
              <div className="form-group">
                <label>Play message for callers with a wait time</label>
                <input type="type" className="form-control" defaultValue="" placeholder="" />
              </div>
              <div className="form-group">
                <label className="right switch">
                  <input type="checkbox" />
                  <span className="slider round"></span>
                </label>
                Play high volume message
              </div>
              <div className="form-group">
                <label >Default handling time in minutes</label>
                <input type="text" className="form-control" />
              </div>
            </div>


            <hr />
            <div className="btns">
              <a href="" className="btn">Save</a>
              <a href="" className="btn">Cancel</a>
            </div>
          </PanelBarItem>
          <PanelBarItem title={"Comfort Message"}>
            <div className="form-group">
              <label className="right switch">
                <input type="checkbox" />
                <span className="slider round"></span>
              </label>
              Play comfort message
            </div>
            <div className="form-group">
              <label>Time between Messages</label>
              <input type="type" className="form-control" defaultValue="" />
              <small>Can't be less than 10 seconds.</small>
            </div>
            <AudioUpload label="Audio" />
            <hr />
            <div className="btns">
              <a href="" className="btn">Save</a>
              <a href="" className="btn">Cancel</a>
            </div>
          </PanelBarItem>
          <PanelBarItem title={"Music On Hold Message"}>
            <div className="form-group">
              <label className="right switch">
                <input type="checkbox" />
                <span className="slider round"></span>
              </label>
              Enable music or video on hold for queued calls
            </div>
            <AudioUpload label="Audio" />
            <hr />
            <div className="btns">
              <a href="" className="btn">Save</a>
              <a href="" className="btn">Cancel</a>
            </div>
          </PanelBarItem>
          <PanelBarItem title={"Call Whisper Message"}>
            <div className="form-group">
              <label className="right switch">
                <input type="checkbox" />
                <span className="slider round"></span>
              </label>
              Play Call Whisper Message
            </div>
            <AudioUpload label="Audio" />
            <hr />
            <div className="btns">
              <a href="" className="btn">Save</a>
              <a href="" className="btn">Cancel</a>
            </div>
          </PanelBarItem>
        </PanelBar>
      </div>
    )
  }
};

export default DNISModal;
