import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { Button } from '@progress/kendo-buttons-react-wrapper';

class PinResetRow extends Component {

	constructor(props) {
		super(props);

    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);

    this.state = { showModal: false };
  }

  handleClose() {
    this.setState({ showModal: false });
  }

  handleShow() {
    this.setState({ showModal: true });
  }

	componentDidMount() {		
	}

  render() {
    return (
      <div className="setting">
        <span onClick={this.handleShow}> 
          <span className="name">Reset Voicemail Pin</span>
          <a className="icon btn"><svg width="14" height="14" viewBox="0 0 24 24"><path d="m163 440l-91-91 251-250 90 90z m309-352l-48-48c-12-11-32-11-45 2l-45 45 91 91 45-45c13-13 13-33 2-45z m-408 275l-32 117 117-32z" transform="scale(0.046875 0.046875)"></path></svg></a>
        </span>

        <Modal show={this.state.showModal} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Reset Voicemail Pin</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="">
              <p>Click the Reset button below to temporarily reset your Voicemail PIN to <b>8642</b>. When you access your voicemail with this temporary PIN, you will be prompted to assign a new PIN.</p>
              <a className="no-btn">Reset Voicemail Pin</a>
            </div>
          </Modal.Body>
          <Modal.Footer>
              <hr />
              <div className="btns">
              <Button className="btn" click={this.handleClose}>Close</Button>
              </div>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
};

export default PinResetRow;
