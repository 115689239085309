import React, { Component } from 'react';
import * as settings from '../../../scripts/settings';
import * as helper from '../../../scripts/helper';
import moment from 'moment';
import * as icons from '../../../scripts/icons';
import * as apiCalls from '../../../requests/api_calls';
import { withRouter, Link } from 'react-router-dom';
import { DataLoadingSpinner } from '../../../components/spinners';
import { ComboBox } from '@progress/kendo-react-dropdowns';
import { filterBy } from '@progress/kendo-data-query';

export default class UserSearch extends Component {
  constructor() {
    super();

    this.state = {
      searchTerm: null,
      Services: [],
      showResults: false,
      //loading: true,
    }
  }
  fetchServices = (apiPath = null) => {
    //await this.setState({ loading: true });
    if (!apiPath) apiPath = this.apiPathWithParams();
    apiCalls.fetchData(apiPath).then(async (data) => {
      if (data !== null) {
        this.setState({
          loading: false,
          Services: data,
          resultsMsg: null,
          //showResults: true,
        });
      } else if (data === null) {
        this.setState({ Services: [], loading: false, resultsMsg: settings.noResultsMsg });
      };
    });
  }

  paramsSeparator = (apiPath) => apiPath.indexOf('?') >= 0 ? '&' : '?';

  apiPathWithParams = () => {
    let { searchTerm } = this.state;
    let path = 'oc/GetAvailableOCUsers';
    if (searchTerm) path += this.paramsSeparator(path) + 'searchText=' + searchTerm;
    return path;
  }

  searchServices = (event) => {
    let searchText = event.target.value.trim();
    if (searchText !== '') {
      this.setState({ searchTerm: searchText, showResults: true, loading: true })
    } else {
      this.setState({ searchTerm: null, showResults: false })
    }
    this.delayedSearch();
  }

  delayedSearch = () => {
    if (this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(() => this.fetchServices(), settings.searchDelay);
  }

  serviceResults = () => {
    let { showResults, Services, loading } = this.state;
    if (showResults) {
      if (Services && Services.length > 0 && !loading) {
        return (
          <div className="search-items">
            <div className="search-header">Services</div>
            {Services.map((item, index) => this.serviceItem(item, index))}
          </div>
        )
      } else if (Services && Services.length === 0 && !loading) {
        return (
          <div className="search-items">
            <div className="no-results">{settings.noResultsMsg}</div>
          </div>
        );
      } else {
        return <div className="search-items">
          <div className="no-results">
            <DataLoadingSpinner
              className='load spinner'
              style={{ top: "-1%" }}
            />
          </div>
        </div>
      }
    } else {
      return null;
    }
  }

  handleUserChange = (user) => {
    this.props.onSelectUser(user);
  }

  serviceItem = (item, index) => {
    return (
      <div className="search-item" key={index}>
        <div className="name"
          onClick={() => {
            this.setState({ searchTerm: item.DisplayName, showResults: false, });
            this.handleUserChange(item);
          }}
        >
          {`${item.DisplayName} - ${item.UserPrincipalName} - ${item.AccountType}`}
        </div>
      </div>
    )
  }

  render() {
    let divStyle = { margin: '1%', };
    let labelStyle = { display: 'inline-block', width: '30%', };
    let inputStyle = { width: '70%', display: 'inline-block', height: '34px', fontSize: '12px' };
    // if (this.props.IsResourceAccount) {
    //   return (
    //     <div
    //       className="ad-user-search"
    //     >
    //       <form ref={'searchTerm'} onSubmit={e => { e.preventDefault() }}>
    //         <input
    //           className="form-control search"
    //           ref={'searchTerm'}
    //           type="text"
    //           onChange={this.searchServices}
    //           autoComplete={"off"}
    //           style={inputStyle}
    //           value={this.state.searchTerm}
    //         />
    //       </form>
    //       <div className="search-results"
    //         style={{ display: this.state.showResults ? 'inline-block' : 'none' }}
    //       >
    //         {this.serviceResults()}
    //       </div>
    //     </div>
    //   )
    // }

    return (
      <div
        className="ad-user-search"
        style={divStyle}
      >
        <form ref={'searchTerm'} onSubmit={e => { e.preventDefault() }}>
          <label style={labelStyle}>User Search</label>
          <input
            className="form-control search"
            ref={'searchTerm'}
            type="text"
            onChange={this.searchServices}
            autoComplete={"off"}
            style={inputStyle}
            value={this.state.searchTerm}
          />
        </form>
        <div className="search-results"
          style={{ display: this.state.showResults ? 'inline-block' : 'none' }}
        >
          {this.serviceResults()}
        </div>
      </div>
    )
  }
}