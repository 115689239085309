import React, { Component } from "react";
import { Link } from "react-router-dom";
import * as apiCalls from "./../../requests/api_calls";
import { DataLoadingSpinner } from "./../../components/spinners";

export default class CircuitCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      CircuitList: null
    };
  }

  componentDidMount() {
    this.fetchCircuits();
  }

  fetchCircuits = async () => {
    let apiPath = "admin/GetCircuitsList";
    //await this.setState({ loading: true });
    await apiCalls.fetchData(apiPath).then((data) => {
      //setLoading(true);
      if (data) {
        // data.forEach(async (itm) => {
        //   if (itm.CheckMkHostName && itm.CheckMkHostName !== "") {
        //     let obj = await this.fetchActiveHosts(itm.CheckMkHostName);
        //     let tt = JSON.parse(obj).ResultDetail;
        //     let ti = JSON.parse(tt).state_text;
        //     if (ti === "OK") {
        //       itm.isActive = true;
        //     } else {
        //       itm.isActive = false;
        //     }
        //   }
        this.setState({
          loading: false,
          CircuitList: data,
        });
        //});
      } else {
        this.setState({ loading: false, CircuitList: null });
      }
    });
    // setTimeout(() => {
    //   this.setState({ loading: false });
    // }, 3000);
  };

  // fetchActiveHosts = async (hostname) => {
  //   let apiPath = `admin/CircuitMonitoring/GetCurrentStatusForHost?hostname=${hostname}`;
  //   return await apiCalls.fetchData(apiPath).then(data => data).catch(err => console.error(err));
  // };


  cardContent = () => {
    let { loading, CircuitList } = this.state;
    if (loading) {
      return (

        <div>
          <DataLoadingSpinner className="load spinner" />
        </div>

      );
    } else {
      if (CircuitList) {
        let upCircuits = CircuitList.filter(itm => itm.ActiveStatus == "active").length;
        let downCircuits = CircuitList.filter(itm => itm.ActiveStatus == "inactive").length;
        let offlineCircuits = CircuitList.filter(itm => itm.ActiveStatus == "Offline").length;
        return (

          <div>
            <div className="quick-items">
              <div className="quick-item">
                <label>Monitored Circuits Up</label>
                <h3>{upCircuits}</h3>
              </div>
              <div className="quick-item">
                <label>Monitored Circuits Down</label>
                <h3>{downCircuits}</h3>
              </div>
              <div className="quick-item">
                <label>Unmonitored Circuits</label>
                <h3>{offlineCircuits}</h3>
              </div>
              <div className="quick-item">
                <label>Total Circuits</label>
                <h3>{CircuitList.length}</h3>
              </div>
              <div className="quick-item">
                <label>Alarms</label>
                <h3 style={{ textDecorationLine: "underline" }}>0</h3>
              </div>
            </div>
          </div>
        );
      }
      else {
        return (
          <div style={{ textAlign: "center" }}>
            <p>No Available Data</p>
          </div>
        )
      }
    }
  };

  render() {
    if (sessionStorage.enterpriseType === 'DATA' || sessionStorage.enterpriseType === 'MIXED') {
      return (
        <div className="favorite-services card">
          <div className="quick-view">
            <div className="card-header">
              <h2 className="move">Circuit Summary</h2>
            </div>
            <div className="card-content">
              {this.cardContent()}
            </div>
          </div>
        </div>
      );
    }
    else {
      return null
    }
  }
}
