import React, { Component } from 'react';
import * as settings from './../../scripts/settings';
import { fetchData } from './../../requests/api_calls';
import { DataLoadingSpinner } from './../../components/spinners';
import { Grid, GridColumn as Column, GridCell } from '@progress/kendo-react-grid';
import { orderBy } from '@progress/kendo-data-query';
import { Link } from 'react-router-dom';
import { serviceFilters } from './../../constants/filters'
import { headerToolTip } from '../../components/ellipsisHeaderTooltip';
import E911Modal from '../profile/modals/e911Modal';
import E911EmailNotificationModal from './modals/E911EmailNotificationModal';
import WrappedCell from '../../components/wordWrappedCell'
import _ from 'lodash';

class LocationName extends GridCell {

  render() {
    let { LocationAccountNumber, LocationName } = this.props.dataItem;
    return (
      <td align="right" className="actions">
        <Link to={'/iframe_page/MSP_GROUP/' + LocationAccountNumber} className="no-btn">{LocationName}</Link>
      </td>
    );
  }
}

class LocationLink extends GridCell {

  render() {
    let locationID = this.props.dataItem.LocationAccountNumber;
    let path = "/services/" + locationID + '/' + serviceFilters.ALL_SERVICES_USERS;
    return (
      <td align="right" className="actions">
        {sessionStorage.enterpriseType ==='DATA'?<p  className="no-btn">{locationID}</p>:<Link to={path} className="no-btn">{locationID}</Link>}
      </td>
    );
  }
}

class ServiceLink extends GridCell {

  render() {
    let locationID = this.props.dataItem.LocationAccountNumber;
    let path = "/services/" + locationID + '/' + serviceFilters.ALL_SERVICES;
    return (
      <td align="right" className="actions">
        <Link to={path} className="no-btn">{this.props.dataItem.CountServices}</Link>
      </td>
    );
  }
}

class UserLink extends GridCell {

  render() {
    let locationID = this.props.dataItem.LocationAccountNumber;
    let path = "/services/" + locationID + '/' + serviceFilters.ALL_USERS;
    return (
      <td align="right" className="actions">
        <Link to={path} className="no-btn">{this.props.dataItem.CountUsers}</Link>
      </td>
    );
  }
}

// class ServiceNumbersClickPop extends GridCell {
//   render() {
//     return (
//       <td align="right" className="actions">
//         <ClickPop
//           CountTN={this.props.dataItem.CountTN}
//           eStreet={this.props.dataItem.StreetSVC}
//           eCity={this.props.dataItem.CitySVC}
//           eState={this.props.dataItem.StateSVC}
//           eAccountNumber={this.props.dataItem.LocationAccountNumber}
//         />
//       </td>
//     );
//   }
// }

class LocationEditButton extends GridCell {

  render() {
    let locationID = this.props.dataItem.LocationAccountNumber;
    return (
      <td align="right" className="actions">
        <Link to={'/iframe_page/MSP_GROUP/' + locationID} className="icon btn">
          <svg width="14" height="14" viewBox="0 0 24 24"><path d="m163 440l-91-91 251-250 90 90z m309-352l-48-48c-12-11-32-11-45 2l-45 45 91 91 45-45c13-13 13-33 2-45z m-408 275l-32 117 117-32z" transform="scale(0.046875 0.046875)"></path></svg>
        </Link>
      </td>
    );
  }
}

class Locations extends Component {

  constructor(props) {
    super(props);
    this.state = {
      locations: [],
      preFilter: props.location.state ? props.location.state.preFilter : null,
      loading: true,
      sort: [],
      skip: 0,
      gridHeight: (window.innerHeight - 210) + 'px',
      searchTerm: ''
    };
    this.fetchLocations = this.fetchLocations.bind(this);
    this.searchLocations = this.searchLocations.bind(this);
    this.sortChange = this.sortChange.bind(this);
    this.pageChange = this.pageChange.bind(this);
    const getLocations = this.getLocations.bind(this);
    const updateLocationFromModal = this.updateLocationFromModal.bind(this);
    class E911Link extends GridCell {
      constructor(props) {
        super(props);
      }
      render() {
        const productItem = this.props.dataItem;
        return (
          <td>
            <E911Modal AccountNumber={productItem.LocationAccountNumber} />
          </td>
        );
      }
    }

    class Location911Email extends GridCell {
      constructor(props) {
        super(props);
      }
      render() {
        const productItem = this.props.dataItem;
        const locations = getLocations();
        let address = productItem.StreetSVC + ' ' + productItem.CitySVC + ', ' + productItem.StateSVC + ' ' + productItem.ZipSVC;
        return (
          <td align="right" className="">
            <E911EmailNotificationModal CurrentLocation={productItem} Locations={locations} StreetAddress={address} updateParentArrayItem={updateLocationFromModal} />
          </td>
        )
      }
    }

    this.E911Link = E911Link;
    this.E911EmailModal = Location911Email;
  };

  handleResize = () => this.setState({
    gridHeight: (window.innerHeight - 210) + 'px'
  });

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize)
  }


  componentWillReceiveProps(nextProps, nextContext) {
    let curFilter = nextProps.location.state ? nextProps.location.state.preFilter : null;
    let apiPath;
    if (curFilter)
      apiPath = 'admin/Locations?term=' + curFilter;
    else
      apiPath = 'admin/Locations';

    this.setState({ ...this.state, preFilter: curFilter, loading: true });
    this.fetchLocations(apiPath);
  }

  componentDidMount() {

    this.handleResize();
    window.addEventListener('resize', this.handleResize)
    let apiPath = '';
    if (this.state.preFilter) {
      apiPath = 'admin/Locations?term=' + this.state.preFilter;
    } else {
      apiPath = 'admin/Locations';
    }
    this.fetchLocations(apiPath);
  }


  fetchLocations = (apiPath, resultsMsg, showLoading = true) => {
    this.setState({ loading: showLoading });
    fetchData(apiPath).then((data) => {
      if (data !== null) {
        this.setState({ locations: data, loading: false, resultsMsg: null });
      } else if (data === null) {
        this.setState({ locations: [], loading: false, resultsMsg: resultsMsg });
      };
    }).catch(ex => {
      console.log(ex);
      this.setState({ locations: [], loading: false });
    });
  }

  getLocations = () => {
    return this.state.locations;
  }

  updateLocationFromModal = async (locations, email) => {
    let updatedLocations = this.state.locations.slice();
    await locations.map((loc) => {
      let updatedLocation = updatedLocations.filter(res => res.LocationAccountNumber === loc);
      if (updatedLocation) {
        updatedLocation = updatedLocation[0];
        let idx = _.indexOf(this.state.locations, updatedLocation);
        updatedLocation.EmergencyCallNotificationEmail = email;
        updatedLocations[idx] = updatedLocation;

      }
    });

    let searchText = this.state.searchTerm;
    var apiPath = ''
    if (searchText !== '') {
      apiPath = 'admin/Locations?term=' + searchText;
    } else { // reset to all locations
      apiPath = 'admin/Locations';
    }
    this.setState({ locations: updatedLocations });
    //this.fetchLocations(apiPath, settings.noResultsMsg, false);
  }

  activeFilters = () => {
    let prefilter = this.state.preFilter;
    if (prefilter) {
      return (
        <h5 className='text-info'>Filters: {prefilter}, registered accounts
          <span className='btn text-danger clear-filter' onClick={this.clearFilter}>&times;</span>
        </h5>
      )
    }
  }

  sortChange(event) {
    this.setState({
      locations: this.sortLocations(event.sort),
      sort: event.sort
    });
  }

  pageChange(event) {
    this.setState({
      locations: this.state.locations.slice(),
      skip: event.page.skip
    });
  }

  sortLocations(sort) {
    var locations = [];
    if (this.state !== undefined) {
      locations = this.state.locations.slice();
    } else {
      locations = [];
    }
    return orderBy(locations, sort);
  }

  searchLocations = (event) => {
    let searchText = event.target.value.trim();
    this.setState({ searchTerm: searchText });
    var apiPath = ''
    if (searchText !== '') {
      apiPath = 'admin/Locations?term=' + searchText;
    } else { // reset to all locations
      apiPath = 'admin/Locations';
    }
    this.delayedSearch(apiPath);
  }

  delayedSearch = (apiPath) => {
    if (this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.fetchLocations(apiPath, settings.noResultsMsg);
    }, settings.searchDelay);
  }

  gridContent = () => {
    let { salesGroupId, serviceProviderName,enterpriseType } = sessionStorage;
    return (
      <Grid
        style={{ height: this.state.gridHeight }}
        data={this.state.locations}
        sortable={{
          allowUnsort: true,
          mode: 'single'
        }}
        sort={this.state.sort}
        onSortChange={this.sortChange}
        scrollable={'scrollable'}
      >
        <Column field='LocationName' title={'Name'} cell={WrappedCell} width="186px" cell={LocationName} />
        <Column field='LocationAccountNumber' cell={LocationLink} title={headerToolTip('Location ID')} width="130px" />
        {enterpriseType !== 'DATA'?<Column field='CountServices' cell={ServiceLink} title={headerToolTip('Services')} width="85px" />:null}
        {enterpriseType !== 'DATA'?<Column field='CountUsers' cell={UserLink} title={headerToolTip('Users')} width="85px" />:null}        
        <Column field='StreetSVC' title={headerToolTip('SVC Street')} cell={WrappedCell} width={"250px"} />
        <Column field='CitySVC' title={'SVC City'} width="225px" />
        <Column field='StateSVC' width="90px" title={'SVC State'} />
        {/*<Column field='MainTN' title='Main Service Number' width="140px" />*/}
        {/*<Column field='CountTN' cell={ServiceNumbersClickPop} title="Service Numbers" />*/}
        {salesGroupId === '14015' ||
          salesGroupId === '16632' ||
          salesGroupId === '24540' ||
          salesGroupId === '25011' ||
          salesGroupId === '26297' ||
          enterpriseType ==='DATA'
          ? null : <Column field='EmergencyCallNotificationEmail' title={'Emergency Call Notification Email'} cell={this.E911EmailModal} sortable={false} filterable={false} width="300px" />}
        {enterpriseType !== 'DATA'?<Column title='E911' cell={this.E911Link} sortable={false} filterable={false} width="155px" />:null}
        <Column title="Edit" cell={LocationEditButton} sortable={false} filterable={false} width="60px" />
        <Column field="spacer" title=" " sortable={false} filterable={false} />
      </Grid>
    )
  }

  locationsContent = () => {
    if (this.state.loading) {
      return <DataLoadingSpinner className='load spinner' />
    } else {
      let message = "";
      if (this.state.resultsMsg) {
        message = <h6 className='no-results-msg full-page-table'>{this.state.resultsMsg}</h6>
      }
      return (
        <div>
          <div className="card-header">
            <h1>Locations & Groups <small>{this.state.locations.length}</small></h1>
            <div className='filters'>{this.activeFilters()}</div>
            <div className="card-actions">
              <div className="search">
                <label htmlFor="location_srch_tb" className="hidden">location search</label>
                <input id="location_srch_tb" type="text" className="form-control search-control" autoComplete={"off"} onChange={this.searchLocations} value={this.state.searchTerm} />
                <svg width="14" height="14" viewBox="0 0 23 23"><path d="m347 238c0-36-12-66-37-91-25-25-55-37-91-37-35 0-65 12-90 37-25 25-38 55-38 91 0 35 13 65 38 90 25 25 55 38 90 38 36 0 66-13 91-38 25-25 37-55 37-90z m147 237c0 10-4 19-11 26-7 7-16 11-26 11-10 0-19-4-26-11l-98-98c-34 24-72 36-114 36-27 0-53-5-78-16-25-11-46-25-64-43-18-18-32-39-43-64-10-25-16-51-16-78 0-28 6-54 16-78 11-25 25-47 43-65 18-18 39-32 64-43 25-10 51-15 78-15 28 0 54 5 79 15 24 11 46 25 64 43 18 18 32 40 43 65 10 24 16 50 16 78 0 42-12 80-36 114l98 98c7 7 11 15 11 25z" transform="scale(0.046875 0.046875)"></path></svg>
              </div>
              {/*<a href="" className="btn">Export Locations</a>*/}
              {/*<a href="" className="orange btn">New Location</a>*/}
            </div>
          </div>
          <div>
            {this.gridContent()}
            {message}
          </div>
        </div>
      )
    }
  }

  render() {
    return (
      <div className="full-card container">
        <div className="locations card">
          {this.locationsContent()}
        </div>
      </div>
    )
  }

}

export default Locations;