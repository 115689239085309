export const UPDATE_TOKEN_STATUS = 'UPDATE_TOKEN_STATUS';
export const LOGIN_USER = 'LOGIN_USER';
export const RESTART_SESSION = 'RESTART_SESSION';
export const SAVE_LOGIN_ERROR = 'SAVE_LOGIN_ERROR';
export const REFRESH_TOKEN = 'REFRESH_TOKEN';
export const REDIRECTING = 'REDIRECTING';
export const SET_LOCATION = 'SET_LOCATION';
export const SET_QUICK_VIEW_LOCATION = 'SET_QUICK_VIEW_LOCATION';
export const SERVICE_SELECTED = 'SERVICE_SELECTED';
export const SET_SERVICE = 'SET_SERVICE';
export const SERVICES_SET = 'SERVICES_SET';
export const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR';
export const REDIRECT_BETA_ACCESS = 'REDIRECT_BETA_ACCESS';
export const RECEIVE_TOKEN = 'RECEIVE_TOKEN';
export const TOKEN_LOADED = 'TOKEN_LOADED';
export const START_LOGIN = 'START_LOGIN';
export const PERMISSIONS_UPDATED = 'PERMISSIONS_UPDATED';
export const RECEIVE_CUSTOM_IMAGE = 'RECEIVE_CUSTOM_IMAGE';
export const SET_CALL_LOGS_FILTER = 'SET_CALL_LOGS_FILTER';
export const SET_SUBSCRIBER_CALL_LOGS_FILTER = 'SET_SUBSCRIBER_CALL_LOGS_FILTER';
export const UI_SKINS_APPLIED = 'UI_SKINS_APPLIED';
export const IP_TRUNKING_ONLY_SET = 'IP_TRUNKING_ONLY_SET';
export const SET_MSP_LOGIN_DATA = 'SET_MSP_LOGIN_DATA';
export const VALIDATE_MFA = 'VALIDATE_MFA';