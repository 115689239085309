import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { Button } from '@progress/kendo-buttons-react-wrapper';

class DepartmentModal extends Component {

	constructor(props) {
		super(props);

    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.state = { showModal:false };
  }

  handleClose() {
    this.setState({ showModal: false });
  }

  handleShow() {
    this.setState({ showModal: true });
  }

	componentDidMount() {		
	}

  render() {
    
    return (
      <div className="setting">
        <span onClick={this.handleShow}> 
          <span className="name">Departments</span>
          <a className="icon btn"><svg width="14" height="14" viewBox="0 0 24 24"><path d="m163 440l-91-91 251-250 90 90z m309-352l-48-48c-12-11-32-11-45 2l-45 45 91 91 45-45c13-13 13-33 2-45z m-408 275l-32 117 117-32z" transform="scale(0.046875 0.046875)"></path></svg></a>
        </span>
        <Modal show={this.state.showModal} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Department Settings</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="department-block">
              <table className="table">
                <thead>
                  <tr>
                    <td>Department Name</td>
                    <td></td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Hyperloop 2</td>
                    <td>
                      <a className="icon btn" data-toggle="collapse" data-target="#dm1"><svg width="14" height="14" viewBox="0 0 24 24"><path d="m163 440l-91-91 251-250 90 90z m309-352l-48-48c-12-11-32-11-45 2l-45 45 91 91 45-45c13-13 13-33 2-45z m-408 275l-32 117 117-32z" transform="scale(0.046875 0.046875)"></path></svg></a>          
                    </td>
                  </tr>
                  <tr className="collapse" id="dm1">
                    {this.getDepartmentSettings()}
                  </tr>
                  <tr>
                    <td>Hyperloop 2</td>
                    <td>
                      <a className="icon btn" data-toggle="collapse" data-target="#dm2"><svg width="14" height="14" viewBox="0 0 24 24"><path d="m163 440l-91-91 251-250 90 90z m309-352l-48-48c-12-11-32-11-45 2l-45 45 91 91 45-45c13-13 13-33 2-45z m-408 275l-32 117 117-32z" transform="scale(0.046875 0.046875)"></path></svg></a>          
                    </td>
                  </tr>
                  <tr className="collapse" id="dm2">
                    {this.getDepartmentSettings()}
                  </tr>
                  <tr>
                    <td>Hyperloop 2</td>
                    <td>
                      <a className="icon btn" data-toggle="collapse" data-target="#dm3"><svg width="14" height="14" viewBox="0 0 24 24"><path d="m163 440l-91-91 251-250 90 90z m309-352l-48-48c-12-11-32-11-45 2l-45 45 91 91 45-45c13-13 13-33 2-45z m-408 275l-32 117 117-32z" transform="scale(0.046875 0.046875)"></path></svg></a>          
                    </td>
                  </tr>
                  <tr className="collapse" id="dm3">
                    {this.getDepartmentSettings()}
                  </tr>
                  <tr>
                    <td colSpan="2">
                      <div className="btns">
                        <a className="btn" data-toggle="collapse" data-target="#dm3">Add Department</a>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Modal.Body>
          <Modal.Footer>
              <hr />
              <div className="btns">
                <Button className="btn">Save</Button>
                <Button className="btn" click={this.handleClose}>Cancel</Button>
              </div>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }

  
  getDepartmentSettings(){
    return (
        <td colSpan="2">
          <div className="table-edit">
            { this.props.type === "EDIT"?  <a className="pull-right red">Delete Department</a>: null }
            <div className="form-group">
              <label>Department Name</label>
              <input type="text" className="form-control" />
            </div>
            <div className="form-group">
              <label >Department Parent</label>
              <select className="form-control">
                <option defaultValue="None">None</option>
                <option defaultValue="Marketing">Marketing</option>
                <option defaultValue="Graphics">Graphics</option>
                <option defaultValue="Sales">Sales</option>
              </select>
            </div>
            <div className="form-group">
              <label>Department Calling Line ID Name</label>
              <input type="type" className="form-control" defaultValue="" />
            </div>
            <div className="form-group">
              <label>Department Calling Line ID Number</label>
              <select className="form-control">
                <option defaultValue=""></option>
                <option defaultValue="">123 123 1234</option>
                <option defaultValue="">123 123 1234</option>
                <option defaultValue="">123 123 1234</option>
              </select>
            </div>
            <hr />
            <div className="btns">
              <a href="" className="btn">Save</a>
              <a href="" className="btn">Cancel</a>
            </div>
          </div>
        </td>
      )
  }
  // newDepartmentName(){
  //   if (this.props.type !== "EDIT") return null;
  //     return ('Department Name');
  // }
};

export default DepartmentModal;
