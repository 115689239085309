import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { Button } from '@progress/kendo-buttons-react-wrapper';
import { DataLoadingSpinner } from './../../components/spinners';

class VoicemailDeleteModal extends Component {

  constructor(props) {
    super(props);

    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);

    this.state = { showModal: false };
  }

  handleClose() {
    this.setState({ showModal: false });
  }

  handleShow() {
    this.setState({ showModal: true });
    this.props.handleShow();
  }

  componentDidMount() {   
  }

  render() {
    return (
      <span>
        <a onClick={this.handleShow}>Delete</a>

        <Modal show={this.state.showModal} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Are you sure you want to delete these voicemails?</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              {this.props.body}
            </div>
          </Modal.Body>
          <Modal.Footer>
              <hr />
              {this.loading()}
              <div className="btns">
              <Button click={this.props.handleYes} enable={!this.props.loading}>Yes</Button>
              <Button className="btn" click={this.handleClose} enable={!this.props.loading}>No</Button>
              </div>
          </Modal.Footer>
        </Modal>
      </span>
    );
  }

  
  loading(){
    if(this.props.loading)
      return <DataLoadingSpinner className='load spinner pull-left'/>;

    return "";
  }
};

export default VoicemailDeleteModal;
