import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import format from 'string-format';
import { DataLoadingSpinner } from './../components/spinners';
import * as apiCalls from '../requests/api_calls';
import moment from 'moment';

import { DatePicker } from '@progress/kendo-dateinputs-react-wrapper';
import * as settings from '../scripts/settings';
import BSCallLogsTab from '../components/bs_call_logs_tab';
import SansayCallLogsTab from '../components/sansay_call_logs_tab';
import FaxCallLogsTab from '../components/fax_call_logs_tab';

class CallLogs extends Component {

  constructor(props) {
    super(props);
    this.state = {
      LocationAccountNumber: '',
      BSCallLogs: [],
      SansayCallLogs: [],
      FaxCallLogs: [],
      tab: 'bs',
      loading: false,
      call_category: (sessionStorage.usercl_call_category || "1"),
      call_type: (sessionStorage.usercl_call_type || "1"),
      status_type: (sessionStorage.usercl_status_type || "1"),
      date_type: (sessionStorage.usercl_date_type || "1"), // Today
      from_date: (sessionStorage.usercl_from_date ||moment(Date.now()).startOf("day").format("YYYY-MM-DD")),
      to_date: (sessionStorage.usercl_to_date || moment(Date.now()).endOf("day").format("YYYY-MM-DD")),
      ServiceNumber: props.ServiceNumber,
      PBXUserID: props.PBXUserID,
      isIpTrunking: props.isIpTrunking
    }
    this.handleCallType = this.handleCallType.bind(this);
    this.handleStatusType = this.handleStatusType.bind(this);
    this.handleDate = this.handleDate.bind(this);
    this.handleChangeFromDate = this.handleChangeFromDate.bind(this);
    this.handleChangeToDate = this.handleChangeToDate.bind(this);
    this.UpdateStoreAndListings = this.UpdateStoreAndListings.bind(this);
  };

  UpdateStoreAndListings = () => {
    settings.setCLFilters(this.state.call_type, this.state.status_type, this.state.call_category, 
      this.state.date_type, this.state.from_date, this.state.to_date, this.state.PBXUserID
     );
     this.fetchCallLogs(this.getApiUrl(this.props), settings.noResultsMsg);
  }

  handleChangeFromDate(event) {
    let from = moment(event.sender._oldText, "M/D/YYYY");
    if (from.isValid()) {
      this.setState({ ...{ from_date: from.format("YYYY-MM-DD") } }, () => {
        let to = moment(this.state.to_date);
        if (to.isValid() && to.isSameOrAfter(from)) {
          this.setState({ ...{ BSCallLogs: [], SansayCallLogs: [], FaxCallLogs: [], loading: true } }, this.UpdateStoreAndListings);
        }
      });
    }
  }

  handleChangeToDate(event) {
    let to = moment(event.sender._oldText, "M/D/YYYY");
    if (to.isValid()) {
      this.setState({ ...{ to_date: to.format("YYYY-MM-DD") } }, () => {
        let from = moment(this.state.from_date);
        if (from.isValid() && from.isSameOrBefore(to)) {
          this.setState({ ...{ BSCallLogs: [], SansayCallLogs: [], FaxCallLogs: [], loading: true } }, this.UpdateStoreAndListings);
        }
      });
    }
  }

  handleDate(event) {
    switch (event.target.value) {
      case "1":
        this.setState({
          ...{
            BSCallLogs: [], SansayCallLogs: [], FaxCallLogs: [], date_type: event.target.value, from_date: moment(Date.now()).startOf("day").format("YYYY-MM-DD"),
            to_date: moment(Date.now()).endOf("day").format("YYYY-MM-DD"), loading: true
          }
        }, this.UpdateStoreAndListings);
        return;
      case "2":
        this.setState({
          ...{
            BSCallLogs: [], SansayCallLogs: [], FaxCallLogs: [], date_type: event.target.value, from_date: moment(Date.now()).subtract(1, "days").startOf("day").format("YYYY-MM-DD"),
            to_date: moment(Date.now()).endOf("day").subtract(1, "days").format("YYYY-MM-DD"), loading: true
          }
        }, this.UpdateStoreAndListings);
        return;
      case "3":
        this.setState({
          ...{
            BSCallLogs: [], SansayCallLogs: [], FaxCallLogs: [], date_type: event.target.value, from_date: moment(Date.now()).subtract(6, "days").startOf("day").format("YYYY-MM-DD"),
            to_date: moment(Date.now()).endOf("day").format("YYYY-MM-DD"), loading: true
          }
        }, this.UpdateStoreAndListings);
        return;
      case "4":
        this.setState({
          ...{
            BSCallLogs: [], SansayCallLogs: [], FaxCallLogs: [], date_type: event.target.value, from_date: moment(Date.now()).subtract(29, "days").startOf("day").format("YYYY-MM-DD"),
            to_date: moment(Date.now()).endOf("day").format("YYYY-MM-DD"), loading: true
          }
        }, this.UpdateStoreAndListings);
        return;
      case "5":
        this.setState({
          ...{
            BSCallLogs: [], SansayCallLogs: [], FaxCallLogs: [], date_type: event.target.value, from_date: moment(Date.now()).subtract(89, "days").startOf("day").format("YYYY-MM-DD"),
            to_date: moment(Date.now()).endOf("day").format("YYYY-MM-DD"), loading: true
          }
        }, this.UpdateStoreAndListings);
        return;
      case "6":
        this.setState({ ...{ BSCallLogs: [], SansayCallLogs: [], FaxCallLogs: [], date_type: event.target.value, from_date: "From", to_date: "To" } });
        settings.setCLFilters(this.state.call_type, this.state.status_type, this.state.call_category, 
          this.state.date_type, this.state.from_date, this.state.to_date, this.state.PBXUserID
         );
        return;
    }
    
  }

  handleCallType(event) {
    this.setState({ ...{ BSCallLogs: [], SansayCallLogs: [], FaxCallLogs: [], call_type: event.target.value, loading: true } }, this.UpdateStoreAndListings);
  }

  handleStatusType(event) {
    this.setState({ ...{ BSCallLogs: [], SansayCallLogs: [], FaxCallLogs: [], status_type: event.target.value, loading: true } }, this.UpdateStoreAndListings);
  }

  componentDidMount() {
    this.setState({ ...{ BSCallLogs: [], SansayCallLogs: [], FaxCallLogs: [], loading: true } });
    this.fetchCallLogs(this.getApiUrl(this.props), settings.noResultsMsg);
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ ...{ BSCallLogs: [], SansayCallLogs: [], FaxCallLogs: [], loading: true } });
    this.fetchCallLogs(this.getApiUrl(nextProps), settings.noResultsMsg);
  }

  fetchCallLogs = async (apiPath) => {
    let selectedTab = this.state.tab;
    await this.setState({ loading: true });
    apiCalls.fetchData(apiPath).then(async (data) => {
      if (data !== null && data !== undefined) {
        let isIpTrunking = false;
        let { BSCallLogs, SansayCallLogs, FDRCallLogs } = data;
        if (BSCallLogs.length === 0 && SansayCallLogs.length > 0) {
          selectedTab = 'sansay';
          isIpTrunking = true; 
        }
        else if (BSCallLogs.length === 0 && SansayCallLogs.length === 0 && (FDRCallLogs && FDRCallLogs.length > 0))
          selectedTab = 'fax';
        else
          selectedTab = 'bs';

        await this.setState({
          BSCallLogs: this.revisedData(BSCallLogs), 
          SansayCallLogs: this.revisedData(SansayCallLogs,true), 
          FaxCallLogs: this.revisedData(FDRCallLogs, false, true), 
          loading: false, 
          tab: selectedTab,
          isIpTrunking: isIpTrunking
        });
      } else this.setState({ BSCallLogs: [], SansayCallLogs: [], FaxCallLogs: [], logs: [], loading: false });
    }).catch((msg) => console.error(msg));
  }

  revisedData = (data, isIPTrunking = false, isFax = false) => {
    let revisedData = [];
    if (isIPTrunking)
      revisedData = data.map((item, idx) => {
        item.Release_Time = new Date(item.Release_Time);
        item.Start_Time = new Date(item.Start_Time);
        return item;
      });
    else if (isFax)
      revisedData = data.map((item, idx) => {
        item.StartTime = new Date(item.StartTime);
        return item;
      });
    else
      revisedData = data.map((item, idx) => {
                      item.Date = new Date(item.Date);
                      return item;
                    });

    return revisedData;
  }


  extension = () => {
    let ext = this.props.Extension;
    if (ext) {
      return <span> ({ext})</span>
    } else return null;
  }

  callLogFilter = (htmlSelect) => {
    return (
      <div className="filter" style={{
        paddingRight: '4px',
        float: 'left',
        width: '33.33%',
      }
      }>
        {htmlSelect}
      </div >
    )
  }

  getTab() {
    let { tab, BSCallLogs, SansayCallLogs, FaxCallLogs, loading } = this.state;
    switch (tab) {
      case 'bs':
        if (BSCallLogs.length > 20) {
          BSCallLogs = BSCallLogs.slice(0, 20);
        }
        return (
          <BSCallLogsTab BsLogs={BSCallLogs} isLoading={loading} isWidget={true} />
        )
      case 'sansay':
        if (SansayCallLogs.length > 20) {
          SansayCallLogs = SansayCallLogs.slice(0, 20);
        }
        return (
          <SansayCallLogsTab SansayLogs={SansayCallLogs} isLoading={loading} />
        )
      case 'fax':
        if (FaxCallLogs.length > 20) {
          FaxCallLogs = FaxCallLogs.slice(0, 20);
        }
        return (
          <FaxCallLogsTab FaxCallLogs={FaxCallLogs} ServiceNumber={this.state.ServiceNumber} isLoading={loading} isWidget={true} />
        )
      default:
        console.log('Sorry there isn\'t a tab named ' + tab + '.');
    }
  }

  render() {
    if (this.state.loading) {
      return (
        <div className="call-logs card">
          <div className="card-header">
            <div className='card-actions'><div className='move'><svg width='12' height='12' viewBox='0 0 23 23'><path d='m512 256c0 5-2 9-5 13l-74 73c-3 4-7 5-12 5-5 0-10-1-13-5-4-4-6-8-6-13l0-36-109 0 0 109 36 0c5 0 9 2 13 6 4 3 5 8 5 13 0 5-1 9-5 12l-73 74c-4 3-8 5-13 5-5 0-9-2-13-5l-73-74c-4-3-5-7-5-12 0-5 1-10 5-13 4-4 8-6 13-6l36 0 0-109-109 0 0 36c0 5-2 9-6 13-3 4-8 5-13 5-5 0-9-1-12-5l-74-73c-3-4-5-8-5-13 0-5 2-9 5-13l74-73c3-4 7-5 12-5 5 0 10 1 13 5 4 4 6 8 6 13l0 36 109 0 0-109-36 0c-5 0-9-2-13-6-4-3-5-8-5-13 0-5 1-9 5-12l73-74c4-3 8-5 13-5 5 0 9 2 13 5l73 74c4 3 5 7 5 12 0 5-1 10-5 13-4 4-8 6-13 6l-36 0 0 109 109 0 0-36c0-5 2-9 6-13 3-4 8-5 13-5 5 0 9 1 12 5l74 73c3 4 5 8 5 13z' transform='scale(0.046875 0.046875)'></path></svg></div><div className='expand'><svg width='12' height='12' viewBox='0 0 23 23'><path d='m26 344l-26 158c0 2 1 5 3 7c0 0 0 0 0 0c2 2 5 3 7 3l158-27c3 0 6-3 7-6c1-3 0-7-3-9l-30-31l82-82c4-4 4-9 0-13l-57-57c-3-3-9-3-12 0l-83 83l-31-31c-2-2-5-3-9-2c-3 1-5 4-6 7z m460-176l26-158c0-2-1-5-3-7c0 0 0 0 0 0c-2-2-5-3-7-3l-158 27c-3 0-6 3-7 6c-1 3 0 7 3 9l30 31l-82 82c-4 4-4 9 0 13l57 57c3 3 9 3 12 0l83-83l31 31c2 2 5 3 9 2c3-1 5-4 6-7z' transform='scale(0.046875 0.046875)'></path></svg></div></div>
            <h2 className="move">Call Logs</h2>
          </div>
          <div className="card-content">
            <DataLoadingSpinner className='load spinner pull-left' />
          </div>
        </div>);
    }
    let newProps = this.props;
    let callLogsLink = format("call_logs_all/{LocationAccountNumber}", this.props);

    if (!newProps.PBXUserID || newProps.PBXUserID === '') callLogsLink += '/1';
    else callLogsLink += '/' + newProps.PBXUserID;

    if (!newProps.ServiceNumber || newProps.ServiceNumber === '') callLogsLink += '/1';
    else callLogsLink += '/' + newProps.ServiceNumber;
    
    return (
      <div className="call-logs card">
        <div className="card-header">
          <div className='card-actions'><div className='move'><svg width='12' height='12' viewBox='0 0 23 23'><path d='m512 256c0 5-2 9-5 13l-74 73c-3 4-7 5-12 5-5 0-10-1-13-5-4-4-6-8-6-13l0-36-109 0 0 109 36 0c5 0 9 2 13 6 4 3 5 8 5 13 0 5-1 9-5 12l-73 74c-4 3-8 5-13 5-5 0-9-2-13-5l-73-74c-4-3-5-7-5-12 0-5 1-10 5-13 4-4 8-6 13-6l36 0 0-109-109 0 0 36c0 5-2 9-6 13-3 4-8 5-13 5-5 0-9-1-12-5l-74-73c-3-4-5-8-5-13 0-5 2-9 5-13l74-73c3-4 7-5 12-5 5 0 10 1 13 5 4 4 6 8 6 13l0 36 109 0 0-109-36 0c-5 0-9-2-13-6-4-3-5-8-5-13 0-5 1-9 5-12l73-74c4-3 8-5 13-5 5 0 9 2 13 5l73 74c4 3 5 7 5 12 0 5-1 10-5 13-4 4-8 6-13 6l-36 0 0 109 109 0 0-36c0-5 2-9 6-13 3-4 8-5 13-5 5 0 9 1 12 5l74 73c3 4 5 8 5 13z' transform='scale(0.046875 0.046875)'></path></svg></div><div className='expand'><svg width='12' height='12' viewBox='0 0 23 23'><path d='m26 344l-26 158c0 2 1 5 3 7c0 0 0 0 0 0c2 2 5 3 7 3l158-27c3 0 6-3 7-6c1-3 0-7-3-9l-30-31l82-82c4-4 4-9 0-13l-57-57c-3-3-9-3-12 0l-83 83l-31-31c-2-2-5-3-9-2c-3 1-5 4-6 7z m460-176l26-158c0-2-1-5-3-7c0 0 0 0 0 0c-2-2-5-3-7-3l-158 27c-3 0-6 3-7 6c-1 3 0 7 3 9l30 31l-82 82c-4 4-4 9 0 13l57 57c3 3 9 3 12 0l83-83l31 31c2 2 5 3 9 2c3-1 5-4 6-7z' transform='scale(0.046875 0.046875)'></path></svg></div></div>
          <h2 className="move">
            Call Logs
          <small> {this.props.ServiceNumber} {this.extension()}</small>
          </h2>
        </div>
        <div className="call-log-actions card-content">
          <div className="call-log-action">
            {this.callLogFilter(<select value="1" className="form-control" value={this.state.call_type} onChange={this.handleCallType}>
              <option value="1">All Calls</option>
              <option value="2">Incoming</option>
              <option value="3">Outgoing</option>
            </select>)}
            {this.callLogFilter(<select value="1" className="form-control" value={this.state.status_type} onChange={this.handleStatusType} disabled={this.state.tab === 'fax' ? 'disabled' : ''}>
              <option value="1">All Calls</option>
              <option value="2">Connected</option>
              <option value="3">No Answer</option>
              <option value="4">Incomplete</option>
              <option value="5">Forwarded</option>
            </select>)}
            {this.callLogFilter(<select className="call-log-dates form-control" value={this.state.date_type} onChange={this.handleDate}>
              <option value="1">Today</option>
              <option value="2">Yesterday</option>
              <option value="3">Last 7 Days</option>
              <option value="4">Last 30 Days</option>
              <option value="5">Last 90 Days</option>
              <option value="6">Custom</option>
            </select>)}
          </div>
          {
            (this.state.date_type === "6") ?
              (<div className="custom-dates">
                <div className="date">
                  <DatePicker value={this.state.from_date} change={this.handleChangeFromDate} />
                </div>
                <div className="date">
                  <DatePicker value={this.state.to_date} change={this.handleChangeToDate} />
                </div>
              </div>)
              : ""
          }
        </div>
        <div className='card-content'>
          <div className="tab-body">
            {this.getTab()}
          </div>
          <div className="view-more">
            <Link to={callLogsLink} className="no-btn">View All Call Logs & Details</Link>
          </div>
        </div>
      </div>
    );
  }

  getApiUrl(props) {

    let finalToDate = this.state.to_date + '%2023:59:59';
    let newOptions = {
      call_type: this.state.call_type,
      status_type: this.state.status_type,
      call_category: this.state.call_category,
      from_date: this.state.from_date,
      to_date: finalToDate
    };

    let apiUrl = format('usr/GetCallLogs?LocationAccountNumber={LocationAccountNumber}&PBXUserID={PBXUserID}&ServiceNumber={ServiceNumber}', props);
    apiUrl += format("&CallType={call_type}&CallStatus={status_type}&CallCategory={call_category}&FromDate={from_date}&ToDate={to_date}", newOptions);
    return apiUrl;
  }
};

export default CallLogs;