import React, { Component } from 'react';
import AffectedCircuitsModal from './components/affected_circuits_modal';
import AffectedDevicesModal from './components/affeced_devices_modal';
import AffectedServicesModal from './components/affected_services_modal';
import * as apiCalls from '../../requests/api_calls';
import { DataLoadingSpinner } from '../../components/spinners';
import * as helper from './helper/ticket_helper';
import { Modal } from 'react-bootstrap';
import { userIsAdmin } from '../../scripts/helper';

const accountID = '0010h00001XhZxcAAF';


class NewTicket extends Component {
  constructor(props) {
    super(props);
    this.state = {
      TicketSubject: '',
      CircuitId: '',
      TicketDescription: '',
      SelectedLocation: '',
      SelectedContact: '',
      SelectedPriority: 'Minor',
      SelectedCategory: '',
      SelectedType: '',
      // SelectedSubCategory: '',
      SelectedOtherCategory: '',
      SelectedCommMethod: '',
      SelectCircuitsFromList: '',
      submittedTicket: false,
      ticketSubFailed: false,
      AffectedDevices: [],
      AffectedServices: [],
      AttachmentList: [],
      AffectedCircuits: [],
      fileIndex: 0,
      redirectedFromCircuitsList: props.location.state ? props.location.state.redirectedFromCircuitsList : null,
      showConfirmationModal: false,
      type: [{ key: "", value: "Select" }, { key: "DATA_SERVICES", value: "Data" }, { key: "RETAIL_TROUBLE", value: "Voice" }, { key: "VOICE_DUPLICATE", value: "Both" }],
      SelectedMixedType: props.location.state ? "DATA_SERVICES" : '',
      ContactName: '',
      ContactEmail: '',
      ContactPhone: '',
      ShowTextBoxesForContactDetails: false,
      LoggedUser: sessionStorage.userEmail
    }
    this.handleCloseConfirmation = this.handleCloseConfirmation.bind(this);
    this.handleCancelTicketYes = this.handleCancelTicketYes.bind(this);
    this.ticketType = "";
  }

  componentDidMount() {
    if (sessionStorage.hasTicketing !== 'FULL') {
      this.props.history.push('/');
    }
    sessionStorage.removeItem('serviceList');
    sessionStorage.removeItem('circuitsList');
    sessionStorage.removeItem('deviceList');
    this.fetchTicketData();
    this.storeAffectedCircuitsRedirectedFromCircuits();

  }

  fetchTicketData = () => {
    let requests = [
      this.fetchContactList(),
      this.getCategoryList(),
      this.fetchLocations(),
      this.getPriorityList(),
      this.getCommMethodList(),
      this.getTypeList(),
      this.getCircuitsList()
    ];
    Promise.all(requests).catch(err => console.error(err));
  }

  getTypeList = () => {
    const type = this.state.type.map((i) => {
      return <option key={i.key} value={i.key}>{i.value}</option>
    })
    this.setState({ TypeListOptions: type });
  }

  getCommMethodList = () => {
    let apiPath;
    sessionStorage.enterpriseType == "DATA" ? apiPath = `admin/GetPicklistValues?RecordTypeId=${helper.dataServices}&Picklist=COMM_METHOD&ControllerValue=` :
      apiPath = `admin/GetPicklistValues?RecordTypeId=${helper.retailTrouble}&Picklist=COMM_METHOD&ControllerValue=`;
    apiCalls.fetchData(apiPath).then(data => {
      if (data) {
        if (data.ValueList.includes('Portal')) {
          data.ValueList.splice(data.ValueList.indexOf('Portal'), 1);
        }
        let options = data.ValueList.map((item, idx) => {
          return <option key={idx} value={item}>{item}</option>
        });
        this.setState({ CommMethodOptions: options });
      } else {
        this.setState({ CommMethodOptions: [] });
      }
    }).catch(err => console.error(err));
  }

  getCircuitsList = () => {
    let apiPath = "admin/GetCircuitsList";
    apiCalls.fetchData(apiPath).then(data => {
      if (data) {
        //console.log(data, "CircuitsList")
        // if (data.ValueList.includes('Portal')) {
        //   data.ValueList.splice(data.ValueList.indexOf('Portal'), 1);
        // }
        let options = data.map((circuit, idx) => {
          return <option key={idx} value={circuit.CircuitId}>{circuit.CircuitId}</option>
        });

        // let options = data.ValueList.map((item, idx) => {
        //   return <option key={idx} value={item}>{item}</option>
        // });
        this.setState({ CircuitsOptions: options });
      } else {
        this.setState({ CircuitsOptions: [] });
      }
    }).catch(err => console.error(err));
  }

  getPriorityList = () => {
    let apiPath;
    sessionStorage.enterpriseType == "DATA" ? apiPath = `admin/GetPicklistValues?RecordTypeId=${helper.dataServices}&Picklist=PRIORITY&ControllerValue=` :
      apiPath = `admin/GetPicklistValues?RecordTypeId=${helper.retailTrouble}&Picklist=PRIORITY&ControllerValue=`;
    apiCalls.fetchData(apiPath).then(data => {
      if (data) {
        let options = data.ValueList.map((item, idx) => {
          return <option key={idx} value={item}>{item}</option>
        });
        this.setState({ PriorityOptions: options });
      } else {
        this.setState({ PriorityOptions: [] });
      }
    }).catch(err => console.error(err));
  }

  getCategoryList = () => {
    let apiPath;

    sessionStorage.enterpriseType == "DATA" ? apiPath = `admin/GetPicklistValues?RecordTypeId=${helper.dataServices}&Picklist=CATEGORY&ControllerValue=` :
      apiPath = `admin/GetPicklistValues?RecordTypeId=${helper.retailTrouble}&Picklist=CATEGORY&ControllerValue=`;
    apiCalls.fetchData(apiPath).then(data => {
      if (data) {
        let options = data.ValueList.filter(option => option !== "Other").map((item, idx) => {
          return <option key={idx} value={item}>{item}</option>
        });
        this.setState({ CategoryOptions: options });
      } else {
        this.setState({ CategoryOptions: [] });
      }
    }).catch(err => console.error(err));
  }

  // getSubCategoryList = (categoryValue) => {
  //   if (categoryValue === '' || categoryValue === 'Other') {
  //     return;
  //   } else {
  //     let apiPath;
  //     sessionStorage.enterpriseType == "DATA" ? apiPath = `admin/GetPicklistValues?RecordTypeId=${helper.dataServices}&Picklist=SUB_CATEGORY&ControllerValue=${categoryValue}` :
  //       apiPath = `admin/GetPicklistValues?RecordTypeId=${helper.retailTrouble}&Picklist=SUB_CATEGORY&ControllerValue=${categoryValue}`;
  //     apiCalls.fetchData(apiPath).then(data => {
  //       if (data) {
  //         let options = data.ValueList.map((item, idx) => {
  //           return <option key={idx} value={item}>{item}</option>
  //         });
  //         this.setState({ SubCategoryOptions: options });
  //       } else {
  //         this.setState({ SubCategoryOptions: [] });
  //       }
  //     }).catch(err => console.error(err));
  //   }
  // }

  fetchLocations = () => {
    let apiPath = '';
    if (userIsAdmin())
      apiPath = 'admin/Locations';
    else
      apiPath = 'usr/GetUserLocation';

    apiCalls.fetchData(apiPath).then(data => {
      if (data) {
        let options = data.map((location, idx) => {
          return <option key={idx} value={location.LocationAccountNumber}>{location.LocationName}</option>
        });
        if (data.length === 1)
          this.setState({ SelectedLocation: data[0].LocationAccountNumber, LocationOptions: options });
        else
          this.setState({ LocationOptions: options });
      } else {
        this.setState({ LocationOptions: [] });
      }
    }).catch(err => console.error(err));
  }

  fetchContactList = () => {
    let apiPath = 'admin/GetContactList';
    apiCalls.fetchData(apiPath).then(data => {
      if (data) {
        let options = data.map((contact, idx) => {
          return <option key={idx} value={contact.ContactId}>{contact.Name}</option>
        });
        this.setState({ ContactOptions: options });
      } else {
        this.setState({ ContactOptions: [] });
      }
    }).catch(err => console.error(err));
  }

  formGroupDropDown = (labelFor, labelName, val, options) => {
    let setOptions = (options) => {
      if (options) {
        if (labelFor === 'Contact' && labelName === 'Contact') {
          return (
            <select id={labelFor} className='form-control' value={val}
              onChange={(e) => this.handleInputChange(e, labelFor)}
              style={{
                width: '40%',
                display: 'inline-block',
              }}
            >
              <option value=''>Select</option>
              <option value='Others'>Write-In Contact</option>
              {options}
            </select>
          )
        }
        if (labelFor === 'Type' && labelName === 'Type') {
          return (
            <select id={labelFor} className='form-control' value={val}
              onChange={(e) => this.handleInputChange(e, labelFor)}
              style={{
                width: '13%',
                display: 'inline-block',
              }}
            >
              {options}
            </select>
          )
        }
        else {
          return (
            <select id={labelFor} className='form-control' value={val}
              onChange={(e) => this.handleInputChange(e, labelFor)}
              style={{
                width: '40%',
                display: 'inline-block',
              }}
            >
              <option value=''>Select</option>
              {options}
            </select>
          )
        }
      } else {
        return <DataLoadingSpinner
          style={{
            position: 'relative',
            height: '0px',
            bottom: '20px',
          }}
          className='load spinner'
        />
      }
    }
    if (labelFor === 'Type') {
      return (
        <div className='form-group-wrapper'
          style={{ paddingBottom: '15px' }}
        >
          {sessionStorage.enterpriseType == "DATA" ?
            <label style={{
              width: '45%',
              fontSize: '100%'
            }} htmlFor={labelFor}>{labelName} {labelFor !== 'SubCategory' && labelFor !== 'Priority' && labelFor !== 'CommunicationMethod' && labelFor !== 'Priority' && labelFor !== 'SubCategory' && labelFor !== 'Contact' ?
              <span style={{ color: 'red', fontSize: '18px' }}>*</span> : null}:</label> :
            this.state.SelectedMixedType === 'RETAIL_TROUBLE' || sessionStorage.enterpriseType === 'VOICE' || this.state.SelectedMixedType === "VOICE_DUPLICATE" ?
              <label style={{
                width: '15%',
                fontSize: '100%'
              }} htmlFor={labelFor}>{labelName} {labelFor !== 'SubCategory' && labelFor !== 'Priority' && labelFor !== 'CommunicationMethod' && labelFor !== 'Priority' && labelFor !== 'Location' && labelFor !== 'Contact' ? <span style={{ color: 'red', fontSize: '18px' }}>*</span> : null}:</label> :
              <label style={{
                width: '15%',
                fontSize: '100%'
              }} htmlFor={labelFor}>{labelName} {labelFor !== 'SubCategory' && labelFor !== 'Priority' && labelFor !== 'CommunicationMethod' && labelFor !== 'Priority' && labelFor !== 'Contact' ?
                <span style={{ color: 'red', fontSize: '18px' }}>*</span> : null}:</label>
          }
          {setOptions(options)}
        </div>
      )
    }
    else {
      return (
        <div className='form-group-wrapper'
          style={{ paddingBottom: '15px' }}
        >
          {sessionStorage.enterpriseType == "DATA" ?
            <label style={{
              width: '45%',
              fontSize: '100%'
            }} htmlFor={labelFor}>{labelName} {labelFor !== 'SubCategory' && labelFor !== 'Priority' && labelFor !== 'CommunicationMethod' && labelFor !== 'Priority' && labelFor !== 'SubCategory' && labelFor !== 'Contact' && labelFor !== 'Category' && labelFor !== 'Location' ? <span style={{ color: 'red', fontSize: '18px' }}>*</span> : null}:</label> :
            this.state.SelectedMixedType === 'RETAIL_TROUBLE' || sessionStorage.enterpriseType === 'VOICE' || this.state.SelectedMixedType === "VOICE_DUPLICATE" ?
              <label style={{
                width: '45%',
                fontSize: '100%'
              }} htmlFor={labelFor}>{labelName} {labelFor !== 'SubCategory' && labelFor !== 'Priority' && labelFor !== 'CommunicationMethod' && labelFor !== 'Priority' && labelFor !== 'Location' && labelFor !== 'Contact' && labelFor !== 'Category' ? <span style={{ color: 'red', fontSize: '18px' }}>*</span> : null}:</label> :
              <label style={{
                width: '45%',
                fontSize: '100%'
              }} htmlFor={labelFor}>{labelName} {labelFor !== 'SubCategory' && labelFor !== 'Priority' && labelFor !== 'CommunicationMethod' && labelFor !== 'Priority' && labelFor !== 'Contact' && labelFor !== 'Category' && labelFor !== 'Location' ? <span style={{ color: 'red', fontSize: '18px' }}>*</span> : null}:</label>
          }
          {setOptions(options)}
        </div>
      )
    }

  }

  renderMandatorySymbol = () => {
    return (
      <div className='form-group-wrapper'
        style={{ paddingBottom: '15px' }}
      >
        {sessionStorage.enterpriseType == "DATA" ?
          <label style={{
            width: '45%',
            fontSize: '100%'
          }} htmlFor={labelFor}>{labelName} {labelFor !== 'SubCategory' && labelFor !== 'Priority' && labelFor !== 'CommunicationMethod' && labelFor !== 'Priority' && labelFor !== 'SubCategory' ? <span style={{ color: 'red', fontSize: '18px' }}>*</span> : null}:</label> :
          this.state.SelectedMixedType === 'RETAIL_TROUBLE' || sessionStorage.enterpriseType === 'VOICE' || this.state.SelectedMixedType === "VOICE_DUPLICATE" ?
            <label style={{
              width: '45%',
              fontSize: '100%'
            }} htmlFor={labelFor}>{labelName} {labelFor !== 'SubCategory' && labelFor !== 'Priority' && labelFor !== 'CommunicationMethod' && labelFor !== 'Priority' && labelFor !== 'Location' ? <span style={{ color: 'red', fontSize: '18px' }}>*</span> : null}:</label> :
            <label style={{
              width: '45%',
              fontSize: '100%'
            }} htmlFor={labelFor}>{labelName} {labelFor !== 'SubCategory' && labelFor !== 'Priority' && labelFor !== 'CommunicationMethod' && labelFor !== 'Priority' ? <span style={{ color: 'red', fontSize: '18px' }}>*</span> : null}:</label>
        }
        {setOptions(options)}
      </div>
    )
  }

  formGroupInput = (labelFor, labelName, val, type = 'text') => {
    return (
      <div className='form-group-wrapper'
        style={{ paddingBottom: '15px' }}
      >
        <label style={{
          width: '45%',
          fontSize: '100%'
        }} htmlFor={labelFor}>{labelName}:</label>
        <input className='form-control'
          style={{
            width: '40%',
            display: 'inline-block'
          }}
          type={type} id={labelFor} name='' value={val}
          onChange={(e) => this.handleInputChange(e, labelFor)}
        />
      </div>
    )
  }

  selectOptions = (options) => {
    return options.map((option, index) => {
      return <option key={index} value={option}>{option}</option>
    })
  }

  handleInputChange = (e, labelFor) => {
    let { value } = e.target;
    switch (labelFor) {
      case 'Location':
        this.setState({ SelectedLocation: value });
        return;
      case 'Contact':
        this.setState({ SelectedContact: value });
        value === 'Others' ?
          this.setState({ ShowTextBoxesForContactDetails: true }) :
          this.setState({ ShowTextBoxesForContactDetails: false })
        return;
      case 'Priority':
        this.setState({ SelectedPriority: value });
        return;
      case 'Category':
        // this.setState({ SubCategoryOptions: undefined })
        // this.getSubCategoryList(value);
        this.setState({ SelectedCategory: value });
        return;
      // case 'SubCategory':
      //   this.setState({ SelectedSubCategory: value });
      //   return;
      case 'Type':
        this.setState({ SelectedMixedType: value });
        if(value=== 'RETAIL_TROUBLE' ||value === 'VOICE' ||value === "VOICE_DUPLICATE"){
          this.setState({SelectCircuitsFromList:""})
          this.setState({ TicketSubject: "" });
        }
        return;
      case 'ContactName':
        this.setState({ ContactName: value });
        return;
      case 'ContactEmail':
        this.setState({ ContactEmail: value });
        return;
      case 'ContactPhone':
        this.setState({ ContactPhone: value });
        return;
      case 'CommunicationMethod':
        this.setState({ SelectedCommMethod: value });
        return;
      case 'CircuitsList':
        this.setState({ SelectCircuitsFromList: value });
        if(value !== ""){
          this.setState({TicketSubject: value+'- Trouble Ticket'})
        }else{
          this.setState({TicketSubject: value})
        }
        return;
      default:
        return;
    }
  }

  handleTicketSubject = (e) => {
    let { value } = e.target;
    this.setState({ TicketSubject: value });
  }

  handleTicketSubjectInternal = (e) => {
    let { value } = e.target;
    this.setState({ TicketSubject: value });
  }

  handleTicketSubjectRFC = (e) => {
    let { value } = e.target;
    this.setState({ TicketSubject: value });
  }

  handleTicketDescription = (e) => {
    let { value } = e.target;
    this.setState({ TicketDescription: value });
  }

  renderModelButtons = () => {
    if (sessionStorage.enterpriseType === "MIXED" && this.state.SelectedMixedType === 'VOICE_DUPLICATE') {
      return (
        <div>
          <span><AffectedCircuitsModal
            RedirectedFromCircuitsSaved={this.state.redirectedFromCircuitsList}
            affectedCircuits={this.storeAffectedCircuitsRedirectedFromCircuits}
            btnStyles={helper.btnStyles}
            saveSelections={true}
          /></span>
          <span><AffectedServicesModal
            affectedServices={this.storeAffectedServices}
            btnStyles={helper.btnStyles}
            saveSelections={true} /></span>
        </div>
      )
    }
    if (sessionStorage.enterpriseType === "MIXED" && this.state.SelectedMixedType === 'DATA_SERVICES') {
      return (
        <span><AffectedCircuitsModal
          RedirectedFromCircuitsSaved={this.state.redirectedFromCircuitsList}
          affectedCircuits={this.storeAffectedCircuitsRedirectedFromCircuits}
          btnStyles={helper.btnStyles}
          saveSelections={true}
        /></span>
      )
    }
    if (sessionStorage.enterpriseType === "MIXED" && this.state.SelectedMixedType === 'RETAIL_TROUBLE') {
      return (
        <div><AffectedServicesModal
          affectedServices={this.storeAffectedServices}
          btnStyles={helper.btnStyles}
          saveSelections={true} /></div>
      )
    }
    if (sessionStorage.enterpriseType === "DATA") {
      return (
        <span><AffectedCircuitsModal
          RedirectedFromCircuitsSaved={this.state.redirectedFromCircuitsList}
          affectedCircuits={this.storeAffectedCircuitsRedirectedFromCircuits}
          btnStyles={helper.btnStyles}
          saveSelections={true}
        /></span>
      )
    }
    if (sessionStorage.enterpriseType === "VOICE") {
      return (
        <div><AffectedServicesModal
          affectedServices={this.storeAffectedServices}
          btnStyles={helper.btnStyles}
          saveSelections={true} /></div>
      )
    }
  }

  renderContent = () => {
    if (sessionStorage.enterpriseType !== "MIXED") {
      return this.newTicketContent();
    }
    else if (sessionStorage.enterpriseType === 'MIXED' && this.state.SelectedMixedType !== '') {
      return this.newTicketContent()
    }
    else {
      return null
    }

  }

  newTicketContent = () => {
    var via = ""
    let ticketCircuitSubject = "";
    const rfc = this.state.redirectedFromCircuitsList !== null ? true : false;
    if (rfc) {
      var circuitName = this.state.redirectedFromCircuitsList[0].CircuitName;
      ticketCircuitSubject = circuitName + '- Trouble Ticket';
      var locationRfc = this.state.redirectedFromCircuitsList[0].SelectedLocation;
      var circuitIdRfc = this.state.redirectedFromCircuitsList[0].CircuitId;
    }
    // if (this.state.SelectCircuitsFromList !== "") {
    //   //var ticketCircuitIdSubject = this.state.SelectCircuitsFromList + '- Trouble Ticket';
    //   ticketCircuitSubject=this.state.SelectCircuitsFromList + '- Trouble Ticket';
    // }

    if (this.state.submittedTicket) {
      return <DataLoadingSpinner className='load spinner' />
    } else {
      return (
        <div className='main-container'>
          <div className='flex-parent-wrapper'>
            <div className='flex-container-one'>
              {/* {this.state.SelectedMixedType === 'RETAIL_TROUBLE' || sessionStorage.enterpriseType === 'VOICE' || this.state.SelectedMixedType === "VOICE_DUPLICATE" ? null : <span>{this.formGroupDropDown('Category', 'Category', this.state.SelectedCategory, this.state.CategoryOptions)}</span>} */}
              {/* {(this.state.SelectedCategory === '' || this.state.SelectedCategory === 'Other' ||this.state.SelectedMixedType === 'RETAIL_TROUBLE' || sessionStorage.enterpriseType === 'VOICE'||this.state.SelectedMixedType ==="VOICE_DUPLICATE")
                ? null
                : this.formGroupDropDown(
                  'SubCategory',
                  'Sub Category',
                  this.state.SelectedSubCategory,
                  this.state.SubCategoryOptions
                )} */}
              {rfc ? this.formGroupInput('Location', 'Location', locationRfc) : this.formGroupDropDown('Location', 'Location', this.state.SelectedLocation, this.state.LocationOptions)}
              {this.state.SelectedMixedType === 'RETAIL_TROUBLE' || sessionStorage.enterpriseType === 'VOICE' || this.state.SelectedMixedType === "VOICE_DUPLICATE"
                ? null
                : <span>{rfc ? this.formGroupInput('CircuitsList', 'Circuit ID', circuitIdRfc) : this.formGroupDropDown('CircuitsList', 'Circuit ID', this.state.SelectCircuitsFromList, this.state.CircuitsOptions)}</span>}
              {this.formGroupDropDown('Contact', 'Contact', this.state.SelectedContact, this.state.ContactOptions)}
              {/* {this.state.SelectedMixedType === 'RETAIL_TROUBLE' || sessionStorage.enterpriseType === 'VOICE' || this.state.SelectedMixedType === "VOICE_DUPLICATE"
                ? null
                : <span>{rfc ? this.formGroupInput('CircuitsList', 'Circuits Id', circuitIdRfc) : this.formGroupDropDown('CircuitsList', 'Circuits Id', this.state.SelectCircuitsFromList, this.state.CircuitsOptions)}</span>} */}
              {/* {rfc ? this.formGroupInput('CircuitsList', 'Circuits Id', circuitIdRfc) : this.formGroupDropDown('CircuitsList', 'Circuits Id', this.state.SelectCircuitsFromList, this.state.CircuitsOptions)} */}
              {/* {this.formGroupDropDown('CircuitsList', 'Circuits Id', this.state.SelectCircuitsFromList, this.state.CircuitsOptions)} */}
              {this.state.ShowTextBoxesForContactDetails ? this.formGroupInput('ContactName', 'Contact Name', this.state.ContactName) : null}
              {this.state.ShowTextBoxesForContactDetails ? this.formGroupInput('ContactEmail', 'Contact Email', this.state.ContactEmail) : null}
              {this.state.ShowTextBoxesForContactDetails ? this.formGroupInput('ContactPhone', 'Contact Phone', this.state.ContactPhone) : null}
              {this.formGroupDropDown('CommunicationMethod', 'Contact Method', this.state.SelectedCommMethod, this.state.CommMethodOptions)}
            </div>
            <div className='flex-container-two'>
              {this.state.AffectedDevices.length > 0
                ? <div>
                  <small>{`Affected Devices: ${this.state.AffectedDevices.length}`}</small>
                  <br />
                </div> : null}
              {this.state.AffectedServices.length > 0
                ? <div>
                  <small>{`Affected Services & Users: ${this.state.AffectedServices.length}`}</small>
                  <br />
                </div> : null}
              {this.state.AffectedCircuits.length > 0
                ? <div>
                  <small>{`Affected Circuits & Users: ${this.state.AffectedCircuits.length}`}</small>
                  <br />
                </div> : null}
              {this.state.AttachmentList.length > 0
                ? <div>
                  <small>{`Attachments: ${this.state.AttachmentList.length}`}</small>
                  <br />
                </div> : null}
              {rfc ? <span>
                <h5
                  style={{
                    fontWeight: '550'
                  }}
                >Subject <span style={{ color: 'red', fontSize: '18px' }}>*</span>:</h5>
                <input
                  style={{
                    width: '75%',
                    marginBottom: '5px'
                  }}
                  htmlFor='ticket-subject'
                  onChange={this.handleTicketSubjectRFC}
                  value={ticketCircuitSubject}
                />
              </span>  :
                  <span>
                    <h5
                      style={{
                        fontWeight: '550'
                      }}
                    >Subject <span style={{ color: 'red', fontSize: '18px' }}>*</span>:</h5>
                    <input
                      style={{
                        width: '75%',
                        marginBottom: '5px'
                      }}
                      htmlFor='ticket-subject'
                      onChange={this.handleTicketSubject}
                      value={this.state.TicketSubject}
                    />
                  </span>
              }

              <h5
                style={{
                  fontWeight: '550'
                }}
              >Description <span style={{ color: 'red', fontSize: '18px' }}>*</span>:</h5>
              <textarea
                style={{
                  display: 'block',
                  width: '75%',
                  height: '250px',
                  resize: 'none'
                }}
                placeholder={via}
                onChange={this.handleTicketDescription}
                value={this.state.TicketDescription}
              ></textarea>
              <div className='new-ticket-btns' id="filePopup" style={{
                display: "none",
                'padding-bottom': '20px',
                'padding-left': '60px',
                'text-align': 'left',
                width: '100% !important'
              }}>
                <span class="file-popup"
                  style={{
                    width: '430px',
                    color: 'black',
                    position: 'absolute'
                  }}> Allowed Attachment Types are PDF, Image(GIF, TIF, JPEG, Etc..)
                  Recommendation:  Send .doc, .txt, .csv, etc. via email.
                </span>
              </div>
              <div className='new-ticket-btns'>
                <button className='btn' style={helper.btnStyles}
                  onClick={this.submitNewTicket}
                >Submit</button>
                {this.renderModelButtons()}
                <span>
                  <AffectedDevicesModal
                    affectedDevices={this.storeAffectedDevices}
                    btnStyles={helper.btnStyles}
                    saveSelections={true}
                  />
                </span>

                <button className='btn' style={helper.btnStyles}
                  onClick={this.openAttachments}
                >Add Attachments
                  <input
                    type="file"
                    id="file"
                    accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps"
                    ref='fileUploader'
                    style={{ display: "none" }}
                    onChange={this.addAttachment}
                    onClick={(event) => {
                      event.target.value = null;
                    }}
                  />

                </button>
                <button className='btn' style={helper.btnStyles}
                  onClick={this.cancelNewTicket}
                >Cancel</button>
              </div>
            </div>
          </div>
        </div>
      )
    }

  }

  addAttachment = (event) => {
    let file = event.target.files[0];
    let fileTypes = ['image/jpeg', 'image/gif', 'image/png', 'application/pdf', 'image/x-eps'];
    if (fileTypes.indexOf(file.type) > -1) {
      $('#filePopup').hide();
      let reader = new FileReader();
      let newFileIndex = this.state.fileIndex + 1;
      reader.addEventListener('loadend', async () => {
        let fileParams = {
          id: newFileIndex,
          body: reader.result.split(',')[1],
          name: file.name
        };
        this.state.AttachmentList.push(fileParams);
        await this.setState({ AttachmentList: this.state.AttachmentList, fileIndex: newFileIndex });
      }, false);
      if (file) {
        reader.readAsDataURL(file);
      }
    }
    else {
      $('#filePopup').show();
    }
  }

  createAttachment = async (body, name, ticketId) => {
    let apiPath = 'admin/CreateAttachment';
    let attachmentDTO = {
      TicketId: ticketId,
      AttachmentBody: body,
      FileName: name,
    };
    let response = await apiCalls.post(apiPath, 'POST', JSON.stringify(attachmentDTO)).then(res => res).catch(err => console.error(err));
    if (response.ok) {
    } else {
      let msg = JSON.parse(response.message);
      console.log('res error', msg);
    }
  }

  openAttachments = () => {
    this.refs.fileUploader.click();
  }

  storeAffectedDevices = (data) => {
    let devices = data.map(itm => {
      return `MAC: ${itm.MacAddress || ''}\nMake/Model: ${itm.MakeModel || ''}\nAssignedTo: ${itm.AssignedTo || ''}\n\n`;
    });
    this.setState({ AffectedDevices: devices });
  }

  storeAffectedCircuitsRedirectedFromCircuits = (data) => {
    const rfc = this.state.redirectedFromCircuitsList !== null ? true : false;
    console.log("new_ticket.js", this.state.redirectedFromCircuitsList);
    if (this.state.redirectedFromCircuitsList !== null) {
      var circuits = this.state.redirectedFromCircuitsList.map(itm => {
        return `CircuitId: ${itm.CircuitId || ''}\nCircuitName: ${itm.CircuitName || ''}\nSelected: ${true || ''}\n\n`;
      });
    }
    if (rfc && data != null) {
      var circuitsData = data.map(itm => {
        return `CircuitId: ${itm.CircuitId || ''}\nCircuitName: ${itm.CircuitName || ''}\nSelected: ${true || ''}\n\n`;
      })
      const affectedCircuits = [...circuits, ...circuitsData]
      this.setState({ AffectedCircuits: affectedCircuits });
    }
    if (rfc && data === undefined) {
      this.setState({ AffectedCircuits: circuits });
    }
    if (data != null && rfc == false) {
      var circuitsData = data.map(itm => {
        return `CircuitId: ${itm.CircuitId || ''}\nCircuitName: ${itm.CircuitName || ''}\nSelected: ${true || ''}\n\n`;
      })
      this.setState({ AffectedCircuits: circuitsData });
    }
    console.log(this.state.AffectedCircuits, "AffectedCircuits")
  }

  storeAffectedServices = (data) => {
    let services = data.map(itm => {
      let extension = ''
      if (itm.Extension)
        extension = "Extension: " + itm.Extension + '\n';
      else if (itm.Username && itm.ServiceNumber !== itm.Username)
        extension = "Username: " + itm.Username + '\n';
      let serviceID = itm.ServiceNumber ? "ServiceID: " + itm.ServiceNumber + '\n' : ''
      return `ServiceType: ${itm.ServiceType || itm.UserType}\n${serviceID}${extension}\n`;
    });
    this.setState({ AffectedServices: services });
  }

  submitNewTicketComment = async (ticketId) => {
    let apiPath = 'admin/CreateComment';
    let string = '';
    if (this.state.AffectedDevices.length > 0 && this.state.AffectedServices.length > 0) {
      string = `AFFECTED DEVICES COUNT: ${this.state.AffectedDevices.length}\n${this.state.AffectedDevices.join().replace(/,/g, '')} AFFECTED SERVICES & USERS COUNT: ${this.state.AffectedServices.length}\n${this.state.AffectedServices.join().replace(/,/g, '')}`;
    } else if (this.state.AffectedDevices.length > 0 && this.state.AffectedServices.length < 1) {
      string = `AFFECTED DEVICES COUNT: ${this.state.AffectedDevices.length}\n${this.state.AffectedDevices.join().replace(/,/g, '')}`;
    }
    else if (this.state.AffectedCircuits.length > 0 && this.state.AffectedDevices.length < 1) {
      string = `AFFECTED CIRCUITS COUNT: ${this.state.AffectedCircuits.length}\n${this.state.AffectedCircuits.join().replace(/,/g, '')}`;
    }
    else if (this.state.AffectedCircuits.length > 0 && this.state.AffectedDevices.length > 0) {
      string = `AFFECTED CIRCUITS COUNT: ${this.state.AffectedCircuits.length}\n${this.state.AffectedDevices.join().replace(/,/g, '')} AFFECTED DEVICES COUNT: ${this.state.AffectedDevices.length}\n${this.state.AffectedDevices.join().replace(/,/g, '')}`;
    }
    else {
      string = `AFFECTED SERVICES & USERS COUNT: ${this.state.AffectedServices.length}\n${this.state.AffectedServices.join().replace(/,/g, '')}`
    }
    let commentDTO = {
      CommentId: '',
      TicketId: ticketId,
      CommentBody: string,
    };
    if (this.state.AffectedDevices.length > 0 || this.state.AffectedServices.length > 0 || this.state.AffectedCircuits.length > 0) {
      await apiCalls.post(apiPath, 'POST', JSON.stringify(commentDTO)).then(res => res).catch(err => console.error(err));
    }
  }

  submitNewTicket = async () => {
    let {
      TicketSubject,
      TicketDescription,
      SelectedLocation,
      SelectedContact,
      SelectedPriority,
      SelectedCategory,
      // SelectedSubCategory,
      SelectedOtherCategory,
      SelectedCommMethod,
      AttachmentList,
      ContactName,
      ContactEmail,
      ContactPhone,
      SelectedType,
      LoggedUser,
      CircuitId,
      SelectCircuitsFromList
    } = this.state;
    if (this.state.TicketDescription === "") {
      this.setState({ submittedTicket: false, ticketSubFailed: true });
    }
    let apiPath = 'admin/CreateTicket';this.state.redirectedFromCircuitsList !== null ? true : false;
    const rfc = this.state.redirectedFromCircuitsList !== null ? true : false;
    if (rfc) {
      var ticketSubjectCircuits = this.state.redirectedFromCircuitsList[0].CircuitName + '- Trouble Ticket'
      var ticketDTO = {
        AccountId: accountID,
        LocationAccountNumber: this.state.redirectedFromCircuitsList[0].LocationAccountNumber,
        CircuitId: this.state.redirectedFromCircuitsList[0].CircuitId,
        Subject: ticketSubjectCircuits,
        Description: ContactName !== '' && SelectedCommMethod !== "" ?
          "Contact Name:- " + ContactName + "\n\nContactEmail:- " + ContactEmail + "\n\nContactPhone:-" + ContactPhone + "\n\nInitial Contact Method:- " + SelectedCommMethod + "\n\nSubmitted By:-" + LoggedUser + "\n\n" + TicketDescription
          : ContactName !== '' && SelectedCommMethod === "" ?
            "Contact Name:-" + ContactName + "\n\nContactEmail:-" + ContactEmail + "\n\nContactPhone:-" + ContactPhone + "\n\nSubmitted By:-" + LoggedUser + "\n\n" + TicketDescription
            : ContactName === '' && SelectedCommMethod !== "" ?
              "Initial Contact Method:- " + SelectedCommMethod + "\n\nSubmitted By:-" + LoggedUser + "\n\n" + TicketDescription :
              "Submitted By:-" + LoggedUser + "\n\n" + TicketDescription,
        Category: SelectedCategory,
        // SubCategory: SelectedSubCategory || "N/A",
        OtherCategory: SelectedOtherCategory,
        Priority: SelectedPriority,
        ContactId: SelectedContact,
        PreferredCommunication: SelectedCommMethod,
        TicketType: sessionStorage.enterpriseType == 'MIXED' ? this.state.SelectedMixedType : this.ticketType
      };
      console.log(JSON.stringify(ticketDTO));
    }
    else {
      var ticketDTO = {
        AccountId: accountID,
        LocationAccountNumber: SelectedLocation,
        Subject: TicketSubject,
        Description: ContactName !== '' && SelectedCommMethod !== "" ?
          "Contact Name:- " + ContactName + "\n\nContactEmail:- " + ContactEmail + "\n\nContactPhone:-" + ContactPhone + "\n\nInitial Contact Method:- " + SelectedCommMethod + "\n\nSubmitted By:-" + LoggedUser + "\n\n" + TicketDescription
          : ContactName !== '' && SelectedCommMethod === "" ?
            "Contact Name:-" + ContactName + "\n\nContactEmail:-" + ContactEmail + "\n\nContactPhone:-" + ContactPhone + "\n\nSubmitted By:-" + LoggedUser + "\n\n" + TicketDescription
            : ContactName === '' && SelectedCommMethod !== "" ?
              "Initial Contact Method:- " + SelectedCommMethod + "\n\nSubmitted By:-" + LoggedUser + "\n\n" + TicketDescription :
              "Submitted By:-" + LoggedUser + "\n\n" + TicketDescription,
        Category: SelectedCategory,
        // SubCategory: SelectedSubCategory || "N/A",
        OtherCategory: SelectedOtherCategory,
        Priority: SelectedPriority,
        ContactId: SelectedContact,
        PreferredCommunication: SelectedCommMethod,
        CircuitId: SelectCircuitsFromList,
        TicketType: sessionStorage.enterpriseType == 'MIXED' ? this.state.SelectedMixedType : this.ticketType
      };
      //PBI:6610 :- this condition will enact in case of SelectedMixedType as "Both", 
      // in case of "Both", the key needs to be same as of VOICE i.e. RETAIL_TROUBLE,
      // and as the keys could not be same for VOICE & BOTH so replacing them before submission
      if (ticketDTO.TicketType === "VOICE_DUPLICATE") {
        ticketDTO.TicketType = "RETAIL_TROUBLE";
      }
    }
    if (this.state.TicketDescription === "") {
      this.setState({ submittedTicket: false, ticketSubFailed: true });
    }
    else {
      if(this.state.SelectedMixedType==='DATA_SERVICES' && this.state.SelectCircuitsFromList ==="" && rfc=== false){
        this.setState({ submittedTicket: false, ticketSubFailed: true });
      }
      else{
        await this.setState({ submittedTicket: true, ticketSubFailed: false });
      let response = await apiCalls.post(apiPath, 'POST', JSON.stringify(ticketDTO)).then(res => res).catch(err => console.error(err));
      if (response.ok) {
        let newTicketID = JSON.parse(response.message).ObjectId;
        await this.submitNewTicketComment(newTicketID);
        await AttachmentList.forEach((itm) => {
          this.createAttachment(itm.body, itm.name, newTicketID);
        });
        this.props.history.push(`/ticket/${newTicketID}`);
      } else {
        this.setState({ submittedTicket: false, ticketSubFailed: true });
      }
      }
    }
  }

  cancelNewTicket = () => {
    this.setState({ showConfirmationModal: true });
  }

  handleCancelTicketYes = () => {
    this.props.history.push('/tickets_all')
  }

  handleCloseConfirmation = () => {
    this.setState({ showConfirmationModal: false });
  }

  attachmentDropdown = () => {
    return (
      <div id="dropdown" className="dropdown">
        <a className="menubtn dropdown-toggle" type="button" id="" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          View Attachments
          <span className="caret"></span>
        </a>
        <ul className="dropdown-menu" aria-labelledby="" style={{
          minWidth: '200px',
          fontSize: '12px',
          padding: '10px',
        }}>
          {this.state.AttachmentList.map((file, idx) => {
            return (
              <li id="attachment-list" key={idx}>
                <div>
                  <a
                    tabIndex="0"
                  >{file.name}</a>
                  <a style={{
                    float: 'right',
                    color: 'red',
                  }}
                    onClick={() => this.removeAttachment(file.id)}
                  >x</a>
                </div>
                <hr />
              </li>
            )
          })}
        </ul>
      </div>
    )
  }

  removeAttachment = (id) => {
    let { AttachmentList } = this.state;
    let filteredList = AttachmentList.filter(itm => itm.id !== id);
    this.setState({ AttachmentList: filteredList });
  }

  createTextBox = () => {
    const rfc = this.state.redirectedFromCircuitsList !== null ? true : false;
    if (rfc && sessionStorage.enterpriseType == 'MIXED') {
      this.ticketType = "DATA";
      return (<span>
        {this.formGroupDropDown('Type', 'Type', this.state.SelectedMixedType, this.state.TypeListOptions)}
      </span>
      )
    }
    else {
      if (sessionStorage.enterpriseType == 'DATA') {
        this.ticketType = "DATA";
        return (<span><p style={{ position: 'relative', left: '-1px', top: '8px' }}>Type</p><input style={{ position: 'relative', left: '234px', top: '-24px', width: '206px', cursor: "not-allowed" }} className='form-control' value="Data" id="type"></input></span>)
      }
      else if (sessionStorage.enterpriseType == 'MIXED') {
        this.ticketType = "DATA";
        return (<span>
          {this.formGroupDropDown('Type', 'Type', this.state.SelectedMixedType, this.state.TypeListOptions)}
        </span>
        )
      }
      else {
        this.ticketType = "VOICE";
        return (<span><p style={{ position: 'relative', left: '-1px', top: '8px' }}>Type</p><input style={{ position: 'relative', left: '234px', top: '-24px', width: '206px', cursor: "not-allowed" }} className='form-control' value="Voice" id="type"></input></span>)
      }
    }
  }

  render() {
    const rfc = this.state.redirectedFromCircuitsList !== null ? true : false;
    return (
      <div className='new-ticket-page'>
        <div className="container full-card">
          <div className="ticketing card">
            <div className="card-header">
              <h1>New Ticket <small>
                <span style={{ width: '33%' }}>
                  {this.createTextBox()}
                </span></small> {this.state.ticketSubFailed ?
                  <small style={{ color: 'red', fontSize: '50%' }}>Submission Error: Confirm All Required Fields</small>
                  : null}</h1>
            </div>
            <div className="card-actions">
              {this.state.AttachmentList.length > 0 ? this.attachmentDropdown() : null}
            </div>
            <div className="ticketing-row"></div>
            <br />
            <div className="card-content">
              <Modal show={this.state.showConfirmationModal} onHide={this.handleClose} backdrop='static'>
                <Modal.Header>
                  <Modal.Title>Are you sure you want to cancel this ticket?</Modal.Title>
                </Modal.Header>
                <Modal.Footer>
                  <hr />
                  <div className="btns">
                    <span>
                      <button type="button" className="btn" onClick={this.handleCancelTicketYes}>Yes</button>
                      <button className="btn" onClick={this.handleCloseConfirmation}>No</button>
                    </span>
                  </div>
                </Modal.Footer>
              </Modal>
              {this.renderContent()}
            </div>
          </div>
        </div>
      </div>
    )
  }
};
export default NewTicket;