import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { Button } from '@progress/kendo-buttons-react-wrapper';
import _ from 'lodash';


class CallForwardingSelectiveModal extends Component {

  constructor(props) {
    super(props);

    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleAddPhoneNumber = this.handleAddPhoneNumber.bind(this);
    this.state = { showModal: false, numberOfPhoneCount:1, numberOfPhoneLimit:10 };
  }

  handleClose() {
    this.setState({ showModal: false });
  }

  handleShow() {
    this.setState({ showModal: true });
  }
  handleAddPhoneNumber(){
    if(this.state.numberOfPhoneCount < 10)
      this.setState({ numberOfPhoneCount: this.state.numberOfPhoneCount + 1 });
  }

  componentDidMount() {   
  }

  render() {
    let phoneNumberCountReachLimit = this.state.numberOfPhoneCount >= this.state.numberOfPhoneLimit;
    return (
      <div className="setting">
        <span onClick={this.handleShow}> 
          <span className="name">Call Forwarding Selective</span>
          <a className="icon btn"><svg width="14" height="14" viewBox="0 0 24 24"><path d="m163 440l-91-91 251-250 90 90z m309-352l-48-48c-12-11-32-11-45 2l-45 45 91 91 45-45c13-13 13-33 2-45z m-408 275l-32 117 117-32z" transform="scale(0.046875 0.046875)"></path></svg></a>
        </span>

        <Modal show={this.state.showModal} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Call Forwarding Selective</Modal.Title>
          </Modal.Header>
          <Modal.Body> 
            <div>
              <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Odio ab modi necessitatibus illo, tempore corrupti!</p>
              <div className="form-group">
                <label className="switch right">
                  <input type="checkbox" />
                  <span className="slider round"></span>
                </label>
                Call Forwarding Selective
              </div>
              <div className="form-group">
                <label className="switch right">
                  <input type="checkbox" />
                  <span className="slider round"></span>
                </label>
                Play ring reminder when a call is forwarded
              </div>
              <hr />
              <div className="form-group">
                <label >Default Call Forward to number</label>
                <input type="text" className="form-control" />
              </div>
              <hr />
              <table className="table">
                <thead>
                  <tr>
                    <th className="check"><input type="checkbox" /></th>
                      <th>Description</th>
                      <th>Active</th>
                      <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="check"><input type="checkbox" /></td>
                    <td>NuisancerCaller_91234</td>
                    <td>Yes</td>
                    <td align="right"><a className="icon btn" data-toggle="collapse" data-target="#ar1"><svg width="14" height="14" viewBox="0 0 24 24"><path d="m163 440l-91-91 251-250 90 90z m309-352l-48-48c-12-11-32-11-45 2l-45 45 91 91 45-45c13-13 13-33 2-45z m-408 275l-32 117 117-32z" transform="scale(0.046875 0.046875)"></path></svg></a></td>
                  </tr>
                  <tr className="collapse" id="ar1">
                    <td colSpan="5">
                        <div className="table-edit">
                          <a href="" className="red delete">Delete Selective Criteria</a>
                            <div className="form-group">
                              <label >Description</label>
                              <input type="type" className="form-control" />
                            </div>
                            <div className="form-group">
                              <label >Forward To</label>
                              <select className="form-control">
                                <option defaultValue="">-Select-</option>
                                <option defaultValue="">123 123 123</option>
                                <option defaultValue="">123 123 124</option>
                                <option defaultValue="">123 123 125</option>
                              </select>
                            </div>
                        <hr />
                        <div className="halves">
                          <div className="half">
                                <div className="form-group">
                                  <label >Time Schedule</label>
                                  <select className="form-control">
                                    <option defaultValue="">-Select-</option>
                                    <option defaultValue="">Normal</option>
                                  </select>
                                </div>
                          </div>
                          <div className="half">
                                <div className="form-group">
                                  <label >Holiday Schedule</label>
                                  <select className="form-control">
                                    <option defaultValue="">-Select-</option>
                                    <option defaultValue="">Normal</option>
                                  </select>
                                </div>
                          </div>
                        </div>
                        <hr />
                        <div>
                          <div className="block-options">
                            <label><input type="radio" name="radio" id="one" defaultValue="multiple" /> From any number</label>
                            <label><input type="radio" name="radio" id="one" defaultValue="multiple" /> From forwarded calls</label>
                            <label><input type="radio" name="radio" id="one" defaultValue="multiple" data-toggle="collapse" data-target="#hideNums" /> From following phone numbers</label>
                            <div className="phone-nums collapse" id="hideNums">
                              <div className="">
                                <label><input type="checkbox" id="autologin" /> Any Private Number</label>
                                <label><input type="checkbox" id="autologin" /> Any Unavailable Number</label>
                              </div>
                              <div className="">
                                {_.times(this.state.numberOfPhoneCount, i =>
                                  <div key={i} className="phone-num">
                                    <input type="text" className="form-control" defaultValue="123 123 1234" />
                                  </div>
                                )}
                              </div>
                              <a style={{visibility: phoneNumberCountReachLimit ? 'hidden':'visible' }} className="new-phone" onClick={this.handleAddPhoneNumber}>Add additional phone number</a>
                            </div>
                          </div>
                        </div>
                        <hr />
                        <div className="btns">
                          <a href="" className="btn">Save</a>
                          <a href="" className="btn">Cancel</a>
                        </div>
                        </div>
                    </td>
                  </tr>
                  <tr>
                    <td className="check"><input type="checkbox" /></td>
                    <td>NuisancerCaller_91234</td>
                    <td>Yes</td>
                    <td align="right"><a className="icon btn" data-toggle="collapse" data-target="#ar2"><svg width="14" height="14" viewBox="0 0 24 24"><path d="m163 440l-91-91 251-250 90 90z m309-352l-48-48c-12-11-32-11-45 2l-45 45 91 91 45-45c13-13 13-33 2-45z m-408 275l-32 117 117-32z" transform="scale(0.046875 0.046875)"></path></svg></a></td>
                  </tr>
                  <tr className="collapse" id="ar2">
                    <td colSpan="5">
                        <div className="table-edit">
                          <a href="" className="red delete">Delete Selective Criteria</a>
                            <div className="form-group">
                              <label >Description</label>
                              <input type="type" className="form-control" />
                            </div>
                            <div className="form-group">
                              <label >Forward To</label>
                              <select className="form-control">
                                <option defaultValue="">-Select-</option>
                                <option defaultValue="">123 123 123</option>
                                <option defaultValue="">123 123 124</option>
                                <option defaultValue="">123 123 125</option>
                              </select>
                            </div>
                        <hr />
                        <div className="halves">
                          <div className="half">
                                <div className="form-group">
                                  <label >Time Schedule</label>
                                  <select className="form-control">
                                    <option defaultValue="">-Select-</option>
                                    <option defaultValue="">Normal</option>
                                  </select>
                                </div>
                          </div>
                          <div className="half">
                                <div className="form-group">
                                  <label >Holiday Schedule</label>
                                  <select className="form-control">
                                    <option defaultValue="">-Select-</option>
                                    <option defaultValue="">Normal</option>
                                  </select>
                                </div>
                          </div>
                        </div>
                        <hr />
                        <div>
                          <div className="block-options">
                            <label><input type="radio" name="radio" id="one" defaultValue="multiple" /> From any number</label>
                            <label><input type="radio" name="radio" id="one" defaultValue="multiple" /> From forwarded calls</label>
                            <label><input type="radio" name="radio" id="one" defaultValue="multiple" data-toggle="collapse" data-target="#hideNums" /> From following phone numbers</label>
                            <div className="phone-nums collapse" id="hideNums">
                              <div className="">
                                <label><input type="checkbox" id="autologin" /> Any Private Number</label>
                                <label><input type="checkbox" id="autologin" /> Any Unavailable Number</label>
                              </div>
                              <div className="">
                                {_.times(this.state.numberOfPhoneCount, i =>
                                  <div key={i} className="phone-num">
                                    <input type="text" className="form-control" defaultValue="123 123 1234" />
                                  </div>
                                )}
                              </div>
                              <a style={{visibility: phoneNumberCountReachLimit ? 'hidden':'visible' }} className="new-phone" onClick={this.handleAddPhoneNumber}>Add additional phone number</a>
                            </div>
                          </div>
                        </div>
                        <hr />
                        <div className="btns">
                          <a href="" className="btn">Save</a>
                          <a href="" className="btn">Cancel</a>
                        </div>
                        </div>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="5">
                      <div className="btns">
                        <a className="btn" data-toggle="collapse" data-target="#ar2">Add Selective Call Forwarding</a>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Modal.Body>
          <Modal.Footer>
              <hr />
              <div className="btns">
              <Button className="btn" click={this.handleClose}>Close</Button>
              </div>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
};

export default CallForwardingSelectiveModal;
