import React, { Component } from "react";
import { DataLoadingSpinner } from "../../components/spinners";
import { withRouter } from "react-router-dom";
import "@progress/kendo-theme-default/dist/all.css";
import "../../css/bootstrap.css";
import "../../css/app.css";
import "../../css/spinner.css";
import "../../css/msp_login.css";
import * as helper from "../../scripts/helper";
import * as config from "../../requests/config";
import * as session from "../../scripts/session";
import momentumLogoWhite from "../../images/momentum_logo.png";
import { connect } from "react-redux";
import validator from "validator";




class MyLogin extends Component {
  constructor(props) {
    super(props);

    this.state = {
      Logo: momentumLogoWhite,
      loginEmail: '',
      loginError: "",
      contactError: "",
      UserData: null,
      isNetworkError: false,
      isInvalidUser: false,
      isRetail: true,
    };

  }

  getCustomizations = async currentUrl => {
    const b2cResults = await helper.getB2CConfigResults(currentUrl);
    //console.log("b2c", b2cResults);
    this.loadingLogo = helper.IsWholesaleDomain(currentUrl)
      ? this.loadingLogo
      : momentumLogoWhite;
    const customAuthPolicy = this.preProcessCustomizations(b2cResults);

    await this.setState({ auth: customAuthPolicy, loading: false });
  };

  preProcessCustomizations = b2cResults => {
    let customAuthPolicy = config.auth;

    if (b2cResults) {
      let authority =
        config.authorityUrl +
        b2cResults.B2CPolicy

      customAuthPolicy = {
        clientId: b2cResults.B2CClientId,
        authority: authority,
        b2cScopes: []
      };
      let { LogoSmall, LogoLarge, VendorName, BrowserIcon } = b2cResults;
      this.loadingLogo = LogoLarge;
      helper.updateFavicon(BrowserIcon);
      if (!helper.isDigitalServicesDomain()) {
        helper.setTitle(VendorName);
      }
      //if (b2cResults && b2cResults != null && b2cResults.VendorId != -1) {
      if (b2cResults && b2cResults != null) {
        this.setState({ isRetail: false });
      }
    }
    return customAuthPolicy;
  };

  async componentDidMount() {
    //this.props.history.push("\session");
    const domain = await helper.getDomain();
    this.getCustomizations(domain);
  }

  handleSubmit = async () => {
    // SB 7/25/2021: new env variable
    const validEmail = validator.isEmail(this.state.loginEmail);
    if (validEmail === false) {
      this.setState({ loginError: "Invalid User Id", contactError: "" })
      return;
    }
    let { auth } = this.state;
    sessionStorage.setItem("CustomAuthClientId", auth.clientId);
    sessionStorage.setItem("CustomAuthUrl", auth.authority);
    const appName = process.env.REACT_APP_NAME;
    // added new var for contact error if retail only
    let contactMsg = this.state.isRetail ? " Please contact Support at techs@momentumtelecom.com or 888-538-3960." : " The login process failed. Please contact your account manager.";
    await helper.checkIsUserValid(this.state.loginEmail, appName).then(async (response) => {
      this.setState({
        isNetworkError: false,
      });
      if (response) {
        sessionStorage.setItem("LoggedUser", response.LoginName);
        await this.setState({ UserData: response, loginError: "" });
        // LOGIN USER
        await auth ? config.loginUser(response, auth) : config.loginUser(response);
      } else {
        this.setState({ loginError: "The login process failed.", contactError: contactMsg });
        return;
      }
    }).catch(async err => {
      console.log(err);
      this.setState({
        isNetworkError: Boolean(err.message),
        loginError: "Unable to reach server",
        contactError: ""
      });
    });
  }

  handleLoginEmailChange = (event) => {
    this.setState({ loginEmail: event.target.value });
  }

  formInput = () => {
    return (
      <div className="entry-item">
        <label htmlFor="logonIdentifier">Email Address</label>

        <div className="error itemLevel" aria-hidden="true" style={{ display: 'none' }}>
          <p role="alert" />
        </div>

        <input type="email" id="logonIdentifier" name="Email address"
          pattern="^[a-zA-Z0-9.!#$%&’'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$"
          placeholder="Email Address"
          tabIndex={1}
          value={this.state.loginEmail}
          onChange={this.handleLoginEmailChange}
        />
      </div>
    )
  }

  loginForm = () => {
    return (
      <div className='msp-login'>
        <div className="login">
          <img id="msp_logo" className="App-logo" src={this.loadingLogo} />
          <div className="form-holder">
            <div id="api" data-name="Unified">
              <div className="localAccount" aria-label="Sign in with your existing account" role="form">
                <div className="intro">
                  <h2>Sign in with your existing account</h2>
                </div>
                <div className="error pageLevel"  >
                  <p role="alert">{this.state.loginError}</p>
                  <div><p role="alert" className="errorMessage">{this.state.contactError}</p></div>
                </div>
                <div className="entry">
                  {this.formInput()}
                  <div className="working" />
                  <div className="buttons">
                    <button
                      className="my-login-btn"
                      id="next"
                      type="submit"
                      tabIndex={2}
                      onClick={this.handleSubmit}
                    >Sign in</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  loginContainer = () => {
    if (sessionStorage.loginError || this.props.loginError) {
      return (
        <div className="login-error container">
          <div className="summary">
            <div className="error">
              <h3>{this.props.loginError}</h3>
              <div>{this.props.loginErrorDescription}</div>
            </div>
            <a
              href="/session"
              className="btn btn-block btn-primary"
              onClick={session.logout}
              alt="restart login button"
            >
              Restart Login
            </a>
          </div>
        </div>
      );
    } else if (sessionStorage.redirecting || this.props.redirecting) {
      // REDIRECTING
      session.transitionTimeout();
      return (
        <div className="redirect container">
          <DataLoadingSpinner className="redirect spinner" />
        </div>
      );
    } else if (helper.atLoginPath()) {
      // LOGIN USER
      return this.loginForm();
    }
    else {
      helper.redirectToLogin();
      return;
    }
  }

  render() {
    return this.loginContainer();
  }
}

const mapStateToProps = state => {
  return {
    redirecting: helper.toBoolean(state.reducer.redirecting),
    restartSession: helper.toBoolean(state.reducer.restartSession),
    loginError: state.reducer.loginError,
    loginErrorDescription: state.reducer.loginErrorDescription,
  };
};

export default withRouter(connect(mapStateToProps)(MyLogin));