import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import VoiceRoutes from "./tabs/voice_route";
import VoicePolicy from "./tabs/voice_policy";

export default class MsTeamsTenantInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            Enterprise: this.props.Enterprise,
            Trunk: this.props.Trunk,
            gridHeight: window.innerHeight - 180,
            VoiceRoutesList: this.props.VoiceRoutesList,
            VoicePoliciesList: this.props.VoicePoliciesList,
            tab: VOICETABS.VoiceRoutes,
            tabHasChanges: false
        };
        this.changeTab = this.changeTab.bind(this);
        this.getTab = this.getTab.bind(this);
        this.getTab = this.getTab.bind(this);
        this.handleTabEdit = this.handleTabEdit.bind(this);
    }


    handleTabEdit = (val) => {
        this.setState({ tabHasChanges: val });
    }

    changeTab(event) {
        if (event.target.dataset.act === 'voicepolicy') {

            this.setState({ tab: VOICETABS.VoicePolicy, tabHasChanges: true })
        }
        else {
            this.setState({ tab: VOICETABS.VoiceRoutes, tabHasChanges: true })
        }
    }

    getTabDisplay() {
        let voiceRoutes =
            (
                <a
                    className={
                        this.state.tab === VOICETABS.VoiceRoutes
                            ? "active tab"
                            : "tab"
                    }
                    data-act={VOICETABS.VoiceRoutes}
                    onClick={this.changeTab}
                >
                    Voice Routes
                </a>
            )
        let voicePolicy =
            (
                <a
                    className={
                        this.state.tab === VOICETABS.VoicePolicy
                            ? "active tab"
                            : "tab"
                    }
                    data-act={VOICETABS.VoicePolicy}
                    onClick={this.changeTab}
                >
                    Voice Policy
                </a>
            )

        return (
            <div className="tabs">
                {voiceRoutes}
                {voicePolicy}
            </div>
        );
    }

    getTab() {
        switch (this.state.tab) {
            case VOICETABS.VoiceRoutes:
                return (
                    <VoiceRoutes
                        Enterprise={this.props.Enterprise}
                        Trunk={this.props.Trunk}
                        VoiceRoutesList={this.props.VoiceRoutesList}
                        TabHasChanges={this.handleTabEdit}
                    />
                );
            case VOICETABS.VoicePolicy:
                return (
                    <VoicePolicy
                        VoicePoliciesList={this.props.VoicePoliciesList}
                        TabHasChanges={this.handleTabEdit}
                    />
                );
            default:
                console.log('Sorry there isn\'t a tab named ' + this.state.tab + '.');
        }
    }

    render() {
        return (
            <div>
                <div>
                    <div className="macd card">
                        {this.getTabDisplay()}
                        <div className="tab-body">{this.getTab()}</div>
                    </div>
                </div>
                <Modal
                    dialogClassName="confirm-cancel-order-macd"
                    show={this.state.isCancelConfirmOpen}
                    onHide={this.closeCancelConfirmationDialog}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Cancel Existing Order?</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div>
                            <p>
                                <span style={{ fontSize: "medium" }}>
                                    Continuing this action will cancel existing changes you have
                                    made for a new MACD Order. Are you sure you wish to continue
                                    and lose all changes?
                                </span>
                            </p>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}

export const VOICEROUTETYPES = {
    VoiceRoutes: "VOICE_ROUTES",
    VoicePolicy: "VOICE_POLICY"
}



export const VOICETABS = {
    VoiceRoutes: "voiceroutes",
    VoicePolicy: "voicepolicy"
}