import * as Msal from "msal";
import * as session from "./../scripts/session";
import * as settings from "./../scripts/settings";
import * as actions from "./../store/actions";
import { store } from "../store/store";
import { fetchData, customFetch } from "./../requests/api_calls";
import * as moment from "moment";
import * as helper from '../scripts/helper';
import jwtDecode from "jwt-decode";

const currentTime = new Date().getTime();

export const IMAGE_URL_PREFIX = 'data:image/png;base64,';

export const baseUrl = process.env.REACT_APP_MSA_URL;

export const baseApiUrl = process.env.REACT_APP_MSA_API_URL;

export const baseMSPUrl = process.env.REACT_APP_MSP_URL;

export const defaultXpolicy = process.env.REACT_APP_MSA_XPOLICY;

export const defaultXPolicyConfig = process.env.REACT_APP_MSA_XPOLICY_CONFIG;

export const clientId = process.env.REACT_APP_MSA_CLIENT_ID;

export const authorityUrl = process.env.REACT_APP_MSA_AUTHORITY_URL;

export const authority = authorityUrl + defaultXpolicy;

export const version = process.env.REACT_APP_VERSION;

const b2c_scope_read = process.env.REACT_APP_MSA_B2C_SCOPES_URL + "read";
const b2c_scope_write = process.env.REACT_APP_MSA_B2C_SCOPES_URL + "write";
export const b2cScopes = [b2c_scope_read, b2c_scope_write];

export const auth = {
  clientId: clientId,
  authority: authority,
  b2cScopes: b2cScopes
};

export const storedAccessToken = () => {
  return sessionStorage.accessToken;
};

export const storedUserEmail = () => {
  return sessionStorage.userEmail;
};

export const storedAccountNumber = () => {
  return sessionStorage.AccountNumber;
};

export const storedXPolicy = () => {
  return sessionStorage.xpolicy;
};

export const serviceId = () => {
  if (sessionStorage.ServiceId) return parseInt(sessionStorage.ServiceId);
  return -1;
};

export const headers = () => {
  return {
    Authorization: "Bearer " + storedAccessToken(),
    'X-Policy': storedXPolicy()
  };
};

export const expirationRemainingDelta = () => {
  var expiration = moment.unix(sessionStorage.expiration);
  let currentMil = moment();
  let duration = moment.duration(expiration.diff(currentMil));
  let finalDuration = duration.asMilliseconds() - (60 * 1000 * 3);
  return finalDuration; //3 minutes before expiration run tokenUpdater
};

export const loginRPXUser = async encParam => {
  let auth =
    "Basic " +
    btoa("Emulated User" + ":" + process.env.REACT_APP_PRPX_BASIC_PWD);
  let url = baseApiUrl + "auth/AzureToken";
  let azureTokenResult = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: auth
    },
    body: JSON.stringify(encParam)
  });
  if (!azureTokenResult.ok) return Promise.reject("token not valid");

  let rpxData = await azureTokenResult.json();
  return storeRPXToken(rpxData);
};

export const storeRPXToken = async rpxData => {
  //sessionStorage.clear();
  //don't wanna clear all sessionStorage just everything not sending to FS
  sessionStorage.removeItem('accessToken');
  sessionStorage.removeItem('refreshToken');
  sessionStorage.removeItem('xpolicy');
  sessionStorage.removeItem('userGivenName');
  sessionStorage.removeItem('redirecting');
  sessionStorage.removeItem('tokenIsValid');
  sessionStorage.removeItem('expiration');
  sessionStorage.removeItem("userFullName");
  sessionStorage.removeItem("username");
  sessionStorage.removeItem("userEnterpriseNumber");
  sessionStorage.removeItem("userRole");
  sessionStorage.removeItem("userType");
  sessionStorage.removeItem("userTypeId");
  sessionStorage.removeItem("IsWholesale");
  sessionStorage.removeItem("userHasServices");
  sessionStorage.removeItem("userReadPermission");
  sessionStorage.removeItem("userWritePermission");
  sessionStorage.removeItem("userTimeZone");
  sessionStorage.removeItem("beta_access_denied");

  let accessToken = rpxData.access_token;
  let refreshToken = rpxData.refresh_token;

  let decodedToken = jwtDecode(accessToken);
  sessionStorage.setItem("accessToken", accessToken);
  sessionStorage.setItem("refreshToken", refreshToken);
  sessionStorage.setItem("xpolicy", decodedToken.tfp);
  sessionStorage.setItem("userGivenName", decodedToken.given_name);
  sessionStorage.setItem("redirecting", false);
  sessionStorage.setItem("tokenIsValid", true);
  sessionStorage.setItem("expiration", decodedToken.exp);

  await refreshTokenIfExpired(decodedToken.exp);

  let data = await fetchData("usr/PrincipalUser");
  if (data === null) return Promise.reject("principal user not found");

  helper.storeVendorId(data.Reseller.CompanyInfo.SalesGroupId)
  setUserPropsToSessionStorage(data);
  sessionStorage.setItem("loggedIn", true);

  if (rpxData.page === "MSP_GROUP") {
    if (sessionStorage.IPTrunkEmulation) {
      //Go to IP Trunking Page
      sessionStorage.setItem('hasIpTrunk', true);
      window.location.replace('/ip_trunking');
    } else {
      window.location.replace("/iframe_page/MSP_GROUP/" + rpxData.LocationID);
    }
  }
  else if (rpxData.page === "MSP_SUBSCRIBER") {
    let service = await fetchData(
      "usr/MyServices?DirectoryNumber=" + rpxData.tn
    );
    if (service) {
      settings.setService(service[0]);
    }
    if (sessionStorage.IPTrunkEmulation) {
      //Go to User Dashboard
      sessionStorage.setItem('hasIpTrunk', true);
      window.location.replace('/dashboard');
    } else {
      window.location.replace(
        "/iframe_page/MSP_SUBSCRIBER/" + rpxData.LocationID + "/" + rpxData.tn
      );
    }
    sessionStorage.removeItem('IPTrunkEmulation');
  }
  return Promise.resolve("");
};

export const loginUser = (data = {}, customPolicy = null) => {
  sessionStorage.setItem("redirecting", true);
  try {
    if (customPolicy) {
      clientApp.authority = customPolicy.authority;
      clientApp.clientId = customPolicy.clientId;
    }

    // SB: 7/25/2021: added IdPAppClientId
    let DomainHint = '';
    if (data.IsExternalUser === true) {
      DomainHint = data.EnterpriseId + '-' + data.IdPApp.IdPAppClientId;
    }

    const queryString = {
      // SB: set domain_hint only for ext. users
      // SB: 7/22/2021 - domain_hint is now in format EnterpriseId-IdPAppClientId
      domain_hint: DomainHint,
      ProviderId: data.ProviderId,
      EnterpriseId: data.EnterpriseId
    };

    clientApp.loginRedirect({
      scopes: b2cScopes,
      loginHint: data.LoginName,
      extraQueryParameters: queryString,
    });
  }
  catch (error) {
    console.log('loginUser Error: ' + error);
    settings.saveLoginError(error);
  }
};

// (1) Create MSAL Instance
// const msalConfig = {
//   auth: {
//     clientId: clientId,
//     authority: authority,
//     validateAuthority: false,
//   }
// }
const msalConfig = {
  auth: {
    clientId: clientId, // This is the ONLY mandatory field that you need to supply.
    authority: authority, // Use a sign-up/sign-in user-flow as a default authority
    validateAuthority: false,
    redirectUri: window.location.origin, // Points to window.location.origin. You must register this URI on Azure Portal/App Registration.
    // postLogoutRedirectUri: window.location.origin, // Indicates the page to navigate after logout.
    // navigateToLoginRequestUrl: false, // If "true", will navigate back to the original request location before processing the auth code response.
  },
  cache: {
    cacheLocation: "sessionStorage", // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
    storeAuthStateInCookie: true, // Set this to "true" if you are having issues on IE11 or Edge
  }
};
export const clientApp = new Msal.UserAgentApplication(msalConfig, { loadFrameTimeout: 30000 });
// (2) Acquire Access Token using Login Redirect
clientApp.handleRedirectCallback(async () => {
  clientApp.authority = sessionStorage.CustomAuthUrl;
  clientApp.clientId = sessionStorage.CustomAuthClientId;
  clientApp.config.auth.authority = sessionStorage.CustomAuthUrl;
  clientApp.config.auth.clientId = sessionStorage.CustomAuthClientId;
  // handle redirect response or error
  // if the user is already logged in you can acquire a token
  if (clientApp.getAccount()) {
    await clientApp.acquireTokenSilent({
      scopes: b2cScopes,
      loginHint: sessionStorage.loggedUser,
    }).then(async (response) => {
      sessionStorage.setItem("tokenResponse", JSON.stringify(response));
      await storeToken(response.accessToken);
      await saveUserProperties();
    }).catch(acquireTokenError => {
      try {
        clientApp.acquireTokenRedirect({
          scopes: b2cScopes,
          loginHint: sessionStorage.loggedUser,
        });
      } catch (error) {
        console.log(`A loging error occurred on the second attempt to acquire token: --> ${error}`);
        settings.saveLoginError(acquireTokenError);
      }
    });
  } else {
    // user is not logged in, you will need to log them in to acquire a token
    console.log("USER IS NOT LOGGED IN");
  }
});

// export const clientApp = new Msal.UserAgentApplication({
//   auth: {
//     clientId: auth.clientId,
//     authority: auth.authority,
//     validateAuthority: false,
//   }
// });

// clientApp.handleRedirectCallback(async (error, response) => {
//   sessionStorage.setItem('isloadingAccessToken', false);
//   if (error) {
//     let { errorMessage } = error;
//     settings.saveLoginError(errorMessage);
//   } else {
//     if (clientApp.getAccount()) {
//       var tokenRequest = {
//         scopes: b2cScopes
//       };
//       await clientApp.acquireTokenSilent(tokenRequest)
//         .then(async response => {
//           await storeToken(response.accessToken);
//           await saveUserProperties();
//         })
//         .catch(err => {
//           session.logout()
//         });
//     }
//     else {
//       // user is not logged in, you will need to log them in to acquire a token
//       session.logout()
//     }
//   }
// });

//used to retrieve existing session thru async redux
export const tokenSaveUserProperties = async (accessToken, rpxEmulation = false) => {
  try {
    //console.log("accessToken", accessToken);
    //using a custom fetch call because for whatever reason accessToken refuses to be translated from local to session prior to this call being made
    //custom fetch allows me to set a custom set of headers and the custom url
    let customHeader = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + accessToken,
      'X-Policy': storedXPolicy()
    };
    let apiPath = baseApiUrl + '/usr/PrincipalUser';
    await customFetch(apiPath, customHeader, true).then(data => {
      if (data) {
        //need to read data to see if current environment is beta & if user is allowed to beta
        let env = process.env.REACT_APP_ENVIRONMENT;
        if (env === "staging" && (!data.BetaAllowed || data.BetaAllowed === false) && (!data.IsWholesSale || data.IsWholeSale === false)) {
          sessionStorage.setItem("beta_access_denied", true);
          store.dispatch({ type: actions.REDIRECT_BETA_ACCESS });
        } else {
          // set redux store to loggedIn:true, redirecting:false
          storeToken(accessToken, rpxEmulation);
          setUserPropsToSessionStorage(data);
          store.dispatch({ type: actions.LOGIN_USER });
          store.dispatch({ type: actions.RECEIVE_TOKEN, accessToken: accessToken });
        }
      } else {
        session.logout();
        //store.dispatch({type: actions.START_LOGIN});
      }
    }).catch((exc) => {
      console.log('error tokenSaveUserProperties: ' + exc);
      session.logout();
      //store.dispatch({type: actions.START_LOGIN});
    });
  }
  catch (ex) {
    console.log('error2 tokenSaveUserProperties: ' + ex);
  }
};

export const storeToken = async (accessToken, rpxEmulation = false) => {

  let decodedToken = jwtDecode(accessToken);

  store.dispatch({ type: actions.RECEIVE_TOKEN, accessToken: accessToken });
  sessionStorage.setItem("accessToken", accessToken);
  sessionStorage.setItem("refreshToken", "");
  sessionStorage.setItem("xpolicy", decodedToken.tfp);
  sessionStorage.setItem("userGivenName", decodedToken.given_name);
  sessionStorage.setItem("expiration", decodedToken.exp);
  sessionStorage.setItem("loggedIn", true);
  sessionStorage.setItem("RPXLogin", rpxEmulation);
  // if (!rpxEmulation)
  //   sessionStorage.removeItem('emulatedUser');

  sessionStorage.setItem("redirecting", false);
  sessionStorage.setItem("tokenIsValid", true);
  sessionStorage.setItem("sessionStart", currentTime);

};

const saveUserProperties = async () => {
  try {
    fetchData("usr/PrincipalUser").then(data => {
      if (data) {
        //need to read data to see if current environment is beta & if user is allowed to beta
        let env = process.env.REACT_APP_ENVIRONMENT;
        if (env === "staging" && (!data.BetaAllowed || data.BetaAllowed === false) && (!data.IsWholesSale || data.IsWholeSale === false)) { //&& data.BetaAllowed === false && data.IsWholeSale === false) {
          sessionStorage.setItem("beta_access_denied", true);
          store.dispatch({ type: actions.REDIRECT_BETA_ACCESS });
        } else {
          // set redux store to loggedIn:true, redirecting:false
          setUserPropsToSessionStorage(data);
          store.dispatch({ type: actions.LOGIN_USER });
        }
      } else {
        //error so nothing is returned from PrincipalUser.  Need to logout because user experience will be blank
        session.logout();
      }
    }).catch((exc) => {
      console.log(exc);
      session.logout();
    });
  } catch (ex) {
    session.logout();
  }
};

export function encryptKey(value) { // encrypting specific user identity property to avoid bypass by manipulating session storage
  // Generate a random key
  const key = new Uint8Array(16);
  window.crypto.getRandomValues(key);
  // Encrypt the value using the Substitution Cipher algorithm
  let encryptedValue = "";
  for (let i = 0; i < value.length; i++) {
    const charCode = value.charCodeAt(i) ^ key[i % key.length];
    encryptedValue += String.fromCharCode(charCode);
  }
  // Convert the key to a regular array before stringifying
  let output = [encryptedValue, Array.from(key)];
  return JSON.stringify(output);
}

export function decryptKey(arr) {
  try {
    arr = JSON.parse(arr);
    // Convert the key back to a Uint8Array after parsing
    let encryptedValue = arr[0];
    let key = new Uint8Array(arr[1]);
    let decryptedValue = "";
    for (let i = 0; i < encryptedValue.length; i++) {
      const charCode = encryptedValue.charCodeAt(i) ^ key[i % key.length];
      decryptedValue += String.fromCharCode(charCode);
    }
    if (decryptedValue == sessionStorage.userId) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
}

const setUserPropsToSessionStorage = data => {
  sessionStorage.setItem("MSTeamsRoutingType", data.MSTeamsRoutingType); // (10/127/2022) * added for MS Teams configuration
  sessionStorage.setItem("VoipSwitch", data.VoipSwitch);
  sessionStorage.setItem("userFullName", data.Name);
  sessionStorage.setItem("userEmail", data.Email);
  sessionStorage.setItem("userId", data.UserId);
  sessionStorage.setItem("enterpriseId", data.EnterpriseId);
  sessionStorage.setItem("enterpriseType", data.EnterpriseType); // (9/18/2020) * added for AD Sync User List retrieval
  sessionStorage.setItem("username", data.Username);
  sessionStorage.setItem("userEnterpriseNumber", data.EnterpriseNumber);
  sessionStorage.setItem("userRole", data.Role);
  sessionStorage.setItem("userType", data.UserType);
  sessionStorage.setItem("userTypeId", data.UserTypeId);
  sessionStorage.setItem("IsWholesale", data.IsWholesale);
  sessionStorage.setItem("userHasServices", data.HasServices);
  sessionStorage.setItem("userReadPermission", data.ReadPermission);
  sessionStorage.setItem("userWritePermission", data.WritePermission);
  sessionStorage.setItem("userTimeZone", data.TZAbbr);
  sessionStorage.setItem("beta_access_denied", false);
  sessionStorage.setItem("salesGroupId", data.Reseller.CompanyInfo.SalesGroupId);
  sessionStorage.setItem("serviceProviderName", data.Reseller.CompanyInfo.ServiceProviderName);
  sessionStorage.setItem("enableMFA", data.EnableMFA);
  sessionStorage.setItem("verificationMethod", data.VerificationMethod); // TSV
  sessionStorage.setItem("MFAOption", data.MFAOption); // TSV
  sessionStorage.setItem("IsOktaPushEnabled", data.IsOktaPushEnabled);
  sessionStorage.setItem("MfaAllowAuthApp", data.MfaAllowAuthApp);
  sessionStorage.setItem("MfaAllowSms", data.MfaAllowSms);
  sessionStorage.setItem("AccountNumber", data.AccountNumber);
};

const refreshTokenIfExpired = async expInSecs => {
  let currentTime = Date.now();
  let expInMillisecs = parseInt(expInSecs) * 1000;
  if (expInMillisecs < currentTime) session.rpxTokenUpdater();
};

export const retrieveCustomConfigs = async (HostName) => {
  return await fetchData("usr/WLGetUIConfig?HostName=" + HostName, true, false);
};