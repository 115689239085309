import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { Button } from '@progress/kendo-buttons-react-wrapper';

// <ExampleModal />

class HotelingHostModal extends Component {

	constructor(props) {
		super(props);

    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);

    this.state = { showModal: false };
  }

  handleClose() {
    this.setState({ showModal: false });
  }

  handleShow() {
    this.setState({ showModal: true });
  }

	componentDidMount() {		
	}

  render() {
    return (
      <div className="setting">
        <span onClick={this.handleShow}> 
          <span className="name">Hoteling Host</span>
          <a className="icon btn"><svg width="14" height="14" viewBox="0 0 24 24"><path d="m163 440l-91-91 251-250 90 90z m309-352l-48-48c-12-11-32-11-45 2l-45 45 91 91 45-45c13-13 13-33 2-45z m-408 275l-32 117 117-32z" transform="scale(0.046875 0.046875)"></path></svg></a>
        </span>

        <Modal show={this.state.showModal} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Hoteling Host</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <p>Hoteling Host allows a user to be designated as a host user. A user, who is assigned the hoteling guest service, can then be associated to the host user. When associated, the host user allows the guest user to use the host's device with the guest's service profile. If association limit is not enforced, the Guest user is allowed to associate with the Host user indefinitely.</p>
              <hr />
              <p>Associated Guest:<br />
              Name: Jared Malan<br />
              123 123 1234 #1111<br />
              Associated on 17 Dec 2017</p>
              <hr />
              <div className="options">
                <div className="form-group">
                  <label className="switch right">
                    <input type="checkbox" />
                    <span className="slider round"></span>
                  </label>
                  Hoteling Host
                </div>
                <div className="form-group">
                  <label className="switch right">
                    <input type="checkbox" />
                    <span className="slider round"></span>
                  </label>
                  Enforce Association Limit 24 Hours
                </div>
                <div className="form-group">
                  <label className="access-level">Access Level:</label>
                  <div className="form-control">
                    <label htmlFor="one"><input type="radio" name="radio" id="one" defaultValue="" /> Enterprise</label>
                    <label htmlFor="two"><input type="radio" name="radio" id="two" defaultValue="" /> Group</label>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
              <hr />
              <div className="btns">
              <Button className="btn" click={this.handleClose}>Close</Button>
              </div>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
};

export default HotelingHostModal;
