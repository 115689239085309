import React, { Component } from 'react';
import { homePageUrl } from './../scripts/helper';

class UnMatchedPath extends Component {

  componentWillMount() {
    this.props.history.push(homePageUrl());
  }

  render() {
    return null
  }

}

export default UnMatchedPath;