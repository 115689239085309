import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { Button } from '@progress/kendo-buttons-react-wrapper';
import { MultiSelect } from '@progress/kendo-dropdowns-react-wrapper';


class LocationCallPickupModal extends Component {

  constructor(props) {
    super(props);

    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.state = { showModal:false };
  }

  handleClose() {
    this.setState({ showModal: false });
  }

  handleShow() {
    this.setState({ showModal: true });
  }

  componentDidMount() {   
  }
  render() {
    
    return (
      <div className="setting">
        <span onClick={this.handleShow}> 
          <span className="name">Call Pickup</span>
          <a className="icon btn"><svg width="14" height="14" viewBox="0 0 24 24"><path d="m163 440l-91-91 251-250 90 90z m309-352l-48-48c-12-11-32-11-45 2l-45 45 91 91 45-45c13-13 13-33 2-45z m-408 275l-32 117 117-32z" transform="scale(0.046875 0.046875)"></path></svg></a>
        </span>
        <Modal show={this.state.showModal} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Call Pickup Settings</Modal.Title>
          </Modal.Header>
          <Modal.Body>

            <table className="table">
              <thead>
                <tr>
                  <th className="check"><input type="checkbox" /></th>
                  <th>Group Name</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="check"><input type="checkbox" /></td>
                  <td>Meet-Me</td>
                  <td align="right">
                    <a className="icon btn"  data-toggle="collapse" data-target="#ar1"><svg width="14" height="14" viewBox="0 0 24 24"><path d="m163 440l-91-91 251-250 90 90z m309-352l-48-48c-12-11-32-11-45 2l-45 45 91 91 45-45c13-13 13-33 2-45z m-408 275l-32 117 117-32z" transform="scale(0.046875 0.046875)"></path></svg></a>
                  </td>
                </tr>
                <tr className="collapse" id="ar1">
                  <td colSpan="3">
                    <div className="table-edit">
                      <p>Answer any ringing line within your call pickup group from your telephone.</p>
                      <div className="form-group">
                        <label>Group Name</label>
                        <input type="type" className="form-control" defaultValue="" />
                      </div>
                      <div className="multiselect-group form-group">
                        <label>Selected Users in Order</label>
                        <MultiSelect autoClose={false} dataSource={["User 1", "User 2", "User 3", "User 4"]}/>
                      </div>
                      <hr />
                      <div className="btns">
                        <a href="" className="btn">Save</a>
                        <a href="" className="btn">Cancel</a>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className="check"><input type="checkbox" /></td>
                  <td>Meet-Me</td>
                  <td align="right">
                    <a className="icon btn"  data-toggle="collapse" data-target="#ar2"><svg width="14" height="14" viewBox="0 0 24 24"><path d="m163 440l-91-91 251-250 90 90z m309-352l-48-48c-12-11-32-11-45 2l-45 45 91 91 45-45c13-13 13-33 2-45z m-408 275l-32 117 117-32z" transform="scale(0.046875 0.046875)"></path></svg></a>
                  </td>
                </tr>
                <tr className="collapse" id="ar2">
                  <td colSpan="3">
                    <div className="table-edit">
                      <p>Answer any ringing line within your call pickup group from your telephone.</p>
                      <div className="form-group">
                        <label>Group Name</label>
                        <input type="type" className="form-control" defaultValue="" />
                      </div>
                      <div className="multiselect-group form-group">
                        <label>Selected Users in Order</label>
                        <MultiSelect autoClose={false} dataSource={["User 1", "User 2", "User 3", "User 4"]}/>
                      </div>
                      <hr />
                      <div className="btns">
                        <a href="" className="btn">Save</a>
                        <a href="" className="btn">Cancel</a>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td colSpan="3">
                    <div className="btns">
                      <a className="btn"  data-toggle="collapse" data-target="#ar2">Add Group</a>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </Modal.Body>
          <Modal.Footer>
              <hr />
              <div className="btns">
                <Button className="btn">Save</Button>
                <Button className="btn" click={this.handleClose}>Cancel</Button>
              </div>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }

};

export default LocationCallPickupModal;
