import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { Button } from '@progress/kendo-buttons-react-wrapper';
import { MultiSelect } from '@progress/kendo-dropdowns-react-wrapper';


class CallDispositionCodeModal extends Component {

	constructor(props) {
		super(props);

    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);

    this.state = { showModal: false };
  }

  handleClose() {
    this.setState({ showModal: false });
  }

  handleShow() {
    this.setState({ showModal: true });
  }

	componentDidMount() {		
	}

  render() {
    return (
      <div className="setting">
        <span onClick={this.handleShow}> 
          <span className="name">Call Disposition Code</span>
          <a className="icon btn"><svg width="14" height="14" viewBox="0 0 24 24"><path d="m163 440l-91-91 251-250 90 90z m309-352l-48-48c-12-11-32-11-45 2l-45 45 91 91 45-45c13-13 13-33 2-45z m-408 275l-32 117 117-32z" transform="scale(0.046875 0.046875)"></path></svg></a>
        </span>
        <Modal show={this.state.showModal} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Call Disposition Code</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <p>Add or Modify Call Disposition Codes. Disposition Codes are attributes applied to a call to identify marketing promotions or other topics pertaining to a call. Add or modify call disposition codes are attributes applied to a call to identify marketing promotions or other topics pertaining to a call.</p>
              
              <div className="form-group">
                <label className="right switch">
                  <input type="checkbox" />
                  <span className="slider round"></span>
                </label>
                Enable call disposition codes
              </div>
              
              <div className="form-group">
                <label className="right switch">
                  <input type="checkbox" />
                  <span className="slider round"></span>
                </label>
                Use group codes in addition to Contact Center codes
              </div>
              <div className="form-group">
                <label className="right switch">
                  <input type="checkbox" data-toggle="collapse" data-target="#hide1" />
                  <span className="slider round"></span>
                </label>
                Force use of call disposition codes with default codes
                <div className="hidden-group collapse" id="hide1">
                  <label >Call disposition codes</label>
                  <select className="form-control">
                    <option>None</option>
                    <option>1-2 Enterprise</option>
                  </select>
                </div>
              </div>
              <table className="table">
                <thead>
                  <tr>
                    <th className="check"><input type="checkbox" /></th>
                    <th>Description</th>
                    <th>Code</th>
                    <th>Level</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td><input type="checkbox" /></td>
                    <td>1249192</td>
                    <td>1</td>
                    <td>Enterprise</td>
                    <td align="right"><a className="icon btn"  data-toggle="collapse" data-target="#ar1" ><svg width="14" height="14" viewBox="0 0 24 24"><path d="m163 440l-91-91 251-250 90 90z m309-352l-48-48c-12-11-32-11-45 2l-45 45 91 91 45-45c13-13 13-33 2-45z m-408 275l-32 117 117-32z" transform="scale(0.046875 0.046875)"></path></svg></a></td>
                  </tr>
                  <tr className="collapse" id="ar1">
                    <td colSpan="6">
                      {this.getConfig()}
                    </td>
                  </tr>
                  <tr>
                    <td><input type="checkbox" /></td>
                    <td>1249192</td>
                    <td>1</td>
                    <td>Enterprise</td>
                    <td align="right"><a className="icon btn"  data-toggle="collapse" data-target="#ar2" ><svg width="14" height="14" viewBox="0 0 24 24"><path d="m163 440l-91-91 251-250 90 90z m309-352l-48-48c-12-11-32-11-45 2l-45 45 91 91 45-45c13-13 13-33 2-45z m-408 275l-32 117 117-32z" transform="scale(0.046875 0.046875)"></path></svg></a></td>
                  </tr>
                  <tr className="collapse" id="ar2">
                    <td colSpan="6">
                      {this.getConfig()}
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="6">
                      <div className="btns">
                        <a className="btn" data-toggle="collapse" data-target="#ar2">Add Code</a>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
              
            </div>
          </Modal.Body>
          <Modal.Footer>
              <hr />
              <div className="btns">
              <Button className="btn" click={this.handleClose}>Close</Button>
              </div>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }

  getConfig() {
    return (
      <div className="table-edit">
        <div className="form-group">
          <label className="right switch">
            <input type="checkbox" />
            <span className="slider round"></span>
          </label>
          Active
        </div>
        <div className="form-group">
          <label>Code</label>
          <input type="type" className="form-control" defaultValue="" />
        </div>
        <div className="form-group">
          <label>Description</label>
          <input type="type" className="form-control" defaultValue="" />
        </div>
        <hr />
        <div className="btns">
          <a href="" className="btn">Save</a>
          <a href="" className="btn">Cancel</a>
        </div>
      </div>
    )
  }
};

export default CallDispositionCodeModal;
