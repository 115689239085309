import React from "react";
import { Button } from "@progress/kendo-buttons-react-wrapper";
import { DataLoadingSpinner } from "../../../../components/spinners";
import { fetchData, post } from "../../../../requests/api_calls";
import { useState } from "react";
import { useEffect } from "react";

export const CallDispositionCodeSectionItem = ({ code, onUpdate, onItemChange }) => {
  const [item, setItem] = useState({ ...code, selectToDelete: false });
  const [showItemDetail, setShowItemDetail] = useState(false);
  const [loading, setLoading] = useState(false);

  const { IsActive, selectToDelete } = item;

  useEffect(() => {
    onItemChange(item);
  }, [IsActive, selectToDelete]);

  if (loading) {
    return (
      <tr>
        <td colSpan={4}>
          <div className="content">
            <DataLoadingSpinner
              className="spinner"
              style={{ margin: "10px auto 0", height: "40px" }}
            />
          </div>
        </td>
      </tr>
    );
  }

  return (
    <>
      <tr>
        <td className="check">
          <label className="">
            <input
              type="checkbox"
              checked={item.IsActive}
              onChange={(e) => setItem({ ...item, IsActive: e.target.checked })}
            />
          </label>
        </td>
        <td>{item.Code}</td>
        <td>{item.Description}</td>
        <td>
          <a
            className="icon btn"
            style={{ float: "none" }}
            onClick={() => setShowItemDetail(!showItemDetail)}
          >
            <svg width="14" height="14" viewBox="0 0 24 24">
              <path
                d="m163 440l-91-91 251-250 90 90z m309-352l-48-48c-12-11-32-11-45 2l-45 45 91 91 45-45c13-13 13-33 2-45z m-408 275l-32 117 117-32z"
                transform="scale(0.046875 0.046875)"
              ></path>
            </svg>
          </a>
        </td>
        <td className="check">
          <label className="">
            <input
              type="checkbox"
              checked={item.selectToDelete}
              onChange={(e) => setItem({ ...item, selectToDelete: e.target.checked })}
            />
          </label>
        </td>
      </tr>
      {showItemDetail && (
        <CallDispositionCodeItem
          item={item}
          onClose={() => setShowItemDetail(false)}
          onUpdate={onUpdate}
        />
      )}
    </>
  );
};

const CallDispositionCodeItem = ({ item, onClose, onUpdate }) => {
  const [selectedTab, setSelectedTab] = useState("CODE");
  const [itemData, setItemData] = useState({ ...item });
  const [usages, setUsages] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getEntCallDispositionCodeUsages();
  }, []);

  const updateCallDispositionCode = async () => {
    setLoading(true);
    const path = "admin/enterprise/UpdateCallDispositionCode";
    try {
      const response = await post(path, "POST", JSON.stringify(itemData));
      if (response.ok) {
        setLoading(false);
        await onUpdate();
      } else {
        console.error(`${path}: failed: ${response.message}`);
        setLoading(false);
      }
    } catch (error) {
      console.error(`${path}: failed: ${error}`);
      setLoading(false);
    }
  };

  const getEntCallDispositionCodeUsages = async () => {
    setLoading(true);
    const path = `admin/enterprise/GetCallDispositionCodeUsage?code=${itemData.Code}`;
    try {
      const response = await fetchData(path);
      if (response && response.length > 0) {
        setUsages(response);
      }
      setLoading(false);
    } catch (error) {
      console.error(`${path}: failed: ${error}`);
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <tr>
        <td colSpan={5}>
          <div className="content">
            <DataLoadingSpinner
              className="spinner"
              style={{ margin: "10px auto 0", height: "40px" }}
            />
          </div>
        </td>
      </tr>
    );
  }
  return (
    <tr className="">
      <td colSpan="5">
        <div className="table-edit">
          <div style={{ textAlign: "center", margin: "10px 0" }}>
            <button
              className="no-btn"
              style={{ marginRight: "5px" }}
              onClick={() => setSelectedTab("CODE")}
            >
              Code
            </button>
            <button className="no-btn" onClick={() => setSelectedTab("USAGE")}>
              Usage
            </button>
          </div>
          {selectedTab === "CODE" && (
            <>
              <div className="form-group">
                <label className="switch right">
                  <input
                    type="checkbox"
                    checked={itemData.IsActive}
                    onChange={(e) => setItemData({ ...itemData, IsActive: e.target.checked })}
                  />
                  <span className="slider round"></span>
                </label>
                Activate Code
              </div>
              <div className="form-group">
                <label>Code</label>
                <input type="text" className="form-control" value={itemData.Code} disabled />
              </div>
              <div className="form-group">
                <label>Description</label>
                <input
                  type="text"
                  className="form-control"
                  value={itemData.Description}
                  onChange={(e) => setItemData({ ...itemData, Description: e.target.value })}
                />
              </div>
              <div className="btns">
                <Button
                  className="btn"
                  click={() => {
                    updateCallDispositionCode();
                  }}
                >
                  Save
                </Button>
                <Button
                  className="btn"
                  click={() => {
                    onClose();
                  }}
                >
                  Close
                </Button>
              </div>
            </>
          )}
          {selectedTab === "USAGE" && (
            <>
              <table className="table" style={{ backgroundColor: "white" }}>
                <thead>
                  <tr>
                    <th>Id</th>
                    <th>GroupId</th>
                    <th>Name</th>
                    <th>Type</th>
                  </tr>
                </thead>
                <tbody>
                  {Boolean(usages && usages.length === 0) && (
                    <tr>
                      <td colSpan={4}>
                        <div>
                          <p>No items found.</p>
                        </div>
                      </td>
                    </tr>
                  )}
                  {usages.map((usedByItem, index) => {
                    if (usedByItem) {
                      return (
                        <tr key={index}>
                          <td>{usedByItem.Id}</td>
                          <td>{usedByItem.GroupId}</td>
                          <td>{usedByItem.Name}</td>
                          <td>{usedByItem.Type}</td>
                        </tr>
                      );
                    }
                  })}
                </tbody>
              </table>
            </>
          )}
        </div>
      </td>
    </tr>
  );
};
