import React, { Component } from 'react';
import { Grid, GridColumn as Column, GridCellProps, GridCell, GridToolbar, GridDetailRow } from '@progress/kendo-react-grid';
import EllipsisWithTooltip from 'react-ellipsis-with-tooltip';


class ToolTipCell extends GridCell {

  render() {
    let fieldName = this.props.field;
    return (
      <td>
        <EllipsisWithTooltip placement="top">
          <div className='use-ellipsis'>{this.props.dataItem[fieldName]}</div>
        </EllipsisWithTooltip>
      </td>
    )
  }
}

export default ToolTipCell;