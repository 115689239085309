import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { Button } from '@progress/kendo-buttons-react-wrapper';
import { MultiSelect } from '@progress/kendo-dropdowns-react-wrapper';


class PushToTalkModal extends Component {

	constructor(props) {
		super(props);

    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);

    this.state = { showModal: false };
  }

  handleClose() {
    this.setState({ showModal: false });
  }

  handleShow() {
    this.setState({ showModal: true });
  }

	componentDidMount() {		
	}

  render() {
    return (
      <div className="setting">
        <span onClick={this.handleShow}> 
          <span className="name">Push To Talk</span>
          <a className="icon btn"><svg width="14" height="14" viewBox="0 0 24 24"><path d="m163 440l-91-91 251-250 90 90z m309-352l-48-48c-12-11-32-11-45 2l-45 45 91 91 45-45c13-13 13-33 2-45z m-408 275l-32 117 117-32z" transform="scale(0.046875 0.046875)"></path></svg></a>
        </span>

        <Modal show={this.state.showModal} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Push To Talk</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <p>Push to Talk allows people to call each other and have the call answered automatically via speakerphone/intercom. You can define which users have Push to Talk access to your phone.</p>
              <hr />
              <div className="form-group">
                <label className="switch right">
                  <input type="checkbox" />
                  <span className="slider round"></span>
                </label>
                Auto Answer
              </div>
              <hr />
              <div className="form-group">
                <label >Outgoing Connection Type</label>
                <select className="form-control">
                  <option defaultValue="">-Select-</option>
                  <option defaultValue="">One Way</option>
                  <option defaultValue="">Two Way</option>
                </select>
              </div>
              <div className="form-group">
                <label >Access List</label>
                <select className="form-control">
                  <option defaultValue="">-Select-</option>
                  <option defaultValue="">Allow Calls From Selected Users</option>
                  <option defaultValue="">Allow Calls From Everyone Except Selected Users</option>
                </select>
              </div>
              <div className="multiselect-group form-group">
                <label>Assigned Monitors:</label>
                <MultiSelect autoClose={false} dataSource={["User 1", "User 2", "User 3", "User 4"]}/>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
              <hr />
              <div className="btns">
              <Button className="btn">Save</Button>
              <Button className="btn" click={this.handleClose}>Cancel</Button>
              </div>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
};

export default PushToTalkModal;
