import React, { Component } from 'react';
import { Button } from '@progress/kendo-buttons-react-wrapper';
import * as apiCalls from '../../../requests/api_calls';
import * as help from'../../locations/locations_helpers';
import * as settings from '../../../scripts/settings';
import SaveStatus from './../../../components/save_status';
import * as helper from '../../../scripts/helper';
import { DataLoadingSpinner } from'./../../../components/spinners';

class GroupVLANTab extends Component {

  constructor(props) {
    super(props);

    this.setUseVlanId = this.setUseVlanId.bind(this);
    this.setVlanId = this.setVlanId.bind(this);
    this.setUseTcp = this.setUseTcp.bind(this);
    this.setAudioIsEncrypted = this.setAudioIsEncrypted.bind(this);
    this.saveGroupVlanSettings = this.saveGroupVlanSettings.bind(this);
    this.fetchVLANSettings = this.fetchVLANSettings.bind(this);
    this.setLocation = this.setLocation.bind(this);
    this.getTabBody = this.getTabBody.bind(this);
    this.state = {
      showModal: false,
      useVlanId: false,
      saving: false,
      showSaveStatus: false,
      showValidationError: false,
      loading: false,
      locations: [],
      currentLocation: ''
    };
  }

  componentDidMount() {
    this.fetchLocations();
  }

  fetchLocations = async(apiPath) => {
    let locationOption = (location, index) => <option key={index} value={location.LocationAccountNumber}>{location.LocationName}</option>

    if (!apiPath) apiPath = 'admin/Locations';
    await this.setState({ loading: true });
    apiCalls.fetchData(apiPath).then((data) => {
        if (data) {
            let locationOptions = data.map((location, index) => locationOption(location, index));
            if (locationOptions.length > 1) {
              locationOptions.unshift(
                locationOption({ LocationAccountNumber: '', LocationName: '- Select Location -' }, '')
              );
            }
            this.setState({ locationOptions: locationOptions });
            if (!sessionStorage.DeviceLocation) {
                let currLocation = this.state.locationOptions[0].props.value;
                this.setState({ currentLocation: currLocation, loading: false }); //initial value
            } else {
                this.setState({ currentLocation: sessionStorage.DeviceLocation }); //stored value
                this.loadLocationDetails(sessionStorage.DeviceLocation);
            }
        } else {
            this.setState({ locations: [], resultsMsg: settings.noResultsMsg, loading: false });
        };
    }).catch(err => console.error(err));
  }

  setLocation = async (event) => {
    let locationValue = event.target.value;
    await this.setState({ currentLocation: locationValue, loading: true });
    this.loadLocationDetails(locationValue);
  }

  loadLocationDetails = async(locationValue) => {
    await this.fetchVLANSettings();
    await this.setState({loading: false});
    sessionStorage.setItem('DeviceLocation', locationValue);
  }

  setUseVlanId = (e) => this.setState({useVlanId: e.target.checked});

  setVlanId = (e) => this.setState({VlanId: e.target.value}, this.validateForm(e));

  setUseTcp = (e) => {
    let protocol = e.target.checked ? 'TCP' : 'UDP';
    this.setState({useTcp: e.target.checked, TransportProtocol: protocol, EncryptedAudio: false});
  }

  setAudioIsEncrypted = (e) => this.setState({EncryptedAudio: e.target.checked});

  fetchVLANSettings = async() => {
    let params = '?locationAccountNumber=' + this.state.currentLocation;
    try
    {
      let data = await apiCalls.fetchData('admin/GetDeviceLocationProperties' + params).then((resp) => resp);
      if (data !== null) {
        await this.setState({
          EncryptedAudio: data.EncryptedAudio,
          TransportProtocol: data.TransportProtocol,
          useTcp: data.TransportProtocol === 'TCP',
          VlanId: data.VlanId || '',
          useVlanId: data.VlanId !== ('' || null),
          FQDN: data.FQDN
        });
      } else if (data === null) {
        await this.setState({fqdns: []});
      }
    }
    catch(ex) 
    {
      console.error(ex);
    };
  }

  validForm = () => {
    if (this.state.useVlanId && this.state.VlanId === '') {
      this.setState({showValidationError: true});
      return false
    } else {
      this.setState({showValidationError: false});
      return true
    }
  }

  validVlanId = (id) => {
    return (id !== "" && id !== undefined) && ((id >= 2 && id <= 1001) || (id >= 1006 && id <= 4094))
  }

  validateForm = (e) => {
    if (this.state.useVlanId && (!this.validVlanId(parseInt(e.target.value))))
    {
      this.setState({showValidationError: true});
    } else {
      this.setState({showValidationError: false});
    }
  }

  saveGroupVlanSettings = () => {
    if (this.validForm()) {
      this.setState({saving: true, showSaveStatus: true});
      let data = {
        TransportProtocol: this.state.TransportProtocol,
        EncryptedAudio: this.state.EncryptedAudio,
        VlanId: this.state.useVlanId ? this.state.VlanId : null,
        LocationAccountNumber: this.state.currentLocation,
        FQDN: this.state.FQDN
      };
      let apiPath = 'admin/UpdateDeviceLocationProperties';
      apiCalls.send(apiPath, 'PATCH', JSON.stringify(data)).then(resp => {
        if(resp.ok) {
          setTimeout(() => {
            this.setState({
              saving: false,
              statusMessage: 'Group VLAN settings were updated.',
              parentCallback: this.resetForm
            }), 
            setTimeout(() => this.resetForm(), help.statusMsgDelay)
          }
          ,help.hideSpinnerDelay);
        } else {
          this.setState({...this.state,
            saving: false,
            error: true,
            statusMessage: this.notSavedErrorMsg(resp),
            parentCallback: this.resetForm
          })
        }
      }).catch(message => {
        this.setState({saving: false});
        console.log('caught exception saving Group VLAN settings.');
        console.log(message);
      });
    }
  }

  resetForm = async() => {
    await this.setState({ loading: true, statusMessage: '' });
    try{
      await this.fetchVLANSettings();
    } catch (ex) { 
      console.error(ex);
    } finally {
      await this.setState({loading: false});
    }
  }

  vlanIdDivSettings = (attr) => {
    var settings = {};
    if (this.state.useVlanId) {
      settings = {class: 'hidden-group collapse in', 'aria-expanded': 'true'};
    } else {
      settings = {class: 'hidden-group collapse', 'aria-expanded': 'false'};
    }
    return settings[attr]
  }

  notSavedErrorMsg = (resp) => 'Device not saved. Error: ' + resp.message;

  getTabBody = () => {
    let perms = JSON.parse(sessionStorage.DevicePerms);//sessionStorage.DevicePerms
    if (this.state.loading) {
      return <DataLoadingSpinner className='load spinner'/>;
    } else if (this.state.currentLocation === '') {
      return (
        <div style={{ "fontSize": "medium", "marginBottom": "5px", "marginTop": "5px", "color": "red" }}>
          <b>​Please select a "Location" from the menu above.</b>
        </div>
      );
    } else {
      return (
        <div className={help.savingOrConfirming(this.state) ? 'opaque' : 'visible'}>
          <h3>Set Group VLAN ID</h3>
          <p>Setup a Virtual Location Area Network (VLAN) as the default. The default can be overwritten on an individual device level if needed.</p>
          <div className="form-group vlan-id">
            <label className="right switch">
              <input
                type="checkbox"
                data-toggle="collapse"
                data-target="#hide1"
                checked={this.state.useVlanId}
                onChange={this.setUseVlanId}
              />
              <span className="slider round"></span>
            </label>
            Use VLAN ID?
            <div
              id="hide1"
              className={this.vlanIdDivSettings('class')}
              aria-expanded={this.vlanIdDivSettings('aria-expanded')}
            >
              <label >VLAN ID</label>
              <input 
                type="text"
                style={{width: '25%', height:'20px', border: 'none', borderBottom: '2px solid #eee', outline: 'none', background: '#f9f9f9', boxShadow: 'none'}} 
                value={this.state.VlanId}
                onChange={this.setVlanId}
              /> <br />
              <span className={this.state.showValidationError ? 'invalid' : 'hidden'}>
                <span className='text-danger'>
                  VLAN ID is required and must be between 2 and 1001 or between 1006 and 4094.
                </span>
              </span>
            </div>
          </div>
          <h3>Set Group IP Transport Protocol for Devices</h3>
          <p>
            Set the IP transport protocol for all devices within the group.  If a device(s) requires a different setting 
            than what is configured for the group, you may elect to overwrite the group IP transport protocol settings 
            during device provisioning.
          </p>
          <p>
            TCP is not the typical transport protocol for voice traffic and should only be used when there are 20+ 
            Busy Lamp Fields (BLF) configured for an individual device.  Contact Momentum Telecom Technical Support 
            for more detail.
          </p>
          <div className="form-group transport-protocol">
            <label className="right switch">
              <input 
                type="checkbox"
                checked={this.state.useTcp}
                data-toggle="collapse" 
                data-target="#hide2"
                onChange={this.setUseTcp}
              />
              <span className="slider round"></span>
            </label>
            Use TCP? (UDP is default)
            <small>
              Note: if you change the current IP transport protocol settings for this group, you must reprovision 
              all devices within the group before the new setting will be pushed to existing device configuration
              files.
            </small>
          </div>
          <h3>Set Group Encrypted Audio & Signaling for Devices</h3>
          <p>
            Set device Audio and Signaling Encryption for all devices within the group.  If a device(s) requires a different 
            setting than what is configured for the group, you may elect to overwrite the group Audio and Signaling Encryption
            settings during device provisioning. 
          </p>
          <div className="form-group encryption">
            <label className="right switch">
              <input
                type="checkbox"
                checked={this.state.EncryptedAudio}
                data-toggle="collapse"
                data-target="#hide3"
                onChange={this.setAudioIsEncrypted}
                disabled={perms.Audio_Signaling && perms.Audio_Signaling === 1 ? '' : 'disabled'}
              />
              <span className="slider round"></span>
            </label>
            Enable Encrypted Audio & Signaling?
            {/* {helper.disableAudioSignaling() ? (<span style={{fontSize: 'x-small', color: 'red'}}> - change permission not enabled</span>) : ''} */}
          </div>
          
          <div>
            <Button className="btn" click={this.saveGroupVlanSettings}>Save</Button>
            <Button className="btn" click={this.resetForm}>Cancel</Button>
          </div>
        </div>
      )
    }
  }

  render() {
    return (
        <div className="setting">
            <h3>Group VLAN Settings</h3>
            <div className="form-group">
                <label>Location</label>
                <select onChange={this.setLocation} value={this.state.currentLocation}
                  style={{width: '20%', height:'20px', border: 'none', borderBottom: '2px solid #eee', outline: 'none', background: '#f9f9f9', boxShadow: 'none'}}
                >
                    {this.state.locationOptions}
                </select>
            </div>
            {this.getTabBody()}
            <SaveStatus 
              saving={this.state.saving}
              statusMessage={this.state.statusMessage}
              error={this.state.error}
              parentCallBack={this.state.parentCallback}
              hideOkBtn={true}
            />
          
        
      </div>
    )
  }
};

export default GroupVLANTab;