import React from 'react';
import DeviceSettingsModal from './deviceSettingsModal';
import { deviceIsAssigned } from './../devices_helpers';

const showModalBtn = (device) => {
  if (!deviceIsAssigned(device)) {
    return <span><a className="btn" style={{fontSize:"small"}}>Edit</a></span>
  } else {
    return null
  }
}

const DeviceEditModal = (props) => {
  let device = props.device;
  return (
    <DeviceSettingsModal
      title={'Edit Device Inventory'}
      showModalBtn={showModalBtn(device)}
      mac={device.MacAddress}
      assignedTo={device.AssignedTo}
      loadDevices={props.loadDevices}
    />
  )
}

export default DeviceEditModal