import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { Button } from '@progress/kendo-buttons-react-wrapper';
import * as apiCalls from '../../../requests/api_calls';
import { Grid, GridColumn as Column, GridCell } from '@progress/kendo-react-grid';
import { AutoComplete } from '@progress/kendo-dropdowns-react-wrapper';
import { DataLoadingSpinner } from '../../../components/spinners';
import _ from 'lodash';
import * as helper from '../../../scripts/helper'
import * as macdHelper from '../components/macd_helper';
import SaveSpinner from '../../../components/AddServicesSaveStatus';
import EllipsisWithTooltip from 'react-ellipsis-with-tooltip';
import ValidationMessagePopup from '../components/validationMessagePopup';
import { DatePicker } from '@progress/kendo-dateinputs-react-wrapper';
import AddressFormE911  from '../../../components/e911AddressForm';

class WrappedCell extends GridCell {

  render() {
    let fieldName = this.props.field;
    let value = this.props.dataItem[fieldName];
    if (fieldName === 'StartDate') {
      let dateValue = new Date(value);
      value = dateValue.getMonth() + '/' + dateValue.getDate() + "/" + dateValue.getFullYear();
    }

    let msg = helper.StringReplaceAll(this.props.dataItem[fieldName], '</br>','',false);
    return (
      <td>
        <EllipsisWithTooltip placement="top">
          <div className='use-ellipsis'>{value}</div>
        </EllipsisWithTooltip>
      </td>
    )
  }
}
class ValidationCell extends GridCell {
  render() {
    if (this.props.dataItem.ErrorMessage && this.props.dataItem.ErrorMessage !== '') {
      let msg = helper.StringReplaceAll(this.props.dataItem.ErrorMessage, '</br>','', false);
      return (
        <td title="Click to view error!">
          <ValidationMessagePopup
            ValidationMessage={msg}
            position={'right center'}
          />
        </td>
      )
    } else return <td> </td>
  }
}



class ConfigureProductModal extends Component {

  initialState = (props) => {    
    return {
      showModal: false,
      className: props.ConfigureClassName ? props.ConfigureClassName : 'product-configuration',
      quantity: props.OrderItem.configurationDetails.length,
      rcStateOptions: props.OrderItem.RCStateOptions,
      getProductProperties: props.ColumnConfig ? false : true,
      ColumnConfig: props.ColumnConfig ? props.ColumnConfig : macdHelper.getDefaultColumnConfig(),
      productData: props.OrderItem,
      configurationDetails: props.OrderItem.configurationDetails,
      loading: true,
      catalogItemId: props.OrderItem.CatalogItemId,
      defaultRateCenterOptions: props.OrderItem.DefaultRateCenterOptions,
      defaultRCState: props.OrderItem.DefaultRCState,
      defaultRCName: props.OrderItem.DefaultRCName,
      defaultLineFirstName: "",
      defaultLineLastName: "",
      defaultCNAM: "",
      serviceAddressRCState: props.OrderItem.DefaultRCState,
      serviceAddressRCName: props.OrderItem.DefaultRCName,
      serviceAddressRCNameOptions: props.OrderItem.DefaultRateCenterOptions,
      TerminatingNumbers: props.OrderItem.TerminatingNumbersList,
      //InventoryTNList: props.OrderItem.InventoryTNList,
      TNDefaultDiv: '',
      CurrentDetailId: 0,
      CurrentItemNumber: 0,
      SaveQueue: [],
      tmpQuantity: props.OrderItem.configurationDetails.length,
      ValidationMessage: '',
      DisplayValidationModal: false,
      SaveMessage: '',
      updating: false,
      ReadOnly: props.ReadOnly ? props.ReadOnly : false,
      ProductName: props.ProductName,
      isConfirmationDialogOpen: false,
      ValidationErrorMessages: [],
      IPTrunkGroupOptions: props.IPTrunkGroupOptions ? props.IPTrunkGroupOptions : [],
      IPTrunkGroups: props.IPTrunkGroupOptions ? props.IPTrunkGroups : [],
      RemovalError: false,
      showE911Modal: false,
      e911AddressItem: {},
      e911Index: -1,
      CustomerId: props.CustomerId
    }
  }

  constructor(props) {
    super(props);
    this.state = this.initialState(props);
    this.handleShow = this.handleShow.bind(this);
    this.postProductUpdate = this.postProductUpdate.bind(this);

    this.SetRCToDefaultByServiceAddress = this.SetRCToDefaultByServiceAddress.bind(this);
    this.delayedUpdate = this.delayedUpdate.bind(this);

    const updateStateValue = this.updateStateValue.bind(this);
    const updateRCValue = this.updateRCValue.bind(this);
    const updateInputValue = this.updateInputValue.bind(this);
    const clearListingName = this.clearListingName.bind(this);
    const updateTerminatingNumber = this.updateTerminatingNumber.bind(this);
    const AddItemToSaveQueue = this.AddItemToSaveQueue.bind(this);
    const displayValidationModal = this.displayValidationModal.bind(this);
    const updateTrunkGroupSelection = this.updateTrunkGroupSelection.bind(this);
    const updateDropdownColumnSelection = this.updateDropdownColumnSelection.bind(this);
    const getTrunkGroupOptions = this.getTrunkGroupOptions.bind(this);
    const handleUpdateDate = this.handleUpdateDate.bind(this);
    const showE911Modal = this.showE911Modal.bind(this);
    const getReadOnlyStatus = this.getReadOnlyStatus.bind(this);
    this.SaveLineItemDetail = this.SaveLineItemDetail.bind(this);
    this.fetchRcNameList = this.fetchRcNameList.bind(this);
    this.fetchProductProperties = this.fetchProductProperties.bind(this);
    this.refreshDefaultRCName = this.refreshDefaultRCName.bind(this);
    this.ApplyDefaultLineNames = this.ApplyDefaultLineNames.bind(this);
    this.ApplyDefaultCNAM = this.ApplyDefaultCNAM.bind(this);

    this.ApplyDefaultRCValues = this.ApplyDefaultRCValues.bind(this);
    this.ResetRCValues = this.ResetRCValues.bind(this);

    this.handleDefaultLineCNAMChange = this.handleDefaultLineCNAMChange.bind(this);
    this.handleDefaultLineFirstNameChange = this.handleDefaultLineFirstNameChange.bind(this);
    this.handleDefaultLineLastNameChange = this.handleDefaultLineLastNameChange.bind(this);
    this.CycleThruSaveQueue = this.CycleThruSaveQueue.bind(this);
    this.getGrid = this.getGrid.bind(this);
    this.handleE911AddressUpdate = this.handleE911AddressUpdate.bind(this);

    class E911AddressCell extends GridCell {
      render() {
        let addressDisplay = '';
        const productItem = this.props.dataItem;        
        let isReadOnly = getReadOnlyStatus();
        
        let address1 = helper.CreateFullStreet1(productItem.address);
        let address2 = helper.CreateFullStreet2(productItem.address);
        let address3 = productItem.address ? `${productItem.address.City !== undefined ? productItem.address.City : ''},${productItem.address.State !== undefined ? productItem.address.State : ''} ${productItem.address.ZipCode !== undefined ? productItem.address.ZipCode : ''}` : '';
        
        if (address1 !== '' && address2 !== '' && address3 != ', ' && address3 !== '')
          addressDisplay = (<span>{address1}<br />{address2}<br />{address3}</span>)
        else if (address1 !== '' && address3 !== ', ' && address3 !== '')
          addressDisplay = (<span>{address1}<br />{address3}</span>)
        else if (address1 !== '' && address2 !== '')
          addressDisplay = (<span>{address1}<br />{address2}</span>)
        else if (address2 !== '' && address3 !== ', ' && address3 !== '')
          addressDisplay = (<span>{address2}<br />{address3}</span>)
        else if (address1 !== '')
          addressDisplay = (<span>{address1}</span>);
        else if (address2 !== '')
          addressDisplay = (<span>{address2}</span>);
        else if (address3 !== ', ' && address3 !== '')
          addressDisplay = (<span>{address3}</span>);
        else if (!isReadOnly)
          addressDisplay = (<span>Click to add an E911 Address</span>)
        if (addressDisplay !== '')
        {
          return (
            <td>
              <a title="Click to add an E911 address" onClick={() => showE911Modal(productItem)}>
                <span>{addressDisplay}</span>
              </a>
            </td>
          )
        } else return (<td></td>);
      }
    }

    class RCStateCell extends GridCell {
      constructor(props) {
        super(props);
      }
      render() {
        const productItem = this.props.dataItem;
        const value = this.props.dataItem[this.props.field];
        return (
          <td>
            <select style={{ width: "95%" }} value={value} onChange={(e) => { updateStateValue(productItem, e) }}
              onBlur={(e) => { if (productItem.HasChanges) AddItemToSaveQueue(productItem) }}
            >
              <option value="">Select a State</option>
              {productItem.RCStateOptions}
            </select>
          </td>
        )
      }
    }
    class RCNameCell extends GridCell {
      constructor(props) {
        super(props);
      }
      render() {
        let productItem = this.props.dataItem;
        const value = this.props.dataItem[this.props.field];

        return (
          <td>
            <select style={{ width: "150px" }} value={value} onChange={(e) => { updateRCValue(productItem, e) }}
              onBlur={(e) => { if (productItem.HasChanges) AddItemToSaveQueue(productItem) }}
            >
              <option value="">Select a Center</option>
              {productItem.RateCenterOptions}
            </select>
          </td>
        )
      }
    }
    class InputTextCell extends GridCell {
      constructor(props) {
        super(props);
      }
      render() {
        const value = this.props.dataItem[this.props.field];
        const productItem = this.props.dataItem;
        if (this.props.field === "ListingName" && this.props.dataItem.ListingType === "4") {
          return (
            <td>
              <input type="text" disabled="disabled" style={{ width: "95%" }} value=""></input>
            </td>
          )
        } else {
          return (
            <td>
              <input type="text" style={{ width: "95%" }} value={value} autoComplete={"off"} 
                maxLength={this.props.field === "AccessCode" ? "9" : this.props.field === 'CNAM' ? "15" : "50"} 
                onChange={(e) => { updateInputValue(productItem, this.props.field, e) }}
                onBlur={(e) => { if (productItem.HasChanges) AddItemToSaveQueue(productItem) }}></input>
            </td>
          )
        }
      }
    }

    class InputNumericTextCell extends GridCell {
      constructor(props) {
        super(props);
      }
      render() {
        const value = this.props.dataItem[this.props.field];
        const productItem = this.props.dataItem;
        return (
          <td>
            <input type="text" style={{ width: "95%" }} value={value} autoComplete={"off"} onChange={(e) => { updateInputValue(productItem, this.props.field, e, true) }}
              onBlur={(e) => { if (productItem.HasChanges) AddItemToSaveQueue(productItem) }}></input>
          </td>
        )
      }
    }
    class InputCheckField extends GridCell {
      constructor(props) {
        super(props);
      }
      render() {
        const value = !!+this.props.dataItem[this.props.field];
        const productItem = this.props.dataItem;
        
        if (this.props.className === 'ReadOnly') 
          return (
            <td>
              <input disabled="disabled" type="checkbox" style={{ width: "100px" }} checked={value}></input>
            </td>
          )
        else if (this.props.field === "OmitAddress" && (productItem.ListingType === "3" || productItem.ListingType === "4"))
          return (
            <td>
              <input disabled="disabled" type="checkbox" style={{ width: "100px" }} checked={false}></input>
            </td>
          )
        else
          return (
            <td>
              <input type="checkbox" style={{ width: "100px" }} checked={value} onChange={(e) => { updateInputValue(productItem, this.props.field, e) }}
                onBlur={(e) => { if (productItem.HasChanges) AddItemToSaveQueue(productItem) }}></input>
            </td>
          )
      }
    }
    class ListingTypeCell extends GridCell {
      constructor(props) {
        super(props);
      }
      render() {
        let value = '';
        let isReadOnly = getReadOnlyStatus();
        value = this.props.dataItem[this.props.field];
        const productItem = this.props.dataItem;
          
        return (
          <td>
            <select style={{ width: "95%" }} value={value} onChange={(e) => { updateInputValue(productItem, this.props.field, e) }}
              onBlur={(e) => { if (productItem.HasChanges) AddItemToSaveQueue(productItem) }} disabled={isReadOnly === true ? "disabled" : ""}
            >
              <option value="1">LISTED</option>
              <option value="2">NONLIST</option>
              <option value="3">NONPUB</option>
              <option value="4">NOSUBMIT</option>
            </select>
          </td>
        ) 
      }
      
    }
    class AutoCompleteCell extends GridCell {
      constructor(props) {
        super(props);
      }
      render() {
        if (this.props.field === "TerminatingNumber") {
          let termArray = this.props.dataItem.TerminatingNumbersList ? this.props.dataItem.TerminatingNumbersList : [];
          const value = this.props.dataItem[this.props.field];
          const productItem = this.props.dataItem;
          return (
            <td>
              <AutoComplete style={{width: '95%'}} dataSource={termArray} placeholder={"Enter number"}
                value={value} change={(e) => { updateTerminatingNumber(productItem, e); }} close={(e) => { if (productItem.HasChanges) AddItemToSaveQueue(productItem) }}
              />
            </td>
          )
        }
      }
    }
    class TrunkGroupPickerCell extends GridCell {
      constructor(props) {
        super(props);
      }
      render() {
        let trunkGroupArray = getTrunkGroupOptions()
        let finalArray = [];
        let blankEntry = (<option value={0}> -Select Trunk Group -</option>);
        finalArray.push(blankEntry);
        finalArray = Array.concat(finalArray, trunkGroupArray);
        //finalArray.concat(trunkGroupArray);
        const value = this.props.dataItem[this.props.field];
        const productItem = this.props.dataItem;
        return (
          <td>
            <select style={{ width: "95%" }} value={value} onChange={(e) => { updateTrunkGroupSelection(productItem, e) }}
                onBlur={(e) => { if (productItem.HasChanges) AddItemToSaveQueue(productItem) }} //disabled={this.props.ReadOnly ? "disabled" : ""}
              >
                {finalArray}
            </select>
          </td>
        )
      }
    }
    
    class GroupPolicyColumn extends GridCell {
      constructor(props) {
        super(props);
      }
      render() {
        let groupPolicyOptions = [];
        groupPolicyOptions.push(
          (<option value='round_robin'>round_robin</option>)
        );

        groupPolicyOptions.push(
          (<option value='top_down'>top_down</option>)
        );
        const value = this.props.dataItem[this.props.field];
        const productItem = this.props.dataItem;
        return (
          <td>
            <select style={{ width: "95%" }} value={value} onChange={(e) => { updateDropdownColumnSelection(this.props.field, productItem, e) }}
                onBlur={(e) => { if (productItem.HasChanges) AddItemToSaveQueue(productItem) }} //disabled={this.props.ReadOnly ? "disabled" : ""}
              >
                {groupPolicyOptions}
            </select>
          </td>
        )
      }
    }

    class InputDateField extends GridCell {
      constructor(props) {
        super(props);
      }
      render() {
        const value = this.props.dataItem[this.props.field];
        const productItem = this.props.dataItem;
        return (
          <td>
            <DatePicker                
                min={new Date()}
                value={value}
                format="MM/dd/yyyy"
                change={(e) => { handleUpdateDate(e, productItem, this.props.field); }}
              />
          </td>
        )
      }
    }

    class ServiceStatusColumn extends GridCell {
      constructor(props) {
        super(props);
      }
      render() {
        let statusOptions = [];
        statusOptions.push(
          (<option value='inservice'>inservice</option>)
        );

        statusOptions.push(
          (<option value='block'>block</option>)
        );

        const value = this.props.dataItem[this.props.field];
        const productItem = this.props.dataItem;
        return (
          <td>
            <select style={{ width: "95%" }} value={value} onChange={(e) => { updateDropdownColumnSelection(this.props.field, productItem, e) }}
                onBlur={(e) => { if (productItem.HasChanges) AddItemToSaveQueue(productItem) }} 
              >
                {statusOptions}
            </select>
          </td>
        )
      }
    }

    this.RCStateCell = RCStateCell;
    this.RCNameCell = RCNameCell;
    this.InputTextCell = InputTextCell;
    this.InputCheckField = InputCheckField;
    this.InputAutoCompleteField = AutoCompleteCell;//(TerminatingNumbers = this.state.TerminatingNumbers);
    this.ListingTypeCell = ListingTypeCell;
    this.InputNumericTextCell = InputNumericTextCell;
    this.TrunkGroupPickerCell = TrunkGroupPickerCell;
    this.GroupPolicyColumn = GroupPolicyColumn;
    this.ServiceStatusColumn = ServiceStatusColumn;
    this.InputDateField = InputDateField;
    this.E911AddressCell = E911AddressCell;
  }

  async handleShow() {
    await this.setState({loading: true, showModal: true, quantity: this.state.configurationDetails.length, editID: 1});
    if (this.state.getProductProperties)
      await this.fetchProductProperties();

    // this.TNDefaults();
    let validationMessages = [];
    this.state.configurationDetails.map((itm, idx) => {
      if (itm.ErrorMessage !== '') {
        validationMessages.push(itm.ErrorMessage);
      }
    });
    await this.setState({ loading: false, showModal: true, quantity: this.state.configurationDetails.length, editID: 1, ValidationErrorMessages: validationMessages, RemovalError: false });
    
  }

  componentDidMount() {
    this.setState({loading: true});
  }

  componentWillReceiveProps = (props) => {
    if (props.catalogItemId && props.catalogItemId !== this.state.catalogItemId) {
      let state = this.initialState(props);
      this.setState({...state});
    } else if (props.IPTrunkGroupOptions !== this.state.IPTrunkGroupOptions) {
      let state = this.state;
      state.IPTrunkGroups = props.IPTrunkGroups;
      state.IPTrunkGroupOptions = props.IPTrunkGroupOptions;
      this.setState({...state});
    }
  }

  getReadOnlyStatus = () => {
    return this.state.ReadOnly;
  }

  fetchProductProperties = async() => {    
    let PartNum = this.state.productData.PartNum;
    let apiPath = macdHelper.apiGetProductPropertiesWithParams(PartNum);
    await apiCalls.fetchData(apiPath).then((data) => {
      let [ColumnConfig, className] = macdHelper.getColumnConfigFromDatabase(data, PartNum);
      this.setState({
        loading: false,
        className: className,
        ColumnConfig: ColumnConfig
      });
    });
  }

  fetchRcNameList = (productItem, rcState) => {
    let apiPath = macdHelper.apiGetRCNameByState(rcState);
    apiCalls.fetchData(apiPath).then((data) => {
      let configDetails = this.state.configurationDetails.slice();
      if (data !== null) {
        let rcCenterOptions = [];
        data.map((rc, index) => {
          if (rc.InventoryAvailable > 0) {
            rcCenterOptions.push(macdHelper.rcNameOption(rc));
          }
        });
        if (this.state.configurationDetails && this.state.configurationDetails.length > 0) {
          configDetails[configDetails.indexOf(productItem)].RateCenterOptions = rcCenterOptions;
          this.setState({ configurationDetails: configDetails });
        }
      } else if (data === null) {
        configDetails[configDetails.indexOf(productItem)].RateCenterOptions = [];
        this.setState({ configurationDetails: configDetails });
      };
    }).catch(message => {
      let configDetails = this.state.configurationDetails.slice();
      configDetails[configDetails.indexOf(productItem)].RateCenterOptions = [];
      this.setState({ configurationDetails: configDetails });
    });
  }

  postProductUpdate = async (OrderItem, configDetails, idx, SaveAll) => {
    await this.setState({ closing: true, SaveMessage: 'Reviewing Product Configuration...' });
    if (this.state.waitingOnTempUpdate) {
      await this.handleQuantityUpdate();
    }
    if (!SaveAll) {
      this.completeModalClose(OrderItem, configDetails, idx, false);
      //this.setState({ closing: false, showModal: false, ValidationErrorMessages: [] });
      return;
    }
    let errorList = [];
    let res = {};
    if (this.state.SaveQueue && this.state.SaveQueue.length > 0) {
      res = await this.CycleThruSaveQueue();
    } else if (SaveAll) {
      const promises = [];
      configDetails.map((cd) => {
        promises.push(this.SaveLineItemDetail(cd));
      });
      res = await Promise.all(promises).then(res => res);
    }
    let [DetailItemValidations, LineItemErrors, TrunkGroupValidations] = await macdHelper.ItemValidations(this.state.productData.OrderId, this.state.productData.MPOLocationId);
    let ConfigurationDetailArray = this.state.configurationDetails.slice();
    await ConfigurationDetailArray.map((cda) => { 
      let detailId = cda.DetailId;
      let detailMessages = _.filter(DetailItemValidations, o => o.SourceId === detailId );
      if (detailMessages) {
        detailMessages.forEach(responseObject => {
          errorList.push(responseObject.ErrorMessage);
          cda.ErrorMessage += cda.ErrorMessage === '' ? responseObject.ErrorMessage : '\n' + responseObject.ErrorMessage;
        });
        cda.ErrorMessage = helper.StringReplaceAll(cda.ErrorMessage, "</br>","", false);
      }
      if (OrderItem.PartNum === 'IP100') {
        _.filter(TrunkGroupValidations, lie => cda.IPTrunkConfigId === lie.SourceId).map((trunkError) => {
          let msg = helper.StringReplaceAll(trunkError.ErrorMessage, '</br>', '', false) + '\n';
          cda.ErrorMessage += msg;
        });
      }
    });
    let LineMessages = _.filter(LineItemErrors, o => o.SourceId === this.state.productData.LineItemId);
    if (LineMessages && LineMessages.length > 0) {
      let productData = this.state.productData;
      productData.ErrorMessage = LineMessages[0].ErrorMessage;
      await this.setState({productData: productData});
    }
    OrderItem.isConfigured = macdHelper.configuredCheck(OrderItem.configurationDetails, OrderItem, DetailItemValidations, LineItemErrors, TrunkGroupValidations);      
    //Get all promise responses
    //if any promise returns a validation error then we should keep the window open
    //otherwise need to close the save message spinner and let user review their errors
    let showModal = false;
    if (res.length > 0)
    {
      showModal = true;
      if (res.length > 0)
        res.forEach(responseObject => {
          if (responseObject && responseObject.ErrorMessage && (responseObject.ErrorMessage !== '' || responseObject.SipsError !== '')) {
            errorList.push((responseObject.ErrorMessage !== '' ? helper.StringReplaceAll(responseObject.ErrorMessage,"</br>","",false) : helper.StringReplaceAll(responseObject.SipsError,"</br>","",false)));
          }
        });
    }
    if (errorList.length === 0) {//if no errors from save queue then set to configured and close.
      // if (!this.state.productData.ErrorMessage || this.state.productData.ErrorMessage === '')
      //   OrderItem.isConfigured = true;
      // else //if error message on line item itself then it needs to continue to keep the product 'unconfigured'
      //   OrderItem.isConfigured = false;

      this.completeModalClose(OrderItem, configDetails, idx, true);
    }
    else {
      //OrderItem.isConfigured = false;
      await this.setState({closing: false, configurationDetails: ConfigurationDetailArray, showModal: showModal, ValidationErrorMessages: errorList});
      await this.props.updateParentArrayItem(OrderItem, configDetails, idx, this.state.IPTrunkGroups);
    }
  }

  completeModalClose = async(OrderItem, configDetails, idx, bShowVerifying = false) => {
    if (bShowVerifying)
      this.setState({ closing: true, SaveMessage: 'Verifying Configuration Entries...' });
    await this.props.updateParentArrayItem(OrderItem, configDetails, idx, this.state.IPTrunkGroups);
    this.setState({ closing: false, showModal: false, ValidationErrorMessages: [], RemovalError: false });
  }

  CycleThruSaveQueue = async () => {
    let arrSaveQueue = this.state.SaveQueue.slice();
    let responses = [];
    while (arrSaveQueue.length > 0) {
      let DetailId = this.state.SaveQueue[0].DetailId;
      let ItemNumber = this.state.SaveQueue[0].ItemNumber;

      let DetailItemsFromQueue = arrSaveQueue.filter(resp => (DetailId !== 0 && resp.DetailId === DetailId) || (DetailId === 0 && resp.ItemNumber === ItemNumber));
      let latestSave = DetailItemsFromQueue[DetailItemsFromQueue.length - 1];
      responses.push(await this.SaveLineItemDetail(latestSave));
      arrSaveQueue = arrSaveQueue.filter(resp => (DetailId !== 0 && resp.DetailId !== DetailId) || (DetailId === 0 && resp.ItemNumber !== ItemNumber));
    }
    this.setState({ SaveQueue: [] });
    return responses;
  }

  updateStateValue = (productItem, e) => {
    let searchText = e.target.value;
    productItem.RCState = searchText;
    productItem.HasChanges = true;
    this.fetchRcNameList(productItem, searchText);
  }

  updateRCValue = (productItem, e) => {
    let searchText = e.target.value;
    productItem.RCName = searchText;
    productItem.HasChanges = true;
    let existingArray = this.state.configurationDetails;
    let index = _.findIndex(existingArray, s => s.ItemNumber == productItem.ItemNumber);
    let newOptions = this.state.configurationDetails.slice();
    newOptions[index] = productItem;
    this.setState({ configurationDetails: newOptions });
  }

  getTrunkGroupOptions = () => {
    return this.state.IPTrunkGroupOptions;
  }

  updateTrunkGroupSelection = (productItem, e) => {
    let selectedValue = parseInt(e.target.value);
    //TODO: determine if this is a new trunk group or an old trunk group and update the correct field
    let tgs = this.state.IPTrunkGroups.slice();
    let selectedTrunkGroup = tgs.filter(resp => resp.ConcatId === selectedValue);
    productItem.TrunkId = selectedValue;
    if (selectedTrunkGroup && selectedTrunkGroup.length === 1) { 
      selectedTrunkGroup = selectedTrunkGroup[0];
      if (selectedTrunkGroup.IsNew) {
        productItem.ParentDetailId = selectedValue;
        productItem.IPTrunkGroupCustomerProductId = 0;
      }
      else {
        productItem.ParentDetailId = 0;
        productItem.IPTrunkGroupCustomerProductId = selectedValue;
      }
    } else {
      productItem.ParentDetailId = 0;
      productItem.IPTrunkGroupCustomerProductId = 0;
    }

    productItem.HasChanges = true;
    let existingArray = this.state.configurationDetails;
    let index = _.findIndex(existingArray, s => s.ItemNumber == productItem.ItemNumber);
    let newOptions = this.state.configurationDetails.slice();
    newOptions[index] = productItem;
    this.setState({configurationDetails: newOptions});
  }

  updateDropdownColumnSelection = (field, productItem, e) => {
    let selection = e.target.value;
    productItem[field] = selection;
    productItem.HasChanges = true;
    let existingArray = this.state.configurationDetails;
    let index = _.findIndex(existingArray, s => s.ItemNumber == productItem.ItemNumber);
    let newOptions = this.state.configurationDetails.slice();
    newOptions[index] = productItem;
    this.setState({configurationDetails: newOptions});
  }

  updateInputValue = (productItem, field, e, isNumeric) => {
    let newValue = "";
    let validationMessages = this.state.ValidationErrorMessages.slice();
    if (field === "OmitAddress" || field === 'CNAMDelivery' || field === 'AllowInternational') {
      newValue = productItem[field] === true ? false : true;
    } else if (field === "ListingType") {
      //if listing type is updated. we have to clear and disable certain fields based on selection
      newValue = isNumeric && isNumeric == true ? parseInt(e.target.value) : e.target.value;
      let listingName = productItem.ListingName, omitAddress = productItem.OmitAddress;
      if (newValue === "4") {
        listingName = "";
        omitAddress = false;
      } else if (newValue === "3") {
        omitAddress = false;
      }
      productItem.ListingName = listingName;
      productItem.OmitAddress = omitAddress;
    } 
    // else if (field.startsWith('IP')) {
    //   //validate ip address
    //   // if (!helper.ValidateIPaddress(e.target.value)) {
    //   //   productItem.ErrorMessage = 'IP Address for ' + field + ' is not a valid IP Address format!';
    //   //   validationMessages.push(productItem.ErrorMessage);
    //   // } else {
    //     let currentMsg = productItem.ErrorMessage;
    //     productItem.ErrorMessage = '';
    //     let tmpVE = validationMessages.slice();
    //     validationMessages = [];
    //     let iCountRemove = 0;
    //     for (var i = 0; i < tmpVE.length; i++) {
    //       if (tmpVE[i] !== currentMsg && iCountRemove === 0) {
    //         validationMessages.push(tmpVE[i]);
    //       } else iCountRemove++;
    //     }
        
    //   //}
    //   newValue = e.target.value;
    // }
    else {
      if (isNumeric && isNumeric === true) {//if numeric make sure entry is numeric before accepting or that it is blank
        if (e.target.value !== '') {
          if (isNaN(parseInt(e.target.value)))
            newValue = productItem[field]
          else
            newValue = parseInt(e.target.value);
        } else newValue = '';
      } else newValue = e.target.value;
    }
    productItem[field] = newValue;
    productItem.HasChanges = true;
    let existingArray = this.state.configurationDetails;
    let index = _.findIndex(existingArray, s => s.ItemNumber == productItem.ItemNumber);
    let newOptions = this.state.configurationDetails.slice();
    newOptions[index] = productItem;
    this.setState({ configurationDetails: newOptions, ValidationErrorMessages: validationMessages });
  }

  updateTerminatingNumber = (productItem, e) => {
    let newVal = ''
    if (e.sender.element[0].value === '' || isNaN(e.sender.element[0].value)) {
      newVal = '';
      e.sender.element[0].value = '';
    } else
      newVal = parseInt(e.sender.element[0].value);

    productItem.TerminatingNumber = newVal;
    productItem.HasChanges = true;
    let existingArray = this.state.configurationDetails;
    let index = _.findIndex(existingArray, s => s.ItemNumber == productItem.ItemNumber);
    let newOptions = this.state.configurationDetails.slice();
    newOptions[index] = productItem;
    this.setState({ configurationDetails: newOptions });
  }

  handleUpdateDate = (e, productItem, field) => {
    let selection = e.sender.value();
    if (selection !== null) {
      let ServiceDateProp = selection.getMonth() + 1 + '/' + selection.getDate() + '/' + selection.getFullYear();
      productItem[field] = ServiceDateProp;
    } else productItem[field] = '';
    productItem.HasChanges = true;
    let existingArray = this.state.configurationDetails;
    let index = _.findIndex(existingArray, s => s.ItemNumber == productItem.ItemNumber);
    let newOptions = this.state.configurationDetails.slice();
    newOptions[index] = productItem;
    this.setState({configurationDetails: newOptions});
  }

  clearListingName = (productItem) => {
    productItem.ListingName = "";
    productItem.HasChanges = true;
    let existingArray = this.state.configurationDetails;
    let index = _.findIndex(existingArray, s => s.ItemNumber == productItem.ItemNumber);
    let newOptions = this.state.configurationDetails.slice();
    newOptions[index] = productItem;
    this.setState({ configurationDetails: newOptions });
  }

  handleQuantityUpdate = async() => {
    let saveQueue = this.state.SaveQueue.splice();
    //need to add an entry to configurationDetails that is blank
    let newVal = parseInt(this.state.tmpQuantity);
    await this.setState({ updating: true, SaveMessage: this.state.ColumnConfig.ConfigType === 'NOCONFIG' ? 'Updating Quantity...' : 'Updating Grid...' });
    let productConfigs = [];
    let iCount = this.state.configurationDetails.length;
    let trunkGroups = this.state.IPTrunkGroups.slice();
    if (this.state.quantity > newVal) {
      
      while (iCount > newVal) {
        let pc = this.state.configurationDetails[iCount - 1];
        if (pc.DetailId !== 0)
        {
          // if (pc.ConfigType === 'IPTCONFIG') {
          //   trunkGroups = trunkGroups.filter(resp => resp.DetailId !== pc.DetailId);
          //   if (this.props.CheckTrunkGroupAssociations(trunkGroups))
          //   {
          //     await macdHelper.RemoveTrunkGroupConfigFromOrder(this.state.productData.OrderId, this.state.productData.MPOLocationId, pc.LineItem, pc.IPTrunkConfigId);
          //   } else {
          //     //TODO: show an error message that says there are other items tied to the configuration being removed
          //     this.setState({RemovalError: true, updating: false, waitingOnTempUpdate: false});
          //     return;
          //   }
          // }
          macdHelper.RemoveDetailItemFromOrder(this.state.productData.OrderId, this.state.productData.MPOLocationId, pc.LineItemId, pc.DetailId);
          if (pc.ConfigType === 'IPTCONFIG' && pc.DetailId > 0)
            this.props.RemoveNewTrunkGroup(pc.DetailId)
        }
        iCount--;
      }
      productConfigs = this.state.configurationDetails.slice(0, newVal);//.splice(this.state.configurationDetails.length - 1, 1);
    } else {      
      if (this.state.ColumnConfig.ConfigType !== 'NOCONFIG') 
        productConfigs = macdHelper.MapConfigDetails(newVal, this.state.configurationDetails, this.state.productData, null, null, null, null, null, false);
      else {
        iCount += 1;//doing this so that the next configuration Item it saves is one of the new indexes
        while (iCount <= newVal) {
          let DetailId = await macdHelper.ReviewAndSaveNoConfigItem(this.state.productData.OrderId, this.state.productData.MPOLocationId, this.state.productData.PartNum, this.state.productData.LineItemId);
          productConfigs = macdHelper.MapConfigDetails(iCount, this.state.configurationDetails, this.state.productData, DetailId, null, null, null, null, false);
          iCount++;
        }
      }
    }

    let productData = this.state.productData;
    productData.configurationDetails = productConfigs;

    let data = {
      OrderId: this.state.productData.OrderId,
      LocationId: this.state.productData.MPOLocationId,
      LineItemId: this.state.productData.LineItemId,
      NewCount: newVal
    }
    let lineItemResponse = await macdHelper.UpdateLineItemInOrder(data);
    let lineItemFromDB = JSON.parse(lineItemResponse.message);
    if (lineItemFromDB) {
      productData.ErrorMessage = lineItemFromDB.ErrorMessage;
    }
    let validationMessages = this.state.ValidationErrorMessages.slice();
    if (this.state.ColumnConfig.ConfigType !== 'NOCONFIG') {
      validationMessages = [];
      productConfigs.map((ci) => {
        if (ci.ErrorMessage && ci.ErrorMessage.length > 0)
          validationMessages.push(ci.ErrorMessage);
      });
    }
    saveQueue = saveQueue.filter(resp => resp.ItemNumber > productData.configurationDetails[productData.configurationDetails.length - 1].ItemNumber);
    //await this.props.updateParentArrayItem(productData, productConfigs, -1, null)
    await this.setState({ 
      quantity: newVal, 
      ValidationErrorMessages: validationMessages,
      configurationDetails: productConfigs, 
      productData: productData, 
      SaveQueue: saveQueue, 
      updating: false, 
      SaveMessage: '', 
      waitingOnTempUpdate: false 
    });
  }

  AddItemToSaveQueue = async (ConfigItem) => {
    let saveQueue = this.state.SaveQueue;
    if (this.state.CurrentDetailId != ConfigItem.DetailId || this.state.CurrentItemNumber != ConfigItem.ItemNumber) {
      let saveItm = this.state.configurationDetails.filter(resp => resp.ItemNumber == this.state.CurrentItemNumber);
      this.setState({ CurrentDetailId: ConfigItem.DetailId, CurrentItemNumber: ConfigItem.ItemNumber });
      if (saveItm && saveItm.length > 0) {
        saveQueue = saveQueue.filter(resp => resp.ItemNumber !== saveItm[0].ItemNumber);
        this.SaveLineItemDetail(saveItm[0]);
      }
    }
    if (ConfigItem && (!saveQueue || saveQueue.length === 0 || saveQueue.indexOf(ConfigItem) < 0)) {
      saveQueue.push(ConfigItem);
    }
    this.setState({ SaveQueue: saveQueue });
  }

  SaveLineItemDetail = async (ConfigItem) => {
    let apiPath = '', method = '';
    let reqBody = {};
    if (!ConfigItem)
      return;
    this.setState({ saving: true });

    if (ConfigItem.DetailId === 0) {
      apiPath = macdHelper.apiAddServicesDetailItem();
      method = 'POST';
    } else {
      apiPath = macdHelper.apiUpdateDetailItem();
      method = 'PATCH';
    }
    reqBody = {
      OrderId: this.state.productData.OrderId,
      LocationId: this.state.productData.MPOLocationId,
      LineItemId: this.state.productData.LineItemId,
      DetailId: ConfigItem.DetailId,
      ServiceNumber: ConfigItem.InventoryTN,
      Extension: ConfigItem.Extension,
      LineFirstName: ConfigItem.LineFirstName,
      LineLastName: ConfigItem.LineLastName,
      ListingType: ConfigItem.ListingType,
      ListingName: ConfigItem.ListingName,
      OmitAddress: Number(ConfigItem.OmitAddress),
      CNAM: ConfigItem.CNAM,
      TerminatingNumber: ConfigItem.TerminatingNumber,
      LineEmail: ConfigItem.LineEmail,
      RateCenterState: this.state.ColumnConfig.ShowRCFields ? ConfigItem.RCState : '',
      RateCenterName: this.state.ColumnConfig.ShowRCFields ? ConfigItem.RCName : '',
      AccessCode: ConfigItem.AccessCode,
      IPTrunkGroupID: ConfigItem.IPTrunkGroupID,
      ParentDetailId: ConfigItem.ParentDetailId,
      IPTrunkGroupCustomerProductId: ConfigItem.IPTrunkGroupCustomerProductId,      
      ErrorMessage: ConfigItem.ErrorMessage,
      Temp911AddressId: ConfigItem.address.AddressId,
      Description: ConfigItem.Description
    }


    let configDetails = this.state.configurationDetails.slice();
    let productData = this.state.productData;
    let response = await apiCalls.post(apiPath, method, JSON.stringify(reqBody)).then(data => data);
    this.setState({ saving: false });
    if (response.ok) {
      let responseBody = JSON.parse(response.message);
      let itmToUpdate = configDetails.filter(resp => resp.DetailId == ConfigItem.DetailId);
      if (!itmToUpdate || itmToUpdate.length > 1) {
        itmToUpdate = configDetails.filter(resp => resp.ItemNumber === ConfigItem.ItemNumber)[0];
      } else {
        itmToUpdate = itmToUpdate[0];
      }
      let idxItem = configDetails.indexOf(itmToUpdate);
      itmToUpdate.DetailId = responseBody.DetailId;
      let trunkGroupError = '';
      if (this.state.ColumnConfig.ConfigType === 'IPTCONFIG') {
        let TrunkGroupSaveResponse = await this.SaveTrunkGroup(itmToUpdate);
        let TrunkGroupDetails = TrunkGroupSaveResponse.TrunkGroupDetails;
        
        // if (TrunkGroupSaveResponse.IsSuccess) {
        //   itmToUpdate.IPTrunkConfigId = TrunkGroupSaveResponse.IPTrunkConfigId;
        // } else {
        //   itmToUpdate.ErrorMessage = 'An error occurred saving the new trunk group!';
        // }
        itmToUpdate.IPTrunkConfigId = TrunkGroupSaveResponse.IPTrunkConfigId;
        itmToUpdate.ErrorMessage = TrunkGroupSaveResponse.ErrorMessage;
        trunkGroupError = itmToUpdate.ErrorMessage;
        itmToUpdate.TrunkDescription = TrunkGroupDetails.TrunkDescription;
        itmToUpdate.CNAMDelivery = TrunkGroupDetails.CNAMDelivery;
        itmToUpdate.AllowInternational = TrunkGroupDetails.AllowInternational;
        itmToUpdate.ServiceStatus = TrunkGroupDetails.ServiceStatus;
        itmToUpdate.GroupPolicy = TrunkGroupDetails.GroupPolicy;
        itmToUpdate.TechPrefix = TrunkGroupDetails.TechPrefix;
        itmToUpdate.IPAddress1 = TrunkGroupDetails.IPAddress1;
        itmToUpdate.IPAddress2 = TrunkGroupDetails.IPAddress2;
        itmToUpdate.IPAddress3 = TrunkGroupDetails.IPAddress3;
        itmToUpdate.IPAddress4 = TrunkGroupDetails.IPAddress4;
        itmToUpdate.IPAddress5 = TrunkGroupDetails.IPAddress5;
        itmToUpdate.IPAddress6 = TrunkGroupDetails.IPAddress6;
        itmToUpdate.IPAddress7 = TrunkGroupDetails.IPAddress7;
        itmToUpdate.IPAddress8 = TrunkGroupDetails.IPAddress8
        itmToUpdate.IPAddress9 = TrunkGroupDetails.IPAddress9;
        itmToUpdate.IPAddress10 = TrunkGroupDetails.IPAddress10;
        itmToUpdate.IPAddress11 = TrunkGroupDetails.IPAddress11;
        itmToUpdate.IPAddress12 = TrunkGroupDetails.IPAddress12;

        this.props.UpdateTrunkGroups(TrunkGroupDetails, itmToUpdate.DetailId, method === 'PATCH' ? false : true);

        // if (newTrunkConfigId === 0) {
        //   ConfigItem.ErrorMessage = 'Trunk Group failed to save configurations!'
        // }
        // ConfigItem.IPTrunkConfigId = newTrunkGroupId;
      }

      itmToUpdate.HasChanges = false;
      itmToUpdate.ErrorMessage = helper.StringReplaceAll(responseBody.ErrorMessage, "</br>","",false);
      if (itmToUpdate.ErrorMessage !== '')
        itmToUpdate.ErrorMessage += '\n' + helper.StringReplaceAll(itmToUpdate.address.ErrorMessage, "</br>","",false);
      else
        itmToUpdate.ErrorMessage = helper.StringReplaceAll(itmToUpdate.address.ErrorMessage, "</br>","",false);
      
      configDetails[idxItem] = itmToUpdate;
      productData.configurationDetails = configDetails;
      this.setState({ configurationDetails: configDetails, productData: productData });

      return responseBody;
    } else {
      //TODO: Error display
    }
  }

  SaveTrunkGroup = async(itm) => {
    let apiPath = '';
    let method = '';
    let postBody = {};
    if (!itm.IPTrunkConfigId || itm.IPTrunkConfigId === 0 || itm.IPTrunkConfigId === '') {
      apiPath = macdHelper.apiAddServicesIPTrunkItem();
      method = 'POST';
    } else {
      apiPath = macdHelper.apiUpdateTrunkItem();
      method = 'PATCH';
    }
    
    postBody = {
      OrderId: this.state.productData.OrderId,
      LocationId: this.state.productData.MPOLocationId,
      DetailId: itm.DetailId,
      IPTrunkConfigId: itm.IPTrunkConfigId,
      IPTrunkGroupID: itm.IPTrunkGroupID,
      GroupPolicy: itm.GroupPolicy,
      Direction: '',
      IPAddress1: itm.IPAddress1,
      Description: itm.TrunkDescription,
      StartDate: itm.StartDate === '' ? null : itm.StartDate,
      CNAMDelivery: itm.CNAMDelivery,
      AllowInternational: itm.AllowInternational,
      TechPrefix: itm.TechPrefix,
      ServiceStatus: itm.ServiceStatus,
      IPAddress2: itm.IPAddress2,
      IPAddress3: itm.IPAddress3,
      IPAddress4: itm.IPAddress4,
      IPAddress5: itm.IPAddress5,
      IPAddress6: itm.IPAddress6,
      IPAddress7: itm.IPAddress7,
      IPAddress8: itm.IPAddress8,
      IPAddress9: itm.IPAddress9,
      IPAddress10: itm.IPAddress10,
      IPAddress11: itm.IPAddress11,
      IPAddress12: itm.IPAddress12
    };
    // let finalPost = {};
    
    let response = await apiCalls.post(apiPath, method, JSON.stringify(postBody)).then(data => data);
    if (response.ok) {
      let body = JSON.parse(response.message);
      //return new TrunkGroupID?
      return {
        IsSuccess: true,
        IPTrunkConfigId: body.IPTrunkConfigId,
        TrunkGroupDetails: itm,
        ErrorMessage: body.ErrorMessage
      }
    } else {
      return {
        IsSuccess: false,
        IPTrunkConfigId: 0,
        TrunkGroupDetails: itm,
        ErrorMessage: response.message
      };
    }
  }

  // RemoveTrunkGroupErrorMessage = () => {
  //   if (this.state.RemovalError === true) {
  //     return (
  //       <div>
  //         <span style={{color: 'red', fontSize: 'medium'}}>Error: </span>
  //         <span style={{fontSize: 'medium'}}>There are currently items associated with the new trunk groups that are going to be removed.  Please update trunk group assignments before removing new trunk groups. </span>
  //       </div>
  //     );
  //   }
  // }

  ErrorDisplay = () => {
    let htmlContents = null;
    let errors = [];
    if (this.state.ColumnConfig.ConfigType === 'NOCONFIG') {
      htmlContents =this.state.productData.configurationDetails.map((cd) => {
        if (cd.ErrorMessage && cd.ErrorMessage !== '')
          return macdHelper.ErrorMessageDisplayItem(cd.ErrorMessage);
      });
    }
    if (this.state.productData.ErrorMessage && this.state.productData.ErrorMessage != '') {
      if (htmlContents && htmlContents.length > 0) {
        htmlContents.push(macdHelper.ErrorMessageDisplayItem(this.state.productData.ErrorMessage));
        errors.push(this.state.productData.ErrorMessage);
      }
      else {
        htmlContents = macdHelper.ErrorMessageDisplayItem(this.state.productData.ErrorMessage)
        errors.push(this.state.productData.ErrorMessage);
      }
    }
    if (errors && errors.length > 0 && this.state.updating === false)
      return (
        <div>
          <h4 style={{color: 'red'}}>Configuration Errors: </h4>
          <ul>
            {htmlContents}
          </ul>
        </div>
      )
    else return null;
  }  

  gridContent = () => {
    if (this.state.loading) {
      return <DataLoadingSpinner className='load spinner' />
    } else if (this.state.closing || this.state.updating) {
      return <SaveSpinner Message={this.state.SaveMessage} />
    }
    else if (this.state.ColumnConfig.ConfigType === 'NOCONFIG') {
      return null;
    }
    else {
      let gridHTML = this.getGrid();
      return (
        <div>          
          {gridHTML}
        </div>

      )
    }
  }  

  getGrid = () => {
    let ColumnConfig = this.state.ColumnConfig;
    let dynamicColumn = (field, title, cell, width, editVal, sortable, classTitle = '') => <Column className={classTitle} key={'col' + field} editable={editVal} width={width} style={{paddingRight: '10px'}} field={field} title={title} cell={cell} sortable={sortable} />
    let columnArray = [];
    
    if (this.state.ValidationErrorMessages && this.state.ValidationErrorMessages.length > 0) {
      columnArray.push(dynamicColumn('ValidationMessage', ' ', ValidationCell, '50px', false, false));
    }
    columnArray.push(dynamicColumn('ItemNumber', 'Item #', GridCell, '60px', false, false));

    if (this.state.ReadOnly === true) {     
      //RC Fields
      if (ColumnConfig.ShowRCFields === true) {
        columnArray.push(dynamicColumn('RCState', 'RC State', WrappedCell, '120px', false, true));
        columnArray.push(dynamicColumn('RCName', 'Rate Center', WrappedCell, '200px', false, true));
      }
      if (ColumnConfig.ShowTN === true)
        columnArray.push(dynamicColumn('InventoryTN', 'Inventory TN', WrappedCell, '150px', false, true));

      if (ColumnConfig.ShowExtension === true)
        columnArray.push(dynamicColumn('Extension', 'Extension', WrappedCell, '100px', false, true));

      if (ColumnConfig.ShowLineFirst === true) {
        columnArray.push(dynamicColumn('LineFirstName', 'First Name', WrappedCell, '150px', false, true));
        columnArray.push(dynamicColumn('LineLastName', 'Last Name', WrappedCell, '150px', false, true));
      }
      if (ColumnConfig.ShowListingType === true)
        columnArray.push(dynamicColumn('ListingType', 'Listing Type', this.ListingTypeCell, '140px', false, true));
      if (ColumnConfig.ShowListingName === true) 
        columnArray.push(dynamicColumn('ListingName', 'Listing Name', WrappedCell, '150px', false, true));
      if (ColumnConfig.ShowOmitAddress === true)
        columnArray.push(dynamicColumn('OmitAddress', 'Omit Address', this.InputCheckField, '130px', false, false, 'ReadOnly'));
      if (ColumnConfig.ShowAccessCode === true) 
        columnArray.push(dynamicColumn('AccessCode', 'Access Code', WrappedCell, '150px', false, true));
      if (ColumnConfig.ShowLineEmail === true)
        columnArray.push(dynamicColumn('LineEmail', 'Line Email', WrappedCell, '150px', false, true));
      if (ColumnConfig.ShowTerminatingNumber === true)
        columnArray.push(dynamicColumn('TerminatingNumber', 'Terminating Number', WrappedCell, '150px', false, true));
      if (ColumnConfig.ShowCNAM === true)
        columnArray.push(dynamicColumn('CNAM', 'CNAM', WrappedCell, '150px', false, true));
      if (ColumnConfig.ShowTrunkGroupPicker === true)
        columnArray.push(dynamicColumn('AssociatedTrunkDescription', 'Trunk Group', WrappedCell, '170px', false, true));
      //IP Addresses for Trunk Group
      if (ColumnConfig.ShowTrunkDescription === true)
        columnArray.push(dynamicColumn('TrunkDescription', 'Description', WrappedCell, '150px', false, true));
      if (ColumnConfig.ShowStartDate === true)
        columnArray.push(dynamicColumn('StartDate', 'Start Date', WrappedCell, '150px', false, true));
      if (ColumnConfig.ShowCNAMDelivery === true)
        columnArray.push(dynamicColumn('CNAMDelivery', 'CNAM Delivery', this.InputCheckField, '150px', false, false, 'ReadOnly'));
      if (ColumnConfig.ShowInternational === true)
        columnArray.push(dynamicColumn('AllowInternational', 'Allow International', this.InputCheckField, '150px', false, false, 'ReadOnly'));
      if (ColumnConfig.ShowTechPrefix === true)
        columnArray.push(dynamicColumn('TechPrefix', 'Tech Prefix', WrappedCell, '150px', false, true));
      if (ColumnConfig.ShowGroupPolicy === true)
        columnArray.push(dynamicColumn('GroupPolicy', 'Group Policy', WrappedCell, '150px', false, true));
      if (ColumnConfig.ShowServiceStatus === true)
        columnArray.push(dynamicColumn('ServiceStatus', 'Status', WrappedCell, '150px', false, true));

      if (ColumnConfig.ShowIPAddresses === true) {
        for (var i = 1; i < 13; i++) {
          columnArray.push(dynamicColumn('IPAddress' + i.toString(), 'IP Address ' + i.toString(), this.WrappedCell, '120px', false, true));
        }
      }
      if (ColumnConfig.Show911 === true) {
        columnArray.push(dynamicColumn('E911Address', 'E911 Address', this.E911AddressCell, '200px', false, true));
      }
      if (ColumnConfig.ShowDescription === true)
        columnArray.push(dynamicColumn('Description', 'Description', WrappedCell, '150px', false, true));
      
    } else {
      //RC Fields
      if (ColumnConfig.ShowRCFields === true) {
        columnArray.push(dynamicColumn('RCState', 'RC State', this.RCStateCell, '120px', true, false));
        columnArray.push(dynamicColumn('RCName', 'Rate Center', this.RCNameCell, '200px', true, false));
      }
      if (ColumnConfig.ShowExtension === true)
        columnArray.push(dynamicColumn('Extension', 'Extension', this.InputNumericTextCell, '100px', true, false));
      if (ColumnConfig.ShowLineFirst === true) {
        columnArray.push(dynamicColumn('LineFirstName', 'First Name', this.InputTextCell, '150px', true, false));
        columnArray.push(dynamicColumn('LineLastName', 'Last Name', this.InputTextCell, '150px', true, false));
      }
      if (ColumnConfig.ShowListingType === true)
        columnArray.push(dynamicColumn('ListingType', 'Listing Type', this.ListingTypeCell, '140px', true, false));
      if (ColumnConfig.ShowListingName === true) 
        columnArray.push(dynamicColumn('ListingName', 'Listing Name', this.InputTextCell, '150px', true, false));
      if (ColumnConfig.ShowOmitAddress === true)
        columnArray.push(dynamicColumn('OmitAddress', 'Omit Address', this.InputCheckField, '130px', true, false));
      if (ColumnConfig.ShowAccessCode === true) 
        columnArray.push(dynamicColumn('AccessCode', 'Access Code', this.InputTextCell, '150px', true, false));
      if (ColumnConfig.ShowLineEmail === true)
        columnArray.push(dynamicColumn('LineEmail', 'Line Email', this.InputTextCell, '150px', true, false));
      if (ColumnConfig.ShowTerminatingNumber === true)
        columnArray.push(dynamicColumn('TerminatingNumber', 'Terminating Number', this.InputAutoCompleteField, '150px', true, false));
      if (ColumnConfig.ShowCNAM === true)
        columnArray.push(dynamicColumn('CNAM', 'CNAM', this.InputTextCell, '150px', true, false));
      if (ColumnConfig.ShowTrunkGroupPicker === true)
        columnArray.push(dynamicColumn('TrunkId', 'Trunk Group', this.TrunkGroupPickerCell, '170px', true, false));

      if (ColumnConfig.ShowTrunkDescription === true)
        columnArray.push(dynamicColumn('TrunkDescription', 'Description', this.InputTextCell, '150px', true, false));
      if (ColumnConfig.ShowStartDate === true)
        columnArray.push(dynamicColumn('StartDate', 'Start Date', this.InputDateField, '150px', true, false));
      if (ColumnConfig.ShowCNAMDelivery === true)
        columnArray.push(dynamicColumn('CNAMDelivery', 'CNAM Delivery', this.InputCheckField, '150px', true, false));
      if (ColumnConfig.ShowInternational === true)
        columnArray.push(dynamicColumn('AllowInternational', 'Allow International', this.InputCheckField, '150px', true, false));
      if (ColumnConfig.ShowTechPrefix === true)
        columnArray.push(dynamicColumn('TechPrefix', 'Tech Prefix', this.InputTextCell, '150px', true, false));
      if (ColumnConfig.ShowGroupPolicy === true)
        columnArray.push(dynamicColumn('GroupPolicy', 'Group Policy', this.GroupPolicyColumn, '150px', true, false));
      if (ColumnConfig.ShowServiceStatus === true)
        columnArray.push(dynamicColumn('ServiceStatus', 'Status', this.ServiceStatusColumn, '150px', true, false));
      //IP Addresses for Trunk Group  
      if (ColumnConfig.ShowIPAddresses === true) {
        for (var i = 1; i < 13; i++) {
          columnArray.push(dynamicColumn('IPAddress' + i.toString(), 'IP Address ' + i.toString(), this.InputTextCell, '120px', true, false));
        }
      }

      if (ColumnConfig.Show911 === true) {
        columnArray.push(dynamicColumn('E911Address', 'E911 Address', this.E911AddressCell, '200px', true, false));
      }
      if (ColumnConfig.ShowDescription === true)
        columnArray.push(dynamicColumn('Description', 'Description', this.InputTextCell, '150px', true, false));

    }
    //columnArray.push(dynamicColumn('ErrorMessage', 'Validation Message', WrappedCell, '200px', false, false ));
    return (
      <Grid id="gridConfigure"
        style={{ minHeight: '200px', maxHeight: '325px', height: '200px', fontSize: 'smaller' }}
        data={this.state.configurationDetails}
      >
        {columnArray}
        <Column field="spacer" title=" " sortable={false} filterable={false} />
      </Grid>
    );
  }
  
  handleDefaultLineFirstNameChange = (e) => {

    this.setState({ defaultLineFirstName: e.target.value });
  }
  handleDefaultLineLastNameChange = (e) => {

    this.setState({ defaultLineLastName: e.target.value });
  }

  handleDefaultLineCNAMChange = (e) => {

    this.setState({ defaultLineCNAM: e.target.value });
  }

  SetRCToDefaultByServiceAddress = () => {
    let rcState = this.state.serviceAddressRCState;
    let rcName = this.state.serviceAddressRCName;
    let rcNameOptions = this.state.serviceAddressRCNameOptions;
    this.setState({ defaultRCState: rcState, defaultRateCenterOptions: rcNameOptions, defaultRCName: rcName });
  }

  refreshDefaultRCName = (e) => {
    this.setState({ defaultRCState: e.target.value });
    let apiPath = macdHelper.apiGetRCNameByState(e.target.value);
    apiCalls.fetchData(apiPath).then((data) => {
      if (data !== null) {
        let rateCenterArray = data.map((rc, idx) => {
          return macdHelper.rcNameOption(rc);
        });
        this.setState({ defaultRateCenterOptions: rateCenterArray });
      } else {
        this.setState({ defaultRateCenterOptions: [], defaultRCName: '' });
      }
    });

  }

  ApplyDefaultLineNames = () => {
    let configDetails = this.state.configurationDetails;
    let firstName = this.state.defaultLineFirstName;
    let lastName = this.state.defaultLineLastName;
    configDetails.map((itm, idx) => {
      itm.LineFirstName = firstName;
      itm.LineLastName = lastName;
    });
    this.setState({ configurationDetails: configDetails });
  }

  ApplyDefaultCNAM = () => {
    let configDetails = this.state.configurationDetails;
    let cnam = this.state.defaultLineCNAM;
    configDetails.map((itm, idx) => {
      itm.CNAM = cnam;
    });
    this.setState({ configurationDetails: configDetails });
  }

  ApplyDefaultRCValues = () => {
    let configDetails = this.state.configurationDetails;
    let rcName = this.state.defaultRCName;
    let rcState = this.state.defaultRCState;
    let rcNameOptions = this.state.defaultRateCenterOptions;
    configDetails.map((itm, idx) => {
      itm.rcNameOptions = rcNameOptions
      itm.RCName = rcName;
      itm.RCState = rcState;
    });
    this.setState({ configurationDetails: configDetails });
  }

  ResetRCValues = () => {
    let configDetails = this.state.configurationDetails;
    let rcName = this.state.serviceAddressRCName;
    let rcState = this.state.serviceAddressRCState;
    let rcNameOptions = this.state.serviceAddressRCName;
    configDetails.map((itm, idx) => {
      itm.rcNameOptions = rcNameOptions
      itm.RCName = rcName;
      itm.rcState = rcState;
    });
    this.setState({ configurationDetails: configDetails, defaultRCName: rcName, defaultRCState: rcState, defaultRateCenterOptions: rcNameOptions });
  }

  displayValidationModal = (message) => {
    this.setState({ ValidationMessage: message, DisplayValidationModal: true });
  }
  closeValidationModal = (e) => {
    this.setState({ ValidationMessage: "", DisplayValidationModal: false });
  }

  delayedUpdate = async(newVal) => {
    if (newVal !== '') {
      newVal = parseInt(newVal);
      if (isNaN(newVal) || newVal < 1 || newVal == this.state.quantity) {
        let quantity = this.state.quantity;
        this.setState({tmpQuantity: quantity});
        return;//do nothing if value was not different and proper
      }
      await this.setState({ tmpQuantity: newVal, waitingOnTempUpdate: true });
      
      if (this.state.ColumnConfig.ConfigType !== 'NOCONFIG') { 
        if (this.timeout)
          clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
            this.handleQuantityUpdate();
          }, 2000
        );
      } else {
        this.handleQuantityUpdate();
      }
    } else {
      this.setState({tmpQuantity: ''});
    }
  }

  handleRemoveItem = () => {    
    let msg = 'Are you sure you wish to remove this item from your order?';
    let title = 'Remove Item from Order?';
    this.setState({ isConfirmationDialogOpen: true, ConfirmationTitle: title, ConfirmationMessage: msg });
  }

  ConfirmationYesAction = async(e) => {
    // if (this.state.ColumnConfig.ConfigType === 'IPTCONFIG') {
    //   let trunks = this.state.IPTrunkGroups.slice().filter(resp => resp.IsNew === false);
    //   if (!this.props.CheckTrunkGroupAssociations(trunks)) {
    //     this.setState({RemovalError: true, isConfirmationDialogOpen: false});
    //     return;
    //   }
    // }
    await this.props.RemoveItemFromOrder(this.state.productData.LineItemId);
    this.setState({isConfirmationDialogOpen: false, showModal: false});
  }

  closeConfirmationDialog = (e) => {
    this.setState({isConfirmationDialogOpen: false});
  }

  showActionButtons = () => {
    if (this.state.updating === false)
      return (
        <div className="btns" style={{ visibility: this.state.loading || this.state.ReadOnly === true || (this.state.ColumnConfig && this.state.ColumnConfig.ConfigType === 'NOCONFIG') ? "hidden" : "visible" }}>
          <Button className="btn" click={(e) => { this.postProductUpdate(this.state.productData, this.state.configurationDetails, this.props.OrderItemIdx, true) }}>Save</Button>
          <Button className="btn" click={(e) => { this.postProductUpdate(this.state.productData, this.state.configurationDetails, this.props.OrderItemIdx) }}>Cancel</Button>
        </div>
      )
    else return null;
  }

  displayTNDetails = () => {
    if (this.state.ColumnConfig.ShowLineFirst || this.state.ColumnConfig.ShowLineLast || this.state.ColumnConfig.ShowCNAM)
      return (
        <div>
          <p>
            <b><u>Note:</u></b> <br />
            Line First Name and Line Last Name will be the on-net Caller ID name seen for internal calls.
            Caller ID [CNAM] is the off-net Caller ID that the Called Party will see for external, off-net outbound calls.
            Leaving Caller ID blank will display the name as Unavailable, and only the telephone number will show.
          </p>
        </div>
      );
    else return null;
  }

  handleE911AddressUpdate = (isSuccess, addressId, addressEntry, errorMsg) => {
    addressEntry.ErrorMessage = errorMsg;
    if (isSuccess) {
      this.saveE911Address(addressEntry, addressId, errorMsg);
      this.closeE911Modal();
    } else {
      this.saveE911Address(addressEntry, addressId, errorMsg);
      this.setState({e911AddressItem: addressEntry});
    }
  }

  showE911Modal = (selectedItem) => {
    let selectedIndex = _.indexOf(this.state.configurationDetails, selectedItem);
    this.setState({showE911Modal: true, e911Index: selectedIndex, e911AddressItem: selectedItem.address});
  }

  saveE911Address = (updatedAddress, addressId = 0, errorMsg = '') => {
    let addressItem = this.state.e911AddressItem;
    let existingItem = this.state.configurationDetails[this.state.e911Index];

    //need to save the line item detail if an address has now been tied to the detail item
    let bSave = addressItem.AddressId !== addressId ? true : false;
    
    let newValidationErrorMessages = this.state.ValidationErrorMessages.slice();
    if (existingItem)
    {
      let idx = _.indexOf(this.state.configurationDetails, existingItem);
      let newListings = this.state.configurationDetails.slice();
      if (addressItem.ErrorMessage !== '')
      {
        let idxMessage = _.indexOf(newValidationErrorMessages, addressItem.ErrorMessage);
        newValidationErrorMessages.splice(idxMessage,1);
      }
      updatedAddress.AddressId = addressId;
      if (errorMsg !== '')
        newValidationErrorMessages.push(errorMsg);
      //update displayed grid
      newListings[idx].address = updatedAddress;
      newListings[idx].Temp911AddressId = updatedAddress.AddressId;
      newListings[idx].ErrorMessage = errorMsg;

      if (bSave)
        this.SaveLineItemDetail(newListings[idx]);
      this.setState({configurationDetails: newListings, ValidationErrorMessages: newValidationErrorMessages});
    }
    
  }

  closeE911Modal = () => {
    this.setState({showE911Modal: false, e911AddressItem: {}, e911Index: -1});
  }

  render() {
    let labelStyle = {
      'fontWeight': 'bold',
      'fontSize': 'small',
      'width': '100px'
    }
    let quantityStyle = {
      'fontWeight': 'bold',
      'fontSize': 'small',
      'width': '50px'
    }
    let button = '';
    if (this.state.productData.isConfigured === true) {
      button = (<a href="#" onClick={this.handleShow} className="configured btn" data-toggle="modal" data-target="#configureProduct"><svg width="12" height="12" viewBox="0 0 24 24"><path d="m462 110l-55-44c-2-1-4-2-6-2-3 0-5 1-7 2l-199 257c0 0-79-75-81-78-2-2-5-6-10-6-4 0-6 4-8 6-2 2-30 31-44 46-1 1-1 1-2 2-1 1-2 3-2 5 0 3 1 4 2 6l3 3c0 0 139 134 142 136 2 2 5 5 9 5 4 0 7-4 9-6l249-320c1-2 2-4 2-6 0-2-1-5-2-6z" transform="scale(0.046875 0.046875)"></path></svg> Configured</a>);
    } else {
      button = (<Button click={this.handleShow}>Configure</Button>);
    }

    return (
      <span>
        {button}
        <Modal dialogClassName={this.state.className} style={{ fontSize: 'small' }} show={this.state.showModal} onHide={(e) => { if (this.state.ReadOnly === false) this.postProductUpdate(this.state.productData, this.state.configurationDetails, this.props.OrderItemIdx); else this.completeModalClose(this.state.productData, this.state.configurationDetails, -1) } }>
          <Modal.Header closeButton>
            <Modal.Title>{this.state.ProductName} Configuration</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="">
              <div id="ProductConfigGrid" className="form-group">
                <span style={labelStyle}>Quantity: </span>
                <input disabled={this.state.ReadOnly === true ? true : false} type="text" id="txtQuantity" style={quantityStyle} value={this.state.tmpQuantity} onChange={(e) => { this.delayedUpdate(e.target.value)}} /> {/* this.setState({ tmpQuantity: e.target.value }); }} /> */}
                <a href="#remove" onClick={() => { this.props.RemoveItemFromOrder(this.state.productData.LineItemId) }} className="remove" style={{marginLeft:'10px', display:this.state.ReadOnly && this.state.ReadOnly === true ? 'none' : 'inline'}}>Remove</a>
                <hr />
                {/*//TBD -> would need a way to save all items at once after applying updates. not in current specs either */}
                <div style={{ display: "none" }}>
                  <h3>Default Rate Center</h3>
                  <table id="DefaultRateCenterSettings" className="DefaultSettings">
                    <tbody>
                      <tr>
                        <td className="DefaultRCLabelCol">RC State:</td>
                        <td className="DefaultRCValueCol">
                          <select style={{ width: "100%" }} value={this.state.defaultRCState} onChange={(e) => { this.refreshDefaultRCName(e); }}>
                            <option value={""}>Select RC State</option>
                            {this.state.rcStateOptions}
                          </select>
                        </td>
                        <td><a href="#" onClick={(e) => { this.SetRCToDefaultByServiceAddress(); }}>Set from Service Address</a></td>
                      </tr>
                      <tr>
                        <td className="DefaultRCLabelCol">RC Name/Inventory:</td>
                        <td className="DefaultRCValueCol">
                          <select style={{ width: "100%" }} value={this.state.defaultRCName} onChange={(e) => { this.setState({ defaultRCName: e.target.value }); }}>
                            <option value={""} >Select Rate Center</option>
                            {this.state.defaultRateCenterOptions}
                          </select>
                        </td>
                        <td><a href="#" onClick={(e) => { this.NPANXXLookup(e); }}>NPANXX Lookup</a></td>
                      </tr>
                      <tr>
                        <td className="DefaultRCLabelCol">&nbsp;</td>
                        <td colSpan="2">
                          <Button click={(evt) => { this.ApplyDefaultRCValues(); }}>Apply to All</Button>
                          <Button click={(evt) => { this.ClearRCValues(); }}>Clear</Button>
                          <Button click={(evt) => { this.ResetRCValues(); }}>Reset</Button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                {/* <hr /> */}
                {this.state.TNDefaultDiv}
                {/* <hr style={{visibility: this.state.ColumnConfig.ShowLineFirst || this.state.ColumnConfig.ShowLineLast || this.state.ColumnConfig.ShowCNAM ? "visible" : "hidden"}} /> */}
                {/* {this.RemoveTrunkGroupErrorMessage()} */}
                {this.ErrorDisplay()}
                {this.gridContent()}
              </div>
              {this.displayTNDetails()}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <hr />
            {this.showActionButtons()}
            <div className="btns" style={{ visibility: this.state.loading || this.state.ReadOnly !== true ? "hidden" : "visible" }}>
              <Button className="btn" click={() => { this.completeModalClose(this.state.productData, this.state.configurationDetails, -1, false); }}>Close</Button>
            </div>
          </Modal.Footer>
        </Modal>
        <Modal dialogClassName="confirm-add-services-modals" show={this.state.isConfirmationDialogOpen} onHide={this.closeConfirmationDialog}>
          <Modal.Header closeButton>
            <Modal.Title>{this.state.ConfirmationTitle}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div><p><span style={{ fontSize: "medium" }}>{this.state.ConfirmationMessage}</span></p></div>
          </Modal.Body>
          <Modal.Footer>
            <Button click={(evt) => { this.ConfirmationYesAction() }}>Yes</Button>
            <Button click={(evt) => { this.closeConfirmationDialog() }}>No</Button>  
          </Modal.Footer>
        </Modal>
        <Modal dialogClassName='well macd-e911-address' show={this.state.showE911Modal} onHide={this.closeE911Modal}>
          <Modal.Header>
            <Modal.Title>Update E911 Address</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <table>
              <AddressFormE911 parentList={this.state.configurationDetails} index={this.state.e911Index} address={this.state.e911AddressItem} validateInComponent={true} 
                UpdateEntry={this.handleE911AddressUpdate} ToggleEditList={this.closeE911Modal} validateTN={false} ReadOnly={this.state.ReadOnly} 
                CustomerId={this.state.CustomerId} apiPath={`admin/macd/AddServicesE911?customerId=${this.state.CustomerId}`}
              />
            </table>
          </Modal.Body>
        </Modal>
      </span>
    );
  }
};

export default ConfigureProductModal;