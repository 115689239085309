import React, { Component } from 'react';
import { DataLoadingSpinner } from '../../components/spinners';
import { Modal } from 'react-bootstrap';
import { sendErrorMessage, convertToLocal } from './components/utility';
import * as apiCalls from '../../requests/api_calls';

export default class MsTeamsSetupDomain extends Component {
  constructor(props) {
    super(props);

    this.state = {
      Enterprise: this.props.Enterprise,
      loading: false,
      Submission: false,
      ErrorMessage: sessionStorage.MsTeamsStatusDscription ? sessionStorage.MsTeamsStatusDscription : "",
      MSTeamsConfig: this.props.MSTeamsConfig,
      Trunk: this.props.Trunk,
      PrimaryGateWay: this.props.PrimaryGateWay,
      SecondaryGateWay: this.props.SecondaryGateWay,
      routingType: this.props.routingType,
    };
  }

  handleInputChange = async (e, key) => {
    let { value } = e.target;
    let MSTeamsConfig = { ...this.state.MSTeamsConfig };
    MSTeamsConfig[key] = value;
    await this.setState({ MSTeamsConfig });
    //console.log("MSTeamsConfig", MSTeamsConfig); // inserted AD Information
  }

  inputDataField = (val, key, onChangeFunc, readOnly = false, type = 'text') => {
    return (
      <input
        style={{
          width: '50%',
          display: 'inline-block',
        }}
        type={type}
        name={key}
        value={val}
        className="form-control"
        onChange={(e) => onChangeFunc(e, key)}
        disabled={readOnly}
      />
    )
  }

  dataRow = (label, field) => {
    return (
      <div className="form-group-wrapper"
        style={{ margin: '1%', }}
      >
        <label
          style={{
            width: '50%',
            display: 'inline-block',
          }}
        >{label} </label>
        {field}
      </div >
    )
  }

  cardContent = () => {
    // SF TICKET 01056065 ***
    let { MSTeamsConfig, Enterprise, PrimaryGateWay, SecondaryGateWay, ErrorMessage, routingType } = this.state;
    return (
      <div className="card-content"
        style={{
          paddingTop: "20px"
        }}>
        <div className="card-actions">
        </div>
        <p><b>In this Deployment Step:</b></p>
        <p>The SBC Primary and Secondary FQDN domains will be added to the office 365/MS Teams Tenant for Derived Routing.</p>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <div className="flex-row"
            style={{
              width: '60%',
            }}
          >
            {/* {this.dataRow("Primary FQDN:", this.inputDataField(PrimaryGateWay, "PstnPrimaryGateway", this.handleInputChange, true))}
            {this.dataRow("Secondary FQDN:", this.inputDataField(SecondaryGateWay, "PstnSecondaryGateway", this.handleInputChange, true))} */}
            {/* The following domains will be added to Office 365 to support Direct Routing
          Domain name 1:  <PSTN Primary Gateway>
          Domain name 2:  <PSTN Secondary Gateway>
        Show Button Create Domain (only SHOW THIS BUTTON WHEN MICROSOFT 365 CONNECTION IS SET UP) */}
            {this.createDomainBtn()}
            <button
              className="a btn"
              onClick={this.saveAndContinue}
            >Continue</button>
            {this.wizardMsg()}
            {ErrorMessage !== "" ? <small style={{
              color: 'red',
              marginLeft: '10px'
            }}>Error: {ErrorMessage}</small> : null}
          </div>
          <div className="flex-row"
            style={{
              marginLeft: '2%',
              width: '40%',
              border: '1px solid grey'
            }}
          >
            <div style={{ textAlign: "center", paddingLeft: '10px' }}>
              <span style={{ textDecoration: "underline" }}>Support Notes</span>
              <p style={{ textAlign: 'left' }}>In this step, the Deployment Wizard will set up the SBC Domains in the Teams Tenant for Derived Routing.
                  <br /><br />Click on the Setup Domain Routing button to add the information to the Microsoft 365 Tenant. Once the green Doamin Created status notification appears (or after deployment is completed) the Admin may elect to click on the View Domain Information for the Derived Routing to review the setup information.
                  <br /><br />When completed and verified, click Continue button to move on to the deployment step.
              </p>
            </div>
          </div>
        </div>
      </div >
    )
  }

  wizardMsg = () => {
    let { IsM365DomainCreated, DomainCreatedOn } = this.state.MSTeamsConfig;
    if (IsM365DomainCreated) {
      return <div><br /><small style={{
        color: 'green',
        marginLeft: '10px'
      }}>Domain Created: {convertToLocal(DomainCreatedOn).toLocaleString()}</small></div>
    } else return <br />
  }

  saveAndContinue = () => {
    //this.setState({ completed: true });
    this.props.Proceed("dns");
  }

  createDomainBtn = () => {
    let { DomainSetupResponse, IsM365DomainCreated, Password, UserName } = this.state.MSTeamsConfig;
    if (!IsM365DomainCreated) {
      if(UserName === "" || UserName === null || Password === "" || Password === null){
        return <button className="a btn"
        disabled ={true} 
        onClick={() => this.setState({ showConfirmationModal: true })}
      >
        {"Create Domain"}
      </button>
      }
      else{
        return <button className="a btn"
        onClick={() => this.setState({ showConfirmationModal: true })}
      >
        {"Create Domain"}
      </button>
      }
    } else {
      return <button className="a btn"
        onClick={() => this.setState({ showDomainInfo: true })}
      >
        {`View Domain Information for ${this.state.routingType}`}
      </button>
    }
  }

  createAzureDomain = async () => {
    let path = 'admin/CreateMSTeamsDomain';
    let { MSTeamsConfig, Trunk, Enterprise } = this.state;
    let dto = {
      userName: sessionStorage.username,//"prodnewsubsite@momentumtelecom.com",
      enterpriseId: Enterprise,
      trunkName: Trunk.TrunkName,
    };
    console.log("DTO", dto);
    let response = await apiCalls.post(path, "POST", JSON.stringify(dto)).then(data => data).catch(err => console.error(err));
    if (response) {
      sendErrorMessage(response.message);
      this.props.RefreshData();
    }
  }

  formatDsRes = () => {
    let { DomainSetupResponse } = this.state.MSTeamsConfig;
    if (DomainSetupResponse) {
      let parsedResponse = JSON.parse(DomainSetupResponse);
      if (parsedResponse.Status && parsedResponse.Status === "Error") {
        return parsedResponse.StatusDscription;
      } else {
        return parsedResponse.map((itm, idx) => {
          return (
            <div key={idx}>
              <div>Name: {itm.Name}</div>
              <div>Is Verified: {itm.IsVerified ? "Yes" : "No"}</div>
              <div>Is Default: {itm.IsDefault ? "Yes" : "No"}</div>
              <br />
            </div>
          )
        })
      }
    } else return "No domain setup response available";
  }

  render() {
    return (
      <div>
        <Modal show={this.state.showConfirmationModal} onHide={this.handleClose} backdrop='static'>
          <Modal.Header>
            <Modal.Title>Setup Azure Domains?</Modal.Title>
          </Modal.Header>
          <Modal.Footer>
            <hr />
            <div className="btns">
              <span>
                <button type="button" className="btn" onClick={this.createAzureDomain}>Yes</button>
                <button className="btn" onClick={() => this.setState({ showConfirmationModal: false })}>No</button>
              </span>
            </div>
          </Modal.Footer>
        </Modal>
        <Modal show={this.state.showDomainInfo} onHide={this.handleClose} backdrop='static'>
          <Modal.Header>
            <Modal.Title>Domain Information</Modal.Title>
          </Modal.Header>
          <div>{this.formatDsRes()}</div>
          {/* <div>{this.state.MSTeamsConfig.DomainSetupResponse}</div> */}
          <Modal.Footer>
            <hr />
            <div className="btns">
              <span>
                <button className="btn" onClick={() => this.setState({ showDomainInfo: false })}>Close</button>
              </span>
            </div>
          </Modal.Footer>
        </Modal>
        {this.cardContent()}
      </div>
    );
  };
}