import React, { Component } from 'react';
import { DataLoadingSpinner } from './spinners';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { orderBy } from '@progress/kendo-data-query';
import * as helper from '../scripts/helper';

export default class SansayCallLogsTab extends Component {
  constructor(props) {
    super(props);

    this.state = {
      LocationAccountNumber: '',
      SansayCallLogs: props.SansayLogs,
      loading: props.isLoading,
      logs: [],
      Sort: [],
    }
  };


  componentDidMount() {
  }

  componentWillReceiveProps(props) {
    if (props.SansayLogs != this.state.SansayCallLogs) {
      this.setState({ SansayCallLogs: props.SansayLogs });
    }
    if (props.isLoading != this.state.loading) {
      this.setState({ loading: props.isLoading });
    }
  }


  searchStatus = () => {
    if (this.state.loading) {
      return <DataLoadingSpinner className={'load spinner relative'} />
    } else if (this.state.SansayCallLogs.length === 0) {
      return (
        <h5 className='text-center' style={{ padding: '30px', textAlign: 'center' }} >
          No results for the filters selected.
        </h5>
      )
    } else return null;
  }

  sortListings = (sort, logs = null) => {
    let returnObj = [];
    if (this.state.SansayCallLogs) {
      if (logs == null) logs = this.state.SansayCallLogs.slice();
      let sortedSet = orderBy(logs, sort);
      returnObj = sortedSet;
      if (sort.length) {
        returnObj = helper.blanksAfterZ(sortedSet, sort);
      }
    }
    return returnObj;
  }

  sortChange = async (event) => {
    await this.setState({
      SansayCallLogs: this.sortListings(event.sort),
      Sort: event.sort
    });
  }

  gridContent = () => {
      if (this.state.loading || this.state.SansayCallLogs.length === 0) {
        return (
          <div style={{
            display: 'block',
            height: '100px',
            overflow: 'hidden',
            width: '100%',
          }}>{this.searchStatus()}</div>
        )
      } else {
        let colWidth = this.props.isWidget === true ? '30px' : '150px';
        let smallColWidth = this.props.isWidget === true ? '30px' : '100px'
        return (
          <Grid
            style={{
              width: '100%',
            }}
            data={this.state.SansayCallLogs}
            scrollable='scrollable'
            sortable={{ allowUnsort: true, mode: 'single' }}
            sort={this.state.Sort}
            onSortChange={this.sortChange}
          >
            <Column width={smallColWidth} field='Direction' title='Direction' />
            <Column width={smallColWidth} field='TrunkID' title='TrunkID' />
            <Column width={colWidth} format="{0:M/d/yyyy hh:mm:ss a}" field='Start_Time' title='Start Time' />
            <Column width={colWidth} format="{0:M/d/yyyy hh:mm:ss a}" field='Release_Time' title='Release Time' />
            <Column width={colWidth} field='Length' title='Duration (HH:mm:ss)' />
            <Column width={colWidth} field='OriginationSource' title='From' />
            <Column width={colWidth} field='OriginationDestination' title='To' />
            <Column width={colWidth} field='OriginationRemoteIP' title='From IP' />
            <Column width={colWidth} field='OriginationMOS' title='OriginationMOS' />
            <Column width={colWidth} field='TerminationMOS' title='TerminationMOS' />
            <Column width={colWidth} field='Category' title='Category' />
            <Column width={colWidth} field='PageCount' title='Pages' />
            <Column title=' ' field='filler' sortable={false} />
          </Grid>
        )
      }
  }

  render() {
    return (
      <div className='card-content' >
        {this.gridContent()}
      </div >
    );
  }
};