export const sendErrorMessage = (apiResponse) => {
  let { Status, StatusDscription } = JSON.parse(JSON.parse(apiResponse));
  if (Status && StatusDscription !== undefined) {
    if (Status === "Error") {
      sessionStorage.setItem("MsTeamsStatusDscription", StatusDscription);
    } else {
      sessionStorage.removeItem("MsTeamsStatusDscription");
    }
  }
};

export const convertToLocal = (dateTime) =>{
  var convertedLocalTime = new Date(dateTime);
  var hourOffset = convertedLocalTime.getTimezoneOffset()/60;
  convertedLocalTime.setHours(convertedLocalTime.getHours() + hourOffset);
  return convertedLocalTime;
  };