import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import LongSwitch from './../components/long_switch';
import { fetchData, post} from './../requests/api_calls';
import format from 'string-format';
import * as config from './../requests/config';
import { DataLoadingSpinner } from './../components/spinners';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'; // ES6

class BasicFeatures extends Component {

	constructor() {
		super();
		this.state = this.initialState();

		this.onChangeBasic = this.onChangeBasic.bind(this);
	}

	initialState =()=>{
		return {
			loading: true,
			anynomous_call_rejection: {
				selected: false,
				enable: false,
				loading: true,
				show_success: false,
				show_error: false,
				details:{
					api:  "CallRejection",
					assigned_service: "AnonymousCallRejection",
					label: "Anonymous Call Rejection",
					info: "Reject incoming callers with directory or identifying Caller ID information displaying 'Anonymous'."
				}
			},
			bargein_exempt: {
				selected: false,
				enable: false,
				loading: true,
				show_success: false,
				show_error: false,
				details:{
					api:  "BargeIn",
					assigned_service: "BargeinExempt",
					label: "Barge-In Exempt",
					info: "Barge-in Exempt allows you to block barge-in attempts from other users with Directed Call Pickup with Barge-in"
				}
			},
			caller_id: {
				selected: false,
				enable: false,
				loading: true,
				show_success: false,
				show_error: false,
				details:{
					api:  "CallerId",
					assigned_service: "ExternalCallingLineIDDelivery",
					label: "Caller ID",
					info: "Identify your caller before picking up the telephone."
				}
			},
			call_waiting: {
				selected: false,
				enable: false,
				loading: true,
				show_success: false,
				show_error: false,
				details:{
					api:  "CallWaiting",
					assigned_service: "CallWaiting",
					label: "Call Waiting",
					info: "Switch to a new incoming call while you are already on another call."
				}
			},
			connected_line_id_restrictions: {
				selected: false,
				enable: false,
				loading: true,
				show_success: false,
				show_error: false,
				details:{
					api:  "LineIdRestriction",
					assigned_service: "ConnectedLineIdentificationRestriction",
					label: "Connected Line ID Restrictions",
					info: "Restricts your number from showing as the “calling” number when you are making a transfer to someone internally."
				}
			},
			do_not_disturb: {
				selected: false,
				enable: false,
				loading: true,
				show_success: false,
				show_error: false,
				details:{
					api:  "DontDisturb",
					assigned_service: "DoNotDisturb",
					label: "Do Not Disturb",
					info: "Callers will receive a message stating you are busy and be sent to your Voicemail."
				}
			},
			music_on_hold: {
				selected: false,
				enable: false,
				loading: true,
				show_success: false,
				show_error: false,
				details:{
					api:  "MusicOnHold",
					assigned_service: "MusicOnHoldUser",
					label: "Music On Hold",
					info: "Select if you want the party you are speaking with to hear hold music when placed on hold."
				}
			},
			outbound_caller_id_block: {
				selected: false,
				enable: false,
				loading: true,
				show_success: false,
				show_error: false,
				details:{
					api:  "OutboundCallerIdBlock",
					assigned_service: "CallingLineIDDeliveryBlocking",
					label: "Outbound Caller ID Block",
					info: "Block your Caller ID information from being displayed when you call someone."
				}
			}
		}
	}
	componentDidMount() {
		this.fetchAssignedServices();
	}

  componentDidUpdate(prevProps){
    if(
      this.props.ServiceNumber !== prevProps.ServiceNumber ||
      this.props.PBXUserID !== prevProps.PBXUserID){
        this.fetchAssignedServices();
    }
	}
	
	fetchAssignedServices=()=>{
		this.setState(this.initialState());
		let basicData = { ServiceNumber:this.props.ServiceNumber, PBXUserID:this.props.PBXUserID };
		let apiUrl = "";
		if(this.props.ServiceNumber && this.props.PBXUserID)
			apiUrl = format('usr/AssignedServices?ServiceNumber={ServiceNumber}&PBXUserID={PBXUserID}',basicData);
		else if(this.props.ServiceNumber)
			apiUrl = format('usr/AssignedServices?ServiceNumber={ServiceNumber}',basicData);
		else if(this.props.PBXUserID)
			apiUrl = format('usr/AssignedServices?PBXUserID={PBXUserID}',basicData);

    fetchData(apiUrl).then((data) => {
      if (data) {
				this.enableBasicFeature(data, this.state.anynomous_call_rejection)
				this.enableBasicFeature(data, this.state.caller_id)
				this.enableBasicFeature(data, this.state.call_waiting)
				this.enableBasicFeature(data, this.state.connected_line_id_restrictions)
				this.enableBasicFeature(data, this.state.do_not_disturb)
				this.enableBasicFeature(data, this.state.music_on_hold)
				this.enableBasicFeature(data, this.state.outbound_caller_id_block)
				this.enableBasicFeature(data, this.state.bargein_exempt)
				this.setState({...{loading:false }})
      }
      else{
				this.setDisableAll();
      }
    }).catch((message)=>{
			this.setDisableAll();
    });
	}

	setLoadingToFalse=()=>{
		//check if this mount
		if(!this.refs.mainDiv) return;

		let state = this.state;
		state.loading = false;
		state.anynomous_call_rejection.loading = false;
		state.bargein_exempt.loading = false;
		state.call_waiting.loading = false;
		state.caller_id.loading = false;
		state.connected_line_id_restrictions.loading = false;
		state.do_not_disturb.loading = false;
		state.music_on_hold.loading = false;
		state.outbound_caller_id_block.loading = false;
		
		this.setState(state);
	}

	setDisableAll=()=>{
		//check if this mount
		if(!this.refs.mainDiv) return;

		let state = this.state;
		state.loading = false;
		state.anynomous_call_rejection.enable = false;
		state.bargein_exempt.enable = false;
		state.call_waiting.enable = false;
		state.caller_id.enable = false;
		state.connected_line_id_restrictions.enable = false;
		state.do_not_disturb.enable = false;
		state.music_on_hold.enable = false;
		state.outbound_caller_id_block.enable = false;

		state.anynomous_call_rejection.loading = false;
		state.bargein_exempt.loading = false;
		state.call_waiting.loading = false;
		state.caller_id.loading = false;
		state.connected_line_id_restrictions.loading = false;
		state.do_not_disturb.loading = false;
		state.music_on_hold.loading = false;
		state.outbound_caller_id_block.loading = false;
		
		this.setState(state);
	}

	enableBasicFeature =(data, feature)=>{
		let service = this.getService(data, feature.details.assigned_service);
		feature.loading = false;
		if(service){
			feature.enable = true;
			feature.selected = service.IsActive;
			feature.loading = false;
		}
		this.setBasicFeature(feature);
	}

	getService=(data, serviceName)=>{
		let filterData = data.filter((item)=>{ return item.ServiceName === serviceName && item.IsActiveSpecified})
		if(filterData.length > 0){
			return filterData[0];
		}
		return null;
	}

  fetchBasicFeatures = (state, errorMsg) => {
		// 	Method:"CallRejection", 
		// 	ServiceNumber:"", 
		// 	PBXUserID:"" 
		let basicData = {Method:state.details.api, ServiceNumber:this.props.ServiceNumber, PBXUserID:this.props.PBXUserID };
		let apiUrl = "";
		if(this.props.ServiceNumber && this.props.PBXUserID)
			apiUrl = format('usr/{Method}?ServiceNumber={ServiceNumber}&PBXUserID={PBXUserID}',basicData);
		else if(this.props.ServiceNumber)
			apiUrl = format('usr/{Method}?ServiceNumber={ServiceNumber}',basicData);
		else if(this.props.PBXUserID)
			apiUrl = format('usr/{Method}?PBXUserID={PBXUserID}',basicData);
		
    fetchData(apiUrl).then((data) => {
			state.loading = false;
      if (data) {
				state.selected = data.SettingValue;
      }
      else{
				state.show_error = true;
			}
			this.setBasicFeature(state);
    }).catch((message)=>{
			state.loading = false;
			state.show_error = true;
			this.setBasicFeature(state);
    });
  }

  onChangeBasic =(state, event)=>{
		let checked = event.target.checked;
		let bodyJson = {
			ServiceNumber: this.props.ServiceNumber,
			PBXUserID: this.props.PBXUserID,
			SettingValue: checked
		};
		let body = JSON.stringify(bodyJson);
		let headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + config.storedAccessToken(),
      'X-Policy': config.defaultXpolicy
		};
		state.selected = checked;
		state.loading = true;
		state.show_success = false;
		state.show_error = false;
		this.setToFalseAllSuccessAndError();
		this.setBasicFeature(state);

		post("usr/" + state.details.api, "POST", body, headers)
		.then(response =>{
			if(response.ok)
			{
				state.selected = checked;
				state.loading = false;
				state.show_success = true;
				this.setBasicFeature(state);
			}
			else
			{
				state.selected = checked;
				state.loading = false;
				state.show_error = true;
				this.setBasicFeature(state);
			}
		})
		.catch((message)=>{
			state.selected = checked;
				state.loading = false;
				state.show_error = true;
				this.setBasicFeature(state);
		});
	}
	
  setBasicFeature = (newState) => {
		//check if this mount
		if(!this.refs.mainDiv) return;
		// BServiceID:2
		// IsActive:true
		// IsActiveSpecified:true
		// ServiceName:"AnonymousCallRejection"
    switch(newState.details.api){
			case "CallRejection":
				this.setState({...{anynomous_call_rejection: newState}});
				break;
			case "CallerId": 
				this.setState({...{caller_id: newState}});
				break;
			case "CallWaiting":
				this.setState({...{call_waiting: newState}});
				break;
			case "LineIdRestriction":
				this.setState({...{connected_line_id_restrictions: newState}});
				break;
			case "DontDisturb":
				this.setState({...{do_not_disturb: newState}});
				break;
			case "MusicOnHold":
				this.setState({...{music_on_hold: newState}});
				break;
			case "OutboundCallerIdBlock":
				this.setState({...{outbound_caller_id_block: newState}});
				break;
			case "BargeIn":
				this.setState({...{bargein_exempt: newState}});
				break;
		}
  }

	setToFalseAllSuccessAndError = () =>{
		let state = this.state;
		state.anynomous_call_rejection.show_success = false;
		state.anynomous_call_rejection.show_error = false;
		state.bargein_exempt.show_success = false;
		state.bargein_exempt.show_error = false;
		state.caller_id.show_success = false;
		state.caller_id.show_error = false;
		state.call_waiting.show_success = false;
		state.call_waiting.show_error = false;
		state.connected_line_id_restrictions.show_success = false;
		state.connected_line_id_restrictions.show_error = false;
		state.do_not_disturb.show_success = false;
		state.do_not_disturb.show_error = false;
		state.music_on_hold.show_success = false;
		state.music_on_hold.show_error = false;
		state.outbound_caller_id_block.show_success = false;
		state.outbound_caller_id_block.show_error = false;
		this.setState(state);
	}

	viewMoreLink = () => {
		let account = config.storedAccountNumber();
		if (account) {
			let sn = "";
			if (this.props.ServiceNumber !== "") {
				sn = this.props.ServiceNumber;
			} else {
				let pbx = this.props.PBXUserID;
				let idx = pbx.indexOf("@");
				sn = pbx.substr(0, idx);
			}
			let iframeUrl = '/iframe_page/MSP_SUBSCRIBER/' + account + '/' + sn;
			return <Link to={iframeUrl} className="no-btn">View All Features</Link>
		} else return null;
	}

	render() {
		return(
			<div className="card"  ref="mainDiv">
				<div className="card-header">
					<div className='card-actions'><div className='move'><svg width='12' height='12' viewBox='0 0 23 23'><path d='m512 256c0 5-2 9-5 13l-74 73c-3 4-7 5-12 5-5 0-10-1-13-5-4-4-6-8-6-13l0-36-109 0 0 109 36 0c5 0 9 2 13 6 4 3 5 8 5 13 0 5-1 9-5 12l-73 74c-4 3-8 5-13 5-5 0-9-2-13-5l-73-74c-4-3-5-7-5-12 0-5 1-10 5-13 4-4 8-6 13-6l36 0 0-109-109 0 0 36c0 5-2 9-6 13-3 4-8 5-13 5-5 0-9-1-12-5l-74-73c-3-4-5-8-5-13 0-5 2-9 5-13l74-73c3-4 7-5 12-5 5 0 10 1 13 5 4 4 6 8 6 13l0 36 109 0 0-109-36 0c-5 0-9-2-13-6-4-3-5-8-5-13 0-5 1-9 5-12l73-74c4-3 8-5 13-5 5 0 9 2 13 5l73 74c4 3 5 7 5 12 0 5-1 10-5 13-4 4-8 6-13 6l-36 0 0 109 109 0 0-36c0-5 2-9 6-13 3-4 8-5 13-5 5 0 9 1 12 5l74 73c3 4 5 8 5 13z' transform='scale(0.046875 0.046875)'></path></svg></div><div className='expand'><svg width='12' height='12' viewBox='0 0 23 23'><path d='m26 344l-26 158c0 2 1 5 3 7c0 0 0 0 0 0c2 2 5 3 7 3l158-27c3 0 6-3 7-6c1-3 0-7-3-9l-30-31l82-82c4-4 4-9 0-13l-57-57c-3-3-9-3-12 0l-83 83l-31-31c-2-2-5-3-9-2c-3 1-5 4-6 7z m460-176l26-158c0-2-1-5-3-7c0 0 0 0 0 0c-2-2-5-3-7-3l-158 27c-3 0-6 3-7 6c-1 3 0 7 3 9l30 31l-82 82c-4 4-4 9 0 13l57 57c3 3 9 3 12 0l83-83l31 31c2 2 5 3 9 2c3-1 5-4 6-7z' transform='scale(0.046875 0.046875)'></path></svg></div></div>
					<h2 className="move">Basic Features</h2>
				</div>
				<div className="basic-features card-content">
					{this.renderBasicFeatureControl()}
					<div className="view-more">
						{this.viewMoreLink()}
					</div>
				</div>
			</div>
		);
	}

	renderBasicFeatureControl=()=>{

		let enableFeatures = [];
		let state = this.state;
		if(state.anynomous_call_rejection.enable)
			enableFeatures.push(this.getBasicFeatureItem(enableFeatures.length, state.anynomous_call_rejection));
		if(state.bargein_exempt.enable)
			enableFeatures.push(this.getBasicFeatureItem(enableFeatures.length, state.bargein_exempt));
		if(state.caller_id.enable)
			enableFeatures.push(this.getBasicFeatureItem(enableFeatures.length, state.caller_id));
		if(state.call_waiting.enable)
			enableFeatures.push(this.getBasicFeatureItem(enableFeatures.length, state.call_waiting));
		if(state.connected_line_id_restrictions.enable)
			enableFeatures.push(this.getBasicFeatureItem(enableFeatures.length, state.connected_line_id_restrictions));
		if(state.do_not_disturb.enable)
			enableFeatures.push(this.getBasicFeatureItem(enableFeatures.length, state.do_not_disturb));
		if(state.music_on_hold.enable)
			enableFeatures.push(this.getBasicFeatureItem(enableFeatures.length, state.music_on_hold));
		if(state.outbound_caller_id_block.enable)
			enableFeatures.push(this.getBasicFeatureItem(enableFeatures.length, state.outbound_caller_id_block));
		
		if(enableFeatures.length < 8){
			let emptyMessage = "";
			if(state.loading){
				emptyMessage = (<DataLoadingSpinner className='load spinner'/>);
			}
			else if(!state.loading && enableFeatures.length === 0){
				emptyMessage = (<h4 className="empty" >No basic features options</h4>);
			}

			let cnt =  8 - enableFeatures.length;
			enableFeatures.push(<div key={enableFeatures.length} className="feature" style={{height: cnt*35}}>
				{emptyMessage}
			</div>);
		}
		return enableFeatures;
	}

	getBasicFeatureItem(key, state){
		let showSuccess = "";
		if(state.show_success){
			showSuccess = <ReactCSSTransitionGroup
					transitionName="basic-feature-success"
					transitionAppear={true}
					transitionAppearTimeout={4000}
					transitionEnter={true}
					transitionEnterTimeout={4000}
					transitionLeave={false}
					
					>
					<div className="inline inline-success">Success!</div>
				</ReactCSSTransitionGroup>
		}

		let showError = "";
		if(state.show_error){
			showError = <div  className="inline inline-fail">The switch failed! Please try again.</div>
		}

		let showLoading = ""
		if(state.loading){
			showLoading = <div className="loading">
					<DataLoadingSpinner className='load spinner'/>
			</div>;
		}

		return (
			<div className="feature" key={key}>
				<LongSwitch 
					on={state.selected} 
					onChange={(e)=>{this.onChangeBasic(state,e)}}
					label={state.details.label} info={state.details.info} />
					{showSuccess}
					{showError}
					{showLoading}
			</div>);
	}

}

export default BasicFeatures;