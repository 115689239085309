import React, { Component, Fragment } from 'react';
import * as apiCalls from '../../requests/api_calls';
import { Modal } from 'react-bootstrap';
import { DataLoadingSpinner } from '../../components/spinners';


export default class VoiceUserSettings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      Settings: null,
      SettingsCopy: null,
      Loading: false,
      VoipSwitch: sessionStorage.VoipSwitch,
      showConfirmModal: false,
      ErrorMsg: "",
      displayCallRecordingConnectField: null
    };
  }

  openModal = async () => {
    await this.setState({ showConfirmModal: true, Loading: true });
    this.fetchDataForModalvalues();
  }

  closeModal = async () => {
    await this.setState({ showConfirmModal: false, ErrorMsg: "" });
    this.props.RefreshDataSource();
  }

  fetchDataForModalvalues = async () => {
    let requests = [this.fetchCallRecordingSettings(), this.fetchCallRecordingValue()];
    return await Promise.all(requests).then(dataArray => dataArray).catch(err => console.error(err));
  }

  fetchCallRecordingValue = async () => {
    await apiCalls.fetchData(`admin/HasCallRecording?PBXUserId=${this.props.UserId}`).then(data => {
      let copy = JSON.parse(JSON.stringify(data.HasCallRecordingService));
      this.setState({
        displayCallRecordingConnectField: data.HasCallRecordingService,
        og_displayCallRecordingConnectField: copy
      });
    }).catch((err) => console.error(err));
  }

  fetchCallRecordingSettings = async () => {
    let path = 'admin/UserCallRecordingGet?switchName=' + this.state.VoipSwitch + '&userId=' + this.props.UserId;
    await apiCalls.fetchData(path).then(data => {
      if (data) {
        //console.log('Data', data);
        let copy = JSON.parse(JSON.stringify(data));
        this.setState({
          Settings: data,
          SettingsCopy: copy,
          Loading: false,
        });
      } else {
        this.setState({
          Settings: null,
          SettingsCopy: null,
          Loading: false,
        });
      }
    }).catch(err => console.error(err));
  }

  sumbitVoiceSettings = async () => {
    await this.setState({ Loading: true });
    let path = 'admin/UserCallRecordingModify';
    this.state.Settings.pauseResumeNotificationField = parseInt(this.state.Settings.pauseResumeNotificationField)
    this.state.Settings.recordCallRepeatWarningToneTimerSecondsField = parseInt(this.state.Settings.recordCallRepeatWarningToneTimerSecondsField)
    this.state.Settings.recordingOptionField = parseInt(this.state.Settings.recordingOptionField)
    let recordingSetting;
    if (this.state.Settings.recordingOptionField === 0) {
      recordingSetting = 'ALWAYS';
    } else if (this.state.Settings.recordingOptionField === 1) {
      recordingSetting = 'NEVER';
    } else if (this.state.Settings.recordingOptionField === 2) {
      recordingSetting = 'ON-DEMAND';
    } else if (this.state.Settings.recordingOptionField === 3) {
      recordingSetting = 'ALWAYS-PR';
    } else {
      recordingSetting = 'ON-DEMAND-UIS';
    }
    let dto = {
      UserName: sessionStorage.username,
      VoipSwitch: this.state.VoipSwitch,
      Extension: this.props.UserId,
      Entity: this.state.Settings,
      RecordingSetting: recordingSetting,
    }

    let DisplayCallRecordingConnectFieldDTO = {
      AccessToCallRecording: this.state.displayCallRecordingConnectField,
      PBXUserId: this.props.UserId,
    }
    //console.log("DTO", dto);
    let response = await apiCalls.post(path, "POST", JSON.stringify(dto)).then(data => data).catch(err => console.error(err));
    //let permissionResponse = await apiCalls.post("/admin/UpdateCallRecordingPermission", "POST", JSON.stringify(DisplayCallRecordingConnectFieldDTO)).then(data => data).catch(err => console.error(err));
    if (response) {
      // if User Settings receives a response
      // compare original setting to user selection to limit API calls
      if (this.state.displayCallRecordingConnectField !== this.state.og_displayCallRecordingConnectField) {
        await apiCalls.post("/admin/UpdateCallRecordingPermission", "POST", JSON.stringify(DisplayCallRecordingConnectFieldDTO)).then(data => data).catch(err => console.error(err));
      }
      if (response.ok) {
        await this.setState({
          Failed: false,
          ErrorMsg: "Success!"
        });
      } else {
        // Specific error message for invalid recordCallRepeatWarningToneTimerSecondsField
        if (parseInt(this.state.Settings.recordCallRepeatWarningToneTimerSecondsField) < 10 || parseInt(this.state.Settings.recordCallRepeatWarningToneTimerSecondsField) > 1800) {
          await this.setState({
            Failed: true,
            ErrorMsg: "Error: Invaliad Repeat Record Call Warning Tone Timer: Value must be >= 10 and <= 1800"
          });
        } else {
          await this.setState({
            Failed: true,
            ErrorMsg: response.message
          });
        }
      }
      this.fetchCallRecordingSettings();
    }
  }

  dataRow = (label, field) => {
    return (
      <div className="form-group-wrapper"
        style={{ margin: '1%', }}
      >
        <label>{label} </label>
        {field}
      </div >
    )
  }

  recordRadioDataField = (val, key, type = "radio") => {
    return (
      <div>
        <input
          type={type}
          value={"0"}
          checked={val === "0"}
          onChange={(e) => this.handleInputChange(e, key, type)}
        />
        <label htmlFor="default"
          style={{
            width: '50%',
          }}
        >Always</label><br />
        <input
          type={type}
          value={"1"}
          checked={val === "1"}
          onChange={(e) => this.handleInputChange(e, key, type)}
        />
        <label htmlFor="default"
          style={{
            width: '50%',
          }}
        >Never</label><br />
        <input
          type={type}
          value={"2"}
          checked={val === "2"}
          onChange={(e) => this.handleInputChange(e, key, type)}
        />
        <label htmlFor="default"
          style={{
            width: '50%',
          }}
        >On Demand</label><br />
        <input
          type={type}
          value={"3"}
          checked={val === "3"}
          onChange={(e) => this.handleInputChange(e, key, type)}
        />
        <label htmlFor="trunkGroupPolicy"
          style={{
            width: '50%',
          }}
        >Always with Pause/Resume</label><br />
        <input
          type={type}
          value={"4"}
          checked={val === "4"}
          onChange={(e) => this.handleInputChange(e, key, type)}
        />
        <label htmlFor="default"
          style={{
            width: '50%',
          }}
        >On Demand with User Initiated Start</label><br />
      </div>
    )
  }

  pauseResumeRadioDataField = (val, key, type = "radio") => {
    return (
      <div>
        <input
          type={type}
          value={"0"}
          checked={val === "0"}
          onChange={(e) => this.handleInputChange(e, key, type)}
        />
        <label htmlFor="default"
          style={{
            width: '50%',
          }}
        >None</label><br />
        <input
          type={type}
          value={"1"}
          checked={val === "1"}
          onChange={(e) => this.handleInputChange(e, key, type)}
        />
        <label htmlFor="trunkGroupPolicy"
          style={{
            width: '50%',
          }}
        >Beep</label><br />
        <input
          type={type}
          value={"2"}
          checked={val === "2"}
          onChange={(e) => this.handleInputChange(e, key, type)}
        />
        <label htmlFor="default"
          style={{
            width: '50%',
          }}
        >Play Announcement</label><br />
      </div>
    )
  }

  inputDataField = (val, key, type = 'text') => {
    if (type === "text" || type === "number") {
      return (
        <input
          type="text"
          id={key}
          value={val}
          className='custom-input-class'
          onChange={(e) => this.handleInputChange(e, key, type)}
        />
      )
    } else {
      return (
        <input
          style={{
            display: 'inline-block',
            float: 'left'
          }}
          type="checkbox"
          checked={val}
          className='check-box'
          onChange={(e) => this.handleInputChange(e, key, type)}
        //disabled={readOnly === true ? true : false}
        />
      )
    }
  }

  callWarningTone = (enableTone, secondsTone) => {
    return (
      <Fragment>
        {this.inputDataField(enableTone, "enableRecordCallRepeatWarningToneField", "checkbox")}
        &nbsp;&nbsp;
        {/* {this.inputDataField(secondsTone, "recordCallRepeatWarningToneTimerSecondsField", "number")} */}
        <input
          type="number"
          id={"recordCallRepeatWarningToneTimerSecondsField"}
          value={secondsTone}
          min={10}
          disabled={enableTone ? false : true}
          className='custom-input-class'
          onChange={(e) => this.handleInputChange(e, "recordCallRepeatWarningToneTimerSecondsField", "number")}
        />
      </Fragment>
    )
  }

  handleInputChange = async (e, key, type = 'text') => {
    let value = type === 'checkbox' ? e.target.checked : e.target.value;
    //console.log("EVENT VALUE", value);
    let Settings = { ...this.state.Settings };
    if (key === "displayCallRecordingConnectField") {
      this.setState({ displayCallRecordingConnectField: value })
    }
    Settings[key] = value;
    await this.setState({ Settings });
  }

  formButtons = () => {
    let {
      enableCallRecordingAnnouncementField,
      enableRecordCallRepeatWarningToneField,
      enableVoiceMailRecordingField,
      pauseResumeNotificationField,
      recordCallRepeatWarningToneTimerSecondsField,
      recordingOptionField,
      displayCallRecordingConnectField
    } = this.state.Settings;
    return (
      <div style={{ textAlign: "center" }}>
        <button className="a btn"
          //onClick={() => this.setState({ showConfirmationModal: true })} // we will show a confirmation modal on click
          onClick={this.sumbitVoiceSettings}
          disabled={
            displayCallRecordingConnectField === this.state.SettingsCopy.displayCallRecordingConnectField &&
              enableCallRecordingAnnouncementField === this.state.SettingsCopy.enableCallRecordingAnnouncementField &&
              enableRecordCallRepeatWarningToneField === this.state.SettingsCopy.enableRecordCallRepeatWarningToneField &&
              enableVoiceMailRecordingField === this.state.SettingsCopy.enableVoiceMailRecordingField &&
              parseInt(pauseResumeNotificationField) === this.state.SettingsCopy.pauseResumeNotificationField &&
              parseInt(recordCallRepeatWarningToneTimerSecondsField) === this.state.SettingsCopy.recordCallRepeatWarningToneTimerSecondsField &&
              parseInt(recordingOptionField) === this.state.SettingsCopy.recordingOptionField
              ? true : false
          }
        >
          Save
        </button>
        <button
          className="a btn"
          onClick={this.closeModal}
        >Cancel</button>
      </div >
    )
  }

  modalBody = () => {
    if (!this.state.Loading) {
      if (this.state.Settings && this.state.Settings !== null) {
        let {
          PropertyChanged,
          enableCallRecordingAnnouncementField,
          enableRecordCallRepeatWarningToneField,
          enableVoiceMailRecordingField,
          pauseResumeNotificationField,
          recordCallRepeatWarningToneTimerSecondsField,
          recordingOptionField,
          isVoiceMessagingAssignedField,
          displayCallRecordingConnectField
        } = this.state.Settings;
        const isVoiceMessagingAssigned = this.state.Settings.isVoiceMessagingAssignedField
        return (
          <div>
            <h6><b>Record Call:</b></h6>
            {this.dataRow("", this.recordRadioDataField(recordingOptionField.toString(), "recordingOptionField"))}
            {this.dataRow("Play Call Recording Start/Stop Announcement", this.inputDataField(enableCallRecordingAnnouncementField, "enableCallRecordingAnnouncementField", "checkbox"))}
            {isVoiceMessagingAssigned === false ? null : (this.dataRow("Record Voice Messaging", this.inputDataField(enableVoiceMailRecordingField, "enableVoiceMailRecordingField", "checkbox")))}
            <h6><b>Pause / Resume Notification:</b></h6>
            {this.dataRow("", this.pauseResumeRadioDataField(pauseResumeNotificationField.toString(), "pauseResumeNotificationField"))}
            <h6><b>Recording Notification:</b></h6>
            {this.dataRow("Repeat Call Record Warning Tone", this.callWarningTone(enableRecordCallRepeatWarningToneField, recordCallRepeatWarningToneTimerSecondsField))}<span style={{ position: "relative", left: "385px", top: "-27px" }}>seconds</span>
            {/* <p style={{ position: "relative", left: "27px", top: "-20px" }}>"enter amount between 10 seconds and 1800 seconds"</p> */}
            <small>{"* Repeat Record Call Warning Tone Timer: Value must be >= 10 and <= 1800"}</small>
            {sessionStorage.hasCallRecordingMenu === 'true'
              ? (this.dataRow("Display Call Recording Connect Access Link in User Dashboard", this.inputDataField(this.state.displayCallRecordingConnectField, "displayCallRecordingConnectField", "checkbox")))
              : null}
            <hr />
            {this.formButtons()}
          </div>
        )
      } else {
        return (
          <p style={{ textAlign: 'center', paddingTop: '15px' }}>No Available Data</p>
        )
      }
    } else {
      return <div>
        <DataLoadingSpinner style={{ marginTop: "3%" }} className='load spinner' />
      </div>
    }
  }

  handleClose = () => {
    this.setState({ showConfirmModal: false });
    this.props.RefreshDataSource();
  }

  cardContent = () => {
    return (
      <Fragment>
        <a onClick={this.openModal} className="icon btn">
          <svg width="14" height="14" viewBox="0 0 24 24"><path d="m163 440l-91-91 251-250 90 90z m309-352l-48-48c-12-11-32-11-45 2l-45 45 91 91 45-45c13-13 13-33 2-45z m-408 275l-32 117 117-32z" transform="scale(0.046875 0.046875)"></path></svg>
        </a>
        <Modal show={this.state.showConfirmModal} backdrop='static' onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Edit {this.props.UserName}</Modal.Title>
          </Modal.Header>
          {this.modalBody()}
          {this.state.Loading ? null : <Modal.Footer>
            <div style={{ textAlign: "center" }}>
              <p
                style={{
                  color: this.state.Failed ? "red" : "green",
                  //float: "left",
                  paddingTop: "10px"
                }}>{this.state.ErrorMsg}</p>
            </div>
          </Modal.Footer>}
        </Modal>
      </Fragment>
    )
  }

  render() {
    return this.cardContent();
  };
}