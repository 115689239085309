import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { Button } from '@progress/kendo-buttons-react-wrapper';
import { MultiSelect } from '@progress/kendo-dropdowns-react-wrapper';


class SupervisorsModal extends Component {

	constructor(props) {
		super(props);

    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);

    this.state = { showModal: false };
  }

  handleClose() {
    this.setState({ showModal: false });
  }

  handleShow() {
    this.setState({ showModal: true });
  }

	componentDidMount() {		
	}

  render() {
    return (
      <div className="setting">
        <span onClick={this.handleShow}> 
          <span className="name">Supervisors</span>
          <a className="icon btn"><svg width="14" height="14" viewBox="0 0 24 24"><path d="m163 440l-91-91 251-250 90 90z m309-352l-48-48c-12-11-32-11-45 2l-45 45 91 91 45-45c13-13 13-33 2-45z m-408 275l-32 117 117-32z" transform="scale(0.046875 0.046875)"></path></svg></a>
        </span>
        <Modal show={this.state.showModal} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Supervisors</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <p>Configure the list of users who may supervise this Contact Center. Configure agents to be supervised. Available agents are any agent available currently not supervised and can include agents that are also supervisors. </p>
              <h5>Assign Supervisors</h5>
              <div className="multiselect-group form-group">
                <label>Assigned Supervisors</label>
                <MultiSelect autoClose={false} dataSource={["User 1", "User 2", "User 3", "User 4"]}/>
              </div>
              <hr />
              <h5>Assign Agents</h5>
              <div className="form-group">
                <label >Supervisors</label>
                <select className="form-control">
                  <option>User 1</option>
                  <option>User 2</option>
                  <option>User 3</option>
                  <option>User 4</option>
                </select>
              </div>
              <div className="multiselect-group form-group">
                <label>Assigned Agents</label>
                <MultiSelect autoClose={false}dataSource={["User 1", "User 2", "User 3", "User 4"]}/>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
              <hr />
              <div className="btns">
              <Button className="btn" click={this.handleClose}>Close</Button>
              </div>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
};

export default SupervisorsModal;
