import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { Button } from '@progress/kendo-buttons-react-wrapper';

// <ExampleModal />

class DirectCallModal extends Component {

	constructor(props) {
		super(props);

    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);

    this.state = { showModal: false };
  }

  handleClose() {
    this.setState({ showModal: false });
  }

  handleShow() {
    this.setState({ showModal: true });
  }

	componentDidMount() {		
	}

  render() {
    return (
      <div className="setting">
        <span onClick={this.handleShow}> 
          <span className="name">Direct Call Pickup with Barge-in</span>
          <a className="icon btn"><svg width="14" height="14" viewBox="0 0 24 24"><path d="m163 440l-91-91 251-250 90 90z m309-352l-48-48c-12-11-32-11-45 2l-45 45 91 91 45-45c13-13 13-33 2-45z m-408 275l-32 117 117-32z" transform="scale(0.046875 0.046875)"></path></svg></a>
        </span>

        <Modal show={this.state.showModal} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Direct Call Pickup with Barge-in Settings</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <p>Directed Call Pickup with Barge-in (DPUBI) allows users to dial a feature access code (FAC) followed by an extension to pickup (answer) a call directed to another user in the same customer group (or enterprise, if the group is part of an enterprise), or barge in on the call if the call was already answered. When a barge-in occurs, a three-way call is established between the parties with the DPUBI user as the controller.</p>
              <hr />
              <div className="form-group">
                <label className="switch right">
                  <input type="checkbox" />
                  <span className="slider round"></span>
                </label>
                Barge-in Warning Tone
              </div>
              <div className="form-group">
                <label className="switch right">
                  <input type="checkbox" />
                  <span className="slider round"></span>
                </label>
                Automatic Target Selection
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
              <hr />
              <div className="btns">
              <Button className="btn" click={this.handleClose}>Close</Button>
              </div>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
};

export default DirectCallModal;
