import React, { Component } from 'react';
import { DataLoadingSpinner } from '../../components/spinners';
import { Modal } from 'react-bootstrap';
import { sendErrorMessage, convertToLocal } from './components/utility';
import * as apiCalls from '../../requests/api_calls';

export default class MigrationTab extends Component {
  constructor(props) {
    super(props);

    this.state = {
      Enterprise: this.props.Enterprise,
      loading: false,
      ErrorMessage: sessionStorage.MsTeamsStatusDscription ? sessionStorage.MsTeamsStatusDscription : "",
      MSTeamsConfig: this.props.MSTeamsConfig,
      routingType: this.props.routingType,
      CheckStatus: this.props.CheckStatus,
    };
  }

  // componentDidMount() {
  //   this.fetchStatus();
  // }

  // fetchStatus = async () => {
  //   let path = "admin/IsReadyForDerivedRoutingMigration";
  //   await apiCalls.fetchData(path).then((data) => {
  //     this.setState({
  //       migrationStatus: data && data == 1 ? true : false,
  //     });
  //   }).catch(err => console.error(err));
  // }

  handleInputChange = async (e, key) => {
    let { value } = e.target;
    let MSTeamsConfig = { ...this.state.MSTeamsConfig };
    MSTeamsConfig[key] = value;
    await this.setState({ MSTeamsConfig });
    //console.log("MSTeamsConfig", MSTeamsConfig); // inserted AD Information
  }

  inputDataField = (val, key, onChangeFunc, readOnly = false, type = 'text') => {
    return (
      <input
        style={{
          width: '50%',
          display: 'inline-block',
        }}
        type={type}
        name={key}
        value={val}
        className="form-control"
        onChange={(e) => onChangeFunc(e, key)}
        disabled={readOnly}
      />
    )
  }

  dataRow = (label, field) => {
    return (
      <div className="form-group-wrapper"
        style={{ margin: '1%', }}
      >
        <label
          style={{
            width: '50%',
            display: 'inline-block',
          }}
        >{label} </label>
        {field}
      </div >
    )
  }

  cardContent = () => {
    let { MSTeamsConfig, ErrorMessage } = this.state;
    return (
      <div className="card-content"
        style={{
          paddingTop: "20px"
        }}>
        <div className="card-actions">
        </div>
        <p><b>Submit the request to migrate and enable Derived Routing.</b></p>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <div className="flex-row"
            style={{
              width: '60%',
            }}
          >
            {this.migrationBtn()}
            {this.wizardMsg()}
            {ErrorMessage !== "" ? <small style={{
              color: 'red',
              marginLeft: '10px'
            }}>Error: {ErrorMessage}</small> : null}
          </div>
          <div className="flex-row"
            style={{
              marginLeft: '2%',
              width: '40%',
              border: '1px solid grey'
            }}
          >
            <div style={{ textAlign: "center", paddingLeft: '10px' }}>
              <span style={{ textDecoration: "underline" }}>Support Notes</span>
              <p style={{ textAlign: "left" }}>Click the Start Migration Button to start migration of direct routing to derived routing.
                <br /><br />This process will take up to 30 minutes to complete.
                <br /><br />Commands needed to run in Microsoft environment for this migration, can be executed while this step is being processed.
              </p>
            </div>
          </div>
        </div>
      </div >
    )
  }

  migrationBtn = () => {
    let { MigrationUpdatedOn, DerivedRoutingMigrationStatus, DerivedRoutingMigrationNotes } = this.state.MSTeamsConfig;
    if (DerivedRoutingMigrationStatus && DerivedRoutingMigrationStatus != "None") {
      return (
        <button
          className="a btn"
          onClick={this.props.CheckConfig}
        >Check Status</button>
      )
    } else {
      return (
        <button
          className="a btn"
          onClick={() => this.setState({ showConfirmationModal: true })}
          disabled={DerivedRoutingMigrationStatus && DerivedRoutingMigrationStatus == "None" ? false : true}
        >Start Migration</button>
      )
    }
  }

  wizardMsg = () => {
    let { CheckStatus } = this.state;
    let { MigrationUpdatedOn, DerivedRoutingMigrationStatus, DerivedRoutingMigrationNotes } = this.state.MSTeamsConfig;
    //if (DerivedRoutingMigrationStatus && (DerivedRoutingMigrationStatus != "" || DerivedRoutingMigrationStatus != null || DerivedRoutingMigrationStatus != undefined)) {
    if (CheckStatus) {
      return <div><br />
        <small
          style={{
            marginLeft: '10px'
          }}
        ><b>{DerivedRoutingMigrationStatus}</b></small><br />
        <small
          style={{
            marginLeft: '10px'
          }}
        ><b>{DerivedRoutingMigrationNotes}</b></small><br />
        <small style={{
          color: 'green',
          marginLeft: '10px'
        }}>Updated On: {convertToLocal(MigrationUpdatedOn).toLocaleString()}</small>
      </div>
    } else return <br />
  }

  migrateToDerivedRouting = async () => {
    await this.setState({ submittedTicket: true });
    let path = 'admin/StartRPXDerivedRoutingMigration';
    // let { MSTeamsConfig, Trunk, Enterprise } = this.state;
    // let dto = {
    //   userName: sessionStorage.username,//"prodnewsubsite@momentumtelecom.com",
    //   enterpriseId: Enterprise,
    //   trunkName: Trunk.TrunkName,
    //   returnOnlyTeamsDomains: true,
    // };
    // console.log("DTO", dto);
    let response = await apiCalls.post(path, "POST", null).then(data => data).catch(err => console.error(err));
    if (response) {
      sendErrorMessage(response.message);
      this.props.RefreshData();
    }
  }

  render() {
    return (
      <div>
        <Modal show={this.state.showConfirmationModal} onHide={this.handleClose} backdrop='static'>
          <Modal.Header>
            <Modal.Title>Start migration process for this Enterprise?</Modal.Title>
          </Modal.Header>
          <Modal.Footer>
            <hr />
            {this.state.submittedTicket ? <DataLoadingSpinner className='load spinner' /> : <div className="btns">
              <span>
                <button type="button" className="btn" onClick={this.migrateToDerivedRouting}>Yes</button>
                <button className="btn" onClick={() => this.setState({ showConfirmationModal: false })}>No</button>
              </span>
            </div>}
          </Modal.Footer>
        </Modal>
        {this.cardContent()}
      </div>
    );
  };
}