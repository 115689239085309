import React, { Component } from 'react';
import { Grid, GridColumn as Column, GridCell } from '@progress/kendo-react-grid';
import { Modal } from 'react-bootstrap';
import { Button } from '@progress/kendo-buttons-react-wrapper';
import { DataLoadingSpinner } from '../../../components/spinners';
import _ from 'lodash';
import { orderBy } from '@progress/kendo-data-query';
import * as apiCalls from '../../../requests/api_calls';
import { filterBy } from '@progress/kendo-data-query';


export default class AffectedDevicesModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      Devices: [],
      FullDevices: [],
      showModal: false,
      Sort: [{ field: "MAC", dir: "asc" }],
    }
    const selectDevice = this.selectDevice.bind(this);

    class SelectDeviceCell extends GridCell {
      constructor(props) {
        super(props);
      }
      render() {
        let device = this.props.dataItem
        let { Selected } = device;
        return (
          <td>
            <input type='checkbox' checked={Selected} onChange={(e) => selectDevice(e, device)} />
          </td>
        )
      }
    }
    this.SelectDeviceCell = SelectDeviceCell;
  }

  fetchDeviceList = async () => {
    await this.setState({ loading: true });
    let apiPath = 'admin/GetDevices';
    apiCalls.fetchData(apiPath).then(data => {
      if (data) {
        this.setState({ Devices: data, FullDevices: data, loading: false });
      } else {
        this.setState({ loading: false });
      }
    }).catch(err => console.error(err));
  }

  selectDevice = async (e, productItem) => {
    let selectedValue = e.target.checked;
    let deviceList = this.state.Devices.slice();
    productItem.Selected = selectedValue;
    let item = deviceList.filter(itm => itm.MacAddress === productItem.MacAddress)[0];
    let idx = _.indexOf(deviceList, item);
    deviceList[idx] = productItem;
    await this.setState({ Devices: deviceList });
  }

  handleClose = () => {
    this.setState({ showModal: false });
  }

  handleShow = async () => {
    await this.setState({ showModal: true });
    let { deviceList } = sessionStorage;
    if (deviceList) {
      let savedDevices = JSON.parse(deviceList);
      this.setState({ Services: savedDevices, FullServices: savedDevices });
    } else {
      this.fetchDeviceList();
    }
  }

  sortChange = (event) => {
    this.setState({
      Devices: orderBy(this.state.Devices, event.sort),
      Sort: event.sort
    });
  }

  gridContent = () => {
    if (this.state.loading) {
      return <DataLoadingSpinner className='load spinner' />
    } else {
      if (this.state.Devices && this.state.Devices.length > 0) {
        return (
          <Grid
            style={{
              maxHeight: '400px',
              overflow: 'auto'
              
            }}
            data={this.state.Devices}
            sortable={{ allowUnsort: true, mode: 'single' }}
            sort={this.state.Sort}
            onSortChange={this.sortChange}
          >
            <Column width='80px' field='Selected' title='Select' cell={this.SelectDeviceCell} filterable={false} />
            <Column width='200px' field='MacAddress' title='MAC' />
            <Column width='200px' field='MakeModel' title='Make / Model' />
            <Column width='200px' field='AssignedTo' title='Assigned To' />
            <Column title=' ' field='filler' sortable={false} filterable={false} />
          </Grid>
        )
      }
      if (this.state.Devices && this.state.Devices.length === 0) {
        return <p style={{ textAlign: 'center' }}>No Available Devices</p>
      }
    }
  }

  assignDevices = () => {
    let deviceList = this.state.Devices.filter(itm => itm.Selected === true);
    this.props.affectedDevices(deviceList);
    if (this.props.saveSelections) {
      sessionStorage.setItem('deviceList', JSON.stringify(this.state.Devices));
    }
    this.handleClose();
  }

  searchListings = (event) => {
    let searchText = event.target.value.trim();
    let fullDetails = this.state.FullDevices;
    if (searchText !== '') {
      searchText = event.target.value;
      let services = filterBy(fullDetails, {
        logic: 'or',
        filters: [
          { field: 'MacAddress', operator: 'contains', value: searchText, ignoreCase: true },
          { field: 'MakeModel', operator: 'contains', value: searchText, ignoreCase: true },
          { field: 'AssignedTo', operator: 'contains', value: searchText, ignoreCase: true },
        ]
      });
      this.setState({ Devices: services })
    }
    else {
      this.setState({ Devices: fullDetails });
    }
  }

  render() {
    return (
      <span>
        <button className='btn' onClick={this.handleShow} style={this.props.btnStyles}>Add Affected Devices</button>

        <Modal dialogClassName="modal-class"
          show={this.state.showModal}
          onHide={this.handleClose}
          bsSize="large"
        >
          <Modal.Header closeButton closeLabel="test test test test est test ets estse">
            <Modal.Title>Add Affected Devices</Modal.Title>
            {this.state.loading ? null
              : <div className="search">
                <label htmlFor="fav_services_srch_tb" className="hidden">favorite services search</label>
                <input id="fav_services_srch_tb" type="text" autoComplete={"off"} className="form-control" onChange={this.searchListings} />
                <svg width="12" height="12" viewBox="0 0 23 23"><path d="m347 238c0-36-12-66-37-91-25-25-55-37-91-37-35 0-65 12-90 37-25 25-38 55-38 91 0 35 13 65 38 90 25 25 55 38 90 38 36 0 66-13 91-38 25-25 37-55 37-90z m147 237c0 10-4 19-11 26-7 7-16 11-26 11-10 0-19-4-26-11l-98-98c-34 24-72 36-114 36-27 0-53-5-78-16-25-11-46-25-64-43-18-18-32-39-43-64-10-25-16-51-16-78 0-28 6-54 16-78 11-25 25-47 43-65 18-18 39-32 64-43 25-10 51-15 78-15 28 0 54 5 79 15 24 11 46 25 64 43 18 18 32 40 43 65 10 24 16 50 16 78 0 42-12 80-36 114l98 98c7 7 11 15 11 25z" transform="scale(0.046875 0.046875)"></path></svg>
              </div>}
          </Modal.Header>
          <Modal.Body>
            <div>
              {this.gridContent()}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <hr />
            {!this.state.loading ? <div className="btns">
              <Button className="btn"
                click={this.assignDevices}
              >Add as Comment</Button>
              <Button className="btn"
                click={this.handleClose}
              >Close</Button>
            </div> : null}
          </Modal.Footer>
        </Modal>
      </span>
    )
  }
};