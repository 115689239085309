import React, { Component, Fragment, useRef } from "react";
import * as helper from "../../scripts/helper";
import * as apiCalls from "../../requests/api_calls";
import * as settings from "../../scripts/settings";
import {
  Grid,
  GridColumn as Column,
  GridDetailRow,
  GridCell,
} from "@progress/kendo-react-grid";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import { DataLoadingSpinner } from "../../components/spinners";
import { headerToolTip } from "../../components/ellipsisHeaderTooltip";
import { orderBy } from "@progress/kendo-data-query";
import { filterBy } from "@progress/kendo-data-query";
import { filtercell } from "../../components/dropdownFilter";
import _ from "lodash";
import Popup from "reactjs-popup";
import { userIsAdmin } from "../../scripts/helper";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Button } from "react-bootstrap";
import * as config from "../../requests/config";
import { CSVLink, CSVDownload } from "react-csv";

export const webexemailfilter = [
  "All",
  "Provisioned",
  "Pending Email Input",
  "Pending Email Validation",
  "Provisioning",
];
export default class Webex extends Component {
  constructor() {
    super();

    this.state = {
      loading: false,
      syncing: false,
      lastSyncedDate: "",
      LocationAccountNumber: "",
      Term: "",
      Listings: [],
      Sort: [{ field: "PackageName", dir: "asc" }],
      filter: { logic: "and", filters: [] },
      SubmittedId: null,
      search: "",
      directorySyncStatus: "",
      directorySyncStatusTime: "",
      disableDirectorySycnButton: false,
      disableDirectorySycnButtonStatus: "",

    };

    const parentToChildStateAccess = this.parentToChildStateAccess.bind(this);
    const updateWebexData = this.updateWebexData.bind(this);
    const handleClearFilter = this.handleClearFilter.bind(this);
    const handleEmailFilter = this.handleEmailFilter.bind(this);
    const searchEmail = this.searchEmail.bind(this);
    const handleInputChange = this.handleInputChange.bind(this);

    ///////////////////////////////////////////////////////////////////////////////////
    class ClearFilterCell extends Component {
      constructor(props) {
        super(props);
      }
      render() {
        return (
          <a onClick={handleClearFilter} className="no-btn">
            Clear Filters
          </a>
        );
      }
    }
    ///////////////////////////////////////////////////////////////////////////////////
    class FilterEmailCell extends Component {
      constructor(props) {
        super(props);

        this.state = {
          FullListings: parentToChildStateAccess()[4],
        };
      }

      roughDraft = () => {
        return (
          <input
            onChange={(e) => {
              console.log("FULL REE", this.state.FullListings);
              let searchText = e.target.value;
              let services = filterBy(this.state.FullDetails, {
                logic: "or",
                filters: [
                  {
                    field: "WebExEmail",
                    operator: "contains",
                    value: searchText,
                    ignoreCase: true,
                  },
                ],
              });
            }}
            type="text"
            name="WebExEmail"
          //value={""}
          />
        );
      };

      cellContent = () => {
        return (
          <a onClick={handleEmailFilter} className="no-btn">
            Show Empty
          </a>
        );
      };

      render() {
        return this.cellContent();
      }
    }
    ///////////////////////////////////////////////////////////////////////////////////
    const DropDownFilter = (data, defaultItem) => {
      return class extends Component {
        constructor(props) {
          super(props);

          this.state = {
            LocationAccountNumber: parentToChildStateAccess()[4],
            Term: parentToChildStateAccess()[5],
          };
        }

        render() {
          return (
            <select
              style={{
                height: "20px",
                maxWidth: "90px",
                border: "1px solid rgb(230, 230, 230)",
              }}
              onChange={(e) => {
                //update Location Account Number
                this.setState({ LocationAccountNumber: e.target.value });
              }}
              value={this.state.LocationAccountNumber}
            >
              <option value="">All</option>
              {data.map((item, index) => {
                return (
                  <option key={index} value={item}>
                    {item}
                  </option>
                );
              })}
            </select>
          );
        }
      };
    };
    ///////////////////////////////////////////////////////////////////////////////////
    class AddOnCountCell extends Component {
      constructor(props) {
        super(props);

        this.state = {
          GridItem: this.props.dataItem,
          LoadPop: false,
          CountAddOnData: [],
        };
      }

      fetchAddOnList = async (ServiceId) => {
        //EnterpriseId, ParentServiceId, PackageName, USOC
        await this.setState({ LoadPop: true });
        let apiPath = `admin/GetServiceAddonList?ServiceId=${ServiceId}`;
        await apiCalls
          .fetchData(apiPath)
          .then((data) => {
            if (data) {
              // let addOnContainer = [];
              // data.forEach(itm => {
              //   if (itm) { addOnContainer.push({ IP: itm }); }
              // });
              this.setState({
                CountAddOnData: data,
                LoadPop: false,
              });
            } else {
              this.setState({
                CountAddOnData: [],
                LoadPop: false,
              });
            }
          })
          .catch((err) => console.error(err));
      };

      loadAddOnList = () => {
        if (this.state.LoadPop) {
          return <DataLoadingSpinner className="load relative spinner" />;
        } else {
          return (
            <span>
              <h4 className="text-center">Addon List:</h4>
              <Grid data={this.state.CountAddOnData}>
                <Column field="PackageName" title="Package" />
                <Column field="USOC" title="USOC" />
              </Grid>
            </span>
          );
        }
      };

      render() {
        //let trunkGroupId = this.props.dataItem.TrunkGroupID;
        //let { CountAddOn } = this.state.gridItem;
        let { CountAddOn, ServiceId } = this.props.dataItem;
        let field = this.props.field;

        if (CountAddOn && CountAddOn > 0 && field === "CountAddOn") {
          return (
            <td
            //onClick={() => this.setState({ ReadOnly: !this.state.ReadOnly })}
            >
              <Popup
                trigger={(open) => (
                  <a tabIndex="0" className="no-btn">
                    {CountAddOn}
                  </a>
                )}
                onOpen={() => {
                  this.fetchAddOnList(ServiceId);
                }}
                position="bottom center"
                closeOnDocumentClick
                contentStyle={{
                  padding: "20px",
                  borderColor: "unset",
                  width: "250px",
                }}
              >
                <span>{this.loadAddOnList()}</span>
              </Popup>
            </td>
          );
        } else return <td>{CountAddOn}</td>;
      }
    }
    ///////////////////////////////////////////////////////////////////////////////////
    class EmailCell extends GridCell {
      constructor(props) {
        super(props);

        this.state = {
          ReadOnly: true,
          GridItem: parentToChildStateAccess()[6].filter(
            (itm) => itm.ServiceId == this.props.dataItem.ServiceId
          )[0],
          IsEmailValid: true,
          isVisible: "hidden",
        };
      }

      validateEmail = (email) => {
        const re =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        let result = re.test(String(email).toLowerCase());
        if (!result && email != "") {
          this.setState({ IsEmailValid: false });
        } else {
          this.setState({ IsEmailValid: true });
        }
      };

      validationError = () => {
        if (this.state.IsEmailValid) {
          return;
        }
        return (
          <p
            style={{
              color: "red",
              fontSize: "70%",
              display: "block",
              margin: "0",
            }}
          >
            * Please use a valid email
          </p>
        );
      };

      render() {
        //console.log("DATA ITEM", this.state.GridItem);
        let { WebExEmail } = this.state.GridItem;

        if (!this.state.ReadOnly) {
          return (
            <td>
              {/* <input
                disabled
                type="text"
                className="form-control"
                name="WebExEmail"
                value={this.props.dataItem.WebExEmail}
              />
              {this.validationError()} */}
              <span>{this.props.dataItem.WebExEmail}</span>
            </td>
          );
        } else {
          return (
            <td>
              {/* <input
                onClick={() =>
                  this.setState({ ReadOnly: !this.state.ReadOnly })
                }
                style={{
                  cursor: "pointer",
                  borderRadius: "0",
                  backgroundColor: "#eee",
                  opacity: "1",
                  height: "34px",
                  lineHeight: "1.42857143",
                }}
                type="text"
                name={`${this.props.dataItem.WebExEmail}`}
                //name="WebExEmail"
                value={this.props.dataItem.WebExEmail}
                className="form-control"
                data-toggle="tooltip"
                data-placement="bottom"
                title={this.props.dataItem.WebExEmail} //tooltip
                autocomplete="off"
              /> */}
              <span>{this.props.dataItem.WebExEmail}</span>
            </td>
          );
        }
      }
    }
    ///////////////////////////////////////////////////////////////////////////////////
    class SubmissionCell extends GridCell {
      constructor(props) {
        super(props);

        this.state = {
          Submission: parentToChildStateAccess()[0],
          SubmitError: parentToChildStateAccess()[1],
          GridItem: this.props.dataItem,
          SubmittedId: parentToChildStateAccess()[2],
          DuplicateError: parentToChildStateAccess()[3],
        };
      }

      async componentWillReceiveProps() {
        if (
          this.props.dataItem.ServiceId !== this.state.GridItem.ServiceId ||
          this.state.Submission !== parentToChildStateAccess()[0] ||
          this.state.SubmitError !== parentToChildStateAccess()[1] ||
          this.state.SubmittedId !== parentToChildStateAccess()[2] ||
          this.state.DuplicateError !== parentToChildStateAccess()[3]
        ) {
          await this.setState({
            GridItem: this.props.dataItem,
            Submission: parentToChildStateAccess()[0],
            SubmitError: parentToChildStateAccess()[1],
            SubmittedId: parentToChildStateAccess()[2],
            DuplicateError: parentToChildStateAccess()[3],
          });
        }
      }

      render() {
        let { Submission, SubmitError, GridItem, SubmittedId, DuplicateError } =
          this.state;
        if (this.props.dataItem.ServiceId === SubmittedId) {
          if (Submission) {
            let successMessage = (
              <img
                style={{ height: "40px", width: "40px" }}
                src="https://thumbs.dreamstime.com/b/green-check-mark-icon-checkmark-circle-checklist-tick-colored-flat-style-vector-illustration-eps-154721515.jpg"
              />
            );
            let failureMessage = (
              <img
                style={{ height: "40px", width: "40px", cursor: "pointer" }}
                src="https://imgix.bustle.com/2017/2/23/19aaee7e-1295-472c-9edd-d55e4a790b0c.jpg?w=800&fit=crop&crop=faces&auto=format%2Ccompress"
              />
            );
            if (!SubmitError && !DuplicateError) {
              return <td>{successMessage}</td>;
            } else if (!SubmitError && DuplicateError) {
              // duplicate error but no sumbit error
              return (
                <td>
                  <div
                    data-toggle="tooltip"
                    data-placement="bottom"
                    title="* Duplicate Error: Email already exists." //tooltip
                  >
                    {failureMessage}
                  </div>
                </td>
              );
            } else if (SubmitError && !DuplicateError) {
              // sumbit error but no duplicate error
              return (
                <td>
                  <div
                    data-toggle="tooltip"
                    data-placement="bottom"
                    title="* Sumbission Error: Error occurred saving data. Please try again." //tooltip
                  >
                    {failureMessage}
                  </div>
                </td>
              );
            } else {
              return <td></td>;
            }
          } else return <td></td>;
        } else return <td></td>;
      }
    }
    this.AddOnCountCell = AddOnCountCell;
    this.ClearFilterCell = ClearFilterCell;
    this.FilterEmailCell = FilterEmailCell;
    this.DropDownFilter = DropDownFilter;
    this.EmailCell = EmailCell;
    this.SubmissionCell = SubmissionCell;
  }

  componentDidMount() {
    if (userIsAdmin()) {
      this.fetchWebexData();
    }
    this.syncAllUsers(true);
  }

  searchEmail = async (event) => {
    if (this.state.FullListings && this.state.FullListings.length > 0) {
      let searchText = event.target.value.trim();
      let fullDetails = this.state.FullListings;
      if (searchText !== "") {
        searchText = event.target.value;
        let services = filterBy(fullDetails, {
          logic: "or",
          filters: [
            {
              field: "WebExEmail",
              operator: "contains",
              value: searchText,
              ignoreCase: true,
            },
          ],
        });
        //this.delayedSearch(services);
        await this.setState({ Listings: services });
        //console.log("SEARCH", services);
      } else {
        //this.delayedSearch(fullDetails);
        this.setState({ Listings: fullDetails });
      }
    }
  };

  parentToChildStateAccess = () => {
    let {
      Submission,
      SubmitError,
      SubmittedId,
      DuplicateError,
      LocationAccountNumber,
      Term,
      FullListings,
    } = this.state;
    return [
      Submission,
      SubmitError,
      SubmittedId,
      DuplicateError,
      LocationAccountNumber,
      Term,
      FullListings,
    ];
  };

  fetchWebexData = async () => {
    await this.setState({ syncing: true });
    let { LocationAccountNumber, Term } = this.state;
    let path = `admin/GetWebExServiceList?LocationAccountNumber=${LocationAccountNumber}&term=${Term}`;
    await apiCalls
      .fetchData(path)
      .then((data) => {
        if (data) {
          let lastSyncedDate = new Date().toString();
          let copy = JSON.parse(JSON.stringify(data));
          for (var i = 0; i < data.length; i++) {
            if (data[i].WebexEmailValidation === "provisioned")
              data[i].WebexEmailValidation = "Provisioned";
            else if (data[i].WebexEmailValidation === "pending_email_input")
              data[i].WebexEmailValidation = "Pending Email Input";
            else if (
              data[i].WebexEmailValidation === "pending_email_validation"
            )
              data[i].WebexEmailValidation = "Pending Email Validation";
            else if (data[i].WebexEmailValidation === "provisioning")
              data[i].WebexEmailValidation = "Provisioning";
            else {
              data[i].WebexEmailValidation;
            }
          }
          //have to remove code till here `sadatla
          this.setState({
            Listings: data,
            FullListings: data,
            syncing: false,
            lastSyncedDate: lastSyncedDate,
          });
        } else {
          this.setState({
            Listings: [],
            FullListings: [],
            syncing: false,
            lastSyncedDate: lastSyncedDate,
          });
        }
      })
      .catch((err) =>
        console.error(`admin/GetWebExServiceList() failed: ${err}`)
      );
  };

  onChange = (event) => {
    var newList = [];
    switch (event.target.value) {
      case "All":
        this.setState({ Listings: this.state.FullListings });
        break;
      case "Provisioned":
        newList = this.state.FullListings.filter(
          (list) => list.WebexEmailValidation === "Provisioned"
        );
        this.setState({ Listings: newList });
        break;
      case "Pending Email Validation":
        newList = this.state.FullListings.filter(
          (list) => list.WebexEmailValidation === "Pending Email Validation"
        );
        this.setState({ Listings: newList });
        break;
      case "Pending Email Input":
        newList = this.state.FullListings.filter(
          (list) => list.WebexEmailValidation === "Pending Email Input"
        );
        this.setState({ Listings: newList });
        break;
      case "Provisioning":
        newList = this.state.FullListings.filter(
          (list) => list.WebexEmailValidation === "Provisioning"
        );
        this.setState({ Listings: newList });
        break;
      default:
        break;
    }
  };

  handleInputChange = async (gridId, e, key) => {
    let { value } = e.target;
    // ** filter current grid item
    let listing = this.state.Listings.filter(
      (itm) => itm.ServiceId == gridId
    )[0];
    // ** replace with updated object
    listing[key] = value;
    await this.setState({ Listings: this.state.Listings });
    //console.log("GridItem", this.state.Listings); // inserted AD Information
  };

  updateWebexData = async (gridItem) => {
    let path = "admin/UpdateWebExServiceList";
    //console.log(gridItem);
    let response = await apiCalls
      .post(path, "POST", JSON.stringify(gridItem))
      .then((data) => data)
      .catch((err) =>
        console.error(`admin/UpdateWebExServiceList() failed: ${err}`)
      );
    if (response.ok) {
      await this.setState({
        Submission: true,
        SubmitError: false,
        DuplicateError: false,
        SubmittedId: gridItem.ServiceId,
      });
      this.fetchWebexData();
    } else {
      if (response.message.includes("Duplicate")) {
        await this.setState({
          Submission: true,
          DuplicateError: true,
          //SubmittedId: null,
          SubmittedId: gridItem.ServiceId,
        });
      } else {
        // an error occurred while trying to submit
        await this.setState({
          Submission: true,
          SubmitError: true,
          //SubmittedId: null,
          SubmittedId: gridItem.ServiceId,
        });
      }
    }
  };

  submissionError = () => {
    if (this.state.SubmitError) {
      return (
        <small style={{ color: "red" }}>
          * Error occurred saving data. Please try again.
        </small>
      );
    } else if (this.state.DuplicateError) {
      return <small style={{ color: "red" }}>* Email already exists.</small>;
    } else return;
  };

  gridToolBar = () => {
    return (
      <div
      //style={{ float: "right", bottom: "20px", position: "relative", display: "inline-block" }}
      >
        <button
          title="Export to Excel"
          className="k-button k-primary"
        //onClick={this.exportExcel}
        >
          Export to Excel
        </button>
        &nbsp;
        <button
          className="k-button k-primary"
        //onClick={this.exportPDF}
        >
          Export to PDF
        </button>
      </div>
    );
  };

  syncWithWebex = async (e, props) => {
    let button = document.getElementById(props.dataItem.BroadsoftUserId);
    button.innerHTML = "Refreshing...";
    let path = `admin/GetWebExValidationResponse?BroadsoftUserId=${props.dataItem.BroadsoftUserId}&ServiceId=${props.dataItem.ServiceId}`;
    await apiCalls
      .fetchData(path)
      .then((data) => {
        button.innerHTML = "Refresh Status";
        if (data) {
          if (data.status === "provisioned") {
            var copy = this.state.Listings;
            copy.forEach(function (part, index) {
              if (part.BroadsoftUserId === props.dataItem.BroadsoftUserId) {
                part.WebexEmailValidation = "Email Validated";
              }
            }, copy);
            this.setState({
              loading: false,
              Listings: copy,
            });
          } else {
            this.setState({
              loading: false,
            });
          }
        }
        console.log("apidata", data);
      })
      .catch((err) => {
        button.innerHTML = "Refresh Status";
        console.error(`admin/GetWebExServiceList() failed: ${err}`);
      });
  };

  syncAllUsers = async (enableDirSync) => {
    let path = `admin/GetEnterpriseUpdateDirectorySync?logUserName=${sessionStorage.username}&enterpriseId=${sessionStorage.userEnterpriseNumber}&enableDirSync=${enableDirSync}`;
    await apiCalls.fetchData(path).then((data) => {
      if (data != "") {
        var date = new Date(data.lastSuccessfulSyncTime);
        var toDate = new Date();
        const msBetweenDates = Math.abs(date.getTime() - toDate.getTime());

        // 👇️ convert ms to hours                  min  sec   ms
        const hoursBetweenDates = msBetweenDates / (60 * 60 * 1000);
        if (data.status == "pending_start" || data.status == "on_hold") {
          this.setState({
            directorySyncStatus: "In queue to be processed",
            disableDirectorySycnButton: true,
            directorySyncStatusTime: ""
          });
        } else if (data.status == "in_progress") {
          this.setState({
            directorySyncStatus: "Currently being processed",
            disableDirectorySycnButton: true,
            directorySyncStatusTime: ""
          });
        } else {
          if (data.status == "complete") {
            if (hoursBetweenDates < 24)
              this.setState({
                disableDirectorySycnButton: true,
                disableDirectorySycnButtonStatus: "Directory sync can only be executed once per 24 hour period",
                directorySyncStatusTime: date.toString()
              })
            else
              this.setState({
                disableDirectorySycnButton: false,
                disableDirectorySycnButtonStatus: "",
                directorySyncStatusTime: date.toString()

              })
          }
          this.setState({ directorySyncStatus: data.status });
        }
      }
    });
  };
  ValidationButton = (props) => {
    return (
      <td>
        {props.dataItem.WebexEmailValidation != "provisioned" ? (
          <Button
            id={props.dataItem.BroadsoftUserId}
            style={{ whiteSpace: "normal" }}
            onClick={(e) => this.syncWithWebex(e, props)}
          >
            Refresh Status
          </Button>
        ) : (
          <></>
        )}
      </td>
    );
  };

  gridContent = () => {
    //let { UsersList } = this.state;
    if (!this.state.syncing) {
      let columnWidth = '140px';
      // if (
      //   this.gridListings(this.state.filter) &&
      //   this.gridListings(this.state.filter).length > 0
      // ) {
      return (
        <Grid
          style={{ height: "auto" }}
          data={this.gridListings(this.state.filter)}
          sortable={{ allowUnsort: true, mode: "single" }}
          sort={this.state.Sort}
          onSortChange={this.sortChange}
          filterable
          filter={this.state.filter}
          onFilterChange={this.filterChange}
          scrollable="scrollable"
        >
          <Column
            field="LocationName"
            width={columnWidth}
            title={headerToolTip("Location", true)}
            filterCell={filtercell(this.dropDownOptions("LocationName"))}
          />
          <Column
            field="WebExLicense"
            width={columnWidth}
            title={headerToolTip("Webex License", true)}
          />
          <Column
            field="PackageName"
            width={columnWidth}
            title={headerToolTip("Service Type", true)}
            filterable={false}
            filterCell={filtercell(this.dropDownOptions("PackageName"))}
          />
          <Column
            field="ServiceNumber"
            width={columnWidth}
            title={headerToolTip("Service ID", true)}
            filterCell={filtercell(this.dropDownOptions("ServiceNumber"))}
          />
          <Column field="Extension" width={columnWidth} title={headerToolTip("Ext", true)} />

          <Column field="FirstName" width={columnWidth} title={headerToolTip("First Name", true)} />
          <Column field="LastName" width={columnWidth} title={headerToolTip("Last Name", true)} />
          <Column
            width="150px"
            field="WebExEmail"
            title={headerToolTip("Email", true)}
            cell={this.EmailCell}
          />
          <Column
            width="145px"
            field="WebexEmailValidation"
            title={headerToolTip("Webex Email Validation", true)}
          />
          <Column
            width="150px"
            cell={this.ValidationButton}
            filterable={false}
          />
        </Grid>
      );
    } else {
      return <DataLoadingSpinner className="load spinner" />;
    }
  };

  sortChange = async (event) => {
    await this.setState({
      Listings: orderBy(this.state.Listings, event.sort),
      Sort: event.sort,
    });
  };

  filterChange = async (event) => {
    await this.setState({
      filter: event.filter,
    });
  };

  handleClearFilter = () => {
    let newFilter = {
      logic: "and",
      filters: [],
    };
    this.setState({ filter: newFilter });
  };

  handleEmailFilter = async () => {
    let newFilter = {
      logic: "and",
      filters: [
        {
          field: "WebExEmail",
          operator: "contains",
          value: "matt",
          ignoreCase: true,
        },
      ],
    };
    this.setState({ filter: newFilter });
    // let services = filterBy(this.state.Listings, {
    //   logic: 'and',
    //   filters: [
    //     { field: 'WebExEmail', operator: 'contains', value: "Matt", ignoreCase: true },

    //   ]
    // });
    // console.log("SERVICES", services);
    // await this.setState({ Listings: services });
  };

  searchListings = (event) => {
    if (this.state.FullListings && this.state.FullListings.length > 0) {
      let searchText = event.target.value.trim();
      let fullDetails = this.state.FullListings;
      if (searchText !== "") {
        searchText = event.target.value;
        let services = filterBy(fullDetails, {
          logic: "or",
          filters: [
            {
              field: "LocationName",
              operator: "contains",
              value: searchText,
              ignoreCase: true,
            },
            {
              field: "WebExLicense",
              operator: "contains",
              value: searchText,
              ignoreCase: true,
            },
            {
              field: "PackageName",
              operator: "contains",
              value: searchText,
              ignoreCase: true,
            },
            {
              field: "FirstName",
              operator: "contains",
              value: searchText,
              ignoreCase: true,
            },
            {
              field: "LastName",
              operator: "contains",
              value: searchText,
              ignoreCase: true,
            },
            {
              field: "ServiceNumber",
              operator: "contains",
              value: searchText,
              ignoreCase: true,
            },
            {
              field: "WebExEmail",
              operator: "contains",
              value: searchText,
              ignoreCase: true,
            },
          ],
        });
        this.delayedSearch(services);
      } else {
        this.delayedSearch(fullDetails);
      }
    }
  };

  delayedSearch = async (services) => {
    await this.setState({ loading: true });
    if (this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.setState({ Listings: services, loading: false });
    }, settings.searchDelay);
  };

  dropDownOptions = (key) => {
    let sort = [{ field: key, dir: "asc" }];
    let listings = orderBy(this.state.Listings.slice(), sort);
    let info = [
      ...listings.map((item) => item[key]).filter(helper.onlyUnique),
    ].filter((nonEmpty) => nonEmpty);
    return info;
  };

  gridListings = (filter) => {
    return filterBy(orderBy(this.state.Listings, this.state.Sort), filter);
  };

  exportBtn = () => {
    if (this.state.Listings.length > 0) {
      return (
        <div id="users-csv" className="pull-right">
          <CSVLink
            data={this.state.Listings}
            filename={`${sessionStorage.userEnterpriseNumber}_ExportWebEx.csv`}
          >
            Export
          </CSVLink>
        </div>
      );
    } else return null;
  };

  exportUsers = () => {
    this.refs.downloadCsv.submit();
    this.refs.exportBtn.text = "Downloading...";
    setTimeout(() => {
      this.refs.exportBtn.text = "Export Users";
    }, 2000);
  };

  openAttachments = () => {
    this.refs.fileUploader.click();
  };

  addAttachment = async (event) => {
    let file = event.target.files[0];
    let reader = new FileReader();
    reader.addEventListener(
      "loadend",
      () => {
        let body = reader.result.split(",")[1];
        let name = file.name;
        this.createAttachment(body, name);
      },
      false
    );
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  createAttachment = async (body, name, ticketId) => {
    let apiPath = "admin/UploadWebExUsers";
    let attachmentDTO = {
      TicketId: this.state.TicketId,
      AttachmentBody: body,
      FileName: name,
    };
    await this.setState({ attachmentSubmitted: true });
    let response = await apiCalls
      .post(apiPath, "POST", JSON.stringify(attachmentDTO))
      .then((res) => res)
      .catch((err) => console.error(err));
    if (response.ok) {
      await this.setState({
        Submission: true,
        submitError: false,
      });
    } else {
      await this.setState({
        Submission: true,
        submitError: true,
      });
    }
  };

  uploadBtn = () => {
    return (
      <div
        id="users-upload"
        className="pull-right"
        style={{ marginRight: 10, paddingLeft: "10px" }}
      >
        <input
          type="file"
          id="file"
          accept=".csv"
          ref="fileUploader"
          style={{ display: "none" }}
          onChange={this.addAttachment}
          onClick={(event) => {
            event.target.value = null;
          }}
        />
        <a
          tabIndex="0"
          className="no-btn"
          ref="uploadBtn"
          onClick={(e) => this.openAttachments()}
        >
          Import Users
        </a>
      </div>
    );
  };
  cardContent = () => {
    if (!this.state.loading) {
      return (
        <Fragment>
          <div className="card-content">
            {/* <small>
              * When entering an email address, you can either press [Enter] or
              mouse click outside the email field to save your data.
            </small>
            {this.uploadBtn()}*/}
          </div>

          <> {this.gridContent()}</>
        </Fragment>
      );
    } else {
      return (
        <div>
          <DataLoadingSpinner
            style={{ marginTop: "3%" }}
            className="load spinner"
          />
        </div>
      );
    }
  };

  render() {
    return (
      <div className="webex-container">
        <div className="full-card container">
          <div className="webex card">
            <div className="card-header">
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <h1>Webex User List</h1>
                <DropDownList
                  style={{
                    width: "200px",
                    height: "30px",
                    position: "absolute",
                    left: "125px",
                  }}
                  data={webexemailfilter}
                  defaultValue="All"
                  onChange={this.onChange}
                />
                {/* {this.submissionError()} */}
                <div className="search">
                  <input
                    id="location_srch_tb"
                    type="text"
                    className="form-control search-control"
                    autoComplete={"off"}
                    onChange={this.searchListings}
                  />
                  <svg width="12" height="12" viewBox="0 0 23 23">
                    <path
                      d="m347 238c0-36-12-66-37-91-25-25-55-37-91-37-35 0-65 12-90 37-25 25-38 55-38 91 0 35 13 65 38 90 25 25 55 38 90 38 36 0 66-13 91-38 25-25 37-55 37-90z m147 237c0 10-4 19-11 26-7 7-16 11-26 11-10 0-19-4-26-11l-98-98c-34 24-72 36-114 36-27 0-53-5-78-16-25-11-46-25-64-43-18-18-32-39-43-64-10-25-16-51-16-78 0-28 6-54 16-78 11-25 25-47 43-65 18-18 39-32 64-43 25-10 51-15 78-15 28 0 54 5 79 15 24 11 46 25 64 43 18 18 32 40 43 65 10 24 16 50 16 78 0 42-12 80-36 114l98 98c7 7 11 15 11 25z"
                      transform="scale(0.046875 0.046875)"
                    ></path>
                  </svg>
                  {/* <span style={{ display: "block", maxWidth: "300px" }}>
                    Last Synced Date: 
                  </span> */}
                  {this.exportBtn()}

                  <Button
                    onClick={() => this.syncAllUsers(false)}
                    disabled={this.state.disableDirectorySycnButton}
                    style={{
                      marginTop: "10px",
                      marginBottom: "3px",
                      background: this.state.disableDirectorySycnButton
                        ? "darkgray"
                        : "",
                    }}
                  >
                    Directory Sync
                  </Button>
                  <br></br>
                  <div style={{ paddingLeft: "7px" }}>
                    Last Directory Sync Status: {this.state.directorySyncStatus}
                  </div>
                  <br></br>
                  <div style={{ paddingLeft: "7px" }}>
                    {this.state.directorySyncStatusTime}
                  </div>
                  <br></br>
                  <div style={{ paddingLeft: "7px" }}> {this.state.disableDirectorySycnButtonStatus}</div>
                </div>
              </div>
              <div style={{ position: "absolute", top: "80px" }}>
                Review Progress of Webex Activation:
                <div>Last Webex Status Update:{this.state.lastSyncedDate}</div>
              </div>
              {/* <div className="card-actions">
                {this.gridToolBar()}
                <span className={showAddDevice() ? 'visible' : 'hidden'}>
                  <DeviceAddModal loadDevices={this.loadDevices} />
                </span>
              </div> */}
            </div>
            {/* <div className="card-actions">
              <div className="search">
                <label htmlFor="fav_services_srch_tb" className="hidden">favorite services search</label>
                <input id="fav_services_srch_tb" type="text" autoComplete={"off"} className="form-control search-control" onChange={this.searchListings} />
                <svg width="12" height="12" viewBox="0 0 23 23"><path d="m347 238c0-36-12-66-37-91-25-25-55-37-91-37-35 0-65 12-90 37-25 25-38 55-38 91 0 35 13 65 38 90 25 25 55 38 90 38 36 0 66-13 91-38 25-25 37-55 37-90z m147 237c0 10-4 19-11 26-7 7-16 11-26 11-10 0-19-4-26-11l-98-98c-34 24-72 36-114 36-27 0-53-5-78-16-25-11-46-25-64-43-18-18-32-39-43-64-10-25-16-51-16-78 0-28 6-54 16-78 11-25 25-47 43-65 18-18 39-32 64-43 25-10 51-15 78-15 28 0 54 5 79 15 24 11 46 25 64 43 18 18 32 40 43 65 10 24 16 50 16 78 0 42-12 80-36 114l98 98c7 7 11 15 11 25z" transform="scale(0.046875 0.046875)"></path></svg>
              </div>
            </div> */}
            {this.cardContent()}
          </div>
        </div>
      </div>
    );
  }
}