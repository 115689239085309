import * as d3 from "d3";

export const setTools = (options) => {
  let { svg, data1, data2, data, type, height, width, margin, x, y, id, summary, deviceName, node, color1, color2, dateRange, axisLabel } = options;
  // Tooltip element
  const tooltip = d3
    .select(`#${id}`)
    .append("div")
    .style("opacity", 0)
    .attr("class", "tooltip")
    .style("background-color", "white")
    .style("border", "solid")
    .style("border-width", "2px")
    .style("border-radius", "5px")
    .style("padding", "5px");
  // Add "dots" when hovering datapoints
  const dots = svg
    .selectAll("dot")
    .data(data)
    .enter()
    .append("circle")
    .attr("cx", (d) => {
      return x(d.x);
    })
    .attr("cy", (d) => {
      return y(d.y);
    })
    .attr("r", 5)
    .attr("opacity", 0) // set the opacity to 0 to make the circles not visible
    .attr("fill", "#69b3a2")
    .on("mouseover", showTooltip)
    .on("mouseout", hideTooltip);
  // Create the horizontal line for clear y-axis visibility
  const horizontalLine = svg
    .append("line")
    .attr("class", "horizontal-line")
    .style("stroke-dasharray", "5, 5")
    .style("stroke", "black")
    .style("opacity", 0)
    .attr("y1", 0)
    .attr("y2", 0);
  // Create the vertical line for clear x-axis visibility
  const verticalLine = svg
    .append("line")
    .attr("class", "vertical-line")
    .style("stroke-dasharray", "5, 5")
    .style("stroke", "black")
    .style("opacity", 0)
    .attr("x1", 0)
    .attr("x2", 0)
    .attr("y1", 0)
    .attr("y2", height);
  // Hide tooltip
  function hideTooltip() {
    d3.select(this).transition().duration(100).style("opacity", 0);
    tooltip.style("display", "none");
    horizontalLine.style("opacity", 0);
    verticalLine.style("opacity", 0);
  };
  // Show tooltip
  function showTooltip(d) {
    // Get the mouse position relative to the chart container
    const [xPos, yPos] = d3.pointer(d);
    d3.select(this).transition().duration(100).style("opacity", 0.7);
    // Update the position of the horizontal line
    horizontalLine
      .style("opacity", 1)
      .attr("x1", 0)
      .attr("x2", width)
      .attr("y1", yPos)
      .attr("y2", yPos);
    // Update the position of the vertical line
    verticalLine
      .style("opacity", 1)
      .attr("x1", xPos)
      .attr("x2", xPos)
      .attr("y1", 0)
      .attr("y2", height);
    if (d) {
      // Display the tooltip if hovering datapoint
      tooltip
        .attr("pointer-events", "none")
        .attr("class", "tooltip")
        .style("opacity", 1)
        .style("height", "120px")
        .style("width", "200px")
        .style("left", "213.554px")
        .style("top", "9.8574px")
        .style("padding", "5px")
        .style("background", "lightgrey")
        .style("border", "1px solid #2b3d54")
        .style("border-radius", "5%")
        .style("color", "#2b3d54")
        .style("display", "inline-block")
        .html(
          `<div><b>Mbps: </b>${d.target.__data__.y}</div><br/><div><b>Date: </b>${d.target.__data__.x}</div><br/><div><b>Direction: </b>${d.target.__data__.Field}</div>`
        );
    } else {
      tooltip.style("opacity", 0);
    }
  };
};
