import React from 'react';
import { fetchData, post } from '../../../requests/api_calls';
import * as helper from '../../../scripts/helper';

const paramsSeparator = (apiPath) => apiPath.indexOf('?') >= 0 ? '&' : '?';

export const apiGetMACDPermissions = () => {
  return 'admin/macd/GetMacdPermissions';
}

export const apiGetUserLevelAddons = (loc_acct_num) => {
  return `admin/macd/GetAvailableAddonList?LocationAccountNumber=${loc_acct_num}`;
}

export const apiGetUserAvailableAddons = (currentLocation, partNum) => {
  return `admin/macd/GetAvailableAddonServiceList?LocationAccountNumber=${currentLocation}&AddonPartNumber=${partNum}`;
}

export const apiGetUserAssignedAddons = (currentLocation, partNum) => {
  return `admin/macd/GetAssignedAddonServiceList?LocationAccountNumber=${currentLocation}&AddonPartNumber=${partNum}`;
}

export const apiPostUnassignedAddons = () => {
  return 'admin/macd/UnassignAddon';
}

export const apiPostAssignedAddons = () => {
  return 'admin/macd/AssignAddon';
}

export const apiGetLocations = () => {
  let path = 'admin/Locations';
  return path;
}

export const apiGetProductPropertiesWithParams = (PartNumber) => {
  let path = 'admin/macd/GetProductProperties?PartNumber=' + PartNumber;
  return path;
}

export const apiLoadMACDWithParams = (OrderId) => {
  let path = 'admin/macd/GetOrder?OrderId=' + OrderId;
  return path;
}

export const apiLoadMACDLineItemList = (OrderId) => {
  let path = 'admin/macd/GetLineItemList?OrderId=' + OrderId;
  return path;
}

export const apiLoadMACDLineItemDetailListWithProperties = (OrderId, LineItemId) => {
  let path = 'admin/macd/GetItemDetailList?OrderId=' + OrderId + "&FilterProductProperties=false&LineItemId=" + LineItemId;
  return path;
}

// export const apiGetInventoryNumbersWithParams = (OrderId) => {
//   let path = 'admin/macd/GetInventoryNumbers?OrderId=' + OrderId;
//   return path;
// }

export const apiGetTerminatingNumbersWithParams = (OrderId, LocationId) => {
  return 'admin/macd/GetTerminatingNumbers?OrderId=' + OrderId + "&LocationId=" + LocationId;
}

export const apiAddServicesOrderWithLineItem = () => {
  return 'admin/macd/CreateOrder';
}

export const apiAddServicesSaveLineItem = () => {
  return 'admin/macd/SaveLineItem';
}

export const apiAddServicesDetailItem = () => {
  return 'admin/macd/SaveItemDetail';
}

export const apiUpdateDetailItem = () => {
  return 'admin/macd/UpdateItemDetail';
}

export const apiUpdateLineItem = () => {
  return 'admin/macd/UpdateLineItem';
}

export const apiSavedOrderPathWithParams = (OrderId) => {
  let path = 'admin/macd/GetOrder?OrderId=' + OrderId;
  return path;
}

export const apiPricebookPathWithParams = (currentLocation) => {
  let path = 'admin/macd/GetProductCatalog';
  path += '?LocationAccountNumber=' + currentLocation;
  path += paramsSeparator(path) + 'MacdType=ADD_SERVICES';
  path += paramsSeparator(path) + 'filter=STANDALONE_AND_ADDON_PRODUCTS';
  return path;
}
export const apiGetRCStateList = () => {
  return 'admin/macd/GetRCStateList';
}

export const apiGetDefaultRateCenterDetails = (OrderId, LocationId) => {
  return 'admin/macd/GetRCListByServiceAddress?OrderId=' + OrderId + '&LocationId=' + LocationId;
};

export const apiGetRCNameByState = (abbr) => {
  return 'admin/macd/GetRCListByState?RCState=' + abbr;
}

export const apiDiscardOrder = () => {
  return 'admin/macd/DiscardOrder';
}

export const apiDiscardLineItem = () => {
  return 'admin/macd/DiscardLineItem';
}

export const apiDiscardItemDetail = () => {
  return 'admin/macd/DiscardItemDetail';
}

export const apiDiscardTrunkGroupConfig = () => {
  return 'admin/macd/DiscardTrunkGroupConfig';
}

export const apiGetLocationList = (OrderId) => {
  return 'admin/macd/GetLocationList?OrderId=' + OrderId;
}
export const apiGetLocationDetails = (OrderId, LocationId) => {
  return 'admin/macd/GetLocation?OrderId=' + OrderId + '&LocationId=' + LocationId;
}
export const apiValidateMACD = (OrderId) => {
  return 'admin/macd/ValidateMacd?OrderId=' + OrderId;
}
export const apiValidateMACDLineItems = (OrderId, LocationId) => {
  return 'admin/macd/ValidateLineItemList?OrderId=' + OrderId + '&LocationId=' + LocationId;
}
export const apiValidateMACDDetailItems = (OrderId, LocationId) => {
  return 'admin/macd/ValidateItemDetailList?OrderId=' + OrderId + '&LocationId=' + LocationId;
}

export const apiGetMPOLocation = (OrderId, LocationId) => {
  return 'admin/macd/GetLocation?OrderId=' + OrderId + '&LocationId=' + LocationId;
}

export const apiUpdateLocation = () => {
  return 'admin/macd/UpdateLocation'
}

export const apiSubmitOrder = () => {
  return 'admin/macd/SubmitOrder';
}

export const apiTerminateOrder = () => {
  return 'admin/macd/TerminateOrder';
}

export const apiGetListingsForChangeExtension = (LocationAccountNumber) => {
  return `admin/macd/GetChangeExtensionList?LocationAccountNumber=${LocationAccountNumber}`;
}

export const apiUpdateExtension = () => {
  return 'admin/macd/SaveChangeExtensionList';
}
export const apiGetChangeLineTypeListings = (MomentumAccountNumber) => {
  return 'admin/macd/GetChangeLineTypeList?LocationAccountNumber=' + MomentumAccountNumber;
}
export const apiSubmitChangeLineType = () => {
  return 'admin/macd/SaveChangeLineTypeList'
}
export const apiGetChangeListingList = (MomentumAccountNumber) => {
  return 'admin/macd/GetChangeListingList?LocationAccountNumber=' + MomentumAccountNumber;
}
export const apiSubmitChangeListingList = () => {
  return 'admin/macd/SaveChangeListingList';
}

export const apiGetChangeSeatType = (MomentumAccountNumber) => {
  return `admin/macd/GetChangeSeatTypeList?LocationAccountNumber=${MomentumAccountNumber}`
}

export const apiSubmitChangeSeatType = () => {
  return `admin/macd/SaveChangeSeatTypeList`;
}

export const apiGetChangeCnam = (MomentumAccountNumber) => {
  return `admin/macd/GetChangeCNAMList?LocationAccountNumber=${MomentumAccountNumber}`;
}

export const apiSubmitChangeCnam = () => {
  return `admin/macd/SaveChangeCNAMList`;
}

export const apiGetBlockType = (MomentumAccountNumber) => {
  return `admin/macd/GetChangeBlockList?LocationAccountNumber=${MomentumAccountNumber}`
}

export const apiSubmitBlockType = () => {
  return `admin/macd/SaveChangeBlockList`;
}

export const apiGetVTNList = (MomentumAccountNumber) => {
  return `admin/macd/GetChangeVNTermNumberList?LocationAccountNumber=${MomentumAccountNumber}`;
}

export const apiSubmitVTN = () => {
  return `admin/macd/SaveChangeVNTermNumberList`;
}

export const apiRetrieveIPTrunks = (MomentumAccountNumber) => {
  return `admin/GetIpTrunkGroupList?LocationAccountNumber=${MomentumAccountNumber}`;
}

export const apiLoadMACDTrunkItemListWithProperties = (OrderId) => {
  return `admin/MACD/GetIPTrunkConfigList?OrderId=${OrderId}`;
}

export const apiLoadMACDAddressListWithProperties = (OrderId) => {
  return `admin/macd/GetE911AddressList?OrderId=${OrderId}`;
}

export const apiAddServicesIPTrunkItem = () => {
  return `admin/macd/SaveIPTrunkConfig`;
}

export const apiUpdateTrunkItem = () => {
  return 'admin/macd/UpdateIPTrunkConfig';
}

export const apiDiscardTrunkItem = () => {
  return 'admin/macd/DiscardIPTrunkConfig';
}

export const apiValidateTrunkItem = (OrderId, IPTrunkConfigId) => {
  return `admin/macd/DiscardIPTrunkConfig?OrderId=${OrderId}&IPTrunkConfigId=${IPTrunkConfigId}`;
}

export const locationSelectLabel = (locationAcctNumber) => {
  return (
    <div style={{ "fontSize": "medium", "marginBottom": "5px", "marginTop": "5px", "color": "red", "display": locationAcctNumber !== '' ? 'none' : 'block' }}>
      <b>​Please select a "Location" from the menu above.</b>
    </div>
  )
}

export const fetchItemConfig = async (OrderId, LocationId) => {
  let requests = [
    //fetchInventoryTN(OrderId),
    fetchRateCenterDetailsByServiceAddress(OrderId, LocationId),
    fetchTerminatingNumbers(OrderId, LocationId),
    fetchLocationDetails(OrderId, LocationId)
  ];
  return await Promise.all(requests).then(dataArray => dataArray);
}
// export const fetchInventoryTN = async (OrderId) => {
//   let apiPath = apiGetInventoryNumbersWithParams(OrderId);
//   return fetchData(apiPath).then(data => data);
// }

export const fetchRateCenterDetailsByServiceAddress = async (OrderId, LocationId) => {
  let apiPath = apiGetDefaultRateCenterDetails(OrderId, LocationId);
  return fetchData(apiPath).then(data => data);
}

export const fetchTerminatingNumbers = async (OrderId, LocationId) => {
  let apiPath = apiGetTerminatingNumbersWithParams(OrderId, LocationId);
  return fetchData(apiPath).then(data => data);
}

export const fetchLocationDetails = async (OrderId, LocationId) => {
  let apiPath = apiGetLocationDetails(OrderId, LocationId);
  return fetchData(apiPath).then(data => data);
}

export const fetchRateCenterOptions = async (StateAbbr) => {
  let apiPath = apiGetRCNameByState(StateAbbr);
  return fetchData(apiPath).then(data => data);
}

export const fetchAvailableAddons = (locationId) => {
  let apiPath = apiGetUserLevelAddons(locationId);
  return fetchData(apiPath).then(data => data);
}
//export const fetchAvailableList;
//export const fetchAssignedList;

export const RetrieveOrderDetails = async (OrderId) => {
  let requests = [
    fetchSavedOrder(OrderId),
    fetchLocationList(OrderId),
    fetchLineItemList(OrderId),
    fetchLineItemDetailList(OrderId),
    fetchTrunkItemList(OrderId),
    fetchOrderAddresses(OrderId)
  ];
  return await Promise.all(requests).then(dataArray => dataArray);
}
export const GetItemValidations = async (OrderId, LocationId) => {
  let requests = [
    fetchOrderValidation(OrderId) //may need to add back details
  ];
  return await Promise.all(requests).then(dataArray => dataArray);
}

export const fetchSavedOrder = async (OrderId) => {
  let apiPath = apiLoadMACDWithParams(OrderId);
  return fetchData(apiPath).then(data => data);
}
export const fetchLocationList = async (OrderId) => {
  let apiPath = apiGetLocationList(OrderId);
  return fetchData(apiPath).then(data => data);
}
export const fetchLineItemList = async (OrderId) => {
  let apiPath = apiLoadMACDLineItemList(OrderId);
  return fetchData(apiPath).then(data => data);
}
export const fetchLineItemDetailList = async (OrderId, LineItemId = 0) => {
  let apiPath = apiLoadMACDLineItemDetailListWithProperties(OrderId, LineItemId);
  return fetchData(apiPath).then(data => data);
}

export const fetchTrunkItemList = async (OrderId = 0) => {
  let apiPath = apiLoadMACDTrunkItemListWithProperties(OrderId);
  return fetchData(apiPath).then(data => data);
}

export const fetchOrderAddresses = async (OrderId = 0) => {
  let apiPath = apiLoadMACDAddressListWithProperties(OrderId);
  return fetchData(apiPath).then(data => data);
}

export const fetchOrderValidation = async (OrderId) => {
  let apiPath = apiValidateMACD(OrderId);
  return fetchData(apiPath).then(data => data);
}

export const fetchLocations = async () => {
  let apiPath = apiGetLocations();
  return fetchData(apiPath).then(data => data);
}

export const fetchPricebookDetails = async (LocationId) => {
  let apiPath = apiPricebookPathWithParams(LocationId);
  return fetchData(apiPath).then(data => data);
}

export const fetchMPOLocation = async (OrderId, LocationId) => {
  let apiPath = apiGetMPOLocation(OrderId, LocationId);
  return fetchData(apiPath).then(data => data);
}

export const fetchLineItemValidation = async (OrderId, LocationId) => {
  let apiPath = apiValidateMACDLineItems(OrderId, LocationId);
  return fetchData(apiPath).then(data => data);
}

export const fetchDetailItemValidation = async (OrderId, LocationId) => {
  let apiPath = apiValidateMACDDetailItems(OrderId, LocationId);
  return fetchData(apiPath).then(data => data);
}

export const UpdateLineItemInOrder = async (LineItem) => {
  let apiPath = apiUpdateLineItem();
  return post(apiPath, 'PATCH', JSON.stringify(LineItem)).then(data => data);
}

export const AddDetailItemToOrder = async (DetailItem) => {
  let apiPath = apiAddServicesDetailItem();

  return await post(apiPath, 'POST', JSON.stringify(DetailItem)).then(data => data);
}

export const UpdateDetailItemInOrder = async (DetailItem) => {
  let apiPath = apiUpdateDetailItem();
  let body = {

  };
  return await post(apiPath, 'PATCH', JSON.stringify(body)).then(data => data);
}

export const RemoveTrunkGroupConfigFromOrder = async(OrderId, LocationId, LineItemId, IPTrunkConfigId) => {
  let body = {
    OrderId: OrderId,
    LocationId: LocationId,
    LineItemId: LineItemId,
    IPTrunkConfigId: IPTrunkConfigId
  };
  let apiPath = apiDiscardTrunkGroupConfig();
  return await post(apiPath, 'PATCH', JSON.stringify(body)).then(data => data);
}

export const RemoveDetailItemFromOrder = async (OrderId, LocationId, LineItemId, DetailId) => {
  let body = {
    OrderId: OrderId,
    LocationId: LocationId,
    LineItemId: LineItemId,
    DetailId: DetailId
  };
  let apiPath = apiDiscardItemDetail();
  return await post(apiPath, 'PATCH', JSON.stringify(body)).then(data => data);
}

export const ItemValidations = async (OrderId, LocationId) => {
  let LineItemErrors = [];
  let DetailItemValidations = [];
  let TrunkGroupValidations = [];
  let [OrderValidationErrors] = await GetItemValidations(OrderId, LocationId);
  
  if (OrderValidationErrors && OrderValidationErrors.length > 0) {
    LineItemErrors = OrderValidationErrors.filter(function (o) { return o.ErrorSource === "LINE_ITEM" });
  
    DetailItemValidations = OrderValidationErrors.filter(function (o) { return o.ErrorSource == "ITEM_DETAIL" });
  // if (DetailItemValidations) {
  //   DetailItemValidations.map((div, idx) => {
  //     LineItemErrors.push(div);
  //   });
  // }

    TrunkGroupValidations = OrderValidationErrors.filter(function(o) { return o.ErrorSource === 'IPTRUNK_CONFIG'});
  // if (TrunkGroupValidations) {
  //   DetailItemValidations.map((tgv, idx) => {
  //     LineItemErrors.push(tgv);
  //   });
  // }

  }
  return [DetailItemValidations, LineItemErrors, TrunkGroupValidations];
}

export const LoadItem = (li, PartNumber, PartDescription, CurrentLocation, LocationName, MPOLocationId, TerminatingNumbersList
  , DefaultRateCenterOptions, RCStateOptions, DefaultRCState, DefaultRCName, CatalogItem, IsReadOnly = false) => {
  let NewItem = {
    LineItemId: li.LineItemId,
    OrderId: li.OrderId,
    LocationId: CurrentLocation,
    MPOLocationId: MPOLocationId,
    CatalogItemId: li.CatalogItemId,
    MRCAdjustedPrice: li.MRCAdjustedPrice,
    MRCDiscountPercent: li.MRCDiscountPercent,
    NRCAdjustedPrice: li.NRCAdjustedPrice,
    NRCDiscountPercent: li.NRCDiscountPercent,
    Quantity: li.NewCount === 0 ? 1 : li.NewCount,
    PartDescription: PartDescription,
    PartNum: PartNumber,
    Location: LocationName,
    configurationDetails: [],
    TerminatingNumbersList: TerminatingNumbersList,
    //InventoryTNList: InventoryTNList,
    DefaultRateCenterOptions: DefaultRateCenterOptions,
    RCStateOptions: RCStateOptions,
    DefaultRCState: !CatalogItem || (CatalogItem.CatalogTicker !== 'MTRTADNTFA' && CatalogItem.CatalogTicker !== 'MTRTIPPTFA') ? DefaultRCState : 'US',
    DefaultRCName: !CatalogItem || (CatalogItem.CatalogTicker !== 'MTRTADNTFA' && CatalogItem.CatalogTicker !== 'MTRTIPPTFA') ? DefaultRCName : 'Toll Free',
    isConfigured: false, //should be true by this page
    ReadOnly: IsReadOnly,
    CatalogTicker: CatalogItem ? CatalogItem.CatalogTicker : '',
    ErrorMessage: li.ErrorMessage
  };
  return NewItem;
}
//TODO: TrunkGroup Records
export const MapConfigDetails = (newCount, configDetailsArray, OrderItem, DetailId, DBConfigurationDetails, DBTrunkGroupConfigs = null, TrunkGroupList = null, AddressItems = null, ValidateTN = true) => {
  let newArray = configDetailsArray ? configDetailsArray.slice() : [];
  let iCount = 1;
  if (DBConfigurationDetails && DBConfigurationDetails.length > 0) {

    DBConfigurationDetails.map((itm, idx) => {
      //coming from the backend save
      
      let trunkItem = DBTrunkGroupConfigs ? DBTrunkGroupConfigs.filter(resp => resp.DetailId === itm.DetailId) : null;
      let trunkLabel = '';
      if (trunkItem)
        trunkItem = trunkItem[0];

      if (itm.IPTrunkGroupCustomerProductId && parseInt(itm.IPTrunkGroupCustomerProductId) > 0) {
        trunkLabel = TrunkGroupList.filter(resp => resp.CustomerProductId === parseInt(itm.IPTrunkGroupCustomerProductId))[0].LineDescription
      } else if (itm.ParentDetailId && parseInt(itm.ParentDetailId) > 0 && TrunkGroupList.filter(resp => resp.DetailId === parseInt(itm.ParnetDetailId)).length > 0)
        trunkLabel = TrunkGroupList.filter(resp => resp.DetailId === parseInt(itm.ParentDetailId))[0].LineDescription

      let newEntry = {
        ItemNumber: configDetailsArray && configDetailsArray.length > 0 ? configDetailsArray[idx].ItemNumber : iCount,
        LineItemId: OrderItem.LineItemId,
        DetailId: itm.DetailId && itm.DetailId > 0 ? itm.DetailId : DetailId,
        RCState: OrderItem.CatalogTicker !== "MTRTADNTFA" && OrderItem.CatalogTicker !== 'MTRTIPPTFA'  ? itm.RateCenterState : 'US',
        RCName: OrderItem.CatalogTicker !== "MTRTADNTFA" && OrderItem.CatalogTicker !== 'MTRTIPPTFA' ? itm.RateCenterName : 'Toll Free',
        LineFirstName: itm.LineFirstName,
        LineLastName: itm.LineLastName,
        CNAM: itm.CNAM,
        Extension: itm.Extension,
        ListingType: itm.ListingType,
        ListingName: itm.ListingName,
        OmitAddress: itm.OmitAddress,
        LineEmail: itm.LineEmail,
        TerminatingNumber: itm.TerminatingNumber,
        TerminatingNumbersList: OrderItem.TerminatingNumbersList,//need to check to make sure the item is in the list
        InventoryTN: itm.InventoryTN ? itm.InventoryTN : itm.ServiceNumber,
        //InventoryTNList: OrderItem.InventoryTNList,//need to check to make sure the item selected is in the list
        AccessCode: itm.AccessCode,
        IPTrunkGroupID: OrderItem.PartNum === 'IP100' ? (trunkItem && trunkItem.IPTrunkGroupID ? trunkItem.IPTrunkGroupID : '') : '',
        IPTrunkConfigId: trunkItem ? (trunkItem.IPTrunkConfigId || 0) : 0,
        TrunkId: (itm.ParentDetailId || itm.IPTrunkGroupCustomerProductId || 0),
        IPTrunkGroupCustomerProductId: (itm.IPTrunkGroupCustomerProductId || 0),
        ParentDetailId: (itm.ParentDetailId || 0),
        IPAddress1: trunkItem ? (trunkItem.IPAddress1 || '') : '',
        IPAddress2: trunkItem ? (trunkItem.IPAddress2 || '') : '',
        IPAddress3: trunkItem ? (trunkItem.IPAddress3 || '') : '',
        IPAddress4: trunkItem ? (trunkItem.IPAddress4 || '') : '',
        IPAddress5: trunkItem ? (trunkItem.IPAddress5 || '') : '',
        IPAddress6: trunkItem ? (trunkItem.IPAddress6 || '') : '',
        IPAddress7: trunkItem ? (trunkItem.IPAddress7 || '') : '',
        IPAddress8: trunkItem ? (trunkItem.IPAddress8 || '') : '',
        IPAddress9: trunkItem ? (trunkItem.IPAddress9 || '') : '',
        IPAddress10: trunkItem ? (trunkItem.IPAddress10 || '') : '',
        IPAddress11: trunkItem ? (trunkItem.IPAddress11 || '') : '',
        IPAddress12: trunkItem ? (trunkItem.IPAddress12 || '') : '',
        TrunkDescription: trunkItem ? (trunkItem.Description || '') : '',
        AssociatedTrunkDescription: trunkLabel,
        StartDate: trunkItem ? (trunkItem.StartDate || '') : '',
        CNAMDelivery: trunkItem ? (trunkItem.CNAMDelivery || false) : false,
        AllowInternational: trunkItem ? (trunkItem.AllowInternational || false) : false,
        TechPrefix: trunkItem ? (trunkItem.TechPrefix || '') : '',
        GroupPolicy: trunkItem ? (trunkItem.GroupPolicy || '') : '',
        ServiceStatus: trunkItem ? (trunkItem.ServiceStatus || '') : '',
        RCStateOptions: OrderItem.RCStateOptions && OrderItem.CatalogTicker !== "MTRTADNTFA" && OrderItem.CatalogTicker !== 'MTRTIPPTFA' ? OrderItem.RCStateOptions : OrderItem.CatalogTicker === 'MTRTADNTFA' || OrderItem.CatalogTicker === 'MTRTIPPTFA' ? getTollFreeStateOption() : [],
        RateCenterOptions: OrderItem.RateCenterOptions && OrderItem.CatalogTicker !== "MTRTADNTFA" && OrderItem.CatalogTicker !== 'MTRTIPPTFA' ? OrderItem.RateCenterOptions : OrderItem.CatalogTicker === 'MTRTADNTFA' || OrderItem.CatalogTicker === 'MTRTIPPTFA' ? getTollFreeCenterOption() : [],
        ErrorMessage: itm.ErrorMessage ? itm.ErrorMessage : '',
        Has911: itm.Has911 && itm.Has911 === true ? true : false,
        address: GetE911AddressObject(itm, AddressItems, ValidateTN),
        Description: itm.Description,
        Temp911AddressId: itm.Temp911AddressId
        // HasChanges: false
      }
      if (newEntry.RCState === OrderItem.DefaultRCState && OrderItem.CatalogTicker !== "MTRTADNTFA" && OrderItem.CatalogTicker !== 'MTRTIPPTFA') {
        newEntry.RateCenterOptions = OrderItem.DefaultRateCenterOptions;
      } //no idea what to do if not default state? 
      if (newEntry.TerminatingNumbersList.indexOf(newEntry.TerminatingNumber) === -1) {
        newEntry.TerminatingNumbersList.unshift(newEntry.TerminatingNumber);
      }
      // if (newEntry.InventoryTNList.indexOf(newEntry.InventoryTN) === -1) {
      //  newEntry.InventoryTNList.unshift(newEntry.InventoryTN);
      // }

      newArray.push(newEntry);
      iCount++;
    });
  }
  if (configDetailsArray) {
    while (newArray.length < newCount) {
      
      //let IPTrunkGroupID = 'New Trunk Group ' + newArray.length;

      let NewConfigItem = {
        ItemNumber: newArray.length + 1,
        LineItemId: OrderItem.LineItemId,
        DetailId: DetailId ? DetailId : 0,
        RCState: OrderItem.CatalogTicker === "MTRTADNTFA" || OrderItem.CatalogTicker === 'MTRTIPPTFA' ? "US" : OrderItem.DefaultRCState,
        RCName: OrderItem.CatalogTicker === "MTRTADNTFA" || OrderItem.CatalogTicker === 'MTRTIPPTFA' ? "Toll Free" : OrderItem.DefaultRCName,
        LineFirstName: "",
        LineLastName: "",
        CatalogItemId: OrderItem.CatalogItemId,
        CNAM: '',
        Extension: '',
        ListingType: '4',
        ListingName: '',
        OmitAddress: 0,
        TerminatingNumber: '',
        TerminatingNumbersList: OrderItem.TerminatingNumbersList,//need to check to make sure the item is in the list
        InventoryTN: '',
        //InventoryTNList: OrderItem.InventoryTNList,//need to check to make sure the item selected is in the list
        AccessCode: '',
        IPTrunkGroupID: '',
        IPTrunkConfigId: '',
        TrunkId: 0,
        IPTrunkGroupCustomerProductId: 0,
        ParentDetailId: 0,
        IPAddress1: '',
        IPAddress2: '',
        IPAddress3: '',
        IPAddress4: '',
        IPAddress5: '',
        IPAddress6: '',
        IPAddress7: '',
        IPAddress8: '',
        IPAddress9: '',
        IPAddress10: '',
        IPAddress11: '',
        IPAddress12: '',
        TrunkDescription: '',
        StartDate: '',
        CNAMDelivery: false,
        AllowInternational: false,
        TechPrefix: '',
        GroupPolicy: OrderItem.PartNum === 'IP100' ? 'round_robin' : '',
        ServiceStatus: OrderItem.PartNum === 'IP100' ? 'inservice' : '',
        RCStateOptions: OrderItem.RCStateOptions && OrderItem.CatalogTicker !== "MTRTADNTFA" && OrderItem.CatalogTicker !== 'MTRTIPPTFA' ? OrderItem.RCStateOptions : OrderItem.CatalogTicker === 'MTRTADNTFA' || OrderItem.CatalogTicker === 'MTRTIPPTFA' ? getTollFreeStateOption() : [],
        RateCenterOptions: OrderItem.DefaultRateCenterOptions && OrderItem.CatalogTicker !== "MTRTADNTFA" && OrderItem.CatalogTicker !== 'MTRTIPPTFA' ? OrderItem.DefaultRateCenterOptions : OrderItem.CatalogTicker == 'MTRTADNTFA' || OrderItem.CatalogTicker === 'MTRTIPPTFA' ? getTollFreeCenterOption() : [],
        ErrorMessage: DetailId && DetailId > 0 ? '' : 'Entry incomplete',
        address: GetBlankE911AddressObject(ValidateTN),
        Temp911AddressId: 0
      };

      newArray.push(NewConfigItem);
    }
  }
  return newArray;
}
export const GetBlankE911AddressObject = (ValidateTN = true) => {
  return {
    AddressId: 0,
    IsActive: false,
    Building: helper.ValueOfBuildingTag,
    StreetNumber: '',
    Street2: '',
    Street1: '',
    StreetSuffix: '',
    PostDirectional: '',
    PreDirectional: '',
    StreetName: '',
    ZipCode: '',
    State: '',
    City: '',
    BuildingValue: '',
    FloorValue: '',
    Unit: '',
    UnitValue: '',
    PhoneNumber: '',
    ValidateTN: ValidateTN,
    ErrorMessage: ''
  }
}
const GetE911AddressObject = (DetailItem, AddressItems) => {
  let addressItem = AddressItems.filter(resp => resp.AddressId === DetailItem.Temp911AddressId);
  if (addressItem && addressItem.length > 0) {
    return addressItem[0];
  } else return GetBlankE911AddressObject();
}

export const ErrorMessageDisplayItem = (msg) => {
  return (
    <li key={msg}>{helper.StringReplaceAll(msg, '</br>', '', false)}</li>
  );
}

export const rcStateOption = (state, index) => {
  return (
    <option key={state.Abbreviation} value={state.Abbreviation}>{state.NAME}</option>
  )
}
export const rcNameOption = (rc) => {
  if (rc.InventoryAvailable)
    return (
      <option key={rc.RCNameAbbr} value={rc.RCNameAbbr}>{rc.RCNameAbbr} / {rc.InventoryAvailable}</option>
    )
  else
    return (
      <option key={rc.RCNameAbbr} value={rc.RCNameAbbr}>{rc.RCNameAbbr}</option>
    );
}

const getTollFreeStateOption = () => {
  let returnObj = [];
  let tollFreeItem = {
    Abbreviation: 'US',
    NAME: 'US'
  }
  returnObj.push(tollFreeItem);
  let rcStateOptions = returnObj.map((state, index) => {
    return (rcStateOption(state, index));
  });
  return rcStateOptions;
}

const getTollFreeCenterOption = () => {
  let initialObj = [];
  let rcCenterOptions = [];
  let tollFreeItem = {
    RCNameAbbr: 'Toll Free',
    InventoryAvailable: null
  }
  initialObj.push(tollFreeItem);
  initialObj.map((rc, index) => {
    rcCenterOptions.push(rcNameOption(rc));
  });
  return rcCenterOptions;
}

export const getDefaultColumnConfig = () => {
  return {
    ShowTNFields: false,
    ShowTN: false,
    ShowCNAM: false,
    ShowLineFirst: false,
    ShowLineLast: false,
    ShowExtension: false,
    ExtensionRequired: false,
    ShowListingName: false,
    ShowListingType: false,
    ShowOmitAddress: false,
    ShowYPH: false,
    ShowDeviceManufacturer: false,
    ShowDeviceModel: false,
    ShowDeviceMAC: false,
    ShowTemporaryNumber: false,
    ShowLineEmail: false,
    ShowAccessCode: false,
    ShowRCFields: false,
    ShowTerminatingNumber: false,
    IsRequiredStandAlone: false,
    ShowTrunkGroupPicker: false,
    ShowIPAddresses: false,
    ShowTrunkDescription: false,
    ShowStartDate: false,
    ShowCNAMDelivery: false,
    ShowInternational: false,
    ShowTechPrefix: false,
    ShowGroupPolicy: false,
    ShowServiceStatus: false,
    ShowE911: false,
    ShowDescription: false,
    ConfigType: ''
    /* int show_trm_num_textbox 
     int show_bwks_status_nug 
     int show_dadl_status_nug 
     Nullable<int> show_e911_status_nug 
     Nullable<int> show_lnp_status_nug 
     Nullable<int> show_did_status_nug 
     Nullable<int> show_bill_status_nug 
     Nullable<int> show_cnam_status_nug 
     int is_incontact 
     int is_voice_stand_alone 
     int required_standalone 
     string portnew 
     string config_type 
     bool is_active 
     bool use_for_metered 
     bool use_for_unlimited 
     bool use_for_trunking 
     int has_tn 
     int has_911 
     int term_tn_qualifies  */
  }
}

export const getColumnConfigFromDatabase = (prodProps, usoc) => {
  let columnConfig =  {
    ShowTNFields: !!+(prodProps.has_tn),
    ShowTN: !!+(prodProps.show_tn_col),
    ShowCNAM: !!+(prodProps.show_cnam_col),
    ShowLineFirst: !!+(prodProps.show_line_name_col),
    ShowLineLast: !!+(prodProps.show_line_name_col),
    ShowExtension: !!+(prodProps.show_extn_col),
    ExtensionRequired: !!+(prodProps.ext_required),
    ShowListingName: !!+(prodProps.show_list_name_col),
    ShowListingType: !!+(prodProps.show_list_type_col),
    ShowOmitAddress: !!+(prodProps.show_omt_addr_col),
    ShowYPH: !!+(prodProps.show_yph_col),
    ShowDeviceManufacturer: !!+(prodProps.show_dev_manufacturer_col),
    ShowDeviceModel: !!+(prodProps.show_dev_model_col),
    ShowDeviceMAC: !!+(prodProps.show_dev_mac_col),
    ShowTemporaryNumber: !!+(prodProps.show_temp_col),
    ShowLineEmail: !!+(prodProps.show_line_email_col),
    ShowAccessCode: !!+(prodProps.show_access_code),
    ShowRCFields: !!+(prodProps.show_rc_col) && !!+(prodProps.show_tn_col),
    ShowTerminatingNumber: !!+(prodProps.show_trm_num_col),
    ShowTrunkGroupPicker: !!+(prodProps.trg_associate),
    ShowTrunkGroupPicker: !!+(prodProps.trg_asscoiate),
    ShowIPAddresses: !!+(prodProps.show_trg_ipaddr_col),
    ShowTrunkDescription: !!+(prodProps.show_trg_description_col),
    ShowStartDate: !!+(prodProps.show_trg_startdate_col),
    ShowCNAMDelivery: !!+(prodProps.show_trg_cnamdel_col),
    ShowInternational: !!+(prodProps.show_trg_intl_col),
    ShowTechPrefix: !!+(prodProps.show_trg_techprefix_col),
    ShowGroupPolicy: !!+(prodProps.show_trg_grppol_col),
    ShowServiceStatus: !!+(prodProps.show_trg_status_col),
    Show911: !!+(prodProps.has_911) && usoc === 'IP201',
    ShowDescription: !!+(prodProps.show_detail_description_col),
    ConfigType: prodProps.config_type,
    /* int show_trm_num_textbox 
    int show_bwks_status_nug 
    int show_dadl_status_nug 
    Nullable<int> show_e911_status_nug 
    Nullable<int> show_lnp_status_nug 
    Nullable<int> show_did_status_nug 
    Nullable<int> show_bill_status_nug 
    Nullable<int> show_cnam_status_nug 
    int is_incontact 
    int is_voice_stand_alone 
    int required_standalone 
    string portnew 
    string config_type 
    bool is_active 
    bool use_for_metered 
    bool use_for_unlimited 
    bool use_for_trunking 
    int has_tn 
    int has_911 
    int term_tn_qualifies  */
  };
  let className = 'product-configuration';
  if (columnConfig.ConfigType === 'NOCONFIG')
    className += ' product-configuration-xsmall';
  else
  {
    var iCount = 0;
    for (var name in columnConfig)
    {
      if (name === 'ConfigType')
        iCount = iCount;
      else if (name === 'ShowRCFields' && columnConfig[name] === true)
        iCount = iCount + 2;
      else if (columnConfig[name] === true)
        iCount = iCount + 1;
    }
    if (iCount < 3)
      className += ' product-configuration-xsmall';
    else if (iCount < 6)
      className += ' product-configuration-small';
    else if (iCount < 8)
      className += ' product-configuration-medium';
    else
      className += ' product-configuration-large';
  }

  return [columnConfig, className];

}

export const ReviewAndSaveNoConfigItem = async (OrderId, MPOLocationId, PartNum, LineItemId) => {
  //need to check product properties to see if detail item is to be saved
  let DetailId = 0;
  let ColumnConfig = getDefaultColumnConfig();
  let className = '';
  let apiPropertiesPath = apiGetProductPropertiesWithParams(PartNum);
  let prodProps = await fetchData(apiPropertiesPath).then(pp => pp).catch(reason => {
    console.error(reason);
  });

  [ColumnConfig, className] = getColumnConfigFromDatabase(prodProps, PartNum);

  if (prodProps && prodProps.config_type === 'NOCONFIG') {
    let apiPathAddDetailItem = apiAddServicesDetailItem();
    let newReqBody = {
      OrderId: parseInt(OrderId),
      LocationId: MPOLocationId,
      LineItemId: LineItemId,
      DetailId: 0,
      ServiceNumber: '',
      Extension: '',
      LineFirstName: '',
      LineLastName: '',
      ListingType: '4',
      ListingName: '',
      OmitAddress: '',
      CNAM: '',
      TerminatingNumber: '',
      LineEmail: '',
      RateCenterState: '',
      RateCenterName: '',
      AccessCode: '',
      IPTrunkGroupID: 0
    }
    let detailResponse = await post(apiPathAddDetailItem, 'POST', JSON.stringify(newReqBody)).then(data => data);
    if (detailResponse.ok) {
      let detailBody = JSON.parse(detailResponse.message);
      DetailId = detailBody.DetailId;
    } else {
      throw (detailResponse.message);
    }
  }
  return [DetailId, ColumnConfig, className];
}

export const configuredCheck = (newConfigDetails, LineItem, DetailItemValidations, LineItemErrors, TrunkItemValidations) => {
  let errors = [];
  let bCheck = true;
  let UnsavedItems = newConfigDetails.filter(res => res.DetailId === 0);
  if (UnsavedItems && UnsavedItems.length > 0) {
    return false;
  }
  if ((DetailItemValidations && DetailItemValidations.length > 0) || (LineItem.PartNum === 'IP100' && TrunkItemValidations && TrunkItemValidations.length > 0)) {
    bCheck = newConfigDetails.map((cd, idx) => {
      errors = DetailItemValidations.filter(resp => resp.SourceId === cd.DetailId);
      if (errors && errors.length > 0)
        return false;
      
      errors = TrunkItemValidations.filter(resp => resp.SourceId === cd.IPTrunkConfigId);
      if (errors && errors.length > 0)
        return false;
      
    });
  }
  if (bCheck.length && bCheck.length > 0 && bCheck.filter(resp => resp === false).length > 0)
    return false;

  if(LineItem.ErrorMessage !== '')
    return false;

  if (LineItemErrors && LineItemErrors.length > 0) {
    errors = LineItemErrors.filter(resp => resp.SourceId === LineItem.LineItemId);
      if (errors && errors.length > 0)
        return false;
  }
  //if there are E911 address validation errors we will not return 'configured'
  let AddressValidations = newConfigDetails.filter(res => parseInt(res.Temp911AddressId) !== 0 && res.address !== GetBlankE911AddressObject() && res.address.ErrorMessage !== '')
  if (AddressValidations && AddressValidations.length > 0)
    return false;

  return true;
}

export const ReviewPageUrl = (oid, vs) => {
  let uri = '/macd_review/' + oid;
  if (vs)
    uri += '/' + vs;
  return uri;
}
export const ConfirmPageUrl = (oid) => {
  return '/macd_confirm/' + oid;
}
export const AddEditPage = (oid = null) => {
  let uri = '/macd_add';
  if (oid)
    uri += '/' + oid;
  return uri;
}

export const UserAddOn = (oid = null) => {
  let uri = '/macd_user';
  if (oid) uri += '/' + oid;
  return uri;
}

export const AllSearch = () => {
  return '/macd_all';
}

export const DisplayLocationRequiredMsg = (location) => {
  if (location === 'All Locations') {
    return (
      <span style={{ "color": "red" }}><b>Please Select a Location Before Continuing</b></span>
    )
  } else return null;

}
export const GetDBBlockValue = (Directory, Operator, International, DomesticLD) => {
  if (Directory === true && Operator === true && International === true && DomesticLD === true)
    return 'ALL'
  else if (DomesticLD === true && Directory === false && Operator === false && International === false)
    return 'DomesticLD'
  else {
    let returnString = '';
    if (Directory === true) {
      returnString = '411Block';
    }
    if (DomesticLD === true) {
      returnString += "_DLD"
    }
    if (International === true) {
      returnString += returnString.length > 0 ? '_INTLD' : 'INTLD';
    }
    if (Operator === true) {
      returnString += returnString.length > 0 ? "_OS" : "OS"
    }
    return returnString;
  }
}
export const GetUIBlockObject = (BlockString) => {

  let returnObj = {
    Directory: false,
    Operator: false,
    International: false,
    DomesticLD: false
  };
  if (!BlockString || BlockString === '')
    return returnObj;

  let splitArray = BlockString.split('_');
  if (splitArray.length === 0) {
    let val = splitArray[0];

    if (val.length === 0)
      return returnObj
    else if (val === "ALL") {
      return {
        Directory: false,
        Operator: false,
        International: false,
        DomesticLD: false
      }
    }
    else if (val === "DomesticLD")
      return {
        Directory: false,
        Operator: false,
        International: false,
        DomesticLD: false
      }
  }
  else {
    splitArray.map((itm, idx) => {
      if (itm === "411Block")
        returnObj.Directory = true;
      else if (itm === "DLD" || itm === "DomesticLD")
        returnObj.DomesticLD = true;
      else if (itm === "INTLD")
        returnObj.International = true;
      else if (itm !== 'ALL')
        returnObj.Operator = true;
    });
    return returnObj;
  }
}
export const ConvertListingTypeNumToText = (numberString) => {
  switch (numberString) {
    case "1": return "LISTED";
    case "2": return "NONLIST";
    case "3": return "NONPUB";
    case "4": return "NOSUBMIT";
    default: return "";
  }
}

export const MACDTYPES = {
  AddServices: "ADD_SERVICES",
  AssignAddOn: "ASSIGN_USER_ADDON",
  UnassignAddOn: "UNASSIGN_USER_ADDON",
  ChangeSeatType: "CHNG_SEAT_TYPE",
  ChangeLineType: "CHNG_LINE_TYPE",
  ChangeListing: "CHNG_LISTING",
  ChangeCNAM: "CHNG_CNAM",
  ChangeExtension: "CHNG_EXT",
  ChangeBlock: "CHNG_BLOCK_CHANGE",
  ChangeVirtualTerminatingNumber: "CHNG_VIRT_TERM_NUM",
  EditAddOrder: "EDIT_ADD_ORDER",
  ChangeIPTrunkCpCount: 'CHNG_IPTRUNK_CP_CNT',
}

export const MACDStatus = {
  Open: "OPEN",
  Processing: "PROCESSING",
  Submitted: "SUBMITTED",
  Closed: "CLOSED",
  Deleted: "DISCARDED",
  Clarification: "CLARIFICATION",
  OnHold: "ON_HOLD",
  Error: "ERROR"
}

export const CheckPermissionsForLinks = (macdType) => {
  let perms = JSON.parse(sessionStorage.MacdPerms);
  switch (macdType) {
    case "ADD_SERVICES":
      return perms.addServices;
    case "ASSIGN_USER_ADDON":
      return perms.assignUserAddons;
    case "UNASSIGN_USER_ADDON":
      return perms.unassignUserAddons;
    case "CHNG_SEAT_TYPE":
      return perms.changeSeatType;
    case "CHNG_LINE_TYPE":
      return perms.changeLineType;
    case "CHNG_LISTING":
      return perms.changeListing;
    case "CHNG_CNAM":
      return perms.changeCNAM;
    case "CHNG_EXT":
      return perms.changeExtension;
    case "CHNG_BLOCK_CHANGE":
      return perms.changeBlock;
    case "CHNG_VIRT_TERM_NUM":
      return perms.changeVnTerminatingNumber;
    case 'CHNG_IPTRUNK_CP_CNT':
      return perms.ipTrunkCallPathBurst;
    default:
      return false;
  }
}

export const MACDTABS = {
  AddServices: "Add_Services",
  AssignAddOn: "ASSIGN_USER_ADDON",
  UnassignAddOn: "UNASSIGN_USER_ADDON",
  AddOns: 'User_Addons',
  AllRequests: 'All_Service_Changes',
  ChangeSeatType: "seat",
  ChangeLineType: "line",
  ChangeListing: "listing",
  ChangeCNAM: "cnam",
  ChangeExtension: "extension",
  ChangeBlock: "block",
  ChangeVirtualTerminatingNumber: "virtual",
  EditAddOrder: "EDIT_ADD_ORDER",
  ChangeIPTrunkCpCount: 'ipTrunk',
  OpenRequests: 'openreq'
}

export const QuantityValidationRules = 
  [
    { PartNum: 'FX417', DependencyList: ['FX418', 'FX423', 'FX424', 'FX425', 'FX426', 'FX427' ] },
    { PartNum: 'FX418', DependencyList: ['FX417', 'FX423', 'FX424', 'FX425', 'FX426', 'FX427' ] },
    // { PartNum: 'FX419', DependencyList: ['FX428', 'FX429', 'FX430', 'FX431', 'FX432' ] },
    { PartNum: 'FX423', DependencyList: ['FX417', 'FX418', 'FX424', 'FX425', 'FX426', 'FX427'  ] },
    { PartNum: 'FX424', DependencyList: ['FX417', 'FX418', 'FX423', 'FX425', 'FX426', 'FX427'  ] },
    { PartNum: 'FX425', DependencyList: ['FX417', 'FX418', 'FX423', 'FX424', 'FX426', 'FX427'  ] },
    { PartNum: 'FX426', DependencyList: ['FX417', 'FX418', 'FX423', 'FX424', 'FX425', 'FX427'  ] },
    { PartNum: 'FX427', DependencyList: ['FX417', 'FX418', 'FX423', 'FX424', 'FX425', 'FX426'  ] },
    { PartNum: 'FX428', DependencyList: ['FX419', 'FX429', 'FX430', 'FX431', 'FX432'  ] },
    { PartNum: 'FX429', DependencyList: ['FX419', 'FX428', 'FX430', 'FX431', 'FX432'  ] },
    { PartNum: 'FX430', DependencyList: ['FX419', 'FX428', 'FX429', 'FX431', 'FX432'  ] },
    { PartNum: 'FX431', DependencyList: ['FX419', 'FX428', 'FX429', 'FX430', 'FX432'  ] },
    { PartNum: 'FX432', DependencyList: ['FX419', 'FX428', 'FX429', 'FX430', 'FX431'  ] }
  ]


export const getIPTrunkGroupOptions = (trunks) => {
  let trunkOption = (val, text) => <option key={val} value={val}>{text}</option>;    
  let trunkOptions = trunks.map((record, idx) => { return trunkOption(record.ConcatId, record.LineDescription); });        
  return trunkOptions;
}