import React, { Component } from 'react';
import { DataLoadingSpinner } from './../../components/spinners';
import ConfigureDevicesModal from './modals/configureDevicesModal';
import DeviceHistoryModal from './modals/deviceHistoryModal';
import DeviceResetModal from './modals/deviceResetModal';
import DeviceAddModal from './modals/deviceAddModal';
import moment from 'moment';
import DeviceEditModal from './modals/deviceEditModal';
import * as helper from './../../scripts/helper';
import * as settings from './../../scripts/settings';
import * as apiCalls from './../../requests/api_calls';
import * as config from './../../requests/config';
import { showRemove, showAddDevice } from './devices_helpers';
import { Grid, GridColumn as Column, GridCell } from '@progress/kendo-react-grid';
import { orderBy } from '@progress/kendo-data-query';
import { Link } from 'react-router-dom';
import WrappedCell from '../../components/wordWrappedCell';
import { headerToolTip } from '../../components/ellipsisHeaderTooltip';
import ServiceTnClickPop from './../../components/serviceTnClickPop';
import DeviceStatuses from '../../components/deviceStatuses';
import GroupFQDNTab from './components/groupFQDNTab';
import GroupVLANTab from './components/groupVLANTab';

class HistoryBtn extends GridCell {

  render() {
    return <td><DeviceHistoryModal mac={this.props.dataItem.MacAddress} /></td>
  }
}

class ServiceLink extends GridCell {

  render() {
    let item = this.props.dataItem;
    let path = { pathname: '/services', searchTerm: item.ServiceId }
    return (
      <td>
        <Link to={path} className="no-btn" title={item.AssignedTo}>{item.AssignedTo}</Link>
      </td>
    );
  }
}

class ServiceNumbersClickPop extends GridCell {
  render() {
    if (this.props.dataItem.TnCount > 0) {
      return (
        <td>
          <ServiceTnClickPop
            TnCount={this.props.dataItem.TnCount}
            MacAddress={this.props.dataItem.MacAddress}
          />
        </td>
      )
    } else return <td>{this.props.dataItem.TnCount}</td>
  }
}

class DevicesList extends Component {

  constructor(props) {
    super(props);


    this.state = {
      allDevicesNoFilter: [],
      allDevices: [],
      visibleDevices: [],
      sort: [{ field: "InsertDate", dir: "desc" }],
      gridHeight: (window.innerHeight - 180),
      activeTab: 'allDevices',
      loading: true,
      searchTerm: '',
      devicesCount: ''
    };
    this.sortChange = this.sortChange.bind(this);
    this.searchDevices = this.searchDevices.bind(this);
    // defining classes within constructor to have access to same state and functions

    const loadDevices = this.loadDevices.bind(this);
    const unassignedDevices = this.unassignedDevices.bind(this);

    class EmptyCell extends GridCell {
      render() {
        return <td></td>
      }
    }
    this.emptyCell = EmptyCell;

    class MacAddressCell extends GridCell {
      render() {
        let device = this.props.dataItem;
        let fieldClass = device.IsRegistered ? 'text-success' : 'text-danger';
        if (device.RegistrationType === "sca") {
          return (
            <td><span className={fieldClass}>{device.MacAddress}&nbsp;</span>
              <span className='text-danger'><sup>SCA</sup></span>
            </td>
          )
        } else {
          return <td><span className={fieldClass}>{device.MacAddress}</span></td>
        }
      }
    }
    this.macAddressCell = MacAddressCell;

    class ConfigureBtn extends GridCell {
      render() {
        return (
          <td>
            <ConfigureDevicesModal
              mac={this.props.dataItem.MacAddress}
              LocationAccountNumber={this.props.dataItem.LocationAccountNumber}
              loadDevices={loadDevices}
              unassignedDevices={unassignedDevices()}
            />
          </td>
        )
      }
    }
    this.configureBtn = ConfigureBtn;


    class UnassignBtn extends GridCell {

      render() {
        return (
          <td>
            <DeviceResetModal
              device={this.props.dataItem}
              loadDevices={loadDevices}
            />
          </td>
        )
      }
    }
    this.unassignBtn = UnassignBtn;

    class EditBtn extends GridCell {
      render() {
        return <td><DeviceEditModal device={this.props.dataItem} loadDevices={loadDevices} /></td>
      }
    }
    this.editBtn = EditBtn;
  };


  handleResize = () => this.setState({
    gridHeight: (window.innerHeight - 180)
  });

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize)
  }


  componentDidMount() {
    this.handleResize();
    window.addEventListener('resize', this.handleResize)
    this.loadDevices();
  }

  loadDevices = (showAll = false) => {
    // defining function without args to concisely pass as callback
    let bShowAll = true;
    if (showAll === false && this.state.activeTab !== 'allDevices')
      bShowAll = false;

    this.fetchDevices('admin/GetDevices', bShowAll, false, true);
  }

  fetchDevices = (apiPath, showAll = false, forceRefresh = false, setFullListings = false) => {
    this.setState({ loading: true });
    apiCalls.fetchData('admin/QuickView').then((data) => {
      if (data)
        this.setState({ devicesCount: data.CountDevices })
    })
    apiCalls.fetchData(apiPath).then((data) => {
      this.fetchDevicesCallback(data, showAll, forceRefresh, setFullListings);
    }).catch(ex => {
      console.log(ex);
      this.setState({ loading: false, allDevices: [], visibleDevices: [] })
    });
  }
  fetchDevicesCallback(data, showAll, forceRefresh, setFullListings) {
    let currentTab = this.state.activeTab;
    if (data !== null) {
      let allDevices = this.normalizedData(data);
      let sortedData = this.sortDevices(this.state.sort, allDevices);
      let filteredData = [];
      if (showAll === true) {
        filteredData = sortedData;
        currentTab = 'allDevices';
      }
      else {
        if (currentTab === 'assigned')
          filteredData = this.assignedDevices(sortedData);
        else
          filteredData = this.unassignedDevices(sortedData);
      }
      let existingArray = this.state.allDevicesNoFilter;
      this.setState({
        allDevicesNoFilter: setFullListings === true ? allDevices : existingArray,
        loading: false,
        allDevices: allDevices,
        visibleDevices: filteredData,
        activeTab: currentTab

      });
    }
    else if (data === null && !forceRefresh) {
      this.setState({ allDevices: [], visibleDevices: [], loading: false });
    }
  }

  normalizedData = (data) => {
    return data.slice().map(item => {
      if (item.FQDN === '' || item.FQDN === null) item.FQDN = 'N/A';
      if (item.VideoEnabled === true) item.VideoEnabled = 'Yes';
      else item.VideoEnabled = 'No';
      item.InsertDate = helper.convertToLocalTime(item.InsertDate);
      return item
    })
  }

  assignedDevices = (allDevices = this.state.allDevicesNoFilter) => {
    return allDevices.filter(device => device.AssignedTo !== null);
  }

  unassignedDevices = (allDevices = this.state.allDevicesNoFilter) => {
    return allDevices.filter(device => device.AssignedTo === null);
  }

  showAssigned = () => {
    let assigned = this.assignedDevices(this.state.allDevices);
    assigned = this.sortDevices(this.state.sort, assigned);
    this.setState({ visibleDevices: assigned, activeTab: 'assigned' });
  }

  showUnassigned = () => {
    let unassigned = this.unassignedDevices(this.state.allDevices);
    unassigned = this.sortDevices(this.state.sort, unassigned);
    this.setState({ visibleDevices: unassigned, activeTab: 'unassigned' });
  }

  showAllDevices = () => {
    let allDevices = this.state.allDevices;
    allDevices = this.sortDevices(this.state.sort, allDevices);
    this.setState({ visibleDevices: allDevices, activeTab: 'allDevices' });
  }

  showGroupFQDN = () => {
    this.setState({ activeTab: 'groupFQDN' });
  }

  showVLANSettings = () => {
    this.setState({ activeTab: 'groupVLAN' });
  }

  sortDevices(sort, visibleDevices = null) {
    if (visibleDevices == null) visibleDevices = this.state.visibleDevices.slice();
    let sortedSet = orderBy(visibleDevices, sort);
    if (sort.length && sort[0].field !== 'TnCount' && sort[0].field !== 'AddedDate') {
      return helper.blanksAfterZ(sortedSet, sort);
    } else {
      return sortedSet;
    }
  }

  sortChange(event) {
    this.setState({
      visibleDevices: this.sortDevices(event.sort),
      sort: event.sort
    });
  }

  searchDevices = (event) => {
    let tab = this.state.activeTab;
    let searchText = event.target.value.trim();
    this.setState({ searchTerm: searchText });
    var apiPath = ''
    if (searchText !== '') {
      apiPath = 'admin/GetDevices?term=' + searchText;
    } else { // reset to all devices
      apiPath = 'admin/GetDevices';
    }
    this.delayedSearch(apiPath, tab);
  }

  delayedSearch = (apiPath, tab) => {

    let bShowAll = tab === 'allDevices' ? true : false;
    if (this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.fetchDevices(apiPath, bShowAll);
    }, settings.searchDelay);
  }

  exportDevicesBtn = () => {
    if (this.state.allDevices.length > 0) {
      return (
        <div id="div-calls-csv" className='pull-right'>
          <form ref='exportDevices' target="iFrameSave" action={config.baseApiUrl + 'admin/GetDevicesCSV'} method="POST">
            <input type="hidden" name="access_token" value={config.storedAccessToken()} className='hidden'></input>
            <input type="hidden" name="x_policy" value={config.storedXPolicy()} className='hidden'></input>
          </form>
          <iframe name="iFrameSave" title="export" height="0" width="0" className='hidden pull-right'></iframe>
          <a tabIndex="0" className='no-btn' ref='exportBtn' onClick={(e) => this.exportDevices()} >Export Devices</a>
        </div>
      )
    } else null;
  }

  exportDevices = () => {
    this.refs.exportDevices.submit();
    this.refs.exportBtn.text = 'Downloading...';
    setTimeout(() => {
      this.refs.exportBtn.text = 'Export Devices';
    }, 2000);
  }

  tabContent = () => {
    if (this.state.loading) {
      return <DataLoadingSpinner className='load spinner' />
    } else if (this.state.activeTab === 'groupFQDN') {
      return (
        <GroupFQDNTab />
      );
    } else if (this.state.activeTab === 'groupVLAN') {
      return (
        <GroupVLANTab />
      );
    } else {
      return (
        <Grid
          style={{ height: this.state.gridHeight }}
          data={this.state.visibleDevices}
          sortable={{ allowUnsort: true, mode: 'single' }}
          sort={this.state.sort}
          onSortChange={this.sortChange}
          scrollable={'scrollable'}
        >
          <Column width='130px' field='MacAddress' cell={this.macAddressCell} title={'MAC'} />
          <Column field='MakeModel' width='180px' title={headerToolTip('Make Model')} />
          <Column field='AcqCode' width='80px' title={'ACQ Code'} />
          <Column width='200px' field='AssignedTo' title={headerToolTip('Assigned To')} cell={ServiceLink} />
          <Column field='LocationName' width='140px' title={headerToolTip('Location')} />
          <Column width="70px" field='FQDN' cell={WrappedCell} title={headerToolTip('FQDN')} />
          <Column width='60px' field='TnCount' cell={ServiceNumbersClickPop} title={'TN Count'} />
          <Column width="120px" field='VideoEnabled' title={headerToolTip('Video Enabled')} />
          <Column field='InsertDate' title={headerToolTip('Date Added')} width="150px" format="{0:MM/dd/yyyy hh:mm a}" />
          <Column width='68px' title={headerToolTip('History')} cell={HistoryBtn} sortable={false} />
          <Column width='120px' title=' ' cell={this.configureBtn} sortable={false} />
          {showRemove() ? <Column width={'120px'} title=' ' cell={this.unassignBtn} sortable={false} /> : null}
          <Column width={'100px'} title=' ' cell={this.editBtn} sortable={false} />
          <Column field="spacer" title=" " sortable={false} filterable={false} />
        </Grid>
      )
    }
  }

  tabClass = (tabName) => this.state.activeTab === tabName ? 'tab active' : 'tab';

  checkRegistration = () => {
    this.setState({ loading: true });
    let searchTerm = this.state.searchTerm;
    let apiPath = '';
    if (searchTerm && searchTerm !== '')
      apiPath = `admin/GetDevices?term=${searchTerm}&ForceDeviceRefresh=TRUE`;
    else
      apiPath = 'admin/GetDevices?ForceDeviceRefresh=TRUE';

    apiCalls.fetchData(apiPath).then((data) => {
      this.fetchDevicesCallback(data, this.state.activeTab === 'allDevices', true)
      if (data !== null) {
        this.setState({
          registrationUpdated: helper.registrationLastUpdated()

        });
      } else {
        this.setState({ loading: false, registrationUpdated: 'Failed to retrieve registration status: ' + moment().format('M-DD-YYYY hh:mm:ss A') })
      }
    }).catch(ex => {
      console.log(ex);
      this.setState({ locations: [], loading: false });
    });
  }

  render() {
    return (
      <div className="full-card container">
        <div className="devices-card card">
          <div className="card-header">
            <h1>Devices <small id='devices-count'>{this.state.devicesCount || null}</small></h1>
            <div className="card-actions">
              <div className="search">
                <label htmlFor="dev_list_srch_tb" className="hidden">device list search</label>
                <input id="dev_list_srch_tb" type="text" className="form-control search-control" onChange={this.searchDevices} value={this.state.searchTerm} autoComplete={"off"} />
                <svg width="14" height="14" viewBox="0 0 23 23"><path d="m347 238c0-36-12-66-37-91-25-25-55-37-91-37-35 0-65 12-90 37-25 25-38 55-38 91 0 35 13 65 38 90 25 25 55 38 90 38 36 0 66-13 91-38 25-25 37-55 37-90z m147 237c0 10-4 19-11 26-7 7-16 11-26 11-10 0-19-4-26-11l-98-98c-34 24-72 36-114 36-27 0-53-5-78-16-25-11-46-25-64-43-18-18-32-39-43-64-10-25-16-51-16-78 0-28 6-54 16-78 11-25 25-47 43-65 18-18 39-32 64-43 25-10 51-15 78-15 28 0 54 5 79 15 24 11 46 25 64 43 18 18 32 40 43 65 10 24 16 50 16 78 0 42-12 80-36 114l98 98c7 7 11 15 11 25z" transform="scale(0.046875 0.046875)"></path></svg>
              </div>
              <span className={showAddDevice() ? 'visible' : 'hidden'}>
                <DeviceAddModal loadDevices={this.loadDevices} />
              </span>

            </div>
            <DeviceStatuses
              checkRegistration={this.checkRegistration}
              updated={this.state.registrationUpdated}
            />
          </div>
          <div>
            <div className="tabs">
              <a onClick={this.showAllDevices} className={this.tabClass('allDevices')}>
                All Devices
              </a>
              <a onClick={this.showAssigned} className={this.tabClass('assigned')}>
                Assigned
              </a>
              <a onClick={this.showUnassigned} className={this.tabClass('unassigned')}>
                Unassigned
              </a>
              <a onClick={this.showGroupFQDN} className={this.tabClass('groupFQDN')} style={{ display: helper.showEditGroupFqdnBtn() ? 'inline-block' : 'none' }}>
                Group FQDN Settings
              </a>
              <a onClick={this.showVLANSettings} className={this.tabClass('groupVLAN')} style={{ display: helper.showEditGroupVlanBtn() ? 'inline-block' : 'none' }}>
                Group VLAN Settings
              </a>
              {this.exportDevicesBtn()}
            </div>
            {this.tabContent()}
          </div>
        </div>
      </div>
    )
  }
}

export default DevicesList;