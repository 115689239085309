import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { Button } from '@progress/kendo-buttons-react-wrapper';
import { DataLoadingSpinner } from '../../../components/spinners';
import { Grid, GridColumn as Column, GridCell } from '@progress/kendo-react-grid';
import { orderBy } from '@progress/kendo-data-query';
import { filterBy } from '@progress/kendo-data-query';
import * as helper from '../../../scripts/helper';
import * as macdHelper from '../components/macd_helper';
import * as apiCalls from '../../../requests/api_calls';
import _ from 'lodash';
import MACDSaveStatus from '../modals/orderResultModal';
import ValidationCell from '../components/validationCell';
import MACDSearch from '../components/macdSearch';

export default class IpTrunkTab extends Component {
  constructor(props) {
    super(props);

    this.state = {
      Listings: [],
      FullListings: [],
      OriginalListings: [],
      NewPlanList: [],
      loading: false,
      saving: false,
      gridHeight: props.GridHeight,
      MomentumAccountNumber: props.MomentumAccountNumber,
      LocationName: props.LocationName,
      isCancelDialogOpen: false,
      ValidationErrorMessages: [],
      Sort: [{ field: "TrunkGroupID", dir: "asc" }],
    }
    const updateInputValue = this.updateInputValue.bind(this);
    const getNewPlanList = this.getNewPlanList.bind(this);

    class inputCell extends GridCell {
      constructor(props) {
        super(props);
      }
      render() {
        const item = this.props.dataItem;
        const field = this.props.field;
        let err = '';
        if (field === 'NewQuantity' && item[field] != '' && ((parseInt(item[field]) === item['Quantity']) || isNaN(parseInt(item[field])) === true)) {
          err = 'err';
        }
        return (
          <td>
            <input type="text"
              onChange={(e) => updateInputValue(item, field, e)}
              style={{
                width: '50%',
                border: (err && err === 'err') ? '2px solid red' : ''
              }}
              value={item[field]}
            ></input>
          </td>
        )
      }
    };

    class DropdownCell extends GridCell {
      constructor(props) {
        super(props);
      }
      render() {
        const item = this.props.dataItem;
        const field = this.props.field;
        let options = getNewPlanList();
        let existingPath = <option key={item.PartDescription} value={item.PartDescription}>{item.PartDescription}</option>
        let finalOptions = options.slice();
        finalOptions.push(existingPath);
        return (
          <td>
            <select
              style={{
                width: "175px",
              }}
              value={item[field]}
              onChange={(e) => updateInputValue(item, field, e)}
            >
              {finalOptions}
            </select>
          </td>
        )
      }
    };

    this.inputCell = inputCell;
    this.DropdownCell = DropdownCell;
    this.ValidationCell = ValidationCell;

  }

  componentDidMount() {
    if (this.state.MomentumAccountNumber !== '') {
      this.fetchListings();
    }
  }

  async componentWillReceiveProps(props) {
    if (props) {
      if (props.MomentumAccountNumber != this.state.MomentumAccountNumber) {
        await this.setState({
          MomentumAccountNumber: props.MomentumAccountNumber,
          LocationName: props.LocationName,
          ValidationErrorMessages: []
        });
        this.fetchListings();
      }
      if (props.GridHeight && props.GridHeight !== this.state.gridHeight)
        this.setState({ gridHeight: props.GridHeight });
    }
  }

  getNewPlanList = () => {
    let newPlanList = JSON.parse(JSON.stringify(this.state.NewPlanList));
    return newPlanList.map(itm => <option key={itm.PartDescription} value={itm.PartDescription}>{itm.PartDescription}</option>);
  }

  fetchListings = async () => {
    await this.setState({ loading: true });
    let apiPath = `admin/GetChangeIpTrunkCPCountList?LocationAccountNumber=${this.state.MomentumAccountNumber}`;
    let response = await apiCalls.fetchData(apiPath).then(data => data).catch(err => console.error(err));
    if (response && response.ServiceList && response.ServiceList.length > 0) {
      let { ServiceList, NewPlanList } = response;
      let copy = JSON.parse(JSON.stringify(ServiceList));
      ServiceList.forEach(res => res.NewQuantity = '');
      this.setState({
        loading: false,
        Listings: ServiceList,
        FullListings: ServiceList,
        OriginalListings: copy,
        NewPlanList: NewPlanList,
      });
    } else {
      this.setState({
        loading: false,
        Listings: [],
        FullListings: [],
        OriginalListings: [],
      });
    }
  }

  CancelYesAction = async () => {
    await this.fetchListings();
    this.props.TabHasChanges(false);
    this.setState({ isCancelDialogOpen: false, ValidationErrorMessages: [] });

  }

  closeCancelDialog = () => {
    this.setState({ isCancelDialogOpen: false });
  }

  handleSave = async () => {
    let changedItems = this.state.Listings.filter(res => res.HasChanges === true);
    let quantityComparison = changedItems.filter(itm => itm && itm.NewQuantity && parseInt(itm.NewQuantity) === itm.Quantity); // Q cant be same as before
    let quantityIsNan = changedItems.filter(itm => itm && itm.NewQuantity && (isNaN(parseInt(itm.NewQuantity)) === true)); // Q must be number
    if (!changedItems || changedItems.length === 0 || (quantityComparison && quantityComparison.length > 0) || (quantityIsNan && quantityIsNan.length > 0)) {
      return;
    }
    await this.setState({ saving: true });
    let originalListings = this.state.OriginalListings.slice();
    let newPathPlan = this.state.NewPlanList.slice()
    let submittedItems = changedItems.map((itm, idx) => {
      let oldService = originalListings.filter(res => res.CustomerProductId === itm.CustomerProductId);
      let newPathCatalogId = newPathPlan.filter(res => res.CatalogItemId === itm.CatalogItemId)[0].CatalogItemId;
      return {
        OldService: oldService[0],
        Quantity: parseInt(itm.NewQuantity),
        CatalogItemId: newPathCatalogId,
        TrunkGroupID: itm.TrunkGroupID
      };
    });
    let reqBody = {
      LocationAccountNumber: this.state.MomentumAccountNumber,
      ServiceList: submittedItems,
    }
    let apiPath = 'admin/macd/SaveChangeIpTrunkCPCountList';
    try
    {
      await apiCalls.post(apiPath, 'POST', JSON.stringify(reqBody)).then((res) => {
        let validationErrors = [];
        if (res.ok === true) {
          this.handleSaveSuccess();
        } else {
          if (helper.IsJsonString(res.message))
          {
            let responseObject = JSON.parse(res.message);
            if (responseObject.length && responseObject.length > 0) {
              validationErrors = responseObject.map((itm, idx) => {
                if (itm.CustomerProductId && itm.CustomerProductId != '')
                  return {
                    CustomerProductId: parseInt(itm.CustomerProductId),
                    ValidationMessage: helper.StringReplaceAll(itm.ErrorMessage, '</br>', '', false)
                  }
                else {
                  //console.log("NON VALIDATION ERROR FOUND!");
                  throw [helper.StringReplaceAll(itm.ErrorMessage, '</br>', '', false)];
                }
              });
            } else {
              if (responseObject.MPOStatus && responseObject.MPOStatus.MPOErrorList && responseObject.MPOStatus.MPOErrorList.length > 0) {
                let ErrorList = responseObject.MPOStatus.MPOErrorList[0];
                let ErrorMessages = ErrorList.MPOResultText;
                validationErrors = ErrorMessages.map((itm, idx) => {
                  if (itm.CustomerProductId && itm.CustomerProductId != '')
                    return {
                      CustomerProductId: parseInt(itm.CustomerProductId),
                      ValidationMessage: helper.StringReplaceAll(itm.ErrorMessage, '</br>', '', false)
                    }
                  else {
                    throw [helper.StringReplaceAll(itm.ErrorMessage, '</br>', '', false)];
                  }
                });
              }
              if (validationErrors) {
                this.showErrorAndResetModal('Your order could not be completed due to entry errors indicated in the grid.', validationErrors);
              }
            }
          } else {
            this.showErrorAndResetModal(res.message);
          }
        }
      }).catch((msg, ValidationErrors = null) => {
        this.showErrorAndResetModal(msg);
      }).finally(() => {
        this.setState({ saving: false });
      });
    }
    catch (e) {
      console.log('Error saving trunk macd');
    }
  }

  handleCancel = () => {
    let changedItems = this.state.Listings.filter(res => res.HasChanges === true);
    if (!changedItems || changedItems.length === 0) return;
    this.setState({ isCancelDialogOpen: true });
  }

  handleSaveSuccess = async () => {
    
    await this.setState({
      loading: true,
      ValidationErrorMessages: [],
      statusMessage: 'Your order has been saved and submitted successfully.'
    });
    this.props.TabHasChanges(false);
    this.fetchListings();
  }

  showErrorAndResetModal = (msg, ValidationErrorMessages = []) => {
    let inconsistentCatcher = null;
    if (msg && msg.message)
      inconsistentCatcher = msg.message;
    else if (msg)
      inconsistentCatcher = msg;
    else
      inconsistentCatcher = 'An unexpected error occurred';

    this.setState({
      error: true,
      statusMessage: inconsistentCatcher,
      ValidationErrorMessages: ValidationErrorMessages
    });
  }

  closeSaveMessage = async () => {
    let listings = orderBy(this.state.Listings.slice(), this.state.Sort);
    let validationErrors = this.state.ValidationErrorMessages.slice();
    validationErrors.map((itm, idx) => {
      let itmInListings = listings.filter(resp => resp.CustomerProductId === itm.CustomerProductId);
      let index = _.indexOf(listings, itmInListings[0]);
      listings[index].ValidationMessage = itm.ValidationMessage;
    });
    await this.setState({ error: false, statusMessage: '', Listings: listings, FullListings: listings });
  }

  updateInputValue = async (productItem, field, e) => {
    let { value } = e.target;
    productItem[field] = value;
    let existingArray = this.state.Listings.slice();
    let index = _.findIndex(existingArray, itm => itm.CustomerProductId === productItem.CustomerProductId);
    existingArray[index] = productItem;

    let originalItem = this.state.OriginalListings.filter(itm => itm.CustomerProductId === productItem.CustomerProductId);
    if (originalItem && originalItem.length > 0) {
      originalItem = originalItem[0]
      if (productItem['PartDescription'] !== originalItem['PartDescription'] || productItem['NewQuantity'] != '') {
        productItem.HasChanges = true;
        this.props.TabHasChanges(true);
      } else {
        delete productItem.HasChanges;
        this.props.TabHasChanges(false);
      }
    }
    await this.setState({
      Listings: existingArray,
      FullListings: existingArray,
    });
  }

  gridContent = () => {
    if (this.state.MomentumAccountNumber == '') {
      return macdHelper.locationSelectLabel(this.state.MomentumAccountNumber);
    }
    if (this.state.loading) {
      return <DataLoadingSpinner className="load spinner relative" />
    } else {
      if (!this.state.loading && this.state.Listings.length === 0) return <p>There are no applicable changes available for services at this location. Please review your search criteria.</p>
      if (this.state.Listings && this.state.Listings.length > 0) {
        let columnWidth = '200px';
        let columnWidth2 = '150px';
        return (
          <Grid
            style={{
              height: this.state.gridHeight + 'px',
              overflow: 'auto',
            }}
            data={this.state.Listings}
            sortable={{ allowUnsort: true, mode: 'single' }}
            sort={this.state.Sort}
            onSortChange={this.sortChange}
          >
            {this.state.ValidationErrorMessages && this.state.ValidationErrorMessages.length > 0 ? <Column field='ValidationMessage' title=" " width={'50px'} cell={this.ValidationCell} sortable={false} /> : null}
            <Column width={columnWidth} field='TrunkGroupID' title='Trunk Group ID' />
            <Column width={columnWidth} field='LineDescription' title='Description' />
            <Column width={columnWidth} field='USOC' title='USOC' />
            <Column width={columnWidth} field='PartDescription' title='Call Path Plan' cell={this.DropdownCell} />
            <Column width={columnWidth} field='Quantity' title='Trunk Limit' />
            <Column width={columnWidth} field='NewQuantity' title='New Trunk Limit' cell={this.inputCell} sortable={false} />
            <Column field="spacer" title=" " sortable={false} filterable={false} />
          </Grid>
        )
      }
    }
  }

  searchListings = (event) => {
    let searchText = event.target.value.trim();
    let fullDetails = this.state.FullListings.slice();
    if (searchText !== '') {
      searchText = event.target.value;
      let services = filterBy(fullDetails, {
        logic: 'or',
        filters: [
          { field: 'TrunkGroupID', operator: 'contains', value: searchText, ignoreCase: true },
          { field: 'USOC', operator: 'contains', value: searchText, ignoreCase: true },
          { field: 'PartDescription', operator: 'contains', value: searchText, ignoreCase: true },
          { field: 'LineDescription', operator: 'contains', value: searchText, ignoreCase: true}
        ]
      });

      this.setState({ Listings: services })
    }
    else {
      this.setState({ Listings: fullDetails });
    }

  }

  sortListings = (sort, Listings = null) => {
    let returnObj = [];
    if (this.state.Listings) {
      if (Listings == null) Listings = this.state.Listings.slice();
      let sortedSet = orderBy(Listings, sort);
      returnObj = sortedSet;
      if (sort.length) {
        returnObj = helper.blanksAfterZ(sortedSet, sort);
      }
    }
    return returnObj;
  }

  sortChange = async (event) => {
    await this.setState({
      Listings: this.sortListings(event.sort),
      Sort: event.sort
    });
  }

  render() {
    return (
      <div>
        <div style={{
          textAlign: "right",
          marginBottom: "5px",
          marginTop: "5px",
          display: this.state.loading || this.state.MomentumAccountNumber === '' ? "none" : "block"
        }}>
          <div className='search'>
            <MACDSearch SearchFunction={this.searchListings} />
            <Button click={this.handleCancel}>Cancel</Button>
            <Button click={this.handleSave}>Save</Button>
          </div>
        </div>
        <hr />

        <Modal dialogClassName="confirm-cancel-macd" show={this.state.isCancelDialogOpen} onHide={this.closeCancelDialog}>
          <Modal.Header closeButton>
            <Modal.Title>Cancel Order?</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div><p><span style={{ fontSize: "medium" }}>Are you sure you wish to cancel this order?</span></p></div>
          </Modal.Body>
          <Modal.Footer>
            <Button click={(evt) => { this.CancelYesAction() }}>Yes</Button>
            <Button click={(evt) => { this.closeCancelDialog() }}>No</Button>
          </Modal.Footer>
        </Modal>

        <MACDSaveStatus
          saving={this.state.saving}
          statusMessage={this.state.statusMessage}
          error={this.state.error}
          parentCallBack={this.closeSaveMessage}
        />

        {this.gridContent()}
      </div>
    );
  }
};