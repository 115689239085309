import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { Button } from '@progress/kendo-buttons-react-wrapper';

class ClientAppModal extends Component {

	constructor(props) {
		super(props);

    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);

    this.state = { showModal: false };
  }

  handleClose() {
    this.setState({ showModal: false });
  }

  handleShow() {
    this.setState({ showModal: true });
  }

	componentDidMount() {		
	}

  render() {
    return (
      <div className="setting">
        <span onClick={this.handleShow}> 
          <span className="name">Client App</span>
          <a className="icon btn"><svg width="14" height="14" viewBox="0 0 24 24"><path d="m163 440l-91-91 251-250 90 90z m309-352l-48-48c-12-11-32-11-45 2l-45 45 91 91 45-45c13-13 13-33 2-45z m-408 275l-32 117 117-32z" transform="scale(0.046875 0.046875)"></path></svg></a>
        </span>

        <Modal show={this.state.showModal} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Client App</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="clientApp">
              <p>To access the Contact Center Agent or Supervisor client applications, please click the link above. You will be prompted to enter a username and password. Your username and password will be the username and password you use to login to this portal; however, you will append the domain “@mymtm.us" to the username. See example and other settings below:</p>
              <p className="indent">Username (example): 5555551234@mymtm.us or 555551234X100@mymtm.us <br />
                  Password (example): *********</p>
              <p>Please contact your administrator or service provider to purchase Contact Center Agent and Supervisor client applications.</p>
              <a className="no-btn">Go To Client App</a>
            </div>
          </Modal.Body>
          <Modal.Footer>
              <hr />
              <div className="btns">
              <Button className="btn" click={this.handleClose}>Close</Button>
              </div>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
};

export default ClientAppModal;
