import React, { Component, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { createPortal } from 'react-dom';
import { fetchData } from '../../../requests/api_calls';
import { DataLoadingSpinner } from '../../../components/spinners';
import { Grid, GridColumn } from '@progress/kendo-react-grid';
import { useRef } from 'react';

import '../../../css/enterprise-settings/enterprise_settings.css'

const EnterpriseDirectoryWindow = (props) => {
  let content;
  const { directory, directoryType, closeWindowPortal } = props;
  const externalWindow = useRef(
    window.open(
      "about:blank",
      "newWin",
      `width=1000,height=700,left=${window.screen.availWidth / 2},top=${window.screen.availHeight / 2}`
    )
  );

  const containerEl = document.createElement("div");

  useEffect(() => {
    const currentWindow = externalWindow.current;
    return () => currentWindow.close();
  }, []);

  const styles = require('../../../css/enterprise-settings/enterprise_settings.css');

  externalWindow.current.document.title = "Enterprise Directory";
  externalWindow.current.document.write = `<style>${styles}</style>`
  externalWindow.current.document.body.appendChild(containerEl);
  copyStyles(document, externalWindow.current.document);

  externalWindow.current.addEventListener("beforeunload", () => {
    closeWindowPortal();
  });

  const printWindow = () => externalWindow.current.print();

  if(directoryType === 'summary') {
    content = (
      <EnterpriseDirectorySummary directory={directory} printWindow={printWindow} />
    )
  } else if(directoryType === 'details') {
    content = (
      <EnterpriseDirectoryDetails directory={directory} printWindow={printWindow} />
    )
  }

  return createPortal(content, containerEl);
}

const copyStyles = (sourceDoc, targetDoc) => {
  Array.from(sourceDoc.styleSheets).forEach(styleSheet => {
    if (styleSheet.cssRules) {
      // true for inline styles
      const newStyleEl = sourceDoc.createElement("style");

      Array.from(styleSheet.cssRules).forEach(cssRule => {
        newStyleEl.appendChild(sourceDoc.createTextNode(cssRule.cssText));
      });

      targetDoc.head.appendChild(newStyleEl);
    } else if (styleSheet.href) {
      // true for stylesheets loaded from a URL
      const newLinkEl = sourceDoc.createElement("link");

      newLinkEl.rel = "stylesheet";
      newLinkEl.href = styleSheet.href;
      targetDoc.head.appendChild(newLinkEl);
    }
  });
}

const CardDetail = ({item}) => {
  return (
    <div style={{ display:'flex', flexDirection: 'column', width: '600px', height: '200px', borderStyle: 'solid', borderWidth: '1px', borderColor: 'black', padding: '3px 3px 3px 3px', marginBottom: '3px' }}>
      <div style={{ flex: '1', backgroundColor: 'lightgray' }}>
        <span style={{ fontSize: '20px', fontWeight: 'bold'}}>{item.nameField}</span>
      </div>
      <div style={{ flex: '3', fontWeight: '700', lineHeight: '1.5' }}>
        <div>{item.userIdField}</div>
        <div>Voice: {item.numberField}</div>
        <div>Extension: {item.extensionField}</div>
        <div style={{ paddingTop: '20px' }}>
          <span>{item.addressLine1Field}</span><br />
          <span>{item.cityField} - {item.stateField} - {item.zipField}</span>
        </div>
      </div>
    </div>
  )
}

const EnterpriseDirectoryDetails = (props) => {
  const { directory, printWindow } = props;

  return (
    <div id="enterpriseDirectoryDetails">
        <button className='btn do-not-print' onClick={() => printWindow()}>Print</button>
      
      {directory.map(item => {
        return <CardDetail item={item} />
      })}
    </div>
  );
}

const EnterpriseDirectorySummary = (props) => {
  const { directory, printWindow } = props;
  
  return (
    <div id="enterpriseDirectorySummary">
      <div>
        <button className='btn do-not-print' onClick={() => printWindow()}>Print</button>
      </div>
      <Grid
        data={directory}
      >
        <GridColumn field="nameField" title="Name" />
        <GridColumn field="userIdField" title="User ID" />
        <GridColumn field="numberField" title="Phone Number" />
        <GridColumn field="extensionField" title="Extension" />
        <GridColumn field="departmentField" title="Department" />
        <GridColumn field="mobileField" title="Mobile" />
        <GridColumn field="emailAddressField" title="E-mail Address" />
        <GridColumn field="yahooIdField" title="YahooID" />
        <GridColumn field="groupIdField" title="Group ID" />
        <GridColumn field="impIdField" title="IMP&P ID" />
        <GridColumn field="titleField" title="Title" />
        <GridColumn field="zipField" title="Zip" />
        <GridColumn field="addressLine1Field" title="Address 1" />
        <GridColumn field="addressLine2Field" title="Address 2" />
        <GridColumn field="cityField" title="City" />
        <GridColumn field="stateField" title="State" />
        <GridColumn field="countryField" title="Country" />
        <GridColumn field="locationField" title="Location" />
      </Grid>
    </div>
  );
}

class EnterpriseDirectoryModal extends Component {

	constructor(props) {
		super(props);

		this.state = {
      showModal: false,
			loading: false,
			directory: [],
      gridData: {
        items: [],
        skip: 0,
        take: 10,
      },
      isEnterpriseDirectoryWindowOpen: false,
      directoryType: '',
		};
    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.closeWindowPortal = this.closeWindowPortal.bind(this);
	}

	handleClose() {
		this.setState({ showModal: false });
	}

	async handleShow() {
		await this.setState({ showModal: true, loading: true });
		const data = await this.fetchEnterpriseDirectory();
    if(data && data.length > 0) {
      const { gridData } = this.state;
      await this.setState({ directory: data, gridData: { ...gridData,  items: data.slice(gridData.skip, gridData.skip + gridData.take) }})
    }
    await this.setState({  loading: false })
	}  
	
	fetchEnterpriseDirectory = async () => {
		return await fetchData('admin/enterprise/GetEnterpriseDirectory').then(directoryData => directoryData);
	}

	componentDidMount() {		
	}

  closeWindowPortal() {
    this.setState({ isEnterpriseDirectoryWindowOpen: false });
  }

  gridPageChange = async (event) => {
    const skip = event.page.skip;
    const take = event.page.take;
    const { directory, gridData } = this.state;
    await this.setState({ 
      gridData: { 
        ...gridData, 
        items: directory.slice(skip, skip+take),
        skip,
        take
      }
    })
  };

  render() {
    let modalContent;
    const { directory, gridData, loading, isEnterpriseDirectoryWindowOpen, directoryType } = this.state;

    if(loading) {
      modalContent = (
        <div className='content'>
          <DataLoadingSpinner className='spinner' style={{ margin: '10px auto 0', height: '40px' }} />
        </div>
      );
    } else if(directory.length > 0){ 
      modalContent = (
        <>
          <p>The Enterprise Directory lists all the services and users within your entire organization. A summary or a detail of the Enterprise Directory can be generated, which can be easily printed.</p>
          {/* <a href="https://msp.mydigitalservices.com/MTEnterprise/EnterpriseDirectorySummary.aspx?level=G" target="_blank" className="no-btn">Enterprise Directory Summary</a>
          <a href="https://msp.mydigitalservices.com/MTEnterprise/EnterpriseDirectoryDetails.aspx?level=G" target="_blank" className="no-btn">Enterprise Directory Details</a> */}
          <a target="_blank" className="no-btn" onClick={() => this.setState({ isEnterpriseDirectoryWindowOpen: true, directoryType: 'summary' })}>Enterprise Directory Summary</a>
          <a target="_blank" className="no-btn" onClick={() => this.setState({ isEnterpriseDirectoryWindowOpen: true, directoryType: 'details' })}>Enterprise Directory Details</a>
          
          <hr />
          <div className="directory-block">
            <Grid
              style={{
                height: "350px",
              }}
              data={gridData.items}
              skip={gridData.skip}
              take={gridData.take}
              total={directory.length}
              onPageChange={this.gridPageChange}
              pageable={{
                buttonCount: 3,
                type: "numeric",
                info: true,
                previousNext: true,
              }}
              resizable={true}
            >
              <GridColumn field="nameField" title="Name" />
              <GridColumn field="numberField" title="Phone Number" />
              <GridColumn field="extensionField" title="Extension" />
              <GridColumn field="departmentField" title="Department" />
              <GridColumn field="mobileField" title="Mobile" />
              <GridColumn field="emailAddressField" title="E-mail Address" />
              <GridColumn field="groupIdField" title="Group ID" />
            </Grid>
          </div>
        </>
      )
    } else {
      modalContent = <></>;
    };
    
    return (
      <div className="setting">
        <span onClick={this.handleShow}> 
          <span className="name">Enterprise Directory</span>
          <a className="icon btn"><svg width="14" height="14" viewBox="0 0 24 24"><path d="m163 440l-91-91 251-250 90 90z m309-352l-48-48c-12-11-32-11-45 2l-45 45 91 91 45-45c13-13 13-33 2-45z m-408 275l-32 117 117-32z" transform="scale(0.046875 0.046875)"></path></svg></a>
        </span>
        <Modal dialogClassName="enterpriseDirectoryModal" show={this.state.showModal} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Enterprise Directory</Modal.Title>
          </Modal.Header>
          <Modal.Body>
          	{modalContent}
          </Modal.Body>
        </Modal>
        {isEnterpriseDirectoryWindowOpen && (
          <EnterpriseDirectoryWindow closeWindowPortal={this.closeWindowPortal} directory={directory} directoryType={directoryType} />
        )}
      </div>
    );
  }
};

export default EnterpriseDirectoryModal;
