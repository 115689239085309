import React, { Component, useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { Button } from '@progress/kendo-buttons-react-wrapper';
import { DataLoadingSpinner } from '../../../components/spinners';
import { fetchData, post } from '../../../requests/api_calls';

import '../../../css/enterprise-settings/enterprise_settings.css'

const CustomCollapse = ({ departmentIndex, department, type = 'EDIT', onSaveDepartment, onDeleteDepartment }) => {
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState(department?.DepartmentName || '');
  const [parent, setParent] = useState(department?.ParentDepartment?.DepartmentName || null);
  const [newName, setNewName] = useState('');
  const [availableParentDepartments, setAvailableParentDepartments] = useState([]);

  const isAddDepartmentType = type === 'ADD';
 
  useEffect(() => {
    setLoading(true);
    const path = isAddDepartmentType
      ? 'admin/enterprise/GetAvailableParentDepartments?departmentName='
      : `admin/enterprise/GetAvailableParentDepartments?departmentName=${department?.DepartmentName}`;
    const fetchAvailableDepartments = async () => {
      const data = await fetchData(path)
        .then(departments => departments).then(data => data);
      await setAvailableParentDepartments(data);
      await setLoading(false);
    };

    fetchAvailableDepartments().catch(console.error);
  }, []);

  const saveDepartment = async () => {
    await setLoading(true);
    await onSaveDepartment(name, parent, newName, type);
    await setLoading(false);
  };

  const deleteDepartment = async (name) => {
    await setLoading(true);
    await onDeleteDepartment(name);
  };

  if(loading) {
    return (
      <td colSpan="2">
        <div className='content'>
          <DataLoadingSpinner className='spinner' style={{ margin: '10px auto 0', height: '40px' }} />
        </div>
      </td>
    );
  }

  if(!department && !isAddDepartmentType) return <></>;
  
  if(department && Object.keys(department).length <= 0 && !isAddDepartmentType) {
    return <></>;
  }
  
  return (
    <td colSpan="2">
      <div className="table-edit">
        {!isAddDepartmentType 
          ? <div className="form-group">
              <a 
                className="pull-right red"
                style={{ cursor: 'pointer' }}
                onClick={() => deleteDepartment(name)}>
                  Delete Department
              </a> 
            </div>
          : null
        }
        <div className="form-group">
          <label style={{ fontWeight: 700 }}>Department Name</label>
          {isAddDepartmentType ? (
            <input
              type="text"
              className="form-control"
              onChange={(e) => setName(e.target.value)}
            />
          ) : 
            (<input 
              type="text"
              disabled={!isAddDepartmentType} 
              className="form-control"
              value={name}
            />)
          }
        </div>
        <div className="form-group">
          <label style={{ fontWeight: 700 }}>Department Parent</label>
          {availableParentDepartments && 
            (<select 
              className='form-control' 
              onChange={(e) => setParent(e.target.value)}
              defaultValue={availableParentDepartments.length === 0 ? '' : parent}
            >
              <>
                <option value=''>None</option>
                {(availableParentDepartments.length > 0) && 
                  availableParentDepartments.map((availableParent, index) => {
                    return (
                    <option 
                      value={availableParent.DepartmentName}
                      key={`${index}-${availableParent.DepartmentName}`}
                    >
                      {availableParent.DepartmentName}
                    </option>)
                  })}
              </>
            </select>)
          }
        </div>
        {!isAddDepartmentType && (
          <div className="form-group">
            <label style={{ fontWeight: 700 }}>New Department Name:</label>
            <input className="form-control" onChange={(e) => setNewName(e.target.value)} />
          </div>
        )}
        <div className="btns">
          <a className="btn" onClick={() => saveDepartment(name, parent, newName, type)}>Save</a>
          <a className="btn" data-toggle="collapse" data-target={isAddDepartmentType ? '#dmAdd' : `#dm${departmentIndex}`}>Cancel</a>
        </div>
      </div>
    </td>
  )
}

const DepartmentItem = ({ department, index, saveDepartment, deleteDepartment }) => {
  const [showCollapse, setShowCollapse] = useState(false);

  return (
    <>
      <tr key={`${index}-item`} id={`item-${index}`}>
        <td>{department.FullPathName}</td>
        <td>
          <a className="icon btn" title='Edit department' 
            onClick={() => setShowCollapse(!showCollapse)}><svg alt="Edit" width="14" height="14" viewBox="0 0 24 24"><path d="m163 440l-91-91 251-250 90 90z m309-352l-48-48c-12-11-32-11-45 2l-45 45 91 91 45-45c13-13 13-33 2-45z m-408 275l-32 117 117-32z" transform="scale(0.046875 0.046875)"></path></svg></a>          
        </td>
      </tr>
      {showCollapse && (
        <tr className="collapse in" id={`dm${index}`} key={`${index}-collapse`}>
          <CustomCollapse
            departmentIndex={index}
            department={department}
            onSaveDepartment={saveDepartment}
            onDeleteDepartment={deleteDepartment}
          />
        </tr>
      )}
    </>
  )
}

class DepartmentModal extends Component {

	constructor(props) {
		super(props);

    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.state = {
      showModal: false,
      loading: false,
      departments: [],
    };
  }

  handleClose() {
    this.setState({ showModal: false });
  }

  async handleShow() {
    await this.setState({ showModal: true, loading: true });
    const data = await this.fetchDepartments();
    await this.setState({ departments: data, loading: false, });
  }

  fetchDepartments = async () => {
    return await fetchData('admin/enterprise/GetDepartments').then(departmentsData => departmentsData);
  }

	componentDidMount() {
	}

  saveDepartment = async (name, parent, newName, type) => {
    const isAddDepartmentType = type === 'ADD';
    const path = isAddDepartmentType ? 'admin/enterprise/AddDepartment' : 'admin/enterprise/UpdateDepartment';
    try {
      const data = isAddDepartmentType ?
      {
        DepartmentName: name,
        ParentDepartment: parent ? { DepartmentName: parent } : null,
      } : {
        DepartmentName: name,
        NewDepartmentName: newName || name,
        ParentDepartment: parent ? { DepartmentName: parent } : null,
      };
      const response = await post(path, "POST", JSON.stringify(data));
      if (response.ok) {
        this.setState({ loading: true });
        const updatedDepartments = await this.fetchDepartments();
        await this.setState({ departments: updatedDepartments, loading: false })
      } else {
        console.error(`${path}: failed: ${response.message}`);
        this.setState({ loading: false });
      }
    } catch (error) {
      console.error(`${path}: failed: ${error}`);
      this.setState({ loading: false });
    }
  }

  deleteDepartment = async(name) => {
    const path = `admin/enterprise/DeleteDepartment?departmentName=${name}`;
    try {
      const response = await post(path, "DELETE", {});
      if (response.ok) {
        this.setState({ loading: true });
        const updatedDepartments = await this.fetchDepartments();
        await this.setState({ departments: updatedDepartments, loading: false })
      } else {
        console.error(`${path}: failed: ${response.message}`);
        this.setState({ loading: false });
      }
    } catch (error) {
      console.error(`${path}: failed: ${error}`);
      this.setState({ loading: false });
    }
  }

  cancelEditDepartment = () => {
    this.handleClose();
  }

  render() {
    let modalContent;
    const { departments, loading } = this.state;
    
    if(loading) {
      modalContent = (
        <div className='content'>
          <DataLoadingSpinner className='spinner' style={{ margin: '10px auto 0', height: '40px' }} />
        </div>
      );
    } else if(departments.length > 0){ 
      modalContent = (
        <div className="department-block">
          <table className="table">
            <thead>
              <tr style={{ backgroundColor: '#f6f6f6'}}>
                <td><b>Department Name</b></td>
                <td></td>
              </tr>
            </thead>
            <tbody>
              {departments.map((department, index) => {
                return (
                  <DepartmentItem 
                    department={department}
                    index={index}
                    saveDepartment={this.saveDepartment}
                    deleteDepartment={this.deleteDepartment}
                  />
                )}
              )}
              <tr id="AddButton">
                <td colSpan="2">
                  <div className="btns">
                    <a className="btn" data-toggle="collapse" data-target="#dmAdd">Add Department</a>
                  </div>
                </td>
              </tr>
              <tr className="collapse" id="dmAdd">
                <CustomCollapse 
                  type={"ADD"}
                  onSaveDepartment={this.saveDepartment}
                />
              </tr>
            </tbody>
          </table>
        </div>
      )
    } else {
      modalContent = <></>;
    };
  
    return (
      <div className="setting">
        <span onClick={this.handleShow}>
          <span className="name">Departments</span>
          <a className="icon btn"><svg width="14" height="14" viewBox="0 0 24 24"><path d="m163 440l-91-91 251-250 90 90z m309-352l-48-48c-12-11-32-11-45 2l-45 45 91 91 45-45c13-13 13-33 2-45z m-408 275l-32 117 117-32z" transform="scale(0.046875 0.046875)"></path></svg></a>
        </span>
        <Modal show={this.state.showModal} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Departments</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {modalContent}
          </Modal.Body>
          <Modal.Footer>
              <div className="btns">
                <Button className="btn" click={this.handleClose}>Close</Button>
              </div>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
};

export default DepartmentModal;
