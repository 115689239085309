import React, { Component } from 'react';
import { Grid, GridColumn as Column, GridCell } from '@progress/kendo-react-grid';
import { orderBy } from '@progress/kendo-data-query';
import { Button } from '@progress/kendo-react-buttons';
import { headerToolTip } from '../../components/ellipsisHeaderTooltip';
import { DataLoadingSpinner } from '../../components/spinners';
import * as apiCalls from '../../requests/api_calls';
import EditForm from './components/edit_applist_dialog';

const CellEditButton = (props) => {

  return (
    <td align="right" className="actions">
      <Button className="icon btn" onClick={() => props.handleOpenModal(props)}>
        <svg width="14" height="14" viewBox="0 0 24 24"><path d="m163 440l-91-91 251-250 90 90z m309-352l-48-48c-12-11-32-11-45 2l-45 45 91 91 45-45c13-13 13-33 2-45z m-408 275l-32 117 117-32z" transform="scale(0.046875 0.046875)"></path></svg>
      </Button>
    </td>
  )
}

class AppList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      AppList: this.props.AppList,
      CustomerIdPID: this.props.CustomerIdPID,
      loading: false,
      Sort: [{ field: "", dir: "desc" }],
      filter: { logic: "and", filters: [] },
      openDialog: false,
      isEditMode: false,
      editItem: {},
      hasEmptyData: []
    }
  }

  componentWillReceiveProps() {
    if (this.props.AppList !== this.state.AppList) {
      this.setState({ AppList: this.props.AppList });
    }
  }

  // handleAddItem = () => {
  //   let defaultIdpAppName = this.state.hasEmptyData[0]?.IdPAppName || '';
  //   this.setState({
  //     openDialog: true,
  //     isEditMode: false,
  //     editItem: {
  //       ConfigJSON: null,
  //       CustomerIdPID: this.state.this.props.CustomerIdPID,
  //       IdPAppClientId: "",
  //       IdPAppClientKey: "",
  //       IdPAppName: defaultIdpAppName,
  //       Jwk: null
  //     },
  //   });
  // }

  gridContent = () => {
    let { Sort, AppList } = this.state;
    if (AppList && AppList.length > 0) {

      return (
        <>
          <Grid
            style={{
              width: '100%',
            }}
            data={orderBy(AppList, Sort)}
            sortable={{ allowUnsort: true, mode: 'single' }}
            sort={Sort}
            onSortChange={this.handleSortChange}
          >
            <Column field="IdPAppName" title={headerToolTip("Idp App Name")} />
            <Column field="IdPAppClientId" title={headerToolTip("Idp App Client ID")} />
            <Column field="IdPAppClientKey" title={headerToolTip("Idp App Client Key")} />
            <Column cell={(props) => <CellEditButton handleOpenModal={this.handleOpenModal} {...props} />} title={headerToolTip("Edit")} sortable={false} filterable={false} width="60px" />
          </Grid>
        </>
      )
    } else {
      return <p style={{ textAlign: 'center', paddingTop: '15px' }}>No Available Data</p>
    }
  }

  handleSortChange = (e) => {
    this.setState({
      customerIdpApps: orderBy(this.state.customerIdpApps, e.sort),
      Sort: e.sort
    });
  }

  handleFilterChange = (event) => {
    this.setState({
      filter: event.filter
    });
  }

  handleOpenModal = (props) => {
    this.setState({
      openDialog: true,
      isEditMode: true,
      editItem: props.dataItem,
    });
  }

  handleCloseModal = async () => {
    if (this.state.Submission) {
      await this.props.RefreshData(this.state.CustomerIdPID);
    }
    this.setState({
      openDialog: false,
      isEditMode: false,
      Submission: false,
      SubmitError: false,
      ErrorMessage: "",
    });
  }

  updateCustomerIdpData = async (data) => {
    let path = 'admin/SaveCustomerIdpApp';
    let response = await apiCalls.post(path, 'POST', JSON.stringify(data)).then(data => data).catch(err => console.error(err));
    if (response.ok) {
      await this.setState({
        Submission: true,
        SubmitError: false,
        ErrorMessage: "",
      });
    } else {
      this.setState({
        Submission: true,
        SubmitError: true,
        ErrorMessage: response.message,
      });
    }
  }

  handleSubmit = async (data) => {
    let idx = this.state.AppList.findIndex((itm) => itm.IdPAppName === data.IdPAppName);
    await this.setState({
      AppList: this.state.AppList.splice(idx, 1, data),
    });
    console.log("APP LIST after splice", this.state.AppList);
    await this.updateCustomerIdpData(data);
  }

  render() {
    let { loading } = this.state;
    if (loading) {
      return <DataLoadingSpinner style={{ marginTop: "3%" }} className='load spinner' />
    }
    return (
      <div className="card-content"
      >
        {/* <div className='add-button'>
          {this.state.hasEmptyData.length > 0 && <Button onClick={this.handleAddItem}>Add New App</Button>}
        </div> */}
        {this.gridContent()}
        <EditForm
          cancelEdit={this.handleCloseModal}
          onSubmit={this.handleSubmit}
          data={this.state.editItem}
          isEditMode={this.state.isEditMode}
          showModal={this.state.openDialog}
          customerIdpAppList={this.state.isEditMode ? this.state.customerIdpApps : this.state.hasEmptyData}
          ErrorMessage={this.state.ErrorMessage}
          Submission={this.state.Submission}
          SubmitError={this.state.SubmitError}
        />
      </div>
    )
  }
}
export default AppList;

