import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { Button } from '@progress/kendo-buttons-react-wrapper';
import SaveStatus from './../../../components/save_status';
import InfoPop from './../../../components/infopop';
import { DataLoadingSpinner } from './../../../components/spinners';
import * as apiCalls from '../../../requests/api_calls';
import * as help from './../devices_helpers';
import MacAdressFormats from './../components/macFormatsClickPop';
import PortSettings from './../components/port_settings';
import ConfigSwitches from './../components/config_switches';
import _ from 'lodash';

export const macAddress = 'macAddress';
export const vlanId = 0;
export const fqdn = 'none';
const deviceType = 'DeviceType';
const defaultSpanValue = 2;
const spanOptions = [2, 3, 4];
const initialFormState = {
  configProps: {
    Ports: [],
    invalidFields: [],
    newDeviceModel: '',
    NewMAC: '',
    IsFirstPortMWI: false
  },
  selectedModelConfigs: {},
  invalidFields: ['newMAC', deviceType],
  invalidMacMsg: 'This field is required.',
  showInvalidMacMsg: false,
  enableSwapDevice: false,
  showRequiredFieldMsgs: false,
  showRebootSuccess: false,
  showRebootFailure: false,
  showSaveStatus: false,
  error: false,
  saving: false
}

class ConfigureDevicesModal extends Component {

  constructor(props) {
    super(props);
    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.saveDevice = this.saveDevice.bind(this);
    this.setPortSetting = this.setPortSetting.bind(this);
    this.setFqdn = this.setFqdn.bind(this);
    this.handleDeviceSelection = this.handleDeviceSelection.bind(this);
    this.handleMacChange = this.handleMacChange.bind(this);
    this.setDeviceConfiguration = this.setDeviceConfiguration.bind(this);
    //this.showSaveWaitMessage = this.showSaveWaitMessage.bind(this);
    this.NewMacChange = this.NewMacChange.bind(this);
    this.state = initialFormState;
  }

  handleClose() {
    this.setState({ showModal: false, error: false, statusMessage: null });
  }

  async handleShow() {
    await this.setState({ ...initialFormState, showModal: true, loading: true });
    await this.setOptions();
    this.setDeviceConfiguration();
  }

  componentDidMount() {

  }

  setOptions = async () => {
    let [fqdns, models] = await help.fetchDeviceSelectOptions();
    this.setState({ deviceModels: models, fqdns: fqdns });
  }

  setDeviceConfiguration = async () => {
    let [config, device, modelConfigs] = await help.fetchDeviceConfigs(this.props);
    let configProps = help.configProps(config, device, initialFormState);
    if (!configProps.DeviceModel) configProps.DeviceModel = device.DeviceType;
    configProps.newDeviceModel = configProps.DeviceModel; // default to current selection

    //this is to setup first time configuration values versus saved configuration values. config will be null on first time load
    //the 'Location' prefixed properties on configProps represent the 'defaults' that appear in the labels so they are still necessary.
    let selectedModelConfigs = this.matchedDeviceConfig(configProps.DeviceModel, modelConfigs);
    let vlanId = 'None';

    configProps.EnableVlanIdText = false;
    if (config) {
      vlanId = config.VlanId === 0 ? 'None' : config.VlanId;
      configProps.CurrentVlanId = vlanId;
      configProps.TextVlanId = vlanId === 'None' ? 0 : vlanId;
      configProps.TransportProtocol = config.TransportProtocol;
      configProps.EncryptedAudio = config.EncryptedAudio;
      configProps.Fqdn = config.Fqdn;

      let revisedPorts = configProps.Ports.map((port) => {
        return this.reviewAndOverrideDisabled(configProps, port, selectedModelConfigs);
      });
      configProps.Ports = revisedPorts;
    }
    else {
      if (selectedModelConfigs.IsVlanAvailable) {
        vlanId = configProps.LocationVLAN && ((configProps.LocationVLAN <= 1001 && configProps.LocationVLAN >= 2) || (configProps.LocationVLAN <= 4094 && configProps.LocationVLAN >= 1006)) ? configProps.LocationVLAN : vlanId
      }
      configProps.VlanId = vlanId;
      configProps.CurrentVlanId = vlanId;
      configProps.TextVlanId = vlanId === 'None' ? 0 : vlanId;
      if (vlanId !== 'None') {
        configProps.enableVlan = true;
        configProps.EnableVlanIdText = true;
      }
      configProps.TransportProtocol = configProps.LocationTransportProtocol;

      configProps.EncryptedAudio = configProps.LocationEncryptedAudio;
      configProps.Fqdn = configProps.LocationFQDN;
      configProps.IsFirstPortMWI = true;
    }

    let startingState = ({
      ...this.state,
      configProps: configProps,
      selectedModelConfigs: selectedModelConfigs,
      modelConfigs: modelConfigs,
      transactionNums: configProps.TransactionNumbers,
      unassignedDevices: this.props.unassignedDevices,
      loading: false
      //enableVlan: configProps.TransportProtocol === 'TCP' ? true : false
    });
    // make copy of starting state to reset back to on error
    let state = { ...startingState, resetState: startingState };
    this.setState(state);
  }

  reviewAndOverrideDisabled = (props, port, selectedModelConfigs) => {
    let availableTns = this.availableTns(props, port.PortNumber, selectedModelConfigs);
    let matchedNumber = availableTns.filter(ph => ph.ServiceNumber === port.PhoneNumber || ph.Extension === port.PhoneNumber)
    if (!matchedNumber || matchedNumber.length === 0)
      return this.getBlankPort(port.PortNumber, false);
    else
      return port;
  }


  matchedDeviceConfig = (model, modelConfigs = this.state.modelConfigs) => {
    let deviceMatches = modelConfigs && modelConfigs.length > 0 ? modelConfigs.filter(modelConfig => {
      return model == modelConfig.DeviceModelKey;
    }) : null; // using filter and getting first item b/c Array.find() does not work in IE
    return deviceMatches ? deviceMatches[0] : null;
  }

  formClass = () => this.state.loading ? 'form-group hidden' : 'form-group';

  handleSwapDevice = () => {
    this.setState({ ...this.state, enableSwapDevice: !this.state.enableSwapDevice });
  }

  handleEnableTransportProtocol = () => {
    this.setState({ ...this.state, enableTransportProtocol: !this.state.enableTransportProtocol });
  }

  handleRebootSuccess = () => {
    this.setState({ showRebootSuccess: true });
    let pageThis = this;
    setTimeout(function () {
      pageThis.setState({ showRebootSuccess: false });
    }, 3000);
  }
  handleRebootFailure = () => {
    this.setState({ showRebootFailure: true });
    let pageThis = this;
    setTimeout(function () {
      pageThis.setState({ showRebootFailure: false });
    }, 3000);
  }

  handleSaveSuccess = () => {
    this.setState({ showSuccess: true, saving: false });
    let pageThis = this;
    setTimeout(function () {
      pageThis.setState({ ...initialFormState, showModal: false, loading: true });
      pageThis.setDeviceConfiguration();
      pageThis.props.loadDevices(true); // reload parent ( list of devices )
    }, 3000);
    //
  }

  showSuccessModal = (msg) => {
    this.setState({})
  }

  closeSaveMessage = () => {
    this.setState({ showSaveStatus: false, error: false, statusMessage: '' });
  }


  showErrorAndResetModal = (msg, returnToScreen = false) => {
    let newState = {};
    if (msg) {
      newState = this.state;
      newState = { ...newState, saving: false, showSaveStatus: true, showModal: true, error: true, statusMessage: msg }
    } else {
      newState = { ...this.state.resetState, resetState: { ...this.state.resetState } }
      newState = { ...newState, showModal: true, error: false, statusMessage: null }
    }
    this.setState(newState);
  }

  switchHandler = (newState) => this.setState(newState);

  numberOfPorts = () => this.state.selectedModelConfigs ? this.state.selectedModelConfigs.NumberOfPorts : 0;

  noUnassignedDevices = () => this.state.unassignedDevices && this.state.unassignedDevices.length === 0;

  devicesAreAvailable = () => this.state.unassignedDevices && this.state.unassignedDevices.length > 0;

  showUnassignedDevicesLink = () => this.devicesAreAvailable() && this.state.showNewMacField;

  isSwapModelDisabled = () => {
    let device = this.state.configProps;
    return device.NewMAC !== null && device.MAC !== device.NewMAC;
  }

  unassignedMacOptions = () => {
    if (this.props.unassignedDevices) {
      let optionalMacs = this.props.unassignedDevices.filter(device => device.MacAddress !== this.props.mac);
      return optionalMacs.map((device, index) => {
        return <option key={index} value={device.MacAddress} >{device.MacAddress}</option>
      });
    } else return [];
  }

  rebootDevice = () => {
    //let postData = {
    var mac = this.state.configProps.MAC;
    //};
    let apiPath = 'admin/RebootDevice';
    apiCalls.send(apiPath, 'POST', JSON.stringify(mac)).then(resp => {
      if (resp.ok) {
        this.handleRebootSuccess();
      } else {
        this.handleRebootFailure();
      }
    }).catch(msg => {
      this.handleRebootFailure();
      this.showErrorAndResetModal(msg);
      console.log('caught an exception')
    });
  }

  saveDevice = () => {
    if (this.validForm()) {
      this.setState({ ...this.state, saving: true, showSaveStatus: true });
      let apiPath = 'admin/UpdateDeviceConfiguration';
      let postData = this.devicePropsToSend();
      //let groupFQDN = this.state.configProps.LocationFQDN;

      apiCalls.send(apiPath, 'PATCH', JSON.stringify(postData)).then(resp => {
        if (resp.ok) {
          this.handleSaveSuccess();
        } else {
          let msg = this.notSavedErrorMsg(resp);
          this.showErrorAndResetModal(msg);
        }
      }).catch(msg => {
        this.showErrorAndResetModal(msg);
        console.log('caught an exception')
      });
    } else this.setInvalidFormMessage();
  }

  devicePropsToSend = () => {
    let config = this.state.configProps;
    let outgoingPayload = {
      DeviceModel: this.deviceModelSelection(),
      EncryptedAudio: config.EncryptedAudio,
      Fqdn: config.Fqdn === '' ? null : config.Fqdn,
      IsVideoEnabled: config.IsVideoEnabled || null,
      LocationAccountNumber: config.LocationAccountNumber,
      MAC: config.MAC,
      NewMAC: config.NewMAC || config.MAC,
      Ports: this.configuredPorts(config.Ports),
      TransportProtocol: config.TransportProtocol,
      TlsSrtp: config.TlsSrtp || null,
      UseLineKeysForBLFList: config.UseLineKeysForBLFList || null,
      VlanId: config.VlanId,
      IsSwapOperation: this.state.enableSwapDevice
    };
    return outgoingPayload;
  }

  deviceModelSelection = () => {
    let config = this.state.configProps;
    if (config.newDeviceModel !== (undefined || null || '')) {
      return config.newDeviceModel
    } else {
      return config.DeviceModel
    }
  }

  configuredPorts = (ports = this.state.configProps.Ports) => {
    return ports.filter(port => port.UserId && port.UserId !== '');
  }

  validForm = () => {
    if (this.configuredPorts().length && !this.invalidVlanId()) {
      return true
    } else {
      return false
    }
  }

  invalidVlanId = () => this.state.configProps.invalidVlanId;

  setInvalidFormMessage = () => {
    if (!this.configuredPorts().length) {
      var msg = 'At least one port must have a phone number. ';
      msg += 'Unassign the device to deactivate all ports.';
      this.setState({ invalidFormMsg: msg });
    } else if (this.invalidVlanId()) {
      this.setState({ invalidFormMsg: 'The provided VLAN ID is invalid.  Please update and try again.' })
    } else {
      this.setState({ invalidFormMsg: 'One or more fields is invalid.  Please update and try again.' })
    }
  }

  saveButton = () => {
    if (!this.state.loading && !this.state.saving && !this.state.error && !this.state.showSuccess) {
      let invalidFormClass = this.validForm() ? 'hidden' : 'text-danger text-center'
      return (
        <div>
          <div className="rebootSuccess" style={{ display: this.state.showRebootSuccess ? 'block' : 'none', textAlign: 'center' }}>
            <span style={{ color: 'green', "fontSize": "medium", marginTop: '15px', marginBottom: '15px' }}>Reboot request sent</span>
          </div>
          <div className="rebootFailure" style={{ display: this.state.showRebootFailure ? 'block' : 'none', textAlign: 'center' }}>
            <span style={{ color: 'red', "fontSize": "medium", marginTop: '15px', marginBottom: '15px' }}>Reboot request failed!</span>
          </div>
          <div className="btns">
            <Button className="btn" click={this.saveDevice}>Save</Button>
            <Button className="btn" click={this.rebootDevice}>Reboot Device</Button>
            <Button className="btn" click={this.handleClose}>Close</Button>
          </div>
          <h6 className={invalidFormClass}>{this.state.invalidFormMsg}</h6>
        </div>
      )
    }
  }

  swapDeviceClass = () => {
    let showSwap = this.state.configProps.Assigned && help.showSwap();
    return showSwap ? 'swap visible' : 'hidden';
  }

  FqdnSelection = () => {
    const { configProps: { DeviceModel: model } = {} } = this.state;
    const device = this.matchedDeviceConfig(model);
    const isFQDNAvailable = device ? device.IsFQDNAvailable : false;
    if (!isFQDNAvailable)
      return null;
    else if (help.showFqdn()) {
      return (
        <div className="form-group">
          <label>FQDN</label>
          <select ref={fqdn}
            className="form-control"
            value={this.state.configProps.Fqdn}
            onChange={this.setFqdn}
          >
            <option value="">none</option>
            {help.fqdnOptions(this.state.fqdns, this.state.configProps.LocationFQDN, this.state.configProps.Fqdn)}
          </select>
          <small className="instructions_highlight">(Current group FQDN default is set to {this.state.configProps.LocationFQDN ? this.state.configProps.LocationFQDN : fqdn})</small>
        </div>
      )
    } else {
      return (
        <div className="form-group">
          <label>FQDN: </label>
          <label>{this.state.configProps.LocationFQDN === '' || this.state.configProps.LocationFQDN === null ? 'no default set' : this.state.configProps.LocationFQDN}</label>
        </div>
      )
    }
  }

  newDeviceSelector = () => {
    return (
      <div>
        <select
          ref={deviceType}
          className="form-control"
          value={this.state.configProps.newDeviceModel || ''}
          onChange={(e) => this.validateSwapDeviceModel(e, deviceType)}
          disabled={this.isSwapModelDisabled()}
        >
          <option value="">-Select-</option>
          {help.modelOptions(this.state.deviceModels)}
        </select>
      </div>
    )
  }

  lineSettingsTable = () => {
    if (this.numberOfPorts() > 0) {
      return (
        <table className='table'>
          <thead>
            <tr>
              <th style={{ width: '50px' }}>Line</th>
              <th style={{ width: '280px' }}>Telephone Number</th>
              <th style={{ width: '150px' }}>Label</th>
              <th className="check">BLF
                <InfoPop
                  message="Busy Lamp: Port 1 must have qualifying seat in order for BLF to 
                           be available for other ports. Port 1 cannot ever have the BLF option.  
                           If port 1 has SCA checked, BLF is unavailable for all other ports"
                />
              </th>
              <th className="check">SCA
                <InfoPop message="Shared-Call Appearance" />
              </th>
              <th className="check">MWI
                <InfoPop message="Message Waiting Indicator" />
              </th>
              <th className="check">LK
                <InfoPop message="Line Key span" />
              </th>
              <th className="check">CC
                <InfoPop message="Contact Center Agent Sign-on" />
              </th>
              <th style={{ width: '100px' }}>Span
                <InfoPop message="Number of Line Keys to Span" />
              </th>
            </tr>
          </thead>
          <tbody>{this.portSettingRows()}</tbody>
        </table>
      )
    } else return null;
  }

  setPortSetting = (e, portNum = null, propName = null) => {
    if (!portNum && !propName) {
      portNum = e.target.dataset.portnum;
      propName = e.target.dataset.propname;
    }
    let newPorts = this.findOrInitializePortsSettings(portNum);
    let portIndex = newPorts.map(port => port.PortNumber).indexOf(parseInt(portNum));
    let port = newPorts[portIndex];
    if (propName === 'tn') {
      let newTn = e.target.value && e.target.value.TN ? e.target.value.TN : { TN: '', TnAndLine: '-Select-' };
      this.setPhoneOrExt(newTn, port);
    } else if (propName === 'DisplayName') {
      port[propName] = e.target.value;
    } else if (propName === 'Lk' || propName === 'Span') {
      let spanValue = defaultSpanValue;
      if (propName === 'Lk') {
        port.Lk = e.target.checked;
        spanValue = e.target.checked ? defaultSpanValue : null;
      }
      else
        spanValue = e.target.value;

      port.Span = spanValue;
      //need to clear out the spanned values
      if (spanValue) {
        for (var i = 1; i < spanValue; i++) {
          let spannedPort = newPorts.filter(resp => resp.PortNumber === port.PortNumber + i)[0];
          let idx = _.indexOf(newPorts, spannedPort);
          spannedPort = this.getBlankPort(port.PortNumber + i, false);
          newPorts[idx] = spannedPort;
        }
      }
    } else {
      port[propName] = e.target.checked;
    }
    let configProps = { ...this.state.configProps, Ports: newPorts };
    configProps.IsFirstPortMWI = false;
    this.setState({ ...this.state, configProps: configProps });
  }

  setPhoneOrExt = (newTn, port) => {
    let tnProps = help.tnProps(this.state, newTn);
    if (tnProps) {
      port.UserId = tnProps.SipAuthenticationId;
      port.LineTypeTicker = tnProps.LineTypeTicker;
      if (tnProps.ServiceNumber) {
        port.PhoneNumber = newTn;
        port.Extension = null;
      } else {
        port.PhoneNumber = null;
        port.Extension = newTn;
      }
    } else { // reset port
      let portClone = { ...port };
      for (var key in port) delete port[key];
      port.PortNumber = portClone.PortNumber;
    }
  }

  portIds = () => {
    let numPorts = this.numberOfPorts()
    return Array.apply(null, { length: numPorts }).map(function (value, index) {
      return index + 1
    });
  }

  findOrInitializePortsSettings = () => {
    let props = this.state.configProps;
    let configuredPorts = [...props.Ports];
    let portSettings = this.portIds().map(portNum => {
      var portMatches = configuredPorts.filter(port => portNum === port.PortNumber);
      if (portMatches.length) {
        return portMatches[0];
      } else {
        return new Object({ PortNumber: portNum })
      }
    });
    return portSettings;
  }

  isExecutive = (e) => {
    // Executive Extension Seat or Enterprise Select Executive
    let tn = e.target.value;
    let ticker = help.tnProps(this.state, tn).LineTypeTicker;
    return ticker === 'MREPUPGEAA' || ticker === 'MREPUPGRAA';
  }

  availableTns = (props, portId, selectedModelConfigs = null) => {
    let tns = this.filterTnsByTicker(props, selectedModelConfigs);
    let usedTNs = props.Ports.filter(ph => ph.UserId !== '' && ph.PortNumber !== portId).map((ph) => {
      return ph.PhoneNumber || ph.Extension
    });
    if (props && props.Ports) {
      return tns.filter(tn => usedTNs.indexOf(tn.ServiceNumber) < 0 && usedTNs.indexOf(tn.Extension) < 0);
      // return props.Ports.reduce((tns, port) => {
      //   return tns.filter(tn => port.PortNumber === portId && (port.PhoneNumber === tn.ServiceNumber || port.PhoneNumber === tn.Extension))
      //   //return tns.filter(tn => tn.ServiceNumber !== port.UserId || (port.PortNumber === portId && tn.ServiceNumber === port.UserId ));
      // }, tns);
    }
    else return tns;
  }

  filterTnsByTicker = (props, selectedModelConfigs = null) => {
    let tns = props.TransactionNumbers.slice();
    if (this.isFaxModel(selectedModelConfigs)) {
      return tns.filter(tn => tn.LineTypeTicker == 'MTRTFAXTFA')
    } else {
      return tns.filter(tn => tn.LineTypeTicker !== 'MTRTFAXTFA')
    }
  }

  portSettingRows = () => {
    return this.portIds().map(id => {
      return <PortSettings key={id} portNum={id} settings={{ ...this.portSettings(id) }} />;
    });
  }

  isFaxModel = (selectedModelConfigs = null) => {
    let modelName = selectedModelConfigs ? selectedModelConfigs.DeviceModelKey : this.state.selectedModelConfigs.DeviceModelKey;
    return (new RegExp('fax')).test(modelName.toLowerCase())
  }

  portSettings = (portId) => {
    let props = this.state.configProps;
    let selectedTn = { TN: '', TnAndLine: '-Select-' };
    let matchingPorts = props.Ports.filter(port => port.PortNumber == portId);
    let availableTns = this.availableTns(props, portId)
    if (matchingPorts && matchingPorts.length > 0) {
      let selectedTnOption = availableTns.filter(tn => (tn.ServiceNumber && tn.ServiceNumber !== '' && tn.ServiceNumber === matchingPorts[0].PhoneNumber) ||
        (tn.Extension && tn.Extension !== '' && tn.Extension === matchingPorts[0].PhoneNumber) ||
        (tn.Extension && matchingPorts[0].Extension && tn.Extension === matchingPorts[0].Extension));
      if (selectedTnOption && selectedTnOption.length > 0)
        selectedTn = {
          TN: (selectedTnOption[0].ServiceNumber || selectedTnOption[0].Extension),
          TnAndLine: (selectedTnOption[0].ServiceNumber || selectedTnOption[0].Extension) + ' (' + selectedTnOption[0].LineName + ')'
        };
    }

    let settings = {
      availableTns: availableTns,
      selectedTn: selectedTn,
      setStateCallback: this.setPortSetting,
      portsConfigured: props.Ports.filter(port => port.UserId !== ''),
      spanOptions: spanOptions,
      selectedModelConfigs: this.state.selectedModelConfigs
    }

    if (this.lineIsSpanned(portId)) {
      let newSettings = settings;
      newSettings.selectedTn = { TN: '', TnAndLine: '-Select-' };
      return { ...settings, disabled: true };
    } else if (matchingPorts.length) {
      let port = matchingPorts[0];
      if (portId === 1 && props.IsFirstPortMWI === true)
        port.Mwi = true;

      return { ...settings, ...port, lineTypeTicker: this.lineTicker(port) }
    } else {
      if (portId === 1 && props.IsFirstPortMWI === true) {
        let port = this.getBlankPort(portId, true);
        return { ...settings, ...port };
      } else
        return { ...settings };
    }
  }

  getBlankPort = (portId, MWI) => {
    return {
      Blf: false,
      Cc: false,
      DisplayName: "",
      Extension: null,
      IsDelete: false,
      Lk: false,
      Mwi: MWI,
      PhoneNumber: null,
      PortNumber: portId,
      Sca: false,
      Span: null,
      UserId: null
    };
  }

  lineTicker = (port) => {
    let tn = port.PhoneNumber || port.Extension;
    let tnProps = help.tnProps(this.state, tn)
    if (tnProps) return tnProps.LineTypeTicker;
  }

  lineIsSpanned = (portId) => {
    let lineKeysSpanning = this.configuredPorts().filter(portSettings => portSettings.Lk);
    let spannedPorts = lineKeysSpanning.map(portSettings => {
      return Array.apply(null, { length: portSettings.Span - 1 }).map((val, i) => {
        return i + 1 + portSettings.PortNumber;
      })
    }); // converts line span value to array of ports that are spanned
    let disabledPorts = [].concat.apply([], spannedPorts);
    return disabledPorts.indexOf(portId) >= 0;
  }

  handleMacChange = (e) => {
    let newMac = e.target.value;
    let newConfig = { ...this.state.configProps, NewMAC: newMac };
    // user cannot change device model if MAC changes so it must be reset if different
    if (newMac !== this.state.configProps.MAC) {
      let modelMatches = this.state.unassignedDevices.filter(dev => dev.MacAddress === newMac);
      newConfig.newDeviceModel = modelMatches[0].MakeModel;
    } else {
      newConfig.newDeviceModel = newConfig.DeviceModel; // reset to device for current MAC
    }
    this.setState({ configProps: newConfig });
  }

  validateSwapDeviceModel = (e, fieldName) => {
    let deviceSelection = e.target.value;
    if (deviceSelection === '') {
      // reset model selection
      this.setState({ configProps: { ...this.state.configProps, newDeviceModel: deviceSelection } })
    } else {
      // handle device selection validation
      this.handleDeviceSelection(deviceSelection);
    }
  }

  handleDeviceSelection = (deviceSelection, modelConfigs = this.state.modelConfigs) => {
    let selectedModelConfigs = this.matchedDeviceConfig(deviceSelection, modelConfigs);
    let newState = { ...this.state, selectedModelConfigs: selectedModelConfigs };
    newState.configProps = { ...this.state.configProps, newDeviceModel: deviceSelection };
    this.setState(newState);
  }

  setFqdn = (e) => {
    this.setState({ configProps: { ...this.state.configProps, Fqdn: e.target.value } });
  }
  NewMacChange = (e) => {
    this.setState({ configProps: { ...this.state.configProps, NewMAC: e.target.value } });
  }
  notSavedErrorMsg = (resp) => {
    if (resp.ok) {
      return null
    } else {
      return resp.message;
    }
  }

  modalBody = () => {
    let state = this.state;
    if (state.loading) {
      return <DataLoadingSpinner className='load spinner' />;
    } else if (state.showSuccess) {
      return (
        <div style={{ "textAlign": "center" }}>
          <label style={{ "color": "green", "fontSize": "medium" }}>Device {this.state.configProps.MAC} Configured Successfully</label>
        </div>
      )
    } else if (!state.loading && !state.saving && !state.error) {
      return (
        <form className={this.formClass()}>

          <div className="form-group">
            <label>Account Number</label>
            <input type="type" className="form-control"
              value={state.configProps.LocationAccountNumber} disabled={true} />
          </div>
          <div className="halves">
            <div className="half">
              <div className="form-group">
                <label>MAC ID</label>
                <input type="type" className="form-control"
                  value={state.configProps.MAC} disabled={true} />
              </div>
            </div>
            <div className="half">
              <div className="form-group">
                <label>Device Type</label>
                <input type="type" className="form-control"
                  value={state.configProps.DeviceModel} disabled={true} />
              </div>
            </div>
          </div>

          <hr />

          <div className='form-group'>
            <div className={this.swapDeviceClass()}>
              <label className="switch">
                <input
                  type="checkbox"
                  checked={this.state.enableSwapDevice}
                  onChange={this.handleSwapDevice}
                />
                <span className="slider round"></span>
              </label>
              Swap Device
              <div className={state.enableSwapDevice ? 'visible' : 'collapse hidden-group'}>
                <div className="halves">
                  <div className="half">
                    <div className="form-group">
                      <label>New MAC ID</label>
                      <span className={help.validMacClass(state, macAddress)}>✓&nbsp;&nbsp;</span>
                      <input type="text" className={state.showNewMacField ? 'hidden' : 'form-control'}
                        value={this.state.configProps.NewMAC} maxlength="12" onChange={this.NewMacChange} />
                      {/* <select
                        ref={deviceType}
                        className={state.showNewMacField ? 'hidden' : 'form-control'}
                        value={state.configProps.NewMAC || ''}
                        onChange={this.handleMacChange}
                      >
                        <option value={state.configProps.MAC}>same ({state.configProps.MAC})</option>
                        {this.unassignedMacOptions()}
                      </select> */}
                    </div>
                  </div>
                  <div className="half">
                    <div className="form-group">
                      <label>New Device Type</label>
                      {this.newDeviceSelector()}
                    </div>
                  </div>
                </div>
                <div className={help.invalidSwapMacMsgClass(state)}>
                  {state.invalidMacMsg}
                  <MacAdressFormats className='mac-validation' />
                </div>
              </div>
            </div>
          </div>

          <ConfigSwitches parentState={this.state} switchHandler={this.switchHandler} />
          {this.FqdnSelection()}
          {this.lineSettingsTable()}

        </form>
      )
    }
  }

  render() {
    return (
      <span>
        <span onClick={this.handleShow}>
          <a className="btn" style={{ fontSize: "small" }}>Configure</a>
        </span>

        <Modal
          id='device-modal'
          dialogClassName="device-config-modal"
          show={this.state.showModal}
          onHide={this.handleClose}
          backdrop='static'
        >
          <Modal.Header closeButton>
            <Modal.Title>Configure Device</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.modalBody()}
          </Modal.Body>
          <Modal.Footer>
            <hr />
            {this.saveButton()}
            <SaveStatus
              saving={this.state.saving}
              statusMessage={this.state.statusMessage}
              error={this.state.error}
              parentCallBack={this.closeSaveMessage}
            // SavingMessage={'Configuring Device. Operation may take some time to complete!'}
            />
          </Modal.Footer>
        </Modal>
      </span>
    );
  }


};

export default ConfigureDevicesModal;