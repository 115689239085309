import React, { Component } from 'react';
import { DataLoadingSpinner } from './spinners';

const SaveSpinner = (props) => {
    if (props.Message) {
        return (
            <div style={{marginTop:'50px'}}>
                <div className='save-status' style={{width: "100%", fontSize: 'x-large', fontWeight: 'bold', textAlign: 'center'}}>
                    {props.Message}
                </div>
                <br />
                <div>
                    <DataLoadingSpinner className='load saving spinner' style={{position: 'fixed !important', marginTop:"50px"}} />
                </div>
            </div>
        )
    } else {
        return null;
    }
}

export default SaveSpinner;