import React, { useState, useEffect, useRef, Fragment } from "react";
import { Row, Col, Button } from "react-bootstrap";
import { Modal } from "react-bootstrap";
import { DataLoadingSpinner } from "../../components/spinners";
import * as apiCalls from "../../requests/api_calls";
import * as config from "../../requests/config";
import moment from "moment";
import { DatePicker } from "@progress/kendo-dateinputs-react-wrapper";
import * as session from "../../scripts/session";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { homePageUrl } from "../../scripts/helper";

const CustomCollapse = ({ id, title, children }) => {
  const [isSectionCollapse, setIsSectionCollapsed] = useState(true);

  const toggleCollapseSection = () => {
    setIsSectionCollapsed(!isSectionCollapse);
  };
  const headerId = `header${id}`;
  const collapseId = `collapse${id}`;

  const collapseHeaderSectionStyle = {
    borderTopRightRadius: "10px",
    borderTopLeftRadius: "10px",
    padding: "1% 1%",
    color: "#ffff",
    marginBottom: "0px",
    backgroundColor: "#44576f",
    fontSize: "16px",
  };

  return (
    <div style={{ marginBottom: "20px" }}>
      <div id={id} role="tablist" aria-multiselectable="true">
        <div role="tab" id={headerId}>
          <a
            role="button"
            data-toggle="collapse"
            data-parent={`#${id}`}
            href={`#${collapseId}`}
            aria-expanded={!isSectionCollapse}
            onClick={toggleCollapseSection}
            aria-controls={collapseId}
          >
            <p style={collapseHeaderSectionStyle} className="bold">
              {title}
              <span style={{ float: "right" }}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 16 13"
                  width="18"
                  height="18"
                  fill="rgb(101, 101, 101)"
                  stroke="rgb(101, 101, 101)"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  style={{
                    transform: isSectionCollapse
                      ? "rotate(0deg)"
                      : "rotate(180deg)",
                  }}
                >
                  <path
                    fill="rgb(101, 101, 101)"
                    d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"
                  />
                </svg>
              </span>
            </p>
          </a>
        </div>
      </div>

      <div
        id={collapseId}
        className="panel panel-collapse collapse"
        role="tabpanel"
        aria-labelledby={headerId}
      >
        <div
          className="panel-body"
          style={{
            margin: "auto",
            background: "#e3e5eb",
            padding: "2% 7%",
            borderBottomRightRadius: "10px",
            borderBottomLeftRadius: "10px",
          }}
        >
          <div className="settings-row">{children}</div>
        </div>
      </div>
    </div>
  );
};

export default function BillingMain(props) {
  const [availableLocations, setAvailableLocations] = useState([]);
  const [customerInfo, setCustomerInfo] = useState({});
  const [billingAddr, setBillingAddr] = useState({});
  const [currentBill, setCurrentBill] = useState({});
  const [paymentHistory, setPaymentHistory] = useState([]);
  const [selectStatement, setSelectStatement] = useState([]);
  const [invoices, setInvoices] = useState([]);
  const [recentInvoices, setRecentInvoices] = useState([]);
  const [loading, setLoading] = useState(false);
  const [accNum, setAccNum] = useState("");
  const [url, setURL] = useState("");
  const [invoiceNotFoundData, setInvoiceNotFoundData] = useState("");
  const currentYear = new Date().getFullYear();
  const years = Array.from({ length: 10 }, (_, index) => currentYear - index);
  const downloadPdfRef2 = useRef();
  const iframeRef = useRef();
  const [modalDisplay, setModalDisplay] = useState(null);
  const downloadCsvRef = useRef(null);

  const [selectedBillingAccountNumber, setSelectedBillingAccountNumber] =
    useState("");

  useEffect(() => {
    if (
      session.IsWholesale() ||
      sessionStorage.userEnterpriseNumber === "3904000825" ||
      sessionStorage.hasBillingAdminPermission == "false"
    ) {
      const homePathToRedirect = homePageUrl();
      props.history.push(homePathToRedirect);
    }
    getBillingData();
  }, []);

  const handleSetUp = async (e, id) => {
    e.preventDefault();
    e.isDefaultPrevented();

    await getURL(
      id,
      accNum,
      currentBill?.RecurringPaymentMethod,
      currentBill?.Balance
    );
    setModalDisplay("showDeletePrompt2");
  };

  const handleProcess = async () => {
    setModalDisplay("showDraftModal");
  };

  const getBillingData = async (accountBillingNumber) => {
    setLoading(true);
    try {
      let path = accountBillingNumber
        ? `usr/billing/GetData?billingAccountNumber=${accountBillingNumber} `
        : "usr/billing/GetData";
      const response = await apiCalls.fetchData(path);
      if (response) {
        setAccNum(response?.BillingAccountNumber);
        setAvailableLocations(
          response?.AvailableBillingLocations &&
            response.AvailableBillingLocations
        );
        setSelectedBillingAccountNumber(
          accountBillingNumber
            ? accountBillingNumber
            : response?.AvailableBillingLocations &&
                response.AvailableBillingLocations[0]
        );
        setCustomerInfo(response?.CustomerName);
        setBillingAddr(response?.BillingAddress);
        setCurrentBill(response?.CurrentBill);
        setPaymentHistory(response?.Payments);
        setInvoices(response?.Invoices);

        const invoicesLastMonth = response?.Invoices
          ? response.Invoices.filter((invoice) => {
              // Get current date
              const currentDate = new Date();
              // Get invoice date
              const invoiceDate = new Date(invoice.InvoiceDate);

              // Get the previous month and the year of the previous month
              let previousMonth = currentDate.getMonth() - 1;
              let previousMonthYear = currentDate.getFullYear();

              // If previous month is January (0), set previousMonth to December (11) and decrease the year by 1
              if (previousMonth < 0) {
                previousMonth = 11;
                previousMonthYear--;
              }

              // Extract the month and year from the invoiceDate
              const invoiceDateMonth = invoiceDate.getMonth();
              const invoiceDateYear = invoiceDate.getFullYear();

              // Check if the invoiceDate is from the previous month
              if (
                invoiceDateMonth === previousMonth &&
                invoiceDateYear === previousMonthYear
              )
                return invoice;
            })
          : [];
        setRecentInvoices(invoicesLastMonth);
      }
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  };
  const getIP = async () => {
    try {
      const response = await fetch("https://api.ipify.org?format=json");
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      return data.ip;
    } catch (error) {
      console.error("Error fetching IP:", error);
      return null;
    }
  };

  const getURL = async (id, accNum, method, bal) => {
    setModalDisplay("loading");
    try {
      const recurringPaymentMethod = method;
      // let returnUrl = window.location.protocol + '//' + window.location.host +'/BillingNew';
      // let returnUrl = window.location.host +'/BillingNew';
      let returnUrl = "";
      const amount = bal;
      const pageId = id;
      let ip = await getIP();
      const reqParam = {
        billingAccountNumber: accNum,
        recurringPaymentMethod: recurringPaymentMethod,
        amount: amount,
        pageId: pageId,
        clientIP: ip,
        returnUrl: returnUrl,
      };
      let path = "usr/billing/GetPayTraceUrl";
      //
      const response = await apiCalls.post(
        path,
        "POST",
        JSON.stringify(reqParam)
      );
      if (response.ok) {
        let parsedResponse = JSON.parse(response?.message);
        setURL(parsedResponse);
      }
    } catch (error) {
      console.error("Getting URL", error);
      throw error;
    } finally {
      // display the one time payment modal
      if (id == 0) {
        setModalDisplay("showOneTimeModal");
      }
      // display the recurring payment modal
      if (id == 1) {
        setModalDisplay("showRecurringModal");
      }
      // display the bank draft payment modal
      if (id == 3) {
        setModalDisplay("showDraftModal");
      }
    }
  };

  const handleSelect = (e) => {
    setSelectStatement(document.getElementById("selectInvoice").value);
  };

  const NumberWithBrackets = ({ number }) => {
    const isNegative = number < 0;
    const formattedNumber = number.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return (
      <span>
        {isNegative ? "(" : ""}
        {"$" + formattedNumber.replace(/-/g, "")}
        {isNegative ? ")" : ""}
      </span>
    );
  };
  const handleDropdownChange = (e) => {
    getBillingData(e.target.value);
  };
  const exportCallLogs = async (id) => {
    await setInvoiceNotFoundData("");
    await setModalDisplay("loading");
    try {
      const response = await fetch(config.baseApiUrl + "admin/GetInvoice", {
        method: "POST",
        body: new URLSearchParams({
          AccountNumber: accNum,
          InvoiceId: id,
          access_token: config.storedAccessToken(),
          x_policy: config.storedXPolicy(),
        }),
      });

      if (!response.ok && response.status === 404) {
        const data = await response.text();
        const pattern = /\w+\(\): (.+?)\. UserId=/;
        const match = data.match(pattern);
        const errorMessage = match ? match[1].trim() + "." : data;

        await setInvoiceNotFoundData(errorMessage);

        setModalDisplay("showInvoiceNotFound");
      } else {
        setModalDisplay("");
      }

      // If the response is successful, submit the form
      downloadCsvRef.current.submit();
    } catch (error) {
      setModalDisplay("");
      // Handle network errors or any other unexpected errors here
      console.error("Error:", error);
    }
  };

  // this function dynamically creates and submits a form on button click so that last loaded invoiceID is used on API call
  const exportInvoice = (id) => {
    return (
      <div id="div-calls-csv">
        <form
          ref={downloadCsvRef}
          target="iFrameSave"
          action={config.baseApiUrl + "admin/GetInvoice"}
          method="POST"
        >
          <input type="hidden" name="AccountNumber" value={accNum}></input>
          <input type="hidden" name="InvoiceId" value={id} />
          <input
            type="hidden"
            name="access_token"
            value={config.storedAccessToken()}
            className="hidden"
          ></input>
          <input
            type="hidden"
            name="x_policy"
            value={config.storedXPolicy()}
            className="hidden"
          ></input>
        </form>
        <iframe
          name="iFrameSave"
          height="0"
          width="0"
          className="hidden pull-right"
        ></iframe>
        {/* Button element */}
        <Button
          bsStyle="link"
          bsSize="xsmall"
          className="linkButton"
          onClick={() => exportCallLogs(id)}
        >
          #{id}
        </Button>
      </div>
    );
  };

  const testExport2 = (invoices, id) => {
    // Get POST data
    if (invoices && invoices.length > 0) {
      return (
        <div id="invoice-csv" className="pull-left">
          <form
            ref={downloadPdfRef2}
            target="iFrameSave"
            action={config.baseApiUrl + "admin/GetInvoice"}
            method="POST"
          >
            <input type="hidden" name="AccountNumber" value={accNum}></input>
            <input type="hidden" name="InvoiceId" value={id}></input>
            <input
              type="hidden"
              name="access_token"
              value={config.storedAccessToken()}
              className="hidden"
            ></input>
            <input
              type="hidden"
              name="x_policy"
              value={config.storedXPolicy()}
              className="hidden"
            ></input>
          </form>
          <iframe
            ref={iframeRef}
            id="iFrameSave"
            name="iFrameSave"
            height="0"
            width="0"
            className="hidden pull-right"
          ></iframe>
          <Button onClick={(e) => exportInvoiceGo(e, id)}>Go</Button>
        </div>
      );
    }
  };

  const exportInvoiceGo = async (e, id) => {
    setInvoiceNotFoundData("");
    setModalDisplay("loading");
    try {
      const response = await fetch(config.baseApiUrl + "admin/GetInvoice", {
        method: "POST",
        body: new URLSearchParams({
          AccountNumber: accNum,
          InvoiceId: id,
          access_token: config.storedAccessToken(),
          x_policy: config.storedXPolicy(),
        }),
      });

      if (!response.ok && response.status === 404) {
        const data = await response.text();
        const pattern = /\w+\(\): (.+?)\. UserId=/;
        const match = data.match(pattern);
        const errorMessage = match ? match[1].trim() + "." : data;

        setInvoiceNotFoundData(errorMessage);

        setModalDisplay("showInvoiceNotFound");
      } else {
        setModalDisplay("");
      }

      // If the response is successful, submit the form
      if (downloadPdfRef2.current) {
        downloadPdfRef2.current.submit();
      }
    } catch (error) {
      setModalDisplay("");
      // Handle network errors or any other unexpected errors here
      console.error("Error:", error);
    }
  };

  const BillingModalContent = (content = "") => {
    let btnStyle = { width: "48%", textWrap: "wrap", backgroundColor: "#fff" };
    // one variable for managing which screen is displayed inside a single modal
    switch (modalDisplay) {
      case "loading":
        return (
          <>
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
              <DataLoadingSpinner className="load spinner" />
            </Modal.Body>
            <Modal.Footer></Modal.Footer>
          </>
        );
      case "showDeletePrompt":
        return (
          <>
            <Modal.Header closeButton style={{ paddingBottom: "0px" }}>
              <Modal.Title
                style={{
                  borderTopLeftRadius: "10px",
                  borderTopRightRadius: "10px",
                  padding: "0 1%",
                  color: "#ffff",
                  marginBottom: "0px",
                  backgroundColor: "#44576f",
                }}
              >
                Manage Payments
              </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ paddingTop: "0px" }}>
              <div
                style={{
                  fontSize: "small",
                  borderBottomRightRadius: "10px",
                  borderBottomLeftRadius: "10px",
                  padding: "2% 3% 3%",
                  textAlign: "justify",
                  backgroundColor: "rgb(227, 229, 235)",
                }}
              >
                <p style={{ paddingTop: "2%" }}>
                  Manage your billing payment methods using any one of the
                  following options:
                </p>
                {/* {currentBill?.RecurringPaymentMethod?.toLowerCase() === 'recurring credit card'
                  &&  */}
                <div style={{ textAlign: "center" }}>
                  <Button style={btnStyle} onClick={(e) => handleSetUp(e, 1)}>
                    Manage recurring credit card payments
                  </Button>
                </div>
                {/* } */}
                <div style={{ textAlign: "center" }}>
                  <Button style={btnStyle} onClick={(e) => handleSetUp(e, 0)}>
                    Make a 1-time credit/debit card payment
                  </Button>
                </div>

                {currentBill?.RecurringPaymentMethod?.toLowerCase() ===
                "recurring bank draft" ? (
                  <div style={{ paddingTop: "3%", textAlign: "center" }}>
                    <Button style={btnStyle} onClick={(e) => handleSetUp(e, 2)}>
                      Edit recurring bank draft
                    </Button>
                    <Button style={btnStyle} onClick={(e) => handleSetUp(e, 2)}>
                      Cancel recurring payment
                    </Button>
                  </div>
                ) : (
                  <div style={{ textAlign: "center" }}>
                    <Button style={btnStyle} onClick={(e) => handleSetUp(e, 2)}>
                      Manage recurring bank draft payments
                    </Button>
                  </div>
                )}
              </div>
            </Modal.Body>
          </>
        );
      case "showDeletePrompt2":
        return (
          <>
            <Modal.Header closeButton></Modal.Header>
            {url && (
              <Fragment>
                <object data={url} height="500" width="500">
                  Please close the popup.
                </object>
                <br />
              </Fragment>
            )}
          </>
        );
      case "showRecurringModal":
        return (
          <>
            <Modal.Header
              closeButton
              style={{ paddingBottom: "10px" }}
            ></Modal.Header>
            <Modal.Body style={{ paddingtop: "0px" }}>
              <div style={{ fontSize: "small" }}>
                <h5
                  style={{
                    borderTopRightRadius: "10px",
                    borderTopLeftRadius: "10px",
                    padding: "1% 1%",
                    color: "#ffff",
                    marginBottom: "0px",
                    backgroundColor: "#44576f",
                  }}
                  className="bold"
                >
                  Recurring Payments
                </h5>
                <p>
                  If you choose to pay by recurring credit card, there will be a
                  credit card processing fee in the amount of 1.8% applied to
                  each recurring transaction. The credit card processing fee is
                  less than our costs incurred with accepting your credit card.
                  You can avoid the processing fee by choosing another non
                  credit card payment method.
                </p>
                <div style={{ margin: "5% 0%" }}>
                  <label htmlFor="account_num" style={{ marginRight: "5%" }}>
                    Account Number
                  </label>
                  <input type="text" id="account_num" value={accNum} disabled />
                </div>
                <div style={{ margin: "5% 0%" }}>
                  <label htmlFor="ammount_due" style={{ marginRight: "5%" }}>
                    Amount Due
                  </label>
                  <input
                    type="text"
                    id="ammount_due"
                    value={currentBill?.Balance}
                    disabled
                  />
                </div>
                <div style={{ margin: "5% 0%" }}>
                  <label htmlFor="month" style={{ marginRight: "5%" }}>
                    Month
                  </label>
                  <select id="month">
                    <option value="01">January</option>
                    <option value="02">February</option>
                    <option value="03">March</option>
                    <option value="04">April</option>
                    <option value="05">May</option>
                    <option value="06">June</option>
                    <option value="07">July</option>
                    <option value="08">August</option>
                    <option value="09">September</option>
                    <option value="10">October</option>
                    <option value="11">November</option>
                    <option value="12">December</option>
                  </select>
                </div>
                <div
                  style={{
                    width: "50%",
                    margin: "5% 0%",
                  }}
                >
                  <label htmlFor="year" style={{ marginRight: "5%" }}>
                    Year
                  </label>
                  <DatePicker
                    id="year"
                    format="yyyy"
                    start="decade"
                    depth="decade"
                    min={new Date(years[years.length - 1], 0, 1)}
                    max={new Date()}
                  />
                </div>
                <div
                  style={{
                    margin: "5% 0%",
                  }}
                >
                  <label htmlFor="cc_num" style={{ marginRight: "5%" }}>
                    CC Number
                  </label>
                  <input
                    type="text"
                    id="cc_num"
                    // value={ccNumber}
                    disabled
                  />
                </div>
                <Button onClick={handleProcess}>Process</Button>
                <Button onClick={() => setModalDisplay(null)}>Cancel</Button>
                <p>
                  © 2020 - PaymentPages -{" "}
                  <a
                    target="_blank"
                    href="https://ptepages.momentumtelecom.com/Home/Privacy"
                  >
                    Privacy
                  </a>
                </p>
              </div>
            </Modal.Body>
            <Modal.Footer></Modal.Footer>
          </>
        );
      case "showOneTimeModal":
        return (
          <>
            <Modal.Header
              closeButton
              style={{ paddingBottom: "10px" }}
            ></Modal.Header>
            <Modal.Body style={{ paddingtop: "0px" }}>
              <div style={{ fontSize: "small" }}>
                <h5
                  style={{
                    borderTopRightRadius: "10px",
                    borderTopLeftRadius: "10px",
                    padding: "1% 1%",
                    color: "#ffff",
                    marginBottom: "0px",
                    backgroundColor: "#44576f",
                  }}
                  className="bold"
                >
                  One Time Payments
                </h5>
                <p>
                  If you choose to pay by credit card, there will be a credit
                  card processing fee in the amount of 1.8% applied to this
                  transaction. The credit card processing fee is less than our
                  costs incurred with accepting your credit card. You can avoid
                  the processing fee by choosing another non credit card payment
                  method.
                </p>
                <div style={{ margin: "5% 0%" }}>
                  <label htmlFor="account_num" style={{ marginRight: "5%" }}>
                    Account Number
                  </label>
                  <input type="text" id="account_num" value={accNum} disabled />
                </div>
                <div style={{ margin: "5% 0%" }}>
                  <label htmlFor="ammount_due" style={{ marginRight: "5%" }}>
                    Amount Due
                  </label>
                  <input
                    type="text"
                    id="ammount_due"
                    value={currentBill?.Balance}
                    disabled
                  />
                </div>
                <div style={{ margin: "5% 0%" }}>
                  <label htmlFor="month" style={{ marginRight: "5%" }}>
                    Month
                  </label>
                  <select id="month">
                    <option value="01">January</option>
                    <option value="02">February</option>
                    <option value="03">March</option>
                    <option value="04">April</option>
                    <option value="05">May</option>
                    <option value="06">June</option>
                    <option value="07">July</option>
                    <option value="08">August</option>
                    <option value="09">September</option>
                    <option value="10">October</option>
                    <option value="11">November</option>
                    <option value="12">December</option>
                  </select>
                </div>
                <div
                  style={{
                    width: "50%",
                    margin: "5% 0%",
                  }}
                >
                  <label htmlFor="year" style={{ marginRight: "5%" }}>
                    Year
                  </label>
                  <DatePicker
                    id="year"
                    format="yyyy"
                    start="decade"
                    depth="decade"
                    min={new Date(years[years.length - 1], 0, 1)}
                    max={new Date()}
                  />
                </div>
                <div
                  style={{
                    margin: "5% 0%",
                  }}
                >
                  <label htmlFor="cc_num" style={{ marginRight: "5%" }}>
                    CC Number
                  </label>
                  <input
                    type="text"
                    id="cc_num"
                    // value={ccNumber}
                    disabled
                  />
                </div>
                <Button onClick={handleProcess}>Process</Button>
                <Button onClick={() => setModalDisplay(null)}>Cancel</Button>
                <p>
                  © 2020 - PaymentPages -{" "}
                  <a
                    target="_blank"
                    href="https://ptepages.momentumtelecom.com/Home/Privacy"
                  >
                    Privacy
                  </a>
                </p>
              </div>
            </Modal.Body>
            <Modal.Footer></Modal.Footer>
          </>
        );
      case "showDraftModal":
        return (
          <>
            <Modal.Header
              closeButton
              style={{ paddingBottom: "10px" }}
            ></Modal.Header>
            <Modal.Body style={{ paddingtop: "0px" }}>
              <div style={{ fontSize: "small" }}>
                <h5
                  style={{
                    borderTopRightRadius: "10px",
                    borderTopLeftRadius: "10px",
                    padding: "1% 1%",
                    color: "#ffff",
                    marginBottom: "0px",
                    backgroundColor: "#44576f",
                  }}
                  className="bold"
                >
                  Payment through Bank Draft
                </h5>
                <p>
                  Please fill the following information to make a payment. All
                  infromation you enter must match you checking or saving
                  accounts statement. Funds will be drafted no earlier than
                  three days before your invoice's due date.
                </p>
                <div style={{ margin: "5% 0%" }}>
                  <label htmlFor="account_name" style={{ marginRight: "5%" }}>
                    Account Name
                  </label>
                  <input type="text" id="account_name" disabled />
                </div>
                <div style={{ margin: "5% 0%" }}>
                  <label htmlFor="ammount_type" style={{ marginRight: "5%" }}>
                    Amount Type
                  </label>
                  <select id="month">
                    <option value="checking">Checking</option>
                    <option value="saving">Saving</option>
                  </select>
                </div>
                <div style={{ margin: "5% 0%" }}>
                  <label htmlFor="routing_num" style={{ marginRight: "5%" }}>
                    Routing Number
                  </label>
                  <input type="text" id="routing_num" disabled />
                </div>
                <div style={{ margin: "5% 0%" }}>
                  <label htmlFor="acct_num" style={{ marginRight: "5%" }}>
                    Account Number
                  </label>
                  <input type="text" id="acct_num" disabled />
                </div>
                <div style={{ margin: "5% 0%" }}>
                  <img src="/img/check_two.gif" />
                </div>
                <div style={{ margin: "5% 0%" }}>
                  <label
                    htmlFor="terms"
                    style={{ marginRight: "5%" }}
                    required
                    className=""
                  >
                    I have read and agree to the
                  </label>
                  <input type="checkbox" id="terms" />
                </div>
                <div style={{ margin: "5% 0%" }}>
                  <Button onClick={handleProcess}>Save</Button>
                  <Button onClick={() => setModalDisplay(null)}>Cancel</Button>
                </div>
                <p>
                  Recurring Payment (Funds will be drafted no earlier than three
                  days before your invoice's due date).
                </p>
                <p>
                  Please be advised when you set up a recurring payment method
                  any past due balance as well as current charges will be
                  charged on your first scheduled payment date.
                </p>
                <p>
                  © 2020 - PaymentPages -{" "}
                  <a
                    target="_blank"
                    href="https://ptepages.momentumtelecom.com/Home/Privacy"
                  >
                    Privacy
                  </a>
                </p>
              </div>
            </Modal.Body>
            <Modal.Footer></Modal.Footer>
          </>
        );
      case "showInvoiceNotFound":
        return (
          <>
            <Modal.Header closeButton style={{ paddingBottom: "0px" }}>
              <Modal.Title
                style={{
                  borderTopLeftRadius: "10px",
                  borderTopRightRadius: "10px",
                  padding: "0 1%",
                  color: "#ffff",
                  marginBottom: "0px",
                  backgroundColor: "#44576f",
                }}
              >
                Error
              </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ paddingTop: "0px" }}>
              <div
                style={{
                  fontSize: "small",
                  borderBottomRightRadius: "10px",
                  borderBottomLeftRadius: "10px",
                  padding: "2% 3% 3%",
                  textAlign: "justify",
                  backgroundColor: "rgb(227, 229, 235)",
                }}
              >
                <p style={{ paddingTop: "2%" }}>{invoiceNotFoundData}</p>
              </div>
            </Modal.Body>
            <Modal.Footer></Modal.Footer>
          </>
        );
      default:
        return <></>;
    }
  };

  const row = (col1, col2, noBorder = false) => {
    return (
      <Row style={{ width: "100%", margin: "auto" }}>
        <div
          style={{
            fontSize: "1.2rem",
            fontWeight: "600",
            padding: "0.5% 0%",
            display: "flex",
            borderBottom: noBorder ? "none" : "1px solid gray",
          }}
        >
          <Col xs={6} md={6} lg={6} xl={6} style={{ paddingLeft: "0px" }}>
            {col1}
          </Col>
          <Col xs={6} md={6} lg={6} xl={6} style={{ color: "#333333b0" }}>
            {col2}
          </Col>
        </div>
      </Row>
    );
  };

  return (
    <div className="full-card container">
      <div className="iframe-holder card">
        <div className="card-header">
          <h1>Billing</h1>
        </div>
        <div className="card" style={{ marginTop: "0%", padding: "4% 6%" }}>
          {loading ? (
            <DataLoadingSpinner
              style={{
                display: "inline",
              }}
              className="load spinner"
            />
          ) : (
            <>
              <Modal
                dialogClassName="confirm-cancel-macd"
                show={modalDisplay}
                onHide={() => setModalDisplay(null)}
                backdrop="static"
              >
                {BillingModalContent()}
              </Modal>

              {customerInfo && Object.keys(customerInfo).length > 0 && (
                <CustomCollapse id="CustomerProfile" title="Customer Profile">
                  <>
                    {row("Name", customerInfo)}
                    {row(
                      "Account Number",
                      availableLocations.length > 1 ? (
                        <DropDownList
                          style={{ width: "300px" }}
                          data={availableLocations}
                          value={selectedBillingAccountNumber}
                          onChange={handleDropdownChange}
                        />
                      ) : (
                        availableLocations[0]
                      )
                    )}
                    {row("Payment", currentBill?.RecurringPaymentMethodDisplay)}
                    {row(
                      "Billing Address",
                      <>
                        {billingAddr?.Street1BILL}
                        <br />
                        {billingAddr?.CityBILL},&nbsp;{billingAddr?.StateBILL}
                        <br />
                        {billingAddr?.ZipBILL}
                      </>,
                      true
                    )}
                  </>
                </CustomCollapse>
              )}

              {currentBill && Object.keys(currentBill).length > 0 && (
                <CustomCollapse id="Bills" title="Bills">
                  <>
                    {row(
                      "Current Bill:",
                      <>{`$${currentBill?.CurrentBalance.toLocaleString(
                        undefined,
                        {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }
                      )}`}</>
                    )}
                    {row(
                      "Past Due:",
                      <>{`$${currentBill?.PastDue.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}`}</>
                    )}
                    {row(
                      "Amount Due:",
                      <>{`$${currentBill?.Balance.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}`}</>
                    )}
                    {row(
                      "Due Date:",
                      <>{moment(currentBill?.DueDate).format("MM/DD/YYYY")}</>,
                      true
                    )}
                    <div style={{ textAlign: "right" }}>
                      <Button
                        onClick={(e) => {
                          setModalDisplay("showDeletePrompt");
                        }}
                        className="a btn"
                      >
                        Pay My Bill
                      </Button>
                    </div>
                  </>
                </CustomCollapse>
              )}

              <CustomCollapse id="RecentActivity" title="Recent Activity">
                <Row style={{ width: "100%", margin: "auto" }}>
                  {Object.keys(paymentHistory).length > 0 && (
                    <Col
                      xs={12}
                      sm={12}
                      md={6}
                      lg={6}
                      style={{ color: "#333333b0" }}
                    >
                      {paymentHistory.slice(0, 4).map((pay) => (
                        <p
                          style={{
                            fontSize: "1.2rem",
                            fontWeight: "600",
                          }}
                        >
                          Payment of $
                          {pay.PaymentAmount.toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}{" "}
                          on{" "}
                          {moment(pay.PaymentDate).format("M/D/YYYY h:mm:ss A")}
                          <br />
                        </p>
                      ))}
                    </Col>
                  )}
                  {Object.keys(recentInvoices).length > 0 && (
                    <Col
                      xs={12}
                      sm={12}
                      md={6}
                      lg={6}
                      style={{
                        color: "#333333b0",
                        borderLeft: "1px solid gray",
                      }}
                    >
                      {recentInvoices.map((invoice) => (
                        <div
                          key={invoice.InvoiceID}
                          style={{ fontSize: "1.2rem", fontWeight: "600" }}
                        >
                          <span>
                            Statement{" "}
                            <div
                              id="invoice-csv"
                              style={{
                                display: "inline-block",
                                margin: "0% -2% 0% -1%",
                              }}
                            >
                              {exportInvoice(invoice?.InvoiceID)}
                            </div>
                          </span>{" "}
                          in the amount of{" "}
                          <NumberWithBrackets
                            number={invoice?.AmountWithCreditIssued}
                          />
                          &nbsp;on&nbsp;
                          {moment(invoice.InvoiceDate).format(
                            "M/D/YYYY h:mm:ss A"
                          )}
                          <br />
                        </div>
                      ))}
                    </Col>
                  )}
                </Row>
              </CustomCollapse>

              <CustomCollapse
                id="CurrentBillingMethod"
                title="Current Billing Method"
              >
                <Row style={{ width: "100%", margin: "auto" }}>
                  <div
                    style={{
                      fontSize: "1.2rem",
                      fontWeight: "600",
                      padding: "0.5% 0%",
                    }}
                  >
                    <p>{currentBill?.RecurringPaymentMethodDisplay}</p>
                    <Button
                      onClick={(e) => {
                        setModalDisplay("showDeletePrompt");
                      }}
                    >
                      Set up
                    </Button>
                  </div>
                </Row>
              </CustomCollapse>

              {invoices?.length > 0 && (
                <CustomCollapse id="Statements" title="Statements">
                  <Row style={{ width: "100%", margin: "auto" }}>
                    <div
                      style={{
                        fontSize: "1.2rem",
                        fontWeight: "600",
                        padding: "0.5% 0%",
                      }}
                    >
                      <div style={{ display: "inline-flex", width: "100%" }}>
                        <p style={{ marginRight: "2%" }}>View Statements</p>
                        <select
                          id="selectInvoice"
                          style={{
                            height: "20px",
                          }}
                          // data={files.name}
                          onChange={(e) => handleSelect(e)}
                          // value={fileSelected}
                        >
                          <option value="">Please Select</option>
                          {invoices &&
                            invoices.map((invoice) => (
                              <option value={invoice.InvoiceID}>
                                {moment(invoice.InvoiceDate).format(
                                  "MM/DD/YYYY"
                                )}
                              </option>
                            ))}
                        </select>
                      </div>
                      {testExport2(invoices, selectStatement)}
                    </div>
                  </Row>
                </CustomCollapse>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
}