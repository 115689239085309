import React from 'react';
import { fetchData } from './../../requests/api_calls';
import * as globalHelper from './../../scripts/helper';
import { orderBy } from '@progress/kendo-data-query';

export const macAddress = 'macAddress';

export const newMac = 'newMAC';

export const validMacAddress = (mac) => {
  let regex1 = /^([A-F, A-F, a-f, 0-9]{2}[:]{1}){5}[A-F, A-F, a-f, 0-9]{2}$/;
  let regex2 = /^([A-F, A-F, a-f, 0-9]{2}[-]{1}){5}[A-F, A-F, a-f, 0-9]{2}$/;
  let regex3 = /^([A-F, A-F, a-f, 0-9]{3}[.]){3}[A-F, A-F, a-f, 0-9]{3}$/;
  let regex4 = /^[A-F, a-f, 0-9]{12}$/;
  return regex1.test(mac) || regex2.test(mac) || regex3.test(mac) || regex4.test(mac);
}

export const saveBtnsClass = (state) => state.showSaveStatus ? 'btns opaque' : 'btns';

export const saveAndAddNewClass = (props) => addingDevice(props) ? 'btn' : 'hidden';

export const formClass = (state) => state.showSaveStatus ? 'form-group hidden' : 'form-group';

export const addingDevice = (props) => !props.mac;

export const editingDevice = (props) => !addingDevice(props);

export const showInvalidMessage = (state, fieldName) => {
  return (fieldName === macAddress && state.showInvalidMacMsg) ||
  state.showRequiredFieldMsgs && state.invalidFields.indexOf(fieldName) > -1;
}

export const invalidMessageClass = (state, fieldName) => {
  return showInvalidMessage(state, fieldName) ? 'text-danger' : 'hidden';
}

export const showInvalidSwapMacMsg = (state) => {
  return state.showInvalidMacMsg && state.invalidFields.indexOf(newMac) < 0;
}

export const invalidSwapMacMsgClass = (state) => {
  return showInvalidSwapMacMsg(state) ? 'text-danger' : 'hidden';
}

export const validMacClass = (state, fieldName) => {
  return !state.showInvalidMacMsg && state.showValidMacMsg ? 'validation-success' : 'hidden';
}

export const fetchFqdns = async () => fetchData('admin/GetFqdns').then(data => data);

export const fetchModels = async () => fetchData('admin/GetDeviceModels').then(data => data);

export const fetchDeviceTns = async (locationAccNum) => {
  let params = '?locationAccountNumber=' + locationAccNum;
  return fetchData('admin/GetDeviceTns' + params).then(data => data);
}

export const fetchDeviceSelectOptions = async () => {
  let requests = [fetchFqdns(), fetchModels()];
  return await Promise.all(requests).then(dataArray => dataArray)
}
export const fetchDeviceConfig = async (mac) => {
  return fetchData('admin/GetDeviceConfiguration?mac=' + mac).then(data => data);
}

export const fetchDeviceAndTns = async (mac) => {
  let deviceProps = await fetchData('admin/GetDevice?mac=' + mac).then(data => data);
  let locationAccNum = deviceProps.LocationAccountNumber;
  let requests = [fetchDeviceLocationProps(locationAccNum), fetchDeviceTns(locationAccNum)];
  let [locationProps, deviceTns] = await Promise.all(requests).then(dataArray => dataArray)
  let sort = [{ field: "ServiceNumber", dir: "asc" }, {field: "Extension", dir: "asc"}]
  deviceTns = orderBy(deviceTns, sort);
  //Created these to pull the Group Setting defaults into the equation for the configureDeviceModal
  deviceProps.LocationFQDN = locationProps && locationProps.FQDN ? locationProps.FQDN : null;
  deviceProps.LocationVLAN = locationProps && locationProps.VlanId ? locationProps.VlanId : null;
  deviceProps.LocationTransportProtocol = locationProps && locationProps.TransportProtocol;
  deviceProps.LocationEncryptedAudio = locationProps && locationProps.EncryptedAudio;
  deviceProps.TransactionNumbers = deviceTns;
  return deviceProps;
}

export const fetchDeviceLocationProps = async (locationAccNum) => {
  let params = '?locationAccountNumber=' + locationAccNum;
  return fetchData('admin/GetDeviceLocationProperties' + params).then(data => data);
}

export const fetchModelConfigs = async () => {
  return fetchData('admin/GetDeviceModelsConfiguration').then(data => data);
}

export const fetchDeviceConfigs = async (props) => {
  let requests = [
    fetchDeviceConfig(props.mac),
    fetchDeviceAndTns(props.mac),
    fetchModelConfigs()
  ];
  return await Promise.all(requests).then(dataArray => dataArray)
}

export const configSetting = (config, settingName) => (config && config[settingName]) ? true : false;

export const fqdnOptions = (fqdns, defaultFQDN, selectedFQDN) => {
  if (fqdns) {
    fqdns.sort();
    return fqdns.map((fqdn, index) => <option key={index} value={fqdn} selected={fqdn === selectedFQDN} >{fqdn}</option>);
  } else return [];
}

export const modelOptions = (models) => {
  if (models) {
    return models.map((model, index) => {
      let deviceType = model.DevicyType ? model.DevicyType : model.DeviceType; //typo fix
      return <option key={index} value={deviceType} >{deviceType}</option>
    });
  } else return [];
}

export const deviceConfigureValue = (state, field) => state.configProps[field] || '';

export const configProps = (config, device, initialFormState) => {
  if (config) {
    return {...initialFormState.configProps, ...config, ...device}
  } else if (device) {
    return {...initialFormState.configProps, ...device}
  } else return null;
}

export const deviceIsAssigned = (deviceProps) => deviceProps.AssignedTo !== null;


export const onlyUnique = (val, index, self) => {
  return self.indexOf(val) === index;
};

export const tnProps = (state, tn) => {
  let matches = state.transactionNums.filter(item => {
    return tn == (item.ServiceNumber || item.Extension)
  });
  return matches[0];
}

export const showRemove = () => globalHelper.devicePerms().Delete_ExistingDevice === 1;

export const showFqdn = () => globalHelper.devicePerms().Setup_FQDN === 1;

export const showAddDevice = () => globalHelper.devicePerms().Setup_NewDevice === 1;

export const showSwap = () => globalHelper.devicePerms().Swap_Device === 1;