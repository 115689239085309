import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { Button } from '@progress/kendo-buttons-react-wrapper';
import VoicemailSettingsModal from './modals/voicemailSettingsModal';


class VoicemailSettingsRow extends Component {

  constructor(props) {
    super(props);

    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.changeForward = this.changeForward.bind(this);

    this.state = { 
      showModal: false, 
      forwardValue: "" ,
      voice_messaging: {
				selected: false,
				loading: true,
				show_success: false,
        show_error: false,
        api:  "VoiceMessage",
        label: "Voice Messaging"
      },
      send_all_calls_to_vm: {
				selected: false,
				loading: true,
				show_success: false,
        show_error: false,
        api:  "SendAllCallsToVoicemail",
				label: "Send All Calls to Voicemail"
      },
      send_busy_calls_to_vm: {
				selected: false,
				loading: true,
				show_success: false,
        show_error: false,
        api:  "SendBusyCallsToVoicemail",
				label: "Send Busy Calls to Voicemail"
				
      },
      send_unanswered_calls_to_vm: {
        forward_value: null,
				selected: false,
				loading: true,
				show_success: false,
        show_error: false,
        api:  "SendUnansweredCallstoVoicemail",
        label: "Send Unanswered Calls to Voicemail"
      },
      notify_me_email_when_new_messages_arrive: {
				selected: false,
				loading: true,
				show_success: false,
        show_error: false,
        api:  "NotifyMebyEmailwhennewmessagesarrive",
        label: "Notify me by Email when new messages arrive",
        email: null
      },
      email_carbon_copy_message: {
				selected: false,
				loading: true,
				show_success: false,
        show_error: false,
        api:  "EmailaCarbonCopyoftheMessage",
        label: "Email a carbon copy of the message",
        email: null
      },
      transfer_on_0_to_phone_number: {
				selected: false,
				loading: true,
				show_success: false,
        show_error: false,
        api:  "Transfer_0_to_Phone_Number",
        label: "Transfer on '0' to Phone Number",
        email: null
      },    
    };
  }

  changeOnOffValue =(setting, event)=>{
    let checked = event.target.checked;
    setting.selected = checked;
    switch(setting.details.api){
      case this.state.voice_messaging.details.api:
        this.setState({...{voice_messaging: setting}})
        break;
      case this.state.send_all_calls_to_vm.details.api:
        this.setState({...{send_all_calls_to_vm: setting}})
        break;
      case this.state.send_busy_calls_to_vm.details.api:
        this.setState({...{send_busy_calls_to_vm: setting}})
        break;
      case this.state.send_unanswered_calls_to_vm.details.api:
        this.setState({...{send_unanswered_calls_to_vm: setting}})
        break;
      case this.state.notify_me_email_when_new_messages_arrive.details.api:
        this.setState({...{notify_me_email_when_new_messages_arrive: setting}})
        break;
      case this.state.email_carbon_copy_message.details.api:
        this.setState({...{email_carbon_copy_message: setting}})
        break;
      case this.state.transfer_on_0_to_phone_number.details.api:
        this.setState({...{transfer_on_0_to_phone_number: setting}})
        break;
      default:
        break;
    }
  }

  changeEmailValue =(setting, event)=>{
    let email = event.target.value.trim();
    setting.email = email;
    switch(setting.api){
      case this.state.send_unanswered_calls_to_vm.api:
        this.setState({...{send_unanswered_calls_to_vm: setting}})
        break;
      case this.state.notify_me_email_when_new_messages_arrive.api:
        this.setState({...{notify_me_email_when_new_messages_arrive: setting}})
        break;
      case this.state.email_carbon_copy_message.api:
        this.setState({...{email_carbon_copy_message: setting}})
        break;
      case this.state.transfer_on_0_to_phone_number.api:
        this.setState({...{transfer_on_0_to_phone_number: setting}})
        break;
      default:
        break;
    }
  }
  handleClose() {
    this.setState({ showModal: false });
  }
  
  handleSave() {

  }

  handleShow() {
    this.setState({ showModal: true });
  }

  changeForward(event){
    let setting = this.state.send_unanswered_calls_to_vm;
    setting.forward_value = event.target.value;
    this.setState({...{send_unanswered_calls_to_vm: setting}})
  }

  componentDidMount() {   
  }

  render() {
    
    return (
      <div className="setting">
        <span onClick={this.handleShow}> 
          <span className="name">Voicemail Settings</span>
          <a className="icon btn"><svg width="14" height="14" viewBox="0 0 24 24"><path d="m163 440l-91-91 251-250 90 90z m309-352l-48-48c-12-11-32-11-45 2l-45 45 91 91 45-45c13-13 13-33 2-45z m-408 275l-32 117 117-32z" transform="scale(0.046875 0.046875)"></path></svg></a>
        </span>

        <Modal show={this.state.showModal} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Voicemail Settings</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <VoicemailSettingsModal model={this.state}
              onHandleChangeOnOffValue={(e,b) => this.changeOnOffValue(e, b)} 
              onHandleChangeEmail={(e,b) => this.changeEmailValue(e, b)}
              onHandleChangeForward={this.changeForward}
              />
          </Modal.Body>
          <Modal.Footer>
              <hr />
              <div className="btns">
              <Button className="btn" click={this.handleSave}>Save</Button>
              <Button className="btn" click={this.handleClose}>Cancel</Button>
              </div>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }

  getForwardOption(){
    switch(this.state.forwardValue){
      case "multiple":
        return (
          <div className="hidden-group">
            <div className="multiple">
              <div className="form-group">
                <label>Add up to five email addresses: <small>Seperate each email by a comma</small></label>
                <textarea name="" id="" className="form-control" placeholder="example1@momemtum.com, example2@momemtum.com"></textarea>
              </div>
              <div className="form-group">
                <label><input type="checkbox" id="waitingindicator" /> Phone Message Waiting Indicator</label>
              </div>
            </div>
          </div>
        );
      case "single":
        return (
          <div className="hidden-group">
            <div className="single">
              <label>Forward to this email:</label>
              <input type="text" className="form-control" placeholder="add an email address" />
            </div>
          </div>
        );
    }
  }

};



export default VoicemailSettingsRow;
