import * as d3 from "d3";

export const setArea = (options) => {
  let { svg, data1, data2, data, type, height, width, margin, x, y, id, summary, deviceName, node, color1, color2, dateRange, axisLabel } = options;
  if (dateRange == "-1d" || dateRange == "-2d") {
    // Define the threshold value
    const thresholdValue = d3.quantile(data1.map(d => parseFloat(d.y)), 0.95);
    // Add the area with data
    svg
      .append("path")
      .datum(data1)
      .attr("fill", "none")
      .attr("stroke", "url(#gradient)")
      .attr("stroke-width", 2.5)
      .attr("d", d3.line()
        .defined((d) => { return d.y !== null; }) // define the data points to include
        .x((d) => { return x(d.x); })
        .y((d) => { return y(d.y); })
      );
    // Add gradient to the area with data1
    const gradient = svg
      .append("linearGradient")
      .attr("id", "gradient")
      .attr("gradientUnits", "userSpaceOnUse")
      .attr("x1", 0)
      .attr("y1", 0)
      .attr("x2", 0)
      .attr("y2", height)
      .selectAll("stop")
      .data([
        { offset: y(thresholdValue) / height, color: "violet" },
        { offset: y(thresholdValue) / height, color: color1 }
      ])
      .join("stop")
      .attr("offset", (d) => d.offset)
      .attr("stop-color", (d) => d.color);
  } else {
    // Define the threshold values
    const thresholdValue = d3.quantile(data1.map(d => parseFloat(d.y)), 0.95);
    // Add the area with data1
    svg
      .append("path")
      .datum(data1)
      .attr("fill", "none")
      .attr("stroke", "url(#gradient1)")
      .attr("stroke-width", 2.5)
      .attr("d", d3.line()
        .defined((d) => { return d.y !== null; }) // define the data points to include
        .x((d) => { return x(d.x); })
        .y((d) => { return y(d.y); })
      );
    // Add the area with data2
    svg
      .append("path")
      .datum(data2)
      .attr("fill", "none")
      .attr("stroke", color2)
      .attr("stroke-width", 2.5)
      .attr("d", d3.line()
        .defined((d) => { return d.y !== null; }) // define the data points to include
        .x((d) => { return x(d.x); })
        .y((d) => { return y(d.y); })
      );
    // Add gradient1 to the area with data1
    const gradient1 = svg
      .append("linearGradient")
      .attr("id", "gradient1")
      .attr("gradientUnits", "userSpaceOnUse")
      .attr("x1", 0)
      .attr("y1", 0)
      .attr("x2", 0)
      .attr("y2", height)
      .selectAll("stop")
      .data([
        { offset: y(thresholdValue) / height, color: "violet" },
        { offset: y(thresholdValue) / height, color: color1 }
      ])
      .join("stop")
      .attr("offset", (d) => d.offset)
      .attr("stop-color", (d) => d.color);
  }
};