import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { Button } from '@progress/kendo-buttons-react-wrapper';
import { DataLoadingSpinner } from './../../components/spinners';

const busy_greetings = "busy_greetings";
const busy_greetings_personal_greetings = "busy_personal_greetings";
const no_answer_greeting = "no_answer_greetings";
const no_answer_greetings_personal_greeting = "no_answer_greeting_personal_greetings";
const alternate_no_answer_greetings_1_name = "alternate_no_answer_greetings_1_name"
const alternate_no_answer_greetings_2_name = "alternate_no_answer_greetings_2_name"
const alternate_no_answer_greetings_3_name = "alternate_no_answer_greetings_3_name"
const alternate_no_answer_greetings_1_audio = "alternate_no_answer_greetings_1_audio"
const alternate_no_answer_greetings_2_audio = "alternate_no_answer_greetings_2_audio"
const alternate_no_answer_greetings_3_audio = "alternate_no_answer_greetings_3_audio"
const alternate_no_answer_greetings_1_selected = "alternate_no_answer_greetings_1_selected"
const alternate_no_answer_greetings_2_selected = "alternate_no_answer_greetings_2_selected"
const alternate_no_answer_greetings_3_selected = "alternate_no_answer_greetings_3_selected"
const number_of_rings_before_greeting = "number_of_rings_before_greeting"
const auto_login_to_voice_portal_when_calling_from_my_phone = "auto_login_to_voice_portal_when_calling_from_my_phone"

class ManageGreetingsModal extends Component {
  
	constructor(props) {
		super(props);
    this.state = { showModal: false };
  }

	componentDidMount() {		
  }
  
  changeAlternateName=(alternate_obj, name)=>{
    alternate_obj.name = name;
  }

  changeAlternateAudio=(alternate_obj, audio)=>{
    alternate_obj.audio_selected = audio;
  }

  // changeAlternateSelected=(alternate_obj, selected)=>{
  //   alternate_obj.audio_selected = selected;
  // } 
  
  handleChanges=(type,event)=>{
    let newModel = {...this.props.Model}
    switch(type){
      case busy_greetings:
        newModel.busy_greeting.type = event.target.value.trim();
        break;
      case busy_greetings_personal_greetings:
        newModel.busy_greeting.audio_selected = event.target.value.trim();
        break;
      case no_answer_greeting:
        newModel.no_answer_greeting.type = event.target.value.trim();
        break;
      case no_answer_greetings_personal_greeting:
        newModel.no_answer_greeting.audio_selected = event.target.value.trim();
        break;
      case alternate_no_answer_greetings_1_name:
        this.changeAlternateName(newModel.no_answer_greeting.alternate_1, event.target.value.trim())
        break;
      case alternate_no_answer_greetings_2_name:
        this.changeAlternateName(newModel.no_answer_greeting.alternate_2, event.target.value.trim())
        break;
      case alternate_no_answer_greetings_3_name:
        this.changeAlternateName(newModel.no_answer_greeting.alternate_3, event.target.value.trim())
        break;
      case alternate_no_answer_greetings_1_audio:
        this.changeAlternateAudio(newModel.no_answer_greeting.alternate_1, event.target.value.trim())
        break;
      case alternate_no_answer_greetings_2_audio:
        this.changeAlternateAudio(newModel.no_answer_greeting.alternate_2, event.target.value.trim())
        break;
      case alternate_no_answer_greetings_3_audio:
        this.changeAlternateAudio(newModel.no_answer_greeting.alternate_3, event.target.value.trim())
        break;
      // case alternate_no_answer_greetings_1_selected:
      //   this.changeAlternateSelected(state.no_answer_greeting.alternate_1, true)
      //   this.changeAlternateSelected(state.no_answer_greeting.alternate_2, false)
      //   this.changeAlternateSelected(newModel.alternate_no_answer_greetings_3, false)
      //   break;
      // case alternate_no_answer_greetings_2_selected:
      //   this.changeAlternateSelected(newModel.alternate_no_answer_greetings_2, true)
      //   this.changeAlternateSelected(newModel.alternate_no_answer_greetings_1, false)
      //   this.changeAlternateSelected(newModel.alternate_no_answer_greetings_3, false)
      //   break;
      // case alternate_no_answer_greetings_3_selected:
      //   this.changeAlternateSelected(newModel.alternate_no_answer_greetings_3, true)
      //   this.changeAlternateSelected(newModel.alternate_no_answer_greetings_1, false)
      //   this.changeAlternateSelected(newModel.alternate_no_answer_greetings_2, false)
      //   break;
      case number_of_rings_before_greeting:
        newModel.number_of_rings_before_greeting = event.target.value.trim();
        break;
      case auto_login_to_voice_portal_when_calling_from_my_phone:
        newModel.auto_login = event.target.checked;
        break;

    }
    this.props.onHandleChangeModel(newModel);
  }

  render() {
    let model = this.props.Model;
    let loading = model.loading;
    if(loading){
      return ( <DataLoadingSpinner className='load spinner'/>);
    }
      

    let loading_error = model.loading_error;
    if(loading_error){
      return (<div className="inline inline-fail text-center">{loading_error}</div>);
    }

    return (
            <div className="greetings-modal">
              <div className="form-group">
                <label>Busy Greeting</label>
                <div className="form-control">
                  <label className="custom-control">
                  <input id="radio1" name="radio1" type="radio" value="0"
                  checked={model.busy_greeting.type === "0"} 
                  onChange={(e)=>{ this.handleChanges(busy_greetings, e)}} />
                    <span >System Greeting</span>
                  </label>
                  <label className="custom-control">
                    <input id="radio1" name="radio1" type="radio"  value="1" disabled={!model.audio_files_selection_enable}
                    checked={model.busy_greeting.type === "1"} 
                    onChange={(e)=>{ this.handleChanges(busy_greetings, e)}} />
                    <span>Personal Greeting</span>
                  </label>
                </div>
                {this.getBusyPersonalGreeting()}
              </div>
              <div className="form-group">
                <label>No Answer Greeting</label>
                <div className="form-control">
                  <label className="custom-control">
                  <input id="radio2" name="radio2" type="radio"  value="0"
                  checked={model.no_answer_greeting.type === "0"} 
                  onChange={(e)=>{ this.handleChanges(no_answer_greeting, e)}}/>
                    <span >System Greeting</span>
                  </label> 
                  <label className="custom-control">
                    <input id="radio2" name="radio2" type="radio" value="1"  
                    disabled={!model.audio_files_selection_enable}
                    checked={model.no_answer_greeting.type === "1"} 
                    onChange={(e)=>{ this.handleChanges(no_answer_greeting, e)}}/>
                    <span>Unavailable Greeting</span>
                  </label>
                </div>
                {this.noAnswerUnavailable()}
              </div>
              <div className="no-answer">
                <label>Alternate No Answer Greetings</label>
                <div className="form-group">
                  <div className="custom-control">
                    <div className="greeting-option-radio">
                      <input id="radio3" name="radio" type="radio"  value="2"  
                      disabled={!model.audio_files_selection_enable}
                      checked={model.no_answer_greeting.type === "2"} 
                      onChange={(e)=>{ this.handleChanges(no_answer_greeting, e)}} />
                    </div>
                    <div className="greeting-option">
                      <label>Greeting Name</label>
                      <input type="text" className="form-control" 
                      disabled={!(model.audio_files_selection_enable && model.no_answer_greeting.type === "2") }
                      value={model.no_answer_greeting.alternate_1.name} 
                      onChange={(e)=>{ this.handleChanges(alternate_no_answer_greetings_1_name, e)}}
                      />
                      {this.getTexboxRequiredError(model.no_answer_greeting.type === "2", model.no_answer_greeting.alternate_1.name)}
                      <label>Greeting Audio</label>
                      <select name="" id="" className="nums form-control"
                        value={model.no_answer_greeting.alternate_1.audio_selected} 
                        disabled={!(model.audio_files_selection_enable && model.no_answer_greeting.type === "2")}
                        onChange={(e)=>{ this.handleChanges(alternate_no_answer_greetings_1_audio, e)}}
                      >
                        <option key="0" value="0"></option>
                        {
                          model.audio_files.map((x,index)=>{
                            let name = x.audio_name + " "+ (x.is_group?" (Group)":"")
                            return <option key={index + 1} value={x.id}>{name}</option>
                          })
                        }
                      </select>
                      {this.getDropdownRequiredError(model.no_answer_greeting.type === "2", model.no_answer_greeting.alternate_1.audio_selected )}
                    </div>
                  </div>
                  <div className="custom-control">
                    <div className="greeting-option-radio">
                      <input id="radio3" name="radio" type="radio"  value="3"  
                      disabled={!model.audio_files_selection_enable}
                      checked={model.no_answer_greeting.type === "3"} 
                      onChange={(e)=>{ this.handleChanges(no_answer_greeting, e)}}/>
                    </div>
                    <div className="greeting-option">
                      <label>Greeting Name</label>
                      <input type="text" className="form-control" 
                        disabled={!(model.audio_files_selection_enable && model.no_answer_greeting.type === "3")}
                         value={model.no_answer_greeting.alternate_2.name} 
                        onChange={(e)=>{ this.handleChanges(alternate_no_answer_greetings_2_name, e)}}/>
                        {this.getTexboxRequiredError(model.no_answer_greeting.type == "3", model.no_answer_greeting.alternate_2.name)}
                      <label>Greeting Audio</label>
                      <select name="" id="" className="nums form-control" 
                        disabled={!(model.audio_files_selection_enable && model.no_answer_greeting.type === "3")}
                        value={model.no_answer_greeting.alternate_2.audio_selected} 
                        onChange={(e)=>{ this.handleChanges(alternate_no_answer_greetings_2_audio, e)}}
                      >
                        <option key="0" value="0"></option>
                        {
                          model.audio_files.map((x,index)=>{
                            let name = x.audio_name + " "+ (x.is_group?" (Group)":"")
                            return <option key={index + 1} value={x.id}>{name}</option>
                          })
                        }
                      </select>
                      {this.getDropdownRequiredError(model.no_answer_greeting.type === "3", model.no_answer_greeting.alternate_2.audio_selected )}
                    </div>
                  </div>
                  <div className="custom-control">
                    <div className="greeting-option-radio">
                      <input id="<radio3></radio3>" name="radio" type="radio" value="4" 
                      disabled={!model.audio_files_selection_enable}
                      checked={model.no_answer_greeting.type === "4"} 
                      onChange={(e)=>{ this.handleChanges(no_answer_greeting, e)}}/>
                    </div>
                    <div className="greeting-option">
                      <label>Greeting Name</label>
                      <input type="text" className="form-control"
                         disabled={!(model.audio_files_selection_enable && model.no_answer_greeting.type === "4")}
                         value={model.no_answer_greeting.alternate_3.name} 
                         onChange={(e)=>{ this.handleChanges(alternate_no_answer_greetings_3_name, e)}}/>
                      {this.getTexboxRequiredError(model.no_answer_greeting.type === "4", model.no_answer_greeting.alternate_3.name)}
                      <label>Greeting Audio</label>
                      <select name="" id="" className="nums form-control" 
                        disabled={!(model.audio_files_selection_enable && model.no_answer_greeting.type === "4")}
                        value={model.no_answer_greeting.alternate_3.audio_selected} 
                        onChange={(e)=>{ this.handleChanges(alternate_no_answer_greetings_3_audio, e)}}
                      >
                        <option key="0" value="0"></option>
                        {
                          model.audio_files.map((x,index)=>{
                            let name = x.audio_name + " "+ (x.is_group?" (Group)":"")
                            return <option key={index + 1} value={x.id}>{name}</option>
                          })
                        }
                      </select>
                      {this.getDropdownRequiredError(model.no_answer_greeting.type === "4", model.no_answer_greeting.alternate_3.audio_selected )}
                    </div>
                  </div>
                </div>
              </div>
              <hr />
              <div className="form-group">
                <label>Number of rings before greeting:</label>
                <select name="" id="" className="nums form-control"
                  value={model.number_of_rings_before_greeting} 
                  onChange={(e)=>{ this.handleChanges(number_of_rings_before_greeting, e)}}
                >
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                  <option value="7">7</option>
                  <option value="8">8</option>
                  <option value="9">9</option>
                  <option value="10">10</option>
                </select>
              </div>
              <div>
                <div className="form-group">
                  <label className="right switch">
                    <input type="checkbox"  
                      checked={model.auto_login} 
                      onChange={(e)=>{ this.handleChanges(auto_login_to_voice_portal_when_calling_from_my_phone, e)}}/>
                    <span className="slider round"></span>
                  </label>
                  Auto-login to Voice Portal when calling from my phone
                </div>
              </div>
            </div>
    );
  }

  getBusyPersonalGreeting=()=>{
    let model = this.props.Model;
    if(model.busy_greeting.type !== "1")
      return "";

    return (<div className="hidden-group" >
              <select name="" id="" className="nums form-control"
              value={model.busy_greeting.audio_selected} 
              onChange={(e)=>{ this.handleChanges(busy_greetings_personal_greetings, e)}}
              >
               <option key="0" value="0"></option>
                {
                  model.audio_files.map((x,index)=>{
                    let name = x.audio_name + " "+ (x.is_group?" (Group)":"")
                    return <option key={index + 1} value={x.id}>{name}</option>
                  })
                }
              </select>
              {this.getDropdownRequiredError(model.busy_greeting.type === "1", model.busy_greeting.audio_selected )}
            </div>)
  }

  noAnswerUnavailable=()=>{
    let model = this.props.Model;

    if(model.no_answer_greeting.type !== "1")
      return "";

    return (<div className="hidden-group" id="mg2">
    <select name="" id="" className="nums form-control"
    value={model.no_answer_greeting.audio_selected} 
    onChange={(e)=>{ this.handleChanges(no_answer_greetings_personal_greeting, e)}}
    >
      <option key="0" value="0"></option>
      {
        model.audio_files.map((x,index)=>{
          let name = x.audio_name + " "+ (x.is_group?" (Group)":"")
          return <option key={index + 1} value={x.id}>{name}</option>
        })
      }
    </select>
    {this.getDropdownRequiredError(model.no_answer_greeting.type === "1", model.no_answer_greeting.audio_selected )}
  </div>)
  }
  
  getTexboxRequiredError=(validate, value)=>{
    let requiredElem = (<div className="inline inline-fail text-left">Required!</div>);
    if(!validate) return "";

    if(!value){
      return requiredElem
    }

    if(value && !value.trim()){
      return requiredElem
    }
  }
    
  getDropdownRequiredError=(validate, value)=>{
    let requiredElem = (<div className="inline inline-fail text-left">Required!</div>);
    if(!validate) return "";

    if(value === "0"){
      return requiredElem;
    }
  }
};

export default ManageGreetingsModal;
