import React, { Component } from 'react';
import * as apiCalls from '../requests/api_calls';
import * as config from '../requests/config';
import * as settings from '../scripts/settings';
import { DataLoadingSpinner } from '../components/spinners';
import { Grid, GridColumn as Column, GridCell } from '@progress/kendo-react-grid';
import { orderBy, filterBy } from '@progress/kendo-data-query';

export default class DocumentRepository extends Component {
  constructor() {
    super();

    this.state = {
      loading: false,
      UserFiles: [],
      AdditionalFiles: [],
      FullListings: [],
      Sort: [{ field: "FileName", dir: "asc" }],
      filter: { logic: "and", filters: [] },
    }

    class DataLink extends GridCell {
      render() {
        //let test = this.props.dataItem;
        let { FileName, DocumentId, URL } = this.props.dataItem;
        return (
          <td>
            <div id="document-list" key={DocumentId}>
              <b>
                <a style={{ display: 'block', padding: '8px 0' }}
                  tabIndex="0"
                  ref='exportBtn'
                  target='_blank'
                  href={FileName}
                >
                  {URL}
                </a>
              </b>
            </div>
          </td>
        )
      }
    }
    this.FileDownloadLink = DataLink;
  }

  componentDidMount() {
    let { IsWholesale } = sessionStorage;
    if (IsWholesale === 'false') {
      this.props.history.push('/');
    } else {
      this.fetchFiles();
    }
  }

  fetchFiles = async () => {
    this.setState({ loading: true });
    let apiPath = `usr/GetDocuments`;
    let response = await apiCalls.fetchData(apiPath).then(data => data).catch(err => console.error(err));
    if (response)
    {
      let userFiles = response.filter(itm => itm.DocumentType !== 'User Guides');
      userFiles.map((doc, idx) => {
        doc.FileDescription = doc.FileDescription.replace(/&lt;/g,'<').replace(/&gt;/g,'>').replace(/&amp;/g,'&').replace(/&quot;/g, '"');
        doc.InsertedDate = new Date(doc.InsertedDate);
        doc.LastUpdatedDate = new Date(doc.LastUpdatedDate);
      });
      this.setState({
        UserFiles: response.filter(itm => itm.DocumentType === "User Guides"),
        AdditionalFiles: userFiles, //response.filter(itm => itm.DocumentType !== "User Guides"),
        FullListings: userFiles, //response.filter(itm => itm.DocumentType !== "User Guides"),
        loading: false
      });
    } else {
      this.setState({
        UserFiles: [],
        AdditionalFiles: [],
        FullListings: [],
        loading: false
      })
    }
  }

  sortChange = (event) => {
    this.setState({
      AdditionalFiles: orderBy(this.state.AdditionalFiles, event.sort),
      Sort: event.sort
    });
  }

  searchListings = (event) => {
    // only searching off the additional files grid based on requirements
    if (this.state.FullListings && this.state.FullListings.length > 0) {
      let searchText = event.target.value.trim();
      let fullDetails = this.state.FullListings;
      if (searchText !== '') {
        searchText = event.target.value;
        let files = filterBy(fullDetails, {
          logic: 'or',
          filters: [
            { field: 'FileName', operator: 'contains', value: searchText, ignoreCase: true },
            { field: 'FileDescription', operator: 'contains', value: searchText, ignoreCase: true },
          ]
        });
        this.delayedSearch(files);
      }
      else {
        this.delayedSearch(fullDetails);
      }
    }
  }

  delayedSearch = async (files) => {
    await this.setState({ loading: true });
    if (this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.setState({ AdditionalFiles: files, loading: false });
    }, settings.searchDelay);
  }

  additionalFilesGrid = () => {
    if (this.state.AdditionalFiles && this.state.AdditionalFiles.length > 0) {
      return (
        <div>
          <h4>Additional Files</h4>
          <Grid
            data={this.state.AdditionalFiles}
            sortable={{ allowUnsort: true, mode: 'single' }}
            sort={this.state.Sort}
            onSortChange={this.sortChange}
          >
            <Column field="URL" title="File Name" cell={this.FileDownloadLink} width="300px" />
            <Column field="FileDescription" title="File Description" width="500px" />
            <Column width='110px' field="FileSize" title="File Size (in KB)" />
            <Column title=' ' field='filler' sortable={false} />
          </Grid>
        </div>
      )
    } else {
      return;
    }
  }

  userFilesGrid = () => {
    let { UserFiles } = this.state;
    if (UserFiles && UserFiles.length > 0) {
      return (
        <div className="documents-container">
          <h4>User Guides</h4>
          {UserFiles.map((file, idx) => {

            return (
              <div id="document-list" key={idx}>
                <b>
                  <a style={{
                    display: 'inline-block',
                    padding: '8px 0'
                  }}
                    tabIndex="0"
                    ref='exportBtn'
                    target='_blank'
                    href={file.FileName}
                  >
                    {file.URL}
                  </a>
                </b>
              </div>
            )
          })}
        </div>
      )
    } else {
      return (
        <div className="documents-container">
          <h4>User Files</h4>
          <div>No User Guides are currently available</div>
        </div>
      )
    }
  }

  render() {
    return (
      <div className='documents-page'>
        <div className="container full-card">
          <div className="documents card">
            <div className="card-header">
              <h1>Documents</h1>
            </div>
            <div className="card-actions">
              <div className="search">
                <label htmlFor="documents_srch_tb" className="hidden">documents search</label>
                <input id="documents_srch_tb" type="text" autoComplete={"off"} className="form-control search-control" onChange={this.searchListings} />
                <svg width="12" height="12" viewBox="0 0 23 23"><path d="m347 238c0-36-12-66-37-91-25-25-55-37-91-37-35 0-65 12-90 37-25 25-38 55-38 91 0 35 13 65 38 90 25 25 55 38 90 38 36 0 66-13 91-38 25-25 37-55 37-90z m147 237c0 10-4 19-11 26-7 7-16 11-26 11-10 0-19-4-26-11l-98-98c-34 24-72 36-114 36-27 0-53-5-78-16-25-11-46-25-64-43-18-18-32-39-43-64-10-25-16-51-16-78 0-28 6-54 16-78 11-25 25-47 43-65 18-18 39-32 64-43 25-10 51-15 78-15 28 0 54 5 79 15 24 11 46 25 64 43 18 18 32 40 43 65 10 24 16 50 16 78 0 42-12 80-36 114l98 98c7 7 11 15 11 25z" transform="scale(0.046875 0.046875)"></path></svg>
              </div>
            </div>
            {!this.state.loading ? <div className="card-content">
              <div className="user-guides-container" style={{paddingBottom:'20px'}}>
                {this.userFilesGrid()}
              </div>
              <div className="additional-files-container" style={{marginBottom: '0px'}}>
                {this.additionalFilesGrid()}
              </div>
            </div> : <DataLoadingSpinner className='load spinner' />}
          </div>
        </div>
      </div>
    )
  }
}