import React, { Component } from 'react';
import { Button } from '@progress/kendo-buttons-react-wrapper';
import _ from 'lodash';
import { fetchData, post } from '../requests/api_calls';
import * as config from '../requests/config';
import * as helper from '../scripts/helper';
import { SavingMessageControlE911 } from './SavingMessageControlE911';

const BuildingValue= "BuildingValue",
      City= "City",
      FloorValue= "FloorValue",
      State= "State",
      Unit= "Unit",
      UnitValue= "UnitValue",
      ZipCode= "ZipCode",
      StreetName= "StreetName",
      StreetNumber  = "StreetNumber",
      HouseNumber= "HouseNumber",
      PostDirectional= "PostDirectional",
      PreDirectional= "PreDirectional",
      StreetType="StreetType",
      StreetSuffix= "StreetSuffix",
      Street1= "Street1",
      Street2= "Street2",
      PhoneNumber= "PhoneNumber";


export default class AddressFormE911 extends Component {
    initialState = (props) => {
        let originalAddress = props.address;
        let addressEntry = {
            AddressId: originalAddress.AddressId,
            CustomerProductId: originalAddress.CustomerProductId,
            StreetNumber: originalAddress.StreetNumber,
            PreDirectional: originalAddress.PreDirectional,
            StreetName: originalAddress.StreetName,
            StreetSuffix: originalAddress.StreetSuffix,
            City: originalAddress.City,
            State: originalAddress.State,
            ZipCode: originalAddress.ZipCode,
            Street1: originalAddress.Street1,
            Street2: originalAddress.Street2,
            Unit: originalAddress.Unit,
            UnitValue: originalAddress.UnitValue,
            FloorValue: originalAddress.FloorValue,
            BuildingValue: originalAddress.BuildingValue,
            IsActive: originalAddress.IsActive
        };
        return {
            parentList: props.parentList ? props.parentList : null,
            index: props.parentIndex ? props.parentIndex : null,
            originalAddress: props.address,
            addressEntry: addressEntry,
            validateInComponent: props.validateInComponent === true ? true : false,
            validateTN: props.validateInComponent === true && props.validateTN === true ? true : false,
            displayTopMessage: props.displayTopMessage === true ? true : false,
            TopMessage: props.TopMessage ? props.TopMessage: '',
            PhoneNumber: props.PhoneNumber,
            savingShowError: props.address.ValidationMessage === undefined || props.address.ValidationMessage === '' ? false : true,
            savingShowSuccess: false,
            savingLoading: false,
            savingErrorMessage: props.address.ValidationMessage === undefined || props.address.ValidationMessage === '' ? '' : props.address.ValidationMessage,
            CustomerId: props.CustomerId ? props.CustomerId : 0,
            ReadOnly: props.ReadOnly === undefined || !props.ReadOnly ? false : props.ReadOnly,
            apiPath: props.apiPath
        };
    }
    constructor(props) {
        super(props);
        this.state = this.initialState(props);
        this.saveInList = this.saveInList.bind(this);
        this.handleToggleEditList911 = this.handleToggleEditList911.bind(this);
        this.IsBlankAddress = this.IsBlankAddress.bind(this);
    }

    componentWillReceiveProps(props) {
        if (props.address != this.state.addressEntry) {
            let updatedEntry = props.address;
            this.setState({
                addressEntry: updatedEntry,
                savingErrorMessage: props.address.ValidationMessage
            });
        }
    }

    handleOnChange=(fieldName, event)=>{
        let state = {...this.state};
        let address = state.addressEntry;

        address[fieldName] = event.target.value;
        
        address.Street1 = helper.CreateFullStreet1(address);
        address.Street2 = helper.CreateFullStreet2(address);

        if (state.parentList) {
            let index = this.state.index;
            state.parentList[index] = address;
        }    
        state.addressEntry = address;
        this.setState(state);
    }   

    saveInList = async() => {
        //validate address
        if (this.state.validateInComponent) {
            this.setState({
                savingLoading: true
            });
            let addressObject = this.GetAddressObject(this.state.addressEntry, this.state.PhoneNumber);
            addressObject.Building = helper.ValueOfBuildingTag;
                            
            if (!this.IsBlankAddress(addressObject)) {            
                let response = await post(this.state.apiPath, 'POST', JSON.stringify(addressObject));
                this.props.UpdateEntry((response.ok === true), (response.ok === true ? JSON.parse(response.message).AddressId : 0), this.state.addressEntry, (response.ok === true ? JSON.parse(response.message).ErrorMessage : 'Address was not saved successfully. Please review your entry'));
                if (response.ok === true) {
                    this.setState({savingErrorMessage: '', savingShowSuccess: true, showError: false, savingLoading: false});
                } else {
                    this.setState({
                        savingShowSuccess: false,
                        savingShowError: true,
                        showError: true,
                        ErrorMessage: 'Address was not saved successfully. Please review your entry.',
                        savingLoading: false
                    });
                }
            } else {
                this.props.UpdateEntry(true, 0, this.state.addressEntry, '');
                this.setState({savingLoading: false, ErrorMessage: '', showError: false, savingShowSuccess: false, savingShowError: false});
            }
        } else {
            this.props.UpdateEntry(true, this.state.addressEntry);
        }
    }

    IsBlankAddress = (addressEntry) => {
        if (addressEntry && helper.CreateFullStreet1(addressEntry) === '' && helper.CreateFullStreet1(addressEntry) === '' && addressEntry.City === '' && addressEntry.State === '' && addressEntry.ZipCode === '')
            return true;
        else 
            return false;
    }

    GetAddressObject = (addressEntry, PhoneNumber) => {
        return {
            AddressId: addressEntry.AddressId,
            IsActive: false,
            Building: addressEntry.Building,
            StreetNumber: addressEntry.StreetNumber,
            Street2: helper.CreateFullStreet2(addressEntry),
            Street1: helper.CreateFullStreet1(addressEntry),
            StreetSuffix: addressEntry.StreetSuffix,
            PostDirectional: addressEntry.PostDirectional === undefined ? '' : addressEntry.PostDirectional,
            PreDirectional: addressEntry.PreDirectional === undefined ? '' : addressEntry.PreDirectional,
            StreetName: addressEntry.StreetName,
            ZipCode: addressEntry.ZipCode,
            State: addressEntry.State,
            City: addressEntry.City,
            BuildingValue: addressEntry.BuildingValue,
            FloorValue: addressEntry.FloorValue,
            Unit: addressEntry.Unit,
            UnitValue: addressEntry.UnitValue,
            PhoneNumber: PhoneNumber
        };
    }

    handleToggleEditList911 = () => {
        //this.props.ToggleEditList
        let originalAddress = this.stateoriginalAddress;
        this.setState({address: originalAddress});
        if (this.props.ToggleEditList) {
            this.props.ToggleEditList();
        }
    }

    ClearAddress = () => {
        if (this.state.ReadOnly === false)
        {
            let clearAddress =  {
                AddressId: 0,
                IsActive: false,
                Building: '',
                StreetNumber: '',
                Street2: '',
                Street1: '',
                StreetSuffix: '',
                PostDirectional: '',
                PreDirectional: '',
                StreetName: '',
                ZipCode: '',
                State: '',
                City: '',
                BuildingValue: '',
                FloorValue: '',
                Unit: '',
                UnitValue: '',
                PhoneNumber: this.state.PhoneNumber
            };
            this.setState({addressEntry: clearAddress});
        }
    }

    ResetToDefaultAddress = () => {
        let state = this.state;
        //this.setState({addressEntry: state.originalAddress });
    }

    render() {
        let e911Address = this.state.addressEntry;
        let saveElement = "";
        let closeElement = "";
        if (e911Address === null || e911Address === undefined)
            return null;
        
        if (this.state.ReadOnly === false)
            saveElement =  (<Button key="2" className="btn" click={() => {this.saveInList()}}>Save</Button>);        

        closeElement = (<Button className="btn" click={(e)=>this.handleToggleEditList911()}>Close</Button>);
       
        return (
            <tr>
                <td colSpan="5">
                    <div className="table-edit">
                        <div className="form-group">
                            <p style={{display: this.state.displayTopMessage === true ? 'inline-block': 'none'}} className="alert alert-danger">{this.state.TopMessage}</p>
                            <div className="reset" style={{display: this.state.ReadOnly === false ? 'inline-block' : 'none'}}>
                                <a onClick={(e) => { this.ClearAddress()}} className="no-btn">Reset</a>
                                {/* <a className="no-btn">Reset to Primary Address</a> */}
                            </div>
        
                            <div className="halves">
                                <div className="half">
                                    <div className="form-group">
                                        <label>Street Number</label>
                                        <input type="type" className="form-control" disabled={this.state.ReadOnly === true ? 'disabled' : ''}
                                        value={e911Address.StreetNumber?e911Address.StreetNumber:""}
                                        onChange={x=>{ this.handleOnChange(StreetNumber, x) }} />
                                    </div>
                                </div>
                                <div className="half">
                                    <div className="form-group">
                                        <label>Street Prefix</label>
                                        <select className="form-control" disabled={this.state.ReadOnly === true ? 'disabled' : ''}
                                        value={e911Address.PreDirectional}
                                        onChange={x=>{ this.handleOnChange(PreDirectional, x) }}
                                        >
                                            <option value=""></option>
                                            <option value="N">N</option>
                                            <option value="S">S</option>
                                            <option value="E">E</option>
                                            <option value="W">W</option>
                                            <option value="NE">NE</option>
                                            <option value="NW">NW</option>
                                            <option value="SE">SE</option>
                                            <option value="SW">SW</option>
                                            <option value="SW">SW</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="halves">
                                <div className="half">
                                    <div className="form-group">
                                        <label>Street Name</label>
                                        <input type="type" className="form-control" disabled={this.state.ReadOnly === true ? 'disabled' : ''}
                                        value={e911Address.StreetName?e911Address.StreetName:""}
                                        onChange={x=>{ this.handleOnChange(StreetName, x) }}/>
                                    </div>
                                </div>
                                <div className="half">
                                {/* <div className="form-group">
                                    <label> Street Type (Dr,Blvd,St)</label>
                                    <input type="type" className="form-control" 
                                    value={e911Address.StreetName?e911Address.StreetName:""}
                                    onChange={x=>{ this.handleOnChange(indexInArray, StreetName, type, x) }}/>
                                </div> */}
                                </div>
                            </div>
                            <div className="halves">
                                <div className="half">
                                    <div className="form-group">
                                        <label>Street Type</label>
                                        <select className="form-control" disabled={this.state.ReadOnly === true ? 'disabled' : ''}
                                            value={e911Address.StreetSuffix ? e911Address.StreetSuffix : ""} 
                                            onChange={(x) => { this.handleOnChange(StreetSuffix , x); } }
                                        >
                                            <option value=""></option>
                                            {
                                                helper.streetTypes.map(x=> <option key={x} value={x}>{x}</option>)
                                            }
                                        </select>
                                    </div>
                                </div>
                                <div className="half"></div>
                            </div>
                            <hr />
                            <div className="halves">
                                <div className="half">
                                    <div className="form-group">
                                        <label>Unit Type</label>
                                        <select className="form-control" disabled={this.state.ReadOnly === true ? 'disabled' : ''} 
                                            value={e911Address.Unit}
                                            onChange={x=>{ this.handleOnChange(Unit, x) }}
                                        >
                                            <option value=""></option>
                                            <option value="APT">Apartment</option>
                                            <option value="BSMT">Basement</option>
                                            <option value="DEPT">Department</option>
                                            <option value="FL">Floor</option>
                                            <option value="FRNT">Front</option>
                                            <option value="HNGR">Hangar</option>
                                            <option value="KEY">Key</option>
                                            <option value="LBBY">Lobby</option>
                                            <option value="LOT">Lot</option>
                                            <option value="LOWR">Lower</option>
                                            <option value="OFC">Office</option>
                                            <option value="PH">Penthouse</option>
                                            <option value="PIER">Pier</option>
                                            <option value="REAR">Rear</option>
                                            <option value="RM">Room</option>
                                            <option value="SIDE">Side</option>
                                            <option value="SLIP">Slip</option>
                                            <option value="SPC">Space</option>
                                            <option value="STOP">Stop</option>
                                            <option value="STE">Suite</option>
                                            <option value="TRLR">Trailer</option>
                                            <option value="UNIT">Unit</option>
                                            <option value="UPPR">Upper</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="half">
                                    <div className="form-group">
                                        <label>Unit</label>
                                        <input type="type" className="form-control" disabled={this.state.ReadOnly === true ? 'disabled' : ''} 
                                            value={e911Address.UnitValue} 
                                            onChange={x=>{ this.handleOnChange(UnitValue, x) }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="halves">
                                <div className="half">
                                    <div className="form-group">
                                        <label>Floor</label>
                                        <input type="type" className="form-control" disabled={this.state.ReadOnly === true ? 'disabled' : ''} 
                                            value={e911Address.FloorValue}  
                                            onChange={x=>{ this.handleOnChange(FloorValue, x) }}
                                        />
                                    </div>
                                </div>
                                <div className="half">
                                    <div className="form-group">
                                        <label>Building</label>
                                        <input type="type" className="form-control" disabled={this.state.ReadOnly === true ? 'disabled' : ''}
                                            value={e911Address.BuildingValue}  
                                            onChange={x=>{ this.handleOnChange(BuildingValue, x) }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="halves">
                                <div className="half">
                                    <div className="form-group">
                                        <label>City</label>
                                        <input type="type" className="form-control" disabled={this.state.ReadOnly === true ? 'disabled' : ''} 
                                            value={e911Address.City} 
                                            onChange={x=>{ this.handleOnChange(City, x) }}
                                        />
                                    </div>
                                </div>
                                <div className="half">
                                    <div className="form-group">
                                        <label>State</label>
                                        <select className="form-control" value={e911Address.State}
                                            onChange={x=>{ this.handleOnChange(State, x) }}
                                        >
                                            <option value=""></option>
                                            <option value="AL">Alabama</option>
                                            <option value="AK">Alaska</option>
                                            <option value="AZ">Arizona</option>
                                            <option value="AR">Arkansas</option>
                                            <option value="CA">California</option>
                                            <option value="CO">Colorado</option>
                                            <option value="CT">Connecticut</option>
                                            <option value="DC">D.C.</option>
                                            <option value="DE">Delaware</option>
                                            <option value="FL">Florida</option>
                                            <option value="GA">Georgia</option>
                                            <option value="HI">Hawaii</option>
                                            <option value="ID">Idaho</option>
                                            <option value="IL">Illinois</option>
                                            <option value="IN">Indiana</option>
                                            <option value="IA">Iowa</option>
                                            <option value="KS">Kansas</option>
                                            <option value="KY">Kentucky</option>
                                            <option value="LA">Louisiana</option>
                                            <option value="ME">Maine</option>
                                            <option value="MD">Maryland</option>
                                            <option value="MA">Massachusetts</option>
                                            <option value="MI">Michigan</option>
                                            <option value="MN">Minnesota</option>
                                            <option value="MS">Mississippi</option>
                                            <option value="MO">Missouri</option>
                                            <option value="MT">Montana</option>
                                            <option value="NE">Nebraska</option>
                                            <option value="NV">Nevada</option>
                                            <option value="NH">New Hampshire</option>
                                            <option value="NJ">New Jersey</option>
                                            <option value="NM">New Mexico</option>
                                            <option value="NY">New York</option>
                                            <option value="NC">North Carolina</option>
                                            <option value="ND">North Dakota</option>
                                            <option value="Ohio">Ohio</option>
                                            <option value="OK">Oklahoma</option>
                                            <option value="OR">Oregon</option>
                                            <option value="PA">Pennsylvania</option>
                                            <option value="SC">South Carolina</option>
                                            <option value="SD">South Dakota</option>
                                            <option value="TN">Tennessee</option>
                                            <option value="TX">Texas</option>
                                            <option value="UT">Utah</option>
                                            <option value="VT">Vermont</option>
                                            <option value="VA">Virginia</option>
                                            <option value="WA">Washington</option>
                                            <option value="WV">West Virginia</option>
                                            <option value="WI">Wisconsin</option>
                                            <option value="WY">Wyoming</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="halves">
                            <div className="half">
                                <div className="form-group">
                                    <label>Zip</label>
                                    <input type="type" className="form-control" disabled={this.state.ReadOnly === true ? 'disabled' : ''} 
                                        value={e911Address.ZipCode} 
                                        onChange={x=>{ this.handleOnChange(ZipCode, x) }}
                                    />
                                </div>
                            </div>
                            <div className="half"></div>
                        </div>
                        </div>
                        <hr />
                        <div className="btns">
                            {saveElement}
                            {closeElement}
                        </div>
                        <SavingMessageControlE911 
                            showSuccess={this.state.savingShowSuccess}
                            showLoading={this.state.savingLoading}
                            showError={this.state.savingShowError}
                            SuccessMessage="Success"
                            SuccessTransitionClass="vm-setting-success"
                            ErrorMessage={this.state.savingErrorMessage}
                        />
                    </div>
                </td>
            </tr>
        );
    }
};