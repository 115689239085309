import React from 'react';

import AnnouncementRepositoryModal from './modals/announcementRepositoryModal';
import AcceptanceModal from './modals/anywhereModal.js';
import CallBlockModal from './modals/callBlockModal';
import CallNotifyModal from './modals/callNotifyModal';
import CallTransferModal from './modals/callTransferModal';
import CollaborateBridgeModal from './modals/collaborateBridgeModal';
import ConferenceListModal from './modals/conferenceListModal';
import DirectCallModal from './modals/directCallModal';
import HotelingGuestModal from './modals/hotelingGuestModal';
import HotelingHostModal from './modals/hotelingHostModal';
import PriorityAlertModal from './modals/priorityAlertModal';
import PrivacyModal from './modals/privacyModal';
import ReceptionistDashboardModal from './modals/receptionistDashboardModal';
import SelectiveCallAcceptanceModal from './modals/selectiveCallAcceptanceModal';
import SpeedDialModal from './modals/speedDialModal';
import CallForwardingModal from './modals/callForwardingModal';
import CallForwardingSelectiveModal from './modals/callForwardingSelectiveModal';
import FindMeSimultaneousModal from './modals/findMeSimultaneousModal';
import FindMeSequentialModal from './modals/findMeSequentialModal';
import PushToTalkModal from './modals/pushToTalkModal';
import ContactCenterModal from './modals/contactCenterModal';
import CustomRingbackModal from './modals/customRingbackModal';
import RemoteOfficeModal from './modals/remoteOfficeModal';
import ClientAppModal from './modals/clientAppModal';
import VoicemailToTextModal from './modals/voicemailToTextModal';
import EnterpriseDirectoryModal from './modals/enterpriseDirectoryModal';
import ScheduleModal from './modals/scheduleModal';
import DistributionListModal from './modals/distributionListModal';
import VoicemailSettingsRow from './voicemailSettingsRow';
import VirtualNumberModal from './modals/virtualNumberModal';
import PreAlertModal from './modals/preAlertModal';
import ManageGreetingsRow from './manageGreetingsRow';
import PinResetRow from './pinResetRow';

const EnterpriseSettings = () => (
  	<div className="container full-card">
		<div className="enterprise-settings card">
			<h1>Service Settings</h1>
			<div className="settings-row">
				<AcceptanceModal />
				<AnnouncementRepositoryModal />
		        <CallBlockModal />
		        <CallForwardingModal />
		        <CallForwardingSelectiveModal />
		        <CallNotifyModal />
		        <CallTransferModal />
		        <ClientAppModal />
		        <CollaborateBridgeModal />
		        <ConferenceListModal />
		        <ContactCenterModal />
		        <CustomRingbackModal />
		        <DirectCallModal />
		        <DistributionListModal />
		        <EnterpriseDirectoryModal />
		        <FindMeSimultaneousModal />
		        <FindMeSequentialModal />
		        <HotelingGuestModal />
		        <HotelingHostModal />
		       	<ManageGreetingsRow />
				<PreAlertModal />
		        <PriorityAlertModal />
		        <PrivacyModal />
		        <ReceptionistDashboardModal />
		        <RemoteOfficeModal />
				<ScheduleModal type="BUSINESS_HOURS"/>
				<ScheduleModal type="HOLIDAY_SCHEDULE"/>
		        <SelectiveCallAcceptanceModal />
		        <SpeedDialModal />
		        <PushToTalkModal />
		       	<VoicemailSettingsRow />
		        <PinResetRow />
				<VirtualNumberModal />
		        <VoicemailToTextModal />
			</div>
		</div>
	</div>
)

export default EnterpriseSettings;