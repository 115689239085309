import React from 'react';
import { GridCell } from '@progress/kendo-react-grid';
import { withRouter } from 'react-router-dom';
import { userHasNoServices } from './../scripts/helper';
import * as settings from './../scripts/settings';
import EllipsisWithTooltip from 'react-ellipsis-with-tooltip';

class ServiceSelector extends GridCell {

	 constructor() {
    super();
    this.setServiceAndRedirect = this.setServiceAndRedirect.bind(this);
   }

  setServiceAndRedirect = (service) => {
    settings.selectService(service.UserId, service.ServiceNumber, service.PBXUserID, service.Extension);
    this.props.history.push('/dashboard');
    //this.props.history.push('/profile/' + service.ServiceNumber)
  }

  serviceNumberValue = (service) => {
    if (userHasNoServices(service)) {
      return service.Username || service.PackageName;
    } else {
      return service.ServiceNumber;
    }
  }

  render() {
    let service = this.props.dataItem;
    var fieldValue = ''
    if (this.props.field === 'ServiceNumber') {
    	fieldValue = this.serviceNumberValue(service);
    } else if (this.props.field === 'Extension') {
	    fieldValue = service.Extension;
    } else {
    	var error = 'field name not recognized';
    	console.log(error);
    	// throw error;
    }
    if (service.ServiceId && service.ServiceId !== 0 && service.UserId && service.HasProfile) {
      return (
        <td>
          {/* store UserId of selected service in redux store */}
          <EllipsisWithTooltip placement="top">
            <a tabIndex="0" className='no-btn' key={service.ServiceId} onClick={(e) => this.setServiceAndRedirect(service)} >
              {fieldValue}
            </a>
          </EllipsisWithTooltip>
        </td>
      )
    } else return <td>{fieldValue}</td>
  }
}

export default withRouter(ServiceSelector);