import * as d3 from "d3";

export const setAxis = (options) => {
  let { svg, data1, data2, data, type, height, width, margin, x, y, id, summary, deviceName, node, color1, color2, dateRange, axisLabel } = options;
  // Create x-Axis
  const xAxis = d3
    .axisBottom(x)
    .tickFormat(d3.timeFormat('%b %d'))
  // Create y-Axis
  const yAxis = d3
    .axisLeft(y)
    .ticks(5)
  // Append x-Axis
  svg
    .append('g')
    .attr('class', 'x-axis')
    .attr('transform', `translate(0, ${height-30})`)
    .call(xAxis)
    .selectAll("text")
    .attr("transform", "rotate(-45)")
    .attr("dx", "-.8em")
    .attr("dy", ".15em")
    .style("text-anchor", "end");
  // Append y-Axis
  svg
    .append('g')
    .attr('class', 'y-axis')
    .call(yAxis);
  // Add y-axis label
  svg
    .append("text")
    .attr("class", "y-axis-label")
    .attr("text-anchor", "middle")
    .attr("x", -height / 2)
    .attr("y", -margin.left / 1.2)
    .attr("transform", "rotate(-90)")
    .text(axisLabel)
    .style("fill", "black")
    .style("font-weight", "bold");
  // Add vertical gridlines
  svg
    .selectAll('.vertical-grid-line')
    .data(x.ticks())
    .enter()
    .append('line')
    .attr('class', 'vertical-grid-line')
    .attr('x1', d => x(d))
    .attr('x2', d => x(d))
    .attr('y1', 0)
    .attr('y2', height)
    .attr('stroke', '#ddd')
    .attr('stroke-width', 1)
    .attr('stroke-dasharray', '5 5');
  // Add horizontal gridlines
  svg
    .selectAll('.horizontal-grid-line')
    .data(y.ticks())
    .enter()
    .append('line')
    .attr('class', 'horizontal-grid-line')
    .attr('x1', 0)
    .attr('x2', width)
    .attr('y1', d => y(d))
    .attr('y2', d => y(d))
    .attr('stroke', '#ddd')
    .attr('stroke-width', 1)
    .attr('stroke-dasharray', '5 5');
};