import React, { Component } from 'react';
import { DataLoadingSpinner } from '../../components/spinners';
import * as apiCalls from '../../requests/api_calls';
import * as config from './../../requests/config';
import * as helper from '../../scripts/helper';
import * as macdHelper from './components/macd_helper';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { orderBy } from '@progress/kendo-data-query';

class MacdConfirm extends Component {

  initialState(props) {
    let orderId = 0;
    if (props.match && props.match.params) {
      if (props.match.params.OrderId)
        orderId = props.match.params.OrderId;
    }
    
    return {
      OrderId: orderId,
      Listings: [],
      MPOLocationId: 0,
      sort: [{field: "PartDescription", dir: "asc"}],
      gridHeight: (window.innerHeight - 300),
      loading: true

    }
  }

  constructor(props) {
    super(props);
    this.state = this.initialState(props);


    this.fetchOrderDetails = this.fetchOrderDetails.bind(this);
    this.sortChange = this.sortChange.bind(this);
    this.LoadLocationsForAccount = this.LoadLocationsForAccount.bind(this);
  };

  componentDidMount() {
    let obj = sessionStorage.getItem('MacdPerms');
    if (obj.indexOf(1) < 0) {
      this.props.history.push(helper.homePageUrl());
      return;
    }
    this.setState({loading: true});
    this.fetchOrderDetails();
  }

  fetchOrderDetails = async() => {    
    let ListingArr = [];
    let locationId = 0;
    //try 
    //{
      let [OrderDetails, LocationDetails, LineItems, DetailItems] = await macdHelper.RetrieveOrderDetails(this.state.OrderId);
      locationId = LocationDetails[0].LocationId;      
      let [LocationListDetails, MPOLocationDetails] = await this.LoadLocationData(this.state.OrderId, locationId);
      let selectedLocation = OrderDetails.MomentumAccountNumber;
      let LocationFromList = LocationListDetails.filter(resp => resp.LocationAccountNumber === selectedLocation);
      let LocationName = LocationFromList[0].LocationName;
      let ServiceDate = MPOLocationDetails.RequestDueDate ? MPOLocationDetails.RequestDueDate : '';
      let svcAddress = MPOLocationDetails.ServiceAddress.AddressLine1 + ' ' + MPOLocationDetails.ServiceAddress.AddressLine2
                      + MPOLocationDetails.ServiceAddress.City + ', ' + MPOLocationDetails.ServiceAddress.AdrState + ' ' + MPOLocationDetails.ServiceAddress.ZipCode;
      
      DetailItems.map((DetailItem, idx) => {
        let LineItem = LineItems.filter(resp => resp.LineItemId === DetailItem.LineItemId)[0];
        let PartNumber = LineItem.PartNumber;
        let PartDescription = LineItem.PartDescription;

        let Listing = {
          DetailId: DetailItem.DetailId,
          LineItemId: LineItem.LineItemId,
          PartNum: PartNumber,
          PartDescription: PartDescription,
          MRCAdjustedPrice: LineItem.MRCAdjustedPrice,
          NRCAdjustedPrice: LineItem.NRCAdjustedPrice,
          ServiceAddress: svcAddress,
          Location: LocationName,
          Extension: DetailItem.Extension,
          DueDate: new Date(OrderDetails.ModifiedDate),
          ServiceDate: new Date(ServiceDate),
          ServiceNumber: DetailItem.ServiceNumber,
          LineEmail: DetailItem.LineEmail,
          Filler: ' '
        }
        ListingArr.push(Listing);
      });
    //}
    //catch (ex) {
      //console.log('error');
    //}

    this.setState({MPOLocationId: locationId, Listings: this.sortOrderDetails(this.state.sort, ListingArr), loading: false});
  }

  LoadLocationData = async(OrderId, LocationId) => {
    let requests = [
      macdHelper.fetchLocations(),      
      macdHelper.fetchMPOLocation(OrderId, LocationId)
    ];
    return await Promise.all(requests).then(dataArray => dataArray);    
  }
  LoadLocationsForAccount = async(OrderId, LocationId) => {
    let apiPath = macdHelper.apiGetMPOLocation(OrderId, LocationId);
    return apiCalls.fetchData(apiPath).then(data => data);    
  }
  fetchPricebookDetails = async(selectedLocation) => {
    let apiPath = macdHelper.apiPricebookPathWithParams(selectedLocation);
    return apiCalls.fetchData(apiPath).then(data => data);
  }


  sortChange(event) {
      this.setState({
        Listings: this.sortOrderDetails(event.sort),
        sort: event.sort
      });
    }

  sortOrderDetails(sort, OrderDetails = null) {
    if (OrderDetails == null) OrderDetails = this.state.Listings.slice();
    let sortedSet = orderBy(OrderDetails, sort);
    if (sort.length) {
      return helper.blanksAfterZ(sortedSet, sort);
    } else {
      return sortedSet;
    }
  }

  gridContent = () => {
    return (
        <Grid 
            style={{ height: this.state.gridHeight + 'px'}}
            data={this.state.Listings}
            sortable={{allowUnsort: true, mode: 'single'}}
            sort={this.state.sort} 
            onSortChange={this.sortChange}
        > 
          <Column width="125px" field='PartNum' title='Part Num' />   
          <Column width="200px" field="PartDescription" title="Product" />
          <Column width="150px" field='MRCAdjustedPrice' format="{0:c2}" title='Monthly' />
          <Column width="150px" field='NRCAdjustedPrice' format="{0:c2}" title='One-Time' />
          <Column width="100px" field='DueDate' format="{0:MM/dd/yyyy}" title='Due Date' />
          {/* <Column width='130px' field='ServiceId'  title='Service ID' />         */}
          {/* <Column field='ServiceNumber' title='Service TN' /> */}
          <Column width="100px" field='Extension' title='Extension' />
          <Column width="175px" field="LineEmail" title='Line Email' />
          <Column width="150px" field='Location' title='Location' />
          <Column width="175px" field='ServiceAddress'  title='Service Address' />
          <Column width="105px" field='ServiceDate' format="{0:MM/dd/yyyy}" title='Service Date' />
          <Column field='Filler' title =' ' />
        </Grid>
      
    )
  }
  exportOrderDetailsBtn = () => {
    if (this.state.allDevices.length > 0) {
      return (
        <div id="div-calls-csv" className='pull-center'>
          <form ref='exportAddServicesOrder' target="iFrameSave" action={config.baseApiUrl + 'admin/GetAddServicesCSV'} method="POST">
            <input type="hidden" name="access_token" value={config.storedAccessToken()} className='hidden'></input>
            <input type="hidden" name="x_policy" value={config.storedXPolicy()} className='hidden'></input>
          </form>
          <iframe name="iFrameSave" height="0" width="0" className='hidden pull-right' title='orderCSV'></iframe>
          <a tabIndex="0" className='no-btn' ref='exportBtn' onClick={(e) => this.exportOrder()} >Print Confirmation</a>
        </div>
      )
    } else null;
  }

  exportDevices = () => {
    this.refs.exportDevices.submit();
    this.refs.exportBtn.text = 'Downloading...';
    setTimeout(() => {
      this.refs.exportBtn.text = 'Print Confirmation';
    }, 2000);
  }

  render() {
    return (
      <div className="container full-card print">
        <div className="macd card print">
          <div className="card-header print">
            <h2>Services Confirmation - Order ID: {this.state.OrderId}</h2>
          </div>
          <div style={{visibility: this.state.loading ? 'visible' : 'hidden'}}>
            <DataLoadingSpinner className='load spinner' />
          </div>
          <div className="print" style={{visibility: this.state.loading ? 'hidden' : 'visible'}}>
            {this.gridContent()}
          </div>
          <div className="btns noPrint" style={{visibility: this.state.loading ? 'hidden' : 'visible'}}>
            {/* {this.exportOrderDetailsBtn()} */}
            <a className="no-btn" onClick={() => { window.print(); }}>Print Confirmation</a>
          </div>
          
        </div>
      </div>
    )
  }
}

export default MacdConfirm;