import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { Button } from '@progress/kendo-buttons-react-wrapper';
import ManageGreetingsModal from './modals/manageGreetingsModal';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'; // ES6
import { DataLoadingSpinner } from './../components/spinners';
import { fetchData, post } from './../requests/api_calls';
import format from 'string-format';
import * as config from './../requests/config';

class ManageGreetingsButton extends Component {

	constructor(props) {
		super(props);

    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);

    this.state = this.getDefaultModel();
  }

  getDefaultModel=()=>{
    return { 
      manage_available: false,
      showModal: false,
      loading: false, 
      loading_error: "",
      saving_enable: false,
      saving_loading: false,
      saving_show_success: false,
      saving_error_message: "",
      busy_greeting: {
        type: 0, //system or personal,
        audio_selected: "0",
        error_message: ""
      }, 
      no_answer_greeting: {
        type: 0, //system or personal, alternative 1, alternative 2, alternative 3
        //Only audio_selected for personal
        audio_selected: "0",
        error_message: "",
        alternate_1:
        {
          name: "",
          audio_selected: "0",
          error_message: ""
        },
        alternate_2:
        {
          name: "",
          audio_selected: "0",
          error_message: ""
        },
        alternate_3:
        {
          name: "",
          audio_selected: "0",
          error_message: ""
        }
      }, 
      audio_files:[
        
      ],
      audio_files_selection_enable: false,
      number_of_rings_before_greeting: 2, //number
      auto_login: false
    };
  }
  getAudioById=(audio_files, id)=>{
    let filtered = audio_files.filter(x=>x.id.toString() === id.toString());
    // console.log(ids);
    // console.log(audio_files);
    // console.log(id.toString());
    if(filtered.length > 0)
      return filtered[0];

    return null;
  }

  IsModelValid=()=>{
    let state = {...this.state};
    let isValid = true;
    state.saving_error_message = "";
    if(state.busy_greeting.type === "1" && state.busy_greeting.audio_selected === "0" )
      isValid = false;

    if(state.no_answer_greeting.type === "1" && state.no_answer_greeting.audio_selected === "0" )
      isValid = false;

    if(state.no_answer_greeting.type === "2" && !state.no_answer_greeting.alternate_1.name)
      isValid = false;
    if(state.no_answer_greeting.type === "3" && !state.no_answer_greeting.alternate_2.name)
      isValid = false;

    if(state.no_answer_greeting.type === "4" && !state.no_answer_greeting.alternate_3.name)
      isValid = false;
 
    if(state.no_answer_greeting.type === "2" && state.no_answer_greeting.alternate_1.audio_selected === "0")
      isValid = false;

    if(state.no_answer_greeting.type === "3" && state.no_answer_greeting.alternate_2.audio_selected === "0")
      isValid = false;

    if(state.no_answer_greeting.type === "4" && state.no_answer_greeting.alternate_3.audio_selected === "0")
      isValid = false;
    
    return isValid;
  }

  handleSave=()=> {
    let state = {...this.state};
    if(!this.IsModelValid()) {
      state.saving_error_message = "Please check field with errors";
      this.setState(state);
      return;
    }; 
    
    state.saving_error_message = "";
    state.saving_loading = true;
    state.saving_show_success = false;
    state.saving_enable = false;


    let postData = {};
    postData.PBXUserID = this.props.PBXUserID;
    postData.ServiceNumber = this.props.ServiceNumber;

    postData.BusyAnnouncementSelection = state.busy_greeting.type;

    if(parseInt(state.busy_greeting.type) === 0){
      postData.BusyPersonalAudioFile = null;
    }
    else if(parseInt(state.busy_greeting.type) === 1){
      let selectedAudio = this.getAudioById(state.audio_files, state.busy_greeting.audio_selected);
      if(selectedAudio){
        postData.BusyPersonalAudioFile ={
          AudioName: selectedAudio.audio_name,
          FileType: selectedAudio.file_type,
          IsGroup: selectedAudio.is_group
        }
      }
      
    }

    postData.noAnswerAnnouncementSelection = state.no_answer_greeting.type;
    if(state.no_answer_greeting.type === "0"){
      postData.NoAnswerPersonalAudioFile = null;
    }
    else if(state.no_answer_greeting.type === "1"){
      let selectedAudio = this.getAudioById(state.audio_files, state.no_answer_greeting.audio_selected);
      if(selectedAudio){
        postData.NoAnswerPersonalAudioFile = {
          AudioName: selectedAudio.audio_name,
          FileType: selectedAudio.file_type,
          IsGroup: selectedAudio.is_group
        }
      }
      
    }else if(state.no_answer_greeting.type === "2"){
      let selectedAudio = this.getAudioById(state.audio_files, state.no_answer_greeting.alternate_1.audio_selected);
      if(selectedAudio){
        postData.GreetingName =  state.no_answer_greeting.alternate_1.name,
        postData.NoAnswerPersonalAudioFile = {
            AudioName: selectedAudio.audio_name,
            FileType: selectedAudio.file_type,
            IsGroup: selectedAudio.is_group
        }
      }
    }
    else if(state.no_answer_greeting.type === "3"){
      let selectedAudio = this.getAudioById(state.audio_files, state.no_answer_greeting.alternate_2.audio_selected);
      if(selectedAudio){
        postData.GreetingName =  state.no_answer_greeting.alternate_2.name,
        postData.NoAnswerPersonalAudioFile = {
            AudioName: selectedAudio.audio_name,
            FileType: selectedAudio.file_type,
            IsGroup: selectedAudio.is_group
        }
      }
    }
    else if(state.no_answer_greeting.type === "4"){
      let selectedAudio = this.getAudioById(state.audio_files, state.no_answer_greeting.alternate_3.audio_selected);
      if(selectedAudio){
        postData.GreetingName =  state.no_answer_greeting.alternate_3.name,
        postData.NoAnswerPersonalAudioFile = {
            AudioName: selectedAudio.audio_name,
            FileType: selectedAudio.file_type,
            IsGroup: selectedAudio.is_group
        }
      }
    }
    postData.NoAnswerNumberOfRings = state.number_of_rings_before_greeting;
    postData.VoicePortalAutoLogin = state.auto_login;
    let body = JSON.stringify(postData);
		let headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + config.storedAccessToken(),
      'X-Policy': config.storedXPolicy()
    };

    post("usr/VMManageGreeting","POST", body, headers)
		.then(response =>{
      let state = {...this.state};
      state.saving_enable = true;
			if(response.ok){
        state.saving_show_success = true;
				state.saving_loading = false;
        state.saving_error_message = "";
			}
			else{
				state.saving_loading = false;
				state.saving_error_message = "Failed Save";
      }
      this.setState(state);
		})
		.catch((message)=>{
      let state = {...this.state};
      state.saving_loading = false;
      state.saving_enable = true;
      state.saving_error_message = "Failed Save";
      this.setState(state);
    });
    
    this.setState(state);
  }

  handleChangeState=(model)=>{
    this.setState(model);
  }

  handleClose() {
    let state = {...this.state};
    state.showModal = false;
    state.loading= false;
    state.loading_error= "";
    state.saving_enable= true;
    state.saving_loading= false;
    state.saving_show_success= false;
    state.saving_error_message= "";
    this.setState(state);
  }

  handleShow() {
    let state = this.getDefaultModel();
    state.manage_available = true;
    state.showModal = true;
    state.loading = true;
    state.saving_show_success=false;
    state.saving_error_message = "";
    this.setState(state);

    let apiPath = format('usr/VMManageGreeting?ServiceNumber={ServiceNumber}&PBXUserID={PBXUserID}',this.props);
    fetchData(apiPath).then((data) => {
      if(data){
        let model = this.getModel(data);
       this.setState(model);
      }
      else{
        let state = {...this.state};
        state.saving_enable = false;
        state.loading= false;
        state.loading_error = "Loading Fail";
        state.manage_available = false;
        this.setState(state);
      }
    }).catch((message)=>{
      let state = {...this.state};
      state.loading = false;
      state.saving_enable = false;
      state.loading_error = message;
      this.setState(state);
    });
  }

  getAudioFileID=(audio_files, audioFile)=>{
    let result = audio_files.
          filter(x=> 
            x.audio_name === audioFile.AudioName && 
            x.file_type === audioFile.FileType && 
            x.is_group === audioFile.IsGroup);
    if(result.length > 0){
      return result[0].id;
    }

    console.log("failedto find")
    return null;
  }

  getModel=(data)=>{
    let state = {...this.state};
    state.loading = false;
    state.saving_enable = true;
    state.manage_available = true;
    state.busy_greeting.type = data.BusyAnnouncementSelection.toString();
    state.audio_files = data.AudioFileList.map((x,index)=> {
      return  {
        id: (index + 1).toString(),
        audio_name: x.AudioName, 
        file_type: x.FileType, 
        is_group:x.IsGroup
      }
    });

    state.audio_files_selection_enable = state.audio_files.length > 0 ;
    if(data.BusyAnnouncementSelection===1 && state.audio_files_selection_enable){
      if(data.BusyPersonalAudioFile){
        let audioFile = data.BusyPersonalAudioFile;
        state.busy_greeting.audio_selected = this.getAudioFileID(state.audio_files, audioFile)
      }
    }

    state.no_answer_greeting.type = data.NoAnswerAnnouncementSelection.toString();
    if(data.NoAnswerAnnouncementSelection===1 && state.audio_files_selection_enable){
      if(data.NoAnswerPersonalAudioFile && data.NoAnswerPersonalAudioFile){
        let audioFile = data.NoAnswerPersonalAudioFile;
        state.no_answer_greeting.audio_selected = this.getAudioFileID(state.audio_files, audioFile)
      }
    }

    if(data.NoAnswerAnnouncementSelection===2 && state.audio_files_selection_enable){
      if(data.NoAnswerPersonalAudioFile){
        let audioFile = data.NoAnswerPersonalAudioFile;
        state.no_answer_greeting.alternate_1.name = data.GreetingName;
        state.no_answer_greeting.alternate_1.audio_selected= this.getAudioFileID(state.audio_files, audioFile)
      }
    }

    if(data.NoAnswerAnnouncementSelection===3 && state.audio_files_selection_enable){
      if(data.NoAnswerPersonalAudioFile){
        let audioFile = data.NoAnswerPersonalAudioFile;
        state.no_answer_greeting.alternate_2.name = data.GreetingName;
        state.no_answer_greeting.alternate_2.audio_selected= this.getAudioFileID(state.audio_files, audioFile)
      }
    }

    if(data.NoAnswerAnnouncementSelection===4 && state.audio_files_selection_enable){
      if(data.NoAnswerPersonalAudioFile){
        let audioFile = data.NoAnswerPersonalAudioFile;
        state.no_answer_greeting.alternate_3.name = data.GreetingName;
        state.no_answer_greeting.alternate_3.audio_selected = this.getAudioFileID(state.audio_files, audioFile)
      }
    }

    state.number_of_rings_before_greeting = data.NoAnswerNumberOfRings;
    state.auto_login = data.VoicePortalAutoLogin;
    return state;
    
  }


	componentDidMount() {		
    let apiPath = format('usr/VMManageGreeting?ServiceNumber={ServiceNumber}&PBXUserID={PBXUserID}',this.props);
    fetchData(apiPath).then((data) => {
      if(data){
        let state = {...this.state};
        state.manage_available = true;
        this.setState(state);
      }
      else{
        let state = {...this.state};
        state.saving_enable = false;
        state.loading= false;
        state.loading_error = "Loading Fail";
        state.manage_available = false;
        this.setState(state);
      }
    }).catch((message)=>{
      let state = {...this.state};
      state.loading = false;
      state.saving_enable = false;
      state.loading_error = message;
      this.setState(state);
    });
	}

  render() {
    // if(!this.state.manage_available)
    //   return (<span></span>);

    return (
      <span>
        <a className="btn" onClick={this.handleShow}>Manage Greetings</a>

        <Modal show={this.state.showModal} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Manage Greetings</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ManageGreetingsModal Model={this.state} onHandleChangeModel={this.handleChangeState} />
          </Modal.Body>
          <Modal.Footer>
              <hr />
              <div className="btns">
              {this.getSaveButton()}
              <Button className="btn" click={this.handleClose}>Cancel</Button>
              </div>
              {this.getSaveMessage()}
          </Modal.Footer>
        </Modal>
      </span>
    );
  }

  getSaveButton=()=>{
    if(this.state.loading || !this.state.saving_enable){
      return (<Button key="1" className="btn" enable={false}>Save</Button>);
    }
    else{
      return (<Button key="2" className="btn" click={this.handleSave}>Save</Button>);
    }
  }
  getSaveMessage=()=>{
    if(this.state.saving_show_success){
      return (<ReactCSSTransitionGroup
        transitionName="vm-setting-success"
        transitionAppear={true}
        transitionAppearTimeout={4000}
        transitionEnter={true}
        transitionEnterTimeout={4000}
        transitionLeave={false}>
        <div className="inline inline-success text-center">Success!</div>
      </ReactCSSTransitionGroup>)
    }
    else if(this.state.saving_loading){
      return (<DataLoadingSpinner className='load spinner'/>)
    }
    else if(this.state.saving_error_message){
      return (<div className="inline inline-fail text-center">{this.state.saving_error_message}</div>)
    }
  }
};

export default ManageGreetingsButton;
