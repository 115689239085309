import React, { Component } from 'react';
import { BrowserRouter, Route, Link } from 'react-router-dom';
import * as settings from './../scripts/session';
import ReactDom from 'react-dom';

import archive from './../images/connect-buttons/archive.png';
import callCenter from './../images/connect-buttons/call-center.png';
import collaborate from './../images/connect-buttons/collaborate.png';
import exchange from './../images/connect-buttons/exchange.png';
import incontact from './../images/connect-buttons/incontact.png';
import ivr from './../images/connect-buttons/ivr.png';
import receptionist from './../images/connect-buttons/receptionist.png';
import skypeForBus from './../images/connect-buttons/skype-for-bus.png';
import voiceAnalytics from './../images/connect-buttons/voice-analytics.png';

class Support extends Component {
  
  constructor(props) {
    super(props);
  }

	componentDidMount() {
    
  }

  render() {
    return (
      <div className="container full-card">
        <div className="support card">
          <div className="card-header">
            <h2>Support</h2>
          </div>
          <div className="card-content">
            <div className="support-items">
              <p>Have questions about your service?</p>
              <div><a href="https://files.mydigitalservices.com/1722_FAQ.pdf" id="Support_ascx_lnkFAQ" className="no-btn" target="_blank">FAQs</a></div>
              <div><a href="https://files.mydigitalservices.com/1722_FeatureGuide.pdf" id="Support_ascx_lnkFeatureGuide" className="no-btn" target="_blank">Feature Guide</a></div>
              <div><a href="https://files.mydigitalservices.com/1722_Terms.pdf" id="Support_ascx_lnkSubscriptionAgreement" className="no-btn" target="_blank">Subscription Agreement</a></div>
              <div><a href="https://files.mydigitalservices.com/1722_911.pdf" id="Support_ascx_lnkE911" className="no-btn" target="_blank">E911</a></div>
              <br />
              <p>Subscribing to Call Recording services?</p>
              <div><small>Click the link above to launch the Call Recording Administration and Management portal. The portal requires an administrative username and password provided by your service provider. Please contact your administrator or service provider to purchase and configure Call Recording services.</small></div>
              <div><a id="Support_ascx_hypCallRecoringServerAdministrationPortal" href="http://callrecording.mymtm.us/CallRecorder" className="no-btn" target="_blank">Call Recording Server Administration Portal</a></div>
            </div>
            <h5>Apps</h5>
            <div className="applications">
              <Link to='/'><img src={archive} className="" alt="App" /></Link>
              <Link to='/'><img src={callCenter} className="" alt="App" /></Link>
              <Link to='/'><img src={collaborate} className="" alt="App" /></Link>
              <Link to='/'><img src={incontact} className="" alt="App" /></Link>
              <Link to='/'><img src={ivr} className="" alt="App" /></Link>
              <Link to='/'><img src={receptionist} className="" alt="App" /></Link>
              <Link to='/'><img src={skypeForBus} className="" alt="App" /></Link>
              <Link to='/'><img src={voiceAnalytics} className="" alt="App" /></Link>
            </div>
            <div>
              <p><b>Description:</b> Momentum Mobility is a Unified Communications (UC) application that offers Instant Messaging, Video Conferencing, Call Control, Click-to-Dial, Presence, and Messaging, as well as soft-phone/soft-client functionality. Momentum Mobility instant messaging is federated with Google Chat, so Momentum Mobility users can chat with Gmail/Google Chat users. Application software is supported for both Windows and Mac. Apple iPhone/iPad and Android versions of the software are available for mobile device download from iTunes and Google Play stores.</p>
              <p>System requirements for native desktop are as follows:</p>
              <ul>
                <li>Operating system (OS): Mac OS 10.9 Mavericks, Mac OS 10.10 Yosemite, MAC OS 10.1.1 El Capitan, Windows 7, Windows 8/8.1, or Windows 10 (Classical view only).</li>
                <li>The installation footprint is approximately 125 megabytes (MB) on OS X and 215 MB on Windows.</li>
                <li>For voice calls, a sound card, speakers, and a microphone or a headset are required.</li>
                <li>For video calls, a web cam is required.</li>
              </ul>
              <p>Minimum system requirements for respective operating systems must be fulfilled, with the following additions:</p>
              <ul>
                <li>A minimum of 2 GB random access memory (RAM) is required.</li>
                <li>A minimum 1.5 GHz CPU is recommended. Dual-core CPU is recommended for video calls at a minimum.</li>
                <li>Open Graphics Library (OpenGL) 1.5 or higher is recommended.</li>
                <li>For HD video, the following is recommended:</li>
                <li>HD camera</li>
                <li>HD resolution support in display</li>
                <li>Quad Core x86 or equivalent at a minimum</li>
              </ul>
              <p>System requirements for Mobile are as follows for Apple iOS and Android:</p>
              <p>Communicator Release 21.3.1 for iOS supports devices with iOS 7, iOS 8 or iOS 9 running on iPhone 4S and later. The client has been validated on the following reference devices:</p>
              <ul>
                <li>iPhone 4S – iOS 7.1.2</li>
                <li>iPhone 5S – iOS 8.4.1</li>
                <li>iPhone 6 – iOS 9.1</li>
                <li>iPhone 6 plus – iOS 9.1</li>
                <li>iPhone 6s – iOS 9.1</li>
                <li>iPhone 6s Plus – iOS 9.1</li>
              </ul>
              <p>Communicator Mobile Release 21.3.1 for iOS has not been validated on iPods although these devices match the specifications above and the application should work with the exception of the telephony functions.</p>
              <p>Communicator Mobile Release 21.3.1 for Android supports devices with Android OS 4 or later that conform to the following requirements:</p>
              <ul>
                <li>Dual-core CPU (or higher)</li>
                <li>1 GB RAM (or higher)</li>
                <li>ARMv7 instruction set</li>
                <li>Minimum screen resolution 320 x 480</li>
                <li>The client has been validated on the following reference devices:</li>
                <li>Samsung Galaxy S4(GT-I9505) – OS 4.4.2</li>
                <li>HTC One M8 – 4.4.4</li>
                <li>Motorola Moto G (XT1032) - OS 5.1</li>
                <li>Nexus 5X (LG) – OS 6</li>
                <li>Nexus 6P (Huawei) – OS 6</li>
                <li>Communicator Tablet (Android) Release 21.0.1 support devices with Android 4 OS or later.</li>
              </ul>
              <p><a href="" className="no-btn">Click here for configuration instructions</a></p>

              <h5>Unity Desktop</h5>
              <p><b>Description:</b> Unity Desktop is a Microsoft Windows®-based Unified Communications (UC) application that combines call control, Instant Messaging, voice service configuration, click-to-dial from Outlook Contacts, directory integration and a busy lamp field to enhance the user experience. Featuring an intuitive icon based interface, Unity provides point and click call control within the familiar desktop environment. Unity improves collaboration by bringing users closer to each other and simplifying internal and external communication.</p>
              <p><b>System Requirements:</b> The PC must be running Window 2000, XP SP2 or higher and must have the Microsoft .NET 1.1 framework installed. The PC must also have an internet connection with visibility of the voice network. CPU – single core 2Ghz. Ram – 2MB. GPU 256MB onboard RAM. Apple’s Mac OS is not supported at this time</p>
              <p><b>Configuration:</b> Upon installation, you will be prompted to enter a server address, username, and password. Your username and password will be the username and password you use to login to this portal; however, you will append the domain “@mymtm.us" to the username. See example and other settings below:</p>
              <p>
                <b>Server address:</b> app.mymtm.us <br />
                <b>Server port:</b> 2208 <br />
                <b>Username (example):</b> 5555551234@mymtm.us or 5555551234X100@mymtm.us <br />
                <b>Password (example):</b> *********
              </p>
              <p><b>Licensing:</b> Unity Desktop comes with a 14-day trial license. You will need to contact your administrator or service provider to purchase a permanent license.</p>
              <p><a href="" className="no-btn">Unity Desktop application download</a></p> 
              
              <h5>Call Control Toolbar</h5>
              <p><b>Description:</b> Call Control Toolbar provides access to calling services from a toolbar. This application enables users to place and accept telephone calls and change telephone settings from within Outlook, Internet Explorer and Firefox. This PC-based application is an available Add-on to Executive and Advanced seat types.</p>
              <p><b>Licensing:</b> You will need to contact your administrator or service provider to purchase a license to use Call Control Toolbar.</p>
              <p><a href="" className="no-btn">Call Control Toolbar download</a></p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Support;