import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { Button } from '@progress/kendo-buttons-react-wrapper';

class SetPrompts extends Component {

  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {   
  }
  render() {
    
    return (
      <div className="prompts">
        <div className="form-group">
          <label className="right switch">
            <input type="checkbox" data-toggle="collapse" data-target="#hide1" />
            <span className="slider round"></span>
          </label>
          User Custom Greeting
          <div className="hidden-group collapse" id="hide1">
            <div className="form-group">
              <label >Custom Greeting</label>
                <select className="form-control">
                  <option>None</option>
                  <option>Jazz</option>
                </select>
              </div>
              <div className="form-group">
                <label><input type="checkbox" id="" /> Enable first-level extension dialing</label> 
              </div>
          </div>
        </div>
        <div className="form-group">
          <label >Holiday Schedule (if holiday schedule prompt)</label>
          <select className="form-control">
            <option>Schedule 1</option>
            <option>Schedule 2</option>
          </select>
        </div>
        <table className="table">
          <thead>
            <tr>
              <th></th>
              <th>Description</th>
              <th>Action</th>
              <th>Telephone Number</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td><input type="text" className="form-control" /></td>
              <td>
                <select name="" id="" className="form-control">
                  <option defaultValue="">---</option>
                  <option defaultValue="6">Exit</option>
                  <option defaultValue="4">Extension Dialing</option>
                  <option defaultValue="3">Name Dialing</option>
                  <option defaultValue="10">Play Announcement</option>
                  <option defaultValue="5">Repeat Menu</option>
                  <option selected="selected" defaultValue="7">Transfer To Mailbox</option>
                  <option defaultValue="2">Transfer To Operator</option>
                  <option defaultValue="">Transfer To Submenu</option> {/* this is only if autoattendant tree*/}
                  <option defaultValue="0">Transfer With Prompt</option>
                  <option defaultValue="1">Transfer Without Prompt</option>
                </select>
              </td>
              <td><input type="text" className="form-control" /></td>
            </tr>
            <tr>
              <td>2</td>
              <td><input type="text" className="form-control" /></td>
              <td>
                <select name="" id="" className="form-control">
                  <option defaultValue="---">---</option>
                  <option defaultValue="6">Exit</option>
                  <option defaultValue="4">Extension Dialing</option>
                  <option defaultValue="3">Name Dialing</option>
                  <option defaultValue="10">Play Announcement</option>
                  <option defaultValue="5">Repeat Menu</option>
                  <option selected="selected" defaultValue="7">Transfer To Mailbox</option>
                  <option defaultValue="2">Transfer To Operator</option>
                  <option defaultValue="0">Transfer With Prompt</option>
                  <option defaultValue="1">Transfer Without Prompt</option>
                </select>
              </td>
              <td><input type="text" className="form-control" /></td>
            </tr>
            <tr>
              <td>3</td>
              <td><input type="text" className="form-control" /></td>
              <td>
                <select name="" id="" className="form-control">
                  <option defaultValue="---">---</option>
                  <option defaultValue="6">Exit</option>
                  <option defaultValue="4">Extension Dialing</option>
                  <option defaultValue="3">Name Dialing</option>
                  <option defaultValue="10">Play Announcement</option>
                  <option defaultValue="5">Repeat Menu</option>
                  <option selected="selected" defaultValue="7">Transfer To Mailbox</option>
                  <option defaultValue="2">Transfer To Operator</option>
                  <option defaultValue="0">Transfer With Prompt</option>
                  <option defaultValue="1">Transfer Without Prompt</option>
                </select>
              </td>
              <td><input type="text" className="form-control" /></td>
            </tr>
            <tr>
              <td>4</td>
              <td><input type="text" className="form-control" /></td>
              <td>
                <select name="" id="" className="form-control">
                  <option defaultValue="---">---</option>
                  <option defaultValue="6">Exit</option>
                  <option defaultValue="4">Extension Dialing</option>
                  <option defaultValue="3">Name Dialing</option>
                  <option defaultValue="10">Play Announcement</option>
                  <option defaultValue="5">Repeat Menu</option>
                  <option selected="selected" defaultValue="7">Transfer To Mailbox</option>
                  <option defaultValue="2">Transfer To Operator</option>
                  <option defaultValue="0">Transfer With Prompt</option>
                  <option defaultValue="1">Transfer Without Prompt</option>
                </select>
              </td>
              <td><input type="text" className="form-control" /></td>
            </tr>
            <tr>
              <td>5</td>
              <td><input type="text" className="form-control" /></td>
              <td>
                <select name="" id="" className="form-control">
                  <option defaultValue="---">---</option>
                  <option defaultValue="6">Exit</option>
                  <option defaultValue="4">Extension Dialing</option>
                  <option defaultValue="3">Name Dialing</option>
                  <option defaultValue="10">Play Announcement</option>
                  <option defaultValue="5">Repeat Menu</option>
                  <option selected="selected" defaultValue="7">Transfer To Mailbox</option>
                  <option defaultValue="2">Transfer To Operator</option>
                  <option defaultValue="0">Transfer With Prompt</option>
                  <option defaultValue="1">Transfer Without Prompt</option>
                </select>
              </td>
              <td><input type="text" className="form-control" /></td>
            </tr>
            <tr>
              <td>6</td>
              <td><input type="text" className="form-control" /></td>
              <td>
                <select name="" id="" className="form-control">
                  <option defaultValue="---">---</option>
                  <option defaultValue="6">Exit</option>
                  <option defaultValue="4">Extension Dialing</option>
                  <option defaultValue="3">Name Dialing</option>
                  <option defaultValue="10">Play Announcement</option>
                  <option defaultValue="5">Repeat Menu</option>
                  <option selected="selected" defaultValue="7">Transfer To Mailbox</option>
                  <option defaultValue="2">Transfer To Operator</option>
                  <option defaultValue="0">Transfer With Prompt</option>
                  <option defaultValue="1">Transfer Without Prompt</option>
                </select>
              </td>
              <td><input type="text" className="form-control" /></td>
            </tr>
            <tr>
              <td>7</td>
              <td><input type="text" className="form-control" /></td>
              <td>
                <select name="" id="" className="form-control">
                  <option defaultValue="---">---</option>
                  <option defaultValue="6">Exit</option>
                  <option defaultValue="4">Extension Dialing</option>
                  <option defaultValue="3">Name Dialing</option>
                  <option defaultValue="10">Play Announcement</option>
                  <option defaultValue="5">Repeat Menu</option>
                  <option selected="selected" defaultValue="7">Transfer To Mailbox</option>
                  <option defaultValue="2">Transfer To Operator</option>
                  <option defaultValue="0">Transfer With Prompt</option>
                  <option defaultValue="1">Transfer Without Prompt</option>
                </select>
              </td>
              <td><input type="text" className="form-control" /></td>
            </tr>
            <tr>
              <td>8</td>
              <td><input type="text" className="form-control" /></td>
              <td>
                <select name="" id="" className="form-control">
                  <option defaultValue="---">---</option>
                  <option defaultValue="6">Exit</option>
                  <option defaultValue="4">Extension Dialing</option>
                  <option defaultValue="3">Name Dialing</option>
                  <option defaultValue="10">Play Announcement</option>
                  <option defaultValue="5">Repeat Menu</option>
                  <option selected="selected" defaultValue="7">Transfer To Mailbox</option>
                  <option defaultValue="2">Transfer To Operator</option>
                  <option defaultValue="0">Transfer With Prompt</option>
                  <option defaultValue="1">Transfer Without Prompt</option>
                </select>
              </td>
              <td><input type="text" className="form-control" /></td>
            </tr>
            <tr>
              <td>9</td>
              <td><input type="text" className="form-control" /></td>
              <td>
                <select name="" id="" className="form-control">
                  <option defaultValue="---">---</option>
                  <option defaultValue="6">Exit</option>
                  <option defaultValue="4">Extension Dialing</option>
                  <option defaultValue="3">Name Dialing</option>
                  <option defaultValue="10">Play Announcement</option>
                  <option defaultValue="5">Repeat Menu</option>
                  <option selected="selected" defaultValue="7">Transfer To Mailbox</option>
                  <option defaultValue="2">Transfer To Operator</option>
                  <option defaultValue="0">Transfer With Prompt</option>
                  <option defaultValue="1">Transfer Without Prompt</option>
                </select>
              </td>
              <td><input type="text" className="form-control" /></td>
            </tr>
            <tr>
              <td>0</td>
              <td><input type="text" className="form-control" /></td>
              <td>
                <select name="" id="" className="form-control">
                  <option defaultValue="---">---</option>
                  <option defaultValue="6">Exit</option>
                  <option defaultValue="4">Extension Dialing</option>
                  <option defaultValue="3">Name Dialing</option>
                  <option defaultValue="10">Play Announcement</option>
                  <option defaultValue="5">Repeat Menu</option>
                  <option selected="selected" defaultValue="7">Transfer To Mailbox</option>
                  <option defaultValue="2">Transfer To Operator</option>
                  <option defaultValue="0">Transfer With Prompt</option>
                  <option defaultValue="1">Transfer Without Prompt</option>
                </select>
              </td>
              <td><input type="text" className="form-control" /></td>
            </tr>
            <tr>
              <td>*</td>
              <td><input type="text" className="form-control" /></td>
              <td>
                <select name="" id="" className="form-control">
                  <option defaultValue="---">---</option>
                  <option defaultValue="6">Exit</option>
                  <option defaultValue="4">Extension Dialing</option>
                  <option defaultValue="3">Name Dialing</option>
                  <option defaultValue="10">Play Announcement</option>
                  <option defaultValue="5">Repeat Menu</option>
                  <option selected="selected" defaultValue="7">Transfer To Mailbox</option>
                  <option defaultValue="2">Transfer To Operator</option>
                  <option defaultValue="0">Transfer With Prompt</option>
                  <option defaultValue="1">Transfer Without Prompt</option>
                </select>
              </td>
              <td><input type="text" className="form-control" /></td>
            </tr>
            <tr>
              <td>#</td>
              <td><input type="text" className="form-control" /></td>
              <td>
                <select name="" id="" className="form-control">
                  <option defaultValue="---">---</option>
                  <option defaultValue="6">Exit</option>
                  <option defaultValue="4">Extension Dialing</option>
                  <option defaultValue="3">Name Dialing</option>
                  <option defaultValue="10">Play Announcement</option>
                  <option defaultValue="5">Repeat Menu</option>
                  <option selected="selected" defaultValue="7">Transfer To Mailbox</option>
                  <option defaultValue="2">Transfer To Operator</option>
                  <option defaultValue="0">Transfer With Prompt</option>
                  <option defaultValue="1">Transfer Without Prompt</option>
                </select>
              </td>
              <td><input type="text" className="form-control" /></td>
            </tr>
            <tr>
              <td colSpan="4">
                <p>Note: Callers who do not indicate a transfer option will be forwarded to the operator.</p>
              </td>
            </tr>
            <tr>
              <td colSpan="4">
                <div className="btns">
                  <a href="" className="btn">Save</a>
                  <a href="" className="btn">Cancel</a>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }

};

export default SetPrompts;
