import React, { Component } from 'react';
import { DataLoadingSpinner } from '../../../components/spinners';
import * as helper from '../../../scripts/helper';
import * as macdHelper from '../components/macd_helper';
import { orderBy } from '@progress/kendo-data-query';
import { Modal } from 'react-bootstrap';
import { Button } from '@progress/kendo-buttons-react-wrapper';
import { Grid, GridColumn as Column, GridCell } from '@progress/kendo-react-grid';
import WrappedCell from '../../../components/wordWrappedCell';

class MacdOrder extends Component {
    initialState(props) {
        return {
            OrderId: props.OrderId,
            MomentumAccountNumber: props.MomentumAccountNumber,
            MacdType: props.MacdType,
            Listings: [],
            ColumnConfig: {
                ShowServiceID: false,
                ShowLineName: false,
                ShowPrimaryExtension: false,
                ShowSeatType: false,
                ShowLineType: false,
                ShowListingType: false,
                ShowListingName: false,
                ShowOmitAddress: false,
                ShowAccessCode: false,
                ShowLineEmail: false,
                ShowTerminatingNumber: false,
                ShowCNAM: false,
                ShowBlockType: false,
                ShowExtension: false,
                ShowAddonPartNum: false,
                ShowTrunkID: false,
                ShowCallLimitOld: false,
                ShowCallLimitNew: false,
                ShowNewProduct: false,
                ShowE911Address: false,
                WindowWidth: '600px'
            },
            sort: [{ field: "Product", dir: "desc" }],
            fullListings: [],
            showModal: false,
            loading: true
        };
    }
    constructor(props) {
        super(props);
        this.state = this.initialState(props);
        this.fetchOrderDetails = this.fetchOrderDetails.bind(this);
        this.getColumnConfig = this.getColumnConfig.bind(this);
        this.handleShow = this.handleShow.bind(this);
        this.sortChange = this.sortChange.bind(this);

        class BlockTypeCell extends GridCell {
            render() {
                let blockObject = macdHelper.GetUIBlockObject(this.props.dataItem.Block);
                const blockDirectoryAssist = blockObject.Directory;
                const blockOperatorAssist = blockObject.Operator;
                const blockInternational = blockObject.International;
                const blockLongDistance = blockObject.DomesticLD;
                return (
                    <td>
                        <label>
                            <input type="checkbox" disabled="disabled" checked={blockDirectoryAssist} />
                            Directory Assistance (411)
                        </label>
                        <label>
                            <input type="checkbox" disabled="disabled" checked={blockOperatorAssist} />
                            Operator Assistance (0)
                        </label>
                        <label>
                            <input type="checkbox" disabled="disabled" checked={blockInternational} />
                            International Calling
                        </label>
                        <label>
                            <input type="checkbox" disabled="disabled" checked={blockLongDistance} />
                            Domestic Long Distance
                        </label>
                    </td>
                )
            }
        }

        class InputCheckField extends GridCell {
            render() {
                const value = this.props.dataItem[this.props.field];
                return (
                    <td>
                        <input disabled="disabled" type="checkbox" style={{ width: "100px" }} checked={value}></input>
                    </td>
                );
            }
        }

        this.BlockTypeCell = BlockTypeCell;
        this.InputCheckField = InputCheckField;
    };

    componentWillReceiveProps(props) {
        if (props.OrderId !== this.state.OrderId) {
            this.setState({ OrderId: props.OrderId, MacdType: props.MacdType, MomentumAccountNumber: props.MomentumAccountNumber });
        }
    }

    fetchOrderDetails = async () => {
        this.setState({ loading: true });
        let ColumnConfig = this.getColumnConfig(this.state.MacdType);
        try {
            let [OrderDetails, LocationDetails, LineItems, DetailItems, TrunkItems, AddressItems] = await macdHelper.RetrieveOrderDetails(this.state.OrderId);
            let DetailArray = [];
            if (DetailItems && DetailItems.length > 0) {
                DetailItems.map((itm, idx) => {
                    let AddressItem = AddressItems.filter(resp => resp.AddressId === itm.Temp911AddressId)
                    let e911Address = '';
                    if (AddressItem && AddressItem.length > 0) {
                        e911Address = AddressItem[0].Street1 + '\n' + AddressItem[0].Street2 + '\n' + AddressItem[0].City + ', ' + AddressItem[0].State + ' ' + AddressItem[0].ZipCode;
                        //e911Address = AddressItem[0].Street1 + '\n' + AddressItem[0].Street2 + '\n' + Ad;
                    }
                    let listing = {
                        DetailId: itm.DetailId,
                        Product: itm.PartDescription,
                        NewProduct: itm.NewPartDesc && itm.NewPartDesc !== '' ? itm.NewPartDesc : itm.PartDescription,
                        ServiceID: itm.ServiceNumber,
                        Extension: itm.Extension,
                        LineName: itm.LineFirstName + ' ' + itm.LineLastName,
                        Location: LocationDetails[0].LocationName,
                        SeatType: itm.NewPartDesc,
                        LineType: itm.NewPartDesc,
                        ListingType: macdHelper.ConvertListingTypeNumToText(itm.ListingType),
                        ListingName: itm.ListingName,
                        OmitAddress: itm.OmitAddress,
                        AccessCode: itm.AccessCode,
                        LineEmail: itm.LineEmail,
                        TerminatingNumber: itm.TerminatingNumber,
                        CNAM: itm.CNAM,
                        NewExtension: itm.Extension,
                        Block: itm.Block,
                        CallLimitNew: itm.IPTrunkGroupCallPathsNew,
                        CallLimitOld: itm.IPTrunkGroupCallPaths,
                        TrunkGroupID: itm.IPTrunkGroupID,
                        neededOrderDetails: OrderDetails,
                        E911Address: e911Address
                    }
                    DetailArray.push(listing);
                });
            }
            // resets the selected location value to reflect the momentum account number
            this.setState({ fullListings: DetailArray, Listings: DetailArray, ColumnConfig: ColumnConfig, loading: false });
        } catch (ex) {
            this.setState({ fullListings: [], Listings: [], loading: false, ColumnConfig: ColumnConfig })
        }
    }
    getColumnConfig = (macdType) => {
        switch (macdType) {
            case "CHNG_SEAT_TYPE":
                return {
                    ShowServiceID: true,
                    ShowLineName: true,
                    ShowPrimaryExtension: true,
                    ShowSeatType: true,
                    ShowLineType: false,
                    ShowListingType: false,
                    ShowListingName: false,
                    ShowOmitAddress: false,
                    ShowAccessCode: false,
                    ShowLineEmail: false,
                    ShowTerminatingNumber: false,
                    ShowCNAM: false,
                    ShowBlockType: false,
                    ShowExtension: false,
                    ShowAddonPartNum: false,
                    ShowTrunkID: false,
                    ShowCallLimitOld: false,
                    ShowCallLimitNew: false,
                    ShowNewProduct: false,
                    ShowE911Address: true,
                    ModalClass: 'macd-single-extension-order-details'
                };
            case "CHNG_CNAM":
                return {
                    ShowServiceID: true,
                    ShowLineName: true,
                    ShowPrimaryExtension: true,
                    ShowSeatType: false,
                    ShowLineType: false,
                    ShowListingType: false,
                    ShowListingName: false,
                    ShowOmitAddress: false,
                    ShowAccessCode: false,
                    ShowLineEmail: false,
                    ShowTerminatingNumber: false,
                    ShowCNAM: true,
                    ShowBlockType: false,
                    ShowExtension: false,
                    ShowAddonPartNum: false,
                    ShowTrunkID: false,
                    ShowCallLimitOld: false,
                    ShowCallLimitNew: false,
                    ShowNewProduct: false,
                    ModalClass: 'macd-single-extension-order-details'
                }
            case "CHNG_BLOCK_CHANGE":
                return {
                    ShowServiceID: true,
                    ShowLineName: true,
                    ShowPrimaryExtension: true,
                    ShowSeatType: false,
                    ShowLineType: false,
                    ShowListingType: false,
                    ShowListingName: false,
                    ShowOmitAddress: false,
                    ShowAccessCode: false,
                    ShowLineEmail: false,
                    ShowTerminatingNumber: false,
                    ShowCNAM: false,
                    ShowBlockType: true,
                    ShowExtension: false,
                    ShowAddonPartNum: false,
                    ShowTrunkID: false,
                    ShowCallLimitOld: false,
                    ShowCallLimitNew: false,
                    ShowNewProduct: false,
                    ModalClass: 'macd-listing-order-details'
                }
            case "CHNG_EXT":
                return {
                    ShowServiceID: true,
                    ShowLineName: true,
                    ShowPrimaryExtension: false, //don't have the 'old' extension value
                    ShowSeatType: false,
                    ShowLineType: false,
                    ShowListingType: false,
                    ShowListingName: false,
                    ShowOmitAddress: false,
                    ShowAccessCode: false,
                    ShowLineEmail: false,
                    ShowTerminatingNumber: false,
                    ShowCNAM: false,
                    ShowBlockType: false,
                    ShowExtension: true,
                    ShowAddonPartNum: false,
                    ShowTrunkID: false,
                    ShowCallLimitOld: false,
                    ShowCallLimitNew: false,
                    ShowNewProduct: false,
                    ModalClass: 'macd-ula-order-details'
                }
            case "CHNG_LINE_TYPE":
                return {
                    ShowServiceID: true,
                    ShowLineName: true,
                    ShowPrimaryExtension: true,
                    ShowSeatType: false,
                    ShowLineType: true,
                    ShowListingType: true,
                    ShowListingName: true,
                    ShowOmitAddress: true,
                    ShowAccessCode: true,
                    ShowLineEmail: true,
                    ShowTerminatingNumber: true,
                    ShowCNAM: false,
                    ShowBlockType: false,
                    ShowExtension: false,
                    ShowAddonPartNum: false,
                    ShowTrunkID: false,
                    ShowCallLimitOld: false,
                    ShowCallLimitNew: false,
                    ShowNewProduct: false,
                    ModalClass: 'macd-listing-order-details'
                }
            case "CHNG_LISTING":
                return {
                    ShowServiceID: true,
                    ShowLineName: true,
                    ShowPrimaryExtension: true,
                    ShowSeatType: false,
                    ShowLineType: false,
                    ShowListingType: true,
                    ShowListingName: true,
                    ShowOmitAddress: true,
                    ShowAccessCode: false,
                    ShowLineEmail: false,
                    ShowTerminatingNumber: false,
                    ShowCNAM: false,
                    ShowBlockType: false,
                    ShowExtension: false,
                    ShowAddonPartNum: false,
                    ShowTrunkID: false,
                    ShowCallLimitOld: false,
                    ShowCallLimitNew: false,
                    ShowNewProduct: false,
                    ModalClass: 'macd-listing-order-details'
                }
            case "CHNG_VIRT_TERM_NUM":
                return {
                    ShowServiceID: true,
                    ShowLineName: true,
                    ShowPrimaryExtension: true,
                    ShowSeatType: false,
                    ShowLineType: false,
                    ShowListingType: false,
                    ShowListingName: false,
                    ShowOmitAddress: false,
                    ShowAccessCode: false,
                    ShowLineEmail: false,
                    ShowTerminatingNumber: true,
                    ShowCNAM: false,
                    ShowBlockType: false,
                    ShowAddonPartNum: false,
                    ShowTrunkID: false,
                    ShowCallLimitOld: false,
                    ShowCallLimitNew: false,
                    ShowNewProduct: false,
                    ModalClass: 'macd-single-extension-order-details'
                }
            case "ASSIGN_USER_ADDON":
            case "UNASSIGN_USER_ADDON":
                return {
                    ShowServiceID: true,
                    ShowLineName: true,
                    ShowPrimaryExtension: true,
                    ShowSeatType: false,
                    ShowLineType: false,
                    ShowListingType: false,
                    ShowListingName: false,
                    ShowOmitAddress: false,
                    ShowAccessCode: false,
                    ShowLineEmail: false,
                    ShowTerminatingNumber: false,
                    ShowCNAM: false,
                    ShowBlockType: false,
                    ShowExtension: false,
                    ShowAddonPartNum: true,
                    ShowTrunkID: false,
                    ShowCallLimitOld: false,
                    ShowCallLimitNew: false,
                    ShowNewProduct: false,
                    ModalClass: 'macd-ula-order-details'
                }
            case "CHNG_IPTRUNK_CP_CNT":
                return {
                    ShowServiceID: false,
                    ShowLineName: false,
                    ShowPrimaryExtension: false,
                    ShowSeatType: false,
                    ShowLineType: false,
                    ShowListingType: false,
                    ShowListingName: false,
                    ShowOmitAddress: false,
                    ShowAccessCode: false,
                    ShowLineEmail: false,
                    ShowTerminatingNumber: false,
                    ShowCNAM: false,
                    ShowBlockType: false,
                    ShowAddonPartNum: false,
                    ShowTrunkID: true,
                    ShowCallLimit: true,
                    ShowCallLimitOld: true,
                    ShowCallLimitNew: true,
                    ShowNewProduct: true,
                    ModalClass: 'macd-ula-order-details'
                }
            default:
                //redirect b/c we don't display these orders
                break;
        }
    }

    handleShow = () => {
        this.fetchOrderDetails();
        this.setState({ showModal: true });
    }

    handleClose = () => {
        this.setState({ showModal: false });
    }

    sortChange(event) {
        this.setState({
            Listings: this.sortSearchResults(event.sort),
            sort: event.sort
        });
    }

    sortSearchResults(sort, Listings = null) {
        if (Listings == null) Listings = this.state.Listings.slice();
        let sortedSet = orderBy(Listings, sort);
        if (sort.length) {
            return helper.blanksAfterZ(sortedSet, sort);
        } else {
            return sortedSet;
        }
    }
    gridContent = () => {
        if (this.state.loading) {
            return <DataLoadingSpinner className='load spinner' />
        }
        else {
            return (
                <div>
                    <Grid id="gridDetails"
                        style={{
                            minHeight: '100px', maxHeight: '525px', fontSize: 'smaller',
                            alignItems: this.state.MacdType === 'CHNG_LINE_TYPE' || this.state.MacdType === 'CHNG_LISTING' || (this.state.Listings && this.state.Listings.length === 0)
                                ? 'none' : 'center'
                        }}
                        data={this.state.Listings}
                        editable={false}
                        sortable={{ allowUnsort: true, mode: 'single' }}
                        sort={this.state.sort}
                        onSortChange={this.sortChange}
                    >
                        {this.state.ColumnConfig.ShowTrunkID ? <Column width={"130px"} field="TrunkGroupID" title='Trunk ID' /> : null }
                        <Column width={"130px"} field="Product" title='Product' />
                        {this.state.ColumnConfig.ShowNewProduct ? <Column width={"130px"} field="NewProduct" title='New Product' /> : null }
                        {this.state.ColumnConfig.ShowServiceID ? <Column width={"130px"} field="ServiceID" title='Service ID' /> : null }                        
                        {this.state.ColumnConfig.ShowPrimaryExtension ? <Column width={"120px"} field="Extension" title='Extension' /> : null}
                        {this.state.ColumnConfig.ShowLineName ?<Column width={"150px"} field="LineName" title='Line Name' /> : null }
                        <Column width={"150px"} field="Location" title='Location' />
                        {this.state.ColumnConfig.ShowSeatType ? <Column width={"140px"} field="SeatType" title='Seat Type' /> : null}
                        {this.state.ColumnConfig.ShowLineType ? <Column width={"140px"} field="LineType" title='Line Type' /> : null}
                        {this.state.ColumnConfig.ShowListingType ? <Column width={"140px"} field="ListingType" title='Listing Type' /> : null}
                        {this.state.ColumnConfig.ShowListingName ? <Column width={"140px"} field="ListingName" title='Listing Name' /> : null}
                        {this.state.ColumnConfig.ShowOmitAddress ? <Column width={"140px"} field="OmitAddress" title='Omit Address' cell={this.InputCheckField} /> : null}
                        {this.state.ColumnConfig.ShowAccessCode ? <Column width={"140px"} field="AccessCode" title='Access Code' /> : null}
                        {this.state.ColumnConfig.ShowLineEmail ? <Column width={"140px"} cell={WrappedCell} field="LineEmail" title='Line Email' /> : null}
                        {this.state.ColumnConfig.ShowTerminatingNumber ? <Column width={"170px"} field="TerminatingNumber" title='Terminating Number' /> : null}
                        {this.state.ColumnConfig.ShowCNAM ? <Column width={"140px"} field="CNAM" title='Caller ID [CNAM]' /> : null}
                        {this.state.ColumnConfig.ShowExtension ? <Column width={"140px"} field="NewExtension" title='Extension' /> : null}
                        {this.state.ColumnConfig.ShowBlockType ? <Column width={"200px"} title='Block Type' cell={this.BlockTypeCell} sortable={false} /> : null}
                        {this.state.ColumnConfig.ShowAddonPartNum ? <Column width={"140px"} field="neededOrderDetails.AddonPartNumber" title='Addon Part Number' /> : null}
                        {this.state.ColumnConfig.ShowLineTypeOld ? <Column width={"140px"} field="neededLineItemDetails.LineType" title='Old Line Type' /> : null}
                        {this.state.ColumnConfig.ShowCallLimitOld ? <Column width={"130px"} field="CallLimitOld" title='Trunk Limit' /> : null }
                        {this.state.ColumnConfig.ShowCallLimitNew ? <Column width={"130px"} field="CallLimitNew" title='New Trunk Limit' /> : null }
                        {this.state.ColumnConfig.ShowE911Address ? <Column width={"180px"} field="E911Address" title='E911 Address' /> : null }
                    </Grid>
                </div>

            )
        }
    }

    render() {
        let button = '';
        button = (<a href="#ViewOrder" onClick={() => this.handleShow()} ><u>View</u></a>);
        let classNames = "macd-order-details " + this.state.ColumnConfig.ModalClass;
        return (
            <span>
                {button}
                <Modal dialogClassName={classNames} style={{ fontSize: 'small' }} show={this.state.showModal} onHide={(e) => { this.setState({ showModal: !this.state.showModal }) }} >
                    <Modal.Header closeButton>
                        <Modal.Title>{this.state.MacdType} Order Details</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {this.gridContent()}
                    </Modal.Body>
                </Modal>
            </span>
        )
    }
}
export default MacdOrder;