import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { Button } from "@progress/kendo-buttons-react-wrapper";
import { fetchData, post } from "../../../../requests/api_calls";
import { DataLoadingSpinner } from "../../../../components/spinners";
import { UnavailableCodesSectionItem } from "./unavailable-codes-item";
import { AddAgentUnavailableCodeItem } from "./add-unavailable-code";

class AgentUnavailableCodesModal extends Component {
  constructor(props) {
    super(props);

    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);

    this.state = {
      showModal: false,
      loading: false,
      unavailableCodesData: null,
      errorMessage: "",
      availableCodeOptions: [],
    };
  }

  handleClose() {
    this.setState({
      showModal: false,
      loading: false,
      unavailableCodesData: null,
      errorMessage: "",
      availableCodeOptions: [],
    });
  }

  handleShow = async () => {
    this.setState({ showModal: true });
    await this.reloadView();
  };

  fetchAgentUnavailableCodes = async () => {
    return await fetchData("admin/enterprise/GetAgentUnavailableCodes").then(
      (agentUnavailableCodes) => agentUnavailableCodes
    );
  };

  getIfDefaultCodeOptionNotSelected = () => {
    const { unavailableCodesData } = this.state;
    const isForceUseOfAgentUnavailableCodesOn = Boolean(
      unavailableCodesData.ForceUseOfAgentUnavailableCodes
    );

    const hasDefaultCodeOptionSelected = Boolean(
      unavailableCodesData.DefaultAgentForceUnavailableCode
    );

    const hasActiveCodeForDefaultCode = unavailableCodesData.UnavailableCodes.some(
      (code) => unavailableCodesData.DefaultAgentForceUnavailableCode === code.Code && code.IsActive
    );

    return (
      isForceUseOfAgentUnavailableCodesOn &&
      hasDefaultCodeOptionSelected &&
      !hasActiveCodeForDefaultCode
    );
  };

  updateAgentUnavailableCodes = async () => {
    this.setState({ loading: true, errorMessage: "" });
    const path = "admin/enterprise/UpdateAgentUnavailableCodes";
    const { unavailableCodesData } = this.state;

    const isCodeOptionNotSelected = this.getIfDefaultCodeOptionNotSelected();

    // Check for disabled option but Force Agent Default Code on
    if (isCodeOptionNotSelected) {
      this.setState({
        errorMessage: "Cannot delete or deactivate the code. It is being used as default code.",
      });
      this.setState({ loading: false });
      return;
    }

    const isForceUseOfAgentOnWithNoOption = Boolean(
      unavailableCodesData.ForceUseOfAgentUnavailableCodes &&
        !unavailableCodesData.DefaultAgentForceUnavailableCode
    );

    if (isForceUseOfAgentOnWithNoOption) {
      this.setState({ errorMessage: "Missing default unavailable code." });
      this.setState({ loading: false });
      return;
    }

    try {
      const response = await post(path, "POST", JSON.stringify(unavailableCodesData));
      if (response.ok) {
        await this.reloadView();
      } else {
        console.error(`${path}: failed: ${response.message}`);
        this.setState({ loading: false });
      }
    } catch (error) {
      console.error(`${path}: failed: ${error}`);
      await this.setState({ loading: false });
    }
  };

  reloadView = async () => {
    this.setState({ loading: true });
    const data = await this.fetchAgentUnavailableCodes();
    const activeCodes = data.UnavailableCodes.filter((code) => code.IsActive);
    this.setState({
      unavailableCodesData: data,
      loading: false,
      availableCodeOptions: [...activeCodes],
    });
  };

  updateItem = async (item) => {
    const updateCodes = this.state.unavailableCodesData?.UnavailableCodes.map((code) => {
      if (code.Code === item.Code) {
        code = { ...code, ...item };
      }
      return code;
    });
    await this.setState({
      ...this.state,
      unavailableCodesData: {
        ...this.state.unavailableCodesData,
        UnavailableCodes: updateCodes,
      },
    });
  };

  componentDidMount() {}

  componentDidUpdate() {}

  render() {
    let modalContent;
    const { loading, unavailableCodesData, availableCodeOptions, errorMessage } = this.state;

    if (loading) {
      modalContent = (
        <div className="content">
          <DataLoadingSpinner
            className="spinner"
            style={{ margin: "10px auto 0", height: "40px" }}
          />
        </div>
      );
    } else if (Boolean(unavailableCodesData && Object.keys(unavailableCodesData).length > 0)) {
      const defaultOptions = (
        <>
          <option value="">None</option>
          {availableCodeOptions.map((unavailableCode, index) => {
            if (unavailableCode && unavailableCode?.IsActive) {
              return (
                <option value={unavailableCode.Code || index} key={index}>
                  {unavailableCode.Code + "-" + unavailableCode.Description}
                </option>
              );
            }
          })}
        </>
      );

      const isAnyCodeActive = unavailableCodesData.UnavailableCodes.some((code) => code.IsActive);
      const hasCodes =
        unavailableCodesData.UnavailableCodes && unavailableCodesData.UnavailableCodes.length > 0;

      modalContent = (
        <div className="">
          <div className="form-group">
            <label className="switch right">
              <input
                type="checkbox"
                checked={unavailableCodesData.EnableAgentUnavailableCodes}
                onChange={(e) => {
                  this.setState({
                    unavailableCodesData: {
                      ...unavailableCodesData,
                      EnableAgentUnavailableCodes: e.target.checked,
                    },
                  });
                }}
                disabled={!isAnyCodeActive}
              />
              <span className="slider round"></span>
            </label>
            Enable Agent Unavailable Codes
            {unavailableCodesData.EnableAgentUnavailableCodes && (
              <div className="hidden-group">
                <div className="form-group">
                  <label>Default Code on Do Not Disturb activation:</label>
                  <select
                    className="form-control"
                    disabled={!isAnyCodeActive}
                    value={unavailableCodesData?.DefaultAgentUnavailableCodeOnDND || ""}
                    onChange={(e) =>
                      this.setState({
                        ...this.state,
                        unavailableCodesData: {
                          ...this.state.unavailableCodesData,
                          DefaultAgentUnavailableCodeOnDND: e.target.value,
                        },
                      })
                    }
                  >
                    {defaultOptions}
                  </select>
                </div>
                <div className="form-group">
                  <label>Default Codes personal calls:</label>
                  <select
                    className="form-control"
                    disabled={!isAnyCodeActive}
                    value={unavailableCodesData?.DefaultAgentUnavailableCodeOnPersonalCalls || ""}
                    onChange={(e) =>
                      this.setState({
                        ...this.state,
                        unavailableCodesData: {
                          ...this.state.unavailableCodesData,
                          DefaultAgentUnavailableCodeOnPersonalCalls: e.target.value,
                        },
                      })
                    }
                  >
                    {defaultOptions}
                  </select>
                </div>
                <div className="form-group">
                  <label>Default Codes on consecutive bounces:</label>
                  <select
                    className="form-control"
                    disabled={!isAnyCodeActive}
                    value={
                      unavailableCodesData?.DefaultAgentUnavailableCodeOnConsecutiveBounces || ""
                    }
                    onChange={(e) =>
                      this.setState({
                        ...this.state,
                        unavailableCodesData: {
                          ...this.state.unavailableCodesData,
                          DefaultAgentUnavailableCodeOnConsecutiveBounces: e.target.value,
                        },
                      })
                    }
                  >
                    {defaultOptions}
                  </select>
                </div>
              </div>
            )}
          </div>
          <div className="form-group">
            <label className="switch right">
              <input
                type="checkbox"
                checked={unavailableCodesData.ForceUseOfAgentUnavailableCodes}
                onChange={(e) => {
                  this.setState({
                    unavailableCodesData: {
                      ...unavailableCodesData,
                      ForceUseOfAgentUnavailableCodes: e.target.checked,
                    },
                  });
                }}
                disabled={!isAnyCodeActive}
              />
              <span className="slider round"></span>
            </label>
            Force use of Agent Codes Unavailable
            {unavailableCodesData.ForceUseOfAgentUnavailableCodes && (
              <div className="hidden-group">
                <div className="form-group">
                  <label>Default code:</label>
                  <select
                    className="form-control"
                    disabled={!isAnyCodeActive}
                    value={unavailableCodesData?.DefaultAgentForceUnavailableCode || ""}
                    onChange={(e) => {
                      this.setState({
                        ...this.state,
                        unavailableCodesData: {
                          ...this.state.unavailableCodesData,
                          DefaultAgentForceUnavailableCode: e.target.value,
                        },
                        errorMessage: "",
                      });
                    }}
                  >
                    {defaultOptions}
                  </select>
                </div>
              </div>
            )}
          </div>

          <AddAgentUnavailableCodeItem onAdd={this.reloadView} />
          <table className="table">
            <thead>
              <tr>
                <th>Active</th>
                <th>Code</th>
                <th>Description</th>
                <th>Edit</th>
              </tr>
            </thead>
            <tbody>
              {!Boolean(hasCodes) && (
                <div>
                  <span>No items found.</span>
                </div>
              )}
              {unavailableCodesData.UnavailableCodes.map((code) => {
                if (code) {
                  return (
                    <UnavailableCodesSectionItem
                      onUpdate={this.reloadView}
                      onDelete={this.reloadView}
                      code={code}
                      onItemChange={this.updateItem}
                    />
                  );
                }
              })}
            </tbody>
          </table>

          <div style={{ margin: "10px 0" }}>
            <span style={{ color: "red", fontSize: "10px" }}>{errorMessage}</span>
          </div>
        </div>
      );
    } else {
      modalContent = <></>;
    }

    return (
      <div className="setting">
        <span onClick={this.handleShow}>
          <span className="name">Agent Unavailable Codes</span>
          <a className="icon btn">
            <svg width="14" height="14" viewBox="0 0 24 24">
              <path
                d="m163 440l-91-91 251-250 90 90z m309-352l-48-48c-12-11-32-11-45 2l-45 45 91 91 45-45c13-13 13-33 2-45z m-408 275l-32 117 117-32z"
                transform="scale(0.046875 0.046875)"
              ></path>
            </svg>
          </a>
        </span>
        <Modal show={this.state.showModal} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Agent Unavailable Codes</Modal.Title>
          </Modal.Header>
          <Modal.Body>{modalContent}</Modal.Body>
          <Modal.Footer>
            {!loading && (
              <>
                <hr />
                <div className="btns">
                  <Button className="btn" click={this.updateAgentUnavailableCodes}>
                    Save
                  </Button>
                  <Button className="btn" click={this.handleClose}>
                    Cancel
                  </Button>
                </div>
              </>
            )}
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default AgentUnavailableCodesModal;
