import React, { Component, Fragment } from "react";
import { Modal } from 'react-bootstrap';
import * as apiCalls from '../../../requests/api_calls';
import { storedUserRole } from '../../../scripts/helper';

export default class CR_MODAL extends Component {
  constructor() {
    super();

    this.state = {
      //showCRModal: getTCValue() ? false : true,
      loading: false,
      //TCFlag: getTCValue(),
      showCRModal: false,
      TCFlag: "",
      submitted: false,
      UserID: sessionStorage.userId,
    }
  }

  componentDidMount() {
    // if (storedUserRole() === 'SUPER_ADMIN') {
    //   this.fetchEnterpriseTerms();
    // } else {
    //   this.fetchUserTerms();
    // }
    this.fetchEnterpriseCallRecordingPopUpCount();
  }

  fetchEnterpriseCallRecordingPopUpCount = async () => {
    let path = "admin/GetEnterpriseCallRecordingPopUp";
    await apiCalls.fetchData(path).then(data => {
      if (data) {
        this.setState({
          //showCRModal: data[0].TCFlag == true ? false : true,
          showCRModal: data[0].ShowedCount >= 3 ? false : true,
          //ShowedCount: data[0].ShowedCount,
        });
      }
    }).catch(err => console.error(err));
  }

  saveEnterpriseCallRecordingPopUpCount = async () => {
    await this.setState({ loading: true });
    //let path = storedUserRole() === 'SUPER_ADMIN' ? "admin/UpdateEnterpriseTC" : `usr/UpdateServiceTC?userId=${this.state.UserID}`;
    let path = "admin/SaveEnterpriseCallRecordingPopUpCount";

    //console.log("DTO", dto);
    let response = await apiCalls.post(path, "POST").then(data => data).catch(err => console.error(err));
    if (response.ok) {
      sessionStorage.setItem("CallRecordingModalLoaded", true);
      this.setState({ loading: false, showCRModal: false, submitted: true });
    } else {
      console.log(response);
      this.setState({ loading: false });
    }
  }

  callRecordingModal = () => {
    let { emulatedUser } = sessionStorage;
    if (!emulatedUser || emulatedUser == "false") {
      // no visibility when user emulates from RPX
      return (
        <Modal className="CR-modal" show={this.state.showCRModal} onHide={!this.state.showCRModal} backdrop='static'>
          <Modal.Header>
            <Modal.Title><b>Your new and enhanced Momentum Call Recording platform is coming soon!</b></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="terms-acknowledgment">
              {/* <p>Please review the recent email communications from Momentum Telecom for important information. Contact your Momentum account manager (or Customer Support at 888.538.3960) to learn more and get details.</p> */}
              <p>In preparation for this change, please review the recent email communications from Momentum Telecom for important information.</p>
              <p>To facilitate quick account setup, System Administrators should provide an updated list of Call Recording portal Administrators and Supervisors to our customer care team via email at: <b>ccare@momentumtelecom.com</b></p>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <hr />
            <div className="btns">
              <span>
                <button type="button" className="btn"
                  onClick={this.saveEnterpriseCallRecordingPopUpCount}
                //onClick={() => console.log('Update count')}
                //disabled={!this.state.TCFlag}
                >Ok</button>
              </span>
            </div>
          </Modal.Footer>
        </Modal>
      )
    } else return <span></span>
  }

  render() {
    return this.callRecordingModal();
  }
}