import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { fetchData } from './../requests/api_calls';
import { DataLoadingSpinner } from './../components/spinners';
import { Grid, GridColumn as Column, GridCell } from '@progress/kendo-react-grid';
import { orderBy } from '@progress/kendo-data-query';
import * as settings from './../scripts/settings';
import { headerToolTip } from '../components/ellipsisHeaderTooltip';

class Services extends Component {

  constructor(props) {
    super(props);
    this.state = {
      sips: [],
      preFilter: props.location.state?props.location.state.preFilter:null,
      loading: true,
      gridHeight: (window.innerHeight - 210)  + 'px'
    }
    this.fetchSips = this.fetchSips.bind(this);
    this.searchSips = this.searchSips.bind(this);
    this.sortChange = this.sortChange.bind(this);
    this.pageChange = this.pageChange.bind(this);
    const setServiceAndRedirect = this.setServiceAndRedirect.bind(this);

    class SelectServiceLink extends GridCell {
      render() {
        let item = this.props.dataItem;
        let textClass = item.PilotRegistered ? 'text-success' : 'text-danger';
        if (item.HasProfile) {
          return (
            <td>
              <a tabIndex="0" className='no-btn' key={item.PilotDID} onClick={(e) => setServiceAndRedirect(item)} >
                <span className={textClass}>{item.PilotDID}</span>
              </a>
            </td>
          )
        } else {
          return (
            <td><span className={textClass}>{item.PilotDID}</span></td>
          )
        }
      }
    }
    this.selectServiceLink = SelectServiceLink;

    class EditSettingsButton extends GridCell {

      render() {
        let item = this.props.dataItem;
        let path = '/iframe_page/MSP_SUBSCRIBER/' + item.LocationAccountNumber + '/' + item.TrunkGroupID;
        return (
          <td align="right" className="actions">
            <Link to={path} className="icon btn">
              <svg width="14" height="14" viewBox="0 0 24 24"><path d="m163 440l-91-91 251-250 90 90z m309-352l-48-48c-12-11-32-11-45 2l-45 45 91 91 45-45c13-13 13-33 2-45z m-408 275l-32 117 117-32z" transform="scale(0.046875 0.046875)"></path></svg>
            </Link>
          </td>
        );
      }
    }
    this.editSettingsButton = EditSettingsButton;

    class FilterServices extends GridCell {
      render() {
        let item = this.props.dataItem;
        return (
          <td>
            <Link to={ "/services/" + item.LocationAccountNumber} className='no-btn'>
              {item.LocationAccountNumber}
            </Link>
          </td>
        )
      }
    }
    this.filterServices = FilterServices;
  }
  
  componentWillReceiveProps(nextProps,nextContext){
    let curFilter = nextProps.location.state?nextProps.location.state.preFilter:null;
    let prevFilter = this.state.preFilter;
    if(curFilter !== prevFilter){
      let apiPath;
      if(curFilter){
        apiPath = 'admin/SipTrunking?term=' + curFilter;
      }
      else{
        apiPath = 'admin/SipTrunking';
      }

      this.setState({...this.state, preFilter: curFilter,loading:true});
      this.fetchSips(apiPath);
    }
  }

  componentDidMount() {

    this.handleResize();
    window.addEventListener('resize', this.handleResize)
    var apiPath = ''
    if (this.state.preFilter) {
      apiPath = 'admin/SipTrunking?term=' + this.state.preFilter;
    } else {
      apiPath = 'admin/SipTrunking';
    }
    this.fetchSips(apiPath);
  }

  handleResize = () => this.setState({
    gridHeight: (window.innerHeight - 210)  + 'px'
  });

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize)
  }


  fetchSips = (apiPath, resultsMsg) => {
    fetchData(apiPath).then((data) => {
      if (data !== null) { 
        this.setState({sips: data, loading: false, resultsMsg: null});
      } else if (data === null) {
        this.setState({sips: [], loading: false, resultsMsg: resultsMsg});
      };
    });
  }

  sortChange(event) {
    this.setState({
      sips: this.sortSips(event.sort),
      sort: event.sort
    });
  }

  pageChange(event) {
    this.setState({
      sips: this.state.sips.slice(),
      skip: event.page.skip
    });
  }

  sortSips(sort) {
    var sips = [];
    if (this.state !== undefined) {
      sips = this.state.sips.slice();
    } else {
      sips = [];
    }
    return orderBy(sips, sort);
  }

  searchSips = (event) => {
    let searchText = event.target.value.trim();
    var apiPath = '';
    if (searchText !== '') {
      apiPath = 'admin/SipTrunking?term=' + searchText;
    } else { // reset to all sips
      apiPath = 'admin/SipTrunking';
    }
    this.delayedSearch(apiPath);
  }

  delayedSearch = (apiPath) => {
    if(this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.fetchSips(apiPath, settings.noResultsMsg);
    }, settings.searchDelay);
  }

  setServiceAndRedirect = (service) => {
    settings.selectService(service.PilotUserId, service.PilotDID)
    this.props.history.push('/dashboard');
  }

  gridContent = () => {
    return (
      <Grid 
        style={{ height: this.state.gridHeight }}
        data={this.state.sips}
        sortable={{
          allowUnsort: true,
          mode: 'single'
        }}
        sort={this.state.sort} 
        onSortChange={this.sortChange} 
        scrollable={'scrollable'}
      >
        <Column field='LocationName' title={headerToolTip('Location')} />
        <Column field='LocationAccountNumber' cell={this.filterServices} title={headerToolTip('Location ID')} />
        <Column field='TrunkGroupID' title={headerToolTip('Trunk Group ID')} />
        <Column field='PilotDID' cell={this.selectServiceLink} title={headerToolTip('Pilot DID')} />
        <Column field='CountEnterpriseCP' title={headerToolTip('Enterprise CP')} />
        <Column field='CountLocationCP' title={headerToolTip('Location CP')} />
        <Column field='CountServiceNumbers' title={headerToolTip('Services Numbers')} />
        <Column title="Edit" cell={this.editSettingsButton} sortable={false} filterable={false} width="60px" />
      </Grid>
    )
  }

  sipsContent = () => {
    if (this.state.loading) {
      return <DataLoadingSpinner className='load spinner'/>
    } else { 
      return (
        <div>
          <div className="card-header">
            <h1>Trunking <small>{this.state.sips.length}</small></h1>
            <div className="card-actions">
              <div className="search">
                <label htmlFor="trunking_srch_tb" className="hidden">trunking search</label>
                <input id="trunking_srch_tb" type="text" className="form-control search-control" onChange={this.searchSips} autoComplete={"off"} />
                <svg width="14" height="14" viewBox="0 0 23 23"><path d="m347 238c0-36-12-66-37-91-25-25-55-37-91-37-35 0-65 12-90 37-25 25-38 55-38 91 0 35 13 65 38 90 25 25 55 38 90 38 36 0 66-13 91-38 25-25 37-55 37-90z m147 237c0 10-4 19-11 26-7 7-16 11-26 11-10 0-19-4-26-11l-98-98c-34 24-72 36-114 36-27 0-53-5-78-16-25-11-46-25-64-43-18-18-32-39-43-64-10-25-16-51-16-78 0-28 6-54 16-78 11-25 25-47 43-65 18-18 39-32 64-43 25-10 51-15 78-15 28 0 54 5 79 15 24 11 46 25 64 43 18 18 32 40 43 65 10 24 16 50 16 78 0 42-12 80-36 114l98 98c7 7 11 15 11 25z" transform="scale(0.046875 0.046875)"></path></svg>
              </div>
              {/*<button className="btn orange">Add a Service</button>*/}
            </div>
          </div>
          <div className="card-content">
            {this.gridContent()}
            <h6 className={ this.state.resultsMsg ? 'no-results-msg full-page-table' : 'hidden'}>{this.state.resultsMsg}</h6>
          </div>
        </div>
      )
    }
  }

  render() {
    return (
      <div className="full-card container">
        <div className="sips card" style={{minWidth:'700px', overflow:'hidden'}}>
          {this.sipsContent()}
        </div>  
      </div>
    )
  }
};

export default Services;