import React, { Component } from 'react';
import { DataLoadingSpinner } from '../../components/spinners';
import { Modal } from 'react-bootstrap';
import { sendErrorMessage, convertToLocal } from './components/utility';
import * as apiCalls from '../../requests/api_calls';

export default class MsTeamsTempUsers extends Component {
  constructor(props) {
    super(props);


    this.state = {
      Enterprise: this.props.Enterprise,
      MSTeamsConfig: this.props.MSTeamsConfig,
      Trunk: this.props.Trunk,
      loading: false,
      Submission: false,
      ErrorMessage: sessionStorage.MsTeamsStatusDscription ? sessionStorage.MsTeamsStatusDscription : "",
      LicenseType: "",
      PrimaryGateWay: this.props.PrimaryGateWay,
      SecondaryGateWay: this.props.SecondaryGateWay,
      routingType: this.props.routingType,
      licenses: ""
    };
  }

  componentDidMount() {
    this.getLicensType();
  }
  getLicensType = async () => {

    await this.setState({ loading: true });

    let path = `admin/MsTeamLicense`;
    await apiCalls.fetchData(path).then(data => {
      if (data) {
        this.setState({
          loading: false,
          licenses: data,
        });
      } else {
        this.setState({
          loading: false,
          licenses: null,
        });
      }
    }).catch(err => console.error(err));
  }


  handleInputChange = async (e, key) => {
    let { value } = e.target;
    let MSTeamsConfig = { ...this.state.MSTeamsConfig };
    MSTeamsConfig[key] = value;
    await this.setState({ MSTeamsConfig });
    //console.log("MSTeamsConfig", MSTeamsConfig); // inserted AD Information
  }

  inputDataField = (val, key, onChangeFunc, readOnly = false, type = 'text') => {
    return (
      <input
        style={{
          width: '50%',
          display: 'inline-block',
        }}
        type={type}
        name={key}
        value={val}
        className="form-control"
        onChange={(e) => onChangeFunc(e, key)}
        disabled={readOnly}
      />
    )
  }

  selectLicenseType = (val) => {
    // params? (val, key, options = [], readOnly = false)
    return (
      <div
        style={{
          width: '50%',
          display: 'inline-block',
        }}
      >
        <select className="form-control"
          onChange={(e) => this.setState({ LicenseType: e.target.value })}
          value={val}
        >
          <option value={""}>--Select--</option>
          {this.state.licenses && this.state.licenses.length > 0 && this.state.licenses.map(license => {
            return <option value={license.ValueId}>{license.ValueName}</option>
          })}

        </select>
      </div>
    )
  }

  dataRow = (label, field) => {
    return (
      <div className="form-group-wrapper"
        style={{ margin: '1%', }}
      >
        <label
          style={{
            width: '50%',
            display: 'inline-block',
          }}
        >{label} </label>
        {field}
      </div >
    )
  }

  cardContent = () => {
    //IsM365DomainCreated=1
    // IsDNSTicketCompleted=1
    // Show Message: 
    // tempuser1@308766235-ATL01.completeuc.com
    // tempuser1@308766235-PHL01.completeuc.com
    // In a drop down for license, Show SKU for E1, E3, E5 and user will select one. (Add note: Please verify license exists in Microsoft 365)
    // On Save. Call MS Teams API
    // show Save and Save and Continue as Enabled only when (IsM365DomainCreated=1 && IsDNSTicketCompleted=1)
    let { Enterprise, LicenseType, ErrorMessage, MSTeamsConfig } = this.state;

    return (
      <div className="card-content"
        style={{
          paddingTop: "20px"
        }}>
        <div className="card-actions">
        </div>
        <p><b>This step creates Microsoft Teams Temp User 1 and Temp User 2 using the License Type selected.</b></p>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <div className="flex-row"
            style={{
              width: '60%',
            }}
          >
            {/* {this.dataRow("Temp User 1:", this.inputDataField(MSTeamsConfig.TempUser1, null, this.handleInputChange, true))}
            {this.dataRow("Temp User 2:", this.inputDataField(MSTeamsConfig.TempUser2, null, this.handleInputChange, true))}
             */}
            {this.dataRow("License Type:", this.selectLicenseType(LicenseType))}
            <div>
              {/* <button
                className="a btn"
                onClick={() => this.setState({ showVerifyDomainModal: true })}
              //disabled={this.state.MSTeamsConfig.IsTempUsersCreated ? true : false}
              >RELOAD</button> */}
              <button
                className="a btn"
                onClick={() => { this.setState({ showConfirmationModal: true }) }}
                disabled={this.state.MSTeamsConfig.IsTempUserCreatedWithin24Hrs == false &&
                  this.state.MSTeamsConfig.IsDNSTicketCreated == true &&
                  this.state.MSTeamsConfig.IsDNSTicketCompleted == true &&
                  this.state.MSTeamsConfig.IsSBCTicketCreated == true &&
                  this.state.MSTeamsConfig.IsSBCTicketCompleted == true ? false : true}
              >Create Temporary Users</button>
              <button
                className="a btn"
                onClick={this.saveAndContinue}r
              >Continue</button>
            </div>
            {this.wizardMsg()}
            {ErrorMessage !== "" ? <small style={{
              color: 'red',
              marginLeft: '10px'
            }}>Error: {ErrorMessage}</small> : null}
          </div>
          <div className="flex-row"
            style={{
              marginLeft: '2%',
              width: '40%',
              border: '1px solid grey'
            }}
          >
            <div style={{ textAlign: "center", paddingLeft: '10px' }}>
              <span style={{ textDecoration: "underline" }}>Support Notes</span>
              <p style={{ textAlign: "left" }}>In this step, the Deployment Wizard allows the Admin to select the appropriate license type and create Temporary users (as shown in the Temp User 1 and 2 fields) within Microsoft 365 to validate the domain configuration setup.
                <br /><br />Select the License Type from the drop-down list of options.
                <br /><br />Click on the Create Temporary Users button.
                <br /><br />Please wait for 24 business hours to allow Microsoft 365 to replicate the domains across their systems.
                <br /><br />When completed (and after 24 business hours have elapsed to provide time for propagation through the Microsoft system), click Continue to move on to the next step.
                <br /><br />Note: A Microsoft 365 Tenant Admin can remove these temporary test users in the Microsoft 365 portal after the {this.state.routingType} setup is completed and it has been verified that it is working.
              </p>
            </div>
          </div>
        </div>
      </div >
    )
  }

  wizardMsg = () => {
    let { IsTempUsersCreated, TempUsersCreatedOn } = this.state.MSTeamsConfig;
    if (IsTempUsersCreated) {
      return <div><br />
        <small
          style={{
            marginLeft: '10px'
          }}
        ><b>WAIT 24 HOURS FOR REPLICATION ACROSS THE SYSTEM</b></small><br />
        <small
          style={{
            marginLeft: '10px'
          }}
        ><b>{this.getTimeAfter24Hrs(TempUsersCreatedOn)}</b></small><br />
        <small style={{
          color: 'green',
          marginLeft: '10px'
        }}>Temporary Users Created: {convertToLocal(TempUsersCreatedOn).toLocaleString()}</small><br />
      </div>
    } else return <br />
  }


  getTimeAfter24Hrs = (dateTime) => {
    try {
      let date = convertToLocal(dateTime);
      if (date instanceof Date) {
        let time = date.getTime();
        let tomorrow = new Date(time + 24 * 60 * 60 * 1000);
        return `${tomorrow.toLocaleString()}`;
      }
    } catch (e) {
      return "";
    }
  }

  verifyAzureDomains = () => {
    let path = 'admin/VerifyAzureDomains';
    let { MSTeamsConfig, Trunk, Enterprise } = this.state;
    let dto = {
      userName: sessionStorage.username,//"prodnewsubsite@momentumtelecom.com",
      enterpriseId: Enterprise,
      trunkName: Trunk.TrunkName,
    };

    // let response = await apiCalls.post(path, "POST", JSON.stringify(dto)).then(data => data).catch(err => console.error(err));
    // if (response.ok) {
    // }
    this.props.RefreshData();
  }

  createTempUsers = async () => {
    await this.setState({ submittedTicket: true });
    let path = 'admin/CreateMsTeamsTempUsers';
    let { MSTeamsConfig, Trunk, Enterprise, LicenseType } = this.state;
    let dto = {
      userName: sessionStorage.username,//"prodnewsubsite@momentumtelecom.com",
      enterpriseId: Enterprise,
      trunkName: Trunk.TrunkName,
      LicenseType,
    };

    let response = await apiCalls.post(path, "POST", JSON.stringify(dto)).then(data => data).catch(err => console.error(err));
    if (response) {
      sendErrorMessage(response.message);
      this.props.RefreshData();
    }
  }

  saveAndContinue = () => {
    if (!this.state.MSTeamsConfig.IsTempUserCreatedWithin24Hrs) {
      this.setState({ completed: true });
      this.props.Proceed("dr");
    }
  }

  render() {
    return (
      <div>
        <Modal show={this.state.showVerifyDomainModal} onHide={this.handleClose} backdrop='static'>
          <Modal.Header>
            <Modal.Title>Verify Azure Domains?</Modal.Title>
          </Modal.Header>
          <Modal.Footer>
            <hr />
            {this.state.submittedTicket ? <DataLoadingSpinner className='load spinner' /> : <div className="btns">
              <span>
                <button type="button" className="btn" onClick={this.verifyAzureDomains}>Yes</button>
                <button className="btn" onClick={() => this.setState({ showVerifyDomainModal: false })}>No</button>
              </span>
            </div>}
          </Modal.Footer>
        </Modal>
        <Modal show={this.state.showConfirmationModal} onHide={this.handleClose} backdrop='static'>
          <Modal.Header>
            <Modal.Title>Create Temp Users?</Modal.Title>
          </Modal.Header>
          <Modal.Footer>
            <hr />
            {this.state.submittedTicket ? <DataLoadingSpinner className='load spinner' /> : <div className="btns">
              <span>
                <button type="button" className="btn" onClick={this.createTempUsers}>Yes</button>
                <button className="btn" onClick={() => this.setState({ showConfirmationModal: false })}>No</button>
              </span>
            </div>}
          </Modal.Footer>
        </Modal>
        {this.cardContent()}
      </div>
    );
  };
}