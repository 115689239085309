import React, { Component } from 'react';
import Voicemails from './voicemails/card';
import CallLogs from './call_logs';
import BasicFeatures from './basic_features';
import ProfileInfo from './profile_info';
import Applications from './applications';
import EnterpriseContactWidget from './user_contact_widget';
import { connect } from 'react-redux';
import { userIsAdmin, redirectToLanding, atRootPath } from './../scripts/helper';
import { DataLoadingSpinner } from './../components/spinners';
import Masonry from "react-masonry-component";
import { fetchData } from "./../requests/api_calls";
//import TC_MODAL from './modals/termsAndConditionsModal';

class Dashboard extends Component {

  constructor(props) {
    super(props);
    const profileInfo = this.profileInfo.bind(this);
    const voicemail = this.voicemail.bind(this);
    const applications = this.applications.bind(this);
    const basicFeatures = this.basicFeatures.bind(this);
    const enterpriseDirectory = this.enterpriseDirectory.bind(this);
    let widgets = [
      { toggle: 'showProfileInfo', functionName: profileInfo, widgetRow: 0, widgetColumn: 0 },
      { toggle: 'showVoicemail', functionName: voicemail, widgetRow: 0, widgetColumn: 0 },
      { toggle: 'showEnterpriseContacts', functionName: enterpriseDirectory, widgetRow: 0, widgetColumn: 0 },
      { toggle: 'showBasicFeatures', functionName: basicFeatures, widgetRow: 0, widgetColumn: 0 },
      { toggle: 'showApplications', functionName: applications, widgetRow: 0, widgetColumn: 0 },

    ];
    let isIpTrunking = sessionStorage.isSelectedServiceIpTrunking !== null && sessionStorage.isSelectedServiceIpTrunking !== undefined ? sessionStorage.isSelectedServiceIpTrunking : false;
    isIpTrunking = isIpTrunking !== undefined && isIpTrunking !== null && isIpTrunking.toString() === 'true' ? true : false;

    this.state = {
      serviceLoading: props.serviceLoading,
      ServiceNumber: props.ServiceNumber,
      PBXUserID: props.PBXUserID,
      AccountNumber: props.AccountNumber,
      DirectoryNumber: props.DirectoryNumber,
      Extension: props.Extension,
      widgets: widgets,
      showProfileInfo: true,
      showVoicemail: isIpTrunking ? false : true,
      showCallLogs: true,
      showApplications: true,
      showBasicFeatures: isIpTrunking ? false : true,
      showEnterpriseContacts: isIpTrunking ? false : true,

    };
  }

  componentDidMount() {
    if (userIsAdmin() && atRootPath()) {
      redirectToLanding();
    }
  }

  componentWillReceiveProps(props) {
    if (props.serviceLoading !== this.state.serviceLoading || (props.ServiceNumber !== undefined && props.ServiceNumber !== this.state.ServiceNumber) ||
      (props.PBXUserID !== undefined && props.PBXUserID !== this.state.PBXUserID)) {
      this.setState({
        serviceLoading: props.serviceLoading,
        ServiceNumber: props.ServiceNumber,
        PBXUserID: props.PBXUserID,
        AccountNumber: props.AccountNumber,
        DirectoryNumber: props.DirectoryNumber,
        Extension: props.Extension,
        showProfileInfo: props.showProfileInfo !== null && props.showProfileInfo !== undefined ? props.showProfileInfo : this.state.showProfileInfo,
        showVoicemail: props.showVoicemail !== null && props.showVoicemail !== undefined ? props.showVoicemail : this.state.showVoicemail,
        showCallLogs: props.showCallLogs !== null && props.showCallLogs !== undefined ? props.showCallLogs : this.state.showCallLogs,
        showApplications: props.showApplications !== null && props.showApplications !== undefined ? props.showApplications : this.state.showApplications,
        showBasicFeatures: props.showBasicFeatures !== null && props.showBasicFeatures !== undefined ? props.showBasicFeatures : this.state.showBasicFeatures,
        showEnterpriseContacts: props.showEnterpriseContacts !== null && props.showEnterpriseContacts !== undefined ? props.showEnterpriseContacts : this.state.showEnterpriseContacts
      });
    }
  }

  enterpriseDirectory = () => {
    let { IsWholesale } = sessionStorage;
    if (this.state.PBXUserID && this.state.PBXUserID.length > 0 && this.state.showEnterpriseContacts === true && IsWholesale.toString().toLowerCase() == "true")
      return <EnterpriseContactWidget
        PBXUserID={this.state.PBXUserID}
      />
  }

  profileInfo = () => {
    // do not load until props have mapped from redux store 
    if (this.state.ServiceNumber !== undefined) {
      return (
        <ProfileInfo
          ServiceNumber={this.state.ServiceNumber}
          PBXUserID={this.state.PBXUserID}
          IsAdmin={userIsAdmin()}
          adminProfile={this.state.adminProfile}
        />
      )
    }
  }

  voicemail = () => {
    // do not load until props have mapped from redux store
    if (this.state.AccountNumber !== undefined && this.state.DirectoryNumber !== undefined
      && this.state.ServiceNumber !== undefined && this.state.PBXUserID !== undefined) { // && this.state.showVoicemail === true) {
      return <Voicemails
        AccountNumber={this.state.AccountNumber}
        DirectoryNumber={this.state.DirectoryNumber}
        ServiceNumber={this.state.ServiceNumber}
        PBXUserID={this.state.PBXUserID} />
    }
  }

  callLogs = () => {
    // do not load until props have mapped from redux store
    if (this.state.AccountNumber !== undefined && this.state.PBXUserID !== undefined) {
      return <CallLogs
        LocationAccountNumber={this.state.AccountNumber}
        PBXUserID={this.state.PBXUserID}
        ServiceNumber={this.state.ServiceNumber}
        Extension={this.state.Extension}
      />
    }
  }

  applications = () => {
    // do not load until props have mapped from redux store
    if (this.state.ServiceNumber !== undefined && this.state.PBXUserID !== undefined) {
      return <Applications ServiceNumber={this.state.ServiceNumber} PBXUserID={this.state.PBXUserID} />
    }
  }

  basicFeatures = () => {
    // do not load until props have mapped from redux store
    if (this.state.ServiceNumber !== undefined && this.state.PBXUserID !== undefined && this.state.showBasicFeatures === true) {
      return <BasicFeatures ServiceNumber={this.state.ServiceNumber} PBXUserID={this.state.PBXUserID} Extension={this.state.Extension} />
    }
  }

  renderColumns = () => {
    let columnObj = {
      column1: [],
      column2: [],
      column3: []
    }
    let iCountNextColumn = 1;
    let iCountActiveWidgets = 0;
    for (var i = 0; i < this.state.widgets.length; i++) {
      let stateLookup = this.state.widgets[i].toggle;
      let pushFunction = this.state.widgets[i].functionName;
      let selectedColumn = this.state.widgets[i].widgetColumn === 0 ? iCountNextColumn : this.state.widgets[i].widgetColumn;
      if (this.state[stateLookup] === true) {
        iCountActiveWidgets += 1;
        columnObj['column' + selectedColumn].push(pushFunction());
        if (iCountActiveWidgets % 2 === 0)
          iCountNextColumn = 1;
        else
          iCountNextColumn += 1;

      }
    }
    let columnWidgets = columnObj.column1.map((f) => {
      return f;
    });
    let column1 = (
      <div className="column">
        {columnWidgets}
      </div>
    );
    columnWidgets = [];
    columnWidgets = columnObj.column2.map((f) => {
      return f;
    });
    let column2 = (
      <div className="column">
        {columnWidgets}
      </div>
    );

    //call logs must take the entirety of the 3rd column. no other widget can be rendered there:
    let column3 = (
      <div className="column">
        {this.state.showCallLogs === true ? this.callLogs() : null}
      </div>
    );

    return (
      <div className="container">
        {/* <TC_MODAL /> */}
        <Masonry id={'grid'}
          className={'cards'}
          disableImagesLoaded={false}
          updateOnEachImageLoad={false}
          enableResizableChildren={true}
        >
          {column1}
          {column2}
          {column3}
        </Masonry>
      </div>
    );
  }

  render() {
    let isLoading = this.state.serviceLoading;

   
   
    if (isLoading === false){
      if(this.state.AccountNumber === undefined && this.state.DirectoryNumber === undefined && this.state.ServiceNumber === undefined)
      {
        return <div style={{'position': 'relative', 'left': '233px', 'top':' 16px','font-size': '20px'}}>No Service associated with User. Please select a Menu item on the left</div>
      }else{
        return this.renderColumns();
      }
    }
    
    else
      return <DataLoadingSpinner className='load spinner' />
  }
}

const mapStateToProps = (state) => {
  let showEnterpriseContacts = false;
  if (state.reducer.PBXUserID && state.reducer.PBXUserID.length > 0 && state.reducer.isSelectedServiceIpTrunking.toString().toLowerCase() === 'false'
    && sessionStorage.IsWholesale.toString().toLowerCase() === 'true')
    showEnterpriseContacts = true;
  return {
    serviceLoading: state.reducer.serviceLoading,
    ServiceNumber: state.reducer.ServiceNumber,
    PBXUserID: state.reducer.PBXUserID,
    AccountNumber: state.reducer.AccountNumber,
    DirectoryNumber: state.reducer.DirectoryNumber,
    Extension: state.reducer.Extension,
    showVoicemail: state.reducer.isSelectedServiceIpTrunking === true ? false : true,
    showBasicFeatures: state.reducer.isSelectedServiceIpTrunking === true ? false : true,
    showEnterpriseContacts: showEnterpriseContacts
  }
}

export default connect(mapStateToProps)(Dashboard);