import React, { Component } from 'react';
import { Grid, GridColumn as Column, GridCell } from '@progress/kendo-react-grid';
import EllipsisWithTooltip from 'react-ellipsis-with-tooltip';
import { headerToolTip } from '../../components/ellipsisHeaderTooltip';
import { DataLoadingSpinner } from '../../components/spinners';
import { orderBy } from '@progress/kendo-data-query';
import { filterBy } from '@progress/kendo-data-query';
import VoiceUserSettings from './voiceUserSettings';
import * as settings from '../../scripts/settings';

export default class CallRecordingUsers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      UsersList: this.props.UsersList,
      FullListings: this.props.FullListings,
      Sort: [{ field: "UserName", dir: "asc" }],
      filter: { logic: "and", filters: [] },
    };
    const RefreshDataSource = this.RefreshDataSource.bind(this);

    class LinkCell extends GridCell {
      constructor(props) {
        super(props);
      }

      render() {
        let { MiaRecUser } = this.props.dataItem;
        const MIAREC_DOMAIN = process.env.REACT_APP_CALL_RECORDING_ENV;//'callrecbeta.momentumtelecom.com';
        const EMULATE_USER_ID = MiaRecUser;//'90b664cc-b71a-11eb-8c13-005056a8496e';

        const MiaRecLoginUrl = 'https://' + MIAREC_DOMAIN + '/SAML/Login';
        const MiaRecEmulationURL = '/impersonate/' + EMULATE_USER_ID;//'/admin/users/' + EMULATE_USER_ID + '/impersonate';//'https://' + MIAREC_DOMAIN + '/admin/users/' + EMULATE_USER_ID + '/impersonate';

        const EmulationRedirectURL = MiaRecLoginUrl + '?came_from=' + encodeURIComponent(MiaRecEmulationURL);
        //console.log("EmulationRedirectURL", EmulationRedirectURL);
        return (
          <td>
            <a target="_blank" href={EmulationRedirectURL} className="icon btn">
              <svg width="14" height="14" viewBox="0 0 24 24"><path d="m163 440l-91-91 251-250 90 90z m309-352l-48-48c-12-11-32-11-45 2l-45 45 91 91 45-45c13-13 13-33 2-45z m-408 275l-32 117 117-32z" transform="scale(0.046875 0.046875)"></path></svg>
            </a>
          </td>
        )
      }
    }

    class VoiceSettings extends GridCell {
      constructor(props) {
        super(props);

      }

      render() {
        let { Extension, UserName } = this.props.dataItem;
        if (Extension && Extension !== "") {
          return (
            <td style={{
              position: 'relative',
              //right: '80px'
            }}>
              <VoiceUserSettings
                UserId={Extension}
                UserName={UserName}
                RefreshDataSource={RefreshDataSource}
              />
            </td>
          )
        } else return <td></td>
      }
    }
    this.LinkCell = LinkCell;
    this.VoiceSettings = VoiceSettings;
  }

  componentDidMount() {
    // load in datasource
  }

  RefreshDataSource = () => {
    this.props.RefreshDataSource();
  }

  gridContent = () => {
    let { Sort, UsersList } = this.state;
    //  if (!loading) {
    if (UsersList && UsersList.length > 0) {
      return (
        <Grid
          style={{
            width: '100%',
          }}
          data={orderBy(UsersList, Sort)} // datasource assigned to state variable
          //data={test}
          sortable={{ allowUnsort: true, mode: 'single' }}
          sort={Sort}
          onSortChange={this.handleSortChange}
        // filterable
        // filter={this.state.filter}
        // onFilterChange={this.handleFilterChange}
        >
          {/* <Checkbox></Checkbox> */}
          <Column field="UserName" title={headerToolTip("Name")} />
          <Column field="GroupName" title={headerToolTip("Group")} />
          <Column field="RoleName" title={headerToolTip("Role")} />
          <Column field="RecordingSetting" title={headerToolTip("Recording")} />
          <Column field="Extension" title={headerToolTip("Username")} />
          <Column title={this.editGridHeader("Edit")} cell={this.VoiceSettings} sortable={false} />
          <Column title=' ' field='filler' />
        </Grid>
      )
    } else {
      return <p style={{ textAlign: 'center', paddingTop: '15px' }}>No Available Data</p>
    }
    //  } else {
    //    return <DataLoadingSpinner className='load spinner' />
    //  }
  }

  editGridHeader = (title) => {
    return (
      <div
        className='use-ellipsis'
        style={{
          position: "relative",
          left: "75%"
        }}
      >{title}</div>
    )
  };

  handleSortChange = async (e) => {
    await this.setState({
      UsersList: orderBy(this.state.UsersList, e.sort),
      Sort: e.sort
    });
  }

  handleFilterChange = async (event) => {
    await this.setState({
      filter: event.filter
    });
  }

  searchListings = (event) => {
    let searchText = event.target.value.trim();
    let fullDetails = this.state.FullListings;
    if (searchText !== '') {
      searchText = event.target.value;
      let services = filterBy(fullDetails, {
        logic: 'or',
        filters: [
          { field: 'UserName', operator: 'contains', value: searchText, ignoreCase: true },
          { field: 'TenantName', operator: 'contains', value: searchText, ignoreCase: true },
          { field: 'GroupName', operator: 'contains', value: searchText, ignoreCase: true },
          { field: 'Extension', operator: 'contains', value: searchText, ignoreCase: true },
        ]
      });
      this.delayedSearch(services);
    }
    else {
      this.delayedSearch(fullDetails);
    }
  }

  delayedSearch = async (services) => {
    await this.setState({ loading: true });
    if (this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.setState({ UsersList: services, loading: false });
    }, settings.searchDelay);
  }

  cardContent = () => {
    if (!this.state.Loading) {
      if (this.state.UsersList && this.state.UsersList.length > 0) {
        return this.gridContent()
      } else {
        return (
          <p style={{ textAlign: 'center', paddingTop: '15px' }}>No Available Data</p>
        )
      }
    } else {
      return <div>
        <DataLoadingSpinner style={{ marginTop: "3%" }} className='load spinner' />
      </div>
    }
  }

  render() {
    return (
      <div className="card-content"
      >
        <div className="card-actions">
          <div className="search">
            <form ref={'searchTerm'} onSubmit={e => { e.preventDefault() }}>
              <label htmlFor="services_srch_tb" className="hidden">services search</label>
              <input id="services_srch_tb" ref={'searchTerm'} type="text" className="form-control search-control"
                autoComplete={"off"}
                onChange={this.searchListings}
              />
              <svg width="14" height="14" viewBox="0 0 23 23"><path d="m347 238c0-36-12-66-37-91-25-25-55-37-91-37-35 0-65 12-90 37-25 25-38 55-38 91 0 35 13 65 38 90 25 25 55 38 90 38 36 0 66-13 91-38 25-25 37-55 37-90z m147 237c0 10-4 19-11 26-7 7-16 11-26 11-10 0-19-4-26-11l-98-98c-34 24-72 36-114 36-27 0-53-5-78-16-25-11-46-25-64-43-18-18-32-39-43-64-10-25-16-51-16-78 0-28 6-54 16-78 11-25 25-47 43-65 18-18 39-32 64-43 25-10 51-15 78-15 28 0 54 5 79 15 24 11 46 25 64 43 18 18 32 40 43 65 10 24 16 50 16 78 0 42-12 80-36 114l98 98c7 7 11 15 11 25z" transform="scale(0.046875 0.046875)"></path></svg>
            </form>
          </div>
        </div>
        {this.cardContent()}
      </div >
    )
  };
}