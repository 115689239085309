import React, { Component } from 'react';
import * as settings from './../scripts/session';
import ReactDom from 'react-dom';

class Training extends Component {
  
  constructor(props) {
    super(props);
  }

	componentDidMount() {
    
  }

  render() {
	 return (
      <div className="container full-card">
        <div className="training card">
          <div className="card-header">
            <h2>Training</h2>
          </div>
          <div className="card-content">
            {/*<div className="iframe-holder">
              Video Window Placeholder
              <iframe src="https://secure.mydigitalservices.com/training/r-content/anonymouscallrejection/story_html5.html"></iframe>
            </div>*/}
            <h5>User Features</h5>
            <div className="training-items">
              <div className="training-item">
                <a href="">Anonymous Call Rejection</a>
              </div>
              <div className="training-item">
                <a href="">Anywhere</a>
              </div>
              <div className="training-item">
                <a href="">Barge-In</a>
              </div>
              <div className="training-item">
                <a href="">Call Block</a>
              </div>
              <div className="training-item">
                <a href="">Call Control</a>
              </div>
              <div className="training-item">
                <a href="">Call Forward Selective</a>
              </div>
              <div className="training-item">
                <a href="">Call Forwarding</a>
              </div>
              <div className="training-item">
                <a href="">Call Waiting</a>
              </div>
              <div className="training-item">
                <a href="">Do Not Disturb</a>
              </div>
              <div className="training-item">
                <a href="">Find Me</a>
              </div>
              <div className="training-item">
                <a href="">Hoteling</a>
              </div>
              <div className="training-item">
                <a href="">Voicemail</a>
              </div>
            </div>
            <h5>Unified Communications</h5>
            <div className="training-items">
              <div className="training-item">
                <a href="">Momentum Messenger</a>
              </div>
            </div>
            <h5>Group Features</h5>
            <div className="training-items">
              <div className="training-item">
                <a href="">Account/ Authorization Codes</a>
              </div>
              <div className="training-item">
                <a href="">Audio Conferencing</a>
              </div>
              <div className="training-item">
                <a href="">Auto Attendant</a>
              </div>
              <div className="training-item">
                <a href="">Hunt Groups</a>
              </div>
              <div className="training-item">
                <a href="">Time Schedules</a>
              </div>
            </div>
            <h5>Devices</h5>
            <div className="training-items">
              <div className="training-item">
                <a href="">Polycom VVX 300</a>
              </div>
              <div className="training-item">
                <a href="">Polycom VVX 400</a>
              </div>
              <div className="training-item">
                <a href="">Polycom VVX 500/600</a>
              </div>
            </div>
            <h5>Contact Center</h5>
            <div className="training-items">
              <div className="training-item">
                <a href="">Contact Center Agent Client App</a>
              </div>
              <div className="training-item">
                <a href="">Contact Center Supervisor Client App</a>
              </div>
              <div className="training-item">
                <a href="">Contact Center Tutorial 1 of 3</a>
              </div>
              <div className="training-item">
                <a href="">Contact Center Tutorial 2 of 3</a>
              </div>
              <div className="training-item">
                <a href="">Contact Center Tutorial 3 of 3</a>
              </div>
            </div>
            <h5>Collaboration Tools</h5>
            <div className="training-items">
              <div className="training-item">
                <a href="">Momentum Meeting</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Training;