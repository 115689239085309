import React, { useEffect, useState, useRef } from "react";
import { Grid, GridColumn as Column, GridCell } from '@progress/kendo-react-grid';
import { headerToolTip } from '../../components/ellipsisHeaderTooltip';
import { DataLoadingSpinner } from "../../components/spinners";
import { orderBy } from "@progress/kendo-data-query";
import * as apiCalls from "../../requests/api_calls";
import { Link } from 'react-router-dom';
import WrappedCell from '../../components/wordWrappedCell';
import Popup from "reactjs-popup";

export default function FaxList(props) {
  const [loading, setLoading] = useState(false);
  const [gridHeight] = useState(window.innerHeight - 180);
  const [faxList, setFaxList] = useState([]);
  const [FullListings, setFullListings] = useState([]);
  const [sort, setSort] = useState([{ field: "AssignedTo", dir: "desc" }]);
  const [Enterprise] = useState(props.Enterprise);
  const [Trunk] = useState(props.Trunk);
  const searchTermRef = useRef(null);
  const timeoutRef = useRef(null);

  useEffect(() => {
    fetchFaxList();
  }, [])

  const fetchFaxList = async () => {
    setLoading(true);
    let path = `admin/GetTeamsFaxUserList`;
    await apiCalls.fetchData(path).then(async (data) => {
      setFaxList(data);
      setFullListings(data);
      setLoading(false);
    }).catch(err => {
      setLoading(false);
      console.error(err);
      return false;
    }
    );
  }

  const mangeUserCell = (props) => {
    return (
      <td style={{
        position: 'relative',
        right: '35px'
      }}>
        <Link
          to={{
            pathname: `/operator-connect-fax-config`,
            state: {
              ServiceNumber: props.dataItem.ServiceNumber,
              Enterprise: Enterprise,
              Trunk: Trunk,
            }
          }}
          className="icon btn">
          <svg width="14" height="14" viewBox="0 0 24 24">
            <path d="m163 440l-91-91 251-250 90 90z m309-352l-48-48c-12-11-32-11-45 2l-45 45 91 91 45-45c13-13 13-33 2-45z m-408 275l-32 117 117-32z" transform="scale(0.046875 0.046875)"></path>
          </svg>
        </Link>
      </td>
    )
  }

  class IPCountCell extends GridCell {
    constructor(props) {
      super(props);

      this.state = {
        LoadPop: false,
        UserList: [],
      }
    }

    fetchAssignedUsers = async (ServiceNumber) => {
      await this.setState({ LoadPop: true });
      let apiPath = `oc/GetTeamsUserByServiceNumber?ServiceNumber=${ServiceNumber}`;
      await apiCalls.fetchData(apiPath).then(data => {
        if (data) {
          // let IPContainer = [];
          // data.forEach(itm => {
          //   if (itm) { IPContainer.push({ IP: itm }); }
          // });
          this.setState({
            UserList: data,
            LoadPop: false
          });
        } else {
          this.setState({
            UserList: [],
            LoadPop: false,
          })
        }
      });
    }

    loadAssignedUsers = () => {
      if (this.state.LoadPop) {
        return <DataLoadingSpinner className='load relative spinner' />
      } else {
        if (this.state.UserList.length == 0) {
          return (
            <div style={{ border: "1px solid rgb(108, 139, 173)", padding: "10px", width: '200%', textAlign: "center", backgroundColor: "white" }}>
              No assigned users
            </div>
          )
        } else {
          return (
            <div style={{ border: "1px solid rgb(108, 139, 173)", padding: "10px", width: '200%', "whiteSpace": "-moz-pre-wrap", "wordWrap": "break-word", "wordBreak": "break-word", backgroundColor: "white", }}>
              <Grid
                style={{ height: this.state.UserList.length > 4 ? '350px' : "" }}
                data={this.state.UserList}
              >
                <Column field="GivenName" title={headerToolTip("First", true)} />
                <Column field="Surname" title={headerToolTip("Last", true)} />
                <Column field="UserPrincipalName" title={headerToolTip("Principal", true)} />
              </Grid>
            </div>
          )
        }
      }
    }

    render() {
      // let trunkGroupId = this.props.dataItem.TrunkGroupID;
      // let IpCount = this.props.dataItem.IPCount;
      // let field = this.props.field;

      //if (IpCount && IpCount > 0 && field === 'IPCount') {
      return (
        <td>
          <Popup
            trigger={open => (
              <a tabIndex="0" className="no-btn">{"View"}</a>
            )}
            onOpen={() => { this.fetchAssignedUsers(this.props.dataItem.ServiceNumber) }}
            position="bottom center"
            closeOnDocumentClick
            contentStyle={{
              padding: '20px', border: 'unset', width: '250px',
            }}
          >
            <span>{this.loadAssignedUsers()}</span>
          </Popup>
        </td>
      )
      //} else return <td>{this.props.dataItem.ServiceNumber}</td>
    }
  }

  const handleSortChange = (e) => {
    setPhNumList(orderBy(faxList, e.sort))
    setSort(e.sort)
  }

  const gridContent = () => {
    if (!loading) {
      let columnWidth = '125px';
      return (
        <Grid
          style={{ height: gridHeight + 'px', width: '100%', marginTop: '10px' }}
          data={orderBy(faxList, sort)}
          sortable={{ allowUnsort: true, mode: 'single' }}
          sort={sort}
          onSortChange={(e) => handleSortChange(e)}
        >
          <Column width="115px" title={headerToolTip("Manage")} cell={mangeUserCell} />
          <Column field="ServiceNumber" title={headerToolTip("Service Number", true)} cell={WrappedCell} />
          <Column field="LocationAccountNumber" title={headerToolTip("Account Number", true)} cell={WrappedCell} />
          <Column field="ParentAccountNumber" title={headerToolTip("Enterprise", true)} cell={WrappedCell} />
          <Column field="" title={headerToolTip("Assigned To", true)} cell={IPCountCell} />
          <Column title=' ' field='filler' />
        </Grid>
      );
    } else {
      return (
        <div className="card-content">
          <div>
            <DataLoadingSpinner style={{ marginTop: "3%" }} className='load spinner' />
          </div>
        </div>
      )
    }
  }

  const searchListings = (event) => {
    let searchText = event.target.value.trim();
    let fullDetails = FullListings;
    if (searchText !== '') {
      searchText = event.target.value;
      let services = filterBy(fullDetails, {
        logic: 'or',
        filters: [
          { field: 'ServiceNumber', operator: 'contains', value: searchText, ignoreCase: true },
          { field: 'AssignedTo', operator: 'contains', value: searchText, ignoreCase: true },
        ]
      });
      delayedSearch(services);
    }
    else {
      delayedSearch(fullDetails);
    }
  }

  const delayedSearch = (services) => {
    setLoading(true);
    if (timeoutRef.current) clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(() => {
      setPhNumList(services);
      setLoading(false);
    }, settings.searchDelay);
  };

  // return (
  //   <div>
  //     {/* {gridContent()} */}
  //   </div>
  // );
  return (
    <div className="card-content">
      <div className="card-actions">
        <div className="search">
          <form onSubmit={e => { e.preventDefault() }}>
            <label htmlFor="services_srch_tb" className="hidden">services search</label>
            <input
              id="services_srch_tb"
              type="text"
              className="form-control search-control"
              autoComplete="off"
              ref={searchTermRef}
              onChange={searchListings}
            />
            <svg width="14" height="14" viewBox="0 0 23 23"><path d="m347 238c0-36-12-66-37-91-25-25-55-37-91-37-35 0-65 12-90 37-25 25-38 55-38 91 0 35 13 65 38 90 25 25 55 38 90 38 36 0 66-13 91-38 25-25 37-55 37-90z m147 237c0 10-4 19-11 26-7 7-16 11-26 11-10 0-19-4-26-11l-98-98c-34 24-72 36-114 36-27 0-53-5-78-16-25-11-46-25-64-43-18-18-32-39-43-64-10-25-16-51-16-78 0-28 6-54 16-78 11-25 25-47 43-65 18-18 39-32 64-43 25-10 51-15 78-15 28 0 54 5 79 15 24 11 46 25 64 43 18 18 32 40 43 65 10 24 16 50 16 78 0 42-12 80-36 114l98 98c7 7 11 15 11 25z" transform="scale(0.046875 0.046875)"></path></svg>
          </form>
        </div>
      </div>
      {gridContent()}
    </div>
  );
}