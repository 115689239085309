import React, { Component } from 'react';
import * as settings from '../../../scripts/settings';
import * as helper from '../../../scripts/helper';
import moment from 'moment';
import * as icons from '../../../scripts/icons';
import * as apiCalls from '../../../requests/api_calls';
import { withRouter, Link } from 'react-router-dom';
import { DataLoadingSpinner } from '../../../components/spinners';
import { ComboBox } from '@progress/kendo-react-dropdowns';
import { filterBy } from '@progress/kendo-data-query';

export default class UserSearch extends Component {
  constructor() {
    super();

    this.state = {
      searchTerm: null,
      Services: [],
      showResults: false,
    }
  }
  fetchServices = (apiPath = null) => {
    if (!apiPath) apiPath = this.apiPathWithParams();
    this.setState({ loading: true });
    apiCalls.fetchData(apiPath).then(async (data) => {
      if (data !== null) {
        let filteredArr = data.filter(itm => !JSON.parse(sessionStorage.UserIdArr).includes(itm.UserId));
        //console.log("REEE", filteredArr);
        this.setState({
          loading: false,
          Services: filteredArr,
          resultsMsg: null,
          //showResults: true,
        });
      } else if (data === null) {
        this.setState({ Services: [], loading: false, resultsMsg: settings.noResultsMsg });
      };
    });
  }

  paramsSeparator = (apiPath) => apiPath.indexOf('?') >= 0 ? '&' : '?';

  apiPathWithParams = () => {
    let { locationAccFilter, searchTerm } = this.state;
    //let path = 'admin/Services';
    let path = 'admin/UserServices';
    //if (locationAccFilter) path += '?LocationAccountNumber=' + locationAccFilter;
    //if (viewFilter) path += this.paramsSeparator(path) + 'view=' + viewFilter;
    if (searchTerm) path += this.paramsSeparator(path) + 'term=' + searchTerm;
    return path;
  }

  searchServices = (event) => {
    let searchText = event.target.value.trim();
    if (searchText !== '') {
      this.setState({ searchTerm: searchText, showResults: true, })
    } else {
      this.setState({ searchTerm: null, showResults: false })
    }
    this.delayedSearch();
  }

  delayedSearch = () => {
    if (this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(() => this.fetchServices(), settings.searchDelay);
  }

  noSearchResults = () => {
    let { showResults, Services } = this.state;
    if (
      showResults &&
      Services &&
      Services.length === 0
    ) {
      return (
        <div className="search-items">
          <div className="no-results">{settings.noResultsMsg}</div>
        </div>
      );
    } else {
      return null;
    }
  }

  serviceResults = () => {
    let { showResults, Services } = this.state;
    if (
      showResults &&
      Services &&
      Services.length > 0
    ) {
      return (
        <div className="search-items">
          <div className="search-header">MSP Users</div>
          {Services.map((item, index) => this.serviceItem(item, index))}
        </div>
      )
    } else {
      return null;
    }
  }

  handleUserChange = (userId) => {
    this.props.onSelectUser(userId);
  }

  serviceItem = (item, index) => {
    return (
      <div className="search-item" key={index}>
        <div className="name"
          onClick={() => {
            //console.log("use this user", item.UserId);
            this.setState({ searchTerm: item.Username, showResults: false, });
            this.handleUserChange(item.UserId);
          }}
        >
          {item.FirstName}&nbsp;{item.LastName}&nbsp;{item.Username}
        </div>
      </div>
    )
  }

  render() {
    let divStyle = { margin: '1%', };
    let labelStyle = { display: 'inline-block', width: '30%', };
    let inputStyle = { width: '70%', display: 'inline-block', height: '34px', fontSize: '12px' };
    return (
      <div
        //className="form-group-wrapper"
        className="ad-user-search"
        style={divStyle}
      >
        <form ref={'searchTerm'} onSubmit={e => { e.preventDefault() }}>
          <label style={labelStyle}>User Search</label>
          <input
            className="form-control search"
            ref={'searchTerm'}
            type="text"
            onChange={this.searchServices}
            autoComplete={"off"}
            style={inputStyle}
            value={this.state.searchTerm}
          />
          {/* <svg width="14" height="14" viewBox="0 0 23 23"><path d="m347 238c0-36-12-66-37-91-25-25-55-37-91-37-35 0-65 12-90 37-25 25-38 55-38 91 0 35 13 65 38 90 25 25 55 38 90 38 36 0 66-13 91-38 25-25 37-55 37-90z m147 237c0 10-4 19-11 26-7 7-16 11-26 11-10 0-19-4-26-11l-98-98c-34 24-72 36-114 36-27 0-53-5-78-16-25-11-46-25-64-43-18-18-32-39-43-64-10-25-16-51-16-78 0-28 6-54 16-78 11-25 25-47 43-65 18-18 39-32 64-43 25-10 51-15 78-15 28 0 54 5 79 15 24 11 46 25 64 43 18 18 32 40 43 65 10 24 16 50 16 78 0 42-12 80-36 114l98 98c7 7 11 15 11 25z" transform="scale(0.046875 0.046875)"></path></svg> */}
        </form>
        <div className="search-results"
          style={{ display: this.state.showResults ? 'inline-block' : 'none' }}
        >
          {this.serviceResults()}
          {this.noSearchResults()}
        </div>
      </div>
    )
  }
}