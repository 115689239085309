import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { Button } from '@progress/kendo-buttons-react-wrapper';
import _ from 'lodash';


class CustomRingbackModal extends Component {

  constructor(props) {
    super(props);

    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleAddPhoneNumber = this.handleAddPhoneNumber.bind(this);
    this.state = { showModal: false, numberOfPhoneCount:1, numberOfPhoneLimit:10 };
  }

  handleClose() {
    this.setState({ showModal: false });
  }

  handleShow() {
    this.setState({ showModal: true });
  }
  handleAddPhoneNumber(){
    if(this.state.numberOfPhoneCount < 10)
      this.setState({ numberOfPhoneCount: this.state.numberOfPhoneCount + 1 });
  }

  componentDidMount() {   
  }

  render() {
    let phoneNumberCountReachLimit = this.state.numberOfPhoneCount >= this.state.numberOfPhoneLimit;
    return (
      <div className="setting">
        <span onClick={this.handleShow}> 
          <span className="name">Custom Ringback User</span>
          <a className="icon btn"><svg width="14" height="14" viewBox="0 0 24 24"><path d="m163 440l-91-91 251-250 90 90z m309-352l-48-48c-12-11-32-11-45 2l-45 45 91 91 45-45c13-13 13-33 2-45z m-408 275l-32 117 117-32z" transform="scale(0.046875 0.046875)"></path></svg></a>
        </span>

        <Modal show={this.state.showModal} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Custom Ringback User Settings</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Custom Ringback User allows you to specify the ringback to be played to your callers, for specific calls matching your pre-defined criteria. Use this service to play a different ringback to your manager, a family member, or a customer. The criteria for each Custom Ringback Selective entry can be a list of up to 12 phone numbers or digit patterns, a specified time schedule, and a specified holiday schedule. All criteria for an entry must be satisfied for the ringback to be played (phone number and day of week and time of day). Otherwise, regular ringback is played to the caller, as if this service was not used.</p>
            <div>
              <div className="form-group">
                <label className="right switch">
                  <input type="checkbox" />
                  <span className="slider round"></span>
                </label>
                Distinctive Ring
              </div>
              <table className="table">
                <thead>
                  <tr>
                    <th className="check"><input type="checkbox" /></th>
                    <th>Active</th>
                    <th>Description</th>
                    <th>Time Schedule</th>
                    <th>Call From</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="check"><input type="checkbox" /></td>
                    <td>Active</td>
                    <td>lorem ipsum</td>
                    <td>Default</td>
                    <td>John</td>
                    <td align="right"><a className="icon btn" data-toggle="collapse" data-target="#sca1"><svg width="14" height="14" viewBox="0 0 24 24"><path d="m163 440l-91-91 251-250 90 90z m309-352l-48-48c-12-11-32-11-45 2l-45 45 91 91 45-45c13-13 13-33 2-45z m-408 275l-32 117 117-32z" transform="scale(0.046875 0.046875)"></path></svg></a></td>
                  </tr>
                  <tr className="collapse" id="sca1">
                    <td colSpan="6">
                      <div className="table-edit">
                        <a href="" className="red delete">Delete</a>
                        <div className="form-group">
                          <label>Description</label>
                          <input type="type" className="form-control" defaultValue="" />
                        </div>
                        <div className="form-group">
                          <label className="right switch">
                            <input type="checkbox" />
                            <span className="slider round"></span>
                          </label>
                          Play custom ringback
                        </div>
                        <div className="halves">
                          <div className="half">
                            <div className="form-group">
                              <label>Time Schedule</label>
                              <select className="form-control">
                                <option defaultValue="">-Select-</option>
                                <option defaultValue="">Normal</option>
                              </select>
                            </div>
                          </div>
                          <div className="half">
                            <div className="form-group">
                              <label>Holiday Schedule</label>
                              <select className="form-control">
                                <option defaultValue="">-Select-</option>
                                <option defaultValue="">Normal</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="form-group">
                          <label>Configuration</label>
                          <div className="form-control">
                            <label className="custom-control">
                              <input id="radio1" name="radio" type="radio"/>
                              <span >Default</span>
                            </label> 
                            <label className="custom-control">
                              <input id="radio1" name="radio" type="radio" checked />
                              <span >URL</span>
                            </label> 
                            <label className="custom-control">
                              <input id="radio1" name="radio" type="radio"/>
                              <span>Personal Ringback File</span>
                            </label>
                          </div>
                        </div>
                        <div className="form-group">
                          <label>URL</label>
                          <input type="type" className="form-control" defaultValue="" />
                        </div>
                        <div className="block-options">
                          <label><input type="radio" name="radio" id="one" defaultValue="multiple" /> From any number</label>
                          <label><input type="radio" name="radio" id="one" defaultValue="multiple" /> From forwarded calls</label>
                          <label><input type="radio" name="radio" id="one" defaultValue="multiple" data-toggle="collapse" data-target="#hideNums" /> From following phone numbers</label>
                          <div className="phone-nums collapse" id="hideNums">
                            <div className="">
                              <label><input type="checkbox" id="autologin" /> Any Private Number</label>
                              <label><input type="checkbox" id="autologin" /> Any Unavailable Number</label>
                            </div>
                            <div className="">
                              {_.times(this.state.numberOfPhoneCount, i =>
                                <div key={i} className="phone-num">
                                  <input type="text" className="form-control" defaultValue="123 123 1234" />
                                </div>
                              )}
                            </div>
                            <a style={{visibility: phoneNumberCountReachLimit ? 'hidden':'visible' }} className="new-phone" onClick={this.handleAddPhoneNumber}>Add additional phone number</a>
                          </div>
                        </div>
                        <hr />
                        <div className="btns">
                          <a href="" className="btn">Save</a>
                          <a href="" className="btn">Cancel</a>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="6">
                      <div className="btns">
                        <a className="btn" data-toggle="collapse" data-target="#sca1">Add Criteria</a>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Modal.Body>
          <Modal.Footer>
              <hr />
              <div className="btns">
              <Button className="btn" click={this.handleClose}>Close</Button>
              </div>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
};

export default CustomRingbackModal;
