import React, { Component } from 'react';
import { DataLoadingSpinner } from '../../components/spinners';
import { DatePicker } from '@progress/kendo-dateinputs-react-wrapper';
import ConfigureProductModal from './modals/configureProductModal';
import * as apiCalls from '../../requests/api_calls';
import * as helper from '../../scripts/helper';
import * as macdHelper from './components/macd_helper';
import { Grid, GridColumn as Column, GridCell } from '@progress/kendo-react-grid';
import { orderBy } from '@progress/kendo-data-query';
import SaveSpinner from '../../components/AddServicesSaveStatus';
import _ from 'lodash';

class MacdReview extends Component {
  initialState = (props) => {
    let orderId = 0;
    if (props.match && props.match.params) {
      if (props.match.params.OrderId)
        orderId = props.match.params.OrderId;
    }
    let maxDate = new Date();
    maxDate.setDate(maxDate.getDate() + 30);

    return {
      filter: { logic: "and", filters: [] },
      useFilter: true,
      OrderDetails: [],//actual datadetails
      OrderId: orderId ? orderId : 0,
      sort: [{ field: "PartDescription", dir: "asc" }],
      skip: 0,
      gridHeight: (window.innerHeight - 300),
      error: false,
      submitting: false,
      loading: true,
      isConfirmSubmitDialogOpen: false,
      resultsMsg: "",
      MaxServiceDate: maxDate,
      currentLocation: 0,
      MPOLocationId: 0,
      TerminatingNumbersList: [],
      //InventoryTNList: [],
      DefaultRateCenterOptions: [],
      rcStateOptions: [],
      DefaultRCState: '',
      DefaultRCName: '',
      pricebookDetails: [],
      SaveMessage: '',
      ServiceDateError: '',
      updateServiceDate: true,
      ServiceDateUpdateMessage: 'Loading Service Date...',
      ReadOnly: false,
      OrderLocationErrors: [],
      DisabledUSOCs: require('./components/DisabledUSOCs.json'),
      IPTrunkGroupOptions: [],
      IPTrunkGroups: [],
      locations: JSON.parse(sessionStorage.locationList),//need to get the location's customerId to pass it so if E911 address is being validated it can be
      CustomerId: 0
    }
  }
  constructor(props) {
    super(props);
    this.state = this.initialState(props);
    this.gridContent = this.gridContent.bind(this);
    this.sortChange = this.sortChange.bind(this);
    this.RetrieveSavedOrder = this.RetrieveSavedOrder.bind(this);
    const RemoveItemFromOrder = this.RemoveItemFromOrder.bind(this);
    this.handleSubmitClick = this.handleSubmitClick.bind(this);
    this.handleBackClick = this.handleBackClick.bind(this);
    this.handleContinueClick = this.handleContinueClick.bind(this);
    this.LoadSupportingData = this.LoadSupportingData.bind(this);
    this.fetchLocations = this.fetchLocations.bind(this);
    this.fetchIPTrunks = this.fetchIPTrunks.bind(this);
    this.fetchPricebookDetails = this.fetchPricebookDetails.bind(this);
    this.fetchRcStateList = this.fetchRcStateList.bind(this);
    const updateOrderItemFromModal = this.updateOrderItemFromModal.bind(this);
    this.handleUpdateServiceDate = this.handleUpdateServiceDate.bind(this);
    this.SubmitOrder = this.SubmitOrder.bind(this);
    this.redirectToConfirm = this.redirectToConfirm.bind(this);
    this.CheckConfigurations = this.CheckConfigurations.bind(this);
    this.DisplayOrderLocationErrors = this.DisplayOrderLocationErrors.bind(this);

    const getIPTrunks = this.getIPTrunks.bind(this);
    const getIPTrunkOptions = this.getIPTrunkOptions.bind(this);
    const updateTrunkGroups = this.UpdateTrunkGroups.bind(this);
    const removeTrunkGroups = this.RemoveTrunkGroups.bind(this);
    const getCustomerId = this.getCustomerId.bind(this);

    class MyAddCell extends GridCell {
      render() {
        let TrunksGroups = getIPTrunks(this.props.dataItem);
        let TrunkGroupOptions = getIPTrunkOptions(this.props.dataItem);
        let CustomerId = getCustomerId();

        return (
          <td>
            <ConfigureProductModal
              OrderItem={this.props.dataItem}
              OrderItemIdx={this.props.dataItem.LineItemId}
              updateParentArrayItem={updateOrderItemFromModal}
              ReadOnly={this.props.dataItem.ReadOnly ? true : false}
              RemoveItemFromOrder={RemoveItemFromOrder}
              ProductName={this.props.dataItem.PartDescription}
              IPTrunkGroupOptions={TrunkGroupOptions}
              IPTrunkGroups={TrunksGroups}
              UpdateTrunkGroups={updateTrunkGroups}//CheckTrunkGroup={this.AreTrunkGroupAssociationsValid}
              RemoveNewTrunkGroup={removeTrunkGroups}
              CustomerId={CustomerId}
            />
          </td>
        )
      }
    }
    this.CommandCell = MyAddCell;
  };

  componentDidMount() {
    let obj = sessionStorage.getItem('MacdPerms');
    if (obj.indexOf(1) < 0) {
      this.props.history.push(helper.homePageUrl());
      return;
    }
    this.fetchRcStateList();
    this.RetrieveSavedOrder(this.state.OrderId);
  }

  LoadItemConfigs = async () => {
    let [RateCenterDetails, TerminatingNumbers, LocationDetails] = await macdHelper.fetchItemConfig(this.state.OrderId, this.state.MPOLocationId);
    //RateCenterDetails => [{InventoryAvailable, RCNameAbbr}]
    //TerminatingNumbers = [{CustomerProductId, ServiceNumber}]
    let serviceAddress = LocationDetails.ShippingAddress;
    let defaultRCOptions = await macdHelper.fetchRateCenterOptions(serviceAddress.AdrState);
    let rcNameOptions = [];
    if (defaultRCOptions) {
      rcNameOptions = defaultRCOptions.map((rc) => {
        return (macdHelper.rcNameOption(rc));
      });
    }
    this.setState({
      //InventoryTNList: InventoryTN ? InventoryTN : [],
      TerminatingNumbersList: TerminatingNumbers ? TerminatingNumbers : [],
      DefaultRCState: serviceAddress.AdrState,
      DefaultRCName: RateCenterDetails && RateCenterDetails.length > 0 ? RateCenterDetails[0].RCNameAbbr : "",
      DefaultRateCenterOptions: rcNameOptions
    });
  }


  RetrieveSavedOrder = async (OrderId) => {
    this.setState({ loading: true });
    let StateOrderDetails = this.state.OrderDetails.slice();
    //await order information
    //order information is the Order itself & OrderItems w/enough details to be able to go from there

    let [OrderDetails, LocationDetails, LineItems, DetailItems, TrunkItems, AddressItems] = await macdHelper.RetrieveOrderDetails(OrderId);
    let locationId = LocationDetails[0].LocationId;
    let currentLocation = OrderDetails.MomentumAccountNumber;
    let bReadOnly = false;
    let selectedLocation = this.state.locations.filter(res => res.LocationAccountNumber === currentLocation)[0];
    if (OrderDetails.ProcessFlag.toUpperCase() !== 'OPEN' || OrderDetails.OrderSource !== 'MSP')
      bReadOnly = true;

    await this.setState({ ReadOnly: bReadOnly, MPOLocationId: locationId, currentLocation: currentLocation, CustomerId: selectedLocation.CustomerId });
    //if (!TrunkItems || TrunkItems.length === 0)
    let trunkGroups = await this.fetchIPTrunks(TrunkItems, DetailItems);
    await this.setState({IPTrunkGroups: trunkGroups});


    await this.LoadItemConfigs();
    //if read-only don't call ValidateMACD b/c it saves location when called
    let [pricebookDetails, MPOLocationDetails] = this.state.ReadOnly ? await this.LoadClosedSupportingData(this.state.OrderId, this.state.MPOLocationId) : await this.LoadSupportingData(this.state.OrderId, this.state.MPOLocationId);
    
    let LocationName = selectedLocation.LocationName;
    let ServiceDate = MPOLocationDetails.RequestDueDate ? MPOLocationDetails.RequestDueDate : '';
    let DateErrorMsg = helper.StripHtml(MPOLocationDetails.ErrorMessage);
    let [DetailItemValidations, LineItemErrors, TrunkGroupValidations] = [[],[],[]];
    //can we combine these?
    if (bReadOnly !== true) 
      [DetailItemValidations, LineItemErrors, TrunkGroupValidations] = await macdHelper.ItemValidations(this.state.OrderId, locationId);
    
    if (LineItems && LineItems.length > 0) {
      LineItems.map((LineItem, idx) => {
        let ConfigurationDetailArray = [];
        if (DetailItems) {
          ConfigurationDetailArray = DetailItems.filter(function (o) { return o.LineItemId === LineItem.LineItemId; });
        }

        let catalogItm = pricebookDetails.filter(function (o) { return o.CatalogItemId === LineItem.CatalogItemId; })[0];
        // if (!catalogItm) {
        //   let disabledListings = this.state.DisabledUSOCs;
        //   catalogItm = this.state.DisabledUSOCs.filter(function (o) { return o.USOC === LineItem.PartNumber})[0];
        // }
        let PartNumber = LineItem.PartNumber;
        let PartDescription = LineItem.PartDescription;

        //if (bReadOnly !== true) {
          ConfigurationDetailArray.map((cda) => {
            let detailItemErrorArray = [];
            if (cda.ErrorMessage !== '')
            {
              cda.ErrorMessage = helper.StringReplaceAll(cda.ErrorMessage, '</br>','', false) + '\n';
              detailItemErrorArray.push(cda.ErrorMessage);
            }
            _.filter(DetailItemValidations, div => cda.DetailId === div.SourceId).map((detailItem) => {
                let msg = cda.ErrorMessage === '' ? detailItem.ErrorMessage : '\n' + detailItem.ErrorMessage;
                if (detailItemErrorArray.length === 0 || (detailItemErrorArray.length > 0 && _.indexOf(detailItemErrorArray, msg.replace('\n','')) < 0))
                {
                  cda.ErrorMessage += msg;
                  detailItemErrorArray.push(msg.replace('\n', ''));
                }
            });


            if (LineItem.PartNumber === 'IP100') {
              let trunkItem = TrunkItems.filter(resp => resp.DetailId === cda.DetailId);
              if (trunkItem && trunkItem.length > 0)
              {
                trunkItem = trunkItem[0];
                _.filter(TrunkGroupValidations, lie => trunkItem.IPTrunkConfigId === lie.SourceId).map((trunkError) => {
                  let msg = helper.StringReplaceAll(trunkError.ErrorMessage, '</br>', '', false) + '\n';
                  cda.ErrorMessage += msg;
                });
              }
            }

            if (cda.Temp911AddressId !== 0)
            {
              let AddressItem = AddressItems.filter(resp => resp.AddressId === cda.Temp911AddressId)[0];
              if (AddressItem.ErrorMessage !== '') {
                let msg = helper.StringReplaceAll(AddressItem.ErrorMessage, '</br>', '', false);
                cda.ErrorMessage += msg;
              }
            }

            if (cda.ErrorMessage && cda.ErrorMessage !== '')
              cda.ErrorMessage = helper.StringReplaceAll(cda.ErrorMessage, '</br>', '', false);
          });

          let lineItemErrorArray = [];
          if (LineItem.ErrorMessage !== '')
          {
            LineItem.ErrorMessage = helper.StringReplaceAll(LineItem.ErrorMessage, '</br>','', false) + '\n';
            lineItemErrorArray.push(LineItem.ErrorMessage);
          }

          _.filter(LineItemErrors, lie => LineItem.LineItemId === lie.SourceId).map((lineError) => {
            let msg = helper.StringReplaceAll(lineError.ErrorMessage, '</br>', '', false) + '\n';
            if (lineItemErrorArray.length === 0 || (lineItemErrorArray.length > 0 && _.indexOf(lineItemErrorArray, msg) < 0))
            {
              LineItem.ErrorMessage += msg;
              lineItemErrorArray.push(msg);
            }
          });
        //}


        let NewItem = macdHelper.LoadItem(
          LineItem,
          PartNumber,
          PartDescription,
          this.state.currentLocation,
          LocationName,
          this.state.MPOLocationId,
          this.state.TerminatingNumbersList,
          //this.state.InventoryTNList,
          this.state.DefaultRateCenterOptions,
          this.state.rcStateOptions,
          this.state.DefaultRCState,
          this.state.DefaultRCName,
          catalogItm,
          this.state.ReadOnly
        )

        //let existingTrunkGroups = trunkGroups && trunkGroups.length > 0 ? trunkGroups.filter(resp => resp.IsNew === false) : []; //this.state.IPTrunkGroups.slice();
        NewItem.configurationDetails = macdHelper.MapConfigDetails(LineItem.NewCount === 0 ? 1 : LineItem.NewCount, [], NewItem, 0, ConfigurationDetailArray, TrunkItems, trunkGroups, AddressItems, false);
        NewItem.isConfigured = macdHelper.configuredCheck(NewItem.configurationDetails, NewItem, DetailItemValidations, LineItemErrors, TrunkGroupValidations, AddressItems);

        StateOrderDetails.push(NewItem);
      });
    }
    this.setState({
      OrderDetails: this.sortOrderDetails(this.state.sort, StateOrderDetails),
      pricebookDetails: pricebookDetails,
      ServiceDate: ServiceDate,
      ServiceDateError: DateErrorMsg,
      loading: false,
      updateServiceDate: false,
      saving: false
    });
  }

  LoadClosedSupportingData = async (OrderId, LocationId) => {
    let requests = [
      this.fetchPricebookDetails(),
      this.fetchMPOLocation(OrderId, LocationId)
    ];
    return await Promise.all(requests).then(dataArray => dataArray);
  }


  LoadSupportingData = async (OrderId, LocationId) => {
    let requests = [
      this.fetchPricebookDetails(),
      this.fetchMPOLocation(OrderId, LocationId),
      macdHelper.GetItemValidations(OrderId, LocationId),
    ];
    return await Promise.all(requests).then(dataArray => dataArray);
  }

  fetchPricebookDetails = async () => {
    let apiPath = macdHelper.apiPricebookPathWithParams(this.state.currentLocation);
    return apiCalls.fetchData(apiPath).then(data => data);
  }

  fetchLocations = async () => {
    let apiPath = macdHelper.apiGetLocations();
    return apiCalls.fetchData(apiPath).then(data => data);
  }

  fetchMPOLocation = async (OrderId, LocationId) => {
    let apiPath = macdHelper.apiGetMPOLocation(OrderId, LocationId);
    return apiCalls.fetchData(apiPath).then(data => data);
  }

  fetchRcStateList = (apiPath = null) => {
    this.setState({ loading: true });
    if (!apiPath) apiPath = 'admin/macd/GetRCStateList';
    apiCalls.fetchData(apiPath).then((data) => {
      this.setState({ loading: true });
      if (data !== null) {
        let rcStateOptions = data.map((state, index) => {
          return (macdHelper.rcStateOption(state, index));
        });

        this.setState({ rcStateOptions: rcStateOptions });

        if (this.state.OrderDetails && this.state.OrderDetails.length > 0) {
          this.state.OrderDetails.map((itm, idx) => {
            this.itm.configurationDetails.map((ci, idx2) => {
              ci.rcStateOptions = rcStateOptions;
              this.state.OrderDetails[idx].configurationDetails[idx2] = ci;
            });
          });
        }
      } else if (data === null) {
        this.setState({ rcStateOptions: [] });
      };

    });
  }

  getCustomerId = () => {
    return this.state.CustomerId;
  }

  sortChange(event) {
    this.setState({
      OrderDetails: this.sortOrderDetails(event.sort),
      sort: event.sort
    });
  }

  sortOrderDetails(sort, OrderDetails = null) {
    if (OrderDetails == null) OrderDetails = this.state.OrderDetails.slice();
    let sortedSet = orderBy(OrderDetails, sort);
    if (sort.length) {
      return helper.blanksAfterZ(sortedSet, sort);
    } else {
      return sortedSet;
    }
  }

  revisedData = (data) => {
    let sortedData = this.sortOrderDetails(this.state.sort, data)
    return sortedData;
  }

  gridContent = () => {
    return (
      <Grid
        style={{ height: this.state.gridHeight + 'px' }}
        data={this.state.OrderDetails}
        sortable={{ allowUnsort: true, mode: 'single' }}
        sort={this.state.sort}
        onSortChange={this.sortChange}
      >
        <Column width="150px" field='PartNum' title='Part Num' />
        <Column field="PartDescription" title="Product" />
        <Column width="200px" field='MRCAdjustedPrice' format="{0:c2}" title='Monthly' />
        <Column width='200px' field='NRCAdjustedPrice' format="{0:c2}" title='One-Time' />
        <Column width='150px' field='Location' title='Location' />
        {/* <Column width='150px' field='Quantity' title='Quantity' /> */}
        <Column width="150px" cell={this.CommandCell} />
        <Column field="spacer" title=" " sortable={false} filterable={false} />
      </Grid>
    )
  }

  ServiceDateContent = () => {
    if (this.state.updateServiceDate) {
      return (
        <DataLoadingSpinner className='load spinner relative' />
      )
    }
    else {
      if (this.state.ReadOnly) {
        // Kendo package containing Datepicker is out of date, unable use a disabled/enabled property... this is short-term solution for Read-Only
        if (!this.state.ServiceDate) {
          return (
            <div className="macdReviewServiceDateDiv" style={{ textAlign: "center", marginTop: "20px", fontSize: "medium", fontWeight: "bold" }}>
              Service Date: N/A
            </div>
          );
        } else {
          let date = new Date(this.state.ServiceDate);
          let serviceDate = (date.getMonth() + 1) + '/' + date.getDate() + '/' + date.getFullYear();
          return (
            <div className="macdReviewServiceDateDiv" style={{ textAlign: "center", marginTop: "20px", fontSize: "medium", fontWeight: "bold" }}>
              Service Date:
              <div style={{ width: "200px", display: "inline-block" }}>
                <span>{serviceDate}</span>
              </div>
              <div>
                <span style={{ color: 'Red', fontSize: 'smaller' }}>{this.state.ServiceDateError}</span>
              </div>
            </div>
          );
        }
      } else {
        return (
          <div className="macdReviewServiceDateDiv" style={{ textAlign: "center", marginTop: "20px", fontSize: "medium", fontWeight: "bold" }}>
            Service Date:
            <div style={{ width: "300px", display: "inline-block" }}>
              <DatePicker
                min={new Date()}
                max={this.state.MaxServiceDate}
                value={this.state.ServiceDate}
                format="MM/dd/yyyy"
                change={(e) => { this.handleUpdateServiceDate(e); }}
              />
            </div>
            <div>
              <span style={{ color: 'Red', fontSize: 'smaller' }}>{this.state.ServiceDateError}</span>
            </div>
          </div>
        );
      }
    }
  }

  handleBackClick(e) {
    this.props.history.push(macdHelper.AddEditPage(this.state.OrderId));
  }

  updateOrderItemFromModal = async (ProductItem, newConfigDetails, LineItemId) => {
    if (this.state.ReadOnly === true)
      return;
    let OrderDetails = this.state.OrderDetails;
    try {
      var result = newConfigDetails.filter(obj => {
        return obj.DetailId === 0
      });
      //if any item in config details has a DetailId = 0 then its not configured
      if (result && result.length > 0) {
        ProductItem.isConfigured = false;
      }
      else {//else need to run back-end validation. if valid then isConfigured = true.
        let [DetailItemValidations, LineItemErrors, TrunkGroupValidations] = await macdHelper.ItemValidations(this.state.OrderId, this.state.MPOLocationId);
        ProductItem.isConfigured = true;
        if ((LineItemErrors && LineItemErrors.length > 0) || (DetailItemValidations && DetailItemValidations.length > 0) || (TrunkGroupValidations && TrunkGroupValidations.length > 0)) {
          
          let errorLine = LineItemErrors.filter(function (o) { return o.SourceId === LineItemId });
          if (errorLine && errorLine.length > 0)
            ProductItem.isConfigured = false;

          newConfigDetails.map((cd, idx) => {
            errorLine = DetailItemValidations.filter(function (o) { return o.SourceId === cd.DetailId });
            if (errorLine && errorLine.length > 0) {
              cd.ErrorMessage = errorLine[0].ErrorMessage;
              newConfigDetails[idx] = cd;
              if (newConfigDetails[idx].LineItemId == ProductItem.LineItemId)
                ProductItem.isConfigured = false;
            } else {
              cd.ErrorMessage = "";
              newConfigDetails[idx] = cd;
            }
            if (ProductItem.PartNum === 'IP100') {
              _.filter(TrunkGroupValidations, lie => cd.IPTrunkConfigId === lie.SourceId).map((trunkError) => {
                let msg = helper.StringReplaceAll(trunkError.ErrorMessage, '</br>', '', false) + '\n';
                cd.ErrorMessage += msg;
              });
            }

            if (cd.Temp911AddressId !== 0)
            {
              let AddressItem = cd.address;
              if (AddressItem.ErrorMessage !== '') {
                let msg = helper.StringReplaceAll(AddressItem.ErrorMessage, '</br>', '', false);
                cd.ErrorMessage += msg;
              }
            }

            if (cd.ErrorMessage && cd.ErrorMessage !== '')
              ProductItem.isConfigured = false;
          });
        }
        if (ProductItem.PartNum === 'IP101' || ProductItem.PartNum === 'IP102' || ProductItem.PartNum === 'IP103') {
          let ipTrunks = this.state.IPTrunkGroups.slice();
          newConfigDetails.map((cd) => {
              //remove the old association
              let idx = -1;
              let updatedTrunk = ipTrunks.filter(resp => resp.CallPathId === cd.DetailId);
              if (updatedTrunk && updatedTrunk.length > 0) { 
                updatedTrunk = updatedTrunk[0];
                idx = _.indexOf(ipTrunks, updatedTrunk);
                if (cd.ParentDetailId !== updatedTrunk.ConcatId && idx > -1)
                  ipTrunks[idx].CallPathId = 0;
              }
              //update new association
              let newTrunk = ipTrunks.filter(resp => parseInt(resp.ConcatId) === parseInt(cd.ParentDetailId) || parseInt(resp.ConcatId) === parseInt(cd.IPTrunkGroupCustomerProductId));
              if (newTrunk && newTrunk.length > 0) {
                newTrunk = newTrunk[0];
                idx = _.indexOf(ipTrunks, newTrunk);
                if (cd.ParentDetailId === newTrunk.ConcatId && idx > -1)
                  ipTrunks[idx].CallPathId = cd.DetailId;
              }
          });
          this.setState({IPTrunkGroups: ipTrunks});
        }

        //Quantity Validations
        console.log('Stupid console statement to make debugs work because react be trippin');
        if (ProductItem.Quantity != newConfigDetails.length)
        {
          let dependencyRules = _.filter(macdHelper.QuantityValidationRules, resp => resp.PartNum === ProductItem.PartNum);
          if (dependencyRules !== null && dependencyRules.length === 1)
          {
            
            let otherUSOCs = dependencyRules[0].DependencyList;
            let dependentOrderItems = _.filter(OrderDetails, resp => _.indexOf(otherUSOCs, resp.PartNum) > -1);
            //Get all other call recording products and validate those
            if (dependentOrderItems !== null && dependentOrderItems.length > 0) {
              await this.ReviewAndRefreshQuantityDependencies(dependentOrderItems);
              return;
            }
          }
        }
      }
    } catch (msg) {
      console.log(msg);
    }
    if (LineItemId !== -1)
    {
      let arrayIdx = OrderDetails.indexOf(OrderDetails.filter(resp => resp.LineItemId === LineItemId)[0]);
      OrderDetails[arrayIdx].configurationDetails = newConfigDetails;
      OrderDetails[arrayIdx].Quantity = newConfigDetails.length;
      OrderDetails[arrayIdx].isConfigured = ProductItem.isConfigured;
    }
    await this.setState({ OrderDetails: OrderDetails });
    this.CheckConfigurations();
    
  }

  handleUpdateServiceDate = async (e) => {
    let ServiceDateVal = e.sender.value();
    if (ServiceDateVal == null) ServiceDateVal = new Date(); // if a past/undefined date is selected, defaults to current date
    this.setState({ ServiceDate: ServiceDateVal, updateServiceDate: true, ServiceDateUpdateMessage: 'Validating Service Date...' });
    let ServiceDateProp = ServiceDateVal.getMonth() + 1 + '/' + ServiceDateVal.getDate() + '/' + ServiceDateVal.getFullYear();
    var reqObject = {
      OrderId: this.state.OrderId,
      LocationId: this.state.MPOLocationId,
      RequestDueDate: ServiceDateProp
    }
    let response = await apiCalls.post(macdHelper.apiUpdateLocation(), 'PATCH', JSON.stringify(reqObject)).then(data => data);
    let errorMsg = '';
    if (!response.ok) {
      //display error message below
      errorMsg = 'An error occurred. Please select another date';
    } else {
      let responseMsg = JSON.parse(response.message);
      errorMsg = helper.StripHtml(responseMsg.ErrorMessage);
    }
    this.setState({ ServiceDateError: errorMsg, updateServiceDate: false });
  }

  handleSubmitClick = (e) => {
    //need to save, then need to validate, then need to submit, then redirect to macd_confirm page
    this.setState({ submitting: true, SaveMessage: 'Saving Order Updates...' });
    try {
      this.setState({ SaveMessage: 'Validating Order Items...' });
      this.SubmitOrder();
    } catch (ex) {
      this.setState({ submitting: false, SaveMessage: '' });
    }
  }

  CheckConfigurations = () => {
    let bIsConfigured = true;
    let newServices = this.state.OrderDetails.slice();
    bIsConfigured = newServices.map((ns) => {
      if (ns.isConfigured === false)
        return false;
    });
    if (!bIsConfigured) {
      let ValidationMessage = 'Order contains errors.  Please review configurations.';
      this.setState({ submitting: false, SaveMessage: '', error: true, resultsMsg: ValidationMessage });
    } else {
      this.setState({ error: false, resultsMsg: '' });
    }
    return bIsConfigured;
  }

  ReviewAndRefreshQuantityDependencies = async (dependentOrderItems) =>
  {
    //dependentOrderItems.map(async (od) => {
      //this.setState({saving: true});
      let pricebook = this.state.pricebookDetails;
      for (let i = 0; i < dependentOrderItems.length; i++)
      {
        let pricebookItem = _.filter(pricebook, resp => resp.CatalogItemId === dependentOrderItems[i].CatalogItemId);
        if (pricebookItem && pricebookItem.length > 0)
          pricebookItem = pricebookItem[0];
        else          
          continue; 
        
        let apiPath = macdHelper.apiUpdateLineItem();
        let dbLineItem = {
          OrderId: this.state.OrderId,
          LocationId: this.state.MPOLocationId,
          LineItemId: dependentOrderItems[i].LineItemId,
          CatalogItemId: dependentOrderItems[i].CatalogItemId,
          NewCount: dependentOrderItems.Quantity,
          NRCAdjustedPrice: pricebookItem.NRCAdjustedPrice ? pricebookItem.NRCAdjustedPrice : pricebookItem.ActivationCharge,
          NRCDiscountPercent: pricebookItem.NRCAdjustedPrice ? (1.00) - parseFloat(pricebookItem.NRCAdjustedPrice / pricebookItem.ActivationCharge) : 0.00,
          MRCAdjustedPrice: pricebookItem.MRCAdjustedPrice ? pricebookItem.MRCAdjustedPrice : pricebookItem.BasePrice,
          MRCDiscountPercent: pricebookItem.MRCAdjustedPrice ? (1.00) - parseFloat(pricebookItem.MRCAdjustedPrice - pricebookItem.BasePrice) : 0.00,
          UpdateNeeded: true //no values are changing in the dependent order item so this is used to force an update
        }

        let reqBody = JSON.stringify(dbLineItem);
        let responseMessage = await apiCalls.post(apiPath, 'PATCH', reqBody).then(resp => resp)
          .catch(message => {
            // this.setState({
            //   saving: false,
            //   error: true,
            //   loading: false,
            //   resultsMsg: 'An error occurred. Please check the Open Requests tab and reload your Order!'
            // });
          });
      //});
      }
      await this.setState({ OrderDetails: [], saving: false });
      //need to save those order items
      this.RetrieveSavedOrder(this.state.OrderId, false);
  }

  SubmitOrder = () => {
    let apiPath = macdHelper.apiSubmitOrder();
    if (!this.CheckConfigurations()) return;
    //let reqData = { OrderId: this.state.OrderId};
    apiCalls.post(apiPath, 'PATCH', this.state.OrderId).then(resp => {
      if (resp.ok) {
        let ValidationMessages = [];
        if (!ValidationMessages || ValidationMessages.length === 0) {
          this.setState({ SaveMessage: 'Submitting Order...' });
          this.redirectToConfirm();
        }
      } else {
        let ValidationMessage = 'Order contains errors.  Please review configurations.';
        this.setState({ submitting: false, SaveMessage: '', error: true, resultsMsg: ValidationMessage })
      }
    }).catch(message => {
      console.log(message)
      this.setState({
        submitting: false,
        SaveMessage: '',
        error: true,
        resultsMsg: message
      });
    });
  }

  handleContinueClick = () => {
    this.props.history.push(macdHelper.ConfirmPageUrl(this.state.OrderId));
  }

  redirectToConfirm = () => {
    this.props.history.push(macdHelper.ConfirmPageUrl(this.state.OrderId));
  }

  handleRemoveItem(id) {
    let msg = 'Are you sure you wish to remove this item from your order?';
    let title = 'Remove Item from Order?';
    this.setState({ isConfirmationDialogOpen: true, ConfirmationTitle: title, ConfirmationMessage: msg, RemoveItemId: id });
  }

  RemoveItemFromOrder = (id) => {
    //if trying to remove an IP Trunk Group we need to test to make sure user hasn't configured another new product to one of these new trunk groups
    let currentDetails = this.state.OrderDetails;
    //let LineItemRemoved = currentDetails.filter(resp => resp.LineItemId === id);
    
    let verb = "PATCH";
    let reqBody = {
      OrderId: this.state.OrderId,
      LocationId: this.state.MPOLocationId,
      LineItemId: id
    };
    let data = JSON.stringify(reqBody);
    let apiPath = macdHelper.apiDiscardLineItem();
    this.setState({ saving: true });
    apiCalls.post(apiPath, verb, data).then(resp => {
      if (resp.ok) {
        let newTrunkGroups = this.state.IPTrunkGroups.slice();
        let newTrunkGroupOptions = this.state.IPTrunkGroupOptions.slice();
        
        let array = this.state.OrderDetails;
        var itmToRemove = array.find((element) => {
          return element.LineItemId === id;
        });
        var index = this.state.OrderDetails.indexOf(itmToRemove);
        array.splice(index, 1);

        if (itmToRemove.PartNum === 'IP100') {
          let removedTrunkGroups = newTrunkGroups.filter(resp => resp.IsNew === true);
          newTrunkGroups = newTrunkGroups.filter(resp => resp.IsNew !== true);
          newTrunkGroupOptions = macdHelper.getIPTrunkGroupOptions(newTrunkGroups);
          removedTrunkGroups.map((rd,idx) => {
            if (rd.IPTrunkGroupID !== 0)
              this.RemoveTrunkGroupsFromDB(rd);
          });
          
        }

        this.setState({ OrderDetails: array, error: false, saving: false, RemoveItemId: 0, isConfirmationDialogOpen: false, IPTrunkGroups: newTrunkGroups, IPTrunkGroupOptions: newTrunkGroupOptions });

        let quantityRules = _.filter(macdHelper.QuantityValidationRules, resp => resp.PartNum === itmToRemove.PartNum);
        if (quantityRules !== null && quantityRules.length > 0)
        {
          //if removing a product where quantity validation is performed, we need to mimic the save updateItemFromModal task and update those items and refresh
          let otherUSOCs = quantityRules[0].DependencyList;
          let dependentOrderItems = _.filter(array, resp => _.indexOf(otherUSOCs, resp.PartNum) > -1);
          //Get all other call recording products and validate those
          if (dependentOrderItems !== null && dependentOrderItems.length > 0)
            this.ReviewAndRefreshQuantityDependencies(dependentOrderItems);
        }


      } else {
        this.setState({ error: true, saving: false, errorMsg: resp.message, RemoveItemId: 0, isConfirmationDialogOpen: false });
      }
    }).catch(message => {
      this.setState({ error: true, saving: false, errorMsg: message, RemoveItemId: 0, isConfirmationDialogOpen: false });
    });
  }

  DisplayOrderLocationErrors = () => {
    let htmlContents = [];
    let errors = [];
    if (this.state.OrderLocationErrors && this.state.OrderLocationErrors.length > 0) {
      this.state.OrderLocationErrors.map((ole) => {
        htmlContents.push(macdHelper.ErrorMessageDisplayItem(ole));
        errors.push(ole);
      });
    }
    if (errors && errors.length > 0)
      return (
        <div style={{ marginLeft: "15px", width: "80%", paddingBottom: "20px" }}>
          <h4 style={{ color: 'red' }}>Configuration Errors: </h4>
          <ul>
            {htmlContents}
          </ul>
        </div>
      )
    else return null;
  }

  fetchIPTrunks = async (TrunkItems = null, Details = null) => {
    let trunks = [];
    let apiPath = macdHelper.apiRetrieveIPTrunks(this.state.currentLocation);
    let data = await apiCalls.fetchData(apiPath).then((data) => data);
    if (data !== null) {
      data.map((record, idx) => {
        let existingTrunkEntry = {};
        existingTrunkEntry = { 
          IsNew: false, 
          ConcatId: record.CustomerProductId, 
          CustomerProductId: record.CustomerProductId, 
          DetailId: null, 
          IPTrunkGroupID: record.IPTrunkGroupID, 
          LineDescription: record.LineDescription,
          CallPathId: record.CallPathCustomerProductId
        };
        trunks.push(existingTrunkEntry); 
      });

      if (TrunkItems) {
        TrunkItems.map((record, idx) => {
          let newTrunkEntry = {};
          let trunkGroupDetailRecord = Details.filter(resp => resp.DetailId === record.DetailId)[0];
          if (trunkGroupDetailRecord)
          {
            let callPath = Details.filter(resp => (resp.PartNumber === 'IP101' || resp.PartNumber === 'IP102' || resp.PartNumber === 'IP103') && resp.ParentDetailId === trunkGroupDetailRecord.DetailId);
            if (callPath)
              callPath = callPath[0];
            newTrunkEntry = { 
              IsNew: true, 
              ConcatId: record.DetailId, 
              CustomerProductId: null, 
              DetailId: record.DetailId, 
              IPTrunkGroupID: record.IPTrunkGroupID, 
              LineDescription: record.Description,
              CallPathId: callPath ? callPath.DetailId : 0
            };
            trunks.push(newTrunkEntry); 
          }
        });
      }
      return trunks;
    } else {
      return [];
    }
  }


  UpdateTrunkGroups = async(TrunkGroupDetails, DetailId, IsAdd) => {
    let existingTrunks = this.state.IPTrunkGroups.slice();
    if (IsAdd)
    {
      let newTrunkEntry = { 
        IsNew: true, 
        ConcatId: DetailId, 
        CustomerProductId: 0, 
        DetailId: DetailId, 
        IPTrunkGroupID: TrunkGroupDetails.IPTrunkConfigId, 
        LineDescription: TrunkGroupDetails.TrunkDescription,
        CallPathId: 0
      };

      existingTrunks.push(newTrunkEntry);
    } else {
      let updatedTrunk = existingTrunks.filter(resp => resp.DetailId === DetailId)[0];
      let idx = _.indexOf(existingTrunks, updatedTrunk);
      existingTrunks[idx].LineDescription = TrunkGroupDetails.TrunkDescription
    }
    let sort = [{ field: "LineDescription", dir: "asc" }]
    let sortedSet = orderBy(existingTrunks, sort);
    let trunkOptions = macdHelper.getIPTrunkGroupOptions(sortedSet);
    this.setState({IPTrunkGroupOptions: trunkOptions, IPTrunkGroups: sortedSet});
  }

  RemoveTrunkGroups = async(DetailId) => {
    let existingTrunks = this.state.IPTrunkGroups.slice();
    let trunk = existingTrunks.filter(resp => resp.DetailId === DetailId);
    trunk = trunk[0];
    this.RemoveTrunkGroupsFromDB(trunk);
    existingTrunks = existingTrunks.filter(resp => resp.DetailId !== DetailId);

    let LineItems = this.state.OrderDetails;
    LineItems.map((li, idx) => {
      let configDetails = li.configurationDetails.slice();
      configDetails.map((cd, idxCD) => {
        if (cd.ParentDetailId === DetailId) {
          cd.ParentDetailId = 0;
          cd.IPTrunkGroupCustomerProductId = 0;
          cd.TrunkId = 0;
          configDetails[idxCD] = cd;
        }
      });
      LineItems[idx].configurationDetails = configDetails;
    });

    let sort = [{ field: "LineDescription", dir: "asc" }]
    let sortedSet = orderBy(existingTrunks, sort);
    let trunkOptions = macdHelper.getIPTrunkGroupOptions(sortedSet);
    this.setState({IPTrunkGroupOptions: trunkOptions, IPTrunkGroups: sortedSet, OrderDetails: LineItems});
  }

  RemoveTrunkGroupsFromDB = async(trunk) => {
    let apiPath = macdHelper.apiDiscardTrunkItem();
    let reqBody = {
      OrderId: this.state.OrderId,
      LocationId: this.state.MPOLocationId,
      DetailId: trunk.DetailId,
      IPTrunkConfigId: trunk.IPTrunkGroupID
    }
    apiCalls.post(apiPath, 'PATCH', JSON.stringify(reqBody)).then((data) => data);//.error((message) => console.error(message));
  }

  getIPTrunks = (product) => {
    //CallPathCustomerProductId
      let ipTrunks = this.state.IPTrunkGroups;
      let newIPTrunks = ipTrunks.slice();
      
      if (product.PartNum === 'IP101' || product.PartNum === 'IP102' || product.PartNum === 'IP103') {
        newIPTrunks = []; //clearing b/c you can't set a call path on an IP Trunk that already has one
        let configDetailIds = [];
        product.configurationDetails.map((cd) => {
          if (cd.ParentDetailId && parseInt(cd.ParentDetailId) > 0)
            configDetailIds.push(cd.DetailId);
          else if (cd.IPTrunkGroupCustomerProductId && parseInt(cd.IPTrunkGroupCustomerProductId) > 0)
            configDetailIds.push(cd.DetailId);
        });
        //filtering out IP Trunks that have been associated with other call plan types in the order from the modal.  
        //Also filtering out previously setup IP Trunks whose IP Trunk already has a call path plan.
        ipTrunks.map((ipt) => {
          if (ipt.CallPathId === 0)
            newIPTrunks.push(ipt);
          else if (_.indexOf(configDetailIds, ipt.CallPathId) > -1 && ipt.IsNew === true)
            newIPTrunks.push(ipt);
        });
      }
      return newIPTrunks;
  }
  
  getIPTrunkOptions = (product) => {
    let existingTrunks = this.getIPTrunks(product);
    let sort = [{ field: "LineDescription", dir: "asc" }]
    let sortedSet = orderBy(existingTrunks, sort);
    let ipTrunkOptions = macdHelper.getIPTrunkGroupOptions(sortedSet);
    return ipTrunkOptions;
  }

  render() {
    if (this.state.loading) {
      return (
        <div className="container full-card">
          <div className="macd card">
            <div className="card-header">
              <h2>Order Summary</h2>
            </div>
            <div>
              <DataLoadingSpinner className='load spinner' />
            </div>
          </div>
        </div>
      )
    } else if (this.state.submitting) {
      return (
        <div className="container full-card">
          <div className="macd card">
            <div className="card-header">
              <h2>Order Summary</h2>
            </div>
            <div>
              <SaveSpinner Message={this.state.SaveMessage} />
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="container full-card">
          <div className="macd card">
            <div className="card-header">
              <h2>Order Summary</h2>
              <h6 className="charges-h6"><b>This order may be subject to MRC and NRC charges. Please review charges before submitting the service change below.</b></h6>
            </div>
            <div style={{ display: this.state.error === true ? "block" : "none", color: "red" }}>
              <h4>{this.state.resultsMsg}</h4>
            </div>
            <div>
              {this.DisplayOrderLocationErrors()}
            </div>
            <div className="">
              {this.gridContent()}

            </div>
            {this.ServiceDateContent()}
            <div className="btns">
              <a onClick={() => { this.handleSubmitClick() }} className="btn" style={{ visibility: this.state.ReadOnly === false ? "visible" : "hidden" }}>Submit Order</a>
              <a onClick={() => { this.handleBackClick() }} style={{ visibility: this.state.ReadOnly === false ? "visible" : "hidden" }} className="btn">Back</a>
            </div>
          </div>
        </div>
      )
    }
  }
}

export default MacdReview;