import React, { Component } from 'react';
import format from 'string-format';
import { DataLoadingSpinner } from '../../../components/spinners';
import moment from 'moment';
import { DatePicker } from '@progress/kendo-dateinputs-react-wrapper';
import * as settings from '../../../scripts/settings';
import * as config from '../../../requests/config';
import * as apiCalls from '../../../requests/api_calls';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { orderBy } from '@progress/kendo-data-query';
import * as helper from '../../../scripts/helper';

export default class CallLogsFrame extends Component {
  constructor(props) {
    super(props);

    this.state = {
      LocationAccountNumber: '',
      logs: [],
      Sort: [],
      loading: false,
      call_category: "ALL",
      call_type: "1",
      status_type: "1",
      date_type: "1", // Today
      from_date: moment(Date.now()).startOf("day").format("YYYY-MM-DD"),
      to_date: moment(Date.now()).endOf("day").format("YYYY-MM-DD")
    }
    this.handleCallCategory = this.handleCallCategory.bind(this);
    this.handleCallType = this.handleCallType.bind(this);
    this.handleStatusType = this.handleStatusType.bind(this);
    this.handleDate = this.handleDate.bind(this);
    this.handleChangeFromDate = this.handleChangeFromDate.bind(this);
    this.handleChangeToDate = this.handleChangeToDate.bind(this);
  };

  componentDidMount() {
    if (this.props.isLocation) {
      this.fetchLocations()
    } else {
      this.fetchCallLogs(this.getApiUrl(), settings.noResultsMsg);
    }
  }

  getApiUrl() {
    let apiUrl = this.props.apiPath;
    if (this.props.isLocation) {
      apiUrl = format(apiUrl, this.state);
    }

    // let finalToDate = this.state.to_date + '%2023:59:59';
    // let newOptions = {
    //   call_type: this.state.call_type,
    //   status_type: this.state.status_type,
    //   call_category: this.state.call_category,
    //   from_date: this.state.from_date,
    //   to_date: finalToDate
    // };

    apiUrl += format('&CallType={call_type}&CallStatus={status_type}&CallCategory={call_category}&FromDate={from_date}&ToDate={to_date}', this.state);
    return apiUrl;
  }

  fetchCallLogs = async (apiPath) => {
    await this.setState({ loading: true });
    apiCalls.fetchData(apiPath).then((data) => {
      if (data !== null && data !== undefined) {
        let revisedData = this.revisedData(data);
        this.setState({ logs: revisedData, loading: false });

      } else this.setState({ logs: [], loading: false });
    }).catch((msg) => console.error(msg));
  }

  revisedData = (data) => {
    let revisedData = data.map((item, idx) => {
      item.Release_Time = new Date(item.Release_Time);
      item.Start_Time = new Date(item.Start_Time);
      return item;
    });
    return revisedData;
  }

  handleChangeFromDate = (event) => {
    let from = moment(event.sender._oldText, "M/D/YYYY");
    if (from.isValid()) {
      this.setState({ ...{ from_date: from.format("YYYY-MM-DD") } }, () => {
        let to = moment(this.state.to_date);
        if (to.isValid() && to.isSameOrAfter(from)) {
          this.setState({ ...{ logs: [], loading: true } }, this.componentDidMount);
        }
      });
    }
  }

  handleChangeToDate = (event) => {
    let to = moment(event.sender._oldText, "M/D/YYYY");
    if (to.isValid()) {
      this.setState({ ...{ to_date: to.format("YYYY-MM-DD") } }, () => {
        let from = moment(this.state.from_date);
        if (from.isValid() && from.isSameOrBefore(to)) {
          this.setState({ ...{ logs: [], loading: true } }, this.componentDidMount);
        }
      });
    }
  }

  handleDate = (event) => {
    switch (event.target.value) {
      case "1":
        this.setState({
          ...{
            logs: [], date_type: event.target.value, from_date: moment(Date.now()).startOf("day").format("YYYY-MM-DD"),
            to_date: moment(Date.now()).endOf("day").format("YYYY-MM-DD"), loading: true
          }
        }, this.componentDidMount);
        return;
      case "2":
        this.setState({
          ...{
            logs: [], date_type: event.target.value, from_date: moment(Date.now()).subtract(1, "days").startOf("day").format("YYYY-MM-DD"),
            to_date: moment(Date.now()).endOf("day").subtract(1, "days").format("YYYY-MM-DD"), loading: true
          }
        }, this.componentDidMount);
        return;
      case "3":
        this.setState({
          ...{
            logs: [], date_type: event.target.value, from_date: moment(Date.now()).subtract(7, "days").startOf("day").format("YYYY-MM-DD"),
            to_date: moment(Date.now()).endOf("day").format("YYYY-MM-DD"), loading: true
          }
        }, this.componentDidMount);
        return;
      case "4":
        this.setState({
          ...{
            logs: [], date_type: event.target.value, from_date: moment(Date.now()).subtract(30, "days").startOf("day").format("YYYY-MM-DD"),
            to_date: moment(Date.now()).endOf("day").format("YYYY-MM-DD"), loading: true
          }
        }, this.componentDidMount);
        return;
      case "5":
        this.setState({
          ...{
            logs: [], date_type: event.target.value, from_date: moment(Date.now()).subtract(90, "days").startOf("day").format("YYYY-MM-DD"),
            to_date: moment(Date.now()).endOf("day").format("YYYY-MM-DD"), loading: true
          }
        }, this.componentDidMount);
        return;
      case "6":
        this.setState({ ...{ logs: [], date_type: event.target.value, from_date: "From", to_date: "To" } });
        return;
    }
  }

  handleCallCategory = (event) => {
    this.setState({ ...{ logs: [], call_category: event.target.value, loading: true } }, this.componentDidMount);
  }

  handleCallType = (event) => {
    this.setState({ ...{ logs: [], call_type: event.target.value, loading: true } }, this.componentDidMount);
  }

  handleStatusType = (event) => {
    this.setState({ ...{ logs: [], status_type: event.target.value, loading: true } }, this.componentDidMount);
  }

  searchStatus = () => {
    if (this.state.loading) {
      return <DataLoadingSpinner className={'load spinner relative'} />
    } else if (this.state.logs.length < 1) {
      return (
        <h5 className='text-center' style={{ padding: '30px', textAlign: 'center' }} >
          No results for the filters selected.
        </h5>
      )
    } else return null
  }

  exportBtn = () => {
    if (this.state.logs.length > 0) {
      return (
        <div id="div-calls-csv" className='pull-right'>
          <form ref='downloadCsv' target="iFrameSave" action={config.baseApiUrl + 'usr/GetIpTrunkingCallLogsCSV'} method="POST">
            <input type="hidden" name="LocationAccountNumber" value={this.props.isLocation ? this.state.LocationAccountNumber : this.props.accountNumber}></input>
            <input type="hidden" name="TrunkID" value={this.props.trunkId} />
            <input type="hidden" name="CallType" value={this.state.call_type}></input>
            <input type="hidden" name="CallStatus" value={this.state.status_type}></input>
            <input type="hidden" name="CallCategory" value={this.state.call_category}></input>
            <input type="hidden" name="FromDate" value={this.state.from_date}></input>
            <input type="hidden" name="ToDate" value={this.state.to_date}></input>
            <input type="hidden" name="access_token" value={config.storedAccessToken()} className='hidden'></input>
            <input type="hidden" name="x_policy" value={config.storedXPolicy()} className='hidden'></input>
          </form>
          <iframe name="iFrameSave" height="0" width="0" className='hidden pull-right' title='exportCSV'></iframe>
          <a tabIndex="0" className='no-btn' ref='exportBtn' onClick={(e) => this.exportCallLogs()} >Export Call Logs</a>
        </div>
      )
    } else null;
  }

  exportCallLogs = () => {
    this.refs.downloadCsv.submit();
    this.refs.exportBtn.text = 'Downloading...';
    setTimeout(() => {
      this.refs.exportBtn.text = 'Export Call Logs';
    }, 2000);
  }

  sortListings = (sort, logs = null) => {
    let returnObj = [];
    if (this.state.logs) {
      if (logs == null) logs = this.state.logs.slice();
      let sortedSet = orderBy(logs, sort);
      returnObj = sortedSet;
      if (sort.length) {
        returnObj = helper.blanksAfterZ(sortedSet, sort);
      }
    }
    return returnObj;
  }

  sortChange = async (event) => {
    await this.setState({
      logs: this.sortListings(event.sort),
      Sort: event.sort
    });
  }

  gridContent = () => {
    if (this.state.loading || this.state.logs.length === 0) {
      return (
        <div style={{
          display: 'block',
          height: '100px',
          overflow: 'hidden',
          width: '100%',
        }}>{this.searchStatus()}</div>
      )
    } else {
      if (this.state.logs && this.state.logs.length > 0) {
        let colWidth = '150px';
        return (
          <Grid
            style={{
              width: '100%',
            }}
            data={this.state.logs}
            scrollable='scrollable'
            sortable={{ allowUnsort: true, mode: 'single' }}
            sort={this.state.Sort}
            onSortChange={this.sortChange}
          >
            <Column width='100px' field='Direction' title='Direction' />
            <Column width='100px' field='TrunkID' title='TrunkID' />
            <Column width={colWidth} format="{0:M/d/yyyy hh:mm:ss a}" field='Start_Time' title='Start Time' />
            <Column width={colWidth} format="{0:M/d/yyyy hh:mm:ss a}" field='Release_Time' title='Release Time' />
            <Column width={colWidth} field='Length' title='Duration (HH:mm:ss)' />
            <Column width={colWidth} field='OriginationSource' title='From' />
            <Column width={colWidth} field='OriginationDestination' title='To' />
            <Column width={colWidth} field='OriginationRemoteIP' title='From IP' />
            <Column width={colWidth} field='OriginationMOS' title='OriginationMOS' />
            <Column width={colWidth} field='TerminationMOS' title='TerminationMOS' />
            <Column width={colWidth} field='Category' title='Category' />
            <Column title=' ' field='filler' sortable={false} />
          </Grid>
        )
      }
    }
  }

  locationDropDown = () => {
    if (this.props.isLocation) {
      return (
        <div className="form-group">
          <label style={{ color: '#3a4b5f' }}><b>Location:</b></label>
          <select onChange={this.changeLocation}
            style={{
              display: 'inline',
              maxWidth: '320px',
              marginLeft: '10px',
            }}
            value={this.state.LocationAccountNumber} className="form-control">
            {this.state.locationOptions}
          </select>
        </div>
      )
    } else return null;
  }

  fetchLocations = async (apiPath = null) => {
    await this.setState({ loading: true });
    if (!apiPath) apiPath = 'admin/GetIpTrunkingLocationList';
    apiCalls.fetchData(apiPath).then((data) => {
      if (data) {
        let locationOptions = data.map((location, index) => {
          return this.locationOption(location, index);
        });
        if (!sessionStorage.ipTrunkLocationNum) {
          this.setState({ locationOptions: locationOptions, LocationAccountNumber: data[0].LocationAccountNumber, LocationName: data[0].LocationName });
        } else {
          this.setState({ locationOptions: locationOptions, LocationAccountNumber: sessionStorage.ipTrunkLocationNum, LocationName: data[0].LocationName });
        }
        if (this.state.LocationAccountNumber !== undefined || this.state.LocationAccountNumber !== null) {
          this.fetchCallLogs(this.getApiUrl(), settings.noResultsMsg);
        }
      } else if (data === null) {
        this.setState({ locations: [], resultsMsg: settings.noResultsMsg });
      };
    });
  }

  locationOption = (location, index) => {
    return (
      <option key={index} value={location.LocationAccountNumber}>
        {location.LocationName}
      </option>
    )
  }

  changeLocation = (event) => {
    let { value } = event.target;
    let getLocationName = (acctNum) => {
      return this.state.locationOptions.map(itm => itm.props).filter(res => res.value === acctNum)[0].children;
    }
    if (value && value !== '') {
      sessionStorage.setItem('ipTrunkLocationNum', value);
    }
    this.setState({
      LocationAccountNumber: value,
      LocationName: event.target.selectedOptions ? event.target.selectedOptions[0].text : getLocationName(value)
    }, this.componentDidMount);
  }

  callLogFilter = (htmlSelect) => {
    return (
      <div className="filter" style={{
        paddingRight: '4px',
        float: 'left',
        width: '33.3333%',
      }
      }>
        {htmlSelect}
      </div >
    )
  }

  render() {
    return (
      <div className="full-card container">
        <div className="ip-call-logs card">
          <div className="card-header">
            <div className="col-md-8 row">
              <h1 className="move">
                {this.props.cardTitle}
                <small> {this.state.loading ? null : this.state.logs.length} </small>
              </h1>
            </div>
            <div className="col-md-4 pull-right export">
              {this.exportBtn()}
            </div>
            <div className="call-log-actions card-content col-md-12">
              {this.locationDropDown()}
              <div className="call-log-action">
                {this.callLogFilter(<select value="1" className="form-control" value={this.state.call_type} onChange={this.handleCallType}>
                  <option value="1">All Calls</option>
                  <option value="2">Incoming</option>
                  <option value="3">Outgoing</option>
                </select>)}
                {this.callLogFilter(<select value="1" className="form-control" value={this.state.status_type} onChange={this.handleStatusType}>
                  <option value="1">All Calls</option>
                  <option value="2">Connected</option>
                  <option value="3">No Answer</option>
                  <option value="4">Incomplete</option>
                  <option value="5">Forwarded</option>
                </select>)}
                {this.callLogFilter(<select className="call-log-dates form-control" value={this.state.date_type} onChange={this.handleDate}>
                  <option value="1">Today</option>
                  <option value="2">Yesterday</option>
                  <option value="3">Last 7 Days</option>
                  <option value="4">Last 30 Days</option>
                  <option value="5">Last 90 Days</option>
                  <option value="6">Custom</option>
                </select>)}
                {
                  (this.state.date_type === "6") ?
                    (<div className="custom-dates">
                      <div className="date">
                        <DatePicker value={this.state.from_date} change={this.handleChangeFromDate} />
                      </div>
                      <div className="date">
                        <DatePicker value={this.state.to_date} change={this.handleChangeToDate} />
                      </div>
                    </div>)
                    : ""
                }
              </div>
            </div>
            <div className='card-content'>
              {this.gridContent()}
            </div>
          </div>
        </div>
      </div>
    );
  }
};