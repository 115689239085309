import React, { Component } from 'react';
import { DataLoadingSpinner } from './../../../components/spinners';
import { Modal } from 'react-bootstrap';
import { Button } from '@progress/kendo-buttons-react-wrapper';

export default class IPTrunkReviewModal extends Component {
  modalTitle = () => {
    if (this.props.reviewSub) {
      return <Modal.Title>Reviewing Submission...</Modal.Title>
    }
    if (this.props.successSub) {
      return <Modal.Title style={{ color: 'green' }}>Success!</Modal.Title>
    }
    if (this.props.failedSub) {
      return <Modal.Title style={{ color: 'red' }}>Unsuccessful</Modal.Title>
    }
  }

  modalBody = () => {
    if (this.props.reviewSub) {
      return (
        <div className='PROCESSING' style={{ overflowWrap: "break-word" }}>
          <DataLoadingSpinner className='load saving spinner' />
        </div>
      )
    }
    if (this.props.failedSub || this.props.successSub) {
      let msg = this.props.failedSub ? 'There was an error in your submission attempt, please re-evaluate your input data.' : 'Your submission was successful. Thank you.'
      return (
        <div className='PROCESSING' style={{ overflowWrap: "break-word" }}>
          <div style={{ textAlign: 'center' }}>{msg}</div>
        </div>
      )
    }
  }
  render() {
    return (
      <Modal show={this.props.show}>
        <Modal.Header>
          {this.modalTitle()}
        </Modal.Header>
        <Modal.Body style={this.props.reviewSub ? { minHeight: '100px' } : { maxHeight: "300px" }}>
          {this.modalBody()}
        </Modal.Body>
        {
          this.props.reviewSub ? null : <Modal.Footer>
            <hr />
            <div className="btns">
              <span><Button className="btn" click={this.props.close}>Close</Button></span>
            </div>
          </Modal.Footer>
        }
      </Modal >
    )
  }
};