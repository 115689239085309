import React, { Component } from 'react';
import * as apiCalls from '../requests/api_calls';
import WrappedCell from '../components/wordWrappedCell'
import { Modal } from 'react-bootstrap';
import { Button } from '@progress/kendo-buttons-react-wrapper';
import _ from 'lodash';
import { Grid, GridColumn as Column, GridCell } from '@progress/kendo-react-grid';
import { orderBy } from '@progress/kendo-data-query';
import { DataLoadingSpinner } from '../components/spinners';
import { connect } from 'react-redux';
import { headerToolTip } from '../components/ellipsisHeaderTooltip';
import { filterBy } from '@progress/kendo-data-query';
//
import AddEditContact from './profile/modals/addEditUserContactModal';
//

export default class UserContactWidget extends Component {
    constructor(props) {
        super(props);

        const handleDeleteItem = this.handleDeleteItem.bind(this);
        const updateFromModal = this.updateFromModal.bind(this);
        const SpeedDialCodes = this.provideSpeedDialCodes.bind(this);

        class ContactEditButton extends GridCell {
            constructor(props) {
                super(props);
            }
            render() {
                //let { Name, TN, SpeedDialCode, CanEdit } = this.props.dataItem;
                return (
                    <td>
                        <AddEditContact AddEditMode='Edit' ContactDetails={this.props.dataItem} UpdateParentFromModal={updateFromModal} SpeedDialCodes={SpeedDialCodes()}
                        />
                    </td>

                );
            }
        }

        class ContactDeleteButton extends GridCell {
            constructor(props) {
                super(props);
            }
            render() {
                //if (this.props.dataItem.CanEdit === true)
                return (
                    <td>
                        <a className="btn" onClick={(e) => { handleDeleteItem(this.props.dataItem) }}
                            style={{ minWidth: "0px" }}
                        ><svg className="bi bi-trash-fill" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" d="M2.5 1a1 1 0 00-1 1v1a1 1 0 001 1H3v9a2 2 0 002 2h6a2 2 0 002-2V4h.5a1 1 0 001-1V2a1 1 0 00-1-1H10a1 1 0 00-1-1H7a1 1 0 00-1 1H2.5zm3 4a.5.5 0 01.5.5v7a.5.5 0 01-1 0v-7a.5.5 0 01.5-.5zM8 5a.5.5 0 01.5.5v7a.5.5 0 01-1 0v-7A.5.5 0 018 5zm3 .5a.5.5 0 00-1 0v7a.5.5 0 001 0v-7z" clipRule="evenodd" />
                            </svg></a>
                    </td>
                );
                // else
                //   return (
                //     <td>

                //     </td>
                //   )
            }
        }
        this.state = {
            Contacts: [],
            ContactsCopy: [],
            SpeedDialCodes: [],
            loading: false,
            showDeletePrompt: false,
            Sort: [{ field: "Name" }],
            //gridHeight: (window.innerHeight - 180),
            searchTerm: ''
        }
        this.sortChange = this.sortChange.bind(this);
        this.searchContacts = this.searchContacts.bind(this);
        this.handleCancelDelete = this.handleCancelDelete.bind(this);

        this.ContactEditButton = ContactEditButton;
        this.ContactDeleteButton = ContactDeleteButton;
    }

    provideSpeedDialCodes = () => {
        if (this.state.SpeedDialCodes && this.state.SpeedDialCodes.length > 0) {
            const codes = JSON.parse(JSON.stringify(this.state.SpeedDialCodes));
            return codes;
        }
    }

    componentDidMount() {
        this.fetchContactDetails();
    }

    fetchContactDetails = async () => {
        await this.setState({ loading: true });
        let { SelectedUserId } = sessionStorage;
        //this.fetchSpeedDial(SelectedUserId);
        let path = `ContactBook/GetUserContactBook?UserId=${SelectedUserId}`;
        await apiCalls.fetchData(path).then(data => {
            if (data !== null) {
                let codeAdds = [];
                data.map(contact => {
                    if (contact && contact.SpeedDialCode) {
                        codeAdds.push(contact.SpeedDialCode);
                    }
                })
                this.fetchSpeedDial(SelectedUserId, codeAdds);
                this.setState({
                    Contacts: data, ContactsCopy: data, loading: false,
                });
            } else {
                this.setState({ Contacts: [], ContactsCopy: [], loading: false });
            }
        }).catch(err => {
            console.error(err);
            this.setState({ Contacts: [], ContactsCopy: [], loading: false });
        });
    }

    fetchSpeedDial = async (userId, adds) => {
        let path = `ContactBook/GetAvailableSpeedDialCodes?UserId=${userId}`;
        await apiCalls.fetchData(path).then(data => {
            if (data !== null) {
                this.setState({ SpeedDialCodes: data });
            } else {
                this.setState({ SpeedDialCodes: [] });
            }
        }).catch(err => console.error(err));
    }

    sortChange = (event) => {
        this.setState({
            ContactsCopy: orderBy(this.state.ContactsCopy, event.sort),
            Sort: event.sort
        });
    }

    searchContacts = (event) => {
        let searchText = '';
        let fullDetails = this.state.Contacts;
        if (event && event.target && event.target.value && event.target.value.trim() !== '') {
            searchText = event.target.value;
            let contacts = filterBy(fullDetails, {
                logic: 'or',
                filters: [
                    { field: 'Name', operator: 'contains', value: searchText, ignoreCase: true },
                    { field: 'ServiceNumber', operator: 'contains', value: searchText, ignoreCase: true }
                ]
            });

            this.setState({ ContactsCopy: contacts, searchTerm: searchText })
        }
        else {
            this.setState({ ContactsCopy: fullDetails, searchTerm: '' });
        }
    }

    handleDeleteItem = (productItem) => {
        this.setState({ showDeletePrompt: true, selectedDeleteItem: productItem });
    }

    handleCancelDelete = () => {
        this.setState({ showDeletePrompt: false, selectedDeleteItem: null });
    }

    handleConfirmedDelete = () => {
        this.setState({ saving: true });
        let contact = this.state.selectedDeleteItem;
        let apiPath = 'ContactBook/RemoveContactInUserContactBook';
        let verb = 'POST';

        apiCalls.post(apiPath, verb, JSON.stringify(contact)).then((data) => {
            if (data.ok) {
                let Contacts = this.state.Contacts.slice();
                let ContactsCopy = this.state.ContactsCopy.slice();
                let SpeedDialList = this.state.SpeedDialCodes.slice();
                if (contact.OldSpeedDialCode !== '') {
                    SpeedDialList.push(contact.OldSpeedDialCode);
                    SpeedDialList.sort();
                }
                let newContacts = Contacts.filter(resp => resp.Name !== contact.Name);
                let newContactsCopy = ContactsCopy.filter(resp => resp.Name !== contact.Name);
                this.setState({
                    saving: false,
                    savingShowSuccess: true,
                    saveResultsMessage: 'Contact Removed Successfully!',
                    Contacts: newContacts,
                    ContactsCopy: newContactsCopy,
                    SpeedDialCodes: SpeedDialList,
                    showDeletePrompt: false
                });
                this.fetchContactDetails();
            } else {
                this.setState({ savingShowError: true, saveResultsMessage: data, saving: false, showDeletePrompt: false })
            }
        }).catch(err => {
            this.setState({
                saving: false,
                savingShowError: true,
                saveResultsMessage: 'An error occurred while attempting to remove contact from contact directory!',
                showDeletePrompt: false
            });
        });
    }

    updateFromModal = (contact, isAdd) => {
        let Contacts = this.state.Contacts.slice();
        let ContactsCopy = this.state.ContactsCopy.slice();
        let speedDialValues = this.state.SpeedDialCodes.slice();
        if (!isAdd) {
            let oldContact = Contacts.filter(resp => resp.OldName === contact.OldName)[0];
            let oldContactCopy = ContactsCopy.filter(resp => resp.OldName === contact.OldName)[0];
            let idxOldContact = _.indexOf(Contacts, oldContact);
            let idxOldContactCopy = _.indexOf(ContactsCopy, oldContactCopy);
            Contacts[idxOldContact] = contact;
            ContactsCopy[idxOldContactCopy] = contact;


            if (oldContact.SpeedDialCode !== '' && contact.SpeedDialCode !== oldContact.SpeedDialCode)//ADD old speed dial code back
            {
                speedDialValues.push(oldContact.SpeedDialCode);
                speedDialValues.sort();
            }
            if (contact.SpeedDialCode !== '' && contact.SpeedDialCode !== oldContact.SpeedDialCode) //REMOVE new speed dial code
            {
                speedDialValues = _.filter(speedDialValues, resp => resp.SpeedDialCode !== contact.SpeedDialCode);
            }

        }
        else {
            Contacts.push(contact);
            ContactsCopy.push(contact);
            if (contact.SpeedDialCode !== '')
                speedDialValues = _.filter(speedDialValues, resp => resp.SpeedDialCode !== contact.SpeedDialCode);
        }



        this.setState({ Contacts: Contacts, ContactsCopy: ContactsCopy, SpeedDialCodes: speedDialValues });
    }

    uploadBtn = () => {
        return (
            <div id="contact-upload" className='pull-right'>
                <input
                    type="file"
                    id="file"
                    accept=".csv"
                    ref='fileUploader'
                    style={{ display: "none" }}
                    onChange={this.addAttachment}
                    onClick={(event) => {
                        event.target.value = null;
                    }}
                />
                <button className="orange btn" style={{ "position": "relative", "left": "-65px", "top": "7px", "fontSize": "10px", "margin": "10px" }} name="btnuploadBtn" onClick={(e) => this.openAttachments()}>Upload Contacts</button>
            </div>)
    }

    addAttachment = async (event) => {
        let file = event.target.files[0];
        let reader = new FileReader();
        reader.addEventListener('loadend', () => {
            let body = reader.result.split(',')[1];
            let name = file.name;
            this.createAttachment(body, name);
        }, false);
        if (file) {
            reader.readAsDataURL(file);
        }
    }
    createAttachment = async (body, name, ticketId) => {
        let apiPath = 'ContactBook/UploadUserLevelContacts';
        let attachmentDTO = {
            TicketId: this.state.TicketId,
            AttachmentBody: body,
            FileName: name,
            UserId: sessionStorage.SelectedUserId
        };
        await this.setState({ attachmentSubmitted: true });
        let response = await apiCalls.post(apiPath, 'POST', JSON.stringify(attachmentDTO)).then(res => res).catch(err => console.error(err));
        if (response.ok) {
            await this.setState({
                Submission: true,
                submitError: false,
            });
            this.fetchContactDetails();
        } else {
            await this.setState({
                Submission: true,
                submitError: true,
            });
        }
    }

    openAttachments = () => {
        this.refs.fileUploader.click();
    }

    uploadBtn = () => {
        return (
            <div id="contact-upload" className='pull-right'>
                <input
                    type="file"
                    id="file"
                    accept=".csv"
                    ref='fileUploader'
                    style={{ display: "none" }}
                    onChange={this.addAttachment}
                    onClick={(event) => {
                        event.target.value = null;
                    }}
                />
                <button className="orange btn" style={{ "position": "relative", "left": "-65px", "top": "7px", "fontSize": "10px", "margin": "10px" }} name="btnuploadBtn" onClick={(e) => this.openAttachments()}>Upload Contacts</button>

            </div>)
    }

    addAttachment = async (event) => {
        let file = event.target.files[0];
        let reader = new FileReader();
        reader.addEventListener('loadend', () => {
            let body = reader.result.split(',')[1];
            let name = file.name;
            this.createAttachment(body, name);
        }, false);
        if (file) {
            reader.readAsDataURL(file);
        }
    }
    createAttachment = async (body, name, ticketId) => {
        let apiPath = 'ContactBook/UploadUserLevelContacts';
        let attachmentDTO = {
            TicketId: this.state.TicketId,
            AttachmentBody: body,
            FileName: name,
            UserId: sessionStorage.SelectedUserId
        };
        await this.setState({ attachmentSubmitted: true });
        let response = await apiCalls.post(apiPath, 'POST', JSON.stringify(attachmentDTO)).then(res => res).catch(err => console.error(err));
        if (response.ok) {
            await this.setState({
                Submission: true,
                submitError: false,
            });
            this.fetchContactDetails();
        } else {
            await this.setState({
                Submission: true,
                submitError: true,
            });
        }
    }

    openAttachments = () => {
        this.refs.fileUploader.click();
    }

    gridContent = () => {
        if (this.state.loading) {
            return <DataLoadingSpinner className='load spinner' />
        } else {
            return (
                <Grid
                    style={{ height: this.state.gridHeight }}
                    data={this.state.ContactsCopy}
                    sortable={{
                        allowUnsort: true,
                        mode: 'single'
                    }}
                    sort={this.state.sort}
                    onSortChange={this.sortChange}
                    scrollable={'scrollable'}
                >
                    <Column field='Name' title={'Name'} cell={WrappedCell} width="150px" />
                    <Column field='TN' title={headerToolTip('Phone Number')} width="140px" />
                    <Column title="" cell={this.ContactEditButton} sortable={false} filterable={false} width="100px" />
                    <Column title="" cell={this.ContactDeleteButton} sortable={false} filterable={false} width="100px" />
                    <Column field="spacer" title=" " sortable={false} filterable={false} />
                </Grid>
            )
        }
    };

    render() {
        let codes = this.provideSpeedDialCodes();
        let displayTitle = () => {
            if (sessionStorage.serviceProviderName === "Vyve_Broadband") {
                if (window.location.href.indexOf("mydigitalservices") != -1 || window.location.href.indexOf("driveuc") != -1) {
                    return "Personal Directory";
                } else {
                    return "Contact Directory";
                }
            } else {
                return "Contact Directory";
            }
        }
        return (
            <div className="enterprise contact card">
                <div className="card-header">
                    <div className='card-actions'><div className='move'><svg width='12' height='12' viewBox='0 0 23 23'><path d='m512 256c0 5-2 9-5 13l-74 73c-3 4-7 5-12 5-5 0-10-1-13-5-4-4-6-8-6-13l0-36-109 0 0 109 36 0c5 0 9 2 13 6 4 3 5 8 5 13 0 5-1 9-5 12l-73 74c-4 3-8 5-13 5-5 0-9-2-13-5l-73-74c-4-3-5-7-5-12 0-5 1-10 5-13 4-4 8-6 13-6l36 0 0-109-109 0 0 36c0 5-2 9-6 13-3 4-8 5-13 5-5 0-9-1-12-5l-74-73c-3-4-5-8-5-13 0-5 2-9 5-13l74-73c3-4 7-5 12-5 5 0 10 1 13 5 4 4 6 8 6 13l0 36 109 0 0-109-36 0c-5 0-9-2-13-6-4-3-5-8-5-13 0-5 1-9 5-12l73-74c4-3 8-5 13-5 5 0 9 2 13 5l73 74c4 3 5 7 5 12 0 5-1 10-5 13-4 4-8 6-13 6l-36 0 0 109 109 0 0-36c0-5 2-9 6-13 3-4 8-5 13-5 5 0 9 1 12 5l74 73c3 4 5 8 5 13z' transform='scale(0.046875 0.046875)'></path></svg></div><div className='expand'><svg width='12' height='12' viewBox='0 0 23 23'><path d='m26 344l-26 158c0 2 1 5 3 7c0 0 0 0 0 0c2 2 5 3 7 3l158-27c3 0 6-3 7-6c1-3 0-7-3-9l-30-31l82-82c4-4 4-9 0-13l-57-57c-3-3-9-3-12 0l-83 83l-31-31c-2-2-5-3-9-2c-3 1-5 4-6 7z m460-176l26-158c0-2-1-5-3-7c0 0 0 0 0 0c-2-2-5-3-7-3l-158 27c-3 0-6 3-7 6c-1 3 0 7 3 9l30 31l-82 82c-4 4-4 9 0 13l57 57c3 3 9 3 12 0l83-83l31 31c2 2 5 3 9 2c3-1 5-4 6-7z' transform='scale(0.046875 0.046875)'></path></svg></div>
                        {<AddEditContact AddEditMode='Add' UpdateParentFromModal={this.updateFromModal} SpeedDialCodes={codes} />}
                    </div>
                    <div>
                        {this.uploadBtn()}
                    </div>
                    {/* <h2 className="move name">{this.getName()}</h2> */}
                    <h2 className="move name">{displayTitle()} <small>{this.state.ContactsCopy.length}</small></h2>
                    <hr style={{ margin: '10px 0 0 0' }} />
                </div>
                <div className="user-info card-content">
                    <div className="card-content">
                        {this.gridContent()}
                        <Modal dialogClassName="confirm-cancel-macd" show={this.state.showDeletePrompt} onHide={this.handleCancelDelete}>
                            <Modal.Header closeButton>
                                <Modal.Title>Remove Contact?</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div><p><span style={{ fontSize: "medium" }}>Are you sure you wish to remove this contact from User Directory?</span></p></div>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button click={this.handleConfirmedDelete}>Yes</Button>
                                <Button click={this.handleCancelDelete}>No</Button>
                            </Modal.Footer>
                        </Modal>
                        <div className="actions"></div>
                    </div>
                </div>
            </div>
        )
    }
};