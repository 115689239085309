import React, { Component } from 'react';
import { ComboBox } from '@progress/kendo-react-dropdowns';
import { filterBy } from '@progress/kendo-data-query';

const spanOptions = (props) => {
  if (props && props.settings.Lk) {
    return props.settings.spanOptions.map((portNum, i) => <option key={i}>{portNum}</option>)
  } else {
    return <option>&nbsp;</option>
  }
}

const disableIfTnMissing = (props) => {
  let tn = props.settings.PhoneNumber || props.settings.Extension;
  if (tn == '' || tn == undefined || tn == null) return true;
}

const disableBlfForFirstPort = (props, propName) => {
  if (props.portNum == 1 && propName === 'Blf') return true;
}

const firstPortIsSca = (props) => {
  let matches = props.settings.portsConfigured.filter(port => port.PortNumber == 1);
  if (matches.length) return matches[0].Sca;
}

const disableBlfIfFirstLineisSca = (props, propName) => {
  return props.portNum > 1 && propName === 'Blf' && firstPortIsSca(props);
}

const isDisabled = (props, propName = null) => {
  if (props) {
    return (
      props.settings.disabled || disableIfTnMissing(props) ||
      disableBlfForFirstPort(props, propName) || disableBlfIfFirstLineisSca(props, propName) ||
      disableIfNotAvailableForModel(props, propName) || isScaAndNotExecutiveLine(props, propName)
    )
  }
}

const disableIfNotAvailableForModel = (props, propName) => {
  let modelConfig = props.settings.selectedModelConfigs;
  if (propName === 'Mwi' && modelConfig && !modelConfig.IsMWIAvailable) return true;
}

const isScaAndNotExecutiveLine = (props, propName) => {
  // Executive Extension Seat or Enterprise Select Executive
  if (propName == 'Sca') {
    let ticker = props.settings.lineTypeTicker;
    return ticker != 'MREPUPGEAA' && ticker != 'MREPUPGRAA';
  }
}

class TnComboBox extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: props.tnNumbers,
      filter: { logic: "and", filters: [] },
      value: props.selectedTn,
    }
  }
  
  componentWillReceiveProps = (props) => {
    let selectedValue = this.state.value;
    if (props.selectedTn.TN != this.state.value.TN)
      selectedValue = props.selectedTn;
    this.setState({value: selectedValue, data: props.tnNumbers});
  }

  filterData = (filter) => {
    return filterBy(this.state.data, filter);
  }

  handleFilterChange = async (event) => {
    await this.setState({
      filter: event.filter
    });
  }

  handleChange = async (event) => {
    let newTn = event.target.value ? event.target.value : {TN: '', TnAndLine: '-Select-'};
    await this.setState({ value: newTn })
    this.props.onChangeCombo(event, this.props.comboPortNum, 'tn');
  }

  content = () => {
    return ( 
      <ComboBox
        disabled={this.props.disabled}
        data={this.filterData(this.state.filter)}
        className='form-control'
        onChange={this.handleChange}
        textField="TnAndLine"
        dataItemKey="TN"
        style={{ width: '260px' }}
        value={this.state.value}
        filterable
        onFilterChange={this.handleFilterChange}
      />
    )
  }

  render() {
    return this.content();
  }
}

const portSettings = (props) => {
  return (
    <tr>
      <td>{props.portNum}</td>
      <td>
        <TnComboBox
          tnNumbers={tnOptions(props)}
          disabled={props.settings.disabled}
          selectedTn={props.settings.selectedTn}
          comboPortNum={props.portNum}
          onChangeCombo={(e) => {
            props.settings.setStateCallback(e, props.portNum, 'tn');
          }}
        />
      </td>

      <td>
        <input type="text"
          className="form-control"
          style={{ width: '120px', resize: 'none' }}
          onChange={props.settings.setStateCallback}
          data-portnum={props.portNum}
          data-propname={'DisplayName'}
          value={props.settings.DisplayName || ''}
          disabled={isDisabled(props)} />
      </td>

      {portSettingCheckBoxes(props)}

      <td>
        <select // line span
          className='form-control'
          onChange={props.settings.setStateCallback}
          data-portnum={props.portNum}
          data-propname={'Span'}
          value={props.settings.Span || ''}
          disabled={isDisabled(props) || !props.settings.Lk}
          style={{ width: '50px' }}
        >
          {spanOptions(props)}
        </select>
      </td>
    </tr>
  )
}

const tnOptions = (props) => {
  if (props.settings) {
    let returnVals = props.settings.availableTns.slice().map(tn => {
      return {
        TnAndLine: (tn.ServiceNumber || tn.Extension) + ' (' + tn.LineName + ')',
        TN: (tn.ServiceNumber || tn.Extension)
      }
    });
    returnVals.unshift({TnAndLine: '-Select-', TN: '' });
    return returnVals;
  } else return [];
}

const portSettingCheckBoxes = (props) => {
  return ['Blf', 'Sca', 'Mwi', 'Lk', 'Cc'].map((propName, index) => {
    let isDisabledVal = isDisabled(props, propName);
    let tooltipText = "";
    if (propName === 'Sca' && isDisabledVal)
      tooltipText = "This user is not eligible for Shared Call Appearance";
    else if (propName === "Blf" && isDisabledVal)
      tooltipText = "This user/port is not eligible for Busy Lamp";
    else ""
    return (
      <td key={index} title={tooltipText} style={{width:'60px'}}>
        <input
          type="checkbox"
          checked={props.settings[propName]}
          onChange={props.settings.setStateCallback}
          data-portnum={props.portNum}
          data-propname={propName}
          disabled={isDisabledVal}
        />
      </td>
    )
  })
}

export default portSettings;