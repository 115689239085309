import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { Button } from '@progress/kendo-buttons-react-wrapper';
import * as apiCalls from './../../../requests/api_calls';
import * as help from'./../locations_helpers';
import SaveStatus from './../../../components/save_status';
import { DataLoadingSpinner } from'./../../../components/spinners';

class GroupFqdnModal extends Component {

  constructor(props) {
    super(props);
    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.fetchFqdns = this.fetchFqdns.bind(this);
    this.fqdnsOptions = this.fqdnsOptions.bind(this);
    this.fqdnsOption = this.fqdnsOption.bind(this);
    this.state = { loading: true, showModal: false, fqdns: []};
  }

  componentDidMount() {
  }

  fetchFqdns = () => {
    let params = '?locationAccountNumber=' + this.props.locationAccNum;
    apiCalls.fetchData('admin/GetFqdns' + params).then((data) => {
      if (data !== null) {
        let fqdnList = [];
        fqdnList.push('')
        data.unshift(fqdnList).sort();
        this.setState({fqdns: data});
      } else if (data === null) {
        // Need to send user instruction on how to create FQDN options
      }
    });
  }

  fetchDeviceLocation = () => {
    let params = '?locationAccountNumber=' + this.props.locationAccNum;
    apiCalls.fetchData('admin/GetDeviceLocationProperties' + params).then((data) => {
      if (data !== null) {
        //need to pull down all properties because the save is otherwise overriding the save in the VLAN modal
        setTimeout(() => this.setState({
          loading: false,
          currentFqdn: data.FQDN, 
          newFqdn: data.FQDN,
          EncryptedAudio: data.EncryptedAudio,
          TransportProtocol: data.TransportProtocol,
          useTcp: data.TransportProtocol === 'TCP', 
          VlanId: data.VlanId || '',
          useVlanId: data.VlanId !== ('' || null)
        }), help.hideSpinnerDelay);
      }
    });
  }

  handleClose() {
    this.setState({ showModal: false, statusMessage: null });
  }

  handleShow() {
    this.setState({ loading: true, showModal: true });
    this.fetchFqdns();
    this.fetchDeviceLocation();
  }

  fqdnsOptions = () => this.state.fqdns.map((fqdn, index) => this.fqdnsOption(fqdn, index));

  fqdnsOption = (fqdn, index) => <option key={index} value={fqdn}>{fqdn}</option>

  setNewFqdn = (e) => this.setState({newFqdn: e.target.value});

  saveStatus = () => {
    if (this.state.showErrorStatus) {
      return <span className='text-danger'>Error updating Group FQDN.  Please try again.</span>
    } else return null;
  }

  handleUpdateSuccess = () => {
    let currentFqdn = this.state.newFqdn;
    setTimeout(() => {this.setState({
      currentFqdn: currentFqdn,
      saving: false,
      statusMessage: 'Group FQDN settings were updated.',
      parentCallback: this.handleClose
    }), setTimeout(() => this.handleClose(), help.statusMsgDelay)}, help.hideSpinnerDelay);
  }

  handleUpdateError = () => {
    this.setState({showErrorStatus: true});
    setTimeout(() => this.setState({showErrorStatus: false}), 3000);
  }

  saveDeviceLocationInput = () => {
    this.setState({saving: true, showSaveStatus: true});
    let data = JSON.stringify({
      LocationAccountNumber: this.props.locationAccNum,
      FQDN: this.state.newFqdn,
      EncryptedAudio: this.state.EncryptedAudio,
      TransportProtocol: this.state.TransportProtocol,
      VlanId: this.state.VlanId
    });
    let apiPath = 'admin/UpdateDeviceLocationProperties';
    apiCalls.send(apiPath, 'PATCH', data).then(resp => {
      resp.ok ? this.handleUpdateSuccess() : this.handleUpdateError();
    }).catch(message => {
      console.log('caught an exception updating device location FQDN');
      console.log(message)
    });
  }

  savingOrConfirming = () => this.state.saving || this.state.statusMessage;

  getModalBody = () => {
    if (this.state.loading) {
      return <DataLoadingSpinner className='load spinner'/>;
    } else {
      return (
        <div className={this.savingOrConfirming() ? 'opaque' : 'visible group-fqdn'}>
          <p>Setup a Fully Qualified Domain Name (FQDN) as default FQDN for the location.</p>
          <div className="form-group">
            <label>Current FQDN</label>
            {this.saveStatus()}
            <input type="text" className="form-control" value={this.state.currentFqdn} disabled />
          </div>         
          <div className="multiselect-group form-group">
            <label>Available FQDN</label>
            <select 
              className="form-control" 
              onChange={this.setNewFqdn}
              defaultValue={this.state.currentFqdn}
            >
              {this.fqdnsOptions()}
            </select>
          </div>
        </div>
      )
    }
  }

  footerBtnClass = () => {
    return this.savingOrConfirming() || this.state.loading ? 'opaque' : 'visible btns';
  }

  modalClass = () => this.state.loading ? 'loading' : 'group-fqdn';

  render() {
    return (
      <div className="setting">
        <span onClick={this.handleShow}> 
          <span className="name">Group FQDN Setup</span>
          <a className="icon btn"><svg width="14" height="14" viewBox="0 0 24 24"><path d="m163 440l-91-91 251-250 90 90z m309-352l-48-48c-12-11-32-11-45 2l-45 45 91 91 45-45c13-13 13-33 2-45z m-408 275l-32 117 117-32z" transform="scale(0.046875 0.046875)"></path></svg></a>
        </span>
        <Modal show={this.state.showModal} onHide={this.handleClose} className={this.modalClass()}>
          <Modal.Header closeButton>
            <Modal.Title>Group FQDN Setup</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.getModalBody()}
          </Modal.Body>
          <Modal.Footer>
            <hr />
            <div className={help.footerBtnClass(this.state)}>
              <Button className="btn" click={this.saveDeviceLocationInput}>Save</Button>
              <Button className="btn" click={this.handleClose}>Close</Button>
            </div>
            <SaveStatus
              saving={this.state.saving}
              statusMessage={this.state.statusMessage}
              error={this.state.error}
              parentCallBack={this.state.parentCallback}
              hideOkBtn={true}
            />
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
};

export default GroupFqdnModal;