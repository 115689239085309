import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { Button } from '@progress/kendo-buttons-react-wrapper';
import { MultiSelect } from '@progress/kendo-dropdowns-react-wrapper';


class AgentsModal extends Component {

	constructor(props) {
		super(props);

    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);

    this.state = { showModal: false };
  }

  handleClose() {
    this.setState({ showModal: false });
  }

  handleShow() {
    this.setState({ showModal: true });
  }

	componentDidMount() {		
	}

  render() {
    return (
      <div className="setting">
        <span onClick={this.handleShow}> 
          <span className="name">Agents</span>
          <a className="icon btn"><svg width="14" height="14" viewBox="0 0 24 24"><path d="m163 440l-91-91 251-250 90 90z m309-352l-48-48c-12-11-32-11-45 2l-45 45 91 91 45-45c13-13 13-33 2-45z m-408 275l-32 117 117-32z" transform="scale(0.046875 0.046875)"></path></svg></a>
        </span>
        <Modal show={this.state.showModal} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Agents</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <p>Configure the list of agents that belong to this Contact Center. Users are available if they have been assigned an appropriate Contact Center feature. Users with Contact Center - Basic may be assigned to Basic Contact Centers. Users with Contact Center - Standard may be assigned to Basic or Standard Contact Centers. Users with Contact Center - Premium may be assigned to any Contact Center.</p>
              
              <div className="form-group">
                <label >Skill Level</label>
                <select className="form-control">
                  <option>1</option>
                  <option>2</option>
                  <option>3</option>
                  <option>20</option>
                </select>
              </div>
              <div className="multiselect-group form-group">
                <label>Assigned Agents</label>
                <MultiSelect autoClose={false} dataSource={["All Agents" ,"User 1", "User 2", "User 3", "User 4"]}/>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
              <hr />
              <div className="btns">
              <Button className="btn" click={this.handleClose}>Close</Button>
              </div>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
};

export default AgentsModal;
