import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { Button } from '@progress/kendo-buttons-react-wrapper';
import { DataLoadingSpinner } from './../../components/spinners';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'; // ES6

class VoicemailBlockModal extends Component {

  constructor(props) {
    super(props);

    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);

    this.state = { showModal: false };
  }

  handleClose() {
    this.props.handleClose();
  }

  handleShow() {
    this.props.handleShow();
  }

  handleYes=(e)=>{
    this.props.handleYes(e);
  }


  componentDidUpdate(prevProps){
    let countWithError = this.props.saveResult.filter((e,i)=>{
      return e.BlockedNumberAlreadyExist || e.ErrorBlocking;
    }).length;
    let countSuccess = this.props.saveResult.filter((e,i)=>{
      return !e.BlockedNumberAlreadyExist && e.Blocked;
    }).length;

    if(countWithError == 0 && countSuccess > 0){
      setTimeout(()=>{
        this.props.handleClose();
      }, 4000);
    }
  }

  render() {
    return (
      <span>
        <a onClick={this.handleShow}>Block</a>
        <Modal show={this.props.show} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>{this.props.confirmationMsg}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              {this.renderResult()}
              {this.error()}
            </div>
          </Modal.Body>
          <Modal.Footer>
              <hr />
              <div className="btns">
                <SaveButton disabled={this.props.loading || this.props.showSuccess} handleSave={this.handleYes} />
                <Button className="btn" click={this.handleClose} enable={!this.props.loading}>No</Button>
              </div>
              <SavingMessageControl 
                showSuccess={this.props.showSuccess} 
                showLoading={this.props.loading} 
                // showError={this.props.body && this.props.body.length > 0}
                SuccessMessage="Success"
                SuccessTransitionClass="vm-setting-success"
                // ErrorMessage={this.props.body}
                />
          </Modal.Footer>
        </Modal>
      </span>
    );
  }

  renderResult=()=>{
    if(this.state.loading) return (<div></div>);

    let errorComponent = (<div></div>);
    let successComponent = (<div></div>);
    let countWithError = this.props.saveResult.filter((e,i)=>{
      return e.BlockedNumberAlreadyExist || e.ErrorBlocking;
    }).length;
    let countSuccess = this.props.saveResult.filter((e,i)=>{
      return !e.BlockedNumberAlreadyExist && e.Blocked;
    }).length;
    
    if(countWithError > 0){
      errorComponent = 
        (<div className="alert alert-danger">
          <ul>
            {
              this.props.saveResult.map((e,i)=>{
                let rowNumber = i+1;
                if(e.BlockedNumberAlreadyExist){
                  return <li>#{rowNumber}. {e.BlockedNumber}  already block in '{e.Criteria}'</li>;
                }
                else if(e.ErrorBlocking){
                  return <li>#{rowNumber}. {e.BlockedNumber}  an error saving the number</li>;
                }
              })
            }
          </ul>
        </div>);
    }


    if(countSuccess > 0){
      successComponent = 
        (<div className="alert alert-success">
          <ul>
            {
              this.props.saveResult.map((e,i)=>{
                let rowNumber = i+1;
                if(!e.BlockedNumberAlreadyExist && e.Blocked){
                  return <li>#{rowNumber}. {e.BlockedNumber}  successfully block in '{e.Criteria}'</li>;
                }
              })
            }
          </ul>
        </div>);
    }

    return (
      <div>
        {successComponent}
        {errorComponent}
      </div>
    );
  }

  loading(){
    if(this.props.loading)
      return <DataLoadingSpinner className='load spinner pull-left'/>;

    return "";
  }

  error=()=>{
    let errorMessage = "";
    if(this.props.showError){
      errorMessage = (<div className="inline inline-fail text-center alert alert-danger">{this.props.ErrorMessage}</div>)
    }
    return errorMessage;
  }
};

class SavingMessageControl extends Component {
  render() {

      let loadingMessage = "";
      if(this.props.showLoading){
        loadingMessage = (<DataLoadingSpinner className='load spinner'/>)
      }

      return (
        <div>
          {loadingMessage}
        </div>
      );
    }
};

class SaveButton extends Component {
  render() {
    if(this.props.disabled)
      return (<Button key="1" className="btn" enable={false}>Yes</Button>);
    else
      return (<Button key="2" className="btn" click={this.props.handleSave}>Yes</Button>);
  }
};

export default VoicemailBlockModal;
