import React, { Component } from 'react';
import { DataLoadingSpinner } from '../../../components/spinners';
import { Modal } from 'react-bootstrap';
import { Button } from '@progress/kendo-buttons-react-wrapper';
import { Grid, GridColumn as Column, GridCell } from '@progress/kendo-react-grid';
import { orderBy } from '@progress/kendo-data-query';
import { filterBy } from '@progress/kendo-data-query';
import * as apiCalls from '../../../requests/api_calls';
import * as helper from '../../../scripts/helper';
import * as macdHelper from './macd_helper';
import MacdOrder from '../modals/macd_order';
import { filtercell } from '../components/dropdownFilter';
import WrappedCell from '../../../components/wordWrappedCell';
import { withRouter } from "react-router";

class MacdOrderListings extends Component {

  initialState = (props) => {

    return {
      ListingPage: props.ListingPage,
      MomentumAccountNumber: props.MomentumAccountNumber,
      searchStatus: "",
      searchOrderId: "",
      searchMACDType: "",
      searchAcctNumber: "",
      searchCustomerName: "",
      searchCreated: "",
      searchCreatedBy: "",
      MACDListings: [],
      gridHeight: props.ListingPage === 'Open Requests' ? props.GridHeight : props.GridHeight + 150,
      sort: [{ field: "OrderId", dir: "desc" }],
      useFilter: true,
      filter: { logic: "and", filters: [] },
      loading: false,
      showOk: false,
      deleting: false,
      isConfirmationDialogOpen: false,
      ConfirmationMessage: '',
      ConfirmationTitle: '',
      tempRecord: {}
    }
  }

  constructor(props) {
    super(props);
    this.state = this.initialState(props);
    this.gridContent = this.gridContent.bind(this);
    this.fetchSearchResults = this.fetchSearchResults.bind(this);
    const handleViewMACD = this.handleViewMACD.bind(this);
    const handleTerminateMACD = this.handleTerminateMACD.bind(this);
    // const handleCloneMACD = this.handleCloneMACD.bind(this);
    // const handleViewMACDStatus = this.handleViewMACDStatus.bind(this);
    // const handleViewMACDNotes = this.handleViewMACDNotes.bind(this);
    const handleEditMACD = this.handleEditMACD.bind(this);
    // const handleCheckMACDError = this.handleCheckMACDError.bind(this);
    const handleDeleteMACD = this.handleDeleteMACD.bind(this);
    this.ConfirmationYesAction = this.ConfirmationYesAction.bind(this);
    this.DeleteMACD = this.DeleteMACD.bind(this);
    this.terminateMacd = this.terminateMacd.bind(this);
    this.sortChange = this.sortChange.bind(this);
    this.dropDownOptions = this.dropDownOptions.bind(this);
    const handleClearFilter = this.handleClearFilter.bind(this);
    
    class ClearFilterCell extends React.Component {
      render() {
        return (
          <a onClick={handleClearFilter} className="no-btn">Clear Filters</a>
        );
      }
    };
  

    class MyCommandCell extends GridCell {
      render() {
        let flag = this.props.dataItem.ProcessFlag;
        let macdtype = this.props.dataItem.MacdTypeName;
        let orderSystem = this.props.dataItem.OrderSource;
        if (macdHelper.CheckPermissionsForLinks(macdtype)) {
          const OrderId = this.props.dataItem.OrderId;
          const LocationAccountNumber = this.props.dataItem.MomentumAccountNumber;
          
          let editLink = () => {
            if (orderSystem === 'MSP' && macdtype === macdHelper.MACDTYPES.AddServices && flag === macdHelper.MACDStatus.Open)
              return (
                <a href="#EditOrder" style={{ display: "block" }} onClick={() => handleEditMACD(this.props.dataItem)}><u>Edit</u></a>
              )
          }

          let deleteLink = () => {
            if (orderSystem === 'MSP' 
              && (macdtype === macdHelper.MACDTYPES.AddServices || macdtype === macdHelper.MACDTYPES.ChangeListing || macdHelper === macdHelper.MACDTYPES.ChangeCNAM)
              && (flag === macdHelper.MACDStatus.Open || flag === macdHelper.MACDStatus.Closed || flag === macdHelper.MACDStatus.Submitted))
            {
              return (
                <a href="#DeleteOrder" style={{ display: 'block'}} onClick={() => handleDeleteMACD(this.props.dataItem)}><u>Delete</u></a>
              )
            }
          }
          let viewLink = () => {
            if (macdtype !== macdHelper.MACDTYPES.AddServices) {
              return (
                <MacdOrder MacdType={macdtype} OrderId={OrderId} LocationAccountNumber={LocationAccountNumber} />
              );
            } else if (macdtype === macdHelper.MACDTYPES.AddServices && ((orderSystem !== 'MSP') || flag !== macdHelper.MACDStatus.Open)) {
              return (
                <a href="#ViewOrder" style={{ display: macdtype !== macdHelper.MACDTYPES.EditAddOrder ? 'block' : 'none' }}
                  onClick={() => handleViewMACD(this.props.dataItem)} >
                  <u>View</u>
                </a>)
            }
          }
          let terminateLink = () => {            
            if (macdtype === macdHelper.MACDTYPES.AddServices) {
              return (
                <a href="#" style={{
                  display: flag === (macdHelper.MACDStatus.Processing
                    || flag === macdHelper.MACDStatus.Clarification
                    || flag === macdHelper.MACDStatus.OnHold
                    || flag === macdHelper.MACDStatus.Error) && orderSystem === 'MSP' ? "block" : "none"
                }}
                  onClick={() => handleTerminateMACD(this.props.dataItem)}><u>Terminate</u></a>
              )
            }
          }
          return (
            <td>
              {viewLink()}
              {editLink()}
              {deleteLink()}
              {terminateLink()}
            </td>
          )
        } else //if no permissions then no links should be available for this order
          return (<td></td>)
      }
    }
    this.CommandCell = MyCommandCell;
    this.ClearFilterCell = ClearFilterCell;
  };

  componentDidMount() {
    this.fetchSearchResults(); /// PROMISE.ALL
  }
  componentWillReceiveProps(props) {
    if (props) {
      if (props.MomentumAccountNumber)
        this.setState({ MomentumAccountNumber: props.MomentumAccountNumber });
      if (props.GridHeight && props.GridHeight !== this.state.gridHeight) {
        let gridHeight = this.state.ListingPage === "Open Requests" ? props.GridHeight : props.GridHeight + 150
        this.setState({ gridHeight: gridHeight });
      }
    }

  }

  fetchSearchResults = () => {
    this.setState({ loading: true });
    let orderProcessFlag = this.state.ListingPage === "Open Requests" ? "MSP_STATUS" : 'ALL';
    let apiPath = `admin/macd/GetOrderList?status=${orderProcessFlag}`;

    apiCalls.fetchData(apiPath).then((data) => {
      //console.log(data);
      if (data !== null) {
        //// OrderId TO STRING ***
        let copy = JSON.parse(JSON.stringify(data));
        copy.map((itm) => itm.OrderId = itm.OrderId.toString());
        this.setState({
          loading: false,
          MACDListings: this.revisedData(copy)
        });
      } else if (data === null) {
        this.setState({ MACDListings: [], loading: false });
      };
    });
  }

  sortSearchResults(sort, MACDListings = null) {
    if (MACDListings == null) MACDListings = this.state.MACDListings.slice();
    let sortedSet = orderBy(MACDListings, sort);
    if (sort.length) {
      return helper.blanksAfterZ(sortedSet, sort);
    } else {
      return sortedSet;
    }
  }

  revisedData = (data) => {
    let sortedData = this.sortSearchResults(this.state.sort, data);
    sortedData.map((item, idx) => {
      item.CreatedDate = new Date(item.CreatedDate);
      item.ModifiedDate = new Date(item.ModifiedDate);
      item.Filler = ' ';
    });
    return sortedData;
  }

  handleViewMACD = (record) => {
    switch (record.MacdTypeName) {
      case macdHelper.MACDTYPES.AddServices:
        this.props.history.push(macdHelper.ReviewPageUrl(parseInt(record.OrderId)));
        break;
      case macdHelper.MACDTYPES.AssignAddOn:
        break;
      case macdHelper.MACDTYPES.UnassignAddOn:
        break;
      default:
        break;
    }
  }

  handleTerminateMACD = (record) => {
    this.setState({
      tempRecord: record,
      isConfirmationDialogOpen: true,
      ConfirmationMessage: 'Are you sure you wish to terminate this MACD Order?',
      ConfirmationTitle: 'Terminate MACD Request?'
    });
  }

  terminateMacd = async () => {
    await this.setState({deleting: true, isConfirmationDialogOpen: false});
    let record = this.state.tempRecord;
    let apiPath = macdHelper.apiTerminateOrder();
    let data = JSON.stringify(parseInt(record.OrderId));
    let resp = await apiCalls.post(apiPath, 'PATCH', data).then(resp => resp);
    let dialogMsg = '';
    let bFailure = false;
    if (resp.ok) {
      let records = this.state.MACDListings;
      records = records.filter(r => r.OrderId !== record.OrderId);
      dialogMsg = 'Your request to terminate the selected order has been completed successfully.  Your order may still appear as processing for a few minutes.';
      this.setState({
        MACDListings: records
      });
    } else {
      dialogMsg = 'Your order was not terminated due to an error.';
      bFailure = true;
    }
    this.setState({
      deleting: false,
      isConfirmationDialogOpen: true,
      showOk: true,
      error: bFailure,
      ConfirmationMessage: dialogMsg,
      tempRecord: {}
    });
  }

  handleCloneMACD = (record) => {
    //TODO: aim for v1, but not MVP
  }

  handleViewMACDNotes = (record) => {
    //Not in scope at time b/c Customer will not be working on provisioning which these notes are usually for
  }

  handleViewMACDStatus = (record) => {
    //not in scope at the time b/c the status will be visible in the list already and none of the 'bells & whistles' in MPO are necessary on the Customer Side
  }

  handleEditMACD = (record) => {
    switch (record.MacdTypeName) {
      case macdHelper.MACDTYPES.AddServices:
        // let uri = macdHelper.AddEditPage(parseInt(record.OrderId), 'RO')
        // window.location.replace(uri);
        this.props.history.push(macdHelper.AddEditPage(parseInt(record.OrderId)));
        break;
      case macdHelper.MACDTYPES.AssignAddOn:
        break;
      case macdHelper.MACDTYPES.UnassignAddOn:
        break;
      default:
        break;
    }
  }

  handleCheckMACDError = (record) => {

  }

  ConfirmationYesAction = (e) => {
    if (this.state.ConfirmationTitle === 'Delete Service Change Request?') {
      this.DeleteMACD();
    }
    if (this.state.ConfirmationTitle === 'Terminate MACD Request?') this.terminateMacd();
  }

  handleDeleteMACD = (record) => {
    this.setState({
      tempRecord: record,
      isConfirmationDialogOpen: true,
      ConfirmationMessage: 'Are you sure you want to discard this Service Change?',
      ConfirmationTitle: 'Delete Service Change Request?'
    });
  }

  DeleteMACD = async () => {
    await this.setState({deleting: true, isConfirmationDialogOpen: false});
    let record = this.state.tempRecord;
    let apiPath = macdHelper.apiDiscardOrder();
    let data = parseInt(record.OrderId);
    
    let resp = await apiCalls.post(apiPath, 'PATCH', JSON.stringify(data)).then(resp => resp);

    let dialogMsg = '';
    let bFailure = false;
    if (resp.ok) {
      dialogMsg = 'Your order has been discarded successfully';
      let records = this.state.MACDListings;
      records = records.filter(r => r.OrderId !== record.OrderId);
      this.setState({
        MACDListings: records
      });
    } else {
      dialogMsg = 'Your order was not discarded due to an error.';
      bFailure = true;
    }
    this.setState({
      deleting: false,
      isConfirmationDialogOpen: true,
      ConfirmationMessage: dialogMsg,
      showOk: true,
      error: bFailure,
      tempRecord: {}
    });

  }

  // isConfirmationDialogOpen: false,
  //     ConfirmationMessage: '',
  //     ConfirmationTitle: '',

  setGridData = () => {
    if (this.state.ListingPage !== 'Open Requests' || (this.state.ListingPage === 'Open Requests' && (this.props.MomentumAccountNumber === "All Locations" || this.props.MomentumAccountNumber === ""))) {
      return this.state.MACDListings;
    } else {
      return helper.filterNestedObject('MomentumAccountNumber', this.props.MomentumAccountNumber, this.state.MACDListings)
    }
  }

  getMACDListings = (filter) => {
    let x = filterBy(this.setGridData(), filter);
    return x;
  }

  handleFilterChange = async (event) => {
    await this.setState({
      filter: event.filter
    });
  }

  handleClearFilter = () => {
    let newFilter = {
      logic: 'and',
      filters: []
    };
    this.setState({filter: newFilter});
  }

  closeConfirmationDialog = (e) => {
    this.setState({ isConfirmationDialogOpen: false, showOk: false });
  }

  sortChange(event) {
    this.setState({
      MACDListings: this.sortSearchResults(event.sort),
      sort: event.sort
    });
  }

  dropDownOptions = (key) => {

    let sort =[{ field: key, dir: "asc" }];
    let listings = orderBy(this.state.MACDListings.slice(), sort);
    let info = [...listings.map(item => item[key]).filter(helper.onlyUnique)];
    return info;
  }

  gridContent = () => {
    if (this.state.loading || this.state.deleting) {
      return <DataLoadingSpinner className='load spinner' />
    } else {
      return (
        <Grid
          style={{ height: this.state.gridHeight + 'px' }}
          data={this.getMACDListings(this.state.filter)}
          sortable={{ allowUnsort: true, mode: 'single' }}
          sort={this.state.sort}
          onSortChange={this.sortChange}
          filterable
          filter={this.state.filter}
          onFilterChange={this.handleFilterChange}
        >
          <Column width="150px" field='OrderId' title='Order' />
          <Column width="150px" field='MomentumAccountNumber' title='Account' filterCell={filtercell(this.dropDownOptions('MomentumAccountNumber'))} />
          <Column width="225px" field='MacdTypeName' title='Service Changes Type' filterCell={filtercell(this.dropDownOptions('MacdTypeName'))} />
          <Column width="150px" field='CreatedDate' format="{0:MM/dd/yyyy}" title='Created Date' filterable={false} />
          <Column width="175px" field='CreatedBy' cell={WrappedCell} title='Created By' />
          <Column width="150px" field='ModifiedDate' format="{0:MM/dd/yyyy}" title='Modified Date' filterable={false} />
          <Column width="125px" field='ModifiedBy'cell={WrappedCell} title='Modified By' filterable={false} />
          <Column width="150px" field='ProcessFlag' title='Status' filterCell={filtercell(this.dropDownOptions('ProcessFlag'))} />
          <Column width="125px" cell={this.CommandCell} filterCell={this.ClearFilterCell} sortable={false} />
          <Column title=' ' field='filler' filterable={false} sortable={false} />
        </Grid>

      )
    }
  }

  render() {

    let statusClass = this.state.showOk === true ? (this.state.error ? ' error text-danger' : ' success text-success') : '';
    return (
      <div>
        {this.gridContent()}
        <Modal dialogClassName="confirm-add-services-modals" show={this.state.isConfirmationDialogOpen} onHide={this.closeConfirmationDialog}>
          <Modal.Header closeButton>
            <Modal.Title>{this.state.ConfirmationTitle}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div><p><span className={statusClass} style={{ fontSize: "medium" }}>{this.state.ConfirmationMessage}</span></p></div>
          </Modal.Body>
          <Modal.Footer>            
            {this.state.showOk ? <Button click={(evt) => this.closeConfirmationDialog()}>OK</Button> :
              <div>
                <Button click={(evt) => { this.ConfirmationYesAction() }}>Yes</Button>
                <Button click={(evt) => { this.closeConfirmationDialog() }}>No</Button>
              </div>
            }
          </Modal.Footer>
        </Modal>
      </div>
    )
  }
}
const MacdOrderListingsWithRouter = withRouter(MacdOrderListings)
export default MacdOrderListingsWithRouter;