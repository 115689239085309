import { store } from '../store/store';
import * as actions from './../store/actions';
import entries from 'object.entries';
import moment from 'moment';
export const searchDelay = 1000;
export const unauthorized = 401;
export const loginTimeout = 1000 * 30;
export const redirectTimeout = 1000 * 10;
export const noResultsMsg = 'No results for the search term provided.';
export const signInPageButtonLabel = process.env.REACT_APP_SIGNIN_PAGE_BTN_LABEL;
export const idleLogoutTime = 1000 * 60 * 20;
export const idleLogoutWarning = 1000 * 60;

export const saveLoginError = (errorMsg, errorDescription='') => {
  let actionParams = {
    loginError: errorMsg.toString(),
    loginErrorDescription: errorDescription.toString()
  }
  store.dispatch({type: actions.SAVE_LOGIN_ERROR, ...actionParams});
  saveToSessionStorage(actionParams);
}

export const setLocation = (locationAccountNumber, locationAddress) => {
  let actionParams = {
    LocationAccountNumber: locationAccountNumber, 
    LocationAddress: locationAddress,
  }
  store.dispatch({type: actions.SET_LOCATION, ...actionParams});
  saveToSessionStorage(actionParams);
}

export const setQuickViewLocation = (locationAccountNumber) => {
  let actionParams = {
    QuickViewLocationAccountNumber: locationAccountNumber
  }
  //QUICK FIX FOR QUICK VIEW NOT LOADING BECAUSE OF VALUE IS ALREADY DISPATCH
  store.dispatch({type: actions.SET_QUICK_VIEW_LOCATION, ...{}});

  store.dispatch({type: actions.SET_QUICK_VIEW_LOCATION, ...actionParams});
}

export const selectService = (userId, serviceNumber, pbxUserId = null, extension=null) => {
  // triggers call to MyServices to retrieve remaining service properties
  let actionParams = {SelectedUserId: userId, ServiceNumber: serviceNumber, PBXUserID: pbxUserId, Extension: extension};
  store.dispatch({type: actions.SERVICE_SELECTED, ...actionParams});
  saveToSessionStorage(actionParams);
}

export const setService = (service) => {
  let actionParams = {};
  if (service) 
    actionParams = {
      ServiceId: service.ServiceId,
      ServiceNumber: service.ServiceNumber, 
      SelectedUserId: service.UserId,
      AccountNumber: service.LocationAccountNumber,
      DirectoryNumber:service.DirectoryNumber,
      PBXUserID: service.PBXUserID,
      Extension:service.Extension,
      isSelectedServiceIpTrunking: service.IsIPTrunking,
      //reset date filters on call logs when selected user/service changes
      usercl_call_type: "1",
      usercl_status_type: "1",
      usercl_call_category: "1",
      usercl_date_type: "1",
      usercl_from_date: moment(Date.now()).startOf("day").format("YYYY-MM-DD"),
      usercl_to_date: moment(Date.now()).endOf("day").format("YYYY-MM-DD")
    };
  else
    actionParams = {
      ServiceId: 0,
      ServiceNumber: '', 
      SelectedUserId: sessionStorage.SelectedUserId,
      AccountNumber: sessionStorage.userEnterpriseNumber,
      DirectoryNumber:'',
      PBXUserID: '',
      Extension: '',
      isSelectedServiceIpTrunking: false,
      //reset date filters on call logs when selected user/service changes
      usercl_call_type: "1",
      usercl_status_type: "1",
      usercl_call_category: "1",
      usercl_date_type: "1",
      usercl_from_date: moment(Date.now()).startOf("day").format("YYYY-MM-DD"),
      usercl_to_date: moment(Date.now()).endOf("day").format("YYYY-MM-DD")
    };
  saveToSessionStorage(actionParams);
  store.dispatch({type: actions.SET_SERVICE, ...actionParams});
}

export const setCLFilters = (cl_call_type,  cl_status_type, cl_call_category, cl_date_type, cl_from_date,  cl_to_date, user = null) => {
  let actionParams = {};
  if (user)
  {
    actionParams = {
      usercl_call_type: cl_call_type,
      usercl_status_type: cl_status_type,
      usercl_call_category: cl_call_category,
      usercl_date_type: cl_date_type,
      usercl_from_date: cl_from_date,
      usercl_to_date: cl_to_date
    }
    store.dispatch({type: actions.SET_SUBSCRIBER_CALL_LOGS_FILTER, ...actionParams});
    saveToSessionStorage(actionParams);
  } else {
    actionParams = {
      cl_call_type: cl_call_type,
      cl_status_type: cl_status_type,
      cl_call_category: cl_call_category,
      cl_date_type: cl_date_type,
      cl_from_date: cl_from_date,
      cl_to_date: cl_to_date
    }
    store.dispatch({type: actions.SET_CALL_LOGS_FILTER, ...actionParams});
    saveToSessionStorage(actionParams);
  }
}

const sameServiceSelected = (serviceNumber) => {
  return sessionStorage.ServiceNumber === serviceNumber
}

const saveToSessionStorage = (actionParams) => {
  // save action params to local storage so they persist across page loads
  entries(actionParams).map(([key, value]) => {
    if (value === undefined || value === null) {
      sessionStorage.removeItem(key);
    } else {
      sessionStorage.setItem(key, value);
    }
  });
}

export const setGlobalSearchValue = (value) => {
  let actionParams = {
    GlobalSearch: value
  }
  saveToSessionStorage(actionParams);
}

export const getGlobalSearchValue = () => {
  return sessionStorage.getItem("GlobalSearch");
}