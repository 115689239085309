import React, { Component } from 'react';
import PropTypes from 'prop-types'
import { Modal } from 'react-bootstrap';
import { Button } from '@progress/kendo-buttons-react-wrapper';
import CountdownTimer from '../../components/countdownTimer';

class IdleWarningModal extends Component {
    render() {
        return (
            <Modal show={true} onHide={this.props.onCancelLogout}>
                <Modal.Header closeButton>
                    <Modal.Title>Session expiration due to inactivity</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='text-center'>
                        Your session will expire in <div className="idle-timer"><CountdownTimer countdown={this.props.countdown} showInTitle={true} timeoutAction={this.props.onLogout}/></div>!
                    </div>
                </Modal.Body>
                <br/>
                <Modal.Footer>
                    <div className="btns">
                        <Button tabIndex="0" className="btn" click={this.props.onCancelLogout}>Stay logged in</Button>
                        <Button tabIndex="0" className="btn" click={this.props.onLogout}>Log out</Button>
                    </div>
                </Modal.Footer>
            </Modal>
        );
    }
}

IdleWarningModal.propTypes = {
    onCancelLogout: PropTypes.func,
    onLogout: PropTypes.func,
    countdown: PropTypes.number
}

export default IdleWarningModal;