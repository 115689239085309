import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { Button } from '@progress/kendo-buttons-react-wrapper';
import { MultiSelect } from '@progress/kendo-dropdowns-react-wrapper';
import _ from 'lodash';
import InfoPop from './../../../components/infopop';


class LocationAdvancedHuntingModal extends Component {

  constructor(props) {
    super(props);

    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.state = { showModal:false };
  }

  handleClose() {
    this.setState({ showModal: false });
  }

  handleShow() {
    this.setState({ showModal: true });
  }

  componentDidMount() {   
  }
  render() {
    
    return (
      <div className="setting">
        <span onClick={this.handleShow}> 
          <span className="name">Advanced Hunting</span>
          <a className="icon btn"><svg width="14" height="14" viewBox="0 0 24 24"><path d="m163 440l-91-91 251-250 90 90z m309-352l-48-48c-12-11-32-11-45 2l-45 45 91 91 45-45c13-13 13-33 2-45z m-408 275l-32 117 117-32z" transform="scale(0.046875 0.046875)"></path></svg></a>
        </span>
        <Modal show={this.state.showModal} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Advanced Hunting</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <table className="table">
              <thead>
                <tr>
                  <th className="check"><input type="checkbox" /></th>
                  <th>Group Name</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="check"><input type="checkbox" /></td>
                  <td>Hunt Group</td>
                  <td align="right">
                    <a className="icon btn"  data-toggle="collapse" data-target="#ar1"><svg width="14" height="14" viewBox="0 0 24 24"><path d="m163 440l-91-91 251-250 90 90z m309-352l-48-48c-12-11-32-11-45 2l-45 45 91 91 45-45c13-13 13-33 2-45z m-408 275l-32 117 117-32z" transform="scale(0.046875 0.046875)"></path></svg></a>
                  </td>
                </tr>
                <tr className="collapse" id="ar1">
                  <td colSpan="3">
                    <div className="table-edit">
                      <div className="form-group">
                        <label>Name</label>
                        <input type="type" className="form-control" defaultValue="" />
                      </div>
                      <div className="form-group">
                        <label>Calling Line ID First Name</label>
                        <input type="type" className="form-control" defaultValue="" />
                      </div>
                      <div className="form-group">
                        <label>Calling Line ID Last Name</label>
                        <input type="type" className="form-control" defaultValue="" />
                      </div>
                      <hr />
                      <div className="form-group">
                        <label >Hunt Style</label>
                        <select className="form-control">
                          <option>Circular</option>
                          <option>Regular</option>
                          <option>Simultaneous</option>
                          <option>Uniform</option>
                        </select>
                      </div>
                      <div className="multiselect-group form-group">
                        <label>Selected User Order</label>
                        <MultiSelect autoClose={false} dataSource={["User 1", "User 2", "User 3", "User 4"]}/>
                      </div>
                      <div className="multiselect-group form-group">
                        <label>Directory Number Hunting
                        <InfoPop message="When a user is assigned to Directory Number Hunting iand the user receives a call, the call is presented to the user first. If this user is busy or unavailable, the service then applies the Hunt Style policy that has been configured for the hunt group. If a number is included in the Directory Number Hunting field, it essentially becomes a pilot number for the hunt group if busy or not answered." />
                        </label>
                        <MultiSelect autoClose={false} dataSource={["User 1", "User 2", "User 3", "User 4"]}/>
                      </div>
                      <hr />
                      <div className="form-group">
                        <label >Department</label>
                        <select className="form-control">
                          <option>Department 1</option>
                          <option>Department 2</option>
                          <option>Department 3</option>
                          <option>Department 4</option>
                        </select>
                      </div>
                      <div className="form-group">
                        <label >Timezone</label>
                        <select className="form-control">
                          <option>Timezone 1</option>
                          <option>Timezone 2</option>
                          <option>Timezone 3</option>
                          <option>Timezone 4</option>
                        </select>
                      </div>
                      <hr />
                      <div className="form-group">
                        <label className="right switch">
                          <input type="checkbox" />
                          <span className="slider round"></span>
                        </label>
                        Allow Call Waiting on Line
                      </div>
                      <div className="form-group">
                        <label className="right switch">
                          <input type="checkbox" />
                          <span className="slider round"></span>
                        </label>
                        Allow members to control Group Busy
                      </div>
                      <div className="form-group">
                        <label className="right switch">
                          <input type="checkbox" />
                          <span className="slider round"></span>
                        </label>
                        Enable Group Busy
                      </div>
                      <div className="form-group">
                        <label className="right switch">
                          <input type="checkbox" />
                          <span className="slider round"></span>
                        </label>
                        Apply Group Busy When Terminating Call to Agent
                      </div>
                      <hr />
                      <h5>No Answer Settings</h5>
                      <div className="form-group">
                        <label className="right switch">
                          <input type="checkbox" data-toggle="collapse" data-target="#hide1" />
                          <span className="slider round"></span>
                        </label>
                        Skip to next agent after a number of rings
                        <div className="hidden-group collapse" id="hide1">
                          <label >How many rings</label>
                          <select className="form-control">
                            {_.times(9, i =>
                              <option key={i} defaultValue={i}>{i+1}</option>
                            )}
                          </select>
                        </div>
                      </div>
                      <div className="form-group">
                        <label className="right switch">
                          <input type="checkbox" data-toggle="collapse" data-target="#hide2" />
                          <span className="slider round"></span>
                        </label>
                        Forward call after wait
                        <div className="hidden-group collapse" id="hide2">
                          <div className="form-group">
                            <label >How many seconds</label>
                            <select className="form-control">
                              {_.times(9, i =>
                                <option key={i} defaultValue={i}>{i+1}</option>
                              )}
                            </select>
                          </div>
                          <div className="form-group">
                            <label >Forward call to</label>
                            <input className="form-control" />
                          </div>
                        </div>
                        <hr />
                        <h5>Not Reachable Settings</h5>
                        <div className="form-group">
                          <label className="right switch">
                            <input type="checkbox" data-toggle="collapse" data-target="#hide3" />
                            <span className="slider round"></span>
                          </label>
                          Enable Call Forwarding Not Reachable
                          <div className="hidden-group collapse" id="hide3">
                            <div className="form-group">
                              <label >Forward call to</label>
                              <input className="form-control" />
                            </div>
                          </div>
                        </div>
                        <div className="form-group">
                          <label className="right switch">
                            <input type="checkbox" />
                            <span className="slider round"></span>
                          </label>
                          Make Hunt Group busy when all available agents are not reachable
                        </div>
                        <hr />
                        <h5>Calling Line ID Settings</h5>
                        <div className="form-group">
                          <label>CLID Configuration</label>
                          <div className="form-control">
                            <label className="custom-control custom-radio">
                            <input id="radio1" name="radio" type="radio"/>
                              <span >Default <small>Includes the Hunt Group Name in the CLID</small></span>
                            </label> 
                            <label className="custom-control custom-radio">
                              <input id="radio1" name="radio" type="radio" data-toggle="collapse" data-target="#hideCustom"/>
                              <span>Customized</span>
                            </label>
                          </div>
                          <div className="hidden-group collapse" id="hideCustom">
                            <label className="right switch">
                              <input type="checkbox" />
                              <span className="slider round"></span>
                            </label>
                            Include the Hunt Group Name in the CLID
                          </div>
                        </div>
                        <hr />
                        <div className="btns">
                          <a href="" className="btn">Save</a>
                          <a href="" className="btn">Cancel</a>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </Modal.Body>
          <Modal.Footer>
              <hr />
              <div className="btns">
                <Button className="btn" click={this.handleClose}>Close</Button>
              </div>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }

};

export default LocationAdvancedHuntingModal;
