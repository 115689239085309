import * as React from 'react';
import { Component ,Fragment} from 'react';
import { Link } from 'react-router-dom';
import { Grid, GridColumn as Column, GridCell } from '@progress/kendo-react-grid';
import { Modal } from 'react-bootstrap';
import { headerToolTip } from '../../components/ellipsisHeaderTooltip';
import { DataLoadingSpinner } from '../../components/spinners';
import { orderBy } from '@progress/kendo-data-query';
import { filterBy } from '@progress/kendo-data-query';
import * as apiCalls from '../../requests/api_calls';
import * as config from '../../requests/config';
import * as settings from '../../scripts/settings';
import * as helper from '../../scripts/helper';
import FlagIconFactory from 'react-flag-icon-css'
import { sendErrorMessage, convertToLocal } from './components/utility';
//import FlagIcon from 'react-flag-icon-css'

const FlagIcon = FlagIconFactory(React, { useCssModules: false })

export default class MsTeamsUserMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Enterprise: this.props.Enterprise,
      Trunk: this.props.Trunk,
      loading: false,
      Submission: false,
      submitError: false,
      UsersList: this.props.TeamsUsers,
      FullListings: this.props.TeamsUsers,
      MSTeamsConfig: this.props.MSTeamsConfig,
      showConfirmationModal: false,
      Sort: [{ field: "UserId", dir: "desc" }],
      filter: { logic: "and", filters: [] },
      routingType: this.props.routingType,
      gridHeight: (window.innerHeight - 180),
      modalAction: "modify",
      DomainList: this.props.DomainList != null ? JSON.parse(this.props.DomainList) : [],
      AccountSettings: {
        UserPrincipalName: "",
        Domain: "",
        JsonParameters: {
          NewDisplayName: "",
          NewDisplayNameSpecified: true,
          NewResourceAccountType: 1,
          NewResourceAccountTypeSpecified: false,
          NewPhoneNumber: "",
          NewPhoneNumberSpecified: false
        },
        AccountType: 0,
        DisplayName: "",
      },
      page: { skip: 0, take: 200 },
      pageSizeValue: 0
    };

    const giveStateData = this.giveStateData.bind(this);

    class ManageUserCell extends GridCell {
      constructor(props) {
        super(props);
      }

      render() {
        let enterprise = giveStateData()[0];
        let trunk = giveStateData()[1];

        return (
          <td style={{
            position: 'relative',
            right: '35px'
          }}>
            <Link
              to={{
                pathname: `/ms-teams-user-config`,
                state: {
                  UserDetails: this.props.dataItem,
                  TeamsId: this.props.dataItem.TeamsUserId,
                  Enterprise: enterprise,
                  Trunk: trunk,
                }
              }}
              className="icon btn">
              <svg width="14" height="14" viewBox="0 0 24 24">
                <path d="m163 440l-91-91 251-250 90 90z m309-352l-48-48c-12-11-32-11-45 2l-45 45 91 91 45-45c13-13 13-33 2-45z m-408 275l-32 117 117-32z" transform="scale(0.046875 0.046875)"></path>
              </svg>
            </Link>
          </td>
        )
      }
    }

    class UserLinkCell extends GridCell {
      constructor(props) {
        super(props);
      }

      selectUser = (service) => {
        settings.selectService(service.UserId, service.TelephoneNumber);
      }

      render() {
        let { TelephoneNumber } = this.props.dataItem;
        if (TelephoneNumber) {

          return (
            <td>
              <Link
                to={{ pathname: `/dashboard`, state: {} }}
                onClick={() => { this.selectUser(this.props.dataItem) }}
              >
                {TelephoneNumber}
              </Link>
            </td>
          )
        } else {
          return <td></td>
        }
      }
    }

    this.ManageUserCell = ManageUserCell;
    this.UserLinkCell = UserLinkCell;
  }

  giveStateData = () => {
    let { Enterprise, Trunk } = this.state;
    return [Enterprise, Trunk];
  }

  StatusCell = (props) => {
    let customClassName = "";

    if (props.dataItem.RoutingStatus === 3) {
      customClassName = "dot dot-success";
    }
    if (props.dataItem.RoutingStatus === 4) {
      customClassName = "dot dot-error";
    }
    if (props.dataItem.RoutingStatus === 2) {
      customClassName = "dot dot-blue";
    }
    if (props.dataItem.RoutingStatus === 1) {
      customClassName = "dot dot-yellow";
    }
    if (props.dataItem.RoutingStatus !== null && props.dataItem.RoutingStatus !== "") {
      return (
        <td>
          <i className={customClassName} />
        </td>
      )
    } else {
      return (
        <td></td>
      )
    }
  };

  gridContent = () => {
    let { MSTeamsConfig, UsersList, Sort } = this.state;
    //if (!loading) {
    if (UsersList && UsersList.length > 0) {
      let columnWidth = '110px';
      let columnWidthSm = "70px";
      let size = "lg";

      return (

        <Grid
          style={{
            width: '100%',
          }}
          data={orderBy(UsersList.slice(this.state.page.skip, this.state.page.take + this.state.page.skip), Sort)}
          sortable={{ allowUnsort: true, mode: 'single' }}
          sort={Sort}
          onSortChange={this.handleSortChange}
          skip={this.state.page.skip}
          take={this.state.page.take}
          total={UsersList.length}
          pageable={{
            buttonCount: 4,
            pageSizes: [10, 50, 100, 200, "All"],
            pageSizeValue: this.state.pageSizeValue,
          }}
          onPageChange={this.pageChange}
        >
          <Column width={columnWidth} field="GivenName" title={headerToolTip("First Name", true)} />
          <Column width={columnWidth} field="Surname" title={headerToolTip("Last Name", true)} />
          <Column width="150px" field="UserPrincipalName" title={headerToolTip("Principal", true)} />
          <Column width={columnWidth} field="TelephoneNumber" title={headerToolTip("Phone", true)} cell={this.UserLinkCell} />
          <Column width="60px" field="Extension" title={headerToolTip("Ext", true)} />
          <Column width={columnWidth} field="UserStateChangedOn" title={headerToolTip("Last Updated")} />
          <Column width={columnWidth} field="UsageLocation" cell={(props) => {
            let code = props.dataItem.UsageLocation?.trim();
            if (code) {
              return (<td title={code}><FlagIcon code={code.toLowerCase()} size={size} /></td>)
            }
            else {
              return (<td></td>);

            }
          }
          } title={headerToolTip("Location")} />
          <Column width="150px" field="SipProxyAddress" title={headerToolTip("Sip Proxy")} />
          <Column width="115px" field="AccountType" title={headerToolTip("User Type")} />
          <Column width="115px" field="RoutingStatus" title="Status" cell={this.StatusCell} />
          {MSTeamsConfig.ConfigProcessCompleted ? <Column width="115px" title={headerToolTip("Manage Account")} cell={this.ManageUserCell} /> : null}
          <Column title=' ' field='filler' />
        </Grid>
      )
    } else {
      return <p style={{ textAlign: 'center', paddingTop: '15px' }}>No Available Data</p>
    }
  }

  pageChange = (event) => {
    const targetEvent = event.page.take;
    if (targetEvent) {
      this.setState({
        page: event.page
      });
    }
    else {
      this.setState({
        page: { skip: 0, take: this.state.UsersList.length }
      });
    }
  };

  handleSortChange = (e) => {
    this.setState({
      UsersList: orderBy(this.state.UsersList, e.sort),
      Sort: e.sort
    });
  }

  handleFilterChange = async (event) => {
    await this.setState({
      filter: event.filter
    });
  }

  exportBtn = () => {
    if (this.state.UsersList.length > 0) {
      return (
        <div id="users-csv" className='pull-left'>
          <form ref='downloadCsv' target="iFrameSave" action={config.baseApiUrl + 'admin/MSTeamsUsersCSV?type=regularusers'} method="POST">
            <input type="hidden" name="access_token" value={config.storedAccessToken()} className='hidden'></input>
            <input type="hidden" name="x_policy" value={config.storedXPolicy()} className='hidden'></input>
          </form>
          <iframe name="iFrameSave" height="0" width="0" className='hidden pull-right'></iframe>
          <a tabIndex="0" className='no-btn' ref='exportBtn' onClick={(e) => this.exportUsers()}>Export Users</a>
        </div>
      )
    } else return null;
  }

  exportUsers = () => {
    this.refs.downloadCsv.submit();
    this.refs.exportBtn.text = 'Downloading...';
    setTimeout(() => {
      this.refs.exportBtn.text = 'Export Users';
    }, 2000);
  }

  openAttachments = () => {
    this.refs.fileUploader.click();
  }

  addAttachment = async (event) => {
    let file = event.target.files[0];
    let reader = new FileReader();
    reader.addEventListener('loadend', () => {
      let body = reader.result.split(',')[1];
      let name = file.name;
      this.createAttachment(body, name);
    }, false);
    if (file) {
      reader.readAsDataURL(file);
    }
  }

  createAttachment = async (body, name, ticketId) => {
    let apiPath = 'admin/UploadMSTeamsUsers';
    let attachmentDTO = {
      TicketId: this.state.TicketId,
      AttachmentBody: body,
      FileName: name,
    };
    await this.setState({ attachmentSubmitted: true });
    let response = await apiCalls.post(apiPath, 'POST', JSON.stringify(attachmentDTO)).then(res => res).catch(err => console.error(err));
    if (response.ok) {
      await this.setState({
        Submission: true,
        submitError: false,
      });
    } else {
      await this.setState({
        Submission: true,
        submitError: true,
      });
    }
  }

  showStatusLegend = () => {
    return (<div className='pull-right'>
      <div><i className="dot dot-success" />{'-'}<i> Provisioned</i></div>
      <div><i className="dot dot-error" />{'-'}<i> Provisioned - Incorrect Voice Policy</i></div>
      <div><i className="dot dot-blue" />{'-'}<i> UnProvisioned With Valid Phone License</i>
      </div><i className="dot dot-yellow" />{'-'}<i> UnProvisioned Missing Valid Phone License</i>
    </div>)
  }

  uploadBtn = () => {
    return (
      <div>
        <div id="users-upload" className='pull-left' style={{ position: "relative", left: "8px" }}>
          <input
            type="file"
            id="file"
            accept=".csv"
            ref='fileUploader'
            style={{ display: "none" }}
            onChange={this.addAttachment}
            onClick={(event) => {
              event.target.value = null;
            }}
          />
          <a tabIndex="0" className='no-btn' ref='uploadBtn' onClick={(e) => this.openAttachments()}>Import Users</a>
        </div>
      </div>)

  }

  submissionMsg = () => {
    if (this.state.Submission) {
      if (this.state.submitError) {
        return <small style={{
          color: 'red',
        }}>{this.state.errorMsg && this.state.errorMsg != "" ? this.state.errorMsg : "Submission Failure: Changes were not processed!"}</small>
      } else {
        return <small style={{
          color: 'green',
        }}>Submission Sucess: Changes were processed!</small>
      }
    }
  };

  manageModal = () => {
    let { DisplayName, UserPrincipalName, AccountType, Domain, ErrorMessage } = this.state.AccountSettings;
    let options = () => {
      return (
        <Fragment>
          <option value={1}>Auto Attendant</option>
          <option value={2}>Call Queue</option>
        </Fragment>
      )
    }
    return (
      <Modal show={this.state.showConfirmationModal} onHide={this.handleClose} backdrop='static'>
        <Modal.Header>
          <Modal.Title>{this.state.modalAction[0].toUpperCase() + this.state.modalAction.substring(1)} Resource Account</Modal.Title>
        </Modal.Header>
        {this.state.modalAction === "delete" ? null : <Modal.Body>
          <div>
            {this.dataRow("Display Name", this.inputDataField(DisplayName, "DisplayName", this.handleInputChange))}
            <br />
            {this.dataRow("Username", this.usernameField(UserPrincipalName, Domain))}
            <br />
            {this.dataRow("Resource Account Type", this.selectField(AccountType, "AccountType", options()))}
            {/* {this.dataRow("Phone Number",  <TNSearch
            onSelectUser={this.handleUserChange}
            IsResourceAccount={true}
          />)} */}
          </div>
        </Modal.Body>}
        <Modal.Footer>
          <hr />
          {this.state.submittedTicket ? <DataLoadingSpinner className='load spinner' /> : <div className="btns">
            <span>
              <button type="button" className="btn" onClick={() => this.handleManageResourceAccount(this.state.modalAction)}>Save</button>
              <button className="btn" onClick={() => this.setState({ showConfirmationModal: false })}>Cancel</button>
            </span>
          </div>}
        </Modal.Footer>
      </Modal>
    )
  }

  handleInputChange = async (e, key) => {
    let { value } = e.target;
    let AccountSettings = { ...this.state.AccountSettings };
    AccountSettings[key] = value;
    await this.setState({ AccountSettings });
    //console.log("AccountSettings", AccountSettings); // inserted AD Information
  }

  dataRow = (label, field) => {
    return (
      <div className="form-group-wrapper"
        style={{ margin: '1%', }}
      >
        <label
          style={{
            //width: '50%',
            display: 'block',
          }}
        >{label} </label>
        {field}
      </div >
    )
  }

  inputDataField = (val, key, onChangeFunc, type = 'text') => {
    return (
      <input
        style={{
          width: '45%',
          display: 'inline-block',
        }}
        type={type}
        name={key}
        value={val}
        className="form-control"
        onChange={(e) => onChangeFunc(e, key)}
      />
    )
  }

  usernameField = (username, domain) => {
    let options = () => {
      if (this.state.DomainList && this.state.DomainList.length > 0) {
        console.log("ITEM", this.state.DomainList)
        return this.state.DomainList.map(itm => {
          if (itm && itm.IsVerified) {
            return <option value={itm.Name}>{itm.Name}</option>
          }
        })
      } else {
        return <option value={""}>No domain</option>
      }
    }
    return (
      <Fragment>
        {this.inputDataField(username, "UserPrincipalName", this.handleInputChange)}
        {" @ "}
        {this.selectField(domain, "Domain", options())}
      </Fragment>
    )
  }


  selectField = (val, key, options) => {
    return (
      <div
        style={{
          width: '45%',
          display: 'inline-block',
        }}
      >
        <select className="form-control"
          onChange={(e) => {
            let { value } = e.target;
            if (key === "AccountType") {
              value = parseInt(value);
            }
            let AccountSettings = { ...this.state.AccountSettings };
            AccountSettings[`${key}`] = value;
            this.setState({ AccountSettings });
          }}
          value={val}
        >
          <option value={""}>--Select--</option>
          {options}
        </select>
      </div>
    )
  }

  cardContent = () => {
    return (
      <div className="card-content" style={{marginTop:'60px'}}>
        <div className="card-actions">
          <div className="search">
            <form ref={'searchTerm'} onSubmit={e => { e.preventDefault() }}>
              <label htmlFor="services_srch_tb" className="hidden">services search</label>
              <input id="services_srch_tb" ref={'searchTerm'} type="text" className="form-control search-control"
                autoComplete={"off"}
                onChange={this.searchListings}
              />
              <svg width="14" height="14" viewBox="0 0 23 23"><path d="m347 238c0-36-12-66-37-91-25-25-55-37-91-37-35 0-65 12-90 37-25 25-38 55-38 91 0 35 13 65 38 90 25 25 55 38 90 38 36 0 66-13 91-38 25-25 37-55 37-90z m147 237c0 10-4 19-11 26-7 7-16 11-26 11-10 0-19-4-26-11l-98-98c-34 24-72 36-114 36-27 0-53-5-78-16-25-11-46-25-64-43-18-18-32-39-43-64-10-25-16-51-16-78 0-28 6-54 16-78 11-25 25-47 43-65 18-18 39-32 64-43 25-10 51-15 78-15 28 0 54 5 79 15 24 11 46 25 64 43 18 18 32 40 43 65 10 24 16 50 16 78 0 42-12 80-36 114l98 98c7 7 11 15 11 25z" transform="scale(0.046875 0.046875)"></path></svg>
            </form>
          </div>
        </div>
        <div className="users-row" style={{ position: 'relative', left: '-82px', top:'-36px' }}>
          <div className='users-item'>
            {this.showStatusLegend()}
          </div>
        </div>
        <div className="users-row" style={{ position: 'relative', top: '-43px' }}>
          <div className='users-item'>
            <span>{this.exportBtn()}</span><p> </p><span>{this.uploadBtn()}</span>
          </div>
         
        </div>
        <div className="users-row" style={{ position: 'relative', top: '-14px' }}>
            <div className='users-item'>
            {this.configurationLinks()}
            {this.btnGroup()}
            </div>
          </div>
        {this.manageModal()}
        <div className="card-content">{this.gridContent()}</div>
       
        {this.submissionMsg()}
      </div >
    );
  }

  btnGroup = () => {
    let btn = (description, action) => <a className="a btn" onClick={() => this.setState({ showConfirmationModal: true, modalAction: action })}>{description}</a>
    return (
      <div className="btn-container">
        {btn("Add Resource Account", "create")}
        {/* {btn("Modify", "modify")}
        {btn("Delete", "delete")} */}
      </div>
    )
  }

  
  configurationLinks = () => {
    let cc = "https://admin.teams.microsoft.com/call-queues";
    let aa = "https://admin.teams.microsoft.com/auto-attendants";
    let link = (name, link) => <small style={{ display: "block", color: '#97999b' }}><a style={{color: '#6c8bad'}} href={link} target="_blank">Configure your Teams {name}</a> (opens in new tab)</small>
    return (
      <div className="config-link-container" style={{position:'relative', left:'-136px'}}>
        {link("Call Queues", cc)}
        {link("Auto Attendants", aa)}
      </div>
    )
  }

  handleManageResourceAccount = async () => {
    await this.setState({ submittedTicket: true });
    let { loading, Trunk, Enterprise, AccountSettings, modalAction } = this.state;
    let path = "admin/ModifyResourceAccount"; //modify by default
    AccountSettings.Username = sessionStorage.username;
    AccountSettings.EnterpriseId = Enterprise;
    AccountSettings.TrunkName = Trunk.TrunkName;
    // let dto = {
    //   UserName: sessionStorage.username,//"prodnewsubsite@momentumtelecom.com",
    //   EnterpriseId: Enterprise,
    //   TrunkName: Trunk.TrunkName,
    //   UserPrincipalName: "aa2@NamasteOnlineYogaStudio.onmicrosoft.com",
    //   JsonParameters: JSON.stringify({
    //     "NewDisplayName": "NamasteUpdated",
    //     "NewDisplayNameSpecified": true,
    //     "NewResourceAccountType": 1,
    //     "NewResourceAccountTypeSpecified": false,
    //     "NewPhoneNumber": "",
    //     "NewPhoneNumberSpecified": false
    //   }),
    // };
    if (modalAction === "create") {
      // create functionality
      path = "admin/CreateResourceAccount";
      delete AccountSettings.JsonParameters;
      AccountSettings.UserPrincipalName += `@${AccountSettings.Domain}`;
      // AccountSettings.AccountType = 1;
      // AccountSettings.DisplayName = "Namaste2";
    }
    if (modalAction === "delete") {
      // delete functionality
      path = "admin/DeleteResourceAccount";
      delete AccountSettings.JsonParameters;
      // delete AccountSettings.DisplayName;
      // delete AccountSettings.AccountType;
    }
    let response = await apiCalls.post(path, "POST", JSON.stringify(AccountSettings)).then(data => data).catch(err => console.error(err));
    if (response) {
      this.props.removeTabRedirectOnSave(false);
      sendErrorMessage(response.message);
      // await this.setState({
      //   submittedTicket: false,
      // });
      this.props.RefreshData();
    }
    console.log("DTO", AccountSettings);
    console.log("PATH", path);
  }

  searchListings = (event) => {
    let searchText = event.target.value.trim();
    let fullDetails = this.state.FullListings;
    if (searchText !== '') {
      searchText = event.target.value;
      let services = filterBy(fullDetails, {
        logic: 'or',
        filters: [
          { field: 'GivenName', operator: 'contains', value: searchText, ignoreCase: true },
          { field: 'TelephoneNumber', operator: 'contains', value: searchText, ignoreCase: true },
          { field: 'StreetAddress', operator: 'contains', value: searchText, ignoreCase: true },
          { field: 'Surname', operator: 'contains', value: searchText, ignoreCase: true },
          { field: 'UserPrincipalName', operator: 'contains', value: searchText, ignoreCase: true },
          { field: 'AccountType', operator: 'contains', value: searchText, ignoreCase: true },
        ]
      });
      this.delayedSearch(services);
    }
    else {
      this.delayedSearch(fullDetails);
    }
  }

  delayedSearch = async (services) => {
    await this.setState({ loading: true });
    if (this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.setState({ UsersList: services, loading: false });
    }, settings.searchDelay);
  }

  render() {
    return this.cardContent();
  };
}