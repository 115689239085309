import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { Button } from '@progress/kendo-buttons-react-wrapper';
import SetPrompts from './../../setPrompts';
import SetSubmenu from './../../setSubmenu';

class LocationAutoAttendantTreeModal extends Component {

  constructor(props) {
    super(props);

    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleTab = this.handleTab.bind(this);
    this.state = { showModal:false,promptValue: false };
  }

  handleClose() {
    this.setState({ showModal: false });
  }

  handleShow() {
    this.setState({ showModal: true });
  }

  handleTab(event) {
    event.preventDefault();
    this.setState({promptValue: event.target.dataset.val})
  }

  componentDidMount() {   
  }
  render() {
    
    return (
      <div className="setting">
        <span onClick={this.handleShow}> 
          <span className="name">Auto Attendant Tree</span>
          <a className="icon btn"><svg width="14" height="14" viewBox="0 0 24 24"><path d="m163 440l-91-91 251-250 90 90z m309-352l-48-48c-12-11-32-11-45 2l-45 45 91 91 45-45c13-13 13-33 2-45z m-408 275l-32 117 117-32z" transform="scale(0.046875 0.046875)"></path></svg></a>
        </span>
        <Modal show={this.state.showModal} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Auto Attendant Tree</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <table className="table">
                <thead>
                  <tr>
                    <th>Phone Number</th>
                    <th>Extension</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>2058036381</td>
                    <td>6381</td>
                    <td align="right">
                      <a className="icon btn"  data-toggle="collapse" data-target="#ar1"><svg width="14" height="14" viewBox="0 0 24 24"><path d="m163 440l-91-91 251-250 90 90z m309-352l-48-48c-12-11-32-11-45 2l-45 45 91 91 45-45c13-13 13-33 2-45z m-408 275l-32 117 117-32z" transform="scale(0.046875 0.046875)"></path></svg></a>
                    </td>
                  </tr>
                  <tr className="collapse" id="ar1">
                    <td colSpan="3">
                      <div className="table-edit">
                        <div className="form-group">
                          <label className="switch">
                            <input type="checkbox" />
                            <span className="slider round"></span>
                          </label>
                          Active?
                        </div>
                        <div className="form-group">
                          <label>Name</label>
                          <input type="type" className="form-control" defaultValue="" />
                        </div>
                        <div className="halves">
                          <div className="half">
                            <div className="form-group">
                              <label>Calling Line Id First Name</label>
                              <input type="type" className="form-control" defaultValue="" />
                            </div>
                          </div>
                          <div className="half">
                            <div className="form-group">
                              <label>Calling Line Id Last Name</label>
                              <input type="type" className="form-control" defaultValue="" />
                            </div>
                          </div>
                        </div>
                        <div className="halves">
                          <div className="half">
                            <div className="form-group">
                              <label>Department</label>
                              <select className="form-control">
                                  <option>Department 1</option>
                                  <option>Department 2</option>
                                  <option>Department 3</option>
                              </select>
                            </div>
                          </div>
                          <div className="half">
                            <div className="form-group">
                              <label>Language</label>
                              <select className="form-control">
                                  <option>Language 1</option>
                                  <option>Language 2</option>
                                  <option>Language 3</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="halves">
                          <div className="half">
                            <div className="form-group">
                              <label>Time Zone</label>
                              <select className="form-control">
                                  <option>Timezone 1</option>
                                  <option>Timezone 2</option>
                                  <option>Timezone 3</option>
                              </select>
                            </div>
                          </div>
                          <div className="half">
                            <div className="form-group">
                              <label>Network Class of Service</label>
                              <select className="form-control">
                                  <option>Net 1</option>
                                  <option>Net 2</option>
                                  <option>Net 3</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="form-group">
                          <label className="switch">
                            <input type="checkbox" />
                            <span className="slider round"></span>
                          </label>
                          Enable video support
                        </div>
                        <div className="form-group">
                          <label>Scope of extension dialing</label>
                          <div className="form-control">
                            <label className="custom-control custom-radio">
                            <input id="radio1" name="radio" type="radio"/>
                              <span >Enterprise</span>
                            </label> 
                            <label className="custom-control custom-radio">
                              <input id="radio1" name="radio" type="radio"/>
                              <span>Group</span>
                            </label>
                            <label className="custom-control custom-radio">
                              <input id="radio1" name="radio" type="radio"/>
                              <span>Department</span>
                            </label>
                          </div>
                        </div> 
                        <div className="form-group">
                          <label>Scope of name dialing</label>
                          <div className="form-control">
                            <label className="custom-control custom-radio">
                              <input id="radio1" name="radio" type="radio"/>
                              <span >Enterprise</span>
                            </label> 
                            <label className="custom-control custom-radio">
                              <input id="radio1" name="radio" type="radio"/>
                              <span>Group</span>
                            </label>
                            <label className="custom-control custom-radio">
                              <input id="radio1" name="radio" type="radio"/>
                              <span>Department</span>
                            </label>
                          </div>
                        </div>
                        <div className="form-group">
                          <label>Name dialing entries</label>
                          <div className="form-control">
                            <label className="custom-control custom-radio">
                              <input id="radio1" name="radio" type="radio"/>
                              <span >LastName + FirstName</span>
                            </label> 
                            <label className="custom-control custom-radio">
                              <input id="radio1" name="radio" type="radio"/>
                              <span>LastName + FirstName and FirstName + LastName</span>
                            </label>
                          </div>
                        </div>
                        <div className="form-group">
                          <label>Transfer to the operator after how many seconds of inactivity</label>
                          <input type="type" className="form-control" defaultValue="" />
                        </div>
                        <hr />
                        <div className="btns">
                          <a href="" className="btn">Save</a>
                          <a href="" className="btn">Cancel</a>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
              <hr />
              <div className="tabs">
                <div className="active tab">
                  <a href="" onClick={this.handleTab} data-val="biz">Business Hour Prompts</a>
                </div>
                <div className="tab">
                  <a href="" onClick={this.handleTab} data-val="after">After Hour Prompts</a>
                </div>
                <div className="tab">
                  <a className="" onClick={this.handleTab} data-val="holiday">Holiday Prompts</a>
                </div>
                <div className="tab">
                  <a className="" onClick={this.handleTab} data-val="submenus">Submenus</a>
                </div>
              </div>
              <div className="tab-body">
                {this.getPromptControl()}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
              <hr />
              <div className="btns">
                <Button className="btn" click={this.handleClose}>Close</Button>
              </div>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
  getPromptControl(){
    switch(this.state.promptValue){
      case "biz":
        return (
          <SetPrompts />
        );
      case "after":
        return (
          <SetPrompts />
        );
      case "holiday":
        return (
          <SetPrompts />
        );
      case "submenus":
        return (
          <SetSubmenu />
        );
      default:
        return (
          <div className="empty">Select a tab to set prompts</div>
        );
    }
  }


};

export default LocationAutoAttendantTreeModal;
