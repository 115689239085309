import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { Button } from '@progress/kendo-buttons-react-wrapper';

// <ExampleModal />

class CallForwardingModal extends Component {

  constructor(props) {
    super(props);

    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);

    this.state = { showModal: false };
  }

  handleClose() {
    this.setState({ showModal: false });
  }

  handleShow() {
    this.setState({ showModal: true });
  }

  componentDidMount() {   
  }

  render() {
    return (
      <div className="setting">
        <span onClick={this.handleShow}> 
          <span className="name">Call Forwarding</span>
          <a className="icon btn"><svg width="14" height="14" viewBox="0 0 24 24"><path d="m163 440l-91-91 251-250 90 90z m309-352l-48-48c-12-11-32-11-45 2l-45 45 91 91 45-45c13-13 13-33 2-45z m-408 275l-32 117 117-32z" transform="scale(0.046875 0.046875)"></path></svg></a>
        </span>

        <Modal show={this.state.showModal} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Call Forwarding</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <p>Forward your calls to another number.</p>
              <hr />
              <div className="form-group">
                <label className="switch right">
                  <input type="checkbox" data-toggle="collapse" data-target="#cf1" />
                  <span className="slider round"></span>
                </label>
                Call Forward Always
                <div className="hidden-group collapse" id="cf1">
                  <label >Forward to</label>
                  <input type="text" className="form-control" />
                </div>
              </div>
              <div className="form-group">
                <label className="switch right">
                  <input type="checkbox" data-toggle="collapse" data-target="#cf2" />
                  <span className="slider round"></span>
                </label>
                Call Forward Don't Answer
                <div className="hidden-group collapse" id="cf2">
                  <div className="form-group">
                    <label >Forward to</label>
                    <input type="text" className="form-control" />
                  </div>
                  <div className="form-group">
                    <label >Number of rings before forwarding:</label>
                    <select name="" id="" className="nums form-control">
                      <option defaultValue="">1</option>
                      <option defaultValue="">2</option>
                      <option defaultValue="">3</option>
                      <option defaultValue="">4</option>
                      <option defaultValue="" selected>5</option>
                      <option defaultValue="">6</option>
                      <option defaultValue="">7</option>
                      <option defaultValue="">8</option>
                      <option defaultValue="">9</option>
                      <option defaultValue="">10</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="form-group">
                <label className="switch right">
                  <input type="checkbox" data-toggle="collapse" data-target="#cf3" />
                  <span className="slider round"></span>
                </label>
                Call Forward Busy Line
                <div className="hidden-group collapse" id="cf3">
                  <label >Forward to</label>
                  <input type="text" className="form-control" />
                </div>
              </div>
              <div className="form-group">
                <label className="switch right">
                  <input type="checkbox" data-toggle="collapse" data-target="#cf4" />
                  <span className="slider round"></span>
                </label>
                Call Forward Not Reachable
                <div className="hidden-group collapse" id="cf4">
                  <label >Forward to</label>
                  <input type="text" className="form-control" />
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
              <hr />
              <div className="btns">
                <a href="" className="btn" >Save</a>
                <a href="" className="btn" data-dismiss="modal">Close</a>
              </div>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
};

export default CallForwardingModal;
