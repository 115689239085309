import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { Button } from '@progress/kendo-buttons-react-wrapper';
import _ from 'lodash';


class VirtualNumberModal extends Component {

  constructor(props) {
    super(props);

    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleAddPhoneNumber = this.handleAddPhoneNumber.bind(this);
    this.state = { showModal: false, numberOfPhoneCount:1, numberOfPhoneLimit:10 };
  }

  handleClose() {
    this.setState({ showModal: false });
  }

  handleShow() {
    this.setState({ showModal: true });
  }
  handleAddPhoneNumber(){
    if(this.state.numberOfPhoneCount < 10)
      this.setState({ numberOfPhoneCount: this.state.numberOfPhoneCount + 1 });
  }

  componentDidMount() {   
  }

  render() {
    let phoneNumberCountReachLimit = this.state.numberOfPhoneCount >= this.state.numberOfPhoneLimit;
    return (
      <div className="setting">
        <span onClick={this.handleShow}> 
          <span className="name">Virtual Number</span>
          <a className="icon btn"><svg width="14" height="14" viewBox="0 0 24 24"><path d="m163 440l-91-91 251-250 90 90z m309-352l-48-48c-12-11-32-11-45 2l-45 45 91 91 45-45c13-13 13-33 2-45z m-408 275l-32 117 117-32z" transform="scale(0.046875 0.046875)"></path></svg></a>
        </span>

        <Modal show={this.state.showModal} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Virtual Number Settings</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Virtual Numbers allows up to ten additional phone numbers and extensions to your primary number and extension. All additional numbers ring your phone(s) just like your primary phone. You can specify a distinctive ringing pattern for each number, if your phone supports it. Only your administrator can configure new numbers and extensions for you.</p>
            <div>
              <table className="table">
                <thead>
                  <tr>
                    <th className="check"><input type="checkbox" /></th>
                    <th>Phone Number</th>
                    <th>Description</th>
                    <th>Ring Pattern</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="check"><input type="checkbox" /></td>
                    <td>123 123 1234</td>
                    <td>lorem ipsum</td>
                    <td>Default</td>
                    <td align="right"><a className="icon btn" data-toggle="collapse" data-target="#sca1"><svg width="14" height="14" viewBox="0 0 24 24"><path d="m163 440l-91-91 251-250 90 90z m309-352l-48-48c-12-11-32-11-45 2l-45 45 91 91 45-45c13-13 13-33 2-45z m-408 275l-32 117 117-32z" transform="scale(0.046875 0.046875)"></path></svg></a></td>
                  </tr>
                  <tr className="collapse" id="sca1">
                    <td colSpan="5">
                      <div className="table-edit">
                        <a href="" className="red delete">Delete</a>
                        <div className="form-group">
                          <label>Phone Number</label>
                          <select className="form-control">
                            <option defaultValue="">-Select-</option>
                            <option defaultValue="">Phone 1</option>
                          </select>
                        </div>
                        <div className="form-group">
                          <label>Extension</label>
                          <input type="type" className="form-control" defaultValue="" />
                        </div>
                        <div className="form-group">
                          <label>Ring Pattern</label>
                          <select className="form-control">
                            <option defaultValue="">-Select-</option>
                            <option defaultValue="">Normal</option>
                            <option defaultValue="">Long-Long</option>
                            <option defaultValue="">Short-Short-Long</option>
                            <option defaultValue="">Short-Long-Short</option>
                          </select>
                        </div>
                        <div className="form-group">
                          <label>Description</label>
                          <input type="type" className="form-control" defaultValue="" />
                        </div>
                        <hr />
                        <div className="btns">
                          <a href="" className="btn">Save</a>
                          <a href="" className="btn">Cancel</a>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Modal.Body>
          <Modal.Footer>
              <hr />
              <div className="btns">
              <Button className="btn" click={this.handleClose}>Close</Button>
              </div>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
};

export default VirtualNumberModal;
