import React from 'react';
import { GridCell } from '@progress/kendo-react-grid';



class WrappedCell extends GridCell {

  render() {
    let fieldName = this.props.field;
    return (
      <td>
        <div style={{"whiteSpace": "-moz-pre-wrap", "wordWrap": "break-word", "wordBreak": "break-word"}}>{this.props.dataItem[fieldName]}</div>

      </td>
    )
  }
}

export default WrappedCell;