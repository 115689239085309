import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { Button } from '@progress/kendo-buttons-react-wrapper';
import VoicemailSettingsModal from './modals/voicemailSettingsModal';
import * as EmailValidator from 'email-validator';
import { fetchData, post } from './../requests/api_calls';
import format from 'string-format';
import * as config from './../requests/config';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'; // ES6
import { DataLoadingSpinner } from './../components/spinners';

class VoicemailSettingsButton extends Component {

  constructor(props) {
    super(props);

    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.changeForward = this.changeForward.bind(this);

    this.state = { 
      //settings_available: false,
      showModal: false,
      loading: true, 
      loading_error: "",
      saving_loading: false,
      saving_show_success: false,
      saving_error_message: "",
      voice_messaging: {
        selected: false,
        api:  "VoiceMessage",
      },
      send_all_calls_to_vm: {
        selected: false,
        api:  "SendAllCallsToVoicemail"
      },
      send_busy_calls_to_vm: {
        selected: false,
        api:  "SendBusyCallsToVoicemail"
        
      },
      send_unanswered_calls_to_vm: {
        forward_value: null,
        selected: false,
        api:  "SendUnansweredCallstoVoicemail",
        email: "",
        waiting_indicator: false
      },
      forward_to_email: {
        api:  "ForwardToEmail",
        processing: "", //multiple or single
        email: "",
        email_is_valid:true,
        waiting_indicator: false
      },
      notify_me_email_when_new_messages_arrive: {
        selected: false,
        api:  "NotifyMebyEmailwhennewmessagesarrive",
        email: "",
        email_is_valid:true
      },
      email_carbon_copy_message: {
        selected: false,
        api:  "EmailaCarbonCopyoftheMessage",
        email: "",
        email_is_valid:true
      },
      transfer_on_0_to_phone_number: {
        selected: false,
        api:  "Transfer_0_to_Phone_Number",
        phone_number: ""
      } ,
      PostData: {
        ServiceNumber: this.props.ServiceNumber,
        PBXUserID: this.props.PBXUserID,
        //Voice Messaging
        isActive:false,
        //Send All Calls to Voicemail
        alwaysRedirectToVoiceMail:false,
        //Send Busy Calls to Voicemail
        busyRedirectToVoiceMail:false,
        //Send Unanswered Calls to Voicemail
        noAnswerRedirectToVoiceMail:false,
        //Forward to multiple email / Forward to one email
        processing:false,
        //Forward email
        voiceMessageDeliveryEmailAddress:"",
        //Phone Message Waiting Indicator
        usePhoneMessageWaitingIndicator:false,
        //Notify me by Email when new message arrive
        sendVoiceMessageNotifyEmail:false,
        voiceMessageNotifyEmailAddress:"",
        //Carbon copy
        sendCarbonCopyVoiceMessage:false,
        voiceMessageCarbonCopyEmailAddress:"",
        //Transfer on '0' to Phone Number
        transferOnZeroToPhoneNumber:false,
        transferPhoneNumber:"",
        outOfPrimaryZoneRedirectToVoiceMail:false
      }
    };
  }

  changeOnOffValue =(setting, event)=>{
    let checked = event.target.checked;
    setting.selected = checked;
    this.updateSetting(setting);
  }

  changeEmailValue =(setting, event)=>{
    let email = event.target.value.trim();
    setting.email = email;
    setting.email_is_valid = EmailValidator.validate(email);
    if(setting.api === this.state.send_unanswered_calls_to_vm.api){
      if(this.state.send_unanswered_calls_to_vm.processing === "0"){
        setting.email_is_valid = EmailValidator.validate(email);
        let emailsArray = setting.email.split(',');
        for(let cnt = 0; cnt < emailsArray.length; cnt ++){
          if(!EmailValidator.validate(emailsArray[cnt]))
            setting.email_is_valid = false;
        }
      }
    }
    this.updateSetting(setting);
  }

  changeWaitingIndicator =(setting, event)=>{
    let checked = event.target.checked;
    setting.waiting_indicator = checked;
    this.updateSetting(setting);
  }

  changePhoneNumber =(setting, event)=>{
    let value = event.target.value.trim();
    setting.phone_number = value;
    this.updateSetting(setting);
  }

  updateSetting=(setting)=>{
    switch(setting.api){
      case this.state.voice_messaging.api:
        this.setState({...{voice_messaging: setting}})
        break;
      case this.state.send_all_calls_to_vm.api:
        this.setState({...{send_all_calls_to_vm: setting}})
        break;
      case this.state.send_busy_calls_to_vm.api:
        this.setState({...{send_busy_calls_to_vm: setting}})
        break;
      case this.state.send_unanswered_calls_to_vm.api:
        this.setState({...{send_unanswered_calls_to_vm: setting}})
        break;
      case this.state.notify_me_email_when_new_messages_arrive.api:
        this.setState({...{notify_me_email_when_new_messages_arrive: setting}})
        break;
      case this.state.email_carbon_copy_message.api:
        this.setState({...{email_carbon_copy_message: setting}})
        break;
      case this.state.transfer_on_0_to_phone_number.api:
        this.setState({...{transfer_on_0_to_phone_number: setting}})
        break;
      case this.state.forward_to_email.api:
        this.setState({...{forward_to_email: setting}})
        break;
      default:
        break;
    }
  }

  handleClose() {
    this.setState({...this.state, showModal: false });
  }

  handleSave=()=> {
    let state = this.state;
    state.saving_loading = true;
    state.saving_show_success = false;
    

    let PostData = state.PostData;
    PostData.AccountNumber = this.props.AccountNumber;
    PostData.DirectoryNumber = this.props.DirectoryNumber;
    PostData.isActive = this.state.voice_messaging.selected;
    PostData.alwaysRedirectToVoiceMail = this.state.send_all_calls_to_vm.selected;
    PostData.busyRedirectToVoiceMail = this.state.send_busy_calls_to_vm.selected;
    PostData.noAnswerRedirectToVoiceMail = this.state.send_unanswered_calls_to_vm.selected;
    
    if(this.state.forward_to_email.processing === "0"){
      PostData.processing = "0";
    }else if(this.state.forward_to_email.processing === "1"){
      PostData.processing = "1";
    }

    PostData.voiceMessageDeliveryEmailAddress = this.state.forward_to_email.email;
    PostData.usePhoneMessageWaitingIndicator = this.state.forward_to_email.waiting_indicator;

    PostData.sendVoiceMessageNotifyEmail = this.state.notify_me_email_when_new_messages_arrive.selected;
    PostData.voiceMessageNotifyEmailAddress = this.state.notify_me_email_when_new_messages_arrive.email;

    PostData.sendCarbonCopyVoiceMessage = this.state.email_carbon_copy_message.selected;
    PostData.voiceMessageCarbonCopyEmailAddress = this.state.email_carbon_copy_message.email;

    PostData.transferOnZeroToPhoneNumber = this.state.transfer_on_0_to_phone_number.selected;
    PostData.transferPhoneNumber = this.state.transfer_on_0_to_phone_number.phone_number;
    this.setState(state);

    let body = JSON.stringify(PostData);
		let headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + config.storedAccessToken(),
      'X-Policy': config.storedXPolicy()
    };
    post("usr/VMSettings","POST", body, headers)
		.then(response =>{
      let state = {...this.state};
			if(response.ok){
        state.saving_show_success = true;
				state.saving_loading = false;
        state.saving_error_message = "";
        this.props.OnVMSettingChange(this.state.PostData);
			}
			else{
				state.saving_loading = false;
				state.saving_error_message = "Failed Save";
      }
      this.setState(state);
		})
		.catch((message)=>{
      console.log(message);
      let state = {...this.state};
			state.saving_loading = false;
      state.saving_error_message = "Failed Save";
      this.setState(state);
		});
  }

  handleShow() {
    this.setState({...this.state, showModal: true,saving_show_success:false, loading: true, saving_error_message:"", });
    let apiPath = format('usr/VMSettings?AccountNumber={AccountNumber}&DirectoryNumber={DirectoryNumber}&ServiceNumber={ServiceNumber}&PBXUserID={PBXUserID}',this.props);
    fetchData(apiPath).then((data) => {
      let newState = this.getNewState(data);	
      this.setState(newState);
    }).catch((message)=>{
      let state = {...this.state};
      state.loading = false;
      state.loading_error = message.toString();
      this.setState(state);
    });
  }

  changeForward(event){
    let setting = this.state.forward_to_email;
    setting.processing = event.target.value;
    this.updateSetting(setting);
  }

	componentDidMount() {	
    let apiPath = format('usr/VMSettings?AccountNumber={AccountNumber}&DirectoryNumber={DirectoryNumber}&ServiceNumber={ServiceNumber}&PBXUserID={PBXUserID}',this.props);
    fetchData(apiPath).then((data) => {
      let newState = this.getNewState(data);	
      this.setState(newState);
    }).catch((message)=>{
      console.log(message);
      let state = {...this.state};
      state.loading = false;
      state.loading_error = message
      this.setState(state);
    });
	}

  getNewState = (data) => {
    let state = {...this.state};
    state.loading = false;
    if (data !== null) {
      state.PostData = data;
      state.loading_error = "";
      state.voice_messaging.selected = data.isActive;
      state.send_all_calls_to_vm.selected = data.alwaysRedirectToVoiceMail;
      state.send_busy_calls_to_vm.selected = data.busyRedirectToVoiceMail;
      state.send_unanswered_calls_to_vm.selected = data.noAnswerRedirectToVoiceMail;

      if(data.processing === 0)
        state.forward_to_email.processing = "0";
      else if(data.processing === 1)
        state.forward_to_email.processing = "1";
      
      state.forward_to_email.email = data.voiceMessageDeliveryEmailAddress;
      state.forward_to_email.waiting_indicator = data.usePhoneMessageWaitingIndicator;
      state.forward_to_email.email_is_valid = true;

      data.voiceMessageNotifyEmailAddress =  data.voiceMessageNotifyEmailAddress?data.voiceMessageNotifyEmailAddress: "";
      state.notify_me_email_when_new_messages_arrive.selected = data.sendVoiceMessageNotifyEmail;
      state.notify_me_email_when_new_messages_arrive.email = data.voiceMessageNotifyEmailAddress;
      state.notify_me_email_when_new_messages_arrive.email_is_valid = true;

      
      data.voiceMessageCarbonCopyEmailAddress =  data.voiceMessageCarbonCopyEmailAddress?data.voiceMessageCarbonCopyEmailAddress: "";
      state.email_carbon_copy_message.selected = data.sendCarbonCopyVoiceMessage;
      state.email_carbon_copy_message.email = data.voiceMessageCarbonCopyEmailAddress;
      state.email_carbon_copy_message.email_is_valid = true;

      data.transferPhoneNumber =  data.transferPhoneNumber?data.transferPhoneNumber: "";
      state.transfer_on_0_to_phone_number.selected = data.transferOnZeroToPhoneNumber;
      state.transfer_on_0_to_phone_number.phone_number = data.transferPhoneNumber;
      //state.settings_available = true;
    }
    else{
      //state.settings_available = false;
    }
    return state;
    
  }
  render() {
    // if(!this.state.settings_available)
    //   return (<span></span>);

    return (
      <span>
        <a className="btn" onClick={this.handleShow}>Voicemail Settings</a>
        <Modal show={this.state.showModal} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Voicemail Settings</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <VoicemailSettingsModal  
              model={this.state}
              onHandleChangeOnOffValue={(e,b) => this.changeOnOffValue(e, b)} 
              onHandleChangeEmail={(e,b) => this.changeEmailValue(e, b)}
              onHandleChangeWaitingIndicator = {(e,b) => this.changeWaitingIndicator(e, b)}
              onHandleChangeForward={this.changeForward}
              onHandleChangePhone={(e,b) => this.changePhoneNumber(e, b)}
              />
          </Modal.Body>
          <Modal.Footer>
              <hr />
              <div className="btns">
              {this.getSaveButton()}
              <Button className="btn" click={this.handleClose}>Cancel</Button>
              </div>
              {this.getSaveMessage()}
          </Modal.Footer>
        </Modal>
      </span>
    );
  }
  getSaveButton=()=>{
    let forwardToEmailValid = this.state.forward_to_email.selected ? this.state.forward_to_email.email_is_valid: true;
    let notifyMeEmailValid = this.state.notify_me_email_when_new_messages_arrive.selected ? this.state.notify_me_email_when_new_messages_arrive.email_is_valid: true;
    let emailCarbonCopyValid = this.state.email_carbon_copy_message.selected ? this.state.email_carbon_copy_message.email_is_valid: true;
    let enableSave = forwardToEmailValid && notifyMeEmailValid && emailCarbonCopyValid;

    if(this.state.loading || this.state.saving_loading || !enableSave)
      return (<Button key="1" className="btn" enable={!enableSave}>Save</Button>);
    else
      return (<Button key="2" className="btn" click={this.handleSave}>Save</Button>);
  }
  getSaveMessage=()=>{
    if(this.state.saving_show_success){
      return (<ReactCSSTransitionGroup
        transitionName="vm-setting-success"
        transitionAppear={true}
        transitionAppearTimeout={4000}
        transitionEnter={true}
        transitionEnterTimeout={4000}
        transitionLeave={false}
        >
        <div className="inline-success text-center">Success!</div>
      </ReactCSSTransitionGroup>)
    }
    else if(this.state.saving_loading){
      return (<DataLoadingSpinner className='load spinner'/>)
    }
    else if(this.state.saving_error_message){
      return (<div className="inline inline-fail text-center">{this.state.saving_error_message}</div>)
    }
  }
};



export default VoicemailSettingsButton;
