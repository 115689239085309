import React, { Component } from 'react';

class SuperLink extends Component {

  render() {
    return(
      <a href={ this.props.url } className="no-btn">{ this.props.label }</a>
    );
  }
}

export default SuperLink;