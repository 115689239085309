import React, { Component } from 'react';
import { connect } from 'react-redux';
import format from 'string-format';
import * as apiCalls from '../requests/api_calls';
import moment from 'moment';
import { DatePicker } from '@progress/kendo-dateinputs-react-wrapper';
import * as settings from '../scripts/settings';
import * as config from '../requests/config';
import BSCallLogsTab from '../components/bs_call_logs_tab';
import SansayCallLogsTab from '../components/sansay_call_logs_tab';
import FaxCallLogsTab from '../components/fax_call_logs_tab';

class CallLogsAll extends Component {
  constructor(props) {
    super(props);
    this.state = {
      LocationAccountNumber: '',
      BSCallLogs: [],
      SansayCallLogs: [],
      FaxCallLogs: [],
      tab: 'bs',
      loading: false,
      call_category: (sessionStorage.usercl_call_category || "1"),
      call_type: (sessionStorage.usercl_call_type || "1"),
      status_type: (sessionStorage.usercl_status_type || "1"),
      date_type: (sessionStorage.usercl_date_type || "1"), // Today
      from_date: (sessionStorage.usercl_from_date ||moment(Date.now()).startOf("day").format("YYYY-MM-DD")),
      to_date: (sessionStorage.usercl_to_date || moment(Date.now()).endOf("day").format("YYYY-MM-DD")),
      PBXUserID: props.match.params.PBXUserID && props.match.params.PBXUserID !== '1' ? props.match.params.PBXUserID : '',
      ServiceNumber: props.match.params.ServiceNumber && props.match.params.ServiceNumber !== '1' ? props.match.params.ServiceNumber : ''
    }
    this.handleCallCategory = this.handleCallCategory.bind(this);
    this.handleCallType = this.handleCallType.bind(this);
    this.handleStatusType = this.handleStatusType.bind(this);
    this.handleDate = this.handleDate.bind(this);
    this.handleChangeFromDate = this.handleChangeFromDate.bind(this);
    this.handleChangeToDate = this.handleChangeToDate.bind(this);
    //this.changeTab = this.changeTab.bind(this);
  };

  componentDidMount() {
    this.fetchCallLogs(this.getApiUrl(), settings.noResultsMsg);
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ ...{ BSCallLogs: [], SansayCallLogs: [], FaxCallLogs: [], loading: true } });
    this.fetchCallLogs(this.getApiUrl(nextProps), settings.noResultsMsg);
  }

  UpdateStoreAndListings = () => {
    settings.setCLFilters(this.state.call_type, this.state.status_type, this.state.call_category, 
      this.state.date_type, this.state.from_date, this.state.to_date, this.state.PBXUserID
     );
     this.fetchCallLogs(this.getApiUrl(this.props), settings.noResultsMsg);
  }

  getApiUrl() {
    let newParams = this.props.match.params;
    if (newParams.PBXUserID === '1')
      newParams.PBXUserID = '';
    if (newParams.ServiceNumber === '1')
      newParams.ServiceNumber = ''

    let finalToDate = this.state.to_date + '%2023:59:59';
    let newOptions = {
      call_type: this.state.call_type,
      status_type: this.state.status_type,
      call_category: this.state.call_category,
      from_date: this.state.from_date,
      to_date: finalToDate
    };

    let apiUrl = format('usr/GetCallLogs?LocationAccountNumber={LocationAccountNumber}&PBXUserID={PBXUserID}&ServiceNumber={ServiceNumber}', newParams);
    apiUrl += format('&CallType={call_type}&CallStatus={status_type}&CallCategory={call_category}&FromDate={from_date}&ToDate={to_date}', newOptions);
    return apiUrl;
  }

  fetchCallLogs = async (apiPath) => {
    let selectedTab = this.state.tab;
    await this.setState({ loading: true });
    apiCalls.fetchData(apiPath).then(async (data) => {
      if (data !== null && data !== undefined) {
        let { BSCallLogs, SansayCallLogs, FDRCallLogs } = data;
        let CallStatus = this.state.status_type;
        if (BSCallLogs.length === 0 && SansayCallLogs.length > 0)
          selectedTab = 'sansay'
        
        if (SansayCallLogs.length === 0 && BSCallLogs.length > 0) 
          selectedTab = 'bs'
        
        if (BSCallLogs.length === 0 && SansayCallLogs.length === 0 && FDRCallLogs.length > 0) {
          selectedTab = 'fax';
          CallStatus = '1';
        }

        await this.setState({
          BSCallLogs: this.revisedData(BSCallLogs), 
          SansayCallLogs: this.revisedData(SansayCallLogs,true), 
          FaxCallLogs: this.revisedData(FDRCallLogs, false, true),
          loading: false, 
          tab: selectedTab,
          status_type: CallStatus
        });
      } else this.setState({ BSCallLogs: [], SansayCallLogs: [], FaxCallLogs: [], loading: false });
    }).catch((msg) => console.error(msg));
  }

  revisedData = (data, isIPTrunking = false, isFax = false) => {
    let revisedData = [];
    if (isIPTrunking)
      revisedData = data.map((item, idx) => {
        item.Release_Time = new Date(item.Release_Time);
        item.Start_Time = new Date(item.Start_Time);
        return item;
      });
    else if (isFax)
      revisedData = data.map((item, idx) => {
        item.StartTime = new Date(item.StartTime);
        return item;
      });
    else 
      revisedData = data.map((item, idx) => {
                      item.Date = new Date(item.Date);
                      return item;
                    });

    return revisedData;
  }

  handleChangeFromDate = (event) => {
    let from = moment(event.sender._oldText, "M/D/YYYY");
    if (from.isValid()) {
      this.setState({ ...{ from_date: from.format("YYYY-MM-DD") } }, () => {
        let to = moment(this.state.to_date);
        if (to.isValid() && to.isSameOrAfter(from)) {
          this.setState({ ...{ SansayCallLogs: [], BSCallLogs: [], FaxCallLogs: [], loading: true } }, this.UpdateStoreAndListings);
        }
      });
    }
  }

  handleChangeToDate = (event) => {
    let to = moment(event.sender._oldText, "M/D/YYYY");
    if (to.isValid()) {
      this.setState({ ...{ to_date: to.format("YYYY-MM-DD") } }, () => {
        let from = moment(this.state.from_date);
        if (from.isValid() && from.isSameOrBefore(to)) {
          this.setState({ ...{ SansayCallLogs: [], BSCallLogs: [], FaxCallLogs: [], loading: true } }, this.UpdateStoreAndListings);
        }
      });
    }
  }

  handleDate = (event) => {
    switch (event.target.value) {
      case "1":
        this.setState({
          ...{
            SansayCallLogs: [], BSCallLogs: [], FaxCallLogs: [], date_type: event.target.value, from_date: moment(Date.now()).startOf("day").format("YYYY-MM-DD"),
            to_date: moment(Date.now()).endOf("day").format("YYYY-MM-DD"), loading: true
          }
        }, this.UpdateStoreAndListings);
        return;
      case "2":
        this.setState({
          ...{
            SansayCallLogs: [], BSCallLogs: [], FaxCallLogs: [], date_type: event.target.value, from_date: moment(Date.now()).subtract(1, "days").startOf("day").format("YYYY-MM-DD"),
            to_date: moment(Date.now()).endOf("day").subtract(1, "days").format("YYYY-MM-DD"), loading: true
          }
        }, this.UpdateStoreAndListings);
        return;
      case "3":
        this.setState({
          ...{
            SansayCallLogs: [], BSCallLogs: [], FaxCallLogs: [], date_type: event.target.value, from_date: moment(Date.now()).subtract(7, "days").startOf("day").format("YYYY-MM-DD"),
            to_date: moment(Date.now()).endOf("day").format("YYYY-MM-DD"), loading: true
          }
        }, this.UpdateStoreAndListings);
        return;
      case "4":
        this.setState({
          ...{
            SansayCallLogs: [], BSCallLogs: [], FaxCallLogs: [], date_type: event.target.value, from_date: moment(Date.now()).subtract(30, "days").startOf("day").format("YYYY-MM-DD"),
            to_date: moment(Date.now()).endOf("day").format("YYYY-MM-DD"), loading: true
          }
        }, this.UpdateStoreAndListings);
        return;
      case "5":
        this.setState({
          ...{
            SansayCallLogs: [], BSCallLogs: [], FaxCallLogs: [], date_type: event.target.value, from_date: moment(Date.now()).subtract(90, "days").startOf("day").format("YYYY-MM-DD"),
            to_date: moment(Date.now()).endOf("day").format("YYYY-MM-DD"), loading: true
          }
        }, this.UpdateStoreAndListings);
        return;
      case "6":
        this.setState({ ...{ SansayCallLogs: [], BSCallLogs: [], FaxCallLogs: [], date_type: event.target.value, from_date: "From", to_date: "To" } });
        settings.setCLFilters(this.state.call_type, this.state.status_type, this.state.call_category, 
          this.state.date_type, this.state.from_date, this.state.to_date, this.state.PBXUserID
         );
        return;
    }
  }

  handleCallCategory = (event) => {
    this.setState({ ...{ SansayCallLogs: [], BSCallLogs: [], FaxCallLogs: [], call_category: event.target.value, loading: true } }, this.UpdateStoreAndListings);
  }

  handleCallType = (event) => {
    this.setState({ ...{ SansayCallLogs: [], BSCallLogs: [], FaxCallLogs: [], call_type: event.target.value, loading: true } }, this.UpdateStoreAndListings);
  }

  handleStatusType = (event) => {
    this.setState({ ...{ SansayCallLogs: [], BSCallLogs: [], FaxCallLogs: [], status_type: event.target.value, loading: true } }, this.UpdateStoreAndListings);
  }

  exportSansayBtn = () => {
    if (this.state.tab === 'sansay' && this.state.SansayCallLogs.length > 0) {
      return (
        <div id="div-calls-csv" className='pull-right'>
          <form ref='downloadCsv' target="iFrameSave" action={config.baseApiUrl + 'usr/GetSansayCallLogsCSV'} method="POST">
            <input type="hidden" name="LocationAccountNumber" value={this.state.LocationAccountNumber}></input>
            <input type="hidden" name="TrunkID" value={this.props.trunkId} />
            <input type="hidden" name="CallType" value={this.state.call_type}></input>
            <input type="hidden" name="CallStatus" value={this.state.status_type}></input>
            <input type="hidden" name="CallCategory" value={this.state.call_category}></input>
            <input type="hidden" name="FromDate" value={this.state.from_date}></input>
            <input type="hidden" name="ToDate" value={this.state.to_date}></input>
            <input type="hidden" name="access_token" value={config.storedAccessToken()} className='hidden'></input>
            <input type="hidden" name="x_policy" value={config.storedXPolicy()} className='hidden'></input>
          </form>
          <iframe name="iFrameSave" height="0" width="0" className='hidden pull-right'></iframe>
          <a tabIndex="0" className='no-btn' ref='exportBtn' onClick={(e) => this.exportCallLogs()} >Export Call Logs</a>
        </div>
      )
    } else return null;
  }

  exportBsBtn = () => {
    if (this.state.tab === 'bs' && this.state.BSCallLogs.length > 0) {
      return (
        <div id="div-calls-csv" className='pull-right'>
          <form ref='downloadCsv' target="iFrameSave" action={config.baseApiUrl + 'usr/GetBSCallLogsCSV'} method="POST">
            <input type="hidden" name="LocationAccountNumber" value={this.props.match.params.LocationAccountNumber}></input>
            <input type="hidden" name="PBXUserID" value={this.state.PBXUserID} />
            <input type="hidden" name="CallType" value={this.state.call_type}></input>
            <input type="hidden" name="CallStatus" value={this.state.status_type}></input>
            <input type="hidden" name="CallCategory" value={this.state.call_category}></input>
            <input type="hidden" name="FromDate" value={this.state.from_date}></input>
            <input type="hidden" name="ToDate" value={this.state.to_date}></input>
            <input type="hidden" name="access_token" value={config.storedAccessToken()} className='hidden'></input>
            <input type="hidden" name="x_policy" value={config.storedXPolicy()} className='hidden'></input>
          </form>
          <iframe name="iFrameSave" height="0" width="0" className='hidden pull-right'></iframe>
          <a tabIndex="0" className='no-btn' ref='exportBtn' onClick={(e) => this.exportCallLogs()} >Export Call Logs</a>
        </div>
      )
    } else return null;
  }

  exportFaxBtn = () => {
    if (this.state.tab === 'fax' && this.state.FaxCallLogs.length > 0) {
      return (
        <div id="div-calls-csv" className='pull-right'>
          <form ref='downloadCsv' target="iFrameSave" action={config.baseApiUrl + 'usr/GetFaxCallLogsCSV'} method="POST">
            <input type="hidden" name="LocationAccountNumber" value={this.props.match.params.LocationAccountNumber}></input>
            <input type="hidden" name="ServiceNumber" value={this.state.ServiceNumber} />
            <input type="hidden" name="CallType" value={this.state.call_type}></input>
            <input type="hidden" name="CallStatus" value={this.state.status_type}></input>
            <input type="hidden" name="CallCategory" value={this.state.call_category}></input>
            <input type="hidden" name="FromDate" value={this.state.from_date}></input>
            <input type="hidden" name="ToDate" value={this.state.to_date}></input>
            <input type="hidden" name="access_token" value={config.storedAccessToken()} className='hidden'></input>
            <input type="hidden" name="x_policy" value={config.storedXPolicy()} className='hidden'></input>
          </form>
          <iframe name="iFrameSave" height="0" width="0" className='hidden pull-right'></iframe>
          <a tabIndex="0" className='no-btn' ref='exportBtn' onClick={(e) => this.exportCallLogs()} >Export Call Logs</a>
        </div>
      )
    } else return null;
  }

  exportCallLogs = () => {
    this.refs.downloadCsv.submit();
    this.refs.exportBtn.text = 'Downloading...';
    setTimeout(() => {
      this.refs.exportBtn.text = 'Export Call Logs';
    }, 2000);
  }

  extension = () => {
    let ext = this.props.Extension;
    if (ext) {
      return <span> ({ext})</span>
    } else return null;
  }

  callLogFilter = (htmlSelect) => {
    return (
      <div className="filter" style={{
        paddingRight: '4px',
        float: 'left',
        width: '33.33%',
      }
      }>
        {htmlSelect}
      </div >
    )
  }

  tabDisplay = () => {
    if ((this.state.BSCallLogs && this.state.BSCallLogs.length > 0) || (this.state.SansayCallLogs && this.state.SansayCallLogs.length > 0) ||
       (this.state.FaxCallLogs && this.state.FaxCallLogs.length > 0)) {
      return (
        <div className="tabs"
          style={{ float: 'left', width: '100%' }}
        >
          <a
            style={{ display: this.state.BSCallLogs && this.state.BSCallLogs.length > 0 ? 'inline-block' : 'none' }}
            className={this.state.tab === "bs" ? "active tab" : "tab"} data-act="bs" onClick={this.changeTab}>Cloud Voice</a>
          <a
            style={{ display: this.state.SansayCallLogs && this.state.SansayCallLogs.length > 0 ? 'inline-block' : 'none' }}
            className={this.state.tab === "sansay" ? "active tab" : "tab"} data-act="sansay" onClick={this.changeTab}>IP Trunking</a>
          <a
            style={{ display: this.state.FaxCallLogs && this.state.FaxCallLogs.length > 0 ? 'inline-block' : 'none' }}
            className={this.state.tab === "fax" ? "active tab" : "tab"} data-act="fax" onClick={this.changeTab}>Fax</a>
        </div>
      )
    } else return;
  }

  changeTab = (event) => {
    if (event.target.dataset.act !== this.state.tab) {
      this.setState({ tab: event.target.dataset.act });
    }
  }

  getTab() {
    let { tab, BSCallLogs, SansayCallLogs, FaxCallLogs, loading } = this.state;
    switch (tab) {
      case 'bs':
        return (
          <BSCallLogsTab BsLogs={BSCallLogs} isLoading={loading} />
        )
      case 'sansay':
        return (
          <SansayCallLogsTab SansayLogs={SansayCallLogs} isLoading={loading} />
        )
      case 'fax':
        return (
          <FaxCallLogsTab FaxCallLogs={FaxCallLogs} isLoading={loading} />
        )
      default:
        console.log('Sorry there isn\'t a tab named ' + tab + '.');
    }
  }

  render() {
    return (
      <div className="full-card container">
        <div className="call-logs card">
          <div className="card-header">
            <div className="col-md-8 row">
              <h1 className="move">
                Call Logs
                <small> {this.props.ServiceNumber} {this.extension()}</small>
              </h1>
            </div>
            <div className="col-md-4 pull-right export">
              {this.exportSansayBtn()}
              {this.exportBsBtn()}
              {this.exportFaxBtn()}
            </div>
            <div className="call-log-actions card-content col-md-12">
              {this.tabDisplay()}
              <div className="call-log-action">
                {this.callLogFilter(<select value="1" className="form-control" value={this.state.call_type} onChange={this.handleCallType}>
                  <option value="1">All Calls</option>
                  <option value="2">Incoming</option>
                  <option value="3">Outgoing</option>
                </select>)}
                {this.callLogFilter(<select value="1" className="form-control" disabled={this.state.tab === 'fax' ? 'disabled' : ''} value={this.state.status_type} onChange={this.handleStatusType}>
                  <option value="1">All Calls</option>
                  <option value="2">Connected</option>
                  <option value="3">No Answer</option>
                  <option value="4">Incomplete</option>
                  <option value="5">Forwarded</option>
                </select>)}
                {this.callLogFilter(<select className="call-log-dates form-control" value={this.state.date_type} onChange={this.handleDate}>
                  <option value="1">Today</option>
                  <option value="2">Yesterday</option>
                  <option value="3">Last 7 Days</option>
                  <option value="4">Last 30 Days</option>
                  <option value="5">Last 90 Days</option>
                  <option value="6">Custom</option>
                </select>)}
                {
                  (this.state.date_type === "6") ?
                    (<div className="custom-dates">
                      <div className="date">
                        <DatePicker value={this.state.from_date} change={this.handleChangeFromDate} />
                      </div>
                      <div className="date">
                        <DatePicker value={this.state.to_date} change={this.handleChangeToDate} />
                      </div>
                    </div>)
                    : ""
                }
              </div>
            </div>
            <div className='card-content'>
              <div className="tab-body">
                {this.getTab()}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

const mapStateToProps = (state) => {
  return {
    ServiceNumber: state.ServiceNumber,
    Extension: state.Extension
  }
}

export default connect(mapStateToProps)(CallLogsAll);