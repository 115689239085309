import React, { Component } from "react";
import { DataLoadingSpinner } from "../../components/spinners";
import { withRouter } from "react-router-dom";
import "@progress/kendo-theme-default/dist/all.css";
import "../../css/bootstrap.css";
import "../../css/app.css";
import "../../css/spinner.css";
import "../../css/msp_validateEmail.css";
import * as helper from "../../scripts/helper";
import * as config from "../../requests/config";
import momentumLogoWhite from "../../images/momentum_logo.png";
import { connect } from "react-redux";

class ValidateMfaEmail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      Error: "",
      validationError: "",
      Logo: momentumLogoWhite,
      password1: '',
      password2: '',
      isNetworkError: false,
      isInvalidUser: true,
      isShowBackButton: false
    };
  }

  getCustomizations = async currentUrl => {
    const b2cResults = await helper.getB2CConfigResults(currentUrl);
    this.loadingLogo = helper.IsWholesaleDomain(currentUrl)
      ? this.loadingLogo
      : momentumLogoWhite;
    const customAuthPolicy = this.preProcessCustomizations(b2cResults);

    await this.setState({ auth: customAuthPolicy, loading: false });
  };

  preProcessCustomizations = b2cResults => {
    let customAuthPolicy = config.auth;
    if (b2cResults) {
      const authority =
        config.authorityUrl +
        b2cResults.B2CPolicy +
        config.defaultXPolicyConfig;
      customAuthPolicy = {
        clientId: b2cResults.B2CClientId,
        authority: authority,
        b2cScopes: []
      };
      const { LogoSmall, LogoLarge, VendorName, BrowserIcon } = b2cResults;
      this.loadingLogo = LogoLarge;
      helper.updateFavicon(BrowserIcon);
      if (!helper.isDigitalServicesDomain()) {
        helper.setTitle(VendorName);
      }
    }
    return customAuthPolicy;
  };

  componentDidMount() {
    const domain = helper.getDomain();
    this.getCustomizations(domain);
    var p_email = new URLSearchParams(window.location.search).get("email");
    this.setState({ email: p_email })
  }

  handleOnChange = (type, event) => {
    let state = { ...this.state };
    state.email = event.target.value;
    this.setState(state);
  }

  // ValidPassword = (pw) => {
  //   var reg = /^.*(?=.{8,})(?=.*([\d]|[!@#$%^&*{}_+=\[\]:;'",<.>/?_`~\\\|-]))(?=.*[a-z])(?=.*[A-Z]).*/;
  //   var test = reg.test(pw);
  //   return test;
  // }
  decode = (value) => {
    let base64ToString = new Buffer.from(value, "base64").toString();
    return base64ToString;
  }

  handleOnBack = () => {
    helper.redirectToLogin();
    return;
  }

  handleOnSave = () => {
    let state = { ...this.state };
    state.savingLoading = true;
    state.savingShowSuccess = false;
    this.setState(state);
    var token = new URLSearchParams(window.location.search).get("token");
    var decodedToken = this.decode(token);
    var userName = '';
    var params = decodedToken.split(',');
    for (var index = 0; index < params.length; index++) {
      if (params[index].startsWith('userName=')) {
        userName = params[index].replace('userName=', '');
        break;
      }
    }
    helper.VerifyTwoStepEmail(window.location.href, state.email, userName).then((response) => {
      if (response == null) {
        this.setState({ Error: "Token link has expired.", validationError: "Please click Back to go to Login page.", isShowBackButton: true });
        return;
      }
      else {
        this.setState({
          isNetworkError: false,
        });
        if (response) {
          var responseCode = parseInt(response);
          if (responseCode == 500) {
            this.setState({ Error: "An internal server error has occurred.", validationError: "Please return to the Sign In page and try again. Contact your Administrator if the error persists." });
            return;
          }
          else if (responseCode == 401) {
            this.setState({ Error: "The token cannot be decoded.", validationError: "Please check the url." });
            return;
          }
          else if (responseCode == 406) {
            this.setState({ Error: "Token link has expired.", validationError: "Please click Back to go to Login page.", isShowBackButton: true });
            return;
          } else {
            this.setState({ Error: "" });
          }
        }
        this.setState({ Error: "", validationError: "", successMessage: "Success! You may now login and continue with MFA configuration" });
        setTimeout(() => {
          helper.redirectToLogin();
        }, 3000);
        return;
      }
    }).catch(async err => {
      console.log(err);
      this.setState({
        isNetworkError: Boolean(err.message),
        Error: "Unable to reach server",
        validationError: ""
      });
    });
  }

  resetForm = () => {
    return (
      <div className='msp_resetPwd2'>
        <div className="resetPwd">
          <img id="msp_logo" className="App-logo" src={this.loadingLogo} />
          <div className="form-holder">
            <h2>MFA Email Validation</h2>
            <div id="api" data-name="Unified">
              <div className="localAccount" aria-label="Sign in with your existing account" role="form">
                <div className="intro">
                  <p>After email validation, you can log back in to configure MFA for your account.</p>
                </div>
                <div className="error pageLevel"  >
                  <p role="alert">{this.state.Error}</p>
                  <div><p role="alert" className="errorMessage">{this.state.validationError}</p></div>
                  {this.state.successMessage && this.state.successMessage !== "" ? <div>
                    <p className="successMessage" style={{ color: 'green' }}>{this.state.successMessage}</p>
                  </div> : null}
                </div>
                {this.resetFormInner()}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  resetFormInner = () => {
    if (this.state.isShowBackButton) {
      return (<div className="entry">
        {this.getValidationError()}
        <div className="working" />
        <div className="buttons">
          <button
            className="my-login-btn"
            id="next"
            type="submit"
            tabIndex={2}
            onClick={this.handleOnBack}
          >Back</button>
        </div>
      </div>);
    }
    else {
      return (<div className="entry">
        <div className="form-group">
          <label>Email:</label>
          <input
            type="text"
            className="form-control"
            value={this.state.email}
            disabled={true}
          />
        </div>
        {this.getValidationError()}
        <div className="working" />
        <div className="buttons">
          <button
            className="my-login-btn"
            id="next"
            type="submit"
            tabIndex={2}
            onClick={this.handleOnSave}
          >Verify MFA Email</button>
        </div>
      </div>);
    }
  }

  getValidationError = () => {
    if (!this.state.valid && this.state.validMessage) {
      return (<div className="inline inline-fail text-center">{this.state.validMessage}</div>)
    }
    return "";
  }

  resetContainer = () => {
    if (this.state.isInvalidUser) {
      return this.resetForm();
    }
    else {
      helper.redirectToLogin();
      return;
    }
  }

  render() {
    return this.resetContainer();
  }
}
const mapStateToProps = state => {
  return {
    redirecting: helper.toBoolean(state.reducer.redirecting),
    restartSession: helper.toBoolean(state.reducer.restartSession),
    loginError: state.reducer.loginError,
    loginErrorDescription: state.reducer.loginErrorDescription,
  };
};

export default withRouter(connect(mapStateToProps)(ValidateMfaEmail));