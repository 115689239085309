import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { Button } from '@progress/kendo-buttons-react-wrapper';
import { MultiSelect } from '@progress/kendo-dropdowns-react-wrapper';
import { DateTimePicker } from '@progress/kendo-dateinputs-react-wrapper';
import _ from 'lodash';


class StatsModal extends Component {

  constructor(props) {
    super(props);

    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleAddPhoneNumber = this.handleAddPhoneNumber.bind(this);
    this.state = { showModal: false, numberOfPhoneCount:1, numberOfPhoneLimit:10 };
  }

  handleClose() {
    this.setState({ showModal: false });
  }

  handleShow() {
    this.setState({ showModal: true });
  }
  handleAddPhoneNumber(){
    if(this.state.numberOfPhoneCount < 10)
      this.setState({ numberOfPhoneCount: this.state.numberOfPhoneCount + 1 });
  }

  componentDidMount() {   
  }

  render() {
    let phoneNumberCountReachLimit = this.state.numberOfPhoneCount >= this.state.numberOfPhoneLimit;
    return (
      <div className="setting">
        <span onClick={this.handleShow}> 
          <span className="name">Status & Statistics</span>
          <a className="icon btn"><svg width="14" height="14" viewBox="0 0 24 24"><path d="m163 440l-91-91 251-250 90 90z m309-352l-48-48c-12-11-32-11-45 2l-45 45 91 91 45-45c13-13 13-33 2-45z m-408 275l-32 117 117-32z" transform="scale(0.046875 0.046875)"></path></svg></a>
        </span>

        <Modal show={this.state.showModal} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Status & Statistics</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="statsModal">
              <p>Contact Center Status and Statistics allows you to view the status and statistics of this Contact Center's activity and, as required, configure e-mail statistics reporting. </p>
              
              <h5>Queue & Agent Statistics</h5>
              <div className="form-group">
                <label className="right switch">
                  <input type="checkbox" />
                  <span className="slider round"></span>
                </label>
                Display current-time statistics
              </div>
              <div className="halves">
                <div className="half">
                  <div className="form-group">
                    <label>Start Date Time</label>
                    <DateTimePicker
                      min={new Date(2017, 9, 1)}
                      max={new Date(2017, 10, 1)}
                      value={new Date(2017, 9, 15)}  
                      format="yyyy/MM/dd hh:mm"
                      change={(e) => {console.log("value changed to:" + e.sender.value())}}
                    />
                  </div>
                </div>
                <div className="half">
                  <div className="form-group">
                    <label>End Date Time</label>
                    <DateTimePicker
                      min={new Date(2017, 9, 1)}
                      max={new Date(2017, 10, 1)}
                      value={new Date(2017, 9, 15)}  
                      format="yyyy/MM/dd hh:mm"
                      change={(e) => {console.log("value changed to:" + e.sender.value())}}
                    />
                  </div>
                </div>
              </div>
              <table className="table">
                <tbody>
                <tr>
                  <td>No. of busy overflows</td>
                  <td>0</td>
                </tr>
                <tr>
                  <td>No. of calls answered</td>
                  <td>0</td>
                </tr>
                <tr>
                  <td>No. of calls abandoned</td>
                  <td>0</td>
                </tr>
                <tr>
                  <td>No. of calls transferred</td>
                  <td>0</td>
                </tr>
                <tr>
                  <td>No. of calls timed out</td>
                  <td>0</td>
                </tr>
                <tr>
                  <td>Average no. of agents talking</td>
                  <td>0</td>
                </tr>
                <tr>
                  <td>Average no. of agents staffed</td>
                  <td>0</td>
                </tr>
                <tr>
                  <td>Average wait time</td>
                  <td>0</td>
                </tr>
                <tr>
                  <td>Average abandonement time</td>
                  <td>0</td>
                </tr>
                </tbody>
              </table>
              <table className="table">
                <thead>
                  <tr>
                    <th>Agents Stattistics</th>
                    <th>No of Calls Handled</th>
                    <th>No of Calls Unanswered</th>
                    <th>Average Call Time</th>
                    <th>Total Talk Time</th>
                    <th>Total Staffed Time</th>
                  </tr>
                </thead>
                <tbody>
                <tr>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                </tr>
                <tr>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                </tr>
                <tr>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                </tr>
                <tr>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                </tr>
                </tbody>
              </table>

              <h5>Statistics Report Settings</h5>
              <div className="form-group">
                <label className="right switch">
                  <input type="checkbox" />
                  <span className="slider round"></span>
                </label>
                Daily Report
              </div>
              <div className="form-group">
                <label >Statistics Source</label>
                <select className="form-control">
                  <option>None</option>
                  <option>Application Server</option>
                  <option>Simeltanious</option>
                  <option>External Reporting Server</option>
                </select>
              </div>
              <div className="form-group">
                <label >Reporting Period</label>
                <select className="form-control">
                  <option>30</option>
                  <option>60</option>
                </select>
              </div>
              <div>
                <div className="block-options">
                  <div className="halves">
                    <div className="half">
                      <div className="form-group">
                        <label>Email</label>
                        <input type="type" className="form-control" defaultValue="" />
                      </div>
                    </div>
                    <div className="half">
                      <div className="form-group">
                        <label>Email</label>
                        <input type="type" className="form-control" defaultValue="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <h5>Queue Status</h5>
              <div className="form-group">
                <label>Number of Calls in Queue Now</label>
                <input type="type" className="form-control" defaultValue="0" disabled />
              </div>
              <table className="table">
                <thead>
                  <tr>
                    <th colSpan="2">Agents Currently Staffed</th>
                  </tr>
                </thead>
                <tbody>
                <tr>
                  <td>Jared Malan</td>
                  <td>jfals82@gmail.com</td>
                </tr>
                <tr>
                  <td>Jared Malan</td>
                  <td>jfals82@gmail.com</td>
                </tr>
                <tr>
                  <td>Jared Malan</td>
                  <td>jfals82@gmail.com</td>
                </tr>
                <tr>
                  <td>Jared Malan</td>
                  <td>jfals82@gmail.com</td>
                </tr>
                </tbody>
              </table>

            </div>
          </Modal.Body>
          <Modal.Footer>
              <hr />
              <div className="btns">
              <Button className="btn">Save</Button>
              <Button className="btn" click={this.handleClose}>Cancel</Button>
              </div>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
};

export default StatsModal;
