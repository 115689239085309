import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { Button } from '@progress/kendo-buttons-react-wrapper';


class RemoteOfficeModal extends Component {

  constructor(props) {
    super(props);

    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.state = { showModal: false };
  }

  handleClose() {
    this.setState({ showModal: false });
  }

  handleShow() {
    this.setState({ showModal: true });
  }

  componentDidMount() {   
  }

  render() {
    let phoneNumberCountReachLimit = this.state.numberOfPhoneCount >= this.state.numberOfPhoneLimit;
    return (
      <div className="setting">
        <span onClick={this.handleShow}> 
          <span className="name">Remote Office</span>
          <a className="icon btn"><svg width="14" height="14" viewBox="0 0 24 24"><path d="m163 440l-91-91 251-250 90 90z m309-352l-48-48c-12-11-32-11-45 2l-45 45 91 91 45-45c13-13 13-33 2-45z m-408 275l-32 117 117-32z" transform="scale(0.046875 0.046875)"></path></svg></a>
        </span>

        <Modal show={this.state.showModal} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Remote Office Settings</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Remote Office allows you to use your home phone, your cell phone or even a hotel phone as your business phone. By using the CommPilot Call Manager, you can make phone calls from this remote phone and have them billed to your business. This service also directs all calls coming to your business phone to ring the remote office phone.</p>
            <hr />
            
            <div className="form-group">
              <label className="right switch">
                <input type="checkbox" data-toggle="collapse" data-target="#hide1" />
                <span className="slider round"></span>
              </label>
              Remote Office
              <div className="hidden-group collapse" id="hide1">
                <label >Remote Phone Number</label>
                <input type="text" className="form-control" />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
              <hr />
              <div className="btns">
              <Button className="btn">Save</Button>
              <Button className="btn" click={this.handleClose}>Cancel</Button>
              </div>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
};

export default RemoteOfficeModal;
