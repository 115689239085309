import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { Button } from '@progress/kendo-buttons-react-wrapper';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'; // ES6
import { DataLoadingSpinner } from '../../../components/spinners';
import { connect } from 'react-redux';
import { fetchData, post } from '../../../requests/api_calls';
import * as config from '../../../requests/config';

const Password1="Password1", Password2="Password2";

class ChangePasswordModal extends Component {

  constructor(props) {
    super(props);

    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.state = { 
      password1: "",
      password2: "",
      valid: false,
      validMessage: "",
      showModal: false,
      savingLoading: false,
      savingShowSuccess: false,
      savingShowError: false,
      savingErrorMessage: "",
      currentUser: props.CurrentUserProfile,
      admin: props.isAdmin,
      ServiceNumber: props.ServiceNumber,
      PBXUserID: props.PBXUserID
    };
  }

  handleClose() {
    this.setState({ showModal: false });
  }

  handleShow() {
    this.setState({ showModal: true });
  }

  handleOnChange=(type, event)=>{
    let state = {...this.state};

    switch(type){
      case Password1:
        state.password1 = event.target.value;
        break;
      case Password2:
        state.password2 = event.target.value;
        break;
    }
    if (state.password1.length > 0 && state.password1.length < 8) {
      state.valid = false;
      state.validMessage = 'Password must be at least 8 characters long';
    } else if (!this.ValidPassword(state.password1)) {
      state.valid = false;
      state.validMessage = 'The password must contain at least 1 number or special character, at least 1 lower case letter, and at least 1 upper case letter';
    }
    else if((state.password1 && state.password2 && state.password1 !== state.password2) || (state.password1 && !state.password2) || (!state.password1 && state.password2)){
      state.valid = false;
      state.validMessage = "The password is not the same";
    } else if(state.password1 && state.password2 && state.password1 === state.password2){
      state.valid = true;
      state.validMessage = "";
    } 
    this.setState(state);
  }

  ValidPassword = (pw) => {    
    //var reg = /^.*(?=.{8,})(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).*/;
    var reg = /^.*(?=.{8,})(?=.*([\d]|[!@#$%^&*{}_+=\[\]:;'",<.>/?_`~\\\|-]))(?=.*[a-z])(?=.*[A-Z]).*/;
    var test = reg.test(pw);
    return test;
}

  handleOnSave=()=>{
    let state = {...this.state};
    state.savingLoading = true;
    state.savingShowSuccess = false;
    this.setState(state);

    let headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + config.storedAccessToken(),
      'X-Policy': config.storedXPolicy()
    };

    let body = {
      ServiceNumber: this.props.ServiceNumber,
      PBXUserID: this.props.PBXUserID,
      Password: this.state.password1
    };
    let apiUrl = "";
    if(this.state.currentUser && this.state.admin){
      body = {
        UserID: sessionStorage.userId,
        Password: this.state.password1
      };
      apiUrl = "admin/ChangeAdminPassword"
    }
    else{
      apiUrl = "usr/ChangeUserPassword"
      body = {
        UserID:  sessionStorage.SelectedUserId,
        ServiceNumber: this.props.ServiceNumber,
        PBXUserID: this.props.PBXUserID,
        Password: this.state.password1
      };
    }

    body = JSON.stringify(body);
    post(apiUrl,"POST", body, headers)
		.then(response =>{
      let state = {...this.state};
			if(response.ok){
        state.savingShowSuccess = true;
        state.savingLoading = false;
        state.savingShowError = false;
			}
			else{
        state.savingLoading = false;
        state.savingShowError = true;
				state.savingErrorMessage = "Failed Save";
      }
      this.setState(state);
		})
		.catch((message)=>{
      console.log(message);
      let state = {...this.state};
      state.savingLoading = false;
      state.savingShowError = true;
      state.savingErrorMessage = "Failed Save";
      this.setState(state);
		});
  }

  componentDidMount() {   
  }

  componentWillReceiveProps(props) {
    if (props.ServiceNumber !== this.state.ServiceNumber || props.PBXUserID != this.state.PBXUserID)
      this.setState({ServiceNumber: props.ServiceNumber, PBXUserID: props.PBXUserID});
  }

  render() {
    let state = this.state;
    return (
      <div className="setting">
        {this.getBtn()}
        <Modal show={this.state.showModal} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Change Password</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <p>We recommend you choose a strong password to protect your online profile. The password length must be at least 8 characters. The password must contain at least 1 number or special character, at least 1 lower case letter, and at least 1 upper case letter.</p>
              <p>Avoid using passwords that can be easily guessed such as your name, telephone number, social security number, or account number.</p>
              <div className="form-group">
                <label>Type Password</label>
                <input type="password" className="form-control" autoComplete='new-password'
                  value={this.state.password1} 
                  maxLength="20"
                  onChange={x=>{ this.handleOnChange(Password1, x) }}/>
              </div>
              <div className="form-group">
                <label>Retype Password</label>
                <input type="password" className="form-control" autoComplete='new-password'
                  value={this.state.password2}  
                  maxLength="20"
                  onChange={x=>{ this.handleOnChange( Password2, x) }}/>
              </div>
              {this.getValidationError()}
            </div>
          </Modal.Body>
          <Modal.Footer>
              <hr />
              <div className="btns">
                <SaveButton disabled={state.savingLoading || !this.state.valid} handleSave={e=> this.handleOnSave()} />
                <Button className="btn" click={this.handleClose}>Close</Button>
              </div>
              <SavingMessageControl 
                showSuccess={state.savingShowSuccess} 
                showLoading={state.savingLoading} 
                showError={state.savingShowError}
                SuccessMessage="Success"
                SuccessTransitionClass="vm-setting-success"
                ErrorMessage={state.savingErrorMessage}
                />
          </Modal.Footer>
        </Modal>
      </div>
    );
  }

  getBtn(){
    const superlink = this.props.superlink;
    if (superlink) {
      return (
        <a onClick={this.handleShow} className="no-btn">Change Password</a>
      )
    } else {
      return (
        <div>
          <span> 
            <span className="name">Change Password</span>
            <a className="icon btn"><svg width="14" height="14" viewBox="0 0 24 24"><path d="m163 440l-91-91 251-250 90 90z m309-352l-48-48c-12-11-32-11-45 2l-45 45 91 91 45-45c13-13 13-33 2-45z m-408 275l-32 117 117-32z" transform="scale(0.046875 0.046875)"></path></svg></a>
          </span>
        </div>
      )
    }
  }

  getValidationError=()=>{
    if(!this.state.valid && this.state.validMessage){
      return (<div className="inline inline-fail text-center">{this.state.validMessage}</div>)
    }
    return "";
  }
  
};

class SavingMessageControl extends Component {
  render() {
      let successMessage = "";
      if(this.props.showSuccess){
        successMessage =  (<ReactCSSTransitionGroup
          transitionName={this.props.SuccessTransitionClass}
          transitionAppear={true}
          transitionAppearTimeout={4000}
          transitionEnter={true}
          transitionEnterTimeout={4000}
          transitionLeave={false}
          >
          <div className="inline-success text-center">{this.props.SuccessMessage}</div>
        </ReactCSSTransitionGroup>)
      }

      let loadingMessage = "";
      if(this.props.showLoading){
        loadingMessage = (<DataLoadingSpinner className='load spinner'/>)
      }
      
      let errorMessage = "";
      if(this.props.showError){
        errorMessage = (<div className="inline inline-fail text-center">{this.props.ErrorMessage}</div>)
      }

      return (
        <div>
          {successMessage}
          {loadingMessage}
          {errorMessage}
        </div>
      );
    }
};

class SaveButton extends Component {
  render() {
    if(this.props.disabled)
      return (<Button key="1" className="btn" enable={false}>Save</Button>);
    else
      return (<Button key="2" className="btn" click={this.props.handleSave}>Save</Button>);
  }
};

const mapStateToProps = (state) => {
  return {
    ServiceNumber: state.reducer.ServiceNumber,
    PBXUserID: state.reducer.PBXUserID
  }
}

export default connect(mapStateToProps)(ChangePasswordModal);

