import {  combineReducers } from 'redux';
import reducer  from './reducer';
import * as config from './../requests/config';
import * as actions from './../store/actions';
import jwtDecode from "jwt-decode";

export const reducers = combineReducers({ reducer });
 
const initialState = () => {
  return {
  }
}

export const tokenRepo = (actionType = '', data = {}) => async dispatch => {
  try {
    
    switch(actionType)
    {
      case actions.UI_SKINS_APPLIED:
        dispatch({type: actions.UI_SKINS_APPLIED});
      case actions.SET_MSP_LOGIN_DATA:
            dispatch({ type: actions.SET_MSP_LOGIN_DATA, data: { ...data }});
      default:
        let TokenObj = sessionStorage.getItem('accessToken') ? sessionStorage.getItem('accessToken') : (localStorage.getItem('accessToken') ? localStorage.getItem('accessToken') : null);
        try
        {
          if (TokenObj !== null && TokenObj !== 'undefined') {

            let decodedToken = jwtDecode(TokenObj);
            let currentTime = new Date();
            let expirationOnCurrentToken = decodedToken.exp;
            const d = new Date(0);
            d.setUTCSeconds(expirationOnCurrentToken);
            if (currentTime > d) {
              dispatch({type: actions.UPDATE_TOKEN_STATUS, tokenIsValid: false});
              dispatch({type: actions.TOKEN_LOADED});
            }
            else {
              await dispatch({type: actions.UPDATE_TOKEN_STATUS, tokenIsValid: true});
              await config.tokenSaveUserProperties(TokenObj);
            }
          } else {
            dispatch({type: actions.TOKEN_LOADED})
          }
        } catch (ex) {
          console.log('tokenRepo error:' + ex);
          dispatch({type: actions.TOKEN_LOADED})
        }
    }
  } catch (error) {
    console.error('tokenRepo error: ' + error);
    dispatch({ type: actions.TOKEN_LOADED });
  }
}