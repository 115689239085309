import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import * as apiCalls from '../../../requests/api_calls';
import _ from 'lodash';
import { DataLoadingSpinner } from '../../../components/spinners';
import TNSearch from './tn_search';

export default class ManageOCTeamsUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAssigned: false,
      loading: false,
      Submission: false,
      SubmitError: false,
      showConfirmationModal: false,
      showDRModal: false,
      TeamsId: this.props.location.state.TeamsId,
      UserDetails: null,
      OriginalDetails: null,
      Enterprise: this.props.location.state.Enterprise,
      Trunk: this.props.location.state.Trunk,
      LocationList: sessionStorage.locationList ? JSON.parse(sessionStorage.locationList) : [],
      routingType: sessionStorage.MSTeamsRoutingType == "DIRECT" ? "Direct Routing" : "Derived Routing",
      //LocationList: JSON.parse(getLocations()),
    };
  }

  async componentDidMount() {
    await this.getTeamsUserByTeamsId();
  }

  inputDataField = (val, key, inputStyle, onChangeFunc, type = 'text', readOnly) => {
    return (
      <input
        style={inputStyle}
        type={type}
        name={key}
        value={val}
        className="form-control"
        onChange={(e) => onChangeFunc(e, key)}
        disabled={readOnly}
      />
    )
  }

  dataRow = (label, field, divStyleObj, labelStyleObj) => {
    return (
      <div className="form-group-wrapper"
        style={divStyleObj}
      >
        <label
          style={labelStyleObj}
        >{label}: </label>
        {field}
      </div >
    )
  }

  dataDropDown = (key, val, inputStyle) => {
    return (
      <select
        className="form-control"
        style={inputStyle}
        //onChange={(e) => this.handleInputChange(e, key)}
        value={val}
        disabled
      >
        {/* {options} */}
        <option value="">-- Select --</option>
        {this.state.LocationList.map((itm, idx) => {
          return <option key={idx} value={itm.LocationId}>{itm[key]}</option>
        })}
      </select>
    )
  }

  cardContent = () => {
    let { loading, UserDetails } = this.state;
    if (loading || UserDetails == null) {
      return (
        <DataLoadingSpinner className='load spinner' />
      );
    } else {
      let divStyle = { margin: "1%", };
      let labelStyle = { display: 'inline-block', width: '30%', };
      let inputStyle = { width: '70%', display: 'inline-block', };
      return (
        <div className="card-content">
          {<TNSearch
            onSelectUser={this.handleUserChange}
          />}
          {this.dataRow("Assigned DID / Seat", this.inputDataField(UserDetails?.TelephoneNumber, 'TelephoneNumber', inputStyle, this.handleInputChange, "text", true), divStyle, labelStyle)}
          {this.dataRow("Extension", this.inputDataField(UserDetails?.Extension, 'Extension', inputStyle, this.handleInputChange, "text", true), divStyle, labelStyle)}
          {this.dataRow("Location", this.dataDropDown("LocationName", UserDetails?.LocationId, inputStyle), divStyle, labelStyle)}
          {this.dataRow("First Name", this.inputDataField(UserDetails?.GivenName, 'GivenName', inputStyle, this.handleInputChange, "text", true), divStyle, labelStyle)}
          {this.dataRow("Last Name", this.inputDataField(UserDetails?.Surname, 'Surname', inputStyle, this.handleInputChange, "text", true), divStyle, labelStyle)}
          {this.dataRow("User Principal Name", this.inputDataField(UserDetails?.UserPrincipalName, 'UserPrincipalName', inputStyle, this.handleInputChange, "text", true), divStyle, labelStyle)}
          {this.dataRow("Street", this.inputDataField(UserDetails?.StreetAddress, 'StreetAddress', inputStyle, this.handleInputChange, "text", true), divStyle, labelStyle)}
          {this.dataRow("City", this.inputDataField(UserDetails?.City, 'City', inputStyle, this.handleInputChange, "text", true), divStyle, labelStyle)}
          {this.dataRow("State", this.inputDataField(UserDetails?.State, 'State', inputStyle, this.handleInputChange, "text", true), divStyle, labelStyle)}
          {this.dataRow("User State Changed On", this.inputDataField(UserDetails?.UserStateChangedOn, 'UserStateChangedOn', inputStyle, this.handleInputChange, "text", true), divStyle, labelStyle)}
          {this.dataRow("User State", this.inputDataField(UserDetails?.UserState, 'UserState', inputStyle, this.handleInputChange, "text", true), divStyle, labelStyle)}
          {this.dataRow("Usage Location", this.inputDataField(UserDetails?.UsageLocation, 'UsageLocation', inputStyle, this.handleInputChange, "text", true), divStyle, labelStyle)}
          {this.dataRow("Sip Proxy Address", this.inputDataField(UserDetails?.SipProxyAddress, 'SipProxyAddress', inputStyle, this.handleInputChange, "text", true), divStyle, labelStyle)}
          {this.dataRow("User Type", this.inputDataField(UserDetails?.AccountType, 'AccountType', inputStyle, this.handleInputChange, "text", true), divStyle, labelStyle)}
          {this.dataRow("Other Mails", this.inputDataField(UserDetails?.OtherMails, 'OtherMails', inputStyle, this.handleInputChange, "text", true), divStyle, labelStyle)}
        </div >
      )
    }
  }

  handleUserChange = async (tn, ext, locationId, serviceId, userId, street, city, state) => {
    let UserDetails = { ...this.state.UserDetails };
    UserDetails["LocationId"] = locationId;
    UserDetails["ServiceId"] = serviceId;
    UserDetails["Extension"] = ext;
    UserDetails["UserId"] = userId;
    UserDetails["TelephoneNumber"] = tn;
    UserDetails["StreetAddress"] = street;
    UserDetails["City"] = city;
    UserDetails["State"] = state;
    await this.setState({ UserDetails });
    //console.log("UserDetails", UserDetails);
  }

  handleInputChange = async (e, key) => {
    let { value } = e.target;
    let UserDetails = { ...this.state.UserDetails };
    UserDetails[key] = value;
    await this.setState({ UserDetails });
    //console.log("UserDetails", UserDetails); // inserted AD Information
  }

  configureDR = async () => {
    this.setState({ SubmissionLoading: true });
    let { UserDetails, OriginalDetails, Trunk, Enterprise } = this.state;
    let { UserPrincipalName, TeamsUserId } = UserDetails;
    let path = `admin/EnableDirectRoutingForUser?teamsId=${TeamsUserId}`;
    let dto = this.state.UserDetails;
    // ** required params for web service ** //
    dto.UserName = sessionStorage.username;
    dto.EnterpriseId = Enterprise;
    dto.TrunkName = Trunk.TrunkName;
    dto.UserPrincipalName = UserPrincipalName;
    dto.PhoneNumber = UserDetails.TelephoneNumber;
    if (OriginalDetails.TelephoneNumber) {
      path = `admin/DisableDirectRoutingForUser?teamsId=${TeamsUserId}`;
    }
    //console.log("___DTO___", dto);
    let response = await apiCalls.post(path, 'POST', JSON.stringify(dto)).then(response => response).catch(err => console.error(err));
    if (response.ok) {
      this.sendErrorMessage(response.message);
      await this.setState({
        Submission: true,
        SubmissionLoading: false,
      });
    } else {
      this.setState({
        Submission: true,
        ErrorMessage: "An unknown error occurred, please try again.",
        SubmitError: true
      });
    }
  }

  getTeamsUserByTeamsId = async () => {
    await this.setState({ loading: true });
    let path = `oc/GetTeamsUserByTeamsId`;
    await apiCalls.post(path, "POST", JSON.stringify(this.state.TeamsId)).then(data => {
      if (data) {
        let result = JSON.parse(data.message)[0]
        let copy = JSON.parse(JSON.stringify(result))
        this.setState({
          UserDetails: result,
          OriginalDetails: copy,
        });
      } else {
        this.setState({
          UserDetails: null,
          OriginalDetails: null,
        });
      }
    }).catch(err => console.error(err)).finally(() => {
      this.setState({
        loading: false,
        SubmitError: false,
        Submission: false,
      });
    });
  }

  sendErrorMessage = (apiResponse) => {
    let { Status, StatusDscription } = JSON.parse(JSON.parse(apiResponse));
    if (Status && StatusDscription) {
      if (Status === "Error") {
        this.setState({ ErrorMessage: StatusDscription, SubmitError: true });
      } else {
        this.setState({ ErrorMessage: "" });
      }
    }
  };

  modalBtns = () => {
    if (this.state.Submission) {
      return (
        <div style={{ textAlign: "center" }}>
          {this.state.ErrorMessage !== "" ? <Fragment><small style={{
            color: 'red',
            display: "block"
            //marginLeft: '10px'
          }}>{this.state.ErrorMessage}</small></Fragment> : null}
          <button
            className="btn"
            onClick={async () => {
              if (this.state.SubmitError) {
                this.setState({ showDRModal: false, SubmitError: false, Submission: false, ErrorMessage: "" });
              } else {
                await this.setState({ showDRModal: false, ErrorMessage: "" });
                this.getTeamsUserByTeamsId();
              }
            }}
          >Close</button>
        </div>
      )
    } else {
      return (
        <div>
          <button
            type="button"
            className="btn"
            onClick={this.configureDR}
          >Yes</button>
          <button
            className="btn"
            onClick={() => this.setState({ showDRModal: false })}
          >No</button>
        </div>
      )
    }
  }

  render = () => {
    return (
      <div className="sync-menu-container">
        <div className="container full-card">
          <div className="sync-menu card">
            <div className="card-header">
              <h1>Update Operator Connect User</h1>
              <span
                style={{
                  //display: 'block',
                  position: 'relative',
                  bottom: '70px',
                  float: 'right',
                  //width: '200px'
                }}
              >
                <a
                  onClick={() => this.setState({ showDRModal: true })}
                  className="a btn"
                >{this.state.OriginalDetails?.TelephoneNumber ? `Disable User Routing` : `Enable User Routing`}</a>
                < Link
                  to={{
                    pathname: `/operator-connectv1`,
                  }}
                  className="a btn">
                  {'User List'}
                </Link>
              </span>
            </div>
            <div>
              <br />
              <br />
              <Modal show={this.state.showDRModal} onHide={this.handleClose} backdrop='static'>
                <Modal.Header>
                  <Modal.Title>{this.state.Submission && !this.state.SubmitError ? "Changes Saved!" : "Are you sure you want to save changes?"}</Modal.Title>
                </Modal.Header>
                <Modal.Footer>
                  <hr />
                  <div className="btns">
                    {this.state.SubmissionLoading ? <DataLoadingSpinner className='load spinner' /> : this.modalBtns()}
                  </div>
                </Modal.Footer>
              </Modal>
              {this.cardContent()}
            </div>
          </div>
        </div >
      </div>
    )
  }
}