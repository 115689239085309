import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { Button } from '@progress/kendo-buttons-react-wrapper';
import { MultiSelect } from '@progress/kendo-dropdowns-react-wrapper';


class LocationManageGroupCallingLineIDModal extends Component {

	constructor(props) {
		super(props);

    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);

    this.state = { showModal: false };
  }

  handleClose() {
    this.setState({ showModal: false });
  }

  handleShow() {
    this.setState({ showModal: true });
  }

	componentDidMount() {		
	}

  render() {
    return (
      <div className="setting">
        <span onClick={this.handleShow}> 
          <span className="name">Manage Group Calling Line ID</span>
          <a className="icon btn"><svg width="14" height="14" viewBox="0 0 24 24"><path d="m163 440l-91-91 251-250 90 90z m309-352l-48-48c-12-11-32-11-45 2l-45 45 91 91 45-45c13-13 13-33 2-45z m-408 275l-32 117 117-32z" transform="scale(0.046875 0.046875)"></path></svg></a>
        </span>
        <Modal show={this.state.showModal} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Manage Group Calling Line ID</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div className="form-group">
                <label className="right switch">
                  <input type="checkbox" data-toggle="collapse" data-target="#hide1" />
                  <span className="slider round"></span>
                </label>
                Use group name for Calling Line Identity 
              </div>
              <hr />
              <div className="form-group">
                <label>Calling Line ID Group Name</label>
                <input type="type" className="form-control" defaultValue="" />
              </div>
              <div className="form-group">
                <label>Calling Line ID Group Number</label>
                <select className="form-control">
                  <option defaultValue=""></option>
                  <option defaultValue="">123 123 1234</option>
                  <option defaultValue="">123 123 1234</option>
                  <option defaultValue="">123 123 1234</option>
                </select>
              </div>
              <hr />
              <div className="form-group">
                <label className="custom-control">
                <input id="radio1" name="radio" type="radio"/>
                  <span >Use user phone number for Calling Line Identity </span>
                </label> 
                <label className="custom-control">
                  <input id="radio1" name="radio" type="radio"/>
                  <span>Use user phone number for Calling Line Identity </span>
                </label>
                <label className="custom-control">
                  <input id="radio1" name="radio" type="radio"/>
                  <span>Use group/department phone number for Calling Line Identity </span>
                </label>
              </div>
              <p>WARNING: If you have extension-only seats, without associated telephone numbers and with Call Recording assigned, changing the Group Calling Line ID will cause Call Recording to stop. Please have your administrator contact the service provider and make the appropriate Call Recording configuration changes before changing the Group Calling Line ID.</p>
            </div>
          </Modal.Body>
          <Modal.Footer>
              <hr />
              <div className="btns">
              <Button className="btn" >Save</Button>
              <Button className="btn" click={this.handleClose}>Cancel</Button>
              </div>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
};

export default LocationManageGroupCallingLineIDModal;
