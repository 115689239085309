import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { Button } from '@progress/kendo-buttons-react-wrapper';
import { MultiSelect } from '@progress/kendo-dropdowns-react-wrapper';
import _ from 'lodash';

class LocationAccountCodesModal extends Component {

  constructor(props) {
    super(props);

    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.state = { showModal:false };
  }

  handleClose() {
    this.setState({ showModal: false });
  }

  handleShow() {
    this.setState({ showModal: true });
  }

  componentDidMount() {   
  }
  render() {
    
    return (
      <div className="setting">
        <span onClick={this.handleShow}> 
          <span className="name">Account / Authorization Codes</span>
          <a className="icon btn"><svg width="14" height="14" viewBox="0 0 24 24"><path d="m163 440l-91-91 251-250 90 90z m309-352l-48-48c-12-11-32-11-45 2l-45 45 91 91 45-45c13-13 13-33 2-45z m-408 275l-32 117 117-32z" transform="scale(0.046875 0.046875)"></path></svg></a>
        </span>
        <Modal show={this.state.showModal} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Account / Authorization Codes</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <p>Group calls for expense tracking and accounting purposes with Account Codes. Control long distance access and secure your phone lines with Authorization Codes.</p>
              <a className="no-btn">Click to view acc/auth code reports</a>
              <hr />
              <table className="table">
                <thead>
                  <tr>
                    <th className="check"></th>
                    <th>Codes</th>
                    <th>Description</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td><input type="checkbox" /></td>
                    <td>1</td>
                    <td>Lorem Ipsum</td>
                    <td align="right"><a className="icon btn"  data-toggle="collapse" data-target="#ar1" ><svg width="14" height="14" viewBox="0 0 24 24"><path d="m163 440l-91-91 251-250 90 90z m309-352l-48-48c-12-11-32-11-45 2l-45 45 91 91 45-45c13-13 13-33 2-45z m-408 275l-32 117 117-32z" transform="scale(0.046875 0.046875)"></path></svg></a></td>
                  </tr>
                  <tr className="collapse" id="ar1">
                    <td colSpan="6">
                      <div className="table-edit">
                        <div className="form-group">
                          <label>Code</label>
                          <input type="type" className="form-control" defaultValue="" />
                        </div>
                        <div className="form-group">
                          <label>Description</label>
                          <input type="type" className="form-control" defaultValue="" />
                        </div>
                        <div className="btns">
                          <a className="btn">Save</a>
                          <a className="btn">Cancel</a>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="6">
                      <div className="btns">
                        <a className="btn" data-toggle="collapse" data-target="#ar1">Add Code</a>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div className="form-group">
                <label>Administration Type</label>
                <div className="form-control">
                  <label className="custom-control custom-radio">
                  <input id="radio1" name="radio" type="radio"/>
                    <span >Account Code On </span>
                  </label> 
                  <label className="custom-control custom-radio">
                    <input id="radio1" name="radio" type="radio"/>
                    <span>Authorization Code On</span>
                  </label>
                  <label className="custom-control custom-radio">
                    <input id="radio1" name="radio" type="radio"/>
                    <span>Off</span>
                  </label>
                </div>
              </div>
              <div className="form-group">
                <label >For how many digits</label>
                <select className="form-control">
                  {_.times(8, i =>
                    <option key={i+2} defaultValue={i+2}>{i+2}</option>
                  )}
                </select>
                <small>Warning: Changing the "Number of Digits" will deactivate all active codes. </small>
              </div>
              <div className="form-group">
                <label className="switch">
                  <input type="checkbox" />
                  <span className="slider round"></span>
                </label>
                Allow Local and Toll-Free Calls without Account/Authorization Code 
              </div>
              <div className="multiselect-group form-group">
                <label>Mandatory Usage</label>
                <MultiSelect autoClose={false} dataSource={["All", "User 1", "User 2", "User 3", "User 4"]}/>
              </div>
              <div className="multiselect-group form-group">
                <label>Optional Usage</label>
                <MultiSelect autoClose={false} dataSource={["All", "User 1", "User 2", "User 3", "User 4"]}/>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
              <hr />
              <div className="btns">
                <Button className="btn">Save</Button>
                <Button className="btn" click={this.handleClose}>Close</Button>
              </div>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }

};

export default LocationAccountCodesModal;
