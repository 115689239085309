import React, { Component } from 'react';
import { DataLoadingSpinner } from '../../components/spinners';
import { Modal } from 'react-bootstrap';
import { sendErrorMessage, convertToLocal } from './components/utility';
import * as apiCalls from '../../requests/api_calls';

export default class MsTeamsDirectRouting extends Component {
  constructor(props) {
    super(props);

    this.state = {
      Enterprise: this.props.Enterprise,
      loading: false,
      Submission: false,
      ErrorMessage: sessionStorage.MsTeamsStatusDscription ? sessionStorage.MsTeamsStatusDscription : "",
      Trunk: this.props.Trunk,
      MSTeamsConfig: this.props.MSTeamsConfig,
      PrimaryGateWay: this.props.PrimaryGateWay,
      SecondaryGateWay: this.props.SecondaryGateWay,
      routingType: this.props.routingType,
    };
  }

  handleInputChange = async (e, key) => {
    let { value } = e.target;
    let MSTeamsConfig = { ...this.state.MSTeamsConfig };
    MSTeamsConfig[key] = value;
    await this.setState({ MSTeamsConfig });
    //console.log("MSTeamsConfig", MSTeamsConfig); // inserted AD Information
  }

  inputDataField = (val, key, onChangeFunc, readOnly = false, type = 'text') => {
    return (
      <input
        style={{
          width: '50%',
          display: 'inline-block',
        }}
        type={type}
        name={key}
        value={val}
        className="form-control"
        onChange={(e) => onChangeFunc(e, key)}
        disabled={readOnly}
      />
    )
  }

  dataRow = (label, field) => {
    return (
      <div className="form-group-wrapper"
        style={{ margin: '1%', }}
      >
        <label
          style={{
            width: '50%',
            display: 'inline-block',
          }}
        >{label} </label>
        {field}
      </div >
    )
  }

  cardContent = () => {
    let { Enterprise, MSTeamsConfig, PrimaryGateWay, SecondaryGateWay, ErrorMessage } = this.state;
    let sessions = MSTeamsConfig.MsTeamsMaxConcurrentSessions ? MSTeamsConfig.MsTeamsMaxConcurrentSessions : 1000
    return (
      <div className="card-content"
        style={{
          paddingTop: "20px"
        }}>
        <div className="card-actions">
        </div>
        <p><b>Submit the request to setup and enable Derived Routing.</b></p>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <div className="flex-row"
            style={{
              width: '60%',
            }}
          >
            {/* {this.dataRow("Primary FQDN", this.inputDataField(PrimaryGateWay, "PstnPrimaryGateway", this.handleInputChange, true))}
            {this.dataRow("Secondary FQDN", this.inputDataField(SecondaryGateWay, "PstnSecondaryGateway", this.handleInputChange, true))}
            {this.dataRow("Max Concurrent Sessions", this.inputDataField(sessions, "MsTeamsMaxConcurrentSessions", this.handleInputChange, true))} */}
            <button
              className="a btn"
              onClick={() => this.setState({ showConfirmationModal: true })}
              disabled={this.state.MSTeamsConfig.IsTempUsersCreated == false ? true : false || this.state.MSTeamsConfig.DirectRouting ? true: false}
            >Setup {this.state.routingType}</button>
            <button
              className="a btn"
              onClick={this.saveAndContinue}
            //(IsTempUserCreatedWithin24Hrs || IsTempUsersCreated == false ? true : false || !IsDNSTicketCompleted || !IsSBCTicketCompleted || loading)
            >Continue</button>
            {this.wizardMsg()}
            {ErrorMessage !== "" ? <small style={{
              color: 'red',
              marginLeft: '10px'
            }}>Error: {ErrorMessage}</small> : null}
          </div>
          <div className="flex-row"
            style={{
              marginLeft: '2%',
              width: '40%',
              border: '1px solid grey'
            }}
          >
            <div style={{ textAlign: "center", paddingLeft: '10px' }}>
              <span style={{ textDecoration: "underline" }}>Support Notes</span>
              <p style={{ textAlign: "left" }}>In this step, the Deployment Wizard will enable Derived routing of voice traffic between the Microsoft Teams Tenant and the Voice Network. The fields in the tab are predefined and read-only.
                <br/><br/>Click on the Setup Derived Routing button.
                <br/><br/>Wait 24 hours for this process to be completed.
                <br/><br/>Once this step is complete, Derived routing and TN assignments may be setup for the individual Teams users already added and assigned licenses in the Microsoft Teams Tenant.
              </p>
            </div>
          </div>
        </div>
      </div >
    )
  }

  wizardMsg = () => {
    let { DirectRouting, DirectRoutingTimeStamp } = this.state.MSTeamsConfig;
    if (DirectRouting) {
      return <div><br /><small
        style={{
          marginLeft: '10px'
        }}
      ><b>WAIT 24 HOURS FOR REPLICATION ACROSS THE SYSTEM</b></small><br />
        {/* <small
          style={{
            marginLeft: '10px'
          }}
        ><b>{this.getTimeAfter24Hrs(convertToLocal(DirectRoutingTimeStamp)).toLocaleString()}</b></small><br /> */}
        <small style={{
          color: 'green',
          marginLeft: '10px'
        }}>{this.state.routingType} Setup: {convertToLocal(DirectRoutingTimeStamp).toLocaleString()}</small>
      </div>
    } else return <br />
  }

  getTimeAfter24Hrs = (date) => {
    try {
      //let date = new Date(dateTime);
      if (date instanceof Date) {
        let time = date.getTime();
        let tomorrow = new Date(time + 24 * 60 * 60 * 1000);
        return tomorrow;
      }
    } catch (e) {
      return "";
    }
  }

  setDirectRouting = async () => {
    await this.setState({ submittedTicket: true });
    let path = 'admin/EnterpriseMsTeamsDirectRouting';
    let { MSTeamsConfig, Trunk, Enterprise } = this.state;
    let dto = {
      userName: sessionStorage.username,//"prodnewsubsite@momentumtelecom.com",
      enterpriseId: Enterprise,
      trunkName: Trunk.TrunkName,
      returnOnlyTeamsDomains: true,
    };
    console.log("DTO", dto);
    let response = await apiCalls.post(path, "POST", JSON.stringify(dto)).then(data => data).catch(err => console.error(err));
    if (response) {
      sendErrorMessage(response.message);
      this.props.RefreshData();
    }
  }

  saveAndContinue = () => {
    //this.setState({ completed: true });
    this.props.Proceed("data");
  }

  render() {
    return (
      <div>
        <Modal show={this.state.showConfirmationModal} onHide={this.handleClose} backdrop='static'>
          <Modal.Header>
            <Modal.Title>Setup {this.state.routingType} for this Enterprise?</Modal.Title>
          </Modal.Header>
          <Modal.Footer>
            <hr />
            {this.state.submittedTicket ? <DataLoadingSpinner className='load spinner' /> : <div className="btns">
              <span>
                <button type="button" className="btn" onClick={this.setDirectRouting}>Yes</button>
                <button className="btn" onClick={() => this.setState({ showConfirmationModal: false })}>No</button>
              </span>
            </div>}
          </Modal.Footer>
        </Modal>
        {this.cardContent()}
      </div>
    );
  };
}