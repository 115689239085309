import React, { Component, Fragment } from "react";
import { Modal } from 'react-bootstrap';
import * as apiCalls from '../../requests/api_calls';
import E911MSARetail from '../../E911retail.pdf';
import E911MSAWs from '../../E911WL.pdf';
import { storedUserRole } from '../../scripts/helper';
import { cngAltevaDctAccount } from '../../utility/utility';
import CR_MODAL from '../login/modals/loginModals';

export default class TC_MODAL extends Component {
  constructor() {
    super();

    this.state = {
      //showTCModal: getTCValue() ? false : true,
      loading: false,
      //TCFlag: getTCValue(),
      showTCModal: false,
      TCFlag: "",
      submitted: false,
      UserID: sessionStorage.userId,
      fetchingData: true,
    }
  }

  componentDidMount() {
    if (storedUserRole() === 'SUPER_ADMIN') {
      this.fetchEnterpriseTerms();
    } else {
      this.fetchUserTerms();
    }
  }

  fetchEnterpriseTerms = async () => {
    //await this.setState({ loading: true });
    let path = "admin/EnterpriseTC";
    await apiCalls.fetchData(path).then(data => {
      if (data) {
        this.setState({
          TCFlag: data[0].TCFlag,
          showTCModal: data[0].TCFlag == true ? false : true,
          fetchingData: false,
        });
      }
    }).catch(err => console.error(err));
  }

  fetchUserTerms = async () => {
    let path = `usr/ServiceTC`;
    await apiCalls.fetchData(path).then(data => {
      if (data) {
        this.setState({
          TCFlag: data[0].TCFlag,
          showTCModal: data[0].TCFlag == true ? false : true,
          fetchingData: false,
        });
      }
    }).catch(err => console.error(err));
  }

  termsAndConditionsDisplay = () => {
    // ** Enterprise
    let modalStyles = (sessionStorage.userTypeId === "1107" || sessionStorage.userTypeId === "1105") && window.location.href.indexOf("/admin") > -1 ? {
      float: "right",
      fontSize: "12px",
      height: "10px",
      marginRight: "35px",
      display: "inline"
    } : {
      float: "right",
      height: "10px",
      //width: "50px",
      display: "inline",
      marginTop: "15px",
      marginRight: "135px",
    };

    if (!this.state.TCFlag && !this.state.submitted) {
      return (
        <span
          style={modalStyles}
        >
          <div>
            <a
              onClick={this.handleOpenModal}
            >E911 Dialing Terms & Conditions</a>
          </div>
        </span>
      )
    } else return;
  }

  handleOpenModal = () => {
    this.setState({
      showTCModal: true,
    });
  }

  saveTermsAndConditions = async () => {
    await this.setState({ loading: true });
    let path = storedUserRole() === 'SUPER_ADMIN' ? "admin/UpdateEnterpriseTC" : `usr/UpdateServiceTC`;
    let { TCFlag } = this.state;
    if (!TCFlag) {
      return;
    }
    let dto = { TCFlag };
    let response = await apiCalls.post(path, "POST", JSON.stringify(dto)).then(data => data).catch(err => console.error(err));
    if (response.ok) {
      this.setState({ loading: false, showTCModal: false, submitted: true });
    } else {
      console.log(response);
      this.setState({ loading: false });
    }
  }

  splashDisplay = () => {
    const src = sessionStorage.IsWholesale == "true" ? E911MSAWs : E911MSARetail;
    return (
      <Fragment>
        {/* <iframe
          src={sessionStorage.IsWholesale == "true" ? E911MSAWs : E911MSARetail}
          height="500" width="500"
        ></iframe> */}
        <object data={src} type="application/pdf" height="500" width="500">
          <p>
            Your browser does not support PDF viewing.
            <a href={src}>Download PDF</a>
          </p>
        </object>
        <br />
      </Fragment>
    )
  }


  termsAndConditionsModal = () => {
    let { emulatedUser } = sessionStorage;
    if ((!emulatedUser || emulatedUser == "false") && !cngAltevaDctAccount()) {
      const acceptCheckBox = (val) => {
        return (
          <input
            style={{
              display: 'inline-block',
            }}
            type="checkbox"
            checked={this.state.TCFlag}
            className='check-box'
            onChange={(e) => this.setState({ TCFlag: e.target.checked })}
          />
        );
      }

      return (
        <Modal className="TC-modal"
          show={this.state.showTCModal}
          //onHide={!this.state.showTCModal} 
          backdrop='static'>
          <Modal.Header>
            <Modal.Title>E911 DIALING TERMS AND CONDITIONS</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="terms-acknowledgment">
              {this.splashDisplay()}
              {acceptCheckBox()}
              <p style={{ display: "inline", marginLeft: "2px" }}>By clicking, you acknowledge that you have read and agree to be bound by the E911 Dialing Terms and Conditions.</p>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <hr />
            <div className="btns">
              <span>
                <button type="button" className="btn"
                  onClick={this.saveTermsAndConditions}
                  disabled={!this.state.TCFlag}
                >Save</button>
              </span>
            </div>
          </Modal.Footer>
        </Modal>
      )
    } else return <span></span>
  }

  modalOrder = () => {
    if ((!this.state.showTCModal || this.state.showTCModal == false || this.state.showTCModal == "false") && !this.state.fetchingData) {
      if (!sessionStorage.CallRecordingModalLoaded) {
        return <CR_MODAL />
      } else return <span></span>;
    } else {
      return this.termsAndConditionsModal();
    }
  }

  render() {
    return this.modalOrder();
  }
}