import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types'
import IdleTimer from 'react-idle-timer';
import {logout} from '../scripts/session'
import { store } from '../store/store';
import * as actions from '../store/actions';
import IdleWarningModal from '../containers/modals/idleWarningModal'

class IdleLogout extends Component {
    constructor(props) {
        super(props);
        this.state = {displayWarning : false};
    }   

    resetLogout = () => {
        this.setState({displayWarning : false})
        store.dispatch({ type: actions.REFRESH_TOKEN });
    }

    logout = () => {
        logout();
    }

    render() {
        return (
            <Fragment>
                {this.state.displayWarning ? (
                    <IdleWarningModal countdown={this.props.logoutDelay} onCancelLogout={this.resetLogout} onLogout={this.logout}/>
                ) : (
                    <IdleTimer 
                        ref={ref => { this.idleTimer = ref}}
                        element={document}
                        idleAction={() => this.setState({displayWarning : true})}
                        timeout={this.props.logoutTime - this.props.logoutDelay}/>
                )}
            </Fragment>
        );
    }    
}


IdleLogout.propTypes = {
    logoutTime: PropTypes.number,
    logoutDelay: PropTypes.number,
};

IdleLogout.defaultProps = {
    logoutTime: (20 * 60 * 1000),
    logoutDelay: (60 * 1000)
};

export default IdleLogout;