import React, { Component } from 'react';
import * as config from './../../requests/config';
import { DataLoadingSpinner } from './../../components/spinners';

const callChartParams = "?CallType=All_CALLS&CallView=CALLS&CallStatus=ALL_CALLS&FromDate=2017-12-11&ToDate=2018-03-09";
const vmListParams = '?AccountNumber=3100001237-01&DirectoryNumber=6785726904AA4500';
const userAppsParams = '?ServiceNumber=2057219499&PBXUserID=0'; // UserID=0 for logged in user
const myServicesParams = '?UserId=0'; // UserID=0 for logged in user
// const userProfileParams = '?ServiceNumber=77030700877&PBXUserID='

const apiMethods = [
  ['2.1', 'admin/UserServices', 'User Services'],
  ['2.2', 'admin/FavoriteServices', 'Favorite Services'],
  ['2.5', 'admin/LocationStat', 'Location Stats'],
  ['2.6', 'admin/DeviceStat', 'Device Stats'],
  ['2.7', 'admin/QuickView', 'Quick View'],
  ['2.8', 'admin/Locations', 'Locations'],
  ['2.9', 'admin/BillingStat', 'Billing Stats'],
  ['2.10', 'admin/ServiceNumbers', 'Service Numbers'],
  ['2.11', 'admin/CallActivity', 'Call Activity | On Page Load', callChartParams],
  ['2.12', 'admin/Services', 'Services'],
  ['2.22', 'usr/MyServices', 'My Services'],
  ['2.23', 'usr/UserApplications', 'User Applications', userAppsParams],
  ['2.24', 'admin/HasTrunking', 'Has Trunking'],
  ['2.25', 'admin/SipTrunking', 'SIP Trunking'],

  // Not yet ready
  // ['2.13', 'vm/VMList', 'VM List', vmListParams],
]

class ApiStatuses extends Component {

  constructor() {
    super();
    this.state = {};
    this.apiRequest = this.apiRequest.bind(this);
  }

  componentDidMount() {
    apiMethods.forEach((method) => this.apiRequest(...method) );
  }

  apiRequest = async (testId, apiPath, name, params='') => {
    let url = config.baseApiUrl + apiPath + params;
    console.log('url: ' + url);
    let response = await fetch(url, {headers: config.headers() });
    this.setState({
      [testId + '-status']: response.status,
      [testId + '-statusText']: response.statusText
    });
  }

  testResultsRow = (index, testId, apipPath, name, params=null) => {
    return (
      <tr key={index}>
        <td>{this.state[testId + '-status']}</td>
        <td>{this.state[testId + '-statusText']}</td>
        <td>{testId}: {name}</td>
        <td>{apipPath}</td>
        <td>{params}</td>
      </tr>
    )
  }

  render() {
    return (
      <div className="container">
        <div className='col-md-12'>
          <table className='table table-striped'>
            <thead>
              <tr>
                <th>Status</th>
                <th>Message</th>
                <th>Method</th>
                <th>URL</th>
                <th>PARAMS</th>
              </tr>
            </thead>
            <tbody>
              { apiMethods.map((method, index) => this.testResultsRow(index,...method) )}
            </tbody>
          </table>
        </div>
      </div>
    )
  }
}

export default ApiStatuses;
