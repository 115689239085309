import React, { Component }  from 'react';
import { Link } from 'react-router-dom';
import { fetchData } from './../requests/api_calls';
import * as settings from './../scripts/settings';
import { userIsAdmin } from './../scripts/helper';
import { DataLoadingSpinner } from './spinners';
import { checkBillingAccount } from '../utility/utility';

//const categoryLimit = 3;

class GlobalSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchValue: '',
      showResults: false,
      blurText: true,
      loading: false,
      showSearchHint: false
    };

    // I think these can be removed
    this.onFocusSearch = this.onFocusSearch.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
  }

  delayedSearch = (apiPath) => {
    if(this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.fetchServices(apiPath);
    }, settings.searchDelay);
  }

  onFocusSearch = () => {
    let searchText = settings.getGlobalSearchValue();
    if(searchText){
      this.delayedSearch(searchText);
    }
    this.setState({showResults: false, searchValue: searchText, blurText: false});
  }
  
  handleChange = (event) => {
    settings.setGlobalSearchValue(event.target.value);
    this.setState({showResults: false, searchValue:  event.target.value});

    this.delayedSearch(event.target.value.trim());
  }

  handleBlur = () => {
    this.timeout = setTimeout(() => {
      this.setState({showResults:false, searchValue: "", blurText: true, showSearchHint: false });
    }, 500);
  }

  /**
   * (3/5) No longer being used to favor handleChange event handler.
   * Will retain the method until testing proves it is truly no longer needed.
   */
  searchGlobally = (event) => {
    let searchText = event.target.value.trim();
    this.delayedSearch(searchText);
  }

  delayedSearch = (searchText) => {
    if(this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      if (searchText && searchText.length < 3) {
        this.setState({showResults: false, showSearchHint: true});
        settings.setGlobalSearchValue(searchText);
      } else if (searchText.length === 0) {
        this.setState({showResults: false, showSearchHint: false});
        settings.setGlobalSearchValue(searchText);
      } else {
        this.fetchResults(searchText);
      }
    }, settings.searchDelay);
  }

  fetchResults = (searchText) => {
    this.setState({loading: true, showSearchHint: false});
    var apiPath = 'admin/GlobalSearch?term=' + searchText;
    fetchData(apiPath).then((data) => {
      if (data) {
        this.setState({
          searchResults: data,
          showResults: !this.state.blurText
        });
      }else{
        this.setState({
          searchResults: [],
          showResults: true
        });
      }
      this.setState({loading: false});
    }).catch((message)=>{
      console.log("catch((message)")
      console.log(message)
      this.setState({
        searchResults: [], showResults: false, loading: true
      });
    });
  }

  locationResults = () => {
    if (this.state.showResults && 
      this.state.searchResults && 
      this.state.searchResults.LocationList &&
      this.state.searchResults.LocationList.length > 0) {
        let results = this.state.searchResults.LocationList;
        return (
          <div className="search-items">
            <div className="search-header">Locations</div>
            {results.map((item, index) => this.locationItem(item, index))}
          </div>
        )
    } else {
      return null;
    }
  }

  locationItem = (item, index) => {
    return (
      <div className="search-item" key={index}>
        <div className="name">
          <Link to={{pathname: '/locations', state: {preFilter: item.ItemValue} }}>
            {item.ItemText}
          </Link>
        </div>
      </div>
    )
  }

  serviceResults = () => {
    if (this.state.showResults && 
      this.state.searchResults && 
      this.state.searchResults.ServiceList &&
      this.state.searchResults.ServiceList.length > 0) {
      let results = this.state.searchResults.ServiceList;
      return (
        <div className="search-items">
          <div className="search-header">Services</div>
          {results.map((item, index) => this.serviceItem(item, index))}
        </div>
      )
    } else {
      return null;
    }
  }

  serviceItem = (item, index) => {
    return (
      <div className="search-item" key={index}>
        <div className="name">
          <Link to={{pathname: '/services', searchTerm: item.ItemValue }}>
            {item.ItemText}
          </Link>
        </div>
      </div>
    )
  }

  trunkingResults = () => {
    if (this.state.showResults && 
      this.state.searchResults && 
      this.state.searchResults.TrunkingList &&
      this.state.searchResults.TrunkingList.length > 0) {
      let results = this.state.searchResults.TrunkingList;
      return (
        <div className="search-items">
          <div className="search-header">Trunking</div>
          {results.map((item, index) => this.trunkingItem(item, index))}
        </div>
      )
    } else {
      return null;
    }
  }

  noSearchResults = () => {
    if (this.state.searchResults && this.state.searchResults.length === 0) {
      return (
        <div className="search-items">
          <div className="no-results">{settings.noResultsMsg}</div>
        </div>
      );
    } else {
      return null;
    }
  }

  trunkingItem = (item, index) => {
    return (
      <div className="search-item" key={index}>
        <div className="name">
          <Link to={{pathname: '/sips', state: {searchTerm: item.ItemValue} }}>
            {item.ItemText}
          </Link>
        </div>
      </div>
    )
  }

  displayHint = () => {
    return (
      <div className={this.state.showSearchHint ? 'global-search-hint' : 'hidden'}>
        Enter at least 3 characters to search
      </div>
    )
  }

  render() {
    if (userIsAdmin() && !checkBillingAccount()) {
      return (
        <div className="global-search search">
          {this.displayHint()}
          <DataLoadingSpinner className={this.state.loading ? 'load spinner' : 'hidden'}/>
          <label htmlFor="admin_global_search_tb" className="hidden">admin global search</label>
          <input id="admin_global_search_tb" type="text" className="form-control" 
            onBlur={this.handleBlur}
            onFocus={this.onFocusSearch}
            onChange={this.handleChange}
            autoComplete={"off"}
            ref='searchField'
            value={this.state.searchValue}
          />
          <svg width="12" height="12" viewBox="0 0 23 23"><path d="m347 238c0-36-12-66-37-91-25-25-55-37-91-37-35 0-65 12-90 37-25 25-38 55-38 91 0 35 13 65 38 90 25 25 55 38 90 38 36 0 66-13 91-38 25-25 37-55 37-90z m147 237c0 10-4 19-11 26-7 7-16 11-26 11-10 0-19-4-26-11l-98-98c-34 24-72 36-114 36-27 0-53-5-78-16-25-11-46-25-64-43-18-18-32-39-43-64-10-25-16-51-16-78 0-28 6-54 16-78 11-25 25-47 43-65 18-18 39-32 64-43 25-10 51-15 78-15 28 0 54 5 79 15 24 11 46 25 64 43 18 18 32 40 43 65 10 24 16 50 16 78 0 42-12 80-36 114l98 98c7 7 11 15 11 25z" transform="scale(0.046875 0.046875)"></path></svg>
          <div className="search-results" style={{display: this.state.showResults ? 'block':'none' }} >
            {this.locationResults()}
            {this.serviceResults()}
            {this.trunkingResults()}
            {this.noSearchResults()}
          </div>
        </div>
      )
    } else return null;
  }
}
export default GlobalSearch;