import React, { Component } from 'react';
import './../css/spinner.css';

export class DataLoadingSpinner extends Component {
  render() {
    return (
      <div>
        <div className={this.props.className} style={this.props.style}>
          <div className='bounce1'></div>
          <div className='bounce2'></div>
          <div className='bounce3'></div>
        </div>
      </div>
    )
  }
}