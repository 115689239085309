import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { Button } from '@progress/kendo-buttons-react-wrapper';
import _ from 'lodash';
import { DataLoadingSpinner } from '../../components/spinners';
import * as apiCalls from '../../requests/api_calls';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'; // ES6
import PropTypes from 'prop-types';
import InputMask from 'react-input-mask';

class AddEditEnterpriseContact extends Component {

    constructor(props) {
      super(props);
      let canEdit = false;
      if (props.AddEditMode === 'Add')
        canEdit = true;
      else if (props.ContactDetails)
        canEdit = props.ContactDetails.CanEdit;

      this.state = {
          isAdd: props.AddEditMode === 'Add' ? true : false,
          saving: false,
          ContactDetails: {
            EnterpriseId: props.ContactDetails && props.ContactDetails.EnterpriseId ? props.ContactDetails.EnterpriseId : 0,
            OldName: props.ContactDetails && props.ContactDetails.OldName ? props.ContactDetails.OldName : '',
            Name: props.ContactDetails && props.ContactDetails.Name ? props.ContactDetails.Name : '',
            TN: props.ContactDetails && props.ContactDetails.TN ? props.ContactDetails.TN : '',
            CanEdit: canEdit
          },
          showModal: false,
          savingShowSuccess: false,
          savingShowError: false,
          saveResultsMessage: '',
          SuccessMessage: 'Success',
          adding: false
      };
      this.handleOnSave = this.handleOnSave.bind(this);
      this.callUpdateFromModal = this.callUpdateFromModal.bind(this);
    }

    getBlankContactDetails = () => {
        return {
            EnterpriseId: 0,
            OldName: '',
            Name: '',
            TN: '',
            CanEdit: true
        };
    }

    showModal = () => {
        this.setState({showModal: true, ErrorMessage: '', savingShowError: false});
    }

    handleClose = () => {
        let state = this.state;
        if (state.isAdd)
            state.ContactDetails = this.getBlankContactDetails();
        
        state.showModal = false;
        state.savingShowSuccess = false;
        state.savingShowError = false;
        
        this.setState(state);
    }

    handleOnChange=(field, event)=>{

        let state = {...this.state};
        state.ContactDetails[field] = event.target.value;
        this.setState(state);
    }

    handleOnStateChange = (e) => {
        let state = this.state;
        state.ContactDetails.State = e.target.value;
        this.setState(state);
    }

    callUpdateFromModal = (newContactModel) => {
        this.props.UpdateParentFromModal(newContactModel, this.state.adding);
    }

    getSaveMessage = () => {
        if (this.state.saving)
        {
            return (<DataLoadingSpinner className='load spinner'/>)
        } else if (this.state.savingShowError) {
            return (
                <div className="inline inline-fail text-center">
                    {this.state.saveResultsMessage}
                </div>
            );
        } else if (this.state.savingShowSuccess) {
            return (
                <ReactCSSTransitionGroup
                    transitionName="vm-setting-success"
                    transitionAppear={true}
                    transitionAppearTimeout={4000}
                    transitionEnter={true}
                    transitionEnterTimeout={4000}
                    transitionLeave={false}>
                    <div className="inline-success text-center">{this.state.SuccessMessage}</div>
                </ReactCSSTransitionGroup>
            );
        } else {
            return null;
        }
    }

    handleOnSave = () => {

        if (this.state.ContactDetails.Name.toString().trim() === '')
        {
            this.setState({savingShowError: true, saveResultsMessage: 'A Name must be provided!'})
            return;
        }
        if (this.state.ContactDetails.TN.toString().trim() === '')
        {
            this.setState({savingShowError: true, saveResultsMessage: 'A TN must be provided!'})
            return;
        }

        this.setState({saving: true, adding: (this.state.ContactDetails.Name && this.state.ContactDetails.OldName === '') ? true : false});
        let apiPath =  '';
        let verb = '';//this.state.isAdd ? 'POST' : 'PATCH';''
        let requestData = {};
        if (this.state.ContactDetails.OldName === '')
        {
            apiPath = 'admin/AddContactToContactBook';
            verb = 'POST'
            requestData = {
                EnterpriseId: 0,
                TN: this.state.ContactDetails.TN.replace(/_/gi, ""),
                OldName: '',
                Name: this.state.ContactDetails.Name,
                CanEdit: this.state.ContactDetails.CanEdit
            };
        } else {
            apiPath = 'admin/UpdateContactInContactBook';
            verb = 'PATCH';
            requestData = {
                EnterpriseId: this.state.ContactDetails.EnterpriseId,
                OldName: this.state.ContactDetails.OldName,
                TN: this.state.ContactDetails.TN.replace(/_/gi, ""),
                Name: this.state.ContactDetails.Name,
                CanEdit: this.state.ContactDetails.CanEdit
            }
        }
        apiCalls.post(apiPath, verb, JSON.stringify(requestData)).then((data) => {
            if (data.ok)
            {
                let newContactModel = JSON.parse(data.message);
                this.callUpdateFromModal(newContactModel);
                this.setState({ContactDetails: newContactModel, savingShowSuccess: true, saving: false, SuccessMessage: "Success", ErrorMessage: '', savingShowError: false});
            } else {
                this.setState({savingShowError: true, saving: false, saveResultsMessage: 'An error occurred saving Contact in Contact Directory: ' + data.message })
            }
        })
        .catch(message => {
            this.setState({
                saving: false,
                savingShowError: true,
                loading: false,
                saveResultsMessage: 'An error occurred saving Contact in Contact Directory: ' + message
            });
        });
        
    }

    getModalButtons = () => {        
        if (this.state.ContactDetails.CanEdit === true)
        {
            return (
                <div className="btns">
                    <Button key="2" className="btn" click={x => { this.handleOnSave() } }>Save</Button>
                    <Button className="btn" click={x => {this.handleClose()} }>Close</Button>
                </div>
            );
        } else {
            return (
                <div className="btns">
                    <Button className="btn" click={x => {this.handleClose()} }>Close</Button>
                </div>
            );
        }        
    }

    getEditViewButton = () => {
        if (this.state.ContactDetails.CanEdit === true)
            return (
                <Button click={(e) => {this.showModal();} }>
                    <svg width="14" height="14" viewBox="0 0 24 24"><path d="m163 440l-91-91 251-250 90 90z m309-352l-48-48c-12-11-32-11-45 2l-45 45 91 91 45-45c13-13 13-33 2-45z m-408 275l-32 117 117-32z" transform="scale(0.046875 0.046875)"></path></svg>
                </Button>
            );
        else
            return (
                <td>
                    <a onClick={(e) => {this.showModal()}}>View</a>
                </td>
            )
    }

    render() {
        let contact = this.state.ContactDetails;
        let canEdit = contact.CanEdit === true ? true : false;
        if (this.state.isAdd)
        {
            return (
                <span>
                    <div>
                        <a onClick={this.showModal}className="orange btn">Add Contact</a>
                    </div>
                    <Modal show={this.state.showModal} onHide={this.handleClose} dialogClassName='enterprise-contact-modal'>
                        <Modal.Header closeButton>
                            <Modal.Title>Add Enterprise Contact</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div>
                                <ContactTextField id='Name' label="Name:" value={contact.Name} enabled={canEdit} onChange={this.handleOnChange} maxLength={20} /> 
                                <ContactTextField id='TN' label="Phone: " value={contact.TN} enabled={canEdit} onChange={this.handleOnChange} maxLength={15} Mask={'999999999999999'} />
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <hr />
                            {this.getModalButtons()}
                            {this.getSaveMessage()}
                            {/* <SavingMessageControl 
                                showSuccess={this.state.savingShowSuccess} 
                                showLoading={this.state.saving} 
                                showError={this.state.savingShowError}
                                SuccessMessage={this.state.SuccessMessage}
                                SuccessTransitionClass="vm-setting-success"
                                ErrorMessage={this.state.saveResultsMessage}
                            /> */}
                        </Modal.Footer>
                    </Modal>
                </span>
            );            
        } else {
            let Button = this.getEditViewButton();
            return (
                <span>
                    {Button}
                    <Modal show={this.state.showModal} onHide={this.handleClose} dialogClassName='enterprise-contact-modal'>
                        <Modal.Header closeButton>
                            <Modal.Title>Edit Enterprise Contact</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div>
                                <ContactTextField id='Name' label="Name:" value={contact.Name} enabled={canEdit} onChange={this.handleOnChange} maxLength={30} />                                 
                                <ContactTextField id='TN' label="Phone: " value={contact.TN} enabled={canEdit} onChange={this.handleOnChange}  maxLength={15} Mask={'999999999999999'} />
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <hr />
                            {this.getModalButtons()}
                            {this.getSaveMessage()}
                            {/* <SavingMessageControl 
                                showSuccess={this.state.savingShowSuccess} 
                                showLoading={this.state.saving} 
                                showError={this.state.savingShowError}
                                SuccessMessage="Success"
                                SuccessTransitionClass="vm-setting-success"
                                ErrorMessage={this.state.saveResultsMessage}
                            /> */}
                        </Modal.Footer>
                    </Modal>
                </span>
            )
        }
    }
}

class ContactTextField extends Component {
    render() {
        if (this.props.Mask && this.props.Mask !== '')
        {
            return (
                <div className='form-group'>
                    <label>{this.props.label}</label>
                    <InputMask className="form-control" disabled={this.props.enabled === true ? "" : "disabled"} value={(this.props.value? this.props.value:"") }
                        mask={this.props.Mask} onChange={e=> { if (this.props.enabled === true) this.props.onChange(this.props.id,e) } } />
                </div>
            );
        } else {
            return (
            <div className="form-group">
                <label>{this.props.label}</label>
                <input type="type"  maxLength={this.props.maxLength} className="form-control" disabled={this.props.enabled === true ? "" : "disabled"} value={(this.props.value? this.props.value:"") } 
                onChange={e=> { if (this.props.enabled === true) this.props.onChange(this.props.id,e) } }/>
                </div>
            );
        }
    }
};

ContactTextField.propTypes = {
    label: PropTypes.string.isRequired,
    textvalue: PropTypes.string,
    id: PropTypes.string.isRequired,
    onChange: PropTypes.func
}



export default AddEditEnterpriseContact;