import React, { Component } from 'react';
// import { Tooltip } from '@progress/kendo-popups-react-wrapper';
// import '@progress/kendo-ui';
import {Popover, OverlayTrigger} from 'react-bootstrap'

class InfoPop extends Component {

	componentWillMount() {}

	render() {
		const message = this.props.message;
		let popop = <Popover id="popover">{ message }</Popover>;
		return(
			<span className="infopop">
					
					<OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={popop}>
						<a><svg width="12" height="12" viewBox="0 0 24 24"><path d="m318 0c17 0 29 5 38 14c9 9 14 21 14 35c0 17-7 32-20 45c-14 13-30 20-49 20c-16 0-29-5-38-14c-9-9-13-21-12-37c0-15 6-30 18-43c12-13 28-20 49-20m-105 512c-34 0-43-30-28-91c0 0 31-130 31-130c5-19 5-29 0-29c-4 0-13 3-28 9c-14 7-26 13-36 20c0 0-14-23-14-23c31-26 63-48 97-64c34-17 60-25 77-25c27 0 33 28 19 83c0 0-36 136-36 136c-6 22-5 33 3 33c15 0 35-10 60-31c0 0 16 21 16 21c-29 29-59 52-90 67c-31 16-55 24-71 24" transform="scale(0.046875 0.046875)"></path></svg></a>	
					</OverlayTrigger>
			    {/* <Tooltip content={ message } position="top" width={200}>
					<a data-trigger="hover" data-toggle="popover" data-placement="top" data-content='{ this.props.message }'><svg width="12" height="12" viewBox="0 0 24 24"><path d="m318 0c17 0 29 5 38 14c9 9 14 21 14 35c0 17-7 32-20 45c-14 13-30 20-49 20c-16 0-29-5-38-14c-9-9-13-21-12-37c0-15 6-30 18-43c12-13 28-20 49-20m-105 512c-34 0-43-30-28-91c0 0 31-130 31-130c5-19 5-29 0-29c-4 0-13 3-28 9c-14 7-26 13-36 20c0 0-14-23-14-23c31-26 63-48 97-64c34-17 60-25 77-25c27 0 33 28 19 83c0 0-36 136-36 136c-6 22-5 33 3 33c15 0 35-10 60-31c0 0 16 21 16 21c-29 29-59 52-90 67c-31 16-55 24-71 24" transform="scale(0.046875 0.046875)"></path></svg></a>	
			    </Tooltip>	 */}
			</span>
		);
	}
}

export default InfoPop;