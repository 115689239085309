import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { Button } from '@progress/kendo-buttons-react-wrapper';
import { DateTimePicker } from '@progress/kendo-dateinputs-react-wrapper';

class ScheduleModal extends Component {

	constructor(props) {
		super(props);

    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.changeRecurs = this.changeRecurs.bind(this);
    this.getRecurMonths = this.getRecurMonths.bind(this);
    this.getRecurDays = this.getRecurDays.bind(this);
    this.getRecurWeekOfMonths = this.getRecurWeekOfMonths.bind(this);
    this.state = { showModal: false, recurValue:"" };
  }

  handleClose() {
    this.setState({ showModal: false });
  }

  handleShow() {
    this.setState({ showModal: true });
  }

  changeRecurs(event){
    this.setState({recurValue: event.target.value})
  }

	componentDidMount() {		
  }

  render() {
    
    return (
      <div className="setting">
        <span onClick={this.handleShow}> 
          <span className="name">{this.getTitle()}</span>
          <a className="icon btn"><svg width="14" height="14" viewBox="0 0 24 24"><path d="m163 440l-91-91 251-250 90 90z m309-352l-48-48c-12-11-32-11-45 2l-45 45 91 91 45-45c13-13 13-33 2-45z m-408 275l-32 117 117-32z" transform="scale(0.046875 0.046875)"></path></svg></a>
        </span>
        <Modal show={this.state.showModal} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>{this.getTitle()}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>{this.getInfo()}</p>
            <div className="schedule-block">
              <button className="no-btn" data-toggle="collapse" data-target="#sm1">Create a Schedule</button>
              <div className="hidden-group collapse" id="sm1">
                <div className="form-group">
                  <label>Schedule Name</label>
                  <input type="text" className="form-control" />
                </div>
              </div>

              <hr />

              <h4>Schedule Name 1</h4>
              <table className="table">
                <thead>
                  <tr>
                    <th className="check"><input type="checkbox" /></th>
                    <th>Event Name</th>
                    <th>Recurring</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="check"><input type="checkbox" /></td>
                    <td>Snow Day</td>
                    <td>No</td>
                    <td align="right"><a className="icon btn" data-toggle="collapse" data-target="#sm2"><svg width="14" height="14" viewBox="0 0 24 24"><path d="m163 440l-91-91 251-250 90 90z m309-352l-48-48c-12-11-32-11-45 2l-45 45 91 91 45-45c13-13 13-33 2-45z m-408 275l-32 117 117-32z" transform="scale(0.046875 0.046875)"></path></svg></a></td>
                  </tr>
                  <tr className="collapse" id="sm2">
                    <td colSpan="4">
                      <div className="table-edit">
                        <a className="red delete">Delete Event</a>
                        <div className="form-group">
                          <label>Event Name</label>
                          <input type="type" className="form-control" />
                        </div>
                        <div className="form-group">
                          <label>Recurs</label>
                          <select className="form-control" onChange={this.changeRecurs} value={this.state.recurValue}>
                            <option defaultValue="">-Select-</option>
                            <option defaultValue="Never">Never</option>
                            <option defaultValue="Daily">Daily</option>
                            <option defaultValue="Weekly">Weekly</option>
                            <option defaultValue="MonthlyByDay">Monthly by Day</option>
                            <option defaultValue="MonthlyByWeek">Monthly by Week</option>
                            <option defaultValue="YearlyByDay">Yearly by Day</option>
                            <option defaultValue="YearlyByWeek">Yearly by Week</option>
                          </select>
                        </div>
                        {this.getRecurrentControl()}
                        <div className="btns">
                          <button className="btn" data-toggle="collapse" data-target="#sm2">Save Event</button>
                          <button className="btn">Cancel</button>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="4">
                      <div className="btns">
                        <button className="btn" data-toggle="collapse" data-target="#sm2">Add event</button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>

              <h4>Schedule Name 2</h4>
              <table className="table">
                <thead>
                  <tr>
                    <th className="check"><input type="checkbox" /></th>
                    <th>Event Name</th>
                    <th>Recurring</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="check"><input type="checkbox" /></td>
                    <td>Snow Day</td>
                    <td>No</td>
                    <td align="right"><a className="icon btn" data-toggle="collapse" data-target="#sm2"><svg width="14" height="14" viewBox="0 0 24 24"><path d="m163 440l-91-91 251-250 90 90z m309-352l-48-48c-12-11-32-11-45 2l-45 45 91 91 45-45c13-13 13-33 2-45z m-408 275l-32 117 117-32z" transform="scale(0.046875 0.046875)"></path></svg></a></td>
                  </tr>
                  <tr className="collapse" id="sm2">
                    <td colSpan="4">
                      <div className="table-edit">
                        <a className="red delete">Delete Event</a>
                        <div className="form-group">
                          <label>Event Name</label>
                          <input type="type" className="form-control" />
                        </div>
                        <div className="form-group">
                          <label>Recurs</label>
                          <select className="form-control"  onChange={this.changeRecurs} value={this.state.recurValue}>
                            <option defaultValue="">-Select-</option>
                            <option defaultValue="Never">Never</option>
                            <option defaultValue="Daily">Daily</option>
                            <option defaultValue="Weekly">Weekly</option>
                            <option defaultValue="MonthlyByDay">Monthly by Day</option>
                            <option defaultValue="MonthlyByWeek">Monthly by Week</option>
                            <option defaultValue="YearlyByDay">Yearly by Day</option>
                            <option defaultValue="YearlyByWeek">Yearly by Week</option>
                          </select>
                        </div>
                        {this.getRecurrentControl()}
                        <div className="btns">
                          <button className="btn" data-toggle="collapse" data-target="#sm2">Save Event</button>
                          <button className="btn">Cancel</button>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="4">
                      <div className="btns">
                        <button className="btn" data-toggle="collapse" data-target="#sm2">Add event</button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Modal.Body>
          <Modal.Footer>
              <hr />
              <div className="btns">
                <Button className="btn">Save</Button>
                <Button className="btn" click={this.handleClose}>Cancel</Button>
              </div>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }

  getInfo() {
    switch(this.props.type){
      case "BUSINESS_HOURS":
        return "Set Business Hours Schedules to automatically trigger feature functionality and time-of-day routing for services.";
      case "HOLIDAY_SCHEDULE":
        return "Set Holiday Hours Schedules to automatically trigger feature functionality and day(s)-of-year routing for services.";
      default:
        return "";
    }
  }

  getTitle(){
    switch(this.props.type){
      case "BUSINESS_HOURS":
        return "Schedule - Business Hours";
      case "HOLIDAY_SCHEDULE":
        return "Schedule - Holiday";
      default:
        return "";
    }
  }
 
  getRecurrentControl(){
    switch(this.state.recurValue){
      case "Never":
        return (
          <div>
            {this.getTime()}
          </div>
        );
      case "Daily":
        return (
          <div>
            {this.getTime()}
            <div className="form-group">
              <label>Recur every how many days</label>
              <input type="type" className="form-control" />
            </div>
            {this.getRecurEnd()}
          </div>
        );     
      case "Weekly":
        return (
          <div>
            {this.getTime()}
            <div className="form-group">
              <label>Recur every how many weeks</label>
              <input type="type" className="form-control" />
            </div>
            <div className="form-group">
              <label className="">
                <input type="checkbox" /> Monday
              </label>
            </div>
            <div className="form-group">
              <label className="">
                <input type="checkbox" /> Tuesday
              </label>
            </div>
            <div className="form-group">
              <label className="">
                <input type="checkbox" /> Wednesday
              </label>
            </div>
            <div className="form-group">
              <label className="">
                <input type="checkbox" /> Thursday
              </label>
            </div>
            <div className="form-group">
              <label className="">
                <input type="checkbox" /> Friday
              </label>
            </div>
            <div className="form-group">
              <label className="">
                <input type="checkbox" /> Saturday
              </label>
            </div>
            <div className="form-group">
              <label className="">
                <input type="checkbox" /> Sunday
              </label>
            </div>
            {this.getRecurEnd()}
          </div>
        );     
      case "Monthly by Day":
        return (
          <div>
            {this.getTime()}
            <div className="form-group">
              <label>Recur every how many months</label>
              <input type="type" className="form-control" />
            </div>
            <div className="form-group">
              <label>Numerical day of the month:</label>
              <input type="type" className="form-control" />
            </div>
            {this.getRecurEnd()}
          </div>
        );            
      case "Monthly by Week":
        return (
          <div>
            {this.getTime()}
            <div className="form-group">
              <label>Recur every how many months</label>
              <input type="type" className="form-control" />
            </div>
            <div className="form-group">
              <label>Week of the month</label>
              {this.getRecurWeekOfMonths()}
            </div>
            <div className="form-group">
              <label>Day of the week:</label>
              {this.getRecurDays()}
            </div>
            {this.getRecurEnd()}
          </div>
        );            
      case "Yearly by Day":
        return (
          <div>
            {this.getTime()}
            <div className="form-group">
              <label>Recur every how many years</label>
              <input type="type" className="form-control" />
            </div>
            <div className="form-group">
              <label>The month</label>
              {this.getRecurMonths()}
            </div>
            <div className="form-group">
              <label>Numerical day of the week:</label>
              <input type="type" className="form-control" />
            </div>
            {this.getRecurEnd()}
          </div>
        );           
      case "Yearly by Week":
        return (
          <div>
            {this.getTime()}
            <div className="form-group">
              <label>Recur ever how many years</label>
              <input type="type" className="form-control" />
            </div>
            <div className="form-group">
              <label>The month</label>
              {this.getRecurMonths()}
            </div>
            <div className="form-group">
              <label>Week of the month</label>
              {this.getRecurWeekOfMonths()}
            </div>
            <div className="form-group">
              <label>Day of the week:</label>
              {this.getRecurDays()}
            </div>
            {this.getRecurEnd()}
          </div>
        );
    }
  }

  getTime() {
    return (
      <div className="form-group">
        <label className="switch">
          <input type="checkbox" data-toggle="collapse" data-target="#sd1" />
          <span className="slider round"></span>
        </label>
        All Day Event
        <div className="collapse show" id="sd1">
          <div className="form-group">
            <label>Start Date Time</label>
            <DateTimePicker
              min={new Date(2017, 9, 1)}
              max={new Date(2017, 10, 1)}
              value={new Date(2017, 9, 15)}  
              format="yyyy/MM/dd hh:mm"
              change={(e) => {console.log("value changed to:" + e.sender.value())}}
            />
          </div>
          <div className="form-group">
              <label>End Date Time</label>
              <DateTimePicker
                min={new Date(2017, 9, 1)}
                max={new Date(2017, 10, 1)}
                value={new Date(2017, 9, 15)}  
                format="yyyy/MM/dd hh:mm"
                change={(e) => {console.log("value changed to:" + e.sender.value())}}
              />
          </div>
        </div>
      </div>
    )
  }

  getRecurEnd(){
    return (
    <div className="form-group">
      <label>End</label>
      <select className="form-control">
        <option defaultValue="Never">Never</option>
        <option defaultValue="After">After</option>
        <option defaultValue="Date">Date</option>
      </select>
    </div>);
  }
  getRecurMonths(){
    return (
      <select className="form-control">
        <option selected="selected" defaultValue="January">January</option>
        <option defaultValue="February">February</option>
        <option defaultValue="March">March</option>
        <option defaultValue="April">April</option>
        <option defaultValue="May">May</option>
        <option defaultValue="June">June</option>
        <option defaultValue="July">July</option>
        <option defaultValue="August">August</option>
        <option defaultValue="September">September</option>
        <option defaultValue="October">October</option>
        <option defaultValue="November">November</option>
        <option defaultValue="December">December</option>
      </select>
    );
  }
  getRecurDays(){
    return (
      <select className="form-control">
        <option defaultValue="Sunday">Sunday</option>
        <option defaultValue="Monday">Monday</option>
        <option defaultValue="Tuesday">Tuesday</option>
        <option defaultValue="Thursday">Thursday</option>
        <option defaultValue="Friday">Friday</option>
        <option defaultValue="Saturday">Saturday</option>
      </select>
    );
  }
  getRecurWeekOfMonths(){
    return (
      <select className="form-control">
        <option defaultValue="First">First</option>
        <option defaultValue="Second">Second</option>
        <option defaultValue="Third">Third</option>
        <option defaultValue="Fourth">Fourth</option>
        <option defaultValue="Last">Last</option>
      </select>
    );
  }
};

export default ScheduleModal;
