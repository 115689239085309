import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { Button } from '@progress/kendo-buttons-react-wrapper';

// <ExampleModal />

class CollaborateBridgeModal extends Component {

	constructor(props) {
		super(props);

    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);

    this.state = { showModal: false };
  }

  handleClose() {
    this.setState({ showModal: false });
  }

  handleShow() {
    this.setState({ showModal: true });
  }

	componentDidMount() {		
	}

  render() {
    return (
      <div className="setting">
        <span onClick={this.handleShow}> 
          <span className="name">Collaborate Bridge</span>
          <a className="icon btn"><svg width="14" height="14" viewBox="0 0 24 24"><path d="m163 440l-91-91 251-250 90 90z m309-352l-48-48c-12-11-32-11-45 2l-45 45 91 91 45-45c13-13 13-33 2-45z m-408 275l-32 117 117-32z" transform="scale(0.046875 0.046875)"></path></svg></a>
        </span>

        <Modal show={this.state.showModal} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Collaborate Bridge</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="collaborate-bridge">
              <div>
                <p>Collaborate Bridge is your “My Room” audio conferencing dial-in that supports conference calls of up to 60 participants.</p>
                <hr />
                <p>Your Collaborate Bridge Details:<br />
                  Name: <b>Collaborate Bridge 1249195</b><br />
                  Supports Outdial: <b>Yes</b><br />
                  Phone Number: <b>123 123 1234 #1111</b></p>
                <hr />
              </div>
              <div>
                <h5>Instant Rooms Common Configuration Settings</h5>
                <div className="form-group">
                  <label className="switch right">
                    <input type="checkbox" />
                    <span className="slider round"></span>
                  </label>
                  Play tone on arrival and exit?
                </div>
                <div className="form-group">
                  <label className="switch right">
                    <input type="checkbox" />
                    <span className="slider round"></span>
                  </label>
                  End room session when owner leaves
                </div>
                  <div className="form-group">
                  <label className="switch right">
                    <input type="checkbox" />
                    <span className="slider round"></span>
                  </label>
                  Require owner to start room session
                </div>
                <hr />
                <table className="table">
                  <thead>
                    <tr>
                        <th>Room Type</th>
                        <th>Room ID</th>
                        <th>Name</th>
                        <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>My Room</td>
                      <td>12345</td>
                      <td>My Room</td>
                      <td align="right"><a className="icon btn"  data-toggle="collapse" data-target="#ar1" ><svg width="14" height="14" viewBox="0 0 24 24"><path d="m163 440l-91-91 251-250 90 90z m309-352l-48-48c-12-11-32-11-45 2l-45 45 91 91 45-45c13-13 13-33 2-45z m-408 275l-32 117 117-32z" transform="scale(0.046875 0.046875)"></path></svg></a></td>
                    </tr>
                    <tr className="collapse" id="ar1">
                      <td colSpan="4">
                        <div className="table-edit">
                          <div className="halves">
                            <div className="half">
                              <div className="form-group">
                                <label >Room Type</label>
                                <input type="type" className="form-control" defaultValue="My Room" disabled />
                              </div>
                            </div>
                            <div className="half">
                              <div className="form-group">
                                <label >Room ID <a href="">Regenerate Room ID</a></label>
                                <input type="type" className="form-control" defaultValue="12345" disabled />
                              </div>
                            </div>
                          </div>
                          <div className="form-group">
                            <label >Name</label>
                            <input type="type" className="form-control" defaultValue="My room" />
                          </div>
                          <div className="form-group">
                            <label className="switch right">
                              <input type="checkbox" />
                              <span className="slider round"></span>
                            </label>
                            Play tone on arrival and exit?
                          </div>
                          <div className="form-group">
                            <label className="switch right">
                              <input type="checkbox" />
                              <span className="slider round"></span>
                            </label>
                            End room session when owner leaves
                          </div>
                          <div className="form-group">
                            <label className="switch right">
                              <input type="checkbox" />
                              <span className="slider round"></span>
                            </label>
                            Require owner to start room session
                          </div>
                          <hr />
                          <div className="btns">
                            <a href="" className="btn">Save</a>
                            <a href="" className="btn">Cancel</a>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
              <hr />
              <div className="btns">
              <Button className="btn" click={this.handleClose}>Close</Button>
              </div>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
};

export default CollaborateBridgeModal;
