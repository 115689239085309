import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { DataLoadingSpinner } from '../../components/spinners';
import { Grid, GridColumn as Column, GridCell } from '@progress/kendo-react-grid';
import { headerToolTip } from '../../components/ellipsisHeaderTooltip';
import * as apiCalls from '../../requests/api_calls';
import * as config from '../../requests/config';
import * as settings from '../../scripts/settings';
import * as _ from 'lodash';
import * as utility from '../../utility/utility';
import { Modal } from 'react-bootstrap';
import ManageTrunkGroupUsers from './components/manage_trunk_users';
import * as helper from '../../scripts/helper';
import { ComboBox } from '@progress/kendo-react-dropdowns';

export default class EnterpriseTrunking extends Component {
    constructor() {
        super();

        // https://localhost:44316/enterprise-trunking

        this.state = {
            loading: false,
            EnterpriseTrunksAvailable: [],
            CurrentTrunk: "",
            RoutingType: "",
            TrunkDetails: null,
            OriginalDetails: null,
            Listings: [],
            OriginalListings: [],
            showConfirmationModal: false,
            Submission: false,
            submitError: false,
            errorMsg: "",
            EditName: false,
            TrunkOptions: [],
        };

        const transitionToManageTrunkGroup = this.transitionToManageTrunkGroup.bind(this);
        const updateInputValue = this.updateInputValue.bind(this);
        const updateTrunkStatus = this.updateTrunkStatus.bind(this);
        const getOriginalListings = this.getOriginalListings.bind(this);

        class ManageTrunkStatusCell extends GridCell {
            constructor(props) {
                super(props);
            }
            render() {
                let { IsAssigned } = this.props.dataItem; // TODO: add params and create transistion function
                let RoutingType = getOriginalListings()[1];
                return (
                    <td>
                        <a
                            onClick={(e) => { updateTrunkStatus(this.props.dataItem, 'IsAssigned') }}
                            className="a btn"
                            disabled={RoutingType === "ordered" ? true : false}
                        >{IsAssigned ? 'Assigned' : 'Available'}
                        </a>
                    </td>
                );
            }
        }

        class ManageTrunkCell extends GridCell {
            constructor(props) {
                super(props);
            }
            render() {
                let { GroupId, TrunkGroupName } = this.props.dataItem;
                let original = getOriginalListings()[0];
                original = original.filter(itm => itm.TrunkGroupName === this.props.dataItem.TrunkGroupName)[0]

                if (original.IsAssigned) {
                    return (
                        <td style={{ position: 'relative', right: '35px' }}>
                            <a
                                onClick={() => { transitionToManageTrunkGroup(GroupId, TrunkGroupName) }}
                                className="icon btn">
                                <svg width="14" height="14" viewBox="0 0 24 24">
                                    <path d="m163 440l-91-91 251-250 90 90z m309-352l-48-48c-12-11-32-11-45 2l-45 45 91 91 45-45c13-13 13-33 2-45z m-408 275l-32 117 117-32z" transform="scale(0.046875 0.046875)"></path>
                                </svg>
                            </a>
                        </td>
                    )
                } else {
                    return <td></td>
                }
            }
        }

        class DataCell extends GridCell {
            constructor(props) {
                super(props);
            }
            render() {
                let { IsAssigned, Priority, Weight } = this.props.dataItem;
                if (IsAssigned) {
                    return (
                        <td>
                            <input
                                value={this.props.dataItem[this.props.field]}
                                onChange={(e) => updateInputValue(this.props.dataItem, this.props.field, e)}
                                style={{
                                    width: '20%',
                                    display: 'inline-block',
                                    textAlign: 'center',
                                }}
                                type="number"
                                min="1"
                                max={this.props.field === "Priority" ? "10" : "65536"}
                            />
                        </td>
                    )
                } else {
                    return (
                        <td></td>
                    )
                }
            }
        }

        class SelectServiceLink extends GridCell {
            render() {
                let item = this.props.dataItem;
                let path = '/iframe_page/MSP_SUBSCRIBER/' + item.GroupId + '/' + item.TrunkGroupName;
                return (
                    <td>
                        <Link to={path}>
                            {item.TrunkGroupName}
                        </Link>
                    </td>
                );
            }
        }
        this.selectServiceLink = SelectServiceLink;
        this.ManageTrunkCell = ManageTrunkCell;
        this.ManageTrunkStatusCell = ManageTrunkStatusCell;
        this.DataCell = DataCell;
    }

    componentDidMount() {
        if (sessionStorage.hasEntTrunking === "true") {
            this.fetchEnterpriseTrunks();
        } else {
            this.props.history.push("/");
        }
    }

    getOriginalListings = () => {
        let list = [this.state.OriginalListings, this.state.RoutingType];
        return list;
    }

    updateTrunkStatus = async (productItem, field, e) => {
        if (this.state.RoutingType === "ordered") {
            return;
        } else {
            let value = !productItem[field]
            productItem[field] = value;
            let existingArray = this.state.Listings;
            let index = _.findIndex(existingArray, s => s.TrunkGroupName === productItem.TrunkGroupName);
            let newOptions = this.state.Listings.slice();
            newOptions[index] = productItem;
            this.state.TrunkDetails.TrunkGroupAssignments = newOptions;
            await this.setState({ Listings: newOptions, TrunkDetails: this.state.TrunkDetails });
            // console.log('ds', this.state.Listings);
            // console.log('check deets', this.state.TrunkDetails);
        }
    }

    updateInputValue = async (productItem, field, e) => {
        let value = e.target.value === "" ? 0 : parseInt(e.target.value);
        productItem[field] = value;
        let TrunkDetails = { ...this.state.TrunkDetails };
        let index = _.findIndex(TrunkDetails.TrunkGroupAssignments, s => s.TrunkGroupName === productItem.TrunkGroupName);
        TrunkDetails.TrunkGroupAssignments[index] = productItem;
        await this.setState({ TrunkDetails });
        //console.log('current deets', this.state.TrunkDetails);
    }

    fetchEnterpriseTrunks = async () => {
        let { TrunkDetails } = this.state;
        await this.setState({ loading: true });
        let path = "admin/GetEnterpriseTrunks";
        await apiCalls.fetchData(path).then(data => {
            if (data) {
                let copy = JSON.parse(JSON.stringify(data));
                let options = copy.map((itm, idx) => {
                    return <option key={idx} value={itm.EnterpriseTrunk}>{itm.EnterpriseTrunk}</option>
                });
                //let options = copy.map((itm, idx) => itm.EnterpriseTrunk);
                let trunkOption = !TrunkDetails ? data[0].EnterpriseTrunk : TrunkDetails.EnterpriseTrunkName;
                let trunkRouting = data.filter(itm => itm.EnterpriseTrunk === trunkOption)[0].RoutingType;
                //console.log('finder', trunkOption, trunkRouting);
                this.setState({
                    EnterpriseTrunksAvailable: data,
                    CurrentTrunk: trunkOption,
                    RoutingType: trunkRouting,
                    TrunkOptions: options,
                    loading: false,
                });
                this.fetchTrunkDetails(trunkOption);
                this.fetchAvailableTrunkGroups(trunkOption);
            } else {
                this.setState({
                    EnterpriseTrunksAvailable: [],
                    CurrentTrunk: "",
                    RoutingType: "",
                    TrunkOptions: [],
                    loading: false,
                });
            }
        }).catch(err => console.error(err));
    }

    fetchTrunkDetails = async (enterpriseTrunk) => {
        await this.setState({ loading: true });
        if (this.state.CurrentTrunk) {
            let path = `admin/GetEnterpriseTrunkDetails?EnterpriseTrunkName=${enterpriseTrunk}`;
            await apiCalls.fetchData(path).then(data => {
                if (data) {
                    let copy = JSON.parse(JSON.stringify(data));
                    this.setState({
                        TrunkDetails: data,
                        OriginalDetails: copy,
                        loading: false,
                    });
                } else {
                    this.setState({
                        TrunkDetails: [],
                        loading: false,
                    });
                }
            }).catch(err => console.error(err));
        }
    }

    fetchAvailableTrunkGroups = async (EnterpriseTrunkName) => {
        await this.setState({ loading: true });
        let path = `admin/GetAvailableTrunkGroups?EnterpriseTrunkName=${EnterpriseTrunkName}`;
        await apiCalls.fetchData(path).then(data => {
            if (data) {
                //console.log('trunks', data);
                let copy = JSON.parse(JSON.stringify(data));
                this.setState({
                    Listings: data,
                    OriginalListings: copy,
                    loading: false,
                });
            } else {
                this.setState({
                    Listings: [],
                    loading: false,
                });
            }
        }).catch(err => console.error(err));
    }

    transitionToManageTrunkGroup = (groupId, trunkGroupName) => {
        this.props.history.push(`/enterprise-trunk-group/${groupId}/${trunkGroupName}`)
    }

    gridContent = () => {
        if (this.state.Listings && this.state.Listings.length > 0) {
            return (
                <Grid
                    style={{
                        //height: `${this.state.gridHeight}px`,
                    }}
                    data={this.state.Listings}
                //sortable={{ allowUnsort: true, mode: 'single' }}
                //sort={this.state.Sort}
                //onSortChange={this.sortChange}
                //filterable
                //filter={this.state.filter}
                //onFilterChange={this.handleFilterChange}
                >
                    <Column field="TrunkGroupName" title={headerToolTip("Trunk Group")} cell={this.selectServiceLink} />
                    <Column field="TrunkStatus" title={headerToolTip("Available / Assigned")} cell={this.ManageTrunkStatusCell} />
                    <Column field="Priority" title={headerToolTip("Priority")} cell={this.DataCell} />
                    <Column field="Weight" title={headerToolTip("Weight")} cell={this.DataCell} />
                    <Column width="120px" title={headerToolTip("Manage Trunk")} cell={this.ManageTrunkCell} />
                    {/* <Column width="120px" title={headerToolTip("Manage Users")} cell={this.ManageUsersCell} /> */}
                    <Column title=' ' field='filler' />
                </Grid>
            )
        } else {
            return <p style={{ textAlign: 'center', paddingTop: '15px' }}>No Available Usage Data</p>
        }
    }

    dataDropDown = (options, val) => {
        return (
            <div
                style={{
                    display: 'inline-block',
                    textAlign: 'center',
                }}
            >
                <select className="form-control"
                    onChange={(e) => this.handleSelectChange(e)}
                    value={val}
                >
                    {options}
                </select>
            </div>
        )
    }

    handleSelectChange = async (e, key) => {
        let { value } = e.target;
        let routingType = this.state.EnterpriseTrunksAvailable.filter(itm => itm.EnterpriseTrunk === value)[0].RoutingType;
        //console.log('---HERE---', x);
        if (value !== this.state.CurrentTrunk) {
            await this.setState({
                CurrentTrunk: value,
                RoutingType: routingType,
                EditName: false,
            });
            this.fetchTrunkDetails(this.state.CurrentTrunk);
            this.fetchAvailableTrunkGroups(this.state.CurrentTrunk);
        }
        //console.log('current trunk', this.state.CurrentTrunk);
    }

    inputDataField = (val, key, type = 'text') => {
        return (
            <input
                style={{
                    width: key === 'EnterpriseTrunkName' ? '18%' : '8%',
                    display: 'inline-block',
                    textAlign: 'center',
                }}
                type={type}
                //id={labelFor}
                disabled={key === "RoutingType" ? true : false}
                name={key}
                value={val}
                min={type === "number" ? "1" : ""}
                max={type === "number" ? "10" : ""}
                className="form-control"
                onChange={(e) => this.handleInputChange(e, key)}
            />
        )
    }

    editEnterpriseTrunkField = (val, key) => {
        if (!this.state.EditName) {
            return (
                <span>
                    &nbsp;
          <a
                        onClick={() => this.setState({ EditName: !this.state.EditName })}
                    >[Edit]</a>
                </span>
            )
        } else {
            return (
                <span>
                    &nbsp;
          {this.inputDataField(val, key)}
                    &nbsp;
          <a
                        onClick={async () => {
                            let TrunkDetails = { ...this.state.TrunkDetails };
                            TrunkDetails[key] = this.state.CurrentTrunk;
                            await this.setState({ TrunkDetails, EditName: !this.state.EditName });
                            //this.setState({ EditName: !this.state.EditName })
                        }}
                    >[X]</a>
                </span>
            )
        }
    }

    trunkDataRow = (label, field, editField = null) => {
        return (
            <div className='form-group-wrapper'
                style={{
                    margin: '1%',
                }}
            >
                <label
                    style={{
                        display: 'inline-block',
                        width: '30%',
                    }}
                >{label}: </label>
                {field}
                {this.state.TrunkDetails.AllowTrunkNameChange === true ? editField : null}
            </div >
        )
    }

    routeExhaustionAction = (val, forwardAddress = "") => {
        return (
            <div className='form-group-wrapper'
                style={{
                    margin: '1%',
                    //position: 'relative',
                    //bottom: '50px',
                }}>
                {/* <form
          onChange={(e) => this.handleInputChange(e, 'RerouteExhaustAction')}
        > */}

                <label
                    style={{
                        display: 'inline-block',
                        width: '30%',
                        position: 'relative',
                        bottom: '50px',
                    }}>Route Exhaustion Action</label>
                <div
                    style={{
                        display: 'inline-block',
                        //position: 'relative',
                        //top: '50px'
                    }}
                >
                    <input type="radio" id="None" name="routeExhaustAction" value={0} checked={val === 0 || val === "0" ? true : false} onChange={(e) => this.handleInputChange(e, 'RerouteExhaustAction')} />
                    <label htmlFor="None">None</label>
                    <br />
                    <input type="radio" id="Forward" name="routeExhaustAction" value={1} checked={val === 0 || val === "0" ? false : true} onChange={(e) => this.handleInputChange(e, 'RerouteExhaustAction')} />
                    <label htmlFor="forward">Forward to Phone</label>
                    <br />
                    <input
                        type="text"
                        value={forwardAddress}
                        disabled={val === 0 || val === "0" ? true : false}
                        onChange={(e) => this.handleInputChange(e, 'RouteExhaustionForwardAddress')}
                        maxLength="30"
                    />

                </div>
                {/* </form> */}
            </div >

        )
    }

    handleInputChange = async (e, key) => {
        let { value } = e.target;
        let TrunkDetails = { ...this.state.TrunkDetails };
        TrunkDetails[key] = value;
        await this.setState({ TrunkDetails });
    }

    saveEnterpriseTrunk = async () => {
        let { TrunkDetails } = this.state;
        let apiPath = `admin/UpdateEnterpriseTrunkDetails`;
        let dto = {
            //OldEnterpriseTrunkName: TrunkDetails.OldEnterpriseTrunkName ? TrunkDetails.OldEnterpriseTrunkName : TrunkDetails.EnterpriseTrunkName,
            OldEnterpriseTrunkName: this.state.CurrentTrunk,
            EnterpriseTrunkName: this.state.EditName ? TrunkDetails.EnterpriseTrunkName : this.state.CurrentTrunk,
            MaxNumRerouteAttempts: parseInt(TrunkDetails.MaxNumRerouteAttempts),
            MaxNumRerouteWithPriority: parseInt(TrunkDetails.MaxNumRerouteWithPriority),
            RerouteExhaustAction: parseInt(TrunkDetails.RerouteExhaustAction),
            RouteExhaustionForwardAddress: parseInt(TrunkDetails.RerouteExhaustAction) == 1 && TrunkDetails.RouteExhaustionForwardAddress != '' && TrunkDetails.RouteExhaustionForwardAddress != null
                ? TrunkDetails.RouteExhaustionForwardAddress.toString() : null,
            EnableCapacityManagement: TrunkDetails.EnableCapacityManagement,
            MaxActiveCalls: TrunkDetails.MaxActiveCalls,
            CapacityExceededInitialAlarm: TrunkDetails.CapacityExceededInitialAlarm,
            CapacityExceededOffsetValue: TrunkDetails.CapacityExceededOffsetValue,
            TrunkGroupAssignments: TrunkDetails.TrunkGroupAssignments,
        };
        //console.log('DTO', dto);
        //console.log('JSON DTO', JSON.stringify(dto));
        let response = await apiCalls.post(apiPath, 'POST', JSON.stringify(dto)).then(res => res).catch(err => console.error(err));
        if (response) {
            //console.log('res', response);
            if (response.ok) {
                await this.setState({
                    Submission: true,
                    submitError: false,
                    EditName: false,
                });
            } else {
                let assignedTrunks = helper.filterNestedObject('IsAssigned', true, TrunkDetails.TrunkGroupAssignments) || [];
                //console.log('hereeee', assignedTrunks);
                await this.setState({
                    Submission: true,
                    submitError: true,
                    EditName: false,
                    errorMsg: (helper.filterNestedObject('Priority', 0, assignedTrunks) && helper.filterNestedObject('Priority', 0, assignedTrunks).length > 0) || (helper.filterNestedObject('Weight', 0, assignedTrunks) && helper.filterNestedObject('Weight', 0, assignedTrunks).length > 0) ? "Please provide a priority & weight (minimum of 1) for the desired trunk group assignment." : response.message,
                });
            }
            this.fetchEnterpriseTrunks();
            await this.setState({ showConfirmationModal: false });
            //console.log('update ent trunk res', response);
            //this.componentDidMount();
        }
    }

    undoEnterpriseTrunk = () => {
        this.setState({ EditName: false });
        this.fetchTrunkDetails(this.state.CurrentTrunk);
        this.fetchAvailableTrunkGroups(this.state.CurrentTrunk);
    }

    cardContent = () => {
        let { CurrentTrunk, TrunkDetails, loading, TrunkOptions, RoutingType } = this.state;

        if (!loading && CurrentTrunk && TrunkDetails) {
            let {
                CapacityExceededInitialAlarm,
                CapacityExceededOffsetValue,
                Enable,
                EnterpriseTrunkName,
                MaxActiveCalls,
                MaxNumRerouteAttempts,
                MaxNumRerouteWithPriority,
                OldEnterpriseTrunkName,
                RerouteExhaustAction,
                RouteExhaustionForwardAddress,
                TrunkGroupAssignments
            } = TrunkDetails;
            //console.log("routing type", RoutingType);
            return (
                <div className="card-content">
                    <div className="section-one"
                        style={{
                            //display: 'flex',
                            //flexDirection: 'column',
                        }}
                    >
                        {this.trunkDataRow("Enterprise Trunk Name", this.dataDropDown(TrunkOptions, CurrentTrunk), this.editEnterpriseTrunkField(EnterpriseTrunkName, 'EnterpriseTrunkName'))}
                        {this.trunkDataRow("Routing Type", this.inputDataField(RoutingType, 'RoutingType'))}
                        {this.trunkDataRow("Max # of Reroute Attempts (max 10)", this.inputDataField(MaxNumRerouteAttempts, 'MaxNumRerouteAttempts', 'number'))}
                        {this.state.RoutingType === "ordered" ? null : this.trunkDataRow("Max # of Reroute Attempts within a Priority (max 10)", this.inputDataField(MaxNumRerouteWithPriority, 'MaxNumRerouteWithPriority', 'number'))}
                        {this.routeExhaustionAction(RerouteExhaustAction, RouteExhaustionForwardAddress)}
                    </div>
                    <div className="section-two"
                        style={{
                            marginTop: '2%',
                        }}
                    >
                        <small>* Trunk Group Weight must be an Integer between 1 and 65536</small><br />
                        <small>* Trunk Group Priority must be an Integer between 1 and 10</small>
                        {this.gridContent()}
                    </div>
                </div >
            )
        } else {
            return <DataLoadingSpinner className='load spinner' />
        }
    }

    submissionMsg = () => {
        if (this.state.Submission) {
            if (this.state.submitError) {
                return <small style={{
                    color: 'red',
                    fontSize: '50%',
                }}>{this.state.errorMsg && this.state.errorMsg != "" ? this.state.errorMsg : "Submission Failure: Changes were not processed!"}</small>
            } else {
                return <small style={{
                    color: 'green',
                    fontSize: '50%',
                }}>Submission Sucess: Changes were processed!</small>
            }
        }
    };

    enterpriseTrunkUpdateBtns = () => {
        let { TrunkDetails, OriginalDetails, Listings, OriginalListings } = this.state;
        if (!TrunkDetails) {
            return;
        } else {
            if (
                parseInt(TrunkDetails.MaxNumRerouteAttempts) === OriginalDetails.MaxNumRerouteAttempts &&
                parseInt(TrunkDetails.MaxNumRerouteWithPriority) === OriginalDetails.MaxNumRerouteWithPriority &&
                parseInt(TrunkDetails.RerouteExhaustAction) === OriginalDetails.RerouteExhaustAction &&
                TrunkDetails.RouteExhaustionForwardAddress === OriginalDetails.RouteExhaustionForwardAddress &&
                TrunkDetails.EnterpriseTrunkName === OriginalDetails.EnterpriseTrunkName &&
                //!this.state.EditName &&
                utility.arraysEqual(Listings, OriginalListings) === true
            ) {
                return;
            } else {
                return (
                    <span
                        style={{
                            //display: 'block',
                            position: 'relative',
                            bottom: '20px',
                            float: 'right',
                            //width: '200px'
                        }}
                    >
                        <button
                            style={{ margin: '0px 5px 0px 0px' }}
                            className='a btn'
                            disabled={parseInt(TrunkDetails.MaxNumRerouteAttempts) === OriginalDetails.MaxNumRerouteAttempts &&
                                parseInt(TrunkDetails.MaxNumRerouteWithPriority) === OriginalDetails.MaxNumRerouteWithPriority &&
                                parseInt(TrunkDetails.RerouteExhaustAction) === OriginalDetails.RerouteExhaustAction &&
                                TrunkDetails.RouteExhaustionForwardAddress === OriginalDetails.RouteExhaustionForwardAddress &&
                                (TrunkDetails.EnterpriseTrunkName === OriginalDetails.EnterpriseTrunkName || TrunkDetails.EnterpriseTrunkName === "") &&
                                utility.arraysEqual(Listings, OriginalListings) === true ? true : false}
                            onClick={() => this.setState({ showConfirmationModal: true })} //OPEN CONFIRMATION BOX
                        >Save</button>
                        <button
                            style={{ margin: '0px' }}
                            className='a btn'
                            onClick={this.undoEnterpriseTrunk}
                        >Cancel</button>
                    </span>
                )
            }
        }
    }

    rowItems = () => {
        return (
            <div
                style={{
                    display: 'block',
                    position: 'relative',
                    float: 'right',
                }}
            >
                {/* {this.enterpriseTrunkUpdateBtns()} */}
                <ManageTrunkGroupUsers
                    EnterpriseTrunkName={this.state.CurrentTrunk}
                    EnterpriseTrunkOptions={this.state.TrunkOptions}
                />

            </div>
        )
    }

    render() {
        return (
            <div className="ent-trunking-container">
                <div className="container full-card">
                    <div className="ent-trunk card">
                        <div className="card-header">
                            {this.enterpriseTrunkUpdateBtns()}
                            <h1>Enterprise Trunking {this.submissionMsg()}</h1>
                        </div>
                        <div className="ent-row">
                            <div className='ent-item'>
                                {this.rowItems()}
                            </div>
                        </div>
                        <Modal show={this.state.showConfirmationModal} onHide={this.handleClose} backdrop='static'>
                            <Modal.Header>
                                <Modal.Title>Are you sure you want to save changes?</Modal.Title>
                            </Modal.Header>
                            <Modal.Footer>
                                <hr />
                                <div className="btns">
                                    <span>
                                        <button type="button" className="btn" onClick={this.saveEnterpriseTrunk}>Yes</button>
                                        <button className="btn" onClick={() => this.setState({ showConfirmationModal: false })}>No</button>
                                    </span>
                                </div>
                            </Modal.Footer>
                        </Modal>
                        {this.cardContent()}
                    </div>
                </div >
            </div>
        )
    }
};