import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { Button } from '@progress/kendo-buttons-react-wrapper';
import { DataLoadingSpinner } from '../../../components/spinners';
import { Grid, GridColumn as Column, GridCell } from '@progress/kendo-react-grid';
import { orderBy } from '@progress/kendo-data-query';
import { filterBy } from '@progress/kendo-data-query';
import PricebookModal from './../modals/pricebook';
import * as helper from '../../../scripts/helper';
import * as apiCalls from '../../../requests/api_calls';
import * as macdHelper from '../components/macd_helper';
import _ from 'lodash';
import MACDSaveStatus from '../modals/orderResultModal';
import ValidationCell from '../components/validationCell';
import MACDSearch from '../components/macdSearch';
import AddressFormE911  from '../../../components/e911AddressForm';

export default class SeatTab extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isCancelDialogOpen: false,
      gridHeight: (window.innerHeight - 200),
      MomentumAccountNumber: props.MomentumAccountNumber,
      LocationName: props.LocationName,
      loading: false,
      saving: false,
      error: false,
      //ShowStatuses: false,
      SeatTypeServices: [],
      Listings: [],
      OriginalListings: [],
      Sort: [{ field: "ServiceNumber", dir: "asc" }],
      ValidationErrorMessages: [],
      restrictedUSOCS: require('../components/DisabledUSOCs.json'),
      productSetTypeName: '',
      showE911Modal: false,
      e911AddressItem: {},
      e911Index: -1,
      showE911Column: false
    };
    this.searchListings = this.searchListings.bind(this);
    const updateNewSeatType = this.updateNewSeatType.bind(this);
    const showE911Modal = this.showE911Modal.bind(this);
    this.closeE911Modal = this.closeE911Modal.bind(this);
    this.saveE911Address = this.saveE911Address.bind(this);
    this.handleE911AddressUpdate = this.handleE911AddressUpdate.bind(this);

    class SeatTypeSelectCell extends GridCell {

      render() {
        let value = this.props.dataItem['NewSeatType'];
        const productItem = this.props.dataItem;
        const NewSeatTypeOptionsList = this.props.dataItem['NewSeatList'];
        const AddOnCount = parseInt(this.props.dataItem["AddonCount"]);
        return (
          <td title={AddOnCount > 0 ? "This record cannot be updated until all Add-Ons are removed from this service!" : ""}>
            <select style={{ width: "175px", cursor: AddOnCount > 0 ? "not-allowed" : "pointer" }} disabled={AddOnCount > 0 ? "disabled" : ""}
              value={value} onChange={(e) => { updateNewSeatType(productItem, this.props.field, e) }}>
              {NewSeatTypeOptionsList}
            </select>
          </td>
        )

      }
    };

    class E911AddressCell extends GridCell {
      render() {
        let addressDisplay = '';
        const productItem = this.props.dataItem;        
        if (productItem.Has911 === true)
        {
          let address1 = helper.CreateFullStreet1(productItem.address);
          let address2 = helper.CreateFullStreet2(productItem.address);
          let address3 = productItem.address ? `${productItem.address.City !== undefined ? productItem.address.City : ''},${productItem.address.State !== undefined ? productItem.address.State : ''} ${productItem.address.ZipCode !== undefined ? productItem.address.ZipCode : ''}` : '';
          if (address1 !== '' && address2 !== '' && address3 != ', ')
            addressDisplay = (<span>{address1}<br />{address2}<br />{address3}</span>)
          else if (address1 !== '' && address3 !== ', ')
            addressDisplay = (<span>{address1}<br />{address3}</span>)
          else if (address1 !== '' && address2 !== '')
            addressDisplay = (<span>{address1}<br />{address2}</span>)
          else if (address2 !== '' && address3 !== ', ')
            addressDisplay = (<span>{address2}<br />{address3}</span>)
          else if (address1 !== '')
            addressDisplay = (<span>{address1}</span>);
          else if (address2 !== '')
            addressDisplay = (<span>{address2}</span>);
          else if (address3 !== ', ')
            addressDisplay = (<span>{address3}</span>);
          else
            addressDisplay = (<span>Click to add an E911 Address</span>)
        }
        return (
          <td>
            <a title="Click to add an E911 address" onClick={() => showE911Modal(productItem)}>
              <span>{addressDisplay}</span>
            </a>
          </td>
        )
      }
    }

    this.SeatTypeSelectCell = SeatTypeSelectCell;
    this.ValidationCell = ValidationCell;
    this.E911AddressCell = E911AddressCell;
  }


  componentDidMount() {
    if (this.state.MomentumAccountNumber !== '')
      this.fetchListings();
  }

  async componentWillReceiveProps(props) {
    if (props) {
      if (props.MomentumAccountNumber !== this.state.MomentumAccountNumber) {
        await this.setState({ MomentumAccountNumber: props.MomentumAccountNumber, LocationName: props.LocationName, ValidationErrorMessages: [] });
        this.fetchListings();
      }
      if (props.GridHeight && props.GridHeight !== this.state.gridHeight) 
        this.setState({gridHeight: props.GridHeight});
    }
  }

  setProductSetTypeName = (productSetType) => {
    this.setState({productSetTypeName: productSetType});
  }

  handleCancel = () => {
    //display confirmation message
    let changedItems = this.state.SeatTypeServices.filter(res => res.HasChanges === true);
    if (!changedItems || changedItems.length === 0) return;
    this.setState({ isCancelDialogOpen: true });
  }

  handleSave = async () => {
    let changedItems = this.state.SeatTypeServices.filter(res => res.HasChanges === true);
    if (!changedItems || changedItems.length === 0) return;
    await this.setState({ saving: true });
    let apiPath = macdHelper.apiSubmitChangeSeatType();
    let originalListings = this.state.OriginalListings;

    let submittedItems = changedItems.map((itm, idx) => {
      let oldService = originalListings.filter(res => res.CustomerProductId === itm.CustomerProductId);
      oldService = oldService[0];
      let selectedSeat = oldService.NewSeatList.filter(res => res.PartNum === itm.NewSeatType);
      return {
        OldService: oldService,
        NewCatalogItemId: selectedSeat[0].CatalogItemId,
        Address: itm.Has911 === true ? itm.address : null,
        E911AddressId: itm.Has911 === true ? itm.Temp911AddressId : null
      }
    });

    let reqBody = {
      LocationAccountNumber: this.state.MomentumAccountNumber,
      ServiceList: submittedItems
    };
    await apiCalls.post(apiPath, 'POST', JSON.stringify(reqBody)).then((res) => {
      let validationErrors = [];
      if (res.ok === true) {
        this.handleSaveSuccess();
      } else {
        if (helper.IsJsonString(res.message))
        {
          let responseObject = JSON.parse(res.message);
          if (responseObject.length && responseObject.length > 0) {
            //another error message response for missing value
            validationErrors = responseObject.map((itm, idx) => {
              if (itm.CustomerProductId && itm.CustomerProductId !== '')
                return {
                  CustomerProductId: parseInt(itm.CustomerProductId),
                  ValidationMessage: helper.StringReplaceAll(itm.ErrorMessage, '</br>', '', false)
                }
              else {
                throw [helper.StringReplaceAll(itm.ErrorMessage, '</br>', '', false)];
              }
            });
          } else {
            if (responseObject.MPOStatus && responseObject.MPOStatus.MPOErrorList && responseObject.MPOStatus.MPOErrorList.length > 0) {
              let ErrorList = responseObject.MPOStatus.MPOErrorList[0];
              let ErrorMessages = ErrorList.MPOResultText;
              validationErrors = ErrorMessages.map((itm, idx) => {
                if (itm.CustomerProductId && itm.CustomerProductId !== '')
                  return {
                    CustomerProductId: parseInt(itm.CustomerProductId),
                    ValidationMessage: helper.StringReplaceAll(itm.ErrorMessage, '</br>', '', false)
                  }
                else {
                  throw [helper.StringReplaceAll(itm.ErrorMessage, '</br>', '', false)];
                }
              });
            }
          }
          if (validationErrors) {
            this.showErrorAndResetModal('Your order could not be completed due to entry errors indicated in the grid.', validationErrors);
          }
        } else {
          this.showErrorAndResetModal(res.message);
        }
      }
    }).catch((msg) => {
      this.showErrorAndResetModal(msg);
    }).finally(() => {
      this.setState({ saving: false });
    });
  }

  handleSaveSuccess = async () => {
    await this.setState({ 
      loading: true, 
      ValidationErrorMessages: [], 
      statusMessage: 'Your order has been saved and submitted successfully.', 
      showE911Column: false, 
      e911AddressItem: {}, 
      e911Index: -1 
    });
    this.props.TabHasChanges(false);
    this.fetchListings();
  }

  showErrorAndResetModal = (msg, ValidationErrorMessages = []) => {
    this.setState({ error: true, statusMessage: msg, ValidationErrorMessages: ValidationErrorMessages });
  }

  closeSaveMessage = () => {
    let serviceListings = this.state.SeatTypeServices.slice();
    let gridListings = this.state.Listings.slice();
    let validationErrors = this.state.ValidationErrorMessages.slice();
    validationErrors.map((itm, idx) => {
      let itmInListings = serviceListings.filter(resp => resp.CustomerProductId === itm.CustomerProductId);
      let indexOfListing = _.indexOf(serviceListings, itmInListings[0]);
      serviceListings[indexOfListing].ValidationMessage = itm.ValidationMessage;
      itmInListings = gridListings.filter(resp => resp.CustomerProductId === itm.CustomerProductId);
      indexOfListing = _.indexOf(gridListings, itmInListings[0]);
      gridListings[indexOfListing].ValidationMessage = itm.ValidationMessage;
    });
    this.setState({ error: false, statusMessage: '', Listings: gridListings, SeatTypeServices: serviceListings });
  }

  CancelYesAction = async () => {
    //clear all editable fields
    await this.fetchListings();
    this.props.TabHasChanges(false);
    this.setState({ isCancelDialogOpen: false, ValidationErrorMessages: [], showE911Column: false, e911AddressItem: {}, e911Index: -1 });

  }

  closeCancelDialog = () => {
    this.setState({ isCancelDialogOpen: false });
  }

  updateNewSeatType = async (productItem, field, e) => {
    let newValue = e.target.value;
    let showE911Column = this.state.showE911Column;
    let updatedListings = this.state.SeatTypeServices.slice();
    let itmInArray = this.state.SeatTypeServices.filter(resp => resp.CustomerProductId === productItem.CustomerProductId);
    if (itmInArray)
      itmInArray = itmInArray[0];
    else
      throw 'Could not find item';

    let idx = _.indexOf(this.state.SeatTypeServices, itmInArray);
    itmInArray[field] = newValue;
    if (newValue === productItem.PartNum) {
      itmInArray.HasChanges = false;
      itmInArray.Has911 = false;
    } else {
      this.props.TabHasChanges(true);
      itmInArray.HasChanges = true;

      //retrieve original listings and find a catalog item that matches the new seat type to determine if E911 address column should show
      let originalData = this.state.OriginalListings.filter(resp => resp.CustomerProductId === productItem.CustomerProductId);
      if (originalData && originalData.length > 0)
        originalData = originalData[0];
      
      let originalDataSI = originalData.NewSeatList.filter(resp => resp.PartNum === newValue);
      if (originalDataSI && originalDataSI.length > 0)
      originalDataSI = originalDataSI[0];
      if (originalDataSI.Has911 && !!+(originalDataSI.Has911) === true) {
        itmInArray.Has911 = true;
        showE911Column = true;
        if (itmInArray.address === null)
        {
          itmInArray.address = macdHelper.GetBlankE911AddressObject();
        }        
      } else itmInArray.Has911 = false;

    }

    updatedListings[idx] = itmInArray;    
    //update grid datasource now that the 'full' datasource is up-to-date.  
    let ListingsTemp = this.state.Listings.slice();
    let itmInListings = ListingsTemp.filter(resp => resp.CustomerProductId === productItem.CustomerProductId);
    idx = _.indexOf(ListingsTemp, itmInListings);
    ListingsTemp[idx] = itmInArray;
    //setting if there are changes so that we can throw a warning message from the macd component on tab/location change
    if (updatedListings && updatedListings.filter(res => res.HasChanges === true).length === 0)
      this.props.TabHasChanges(false);
    else
      this.props.TabHasChanges(true);

    await this.setState({ SeatTypeServices: updatedListings, Listings: ListingsTemp, showE911Column: showE911Column });
  }

  fetchListings = async () => {
    await this.setState({ loading: true });
    let locationName = this.state.LocationName;
    let apiPath = macdHelper.apiGetChangeSeatType(this.state.MomentumAccountNumber);
    let [responseData, unformattedResponse] = await apiCalls.fetchData(apiPath).then((data) => {
      if (data) {
        let formattedData = data.ServiceList.map((itm, idx) => {
          let newItm = {
            CustomerProductId: itm.CustomerProductId,
            CatalogItemId: itm.CatalogItemId,
            PartDescription: itm.PartDescription,
            PartNum: itm.PartNum,
            ServiceNumber: itm.ServiceNumber,
            Extension: itm.Extension,
            Location: locationName,
            LineName: itm.LineName,
            LineFirstName: itm.LineFirstName,
            LineLastName: itm.LineLastName,
            ListingName: itm.ListingName,
            AddonCount: itm.AddonCount,
            ListingType: itm.ListingType,
            OmitAddress: itm.OmitAddress,
            CNAM: itm.CNAM,
            TerminatingNumber: itm.TerminatingNumber,
            LineEmail: itm.LineEmail,
            NewSeatType: itm.PartNum,
            NewSeatList: this.newSeatListOptions(itm.PartNum, itm.NewSeatList),
            HasChanges: false,
            ValidationMessage: '',
            address: macdHelper.GetBlankE911AddressObject()
          }
          return newItm;
        });
        return [formattedData, data.ServiceList];
      } else {
        return [[], []];
      }
    });
    await this.setState({ Listings: responseData, SeatTypeServices: responseData, OriginalListings: unformattedResponse, loading: false });
  }

  newSeatListOptions = (CurrentPartNum, NewList) => {
    let returnObj = [];
    if (NewList) {
      let sort = [{ field: "PartDescription", dir: "asc" }]
      let sortedSet = orderBy(NewList, sort);
      sortedSet = helper.blanksAfterZ(sortedSet, sort);
      let restrictedUsocs = this.state.restrictedUSOCS;
      let productSetType = this.state.productSetTypeName;
      returnObj = sortedSet.map((itm, idx) => {
        let restrictedItem = _.filter(restrictedUsocs, resp => resp.USOC === itm.PartNum && resp.ProductSetTypeName === productSetType);
        if (!restrictedItem || restrictedItem.length === 0 || restrictedItem[0].USOC === CurrentPartNum)
          return (
            <option key={itm.CatalogItemId} value={itm.PartNum}>{itm.PartDescription}</option>
          )
      });
    }
    return returnObj;
  }

  showE911Modal = (selectedItem) => {
    let selectedIndex = _.indexOf(this.state.Listings, selectedItem);
    //selectedItem.address.IsActive = true; //setting so that the save button doesn't de-activate
    this.setState({showE911Modal: true, e911Index: selectedIndex, e911AddressItem: selectedItem.address});
  }

  handleE911AddressUpdate = (isSuccess, addressId, addressEntry, errorMsg) => {
    if (isSuccess) {
      this.saveE911Address(addressEntry, addressId);
      this.closeE911Modal();
    } else {
      addressEntry.ValidationMessage = errorMsg;
      this.saveE911Address(addressEntry, addressId, errorMsg);
      this.setState({e911AddressItem: addressEntry});
    }
  }

  saveE911Address = (updatedAddress, addressId = 0, errorMsg = '') => {
    let addressItem = this.state.e911AddressItem;
    let existingItem = this.state.Listings[this.state.e911Index];//.filter(resp => resp.CustomerProductId === addressItem.CustomerProductId);
    
    let newValidationErrorMessages = this.state.ValidationErrorMessages.slice();
    if (existingItem)
    {
      //existingItem = existingItem[0];
      let idx = _.indexOf(this.state.Listings, existingItem);
      let newListings = this.state.Listings.slice();
      if (addressItem.ValidationMessage !== '')
      {
        let idxMessage = _.indexOf(newValidationErrorMessages, addressItem.ValidationMessage);
        newValidationErrorMessages.splice(idxMessage,1);
      }
      
      updatedAddress.AddressId = addressId;
      //updatedAddress.ValidationMessage = errorMsg;
      if (errorMsg !== '')
        newValidationErrorMessages.push(errorMsg);
      //update displayed grid
      newListings[idx].address = updatedAddress;
      newListings[idx].Temp911AddressId = updatedAddress.AddressId;
      newListings[idx].ValidationMessage = errorMsg;

      //update the dataSource storing all changes, including those that may be hidden due to search criteria
      let updatedListings = this.state.SeatTypeServices.slice();
      let itmInArray = this.state.SeatTypeServices.filter(resp => resp.CustomerProductId === existingItem.CustomerProductId);
      if (itmInArray)
        itmInArray = itmInArray[0];
      else
        throw 'Could not find item';
  
      idx = _.indexOf(this.state.SeatTypeServices, itmInArray);
      itmInArray.address = updatedAddress;
      itmInArray.Temp911AddressId = updatedAddress.AddressId;
      itmInArray.ValidationMessage = errorMsg;
      updatedListings[idx] = itmInArray;
      
      this.setState({SeatTypeServices: updatedListings ,Listings: newListings, ValidationErrorMessages: newValidationErrorMessages});
    }
    
  }

  closeE911Modal = () => {
    this.setState({showE911Modal: false, e911AddressItem: {}});
  }


  sortListings(sort, Listings = null) {
    let returnObj = [];
    if (this.state.Listings) {
      if (Listings === null) Listings = this.state.Listings.slice();
      let sortedSet = orderBy(Listings, sort);
      returnObj = sortedSet;
      if (sort.length) {
        returnObj = helper.blanksAfterZ(sortedSet, sort);
      }
    }
    return returnObj;
  }

  sortChange = (event) => {
    this.setState({
      Listings: this.sortListings(event.sort),
      Sort: event.sort
    });
  }

  searchListings = (event) => {
    let searchText = event.target.value.trim();
    let fullDetails = this.state.SeatTypeServices;
    if (searchText !== '') {
      searchText = event.target.value;
      let services = filterBy(fullDetails, {
        logic: 'or',
        filters: [
          { field: 'ServiceNumber', operator: 'contains', value: searchText, ignoreCase: true },
          { field: 'Extension', operator: 'contains', value: searchText, ignoreCase: true },
          { field: 'LineName', operator: 'contains', value: searchText, ignoreCase: true },
          { field: 'PartDescription', operator: 'contains', value: searchText, ignoreCase: true }
        ]
      });

      this.setState({ Listings: services })
    }
    else {
      this.setState({ Listings: fullDetails });
    }

  }

  gridContent = () => {
    if (this.state.MomentumAccountNumber === '') {
      return macdHelper.locationSelectLabel(this.state.MomentumAccountNumber);
    };
    if (this.state.loading) return <DataLoadingSpinner className="load spinner relative" />
    else if(!this.state.loading && this.state.Listings.length === 0) return <p>There are no applicable changes available for the seats on this location. Please review your search criteria. Note that some changes may be available only under “Line Type”.</p>
    else if (!this.state.loading && this.state.Listings && this.state.Listings.length > 0) {
      let e911Width = '0px';
      let serviceNumberWidth = '320px'
      if (this.state.showE911Column) {
        e911Width = '200px';
        serviceNumberWidth = '150px'
      }
      
      return (
        <Grid
          style={{ height: this.state.gridHeight + 'px' }}
          data={this.state.Listings}
          sortable={{ allowUnsort: true, mode: 'single' }}
          sort={this.state.Sort}
          onSortChange={this.sortChange}
        >
          {this.state.ValidationErrorMessages && this.state.ValidationErrorMessages.length > 0 ? <Column field='ValidationMessage' title=" " width={'50px'} cell={this.ValidationCell} sortable={false} /> : null}
          <Column width="300px" field='PartDescription' title='Product' />
          <Column width={serviceNumberWidth} field='ServiceNumber' title='Service ID' />
          <Column width="120px" field='Extension' title='Extension' />
          <Column width="300px" field='LineName' title='Line Name' />
          <Column width="250px" field='Location' title='Location' />
          <Column width="200px" field='NewSeatType' cell={this.SeatTypeSelectCell} title='Seat Type' sortable={false} />
          <Column width={e911Width} field='E911 Address' cell={this.E911AddressCell} title='E911 Address' sortable={false} />
          <Column field="spacer" title=" " sortable={false} filterable={false} />
        </Grid>
      )
    }
  }

  render() {
    let tn = '';
    if (this.state.e911Index > -1)
      tn = this.state.Listings[this.state.e911Index].ServiceNumber;
    return (
      <div>
        <div style={{ "textAlign": "right", "marginBottom": "5px", "marginTop": "5px", "display": this.state.loading || this.state.MomentumAccountNumber === '' ? "none" : "block" }}>
          <div className='search'>
            <MACDSearch SearchFunction={this.searchListings} />
            <PricebookModal MomentumAccountNumber={this.state.MomentumAccountNumber} SetProductSetTypeName={this.setProductSetTypeName} />
            <Button click={this.handleCancel}>Cancel</Button>
            <Button click={this.handleSave}>Save</Button>
          </div>
        </div>
        <hr />
        <Modal dialogClassName='macd-e911-address' show={this.state.showE911Modal} onHide={this.closeE911Modal}>
          <Modal.Header>
            <Modal.Title>Update E911 Address</Modal.Title>
          </Modal.Header>
          <Modal.Body>
              <table>
                <AddressFormE911 parentList={this.state.Listings} index={this.state.e911Index} address={this.state.e911AddressItem} validateInComponent={true} 
                  UpdateEntry={this.handleE911AddressUpdate} ToggleEditList={this.closeE911Modal} PhoneNumber={tn} validateTN={true} apiPath={'usr/ValidateAndSaveTempE911'}
                />
              </table>
          </Modal.Body>
        </Modal>
        <Modal dialogClassName="confirm-cancel-macd" show={this.state.isCancelDialogOpen} onHide={this.closeCancelDialog}>
          <Modal.Header closeButton>
            <Modal.Title>Cancel Order?</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div><p><span style={{ fontSize: "medium" }}>Are you sure you wish to cancel this order?</span></p></div>
          </Modal.Body>
          <Modal.Footer>
            <Button click={(evt) => { this.CancelYesAction() }}>Yes</Button>
            <Button click={(evt) => { this.closeCancelDialog() }}>No</Button>
          </Modal.Footer>
        </Modal>
        <MACDSaveStatus
          saving={this.state.saving}
          statusMessage={this.state.statusMessage}
          error={this.state.error}
          parentCallBack={this.closeSaveMessage}
        />

        {this.gridContent()}
      </div>
    );
  }
};