import React, { Component } from 'react';
import MacdOrderListingsWithRouter from './../components/order_listings'


class OpenReqTab extends Component {  

  constructor(props) {
    super(props);  
    this.state = {MomentumAccountNumber: props.MomentumAccountNumber, gridHeight: props.GridHeight};
  }
  componentDidMount() {
    
  }
  componentWillReceiveProps(props) {
    this.setState({ MomentumAccountNumber: props.MomentumAccountNumber, gridHeight: props.GridHeight })
  }

  // getMACDListings = (filter) => filterBy(this.setGridData(), filter);

  render() {
    return (
      <div>      
        <MacdOrderListingsWithRouter ListingPage="Open Requests" MomentumAccountNumber={this.state.MomentumAccountNumber}  GridHeight={this.state.gridHeight} />
      </div>
    )
  }
}
export default OpenReqTab;
