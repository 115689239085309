import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { Button } from '@progress/kendo-buttons-react-wrapper';
import { DataLoadingSpinner } from '../../../components/spinners';
import { Grid, GridColumn as Column, GridCell } from '@progress/kendo-react-grid';
import { orderBy } from '@progress/kendo-data-query';
import { filterBy } from '@progress/kendo-data-query';
import * as helper from '../../../scripts/helper';
import * as apiCalls from '../../../requests/api_calls';
import * as macdHelper from '../components/macd_helper';
import _ from 'lodash';
import MACDSaveStatus from '../modals/orderResultModal';
import ValidationCell from '../components/validationCell';
import MACDSearch from '../components/macdSearch';

export default class CnamTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isCancelDialogOpen: false,
      gridHeight: (window.innerHeight - 200),
      MomentumAccountNumber: props.MomentumAccountNumber,
      LocationName: props.LocationName,
      loading: false,
      saving: false,
      error: false,
      //ShowStatuses: false,
      CnamServices: [],
      Listings: [],
      OriginalListings: [],
      Sort: [{ field: "ServiceNumber", dir: "asc" }],
      ValidationErrorMessages: []
    };
    this.searchListings = this.searchListings.bind(this);
    const updateInputValue = this.updateInputValue.bind(this);

    class CnamInputCell extends GridCell {
      constructor(props) {
        super(props);
      }

      render() {
        const productItem = this.props.dataItem;
        let ReadOnlyStatus = false;
        const value = this.props.dataItem[this.props.field];

        return (
          <td>
            <input type="text" maxLength={15} disabled={ReadOnlyStatus === true ? "disabled" : ""} autoComplete={"off"} onChange={(e) => {
              updateInputValue(productItem, this.props.field, e);
            }}
              style={{ width: "90%", cursor: ReadOnlyStatus === true ? 'not-allowed' : 'text' }}
              value={value}></input>
          </td>
        )
      }
    };
    
    this.ValidationCell = ValidationCell;
    this.CnamInputCell = CnamInputCell;
  }


  componentDidMount() {
    if (this.state.MomentumAccountNumber !== '')
      this.fetchListings();
  }

  async componentWillReceiveProps(props) {
    if (props) {
      if (props.MomentumAccountNumber != this.state.MomentumAccountNumber) {
        await this.setState({ MomentumAccountNumber: props.MomentumAccountNumber, LocationName: props.LocationName, ValidationErrorMessages: [] });
        this.fetchListings();
      }
      if (props.GridHeight && props.GridHeight !== this.state.gridHeight)
        this.setState({ gridHeight: props.GridHeight });
    }
  }

  handleSave = async () => {
    let changedItems = this.state.CnamServices.filter(res => res.HasChanges === true);
    if (!changedItems || changedItems.length === 0) return;

    await this.setState({ saving: true });
    let apiPath = macdHelper.apiSubmitChangeCnam();
    let originalListings = this.state.OriginalListings;

    let submittedItems = changedItems.map((itm, idx) => {
      let oldService = originalListings.filter(res => res.CustomerProductId === itm.CustomerProductId);
      oldService = oldService[0];
      return {
        OldService: oldService,
        CNAM: itm.CNAM
      }
    });

    let reqBody = {
      LocationAccountNumber: this.state.MomentumAccountNumber,
      ServiceList: submittedItems
    }
    await apiCalls.post(apiPath, 'POST', JSON.stringify(reqBody)).then((res) => {
      let validationErrors = [];
      if (res.ok === true) {
        this.handleSaveSuccess();
      } else {
        let responseObject = JSON.parse(res.message);
        if (responseObject.length && responseObject.length > 0) {
          //another error message response for missing value
          validationErrors = responseObject.map((itm, idx) => {
            if (itm.CustomerProductId && itm.CustomerProductId != '')
              return {
                CustomerProductId: parseInt(itm.CustomerProductId),
                ValidationMessage: helper.StringReplaceAll(itm.ErrorMessage, '</br>', '', false)
              }
            else {
              throw [helper.StringReplaceAll(itm.ErrorMessage, '</br>', '', false)];
            }
          });
        } else {
          if (responseObject.MPOStatus && responseObject.MPOStatus.MPOErrorList && responseObject.MPOStatus.MPOErrorList.length > 0) {
            let ErrorList = responseObject.MPOStatus.MPOErrorList[0];
            let ErrorMessages = ErrorList.MPOResultText;
            validationErrors = ErrorMessages.map((itm, idx) => {
              if (itm.CustomerProductId && itm.CustomerProductId != '')
                return {
                  CustomerProductId: parseInt(itm.CustomerProductId),
                  ValidationMessage: helper.StringReplaceAll(itm.ErrorMessage, '</br>', '', false)
                }
              else {
                throw [helper.StringReplaceAll(itm.ErrorMessage, '</br>', '', false)];
              }
            });
          }
        }
        if (validationErrors) {
          this.showErrorAndResetModal('Your order could not be completed due to entry errors indicated in the grid.', validationErrors);
        }
      }
    }).catch((msg, ValidationErrors = null) => {
      this.showErrorAndResetModal(msg);
    }).finally(() => {
      this.setState({ saving: false });
    });
  }

  handleCancel = () => {
    //display confirmation message
    let changedItems = this.state.CnamServices.filter(res => res.HasChanges === true);
    if (!changedItems || changedItems.length === 0) return;
    this.setState({ isCancelDialogOpen: true });
  }

  handleSaveSuccess = async () => {
    await this.setState({ loading: true, ValidationErrorMessages: [], statusMessage: 'Your order has been saved and submitted successfully.' });
    this.props.TabHasChanges(false);
    this.fetchListings();
  }

  showErrorAndResetModal = (msg, ValidationErrorMessages = []) => {
    this.setState({ error: true, statusMessage: msg, ValidationErrorMessages: ValidationErrorMessages });
  }

  closeSaveMessage = () => {
    let serviceListings = this.state.CnamServices.slice();
    let gridListings = this.state.Listings.slice();
    let validationErrors = this.state.ValidationErrorMessages.slice();
    validationErrors.map((itm, idx) => {
      let itmInListings = serviceListings.filter(resp => resp.CustomerProductId === itm.CustomerProductId);
      let indexOfListing = _.indexOf(serviceListings, itmInListings[0]);
      serviceListings[indexOfListing].ValidationMessage = itm.ValidationMessage;
      itmInListings = gridListings.filter(resp => resp.CustomerProductId === itm.CustomerProductId);
      indexOfListing = _.indexOf(gridListings, itmInListings[0]);
      gridListings[indexOfListing].ValidationMessage = itm.ValidationMessage;
    });
    this.setState({ error: false, statusMessage: '', Listings: gridListings, CnamServices: serviceListings });
  }

  CancelYesAction = async () => {
    //clear all editable fields
    await this.fetchListings();
    this.props.TabHasChanges(false);
    this.setState({ isCancelDialogOpen: false, ValidationErrorMessages: [] });

  }

  closeCancelDialog = () => {
    this.setState({ isCancelDialogOpen: false });
  }

  updateInputValue = (productItem, field, e, isNumeric) => {
    let newValue = isNumeric && isNumeric == true ? parseInt(e.target.value) : e.target.value;
    productItem[field] = newValue;
    let existingArray = this.state.CnamServices;
    let index = _.findIndex(existingArray, s => s.CustomerProductId === productItem.CustomerProductId);
    let newOptions = this.state.CnamServices.slice();
    newOptions[index] = productItem;
    //update grid datasource now that the 'full' datasource is up-to-date.  
    let ListingsTemp = this.state.Listings.slice();
    let itmInListings = ListingsTemp.filter(resp => resp.CustomerProductId === productItem.CustomerProductId);
    index = _.indexOf(ListingsTemp, itmInListings);
    ListingsTemp[index] = productItem;

    let originalItem = this.state.OriginalListings.filter(res => res.CustomerProductId === productItem.CustomerProductId);
    if (originalItem && originalItem.length > 0) {
      originalItem = originalItem[0];
      if (originalItem.CNAM !== productItem.CNAM) {
        productItem.HasChanges = true;
        this.props.TabHasChanges(true);
      } else {
        productItem.HasChanges = false;
        this.props.TabHasChanges(false);
      }
    }

    this.setState({ CnamServices: newOptions, Listings: ListingsTemp });
  }

  fetchListings = async () => {
    await this.setState({ loading: true });
    let locationName = this.state.LocationName;
    let apiPath = macdHelper.apiGetChangeCnam(this.state.MomentumAccountNumber);
    let [responseData, unformattedResponse] = await apiCalls.fetchData(apiPath).then((data) => {
      if (data) {
        let formattedData = data.ServiceList.map((itm, idx) => {
          let newItm = {
            CustomerProductId: itm.CustomerProductId,
            CatalogItemId: itm.CatalogItemId,
            PartDescription: itm.PartDescription,
            PartNum: itm.PartNum,
            ServiceNumber: itm.ServiceNumber,
            Extension: itm.Extension,
            Location: locationName,
            LineName: itm.LineName,
            LineFirstName: itm.LineFirstName,
            LineLastName: itm.LineLastName,
            ListingName: itm.ListingName,
            ListingType: itm.ListingType,
            OmitAddress: itm.OmitAddress,
            CNAM: itm.CNAM,
            TerminatingNumber: itm.TerminatingNumber,
            OldLineEmail: itm.LineEmail,
            LineEmail: itm.LineEmail,
            NewLineType: itm.PartNum,
            NewCatalogItemId: itm.CatalogItemId,
            NewSeatType: itm.PartNum,
            NewTypeDescription: itm.PartDescription,
            HasChanges: false,
            ValidationMessage: '',
            EnableCNAM: false,
          }
          return newItm;
        });
        return [formattedData, data.ServiceList];
      } else {
        return [[], []];
      }
    });
    await this.setState({ Listings: responseData, CnamServices: responseData, OriginalListings: unformattedResponse, loading: false });
  }

  sortListings(sort, Listings = null) {
    let returnObj = [];
    if (this.state.Listings) {
      if (Listings == null) Listings = this.state.Listings.slice();
      let sortedSet = orderBy(Listings, sort);
      returnObj = sortedSet;
      if (sort.length) {
        returnObj = helper.blanksAfterZ(sortedSet, sort);
      }
    }
    return returnObj;
  }

  sortChange = (event) => {
    this.setState({
      Listings: this.sortListings(event.sort),
      Sort: event.sort
    });
  }

  searchListings = (event) => {
    let searchText = event.target.value.trim();
    //console.log("Search Text: " + searchText);
    let fullDetails = this.state.CnamServices;
    if (searchText !== '') {
      searchText = event.target.value;
      let services = filterBy(fullDetails, {
        logic: 'or',
        filters: [
          { field: 'ServiceNumber', operator: 'contains', value: searchText, ignoreCase: true },
          { field: 'Extension', operator: 'contains', value: searchText, ignoreCase: true },
          { field: 'LineName', operator: 'contains', value: searchText, ignoreCase: true },
          { field: 'PartDescription', operator: 'contains', value: searchText, ignoreCase: true }
        ]
      });

      this.setState({ Listings: services })
    }
    else {
      this.setState({ Listings: fullDetails });
    }

  }

  gridContent = () => {
    if (this.state.MomentumAccountNumber == '') {
      return macdHelper.locationSelectLabel(this.state.MomentumAccountNumber);
    }
    if (this.state.loading) return <DataLoadingSpinner className="load spinner relative" />
    else if(!this.state.loading && this.state.Listings.length === 0) return <p>There are no applicable changes available for services at this location. Please review your search criteria.</p>
    else if (!this.state.loading && this.state.Listings && this.state.Listings.length > 0) {
      return (
        <Grid
          style={{ height: this.state.gridHeight + 'px' }}
          data={this.state.Listings}
          sortable={{ allowUnsort: true, mode: 'single' }}
          sort={this.state.Sort}
          onSortChange={this.sortChange}
        >
          {this.state.ValidationErrorMessages && this.state.ValidationErrorMessages.length > 0 ? <Column field='ValidationMessage' title=" " width={'50px'} cell={this.ValidationCell} sortable={false} /> : null}
          <Column width="350px" field='PartDescription' title='Product' />
          <Column width="300px" field='ServiceNumber' title='Service ID' />
          <Column width="150px" field='Extension' title='Extension' />
          <Column width="300px" field='LineName' title='Line Name' />
          <Column width="200px" field='Location' title='Location' />
          <Column width="200px" field='CNAM' cell={this.CnamInputCell} title='Caller ID (CNAM)' sortable={false} />
          <Column field="spacer" title=" " sortable={false} filterable={false} />
        </Grid>
      )
    }
  }

  render() {

    return (
      <div>
        <div style={{ "textAlign": "right", "marginBottom": "5px", "marginTop": "5px", "display": this.state.loading || this.state.MomentumAccountNumber === '' ? "none" : "block" }}>
          <div className='search'>
            <MACDSearch SearchFunction={this.searchListings} />
            <Button click={this.handleCancel}>Cancel</Button>
            <Button click={this.handleSave}>Save</Button>
          </div>
        </div>
        <hr />

        <Modal dialogClassName="confirm-cancel-macd" show={this.state.isCancelDialogOpen} onHide={this.closeCancelDialog}>
          <Modal.Header closeButton>
            <Modal.Title>Cancel Order?</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div><p><span style={{ fontSize: "medium" }}>Are you sure you wish to cancel this order?</span></p></div>
          </Modal.Body>
          <Modal.Footer>
            <Button click={(evt) => { this.CancelYesAction() }}>Yes</Button>
            <Button click={(evt) => { this.closeCancelDialog() }}>No</Button>
          </Modal.Footer>
        </Modal>
        <MACDSaveStatus
          saving={this.state.saving}
          statusMessage={this.state.statusMessage}
          error={this.state.error}
          parentCallBack={this.closeSaveMessage}
        />

        {this.gridContent()}
      </div>
    );
  }
};
