
import React, { Component } from 'react';
import { Grid, GridColumn as Column, GridCellProps, GridCell, GridToolbar, GridDetailRow } from '@progress/kendo-react-grid';
import InfoPop from './infopop';
class InfoCell extends GridCell {
    constructor(props) {
        super(props);
    }
    render() {
        if (this.props.dataItem[this.props.field] !== '')
            return (
                <td>
                <span style={{marginLeft: '20px'}}>
                    <InfoPop message={this.props.dataItem[this.props.field]} />
                </span>
                </td>
            );
        else
            return (<td></td>);
    }
}

export default InfoCell;