import React from 'react';

export const filtercell = (data, defaultItem) => {
    return class extends React.Component {
        hasValue(value) {
            return value && value !== defaultItem;
        };
        render() {
            return (
                <select style={{
                    height: '20px',
                    maxWidth: '90px',
                    border: '1px solid rgb(230, 230, 230)'
                }}
                    onChange={(event) => {
                        const hasValue = this.hasValue(event.target.value)
                        this.props.onChange({
                            value: hasValue ? event.target.value : '',
                            operator: hasValue ? 'eq' : '',
                        });
                    }}
                    value={this.props.value}
                >
                    <option value=''>All</option>
                    {data.map((item, index) => {
                        return <option key={index} value={item}>{item}</option>
                    })}
                </select>
            );
        }
    };
}