import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { store } from '../../store/store';
import { userIsAdmin } from './../../scripts/helper';
import * as settings from './../../scripts/settings';
import { training_url} from "../../constants/urls"
import {support_url} from "../../constants/urls"
import * as session from "../../scripts/session";
import { checkBillingAccount } from '../../utility/utility';

class HelpCenter extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }


  userSelected = () => {
    // user was selected in another component and updated in redux store
    let newUserId = this.props.UserId;
    let currentUserId = this.state.UserId;
    if (newUserId !== currentUserId) {
      // only make a new API call if the selected user has changed
      let apiPath = 'usr/MyServices?UserId=' + newUserId;
      this.setState({UserId: newUserId})
      this.fetchMyServices(apiPath)
    }
  }

  subscriberSettings = () => {
  	if (this.props.ServiceNumber) {
  		return (
	      <Link to={"/iframe_page/MSP_SUBSCRIBER/" + this.props.ServiceNumber} className="help-item">
	        <span className="name">Subscriber Settings</span>
	      </Link>
  		)
  	} else {
	  	return null;
  	}
  		
  }

  render() {
    const isWholesale = session.IsWholesale();
    if (!isWholesale && !checkBillingAccount()) {
      return (
        <div className="help-center">
          {/* <div className="help-item">Applications</div> */}
          {/*this.subscriberSettings()*/}
          {/* <Link to="/iframe_page/MSP_SUPPORT" className="help-item">
            <span className="name">Support</span>
          </Link> */}
           <a href="https://gomomentum.com/support/" target="_blank" className="help-item">
            <span className="name">Support</span>
          </a>
          <a href={training_url} target="_blank" className="help-item">
            <span className="name">Training</span>
          </a>
        </div>
      )      
      } else return null;
  }
}

const mapStateToProps = (state) => {
  return {
    ServiceNumber: state.reducer.ServiceNumber
  }
}

export default connect(mapStateToProps)(HelpCenter);