import { EventItem } from "./event-item";

import { EDIT_EVENT } from "./constants";

export const EventList = ({ events, selectItemToDelete, scheduleItem, onCancelAddOrEditEvent }) => {
  const hasEvents = Boolean(events && events.length > 0);

  return (
    <table className="table">
      <thead>
        <tr>
          <th className="check">
            <input type="checkbox" disabled />
          </th>
          <th>Event Name</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {!hasEvents && (
          <tr>
            <td colSpan="3">
              <div>
                <span>No events added for this schedule yet.</span>
              </div>
            </td>
          </tr>
        )}
        {hasEvents &&
          events.map((event, index) => {
            return (
              <EventItem
                key={index}
                type={EDIT_EVENT}
                eventItem={event}
                selectItemToDelete={selectItemToDelete}
                scheduleName={scheduleItem.ScheduleName}
                scheduleType={scheduleItem.ScheduleType}
                onCancelAddOrEditEvent={onCancelAddOrEditEvent}
              />
            );
          })}
      </tbody>
    </table>
  );
};
