import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { Button } from '@progress/kendo-buttons-react-wrapper';
import * as apiCalls from './../../../requests/api_calls';
import * as help from'./../locations_helpers';
import SaveStatus from './../../../components/save_status';
import { DataLoadingSpinner } from'./../../../components/spinners';

class GroupVLANModal extends Component {

  constructor(props) {
    super(props);
    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.setUseVlanId = this.setUseVlanId.bind(this);
    this.setVlanId = this.setVlanId.bind(this);
    this.setUseTcp = this.setUseTcp.bind(this);
    this.setAudioIsEncrypted = this.setAudioIsEncrypted.bind(this);
    this.saveGroupVlanSettings = this.saveGroupVlanSettings.bind(this);
    this.fetchDeviceLocation = this.fetchDeviceLocation.bind(this);

    this.handleClose = this.handleClose.bind(this);
    this.state = {
      showModal: false,
      useVlanId: false,
      saving: false,
      showSaveStatus: false,
      showValidationError: false,
      loading: true
    };
  }

  handleClose() {
    this.setState({ showModal: false, statusMessage: null });
  }

  handleShow() {
    this.setState({ loading: true, showModal: true });
    setTimeout(() => this.fetchDeviceLocation(), help.hideSpinnerDelay);
  }

  componentDidMount() {
    
  }

  setUseVlanId = (e) => this.setState({useVlanId: e.target.checked});

  setVlanId = (e) => this.setState({VlanId: e.target.value}, this.validateForm(e));

  setUseTcp = (e) => {
    let protocol = e.target.checked ? 'TCP' : 'UDP';
    this.setState({useTcp: e.target.checked, TransportProtocol: protocol, EncryptedAudio: false});
  }

  setAudioIsEncrypted = (e) => this.setState({EncryptedAudio: e.target.checked});

  fetchDeviceLocation = () => {
    let params = '?locationAccountNumber=' + this.props.locationAccNum;
    apiCalls.fetchData('admin/GetDeviceLocationProperties' + params).then((data) => {
      if (data !== null) {
        this.setState({
          EncryptedAudio: data.EncryptedAudio,
          TransportProtocol: data.TransportProtocol,
          useTcp: data.TransportProtocol === 'TCP',
          VlanId: data.VlanId || '',
          useVlanId: data.VlanId !== ('' || null),
          FQDN: data.FQDN,
          loading: false
        });
      } else if (data === null) {
        this.setState({fqdns: [], loading: false});
      }
    }).catch(message =>{
      console.log('caught an exception fetching device locations');
      console.log(message);
      this.setState({loading: false});
    });
  }

  validForm = () => {
    if (this.state.useVlanId && this.state.VlanId === '') {
      this.setState({showValidationError: true});
      return false
    } else {
      this.setState({showValidationError: false});
      return true
    }
  }

  validateForm = (e) => {
    if (this.state.useVlanId && e.target.value === '') {
      this.setState({showValidationError: true});
    } else {
      this.setState({showValidationError: false});
    }
  }

  saveGroupVlanSettings = () => {
    if (this.validForm()) {
      this.setState({saving: true, showSaveStatus: true});
      let data = {
        TransportProtocol: this.state.TransportProtocol,
        EncryptedAudio: this.state.EncryptedAudio,
        VlanId: this.state.useVlanId ? this.state.VlanId : null,
        LocationAccountNumber: this.props.locationAccNum,
        FQDN: this.state.FQDN
      };
      let apiPath = 'admin/UpdateDeviceLocationProperties';
      apiCalls.send(apiPath, 'PATCH', JSON.stringify(data)).then(resp => {
        if(resp.ok) {
          setTimeout(() => {this.setState({
            saving: false,
            statusMessage: 'Group VLAN settings were updated.',
            parentCallback: this.handleClose
          }), setTimeout(() => this.handleClose(), help.statusMsgDelay)}, help.hideSpinnerDelay);
        } else {
          this.setState({...this.state,
            saving: false,
            error: true,
            statusMessage: this.notSavedErrorMsg(resp),
            parentCallback: this.handleClose
          })
        }
      }).catch(message => {
        this.setState({saving: false});
        console.log('caught exception saving Group VLAN settings.');
        console.log(message);
      });
    }
  }

  vlanIdDivSettings = (attr) => {
    var settings = {};
    if (this.state.useVlanId) {
      settings = {class: 'hidden-group collapse in', 'aria-expanded': 'true'};
    } else {
      settings = {class: 'hidden-group collapse', 'aria-expanded': 'false'};
    }
    return settings[attr]
  }

  notSavedErrorMsg = (resp) => 'Device not saved. Error: ' + resp.message;

  getModalBody = () => {
    if (this.state.loading) {
      return <DataLoadingSpinner className='load spinner'/>;
    } else {
      return (
        <div className={help.savingOrConfirming(this.state) ? 'opaque' : 'visible'}>
          <h3>Set Group VLAN ID</h3>
          <p>Setup a Virtual Location Area Network (VLAN) as the default. The default can be overwritten on an individual device level if needed.</p>
          <div className="form-group vlan-id">
            <label className="right switch">
              <input
                type="checkbox"
                data-toggle="collapse"
                data-target="#hide1"
                checked={this.state.useVlanId}
                onChange={this.setUseVlanId}
              />
              <span className="slider round"></span>
            </label>
            Use VLAN ID?
            <div
              id="hide1"
              className={this.vlanIdDivSettings('class')}
              aria-expanded={this.vlanIdDivSettings('aria-expanded')}
            >
              <label >VLAN ID</label>
              <span className={this.state.showValidationError ? 'invalid' : 'hidden'}>
                <span className='text-danger'>VLAN ID is required.</span>
              </span>
              <input 
                type="text"
                className="form-control"
                value={this.state.VlanId}
                onChange={this.setVlanId}
              />
            </div>
          </div>
          <h3>Set Group IP Transport Protocol for Devices</h3>
          <p>
            Set the IP transport protocol for all devices within the group.  If a device(s) requires a different setting 
            than what is configured for the group, you may elect to overwrite the group IP transport protocol settings 
            during device provisioning.
          </p>
          <p>
            TCP is not the typical transport protocol for voice traffic and should only be used when there are 20+ 
            Busy Lamp Fields (BLF) configured for an individual device.  Contact Momentum Telecom Technical Support 
            for more detail.
          </p>
          <div className="form-group transport-protocol">
            <label className="right switch">
              <input 
                type="checkbox"
                checked={this.state.useTcp}
                data-toggle="collapse" 
                data-target="#hide2"
                onChange={this.setUseTcp}
              />
              <span className="slider round"></span>
            </label>
            Use TCP? (UDP is default)
            <small>
              Note: if you change the current IP transport protocol settings for this group, you must reprovision 
              all devices within the group before the new setting will be pushed to existing device configuration
              files.
            </small>
          </div>

          <h3>Set Group Encrypted Audio & Signaling for Devices</h3>
          <p>
            Set device Audio and Signaling Encryption for all devices within the group.  If a device(s) requires a different 
            setting than what is configured for the group, you may elect to overwrite the group Audio and Signaling Encryption
            settings during device provisioning. 
          </p>
          <div className="form-group encryption">
            <label className="right switch">
              <input
                type="checkbox"
                checked={this.state.EncryptedAudio}
                data-toggle="collapse"
                data-target="#hide3"
                onChange={this.setAudioIsEncrypted}
              />
              <span className="slider round"></span>
            </label>
            Enable Encrypted Audio & Signaling?
          </div>
        </div>
      )
    }
  }

  render() {
    return (
      <div className="setting">
        <span onClick={this.handleShow}>
          <span className="name">Group VLAN Setup</span>
          <a className="icon btn"><svg width="14" height="14" viewBox="0 0 24 24"><path d="m163 440l-91-91 251-250 90 90z m309-352l-48-48c-12-11-32-11-45 2l-45 45 91 91 45-45c13-13 13-33 2-45z m-408 275l-32 117 117-32z" transform="scale(0.046875 0.046875)"></path></svg></a>
        </span>
        <Modal show={this.state.showModal} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Group VLAN Settings</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.getModalBody()}
          </Modal.Body>
          <Modal.Footer>
            <hr />
            <div className={help.footerBtnClass(this.state)}>
              <Button className="btn" click={this.saveGroupVlanSettings}>Save</Button>
              <Button className="btn" click={this.handleClose}>Close</Button>
            </div>
            <div className={this.state.showValidationError ? 'invalid' : 'hidden'}>
              <h6 className='text-danger text-center'>VLAN ID is required.</h6>
            </div>
            <SaveStatus 
              saving={this.state.saving}
              statusMessage={this.state.statusMessage}
              error={this.state.error}
              parentCallBack={this.state.parentCallback}
              hideOkBtn={true}
            />
          </Modal.Footer>
        </Modal>
      </div>
    )
  }
};

export default GroupVLANModal;