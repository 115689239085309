import React, { Component } from "react";
import { Grid, GridCell, GridColumn as Column } from '@progress/kendo-react-grid';
import * as apiCalls from "../../../../src/requests/api_calls";
import { Modal } from 'react-bootstrap';
import { orderBy } from "@progress/kendo-data-query";
import { headerToolTip } from "../../../components/ellipsisHeaderTooltip";
import { DataLoadingSpinner } from '../../../components/spinners';

export default class VoicePolicy extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            CurrentTrunk: {},
            VoicePoliciesList: this.props.VoicePoliciesList,
            gridHeight: window.innerHeight - 200,
            Sort: [{ field: "UsersCount", dir: "desc" }],
            filter: { logic: "and", filters: [] },
            showModal: false,
        };

        class UserBtn extends GridCell {
            constructor(props) {
                super(props);
                this.state = {
                    loading: false,
                    showModal: false,
                    UsersList: {},
                    Sort: [{ field: "DisplayName", dir: "desc" }],
                };
            }

            async componentDidMount() {
                await this.setState({ loading: true })
                console.log("logged", this.props.dataItem.VoiceRoutePolicy)
                await this.setState({ loading: true });
                let path = `admin/MSTeamsUserPolicy?policyName=${this.props.dataItem.VoiceRoutePolicy}`;
                await apiCalls.fetchData(path).then(async (data) => {
                    if (data) {
                        console.log(data);
                        this.setState({
                            UsersList: data,
                            loading: false,
                        });
                    } else {
                        this.setState({
                            UsersList: [],
                            loading: false,
                        });
                    }
                }).catch(err => console.error(err));

            }

            handleOpen = () => {
                this.componentDidMount();
                this.setState({
                    showModal: true,

                });
            }

            handleClose = () => {
                this.setState({
                    showModal: false,
                });
            };
            gridContent = () => {
                let loading = this.state.loading;
                if (loading) {
                    return (<DataLoadingSpinner className='load spinner' />);
                }
                let loadingError = this.state.loadingError;
                if (loadingError) {
                    return (<div className="inline inline-fail text-center">{loadingError}</div>);
                }

                let { UsersList, Sort } = this.state;
                if (UsersList && UsersList.length > 0) {
                    return (
                        <Grid
                            data={orderBy(UsersList, Sort)}
                            sortable={{ allowUnsort: true, mode: "single" }}
                            sort={Sort}
                            onSortChange={this.handleSortChange}
                        >
                            <Column
                                field="DisplayName"
                                title={headerToolTip("Name", true)}
                            />
                            <Column
                                field="UserPrincipalName"
                                title={headerToolTip("Email", true)}
                            />
                            <Column
                                field="TelephoneNumber"
                                title={headerToolTip("Phone No.", true)}
                            />
                        </Grid>
                    );
                } else {
                    return (
                        <p style={{ textAlign: "center", paddingTop: "15px" }}>
                            No Available Data
                        </p>
                    );
                }
            };



            render() {
                return (
                    <td>
                        <a onClick={this.handleOpen} className="no-btn">{this.props.dataItem.UsersCount}</a>
                        <Modal show={this.state.showModal} onHide={this.handleClose} backdrop='static'>
                            <Modal.Header>
                                <Modal.Title>
                                    LIST OF USERS
                                    <button className="mfa-close" style={{
                                        float: "right",
                                        border: "none",
                                        backgroundColor: "transparent"
                                    }} onClick={this.handleClose}>X</button>
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                {this.gridContent()}
                            </Modal.Body>
                        </Modal>
                    </td>

                )
            }
        }
        this.editBtn = UserBtn;
    }

    async componentDidMount() { }



    handleSortChange = (e) => {
        this.setState({
            VoicePoliciesList: orderBy(this.state.VoicePoliciesList, e.sort),
            Sort: e.sort,
        });
    };

    StatusCell = (props) => {
        let customClassName = "";
    
        if (props.dataItem.Status) {
          customClassName = "dot dot-success";
          return (
            <td>
              <i className={customClassName} />
            </td>
          )
        }
        else{
            customClassName = "dot dot-error";
            return (
                <td>
                  <i className={customClassName} />
                </td>
              )
        }
      };

      showStatusLegend = () => {
        const derivedMatchingObject =  this.state.VoicePoliciesList.find(x => x.VoiceRoutePolicy === 'Momentum_VoicePolicy')
        const directMatchingObject =  this.state.VoicePoliciesList.find(x => x.VoiceRoutePolicy === 'Momentum-ALL')
        if(derivedMatchingObject !== undefined || directMatchingObject !== undefined){
            if(derivedMatchingObject.VoiceRoutePolicy === "Momentum_VoicePolicy" && derivedMatchingObject.Status==="true"){
                return (<div className='pull-right'>
                <div><i className="dot dot-success" />{'-'}<i> Momentum Supported Derived Routing Voice Policy</i></div>
                <div><i className="dot dot-error" />{'-'}<i> Non Supported Voice Policy</i></div>
              </div>)
            }
            if(directMatchingObject.VoiceRoutePolicy === "Momentum-ALL" && directMatchingObject.Status==="true"){
                return (<div className='pull-right'>
                <div><i className="dot dot-success" />{'-'}<i> Momentum Supported Direct Routing Voice Policy</i></div>
                <div><i className="dot dot-error" />{'-'}<i> Non Supported Voice Policy</i></div>
              </div>)
            }
        }
        if(derivedMatchingObject === undefined && directMatchingObject === undefined){
            return (<div className='pull-right'>
            {/* <div><i className="dot dot-success" />{'-'}<i> </i></div> */}
            <div><i className="dot dot-error" />{'-'}<i> Non Supported Voice Policy</i></div>
          </div>)
        }
        // return (<div className='pull-right'>
        //   <div><i className="dot dot-success" />{'-'}<i> Users with Correct VoicePolicy</i></div>
        //   <div><i className="dot dot-error" />{'-'}<i> Users with Incorrect Voice Policy</i></div>
        // </div>)
      }

    gridContent = () => {
        let { VoicePoliciesList, Sort } = this.state;
        console.log("VoiceRoutesList", VoicePoliciesList);
        if (VoicePoliciesList && VoicePoliciesList.length > 0) {
            return (
                <Grid
                    style={{ height: this.state.gridHeight + "px", width: "100%" }}
                    data={orderBy(VoicePoliciesList, Sort)}
                    sortable={{ allowUnsort: true, mode: "single" }}
                    sort={Sort}
                    onSortChange={this.handleSortChange}
                >
                    <Column
                        field="VoiceRoutePolicy"
                        title={headerToolTip("Voice Route Policy", true)}
                    />
                    <Column cell={this.editBtn} title={headerToolTip("Number of users", true)} />
                    <Column title={headerToolTip("Status", true)} cell={this.StatusCell} />
                </Grid>
            );
        } else {
            return (
                <p style={{ textAlign: "center", paddingTop: "15px" }}>
                    No Available Data
                </p>
            );
        }
    };

    cardContent = () => {
        return (
          <div className="card-content">
            <div className="users-row" style={{ position: 'relative', left: '-82px' }}>
              <div className='users-item'>
                {this.showStatusLegend()}
              </div>
            </div>
            <div className="card-content">{this.gridContent()}</div>
          </div >
        );
      }

    render() {
        //return this.gridContent();
        return this.cardContent();
    }
}
