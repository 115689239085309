import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { Grid, GridColumn as Column, GridCell } from '@progress/kendo-react-grid';
import { headerToolTip } from '../../components/ellipsisHeaderTooltip';
import { post, fetchData } from '../../requests/api_calls';
import { orderBy } from '@progress/kendo-data-query';
import { filterBy } from '@progress/kendo-data-query';
import { DataLoadingSpinner } from '../spinners';
import { storedUserRole, userIsAdmin, redirectToLanding } from "../../scripts/helper";
import * as settings from '../../scripts/settings';

export default class MFAAdminTool extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      showConfirmation: false,
      UserList: [],
      Sort: [{ field: "UserId", dir: "desc" }],
      filter: { logic: "and", filters: [] },
      GroupAdminBanner: null,
    };

    const openConfirmWindow = this.openConfirmWindow.bind(this);

    class ManageCell extends GridCell {
      constructor(props) {
        super(props);
      }

      render() {
        let role = storedUserRole();
        let { UserId, VerificationMethod, UserTypeId } = this.props.dataItem;
        if (UserId && VerificationMethod) {
          if (role === 'GROUP_ADMIN' && UserTypeId === 1107) {
            return (
              <td>
                <a
                  className="a btn"
                  style={{ backgroundColor: "lightgrey", cursor: "not-allowed" }}
                >
                  {"Reset MFA"}
                </a>
              </td>
            )
          }
          return (
            <td>
              <a
                className="a btn"
                onClick={() => openConfirmWindow(UserId)}
              >
                {"Reset MFA"}
              </a>
            </td>
          )
        } else {
          return (
            <td>
              <a
                className="a btn"
                style={{ backgroundColor: "lightgrey", cursor: "not-allowed" }}
              >
                {"Reset MFA"}
              </a>
            </td>
          )
        }
      }
    }

    this.ManageCell = ManageCell;
  }

  componentDidMount() {
    if (userIsAdmin()) {
      let role = storedUserRole();
      if (role === 'GROUP_ADMIN') {
        this.setState({ GroupAdminBanner: sessionStorage.AccountNumber })
      }
      this.fetchMfaUsers();
    } else {
      redirectToLanding();
    }
  }

  openConfirmWindow = (userid) => {
    this.setState({
      showConfirmation: true,
      selectedUser: userid
    });
  }

  fetchMfaUsers = async () => {
    await this.setState({ loading: true });
    let path = 'admin/MFAUserSearch';
    await fetchData(path)
      .then((data) => {
        if (data) {
          this.setState({
            UserList: data,
            FullListings: data,
            loading: false,
            validationError: "",
            //successMessage: ""
          });
        } else {
          this.setState({
            UserList: [],
            FullListings: [],
            loading: false,
            validationError: "",
            successMessage: ""
          });
        }
      })
      .catch((err) => console.error(err));
  }

  resetUserMFA = async (userId) => {
    try {
      this.setState({ loading: true });
      const path = 'admin/ResetUserMFA';
      const response = await post(path, "POST", JSON.stringify(userId));
      if (response.ok) {
        this.setState({
          loading: false,
          showConfirmation: false,
          validationError: "",
          successMessage: "User's multi-factor authentication has been successfully reset!",
        });
        this.fetchMfaUsers();
        if (userId === sessionStorage.userId) {
          sessionStorage.removeItem("verificationMethod");
        }
      } else {
        console.error(`resetUserMFA(): failed: ${response.message}`);
        this.setState({
          loading: false,
          showConfirmation: false,
          validationError: "Something went wrong while trying to reset user multi-factor authentication.",
          successMessage: "",
        });
      }
    } catch (error) {
      console.error(`resetUserMFA(): failed: ${error}`);
      this.setState({
        loading: false,
        showConfirmation: false,
        validationError: "Something went wrong while trying to reset user multi-factor authentication.",
        successMessage: "",
      });
    }
  }

  errorContent = () => {
    if (this.state.successMessage !== "") {
      return (
        <p className='error-message' style={{ marginTop: "10px", color: "green", textAlign: "center", overflowWrap: "anywhere" }}>{this.state.successMessage}</p>
      )
    }

    if (this.state.validationError === "") {
      return;
    } else {
      return (
        <p className='error-message' style={{ marginTop: "10px", color: "red", textAlign: "center", overflowWrap: "anywhere" }}>{this.state.validationError}</p>
      )
    }
  }

  gridContent = () => {
    let { UserList, Sort } = this.state;
    if (UserList && UserList.length > 0) {
      return (
        <Grid
          style={{
            width: '100%',
          }}
          data={orderBy(UserList, Sort)}
          sortable={{ allowUnsort: true, mode: 'single' }}
          sort={Sort}
          onSortChange={this.handleSortChange}
        >
          <Column width="75px" field="UserId" title={headerToolTip("User ID", true)} />
          <Column field="AzureUserName" title={headerToolTip("Azure User Name", true)} />
          <Column field="EmailAddress" title={headerToolTip("Email", true)} />
          <Column field="VerificationMethod" title={headerToolTip("Verification Method", true)} />
          <Column field="ResetDate" title={headerToolTip("Last Reset", true)} />
          <Column title={headerToolTip("Manage User")} cell={this.ManageCell} />
          <Column title=' ' field='filler' />
        </Grid>
      )
    } else {
      return <p style={{ textAlign: 'center', paddingTop: '15px' }}>No Available Data</p>
    }
  }

  handleSortChange = (e) => {
    this.setState({
      UserList: orderBy(this.state.UserList, e.sort),
      Sort: e.sort
    });
  }

  getBodyContent() {
    let loading = this.state.loading;
    if (loading) {
      return (<DataLoadingSpinner className='load spinner' />);
    } else {
      return this.gridContent();
    }
  }

  handleClose = () => {
    this.setState({ showConfirmation: false });
  }


  searchListings = (event) => {
    let searchText = event.target.value.trim();
    let fullDetails = this.state.FullListings;

    if (searchText !== '') {
      searchText = event.target.value;
      let services = filterBy(fullDetails, {
        logic: 'or',
        filters: [
          { field: 'AzureUserName', operator: 'contains', value: searchText, ignoreCase: true },
          { field: 'EmailAddress', operator: 'contains', value: searchText, ignoreCase: true },
        ]
      });
      this.setState({ UserList: services, loading: false });
    }
    else {
      this.setState({ UserList: fullDetails, loading: false });
    }
  }

  render() {
    return (
      <>
        <div className="mfa-page">
          <div className="container full-card">
            <div className="mfa card">
              <div className="card-header">
                <h1>Manage MFA Users
                  <small>&nbsp;{this.state.GroupAdminBanner ? this.state.GroupAdminBanner : null}&nbsp;</small>
                </h1>
                <div className="card-actions">
                  <div className="search">
                    <form ref={'searchTerm'} onSubmit={e => { e.preventDefault() }}>
                      <label htmlFor="services_srch_tb" className="hidden">services search</label>
                      <input id="services_srch_tb" ref={'searchTerm'} type="text" className="form-control search-control"
                        autoComplete={"off"}
                        onChange={this.searchListings}
                      />
                      <svg width="14" height="14" viewBox="0 0 23 23"><path d="m347 238c0-36-12-66-37-91-25-25-55-37-91-37-35 0-65 12-90 37-25 25-38 55-38 91 0 35 13 65 38 90 25 25 55 38 90 38 36 0 66-13 91-38 25-25 37-55 37-90z m147 237c0 10-4 19-11 26-7 7-16 11-26 11-10 0-19-4-26-11l-98-98c-34 24-72 36-114 36-27 0-53-5-78-16-25-11-46-25-64-43-18-18-32-39-43-64-10-25-16-51-16-78 0-28 6-54 16-78 11-25 25-47 43-65 18-18 39-32 64-43 25-10 51-15 78-15 28 0 54 5 79 15 24 11 46 25 64 43 18 18 32 40 43 65 10 24 16 50 16 78 0 42-12 80-36 114l98 98c7 7 11 15 11 25z" transform="scale(0.046875 0.046875)"></path></svg>
                    </form>
                  </div>
                </div>
              </div>
              <div className="card-content">
                {this.getBodyContent()}
                {this.errorContent()}
              </div>
            </div>
          </div>
        </div>
        <Modal show={this.state.showConfirmation} onHide={this.handleClose} backdrop='static'>
          <Modal.Header>
            <Modal.Title>Are you sure you want to reset MFA configuration for this user?</Modal.Title>
          </Modal.Header>
          <Modal.Footer>
            <hr />
            <div className="btns">
              <span>
                <a className='a btn' onClick={() => this.resetUserMFA(this.state.selectedUser)}>Yes</a>
                <a className='a btn' onClick={() => this.setState({ showConfirmation: false })}>No</a>
              </span>
            </div>
          </Modal.Footer>
        </Modal>
      </>
    )
  }
}