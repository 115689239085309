import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { Button } from '@progress/kendo-buttons-react-wrapper';
import { Switch } from '@progress/kendo-inputs-react-wrapper';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'; // ES6
import { DataLoadingSpinner } from './../../../components/spinners';
import SaveStatus from './../../../components/save_status';
import * as apiCalls from './../../../requests/api_calls';
import * as config from './../../../requests/config';
import _ from 'lodash';

const validationDelay = 500;

class FQDNSetupModal extends Component {

  constructor(props) {
    super(props);
    this.state = { 
      showModal:false, 
      validFqdn: false,
      newFqdn: '',
      fqdns: [],
      status: null
    };
    this.timer = null;
    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.fetchFqdns = this.fetchFqdns.bind(this);
    this.setNewFqdn = this.setNewFqdn.bind(this);
    this.saveNewFqdn = this.saveNewFqdn.bind(this);
    this.removeFqdn = this.removeFqdn.bind(this);
    this.fqdnsTable = this.fqdnsTable.bind(this);
    this.delayedValidation = this.delayedValidation.bind(this);
  }

  componentDidMount() {   
    this.fetchFqdns()
  }

  componentWillUnmount() {
    if(this.timer) {
      clearTimeout(this.timer);
    }
  }

  fetchFqdns = () => {
    let params = '?locationAccountNumber=' + this.props.locationAccNum;
    apiCalls.fetchData('admin/GetFqdns' + params).then((data) => {
      if (data !== null) {
        this.setState({loading: false, fqdns: data});
      } else if (data === null) {
        this.setState({fqdns: [], loading: false});
      }
    });
  }

  handleClose() {
    this.setState({...this.state, error: false, statusMessage:'', saving: false, showModal: false, newFqdn: '' });
  }

  handleShow() {
    this.setState({...this.state, showModal: true, saving_show_success:false });
  }

  showClearForm = () => this.setState({error: false});

  validFqdn = () => {
    let regex = /(?=^.{1,254}$)(^(?:(?!\d+\.)[a-zA-Z0-9_\-]{1,63}\.?)+(?:[a-zA-Z]{2,})$)|(^(?:[0-9]{1,3}\.){3}[0-9]{1,3}$)/;
    return regex.test(this.state.newFqdn);
  }

  delayedValidation = () => {
    if(this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      if (this.validFqdn()) {
        this.setState({validFqdn: true});
      } else {
        this.setState({validFqdn: false});
      }
    }, validationDelay);
  }

  setNewFqdn = (e) => {
    this.setState({newFqdn: e.target.value});
    this.delayedValidation();
  }

  showInvalidFqdnClass = () => {
    return this.state.newFqdn && !this.validFqdn();
  }

  verifyValidFqdnClass = () => {
    return this.state.newFqdn && this.validFqdn();
  }

  saveOnEnter = (e) => {
    if (e.key === 'Enter') this.saveNewFqdn();
  }

  saveNewFqdn = () => {
    if (this.state.newFqdn) {
      let newFqdn = this.state.newFqdn;
      let data = JSON.stringify({Fqdn: newFqdn});
      apiCalls.send('admin/AddFqdn', 'POST', data).then(resp => {
        if(resp.ok) {
          let updatedFqdns = this.state.fqdns.slice();
          updatedFqdns.unshift(newFqdn);
          this.setState({fqdns: updatedFqdns, newFqdn: '', saving: false, status: 'Successfully saved FQDN'});
        } else {
          this.setState({...this.state, 
            error: true,
            statusMessage: this.notSavedErrorMsg(resp),
            parentCallback: this.showClearForm,
            status: false
          })
        }
      }).catch(message => {
        console.log('caught an exception saving a new FQDN');
        console.log(message);
      });
    }
  }
  
  removeFqdn = (fqdn) => {
    let data = JSON.stringify({Fqdn: fqdn});
    this.setState({removedFqdn: fqdn});
    apiCalls.send('admin/DeleteFqdn', 'DELETE', data).then(resp => {
      if(resp.ok) {
        let updatedFqdns = this.state.fqdns.filter(fqdn => fqdn !== this.state.removedFqdn);
        this.setState({fqdns: updatedFqdns, removedFqdn: null, status: 'Successfully removed FQDN'});
      } else {
        this.setState({error: true, statusMessage: this.notSavedErrorMsg(resp), status: false});
      }
    }).catch(message => {
      console.log(message)
    });
  }

  notSavedErrorMsg = (resp) => {
    if (resp.message.indexOf("already exists") >= 0) {
      return "FQDN already exists"
    } else {
      return 'FQDN not saved. Error: ' + resp.message;
    }
  }

  closeSavingMessage = () => {
    this.setState({saving: false, error: false, statusMessage: ''});
  }

  modalBody = () => {
    if (this.state.error || this.state.saving)
      return (
        <SaveStatus 
          saving={this.state.saving}
          statusMessage={this.state.statusMessage}
          error={this.state.error}
          parentCallBack={this.closeSavingMessage}
        />
      )
    return (
      <div className="fqdn-setup-modal">
        <p>Add and remove Fully Qualified Domain Names (FQDNs) to be used by devices.</p>
        {this.state.status 
          && <div className='fqdn-status alert alert-success'>{this.state.status}</div>
        }
        <div className="form-group">
          <label>New FQDN</label>
          <span className={this.verifyValidFqdnClass() ? 'text-success': 'hidden'}>✓</span>
          <span className={this.showInvalidFqdnClass() ? 'text-danger': 'hidden'}>
            This FQDN format is invalid.
          </span>
          <div className="form-group">
            <label>{this.props.label}</label>
            <input 
              id="new-fqdn" 
              type="type" 
              className="form-control" 
              onChange={this.setNewFqdn}
              value={this.state.newFqdn}
              onKeyPress={this.saveOnEnter}
            />
          </div>
        </div>
        {this.fqdnsTable()}
      </div>
    )
  }

  fqdnsTable = () => {
    if (this.state.fqdns.length) {
      return (
        <div>
          <label className='existing'>Existing FQDNs:</label>
          <div className={this.state.error ? 'opaque' : 'existing-fqdns'}>
            <table className="fqdns table">
              <tbody>
                {this.state.fqdns.map((fqdn) => this.fqdnRow(fqdn))}
              </tbody>
            </table>
          </div>
        </div>
      )
    } else {
      return null
    }
  }

  fqdnRow = (fqdn) => {
    return (
      <tr key={fqdn}>
        <td>{fqdn}</td>
        <td style={{width:"20%"}}>
          <Button className="icon btn" click={e => this.removeFqdn(fqdn)}>Delete</Button>
        </td>
      </tr>
    )
  }

  displayTimedStatus = () => {
    this.timer = setTimeout(() => {
      this.setState({ status: false });
    },3000)
  }

  render() {
    if(this.state.status) {
      this.displayTimedStatus();
    }

    return (
      <div className="setting">
        <span onClick={this.handleShow}> 
          <span className="name">FQDN Setup</span>
          <a id="lnkFQDNSetup" className="icon btn"><svg width="14" height="14" viewBox="0 0 24 24"><path d="m163 440l-91-91 251-250 90 90z m309-352l-48-48c-12-11-32-11-45 2l-45 45 91 91 45-45c13-13 13-33 2-45z m-408 275l-32 117 117-32z" transform="scale(0.046875 0.046875)"></path></svg></a>
        </span>
        <Modal show={this.state.showModal} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>FQDN Setup</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.modalBody()}
          </Modal.Body>
          <Modal.Footer>
            <hr />
            <div className="btns" style={{"display": this.state.error || this.state.saving ? "none" : "block"}}>
              <Button className="btn" click={this.saveNewFqdn}>Save</Button>
              <Button className="btn" click={this.handleClose}>Cancel</Button>
            </div>            
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default FQDNSetupModal;
