import React from 'react';
import * as apiCalls from '../../../requests/api_calls';

export const retailTrouble = '0121A000000MZv3QAG'; //VOICE
export const dataServices = '0120h000000Upkk';//DATA
export const btnStyles = {
    fontSize: '80%',
    width: '18%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
};

export const btnStyles2 = {
    fontSize: '95%',
    width: '32%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
};

export const btnStyles3 = {
    fontSize: '95%',
    width: '30%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
};

export const pickListOptions = (data) => {
    let options = data.map((item, idx) => {
        return <option key={idx} value={item}>{item}</option>
    });
    return options;
}

export const contactOptions = (data) => {
    let options = data.map((contact, idx) => {
        return <option key={idx} value={contact.ContactId}>{contact.Name}</option>
    });
    return options;
}