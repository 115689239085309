import React, { Component } from 'react';
import { DataLoadingSpinner } from '../../components/spinners';
import { Modal } from 'react-bootstrap';
import * as apiCalls from '../../requests/api_calls';
export default class MsTeamsConfig extends Component {
  constructor(props) {
    super(props);

    this.state = {
      Enterprise: this.props.Enterprise,
      Trunk: this.props.Trunk,
      MSTeamsConfig: this.props.MSTeamsConfig,
      MSTeamsConfigCopy: this.props.MSTeamsConfig,
      loading: this.props.loading,
      Submission: false,
      submitError: false,
      completed: false,
      msgDetails: "",
      PrimaryGateWay: this.props.PrimaryGateWay,
      SecondaryGateWay: this.props.SecondaryGateWay,
      testingCredentials: false,
      verify: true,
      verified: "",
      routingType: this.props.routingType,
    };
  }

  componentWillReceiveProps() {
    if (this.props.MsTeamsConfig !== this.state.MsTeamsConfig) {
      this.setState({ MSTeamsConfig: this.props.MsTeamsConfig });
    }
  }

  msTrunkData = () => {
    let { MSTeamsConfig, Trunk, Enterprise, PrimaryGateWay, SecondaryGateWay } = this.state;
    if (MSTeamsConfig.IsPasswordSaved == 1) {
      MSTeamsConfig["Password"] = "**********";
      MSTeamsConfig["PasswordLengthExceeded"] = false;
    }
    return (
      <div>
        {this.dataRow("Trunk:", this.inputDataField(Trunk.TrunkName, "TrunkName", null, true))}
        {this.dataRow("User Name:", this.inputDataField(MSTeamsConfig.UserName, "UserName", this.handleInputChange))}
        {this.dataRow("Password:", this.inputDataField(MSTeamsConfig.Password, "Password", this.handleInputChange, false, "password"))}
        {/* { this.dataRow("Primary FQDN:", this.inputDataField(PrimaryGateWay, "PstnPrimaryGateway", this.handleInputChange, true))}
        { this.dataRow("Secondary FQDN:", this.inputDataField(SecondaryGateWay, "PstnSecondaryGateway", this.handleInputChange, true))} */}
      </div>
    )
  }

  handleInputChange = async (e, key) => {
    let { value } = e.target;
    let MSTeamsConfig = { ...this.state.MSTeamsConfig };
    MSTeamsConfig[key] = value;
    if (key == "Password") {
      var len = value.length;
      if(len>=30){
        console.log("provide password less then 30")
        MSTeamsConfig["PasswordLengthExceeded"] = true;
        await this.setState({
          Submission: true,
          submitError: true,
          msgDetails: "**Please enter password less then 30 characters",
          testingCredentials: true,
          verify: false,
          verified: true
        });
        
      }
      else{
        MSTeamsConfig["PasswordLengthExceeded"] = false;
        await this.setState({
          Submission: true,
          submitError: true,
          msgDetails: "",
          testingCredentials: false,
          verify: false,
          verified: true
        });
      }
      // set bool to 0 when only password/username form fields are updated; these fields are used as params in web service method that checks credential integrity
      MSTeamsConfig["IsPasswordSaved"] = 0
      MSTeamsConfig["Password"] = value
    }
    if (key == "UserName") {
      MSTeamsConfig["IsPasswordSaved"] = 0
      MSTeamsConfig["UserName"] = value
    }
    await this.setState({ MSTeamsConfig });
    //console.log("MSTeamsConfig", MSTeamsConfig); // inserted AD Information
  }

  inputDataField = (val, key, onChangeFunc, readOnly = false, type = 'text') => {
    return (
      <input
        style={{
          width: '50%',
          display: 'inline-block',
        }}
        type={type}
        name={key}
        value={val}
        className="form-control"
        onChange={(e) => onChangeFunc(e, key)}
        disabled={readOnly}
      />
    )
  }

  dataRow = (label, field) => {
    return (
      <div className="form-group-wrapper"
        style={{ margin: '1%', }}
      >
        <label
          style={{
            width: '50%',
            display: 'inline-block',
          }}
        >{label} </label>
        {field}
      </div >
    )
  }

  verifyCredentials = async () => {
    let { testingCredentials, Enterprise, MSTeamsConfig } = this.state;
    await this.setState({ testingCredentials: true, Submission: false });
    let path = `admin/TestCredentialsRoutingForUser`;
    let dto = {
      UserName: sessionStorage.username,
      EnterpriseId: Enterprise,
      Usrn: MSTeamsConfig.UserName,//"gopi@themountgreenteams.com",
      Password: MSTeamsConfig.Password,//"TheMountGreenTeams*1",
      EnterpriseTrunkId: this.props.EnterpriseTrunkId,
    };
    // console.log("DTO", dto);
    // console.log("checking config object", MSTeamsConfig);

    let resp = await apiCalls.post(path, "POST", JSON.stringify(dto)).then(data => data).catch(err => console.error(err));
    if (resp.ok) {
      if (!resp.message.includes("Error")) {
        await this.setState({
          Submission: true,
          submitError: false,
          msgDetails: "Credentials Sucessfully Verified!",
          testingCredentials: false,
          verify: true
        });
      } else {
        await this.setState({
          Submission: true,
          submitError: true,
          msgDetails: "Error in credential verification!",
          testingCredentials: false,
          verify: false,
          verified: true
        });
      }
    }
  }

  checkDisabledBtn = () => {
    let { MSTeamsConfig, MSTeamsConfigCopy } = this.state;
    if(MSTeamsConfig.PasswordLengthExceeded)
    {
      return true
    }
    else{
      if (
        MSTeamsConfig.UserName == MSTeamsConfigCopy.UserName &&
        MSTeamsConfig.Password == MSTeamsConfigCopy.Password &&
        MSTeamsConfig.PstnPrimaryGateway == MSTeamsConfigCopy.PstnPrimaryGateway &&
        MSTeamsConfig.PstnSecondaryGateway == MSTeamsConfigCopy.PstnSecondaryGateway &&
        MSTeamsConfig.MsTeamsMaxConcurrentSessions == MSTeamsConfigCopy.MsTeamsMaxConcurrentSessions 
      ) {
        return true
      } else {
        return false;
      }
    }
  }

  cardContent = () => {
    let { loading, MSTeamsConfig } = this.state;

    if (!loading) {
      if (MSTeamsConfig) {
        return (
          <div className="card-content"
            style={{
              paddingTop: "20px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <div className="flex-row"
                style={{
                  width: '60%',
                }}
              >
                {this.msTrunkData()}
                <div>
                  <button className="a btn"
                    onClick={() => this.setState({ showConfirmationModal: true })}
                    // disabled={true}
                    disabled={this.checkDisabledBtn()}
                  >
                    {"Save"}
                  </button>
                  <button
                    className="a btn"
                    disabled={this.state.testingCredentials}
                    //disabled={this.state.verify}
                    onClick={this.verifyCredentials}
                  >Verify Credentials</button>
                  <button
                    className="a btn"
                    onClick={this.saveAndContinue}
                  >Continue</button>
                </div>
                {/* {this.wizardMsg()} */}
                {this.submissionMsg()}
              </div>
              <div className="flex-row"
                style={{
                  marginLeft: '2%',
                  width: '40%',
                  border: '1px solid grey'
                }}
              >
                <div style={{ textAlign: "center", paddingLeft: '10px' }}>
                  <span style={{ textDecoration: "underline" }}>Support Notes</span>
                  <p style={{ textAlign: "left" }}>The Deployment Wizard helps the Admin initiate the connection to the Microsoft 365 Tenant.
                    <br /><br />Provide the User Name and Password credentials for the Microsoft 365 Tenant and click Save.
                    <br /><br />Note: These credentials must have Admin access and PowerShell® command execution privileges to set up {this.state.routingType}.
                    <br /><br />After the User Name and Password are Saved, click on the Verify Credentials button to test the credentials, and ensure that the Deployment Wizard is able to connect to the Microsoft 365 Tenant.
                    <br /><br />Once the connection is verified, the user list is retrieved from the Microsoft 365 tenant within 15 minutes.
                    <br /><br />When completed and the connection is verified, click Continue to move on to the next step.
                  </p>
                </div>
              </div>
            </div>
          </div >
        );
      } else {
        return (
          <p style={{ textAlign: 'center', paddingTop: '15px' }}>No Available Data</p>
        )
      }
    } else {
      return <DataLoadingSpinner style={{ marginTop: "3%" }} className='load spinner' />
    }
  }

  selectTrunk = (val) => {
    return (
      <select className="form-control"
        style={{ width: '30%', display: 'inline-block', }}
        disabled
        onChange={async (e) => {
          let { value } = e.target;
          let targetTrunk = this.state.Trunks.filter(itm => {
            if (itm.TrunkId == value) {
              return itm;
            }
          });
          await this.setState({ Trunk: targetTrunk[0], MSTeamsConfig: {} }); // clear out obj on change
          this.fetchMSTeamsConfigInfo();
        }}
        value={val}
      >
      </select>
    )
  }

  handleSubmit = async () => {
    await this.setState({ configSaving: true });
    let { Trunk, MSTeamsConfig } = this.state;
    let path = "admin/UpdateMSTeamsConfig";
    MSTeamsConfig.TrunkId = Trunk.TrunkId;
    MSTeamsConfig.IsPasswordSaved = 0;
    MSTeamsConfig.LoginName = sessionStorage.username; // added new KVP
    //console.log("DTO", MSTeamsConfig);
    let resp = await apiCalls.post(path, "POST", JSON.stringify(MSTeamsConfig)).then(data => data).catch(err => console.error(err));
    if (resp.ok) {
      this.props.removeTabRedirectOnSave(false);
      if (!resp.message.includes("Error")) {
        await this.setState({
          showConfirmationModal: false,
          Submission: true,
          submitError: false,
          msgDetails: "Submission Sucess: Changes were processed!, Credentials Sucessfully Verified!",
          testingCredentials: false,
          verify: true,
        });
      } else {
        await this.setState({
          showConfirmationModal: false,
          Submission: true,
          submitError: true,
          verified: false,
          msgDetails: "Submission Success!",
          verifyMsgDetails: "Error in credential verification!",
          testingCredentials: false,
        });
      }
    }

    if (!resp.message.includes("Error")) {
      this.props.RefreshData();
    }
    this.setState({ configSaving: false });
  }

  submissionMsg = () => {
    if (this.state.Submission) {
      if (this.state.submitError && this.state.verified === false) {
        return <div><small style={{
          color: 'green',
        }}>{this.state.msgDetails}</small><br></br><small style={{
          color: 'red',
        }}>{this.state.verifyMsgDetails}</small></div>
      }
      else if (this.state.submitError) {
        return <small style={{
          color: 'red',
        }}>{this.state.msgDetails}</small>
      }
      else {
        return <small style={{
          color: 'green',
        }}>{this.state.msgDetails}</small>
      }
    }
    if (this.state.testingCredentials) {
      return <DataLoadingSpinner
        style={{
          position: "relative",
          left: "25%",
          display: "inline"
        }}
        className='load spinner' />
    }
  };

  saveAndContinue = () => {
    //this.setState({ completed: true });
    this.props.Proceed("domain");
  }


  render() {
    return (
      <div>
        <Modal show={this.state.showConfirmationModal} onHide={this.handleClose} backdrop='static'>
          <Modal.Header>
            <Modal.Title>Are you sure you want to save changes?</Modal.Title>
          </Modal.Header>
          <Modal.Footer>
            <hr />
            {!this.state.configSaving ? <div className="btns">
              <span>
                <button type="button" className="btn" onClick={this.handleSubmit}>Yes</button>
                <button className="btn" onClick={() => this.setState({ showConfirmationModal: false })}>No</button>
              </span>
            </div> : <DataLoadingSpinner className='load spinner' />}
          </Modal.Footer>
        </Modal>
        {this.cardContent()}
      </div>
    );
  };
}