import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { Button } from '@progress/kendo-buttons-react-wrapper';
import { DataLoadingSpinner } from '../../../components/spinners'; 
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'; // ES6
import { postDataWithCustomHeader } from '../../../requests/api_calls';
import * as settings from '../../../scripts/session';

class PinResetModal extends Component {

  constructor(props) {
    super(props);
    let pin = '8642';
    let isCustom = false;
    if ((props.DirectoryNumber !== '') && 
    (props.DirectoryNumber.indexOf('86') >= 0 || props.DirectoryNumber.indexOf('42') >= 0 || props.DirectoryNumber.indexOf('68') >= 0 || props.DirectoryNumber.indexOf('24') >= 0))
    {
      isCustom = true;      
      pin = this.GeneratePin();
    }
      

    this.state = { 
      showModal: false,
      loading: false,
      saving_loading: false,
      saving_show_success: false,
      saving_error_message: '',
      saving_show_error: false,
      PIN: pin,
      isCustom: isCustom
    };
  }

  handleClose=()=> {
    this.setState({ showModal: false });
  }

  handleShow=()=> {
    this.setState({ showModal: true });
  }

  GeneratePin = () => {
    let returnString = '';
    while (returnString.length < 4) {
      let newNumber = Math.floor(Math.random() * 10).toString();
      if (returnString.indexOf(newNumber) < 0)
        returnString += newNumber;
    }
    
    return returnString;
    
  }

  generateNewPin=()=> {
    this.setState({loading: true});
    let pin = this.state.PIN;
    let newPin = pin;
    while (pin === newPin)
      newPin = this.GeneratePin();
    
    this.setState({PIN: newPin, loading: false});
  }

  handleResetPin=()=> {
    let state = {...this.state};
    state.saving_loading = true;
    state.saving_show_success = false;
    this.setState(state);
    
    
    
    let body = {
      AccountNumber: this.props.AccountNumber,
      DirectoryNumber: this.props.DirectoryNumber,
      ResetPin: this.state.PIN,
      access_token: settings.storedAccessToken(),
      x_policy: settings.storedXPolicy(),
    };
    let headers = {
      'Content-Type': 'application/x-www-form-urlencoded', 
      'Accept': 'text/html'
    }


    postDataWithCustomHeader("vm/ResetVMPin", body, headers)
		.then(response =>{
      let state = {...this.state};
			if(response.ok){
        state.saving_show_success = true;
        state.saving_loading = false;
        state.saving_show_error = false;
			}
			else{
        let message = response.message;
        let msg = 'Failed Save';
        if (message.indexOf('Invalid portal passcode') >= 0)
          msg = 'Invalid PIN.  Please click on Generate New Voicemail Pin button and try again or contact support!';  
        
        
        state.saving_loading = false;
        state.saving_show_error = true;
        state.saving_error_message = msg
      }
      this.setState(state);
		})
		.catch((message)=>{
      console.log(message);
      let state = {...this.state};
			state.saving_loading = false;
      this.setState(state);
		});
  }

  componentDidMount() {   
  }

  render() {
    return (
      <span>
        <Button click={this.handleShow}>Reset Voicemail Pin</Button>

        <Modal show={this.state.showModal} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Reset Voicemail Pin</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <DataLoadingSpinner style={{display:this.state.loading === true ? 'block' : 'none'}} className='load spinner' />
            <div className="" style={{display: this.state.loading === true ? 'none' : 'block'}}>
              <p>Click the Reset button below to temporarily reset your Voicemail PIN to <span style={{fontSize:'larger'}}><b>{this.state.PIN}</b></span>. When you access your voicemail with this temporary PIN, you will be prompted to assign a new PIN.</p>
              <a className="no-btn" onClick={this.handleResetPin}>Reset Voicemail Pin</a> <br /><br />
              <a className="no-btn" onClick={this.generateNewPin}>Generate New Voicemail Pin</a>
            </div>
          </Modal.Body>
          <Modal.Footer>
              <hr />
              <div className="btns">
                <Button className="btn" click={this.handleClose}>Close</Button>
              </div>
              <SavingMessageControl 
                showSuccess={this.state.saving_show_success} 
                showLoading={this.state.saving_loading} 
                showError={this.state.saving_show_error}
                SuccessMessage="Success"
                SuccessTransitionClass="vm-setting-success"
                ErrorMessage={this.state.saving_error_message}
                />
          </Modal.Footer>
        </Modal>
      </span>
    );
  }
};

class SavingMessageControl extends Component {
  render() {
      let successMessage = "";
      if(this.props.showSuccess){
        successMessage =  (<ReactCSSTransitionGroup
          transitionName={this.props.SuccessTransitionClass}
          transitionAppear={true}
          transitionAppearTimeout={4000}
          transitionEnter={true}
          transitionEnterTimeout={4000}
          transitionLeave={false}
          >
          <div className="inline-success text-center">{this.props.SuccessMessage}</div>
        </ReactCSSTransitionGroup>)
      }

      let loadingMessage = "";
      if(this.props.showLoading){
        loadingMessage = (<DataLoadingSpinner className='load relative'/>)
      }
      
      let errorMessage = "";
      if(this.props.showError){
        errorMessage = (<div className="inline inline-fail text-center">{this.props.ErrorMessage}</div>)
      }

      return (
        <div>
          {successMessage}
          {loadingMessage}
          {errorMessage}
        </div>
      );
    }
};

export default PinResetModal;