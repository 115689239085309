import React, { Component } from 'react';
import FullCircuitsGraph from './full_graph';
import { Modal } from 'react-bootstrap';
import { DataLoadingSpinner } from '../../../components/spinners';
import { DataViz } from './d3-line-chart';

export default class CircuitsGraph extends React.Component {
  constructor(props) {
    super(props);


    this.state = {
      DateRange: this.props.DateRange,
      Measurement: this.props.measurement,
      Type: this.props.type,
      AggWindow: this.props.aggWindow,
    };
  }

  componentDidMount() {
    this.props.updateGraphUrl(this.state.DateRange, this.state.Type, this.state.AggWindow, this.state.Measurement);
  }

  componentWillReceiveProps() {
    if (
      this.state.DateRange !== this.props.DateRange ||
      this.state.Type !== this.props.type ||
      this.state.AggWindow !== this.props.aggWindow ||
      this.state.Measurement !== this.props.measurement
    ) {
      this.setState({
        DateRange: this.props.DateRange,
        Type: this.props.type,
        AggWindow: this.props.aggWindow,
        Measurement: this.props.measurement
      });
    }
  }

  cardContent = () => {
    if (this.props.graphData && this.props.graphData.length > 0 && !this.state.loading) {
      return (
        <div className="card-content">
          <div className="activity-actions">
            <div className="activity-action">
              <div className='filter' >
                <select
                  className="circuit-dates form-control"
                  onChange={async (e) => {
                    let { value } = e.target;
                    let Type = this.props.type;
                    let AggWindow = "D";
                    if (Type !== "upDown") {
                      if (value == "-7d") {
                        Type = `${this.state.Measurement}_${this.props.type},avg_${this.props.type}`;
                        AggWindow = "W";
                      } else if (value == "-30d") {
                        Type = `${this.state.Measurement}_${this.props.type},avg_${this.props.type}`;
                        AggWindow = "M";
                      } else if (value == "-365d") {
                        Type = `${this.state.Measurement}_${this.props.type},avg_${this.props.type}`;
                        AggWindow = "Y";
                      } else {
                        Type = this.props.defaultType;
                        AggWindow = "D";
                      }
                    }
                    await this.setState({ loading: true, DateRange: e.target.value, Type: Type, AggWindow: AggWindow });
                    await this.props.updateGraphUrl(this.state.DateRange, Type, this.state.AggWindow, this.state.Measurement);
                    await this.setState({ loading: false });
                  }}
                  value={this.state.DateRange}
                >
                  <option value={"-1d"}>Today</option>
                  <option value={"-2d"}>Yesterday</option>
                  <option value={"-7d"}>Last 7 Days</option>
                  <option value={"-30d"}>Last 30 Days</option>
                  <option value={"-365d"}>Last 365 Days</option>
                </select>
              </div>
              {this.state.DateRange === "-1d" || this.state.DateRange === "-2d" || this.props.type === "upDown" ? null : <div className="filter">
                <select className="form-control"
                  onChange={async (e) => {
                    let { value } = e.target;
                    let Type = `${value}_${this.props.type},avg_${this.props.type}`;
                    await this.setState({ loading: true, Measurement: value, Type: Type });
                    await this.props.updateGraphUrl(this.state.DateRange, Type, this.state.AggWindow, value);
                    await this.setState({ loading: false });
                  }}
                  value={this.state.Measurement}
                >
                  <option value={"max"}>Max</option>
                  <option value={"min"}>Min</option>
                </select>
              </div>}
            </div>
          </div>
          {this.testChart()}
        </div>
      )
    } else {
      return <DataLoadingSpinner className='load spinner' />
    }
  }

  testChart = () => {
    if (this.state.DateRange == "-1d" || this.state.DateRange == "-2d") {
      return (
        <div className="k-chart-holder">
          <div id={`${this.props.GraphId}-wrapper`}>
            <DataViz
              data1={this.props.graphData[0]}
              data2={[]}
              summary={this.props.graphData[1]}
              id={this.props.GraphId}
              DateRange={this.state.DateRange}
              AggWindow={this.state.AggWindow}
              Measurement={this.state.Measurement}
              Type={this.state.Type}
              defaultType={this.props.defaultType}
              axisLabel={this.props.axisLabel}
              aspect={"0 0 660 200"}
            />
          </div>
        </div>
      )
    } else {
      return (
        <div className="k-chart-holder">
          <div id={`${this.props.GraphId}-wrapper`}>
            <DataViz
              data1={this.props.graphData[0]}
              data2={this.props.graphData[1]}
              summary={this.props.graphData[2]}
              id={this.props.GraphId}
              DateRange={this.state.DateRange}
              AggWindow={this.state.AggWindow}
              Measurement={this.state.Measurement}
              Type={this.state.Type}
              defaultType={this.props.defaultType}
              axisLabel={this.props.axisLabel}
              aspect={"0 0 660 200"}
            />
          </div>
        </div>
      )
    }
  }

  render() {
    return (
      <div
        className="activity card">
        <div className="card-header">
          <h6><b>{this.props.GraphTitle}</b>
            <a
              style={{
                position: "relative",
                float: "right",
                cursor: this.props.graphData && this.props.graphData.length > 0 ? "pointer" : "not-allowed"
              }}
              disabled={this.props.graphData && this.props.graphData.length > 0 ? false : true}
              onClick={() => {
                this.setState({ showModal: true });
              }}
            >[Expand]</a>
          </h6>
        </div>
        <Modal
          show={this.state.showModal}
          onHide={this.handleClose}
          dialogClassName="circuits-modal"
          bsSize="large"
        >
          <Modal.Header>
            <Modal.Title>{this.props.GraphTitle}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}>
              <FullCircuitsGraph
                graphData={this.props.graphData}
                updateGraphUrl={this.props.updateGraphUrl}
                id={`Expanded${this.props.GraphId}`}
                DateRange={this.state.DateRange}
                AggWindow={this.state.AggWindow}
                Measurement={this.state.Measurement}
                Type={this.state.Type}
                defaultType={this.props.defaultType}
                axisLabel={this.props.axisLabel}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <hr />
            <div className="btns">
              <span>
                <button className="btn" onClick={() => this.setState({ showModal: false })}>Close</button>
              </span>
            </div>
          </Modal.Footer>
        </Modal>
        {this.cardContent()}
      </div>
    )
  }
}