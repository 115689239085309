import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { Button } from '@progress/kendo-buttons-react-wrapper';
import * as helper from '../../../scripts/helper';
import { DataLoadingSpinner } from '../../../components/spinners';
import { Grid, GridColumn as Column, GridCell } from '@progress/kendo-react-grid';
import * as apiCalls from '../../../requests/api_calls';
import { orderBy } from '@progress/kendo-data-query';
import _ from 'lodash';
import { filterBy } from '@progress/kendo-data-query';
// import { ItemValidations } from '../../../scripts/macd_helper';
// import { map } from '@progress/kendo-data-query/dist/npm/transducers';
import * as macdHelper from '../components/macd_helper';
import * as session from '../../../scripts/session';
import MACDSaveStatus from '../modals/orderResultModal';
import ValidationCell from '../components/validationCell';
import MACDSearch from '../components/macdSearch';

class BlockTab extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isCancelDialogOpen: false,
      MomentumAccountNumber: props.MomentumAccountNumber,
      LocationName: props.LocationName,
      gridHeight: props.GridHeight,
      loading: false,
      saving: false,
      error: false,
      BlockServices: [],
      Listings: [],
      OriginalListings: [],
      Sort: [{ field: "ServiceNumber", dir: "asc" }],
      ValidationErrorMessages: []
    };

    const updateBlock = this.updateBlock.bind(this);

    class BlockTypeCell extends GridCell {
      // constructor(props) {
      //   super(props);
      // }
      render() {
        const productItem = this.props.dataItem;
        const blockDirectoryAssist = productItem.BlockCallDirectoryAssistance;
        const blockOperatorAssist = productItem.BlockOperatorAssistance;
        const blockInternational = productItem.BlockInternationalCalling;
        const blockLongDistance = productItem.BlockDomesticLongDistance;
        const disableField = session.IsWholesale();
        
        return (
          <td>
            <label>
              <input type="checkbox" checked={blockDirectoryAssist} disabled={disableField} onChange={(e) => { updateBlock(e, productItem, 'BlockCallDirectoryAssistance') }} />
              Directory Assistance (411)
            </label>
            &nbsp;&nbsp;&nbsp;
            <label>
              <input type="checkbox" checked={blockOperatorAssist} disabled={disableField} onChange={(e) => { updateBlock(e, productItem, 'BlockOperatorAssistance') }} />
              Operator Assistance (0)
            </label>
            &nbsp;&nbsp;&nbsp;
            <label>
              <input type="checkbox" checked={blockInternational} disabled={disableField} onChange={(e) => { updateBlock(e, productItem, 'BlockInternationalCalling') }} />
              International Calling
            </label>
            &nbsp;&nbsp;&nbsp;
            <label>
              <input type="checkbox" checked={blockLongDistance} disabled={disableField} onChange={(e) => { updateBlock(e, productItem, 'BlockDomesticLongDistance') }} />
              Domestic Long Distance
            </label>
          </td>
        )
      }
    }
    
    this.ValidationCell = ValidationCell;
    this.BlockTypeCell = BlockTypeCell;

  }

  componentDidMount() {
    if (this.state.MomentumAccountNumber !== '') {
      this.fetchListings();
    }
    
  }
  async componentWillReceiveProps(props) {
    if (props) {
      if (props.MomentumAccountNumber !== this.state.MomentumAccountNumber) {
        await this.setState({ MomentumAccountNumber: props.MomentumAccountNumber, LocationName: props.LocationName });
        this.fetchListings();
      }
      if (props.GridHeight && props.GridHeight !== this.state.gridHeight) 
          this.setState({gridHeight: props.GridHeight});
    }
  }

  handleSaveSuccess = async () => {
    await this.setState({ loading: true, ValidationErrorMessages: [], statusMessage: 'Your order has been saved and submitted successfully.' });
    this.props.TabHasChanges(false);
    this.fetchListings();
  }

  showErrorAndResetModal = (msg, ValidationErrorMessages = []) => {
    this.setState({ error: true, statusMessage: msg, ValidationErrorMessages: ValidationErrorMessages });
  }

  closeSaveMessage = () => {
    let serviceListings = this.state.BlockServices.slice();
    let gridListings = this.state.Listings.slice();
    let validationErrors = this.state.ValidationErrorMessages.slice();
    validationErrors.map((itm, idx) => {
      let itmInListings = serviceListings.filter(resp => resp.CustomerProductId === itm.CustomerProductId);
      let indexOfListing = _.indexOf(serviceListings, itmInListings[0]);
      serviceListings[indexOfListing].ValidationMessage = itm.ValidationMessage;
      itmInListings = gridListings.filter(resp => resp.CustomerProductId === itm.CustomerProductId);
      indexOfListing = _.indexOf(gridListings, itmInListings[0]);
      gridListings[indexOfListing].ValidationMessage = itm.ValidationMessage;
    });
    this.setState({ error: false, statusMessage: '', Listings: gridListings, BlockServices: serviceListings });
  }

  CancelYesAction = async () => {
    //clear all editable fields
    await this.fetchListings();
    this.props.TabHasChanges(false);
    this.setState({ isCancelDialogOpen: false, ValidationErrorMessages: [] });

  }

  closeCancelDialog = () => {
    this.setState({ isCancelDialogOpen: false });
  }

  handleCancel = () => {
    //display confirmation message
    let changedItems = this.state.BlockServices.filter(res => res.HasChanges === true);
    if (!changedItems || changedItems.length === 0) return;
    this.setState({ isCancelDialogOpen: true });
  }

  handleSave = async () => {
    let changedItems = this.state.BlockServices.filter(res => res.HasChanges === true);
    if (!changedItems || changedItems.length === 0) return;
    await this.setState({ saving: true });
    let apiPath = macdHelper.apiSubmitBlockType();
    let originalListings = this.state.OriginalListings;

    let submittedItems = changedItems.map((itm, idx) => {
      let oldService = originalListings.filter(res => res.CustomerProductId === itm.CustomerProductId);
      oldService = oldService[0];
      return {
        OldService: oldService,
        BlockCallDirectoryAssistance: itm.BlockCallDirectoryAssistance,
        BlockOperatorAssistance: itm.BlockOperatorAssistance,
        BlockInternationalCalling: itm.BlockInternationalCalling,
        BlockDomesticLongDistance: itm.BlockDomesticLongDistance
      }
    });

    let reqBody = {
      LocationAccountNumber: this.state.MomentumAccountNumber,
      ServiceList: submittedItems
    };
    //console.log(reqBody);
    await apiCalls.post(apiPath, 'POST', JSON.stringify(reqBody)).then((res) => {
      let validationErrors = [];
      if (res.ok === true) {
        this.handleSaveSuccess();
      } else {
        let responseObject = JSON.parse(res.message);
        //console.log(responseObject);
        // RESPONSE ARRARY
        if (responseObject.length && responseObject.length > 0) {
          validationErrors = responseObject.map((itm, idx) => {
            if (itm.CustomerProductId && itm.CustomerProductId !== '')
              return {
                CustomerProductId: parseInt(itm.CustomerProductId),
                ValidationMessage: itm.ErrorMessage
              }
            else {
              throw [itm.ErrorMessage];
            }
          });
        } else {
          // RESPONSE OBJECT
          if (responseObject.MPOStatus && responseObject.MPOStatus.MPOErrorList && responseObject.MPOStatus.MPOErrorList.length > 0) {
            let ErrorList = responseObject.MPOStatus.MPOErrorList[0];
            let ErrorMessages = ErrorList.MPOResultText;
            validationErrors = ErrorMessages.map((itm, idx) => {
              if (itm.CustomerProductId && itm.CustomerProductId !== '')
                return {
                  CustomerProductId: parseInt(itm.CustomerProductId),
                  ValidationMessage: itm.ErrorMessage
                }
              else {
                throw [itm.ErrorMessage];
              }
            });
          }
        }
        if (validationErrors) {
          this.showErrorAndResetModal('Your order could not be completed due to entry errors indicated in the grid.', validationErrors);
        }
      }
    }).catch((msg) => {
      this.showErrorAndResetModal(msg);
    }).finally(() => {
      this.setState({ saving: false });
    });
  }

  fetchListings = async () => {
    await this.setState({ loading: true });
    let locationName = this.state.LocationName;
    let apiPath = macdHelper.apiGetBlockType(this.state.MomentumAccountNumber);
    let [responseData, unformattedResponse] = await apiCalls.fetchData(apiPath).then((data) => {
      if (data) {
        let formattedData = data.ServiceList.map((itm, idx) => {
          let newItm = {
            AddonCount: itm.AddonCount,
            Block: itm.Block,
            BlockCallDirectoryAssistance: itm.BlockCallDirectoryAssistance,
            BlockDomesticLongDistance: itm.BlockDomesticLongDistance,
            BlockInternationalCalling: itm.BlockInternationalCalling,
            BlockOperatorAssistance: itm.BlockOperatorAssistance,
            CustomerProductId: itm.CustomerProductId,
            CatalogItemId: itm.CatalogItemId,
            PartDescription: itm.PartDescription,
            PartNum: itm.PartNum,
            ServiceNumber: itm.ServiceNumber,
            Extension: itm.Extension,
            isExtensionOnly: itm.isExtensionOnly,
            Location: locationName,
            LineName: itm.LineName,
            LineFirstName: itm.LineFirstName,
            LineLastName: itm.LineLastName,
            HasChanges: false,
            ValidationMessage: '',
          };
          return newItm;
        });
        return [formattedData, data.ServiceList];
      } else {
        return [[], []];
      }
    });
    await this.setState({ Listings: responseData, BlockServices: responseData, OriginalListings: unformattedResponse, loading: false });
  }

  updateBlock = async (e, productItem, key) => {
    let selectedValue = e.target.checked;
    let blockServices = this.state.BlockServices;
    let gridListings = this.state.Listings;
    let originalItem = this.state.OriginalListings.filter(res => res.CustomerProductId === productItem.CustomerProductId)[0]; // non-mutated

    productItem[key] = selectedValue;
    if (
      productItem['BlockCallDirectoryAssistance'] !== originalItem['BlockCallDirectoryAssistance'] ||
      productItem['BlockOperatorAssistance'] !== originalItem['BlockOperatorAssistance'] ||
      productItem['BlockInternationalCalling'] !== originalItem['BlockInternationalCalling'] ||
      productItem['BlockDomesticLongDistance'] !== originalItem['BlockDomesticLongDistance']
    ) {
      productItem['HasChanges'] = true;
    } else {
      productItem['HasChanges'] = false;
    }

    // GRID ITEM
    let gridItm = gridListings.filter(itm => itm.CustomerProductId === productItem.CustomerProductId);
    let idx = _.indexOf(gridListings, gridItm);
    gridListings[idx] = productItem;
    // BLOCK SERVICES' ITEM
    let blockItm = blockServices.filter(itm => itm.CustomerProductId === productItem.CustomerProductId);
    let idx2 = _.indexOf(blockServices, blockItm);
    blockServices[idx2] = productItem;

    if (blockServices && blockServices.filter(res => res.HasChanges === true).length === 0) {
      this.props.TabHasChanges(false);
    } else {
      this.props.TabHasChanges(true);
    };
    await this.setState({ BlockServices: blockServices, Listings: gridListings });
  }

  gridContent = () => {
    //console.log(this.state.Listings);
    if (this.state.MomentumAccountNumber === '') {
      return macdHelper.locationSelectLabel(this.state.MomentumAccountNumber);
    }
    else if (this.state.loading) return <DataLoadingSpinner className="load spinner relative" />
    else if(!this.state.loading && this.state.Listings.length === 0) return <p>There are no applicable changes available for services at this location. Please review your search criteria.</p>
    else if (!this.state.loading && this.state.Listings && this.state.Listings.length > 0) {
      return (
        <Grid
          style={{ height: this.state.gridHeight + 'px' }}
          data={this.state.Listings}
          sortable={{ allowUnsort: true, mode: 'single' }}
          sort={this.state.Sort}
          onSortChange={this.sortChange}
        >
          {this.state.ValidationErrorMessages && this.state.ValidationErrorMessages.length > 0 ? <Column field='ValidationMessage' title=" " width={'50px'} cell={this.ValidationCell} sortable={false} /> : null}
          <Column field='PartDescription' title='Product' width="200px" />
          <Column field='ServiceNumber' title='Service ID' width="200px" />
          <Column field='Extension' title='Extension' width="100px" />
          <Column field='LineName' title='Line Name' width="175px" />
          <Column field='Location' title='Location' width="150px" />
          <Column width="650px" cell={this.BlockTypeCell} title='Block' sortable={false} />
          <Column field="spacer" title=" " sortable={false} filterable={false} />
        </Grid>
      )
    }
  }

  sortListings = (sort, Listings = null) => {
    let returnObj = [];
    if (this.state.Listings) {
      if (Listings == null) Listings = this.state.Listings.slice();
      let sortedSet = orderBy(Listings, sort);
      returnObj = sortedSet;
      if (sort.length) {
        returnObj = helper.blanksAfterZ(sortedSet, sort);
      }
    }
    return returnObj;
  }

  sortChange = (event) => {
    this.setState({
      Listings: this.sortListings(event.sort),
      Sort: event.sort
    });
  }

  searchListings = (event) => {
    let searchText = event.target.value.trim();
    //console.log("Search Text: " + searchText);
    let fullDetails = this.state.BlockServices;
    if (searchText !== '') {
      searchText = event.target.value;
      let services = filterBy(fullDetails, {
        logic: 'or',
        filters: [
          { field: 'ServiceNumber', operator: 'contains', value: searchText, ignoreCase: true },
          { field: 'Extension', operator: 'contains', value: searchText, ignoreCase: true },
          { field: 'LineName', operator: 'contains', value: searchText, ignoreCase: true },
          { field: 'PartDescription', operator: 'contains', value: searchText, ignoreCase: true }
        ]
      });

      this.setState({ Listings: services })
    }
    else {
      this.setState({ Listings: fullDetails });
    }

  }

  render() {

    return (
      <div>
        <div style={{ "textAlign": "right", "marginBottom": "5px", "marginTop": "5px", "display": this.state.loading || this.state.MomentumAccountNumber === '' ? "none" : "block" }}>
          <div className="search">
            <MACDSearch SearchFunction={this.searchListings} />
            <Button click={this.handleCancel}>Cancel</Button>
            <Button click={this.handleSave}>Save</Button>
          </div>
        </div>
        <hr />
        <Modal dialogClassName="confirm-cancel-macd" show={this.state.isCancelDialogOpen} onHide={this.closeCancelDialog}>
          <Modal.Header closeButton>
            <Modal.Title>Cancel Order?</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div><p><span style={{ fontSize: "medium" }}>Are you sure you wish to cancel this order?</span></p></div>
          </Modal.Body>
          <Modal.Footer>
            <Button click={(evt) => { this.CancelYesAction() }}>Yes</Button>
            <Button click={(evt) => { this.closeCancelDialog() }}>No</Button>
          </Modal.Footer>
        </Modal>
        <MACDSaveStatus
          saving={this.state.saving}
          statusMessage={this.state.statusMessage}
          error={this.state.error}
          parentCallBack={this.closeSaveMessage}
        />
        {this.gridContent()}
      </div>
    );
  }
};

export default BlockTab;
