import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { Button } from "@progress/kendo-buttons-react-wrapper";
import { DataLoadingSpinner } from "../../../../components/spinners";
import { fetchData, post } from "../../../../requests/api_calls";
import { filterErrorMessage } from "../../../../utility/utility";
import { CallDispositionCodesTable } from "./disposition-codes-table";

class CallDispositionCodesModal extends Component {
  constructor(props) {
    super(props);

    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);

    this.state = {
      callDispositionCodes: null,
      errorMessage: "",
      loading: false,
      showModal: false,
      itemsToDelete: [],
    };
  }

  handleClose() {
    this.setState({
      callDispositionCodes: null,
      errorMessage: "",
      loading: false,
      showModal: false,
      itemsToDelete: [],
    });
  }

  handleShow = async () => {
    await this.setState({ showModal: true });
    await this.reloadView();
  };

  fetchDispositionCodes = async () => {
    return await fetchData("admin/enterprise/GetCallDispositionCodes").then(
      (dispositionCodes) => dispositionCodes
    );
  };

  updateCallDispositionCodesActiveList = async () => {
    const { callDispositionCodes } = this.state;
    const path = "admin/enterprise/UpdateCallDispositionCodesActiveList";
    this.setState({ loading: true, errorMessage: "" });
    try {
      const response = await post(path, "POST", JSON.stringify(callDispositionCodes));
      if (response.ok) {
        await this.reloadView();
      } else {
        console.error(`${path}: failed: ${response.message}`);
        this.setState({ loading: false, errorMessage: filterErrorMessage(response.message) });
      }
    } catch (error) {
      console.error(`${path}: failed: ${error}`);
      this.setState({ loading: false, errorMessage: filterErrorMessage(error) });
    }
  };

  deleteCallDispositionCodes = async () => {
    const path = "admin/enterprise/DeleteCallDispositionCodes";
    this.setState({ loading: true, errorMessage: "" });
    try {
      const response = await post(path, "DELETE", JSON.stringify(this.state.itemsToDelete));
      if (response.ok) {
        await this.reloadView();
      } else {
        console.error(`${path}: failed: ${response.message}`);
        this.setState({ loading: false, errorMessage: filterErrorMessage(response.message) });
      }
    } catch (error) {
      console.error(`${path}: failed: ${error}`);
      this.setState({ loading: false, errorMessage: filterErrorMessage(error) });
    }
  };

  reloadView = async () => {
    this.setState({ loading: true });
    const data = await this.fetchDispositionCodes();
    this.setState({ callDispositionCodes: data, loading: false });
  };

  updateItem = (item) => {
    const updateCodes = this.state.callDispositionCodes.map((code) => {
      if (code.Code === item.Code) {
        code = { ...code, ...item };
      }
      return code;
    });
    const itemsToDelete = updateCodes.filter((code) => code.selectToDelete);
    this.setState({ ...this.state, callDispositionCodes: updateCodes, itemsToDelete });
  };

  componentDidMount() {}

  render() {
    let modalContent;
    const { loading, callDispositionCodes, errorMessage, itemsToDelete } = this.state;

    if (loading) {
      modalContent = (
        <div className="content">
          <DataLoadingSpinner
            className="spinner"
            style={{ margin: "10px auto 0", height: "40px" }}
          />
        </div>
      );
    } else if (callDispositionCodes && callDispositionCodes.length > 0) {
      modalContent = (
        <div className="CallDispositionCodesModal">
          <div>
            <CallDispositionCodesTable
              codes={callDispositionCodes}
              reloadView={this.reloadView}
              updateItem={this.updateItem}
            />
          </div>
        </div>
      );
    } else {
      modalContent = <></>;
    }

    return (
      <div className="setting">
        <span onClick={this.handleShow}>
          <span className="name">Call Disposition Codes</span>
          <a className="icon btn">
            <svg width="14" height="14" viewBox="0 0 24 24">
              <path
                d="m163 440l-91-91 251-250 90 90z m309-352l-48-48c-12-11-32-11-45 2l-45 45 91 91 45-45c13-13 13-33 2-45z m-408 275l-32 117 117-32z"
                transform="scale(0.046875 0.046875)"
              ></path>
            </svg>
          </a>
        </span>
        <Modal show={this.state.showModal} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Call Disposition Codes</Modal.Title>
          </Modal.Header>
          <Modal.Body>{modalContent}</Modal.Body>
          <Modal.Footer>
            {!loading && (
              <>
                {errorMessage && (
                  <div style={{ margin: "10px 0", textAlign: "left" }}>
                    <span style={{ color: "red", fontSize: "10px" }}>{errorMessage}</span>
                  </div>
                )}
                <hr />
                <div className="btns">
                  <Button className="btn" click={this.updateCallDispositionCodesActiveList}>
                    Save
                  </Button>
                  {itemsToDelete.length > 0 && (
                    <Button className="btn" click={this.deleteCallDispositionCodes}>
                      Delete Codes
                    </Button>
                  )}
                  <Button className="btn" click={this.handleClose}>
                    Cancel
                  </Button>
                </div>
              </>
            )}
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default CallDispositionCodesModal;
