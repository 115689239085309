import React, { Component } from 'react';
import * as settings from './../scripts/settings.js';
import { store } from '../store/store';
import { connect } from 'react-redux';
import { Button } from '@progress/kendo-buttons-react-wrapper';
import { fetchData } from './../requests/api_calls';
import { DataLoadingSpinner } from './../components/spinners';
import EditContactCenterModal from './modals/editContactCenterModal';

class ContactCenter extends Component {

  constructor(props) {
    super(props);
    this.state = {};
  };


  servicesContent = () => {
    if (this.state.loading) {
      return <DataLoadingSpinner className='load spinner'/>
    } else { 
      return (
        <div>
          <div className="card-header">
            <h1>Contact Center <small>12</small></h1>
            <div className="card-actions">
              <div className="search">
                <label htmlFor="contact_center_srch_tb" className="hidden">contact center search</label>
                <input id="contact_center_srch_tb" type="text" className="form-control" />
                <svg width="14" height="14" viewBox="0 0 23 23"><path d="m347 238c0-36-12-66-37-91-25-25-55-37-91-37-35 0-65 12-90 37-25 25-38 55-38 91 0 35 13 65 38 90 25 25 55 38 90 38 36 0 66-13 91-38 25-25 37-55 37-90z m147 237c0 10-4 19-11 26-7 7-16 11-26 11-10 0-19-4-26-11l-98-98c-34 24-72 36-114 36-27 0-53-5-78-16-25-11-46-25-64-43-18-18-32-39-43-64-10-25-16-51-16-78 0-28 6-54 16-78 11-25 25-47 43-65 18-18 39-32 64-43 25-10 51-15 78-15 28 0 54 5 79 15 24 11 46 25 64 43 18 18 32 40 43 65 10 24 16 50 16 78 0 42-12 80-36 114l98 98c7 7 11 15 11 25z" transform="scale(0.046875 0.046875)"></path></svg>
              </div>
            </div>
          </div>
          <div className="card-content">
            <table className="table table-responsive">
              <thead>
              	<tr>
	                <th>Active</th>
	                <th>Name</th>
	                <th>Type</th>
	                <th>Phone Number</th>
	                <th>Ext</th>
	                <th></th>
	            </tr>
              </thead>
              <tbody>
              	<tr>
              		<td><div className="circle green"></div></td>
              		<td>PHL Sales</td>
              		<td>Premium</td>
              		<td>123 123 1234</td>
              		<td>123</td>
              		<td align="right">
						        <EditContactCenterModal />
  			        	  <a className="btn" href="contact_center_settings">Configure</a>
              		</td>
              	</tr>
              </tbody>
            </table>
          </div>
        </div>
      )
    }
  }

  render() {
    return (
      <div className="full-card container">
        <div className="contact-center card">
          {this.servicesContent()}
        </div>  
      </div>
    )
  }

};

export default ContactCenter;