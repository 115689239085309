import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { Button } from '@progress/kendo-buttons-react-wrapper';
import * as apiCalls from '../../../requests/api_calls';
import SaveStatus from '../../../components/save_status';
import { DataLoadingSpinner } from '../../../components/spinners';


export default class messageDeposit extends Component {
  constructor() {
    super();
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    console.log(this);
    //initial state values
    this.state = {
      loading: false,
      showModal: false,
      userGetGreeting: {},
      ErrorMessage: ""
    }


  }
  componentDidMount() {
    // here executes code when component is loaded

  }

  fetchUserVoiceMessagingUserGetGreeting = async (voipSwitch, userid, accountNumber, directoryNumber, serviceNumber, pBXUserID) => {
    await this.setState({ loading: true });
    let path = 'usr/UserVoiceMessagingUserGetGreeting?switchName=' +
      voipSwitch + '&userId=' + userid + '&AccountNumber=' + accountNumber +
      '&DirectoryNumber=' + directoryNumber + '&ServiceNumber=' + serviceNumber + '&PBXUserID=' + pBXUserID;
    console.log("url", path);
    await apiCalls.fetchData(path).then(data => {
      if (data) {
        //console.log('Data', data);
        this.setState({
          userGetGreeting: data,
          loading: false,
        });
      } else {
        this.setState({
          userGetGreeting: {},
          loading: false,
        });
      }
    }).catch(err => console.error(err));

  }

  modalBody = () => {
    // checkbox html?
  }

  openModal() {
    this.setState({ showModal: true });
    const { VoipSwitch, userId, AccountNumber, DirectoryNumber, ServiceNumber, PBXUserID } = sessionStorage;
    this.fetchUserVoiceMessagingUserGetGreeting(VoipSwitch, userId, AccountNumber, DirectoryNumber, ServiceNumber, PBXUserID)
  }

  closeModal() {
    this.setState({ showModal: false, ErrorMessage: "" });
  }

  handleInputChange = (e) => {
    const { name } = e.target;
    let { userGetGreeting } = this.state;
    userGetGreeting[name] = !userGetGreeting[name];
    this.setState({
      ...this.state,
      userGetGreeting
    })
  }

  modalContent() {
    if (this.state.loading) {
      return <DataLoadingSpinner style={{ marginTop: "3%" }} className='load spinner' />
    }

    const { disableMessageDepositField } = this.state.userGetGreeting || false;
    const { extendedAwayDisableMessageDepositField } = this.state.userGetGreeting || false;

    return (
      <div>
        <div className="form-group">
          <label>General</label>
          <div className="form-control">
            <label className="custom-control">
              <input id="disableMessageDepositField" name="disableMessageDepositField" type="checkbox" defaultChecked={disableMessageDepositField}

                onChange={this.handleInputChange} />
              <span >Disable Message Deposit</span>
            </label>

          </div>

        </div>
        <div className="form-group">
          <label>No Answer Greeting</label>
          <div className="form-control">
            <label className="custom-control">
              <input id="extendedAwayDisableMessageDepositField" name="extendedAwayDisableMessageDepositField" type="checkbox" defaultChecked={extendedAwayDisableMessageDepositField}

                onChange={this.handleInputChange} />
              <span >Disable Message Deposit</span>
            </label>

          </div>

        </div>
      </div>
    );
  }

  handleSubmit = async () => {
    await this.setState({ loading: true });
    const { VoipSwitch, userId, AccountNumber, DirectoryNumber, ServiceNumber, PBXUserID } = sessionStorage;
    const apiPath = "usr/UserVoiceMessagingUserModifyGreeting";
    let { userGetGreeting: requestData } = this.state;

    let dto = {
      AccountNumber: AccountNumber,
      DirectoryNumber: DirectoryNumber,
      ServiceNumber: ServiceNumber,
      PBXUserID: PBXUserID,
      uvmg: requestData,
    }
    let response = await apiCalls.post(apiPath, 'POST', JSON.stringify(dto)).then((data) => data).catch(err => console.error(err));
    if (response.ok) {
      this.setState({ ErrorMessage: "Success" })
      this.fetchUserVoiceMessagingUserGetGreeting(VoipSwitch, userId, AccountNumber, DirectoryNumber, ServiceNumber, PBXUserID);
    } else {
      this.setState({ ErrorMessage: response.message })
      this.fetchUserVoiceMessagingUserGetGreeting(VoipSwitch, userId, AccountNumber, DirectoryNumber, ServiceNumber, PBXUserID);
    }
  }



  render() {

    return (
      <span className='trunk-modal-wrapper'>
        <a className="btn" onClick={this.openModal}>Message Deposit</a>
        <Modal dialogClassName="ip-trunk-modal"
          show={this.state.showModal}
          onHide={this.closeModal}
        >
          <Modal.Header>
            <Modal.Title>Message Deposit</Modal.Title>
          </Modal.Header>
          <Modal.Body>

            {this.modalContent()}

          </Modal.Body>
          <Modal.Footer style={{ textAlign: 'left' }}>
            <hr />
            <div className="btns">
              <button type="button" className="btn" onClick={this.handleSubmit} disabled={this.state.loading}>Submit</button>
              <button className="btn" onClick={this.closeModal} disabled={this.state.loading}>Close</button>
              {/* <span><Button className="btn" click={this.handleSubmit} disabled >Submit</Button></span>
              <span><Button className="btn" click={() => this.closeModal()}>Cancel</Button></span> */}
            </div>
          </Modal.Footer>
          <div style={{ textAlign: 'center', width:370  }}>
            {this.state.ErrorMessage !== "" && !this.state.loading ? <small style={{
              color: this.state.ErrorMessage === "Success" ? 'green' : 'red',
              LeftMargin:'10px'
            }}>{this.state.ErrorMessage}</small> : null}
            </div>
        </Modal>
      </span>

    )
  }
}

