import React, { Component } from 'react';
import { DataLoadingSpinner } from './../../../components/spinners';
import { Modal } from 'react-bootstrap';

const MACDSaveStatus = (props) => {
  if (props.saving) {
    return (
      <Modal show={true} >
        <Modal.Header>
          <Modal.Title>Reviewing MACD Order Submission...</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ minHeight: "100px" }}>
          <div className='PROCESSING' style={{ overflowWrap: "break-word" }}>
            <DataLoadingSpinner className='load saving spinner' />
          </div>
        </Modal.Body>
      </Modal>
    )
  } else if (props.error || props.statusMessage) {
    let statusClass = props.error ? ' error text-danger' : ' success text-success';
    statusClass = 'save-status' + statusClass;
    let okBtnClass = props.hideOkBtn ? 'hidden' : 'btn'
    return (
      <Modal show={true} >
        <Modal.Header>
          <Modal.Title>MACD Order Submission</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ minHeight: "100px" }}>
          <div className={statusClass}>
            {props.statusMessage}
            <div className='text-center'>
              <a className={okBtnClass} onClick={() => props.parentCallBack()}>OK</a>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    )
  } else return null
}

export default MACDSaveStatus;