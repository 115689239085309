import React, { Component } from 'react';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'; // ES6
import { DataLoadingSpinner } from '../components/spinners'

export class SavingMessageControlE911 extends Component {
    render() {
        let successMessage = "";
        if(this.props.showSuccess){
          successMessage =  (<ReactCSSTransitionGroup
            transitionName={this.props.SuccessTransitionClass}
            transitionAppear={true}
            transitionAppearTimeout={4000}
            transitionEnter={true}
            transitionEnterTimeout={4000}
            transitionLeave={false}
            >
            <div className="inline-success text-center">{this.props.SuccessMessage}</div>
          </ReactCSSTransitionGroup>)
        }
  
        let loadingMessage = "";
        if(this.props.showLoading){
          loadingMessage = (<DataLoadingSpinner className='load spinner'/>)
        }
        
        let errorMessage = "";
        if(this.props.showError){
          errorMessage = (<div className="inline inline-fail text-center">{this.props.ErrorMessage}</div>)
        }
  
        return (
          <div>
            {successMessage}
            {loadingMessage}
            {errorMessage}
          </div>
        );
      }
      
};