import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { Button } from '@progress/kendo-buttons-react-wrapper';
import { fetchData, post } from '../../../requests/api_calls';
import * as session from "../../../scripts/session";
import { store } from '../../../store/store';
import * as actions from "../../../store/actions";
import { DataLoadingSpinner } from '../../../components/spinners';

export default class MFAValidateModal extends Component {
  constructor(props) {
    super(props);

    //this.inputRef = React.createRef();

    this.state = {
      showModal: true,
      pinCode: '',
      validationError: "",
      isOktaPushEnabled: sessionStorage.getItem("IsOktaPushEnabled") == "true" ? true : false,
    };
  }

  componentDidMount() {
    this.getMFADetails();
    //this.inputRef.current.focus();
  }

  getMFADetails = async () => {
    try {
      const verificationMethod = sessionStorage.getItem('verificationMethod');
      if (verificationMethod && verificationMethod == "sms") {
        let path = 'usr/ValidateTwoStepSmsConfig';
        await post(path, "POST");
      }
      if (this.state.isOktaPushEnabled) {
        this.onSendPushNotification();
      }
    } catch (error) {
      console.log("no MFA details or error MFA fetching details")
    }
  }

  handleClose = () => {
    this.setState({ showModal: false });
  };

  onSubmitAuthPinCode = async () => {
    try {
      const response = await post('usr/ValidateTwoStepVerificationConfig', 'POST', this.state.pinCode);
      if (response.ok) {
        this.setState({ validationError: "" });
        store.dispatch({ type: actions.VALIDATE_MFA });
        this.handleClose();
      } else {
        console.log(`onSubmitAuthPinCode failed: ${response.message}`);
        this.setState({ validationError: `Authentication PIN validation failed. The code entered is invalid or has expired.` });
      }
    } catch (error) {
      console.log(`onSubmitAuthPinCode failed: ${error}`);
      this.setState({ validationError: "Something went wrong while attempting to validate the pin." });
    }
  };

  onSendPushNotification = async () => {
    await this.setState({ validationError: "", oktaFailed: false });
    try {
      let path = "usr/SendOktaPushNotification?isConfigure=false";
      const response = await post(path, 'POST');
      if (response.ok) {
        //this.setState({ validationError: "", oktaFailed: false });
        store.dispatch({ type: actions.VALIDATE_MFA });
        this.handleClose();
      } else {
        console.log(`onSendPushNotification failed: ${response.message}`);
        this.setState({ validationError: `Okta push notification failed.`, oktaFailed: true });
      }
    } catch (error) {
      console.log(`onSendPushNotification failed: ${error}`);
      this.setState({ validationError: "Something went wrong while attempting to validate the push notification.", oktaFailed: true });
    }
  }

  errorContent = () => {
    if (this.state.validationError === "") {
      return;
    } else {
      return (
        <p className='error-message' style={{ marginTop: "10px", color: "red", textAlign: "center" }}>{this.state.validationError}</p>
      )
    }
  }

  getBodyContent() {
    if (this.state.isOktaPushEnabled) {
      if (this.state.oktaFailed) {
        return (
          <div style={{ display: "flex", justifyContent: "center", flexDirection: "column" }}>
            <div style={{ textAlign: "center" }}>
              <button
                className="a btn"
                onClick={this.onSendPushNotification}
              >
                Resend push notification
              </button>
            </div>
            <div style={{ textAlign: "center" }}>
              <Button className="btn" click={session.logout}>Exit</Button>
            </div>
          </div>
        )
      }
      return (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <DataLoadingSpinner className='load spinner' />
        </div>
      )
    }

    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <div className="mfa-modal__save-pin">
          <label htmlFor="pin-code">Enter the 6-digit code from your Authenticator App or SMS Text:</label>
          <input
            id="pin-code"
            className="form-control"
            onChange={(e) => this.setState({ pinCode: e.target.value })}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                this.onSubmitAuthPinCode();
              }
            }}
            autoFocus
          />
          <div style={{ textAlign: "center" }}>
            <Button className="btn" click={this.onSubmitAuthPinCode}>Submit</Button>
            <Button className="btn" click={session.logout}>Exit</Button>
          </div>
        </div>
      </div>

    )
  }

  render() {
    return (
      <Modal show={this.state.showModal} onHide={this.handleClose} backdrop='static' style={{ backgroundColor: 'rgb(43, 61, 84)' }}>
        <div style={{ backdropFilter: 'blur(5px)', backgroundColor: 'rgba(255, 255, 255)', borderRadius: '5px' }}>
          <Modal.Header>
            <Modal.Title>Multi-Factor Authentication</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.getBodyContent()}
            {this.errorContent()}
          </Modal.Body>
        </div>
      </Modal>

    );
  }
}