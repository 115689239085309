import React, { Component } from 'react';
import { DataLoadingSpinner } from '../components/spinners';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { orderBy } from '@progress/kendo-data-query';
import * as helper from '../scripts/helper';

export default class BSCallLogsTab extends Component {
  constructor(props) {
    super(props);

    this.state = {
      LocationAccountNumber: '',
      BSCallLogs: props.BsLogs,
      loading: props.isLoading,
      logs: [],
      Sort: [],
      skip: 0
    }
    // this.gridScroll = this.gridScroll.bind(this);
  };


  componentDidMount() {
  }

  componentWillReceiveProps(props) {
    if (props.BsLogs != this.state.BSCallLogs) {
      this.setState({ BSCallLogs: props.BsLogs });
    }
    if (props.isLoading != this.state.loading) {
      this.setState({ loading: props.isLoading });
    }
  }


  searchStatus = () => {
    if (this.state.loading) {
      return <DataLoadingSpinner className={'load spinner relative'} />
    } else if (this.state.BSCallLogs.length === 0) {
      return (
        <h5 className='text-center' style={{ padding: '30px', textAlign: 'center' }} >
          No results for the filters selected.
        </h5>
      )
    } else return null;
  }

  sortListings = (sort, logs = null) => {
    let returnObj = [];
    if (this.state.BSCallLogs) {
      if (logs == null) logs = this.state.BSCallLogs.slice();
      let sortedSet = orderBy(logs, sort);
      returnObj = sortedSet;
      if (sort.length) {
        returnObj = helper.blanksAfterZ(sortedSet, sort);
      }
    }
    return returnObj;
  }

  sortChange = async (event) => {
    await this.setState({
      BSCallLogs: this.sortListings(event.sort),
      Sort: event.sort
    });
  }

  // gridScroll = (event) => {
  //   const e = event.nativeEvent;
  //   if (e.target.scrollTop + 30 >= e.target.scrollHeight - e.target.clientHeight) {
  //       //TODO: grab more data from the source
  //       this.props.GetBSCallLogs(this.state.BSCallLogs.length);//skip method
  //   }
  // }

  gridContent = () => {
      if (this.state.loading || this.state.BSCallLogs.length === 0 ) {
        return (
          <div style={{
            display: 'block',
            height: '100px',
            overflow: 'hidden',
            width: '100%',
          }}>{this.searchStatus()}</div>
        )
      } else {
        let colWidth = this.props.isWidget ? '105px' : '165px';
        if (this.props.isWidget) {
          return (
            <Grid
              style={{
                width: '100%',
                height: '600px'
              }}
              data={this.state.BSCallLogs}
              scrollable='scrollable'
              sortable={{ allowUnsort: true, mode: 'single' }}
              sort={this.state.Sort}
              onSortChange={this.sortChange}
            // onScroll={(e) => {this.gridScroll(e)} }
            >
              <Column width="85px" field='Direction' title='Direction' />
              <Column width={colWidth} field='CallerID' title='Caller ID' />
              <Column width={colWidth} field='Phone' title='Phone' />
              <Column width="85px" field='Date' title='Date' format="{0:M/d/yyyy}" />
              <Column width={colWidth} field='Time' title='Time' />
              <Column title=' ' field='filler' sortable={false} />
            </Grid>
          )
        } else {
          return (
            <Grid
              style={{
                width: '100%',
              }}
              data={this.state.BSCallLogs}
              scrollable='scrollable'
              sortable={{ allowUnsort: true, mode: 'single' }}
              sort={this.state.Sort}
              onSortChange={this.sortChange}
            >
              <Column width="85px" field='Direction' title='Direction' />
              <Column width={colWidth} field='OrginatingTN' title='From' />
              <Column width={colWidth} field='DestinationTN' title='To' />
              <Column width={colWidth} field='Location' title='Location' />
              <Column width={colWidth} format="{0:M/d/yyyy}" field='Date' title='Date' />
              <Column width={colWidth} field='Time' title='Time' />
              <Column width={colWidth} field='Length' title='Duration (HH:mm:ss)' />
              <Column width={colWidth} field='Completion' title='Completion' />
              <Column width={colWidth} field='CallerID' title='Caller ID' />
              <Column width={colWidth} field='Category' title='Category' />
              <Column width={colWidth} field='AuthorizationCode' title='Authorization Code' />
              <Column width={colWidth} field='PageCount' title='Pages' />
              <Column title=' ' field='filler' sortable={false} />
            </Grid>
          )
        }
      }
  }

  render() {
    return (
      <div className='card-content' >
        {this.gridContent()}
      </div >
    );
  }
};