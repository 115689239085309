import React, { Component } from 'react';
import * as apiCalls from '../../requests/api_calls';
import { DataLoadingSpinner } from '../../components/spinners';
import { Grid, GridColumn as Column, GridCell } from '@progress/kendo-react-grid';
import { orderBy } from '@progress/kendo-data-query';
import { filterBy } from '@progress/kendo-data-query';
import Popup from "reactjs-popup";
import moment from 'moment';
import { serviceFilters } from '../../constants/filters.js';
import { Link } from 'react-router-dom';
import ManageTrunkModal from './components/manageTrunkModal';
import * as settings from '../../scripts/settings';
import WrappedCell from '../../components/wordWrappedCell';
export default class IPTrunking extends Component {

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      Listings: [],
      FullListings: [],
      Sort: [{ field: "TrunkGroupID", dir: "asc" }],
      gridHeight: (window.innerHeight - 200),
    }
    const transitionToManagePage = this.transitionToManagePage.bind(this);
    const transitionToDetailedCallLogs = this.transitionToDetailedCallLogs.bind(this);
    this.fetchTrunkList = this.fetchTrunkList.bind(this);

    class IPCountCell extends GridCell {
      constructor(props) {
        super(props);

        this.state = {
          LoadPop: false,
          IPInfo: [],
        }
      }

      fetchIpList = async (trunkId) => {
        await this.setState({ LoadPop: true });
        let apiPath = `admin/GetIpTrunkIPList?TrunkGroupID=${trunkId}`;
        await apiCalls.fetchData(apiPath).then(data => {
          if (data) {
            let IPContainer = [];
            data.forEach(itm => {
              if (itm) { IPContainer.push({ IP: itm }); }
            });
            this.setState({
              IPInfo: IPContainer,
              LoadPop: false
            });
          } else {
            this.setState({
              IPInfo: [],
              LoadPop: false,
            })
          }
        });
      }

      loadIpList = () => {
        if (this.state.LoadPop) {
          return <DataLoadingSpinner className='load relative spinner' />
        } else {
          return (
            <span>
              <h4 className='text-center'>IP Addresses:</h4>
              <Grid
                data={this.state.IPInfo}
              >
                <Column field="IP" title="IP Address" />
              </Grid>
            </span>
          )
        }
      }

      render() {
        let trunkGroupId = this.props.dataItem.TrunkGroupID;
        let IpCount = this.props.dataItem.IPCount;
        let field = this.props.field;

        if (IpCount && IpCount > 0 && field === 'IPCount') {
          return (
            <td>
              <Popup
                trigger={open => (
                  <a tabIndex="0" className="no-btn">{IpCount}</a>
                )}
                onOpen={() => { this.fetchIpList(trunkGroupId) }}
                position="bottom center"
                closeOnDocumentClick
                contentStyle={{
                  padding: '20px', border: 'unset', width: '250px',
                }}
              >
                <span>{this.loadIpList()}</span>
              </Popup>
            </td>
          )
        } else return <td>{IpCount}</td>
      }
    }
    /* Rolled back changes to release CM5469 - restore later */
    class DataLink extends GridCell {

      render() {
        let { TrunkGroupID, LocationAccountNumber, LocationName, StartDate, CountServiceNumbers, IPTrunkGroupServiceId } = this.props.dataItem;

        switch (this.props.field) {
          case "TrunkGroupID":
            return (
              <td>
                <a onClick={() => { transitionToManagePage(TrunkGroupID, LocationName, StartDate) }} className='no-btn'>{TrunkGroupID}</a>
              </td>
            );
          case "LocationName":
            return (
              <td>
                <div style={{ "whiteSpace": "-moz-pre-wrap", "wordWrap": "break-word", "wordBreak": "break-word" }}>
                  <a
                    onClick={() => { transitionToManagePage(TrunkGroupID, LocationName, StartDate) }}
                    className='no-btn'
                  >
                    {LocationName}
                  </a>
                </div>
              </td>
            );
          case "CountServiceNumbers":
            if (CountServiceNumbers > 0) {
              let path = `/services/${LocationAccountNumber}/ALL_SERVICES/${IPTrunkGroupServiceId}`;
              let link = <Link className='no-btn' to={{
                pathname: path,
                TrunkGroupServiceId: IPTrunkGroupServiceId,
                serviceUserFilter: serviceFilters.IP_TRUNK_ASSIGNED_DID
              }} onClick={() => sessionStorage.setItem('AssignedDIDTrunkID', TrunkGroupID)}>
                {CountServiceNumbers}
              </Link>
              return <td>{link}</td>
            } else {
              return <td>{CountServiceNumbers}</td>
            }
          case "Detailed_Logs":
            return (
              <td>
                <a onClick={() => transitionToDetailedCallLogs(LocationAccountNumber, TrunkGroupID)} className='no-btn'>Call Logs</a>
              </td>
            );
          default:
            return (
              <td>
                <a onClick={() => { transitionToManagePage(TrunkGroupID, LocationName, StartDate) }} className="icon btn">
                  <svg width="14" height="14" viewBox="0 0 24 24"><path d="m163 440l-91-91 251-250 90 90z m309-352l-48-48c-12-11-32-11-45 2l-45 45 91 91 45-45c13-13 13-33 2-45z m-408 275l-32 117 117-32z" transform="scale(0.046875 0.046875)"></path></svg>
                </a>
              </td>
            )
        }
      }
    }
    
    class IconCell extends GridCell {
      constructor(props) {
        super(props);
      }
      render() {
        let { TrunkGroupID, LocationName, StartDate } = this.props.dataItem;
        return (
          <td>
            <a onClick={() => { transitionToManagePage(TrunkGroupID, LocationName, StartDate) }} className="icon btn">
              <svg width="14" height="14" viewBox="0 0 24 24"><path d="m163 440l-91-91 251-250 90 90z m309-352l-48-48c-12-11-32-11-45 2l-45 45 91 91 45-45c13-13 13-33 2-45z m-408 275l-32 117 117-32z" transform="scale(0.046875 0.046875)"></path></svg>
            </a>
          </td>
        )
      }
    }
    this.IconCell = IconCell;
    this.DataLink = DataLink;
    this.IPCountCell = IPCountCell;
  }

  componentDidMount() {
    let hasIpTrunk = sessionStorage.hasIpTrunk;
    if (hasIpTrunk === 'true') {
      this.fetchTrunkList();
    } else {
      this.props.history.push('/');
    }
  }

  trunkIDtoString = (data) => {
    data.forEach(itm => {
      itm.StartDate = new Date(itm.StartDate);
      itm.TrunkGroupID = itm.TrunkGroupID.toString();
    });
    return data;
  }

  fetchTrunkList = async () => {
    await this.setState({ loading: true });
    let apiPath = 'admin/GetIpTrunkingList';
    await apiCalls.fetchData(apiPath).then((data) => {
      if (data) {
        // convert trunkid to string for grid search ability
        let newData = this.trunkIDtoString(data);
        this.setState({
          Listings: newData,
          FullListings: newData,
          loading: false,
        });
      } else {
        this.setState({
          Listings: [],
          FullListings: [],
          loading: false,
        });
      }
    }).catch(error => console.error(error));
  }

  transitionToManagePage = (trunkId, LocationName, StartDate) => {
    sessionStorage.setItem('IPTrunkLocationName', LocationName); // creates values used in Manage IP Trunk form
    sessionStorage.setItem('IPTrunkStartDate', moment(StartDate).format("M-DD-YYYY hh:mm:ss A"));
    this.props.history.push(`/manage_ip_trunking/${trunkId}`);
  }

  transitionToDetailedCallLogs = (LocationAccountNumber, TrunkID) => {
    this.props.history.push(`/ip_detailed_call_logs/${LocationAccountNumber}/${TrunkID}`);
  }

  sortChange = (event) => {
    this.setState({
      Listings: orderBy(this.state.Listings, event.sort),
      Sort: event.sort
    });
  }

  searchListings = (event) => {
    let searchText = event.target.value.trim();
    let fullDetails = this.state.FullListings;
    if (searchText !== '') {
      searchText = event.target.value;
      let services = filterBy(fullDetails, {
        logic: 'or',
        filters: [
          { field: 'TrunkGroupID', operator: 'contains', value: searchText, ignoreCase: true },
          { field: 'TrunkName', operator: 'contains', value: searchText, ignoreCase: true },
          { field: 'LocationName', operator: 'contains', value: searchText, ignoreCase: true },
        ]
      });
      this.delayedSearch(services);
    }
    else {
      this.delayedSearch(fullDetails);
    }
  }

  delayedSearch = async (services) => {
    await this.setState({ loading: true });
    if (this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.setState({ Listings: services, loading: false });
    }, settings.searchDelay);
  }

  gridContent = () => {
    if (this.state.loading) {
      return <DataLoadingSpinner className='load spinner' />
    } else {
      if (this.state.Listings && this.state.Listings.length > 0) {
        return (
          <Grid
            style={{
              height: `${this.state.gridHeight}px`,
            }}
            data={orderBy(this.state.Listings, this.state.Sort)}
            sortable={{ allowUnsort: true, mode: 'single' }}
            sort={this.state.Sort}
            onSortChange={this.sortChange}
          >
            <Column width="120px" field='TrunkGroupID' title='Trunk ID' cell={this.DataLink} />
            <Column width="120px" field='TrunkName' title='Trunk Name' cell={WrappedCell} />
            <Column width="140px" field='LocationName' title='Location' cell={this.DataLink} />
            <Column width="180px" field='StartDate' format="{0:MM/dd/yyyy hh:mm:ss a}" title='Start Date' />
            <Column width="140px" field='IPAddress' title='IP Address 1' />
            <Column width="120px" field='IPCount' title='IP Count' cell={this.IPCountCell} />
            <Column width="120px" field='CountServiceNumbers' title="Assigned DID's" cell={this.DataLink} />
            <Column width="140px" field='GroupPolicy' title='Group Policy' />
            <Column width="120px" field='ServiceState' title='Service State' />
            <Column width="120px" field='Direction' title='Direction' />
            <Column width='120px' field='Detailed_Logs' title=' ' cell={this.DataLink} />
            <Column width="60px" cell={this.DataLink} sortable={false} title='' />
            <Column title=' ' field='filler' sortable={false} />
          </Grid>
        )
      }
      if (this.state.Listings && this.state.Listings.length === 0) {
        return <p>No Available Data</p>
      }
    }
  }

  render() {
    return (
      <div className="ip-trunk-page">
        <div className="container full-card">
          <div className="ip-trunk card">
            <div className="card-header">
              <h1>IP Trunking</h1>
              <div className="card-actions">
                <div className="search">
                  <form ref={'searchTerm'} onSubmit={e => { e.preventDefault() }}>
                    <label htmlFor="services_srch_tb" className="hidden">services search</label>
                    <input id="services_srch_tb" ref={'searchTerm'} type="text" className="form-control search-control"
                      autoComplete={"off"}
                      onChange={this.searchListings}
                    />
                    <svg width="14" height="14" viewBox="0 0 23 23"><path d="m347 238c0-36-12-66-37-91-25-25-55-37-91-37-35 0-65 12-90 37-25 25-38 55-38 91 0 35 13 65 38 90 25 25 55 38 90 38 36 0 66-13 91-38 25-25 37-55 37-90z m147 237c0 10-4 19-11 26-7 7-16 11-26 11-10 0-19-4-26-11l-98-98c-34 24-72 36-114 36-27 0-53-5-78-16-25-11-46-25-64-43-18-18-32-39-43-64-10-25-16-51-16-78 0-28 6-54 16-78 11-25 25-47 43-65 18-18 39-32 64-43 25-10 51-15 78-15 28 0 54 5 79 15 24 11 46 25 64 43 18 18 32 40 43 65 10 24 16 50 16 78 0 42-12 80-36 114l98 98c7 7 11 15 11 25z" transform="scale(0.046875 0.046875)"></path></svg>
                  </form>
                </div>
              </div>
              <div style={{ marginTop: '8px', textAlign: 'right' }}>
                <ManageTrunkModal TrunkGroups={this.state.Listings} loadTrunks={this.fetchTrunkList} />&nbsp;|&nbsp;
                  <a onClick={() => this.props.history.push(`/ip_location_call_logs`)}>Call Logs for Location</a>
              </div>
            </div>
            <div className="card-content">
              {this.gridContent()}
            </div>
          </div>
        </div>
      </div>
    )
  }
};