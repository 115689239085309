import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { Button } from '@progress/kendo-buttons-react-wrapper';


class LocationManageUsersModal extends Component {

  constructor(props) {
    super(props);

    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.state = { showModal:false };
  }

  handleClose() {
    this.setState({ showModal: false });
  }

  handleShow() {
    this.setState({ showModal: true });
  }

  componentDidMount() {   
  }

  render() {
    return (
      <div className="setting">
        <span onClick={this.handleShow}> 
          <span className="name">Manage Users</span>
          <a className="icon btn"><svg width="14" height="14" viewBox="0 0 24 24"><path d="m163 440l-91-91 251-250 90 90z m309-352l-48-48c-12-11-32-11-45 2l-45 45 91 91 45-45c13-13 13-33 2-45z m-408 275l-32 117 117-32z" transform="scale(0.046875 0.046875)"></path></svg></a>
        </span>
        <Modal show={this.state.showModal} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Manage Users</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <p>Edit existing user and service permissions, password recovery email and change portal login passwords. Add and Delete portal administrators.</p>
            </div>
            <div id="announcementRepositoryModal">
              <table className="table">
                <thead>
                  <tr>
                    <th className="check"><input type="checkbox" /></th>
                    <th>User Name</th>
                    <th>Main Account</th>
                    <th>Admin</th>
                    <th>Password Recovery Email</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td><input type="checkbox" /></td>
                    <td>JaredMalan</td>
                    <td>Yes</td>
                    <td>Yes</td>
                    <td>j@momentum.com</td>
                    <td align="right"><a className="icon btn" data-toggle="collapse" data-target="#ar1"><svg width="14" height="14" viewBox="0 0 24 24"><path d="m163 440l-91-91 251-250 90 90z m309-352l-48-48c-12-11-32-11-45 2l-45 45 91 91 45-45c13-13 13-33 2-45z m-408 275l-32 117 117-32z" transform="scale(0.046875 0.046875)"></path></svg></a></td>
                  </tr>
                  <tr className="collapse" id="ar1">
                    <td colSpan="6">
                      <div className="table-edit">
                        <div className="form-group">
                          <label>User Name</label>
                          <input type="type" className="form-control" defaultValue="" />
                        </div>
                        <div className="form-group">
                          <label>Password</label>
                          <input type="password" className="form-control" defaultValue="" />
                        </div>
                        <div className="form-group">
                          <label>Re-enter Password</label>
                          <input type="password" className="form-control" defaultValue="" />
                        </div>
                        <div className="form-group">
                          <label>Password Recovery Email</label>
                          <input type="type" className="form-control" defaultValue="" />
                        </div>
                        <hr />
                        <div className="btns">
                          <a className="btn">Save</a>
                          <a className="btn">Cancel</a>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td><input type="checkbox" /></td>
                    <td>JaredMalan</td>
                    <td>Yes</td>
                    <td>Yes</td>
                    <td>j@momentum.com</td>
                    <td align="right"><a className="icon btn" data-toggle="collapse" data-target="#ar2"><svg width="14" height="14" viewBox="0 0 24 24"><path d="m163 440l-91-91 251-250 90 90z m309-352l-48-48c-12-11-32-11-45 2l-45 45 91 91 45-45c13-13 13-33 2-45z m-408 275l-32 117 117-32z" transform="scale(0.046875 0.046875)"></path></svg></a></td>
                  </tr>
                  <tr className="collapse" id="ar2">
                    <td colSpan="6">
                      <div className="table-edit">
                        <div className="form-group">
                          <label>User Name</label>
                          <input type="type" className="form-control" defaultValue="" />
                        </div>
                        <div className="form-group">
                          <label>Password</label>
                          <input type="password" className="form-control" defaultValue="" />
                        </div>
                        <div className="form-group">
                          <label>Re-enter Password</label>
                          <input type="password" className="form-control" defaultValue="" />
                        </div>
                        <div className="form-group">
                          <label>Password Recovery Email</label>
                          <input type="type" className="form-control" defaultValue="" />
                        </div>
                        <hr />
                        <div className="btns">
                          <a className="btn">Save</a>
                          <a className="btn">Cancel</a>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td><input type="checkbox" /></td>
                    <td>JaredMalan</td>
                    <td>Yes</td>
                    <td>Yes</td>
                    <td>j@momentum.com</td>
                    <td align="right"><a className="icon btn" data-toggle="collapse" data-target="#ar3"><svg width="14" height="14" viewBox="0 0 24 24"><path d="m163 440l-91-91 251-250 90 90z m309-352l-48-48c-12-11-32-11-45 2l-45 45 91 91 45-45c13-13 13-33 2-45z m-408 275l-32 117 117-32z" transform="scale(0.046875 0.046875)"></path></svg></a></td>
                  </tr>
                  <tr className="collapse" id="ar3">
                    <td colSpan="6">
                      <div className="table-edit">
                        <div className="form-group">
                          <label>User Name</label>
                          <input type="type" className="form-control" defaultValue="" />
                        </div>
                        <div className="form-group">
                          <label>Password</label>
                          <input type="password" className="form-control" defaultValue="" />
                        </div>
                        <div className="form-group">
                          <label>Re-enter Password</label>
                          <input type="password" className="form-control" defaultValue="" />
                        </div>
                        <div className="form-group">
                          <label>Password Recovery Email</label>
                          <input type="type" className="form-control" defaultValue="" />
                        </div>
                        <hr />
                        <div className="btns">
                          <a href="" className="btn">Save</a>
                          <a href="" className="btn">Cancel</a>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td><input type="checkbox" /></td>
                    <td>JaredMalan</td>
                    <td>Yes</td>
                    <td>Yes</td>
                    <td>j@momentum.com</td>
                    <td align="right"><a className="icon btn" data-toggle="collapse" data-target="#ar4"><svg width="14" height="14" viewBox="0 0 24 24"><path d="m163 440l-91-91 251-250 90 90z m309-352l-48-48c-12-11-32-11-45 2l-45 45 91 91 45-45c13-13 13-33 2-45z m-408 275l-32 117 117-32z" transform="scale(0.046875 0.046875)"></path></svg></a></td>
                  </tr>
                  <tr className="collapse" id="ar4">
                    <td colSpan="6">
                      <div className="table-edit">
                        <div className="form-group">
                          <label>User Name</label>
                          <input type="type" className="form-control" defaultValue="" />
                        </div>
                        <div className="form-group">
                          <label>Password</label>
                          <input type="password" className="form-control" defaultValue="" />
                        </div>
                        <div className="form-group">
                          <label>Re-enter Password</label>
                          <input type="password" className="form-control" defaultValue="" />
                        </div>
                        <div className="form-group">
                          <label>Password Recovery Email</label>
                          <input type="type" className="form-control" defaultValue="" />
                        </div>
                        <hr />
                        <div className="btns">
                          <a href="" className="btn">Save</a>
                          <a href="" className="btn">Cancel</a>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                  	<td colSpan="6">
                        <div className="btns">
                          <a className="btn" data-toggle="collapse" data-target="#ar4">Add a User</a>
                        </div>
                  	</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Modal.Body>
          <Modal.Footer>
              <hr />
              <div className="btns">
              <Button className="btn" click={this.handleClose}>Close</Button>
              </div>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
};

export default LocationManageUsersModal;
