import React, { Component, Fragment } from "react";
import MsTeamsConfig from "./msteams_config";
import MsTeamsUserMenu from "./msteams_menu";
import MsTeamsSetupDomain from "./msteams_setup_domain";
import MsTeamsDNS from "./msteams_dns";
import MsTeamsSBC from "./msteams_sbc";
import MsTeamsTempUsers from "./msteams_temp";
import MsTeamsDirectRouting from "./msteams_dr";
import MigrationTab from "./migration";
import MsTeamsRoutingMenu from './msteams_routing_menu';
import MsTeamsTenantInfo from "./msteams_tenantinfo";
import { DataLoadingSpinner } from "../../components/spinners";
import * as apiCalls from "../../requests/api_calls";
import { userIsAdmin } from "../../scripts/helper";

export default class MsTeamsMain extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      Enterprise: sessionStorage.userEnterpriseNumber,
      tab: sessionStorage.msteamstabName || "config" || "tenant",
      CurrentTrunk: {},
      MSTeamsConfig: null,
      MSTeamsConfigCopy: {},
      showConfirmationModal: false,
      showDirectRoutingModal: false,
      Trunks: [],
      TeamsUsers: [],
      CustomerName: "",
      DNSTicketDetails: null,
      SBCTicketDetails: null,
      TabRedirect: true,
      showUserList: false,
      routingType: sessionStorage.MSTeamsRoutingType == "DIRECT" ? "Direct Routing" : "Derived Routing",
      CheckStatus: false,
      gridHeight: (window.innerHeight - 180),
      VoiceRoutesList:[],
      VoicePoliciesList:[]
      //routingType: "Derived Routing",
      //migrationStatus: false,
    };
  }

  async componentDidMount() {
    if (userIsAdmin()) {
      await this.fetchTrunks();
      await this.fetchMSTeamsConfigInfo();
      
      //await this.fetchStatus();
    }
  }

  async componentDidUpdate(prevProps, prevState) {
      if (!sessionStorage.msteamstabName && prevState.tab !== this.state.tab)
      if (this.state.tab === "data") {
        await this.setState({ tab: "data" });
      }
  }

  // fetchStatus = async () => {
  //   let path = "admin/IsReadyForDerivedRoutingMigration";
  //   await apiCalls.fetchData(path).then((data) => {
  //     this.setState({
  //       migrationStatus: data && data == 1 ? true : false,
  //     });
  //   }).catch(err => console.error(err));
  // }

  fetchBillingStat = async () => {
    await this.setState({ loading: true });
    let path = "admin/BillingStat";
    await apiCalls
      .fetchData(path)
      .then((data) => {
        if (data) {
          this.setState({
            CustomerName: data.CustomerName,
            loading: false,
          });
        } else {
          this.setState({
            CustomerName: "",
            loading: false,
          });
        }
      })
      .catch((err) => console.error(err));
  };

  fetchTrunks = async () => {
    await this.setState({ loading: true });
    let path = `admin/EnterpriseMSTrunks`;
    await apiCalls
      .fetchData(path)
      .then((data) => {
        if (data) {
          this.setState({
            Trunks: data,
            CurrentTrunk: data[0],
          });
        } else {
          this.setState({
            Trunks: [],
            CurrentTrunk: {},
            loading: false,
          });
        }
      })
      .catch((err) => console.error(err));
  };

  fetchDomainList = async () => {
    await this.setState({ loading: true });
    let path = `admin/GetMSTeamsDomain?enterprise=${this.state.Enterprise}&trunkName=${this.state.CurrentTrunk.TrunkName}`;
    await apiCalls.fetchData(path).then(async (data) => {
      if (data) {
        //data.DirectRouting = true; // test direct routing rendering
        this.setState({
          DomainList: data,
          loading: false,
        });
      } else {
        this.setState({
          DomainList: [],
          loading: false,
        });
      }
    }).catch(err => console.error(err));
  }

  fetchMSTeamsConfigInfo = async () => {
    await this.setState({ loading: true });
    let path = `admin/MSTeamsConfig?EnterpriseTrunkId=${this.state.CurrentTrunk.TrunkId}`;
    await apiCalls
      .fetchData(path)
      .then(async (data) => {
        if (data) {
          let copy = JSON.parse(JSON.stringify(data));
          //data.DirectRouting = true; // test Derived routing rendering
          this.setState({
            MSTeamsConfig: data,
            MSTeamsConfigCopy: copy,
            PrimaryGateWay: data.PstnPrimaryGateway,
            SecondaryGateWay: data.PstnSecondaryGateway,
            loading: false,
          });
          await this.fetchMSTeamsUserList();
          await this.fetchDomainList();
          await this.fetchBillingStat();
          await this.fetchListings();
          await this.fetchListingsForVoicePolicies();
          if (!sessionStorage.msteamstabName && this.state.TabRedirect) {
            if (this.state.MSTeamsConfig.ConfigProcessCompleted) {
              this.setState({ tab: "data" });
            }
          }
          //this.setState({ loading: false })
        } else {
          this.setState({
            MSTeamsConfig: null,
            MSTeamsConfigCopy: null,
            PrimaryGateWay: "",
            SecondaryGateWay: "",
            loading: false,
          });
        }
      })
      .catch((err) => console.error(err));
  };

  fetchMSTeamsUserList = async () => {
    await this.setState({ loading: true });
    let path = `admin/MSTeamsUsers`;
    await apiCalls
      .fetchData(path)
      .then((data) => {
        if (data) {
          this.setState({
            TeamsUsers: data,
            FullListings: data,
            loading: false,
          });
        } else {
          this.setState({
            TeamsUsers: [],
            FullListings: [],
            loading: false,
          });
        }
      })
      .catch((err) => console.error(err));
  };

  fetchListings = async () => {
    await this.setState({ loading: true });
    let path = `admin/GetCsOnlineVoiceRoute?enterprise=${this.state.Enterprise}&trunkName=${this.state.CurrentTrunk.TrunkName}`;
    await apiCalls.fetchData(path).then(async (data) => {
        if (data) {
            this.setState({
                VoiceRoutesList: JSON.parse(data),
                loading: false,
            });
        } else {
            this.setState({
                VoiceRoutesList: [],
                loading: false,
            });
        }
    }).catch(err => console.error(err));
}

fetchListingsForVoicePolicies = async () => {
  await this.setState({ loading: true });
  let path = `admin/GetVoiceRoutePolicies`;
  await apiCalls.fetchData(path).then(async (data) => {
      if (data) {
          this.setState({
              VoicePoliciesList: data,
              loading: false,
          });
      } else {
          this.setState({
            VoicePoliciesList: [],
              loading: false,
          });
      }
  }).catch(err => console.error(err));
}

  btnField = (description, tabName) => {
    return (
      <button
        className={this.state.tab === tabName ? "wizard active" : "wizard"}
        type="button"
        onClick={() => this.changeTab(tabName)}
        disabled={this.state.loading}
        style={{
          padding: "5px 10px",
          margin: "2px",
          color: this.state.tab === tabName ? "black" : "#6c8bad",
          borderRadius: "10px",
          border:
            this.state.tab === tabName
              ? "1px solid black"
              : "1px solid #6c8bad",
        }}
      >
        {" "}
        {description}
      </button>
    );
  };

  tabDisplay = () => {
    let { loading, MSTeamsConfig } = this.state;

    if (MSTeamsConfig) {
      let { ConfigProcessCompleted, AllowManualDerivedRoutingMigration } = MSTeamsConfig;
      return (
        <div
          className="btn-group"
          role="group"
          aria-label="Basic example"
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          {this.btnField("Connect to Microsoft 365", "config")}
          {this.state.routingType == "Derived Routing" ? this.btnField("Setup Domain", "domain") : null}
          {this.state.routingType == "Derived Routing" ? this.btnField("DNS Configuration", "dns") : null}
          {this.btnField("Setup Temp Users", "temp")}
          {this.state.routingType == "Derived Routing" ? this.btnField(this.state.routingType, "dr") : null}
          {this.btnField("User List", "data")}
          {AllowManualDerivedRoutingMigration ? this.btnField("Migrate Tenant to Derived Routing", "migrate") : null}
          {sessionStorage.RPXLogin === "true" ?this.btnField("Tenant Info", "tenant"): null}
        </div>
      );
    }
  };

    changeTab = (tabName) => {
      sessionStorage.setItem('msteamstabName', tabName);
      this.setState({ tab: tabName });
    };

  proceedToNextTab = (value) => {
    this.setState({ tab: value });
  };

  removeTabRedirectOnSave = async (value) => {
    await this.setState({ TabRedirect: value });
  };

  getTab() {
    switch (this.state.tab) {
      case "config":
        return (
          <div>
          <MsTeamsConfig
            MSTeamsConfig={this.state.MSTeamsConfig}
            loading={this.state.loading}
            Trunk={this.state.CurrentTrunk}
            Enterprise={this.state.Enterprise}
            Proceed={this.proceedToNextTab}
            RefreshData={this.fetchMSTeamsConfigInfo}
            PrimaryGateWay={this.state.PrimaryGateWay}
            SecondaryGateWay={this.state.SecondaryGateWay}
            removeTabRedirectOnSave={this.removeTabRedirectOnSave}
            EnterpriseTrunkId={this.state.CurrentTrunk.TrunkId}
            routingType={this.state.routingType}
          />
          </div>
        );
      case "domain":
        return (
          <MsTeamsSetupDomain
            MSTeamsConfig={this.state.MSTeamsConfig}
            loading={this.state.loading}
            Trunk={this.state.CurrentTrunk}
            Enterprise={this.state.Enterprise}
            Proceed={this.proceedToNextTab}
            RefreshData={this.fetchMSTeamsConfigInfo}
            PrimaryGateWay={this.state.PrimaryGateWay}
            SecondaryGateWay={this.state.SecondaryGateWay}
            routingType={this.state.routingType}
          />
        );
      case "dns":
        return (
          <MsTeamsDNS
            MSTeamsConfig={this.state.MSTeamsConfig}
            loading={this.state.loading}
            Trunk={this.state.CurrentTrunk}
            Enterprise={this.state.Enterprise}
            Proceed={this.proceedToNextTab}
            RefreshData={this.fetchMSTeamsConfigInfo}
            CustomerName={this.state.CustomerName}
            PrimaryGateWay={this.state.PrimaryGateWay}
            SecondaryGateWay={this.state.SecondaryGateWay}
            routingType={this.state.routingType}
          />
        );
      case "sbc":
        return (
          <MsTeamsSBC
            MSTeamsConfig={this.state.MSTeamsConfig}
            loading={this.state.loading}
            Trunk={this.state.CurrentTrunk}
            Enterprise={this.state.Enterprise}
            Proceed={this.proceedToNextTab}
            RefreshData={this.fetchMSTeamsConfigInfo}
            CustomerName={this.state.CustomerName}
            PrimaryGateWay={this.state.PrimaryGateWay}
            SecondaryGateWay={this.state.SecondaryGateWay}
            routingType={this.state.routingType}
          />
        );
      case "temp":
        return (
          <MsTeamsTempUsers
            MSTeamsConfig={this.state.MSTeamsConfig}
            loading={this.state.loading}
            Trunk={this.state.CurrentTrunk}
            Enterprise={this.state.Enterprise}
            Proceed={this.proceedToNextTab}
            RefreshData={this.fetchMSTeamsConfigInfo}
            PrimaryGateWay={this.state.PrimaryGateWay}
            SecondaryGateWay={this.state.SecondaryGateWay}
            routingType={this.state.routingType}
          />
        );
      case "dr":
        return (
          <MsTeamsDirectRouting
            MSTeamsConfig={this.state.MSTeamsConfig}
            loading={this.state.loading}
            Trunk={this.state.CurrentTrunk}
            Enterprise={this.state.Enterprise}
            Proceed={this.proceedToNextTab}
            RefreshData={this.fetchMSTeamsConfigInfo}
            PrimaryGateWay={this.state.PrimaryGateWay}
            SecondaryGateWay={this.state.SecondaryGateWay}
            routingType={this.state.routingType}
          />
        );
      case "data":
        //let userList = this.state.TeamsUsers.filter(itm => itm.ApplicationName === null);;
        return (
          <MsTeamsUserMenu
            MSTeamsConfig={this.state.MSTeamsConfig}
            loading={this.state.loading}
            Trunk={this.state.CurrentTrunk}
            Enterprise={this.state.Enterprise}
            TeamsUsers={this.state.TeamsUsers}
            Proceed={this.proceedToNextTab}
            RefreshData={this.fetchMSTeamsConfigInfo}
            routingType={this.state.routingType}
            DomainList={this.state.DomainList}
            removeTabRedirectOnSave={this.removeTabRedirectOnSave}
          // userListShow={this.userListShow()}
          />
        );
      case "resource":
        let routingList = this.state.TeamsUsers.filter(itm => itm.ApplicationName !== null);
        return (
          <MsTeamsRoutingMenu
            MSTeamsConfig={this.state.MSTeamsConfig}
            loading={this.state.loading}
            Trunk={this.state.CurrentTrunk}
            Enterprise={this.state.Enterprise}
            TeamsUsers={routingList}
            Proceed={this.proceedToNextTab}
            RefreshData={this.fetchMSTeamsConfigInfo}
            DomainList={this.state.DomainList}
            removeTabRedirectOnSave={this.removeTabRedirectOnSave}
          />
        );
      case "migrate":
        return (
          <MigrationTab
            MSTeamsConfig={this.state.MSTeamsConfig}
            loading={this.state.loading}
            Trunk={this.state.CurrentTrunk}
            Enterprise={this.state.Enterprise}
            Proceed={this.proceedToNextTab}
            RefreshData={this.fetchMSTeamsConfigInfo}
            PrimaryGateWay={this.state.PrimaryGateWay}
            SecondaryGateWay={this.state.SecondaryGateWay}
            routingType={this.state.routingType}
            CheckStatus={this.state.CheckStatus}
            CheckConfig={this.checkStatus}
          />
        );
        case "tenant":
        return (
          <MsTeamsTenantInfo
            MSTeamsConfig={this.state.MSTeamsConfig}
            VoiceRoutesList ={this.state.VoiceRoutesList}
            VoicePoliciesList={this.state.VoicePoliciesList}
            loading={this.state.loading}
            Trunk={this.state.CurrentTrunk}
            Enterprise={this.state.Enterprise}
            Proceed={this.proceedToNextTab}
            RefreshData={this.fetchMSTeamsConfigInfo}
            CustomerName={this.state.CustomerName}
            PrimaryGateWay={this.state.PrimaryGateWay}
            SecondaryGateWay={this.state.SecondaryGateWay}
            routingType={this.state.routingType}
          />
        );
      default:
        console.log("Sorry there isn't a tab named " + this.state.tab + ".");
    }
  }

  checkStatus = async () => {
    await this.fetchMSTeamsConfigInfo();
    this.setState({ tab: "migrate", CheckStatus: true });
  }

  cardContent = () => {
    if (!this.state.loading) {
      return (
        <Fragment>
          <div className="card-content">
            <div className="tab-body">{this.getTab()}</div>
          </div>
        </Fragment>
      );
    } else {
      return (
        <div>
          <DataLoadingSpinner
            style={{ marginTop: "5%" }}
            className="load spinner"
          />
        </div>
      );
    }
  };
  servicesCardInlineStyle = () => {
    // offset by 30 to account for missing table headers
    if (this.state.loading) return { height: this.state.gridHeight + 100 + 'px' }
  }
  render() {
    return (
      <div className="sync-menu-container">
        <div className="container full-card">
          <div className="sync-menu card">
            <div className="card-header">
              <h1>Microsoft Teams</h1>
            </div>
            {this.tabDisplay()}
            {this.cardContent()}
          </div>
        </div>
      </div>
    );
  }
}