import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { Button } from '@progress/kendo-buttons-react-wrapper';
import _ from 'lodash';
import { DataLoadingSpinner } from '../../../components/spinners';
import * as apiCalls from '../../../requests/api_calls';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'; // ES6
import PropTypes from 'prop-types';
import InputMask from 'react-input-mask';

class AddEditEnterpriseContact extends Component {

  constructor(props) {
    super(props);
    let canEdit = false;
    // if (props.AddEditMode === 'Add')
    //   canEdit = true;
    // else if (props.ContactDetails)
    //   canEdit = props.ContactDetails.CanEdit;

    this.state = {
      isAdd: props.AddEditMode === 'Add' ? true : false,
      saving: false,
      ContactDetails: {
        UserId: sessionStorage.SelectedUserId ? sessionStorage.SelectedUserId : 0,
        EnterpriseId: props.ContactDetails && props.ContactDetails.EnterpriseId ? props.ContactDetails.EnterpriseId : 0,
        Name: props.ContactDetails && props.ContactDetails.Name ? props.ContactDetails.Name : '',
        OldName: props.ContactDetails && props.ContactDetails.OldName ? props.ContactDetails.OldName : '',
        TN: props.ContactDetails && props.ContactDetails.TN ? props.ContactDetails.TN : '',
        SpeedDialCode: props.ContactDetails && props.ContactDetails.SpeedDialCode ? props.ContactDetails.SpeedDialCode : '',
        OldSpeedDialCode: props.ContactDetails && props.ContactDetails.OldSpeedDialCode ? props.ContactDetails.OldSpeedDialCode : '',
        //CanEdit: canEdit
      },
      SpeedDialCodes: this.props.SpeedDialCodes && this.props.SpeedDialCodes.length > 0 ? this.props.SpeedDialCodes : [],
      SpeedDialEnabled: props.ContactDetails && props.ContactDetails.SpeedDialCode ? true : false,
      showModal: false,
      savingShowSuccess: false,
      savingShowError: false,
      saveResultsMessage: '',
      adding: false
    };
    this.handleOnSave = this.handleOnSave.bind(this);
    this.callUpdateFromModal = this.callUpdateFromModal.bind(this);
  }

  componentWillReceiveProps(props) {
    if (props) {
      if (this.state.SpeedDialCodes !== props.SpeedDialCodes) {
        let newSpeedDials = props.SpeedDialCodes ? props.SpeedDialCodes.slice() : [];
        if (this.state.ContactDetails.OldSpeedDialCode !== '') {
          newSpeedDials.push(this.state.ContactDetails.OldSpeedDialCode);
          newSpeedDials.sort();
        }
        this.setState({ SpeedDialCodes: newSpeedDials });
      }
    }
  };

  getBlankContactDetails = () => {
    return {
      UserId: parseInt(sessionStorage.SelectedUserId), //=> should be able to pull from 'selectedUserId' in sessionStorage, do not use UserId b/ c thats the logged in user
      EnterpriseId: 0, //(just hardcode),
      TN: "", //=> phoneNumber
      OldName: "", //(hardcode to blank)
      Name: "", //=> from Name field
      OldSpeedDialCode: "", //(this can be blank too)
      SpeedDialCode: "",
    };
  }

  showModal = () => {
    this.setState({ showModal: true });
  }

  handleClose = () => {
    let state = this.state;
    if (state.isAdd) {
      state.ContactDetails = this.getBlankContactDetails();
      state.SpeedDialEnabled = false;
    }
    state.showModal = false;
    state.savingShowError = false;
    state.savingShowSuccess = false;
    this.setState(state);
  }

  handleOnChange = (field, e) => {
    let state = { ...this.state };
    state.ContactDetails[field] = e.target.value;
    this.setState(state);
  }

  handleOnStateChange = (e) => {
    let state = this.state;
    state.ContactDetails.State = e.target.value;
    this.setState(state);
  }

  callUpdateFromModal = (newContactModel) => {
    this.props.UpdateParentFromModal(newContactModel, this.state.adding);
  }

  handleOnSave = () => {
    if (this.state.ContactDetails.Name.toString().trim() === '') {
      this.setState({ savingShowError: true, saveResultsMessage: 'A Name must be provided!' })
      return;
    }
    if (this.state.ContactDetails.TN.toString().trim() === '') {
      this.setState({ savingShowError: true, saveResultsMessage: 'A TN must be provided!' })
      return;
    }
    this.setState({ saving: true, adding: (this.state.ContactDetails.Name && this.state.ContactDetails.OldName === '') ? true : false });
    let apiPath = '';
    let verb = '';
    let requestData = {};
    // ***** 
    let { Name, TN, SpeedDialCode, EnterpriseId, UserId, OldName, OldSpeedDialCode } = this.state.ContactDetails;
    let { SelectedUserId } = sessionStorage;
    if (this.state.ContactDetails.OldName === '') {
      // ADD NEW CONTACT
      apiPath = 'ContactBook/AddContactToUserContactBook';
      verb = 'POST'
      requestData = {
        UserId: parseInt(SelectedUserId), //=> should be able to pull from 'selectedUserId' in sessionStorage, do not use UserId b/ c thats the logged in user
        EnterpriseId: 0, //(just hardcode),
        TN: TN, //=> phoneNumber
        OldName: "", //(hardcode to blank)
        Name: Name, //=> from Name field
        OldSpeedDialCode: "", //(this can be blank too)
        SpeedDialCode: this.state.SpeedDialEnabled ? SpeedDialCode : "", //(from speed dial dropdown),
        //CanEdit: true,
      };
      //console.log("add dto", requestData);
    } else {
      // EDIT EXISTING CONTACT
      apiPath = 'ContactBook/UpdateContactInUserContactBook';
      verb = 'PATCH';
      requestData = {
        UserId: parseInt(UserId), //=> should be able to pull from 'selectedUserId' in sessionStorage, do not use UserId b/ c thats the logged in user
        EnterpriseId: EnterpriseId, //(just hardcode),
        TN: TN, //=> phoneNumber
        OldName: OldName, //(hardcode to blank)
        Name: Name, //=> from Name field
        OldSpeedDialCode: OldSpeedDialCode, //(this can be blank too)
        SpeedDialCode: this.state.SpeedDialEnabled ? SpeedDialCode : "", //(from speed dial dropdown),
        //CanEdit: true,
      }
      //console.log("edit dto", requestData);
    }
    apiCalls.post(apiPath, verb, JSON.stringify(requestData)).then(res => {
      if (res.ok) {
        let newContactModel = JSON.parse(res.message);
        //console.log('New contact', newContactModel);
        this.callUpdateFromModal(newContactModel);
        this.setState({ ContactDetails: newContactModel, savingShowSuccess: true, saving: false });
      } else {
        this.setState({ savingShowError: true, saving: false, saveResultsMessage: 'An error occurred saving Contact in Contact Directory' })
      }
    })
      .catch(message => {
        this.setState({
          saving: false,
          savingShowError: true,
          loading: false,
          saveResultsMessage: 'An error occurred saving Contact in Contact Directory'
        });
      });
  }

  getModalButtons = () => {
    return (
      <div className="btns">
        <Button key="2" className="btn" click={this.handleOnSave}>Save</Button>
        <Button className="btn" click={this.handleClose}>Close</Button>
      </div>
    );
  }

  getEditViewButton = () => {
    // if (this.state.ContactDetails.CanEdit === true)
    return (
      <a className="btn" onClick={this.showModal} style={{ minWidth: "10px" }}>
        <svg width="14" height="14" viewBox="0 0 24 24"><path d="m163 440l-91-91 251-250 90 90z m309-352l-48-48c-12-11-32-11-45 2l-45 45 91 91 45-45c13-13 13-33 2-45z m-408 275l-32 117 117-32z" transform="scale(0.046875 0.046875)"></path></svg>
      </a>
    );
    // else
    // return (
    //   <td>
    //     <a onClick={this.showModal}>View</a>
    //   </td>
    // )
  }

  formatSpeedDialOption = (data) => {
    if (data && data.length > 0) {
      let copy = JSON.parse(JSON.stringify(data));
      return copy.map((itm, idx) => {
        if (itm && itm != undefined) {
          return <option key={idx} value={itm}>{`${itm}`}</option>
        }
      });
    } else return;
  };

  toggleSpeedDial = () => {
    const toggleSetting = async () => {
      await this.setState({ SpeedDialEnabled: !this.state.SpeedDialEnabled });
      if (!this.state.SpeedDialEnabled) {
        let state = { ...this.state };
        state.ContactDetails["SpeedDialCode"] = "";
        this.setState(state);
      }
    }
    if (this.state.SpeedDialCodes && this.state.SpeedDialCodes.length > 1 && this.state.SpeedDialCodes != undefined) {
      return (
        <div className="input-group">
          <div>Enable Speed Dial:</div><hr style={{ margin: "0", display: "block" }} />
          <input type="radio" id="yes" name="SpeedDialSetting" checked={this.state.SpeedDialEnabled} onChange={toggleSetting} />
          <label htmlFor="yes">Yes</label><br />
          <input type="radio" id="no" name="SpeedDialSetting" checked={!this.state.SpeedDialEnabled} onChange={toggleSetting} />
          <label htmlFor="no">No</label><br />
        </div>
      );
    } else return;
  }

  speedDialSetting = () => {
    let current = JSON.parse(JSON.stringify(this.state.ContactDetails.SpeedDialCode));
    let existing = JSON.parse(JSON.stringify(this.state.ContactDetails.OldSpeedDialCode));
    let codes = this.state.SpeedDialCodes;
    if (existing !== '' && _.indexOf(codes, existing) < 0) {
      codes.push(existing);
      codes.sort();
    }

    if (this.state.SpeedDialEnabled && codes && codes.length > 1 && codes != undefined) {
      return (
        <div className="form-group">
          <label style={{ color: '#3a4b5f' }}>Speed Dial:</label>
          <select
            onChange={(e) => this.handleOnChange("SpeedDialCode", e)}
            value={current}
            className="form-control">
            <option value="">Select</option>
            {this.formatSpeedDialOption(codes)}
          </select> <br />
          <div>Note: Some Speed Dial codes conflict with certain Feature Access Codes. See below for a list of conflicting Speed Dial codes that completely over-ride their Feature Access Code functionality when active: 
              <br /><br />
              <div className="">
                  #31 Calling Line ID Delivery Blocking Persistent Deactivation <br />
                  #40 Call Forwarding Busy To Voice Mail Deactivation <br />
                  #41 Call Forwarding No Answer To Voice Mail Deactivation <br />
                  #43 Call Waiting Persistent Deactivation <br />
                  #58 Group Call Park <br />
                  #70 Night Service Activation Manual Override <br />
                  #71 Night Service Deactivation Manual Override<br />
                  #72 Forced Forwarding Activation <br />
                  #73 Forced Forwarding Deactivation <br />
                  #76 Call Forwarding Selective Activation <br />
                  #77 Call Forwarding Selective Deactivation <br />
                  #80 Make Outgoing Call as Call Center <br />
                  #81 Make Personal Outgoing Call <br />
                  #83 Escalate Call to Supervisor<br />
              </div>
            </div>
        </div>
      )
    } else return;
  }

  getSaveMessage = () => {
    if (this.state.saving)
    {
        return (<DataLoadingSpinner className='load spinner'/>)
    } else if (this.state.savingShowError) {
        return (
            <div className="inline inline-fail text-center">
                {this.state.saveResultsMessage}
            </div>
        );
    } else if (this.state.savingShowSuccess) {
        return (
            <ReactCSSTransitionGroup
                transitionName="vm-setting-success"
                transitionAppear={true}
                transitionAppearTimeout={4000}
                transitionEnter={true}
                transitionEnterTimeout={4000}
                transitionLeave={false}>
                <div className="inline-success text-center">Success</div>
            </ReactCSSTransitionGroup>
        );
    } else {
        return null;
    }
}

  render() {
    let { Name, TN, CanEdit, SpeedDialCode } = this.state.ContactDetails;
    if (this.state.isAdd) {
      return (
        <span>
          <div>
            <a onClick={this.showModal} style={{"position":"relative","left":"18px","fontSize":"10px","transition":"none 0s ease 0s","cursor":"move"}} className="orange btn">Add Contact</a>
          </div>
          <Modal show={this.state.showModal} onHide={this.handleClose} dialogClassName='user-contact-modal'>
            <Modal.Header closeButton>
              <Modal.Title>Add Contact</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <ContactTextField id='Name' label="Name:" value={Name} enabled={true} onChange={this.handleOnChange} maxLength={255} />
                <ContactTextField id='TN' label="Phone: " value={TN} enabled={true} onChange={this.handleOnChange} Mask={'999999999999999'}
                //Mask={'(999) 999-9999'}
                />
                {this.toggleSpeedDial()}
                {this.speedDialSetting()}
              </div>
            </Modal.Body>
            <Modal.Footer>
              <hr />
              {this.getModalButtons()}
              {this.getSaveMessage()}
            </Modal.Footer>
          </Modal>
        </span>
      );
    } else {
      let Button = this.getEditViewButton();
      return (
        <span>
          {Button}
          <Modal show={this.state.showModal} onHide={this.handleClose} dialogClassName='enterprise-contact-modal'>
            <Modal.Header closeButton>
              <Modal.Title>Edit Contact</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <ContactTextField id='Name' label="Name:" value={Name} enabled={true} onChange={this.handleOnChange} maxLength={255} />
                <ContactTextField id='TN' label="Phone: " value={TN} enabled={true} onChange={this.handleOnChange} Mask={'999999999999999'}
                //Mask={'(999) 999-9999'}
                />
                {this.toggleSpeedDial()}
                {this.speedDialSetting()}
              </div>
            </Modal.Body>
            <Modal.Footer>
              <hr />
              {this.getModalButtons()}
              {this.getSaveMessage()}
            </Modal.Footer>
          </Modal>
        </span>
      )
    }
  }
}

class ContactTextField extends Component {
  render() {
    if (this.props.Mask && this.props.Mask !== '') {
      return (
        <div className='form-group'>
          <label>{this.props.label}</label>
          <InputMask className="form-control" //disabled={this.props.enabled === true ? "" : "disabled"} 
            value={(this.props.value ? this.props.value : "")}
            mask={this.props.Mask} onChange={e => { if (this.props.enabled === true) this.props.onChange(this.props.id, e) }} />
        </div>
      );
    } else {
      return (
        <div className="form-group">
          <label>{this.props.label}</label>
          <input type="type" maxLength={this.props.maxLength} className="form-control" //disabled={this.props.enabled === true ? "" : "disabled"}
            value={(this.props.value ? this.props.value : "")}
            onChange={e => { if (this.props.enabled === true) this.props.onChange(this.props.id, e) }} />
        </div>
      );
    }
  }
};

ContactTextField.propTypes = {
  label: PropTypes.string.isRequired,
  textvalue: PropTypes.string,
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func
}

export default AddEditEnterpriseContact;