import { useEffect, useState } from "react";

import { EventItem } from "./event-item";
import { EventList } from "./event-list";
import { ADD_EVENT } from "./constants";

export const ScheduleItem = ({ scheduleItem, selectItemToDelete, onUpdateScheduleName }) => {
  const { ScheduleName, ScheduleType, events } = scheduleItem;

  const [newScheduleName, setNewScheduleName] = useState(ScheduleName || "");
  const [showAddEventItemSection, setShowAddEventItemSection] = useState(false);
  const [eventList, setEventList] = useState(events || []);

  const onAddEvent = (event) => {
    const newEventList = [...eventList, event];
    setEventList(newEventList);
    setShowAddEventItemSection(false);
  };

  const closeEventItemSection = () => {
    setShowAddEventItemSection(false);
  };

  useEffect(() => {
    onUpdateScheduleName(newScheduleName);
  }, [newScheduleName]);

  return (
    <>
      <div className="form-group">
        <label>Schedule Name</label>
        <input
          type="text"
          className="form-control"
          value={newScheduleName}
          onChange={(e) => setNewScheduleName(e.target.value)}
        />
      </div>
      <button
        className="btn"
        onClick={() => {
          setShowAddEventItemSection(!showAddEventItemSection);
        }}
      >
        Add Event
      </button>
      {showAddEventItemSection && (
        <table className="table">
          <tbody>
            <EventItem
              type={ADD_EVENT}
              scheduleName={ScheduleName}
              scheduleType={ScheduleType}
              onAddEvent={onAddEvent}
              onCancelAddOrEditEvent={closeEventItemSection}
            />
          </tbody>
        </table>
      )}
      <EventList
        events={eventList}
        selectItemToDelete={selectItemToDelete}
        scheduleItem={scheduleItem}
        onCancelAddOrEditEvent={closeEventItemSection}
      />
    </>
  );
};
