import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { Tooltip } from '@progress/kendo-popups-react-wrapper';
import { Button } from '@progress/kendo-buttons-react-wrapper';
import * as settings from './../../scripts/session';
import * as config from './../../requests/config'

class Voicemail extends Component {

  constructor(props) {
		super(props);
	}

	render() {
		return (
			<tr>
				<td className="new"><div className="circle"></div></td>
				<td className="check">
					<input type="checkbox" checked={this.props.selected} onChange={this.props.handledSelectChanged} />
				</td>
				<td className="play">
					<Tooltip content="Play" position="top"> 
						<a href="#" onClick={this.props.handlePlay}><svg width="12" height="12" viewBox="0 0 23 23"><path d="m405 233l-278-166c-4-2-7-3-11-3-11 0-20 9-20 20l0 0 0 344 0 0c0 11 9 20 20 20 4 0 7-1 11-3l278-166c7-5 11-14 11-23 0-9-4-17-11-23z" transform="scale(0.046875 0.046875)"></path></svg></a>
					</Tooltip>
				</td>
				<td>{this.props.phone}</td>
				<td>{this.props.datetime}</td>
				<td className="actions">
					<Tooltip content="Download" position="top"> 
						<a href="#" onClick={this.props.handleDownload}><svg width="12" height="12" viewBox="0 0 23 23" alt="download"><path d="m478 280c9 15 11 31 7 49c0 0-17 94-17 94c-1 7-4 13-10 18c-6 5-13 8-20 8c0 0-365 0-365 0c-7 0-13-3-19-8c-6-5-10-11-10-18c0 0-18-94-18-94c-3-18 0-34 7-49c0 0 81-192 81-192c8-16 20-23 37-23c0 0 209 0 209 0c17 0 30 7 38 23c0 0 80 192 80 192m-35 67c1-7-1-14-5-19c-4-6-10-9-18-9c0 0-329 0-329 0c-8 0-14 3-18 9c-4 5-6 12-5 19c0 0 7 38 7 38c1 8 4 14 10 19c6 5 13 8 20 8c0 0 302 0 302 0c8 0 14-3 20-8c6-5 9-11 10-19c0 0 6-38 6-38" transform="scale(0.046875 0.046875)"></path></svg></a>
					</Tooltip> 
					<Tooltip content="Send" position="top"> 
						<a href="#" onClick={this.props.handleShowSendModal}><svg width="14" height="12" viewBox="0 0 23 23" alt="send"><path d="m512 183c0 5-2 9-5 13l-147 146c-3 4-8 5-13 5c-5 0-9-1-12-5c-4-4-6-8-6-13l0-73l-64 0c-19 0-35 1-50 2c-15 1-29 3-44 6c-15 3-27 7-38 12c-11 5-21 12-30 20c-9 8-17 18-23 29c-6 11-11 24-14 39c-3 16-5 33-5 52c0 10 1 22 2 35c0 1 0 4 0 7c1 3 1 6 1 7c0 3-1 6-2 8c-2 1-4 2-7 2c-3 0-6-1-8-4c-1-2-3-4-4-7c-1-2-2-5-4-8c-1-4-2-6-3-7c-24-54-36-97-36-129c0-38 5-70 15-95c31-77 114-115 250-115l64 0l0-73c0-5 2-10 6-13c3-4 7-6 12-6c5 0 10 2 13 6l147 146c3 4 5 8 5 13z" transform="scale(0.046875 0.046875)"></path></svg></a>
					</Tooltip>					
				</td>
			</tr>
		);
	}
}

export default Voicemail;