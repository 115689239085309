import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { Button } from '@progress/kendo-buttons-react-wrapper';

class ContactCenterModal extends Component {

  constructor(props) {
    super(props);

    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);

    this.state = { showModal: false };
  }

  handleClose() {
    this.setState({ showModal: false });
  }

  handleShow() {
    this.setState({ showModal: true });
  }

  componentDidMount() {   
  }

  render() {
    return (
      <div className="setting">
        <span onClick={this.handleShow}> 
          <span className="name">Contact Center</span>
          <a className="icon btn"><svg width="14" height="14" viewBox="0 0 24 24"><path d="m163 440l-91-91 251-250 90 90z m309-352l-48-48c-12-11-32-11-45 2l-45 45 91 91 45-45c13-13 13-33 2-45z m-408 275l-32 117 117-32z" transform="scale(0.046875 0.046875)"></path></svg></a>
        </span>

        <Modal show={this.state.showModal} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Contact Center</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="">
              <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Odio ab modi necessitatibus illo, tempore corrupti!</p>
              <hr />
              <p>Contact Center Service Assigned:  <b>Premium</b></p>
              <hr />
              <div className="form-group">
                <label>ACD State</label>
                <select className="form-control">
                  <option defaultValue="">-Select-</option>
                  <option defaultValue="">Sign In</option>
                  <option defaultValue="">Sign Out</option>
                  <option defaultValue="">Available</option>
                  <option defaultValue="">Unavailable</option>
                  <option defaultValue="">Wrap Up</option>
                </select>
              </div>
              <div className="form-group">
                <label className="switch right">
                  <input type="checkbox" data-toggle="collapse" data-target="#cc1" />
                  <span className="slider round"></span>
                </label>
                Make outgoing calls as another number
                <div className="hidden-group collapse" id="cc1">
                  <div className="form-group">
                    <label>Select phone number</label>
                    <select className="form-control">
                      <option defaultValue="">-Select-</option>
                      <option defaultValue="">123 123 1231</option>
                      <option defaultValue="">123 123 1232</option>
                      <option defaultValue="">123 123 1233</option>
                      <option defaultValue="">123 123 1234</option>
                      <option defaultValue="">123 123 1235</option>
                    </select>
                  </div>
                </div>
              </div>

              <div className="form-group">
                <label>Use Guard Timer Setting</label>
                <div className="form-control">
                  <label className="custom-control">
                  <input id="radio1" name="radio" type="radio"/>
                    <span >Default</span>
                  </label> 
                  <label className="custom-control">
                    <input id="radio1" name="radio" type="radio"/>
                    <span>User</span>
                  </label>
                </div>
              </div>
              <div className="form-group">
                <label>Guard timer number of seconds</label>
                <select className="form-control">
                  <option defaultValue="">-Select-</option>
                  <option defaultValue="">1</option>
                  <option defaultValue="">2</option>
                  <option defaultValue="">3</option>
                  <option defaultValue="">4</option>
                  <option defaultValue="">5</option>
                </select>
              </div>

              <div className="form-group">
                <label>Use Agent Unavailable Settings</label>
                <div className="form-control">
                  <label className="custom-control">
                  <input id="radio1" name="radio" type="radio"/>
                    <span >Default</span>
                  </label> 
                  <label className="custom-control">
                    <input id="radio1" name="radio" type="radio"/>
                    <span>User</span>
                  </label>
                </div>
              </div>
              <div className="form-group">
                <label className="switch right">
                  <input type="checkbox" data-toggle="collapse" />
                  <span className="slider round"></span>
                </label>
                Force agent to unavailable on Do Not Disturb activation
              </div>
              <div className="form-group">
                <label className="switch right">
                  <input type="checkbox" data-toggle="collapse" data-target="#cc4" />
                  <span className="slider round"></span>
                </label>
                Force agent to unavailable after consecutive bounced calls
                <div className="hidden-group collapse" id="cc4">
                  <div className="form-group">
                    <label>Number of bounced calls:</label>
                    <select className="form-control">
                      <option defaultValue="">-Select-</option>
                      <option defaultValue="">1</option>
                      <option defaultValue="">2</option>
                      <option defaultValue="">3</option>
                      <option defaultValue="">4</option>
                      <option defaultValue="">5</option>
                    </select>
                  </div>
                </div>
              </div>
              <table className="table">
                <thead>
                  <tr>
                    <th>Join</th>
                    <th>Contact Center ID</th>
                    <th>Phone Number</th>
                    <th>Ext</th>
                    <th>Skill Level</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td><input type="checkbox" /></td>
                    <td>123123123@test.com</td>
                    <td>123 123 1234</td>
                    <td>123</td>
                    <td>10</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Modal.Body>
          <Modal.Footer>
              <hr />
              <div className="btns">
              <Button className="btn" click={this.handleClose}>Close</Button>
              </div>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
};

export default ContactCenterModal;
