import React, { useEffect, useState } from 'react';

export default function MfaTooltip() {
  const [isVisible, setIsVisible] = useState(true);

  //if (sessionStorage.enableMFA == "true" && sessionStorage.MFAOption === "Optional") {
  if (isVisible && sessionStorage.enableMFA == "true" && sessionStorage.MFAOption === "Optional" && (!sessionStorage.RPXLogin || sessionStorage.RPXLogin == false || sessionStorage.RPXLogin == "false")) {
    return (
      <div className="mfa-chat-container">
        <div className="mfa-chat-banner">
          <button className="mfa-chat-close" onClick={() => setIsVisible(false)}>X</button>
          <div className="mfa-chat-content">
            <br />
            <span className="mfa-chat-text">
              &nbsp;Mandatory Multi-factor authentication (MFA) security protocols for portal access are coming soon! We set it to Optional for a while so you can enjoy a sneak preview. Check out the guides in Momentum University to learn more.
            </span>
          </div>
        </div>
      </div>
    );
  } else {
    return null;
  }
}