import React, { Component } from 'react';
import SuperLink from './../../components/super_link';
import PayModal from './../modals/payModal';
import { Button } from '@progress/kendo-buttons-react-wrapper';
import { fetchData } from './../../requests/api_calls';
import { DataLoadingSpinner } from './../../components/spinners';
import { Link } from 'react-router-dom';
import { userIsAdmin } from './../../scripts/helper';
import * as config from './../../requests/config';
import * as currency from 'currency-formatter'
import * as session from '../../scripts/session';

class BillingCard extends Component {

  constructor() {
    super();
    this.state = {
      loading: true,
      downloading: true,
    };
  }

  componentDidMount() {
    if (userIsAdmin()) this.fetchBillingStat('admin/BillingStat');
  }

  fetchBillingStat = (apiPath, errorMsg) => {
    fetchData(apiPath).then((data) => {
      if (data !== null) {
        this.loadData(data);
      } else {
        this.setState({
          CustomerAccountNumber: sessionStorage.getItem('userEnterpriseNumber'),
          loading: false
        })
      }
    }).catch(ex => {
      this.setState({ loading: false });
    });
  }

  loadData = (data) => {
    this.setState({
      CustomerAccountNumber: data.CustomerAccountNumber,
      CustomerName: data.CustomerName,
      AccountNumber: data.AccountNumber,
      AccountBalance: currency.format(data.AccountBalance, { code: 'USD' }),
      Street1BILL: data.Street1BILL,
      Street2BILL: data.Street2BILL,
      CityBILL: data.CityBILL,
      StateBILL: data.StateBILL,
      ZipBILL: data.ZipBILL,
      InvoiceAmount: currency.format(data.InvoiceAmount, { code: 'USD' }),
      InvoiceDueDate: (new Date(data.InvoiceDueDate)).toLocaleDateString(),
      InvoiceURL: data.InvoiceURL.trim(),
      InvoiceID: data.InvoiceID,
      loading: false
    });
  }

  invoiceLink = () => {
    if (this.state.InvoiceID != 0 || this.state.InvoiceURL != "") {
      return (
        <div>
          <form ref='downloadInvoice' target='iFrameSave' action={config.baseApiUrl + 'admin/GetInvoice'} method='POST'>
            <input type='hidden' name='AccountNumber' value={this.state.AccountNumber}></input>
            <input type='hidden' name='InvoiceId' value={this.state.InvoiceID}></input>
            <input type='hidden' name='access_token' value={config.storedAccessToken()}></input>
            <input type='hidden' name='x_policy' value={config.storedXPolicy()}></input>
          </form>
          <iframe title="ignore this frame" className='hidden' name="iFrameSave"></iframe>
          <a tabIndex="0" className='no-btn' ref='invoiceBtn' onClick={(e) => this.getInvoice()} >View Invoice</a>
        </div>
      )
    } else null;
  }

  getInvoice = () => {
    this.refs.downloadInvoice.submit();
    this.refs.invoiceBtn.text = 'Downloading...';
    setTimeout(() => {
      this.refs.invoiceBtn.text = 'View Invoice';
    }, 2000);
  }

  addressPartTwoSeparator = () => {
    if (this.state.Street2BILL) return <br />;
  }

  billingData = () => {
    if (this.state.loading) {
      return <DataLoadingSpinner className='load spinner' />
    } else {
      let address = this.state.CityBILL ? (<address>
        {this.state.Street1BILL} <br />
        {this.state.Street2BILL} {this.addressPartTwoSeparator()}
        {this.state.CityBILL}, {this.state.StateBILL} {this.state.ZipBILL}

      </address>) : '';
      let billingMessage = process.env.REACT_APP_BILLING_MESSAGE;

      return (
        <div>
          <div className="card-header">
            <h2>{this.state.CustomerName}</h2>
          </div>
          <div className="card-content">
            <div className="card-header-section">
              <div className="address">
                {address}
              </div>
              <div className="billing-item">
                <label>Account No.: &nbsp;</label>
                {this.state.CustomerAccountNumber}
              </div>
            </div>

            {!session.IsWholesale() && (!billingMessage || billingMessage === '') && (
              <div className="card-header-section">
                <div className="actions">
                  <Link
                    to="/BillingNew"
                    className="k-button green btn"
                    alt="make a payment button"
                  >Make a Payment</Link>
                  {this.invoiceLink()}
                </div>
                <div className="billing-item">
                  <label>Current Balance: &nbsp;</label>
                  {this.state.AccountBalance}
                </div>
                <div className="billing-item hide">
                  <label>Service Period: &nbsp;</label>
                  Nov 1, 2017 - Nov 31, 2017
                </div>
                <div className="billing-item">
                  <label>Due Date: &nbsp;</label>
                  {this.state.InvoiceDueDate}
                </div>
              </div>)
            }
            {!session.IsWholesale() && billingMessage && billingMessage !== '' && (
              <div className="card-header-section">
                <div className="billing_maintenance">
                  <span>{billingMessage}</span>
                </div>
              </div>)
            }
          </div>
        </div>
      )
    }
  }

  render() {
    if (sessionStorage.enterpriseType === 'VOICE' || sessionStorage.enterpriseType === 'DATA' || sessionStorage.enterpriseType === 'MIXED') {
      return (
        <div className="billing_card top-card">
          <div className="card">
            {this.billingData()}
          </div>
        </div>
      )
    } else {
      return null;
    }
  }
}

export default BillingCard;

