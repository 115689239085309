import React, { Component } from 'react';
import * as apiCalls from '../../../requests/api_calls';
import * as helper from '../../../scripts/helper';
import * as macdHelper from '../components/macd_helper';
import { orderBy } from '@progress/kendo-data-query';
import { filterBy } from '@progress/kendo-data-query';
import { Modal } from 'react-bootstrap';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import InfoCell from '../../../components/infoCell';
import _ from 'lodash';

class PricebookModal extends Component {
    initialState(props) {
        return {
            MomentumAccountNumber: props.MomentumAccountNumber,
            showModal: false,
            saving: false,
            fullListings: [],
            Listings: [],
            sort: [{ field: "Product", dir: "desc" }],
            Editable: false, //check sessionStorage for emulation if emulated editable is true else false
            restrictedUSOCS: require('../components/DisabledUSOCs.json')
        };
    }
    constructor(props) {
        super(props);
        this.state = this.initialState(props);

        
        this.InfoCell = InfoCell;

        this.handleShow = this.handleShow.bind(this);
        this.sortChange = this.sortChange.bind(this);
        this.fetchPricebookDetails = this.fetchPricebookDetails.bind(this);
    }

    componentWillMount() {
        if (this.state.MomentumAccountNumber !== '')
            this.fetchPricebookDetails();
    }

    async componentWillReceiveProps(props) {
        if (props.MomentumAccountNumber !== '' && props.MomentumAccountNumber !== this.state.MomentumAccountNumber) {
            await this.setState({MomentumAccountNumber: props.MomentumAccountNumber});
            this.fetchPricebookDetails();        
        }
    }

    fetchPricebookDetails = async () => {
        await this.setState({ loading: true });
        let apiPath = macdHelper.apiPricebookPathWithParams(this.state.MomentumAccountNumber);
        await apiCalls.fetchData(apiPath).then(data => {
          if (data !== null) {
            this.setState({
              loading: false,
              fullListings: this.revisedData(data),
              Listings: this.revisedData(data)
            });
          } else if (data === null) {
            this.setState({ fullListings: [], Listings: [], loading: false });
          };
        });
        if (this.state.fullListings && this.state.fullListings.length > 0 && this.props.SetProductSetTypeName)
            this.props.SetProductSetTypeName(this.state.fullListings[0].ProductSetTypeName);
    }

    handleClose = () => {
        this.setState({showModal: false});
    }

    handleShow = () => {
        this.setState({showModal: true});
        //load pricebook listings for location
    }

    // handleSave = () => {
    //     this.setState({saving: true});

    //     this.setState({saving: false});
    // }

    revisedData = (data) => {
        let removedUSOCs = this.state.restrictedUSOCS;
        let revisedData = [];
        data.map((sd) => {
            let disabledUSOCEntry = _.filter(removedUSOCs, resp => resp.USOC === sd.PartNum && resp.ProductSetTypeName === sd.ProductSetTypeName);
            if (sd.LineTypeTicker === 'MTRTPKGBTA') {
                sd.adding = false;
                sd.disabled = true;
                sd.DisabledMessage = "Only one of these items can be added per SIP Trunking location.  After service is connected, contact support for any change orders to this service. Changes may affect other services connected to the Trunk."
                revisedData.push(sd);
            }
            else if (sd.MSP_Visible === 0)
            {
                sd.adding = false;
                sd.disabled = true;
                sd.DisabledMessage = "This product requires additional manual configuration. Please contact support to order this item.";
                revisedData.push(sd);
            } 
            else if (disabledUSOCEntry.length === 0 || disabledUSOCEntry[0].ShowListing !== false)
            {
                sd.adding = false;
                sd.disabled = disabledUSOCEntry.length > 0 ? true : false
                sd.DisabledMessage = disabledUSOCEntry.length > 0 ? disabledUSOCEntry[0].Message : ''
                revisedData.push(sd);
            }

        });


        let sortedData = this.sortSearchResults(this.state.sort, revisedData)
        return sortedData;
    }

    getSearchResults = (event) => {
        let searchText = '';
        let fullDetails = this.state.fullListings;
        if (event && event.target && event.target.value && event.target.value.trim() !== '') {
            searchText = event.target.value;
            let products = filterBy(fullDetails, {
            logic: 'or',
            filters: [
                { field: 'PartDescription', operator: 'contains', value: searchText, ignoreCase: true },
                { field: 'PartNum', operator: 'contains', value: searchText, ignoreCase: true },
            ]
            });

            this.setState({ Listings: products })
        }
        else {
            this.setState({ Listings: fullDetails });
        }
    }

    sortChange(event) {
        this.setState({
            Listings: this.sortSearchResults(event.sort),
            sort: event.sort
        });
    }
    
    sortSearchResults(sort, Listings = null) {
        if (Listings == null) Listings = this.state.Listings.slice();
        let sortedSet = orderBy(Listings, sort);
        if (sort.length && sort[0].field !== 'BasePrice' && sort[0].field !== 'ActivationCharge') { 
            return helper.blanksAfterZ(sortedSet, sort);
        } else {
            return sortedSet;
        }
    }

    gridContent = () => {
        return (
            <div>
              <Grid id="gridDetails"
                style={{ minHeight: '200px', maxHeight: '475px', fontSize: 'smaller' }}
                data={this.state.Listings}
                editable={false}
                sortable={{ allowUnsort: true, mode: 'single' }}
                sort={this.state.sort}
                onSortChange={this.sortChange}
              >
                <Column width="150px" field='PartNum' title='Part Num' />
                <Column field='PartDescription' title='Product Description' />
                <Column width="200px" field='BasePrice' format="{0:c2}" title='Monthly' />
                <Column width='200px' field='ActivationCharge' format="{0:c2}" title='One-Time' />
                <Column width='150px' field='DisabledMessage' title='Note' cell={this.InfoCell} />
                {/* <Column width={this.state.Editable ? "140px" : "0px"} field="MRCAdjustedPrice" title='Monthly (New)' />
                <Column width={this.state.Editable ? "140px" : "0px"} field="NRCAdjustedPrice" title='One-Time (New)' /> */}
                
    
              </Grid>
            </div>
    
          )
    }

    render() {
        let button = '';    
        button = (<a onClick={this.handleShow} className="orange btn">View Pricebook</a>);
    
        return (
            <span>
                {button}
                <Modal dialogClassName="macd-pricebook-listings" style={{ fontSize: 'small'}} show={this.state.showModal} onHide={(e) => { this.setState({ showModal: !this.state.showModal }) }} >
                    <Modal.Header closeButton>
                        <Modal.Title>Pricing for {this.state.MomentumAccountNumber}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="macd-search">
                            <div className="search">
                                <input type="text" className="form-control search-control" onChange={(e) => this.getSearchResults(e)} placeholder="Search Momentum Products by Part Num or Description" />
                            </div>
                        </div>
                        <br />
                        {this.gridContent()}
                    </Modal.Body>
                </Modal>
            </span>
        )
    }
}
export default PricebookModal;