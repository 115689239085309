import React, { Component } from 'react';
import InfoPop from './../components/infopop';
import { DataLoadingSpinner } from './../components/spinners';

class LongSwitch extends Component {

	constructor(props) {
    super(props);
	}

	componentWillMount() {

	}

	render() {
		const hasInfo = this.props.info;

		return(		
			<div>
				{ this.props.label }
				{ hasInfo &&
							<InfoPop message={ this.props.info } /> 
				}
				<label className="switch">
				  <input type="checkbox" checked={this.props.on} onChange={this.props.onChange}/>				
				  <span className="slider round"></span>
				</label>
			</div>
		);
	}
}

export default LongSwitch;