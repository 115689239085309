import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { Button } from '@progress/kendo-buttons-react-wrapper';

class LocationMusicOnHoldModal extends Component {

  constructor(props) {
    super(props);

    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.state = { showModal:false };
  }

  handleClose() {
    this.setState({ showModal: false });
  }

  handleShow() {
    this.setState({ showModal: true });
  }

  componentDidMount() {   
  }
  render() {
    
    return (
      <div className="setting">
        <span onClick={this.handleShow}> 
          <span className="name">Music on Hold</span>
          <a className="icon btn"><svg width="14" height="14" viewBox="0 0 24 24"><path d="m163 440l-91-91 251-250 90 90z m309-352l-48-48c-12-11-32-11-45 2l-45 45 91 91 45-45c13-13 13-33 2-45z m-408 275l-32 117 117-32z" transform="scale(0.046875 0.046875)"></path></svg></a>
        </span>
        <Modal show={this.state.showModal} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Music on Hold</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <p>Play recordings to callers on hold.</p>
              <div className="form-group">
                <label className="right switch">
                  <input type="checkbox" data-toggle="collapse" data-target="#hide1" />
                  <span className="slider round"></span>
                </label>
                  Turn on hold music
              </div>
              <div className="form-group">
                <label>Configuration</label>
                <div className="form-control">
                  <label className="custom-control">
                  <input id="radio1" name="radio" type="radio"/>
                    <span >Standard Hold Music</span>
                  </label> 
                  <label className="custom-control">
                    <input id="radio1" name="radio" type="radio" data-toggle="collapse" data-target="#hide2"/>
                    <span>Custom Hold Music</span>
                  </label>
                </div>
                <div className="hidden-group collapse" id="hide2">
                  <div className="form-group">
                    <label >Custom Music</label>
                    <select className="form-control">
                      <option>Jazz</option>
                      <option>Upload New Music</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
              <hr />
              <div className="btns">
                <Button className="btn">Save</Button>
                <Button className="btn" click={this.handleClose}>Cancel</Button>
              </div>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }

};

export default LocationMusicOnHoldModal;
