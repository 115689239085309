import React, { Component } from "react";
import { DataLoadingSpinner } from "../../components/spinners";
import { withRouter } from "react-router-dom";
import "@progress/kendo-theme-default/dist/all.css";
import "../../css/bootstrap.css";
import "../../css/app.css";
import "../../css/spinner.css";
import "../../css/msp_resetpassword.css";
import * as helper from "../../scripts/helper";
import * as config from "../../requests/config";
import momentumLogoWhite from "../../images/momentum_logo.png";
import { connect } from "react-redux";

class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Email: '',
      Error: "",
      Logo: momentumLogoWhite,
      validationError: "",
      isNetworkError: false,
      isInvalidUser: true,
      message: "",
      isMailSent: false,
      ProviderId: "",
    };
  }
  getCustomizations = async currentUrl => {
    const queryParams = new URLSearchParams(window.location.search);
    this.state.Email = queryParams.get("login_hint");
    this.state.ProviderId = queryParams.get("ProviderId");
    const b2cResults = await helper.getB2CConfigResults(currentUrl);
    this.loadingLogo = helper.IsWholesaleDomain(currentUrl)
      ? this.loadingLogo
      : momentumLogoWhite;
    const customAuthPolicy = this.preProcessCustomizations(b2cResults);

    await this.setState({ auth: customAuthPolicy, loading: false });
  };

  preProcessCustomizations = b2cResults => {
    let customAuthPolicy = config.auth;

    if (b2cResults) {
      const authority =
        config.authorityUrl +
        b2cResults.B2CPolicy +
        config.defaultXPolicyConfig;
      customAuthPolicy = {
        clientId: b2cResults.B2CClientId,
        authority: authority,
        b2cScopes: []
      };
      const { LogoSmall, LogoLarge, VendorName, BrowserIcon } = b2cResults;
      this.loadingLogo = LogoLarge;
      helper.updateFavicon(BrowserIcon);
      if (!helper.isDigitalServicesDomain()) {
        helper.setTitle(VendorName);
      }
    }
    return customAuthPolicy;
  };

  componentDidMount() {
    // this.props.history.push("\session");
    const domain = helper.getDomain();
    this.getCustomizations(domain);
  }

  handleOnSave = () => {
    this.setState({ message: "" });
    if (!this.state.Email) {
      this.setState({ Error: "Email is missing.", validationError: " Please contact your account manager." });
      return;
    }
    else {
      let state = { ...this.state };
      state.savingLoading = true;
      state.savingShowSuccess = false;
      this.setState(state);

      let headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + config.storedAccessToken(),
        'X-Policy': config.storedXPolicy()
      };


      //let apiUrl = "admin/GetPasswordResetLink";

      helper.SendResetPasswordLink(this.state.Email).then((response) => {
        if (response == null) {
          this.setState({ Error: "The reset password process could not proceed: No email on file.", validationError: "Please contact your System Administrator for password assistance. ", isMailSent: false });
          return;
        }
        else {
          this.setState({
            isNetworkError: false,
            message: "The mail has been successfully sent to your email. Please click the link in your email to reset the password.",
            isMailSent: true
          });
          if (response) {
            this.setState({ Error: "" });
          }
        }
      }).catch(async err => {
        console.log(err);
        this.setState({
          isNetworkError: Boolean(err.message),
          Error: "Unable to reach server",
          validationError: "",
          isMailSent: false
        });
      });
    }
  }
  handleOnBack = () => {
    helper.redirectToLogin();
    return;
  }

  handleLoginEmailChange = (event) => {
    if (this.state.ProviderId === 18129 || this.state.ProviderId === "18129") {
      this.setState({ Email: event.target.value });
    } else return;
  }

  sendLinkForm = () => {
    let state = this.state;
    // const queryParams = new URLSearchParams(window.location.search);
    // state.Email = queryParams.get("login_hint");
    return (
      <div className='msp_resetPwd'>
        <div className="resetPwd">
          <img id="msp_logo" class="App-logo" src={this.loadingLogo} />
          <div className="form-holder">
            <h2>Forgot Password</h2>
            <div id="api" data-name="Unified">
              <div className="localAccount" aria-label="Sign in with your existing account" role="form">
                <div className="intro">
                  <p>A link to reset your password will be sent to the email address associated with the user name below.</p>
                </div>
                <div className="error pageLevel"  >
                  <p role="alert">{this.state.Error}</p>
                  <div><p role="alert" className="errorMessage">{this.state.validationError}</p></div>
                </div>
                <div className="entry">
                  <div className="form-group">
                    <label>Username</label>
                    <input
                      type="text"
                      //className="mailTxt"
                      disabled={this.state.ProviderId === 18129 || this.state.ProviderId === "18129" ? false : true}
                      value={this.state.Email}
                      onChange={this.handleLoginEmailChange}
                    />
                  </div>
                  {this.getResponseMessage()}
                  {!this.state.isMailSent ? <div className="buttons">
                    <button
                      className="my-login-btn"
                      id="next"
                      type="submit"
                      tabIndex={2}
                      onClick={this.handleOnSave}
                    >Send</button>
                  </div> : null}
                  <button
                    className="my-login-btn"
                    id="back"
                    type="submit"
                    tabIndex={2}
                    onClick={this.handleOnBack}
                  >Back</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  getResponseMessage = () => {
    if (this.state.message) {
      return (<div className="inline inline-success text-center successMsg">{this.state.message}</div>)
    }
    return "";
  }
  sendLinkContainer = () => {
    if (this.state.isInvalidUser) {
      return this.sendLinkForm();
    }
    else {
      helper.redirectToLogin();
      return;
    }
  }

  render() {
    return this.sendLinkContainer();
  }
}
const mapStateToProps = state => {
  return {
    redirecting: helper.toBoolean(state.reducer.redirecting),
    restartSession: helper.toBoolean(state.reducer.restartSession),
    loginError: state.reducer.loginError,
    loginErrorDescription: state.reducer.loginErrorDescription,
  };
};
export default withRouter(connect(mapStateToProps)(ForgotPassword));