import React, { Component } from 'react';
import ReactDOMServer from 'react-dom/server';
import { Link, withRouter, BrowserRouter } from 'react-router-dom';
import '@progress/kendo-ui';
import { fetchData } from './../requests/api_calls';
import { DataLoadingSpinner } from './spinners';
import Popup from "reactjs-popup";
import { Grid, GridCell, GridColumn as Column } from '@progress/kendo-react-grid';
import * as helper from './../scripts/helper';
import { orderBy } from '@progress/kendo-data-query';

class ServiceMACClickPop extends Component {

    constructor(props) {
      super(props);
      this.state = {
        MACListings: [],
        ServiceId: this.props.ServiceId,
        loading: true,
        popupHeight: 100 + (40 * this.props.DevCount) > 500 ? 500 : 100 + (40 * this.props.DevCount),
        sort: [{field: "MAC", dir: "asc"}],
      };

      this.sortChange = this.sortChange.bind(this);
      this.sortServices = this.sortServices.bind(this);

      class MacAddressCell extends GridCell {
        render() {
            let service = this.props.dataItem;
            let fieldClass = service.DevRegistered ? 'text-success' : 'text-danger';
            return <td><span className={fieldClass}>{service.MAC}</span></td>
        }
      }
      
      class EditBtn extends GridCell {
        render() {
          let path = {pathname: '/services', searchTerm:  this.props.dataItem.UserId}
          return (
            <td>
              <Link to={path} className="no-btn">Service</Link>
            </td>
          )
        }
      }

      this.macAddressCell = MacAddressCell;
      this.editBtn = EditBtn;
    }

    showMacs = () => {
        this.setState({loading: true});
        fetchData('admin/GetServiceMacList?ServiceId=' + this.props.ServiceId).then(data => {
            if(data && data.length > 0) {
                let macListings = [];
                data.map((mac, index) => {
                    macListings.push(mac);
                });
                let sortedData = this.sortServices(this.state.sort, macListings);
                this.setState({MACListings: sortedData, loading: false});
            }
        });
    }

    sortServices(sort, MACListings = null) {
        if (MACListings == null) MACListings = this.state.MACListings.slice();
        let sortedSet = orderBy(MACListings, sort);
        if (sort.length) {
          return helper.blanksAfterZ(sortedSet, sort);
        } else {
          return sortedSet;
        }
      }
    
      sortChange(event) {
        this.setState({
          MACListings: this.sortServices(event.sort),
          sort: event.sort
        });
      }

    gridContent = () => {
        if (this.state.loading) {
            return <DataLoadingSpinner className='load relative spinner' />
        } else {
            return (
          <span>
            <h4 className='text-center'>MAC Addresses:</h4>
            <Grid
              style={{ height: (this.state.popupHeight - 75) + 'px' }}
              data={this.state.MACListings}
              sortable={{allowUnsort: true, mode: 'single'}}
              sort={this.state.sort} 
              onSortChange={this.sortChange}
            >
              <Column field="MAC" cell={this.macAddressCell} title="MAC" />
              {/* <Column title=' ' cell={this.editBtn} sortable={false} /> */}
            </Grid>
          </span>
        )
        }
    }

    render() {
        return (
            <Popup
              trigger={open => (
                <a tabIndex="0" className="no-btn">{ this.props.MAC }</a>
              )}
              onOpen={this.showMacs}
              position="bottom center"
              closeOnDocumentClick
              contentStyle={{padding: '20px', border: 'unset', width: '350px', height: this.state.popupHeight + 'px'}}
            >
              <span> {this.gridContent()} </span>
            </Popup>
          )
    }
}

export default ServiceMACClickPop;