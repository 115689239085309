import React, { useEffect, useState } from "react";
import { Modal } from 'react-bootstrap';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { Input } from "@progress/kendo-react-inputs";
import { Button } from '@progress/kendo-buttons-react-wrapper';



const EditForm = props => {
  const { data: defaultData, customerIdpAppList, onSubmit, isEditMode } = props;

  const [editItem, setEditItem] = useState({});

  useEffect(() => {
    setEditItem(defaultData);
  }, [props.data]);


  const handleInputChange = e => {
    setEditItem({
      ...editItem,
      [e.target.name]: e.target.value
    })
  };

  const handleDropdownChange = e => {
    setEditItem({
      ...editItem,
      "IdPAppName": e.target.value?.IdPAppName
    })
  };

  const handleSubmit = () => {
    onSubmit(editItem);
  }

  const submissionMsg = () => {
    if (props.Submission) {
      if (props.SubmitError) {
        return <small style={{
          color: 'red',
        }}>{props.ErrorMessage && props.ErrorMessage != "" ? props.ErrorMessage : "Submission Failure: Changes were not processed!"}</small>
      } else {
        return <small style={{
          color: 'green',
        }}>Submission Sucess: Changes were processed!</small>
      }
    }
  };


  return (
    <Modal show={props.showModal}>
      <Modal.Header>
        <Modal.Title>{isEditMode ? 'Edit' : 'Add New App'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="mb-3">
          <DropDownList
            data={customerIdpAppList}
            textField="IdPAppName"
            value={{ IdPAppName: editItem.IdPAppName }}
            onChange={handleDropdownChange}
            style={{
              width: '100%'
            }}
            label='IdP App Name'
            disabled={isEditMode}
          />
        </div>
        <div className="mb-3">
          <Input
            name="IdPAppClientId"
            onChange={handleInputChange}
            label='IdP App ClientId'
            style={{
              width: '100%'
            }}
            value={editItem.IdPAppClientId}
          />
        </div>
        <div className="mb-3">
          <Input
            name="IdPAppClientKey"
            onChange={handleInputChange}
            label='IdP App ClientKey'
            style={{
              width: '100%'
            }}
            value={editItem.IdPAppClientKey}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <a
          className="a btn"
          disabled={props.Submission ? true : false}
          onClick={handleSubmit}
        >{isEditMode ? 'Update' : 'Add'}</a>
        <Button click={props.cancelEdit}>Cancel</Button>
        <br />
        <br />
        <div>
          {submissionMsg()}
        </div>
      </Modal.Footer>
    </Modal>
  )
};

export default EditForm;