import React, { Component } from "react";
import { DataLoadingSpinner } from "../../components/spinners";
import { withRouter } from "react-router-dom";
import "@progress/kendo-theme-default/dist/all.css";
import "../../css/bootstrap.css";
import "../../css/app.css";
import "../../css/spinner.css";
import "../../css/msp_resetpassword.css";
import * as helper from "../../scripts/helper";
import * as config from "../../requests/config";
import momentumLogoWhite from "../../images/momentum_logo.png";
import { connect } from "react-redux";


const Password1 = "Password1", Password2 = "Password2";

class ResetPassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      Email: '',
      Error: "",
      validationError: "",
      Logo: momentumLogoWhite,
      password1: '',
      password2: '',
      isNetworkError: false,
      isInvalidUser: true,
      isShowBackButton:false
    };
  }

  getCustomizations = async currentUrl => {
    const b2cResults = await helper.getB2CConfigResults(currentUrl);
    this.loadingLogo = helper.IsWholesaleDomain(currentUrl)
      ? this.loadingLogo
      : momentumLogoWhite;
    const customAuthPolicy = this.preProcessCustomizations(b2cResults);

    await this.setState({ auth: customAuthPolicy, loading: false });
  };

  preProcessCustomizations = b2cResults => {
    let customAuthPolicy = config.auth;
    if (b2cResults) {
      const authority =
        config.authorityUrl +
        b2cResults.B2CPolicy +
        config.defaultXPolicyConfig;
      customAuthPolicy = {
        clientId: b2cResults.B2CClientId,
        authority: authority,
        b2cScopes: []
      };
      const { LogoSmall, LogoLarge, VendorName, BrowserIcon } = b2cResults;
      this.loadingLogo = LogoLarge;
      helper.updateFavicon(BrowserIcon);
      if (!helper.isDigitalServicesDomain()) {
        helper.setTitle(VendorName);
      }
    }
    return customAuthPolicy;
  };

  componentDidMount() {
    // this.props.history.push("\session");
    const domain = helper.getDomain();
    this.getCustomizations(domain);
  }

  handleOnChange = (type, event) => {
    let state = { ...this.state };

    switch (type) {
      case Password1:
        state.password1 = event.target.value;
        break;
      case Password2:
        state.password2 = event.target.value;
        break;
    }
    if (state.password1.length > 0 && state.password1.length < 8) {
      state.valid = false;
      state.validMessage = 'Password must be at least 8 characters long';
    } else if (!this.ValidPassword(state.password1)) {
      state.valid = false;
      state.validMessage = 'The password must contain at least 1 number or special character, at least 1 lower case letter, and at least 1 upper case letter';
    }
    else if ((state.password1 && state.password2 && state.password1 !== state.password2) || (state.password1 && !state.password2) || (!state.password1 && state.password2)) {
      state.valid = false;
      state.validMessage = "The password is not the same";
    } else if (state.password1 && state.password2 && state.password1 === state.password2) {
      state.valid = true;
      state.validMessage = "";
    }
    this.setState(state);
  }

  ValidPassword = (pw) => {
    //var reg = /^.*(?=.{8,})(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).*/;
    var reg = /^.*(?=.{8,})(?=.*([\d]|[!@#$%^&*{}_+=\[\]:;'",<.>/?_`~\\\|-]))(?=.*[a-z])(?=.*[A-Z]).*/;
    var test = reg.test(pw);
    return test;
  }
  decode = (value) => {
    let base64ToString = new Buffer.from(value, "base64").toString();
    return base64ToString;
  }
  handleOnBack = () => {
    helper.redirectToLogin();
    return;
  }

  handleOnSave = () => {
    if (!this.state.valid) {
      return;
    }
    else {
      let state = { ...this.state };
      state.savingLoading = true;
      state.savingShowSuccess = false;
      this.setState(state);
      //new Buffer.from(window.location.search.replace('?token=',''), "base64").toString().split(',')[2].replace('userName=','')
      var token = new URLSearchParams(window.location.search).get("token");
      var decodedToken = this.decode(token);
      var userName = '';
      var params = decodedToken.split(',');
      for (var index = 0; index < params.length; index++) {
        if (params[index].startsWith('userName=')) {
          userName = params[index].replace('userName=', '');
          break;
        }
      }
      helper.ValidateResetPassword(window.location.href, state.password1, userName).then((response) => {
        if (response == null) {
          this.setState({ Error: "Token link has expired.", validationError: "Please click Back to go to Login page.",isShowBackButton:true });
          
          return;
        }
        else {
          this.setState({
            isNetworkError: false,
          });
          if (response) {
            var responseCode = parseInt(response);
            if (responseCode == 500) {
              this.setState({ Error: "An internal server error has occurred.", validationError: "Please return to the Sign In page and try Forgot Password again. Contact your Administrator if the error persists." });
              return;
            }
            else if (responseCode == 401) {
              this.setState({ Error: "The token cannot be decoded.", validationError: "Please check the url." });
              return;
            }
            else if (responseCode == 406) {
              this.setState({ Error: "Token link has expired.", validationError: "Please click Back to go to Login page.",isShowBackButton:true });
              return;
            }
            else {
              this.setState({ Error: "" });
            }
          }
          helper.redirectToLogin();
          return;
        }
      }).catch(async err => {
        console.log(err);
        this.setState({
          isNetworkError: Boolean(err.message),
          Error: "Unable to reach server",
          validationError: ""
        });
      });
    }
  }
  resetForm = () => {
    let state = this.state;
    return (
      <div className='msp_resetPwd'>
        <div className="resetPwd">
          <img id="msp_logo" className="App-logo" src={this.loadingLogo} />
          <div className="form-holder">
            <h2>Reset Password</h2>
            <div id="api" data-name="Unified">
              <div className="localAccount" aria-label="Sign in with your existing account" role="form">
                <div className="intro">
                  <p>We recommend you choose a strong password to protect your online profile. The password length must be at least 8 characters. The password must contain at least 1 number or special character, at least 1 lower case letter, and at least 1 upper case letter.</p>
                  <p>Avoid using passwords that can be easily guessed such as your name, telephone number, social security number, or account number.</p>
                </div>
                <div className="error pageLevel"  >
                  <p role="alert">{this.state.Error}</p>
                  <div><p role="alert" className="errorMessage">{this.state.validationError}</p></div>
                </div>
                {this.resetFormInner()}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  resetFormInner = () =>{
    if(this.state.isShowBackButton){
      return (<div className="entry">      
      {this.getValidationError()}
      <div className="working" />
      <div className="buttons">
        <button
          className="my-login-btn"
          id="next"
          type="submit"
          tabIndex={2}
          onClick={this.handleOnBack}
        >Back</button>
      </div>
    </div>);
    }
    else {
      return (<div className="entry">
      <div className="form-group">
        <label>Type Password</label>
        <input type="password" className="form-control" autoComplete='new-password'
          value={this.state.password1}
          maxLength="20"
          onChange={x => { this.handleOnChange(Password1, x) }} />
      </div>
      <div className="form-group">
        <label>Retype Password</label>
        <input type="password" className="form-control" autoComplete='new-password'
          value={this.state.password2}
          maxLength="20"
          onChange={x => { this.handleOnChange(Password2, x) }} />
      </div>
      {this.getValidationError()}
      <div className="working" />
      <div className="buttons">
        <button
          className="my-login-btn"
          id="next"
          type="submit"
          tabIndex={2}
          onClick={this.handleOnSave}
        >Save</button>
      </div>
    </div>);
    }
  }
  getValidationError = () => {
    if (!this.state.valid && this.state.validMessage) {
      return (<div className="inline inline-fail text-center">{this.state.validMessage}</div>)
    }
    return "";
  }
  resetContainer = () => {
    if (this.state.isInvalidUser) {
      return this.resetForm();
    }
    else {
      helper.redirectToLogin();
      return;
    }
  }

  render() {
    return this.resetContainer();
  }
}
const mapStateToProps = state => {
  return {
    redirecting: helper.toBoolean(state.reducer.redirecting),
    restartSession: helper.toBoolean(state.reducer.restartSession),
    loginError: state.reducer.loginError,
    loginErrorDescription: state.reducer.loginErrorDescription,
  };
};
export default withRouter(connect(mapStateToProps)(ResetPassword));