import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Grid, GridColumn as Column, GridCell } from '@progress/kendo-react-grid';
import { Modal } from 'react-bootstrap';
import { headerToolTip } from '../../components/ellipsisHeaderTooltip';
import { DataLoadingSpinner } from '../../components/spinners';
import { orderBy } from '@progress/kendo-data-query';
import { filterBy } from '@progress/kendo-data-query';
import * as apiCalls from '../../requests/api_calls';
import * as config from '../../requests/config';
import * as settings from '../../scripts/settings';
import * as helper from '../../scripts/helper';

export default class SyncMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      EnterpriseId: sessionStorage.enterpriseId,
      //EnterpriseId: props.match.params.enterpriseId,
      loading: false,
      Submission: false,
      submitError: false,
      //tab: "azure",
      UsersList: [],
      FullListings: [],
      ConnectorInfo: {},
      OriginalConnectorInfo: {},
      showConfirmationModal: false,
      Sort: [{ field: "UserId", dir: "desc" }],
      filter: { logic: "and", filters: [] },
    };

    class ManageUserCell extends GridCell {
      constructor(props) {
        super(props);
      }
      render() {
        let { UserId, AzureObjectId } = this.props.dataItem;

        //if (UserId) {
        return (
          <td style={{
            position: 'relative',
            right: '35px'
          }}>
            <Link
              to={{ pathname: `/ad-user-config`, state: { UserId: UserId, AzureObjId: AzureObjectId } }}
              className="icon btn">
              <svg width="14" height="14" viewBox="0 0 24 24">
                <path d="m163 440l-91-91 251-250 90 90z m309-352l-48-48c-12-11-32-11-45 2l-45 45 91 91 45-45c13-13 13-33 2-45z m-408 275l-32 117 117-32z" transform="scale(0.046875 0.046875)"></path>
              </svg>
            </Link>
          </td>
        )
        // } else {
        //   return <td></td>
        // }
      }
    }

    class DataLinkCell extends GridCell {
      constructor(props) {
        super(props);
      }
      render() {
        let { LocationId } = this.props.dataItem;
        if (LocationId) {
          //let locNum = helper.filterNestedObject("LocationId", 4378, JSON.parse(sessionStorage.locationList))[0].LocationAccountNumber;
          let locNum = sessionStorage.locationList && JSON.parse(sessionStorage.locationList).length > 0 && helper.filterNestedObject("LocationId", LocationId, JSON.parse(sessionStorage.locationList)) ? helper.filterNestedObject("LocationId", LocationId, JSON.parse(sessionStorage.locationList))[0].LocationAccountNumber : LocationId;
          return (
            <td>
              <Link
                to={{ pathname: `/services/${locNum}/ALL_SERVICES_USERS`, state: {} }}
              //className="a btn"
              >
                {locNum}
              </Link>
            </td>
          )
        } else {
          return <td></td>
        }
      }
    }

    class UserLinkCell extends GridCell {
      constructor(props) {
        super(props);
      }

      selectUser = (service) => {
        settings.selectService(service.UserId, null);
      }

      render() {
        let { UserId } = this.props.dataItem;
        if (UserId) {

          return (
            <td>
              <Link
                to={{ pathname: `/dashboard`, state: {} }}
                //className="a btn"
                onClick={() => { this.selectUser(this.props.dataItem) }}
              >
                {UserId}
              </Link>
            </td>
          )
        } else {
          return <td></td>
        }
      }
    }

    this.ManageUserCell = ManageUserCell;
    this.DataLinkCell = DataLinkCell;
    this.UserLinkCell = UserLinkCell;
  }

  componentDidMount() {
    this.fetchAdUserList(this.state.EnterpriseId);
  }

  alterData = (data) => {
    let userIdArr = [];
    data.forEach(itm => {
      //itm.UserId = itm.UserId.toString();
      //itm.LocationId = itm.LocationId.toString();
      if(itm.UserId) {
        userIdArr.push(itm.UserId);
      }
      itm.IsDeleted = itm.IsDeleted == true ? "Yes" : "No"
    });
    //console.log("USERIDS", userIdArr);
    sessionStorage.setItem("UserIdArr", JSON.stringify(userIdArr));
    return data;
  }

  fetchAdUserList = async (EnterpriseId) => {
    await this.setState({ loading: true });
    let path = `admin/GetAzureADUserList?EnterpriseId=${EnterpriseId}`;
    await apiCalls.fetchData(path).then(data => {
      //console.log('ad sync user list', data);
      if (data) {
        let newData = this.alterData(data);
        this.setState({
          UsersList: newData,
          FullListings: newData,
          loading: false,
        });
      } else {
        this.setState({
          UsersList: [],
          FullListings: [],
          loading: false,
        });
      }
    }).catch(err => console.error(err));
  }



  gridContent = () => {
    let { loading, UsersList, Sort } = this.state;
    if (!loading) {
      if (UsersList && UsersList.length > 0) {
        return (
          <Grid
            style={{
              width: '100%',
            }}
            data={orderBy(UsersList, Sort)}
            sortable={{ allowUnsort: true, mode: 'single' }}
            sort={Sort}
            onSortChange={this.handleSortChange}
          // filterable
          // filter={this.state.filter}
          // onFilterChange={this.handleFilterChange}
          >
            <Column field="UserId" title={headerToolTip("User ID")} cell={this.UserLinkCell} />
            <Column field="LocationId" title={headerToolTip("Location ID")} cell={this.DataLinkCell} />
            <Column field="UserPrincipalName" title={headerToolTip("Principal Name")} />
            <Column field="Department" title={headerToolTip("Department")} />
            <Column field="FacsimilieTelephone" title={headerToolTip("Fax")} />
            <Column field="GivenName" title={headerToolTip("First Name")} />
            <Column field="Surname" title={headerToolTip("Last Name")} />
            <Column field="Mail" title={headerToolTip("Mail")} />
            <Column field="Mobile" title={headerToolTip("Mobile")} />
            <Column field="Telephone Number" title={headerToolTip("Phone")} />
            <Column field="IsDeleted" title={headerToolTip("Active")} />
            <Column width="120px" title={headerToolTip("Manage User")} cell={this.ManageUserCell} />
            <Column title=' ' field='filler' />
          </Grid>
        )
      } else {
        return <p style={{ textAlign: 'center', paddingTop: '15px' }}>No Available Data</p>
      }
    } else {
      return <DataLoadingSpinner className='load spinner' />
    }
  }

  handleSortChange = (e) => {
    this.setState({
      UsersList: orderBy(this.state.UsersList, e.sort),
      Sort: e.sort
    });
  }

  handleFilterChange = async (event) => {
    await this.setState({
      filter: event.filter
    });
  }

  openAttachments = () => {
    this.refs.fileUploader.click();
  }

  addAttachment = async (event) => {
    let file = event.target.files[0];
    let reader = new FileReader();
    reader.addEventListener('loadend', () => {
      let body = reader.result.split(',')[1];
      let name = file.name;
      this.createAttachment(body, name);
    }, false);
    if (file) {
      reader.readAsDataURL(file);
    }
  }

  createAttachment = async (body, name, ticketId) => {
    let apiPath = 'admin/UploadIDPUsers';
    let attachmentDTO = {
      TicketId: this.state.TicketId,
      AttachmentBody: body,
      FileName: name,
    };
    await this.setState({ attachmentSubmitted: true });
    let response = await apiCalls.post(apiPath, 'POST', JSON.stringify(attachmentDTO)).then(res => res).catch(err => console.error(err));
    if (response.ok) {
      await this.setState({
        Submission: true,
        submitError: false,
      });
    } else {
      await this.setState({
        Submission: true,
        submitError: true,
      });
    }
  }

  uploadBtn = () => {
    return (
      <div id="users-upload" className='pull-right' style={{ marginRight: 10, paddingLeft: "10px" }}>
        <input
          type="file"
          id="file"
          accept=".csv"
          ref='fileUploader'
          style={{ display: "none" }}
          onChange={this.addAttachment}
          onClick={(event) => {
            event.target.value = null;
          }}
        />
        <a tabIndex="0" className='no-btn' ref='uploadBtn' onClick={(e) => this.openAttachments()}>Import Users</a>
      </div>)

  }
  exportUsers = () => {
    this.refs.downloadCsv.submit();
    this.refs.exportBtn.text = 'Downloading...';
    setTimeout(() => {
      this.refs.exportBtn.text = 'Export Users';
    }, 2000);
  }

  exportBtn = () => {
    if (this.state.UsersList.length > 0) {
      return (
        <div id="users-csv" className='pull-right'>
          <form ref='downloadCsv' target="iFrameSave" action={config.baseApiUrl + 'admin/IDPUsersCSV?type=regularusers'} method="POST">
            <input type="hidden" name="access_token" value={config.storedAccessToken()} className='hidden'></input>
            <input type="hidden" name="x_policy" value={config.storedXPolicy()} className='hidden'></input>
          </form>
          <iframe name="iFrameSave" height="0" width="0" className='hidden pull-right'></iframe>
          <a tabIndex="0" className='no-btn' ref='exportBtn' onClick={(e) => this.exportUsers()}>Export Users</a>
        </div>
      )
    } else return null;
  }

  cardContent = () => {
    return (
      <div className="card-content"
      >
        <div className="card-actions">
          <div className="search">
            <form ref={'searchTerm'} onSubmit={e => { e.preventDefault() }}>
              <label htmlFor="services_srch_tb" className="hidden">services search</label>
              <input id="services_srch_tb" ref={'searchTerm'} type="text" className="form-control search-control"
                autoComplete={"off"}
                onChange={this.searchListings}
              />
              <svg width="14" height="14" viewBox="0 0 23 23"><path d="m347 238c0-36-12-66-37-91-25-25-55-37-91-37-35 0-65 12-90 37-25 25-38 55-38 91 0 35 13 65 38 90 25 25 55 38 90 38 36 0 66-13 91-38 25-25 37-55 37-90z m147 237c0 10-4 19-11 26-7 7-16 11-26 11-10 0-19-4-26-11l-98-98c-34 24-72 36-114 36-27 0-53-5-78-16-25-11-46-25-64-43-18-18-32-39-43-64-10-25-16-51-16-78 0-28 6-54 16-78 11-25 25-47 43-65 18-18 39-32 64-43 25-10 51-15 78-15 28 0 54 5 79 15 24 11 46 25 64 43 18 18 32 40 43 65 10 24 16 50 16 78 0 42-12 80-36 114l98 98c7 7 11 15 11 25z" transform="scale(0.046875 0.046875)"></path></svg>
            </form>
          </div>
        </div>
        <div className="users-row">
          <div className='users-item'>
            {this.uploadBtn()}
            {this.exportBtn()}
          </div>
        </div>
        {this.gridContent()}
      </div >
    );
  }

  searchListings = (event) => {
    let searchText = event.target.value.trim();
    let fullDetails = this.state.FullListings;
    if (searchText !== '') {
      searchText = event.target.value;
      let services = filterBy(fullDetails, {
        logic: 'or',
        filters: [
          { field: 'GivenName', operator: 'contains', value: searchText, ignoreCase: true },
          { field: 'Surname', operator: 'contains', value: searchText, ignoreCase: true },
          { field: 'UserPrincipalName', operator: 'contains', value: searchText, ignoreCase: true },
        ]
      });
      this.delayedSearch(services);
    }
    else {
      this.delayedSearch(fullDetails);
    }
  }

  delayedSearch = async (services) => {
    await this.setState({ loading: true });
    if (this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.setState({ UsersList: services, loading: false });
    }, settings.searchDelay);
  }

  render() {
    return this.cardContent();
  };
}