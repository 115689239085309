import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { Button } from '@progress/kendo-buttons-react-wrapper';
import { Slider } from '@progress/kendo-inputs-react-wrapper';
import { MultiSelect } from '@progress/kendo-dropdowns-react-wrapper';
import _ from 'lodash';

class locationCallPark extends Component {

  constructor(props) {
    super(props);

    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.state = { showModal:false };
  }

  handleClose() {
    this.setState({ showModal: false });
  }

  handleShow() {
    this.setState({ showModal: true });
  }

  componentDidMount() {   
  }
  render() {
    
    return (
      <div className="setting">
        <span onClick={this.handleShow}> 
          <span className="name">Call Park</span>
          <a className="icon btn"><svg width="14" height="14" viewBox="0 0 24 24"><path d="m163 440l-91-91 251-250 90 90z m309-352l-48-48c-12-11-32-11-45 2l-45 45 91 91 45-45c13-13 13-33 2-45z m-408 275l-32 117 117-32z" transform="scale(0.046875 0.046875)"></path></svg></a>
        </span>
        <Modal show={this.state.showModal} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Call Park</Modal.Title>
          </Modal.Header>
          <Modal.Body>
          <table className="table">
                <thead>
                  <tr>
                    <th className="check"><input type="checkbox" /></th>
                    <th>Group Name</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="check"><input type="checkbox" /></td>
                    <td>Meet-Me</td>
                    <td align="right">
                      <a className="icon btn"  data-toggle="collapse" data-target="#ar1"><svg width="14" height="14" viewBox="0 0 24 24"><path d="m163 440l-91-91 251-250 90 90z m309-352l-48-48c-12-11-32-11-45 2l-45 45 91 91 45-45c13-13 13-33 2-45z m-408 275l-32 117 117-32z" transform="scale(0.046875 0.046875)"></path></svg></a>
                    </td>
                  </tr>
                  <tr className="collapse" id="ar1">
                    <td colSpan="3">
                      <div className="table-edit">
                        {/*<p className="red">No extension is configured for this Call Park. <a className="btn">Configure</a></p>*/}
                        <div className="form-group">
                          <label>Name</label>
                          <input type="type" className="form-control" defaultValue="" />
                        </div>
                        <div className="multiselect-group form-group">
                          <label>Selected Users</label>
                          <MultiSelect autoClose={false} dataSource={["User 1", "User 2", "User 3", "User 4"]}/>
                        </div>
                        <hr />
                        <div className="btns">
                          <a href="" className="btn">Save</a>
                          <a href="" className="btn">Cancel</a>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="3">
                      <div className="btns">
                        <button className="btn" data-toggle="collapse" data-target="#ar1">Add Group</button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
              <hr />
              <div className="form-group">
                <label >Recall Timer in Seconds</label>
                <Slider
                  min={30}
                  max={600}
                  value={10}
                  largeStep={5}
                  showButtons={false} />
              </div>
              <div className="form-group">
                <label >Display Timer in Seconds</label>
                <select className="form-control">
                  {_.times(14, i =>
                    <option key={i+2} defaultValue={i+2}>{i+2}</option>
                  )}
                </select>
              </div>
              <div className="form-group">
                <label className="right switch">
                  <input type="checkbox" />
                  <span className="slider round"></span>
                </label>
                Enable Parked Destination Announcement
              </div>
          </Modal.Body>
          <Modal.Footer>
              <hr />
              <div className="btns">
                <Button className="btn">Save</Button>
                <Button className="btn" click={this.handleClose}>Cancel</Button>
              </div>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }

};

export default locationCallPark;
