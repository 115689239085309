import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { Button } from '@progress/kendo-buttons-react-wrapper';
import { MultiSelect } from '@progress/kendo-dropdowns-react-wrapper';


class receptionistDashboardModal extends Component {

  constructor(props) {
    super(props);

    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);

    this.state = { showModal: false };
  }

  handleClose() {
    this.setState({ showModal: false });
  }

  handleShow() {
    this.setState({ showModal: true });
  }

  componentDidMount() {   
  }

  render() {
    return (
      <div className="setting">
        <span onClick={this.handleShow}> 
          <span className="name">Receptionist Dashboard</span>
          <a className="icon btn"><svg width="14" height="14" viewBox="0 0 24 24"><path d="m163 440l-91-91 251-250 90 90z m309-352l-48-48c-12-11-32-11-45 2l-45 45 91 91 45-45c13-13 13-33 2-45z m-408 275l-32 117 117-32z" transform="scale(0.046875 0.046875)"></path></svg></a>
        </span>

        <Modal show={this.state.showModal} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Receptionist Dashboard</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <p>Select users whom you want to monitor presence status.</p>
              <a className="no-btn">Go to Client App</a>
              <hr />
              <div className="multiselect-group form-group">
                <label>Monitored Users:</label>
                <MultiSelect autoClose={false} dataSource={["User 1", "User 2", "User 3", "User 4", "User 2", "User 3", "User 4", "User 2", "User 3", "User 4", "User 2", "User 3", "User 4", "User 2", "User 3", "User 4", "User 2", "User 3", "User 4", "User 2", "User 3", "User 4", "User 2", "User 3", "User 4", "User 2", "User 3", "User 4", "User 2", "User 3", "User 4", "User 2", "User 3", "User 4", "User 2", "User 3", "User 4", "User 2", "User 3", "User 4", "User 2", "User 3", "User 4", "User 2", "User 3", "User 4", "User 2", "User 3", "User 4", "User 2", "User 3", "User 4", "User 2", "User 3", "User 4", "User 2", "User 3", "User 4", "User 2", "User 3", "User 4", "User 2", "User 3", "User 4", "User 2", "User 3", "User 4", "User 2", "User 3", "User 4", "User 2", "User 3", "User 4", "User 2", "User 3", "User 4", "User 2", "User 3", "User 4", "User 2", "User 3", "User 4", "User 2", "User 3", "User 4", "User 2", "User 3", "User 4", "User 2", "User 3", "User 4", "User 2", "User 3", "User 4", "User 2", "User 3", "User 4", "User 2", "User 3", "User 4", "User 2", "User 3", "User 4", "User 2", "User 3", "User 4", "User 2", "User 3", "User 4"]}/>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
              <hr />
              <div className="btns">
              <Button className="btn">Save</Button>
              <Button className="btn" click={this.handleClose}>Close</Button>
              </div>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
};

export default receptionistDashboardModal;
