import React, { Component, useEffect, useState } from "react";
import * as d3 from "d3";
import { setAxis } from "./components/axis";
import { setArea } from "./components/area";
import { setTools } from "./components/tools";
import { setLegend } from "./components/legend";

export const DataViz = (props) => {
  const [data1, setData1] = useState(props.data1);
  const [data2, setData2] = useState(props.data2);
  const [data] = useState(props.data1.concat(props.data2));
  const [dateRange] = useState(props.DateRange)
  const [summary] = useState(props.summary);
  const [hasRendered, setHasRendered] = useState(false);
  //

  useEffect(() => {
    if (!hasRendered) { // TODO: trigger re-redner on screen resize
      drawViz();
      setHasRendered(true);
    }
  }, [data1, data2, data, summary, hasRendered]);

  const drawViz = () => {
    const margin = { top: 10, right: 30, bottom: 20, left: 50 };
    const width = 600;
    const height = 400;
    // append the svg object to the body of the page
    const svg = d3
      .select(`#${props.id}`)
      .append("svg")
      .style("overflow", "visible")
      .attr("preserveAspectRatio", "xMinYMin meet")
      .attr("viewBox", props.aspect)
      .append("g")
      .attr("transform", "translate(" + margin.left + "," + margin.top + ")");
    // Determine the maximum and minimum values of all the data points
    const maxValue = d3.max(data, (d) => +d.y);
    const minValue = d3.min(data, d => d.y !== '' && d.y !== null ? +d.y : undefined);
    // Set x-scale
    const x = d3
      .scaleTime()
      .domain(
        d3.extent(data, function (d) {
          return d.x;
        })
      )
      .range([0, width]);
    // Set the y-scale
    const y = d3
      .scaleLinear()
      .domain([0, maxValue])
      .range([height - margin.bottom - margin.top, 0])
      .nice();
    // chart options
    let chartOptions = {
      svg: svg,
      data1: data1,
      data2: data2,
      data: data,
      type: props.Type,
      width: width,
      height: height,
      margin: margin,
      x: x,
      y: y,
      id: props.id,
      summary: summary,
      deviceName: props.DeviceName,
      node: props.Node,
      color1: "#77B6EA",
      color2: "#545454",
      dateRange: dateRange,
      axisLabel: props.axisLabel
    }
    // set chart axis
    setAxis(chartOptions);
    // set chart areas and gradients
    setArea(chartOptions);
    // set chart tools
    setTools(chartOptions);
    // set chart legend
    setLegend(chartOptions);
  };


  return (
    <div>
      <div id={props.id}></div>
      <div className="circuit-legend-container" style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: '50%' }}>
        <div id={`${props.id}-legend`}></div>
      </div>
    </div>
  )
};