import React, { useEffect, useState, useRef } from "react";
import { MultiSelect } from "@progress/kendo-react-dropdowns";
import { Button } from "@progress/kendo-react-buttons";
import * as apiCalls from "../../../requests/api_calls";
import { filterBy } from "@progress/kendo-data-query";
import * as config from "../../../requests/config";
import { Upload } from "@progress/kendo-react-upload";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { DataLoadingSpinner } from "../../../components/spinners";
import { Modal } from "react-bootstrap";
import * as helper from "../../../scripts/helper";
import moment from "moment";

// TODO: Eval to merge with the other AutoAttendantRepoManagerV2 file
// as the other file differs with setting the section to collapse by default
// Note: This file is included in Iframe page so changes will replicate to
// all views that uses Iframe and displays this modal/section
const AutoAttendantRepoManager = () => {
  const [locValues, setLocValues] = useState([]);
  const [selectedLocs, setSelectedLocs] = useState([]);
  const [hrsValues, setHrsValues] = useState({
    business: false,
    after: false,
    holidays: false,
  });
  const [selectAll, setSelectAll] = useState({
    locSelect: false,
    hrsSelect: false,
  });
  const [loading, setLoading] = useState({
    loadingOverrideRepo: false,
    loadingAAList: false,
    loadingAAAOverride: false,
    load: false,
  });
  const [fetchMsg, setFetchMsg] = useState({
    Submission: false,
    submitError: false,
    msgDetails: "",
  });
  const [dispErr, setDispErr] = useState({
    filesMsg: false,
    applyMsg: false,
    fileClickMsg: false,
    deleteMsg: false,
  });
  const [showDeletePrompt, setShowDeletePrompt] = useState(false);
  const [serviceList, setServiceList] = useState([]);
  const hoursData = ["Business Hours", "After Hours", "Holidays"];
  const [files, setFiles] = useState([]);
  const locationsData1 = sessionStorage.locationList ? JSON.parse(sessionStorage.locationList) : [];
  const [filteredLoc, setFilteredLoc] = useState(locationsData1?.slice());
  const [fileName, setFileName] = useState("");
  const [uploadFile, setUploadFile] = useState([]);
  const [fileSelected, setFileSelected] = useState("All");
  const [getFilesUpdate, setGetFilesUpdate] = useState(false);
  const uploadRef = useRef(null);
  const [deleteFileName, setDeleteFileName] = useState("");

  const [isCollapsedTwo, setIsCollapsedTwo] = useState(true);
  const [isCollapsedOne, setIsCollapsedOne] = useState(true);

  const [isCollapsedThree, setIsCollapsedThree] = useState(true);
  const [isCollapsedSeven, setIsCollapsedSeven] = useState(true);

  const toggleCollapseSeven = () => {
    setIsCollapsedSeven(!isCollapsedSeven);
  };
  const toggleCollapseOne = () => {
    setIsCollapsedOne(!isCollapsedOne);
  };
  const toggleCollapseTwo = () => {
    setIsCollapsedTwo(!isCollapsedTwo);
  };
  const toggleCollapseThree = () => {
    setIsCollapsedThree(!isCollapsedThree);
  };
  const handleUpload = (event) => {
    const allowedExtensions = [".WMA", ".WAV", ".3GP", ".MOV", ".wma", ".wav", ".3gp", ".mov"]; // Allowed file extensions
    const selectedFiles = event.affectedFiles[0];
    // Check if any of the selected files have an invalid extension
    const invalidFiles = event.affectedFiles.filter((file) => {
      console.log(file.extension);
      return !allowedExtensions.includes(file.extension);
    });

    if (invalidFiles.length > 0) {
      alert("Invalid file extension. Only WAV, WMA, 3GP, and MOV files are allowed.");
      setUploadFile([]);
      setFileName("");
      uploadRef.current.onClear();
    } else if (selectedFiles) {
      event.affectedFiles
        .filter((file) => !file.validationErrors)
        .forEach((file) => {
          const reader = new FileReader();
          reader.onload = (event) => {
            const fileContent = reader.result.split(",")[1];
            setUploadFile(fileContent);
            setFileName(file.name);
          };
          reader.readAsDataURL(file.getRawFile());
        });
    }
  };

  const handleClearButton = (e) => {
    setUploadFile([]);
    setFileName("");
    uploadRef.current.onClear();
  };

  const handlePost = () => {
    addFiles(fileName, uploadFile);
  };

  const getFiles = () => {
    let apiPath = "/announcement/enterprise/GetFiles";
    setLoading((prevLoading) => ({
      ...prevLoading,
      load: true,
    }));

    setGetFilesUpdate(false);
    apiCalls
      .fetchData(apiPath)
      .then((data) => {
        if (data !== null) {
          setLoading((prevLoading) => ({
            ...prevLoading,
            load: false,
          }));
          setFiles(data);
        }
      })
      .catch((e) => {
        setLoading((prevLoading) => ({
          ...prevLoading,
          load: false,
        }));
        setFiles([]);
        console.error(e);
      });
  };

  const addFiles = async (name, content) => {
    const apiPath = "announcement/enterprise/Add";
    const req = {
      Name: name,
      Content: content,
    };
    setLoading((prevLoading) => ({
      ...prevLoading,
      loadingOverrideRepo: true,
    }));

    setDispErr({ filesMsg: true });
    let response = await apiCalls
      .post(apiPath, "POST", JSON.stringify(req))
      .then((res) => res)
      .catch((err) => {
        setLoading((prevLoading) => ({
          ...prevLoading,
          loadingOverrideRepo: false,
        }));
        console.error(err);
      });
    if (response?.ok) {
      if (!response.message.includes("Error")) {
        setLoading((prevLoading) => ({
          ...prevLoading,
          loadingOverrideRepo: false,
        }));
        setGetFilesUpdate(true);
        setFetchMsg({
          Submission: true,
          submitError: false,
          msgDetails: name + " uploaded successfully",
          verify: true,
        });
      }
    } else {
      setLoading((prevLoading) => ({
        ...prevLoading,
        loadingOverrideRepo: false,
      }));
      let msg = response?.message;
      let i = msg?.indexOf(":");
      let i2 = msg?.indexOf("UserId");
      let result = msg?.slice(i + 1, i2);
      setFetchMsg({
        Submission: true,
        submitError: true,
        msgDetails: result && result,
      });
      setUploadFile([]);
      setFileName("");
      uploadRef.current.onClear();
    }
  };

  const deleteFiles = (name) => {
    let req = {
      Name: name,
    };
    setLoading((prevLoading) => ({
      ...prevLoading,
      load: true,
    }));

    let apiPath = "announcement/enterprise/Delete";
    apiCalls
      .send(apiPath, "DELETE", JSON.stringify(req))
      .then((data) => {
        if (data) {
          setLoading((prevLoading) => ({
            ...prevLoading,
            load: false,
          }));
          getFiles();
          setFetchMsg({
            Submission: true,
            submitError: false,
            msgDetails: "File deleted successfully.",
            verify: true,
          });
        } else {
          setFetchMsg({
            Submission: true,
            submitError: true,
            msgDetails: "File could not be deleted.",
          });
        }
      })
      .catch((message) => {
        setLoading((prevLoading) => ({
          ...prevLoading,
          load: false,
        }));
        setFetchMsg({
          Submission: true,
          submitError: true,
          msgDetails: "An error occurred while deleting the file. Please try again",
        });
      });
  };

  const announceSelect = (e) => {
    if (e.target.value === "All") {
      setFileSelected(files);
    }
    setFileSelected(e.target.value);
  };

  const onApply = async () => {
    if (
      (hrsValues.business || hrsValues.after || hrsValues.holidays) &&
      (selectAll.locSelect || selectedLocs.length != 0) &&
      (!fileSelected || fileSelected !== "All")
    ) {
      let dataItemKeys = [];
      if (selectAll.locSelect) {
        dataItemKeys = locationsData1.map((item) => item.LocationAccountNumber);
      }
      let req = {
        AnnouncementName: fileSelected,
        Locations: selectAll.locSelect ? dataItemKeys : selectedLocs,
        BusinessHours: hrsValues.business,
        AfterHours: hrsValues.after,
        Holidays: hrsValues.holidays,
        AutoAttendants: null,
      };
      setLoading((prevLoading) => ({
        ...prevLoading,
        loadingAAAOverride: true,
      }));
      setDispErr({ applyMsg: true });
      let apiPath = "announcement/enterprise/ApplyAutoAttendant";
      let response = await apiCalls
        .post(apiPath, "POST", JSON.stringify(req))
        .then((res) => {
          if (res?.ok) {
            setLoading((prevLoading) => ({
              ...prevLoading,
              loadingAAAOverride: false,
            }));
            onCancel();
            if (!response.message.includes("Error")) {
              setFetchMsg({
                Submission: true,
                submitError: false,
                msgDetails: "Successfully updated the request.",
              });
            }
          } else {
            setLoading((prevLoading) => ({
              ...prevLoading,
              loadingAAAOverride: false,
            }));
            onCancel();
            setFetchMsg({
              Submission: true,
              submitError: true,
              msgDetails: "Update failed, please try again.",
            });
          }
        })
        .catch((err) => {
          setLoading((prevLoading) => ({
            ...prevLoading,
            loadingAAAOverride: false,
          }));
          console.error(err);
        });
    } else {
      alert("Please select all the required fields.");
    }
  };

  const onCancel = () => {
    setLocValues([]);
    setHrsValues({
      business: false,
      after: false,
      holidays: false,
    });
    setSelectAll({
      locSelect: false,
      hrsSelect: false,
    });
    setFileSelected("All");
  };

  const handleChange = (e) => {
    let { checked, name } = e.target;
    name = name.toLowerCase().split(" ")[0];
    switch (name) {
      case "business":
        setHrsValues({ ...hrsValues, business: checked ? true : false });
        break;
      case "after":
        setHrsValues({ ...hrsValues, after: checked ? true : false });
        break;
      case "holidays":
        setHrsValues({ ...hrsValues, holidays: checked ? true : false });
        break;
      default:
        setHrsValues({ ...hrsValues });
    }
  };

  const handleSelectAllChange = (event) => {
    event.target.name === "locations"
      ? setSelectAll({
          ...selectAll,
          locSelect: event.target.checked,
        })
      : setSelectAll({
          ...selectAll,
          hrsSelect: event.target.checked,
        });
    event.target.name === "hours"
      ? setHrsValues({
          business: event.target.checked ? true : false,
          after: event.target.checked ? true : false,
          holidays: event.target.checked ? true : false,
        })
      : setLocValues(event.target.checked ? locationsData1 && locationsData1 : []);
  };

  const handleValueChange = (event) => {
    let val = event.target.value;
    const dataItemKeys = val.map((item) => item.LocationAccountNumber);
    setLocValues(val);
    setSelectedLocs(dataItemKeys);
    setSelectAll({
      ...selectAll,
      locSelect: event.target.value?.length === locationsData1?.length,
    });
  };

  const filterChange = (event) => {
    setFilteredLoc(filterBy(locationsData1?.slice(), event.filter));
  };

  const CustomClickableCell = ({ dataItem }) => {
    const getUsedServiceList = async (name, content) => {
      try {
        let req = {
          Name: name,
          Content: content,
        };
        setLoading((prevLoading) => ({
          ...prevLoading,
          loadingAAList: true,
        }));

        setDispErr({ fileClickMsg: true });
        let apiPath = "announcement/enterprise/GetServicesList";
        let response = await apiCalls
          .post(apiPath, "POST", JSON.stringify(req))
          .then((data) => data)
          .catch((err) => console.error(err));
        if (response?.ok && helper.IsJsonString(response?.message)) {
          let messageObj = JSON.parse(response.message);
          setLoading((prevLoading) => ({
            ...prevLoading,
            loadingAAList: false,
          }));
          setFetchMsg({
            Submission: true,
            submitError: false,
            msgDetails: "Successfully fetched services.",
            verify: true,
          });
          setServiceList(messageObj);
          setShowDeletePrompt(true);
          setDeleteFileName(name);
        } else {
          setLoading((prevLoading) => ({
            ...prevLoading,
            loadingAAList: false,
          }));
          setFetchMsg({
            Submission: true,
            submitError: true,
            msgDetails: "Error occurred while fetching for services list.",
          });
        }
      } catch (err) {
        setLoading((prevLoading) => ({
          ...prevLoading,
          loadingAAList: false,
        }));
        setFetchMsg({
          Submission: true,
          submitError: true,
          msgDetails: err,
        });
        console.error(err);
      }
    };

    const handleClick = () => {
      getUsedServiceList(dataItem.Name, dataItem.Content);
    };

    return (
      <td>
        <a className="clickable-cell" onClick={handleClick}>
          {dataItem.Name}
        </a>
      </td>
    );
  };

  const submissionMsg = () => {
    if (fetchMsg.Submission) {
      if (fetchMsg.submitError) {
        return (
          <p
            style={{
              color: "red",
              fontSize: "1.2rem",
            }}
          >
            {fetchMsg.msgDetails}
          </p>
        );
      } else {
        return (
          <p
            style={{
              color: "green",
              fontSize: "1.2rem",
            }}
          >
            {fetchMsg.msgDetails}
          </p>
        );
      }
    }
  };
  const handleCancelDelete = () => {
    setShowDeletePrompt(false);
  };

  const handleConfirmedDelete = () => {
    if (serviceList.length < 1 && deleteFileName !== "") {
      setShowDeletePrompt(false);
      deleteFiles(deleteFileName);
    }
  };

  useEffect(() => {
    getFiles();
    window.scrollTo(0, 0);
  }, [getFilesUpdate]);

  return (
    <>
      <Modal
        dialogClassName="confirm-cancel-macd"
        show={showDeletePrompt}
        onHide={handleCancelDelete}
      >
        <Modal.Header closeButton style={{ paddingBottom: "10px" }}>
          <Modal.Title>Delete File</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ paddingtop: "0px" }}>
          {serviceList.length < 1 && (
            <div style={{ fontSize: "small" }}>
              <p>Are you sure you wish to remove this contact from Enterprise Directory?</p>
              <p style={{ padding: "15px", textAlign: "center", border: "1px solid #000" }}>
                {deleteFileName}{" "}
              </p>
            </div>
          )}
          {serviceList.length > 0 && (
            <Grid
              data={serviceList && serviceList}
              sortable={{ allowUnsort: true, mode: "single" }}
            >
              <GridColumn field="ServiceName" title="Name" />
              <GridColumn field="Location" title="Location ID" />
              <GridColumn field="InstanceName" title="Location Name" />
            </Grid>
          )}
        </Modal.Body>
        <Modal.Footer>
          {serviceList.length > 0 && (
            <p style={{ color: "red", marginBottom: "0px" }}>
              File Can't be deleted while being used
            </p>
          )}
          <Button disabled={!!serviceList.length} onClick={handleConfirmedDelete}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
      <div
        className="panel-group settings"
        id="accordion7"
        role="tablist"
        aria-multiselectable="true"
      >
        <div className="panel panel-default">
          <div className="panel-heading" role="tab" id="headingOne">
            <a
              role="button"
              data-toggle="collapse"
              data-parent="#accordion7"
              href="#collapseSeven"
              aria-expanded={!isCollapsedSeven}
              onClick={toggleCollapseSeven}
              aria-controls="collapseOne"
            >
              <p
                className="title"
                style={{
                  width: "100%",
                  textAlign: "left",
                  fontFamily: "arial, sans-serif",
                  color: "#555",
                  fontSize: "13px",
                  fontWeight: "bold",
                  marginBottom: "0",
                }}
              >
                Auto Attendant Override
                <span style={{ float: "right" }}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 16 13"
                    width="18"
                    height="18"
                    fill="rgb(101, 101, 101)"
                    stroke="rgb(101, 101, 101)"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    style={{ transform: isCollapsedSeven ? "rotate(0deg)" : "rotate(180deg)" }}
                  >
                    <path
                      fill="rgb(101, 101, 101)"
                      d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"
                    />
                  </svg>
                </span>
              </p>
            </a>
          </div>
          <div
            id="collapseSeven"
            className="panel-collapse collapse"
            role="tabpanel"
            aria-labelledby="headingOne"
          >
            <div
              className="panel-body"
              style={{
                fontSize: "13px",
                fontWeight: "normal",
                color: "#555",
              }}
            >
              <br />
              <div
                className="panel-group settings"
                id="accordion3"
                role="tablist"
                aria-multiselectable="true"
                style={{
                  padding: "0% 1%",
                }}
              >
                <div className="panel panel-default">
                  <div className="panel-heading" role="tab" id="headingOne">
                    <a
                      role="button"
                      data-toggle="collapse"
                      data-parent="#accordion3"
                      href="#collapseOne"
                      aria-expanded={!isCollapsedOne}
                      onClick={toggleCollapseOne}
                      aria-controls="collapseOne"
                    >
                      <p
                        className="title"
                        style={{
                          width: "100%",
                          textAlign: "left",
                          fontFamily: "arial, sans-serif",
                          color: "#555",
                          fontSize: "13px",
                          fontWeight: "bold",
                          marginBottom: "0",
                        }}
                      >
                        Enterprise AA Announcement Override Repository
                        <span style={{ float: "right" }}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 16 13"
                            width="16"
                            height="16"
                            fill="none"
                            stroke="#6c8bad"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            style={{
                              transform: isCollapsedOne ? "rotate(0deg)" : "rotate(180deg)",
                            }}
                          >
                            <path d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" />
                          </svg>
                        </span>
                      </p>
                    </a>
                  </div>
                  {loading.loadingOverrideRepo ? (
                    <DataLoadingSpinner
                      style={{
                        position: "relative",
                        left: "25%",
                        display: "inline",
                      }}
                      className="load spinner"
                    />
                  ) : (
                    <div
                      id="collapseOne"
                      className="panel-collapse collapse"
                      role="tabpanel"
                      aria-labelledby="headingOne"
                    >
                      <div
                        className="panel-body"
                        style={{
                          fontSize: "13px",
                          fontWeight: "normal",
                          color: "#555",
                        }}
                      >
                        {dispErr.filesMsg && submissionMsg()}
                        <p>
                          Allowed file formats are: WMA, WAV, 3GP, MOV.
                          <br />
                          Max file size for audio announcements is 5MB in CCIT u-Law 8kHz, 8bit Mono
                          format
                        </p>
                        <Upload
                          defaultFiles={[]}
                          ref={uploadRef}
                          batch={false}
                          multiple={false}
                          async={{
                            saveUrl: `${config.baseApiUrl}/announcement/enterprise/Add`,
                            autoUpload: false,
                          }}
                          showFileList={false}
                          showActionButtons={false}
                          onAdd={handleUpload}
                          withCredentials={false}
                          restrictions={{
                            maxFileSize: 5000000,
                          }}
                          key="uploader1"
                        />
                        {fileName && <p>{fileName}</p>}
                        <Button onClick={handleClearButton} disabled={!fileName}>
                          Clear
                        </Button>
                        <Button onClick={handlePost} disabled={!fileName}>
                          Upload
                        </Button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div
                className="panel-group settings"
                id="accordion2"
                role="tablist"
                aria-multiselectable="true"
                style={{
                  padding: "0% 1%",
                }}
              >
                <div className="panel panel-default">
                  <div className="panel-heading" role="tab" id="headingOne">
                    <a
                      role="button"
                      data-toggle="collapse"
                      data-parent="#accordion2"
                      href="#collapseTwo"
                      aria-expanded={!isCollapsedTwo}
                      onClick={toggleCollapseTwo}
                      aria-controls="collapseOne"
                    >
                      <p
                        className="title"
                        style={{
                          width: "100%",
                          textAlign: "left",
                          fontFamily: "arial, sans-serif",
                          color: "#555",
                          fontSize: "13px",
                          fontWeight: "bold",
                          marginBottom: "0",
                        }}
                      >
                        Auto Attendant Repository List
                        <span style={{ float: "right" }}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 16 13"
                            width="16"
                            height="16"
                            fill="none"
                            stroke="#6c8bad"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            style={{
                              transform: isCollapsedTwo ? "rotate(0deg)" : "rotate(180deg)",
                            }}
                          >
                            <path d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" />
                          </svg>
                        </span>
                      </p>
                    </a>
                  </div>
                  {loading.loadingAAList ? (
                    <DataLoadingSpinner
                      style={{
                        position: "relative",
                        left: "25%",
                        display: "inline",
                      }}
                      className="load spinner"
                    />
                  ) : (
                    <div
                      id="collapseTwo"
                      className="panel-collapse collapse"
                      role="tabpanel"
                      aria-labelledby="headingOne"
                    >
                      <div
                        className="panel-body"
                        style={{ fontSize: "13px", fontWeight: "normal", color: "#555" }}
                      >
                        To delete a file please click on the filename.
                        <br />
                        <br />
                        {dispErr.fileClickMsg && submissionMsg()}
                        <Grid data={files} sortable={{ allowUnsort: true, mode: "single" }}>
                          <GridColumn field="Name" title="File Name" cell={CustomClickableCell} />
                          <GridColumn
                            field="FileSize"
                            title="File Size (KB)"
                            cell={(props) => (
                              <td>{props.dataItem[props.field].toLocaleString("en-US")}</td>
                            )}
                          />
                          <GridColumn
                            field="Uploaded"
                            title="Date"
                            cell={(props) => (
                              <td>
                                {moment(props.dataItem[props.field]).utc().format("MM-DD-YYYY")}
                              </td>
                            )}
                          />
                        </Grid>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div
                className="panel-group settings"
                id="accordion"
                role="tablist"
                aria-multiselectable="true"
                style={{
                  padding: "0% 1%",
                }}
              >
                <div className="panel panel-default">
                  <div className="panel-heading" role="tab" id="headingOne">
                    <a
                      role="button"
                      data-toggle="collapse"
                      data-parent="#accordion"
                      href="#collapseThree"
                      aria-expanded={!isCollapsedThree}
                      onClick={toggleCollapseThree}
                      aria-controls="collapseOne"
                    >
                      <p
                        className="title"
                        style={{
                          width: "100%",
                          textAlign: "left",
                          fontFamily: "arial, sans-serif",
                          color: "#555",
                          fontSize: "13px",
                          fontWeight: "bold",
                          marginBottom: "0",
                        }}
                      >
                        Enterprise AA Announcement Override
                        <span style={{ float: "right" }}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 16 13"
                            width="16"
                            height="16"
                            fill="none"
                            stroke="#6c8bad"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            style={{
                              transform: isCollapsedThree ? "rotate(0deg)" : "rotate(180deg)",
                            }}
                          >
                            <path d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" />
                          </svg>
                        </span>
                      </p>
                    </a>
                  </div>
                  {loading.loadingAAAOverride ? (
                    <DataLoadingSpinner
                      style={{
                        position: "relative",
                        left: "25%",
                        display: "inline",
                      }}
                      className="load spinner"
                    />
                  ) : (
                    <div
                      id="collapseThree"
                      className="panel-collapse collapse"
                      role="tabpanel"
                      aria-labelledby="headingOne"
                    >
                      <div
                        className="panel-body"
                        style={{
                          fontSize: "13px",
                          fontWeight: "normal",
                          color: "#555",
                        }}
                      >
                        {dispErr.applyMsg && submissionMsg()}
                        <form method="POST">
                          Select an announcement from the drop-down and then select one or more
                          locations/groups from those displayed below.
                          <br />
                          The selected announcement will override normal announcement/prompts for
                          all Auto-Attendants for the selected locations/groups while they continue
                          to be selected here.
                          <br />
                          <br />
                          <div className="multiselect-group form-group">
                            <label>Select Announcement File:</label>
                            <select
                              style={{
                                height: "20px",
                                maxWidth: "95px",
                              }}
                              data={files.name}
                              onChange={(e) => announceSelect(e)}
                              value={fileSelected}
                            >
                              <option key="file" value="All">
                                All
                              </option>
                              {files.map((item, index) => {
                                return (
                                  <option key={`file${index}`} value={item.Name}>
                                    {item.Name}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                          <br />
                          <div className="row">
                            <div className="col-md-6" style={{ marginBottom: "15px" }}>
                              <label>Select Locations:</label>&nbsp;&nbsp;
                              <input
                                name="locations"
                                value={selectAll.locSelect}
                                style={{
                                  textAlign: "center",
                                  verticalAlign: "middle",
                                  marginTop: "-2px",
                                }}
                                type="checkbox"
                                checked={selectAll.locSelect}
                                onChange={handleSelectAllChange}
                              />
                              &nbsp;<label>Select All</label>
                              <MultiSelect
                                data={filteredLoc?.length > 0 ? filteredLoc : []}
                                value={locValues}
                                textField="LocationName"
                                dataItemKey="LocationAccountNumber"
                                onChange={handleValueChange}
                                filterable={true}
                                key={locValues.LocationId}
                                onFilterChange={filterChange}
                                size="medium"
                                fillMode="outline"
                                rounded="large"
                              />
                            </div>
                            <div className="col-md-6">
                              <label>Select Prompt Type:</label>&nbsp;&nbsp;
                              <input
                                name="hours"
                                value={selectAll.hrsSelect}
                                style={{
                                  textAlign: "center",
                                  verticalAlign: "middle",
                                  marginTop: "-2px",
                                }}
                                type="checkbox"
                                checked={selectAll.hrsSelect}
                                onChange={handleSelectAllChange}
                              />
                              &nbsp;<label>Select All</label>
                              {hoursData.map((item, i) => {
                                let itemName = item.toLowerCase().split(" ")[0];
                                return (
                                  <div style={{ marginLeft: "10.4rem" }} id={item}>
                                    <input
                                      style={{
                                        textAlign: "center",
                                        verticalAlign: "middle",
                                        marginTop: "-2px",
                                      }}
                                      className="form-check-input"
                                      type="checkbox"
                                      name={item}
                                      value={hrsValues[itemName]}
                                      id={item}
                                      key={itemName + i}
                                      onChange={handleChange}
                                      checked={hrsValues[itemName]}
                                      disabled={selectAll.hrsSelect ? true : false}
                                    />
                                    &nbsp;
                                    <label className="form-check-label">{item}</label>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                          <div className="row text-center">
                            <Button key="apply" type="button" className="btn" onClick={onApply}>
                              Save
                            </Button>
                            <Button key="cancel" type="button" className="btn" onClick={onCancel}>
                              Cancel
                            </Button>
                          </div>
                        </form>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AutoAttendantRepoManager;
