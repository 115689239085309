import React, { Component } from 'react';
import { version } from './../requests/config';

export default class Footer extends Component {
    render() {
        return (
            <div className="footer-container" style={{ textAlign: 'center' }}>
                <div className="version-number-container" style={{ display: 'inline-block' }}>{version}</div>
            </div>
        )
    }
}