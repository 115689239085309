import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { Button } from '@progress/kendo-buttons-react-wrapper';
import { Grid, GridColumn as Column, GridCell } from '@progress/kendo-react-grid';
import { orderBy } from '@progress/kendo-data-query';
import { DataLoadingSpinner } from'./../../../components/spinners';
import { historyBtn } from './../../../scripts/icons';
import moment from 'moment';
import * as apiCalls from'./../../../requests/api_calls';
import { onlyUnique } from './../devices_helpers';

class AssignedToLabel extends GridCell {
  render() {
    return <td>{this.props.dataItem.FirstName} {this.props.dataItem.LastName}</td>
  }
}

class RequestSentLabel extends GridCell {
  render() {
    return <td>{moment.utc(this.props.dataItem.RequestSent).format('M-D-YYYY, hh:mm A')} </td>
  }
}

class DeviceHistoryModal extends Component {

  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      sort: [{ field: "RequestSent", dir: "desc" }],
    };
    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.sortChange = this.sortChange.bind(this);
    this.sortHistory = this.sortHistory.bind(this);
  }

  componentDidMount() {
  }

  fetchDevicesHistory = (apiPath, showAll = false) => {
    this.setState({ loading: true });
    let params = '?mac=' + this.props.mac;
    apiCalls.fetchData('admin/GetDeviceHistoryByMac' + params).then((data) => {
      if (data !== null) {
        this.setState({
          loading: false,
          devicesHistory: this.sortHistory(this.state.sort, data)
        });
      } else if (data === null) {
        this.setState({ devicesHistory: [], loading: false });
      };
    }).catch((err) => {
      console.error(err);
    })
  }

  handleClose() {
    this.setState({ showModal: false });
  }

  handleShow() {
    this.setState({ showModal: true });
    this.fetchDevicesHistory();
  }

  sortChange(event) {
    this.setState({
      services: this.sortHistory(event.sort),
      sort: event.sort
    });
  }

  sortHistory(sort, history = null) {
    if (history == null) history = this.state.devicesHistory.slice();
    // added onlyUnique into devices_helper.js (bottom)x
    let uniqHistory = [...history.filter(onlyUnique)]
    return orderBy(uniqHistory, sort);
  }

  gridContent = () => {
    if (this.state.loading) {
      return <DataLoadingSpinner className='load spinner' />
    } else if (this.state.devicesHistory && this.state.devicesHistory.length) {
      return (
        <Grid
          style={{ height: this.state.gridHeight + 'px' }}
          data={this.state.devicesHistory}
        // sortable={{allowUnsort: true, mode: 'single'}}
        // sort={this.state.sort}
        // sortChange={this.sortChange}
        >
          <Column field='RequestSent' title='Time' cell={RequestSentLabel} />
          <Column field='RequestType' title='Type' />
          <Column title='Assigned To'  cell={AssignedToLabel} field='FirstName'/>
          <Column field='LocationName' title='Location' />
          <Column field='OperatorUserName' title='Username' />
          <Column field='MAC' title='Mac Address' />
          <Column field='ModelNumber' title='Device' />
        </Grid>
      )
    } else return <h4 className='text-center'>There is no device history for this device.</h4>
  }

  render() {

    return (
      <span>
        <span onClick={this.handleShow}>
          <a className="btn icon">{historyBtn}</a>
        </span>
        <Modal
          dialogClassName="device-history-modal"
          show={this.state.showModal}
          onHide={this.handleClose}
          backdrop='static'
        >
          <Modal.Header closeButton>
            <Modal.Title>Device History</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="card-content">
              {this.gridContent()}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <hr />
            <div className="btns">
              <Button className="btn" click={this.handleClose}>Close</Button>
            </div>
          </Modal.Footer>
        </Modal>
      </span>
    );
  }
};

export default DeviceHistoryModal;
