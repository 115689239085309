import React, { useState } from "react";
import { Button } from "@progress/kendo-buttons-react-wrapper";
import { DataLoadingSpinner } from "../../../../components/spinners";
import { post } from "../../../../requests/api_calls";

export const AddCallDispositionCodeItem = ({ onAdd }) => {
  const [newItem, setNewItem] = useState({});
  const [showAddItem, setShowAddItem] = useState(false);
  const [loading, setLoading] = useState(false);

  const addEntAgentUnavailableCode = async () => {
    await setLoading(true);
    const path = "admin/enterprise/AddCallDispositionCode";
    try {
      const response = await post(path, "POST", JSON.stringify(newItem));
      if (response.ok) {
        await setLoading(false);
        await onAdd();
      } else {
        console.error(`${path}: failed: ${response.message}`);
        await setLoading(false);
      }
    } catch (error) {
      console.error(`${path}: failed: ${error}`);
      await setLoading(false);
    }
  };

  if (loading) {
    return (
      <div className="content">
        <DataLoadingSpinner className="spinner" style={{ margin: "10px auto 0", height: "40px" }} />
      </div>
    );
  }
  return (
    <>
      <div style={{ margin: "5px 0" }}>
        <button
          className="btn"
          onClick={() => {
            setShowAddItem(!showAddItem);
          }}
        >
          Add Code
        </button>
      </div>
      {showAddItem && (
        <div>
          <div className="table-edit">
            <div className="form-group">
              <label className="switch right">
                <input
                  type="checkbox"
                  checked={newItem.IsActive}
                  onChange={(e) => setNewItem({ ...newItem, IsActive: e.target.checked })}
                />
                <span className="slider round"></span>
              </label>
              Activate Code
            </div>
            <div className="form-group">
              <label>Code</label>
              <input
                type="text"
                className="form-control"
                value={newItem.Code}
                onChange={(e) => setNewItem({ ...newItem, Code: e.target.value })}
              />
            </div>
            <div className="form-group">
              <label>Description</label>
              <input
                type="text"
                className="form-control"
                value={newItem.Description}
                onChange={(e) => setNewItem({ ...newItem, Description: e.target.value })}
              />
            </div>
            <div className="btns">
              <Button
                className="btn"
                click={() => {
                  addEntAgentUnavailableCode();
                }}
              >
                Save
              </Button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
