import React, { Component } from 'react';
import * as config from './../../../requests/config';
import * as apiCalls from './../../../requests/api_calls';
import { isRpxSession } from './../../../scripts/session';
import { Modal } from 'react-bootstrap';
import { Button } from '@progress/kendo-buttons-react-wrapper';
import * as help from'./../devices_helpers';
import SaveStatus from './../../../components/save_status';
import MacAdressFormats from './../components/macFormatsClickPop';
import { DataLoadingSpinner } from'./../../../components/spinners';

export const macAddress = 'macAddress';
export const location = 'location';
export const deviceType = 'deviceType';
export const acqCode = 'acqCode';
export const notes = 'notes';

const invalidFields = (props) => {
  if (help.addingDevice(props)) {
    let fields = [macAddress, deviceType, location];
    if (isRpxSession()) fields.push(acqCode);
    return fields;
  } else return [];
}

const initialFormState = (props) => {
  return {
    deviceProps: {},
    invalidFields: invalidFields(props),
    showRequiredFieldMsgs: false,
    showInvalidMacMsg: false,
    showValidMacMsg: false,
    showSaveStatus: false,
    error: false,
    saving: false,
    loadingLocations: true,
    statusMessage: null,
    initialValue: '',
    invalidMacMsg: 'This field is required.'
  }
}

class DeviceSettingsModal extends Component {

  constructor(props) {
    super(props);
    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.macAddressFieldValue = this.macAddressFieldValue.bind(this);
    this.showOriginalValues = this.showOriginalValues.bind(this);
    this.modalBody = this.modalBody.bind(this);
    this.state = initialFormState(props);
  }

  componentDidMount() {
    
  }

  fetchDeviceInfo = () => {
    let apiPath = 'admin/GetDevice?mac=' + this.props.mac;
    apiCalls.fetchData(apiPath).then((data) => {
      if (data !== null) {
        let defaultVal = '' // should not use null or undefined for select values
        let deviceProps = {
          macAddress: data.MAC,
          assigned: data.Assigned,
          location: data.LocationAccountNumber || defaultVal,
          acqCode: data.AcquisitionCode || defaultVal,
          deviceType: data.DeviceType || defaultVal,
          notes: data.Notes || defaultVal
        }
        this.setState({
          deviceProps: deviceProps, 
          originalDeviceProps: {...deviceProps} // save original device values for resetting
        });
      } else if (data === null) {
        // need error handler (not sure how data could be empty)
      };
    });
  }

  handleClose() {
    let state = {
      ...initialFormState(this.props),
      showModal: false, 
      deviceModalOptions: this.state.deviceModalOptions, 
      locationOptions: this.state.locationOptions
    };
    this.setState(state, this.props.loadDevices(false));
  }

  handleShow() {
    if (!this.state.deviceModalOptions) this.fetchDeviceModels();
    if (!this.state.locationOptions) this.fetchLocations() 
    else this.setState({loadingLocations: false});
    
    if (!help.addingDevice(this.props)) {
      (async () => await this.fetchDeviceInfo())();
    }
    if (!this.state.deviceProps.assigned) {
      let state = {
        showModal: true,
        deviceModalOptions: this.state.deviceModalOptions,
        locationOptions: this.state.locationOptions
      };
      this.setState(state);
    }
  }

  showOriginalValues = () => {
    const currentDeviceProps = {...this.state.deviceProps};
    currentDeviceProps.macAddress = '';
    currentDeviceProps.notes = '';

    let originalDeviceProps = this.state.originalDeviceProps;
    let newState = {
      ...initialFormState(this.props),
      deviceProps: currentDeviceProps,
      deviceModalOptions: this.state.deviceModalOptions,
      locationOptions: this.state.locationOptions
    };
    if (originalDeviceProps) {
      newState.deviceProps = newState.originalDeviceProps = originalDeviceProps;
    }
    newState.loadingLocations = false;
    newState.loading = false;
    let checkFields = newState.invalidFields.filter(res => res === "macAddress");
    newState.invalidFields = checkFields;
    this.setState(newState);
  }

  clearFormAndCloseModal = (showAll) => {
    this.props.loadDevices(showAll);
    this.setState({...initialFormState(this.props), showModal: false});
  }

  showFailedToSaveForm = () => {
    this.setState({
      showRequiredFieldMsgs: false,
      showSaveStatus: false,
      error: false,
      saving: false,
      statusMessage: null
    });
  }

  validForm = () => {
    if (!this.state.showRequiredFieldMsgs) this.setState({showRequiredFieldMsgs: true});
    let formIsEmpty = !Object.keys(this.state.deviceProps).length;
    return !formIsEmpty && !this.state.invalidFields.length;
  }

  postDeviceInput = (successAction, p) => {
    if (this.validForm()) {
      this.setState({saving: true, showSaveStatus: true});
      let data = JSON.stringify(this.deviceInputData());
      let apiPath = help.addingDevice(this.props) ? 'admin/AddDevice' : 'admin/UpdateDevice';
      apiCalls.post(apiPath, 'POST', data).then(resp => {
        if(resp.ok) {
          if (p)
            successAction(p);
          else
            successAction();
        } else {
          this.setState({
            saving: false,
            error: true,
            statusMessage: this.notSavedErrorMsg(resp),
            parentCallback: this.showOriginalValues
          })
        }
      }).catch(message => {
        console.log(message)
        this.setState({
          saving: false,
          error: true,
          statusMessage: 'Device not saved. Please try again.',
          parentCallback: this.showOriginalValues
        })
      });
    }
  }

  deviceInputData = () => {
    let data = {
      MAC: this.state.deviceProps[macAddress],
      DeviceType: this.state.deviceProps[deviceType],
      LocationAccountNumber: this.state.deviceProps[location],
      AcquisitionCode: this.state.deviceProps[acqCode] || 'Owned',
      Notes: this.state.deviceProps[notes]
    };
    if (help.editingDevice(this.props)) {
      // the original MAC does not change if editing a device
      data.MAC = this.props.mac;
      data.newMAC = this.state.deviceProps[macAddress];
    }
    return data;
  }

  notSavedErrorMsg = (resp) => {
    if (resp.message.indexOf("already exists") >= 0) {
      return "Device with mac "+ this.state.deviceProps[macAddress] + " already exists"
    } else {
      return 'Device not saved. Error: ' + resp.message;
    }
  }

  saveDevice = () => this.postDeviceInput(this.clearFormAndCloseModal, true);

  saveDeviceAndAddNew = () => this.postDeviceInput(this.showOriginalValues);

  setDeviceNotes = (e) => {
    let deviceProps = {...this.state.deviceProps}
    deviceProps[notes] = e.target.value || ''; // API rejects null for Notes
    this.setState({deviceProps: deviceProps});
  }

  fetchDeviceModels = () => {
    apiCalls.fetchData('admin/GetDeviceModels').then((data) => {
      if (data !== null) {
        let deviceModelOptions = data.map((deviceModel, index) => {
          return ( this.deviceModelOption(deviceModel, index) );
        });
        this.setState({deviceModelOptions: deviceModelOptions,  loading: false });
      } else if (data === null) {
        this.setState({deviceModelOptions: [], loading: false, resultsMsg: 'device models not found'});
      };
    });
  }

  deviceModelOption = (deviceModel, index) => {
    // typo fix
    let deviceType = deviceModel.DevicyType ? deviceModel.DevicyType : deviceModel.DeviceType;
    return <option key={index} value={deviceType} >{deviceType}</option>
  }

  fetchLocations = () => {
    apiCalls.fetchData('admin/Locations').then((data) => {
      if (data !== null) {
        let locationOptions = data.map((loc, i) => this.locationOption(loc, i) );
        this.setState({locationOptions: locationOptions, loadingLocations: false});
      } else {
        console.log('locations not returned')
        this.setState({loading: false});
      }
    });
  }

  locationOption = (location, index) => {
    return (
      <option key={index} value={location.LocationAccountNumber}>
        {location.LocationName} - {location.LocationAccountNumber}
      </option>
    )
  }

  acqCodeClass = () => isRpxSession() ? 'form-group' : 'hidden';

  acqCodeOptions = () => {
    // mapping since adjacent JSX elements must be wrapped but can only select around options
    return ['Rent', 'Purchase', 'Owned'].map((text, i) => <option key={i}>{text}</option>);
  }

  selectField = (fieldType, options) => {
    return (
      <span>
        <span className={help.invalidMessageClass(this.state, fieldType)}>
          This field is required.
        </span>
        <select 
          ref={fieldType}
          className="form-control"
          onChange={(e) => this.validateSelectField(e, fieldType)}
          value={this.selectFieldValue(fieldType)}
        >
          <option value="">-Select-</option>
          {options}
        </select>
      </span>
    )
  }

  deviceNotesField = () => {
    return(
      <div className='form-group'>
        <label>Device Notes</label>
        <input
          type="type"
          autoComplete={"off"}
          className="form-control"
          value={this.noteFieldValue()}
          onChange={this.setDeviceNotes}
        />
      </div>
    )
  }

  validateSelectField = (e, fieldName) => {
    let value = e.target.value;
    let newState = {...this.state};
    let newInvalidFields = this.state.invalidFields.slice();
    newState.deviceProps[fieldName] = value;
    if (value === '') {
      newInvalidFields.push(fieldName);
      newState.invalidFields = Array.from(new Set(newInvalidFields));
    } else {
      newState.invalidFields = newInvalidFields.filter(field => field !== fieldName);
    }
    this.setState(newState);
  }

  validateMacField = (e) => {
    let value = e.target.value;
    let newState = {...this.state};
    let newInvalidFields = this.state.invalidFields.slice();
    newState.deviceProps[macAddress] = value;
    if(!help.validMacAddress(value)) {
      newState.invalidMacMsg = 'The format is invalid. ';
      newState.showInvalidMacMsg = true;
      newInvalidFields.push(macAddress);
      newState.invalidFields = newInvalidFields;
    } else {
      newState.invalidFields = newInvalidFields.filter(field => field !== macAddress);
      newState.showInvalidMacMsg = false;
      newState.showValidMacMsg = true;
    }
    this.setState(newState);
  }

  macAddressFieldValue = () => {
    if (this.state.deviceProps && this.state.deviceProps[macAddress]) {
      return this.state.deviceProps[macAddress]
    } else return ''
  }

  selectFieldValue = (fieldType) => {
    if (this.state.deviceProps && this.state.deviceProps[fieldType]) {
      return this.state.deviceProps[fieldType]
    } else return ''
  }

  noteFieldValue = (fieldType) => {
    if (this.state.deviceProps && this.state.deviceProps[notes]) {
      return this.state.deviceProps[notes]
    } else return ''
  }

  modalBody = () => {
    let state = this.state;
    if (state.loading === true || state.loadingLocations === true) {
      return (
        <DataLoadingSpinner className="load spinner" />
      )
    } else {
      return (
        <div className='form-group'>
          {this.props.instructions}
          <hr />
          <form ref={'deviceForm'} className={help.formClass(state)}>
            <div className='form-group'>
              <label>MAC Address</label>
              <span className={help.validMacClass(state, macAddress)}>✓</span>
              <span className={help.invalidMessageClass(state, macAddress)}>
                {state.invalidMacMsg}
                <MacAdressFormats />
              </span>
              <input
                ref={macAddress}
                type="type"
                className="form-control"
                autoComplete={"off"}
                value={this.macAddressFieldValue()}
                onChange={this.validateMacField}
              />
            </div>
            <div className="form-group">
              <label>Device Type </label>
              {this.selectField(deviceType, state.deviceModelOptions)}
            </div>
            <div className="form-group">
              <label>Location </label>
              {this.selectField(location, state.locationOptions)}
            </div>
            <div className={this.acqCodeClass()}>
              <label>Acquisition Code  </label>
              {this.selectField(acqCode, this.acqCodeOptions() )}
            </div>
            {this.deviceNotesField()}
          </form>
        </div>
      )
    }    
  }

  render() {
    let state = this.state;
    return (
      <span>
        <span onClick={this.handleShow}>
          {this.props.showModalBtn}
        </span>
        <Modal
          dialogClassName="add-device-modal"
          show={state.showModal}
          onHide={this.handleClose}
          backdrop='static'
        >
          <Modal.Header closeButton>
            <Modal.Title>{this.props.title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.modalBody()}
          </Modal.Body>
          <Modal.Footer>
            <hr />
            <div className={help.saveBtnsClass(state)}>
              <Button className="btn" click={this.saveDevice}>Save</Button>
              <span className={help.saveAndAddNewClass(this.props)}>
                <Button click={this.saveDeviceAndAddNew}>Save & Add Another Device</Button>
              </span>
              <Button className="btn" click={this.handleClose}>Close</Button>
            </div>
            <SaveStatus 
              saving={state.saving}
              statusMessage={state.statusMessage}
              error={state.error}
              parentCallBack={state.parentCallback}
            />
          </Modal.Footer>
        </Modal>
      </span>
    );
  }
};

export default DeviceSettingsModal