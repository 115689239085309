import React, { Component } from 'react';
import { DataLoadingSpinner } from '../../components/spinners';
import { Grid, GridColumn as Column, GridCell } from '@progress/kendo-react-grid';
import { headerToolTip } from '../../components/ellipsisHeaderTooltip';
import * as apiCalls from '../../requests/api_calls';
import * as config from '../../requests/config';
import * as settings from '../../scripts/settings';
import { Modal } from 'react-bootstrap';

export default class ManageEntTrunkGroup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      TrunkGroupDetails: null,
      OriginalDetails: null,
      showConfirmationModal: false,
      DoaminList: [],
      DepartmentList: [],
      errorMsg: "",
      Submission: false,
      submitError: false,
    }

  }

  componentDidMount() {
    this.fetchTrunkGroup();
  };

  fetchDepartments = async (acctNum) => {
    await this.setState({ loading: true });
    let path = `admin/GetGroupDepartmentList?locationAccountNumber=${acctNum}&IncludeEnterpriseDepartments=true`;
    await apiCalls.fetchData(path).then(data => {
      if (data) {
        let copy = JSON.parse(JSON.stringify(data));
        copy = copy.map(itm => itm.Name);
        this.setState({
          DepartmentList: copy,
          loading: false,
        });
      } else {
        this.setState({
          DepartmentList: data,
          loading: false,
        });
      }
    }).catch(err => console.error(err));
  }

  fetchDomains = async (acctNum) => {
    await this.setState({ loading: true });
    let path = `admin/GetGroupDomainAssignedList?locationAccountNumber=${acctNum}`;
    await apiCalls.fetchData(path).then(data => {
      if (data) {
        let copy = JSON.parse(JSON.stringify(data));
        copy = copy.map(itm => itm.Domain);
        this.setState({
          DoaminList: copy,
          loading: false,
        });
      } else {
        this.setState({
          DoaminList: data,
          loading: false,
        });
      }
    }).catch(err => console.error(err));
  }

  fetchTrunkGroup = async () => {
    await this.setState({ loading: true });
    let { groupId, trunkGroupName } = this.props.match.params;
    let path = `admin/GetEnterpriseTrunkGroupInstance?groupId=${groupId}&trunkGroupName=${trunkGroupName}`;
    await apiCalls.fetchData(path).then(data => {
      if (data) {
        let copy = JSON.parse(JSON.stringify(data));
        this.setState({
          TrunkGroupDetails: data,
          OriginalDetails: copy,
          loading: false,
        });
        this.fetchDomains(data.GroupId);
        this.fetchDepartments(data.GroupId);
      } else {
        this.setState({
          TrunkGroupDetails: {},
          loading: false,
        });
      }
    }).catch(err => console.error(err));
  }

  radioDataField = (val, key, type = "radio", readOnly = false) => {
    return (
      <div className='form-group-wrapper'
        style={{
          width: '30%',
          display: 'inline-block',
          position: 'relative',
          top: '30px'
        }}
      >
        <input
          type={type}
          value={true}
          checked={val === true || val === "true" ? true : false}
          onChange={(e) => this.handleInputChange(e, key, type)}
          disabled={readOnly === true ? true : false}
        />
        <label htmlFor="default"
          style={{
            width: '50%',
          }}
        >Use default system policy</label><br />
        <input
          type={type}
          value={false}
          checked={val === true || val === "true" ? false : true}
          onChange={(e) => this.handleInputChange(e, key, type)}
          disabled={readOnly === true ? true : false}
        />
        <label htmlFor="trunkGroupPolicy"
          style={{
            width: '50%',
          }}
        >Use this trunk group policy</label><br />
      </div>
    )
  }

  dataDropDown = (val, key, options = [], readOnly = false) => {
    return (
      <div
        style={{
          display: 'inline-block',
        }}
      >
        <select className="form-control"
          onChange={(e) => this.handleInputChange(e, key)}
          value={val}
          disabled={readOnly === true ? true : false}
        >
          {key === 'PeeringDomain' ? <option value={null}>None</option> : null}
          {options && options.length > 0 ? options.map((itm, idx) => {
            return <option key={idx} value={key === 'PeeringDomain' || key === 'Department' ? itm : idx}>{itm}</option>

          }) : null}
        </select>
      </div>
    )
  }

  additionalDropField = (activateField, val, key, options = []) => {
    return (
      <div
        style={{
          display: 'inline-block',
          marginLeft: '30%',
          position: 'relative',
          top: '10px'
        }}
      >
        <select className="form-control"
          onChange={(e) => this.handleInputChange(e, key)}
          value={val}
          disabled={activateField === false || activateField === 'false' ? false : true}
        >

          {options && options.length > 0 ? options.map((itm, idx) => {
            return <option key={idx} value={idx}>{itm}</option>

          }) : null}
        </select>
      </div>
    )
  }

  additionalInputFeld = (activateField, val, key) => {
    return (
      <span>
        <input
          style={{
            width: '20%',
            display: 'inline-block',
            marginLeft: '30%',
          }}
          type="text"
          id={key}
          value={val}
          className='custom-input-class'
          onChange={(e) => this.handleInputChange(e, key)}
          disabled={activateField === true ? false : true}
          maxLength={key === "TrunkGroupPrefix" ? "15" : "none"}
        />
      </span>
    )
  }

  inputDataField = (val, key, type = 'text', readOnly = false) => {
    if (type === "text" || type === "number") {
      return (
        <input
          style={{
            width: type === "number" ? '5%' : "25%",
            display: 'inline-block',
            textAlign: type === "number" ? 'center' : "",
            cursor: readOnly === true ? "not-allowed" : "default"
          }}
          type="text"
          id={key}
          value={val}
          className='custom-input-class'
          onChange={(e) => this.handleInputChange(e, key, type)}
          disabled={readOnly === true ? true : false}
          maxLength={key === "NewAuthenticationPassword" || key === "AuthenticationUserName" || key === "OTGDTGIdentity" ? "30" : "none"}
        />
      )
    } else {
      return (
        <input
          style={{
            display: 'inline-block',
          }}
          type="checkbox"
          checked={val}
          className='check-box'
          onChange={(e) => this.handleInputChange(e, key, type)}
          disabled={readOnly === true ? true : false}
        />
      )
    }
  }

  trunkGroupDataRow = (label, field, additionalFeld = null) => {
    return (
      <div
        className='form-group-wrapper'
        style={{
          margin: '1%',
        }}
      >
        <label
          style={{
            display: 'inline-block',
            width: '30%',
          }}
        >{label}: </label>
        {field}
        {!additionalFeld ? null : <div><br />{additionalFeld}</div>}
      </div >
    )
  }


  handleInputChange = async (e, key, type = 'text') => {
    let value = type === 'checkbox' ? e.target.checked : e.target.value;
    if ((key === 'PeeringDomain' || key === 'Department') && value === 'None') {
      value = null;
    }
    let TrunkGroupDetails = { ...this.state.TrunkGroupDetails };
    TrunkGroupDetails[key] = value;
    await this.setState({ TrunkGroupDetails });
  }

  cardContent = () => {
    let { loading, TrunkGroupDetails, DoaminList, DepartmentList } = this.state;
    if (!loading && TrunkGroupDetails != null) {
      let { AccessDevice,
        AllowCallsDirectlyToTrunkGroupWithDTGIdentity,
        AllowCallsDirectlyToTrunkGroupWithTrunkIdentity,
        AllowUnscreenedCalls,
        AllowUnscreenedEmergencyCalls,
        AuthenticationUserName,
        CallingLineIdentitySourceForScreenedTrunkGroupCallsPolicy,
        Department,
        EnableAuthentication,
        EnableNetworkAddressIdentity,
        EnableTrunkGroupPrefix,
        GroupId,
        IncludeDTGIdentityForCallsToTrunkGroup,
        IncludeOTGIdentityForCallsFromTrunkGroup,
        IncludeTrunkIdentityForCallsFromTrunkGroup,
        IncludeTrunkIdentityForCallsToTrunkGroup,
        MaximumActiveCallsAllowed,
        MaximumActiveIncomingCallsAllowed,
        MaximumActiveOutgoingCallsAllowed,
        NewAuthenticationPassword,
        NewSipAuthenticationPassword,
        NewTrunkGroupName,
        OTGDTGIdentity,
        PeeringDomain,
        PilotUserCallOptimizationPolicy,
        PilotUserCallingLineIdentityForExternalCallsUsagePolicy,
        PilotUserCallingLineIdentityUsageForEmergencyCallsPolicy,
        PilotUserChargeNumberUsagePolicy,
        PilotUserId,
        RouteToPeeringDomain,
        SupportConnectedIdentityPolicy,
        TrunkGroupIdentity,
        TrunkGroupName,
        TrunkGroupPrefix,
        TrunkGroupUserLookupPolicy,
        UseSystemCLIDSourceForScreenedCallsPolicy,
        UseSystemSupportConnectedIdentityPolicy,
        UseSystemUserLookupPolicy,
      } = TrunkGroupDetails;
      return (
        <div className="card-content"
          style={{
            margin: '1% 10% 1% 10%',
          }}
        >
          {this.trunkGroupDataRow('* Name', this.inputDataField(TrunkGroupName, 'TrunkGroupName', 'text', true))}
          {this.trunkGroupDataRow('Department', this.dataDropDown(Department, 'Department', DepartmentList))}
          {this.trunkGroupDataRow('* Maximum Active Calls Allowed', this.inputDataField(MaximumActiveCallsAllowed, 'MaximumActiveCallsAllowed', 'number', true))}
          {this.trunkGroupDataRow('Maximum Incoming Calls Allowed', this.inputDataField(MaximumActiveIncomingCallsAllowed, 'MaximumActiveIncomingCallsAllowed', 'number', true))}
          {this.trunkGroupDataRow('Maximum Outgoing Calls Allowed', this.inputDataField(MaximumActiveOutgoingCallsAllowed, 'MaximumActiveOutgoingCallsAllowed', 'number', true))}

          {this.trunkGroupDataRow('Enable Authentication', this.inputDataField(EnableAuthentication, 'EnableAuthentication', 'checkbox'))}
          {this.trunkGroupDataRow('Authentication User Name', this.inputDataField(AuthenticationUserName, 'AuthenticationUserName'))}
          {this.trunkGroupDataRow('Type New Authentication Password', this.inputDataField(NewSipAuthenticationPassword, 'NewAuthenticationPassword'))}
          {/* {this.trunkGroupDataRow('Re-type New Authentication Password', this.inputDataField(''))} */}
          {this.trunkGroupDataRow('Trunk Group Identity', this.inputDataField(TrunkGroupIdentity, 'TrunkGroupIdentity', 'text', true))}
          {this.trunkGroupDataRow('OTG/DTG Identity', this.inputDataField(OTGDTGIdentity, 'OTGDTGIdentity'))}
          {this.trunkGroupDataRow('Enable Trunk Group Prefix', this.inputDataField(EnableTrunkGroupPrefix, 'EnableTrunkGroupPrefix', 'checkbox'), this.additionalInputFeld(EnableTrunkGroupPrefix, TrunkGroupPrefix, 'TrunkGroupPrefix'))}

          {this.trunkGroupDataRow('Allow calls directly to trunk group with trunk identity', this.inputDataField(AllowCallsDirectlyToTrunkGroupWithTrunkIdentity, 'AllowCallsDirectlyToTrunkGroupWithTrunkIdentity', 'checkbox'))}
          {this.trunkGroupDataRow('Allow calls directly to trunk group with DTG identity', this.inputDataField(AllowCallsDirectlyToTrunkGroupWithDTGIdentity, 'AllowCallsDirectlyToTrunkGroupWithDTGIdentity', 'checkbox'))}
          {this.trunkGroupDataRow('Include trunk identity for call to trunk group', this.inputDataField(IncludeTrunkIdentityForCallsToTrunkGroup, 'IncludeTrunkIdentityForCallsToTrunkGroup', 'checkbox'))}
          {this.trunkGroupDataRow('Include DTG identity for call to trunk group', this.inputDataField(IncludeDTGIdentityForCallsToTrunkGroup, 'IncludeDTGIdentityForCallsToTrunkGroup', 'checkbox'))}
          {this.trunkGroupDataRow('Enable network address identity', this.inputDataField(EnableNetworkAddressIdentity, 'EnableNetworkAddressIdentity', 'checkbox'))}
          {this.trunkGroupDataRow('Allow unscreened calls', this.inputDataField(AllowUnscreenedCalls, 'AllowUnscreenedCalls', 'checkbox'))}
          {this.trunkGroupDataRow('Allow unscreened emergency calls', this.inputDataField(AllowUnscreenedEmergencyCalls, 'AllowUnscreenedEmergencyCalls', 'checkbox'))}
          {this.trunkGroupDataRow('Route to peering domain', this.inputDataField(RouteToPeeringDomain, 'RouteToPeeringDomain', 'checkbox', true))}
          {this.trunkGroupDataRow('Peering Domain', this.dataDropDown(PeeringDomain, "PeeringDomain", DoaminList, true))}
          {/* Radio */}
          {/* {this.trunkGroupDataRow('Pilot User Call Optimization Policy', () => {
            return (
              <div className='form-group-wrapper'
                style={{
                  width: '40%',
                  display: 'inline-block',
                }}
              >
                <div>
                  <input
                    type="radio"
                    id="optimizeForUserServices"
                    name="optimizeForUserServices"
                    value="optimizeForUserServices"
                    checked={PilotUserCallOptimizationPolicy}
                  />
                  <label htmlFor="optimizeForUserServices"
                    style={{
                      width: '50%',
                    }}
                  >Optimize For User Services</label><br />
                  <input
                    type="radio"
                    id="optimizeForHighCallVolume"
                    name="optimizeForHighCallVolume"
                    value="optimizeForHighCallVolume"
                    checked={PilotUserCallOptimizationPolicy}
                  />
                  <label htmlFor="optimizeForHighCallVolume"
                    style={{
                      width: '50%',
                    }}
                  >Optimize For High Call Volume</label><br />
                </div>
              </div>
            )
          })} */}
          {this.trunkGroupDataRow('Trunk Group User Lookup Policy', this.radioDataField(UseSystemUserLookupPolicy, 'UseSystemUserLookupPolicy'), this.additionalDropField(UseSystemUserLookupPolicy, TrunkGroupUserLookupPolicy, 'TrunkGroupUserLookupPolicy', TrunkGroupUserLookupPolicyOptions))}
          {this.trunkGroupDataRow('Calling Line Identity Source For Screened Trunk Group Calls Policy', this.radioDataField(UseSystemCLIDSourceForScreenedCallsPolicy, 'UseSystemCLIDSourceForScreenedCallsPolicy'), this.additionalDropField(UseSystemCLIDSourceForScreenedCallsPolicy, CallingLineIdentitySourceForScreenedTrunkGroupCallsPolicy, 'CallingLineIdentitySourceForScreenedTrunkGroupCallsPolicy', TrunkGroupCLIDSourceForScreenedCallsPolicyOptions))}
          {this.trunkGroupDataRow('Support Connected Identity Policy', this.radioDataField(UseSystemSupportConnectedIdentityPolicy, 'UseSystemSupportConnectedIdentityPolicy'), this.additionalDropField(UseSystemSupportConnectedIdentityPolicy, SupportConnectedIdentityPolicy, 'SupportConnectedIdentityPolicy', TrunkGroupSupportConnectedIdentityPolicyOptions))}

          {this.trunkGroupDataRow('Pilot User Calling Line Identity for External Calls Usage Policy', this.dataDropDown(PilotUserCallingLineIdentityForExternalCallsUsagePolicy, "PilotUserCallingLineIdentityForExternalCallsUsagePolicy", TrunkGroupPilotUserCallingLineIdentityUsagePolicyOptions))}
          {this.trunkGroupDataRow('Pilot User Calling Line Identity Usage for Emergency Calls Policy', this.dataDropDown(PilotUserCallingLineIdentityUsageForEmergencyCallsPolicy, "PilotUserCallingLineIdentityUsageForEmergencyCallsPolicy", TrunkGroupPilotUserCallingLineIdentityUsagePolicyOptions))}
          {this.trunkGroupDataRow('Pilot User Charge Number Usage Policy', this.dataDropDown(PilotUserChargeNumberUsagePolicy, "PilotUserChargeNumberUsagePolicy", TrunkGroupPilotUserCallingLineIdentityUsagePolicyOptions))}
          {/* <div className="section-two">
            {this.gridContent()}
          </div> */}
        </div>
      )
    } else {
      return <DataLoadingSpinner className='load spinner' />
    }
  }

  saveTrunkGroup = async () => {
    let { TrunkGroupDetails } = this.state;
    let apiPath = `admin/UpdateEnterpriseTrunkGroupInstance`;
    let dto = {
      AllowCallsDirectlyToTrunkGroupWithDTGIdentity: TrunkGroupDetails.AllowCallsDirectlyToTrunkGroupWithDTGIdentity,
      AllowCallsDirectlyToTrunkGroupWithTrunkIdentity: TrunkGroupDetails.AllowCallsDirectlyToTrunkGroupWithTrunkIdentity,
      AllowUnscreenedCalls: TrunkGroupDetails.AllowUnscreenedCalls,
      AllowUnscreenedEmergencyCalls: TrunkGroupDetails.AllowUnscreenedEmergencyCalls,
      AuthenticationUserName: TrunkGroupDetails.AuthenticationUserName,
      CallingLineIdentitySourceForScreenedTrunkGroupCallsPolicy: parseInt(TrunkGroupDetails.CallingLineIdentitySourceForScreenedTrunkGroupCallsPolicy),
      Department: TrunkGroupDetails.Department === "None" ? null : TrunkGroupDetails.Department,
      EnableAuthentication: TrunkGroupDetails.EnableAuthentication,
      EnableNetworkAddressIdentity: TrunkGroupDetails.EnableNetworkAddressIdentity,
      EnableTrunkGroupPrefix: TrunkGroupDetails.EnableTrunkGroupPrefix,
      GroupId: TrunkGroupDetails.GroupId,
      IncludeDTGIdentityForCallsToTrunkGroup: TrunkGroupDetails.IncludeDTGIdentityForCallsToTrunkGroup,
      IncludeOTGIdentityForCallsFromTrunkGroup: TrunkGroupDetails.IncludeOTGIdentityForCallsFromTrunkGroup,
      IncludeTrunkIdentityForCallsFromTrunkGroup: TrunkGroupDetails.IncludeTrunkIdentityForCallsFromTrunkGroup,
      IncludeTrunkIdentityForCallsToTrunkGroup: TrunkGroupDetails.IncludeTrunkIdentityForCallsToTrunkGroup,
      MaximumActiveCallsAllowed: parseInt(TrunkGroupDetails.MaximumActiveCallsAllowed),
      MaximumActiveIncomingCallsAllowed: parseInt(TrunkGroupDetails.MaximumActiveIncomingCallsAllowed),
      MaximumActiveOutgoingCallsAllowed: parseInt(TrunkGroupDetails.MaximumActiveOutgoingCallsAllowed),
      NewAuthenticationPassword: TrunkGroupDetails.NewAuthenticationPassword,
      NewSipAuthenticationPassword: TrunkGroupDetails.NewAuthenticationPassword,
      //NewSipAuthenticationPassword: TrunkGroupDetails.NewSipAuthenticationPassword,
      NewTrunkGroupName: TrunkGroupDetails.NewTrunkGroupName,
      OTGDTGIdentity: TrunkGroupDetails.OTGDTGIdentity,
      PeeringDomain: TrunkGroupDetails.PeeringDomain === "None" ? null : TrunkGroupDetails.PeeringDomain,
      PilotUserCallOptimizationPolicy: parseInt(TrunkGroupDetails.PilotUserCallOptimizationPolicy),
      PilotUserCallingLineIdentityForExternalCallsUsagePolicy: parseInt(TrunkGroupDetails.PilotUserCallingLineIdentityForExternalCallsUsagePolicy),
      PilotUserCallingLineIdentityUsageForEmergencyCallsPolicy: parseInt(TrunkGroupDetails.PilotUserCallingLineIdentityUsageForEmergencyCallsPolicy),
      PilotUserChargeNumberUsagePolicy: parseInt(TrunkGroupDetails.PilotUserChargeNumberUsagePolicy),
      PilotUserId: TrunkGroupDetails.PilotUserId,
      RouteToPeeringDomain: TrunkGroupDetails.RouteToPeeringDomain,
      SupportConnectedIdentityPolicy: parseInt(TrunkGroupDetails.SupportConnectedIdentityPolicy),
      TrunkGroupIdentity: TrunkGroupDetails.TrunkGroupIdentity,
      TrunkGroupName: TrunkGroupDetails.TrunkGroupName,
      TrunkGroupPrefix: TrunkGroupDetails.TrunkGroupPrefix,
      TrunkGroupUserLookupPolicy: parseInt(TrunkGroupDetails.TrunkGroupUserLookupPolicy),
      UseSystemCLIDSourceForScreenedCallsPolicy: TrunkGroupDetails.UseSystemCLIDSourceForScreenedCallsPolicy === "true" ? true : false,
      UseSystemSupportConnectedIdentityPolicy: TrunkGroupDetails.UseSystemSupportConnectedIdentityPolicy === "true" ? true : false,
      UseSystemUserLookupPolicy: TrunkGroupDetails.UseSystemUserLookupPolicy === "true" ? true : false,
    };
    //console.log('dto', JSON.stringify(dto));
    let response = await apiCalls.post(apiPath, 'POST', JSON.stringify(dto)).then(res => res).catch(err => console.error(err));
    if (response) {
      this.fetchTrunkGroup();
      await this.setState({ showConfirmationModal: false });
      //console.log('response', response);
      if (response.ok) {
        await this.setState({
          Submission: true,
          submitError: false,
          errorMsg: response.message,
        });
      } else {
        let msg = response.message;
        // if username is present but no password
        if (dto.AuthenticationUserName && !dto.NewAuthenticationPassword) {
          msg = "Submission Failure: Both authentication Username and Password are required.";
        }
        // if password is present but no username
        if (!dto.AuthenticationUserName && dto.NewAuthenticationPassword) {
          msg = "Submission Failure: Both authentication Username and Password are required.";
        }
        // if password is present but does not meet min character limit
        if (dto.NewAuthenticationPassword && dto.NewAuthenticationPassword.length < 3) {
          msg = "Submission Failure: Password needs to be between 3 to 30 characters.";
        }
        // if OTG/DTG identity is empty
        if(!dto.OTGDTGIdentity) {
          msg = "Submission Failure: OTG / DTG Identity is empty.";
        }
        // if trunk group prefix enabled but field is empty
        if(dto.EnableTrunkGroupPrefix && !dto.TrunkGroupPrefix) {
          msg = "Submission Failure: Prefix is required when Trunk Group Prefix is enabled.";
        }
        await this.setState({
          Submission: true,
          submitError: true,
          errorMsg: msg,
        });
      }
    }
  }

  trunkGroupUpdateBtns = () => {
    let { TrunkGroupDetails, OriginalDetails } = this.state;
    if (
      TrunkGroupDetails.AllowCallsDirectlyToTrunkGroupWithDTGIdentity === OriginalDetails.AllowCallsDirectlyToTrunkGroupWithDTGIdentity &&
      TrunkGroupDetails.AllowCallsDirectlyToTrunkGroupWithTrunkIdentity === OriginalDetails.AllowCallsDirectlyToTrunkGroupWithTrunkIdentity &&
      TrunkGroupDetails.AllowUnscreenedCalls === OriginalDetails.AllowUnscreenedCalls &&
      TrunkGroupDetails.AllowUnscreenedEmergencyCalls === OriginalDetails.AllowUnscreenedEmergencyCalls &&
      TrunkGroupDetails.AuthenticationUserName === OriginalDetails.AuthenticationUserName &&
      parseInt(TrunkGroupDetails.CallingLineIdentitySourceForScreenedTrunkGroupCallsPolicy) === OriginalDetails.CallingLineIdentitySourceForScreenedTrunkGroupCallsPolicy &&
      TrunkGroupDetails.Department === OriginalDetails.Department &&
      TrunkGroupDetails.EnableAuthentication === OriginalDetails.EnableAuthentication &&
      TrunkGroupDetails.EnableNetworkAddressIdentity === OriginalDetails.EnableNetworkAddressIdentity &&
      TrunkGroupDetails.EnableTrunkGroupPrefix === OriginalDetails.EnableTrunkGroupPrefix &&
      TrunkGroupDetails.GroupId === OriginalDetails.GroupId &&
      TrunkGroupDetails.IncludeDTGIdentityForCallsToTrunkGroup === OriginalDetails.IncludeDTGIdentityForCallsToTrunkGroup &&
      TrunkGroupDetails.IncludeOTGIdentityForCallsFromTrunkGroup === OriginalDetails.IncludeOTGIdentityForCallsFromTrunkGroup &&
      TrunkGroupDetails.IncludeTrunkIdentityForCallsFromTrunkGroup === OriginalDetails.IncludeTrunkIdentityForCallsFromTrunkGroup &&
      TrunkGroupDetails.IncludeTrunkIdentityForCallsToTrunkGroup === OriginalDetails.IncludeTrunkIdentityForCallsToTrunkGroup &&
      parseInt(TrunkGroupDetails.MaximumActiveCallsAllowed) === OriginalDetails.MaximumActiveCallsAllowed &&
      parseInt(TrunkGroupDetails.MaximumActiveIncomingCallsAllowed) === OriginalDetails.MaximumActiveIncomingCallsAllowed &&
      parseInt(TrunkGroupDetails.MaximumActiveOutgoingCallsAllowed) === OriginalDetails.MaximumActiveOutgoingCallsAllowed &&
      //TrunkGroupDetails.NewAuthenticationPassword === OriginalDetails.NewAuthenticationPassword &&
      TrunkGroupDetails.NewSipAuthenticationPassword === OriginalDetails.NewSipAuthenticationPassword &&
      TrunkGroupDetails.NewAuthenticationPassword === OriginalDetails.NewAuthenticationPassword &&
      TrunkGroupDetails.NewTrunkGroupName === OriginalDetails.NewTrunkGroupName &&
      TrunkGroupDetails.OTGDTGIdentity === OriginalDetails.OTGDTGIdentity &&
      TrunkGroupDetails.PeeringDomain === OriginalDetails.PeeringDomain &&
      parseInt(TrunkGroupDetails.PilotUserCallOptimizationPolicy) === OriginalDetails.PilotUserCallOptimizationPolicy &&
      parseInt(TrunkGroupDetails.PilotUserCallingLineIdentityForExternalCallsUsagePolicy) === OriginalDetails.PilotUserCallingLineIdentityForExternalCallsUsagePolicy &&
      parseInt(TrunkGroupDetails.PilotUserCallingLineIdentityUsageForEmergencyCallsPolicy) === OriginalDetails.PilotUserCallingLineIdentityUsageForEmergencyCallsPolicy &&
      parseInt(TrunkGroupDetails.PilotUserChargeNumberUsagePolicy) === OriginalDetails.PilotUserChargeNumberUsagePolicy &&
      TrunkGroupDetails.PilotUserId === OriginalDetails.PilotUserId &&
      TrunkGroupDetails.RouteToPeeringDomain === OriginalDetails.RouteToPeeringDomain &&
      parseInt(TrunkGroupDetails.SupportConnectedIdentityPolicy) === OriginalDetails.SupportConnectedIdentityPolicy &&
      TrunkGroupDetails.TrunkGroupIdentity === OriginalDetails.TrunkGroupIdentity &&
      TrunkGroupDetails.TrunkGroupName === OriginalDetails.TrunkGroupName &&
      TrunkGroupDetails.TrunkGroupPrefix === OriginalDetails.TrunkGroupPrefix &&
      parseInt(TrunkGroupDetails.TrunkGroupUserLookupPolicy) === OriginalDetails.TrunkGroupUserLookupPolicy &&
      TrunkGroupDetails.UseSystemCLIDSourceForScreenedCallsPolicy === OriginalDetails.UseSystemCLIDSourceForScreenedCallsPolicy &&
      TrunkGroupDetails.UseSystemSupportConnectedIdentityPolicy === OriginalDetails.UseSystemSupportConnectedIdentityPolicy &&
      TrunkGroupDetails.UseSystemUserLookupPolicy === OriginalDetails.UseSystemUserLookupPolicy
    )
      return;
    else {
      return (
        <div
          style={{
            display: 'inline-block',
            position: 'relative',
            bottom: '20px',
            float: 'right',
          }}>
          <button
            className='btn'
            onClick={() => this.setState({ showConfirmationModal: true })}
            disabled={TrunkGroupDetails.AllowCallsDirectlyToTrunkGroupWithDTGIdentity === OriginalDetails.AllowCallsDirectlyToTrunkGroupWithDTGIdentity &&
              TrunkGroupDetails.AllowCallsDirectlyToTrunkGroupWithTrunkIdentity === OriginalDetails.AllowCallsDirectlyToTrunkGroupWithTrunkIdentity &&
              TrunkGroupDetails.AllowUnscreenedCalls === OriginalDetails.AllowUnscreenedCalls &&
              TrunkGroupDetails.AllowUnscreenedEmergencyCalls === OriginalDetails.AllowUnscreenedEmergencyCalls &&
              TrunkGroupDetails.AuthenticationUserName === OriginalDetails.AuthenticationUserName &&
              parseInt(TrunkGroupDetails.CallingLineIdentitySourceForScreenedTrunkGroupCallsPolicy) === OriginalDetails.CallingLineIdentitySourceForScreenedTrunkGroupCallsPolicy &&
              TrunkGroupDetails.Department === OriginalDetails.Department &&
              TrunkGroupDetails.EnableAuthentication === OriginalDetails.EnableAuthentication &&
              TrunkGroupDetails.EnableNetworkAddressIdentity === OriginalDetails.EnableNetworkAddressIdentity &&
              TrunkGroupDetails.EnableTrunkGroupPrefix === OriginalDetails.EnableTrunkGroupPrefix &&
              TrunkGroupDetails.GroupId === OriginalDetails.GroupId &&
              TrunkGroupDetails.IncludeDTGIdentityForCallsToTrunkGroup === OriginalDetails.IncludeDTGIdentityForCallsToTrunkGroup &&
              TrunkGroupDetails.IncludeOTGIdentityForCallsFromTrunkGroup === OriginalDetails.IncludeOTGIdentityForCallsFromTrunkGroup &&
              TrunkGroupDetails.IncludeTrunkIdentityForCallsFromTrunkGroup === OriginalDetails.IncludeTrunkIdentityForCallsFromTrunkGroup &&
              TrunkGroupDetails.IncludeTrunkIdentityForCallsToTrunkGroup === OriginalDetails.IncludeTrunkIdentityForCallsToTrunkGroup &&
              parseInt(TrunkGroupDetails.MaximumActiveCallsAllowed) === OriginalDetails.MaximumActiveCallsAllowed &&
              parseInt(TrunkGroupDetails.MaximumActiveIncomingCallsAllowed) === OriginalDetails.MaximumActiveIncomingCallsAllowed &&
              parseInt(TrunkGroupDetails.MaximumActiveOutgoingCallsAllowed) === OriginalDetails.MaximumActiveOutgoingCallsAllowed &&
              //TrunkGroupDetails.NewAuthenticationPassword === OriginalDetails.NewAuthenticationPassword &&
              TrunkGroupDetails.NewSipAuthenticationPassword === OriginalDetails.NewSipAuthenticationPassword &&
              TrunkGroupDetails.NewAuthenticationPassword === OriginalDetails.NewAuthenticationPassword &&
              TrunkGroupDetails.NewTrunkGroupName === OriginalDetails.NewTrunkGroupName &&
              TrunkGroupDetails.OTGDTGIdentity === OriginalDetails.OTGDTGIdentity &&
              TrunkGroupDetails.PeeringDomain === OriginalDetails.PeeringDomain &&
              parseInt(TrunkGroupDetails.PilotUserCallOptimizationPolicy) === OriginalDetails.PilotUserCallOptimizationPolicy &&
              parseInt(TrunkGroupDetails.PilotUserCallingLineIdentityForExternalCallsUsagePolicy) === OriginalDetails.PilotUserCallingLineIdentityForExternalCallsUsagePolicy &&
              parseInt(TrunkGroupDetails.PilotUserCallingLineIdentityUsageForEmergencyCallsPolicy) === OriginalDetails.PilotUserCallingLineIdentityUsageForEmergencyCallsPolicy &&
              parseInt(TrunkGroupDetails.PilotUserChargeNumberUsagePolicy) === OriginalDetails.PilotUserChargeNumberUsagePolicy &&
              TrunkGroupDetails.PilotUserId === OriginalDetails.PilotUserId &&
              TrunkGroupDetails.RouteToPeeringDomain === OriginalDetails.RouteToPeeringDomain &&
              parseInt(TrunkGroupDetails.SupportConnectedIdentityPolicy) === OriginalDetails.SupportConnectedIdentityPolicy &&
              TrunkGroupDetails.TrunkGroupIdentity === OriginalDetails.TrunkGroupIdentity &&
              TrunkGroupDetails.TrunkGroupName === OriginalDetails.TrunkGroupName &&
              TrunkGroupDetails.TrunkGroupPrefix === OriginalDetails.TrunkGroupPrefix &&
              parseInt(TrunkGroupDetails.TrunkGroupUserLookupPolicy) === OriginalDetails.TrunkGroupUserLookupPolicy &&
              TrunkGroupDetails.UseSystemCLIDSourceForScreenedCallsPolicy === OriginalDetails.UseSystemCLIDSourceForScreenedCallsPolicy &&
              TrunkGroupDetails.UseSystemSupportConnectedIdentityPolicy === OriginalDetails.UseSystemSupportConnectedIdentityPolicy &&
              TrunkGroupDetails.UseSystemUserLookupPolicy === OriginalDetails.UseSystemUserLookupPolicy ? true : false}
          >Save</button>
          <button
            className='btn'
            onClick={this.fetchTrunkGroup}
          >Cancel</button>
        </div>
      )
    }
  }

  submissionMsg = () => {
    if (this.state.Submission) {
      if (this.state.submitError) {
        return <small style={{
          color: 'red',
          fontSize: '50%',
        }}>{this.state.errorMsg && this.state.errorMsg != "" ? this.state.errorMsg : "Submission Failure: Changes were not processed!"}</small>
      } else {
        return <small style={{
          color: 'green',
          fontSize: '50%',
        }}>Submission Sucess: Changes were processed!</small>
      }
    }
  };

  render() {
    return (
      <div className="manage-trunk-group-container">
        <div className="container full-card">
          <div className="ent-trunk card">
            <div className="card-header">
              {this.state.TrunkGroupDetails ? this.trunkGroupUpdateBtns() : null}
              <h1
              //style={{ display: 'inline-block', }}
              >Manage Trunk Group {this.submissionMsg()}</h1>
            </div>
            <Modal show={this.state.showConfirmationModal} onHide={this.handleClose} backdrop='static'>
              <Modal.Header>
                <Modal.Title>Are you sure you want to save changes?</Modal.Title>
              </Modal.Header>
              <Modal.Footer>
                <hr />
                <div className="btns">
                  <span>
                    <button type="button" className="btn" onClick={this.saveTrunkGroup}>Yes</button>
                    <button className="btn" onClick={() => this.setState({ showConfirmationModal: false })}>No</button>
                  </span>
                </div>
              </Modal.Footer>
            </Modal>
            {this.cardContent()}
          </div>
        </div >
      </div>
    )
  }
}

var TrunkGroupPilotUserCallOptimizationPolicyOptions = [
  "Optimize For User Services",
  "Optimize For High Call Volume",
];
var TrunkGroupUserLookupPolicyOptions = [
  "Basic",
  "Extended",
];
var TrunkGroupCLIDSourceForScreenedCallsPolicyOptions = [
  "Profile Name Profile Number",
  "Received Name Profile Number",
  "Received Name Received Number",
];
var TrunkGroupSupportConnectedIdentityPolicyOptions = [
  "Enabled",
  "Disabled",
];
var TrunkGroupPilotUserCallingLineIdentityUsagePolicyOptions = [
  "All Originating Calls",
  "Unscreened Originating Calls",
  "No Calls"
];
var TrunkGroupPilotUserChargeNumberUsagePolicyOptions = [
  "All Originating Calls",
  "Unscreened Originating Calls",
  "No Calls"
];