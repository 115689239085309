import React, { Component } from 'react';
import { Grid, GridColumn as Column, GridCell } from '@progress/kendo-react-grid';
import { Modal } from 'react-bootstrap';
import { Button } from '@progress/kendo-buttons-react-wrapper';
import { DataLoadingSpinner } from '../../../components/spinners';
import _ from 'lodash';
import { orderBy } from '@progress/kendo-data-query';
import * as apiCalls from '../../../requests/api_calls';
import { filterBy } from '@progress/kendo-data-query';
import { userIsAdmin } from '../../../scripts/helper';

export default class AffectedCircuitsModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      Circuits: [],
      RedirectedFromCircuitsSaved:props.RedirectedFromCircuitsSaved ? props.RedirectedFromCircuitsSaved : null,
      FullCircuits: [],
      showModal: false,
      Sort: [{ field: 'CombinedTypes', dir: "asc" }],
    }

    const selectService = this.selectService.bind(this);


    class SelectServiceCell extends GridCell {
      constructor(props) {
        super(props);
      }
      render() {
        let service = this.props.dataItem;
        let { Selected } = service;
        return (
          <td>
            <input type='checkbox' checked={Selected === true ? true : false} onChange={(e) => selectService(e, service)} />
          </td>
        )
      }
    }

    class ExtensionCell extends GridCell {
      constructor(props) {
        super(props);
      }
      render() {
        let service = this.props.dataItem
        let { Extension, Username } = service;
        return (
          <td>
            {Extension ? Extension : Username}
          </td>
        )
      }
    }
    this.SelectServiceCell = SelectServiceCell;
    this.ExtensionCell = ExtensionCell;
  }

  fetchServiceList = async () => {
    await this.setState({ loading: true });
    let apiPath = 'admin/GetCircuitsList';
    apiCalls.fetchData(apiPath).then(async data => {
      if (data) {
        let filteredData =data.filter(x=> x.CircuitId !=="" || x.CircuitName!=="")
        if(this.state.RedirectedFromCircuitsSaved)
        {
          let filtered =filteredData.filter(x=> x.CircuitId ===this.state.RedirectedFromCircuitsSaved[0].CircuitId)
          filtered[0].Selected = true
        }
        this.setState({
          Circuits: filteredData,
          FullCircuits: filteredData,
          loading: false
        });
      } else {
        this.setState({ loading: false });
      }
    }).catch(err => console.error(err));
  }

  handleClose = () => {
    this.setState({ showModal: false });
  }

  handleShow = async () => {
    await this.setState({ showModal: true });
    let { circuitsList } = sessionStorage;
    if (circuitsList) {
      let savedCircuits = JSON.parse(circuitsList);
      this.setState({ Circuits: savedCircuits });
    } else {
      this.fetchServiceList();
    }
  }

  selectService = async (e, productItem) => {
    let selectedValue = e.target.checked;
    let circuitsList = this.state.Circuits.slice();
    productItem.Selected = selectedValue;
    let item = circuitsList.filter(itm => {
      if (itm.CircuitId) {
        return (itm.CircuitId === productItem.CircuitId && itm.CircuitName === productItem.CircuitName);
      } else {
        return (itm.UserId === productItem.UserId && itm.UserType === productItem.UserType);
      }
    })[0];
    let idx = _.indexOf(circuitsList, item);
    circuitsList[idx] = productItem;
    await this.setState({ Circuits: circuitsList });
  }

  sortChange = (event) => {
    this.setState({
      Circuits: orderBy(this.state.Circuits, event.sort),
      Sort: event.sort
    });
  }

  gridContent = () => {
    if (this.state.loading) {
      return <DataLoadingSpinner className="load spinner" />;
    } else {
      if (this.state.Circuits && this.state.Circuits.length > 0) {
        return (
          <Grid
            style={{
              maxHeight: "560px",
              overflow: "auto",
            }}
            data={orderBy(this.state.Circuits, this.state.Sort)}
            sortable={{ allowUnsort: true, mode: "single" }}
            sort={this.state.Sort}
            onSortChange={this.sortChange}
          >
            <Column
              width="80px"
              field="Selected"
              title="Select"
              cell={this.SelectServiceCell}
              filterable={false}
            />
            <Column
              width="100px"
              field="CircuitId"
              title="Circuit ID"
              cell={this.DataLinkCell}
              filterable={false}
            />
            <Column width="100px" field="CircuitName" title="Circuit Name" />
            <Column
              width="80px"
              field="SpeedDownstream"
              title="Download Speed (Mbps)"
            />
            <Column
              width="80px"
              field="SpeedUpstream"
              title="Upload Speed (Mbps)"
            />
            <Column width="100px" field="LocationName" title="Location" />
            <Column
              width="180px"
              field="NotificationEmail"
              title="Notification Email"
              cell={this.NameCell}
            />
          </Grid>
        );
      }
      if (this.state.Circuits && this.state.Circuits.length === 0) {
        return (
          <p style={{ textAlign: "center" }}>No Available Circuits To Load</p>
        );
      }
    }
  }

  assignCircuits = () => {
    let circuitsList = this.state.Circuits.filter(itm => itm.Selected === true);
    if(circuitsList && this.state.RedirectedFromCircuitsSaved!=null)
    {
      circuitsList =circuitsList.filter(object =>{return object.CircuitId !== this.state.RedirectedFromCircuitsSaved[0].CircuitId})
    }
    this.props.affectedCircuits(circuitsList);
    if (this.props.saveSelections) {
      sessionStorage.setItem('circuitsList', JSON.stringify(this.state.Circuits));
    }
    this.handleClose();
  }

  searchListings = (event) => {
    let searchText = event.target.value.trim();
    let fullDetails = this.state.FullCircuits;
    if (searchText !== '') {
      searchText = event.target.value;
      let circuits = filterBy(fullDetails, { 
        logic: 'or',
        filters: [
          { field: 'CombinedTypes', operator: 'contains', value: searchText, ignoreCase: true },
          { field: 'ServiceNumber', operator: 'contains', value: searchText, ignoreCase: true },
          { field: 'Extension', operator: 'contains', value: searchText, ignoreCase: true },
        ]
      });
      this.setState({ Circuits: circuits })
    }
    else {
      this.setState({ Circuits: fullDetails });
    }
  }

  render() {
    if (userIsAdmin())
    {
      return (
        <span>
          <button className='btn' onClick={this.handleShow} style={this.props.btnStyles}>Add Affected Circuits</button>

          <Modal
            dialogClassName="modal-class"
            show={this.state.showModal}
            onHide={this.handleClose}
            bsSize="large"
          >
            <Modal.Header closeButton closeLabel="test test test test est test ets estse">
              <Modal.Title>Add Affected Circuits</Modal.Title>
              {this.state.loading ? null
                : <div className="search">
                  <label htmlFor="fav_services_srch_tb" className="hidden">favorite circuits search</label>
                  <input id="fav_services_srch_tb" type="text" autoComplete={"off"} className="form-control" onChange={this.searchListings} />
                  <svg width="12" height="12" viewBox="0 0 23 23"><path d="m347 238c0-36-12-66-37-91-25-25-55-37-91-37-35 0-65 12-90 37-25 25-38 55-38 91 0 35 13 65 38 90 25 25 55 38 90 38 36 0 66-13 91-38 25-25 37-55 37-90z m147 237c0 10-4 19-11 26-7 7-16 11-26 11-10 0-19-4-26-11l-98-98c-34 24-72 36-114 36-27 0-53-5-78-16-25-11-46-25-64-43-18-18-32-39-43-64-10-25-16-51-16-78 0-28 6-54 16-78 11-25 25-47 43-65 18-18 39-32 64-43 25-10 51-15 78-15 28 0 54 5 79 15 24 11 46 25 64 43 18 18 32 40 43 65 10 24 16 50 16 78 0 42-12 80-36 114l98 98c7 7 11 15 11 25z" transform="scale(0.046875 0.046875)"></path></svg>
                </div>}
            </Modal.Header>
            <Modal.Body>
              <div>
                {this.gridContent()}
              </div>
            </Modal.Body>
            <Modal.Footer>
              <hr />
              {!this.state.loading ? <div className="btns">
                <Button className="btn"
                  click={this.assignCircuits}
                >Add as Comment</Button>
                <Button className="btn"
                  click={this.handleClose}
                >Close</Button>
              </div> : null}
            </Modal.Footer>
          </Modal>
        </span>
      )
    }
    return null;
  }
};