export const arraysEqual = (a, b) => {
    if (a === b) {
      return true;
    }
    if (a == null || b == null) {
      return false;
    }
    if (a.length !== b.length) {
      return false;
    }
  
    for (let i = 0; i < a.length; ++i) {
      if (a[i].Priority !== b[i].Priority) {
        return false;
      }
      if (a[i].Weight !== b[i].Weight) {
        return false;
      }
      if (a[i].IsAssigned !== b[i].IsAssigned) {
        return false;
      }
    }
    return true;
  };
  
  export const validateFields = (obj, a, b) => {
    if (parseInt(obj.MaxNumRerouteAttempts) > 10 || obj.MaxNumRerouteAttempts === "") {
      console.log("false 1");
      return false;
    }
    if (parseInt(obj.MaxNumRerouteWithPriority) > 10 || obj.MaxNumRerouteWithPriority === "") {
      console.log("false 2");
      return false;
    }
    let arr = obj.TrunkGroupAssignments;
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].IsAssigned === true) {
        if (
          arr[i].Priority < 1 ||
          arr[i].Priority > 10 ||
          Number.isInteger(arr[i].Priority) === false
        ) {
          console.log("false 3");
          return false;
        }
        if (arr[i].Weight < 1 || arr[i].Weight > 65536 || Number.isInteger(arr[i].Weight) === false) {
          console.log("false 4");
          return false;
        }
        return true;
      }
    }
    return true;
  };
  
  export const checkBillingAccount = () => {
    let account = sessionStorage.userEnterpriseNumber;
    let userType = sessionStorage.userTypeId;
    let sp = sessionStorage.serviceProviderName;
    //serviceProviderName
    if (
      account.startsWith("390") &&
      userType === "1107" &&
      (sp === "Momentum_Retail_PHL_Legacy" || sp === "CNG_Retail")
    ) {
      return true;
    } else {
      return false;
    }
  };
  
  export const cngAltevaDctAccount = () => {
    let account = sessionStorage.userEnterpriseNumber;
    let sp = sessionStorage.serviceProviderName;
    // "Momentum_Retail_CLE_Legacy" -> DCT
    // Momentum_Retail_PHL_Legacy -> Alteva
    // CNG_Retail
    if (account.startsWith("390") && (sp === "Momentum_Retail_PHL_Legacy" || sp === "CNG_Retail")) {
      return true;
    } else {
      return false;
    }
  };
  
  export const grabLocations = async () => {
    let list = await JSON.parse(sessionStorage.locationList);
    return list;
  };
  
  export const filterErrorMessage = (errorMessage) => {
    const result = errorMessage.replace(/^[^\(]+\(\):/, "").replace(/UserId=(.*)/, "");
  
    if (result) return result;
    return errorMessage;
  };
  