import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import CircuitsAlerts from "./circuits_alerts";
import CircuitList from "./circuits_list";
import CircuitsChart from "./circuits_chart";
import Masonry from "react-masonry-component";
import * as apiCalls from "../../requests/api_calls";

export default class CircuitsDashboard extends Component {
  constructor(props) {
    super(props);

    const CircuitsList = this.CircuitsList.bind(this);
    const CircuitsChart = this.CircuitsChart.bind(this);

    let widgets = [
      {
        toggle: "showFavServices",
        functionName: CircuitsList,
        widgetRow: 0,
        widgetColumn: 0,
      },
      {
        toggle: "showCallChart",
        functionName: CircuitsChart,
        widgetRow: 0,
        widgetColumn: 0,
      },
    ];

    this.state = {
      showFavServices: true,
      showCallChart: true,
      widgets: widgets,
    };
  }

  componentDidMount() {
    //this.fetchCircuits();
  }

  fetchCircuits = async () => {
    let path = "admin/GetCircuitsPermission";
    await apiCalls
      .fetchData(path)
      .then((data) => data)
      .catch((err) => console.error(err));
  };

  // componentWillReceiveProps = (props) => {
  //   let state = this.state;
  //   if (state.hasTicketing !== props.hasTicketing || state.hasDevices !== props.hasDevices || state.ipTrunkingOnly !== props.ipTrunkingOnly) //in the future this may change if user can customize their dashboard
  //     this.setState({
  //       hasTicketing: props.hasTicketing,
  //       hasDevices: props.hasDevices,
  //       showFavServices: true,
  //       showActivityHistory: true,
  //       showStarredTicketingWidget: ((!props.hasTicketing && state.hasTicketing === false) || props.hasTicketing === 'NONE') ? false : true,
  //       showQuickView: true,
  //       showDevices: (props.ipTrunkingOnly !== null && props.ipTrunkingOnly !== undefined && props.ipTrunkingOnly === true) ? false : state.showDevices,
  //       showCallChart: true,
  //       showTicketingCountsWidget: ((!props.hasTicketing && state.hasTicketing === false) || props.hasTicketing === 'NONE') ? false : true,
  //     })
  // }

  transitionToCallLogDetails = () => {
    this.props.history.push("/enterpise_call_logs_all");
  };

  CircuitsList = () => {
    return <CircuitsList />;
  };

  CircuitsChart = () => {
    return <CircuitsChart transition={this.transitionToCallLogDetails} />;
  };

  render() {
    return (
      <div className="container">
        {/* <CircuitsAlerts /> */}
        <Masonry
          id={"grid"}
          className={"admin cards"}
          disableImagesLoaded={false}
          updateOnEachImageLoad={false}
          enableResizableChildren={true}
        >
          <CircuitList />
          {/* <div className="card-holder">
            <CircuitsChart transition={this.transitionToCallLogDetails} />
          </div> */}
        </Masonry>
      </div>
    );
  }
}