import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { Button } from '@progress/kendo-buttons-react-wrapper';
import * as apiCalls from './../../../requests/api_calls';
import * as helper from './../../../scripts/helper';
import * as macdHelper from '../components/macd_helper';
import { Grid, GridColumn as Column, GridCell } from '@progress/kendo-react-grid';
import { orderBy } from '@progress/kendo-data-query';
import { filterBy } from '@progress/kendo-data-query';
import _ from 'lodash';
import { DataLoadingSpinner } from '../../../components/spinners';

class MacdUserModal extends Component {

  initialState = (props) => {

    return {
      orderSubmitted: false,
      userListings: [],
      gridCopy: [],
      gridData: [],
      loading: false,
      postCompleted: false,
      errorMsg: '',
      ConfirmationTitle: '',
      ConfirmationMessage: '',
      isConfirmationDialogOpen: false,
      isAssign: props.isAssign,
      ReadOnly: false,
      AvailableCount: this.props.countAvailable,
      AssignedCount: this.props.countAssigned,
      selectedForAction: [],
      showModal: false,
      CatalogItemId: props && props.CatalogItemId ? props.CatalogItemId : '',
      MomentumAccountNumber: props && props.MomentumAccountNumber ? props.MomentumAccountNumber : '',
      sort: [{field: 'ServiceNumber', dir: 'asc'}]
    }
  }
  constructor(props) {
    super(props);

    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleCounts = this.handleCounts.bind(this);
    this.state = this.initialState(props);

    const updateConfiguredVal = this.updateConfiguredVal.bind(this);
    const getInfo = this.getInfo.bind(this);

    class MyActions extends GridCell {
      render() {
        let productItem = this.props.dataItem;
        const selectedVal = this.props.dataItem.IsSelected;
        let isAssign = props.isAssign;
        let BSSaved = this.props.dataItem.BSSaved;
        let successfulPost = this.props.dataItem.successfulPost;


        let [countAvailable, countAssigned, countRemaining, userListings, readOnly, changedItems] = getInfo();
        

        let updateConfiguredValues = () => {
          updateConfiguredVal(productItem, selectedVal);
        };

        if (readOnly === true) { // AFTER SUBMISSION BTN
          return (
            <td align="right">
              <a className="btn"
                style={{
                  color: BSSaved || successfulPost ? '#4CAF50' : 'red',
                  borderColor: BSSaved || successfulPost ? '#4CAF50' : 'red',
                  display: 'inline'
                }}>
                {isAssign === true ? "Assigned" : "Un-Assigned"}</a>
            </td>
          )
        }

        if (selectedVal && readOnly === false) {
          return (
            <td align="right">
              <a onClick={updateConfiguredValues} className="btn"
                style={{
                  color: '#4CAF50',
                  borderColor: '#4CAF50',
                  display: 'inline'
                }}>
                {isAssign === true ? "✓ Assign" : "✓ Un-Assign"}</a>
            </td>
          )
        }
        if (!selectedVal && readOnly === false) {
          return (
            <td align="right">
              <a onClick={updateConfiguredValues} className="btn"
                style={{
                  display: (isAssign === true && countRemaining === 0) ||
                    (isAssign === false && countAssigned === 0) ||
                    (changedItems && changedItems.length === 10) ? 'none' : 'inline'
                }}>
                {isAssign === true ? "Assign" : "Un-Assign"}</a>
            </td>
          )
        }
      }
    }
    this.CommandCell = MyActions;

  }

  componentWillReceiveProps = (props) => {
    if (props) {
      if (this.state.AvailableCount !== props.countAvailable) {
        this.setState({ AvailableCount: props.countAvailable });
      }
      if (this.state.AssignedCount !== props.countAssigned) {
        this.setState({ AssignedCount: props.countAssigned });
      }
    }
  }

  getChangedItems = () => {
    let items = this.state.gridData && this.state.gridData.length > 0 ? this.state.gridData.filter(itm => itm.IsSelected === true) : [];
    return items;
  }

  getInfo = () => {
    let changedItems = this.getChangedItems();
    const remaining = this.state.AvailableCount - this.state.AssignedCount;
    return [this.state.AvailableCount, this.state.AssignedCount, remaining, this.state.userListings, this.state.ReadOnly, changedItems];
  }

  correspondingBSSavedItems = (initialItems, assignedArr, unassignedArr) => {
    // SHOWS ITEMS THAT SAVED TO BS AND THOSE THAT FAILED
    let copy = JSON.parse(JSON.stringify(initialItems));
    let savedToBroadSoft = (data) => {
      data.map((item) => {
        copy.forEach(res => {
          if (res.CustomerProductId === item.CustomerProductId) {
            res.BSSaved = true;
          }
        });
      });
      return copy;
    }
    if ((this.state.isAssign && assignedArr.length > 0) || (!this.state.isAssign && unassignedArr.length > 0)) {
      return this.state.isAssign ? savedToBroadSoft(assignedArr) : savedToBroadSoft(unassignedArr);
    } else return initialItems;
  }

  postAddons = async () => {
    let changedItems = this.getChangedItems();
    if (!changedItems || changedItems.length === 0) return;
    if (changedItems.length > 10) {
      this.setState({ errorMsg: 'Unable to Submit more than 10 Items...' });
      return;
    }
    await this.setState({ loading: true, orderSubmitted: true, errorMsg: '' });
    let changedItemsCopy = JSON.parse(JSON.stringify(changedItems));
    let originalList = changedItemsCopy.map((itm) => {
      return this.state.userListings.filter(res => res.CustomerProductId === itm.CustomerProductId)[0];
    });
    let assignReq = {
      LocationAccountNumber: this.props.currentLocation,
      Addon: this.props.parentAddon,
      ServiceList: originalList,
    };
    let unassignReq = {
      LocationAccountNumber: this.props.currentLocation,
      ServiceList: originalList
    };
    let reqBody = this.state.isAssign ? assignReq : unassignReq;
    //console.log('request', reqBody);
    let apiPath = this.state.isAssign ? macdHelper.apiPostAssignedAddons() : macdHelper.apiPostUnassignedAddons();
    await apiCalls.post(apiPath, 'POST', JSON.stringify(reqBody)).then((res) => {
      let response = res ? (JSON.parse(res.message) || res.message) : null;
      //console.log('response', response);
      let BSAssigned = response.BSStatus.BSAssignedServices;
      let BSUnassigned = response.BSStatus.BSUnassignedServices;
      let BSErrors = response.BSStatus.BSErrorList;

      let MPOError = response.MPOStatus.MPOErrorList && response.MPOStatus.MPOErrorList.length > 0 ? response.MPOStatus.MPOErrorList[0].MPOResultText : 'An error occurred.';

      if (res.ok) {
        // IF SUCCESSFUL SUBMISSION
        if (BSErrors.length === 0) {
          let success = (data) => data.map((item) => {
            item.successfulPost = true;
            return item;
          });
          this.setState({
            errorMsg: 'Success!',
            gridData: success(changedItems),
            ReadOnly: true,
            loading: false,
          });
        }
        if (BSErrors.length > 0) {
          // BROADSOFT ERRORS PRESENT
          // SOME PARTIAL SUCCESS, SOME ITEMS SAVED TO BS
          if ((this.state.isAssign && BSAssigned && BSAssigned.length > 0) || (!this.state.isAssign && BSUnassigned && BSUnassigned.length > 0)) {
            this.setState({
              errorMsg: BSErrors,
              gridData: this.correspondingBSSavedItems(changedItems, BSAssigned, BSUnassigned),
              ReadOnly: true,
              loading: false,
            });
          }
          // NO ITEMS WERE SAVED TO BS, NO PARTIAL SUCCESS
          if ((this.state.isAssign && BSAssigned.length === 0) || (!this.state.isAssign && BSUnassigned.length === 0)) {
            this.setState({
              errorMsg: BSErrors,
              gridData: changedItems,
              ReadOnly: true,
              AssignedCount: this.props.countAssigned,
              loading: false,
            });
          }
        }
      } else {
        this.setState({
          loading: false,
          ReadOnly: true,
          gridData: this.correspondingBSSavedItems(changedItems, BSAssigned, BSUnassigned),
          errorMsg: MPOError
        });
      }
    }).catch(err => console.error(err));
  }

  fetchUserAddons = async () => {
    await this.setState({ loading: true });
    let apiPath = this.props.isAssign ? macdHelper.apiGetUserAvailableAddons(this.props.currentLocation, this.props.partNum) : macdHelper.apiGetUserAssignedAddons(this.props.currentLocation, this.props.partNum);
    apiCalls.fetchData(apiPath).then((data) => {
      if (data) {
        let copy = JSON.parse(JSON.stringify(data));
        this.setState({ gridData: copy, gridCopy: copy, userListings: data, loading: false });
      } else this.setState({ gridData: [], gridCopy: [], userListings: [], loading: false })
    }).catch(err => console.error(err));
  }

  updateConfiguredVal = (item, val) => {
    item.IsSelected = !item.IsSelected;
    if (this.state.isAssign) {
      if (val) this.setState({ AssignedCount: this.state.AssignedCount - 1 });
      else this.setState({ AssignedCount: this.state.AssignedCount + 1 });
    } else if (!this.state.isAssign) {
      if (val) this.setState({ AssignedCount: this.state.AssignedCount + 1 });
      else this.setState({ AssignedCount: this.state.AssignedCount - 1 });
    }
  }

  handleClose = async () => {
    await this.setState({ showModal: false });
    this.props.refresh(true);
  }

  handleShow = async () => {
    await this.setState({ showModal: true });
    this.fetchUserAddons();
  }

  handleCounts = () => {
    return (
      <div className="stats">
        Available: {this.state.AvailableCount} &nbsp; Assigned: {this.state.AssignedCount} &nbsp; Remaining: {this.state.AvailableCount - this.state.AssignedCount}
      </div>
    )
  }

  getSearchResults = (event) => {
    let searchText = '';
    let fullDetails = this.state.gridCopy;
    if (event && event.target && event.target.value && event.target.value.trim() !== '') {
      searchText = event.target.value;
      let users = filterBy(fullDetails, {
        logic: 'or',
        filters: [
          { field: 'ServiceNumber', operator: 'contains', value: searchText, ignoreCase: true },
          { field: 'Extension', operator: 'contains', value: searchText, ignoreCase: true },          
          { field: 'LineName', operator: 'contains', value: searchText, ignoreCase: true },
        ]
      });

      this.setState({ gridData: users })
    }
    else {
      this.setState({ gridData: fullDetails });
    }
  }

  sortChange = (e) => {
    this.setState({
      gridData: orderBy(this.state.gridData, e.sort),
      sort: e.sort
    })
  }

  gridContent = () => {
    if (this.state.loading) return <DataLoadingSpinner className='load spinner' />
    if (this.state.userListings.length === 0) return <p>No Qualifiying Products Available to Assign</p>
    if (this.state.userListings && this.state.userListings.length > 0) {
      return (
        <Grid
          data={this.state.gridData}
          style={{ maxHeight: '400px', overflowY: 'auto' }}
          sortable={{ allowUnsort: true, mode: 'single' }}
          sort={this.state.sort}
          onSortChange={this.sortChange}
        >
          <Column width="150px" field="ServiceNumber" title="Service ID" />
          <Column width="100px" field="Extension" title="Extension" />
          <Column width="200px" field='LineFirstName' title="First Name" />
          <Column width="200px" field='LineLastName' title="Last Name" />
          <Column width="100px" field='PartDescription' title="Seat Type" />
          <Column width="200px" cell={this.CommandCell} sortable={false} />
          <Column field="spacer" title=" " sortable={false} filterable={false} />

        </Grid>
      );
    }
  }

  confirmationYesAction = () => {
    this.postAddons();
  }

  errorMsg = () => {
    if (this.state.errorMsg && this.state.errorMsg.length > 0) {
      if (this.state.errorMsg === '') return;
      if (this.state.errorMsg === 'Success!') return <span style={{ color: 'green' }}>{this.state.errorMsg}</span>;
      if (this.state.errorMsg && typeof this.state.errorMsg === 'string' && this.state.errorMsg !== 'Success!' && this.state.errorMsg !== '') {
        return <span style={{ color: 'red' }}>{this.state.errorMsg}</span>;
      }
      return this.state.errorMsg.map((itm, index) => {
        if (itm && itm.ErrorMessage) return <React.Fragment key={index}><span style={{ color: 'red' }}>{helper.StringReplaceAll(itm.ErrorMessage, '</br>', '', false)}</span><br /></React.Fragment>;
        if (itm && itm.APIError) return <React.Fragment key={index}><span style={{ color: 'red' }}>{helper.StringReplaceAll(itm.APIError, '</br>', '', false)}</span><br /></React.Fragment>;
        return;
      });
    }
  }

  render() {
    return (
      <span>
        <span onClick={this.handleShow}>
          <a className="btn">{this.state.isAssign ? "Assign" : "Un-Assign"}</a>
        </span>
        <Modal dialogClassName="user-level-addon-modal" show={this.state.showModal} onHide={this.state.orderSubmitted ? () => { return } : this.handleClose}>
          <Modal.Header closeButton={this.state.orderSubmitted ? false : true}>
            <Modal.Title>{this.state.isAssign ? "Assign" : "Un-Assign"} User Add-On</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <span><b>{this.props.parentAddon.PartDescription}</b></span>
              {this.handleCounts()}
              <hr />
              <div className="macd-search" style={{ display: this.state.ReadOnly || this.state.loading ? "none" : "block" }}>
                <div className="search">
                  <input type="text" className="form-control" autoComplete={"off"} onChange={(e) => this.getSearchResults(e)} placeholder="Search Users by Name or TN" />
                </div>
              </div>
              <hr />
              {this.gridContent()}
            </div>
          </Modal.Body>
          {this.errorMsg()}
          <Modal.Footer>
            <hr />
            <div className="btns">
              <span style={{ display: this.state.ReadOnly || this.state.loading ? 'none' : 'inline' }}><Button className="btn" click={this.confirmationYesAction}>Save</Button></span>
              <span style={{ display: this.state.loading ? 'none' : 'inline' }}><Button className="btn" click={this.handleClose}>Close</Button></span>
            </div>
          </Modal.Footer>
        </Modal>
      </span >
    );
  }
};

export default MacdUserModal;
