import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { Button } from '@progress/kendo-buttons-react-wrapper';
import * as apiCalls from './../../../requests/api_calls';
import { deviceIsAssigned } from './../devices_helpers';
import SaveStatus from './../../../components/save_status';

class DeviceResetModal extends Component {

  constructor(props) {
    super(props);
    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.state = { showModal:false };
  }

  handleClose() {
    this.setState({ showModal: false }, this.props.loadDevices());
  }

  handleShow() {
    this.setState({ showModal: true });
  }

  componentDidMount() {
  }

  isAssigned = () => deviceIsAssigned(this.props.device);

  unassignOrRemoveLabel = () => this.isAssigned() ? 'Unassign' : 'Remove';

  showStatus = () => this.state.saving || this.state.statusMessage;

  successMsg = () => {
    let action = this.isAssigned() ? 'unassigned.' : 'removed.';
    return this.props.device.MacAddress + ' was successfully ' + action;
  }

  errorMsg = () => {
    let action = this.isAssigned() ? ' unassign ' : ' remove ';
    return 'Failed to' + action + this.props.device.MacAddress + '. Please try again.';
  }

  unassign = (option) => {
    let params = '?mac=' + this.props.device.MacAddress + '&delete=' + option.delete;
    this.setState({saving: true, showSaveStatus: true});
    apiCalls.send('admin/UnassignDeleteDevice' + params, 'GET').then(resp => {
      if(resp.ok) {
        this.setState({
          saving: false,
          statusMessage: this.successMsg()
        });
      } else {
        this.setState({
          saving: false,
          error: true,
          statusMessage: this.errorMsg(),
        })
      }
    }).catch(message => console.log(message));
  }

  modalBody = () => {
    let buttonClass = this.showStatus() ? 'opaque' : 'no-btn';
    let paragraphClass = this.showStatus() ? 'opaque' : 'visible';
    if (this.isAssigned()) {
      return (
        <Modal.Body>
          <p className={paragraphClass}>
            By clicking ‘Unassign’ you acknowledge the current device will have its configuration reset. 
            setting a device is service impacting if it is in use.
          </p>
          <hr />
          <button className={buttonClass} onClick={e => this.unassign({delete: true})}>
            Unassign & Remove from Inventory
          </button>
          <br /><br />
          <button className={buttonClass} onClick={e => this.unassign({delete: false})}>
            Unassign Only
          </button>
        </Modal.Body>
      )
    } else return (
      <Modal.Body>
        <p className={paragraphClass}>
          By clicking ‘Remove’ you acknowledge the current device will be removed from your inventory.
        </p>
        <hr />
        <button className={buttonClass} onClick={e => this.unassign({delete: true})}>
          Remove from Inventory
        </button>
      </Modal.Body>
    )
  }

  unassignOrRemoveButton = () => {
    return (
      <span onClick={this.handleShow}>
        <a className="btn" style={{fontSize:"small"}}>{this.unassignOrRemoveLabel()}</a>
      </span>
    )
  }

  render() {
    return (
      <span>
        {this.unassignOrRemoveButton()}
        <Modal show={this.state.showModal} onHide={this.handleClose} backdrop='static'>
          <Modal.Header closeButton>
            <Modal.Title>
              <div className={this.showStatus() ? 'opaque' : 'visible'}>
                {this.unassignOrRemoveLabel()} this device?
              </div>
            </Modal.Title>
          </Modal.Header>
          {this.modalBody()}
          <Modal.Footer>
            <div className={this.showStatus() ? 'opaque' : 'visible'}>
              <hr />
              <div className='btns'>
                <Button className="btn" click={this.handleClose}>Close</Button>
              </div>
            </div>
            <SaveStatus 
              saving={this.state.saving}
              statusMessage={this.state.statusMessage}
              error={this.state.error}
              parentCallBack={this.props.loadDevices}
            />
          </Modal.Footer>
        </Modal>
      </span>
    )
  }

}

export default DeviceResetModal;
