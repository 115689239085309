import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { Button } from '@progress/kendo-buttons-react-wrapper';

class EnterpriseDirectoryModal extends Component {

	constructor(props) {
		super(props);

		this.handleShow = this.handleShow.bind(this);
		this.handleClose = this.handleClose.bind(this);
		this.state = { showModal:false };
	}

	handleClose() {
		this.setState({ showModal: false });
	}

	handleShow() {
		this.setState({ showModal: true });
	}

	componentDidMount() {		
	}

  render() {
    
    return (
      <div className="setting">
        <span onClick={this.handleShow}> 
          <span className="name">Enterprise Directory</span>
          <a className="icon btn"><svg width="14" height="14" viewBox="0 0 24 24"><path d="m163 440l-91-91 251-250 90 90z m309-352l-48-48c-12-11-32-11-45 2l-45 45 91 91 45-45c13-13 13-33 2-45z m-408 275l-32 117 117-32z" transform="scale(0.046875 0.046875)"></path></svg></a>
        </span>
        <Modal dialogClassName="enterpriseDirectoryModal" show={this.state.showModal} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Enterprise Directory</Modal.Title>
          </Modal.Header>
          <Modal.Body>
          	<p>The Enterprise Directory lists all the services and users within your entire organization. A summary or a detail of the Enterprise Directory can be generated, which can be easily printed.</p>
			<a href="https://msp.mydigitalservices.com/MTEnterprise/EnterpriseDirectorySummary.aspx?level=G" target="_blank" className="no-btn">Enterprise Directory Summary</a>
			<a href="https://msp.mydigitalservices.com/MTEnterprise/EnterpriseDirectoryDetails.aspx?level=G" target="_blank" className="no-btn">Enterprise Directory Details</a>
			<hr />
            <div className="department-block">
              	<table className="table">
					<thead>
						<tr>
							<th>Name</th>
							<th>Service Number</th>
							<th>Extension</th>
							<th>Department</th>
							<th>Mobile</th>
							<th>Email Address</th>
							<th>Group ID</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>3100000028-01-Default (Collaborate - Audio)</td>
							<td>2057219514</td>
							<td>1007</td>
							<td>Hyperloop</td>
							<td>Yes</td>
							<td>test@momentum.com</td>
							<td>1234</td>
						</tr>
						<tr>
							<td>3100000028-01-Default (Collaborate - Audio)</td>
							<td>2057219514</td>
							<td>1007</td>
							<td>Hyperloop</td>
							<td>Yes</td>
							<td>test@momentum.com</td>
							<td>1234</td>
						</tr>
						<tr>
							<td>3100000028-01-Default (Collaborate - Audio)</td>
							<td>2057219514</td>
							<td>1007</td>
							<td>Hyperloop</td>
							<td>Yes</td>
							<td>test@momentum.com</td>
							<td>1234</td>
						</tr>
						<tr>
							<td>3100000028-01-Default (Collaborate - Audio)</td>
							<td>2057219514</td>
							<td>1007</td>
							<td>Hyperloop</td>
							<td>Yes</td>
							<td>test@momentum.com</td>
							<td>1234</td>
						</tr>
						<tr>
							<td>3100000028-01-Default (Collaborate - Audio)</td>
							<td>2057219514</td>
							<td>1007</td>
							<td>Hyperloop</td>
							<td>Yes</td>
							<td>test@momentum.com</td>
							<td>1234</td>
						</tr>
						<tr>
							<td>3100000028-01-Default (Collaborate - Audio)</td>
							<td>2057219514</td>
							<td>1007</td>
							<td>Hyperloop</td>
							<td>Yes</td>
							<td>test@momentum.com</td>
							<td>1234</td>
						</tr>
						<tr>
							<td>3100000028-01-Default (Collaborate - Audio)</td>
							<td>2057219514</td>
							<td>1007</td>
							<td>Hyperloop</td>
							<td>Yes</td>
							<td>test@momentum.com</td>
							<td>1234</td>
						</tr>
					</tbody>
				</table>
            </div>
          </Modal.Body>
          <Modal.Footer>
              <hr />
              <div className="btns">
                <Button className="btn">Save</Button>
                <Button className="btn" click={this.handleClose}>Cancel</Button>
              </div>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }

  
  getDepartmentSettings(){
    return (
        <td colSpan="2">
          <div className="table-edit">
            { this.props.type === "EDIT"?  <a className="pull-right red">Delete Department</a>: null }
            <div className="form-group">
              <label>Department Name</label>
              <input type="text" className="form-control" />
            </div>
            <div className="form-group">
              <label >Department Parent</label>
              <select className="form-control">
                <option defaultValue="None">None</option>
                <option defaultValue="Marketing">Marketing</option>
                <option defaultValue="Graphics">Graphics</option>
                <option defaultValue="Sales">Sales</option>
              </select>
            </div>
            <hr />
            <div className="btns">
              <a href="" className="btn">Save</a>
              <a href="" className="btn">Cancel</a>
            </div>
          </div>
        </td>
      )
  }
  // newDepartmentName(){
  //   if (this.props.type !== "EDIT") return null;
  //     return ('Department Name');
  // }
};

export default EnterpriseDirectoryModal;
