import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { Button } from '@progress/kendo-buttons-react-wrapper';
import _ from 'lodash';
import { DataLoadingSpinner } from '../../../components/spinners';
import * as apiCalls from '../../../requests/api_calls';
import * as EmailValidator from 'email-validator';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'; // ES6

class E911EmailNotificationModal extends Component {

    constructor(props) {
      super(props);
      
      this.state = { 
        e911AddressLoading: false,
        DisplayEmailAddress: props.CurrentLocation.EmergencyCallNotificationEmail,
        EmailAddress: props.CurrentLocation.EmergencyCallNotificationEmail,
        LocationOverride: props.CurrentLocation.LocationOverride911Email,
        AccountNumber: props.CurrentLocation.LocationAccountNumber,
        LocationName: props.CurrentLocation.LocationName,
        StreetAddress: props.StreetAddress,
        LocationList: props.Locations.filter(resp => resp.LocationAccountNumber !== props.CurrentLocation.LocationAccountNumber),
        showModal: false,
        IsEmailValid: this.isValidEmail(props.CurrentLocation.EmergencyCallNotificationEmail),
        selectedLocations: [],
        isAllLocationsSelected: false,
        saving: false,
        saveResultsMessage: '',
        savingShowError: false,
        savingShowSuccess: false,
        savingShowTestSuccess: false,
        savingTest: false,
        savingShowTestError: false,
        testResultsMessage: ''
      };
      this.handleShow = this.handleShow.bind(this);
      this.handleClose = this.handleClose.bind(this);
      this.UpdateSelectedLocations = this.UpdateSelectedLocations.bind(this);
      this.SelectAllLocations = this.SelectAllLocations.bind(this);
      this.SendTestEmail = this.SendTestEmail.bind(this);
      this.getSaveMessage = this.getSaveMessage.bind(this);
    }
  
    componentDidMount() {   

    }

    componentWillReceiveProps = (props) => {
        let emailAddress = this.state.EmailAddress;
        let displayAddress = this.state.DisplayEmailAddress;
        if (props.CurrentLocation.EmergencyCallNotificationEmail != this.state.EmailAddress)
            emailAddress = props.CurrentLocation.EmergencyCallNotificationEmail;
        if (props.CurrentLocation.EmergencyCallNotificationEmail != this.state.DisplayEmailAddress)
            displayAddress = props.CurrentLocation.EmergencyCallNotificationEmail;
        this.setState({DisplayEmailAddress: displayAddress, EmailAddress: emailAddress})
    }
  
    handleClose() {
      let state = {...this.state};
      state.showModal = false;
      state.savingShowError = false;
      state.savingShowSuccess = false;
      state.savingShowTestError = false;
      state.savingShowTestSuccess = false;      
      state.selectedLocations = [];
      this.setState(state);
    }
  
    handleShow() {
      let state = {...this.state};
      state.e911AddressLoading = true;
      state.showModal = true;
      state.e911Address = null;
      state.EmailAddress = state.DisplayEmailAddress;
      state.IsEmailValid = this.isValidEmail(state.EmailAddress);
      this.setState(state);
  
      
    }

    handleOnSave = () => {
        //if email address is valid
        if (this.state.IsEmailValid === true)
        {
            this.setState({saving: true});
            let selectedLocationAccountNumbers = [];
            this.state.selectedLocations.map((location) => {
                selectedLocationAccountNumbers.push(location.LocationAccountNumber);
            });
            selectedLocationAccountNumbers.push(this.state.AccountNumber);
            let apiPath = 'admin/locations/UpdateEmergencyNotificationEmail';
            let postData = {
                EmailAddress: this.state.EmailAddress,
                Locations: selectedLocationAccountNumbers
            }
            apiCalls.post(apiPath, 'POST', JSON.stringify(postData)).then(async resp => {
                let saveMessage = 'Success';
                let savingShowError = false;
                let savingShowSuccess = false;
                let DisplayEmail = this.state.DisplayEmailAddress;
                if(resp.ok) {
                    savingShowSuccess = true;
                    //update selected locations
                    //let selectedLocations = this.state.selectedLocations;
                    await this.props.updateParentArrayItem(selectedLocationAccountNumbers, this.state.EmailAddress);
                    DisplayEmail = this.state.EmailAddress;
                } else {
                    savingShowError = true;
                    saveMessage = 'An error occurred during save!';
                }
                this.setState({saving: false, saveResultsMessage: saveMessage, savingShowError: savingShowError, savingShowSuccess: savingShowSuccess, DisplayEmailAddress: DisplayEmail});
            }).catch(err => {
                this.setState({saving: false, saveResultsMessage: 'An error occurred during save!', savingShowError: true});
                console.error(err)
            });
        }
    }

    SendTestEmail = (event) => {
        if (this.state.IsEmailValid && this.state.EmailAddress !== '')
        {
            this.setState({savingTest: true});
            let apiPath = 'admin/locations/SendTestEmailNotification';
            let postData = {
                LocationAccountNumber: this.state.AccountNumber,
                LocationName: this.state.LocationName,
                LocationAddress: this.state.StreetAddress,
                EmailAddress: this.state.EmailAddress
            };
            
            apiCalls.post(apiPath, 'POST', JSON.stringify(postData)).then(resp => {
                let saveMessage = '';
                let savingShowTestSuccess = false;
                let savingShowTestError = false;
                if(resp.ok) {
                    saveMessage = 'Success! An Email should be received by the email address specified!';
                    savingShowTestSuccess = true
                } else {
                    saveMessage = 'An error occurred generating an emergency notification test email to the designated email address!';
                    savingShowTestError = true;
                }
                this.setState({savingTest: false, savingShowTestSuccess: savingShowTestSuccess, savingShowTestError: savingShowTestError, testResultsMessage: saveMessage});
            }).catch((message) => {
                this.setState({savingTest: false, savingShowTestSuccess: false, savingShowTestError: true, testResultsMessage: 'An error occurred generating an emergency notification test email to the designated email address!'});
                console.error(message)
            });
        } else
        {
            this.setState({savingTest: false, savingShowTestSuccess: false, savingShowTestError: true, testResultsMessage: 'An email must be specified and be a valid email format!'});
        }
    }

    handleEmailChange = (event) => {
        let email = event.target.value;
    
        let isValidEmail = this.isValidEmail(email)
        this.setState({EmailAddress: email, IsEmailValid: isValidEmail});
    }

    validateEmail=()=>{
        let email = this.state.EmailAddress;
        if (this.state.LocationOverride === true && (!email || email === ''))            
            return (<div className="inline inline-fail">If Email is left blank, email will be set to the default value defined for the parent Enterprise!</div>);
        else if(this.state.LocationOverride === true && !EmailValidator.validate(email.trim()))
            return (<div className="inline inline-fail">Email address is not valid!</div>);
        else 
            return (<div className="inline inline-fail"></div>);
        
      }
    
    isValidEmail=(email)=> (email && EmailValidator.validate(email)) || email === '';

    SelectAllLocations = (checked) => {
        let selectedLocations = [];
        let isSelectAll = checked;
        if (checked)
            selectedLocations = this.state.LocationList
        this.setState({selectedLocations: selectedLocations, isAllLocationsSelected: isSelectAll });
    }

    UpdateSelectedLocations = (locationAccountNumber, checked) => {
        let selectedLocations = this.state.selectedLocations;
        let isSelectAll = false;
        if (checked)
        {
            selectedLocations.push(this.state.LocationList.filter(resp => resp.LocationAccountNumber === locationAccountNumber)[0]);
            if (selectedLocations.length === this.state.LocationList.length)
                isSelectAll = true;
        } else {
            selectedLocations = selectedLocations.filter(resp => resp.LocationAccountNumber !== locationAccountNumber);
        }
        this.setState({selectedLocations: selectedLocations, isAllLocationsSelected: isSelectAll });
    }

    getCheckBoxHeader = () => {
        return (
            <input
                name='select-all-checkbox'
                type="checkbox"
                disabled={this.state.LocationOverride === false ? true : false}
                value={this.state.isAllLocationsSelected}
                checked={this.state.isAllLocationsSelected}
                onChange={(e) => { this.SelectAllLocations(e.target.checked)}}
            />
        );
    }

    getCheckBoxListing = (location) => {
        const bChecked = this.state.selectedLocations && this.state.selectedLocations.length > 0 && 
        this.state.selectedLocations.filter(resp => resp.LocationAccountNumber === location.LocationAccountNumber).length > 0
        return (
            <input
                name={'chk'+location.LocationName}
                type="checkbox"
                value={location.LocationAccountNumber}
                disabled={this.state.LocationOverride === false ? true : false}
                checked={ bChecked }
                onChange={(e) => { this.UpdateSelectedLocations(location.LocationAccountNumber, e.target.checked)}}
            />
        )
    }

    getLocationRows = () => {
        let responseObj = this.state.LocationList.map((location) => {
            return (
                <tr>
                    <td>{this.getCheckBoxListing(location)}</td>
                    <td>{location.LocationAccountNumber} {location.LocationName}</td>
                </tr>
            )
        });
        return responseObj;
    }

    getLocationsCheckBoxList = () => {
        return (
            <table className="CheckBoxList">
                <thead>
                    <th>{this.getCheckBoxHeader()}</th>
                    <th>Location</th>
                </thead>
                <tbody>
                    {this.getLocationRows()}
                </tbody>
            </table>
        );
    }

    getSaveMessage = () => {
        if (this.state.saving)
        {
            return (<DataLoadingSpinner className='load spinner'/>)
        } else if (this.state.savingShowError) {
            return (
                <div className="inline inline-fail text-center">
                    {this.state.saveResultsMessage}
                </div>
            );
        } else if (this.state.savingShowSuccess) {
            return (
                <ReactCSSTransitionGroup
                    transitionName="vm-setting-success"
                    transitionAppear={true}
                    transitionAppearTimeout={4000}
                    transitionEnter={true}
                    transitionEnterTimeout={4000}
                    transitionLeave={false}>
                    <div className="inline-success text-center">Success</div>
                </ReactCSSTransitionGroup>
            );
        } else {
            return null;
        }
    }

    getTestResultMessage = () => {
        if (this.state.savingTest === true) {
            return (<DataLoadingSpinner className='load spinner'/>)
        }
        else if (this.state.savingShowTestError === true) {
            return (
                <div className="inline inline-fail text-center">
                    {this.state.testResultsMessage}
                </div>
            );
        } else if (this.state.savingShowTestSuccess === true) {
            return (
                <ReactCSSTransitionGroup
                    transitionName="vm-setting-success"
                    transitionAppear={true}
                    transitionAppearTimeout={4000}
                    transitionEnter={true}
                    transitionEnterTimeout={4000}
                    transitionLeave={false}>
                    <div className="inline-success text-center">{this.state.testResultsMessage}</div>
                </ReactCSSTransitionGroup>
            );
        } else {
            return null;
        }
    }

    getModalButtons = () => {
        if (this.state.LocationOverride === true)
            return (
                <div className="btns">
                    <Button key="2" className="btn" click={x => { this.handleOnSave() } }>Save</Button>
                    <Button className="btn" click={x => {this.handleClose()} }>Close</Button>
                </div>
            );
        else
            return (
                <div className="btns">
                    <Button className="btn" click={x => {this.handleClose()} }>Close</Button>
                </div>
            );
    }

    render() {
        let state = this.state;
        let emailDisplay = ''
        let textDisplay = (!state.DisplayEmailAddress || state.DisplayEmailAddress === '') ? (state.LocationOverride === true ? 'Update' : '') : state.DisplayEmailAddress;
        if (state.LocationOverride === true || (textDisplay !== ''))
            emailDisplay = (
                <a className={this.props.labelClassName} onClick={this.handleShow}>{textDisplay}</a>
            )
        else
            emailDisplay = (<span> </span>);

        return (
            <span>
            {emailDisplay}
            <Modal dialogClassName={"emergency-call-notification-email-dialog"} show={this.state.showModal} onHide={this.handleClose}>
                <Modal.Header closeButton>
                <Modal.Title>Edit Emergency Call Notification Email</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        
                        <h5>{this.state.AccountNumber} - {this.state.LocationName}</h5>
                        <br />
                        <table>
                            <tr>
                                <td><b>Emergency Notification Email Address:</b></td>
                                <td> 
                                    <input type="type" className="form-control" value={this.state.EmailAddress} style={{width:"325px"}}
                                        disabled={this.state.LocationOverride === true ? false : true}
                                        onChange={x=>{ this.handleEmailChange(x) }} />                                    
                                </td>
                            </tr>
                            <tr>
                                <td colspan="2" style={{textAlign: "right", paddingBottom: '10px'}}>
                                    { this.validateEmail()}
                                </td>
                            </tr>
                            <tr>
                                <td colSpan="2" style={{textAlign: "right", paddingBottom: '15px'}}>
                                    <Button click={(x) => {this.SendTestEmail()}}>Test Email</Button>
                                    {this.getTestResultMessage()}
                                </td>
                            </tr>
                            <tr>
                                <td colspan="2">
                                    <h6><b>Apply Setting to Other Locations:</b></h6>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="2">
                                    {this.getLocationsCheckBoxList() }
                                </td>
                            </tr>
                        </table>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <hr />
                    {this.getModalButtons()}
                    {this.getSaveMessage()}
                    {/* <SavingMessageControl 
                        showSuccess={this.state.savingShowSuccess} 
                        showLoading={this.state.saving} 
                        showError={this.state.savingShowError}
                        SuccessMessage="Success"
                        SuccessTransitionClass="vm-setting-success"
                        ErrorMessage={}
                    /> */}
                </Modal.Footer>
            </Modal>
            </span>
        );
    }


    
};


export default E911EmailNotificationModal;