import React, { Component } from 'react';
import _ from 'lodash';
import * as helper from '../../scripts/helper'
import { customFetch } from '../../requests/api_calls'
import * as config from '../../requests/config'
import { parse } from "query-string";
import { render } from 'react-dom';
import { DataLoadingSpinner } from '../../components/spinners';

export default class SupportLinks extends Component {
    constructor(props) {
        super(props);
        let serviceProviderId = parse(window.location.search).ServiceProviderId;
        if (!serviceProviderId)
            serviceProviderId = '';

        this.state = {
            loading: true,
            DownloadLinks: [],
            ServiceProviderId: serviceProviderId,
            HostName: helper.getDomain()
        }

        this.getDownloadLinksForProvider = this.getDownloadLinksForProvider.bind(this);
    }
  
  
    componentDidMount() {
        this.getDownloadLinksForProvider();
    }

    getDownloadLinksForProvider = async() => {
        let apiPath = `${config.baseApiUrl}usr/GetDocumentsSupport?ServiceProviderId=${this.state.ServiceProviderId}&HostName=${encodeURI(this.state.HostName)}`;
        const auth = "Basic " + btoa("msp" + ":" + process.env.REACT_APP_PRPX_BASIC_PWD);
        const apiHeaders = {"Content-Type": "application/json", Accept: "application/json", Authorization: auth};
        try {
            let documentResults = await customFetch(apiPath, apiHeaders);
            this.setState({DownloadLinks: documentResults});
        } catch (e) {
            console.log('Pre login customization call failed');
        } finally
        {
          this.setState({loading: false});
        }
    }

    getDownloads = () => {
        if (this.state.DownloadLinks > 0)
        {
            return (
                <div className="documents-container">
                  {this.state.DownloadLinks.map((file, idx) => {
        
                    return (
                      <div id="document-list" key={idx}>
                        <b>
                          <a style={{
                            display: 'inline-block',
                            padding: '8px 0'
                          }}
                            tabIndex="0"
                            ref='exportBtn'
                            target='_blank'
                            href={file.FileName}
                          >
                            {file.URL}
                          </a>
                        </b>
                      </div>
                    )
                  })}
                </div>
            );
        }
        return null;
    }

    render() {
      if (this.state.loading)
      {
        return (
          <div className="supportLinks card">
            <div className="card-header">
              <h1>Help &amp; Support</h1>
            </div>
            <div className='card-content' >
              <DataLoadingSpinner className='load spinner' />
            </div>
          </div>
        );
      } else {
        return (
          <div className="supportLinks card">
            <div className="card-header">
              <h1>Help &amp; Support</h1>
            </div>
            <div className='card-content'>              
                {this.getDownloads()}
            </div >
          </div>
        );
      }
        
    }
};
