import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { Button } from '@progress/kendo-buttons-react-wrapper';
import * as apiCalls from '../../../requests/api_calls';
import _ from 'lodash';
import SaveStatus from '../../../components/save_status';
import { DataLoadingSpinner } from '../../../components/spinners';
import UserSearch from './user_search';
import * as macdHelper from '../../macd/components/macd_helper';
export default class ManageADUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAssigned: false,
      loading: false,
      Submission: false,
      submitError: false,
      showConfirmationModal: false,
      UserId: this.props.location.state.UserId,
      AzureObjId: this.props.location.state.AzureObjId,
      UserDetails: {
        EnterpriseId: sessionStorage.enterpriseId,
        LocationId: null,
        UserId: null,
        AzureObjectId: this.props.location.state.AzureObjId,
        UserPrincipalName: null,
        DisplayName: null,
        AccountEnabled: false, // verify
        Country: null,
        City: null,
        State: null,
        PostalCode: null,
        StreetAddress: null,
        CompanyName: null,
        Department: null,
        FacsimileTelephoneNumber: null,
        GivenName: null,
        Surname: null,
        Mail: null,
        MailNickName: null,
        Mobile: null,
        OtherMails: null,
        TelephoneNumber: null,
        IsDeleted: false
      },
      LocationList: sessionStorage.locationList ? JSON.parse(sessionStorage.locationList) : [],
    };
  }

  componentDidMount() {
    this.fecthAdUser(this.state.UserId);
  }

  componentWillReceiveProps = () => {
    let { UserId } = this.state;
    if (UserId !== this.props.location.state.UserId) {
      this.setState({ UserId: this.props.location.state.UserId });
    }
  }

  fecthAdUser = async (userId) => {
    await this.setState({ loading: true });
    let path = `admin/GetAzureADUser?MSPUserId=${userId}`;
    await apiCalls.fetchData(path).then(data => {
      if (data) {
        this.setState({
          UserDetails: data,
          isAssigned: true,
          loading: false,
        });
      } else {
        this.setState({
          loading: false,
        });
      }
    }).catch(err => console.error(err));
  }

  inputDataField = (val, key, inputStyle, onChangeFunc, type = 'text', readOnly) => {
    return (
      <input
        style={inputStyle}
        type={type}
        name={key}
        value={val}
        className="form-control"
        onChange={(e) => onChangeFunc(e, key)}
        disabled={readOnly}
      />
    )
  }

  dataRow = (label, field, divStyleObj, labelStyleObj) => {
    return (
      <div className="form-group-wrapper"
        style={divStyleObj}
      >
        <label
          style={labelStyleObj}
        >{label}: </label>
        {field}
      </div >
    )
  }

  dataDropDown = (locations, key, val, inputStyle) => {
    return (
      <select
        className="form-control"
        style={inputStyle}
        onChange={(e) => this.handleInputChange(e, key)}
        value={val}
      >
        {/* {options} */}
        <option value="">-- Select --</option>
        {locations.map((itm, idx) => {
          return <option key={idx} value={itm.LocationId}>{itm.LocationAccountNumber}</option>
        })}
      </select>
    )
  }

  cardContent = () => {
    let { loading, LocationList, UserDetails } = this.state;
    if (loading) {
      return (
        <DataLoadingSpinner className='load spinner' />
      );
    } else {
      let {
        EnterpriseId,
        LocationId,
        UserId,
        AzureObjectId,
        UserPrincipalName,
        DisplayName,
        AccountEnabled,
        Country,
        City,
        State,
        PostalCode,
        StreetAddress,
        CompanyName,
        Department,
        FacsimileTelephoneNumber,
        GivenName,
        Surname,
        Mail,
        MailNickName,
        Mobile,
        OtherMails,
        TelephoneNumber,
        IsDeleted,
      } = UserDetails;
      let divStyle = { margin: "1%", };
      let labelStyle = { display: 'inline-block', width: '30%', };
      let inputStyle = { width: '70%', display: 'inline-block', };
      return (
        <div className="card-content">
          {this.dataRow("Location", this.dataDropDown(LocationList, "LocationId", LocationId, inputStyle), divStyle, labelStyle)}
          {/* {this.dataRow("Select Location", this.inputDataField("TEST IGNORE", 'LocationId', inputStyle, this.handleInputChange), divStyle, labelStyle)} */}
          {<UserSearch
            onSelectUser={this.handleUserChange}
          />}
          {this.dataRow("User Principal Name", this.inputDataField(UserPrincipalName, 'UserPrincipalName', inputStyle, this.handleInputChange, "text", true), divStyle, labelStyle)}
          {this.dataRow("Department", this.inputDataField(Department, 'Department', inputStyle, this.handleInputChange, "text", true), divStyle, labelStyle)}
          {this.dataRow("Fax #", this.inputDataField(FacsimileTelephoneNumber, 'FacsimileTelephoneNumber', inputStyle, this.handleInputChange, "text", true), divStyle, labelStyle)}
          {this.dataRow("Given Name", this.inputDataField(GivenName, 'GivenName', inputStyle, this.handleInputChange, "text", true), divStyle, labelStyle)}
          {this.dataRow("Surname", this.inputDataField(Surname, 'Surname', inputStyle, this.handleInputChange, "text", true), divStyle, labelStyle)}
          {this.dataRow("Mail", this.inputDataField(Mail, 'Mail', inputStyle, this.handleInputChange, "text", true), divStyle, labelStyle)}
          {this.dataRow("Mobile", this.inputDataField(Mobile, 'Mobile', inputStyle, this.handleInputChange, "text", true), divStyle, labelStyle)}
          {this.dataRow("Telephone #", this.inputDataField(TelephoneNumber, 'TelephoneNumber', inputStyle, this.handleInputChange, "text", true), divStyle, labelStyle)}
          {this.dataRow("Active", this.inputDataField(IsDeleted === true ? "Yes" : "No", 'IsDeleted', inputStyle, this.handleInputChange, "text", true), divStyle, labelStyle)}

        </div >
      )
    }
  }

  handleUserChange = async (id) => {
    let UserDetails = { ...this.state.UserDetails };
    UserDetails["UserId"] = id;
    await this.setState({ UserDetails });
    //console.log("UserDetails", UserDetails);
  }

  inputCheck = (val) => {
    return (
      <input
        style={{
          display: 'inline-block',
        }}
        type="checkbox"
        checked={val}
        className='check-box'
      //onChange={(e) => this.handleInputChange(e, key)}
      //disabled={readOnly === true ? true : false}
      />
    );
  }

  handleInputChange = async (e, key) => {
    let { value } = e.target;
    let UserDetails = { ...this.state.UserDetails };
    UserDetails[key] = value;
    await this.setState({ UserDetails });
    //console.log("UserDetails", UserDetails); // inserted AD Information
  }

  saveAdUserConfiguaration = async () => {
    let path = 'admin/UpdateAzureADUser';
    let dto = this.state.UserDetails;
    //console.log("DTO verification", dto);
    // let response = await apiCalls.post(path, 'POST', JSON.stringify(dto)).then(response => response).catch(err => console.error(err));
    // if (response) {
    //   if (response.ok) {
    //     await this.setState({
    //       Submission: true,
    //       submitError: false,
    //       showConfirmationModal: false,
    //     });
    //   } else {
    //     await this.setState({
    //       Submission: true,
    //       submitError: true,
    //       showConfirmationModal: false,
    //     });
    //   }
    //   this.fecthAdUser(dto.UserId);
    // }
  }

  // loadAddServices = () => {
  //   this.props.history.push(macdHelper.AddEditPage());
  // }

  submissionMsg = () => {
    if (this.state.Submission) {
      if (this.state.submitError) {
        return <small style={{
          color: 'red',
          fontSize: '50%',
        }}>{this.state.errorMsg && this.state.errorMsg != "" ? this.state.errorMsg : "Submission Failure: Changes were not processed!"}</small>
      } else {
        return <small style={{
          color: 'green',
          fontSize: '50%',
        }}>Submission Sucess: Changes were processed!</small>
      }
    }
  };

  render = () => {
    return (
      <div className="sync-menu-container">
        <div className="container full-card">
          <div className="sync-menu card">
            <div className="card-header">
              <h1>Update External User {this.submissionMsg()}</h1>
              {/* <span
                style={{
                  //display: 'block',
                  position: 'relative',
                  bottom: '70px',
                  float: 'right',
                  //width: '200px'
                }}
              >
                <a
                  onClick={() => this.setState({ showConfirmationModal: true })}
                  className="a btn"
                >{this.state.isAssigned ? "Update User" : "Assign User"}</a>
                <a
                  onClick={this.loadAddServices}
                  className="orange btn"
                >Add Service</a>
              </span> */}
            </div>
            <div>
              <br />
              <br />
              <Modal show={this.state.showConfirmationModal} onHide={this.handleClose} backdrop='static'>
                <Modal.Header>
                  <Modal.Title>Are you sure you want to save changes?</Modal.Title>
                </Modal.Header>
                <Modal.Footer>
                  <hr />
                  <div className="btns">
                    <span>
                      <button type="button" className="btn" onClick={this.saveAdUserConfiguaration}>Yes</button>
                      <button className="btn" onClick={() => this.setState({ showConfirmationModal: false })}>No</button>
                    </span>
                  </div>
                </Modal.Footer>
              </Modal>
              {this.cardContent()}
            </div>
          </div>
        </div >
      </div>
    )
  }
}