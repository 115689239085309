import React, { Component, Fragment } from 'react';
import * as apiCalls from '../../requests/api_calls';
import CallRecordingUsers from './callRecordingUsers';
import VoiceUserSettings from './voiceUserSettings';
import { DataLoadingSpinner } from '../../components/spinners';
import { userIsAdmin, } from '../../scripts/helper';

export default class CallRecordingMain extends Component {
  constructor() {
    super();

    this.state = {
      loading: false,
      tab: "users",
      UsersList: [],
      FullListings: [],
      Settings: null,
    }

  }

  componentDidMount() {
    this.fetchUserLists();
    this.fetchCallRecordingUrl();
  }

  fetchCallRecordingUrl = async () => {
    await this.setState({ loading: true });
    let path = 'admin/GetCallRecordingUrl';
    await apiCalls.fetchData(path).then((data) => {
      if (data) {
        this.setState({
          Link: data[0].ApplicationURL,
        });
      } else {
        this.setState({
          Link: 'https://' + process.env.REACT_APP_CALL_RECORDING_ENV + '/SAML/Login' + '?came_from=%2Fdashboard',
        });
      }
    }).catch(err => console.error(err));
  }

  fetchUserLists = async () => {
    await this.setState({ loading: true });
    let path = 'admin/UserCallRecordingList';
    await apiCalls.fetchData(path).then((data) => {
      if (data) {
        let copy = JSON.parse(JSON.stringify(data));
        this.setState({
          UsersList: data,
          FullListings: copy,
          loading: false,
        });
      } else {
        this.setState({
          UsersList: [],
          FullListings: [],
          loading: false,
        });
      }
    }).catch(err => console.error(err));
  }

  tabDisplay = () => {
    return (
      <div className="tabs" style={{ width: "100%" }}>
        <a
          style={{ display: "inline-block" }}
          className={this.state.tab === "users" ? "active tab" : "tab"}
          data-act="config"
          onClick={() => this.changeTab('users')}
        >
          Call Recording Users
        </a>
        <a
          className={this.state.tab === "settings" ? "active tab" : "tab"}
          data-act="data"
          onClick={() => this.changeTab('settings')}
        >
          Voice User Settings
        </a>
      </div>
    );
  };

  changeTab = (tabName) => {
    this.setState({ tab: tabName })
  }

  getTab() {
    let { tab, loading, UsersList, FullListings } = this.state;
    switch (tab) {
      case "users":
        return <CallRecordingUsers
          UsersList={UsersList}
          FullListings={FullListings}
          Loading={loading}
          RefreshDataSource={this.fetchUserLists}
        />;
      // case "settings":
      //   return <VoiceUserSettings
      //     Settings={Settings}
      //     Loading={loading}
      //   />;
      default:
        console.log("Sorry there isn't a tab named " + tab + ".");
    }
  }

  cardContent = () => {
    if (!this.state.loading) {
      return (
        <Fragment>
          <div className="card-content">
            <div className="tab-body">
              {this.getTab()}
            </div>
          </div>
        </Fragment>
      )
    } else {
      return <div>
        <DataLoadingSpinner style={{ marginTop: "3%" }} className='load spinner' />
      </div>
    }
  }

  render() {
    // let MIAREC_DOMAIN = process.env.REACT_APP_CALL_RECORDING_ENV;
    // let link = 'https://' + MIAREC_DOMAIN + '/SAML/Login' + '?came_from=%2Fdashboard';
    //console.log("DASH LINK", link);
    return (
      <div className="call-recording-container">
        <div className="container full-card">
          <div className="call-recording card">
            <div className="card-header">
              <h1>Call Recording</h1>
            </div>
            {!sessionStorage.emulatedUser || sessionStorage.emulatedUser == false ? <div>
              <a id="miarec-link2" href={this.state.Link} target="_blank">Admin Dashboard</a>
            </div> : null}
            {/* {this.tabDisplay()} */}
            {this.cardContent()}
          </div>
        </div >
      </div>
    )
  }
}