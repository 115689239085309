import React from 'react';
import { DataLoadingSpinner } from './spinners';

const saveStatus = (props) => {
  if (props.saving) {
    let savingMessage = '';
    if (props.SavingMessage !== '')
    {
      savingMessage = (<span style={{fontSize: 'medium'}}>
          {props.SavingMessage}
        </span>);
    }
    return (
      <div style={{textAlign:'center'}}>
        {savingMessage}
        <DataLoadingSpinner className='load saving spinner' />
      </div>
    )
  } else if (props.error || props.statusMessage) {
    let statusClass = props.error ? ' error text-danger' : ' success text-success';
    let okBtnClass = props.hideOkBtn ? 'hidden' :'btn'
    return (
      <div className={'save-status' + statusClass}>
        {props.statusMessage}
        <div className='text-center'>
          <a className={okBtnClass} onClick={() => props.parentCallBack()}>OK</a>
        </div>
      </div>
    )
  } else return null
}

export default saveStatus;