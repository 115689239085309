import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { Button } from '@progress/kendo-buttons-react-wrapper';

class LocationManageLinesModal extends Component {

  constructor(props) {
    super(props);

    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.state = { showModal:false };
  }

  handleClose() {
    this.setState({ showModal: false });
  }

  handleShow() {
    this.setState({ showModal: true });
  }

  componentDidMount() {   
  }

  render() {
    return (
      <div className="setting">
        <span onClick={this.handleShow}> 
          <span className="name">Manage Lines</span>
          <a className="icon btn"><svg width="14" height="14" viewBox="0 0 24 24"><path d="m163 440l-91-91 251-250 90 90z m309-352l-48-48c-12-11-32-11-45 2l-45 45 91 91 45-45c13-13 13-33 2-45z m-408 275l-32 117 117-32z" transform="scale(0.046875 0.046875)"></path></svg></a>
        </span>
        <Modal show={this.state.showModal} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Manage Lines</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <p>Edit the name, description and caller ID details for services and users.</p>
            </div>
            <div id="announcementRepositoryModal">
              <table className="table">
                <thead>
                  <tr>
                    <th>First Name</th>
                    <th>Last Name</th>
                    <th>Phone Number</th>
                    <th>Extension</th>
                    <th>Line Description</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Jared</td>
                    <td>Malan</td>
                    <td>+1-2058472990</td>
                    <td>2990</td>
                    <td>Jared Malan</td>
                    <td align="right"><a className="icon btn" data-toggle="collapse" data-target="#ar1"><svg width="14" height="14" viewBox="0 0 24 24"><path d="m163 440l-91-91 251-250 90 90z m309-352l-48-48c-12-11-32-11-45 2l-45 45 91 91 45-45c13-13 13-33 2-45z m-408 275l-32 117 117-32z" transform="scale(0.046875 0.046875)"></path></svg></a></td>
                  </tr>
                  <tr className="collapse" id="ar1">
                    <td colSpan="6">
                      <div className="table-edit">
                        <div className="form-group">
                          <label>First Name</label>
                          <input type="type" className="form-control" defaultValue="" />
                        </div>
                        <div className="form-group">
                          <label>Last Name</label>
                          <input type="type" className="form-control" defaultValue="" />
                        </div>
                        <div className="form-group">
                          <label>Line Description</label>
                          <input type="type" className="form-control" defaultValue="" />
                        </div>
                        <div className="form-group">
                          <label >Outbound Caller ID</label>
                          <select className="form-control">
                            <option>123 123 1234</option>
                            <option>123 123 1235</option>
                            <option>123 123 1236</option>
                          </select>
                        </div>
                        <div className="form-group">
                          <label>Group Caller ID</label>
                          <input type="type" className="form-control" defaultValue="123 123 1234" disabled />
                        </div>
                        <div className="form-group">
                          <label className="right switch">
                            <input type="checkbox" data-toggle="collapse" data-target="#hide1" />
                            <span className="slider round"></span>
                          </label>
                          Override Group/User Outbound Caller ID
                        </div>  
                        <hr />
                        <div className="btns">
                          <a href="" className="btn">Save</a>
                          <a href="" className="btn">Cancel</a>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Modal.Body>
          <Modal.Footer>
              <hr />
              <div className="btns">
              <Button className="btn" click={this.handleClose}>Close</Button>
              </div>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
};

export default LocationManageLinesModal;
