import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { Button } from '@progress/kendo-buttons-react-wrapper';

// <ExampleModal />

class SpeedDialModal extends Component {

	constructor(props) {
		super(props);

    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);

    this.state = { showModal: false };
  }

  handleClose() {
    this.setState({ showModal: false });
  }

  handleShow() {
    this.setState({ showModal: true });
  }

	componentDidMount() {		
	}

  render() {
    return (
      <div className="setting">
        <span onClick={this.handleShow}> 
          <span className="name">Speed Dial</span>
          <a className="icon btn"><svg width="14" height="14" viewBox="0 0 24 24"><path d="m163 440l-91-91 251-250 90 90z m309-352l-48-48c-12-11-32-11-45 2l-45 45 91 91 45-45c13-13 13-33 2-45z m-408 275l-32 117 117-32z" transform="scale(0.046875 0.046875)"></path></svg></a>
        </span>

        <Modal show={this.state.showModal} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Speed Dial</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <p>Speed Dial allows you to set up quick dial shortcuts for your most frequent calls. </p>
              <a href="" className="no-btn">Export Contacts</a>
              <hr />
              <table className="table">
                <thead>
                  <tr>
                    <th className="check"><input type="checkbox" /></th>
                      <th>Name</th>
                      <th>Phone Number</th>
                      <th>Speed Dial</th>
                      <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="check"><input type="checkbox" /></td>
                    <td>Joe Single</td>
                    <td>123 123 1234</td>
                    <td>01</td>
                    <td align="right"><a className="icon btn" data-toggle="collapse" data-target="#sca1"><svg width="14" height="14" viewBox="0 0 24 24"><path d="m163 440l-91-91 251-250 90 90z m309-352l-48-48c-12-11-32-11-45 2l-45 45 91 91 45-45c13-13 13-33 2-45z m-408 275l-32 117 117-32z" transform="scale(0.046875 0.046875)"></path></svg></a></td>
                  </tr>
                  <tr className="collapse" id="sca1">
                    <td colSpan="5">
                        <div className="table-edit">
                          <a href="" className="red delete">Delete Speed Dial Contact</a>
                        <div className="halves">
                          <div className="half">    
                                <div className="form-group">
                                  <label >First Name:</label>
                                  <input type="type" className="form-control" />
                                </div>
                          </div>
                          <div className="half">    
                                <div className="form-group">
                                  <label >Last Name:</label>
                                  <input type="type" className="form-control" />
                                </div>
                          </div>
                        </div>
                        <div className="form-group">
                          <label >Home Phone:</label>
                          <input type="type" className="form-control" />
                        </div>
                        <div className="form-group">
                          <label >Work Phone:</label>
                          <input type="type" className="form-control" />
                        </div>
                        <div className="form-group">
                          <label >Mobile Phone:</label>
                          <input type="type" className="form-control" />
                        </div>  
                        <div className="form-group">
                          <label >Primary Phone</label>
                          <select className="form-control">
                            <option defaultValue="">-Select-</option>
                            <option defaultValue="">Home Phone</option>
                            <option defaultValue="">Work Phone</option>
                            <option defaultValue="">Mobile Phone</option>
                          </select>
                        </div>
                          <div>Note: Some Speed Dial codes conflict with certain Feature Access Codes. See below for a list of conflicting Speed Dial codes that completely over-ride their Feature Access Code functionality when active: 
                          <hr />
                          <div className="">
                              #31 Calling Line ID Delivery Blocking Persistent Deactivation <br />
                              #40 Call Forwarding Busy To Voice Mail Deactivation <br />
                              #41 Call Forwarding No Answer To Voice Mail Deactivation <br />
                              #43 Call Waiting Persistent Deactivation <br />
                              #58 Group Call Park <br />
                              #70 Night Service Activation Manual Override <br />
                              #71 Night Service Deactivation Manual Override<br />
                              #72 Forced Forwarding Activation <br />
                              #73 Forced Forwarding Deactivation <br />
                              #76 Call Forwarding Selective Activation <br />
                              #77 Call Forwarding Selective Deactivation <br />
                              #80 Make Outgoing Call as Call Center <br />
                              #81 Make Personal Outgoing Call <br />
                              #83 Escalate Call to Supervisor<br />
                          </div>
                          <hr />
                          <div className="form-group">
                            <label >Speed Dial Code</label>
                            <select className="form-control">
                              <option defaultValue="">-Select-</option>
                              <option defaultValue="">00</option>
                              <option defaultValue="">01</option>
                              <option defaultValue="">02</option>
                              <option defaultValue="">03</option>
                              <option defaultValue="">...</option>
                              <option defaultValue="">99</option>
                            </select>
                          </div>
                        </div>
                        <hr />
                        <div className="btns">
                          <a href="" className="btn">Save</a>
                          <a href="" className="btn">Cancel</a>
                        </div>
                        </div>
                    </td>
                  </tr>
                  <tr>
                    <td className="check"><input type="checkbox" /></td>
                    <td>Joe Single</td>
                    <td>123 123 1234</td>
                    <td>01</td>
                    <td align="right"><a className="icon btn" data-toggle="collapse" data-target="#sca2"><svg width="14" height="14" viewBox="0 0 24 24"><path d="m163 440l-91-91 251-250 90 90z m309-352l-48-48c-12-11-32-11-45 2l-45 45 91 91 45-45c13-13 13-33 2-45z m-408 275l-32 117 117-32z" transform="scale(0.046875 0.046875)"></path></svg></a></td>
                  </tr>
                  <tr className="collapse" id="sca2">
                    <td colSpan="5">
                        <div className="table-edit">
                          <a href="" className="red delete">Delete Speed Dial Contact</a>
                        <div className="halves">
                          <div className="half">    
                                <div className="form-group">
                                  <label >First Name:</label>
                                  <input type="type" className="form-control" />
                                </div>
                          </div>
                          <div className="half">    
                                <div className="form-group">
                                  <label >Last Name:</label>
                                  <input type="type" className="form-control" />
                                </div>
                          </div>
                        </div>
                        <div className="form-group">
                          <label >Home Phone:</label>
                          <input type="type" className="form-control" />
                        </div>
                        <div className="form-group">
                          <label >Work Phone:</label>
                          <input type="type" className="form-control" />
                        </div>
                        <div className="form-group">
                          <label >Mobile Phone:</label>
                          <input type="type" className="form-control" />
                        </div>  
                        <div className="form-group">
                          <label >Primary Phone</label>
                          <select className="form-control">
                            <option defaultValue="">-Select-</option>
                            <option defaultValue="">Home Phone</option>
                            <option defaultValue="">Work Phone</option>
                            <option defaultValue="">Mobile Phone</option>
                          </select>
                        </div>
                          <div>Note: Some Speed Dial codes conflict with certain Feature Access Codes. See below for a list of conflicting Speed Dial codes that completely over-ride their Feature Access Code functionality when active: 
                          <hr />
                          <div className="">
                              #31 Calling Line ID Delivery Blocking Persistent Deactivation <br />
                              #40 Call Forwarding Busy To Voice Mail Deactivation <br />
                              #41 Call Forwarding No Answer To Voice Mail Deactivation <br />
                              #43 Call Waiting Persistent Deactivation <br />
                              #58 Group Call Park <br />
                              #70 Night Service Activation Manual Override <br />
                              #71 Night Service Deactivation Manual Override<br />
                              #72 Forced Forwarding Activation <br />
                              #73 Forced Forwarding Deactivation <br />
                              #76 Call Forwarding Selective Activation <br />
                              #77 Call Forwarding Selective Deactivation <br />
                              #80 Make Outgoing Call as Call Center <br />
                              #81 Make Personal Outgoing Call <br />
                              #83 Escalate Call to Supervisor<br />
                          </div>
                          <hr />
                          <div className="form-group">
                            <label >Speed Dial Code</label>
                            <select className="form-control">
                              <option defaultValue="">-Select-</option>
                              <option defaultValue="">00</option>
                              <option defaultValue="">01</option>
                              <option defaultValue="">02</option>
                              <option defaultValue="">03</option>
                              <option defaultValue="">...</option>
                              <option defaultValue="">99</option>
                            </select>
                          </div>
                        </div>
                        <hr />
                        <div className="btns">
                          <a href="" className="btn">Save</a>
                          <a href="" className="btn">Cancel</a>
                        </div>
                        </div>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="5">
                      <div className="btns">
                        <a href="" className="btn" data-toggle="collapse" data-target="#sca2">Add Contact</a>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Modal.Body>
          <Modal.Footer>
              <hr />
              <div className="btns">
              <Button className="btn" click={this.handleClose}>Close</Button>
              </div>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
};

export default SpeedDialModal;
