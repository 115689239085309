import React, { Component } from 'react';
import format from 'string-format';
import CallLogsFrame from './call_logs_frame';

export default class IPCallLogsDetailed extends Component {

  getApiUrl() {
    let apiUrl = format('usr/GetIpTrunkingCallLogs?LocationAccountNumber={LocationAccountNumber}&TrunkID={TrunkID}', this.props.match.params);
    return apiUrl;
  }

  render() {
    return <CallLogsFrame
      cardTitle={'Detailed Call Logs'}
      apiPath={this.getApiUrl()}
      accountNumber={this.props.match.params.LocationAccountNumber}
      trunkId={this.props.match.params.TrunkID}
      isLocation={false}
    />
  }
};