import React from 'react';
import DeviceSettingsModal from './deviceSettingsModal';

const instructions = () => {
  return (
    <div>
      <p>Individually add devices to inventory. Once added, devices can be assigned to users and configured.</p>
      {/*
        <p>Individually add or bulk upload devices to inventory.  Once added, devices can be assigned to users and configured.</p>
        <a className="no-btn">Download Inventory Template </a>
        <a className="no-btn">Bulk Upload Inventory</a>
      */}
    </div>
  )
}

const showModalBtn = () => {
  return <span><a className="orange btn">Add Device</a></span>
}

const DeviceAddModal = (props) => {
  return (
    <DeviceSettingsModal
      title={'Add Device Inventory'}
      instructions={instructions()}
      showModalBtn={showModalBtn()}
      loadDevices={props.loadDevices}
    />
  )
}

export default DeviceAddModal
