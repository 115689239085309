import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { Button } from '@progress/kendo-buttons-react-wrapper';
import _ from 'lodash';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'; // ES6
import { DataLoadingSpinner } from '../../../components/spinners';
import { connect } from 'react-redux';
import { fetchData, post } from '../../../requests/api_calls';
import * as config from '../../../requests/config';
import * as helper from '../../../scripts/helper';

const StructureNum = "StructureNum",
  City = "City",
  ElevationNum = "ElevationNum",
  State = "State",
  UnitType = "UnitType",
  UnitNum = "UnitNum",
  ZipCode = "ZipCode",
  StreetName = "StreetName",
  StreetNumber = "StreetNumber",
  HouseNumber = "HouseNumber",
  PostDirectional = "PostDirectional",
  PreDirectional = "PreDirectional",
  StreetType = "StreetType",
  StreetSuffix = "StreetSuffix",
  Street1 = "Street1",
  Street2 = "Street2",
  PhoneNumber = "PhoneNumber",
  DirectionPrefix = "DirectionPrefix",
  DirectionSuffix = "DirectionSuffix",
  ElevationType = "ElevationType",
  StructureType = "StructureType",
  County = "County"

const emptye911Address = {
  savingLoading: false,
  savingShowSuccess: false,
  savingErrorMessage: "",
  savingShowError: false,
  showAddress: false,
  StructureNum: "",
  City: "",
  ElevationNum: "",
  State: "",
  UnitType: "",
  UnitNum: "",
  ZipCode: "",
  StreetName: "",
  HouseNumber: "",
  StreetNumber: "",
  PostDirectional: "",
  PreDirectional: "",
  StreetType: "",
  StreetSuffix: "",
  Street1: "",
  Street2: "",
  PhoneNumber: "",
  IsActive: false,
  Address1: '',
  Address2: '',
  DirectionPrefix:"",
  DirectionSuffix:"",
  ElevationType :"",
  StructureType :"",
  County :""
};

const states = {
  'Alabama': 'AL',
  'Alaska': 'AK',
  'American Samoa': 'AS',
  'Arizona': 'AZ',
  'Arkansas': 'AR',
  'California': 'CA',
  'Colorado': 'CO',
  'Connecticut': 'CT',
  'Delaware': 'DE',
  'District Of Columbia': 'DC',
  'Federated States Of Micronesia': 'FM',
  'Florida': 'FL',
  'Georgia': 'GA',
  'Guam': 'GU',
  'Hawaii': 'HI',
  'Idaho': 'ID',
  'Illinois': 'IL',
  'Indiana': 'IN',
  'Iowa': 'IA',
  'Kansas': 'KS',
  'Kentucky': 'KY',
  'Louisiana': 'LA',
  'Maine': 'ME',
  'Marshall Islands': 'MH',
  'Maryland': 'MD',
  'Massachusetts': 'MA',
  'Michigan': 'MI',
  'Minnesota': 'MN',
  'Mississippi': 'MS',
  'Missouri': 'MO',
  'Montana': 'MT',
  'Nebraska': 'NE',
  'Nevada': 'NV',
  'New Hampshire': 'NH',
  'New Jersey': 'NJ',
  'New Mexico': 'NM',
  'New York': 'NY',
  'North Carolina': 'NC',
  'North Dakota': 'ND',
  'Northern Mariana Islands': 'MP',
  'Ohio': 'OH',
  'Oklahoma': 'OK',
  'Oregon': 'OR',
  'Palau': 'PW',
  'Pennsylvania': 'PA',
  'Puerto Rico': 'PR',
  'Rhode Island': 'RI',
  'South Carolina': 'SC',
  'South Dakota': 'SD',
  'Tennessee': 'TN',
  'Texas': 'TX',
  'Utah': 'UT',
  'Vermont': 'VT',
  'Virgin Islands': 'VI',
  'Virginia': 'VA',
  'Washington': 'WA',
  'West Virginia': 'WV',
  'Wisconsin': 'WI',
  'Wyoming': 'WY'
};

class e911Modal extends Component {

  constructor(props) {
    super(props);
    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.state = {
      showUser911: props.showUser911,
      E911ButtonText: props.showUser911 ? 'Temporarily Update Current 911 Service Address' : 'See 911 Address List',
      elist911Loading: false,
      list911: [
        emptye911Address
      ],
      existingList911: [
        emptye911Address
      ],
      e911AddressLoading: false,
      e911Address: emptye911Address,
      existinge911Address: emptye911Address,
      AccountNumber: this.props.AccountNumber,
      ServiceNumber: this.props.ServiceNumber,
      showModal: props.showModal || false
    };
    this.getE911List = this.getE911List.bind(this);
    this.getList911Addresses = this.getList911Addresses.bind(this);
  }

  componentDidMount() {
  }

  handleClose() {
    let state = { ...this.state };
    state.showModal = false;
    this.setState(state);
  }

  handleShow() {
    let state = { ...this.state };
    state.e911AddressLoading = true;
    state.elist911Loading = true;
    state.showModal = true;
    state.list911 = [];
    state.e911Address = null;
    this.setState(state);

    let list911 = `usr/Get911List?AccountNumber=${state.AccountNumber}`;
    this.fetch911List(list911);
    if (this.state.showUser911) {
      let list911Address = `usr/Get911Address?ServiceNumber=${state.ServiceNumber}`;
      this.fetch911Address(list911Address);
    }
  }

  fetch911List = (apiPath, errorMsg) => {
    fetchData(apiPath).then((data) => {

      let state = { ...this.state };
      if (data) {
        state.existingList911 = data;
        state.list911 = state.existingList911 && state.existingList911.length > 0 ? state.existingList911.slice() : [];
      }
      state.elist911Loading = false;
      this.setState(state);
    }).catch((message) => {
      let state = { ...this.state };
      state.elist911Loading = false;
      fetch_error: message.toString()
      this.setState(state);
    });
  }

  fetch911Address = (apiPath, errorMsg) => {
    fetchData(apiPath).then((data) => {
      //console.log(JSON.stringify(data));
      let state = { ...this.state };
      state.e911Address = data;
      state.existinge911Address = data;
      state.e911AddressLoading = false;
      this.setState(state);
    }).catch((message) => {
      let state = { ...this.state };
      state.e911AddressLoading = false;
      fetch_error: message.toString()
      this.setState(state);
    });
  }

  getE911List = () => {
    if (this.state.elist911Loading) {
      return (
        <div style={{ height: 100 }}>
          <DataLoadingSpinner className='load spinner' />
        </div>
      );
    }

    if (this.state.existingList911.length === 0) {
      return (
        <div style={{ height: 100 }} className='empty'>
          <h4>No address</h4>
        </div>
      )
    }
    let addresses = [];

    this.state.existingList911.forEach((e99Address, i) => {
      e99Address.Address1 = '';
      e99Address.Address2 = '';
      let addressLine1 = this.getAddressLine1(e99Address);
      let addressLine2 = this.getAddressLine2(e99Address);

      e99Address.Address1 = addressLine1;
      e99Address.Address2 = addressLine2;

      addresses.push((
        <tr key={addresses.length}>
          <td>{e99Address.PhoneNumber}</td>
          <td>{e99Address.Address1} {e99Address.Address2}</td>
          <td>{e99Address.IsActive ? "Active" : "Not Active"}</td>
          <td align="right">
            {/* <a className="icon btn" data-toggle="collapse" data-target="#opener">
              <svg width="14" height="14" viewBox="0 0 24 24"><path d="m163 440l-91-91 251-250 90 90z m309-352l-48-48c-12-11-32-11-45 2l-45 45 91 91 45-45c13-13 13-33 2-45z m-408 275l-32 117 117-32z" transform="scale(0.046875 0.046875)"></path>
              </svg>
            </a> */}
            <a className="icon btn" onClick={e => this.handleToggleEditList911(e99Address)}>
              <svg width="14" height="14" viewBox="0 0 24 24"><path d="m163 440l-91-91 251-250 90 90z m309-352l-48-48c-12-11-32-11-45 2l-45 45 91 91 45-45c13-13 13-33 2-45z m-408 275l-32 117 117-32z" transform="scale(0.046875 0.046875)"></path>
              </svg>
            </a>
          </td>
        </tr>
      ));

      let editableAddress = this.state.list911[i];
      if (editableAddress=== undefined) {
        return (
          <div style={{ height: 100 }} className='empty'>
            <h4>No address</h4>
          </div>
        );
      }
      if (editableAddress.showAddress) {
        addresses.push((this.addressForm(addresses.length, editableAddress, i, "911List")));
      }
      else {
        addresses.push(<tr key={addresses.length}></tr>);
      }
    });

    return (
      <table className="table">
        <thead>
          <tr>
            {/* <th className="check"><input type="checkbox" /></th> */}
            <th>Phone Number</th>
            <th>Address</th>
            <th>Status</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {addresses}
        </tbody>
      </table>);
  }

  getList911Addresses = () => {
    if (this.state.e911AddressLoading) {
      return (
        <div style={{ height: 100 }}>
          <DataLoadingSpinner className='load spinner' />
        </div>
      );
    }

    if (!this.state.existinge911Address) {
      return (
        <div style={{ height: 100 }} className='empty'>
          <h4>No address</h4>
        </div>
      );
    }

    let e99Address = this.state.existinge911Address;
    let addresses = [];
    e99Address.Address1 = '';
    e99Address.Address2 = '';
    let addressLine1 = this.getAddressLine1(e99Address);
    let addressLine2 = this.getAddressLine2(e99Address);

    e99Address.Address1 = addressLine1;
    e99Address.Address2 = addressLine2;

    addresses.push((
      <tr key={addresses.length}>
        <td>{e99Address.PhoneNumber}</td>
        <td>{e99Address.Address1} {e99Address.Address2}</td>
        <td>{e99Address.IsActive ? "Active" : "Not Active"}</td>
        <td align="right">
          {/* <a className="icon btn" data-toggle="collapse" data-target="#opener">
            <svg width="14" height="14" viewBox="0 0 24 24"><path d="m163 440l-91-91 251-250 90 90z m309-352l-48-48c-12-11-32-11-45 2l-45 45 91 91 45-45c13-13 13-33 2-45z m-408 275l-32 117 117-32z" transform="scale(0.046875 0.046875)"></path>
            </svg>
          </a> */}
          <a className="icon btn" onClick={e => this.handleToggleEdit911Addresses(e99Address)}>
            <svg width="14" height="14" viewBox="0 0 24 24"><path d="m163 440l-91-91 251-250 90 90z m309-352l-48-48c-12-11-32-11-45 2l-45 45 91 91 45-45c13-13 13-33 2-45z m-408 275l-32 117 117-32z" transform="scale(0.046875 0.046875)"></path>
            </svg>
          </a>
        </td>
      </tr>
    ));
    let editableAddress = this.state.e911Address;
    if (editableAddress.showAddress) {
      //addresses.push((this.addressForm(addresses.length, editableAddress, 1, "911Address")));
      // ** changed 1 -> 0
      addresses.push((this.addressForm(addresses.length, editableAddress, 0, "911Address")));
    }
    else {
      addresses.push(<tr key={addresses.length}></tr>);
    }

    return (
      <table className="table">
        <thead>
          <tr>
            <th>Phone Number</th>
            <th>Address</th>
            <th>Status</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {addresses}
        </tbody>
      </table>);
  }

  getAddressLine1 = (e99Address) => {
    return e99Address.Street1 + ' ' + e99Address.City + ', ' + e99Address.State + ' ' + e99Address.ZipCode;
  }

  getAddressLine2 = (e99Address) => {
    let addressLine2 = '';

    if ((e99Address.UnitType && e99Address.UnitType !== '') || (e99Address.UnitNum && e99Address.UnitNum !== '')) {
      addressLine2 = (e99Address.UnitType || 'UnitType') + (e99Address.UnitNum ? ' ' + e99Address.UnitNum : '');
    }

    if ((e99Address.Building || e99Address.StructureNum) && (e99Address.Building !== '' || e99Address.StructureNum !== '')) {
      if (addressLine2 !== '')
        addressLine2 += (e99Address.Building ? ' ' + e99Address.Building : ' BLDG') + (e99Address.StructureNum ? ' ' + e99Address.StructureNum : '');
      else
        addressLine2 += (e99Address.Building ? e99Address.Building : 'BLDG') + (e99Address.StructureNum ? ' ' + e99Address.StructureNum : '');
    }
    if (e99Address.ElevationNum && e99Address.ElevationNum !== '') {
      if (addressLine2 !== '')
        addressLine2 += ' FLR ' + e99Address.ElevationNum;
      else
        addressLine2 += 'FLR ' + e99Address.ElevationNum;
    }
    return addressLine2;
  }

  handleOnChange = (index, fieldName, listType, event) => {
    let state = { ...this.state };
    let newList911 = state.list911.slice();
    let existing911List = state.existingList911.slice();
    let address = {};

    if (listType === "911List") {
      address = {
        savingLoading: newList911[index].savingLoading,
        savingShowSuccess: newList911[index].savingShowSuccess,
        savingErrorMessage: newList911[index].savingErrorMessage,
        savingShowError: newList911[index].savingShowError,
        showAddress: newList911[index].showAddress,
        StructureNum: newList911[index].StructureNum,
        City: newList911[index].City,
        ElevationNum: newList911[index].ElevationNum,
        State: newList911[index].State,
        UnitType: newList911[index].UnitType,
        UnitNum: newList911[index].UnitNum,
        ZipCode: newList911[index].ZipCode,
        StreetName: newList911[index].StreetName,
        HouseNumber: newList911[index].HouseNumber, //|| newList911[index].StreetNumber,
        StreetNumber: newList911[index].StreetNumber, //|| newList911[index].HouseNumber,
        PostDirectional: newList911[index].PostDirectional,
        PreDirectional: newList911[index].PreDirectional,
        StreetType: newList911[index].StreetType,
        StreetSuffix: newList911[index].StreetSuffix,
        Street1: newList911[index].Street1,
        Street2: newList911[index].Street2,
        PhoneNumber: newList911[index].PhoneNumber,
        IsActive: newList911[index].IsActive,
        Address1: newList911[index].Address1,
        Address2: newList911[index].Address2,
        DirectionPrefix: newList911[index].DirectionPrefix,
        DirectionSuffix: newList911[index].DirectionSuffix,
        ElevationType : newList911[index].ElevationType,
        StructureType : newList911[index].StructureType,
        County : newList911[index].County
      }
    }
    else if (listType === "911Address") {
      //address = state.e911Address;
      address = {
        savingLoading: state.e911Address.savingLoading,
        savingShowSuccess: state.e911Address.savingShowSuccess,
        savingErrorMessage: state.e911Address.savingErrorMessage,
        savingShowError: state.e911Address.savingShowError,
        showAddress: state.e911Address.showAddress,
        StructureNum: state.e911Address.StructureNum,
        City: state.e911Address.City,
        ElevationNum: state.e911Address.ElevationNum,
        State: state.e911Address.State,
        UnitType: state.e911Address.UnitType,
        UnitNum: state.e911Address.UnitNum,
        ZipCode: state.e911Address.ZipCode,
        StreetName: state.e911Address.StreetName,
        HouseNumber: state.e911Address.HouseNumber || state.e911Address.StreetNumber,
        StreetNumber: state.e911Address.StreetNumber || state.e911Address.HouseNumber,
        PostDirectional: state.e911Address.PostDirectional,
        PreDirectional: state.e911Address.PreDirectional,
        StreetType: state.e911Address.StreetType,
        StreetSuffix: state.e911Address.StreetSuffix,
        Street1: state.e911Address.Street1,
        Street2: state.e911Address.Street2,
        PhoneNumber: state.e911Address.PhoneNumber,
        IsActive: state.e911Address.IsActive,
        Address1: state.e911Address.Address1,
        Address2: state.e911Address.Address2,
        DirectionPrefix:state.e911Address.DirectionPrefix,
        DirectionSuffix:state.e911Address.DirectionSuffix,
        ElevationType : state.e911Address.ElevationType ,
        StructureType : state.e911Address.StructureType,
        County : state.e911Address.County 
      }
    }

    address[fieldName] = event.target.value;

    address.Street1 = helper.CreateFullStreet1(address);
    address.Street2 = helper.CreateFullStreet2(address);
    if (listType === "911List") {
      newList911[index] = address;
    }
    else if (listType === "911Address") {
      state.e911Address = address;
    }
    state.list911 = newList911.slice();
    state.existingList911 = existing911List;
    this.setState(state);
  }

  save911InList = (e911Address, indexInList) => {
    if (e911Address.StructureNum && e911Address.StructureNum !== '')
      e911Address.Building = helper.ValueOfBuildingTag;
      // e911Address.StructureType = helper.ValueOfBuildingTag;
      // e911Address.ElevationType = helper.ValueOfFloorTag;
    let state = { ...this.state };
    state.list911[indexInList].savingLoading = true;
    state.list911[indexInList].savingShowSuccess = false;
    this.setState(state);
    state.list911[indexInList].Address1 = this.getAddressLine1(state.list911[indexInList]);
    let body = JSON.stringify(e911Address);
    //console.log('POST BODY', JSON.parse(body));

    let headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + config.storedAccessToken(),
      'X-Policy': config.storedXPolicy()
    };

    post("usr/ChangeTempE911", "POST", body, headers)
      .then(response => {
        let state = { ...this.state };
        if (response.ok) {
          state.list911[indexInList].Address1 = this.getAddressLine1(state.list911[indexInList]);
          state.list911[indexInList].Address2 = this.getAddressLine2(state.list911[indexInList]);
          state.list911[indexInList].IsActive = false;
          state.list911[indexInList].savingShowSuccess = true;
          state.list911[indexInList].savingLoading = false;
          state.list911[indexInList].savingShowError = false;
          state.existingList911 = state.list911;

          if (state.e911Address && state.e911Address.PhoneNumber === state.list911[indexInList].PhoneNumber) {
            let showHideStatus = state.e911Address.showAddress || false;
            state.e911Address = {
              savingLoading: false,
              savingShowSuccess: false,
              savingErrorMessage: "",
              savingShowError: false,
              showAddress: showHideStatus,
              StructureNum: state.list911[indexInList].StructureNum,
              City: state.list911[indexInList].City,
              ElevationNum: state.list911[indexInList].ElevationNum,
              State: state.list911[indexInList].State,
              UnitType: state.list911[indexInList].UnitType,
              UnitNum: state.list911[indexInList].UnitNum,
              ZipCode: state.list911[indexInList].ZipCode,
              StreetName: state.list911[indexInList].StreetName,
              HouseNumber: state.list911[indexInList].HouseNumber,
              StreetNumber: state.list911[indexInList].StreetNumber,
              PostDirectional: state.list911[indexInList].PostDirectional,
              PreDirectional: state.list911[indexInList].PreDirectional,
              StreetType: state.list911[indexInList].StreetType,
              StreetSuffix: state.list911[indexInList].StreetSuffix,
              Street1: state.list911[indexInList].Street1,
              Street2: state.list911[indexInList].Street2,
              PhoneNumber: state.list911[indexInList].PhoneNumber,
              IsActive: state.list911[indexInList].IsActive,
              Address1: state.list911[indexInList].Address1,
              Address2: state.list911[indexInList].Address2,
              DirectionPrefix:state.list911[indexInList].DirectionPrefix,
              DirectionSuffix:state.list911[indexInList].DirectionSuffix,
              ElevationType :state.list911[indexInList].ElevationType,
              StructureType :state.list911[indexInList].StructureType,
              County :state.list911[indexInList].County
            };
            state.existinge911Address = state.e911Address;
          }
        }
        else {
          state.list911[indexInList].savingLoading = false;
          state.list911[indexInList].savingShowError = true;
          state.list911[indexInList].savingErrorMessage = "Failed Save";
        }
        this.setState(state);
      })
      .catch((message) => {
        console.log(message);
        let state = { ...this.state };
        state.list911[indexInList].savingLoading = false;
        state.list911[indexInList].savingShowError = true;
        state.list911[indexInList].savingErrorMessage = "Failed Save";
        this.setState(state);
      });
  }

  save911AddressesInList = (e911Address, indexInList) => {
    let state = { ...this.state };
    if (e911Address.StructureNum && e911Address.StructureNum !== '')
      e911Address.Building = helper.ValueOfBuildingTag;
    state.e911Address = e911Address
    state.e911Address.savingLoading = true;
    state.e911Address.savingShowSuccess = false;
    this.setState(state);

    let body = JSON.stringify(e911Address);
    // console.log('POST BODY', JSON.parse(body));
    // console.log('check state vars', state);
    // console.log("state.list911[indexInList]", state.list911[indexInList]);
    let headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + config.storedAccessToken(),
      'X-Policy': config.storedXPolicy()
    };

    post("usr/ChangeTempE911", "POST", body, headers)
      .then(response => {
        let state = { ...this.state };
        state.e911Address.savingLoading = false;
        if (response.ok) {

          state.e911Address.Address1 = this.getAddressLine1(state.list911[indexInList]);
          state.e911Address.Address2 = this.getAddressLine2(state.list911[indexInList]);
          state.e911Address.IsActive = false;
          state.e911Address.savingShowSuccess = true;
          state.e911Address.savingShowError = false;
          state.e911Address.savingErrorMessage = "";
          state.existinge911Address = state.e911Address;
          if (state.list911 && state.list911.length > 0) {
            let newList = state.list911.slice();
            let newExistingList = newList.slice();
            let e911InList = newList.filter(resp => resp.PhoneNumber === state.e911Address.PhoneNumber);
            if (e911InList) {
              e911InList = e911InList[0];
              let listIdx = _.indexOf(newList, e911InList);
              let itemInList = newList[listIdx];
              let showHideStatus = itemInList.showAddress || false;
              newList[listIdx] = {
                savingLoading: false,
                savingShowSuccess: false,
                savingErrorMessage: "",
                savingShowError: false,
                showAddress: showHideStatus,
                StructureNum: state.e911Address.StructureNum,
                City: state.e911Address.City,
                ElevationNum: state.e911Address.ElevationNum,
                State: state.e911Address.State,
                UnitType: state.e911Address.UnitType,
                UnitNum: state.e911Address.UnitNum,
                ZipCode: state.e911Address.ZipCode,
                StreetName: state.e911Address.StreetName,
                HouseNumber: state.e911Address.HouseNumber,
                StreetNumber: state.e911Address.StreetNumber,
                PostDirectional: state.e911Address.PostDirectional,
                PreDirectional: state.e911Address.PreDirectional,
                StreetType: state.e911Address.StreetType,
                StreetSuffix: state.e911Address.StreetSuffix,
                Street1: state.e911Address.Street1,
                Street2: state.e911Address.Street2,
                PhoneNumber: state.e911Address.PhoneNumber,
                IsActive: state.e911Address.IsActive,
                Address1: state.e911Address.Address1,
                Address2: state.e911Address.Address2,
                DirectionPrefix:state.e911Address.DirectionPrefix,
                DirectionSuffix:state.e911Address.DirectionSuffix,
                ElevationType :state.e911Address.ElevationType,
                StructureType :state.e911Address.StructureType,
                County :state.e911Address.County
              };
              state.list911 = newList;

              newExistingList[listIdx] = {
                savingLoading: false,
                savingShowSuccess: false,
                savingErrorMessage: "",
                savingShowError: false,
                showAddress: false,
                StructureNum: state.e911Address.StructureNum,
                City: state.e911Address.City,
                ElevationNum: state.e911Address.ElevationNum,
                State: state.e911Address.State,
                UnitType: state.e911Address.UnitType,
                UnitNum: state.e911Address.UnitNum,
                ZipCode: state.e911Address.ZipCode,
                StreetName: state.e911Address.StreetName,
                HouseNumber: state.e911Address.HouseNumber,
                StreetNumber: state.e911Address.StreetNumber,
                PostDirectional: state.e911Address.PostDirectional,
                PreDirectional: state.e911Address.PreDirectional,
                StreetType: state.e911Address.StreetType,
                StreetSuffix: state.e911Address.StreetSuffix,
                Street1: state.e911Address.Street1,
                Street2: state.e911Address.Street2,
                PhoneNumber: state.e911Address.PhoneNumber,
                IsActive: state.e911Address.IsActive,
                Address1: state.e911Address.Address1,
                Address2: state.e911Address.Address2,
                DirectionPrefix:state.e911Address.DirectionPrefix,
                DirectionSuffix:state.e911Address.DirectionSuffix,
                ElevationType :state.e911Address.ElevationType,
                StructureType :state.e911Address.StructureType,
                County :state.e911Address.County
              }
              state.existingList911 = newExistingList;
            }
          }
        }
        else {
          state.e911Address.savingShowSuccess = false;
          state.e911Address.savingShowError = true;
          state.e911Address.savingErrorMessage = "Failed Save";
        }
        this.setState(state);
      })
      .catch((message) => {
        console.log(message);
        let state = { ...this.state };
        state.e911Address.savingLoading = false;
        state.e911Address.savingShowError = true;
        state.e911Address.savingErrorMessage = "Failed Save";
        this.setState(state);
      });
  }

  handleToggleEditList911 = (address) => {
    address.showAddress = !address.showAddress;
    let state = { ...this.state };
    let addressFind = state.list911.filter(e => e.PhoneNumber === address.PhoneNumber)[0];
    let index = state.list911.indexOf(addressFind);
    state.list911[index] = address;
    this.setState(state);
  }

  handleToggleEdit911Addresses = (address) => {
    address.showAddress = !address.showAddress;
    let state = { ...this.state };
    state.e911Address = address;
    this.setState(state);
  }

  addressForm = (key, e911Address, indexInArray, type) => {
    let saveElement = "";
    let closeElement = "";

    if (type === "911List") {
      //if(e911Address.IsActive){
      saveElement = (<SaveButton disabled={e911Address.savingLoading} handleSave={(e) => this.save911InList(e911Address, indexInArray)} />);
      //}
      closeElement = (<Button className="btn" click={(e) => this.handleToggleEditList911(e911Address)}>Close</Button>);
    }
    else if (type === "911Address") {
      //if(e911Address.IsActive){
      saveElement = (<SaveButton disabled={e911Address.savingLoading} handleSave={(e) => this.save911AddressesInList(e911Address, indexInArray)} />);
      //}
      closeElement = (<Button className="btn" click={(e) => this.handleToggleEdit911Addresses(e911Address)}>Close</Button>);
    }

    return (
      <tr key={key}>
        <td colSpan="5">
          <div className="table-edit">
            <div className="form-group">
              <p className="alert alert-danger">Fill in all that apply and allow 10 minutes for address to validate in our system after updating.</p>
              <div className="reset">
                <a className="no-btn">Reset</a>
                <a className="no-btn">Reset to Primary Address</a>
              </div>

              <div className="halves">
                <div className="half">
                  <div className="form-group">
                    <label>Street Number</label>
                    <input type="type" className="form-control"
                      value={e911Address.StreetNumber}
                      onChange={x => { this.handleOnChange(indexInArray, StreetNumber, type, x) }} />
                  </div>
                </div>
                <div className="half">
                  <div className="form-group">
                  <label>Direction Prefix</label>
                    <select className="form-control"
                      value={e911Address.DirectionPrefix}
                      onChange={x => { this.handleOnChange(indexInArray, DirectionPrefix, type, x) }}
                    >
                      <option value=""></option>
                      <option value="E">East</option>
			                <option value="NE">North East</option>
			                <option value="SE">South East</option>
			                <option value="W">West</option>
			                <option value="NW">North West</option>
			                <option value="SW">South West</option>
			                <option value="S">South</option>
			                <option value="N">North</option>
                    </select>
                    {/* <label>Street Suffix</label>
                    <input type="type" className="form-control"
                      value={e911Address.StreetSuffix}
                      onChange={x => { this.handleOnChange(indexInArray, StreetSuffix, type, x) }} /> */}
                    {/* <select className="form-control"
                      value={e911Address.PreDirectional}
                      onChange={x => { this.handleOnChange(indexInArray, PreDirectional, type, x) }}
                    >
                      <option value=""></option>
                      <option value="N">N</option>
                      <option value="S">S</option>
                      <option value="E">E</option>
                      <option value="W">W</option>
                      <option value="NE">NE</option>
                      <option value="NW">NW</option>
                      <option value="SE">SE</option>
                      <option value="SW">SW</option>
                      <option value="SW">SW</option>
                    </select> */}
                  </div>
                </div>
              </div>
              <div className="halves">
                <div className="half">
                  <div className="form-group">
                    <label>Street Name</label>
                    <input type="type" className="form-control"
                      value={e911Address.StreetName ? e911Address.StreetName : ""}
                      onChange={x => { this.handleOnChange(indexInArray, StreetName, type, x) }} />
                  </div>
                </div>
                <div className="half">
                  {/* <div className="form-group">
                    <label> Street Type (Dr,Blvd,St)</label>
                    <input type="type" className="form-control" 
                      value={e911Address.StreetName?e911Address.StreetName:""}
                      onChange={x=>{ this.handleOnChange(indexInArray, StreetName, type, x) }}/>
                  </div> */}
                  <div className="form-group">
                    <label>Street Type</label>
                    <select className="form-control"
                      value={e911Address.StreetType ? e911Address.StreetType : ""}
                      onChange={x => { this.handleOnChange(indexInArray, StreetType, type, x) }}>
                      <option value=""></option>
                      {
                        helper.streetTypes.map(x => <option key={x} value={x}>{x}</option>)
                      }
                    </select>
                  </div>
                </div>
              </div>
              <div className="halves">
                <div className="half">
                <div className="form-group">
                    <label>Direction Suffix</label>
                    <select className="form-control"
                      value={e911Address.DirectionSuffix}
                      onChange={x => { this.handleOnChange(indexInArray, DirectionSuffix, type, x) }}
                    >
                      <option value=""></option>
                      <option value="E">East</option>
			                <option value="NE">North East</option>
			                <option value="SE">South East</option>
			                <option value="W">West</option>
			                <option value="NW">North West</option>
			                <option value="SW">South West</option>
			                <option value="S">South</option>
			                <option value="N">North</option>
                    </select>
                  </div>
                </div>
                {/* <div className="half">
                <div className="form-group">
                <label>Street Suffix</label>
                    <input type="type" className="form-control"
                      value={e911Address.StreetSuffix}
                      onChange={x => { this.handleOnChange(indexInArray, StreetSuffix, type, x) }} />
                  </div>
                </div> */}
              </div>
              <hr />
              <div className="halves">
                <div className="half">
                  <div className="form-group">
                    <label>Unit Type</label>
                    <select className="form-control"
                      value={e911Address.UnitType}
                      onChange={x => { this.handleOnChange(indexInArray, UnitType, type, x) }}>
                      <option value=""></option>
                      <option value="APT">Apartment</option>
                      <option value="BSMT">Basement</option>
                      <option value="DEPT">Department</option>
                      <option value="FL">Floor</option>
                      <option value="FRNT">Front</option>
                      <option value="HNGR">Hangar</option>
                      <option value="KEY">Key</option>
                      <option value="LBBY">Lobby</option>
                      <option value="LOT">Lot</option>
                      <option value="LOWR">Lower</option>
                      <option value="OFC">Office</option>
                      <option value="PH">Penthouse</option>
                      <option value="PIER">Pier</option>
                      <option value="REAR">Rear</option>
                      <option value="RM">Room</option>
                      <option value="SIDE">Side</option>
                      <option value="SLIP">Slip</option>
                      <option value="SPC">Space</option>
                      <option value="STOP">Stop</option>
                      <option value="STE">Suite</option>
                      <option value="TRLR">Trailer</option>
                      <option value="UNIT">Unit</option>
                      <option value="UPPR">Upper</option>
                    </select>
                  </div>
                </div>
                <div className="half">
                  <div className="form-group">
                    <label>No.</label>
                    <input type="type" className="form-control"
                      value={e911Address.UnitNum}
                      onChange={x => { this.handleOnChange(indexInArray, UnitNum, type, x) }} />
                  </div>
                </div>
              </div>
              <div className="halves">
                <div className="half">
                <div className="form-group">
                    <label>Elevation Type</label>
                    <select className="form-control"
                      value={e911Address.ElevationType}
                      onChange={x => { this.handleOnChange(indexInArray, ElevationType, type, x) }}
                    >
                      <option value=""></option>
                      <option value="FLR">FLR</option>
                    </select>
                  </div>
                </div>
                <div className="half">
                  <div className="form-group">
                    <label>No.</label>
                    <input type="type" className="form-control"
                      value={e911Address.ElevationNum}
                      onChange={x => { this.handleOnChange(indexInArray, ElevationNum, type, x) }} />
                  </div>
                </div>
              </div>
              <div className="halves">
              <div className="half">
              <div className="form-group">
                    <label>Structure Type</label>
                    <select className="form-control"
                      value={e911Address.StructureType}
                      onChange={x => { this.handleOnChange(indexInArray, StructureType, type, x) }}
                    >
                      <option value=""></option>
                      <option value="BLDG">BLDG</option>
                    </select>
                  </div>
              </div>
              <div className="half">
                  <div className="form-group">
                    <label>No.</label>
                    <input type="type" className="form-control"
                      value={e911Address.StructureNum}
                      onChange={x => { this.handleOnChange(indexInArray, StructureNum, type, x) }} />
                  </div>
                </div>
              </div>
              <div className="halves">
                <div className="half">
                  <div className="form-group">
                    <label>City</label>
                    <input type="type" className="form-control"
                      value={e911Address.City}
                      onChange={x => { this.handleOnChange(indexInArray, City, type, x) }} />
                  </div>
                </div>
                <div className="half">
                <div className="form-group">
                    <label>County</label>
                    <input type="type" className="form-control"
                      value={e911Address.County}
                      onChange={x => { this.handleOnChange(indexInArray, County, type, x) }} />
                  </div>
                </div>
              </div>
              <div className="halves">
                <div className="half">
                  <div className="form-group">
                    <label>Zip</label>
                    <input type="type" className="form-control"
                      value={e911Address.ZipCode}
                      onChange={x => { this.handleOnChange(indexInArray, ZipCode, type, x) }} />
                  </div>
                </div>
                <div className="half">
                <div className="form-group">
                    <label>State</label>
                    <select className="form-control"
                      value={e911Address.State}
                      onChange={x => { this.handleOnChange(indexInArray, State, type, x) }}>
                      <option value=""></option>
                      <option value="AL">Alabama</option>
                      <option value="AK">Alaska</option>
                      <option value="AZ">Arizona</option>
                      <option value="AR">Arkansas</option>
                      <option value="CA">California</option>
                      <option value="CO">Colorado</option>
                      <option value="CT">Connecticut</option>
                      <option value="DC">D.C.</option>
                      <option value="DE">Delaware</option>
                      <option value="FL">Florida</option>
                      <option value="GA">Georgia</option>
                      <option value="HI">Hawaii</option>
                      <option value="ID">Idaho</option>
                      <option value="IL">Illinois</option>
                      <option value="IN">Indiana</option>
                      <option value="IA">Iowa</option>
                      <option value="KS">Kansas</option>
                      <option value="KY">Kentucky</option>
                      <option value="LA">Louisiana</option>
                      <option value="ME">Maine</option>
                      <option value="MD">Maryland</option>
                      <option value="MA">Massachusetts</option>
                      <option value="MI">Michigan</option>
                      <option value="MN">Minnesota</option>
                      <option value="MS">Mississippi</option>
                      <option value="MO">Missouri</option>
                      <option value="MT">Montana</option>
                      <option value="NE">Nebraska</option>
                      <option value="NV">Nevada</option>
                      <option value="NH">New Hampshire</option>
                      <option value="NJ">New Jersey</option>
                      <option value="NM">New Mexico</option>
                      <option value="NY">New York</option>
                      <option value="NC">North Carolina</option>
                      <option value="ND">North Dakota</option>
                      <option value="Ohio">Ohio</option>
                      <option value="OK">Oklahoma</option>
                      <option value="OR">Oregon</option>
                      <option value="PA">Pennsylvania</option>
                      <option value="SC">South Carolina</option>
                      <option value="SD">South Dakota</option>
                      <option value="TN">Tennessee</option>
                      <option value="TX">Texas</option>
                      <option value="UT">Utah</option>
                      <option value="VT">Vermont</option>
                      <option value="VA">Virginia</option>
                      <option value="WA">Washington</option>
                      <option value="WV">West Virginia</option>
                      <option value="WI">Wisconsin</option>
                      <option value="WY">Wyoming</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div className="btns">
              {saveElement}
              {closeElement}
            </div>
            <SavingMessageControl
              showSuccess={e911Address.savingShowSuccess}
              showLoading={e911Address.savingLoading}
              showError={e911Address.savingShowError}
              SuccessMessage="Success"
              SuccessTransitionClass="vm-setting-success"
              ErrorMessage={e911Address.savingErrorMessage}
            />
          </div>
        </td>
      </tr>
    )
  }

  render() {
    return (
      <span>
        <a className={this.props.labelClassName} onClick={this.handleShow}>{this.state.E911ButtonText}</a>
        <Modal show={this.state.showModal} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>E911 List</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="distributionList">
              <h5>Phone Numbers</h5>
              {this.getE911List()}
              <div style={{ display: this.state.showUser911 === true ? 'block' : 'none' }}>
                <h5>Addresses</h5>
                {this.getList911Addresses()}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <hr />
            <div className="btns">
              <Button className="btn" click={this.handleClose}>Close</Button>
            </div>
          </Modal.Footer>
        </Modal>
      </span>
    );
  }
};

class SavingMessageControl extends Component {
  render() {
    let successMessage = "";
    if (this.props.showSuccess) {
      successMessage = (<ReactCSSTransitionGroup
        transitionName={this.props.SuccessTransitionClass}
        transitionAppear={true}
        transitionAppearTimeout={4000}
        transitionEnter={true}
        transitionEnterTimeout={4000}
        transitionLeave={false}
      >
        <div className="inline-success text-center">{this.props.SuccessMessage}</div>
      </ReactCSSTransitionGroup>)
    }

    let loadingMessage = "";
    if (this.props.showLoading) {
      loadingMessage = (<DataLoadingSpinner className='load spinner' />)
    }

    let errorMessage = "";
    if (this.props.showError) {
      errorMessage = (<div className="inline inline-fail text-center">{this.props.ErrorMessage}</div>)
    }

    return (
      <div>
        {successMessage}
        {loadingMessage}
        {errorMessage}
      </div>
    );
  }
};

class SaveButton extends Component {
  render() {
    if (this.props.disabled)
      return (<Button key="1" className="btn" enable={false}>Save</Button>);
    else
      return (<Button key="2" className="btn" click={this.props.handleSave}>Save</Button>);
  }
};

export default e911Modal;