import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { Button } from '@progress/kendo-buttons-react-wrapper';

// <ExampleModal />

class ServicesChangePasswordModal extends Component {

	constructor(props) {
		super(props);

    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);

    this.state = { showModal: false };
  }

  handleClose() {
    this.setState({ showModal: false });
  }

  handleShow() {
    this.setState({ showModal: true });
  }

	componentDidMount() {		
	}

  render() {
    return (
      <span>
        <Button click={this.handleShow}>Change Password / Pin</Button>

        <Modal show={this.state.showModal} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Change Password / Pin</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Click the Reset button below to temporarily reset your Voicemail PIN to 8642. When you access your voicemail with this temporary PIN, you will be prompted to assign a new PIN.</p>
            <a className="btn">Reset Voicemail Pin</a>
            <hr />
            <div className="form-group">
              <label>New Password</label>
              <input className="form-control" />
            </div>
            <div className="form-group">
              <label>Confirm New Password</label>
              <input className="form-control" />
            </div>
          </Modal.Body>
          <Modal.Footer>
              <hr />
              <div className="btns">
              <Button>Save New Password</Button>
              <Button className="btn" click={this.handleClose}>Cancel</Button>
              </div>
          </Modal.Footer>
        </Modal>
      </span>
    );
  }
};

export default ServicesChangePasswordModal;
