import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { Button } from '@progress/kendo-buttons-react-wrapper';
import { MultiSelect } from '@progress/kendo-dropdowns-react-wrapper';
import AudioUpload from './../../components/audioUpload';


class AcdNightModal extends Component {

  constructor(props) {
    super(props);

    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.changeAction = this.changeAction.bind(this);

    this.state = { showModal: false };
  }

  handleClose() {
    this.setState({ showModal: false });
  }

  handleShow() {
    this.setState({ showModal: true });
  }

  changeAction(event){
    this.setState({actionValue: event.target.value})
  }

  componentDidMount() {   
  }

  render() {
    return (
      <div className="setting">
        <span onClick={this.handleShow}> 
          <span className="name">ACD - Night Service</span>
          <a className="icon btn"><svg width="14" height="14" viewBox="0 0 24 24"><path d="m163 440l-91-91 251-250 90 90z m309-352l-48-48c-12-11-32-11-45 2l-45 45 91 91 45-45c13-13 13-33 2-45z m-408 275l-32 117 117-32z" transform="scale(0.046875 0.046875)"></path></svg></a>
        </span>
        <Modal show={this.state.showModal} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>ACD - Night Service</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <p>Configure the Contact Center to route calls differently during hours when the queue is not in service. This is determined by a schedule that defines the business hours of the queue. Night service cannot be disabled; however, it can have a schedule that defines business hours to be 24x7 which means that no night service shall be provided. The URLs/files for audio video will be played in order they are listed.</p>
              <div className="form-group">
                <label>Action</label>
                <div className="form-control">
                  <label className="custom-control">
                  <input id="radio1" name="radio" type="radio" onChange={this.changeAction} />
                    <span >None</span>
                  </label> 
                  <label className="custom-control">
                    <input id="radio1" name="radio" type="radio" onChange={this.changeAction} />
                    <span>Perform busy treatment</span>
                  </label>
                  <label className="custom-control">
                    <input id="radio1" name="radio" type="radio" onChange={this.changeAction} defaultValue='sip' />
                    <span>Transfer to phone number</span>
                  </label>
                </div>
              </div>
              <div className="form-group">
                {this.getInputs()}
              </div>
              <div className="form-group">
                <label className="right switch">
                  <input type="checkbox" />
                  <span className="slider round"></span>
                </label>
                Force night service regardless of business hour schedule
              </div>
              <div className="form-group">
                <label >Business Hours</label>
                <select className="form-control">
                  <option>Schedule 1</option>
                  <option>Schedule 2</option>
                  <option>Schedule 3</option>
                </select>
              </div>
              <div className="form-group">
                <label className="right switch">
                  <input type="checkbox" />
                  <span className="slider round"></span>
                </label>
                Allow feature access codes to manually override night service
              </div>
              <div className="form-group">
                <label className="right switch">
                  <input type="checkbox" />
                  <span className="slider round"></span>
                </label>
                Play announcement before night service action
              </div>
              <div className="form-group">
                <label>Announcement to play in manual override mode</label>
                <div className="form-control">
                  <label className="custom-control">
                  <input id="radio1" name="radio" type="radio" />
                    <span >Normal announcement</span>
                  </label> 
                  <label className="custom-control">
                    <input id="radio1" name="radio" type="radio" />
                    <span>Manual mode announcement</span>
                  </label>
                </div>
              </div>
              <AudioUpload label="Normal Mode Audio" />
              <AudioUpload label="Manual Mode Audio" />
            </div>
          </Modal.Body>
          <Modal.Footer>
              <hr />
              <div className="btns">
                <Button className="btn">Save</Button>
                <Button className="btn" click={this.handleClose}>Close</Button>
              </div>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
  getInputs() {
    switch(this.state.actionValue){
      case "sip":
        return (
            <div className="hidden-group">
              <label>Phone Number</label>
              <input type="type" className="form-control" defaultValue="" />
            </div>
        );
      default:
        return (<div></div>)
    }
  }
};

export default AcdNightModal;
