import React, { Component } from 'react';
import { DataLoadingSpinner } from './spinners';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { orderBy } from '@progress/kendo-data-query';
import * as helper from '../scripts/helper';

export default class FaxCallLogsTab extends Component {
  constructor(props) {
    super(props);

    this.state = {
      LocationAccountNumber: '',
      FaxCallLogs: props.FaxCallLogs,
      loading: props.isLoading,
      logs: [],
      Sort: [],
      isWidget: props.isWidget ? props.isWidget : false
    }
  };


  componentDidMount() {
  }

  componentWillReceiveProps(props) {
    if (props.FaxCallLogs != this.state.FaxCallLogs) {
      this.setState({ FaxCallLogs: props.FaxCallLogs });
    }
    if (props.isLoading != this.state.loading) {
      this.setState({ loading: props.isLoading });
    }
  }

  searchStatus = () => {
    if (this.state.loading) {
      return <DataLoadingSpinner className={'load spinner relative'} />
    } else if (!this.state.FaxCallLogs || this.state.FaxCallLogs.length === 0) {
      return (
        <h5 className='text-center' style={{ padding: '30px', textAlign: 'center' }} >
          No results for the filters selected.
        </h5>
      )
    } else return null;
  }

  sortListings = (sort, logs = null) => {
    let returnObj = [];
    if (this.state.FaxCallLogs) {
      if (logs == null) logs = this.state.FaxCallLogs.slice();
      let sortedSet = orderBy(logs, sort);
      returnObj = sortedSet;
      if (sort.length) {
        returnObj = helper.blanksAfterZ(sortedSet, sort);
      }
    }
    return returnObj;
  }

  sortChange = async (event) => {
    await this.setState({
      FaxCallLogs: this.sortListings(event.sort),
      Sort: event.sort
    });
  }

  gridContent = () => {
      let colWidth = this.props.isWidget === true ? '150px' : '250px';
      let smallColWidth = this.props.isWidget === true ? '85px' : '100px'
      if (this.state.loading || !this.state.FaxCallLogs || this.state.FaxCallLogs.length === 0) {
        return (
          <div style={{
            display: 'block',
            height: '100px',
            overflow: 'hidden',
            width: '100%',
          }}>{this.searchStatus()}</div>
        )
      } else if (this.props.isWidget) {
        return (
          <Grid
            style={{
              width: '100%',
            }}
            data={this.state.FaxCallLogs}
            scrollable='scrollable'
            sortable={{ allowUnsort: true, mode: 'single' }}
            sort={this.state.Sort}
            onSortChange={this.sortChange}
          >
            <Column width={smallColWidth} field='Direction' title='Direction' />
            <Column width={colWidth} format="{0:M/d/yyyy hh:mm:ss a}" field='StartTime' title='Start Time' />
            <Column width={colWidth} field='DirectoryNumber' title='From' />
            <Column width={colWidth} field='RemoteDirectoryNumber' title='To' />
            <Column title=' ' field='filler' sortable={false} />
          </Grid>
        );
      } else {

        return (
          <Grid
            style={{
              width: '100%',
            }}
            data={this.state.FaxCallLogs}
            scrollable='scrollable'
            sortable={{ allowUnsort: true, mode: 'single' }}
            sort={this.state.Sort}
            onSortChange={this.sortChange}
          >
            <Column width={smallColWidth} field='Direction' title='Direction' />
            <Column width={colWidth} format="{0:M/d/yyyy hh:mm:ss a}" field='StartTime' title='Start Time' />
            <Column width={colWidth} field='DirectoryNumber' title='From' />
            <Column width={colWidth} field='RemoteDirectoryNumber' title='To' />
            <Column width={colWidth} field='Location' title='Location' />
            <Column width={colWidth} field='Category' title='Category' />
            <Column width={colWidth} field='PageCount' title='Pages' />
            <Column title=' ' field='filler' sortable={false} />
          </Grid>
        )
      }
  }

  render() {
    return (
      <div className='card-content' >
        {this.gridContent()}
      </div >
    );
  }
};