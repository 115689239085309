import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { Button } from '@progress/kendo-buttons-react-wrapper';
import * as apiCalls from '../../../requests/api_calls';
import _ from 'lodash';
import SaveStatus from '../../../components/save_status';
import * as helper from '../../../scripts/helper';
import { DataLoadingSpinner } from '../../../components/spinners';

export default class ManageTrunkGroupUsers extends Component {
  initialState = (props = null) => {
    return {
      //TrunkGroupId: 0,
      //TrunkUserId: 0,
      //LocationAccountNumber: '',
      GroupId: this.props.GroupId,
      EnterpriseTrunkName: this.props.EnterpriseTrunkName,
      loading: false,
      saving: false,
      error: false,
      statusMessage: '',
      showModal: false,
      DIDAssigned: [],
      DIDUnassigned: [],
      DIDPending: [],
      SelectedAssigned: [],
      SelectedUnassigned: [],
      SelectedPending: [],
      EnterpriseTrunkOptions: this.props.EnterpriseTrunkOptions,
      TrunkGroups: props ? props.TrunkGroups : []
    };
  }
  constructor(props) {
    super(props);

    this.state = this.initialState();
  }

  componentWillReceiveProps = () => {
    let { EnterpriseTrunkName, EnterpriseTrunkOptions } = this.state;
    if (EnterpriseTrunkName !== this.props.EnterpriseTrunkName) {
      this.setState({
        EnterpriseTrunkName: this.props.EnterpriseTrunkName,
      });
    }
    if (EnterpriseTrunkOptions !== this.props.EnterpriseTrunkOptions) {
      this.setState({
        EnterpriseTrunkOptions: this.props.EnterpriseTrunkOptions,
      });
    }
  }

  fetchAvailableTrunkUsers = async () => {
    try {
      //await this.setState({ loading: true });
      let path = `admin/GetEnterpriseEnterpriseTrunkAvailableUserList?EnterpriseTrunkName=${this.state.EnterpriseTrunkName}`;
      let DIDs = await apiCalls.fetchData(path).then(data => data);

      //let assignedDIDs = [];
      let unAssignedDIDs = [];
      DIDs.map((item) => {
        let DIDEntry = {
          isSelected: false,
          Status: 'Unassigned',
          ServiceNumber: item.Phone_Number,
          UserId: item.UserId
        }
        unAssignedDIDs.push(DIDEntry);
      });
      await this.setState({
        //DIDAssigned: assignedDIDs,
        DIDUnassigned: unAssignedDIDs,
        loading: false,
      });
    } catch (error) {
      console.error(error);
      this.setState({
        loading: false,
        //DIDAssigned: [],
        DIDUnassigned: [],
      });
    }
  }

  fetchAssignedUsers = async () => {
    try {
      //await this.setState({ loading: true });
      let path = `admin/GetEnterpriseEnterpriseTrunkUserList?EnterpriseTrunkName=${this.state.EnterpriseTrunkName}`;
      let DIDs = await apiCalls.fetchData(path).then(data => data);

      let assignedDIDs = [];
      //let unAssignedDIDs = [];
      DIDs.map((item) => {
        let DIDEntry = {
          isSelected: false,
          Status: 'Assigned',
          ServiceNumber: item.Phone_Number,
          UserId: item.UserId
        }
        assignedDIDs.push(DIDEntry);
      });
      await this.setState({
        DIDAssigned: assignedDIDs,
        //DIDUnassigned: unAssignedDIDs,
        loading: false,
      });
    } catch (error) {
      console.error(error);
      this.setState({
        loading: false,
        DIDAssigned: [],
        //DIDUnassigned: [],
      });
    }
  }

  OpenModal = async () => {
    this.setState({ showModal: true, loading: true });
    this.fetchAvailableTrunkUsers();
    this.fetchAssignedUsers();
  }

  CloseModal = () => {
    let state = this.initialState();
    this.setState({
      ...state,
    }
      , async () => {
        try {
          this.fetchAvailableTrunkUsers();
          this.fetchAssignedUsers();
        } catch (error) {
          // handle error
          console.error(error)
        }
      });
  }

  handleSelection = async (queue, UserId) => {
    let newSelectionList = [];
    let dynamicDID = 'DID' + queue[0].toUpperCase() + queue.substring(1);
    let dynamicSelected = 'Selected' + queue[0].toUpperCase() + queue.substring(1);
    let DIDQueue = this.state[dynamicDID].slice();

    let currentItem = null;
    currentItem = this.state[dynamicDID].filter(resp => resp.UserId === UserId); // THIS CRUCIAL PARAM MAY CHANGE *******
    if (currentItem)
      currentItem = currentItem[0];

    let currentItemIdx = _.indexOf(DIDQueue, currentItem); //get before toggling selected status
    currentItem.isSelected = !currentItem.isSelected;
    newSelectionList = this.state[dynamicSelected].filter(resp => resp.UserId !== currentItem.UserId);
    if (currentItem.isSelected)
      newSelectionList.push(currentItem);

    DIDQueue[currentItemIdx] = currentItem;
    await this.setState({ [dynamicSelected]: newSelectionList, [dynamicDID]: DIDQueue });
  }

  handleTransition = async (actionName) => {
    let { DIDAssigned, DIDPending, DIDUnassigned, SelectedAssigned, SelectedPending, SelectedUnassigned } = this.state;
    let newPendingList = DIDPending.slice();
    let newAssignedList = DIDAssigned.slice();
    let newUnassignedList = DIDUnassigned.slice();
    let selectedItems = [];
    switch (actionName) {
      case 'add':
        SelectedUnassigned.map((record, idx) => {
          let newRecord = {
            UserId: record.UserId,
            ServiceNumber: record.ServiceNumber,
            Status: 'Add',
            isSelected: false
          };
          newPendingList.push(newRecord);
          newUnassignedList = newUnassignedList.filter(resp => resp.UserId !== record.UserId);
        });
        SelectedUnassigned = [];
        break;
      case 'undoAdd':
        SelectedPending.map((record, idx) => {
          if (record.Status === 'Add') {
            let newRecord = {
              UserId: record.UserId,
              ServiceNumber: record.ServiceNumber,
              Status: 'Unassigned',
              isSelected: false
            }
            newUnassignedList.push(newRecord);
            newPendingList = newPendingList.filter(resp => resp.UserId !== record.UserId);
          } else selectedItems.push(record);

        });
        SelectedPending = selectedItems;
        break;
      case 'remove':
        SelectedAssigned.map((record, idx) => {
          let newRecord = {
            UserId: record.UserId,
            ServiceNumber: record.ServiceNumber,
            Status: 'Remove',
            isSelected: false
          }
          newPendingList.push(newRecord);
          newAssignedList = newAssignedList.filter(resp => resp.UserId !== record.UserId);
        });
        SelectedAssigned = [];
        break;
      case 'undoRemove':

        SelectedPending.map((record, idx) => {
          if (record.Status === 'Remove') {
            let newRecord = {
              UserId: record.UserId,
              ServiceNumber: record.ServiceNumber,
              Status: 'Assigned',
              isSelected: false
            }
            newAssignedList.push(newRecord);
            newPendingList = newPendingList.filter(resp => resp.UserId !== record.UserId);
          } else selectedItems.push(record);
        });
        SelectedPending = selectedItems;
        break;
    }
    await this.setState({
      SelectedAssigned: SelectedAssigned,
      SelectedPending: SelectedPending,
      SelectedUnassigned: SelectedAssigned,
      DIDAssigned: newAssignedList,
      DIDPending: newPendingList,
      DIDUnassigned: newUnassignedList
    });
  }

  formatDids = (queue, arr = []) => {
    if (arr && arr.length > 0) {
      return arr.map((itm, index) => {
        let itemClass = itm.isSelected === true ? 'selectedItem' : '';
        let label = itm.ServiceNumber;
        if (queue === 'pending')
          label += ' (' + itm.Status + ')';
        return <div
          key={itm.UserId}
          style={{
            cursor: 'pointer'
          }}
          className={itemClass}
          onClick={() => { this.handleSelection(queue, itm.UserId); }}
        >{label}</div>
      })
    }
  }

  didField = (queue, arr = []) => {
    return (
      <div style={{
        height: '150px',
        width: '135px',
        overflow: 'auto'
      }}>
        {this.formatDids(queue, arr)}
      </div>
    )
  }

  SubmitChanges = async () => {
    try {
      if (this.state.DIDPending.length === 0) return; //end process if nothing selected
      let apiPath = 'admin/AddEnterpriseEnterpriseTrunkUserList';
      let reqBody = {};
      let statusMessage = '';
      let bError = false;
      let bPartialSuccess = false;
      let pending = this.state.DIDPending.slice();
      let arrAssigned = pending.filter(resp => resp.Status === 'Add').map((record, idx) => {
        return record.UserId;
      });
      let arrUnassigned = pending.filter(resp => resp.Status === 'Remove').map((record, idx) => {
        return record.UserId;
      });
      if (arrAssigned && arrAssigned.length > 0) {
        reqBody = {
          EnterpriseTrunkName: this.state.EnterpriseTrunkName,
          UserIdList: arrAssigned
        }

        await this.setState({ saving: true });
        let responseAssigns = await apiCalls.post(apiPath, 'POST', JSON.stringify(reqBody)).then((data) => data).catch(err => console.error(err));
        // if (helper.IsJsonString(responseAssigns.message)) {
        //   let messageObj = JSON.parse(responseAssigns.message);
        //   if (messageObj.MPOErrorList && messageObj.MPOErrorList.length > 0) {
        //     statusMessage += this.displayErrors(messageObj.MPOErrorList);
        //     bError = true;
        //   }
        // } else if (responseAssigns.ok) {
        //   statusMessage = 'All DIDs marked for assignment have updated successfully';
        //   bPartialSuccess = true;
        // }
        if (responseAssigns.ok) {
          statusMessage = 'All DIDs marked for assignment have updated successfully';
          bPartialSuccess = true;
        } else {
          statusMessage = 'An error occurred during the process';
          bError = true;
        }
      }
      if (arrUnassigned && arrUnassigned.length > 0) {
        apiPath = 'admin/DeleteEnterpriseEnterpriseTrunkUserList';
        if (!this.state.saving)
          await this.setState({ saving: true });
        reqBody = {
          EnterpriseTrunkName: this.state.EnterpriseTrunkName,
          UserIdList: arrUnassigned
        }
        let responseUnassigns = await apiCalls.post(apiPath, 'DELETE', JSON.stringify(reqBody)).then((assignData) => assignData).catch(err => console.error(err));

        if (responseUnassigns.ok) {
          statusMessage = 'All DIDs marked for assignment have updated successfully';
          bPartialSuccess = true;
        } else {
          statusMessage = 'An error occurred during the process';
          bError = true;
        }
      }
      if (!bError)
        statusMessage = 'All DIDs marked for assignment and unassignment have updated successfully'
      else if (bError && !bPartialSuccess)
        statusMessage = 'All DIDs marked for assignment and/or unassignment have failed\n' + statusMessage;

      await this.setState({ saving: false, statusMessage: statusMessage, error: bError });
    }
    catch (ex) {
      this.setState({
        saving: false,
        error: true,
        statusMessage: 'An error occurred: ' + ex.toString(),
      });
    }
  }

  displayErrors = (ErrorList) => {
    let errorMsg = '';
    ErrorList.map((err, idx) => {
      if (err.MPOResultText && err.MPOResultText.length > 0) {
        err.MPOResultText.map((rt, idx2) => {
          errorMsg = errorMsg === '' ? rt.ErrorMessage : '\n' + rt.ErrorMessage;
        });
      } else
        errorMsg = errorMsg === '' ? err.APIError : '\n' + err.APIError;
    });
    return errorMsg;
  }

  ClearSaveStatusModal = async () => {
    let selectedTrunk = this.state.TrunkUserId;
    let TrunkGroup = this.state.TrunkGroups.filter(resp => resp.UserId === selectedTrunk);
    TrunkGroup = TrunkGroup[0];

    //let selectedText = e.target.selectedText;
    //let TrunkGroupId =
    await this.setState({ error: false, statusMessage: '', loading: true, DIDAssigned: [], DIDPending: [], DIDUnassigned: [], SelectedAssigned: [], SelectedPending: [], SelectedUnassigned: [] });
    this.fetchAssignedUsers();
    this.fetchAvailableTrunkUsers();
  }

  savingOrConfirming = () => this.state.saving || this.state.statusMessage;

  handleSelectChange = async (e) => {
    let { value } = e.target;
    if (value !== this.state.EnterpriseTrunkName) {
      await this.setState({
        EnterpriseTrunkName: value,
        loading: true,
      });
      this.fetchAvailableTrunkUsers();
      this.fetchAssignedUsers();
    }
  }

  modalBody = () => {
    if (this.state.loading === true) {
      return (
        <DataLoadingSpinner className='load spinner' />
      );
    } else {
      return (
        <div className={this.savingOrConfirming() ? 'opaque' : 'visible'} >
          <div>
            <label
              style={{ display: 'block' }}
              htmlFor={'select-trunkid'}>Trunk ID:</label>
            <select id={'select-trunkid'}
              value={this.state.EnterpriseTrunkName}
              className='custom-input-class'
              style={{ width: '670px' }}
              onChange={(e) => { this.handleSelectChange(e); }}>
              {this.state.EnterpriseTrunkOptions}
            </select>
          </div>
          <div className='column-container'>
            <div className='column-wrapper'>
              Available DID(s)
              <div className='column-body'>
                {this.didField('unassigned', this.state.DIDUnassigned)}
              </div>
            </div>
            <div className='modal-btn-wrapper'>
              <a className='tg-selections'
                onClick={() => { this.handleTransition('add'); }}
              >{'Assign >'}</a>
              {/* SPACING DIV */}
              <div style={{ height: '5px' }}></div>
              {/* SPACING DIV */}
              <a className='tg-selections'
                onClick={() => { this.handleTransition('undoAdd') }}
              >{'< Undo'}</a>
            </div>
            <div className='column-wrapper'>
              Pending DID(s)
              <div className='column-body'>
                {this.didField('pending', this.state.DIDPending)}
              </div>
            </div>
            <div className='modal-btn-wrapper'>
              <a className='tg-selections'
                onClick={() => { this.handleTransition('remove') }}
              >{'< Unassign'}</a>
              {/* SPACING DIV */}
              <div style={{ height: '5px' }}></div>
              {/* SPACING DIV */}
              <a className='tg-selections'
                onClick={() => { this.handleTransition('undoRemove') }}
              >{'Undo >'}</a>
            </div>
            <div className='column-wrapper'>
              Assigned DID(s)
              <div className='column-body'>
                {this.didField('assigned', this.state.DIDAssigned)}
              </div>
            </div>
          </div>
        </div>
      )
    }
  }

  render = () => {
    return (
      <span className='trunk-modal-wrapper'>
        <span>
          <a onClick={() => this.OpenModal()}>Manage Associated DIDs</a>
        </span>
        <Modal
          dialogClassName="ip-trunk-modal"
          show={this.state.showModal}
          onHide={this.CloseModal}
        >
          <Modal.Header>
            <Modal.Title>Enterprise Trunk Management</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.modalBody()}
          </Modal.Body>
          <Modal.Footer style={{ textAlign: 'left' }}>
            <hr />
            <label
              style={{ color: 'Red', width: '60%', textAlign: 'left', display: this.savingOrConfirming() ? 'none' : 'inline-block' }}
            >When assigning DIDs to a trunk or unassigning from a trunk,  DIDs are held in the Pending DID(s) bucket until user submits the order. Once an order is submitted, changes are committed immediately.</label>
            <div className="btns"
              style={{ display: this.savingOrConfirming() ? 'none' : 'inline-block', marginLeft: '10%' }}
            >
              <span><Button className="btn"
                click={this.SubmitChanges}
              >Submit</Button></span>
              <span><Button className="btn"
                click={this.CloseModal}
              >Cancel</Button></span>
            </div>
            <SaveStatus
              saving={this.state.saving}
              statusMessage={this.state.statusMessage}
              error={this.state.error}
              parentCallBack={this.ClearSaveStatusModal}
            />
          </Modal.Footer>
        </Modal>
      </span>
    )
  }
}