import React, { Component } from 'react';
import BlockTab from './tabs/blockTab'
import CnamTab from './tabs/cnamTab';
import ExtensionTab from './tabs/extensionTab';
import LineTab from './tabs/lineTab';
import ListingTab from './tabs/listingTab';
import SeatTab from './tabs/seatTab';
import VirtualTab from './tabs/virtualTab';
import OpenRequestTab from './tabs/openReqTab';
import * as apiCalls from '../../requests/api_calls';
import * as settings from '../../scripts/settings';
import * as macdHelper from './components/macd_helper';
import * as helper from '../../scripts/helper';
import { Modal } from 'react-bootstrap';
import { Button } from '@progress/kendo-buttons-react-wrapper';
import IpTrunkTab from './tabs/ipTrunkTab';

class Macd extends Component {

  
  constructor(props) {
    super(props);
    let tab = this.parseViewFilter(props);
    this.state = {
      tab: tab ? tab : macdHelper.MACDTABS.OpenRequests,
      MomentumAccountNumber: "",
      LocationName: "",
      locations: [],
      locationOptions: [],
      tabHasChanges: false
    };

    this.getTab = this.getTab.bind(this);
    this.changeTab = this.changeTab.bind(this);
    this.changeLocation = this.changeLocation.bind(this);
    this.fetchLocations = this.fetchLocations.bind(this);
    this.LoadAddServices = this.LoadAddServices.bind(this);
    this.LoadUserAddOn = this.LoadUserAddOn.bind(this);
    this.LoadAllSearch = this.LoadAllSearch.bind(this);
    this.getTabDisplay = this.getTabDisplay.bind(this);
    this.DisplayCancelOrderConfirmationTab = this.DisplayCancelOrderConfirmationTab.bind(this);
    this.handleTabEdit = this.handleTabEdit.bind(this);
    this.closeCancelConfirmationDialog = this.closeCancelConfirmationDialog.bind(this);
  };

  parseViewFilter = (props) => {
    let tab = macdHelper.MACDTABS.OpenRequests;
    if (props.match.params && props.match.params.tabName) {
      tab = props.match.params.tabName;
    };
    return tab;
  }

  handleResize = async () => {
    await this.setState({
      gridHeight: (window.innerHeight - 350)
    });
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize)
  }

  componentDidMount() {
    let obj = sessionStorage.getItem('MacdPerms');
    if (obj.indexOf(1) < 0) {
      this.props.history.push(helper.homePageUrl());
      return;
    }
    this.fetchLocations();
    this.handleResize();
    window.addEventListener('resize', this.handleResize)
  }

  componentWillReceiveProps(props) {
    let tab = this.state.tab;
    if (props.location.pathname === '/macd')
    {
      //set Open Requests as the open tab  AZDO: 1467
      if (!this.state.tabHasChanges)
        tab = macdHelper.MACDTABS.OpenRequests;
      else
      { 
        this.DisplayCancelOrderConfirmationTab(macdHelper.MACDTABS.OpenRequests);
      }
    }
    this.setState({tab: tab});
  }

  handleTabEdit = (val) => {
    this.setState({ tabHasChanges: val });
  }

  changeTab(event) {
    if (event.target.dataset.act !== this.state.tab) {
      if (!this.state.tabHasChanges) {
        this.setState({ tab: event.target.dataset.act, tabHasChanges: false });
      } else {
        this.DisplayCancelOrderConfirmationTab(event.target.dataset.act);
      }
    }
  }

  DisplayCancelOrderConfirmationTab = (tab) => {
    this.setState({ isCancelConfirmOpen: true, tmpTab: tab });
  }

  apiLocationsPathWithParams = () => {
    let path = 'admin/Locations';
    return path;
  }

  fetchLocations = async (apiPath = null) => {
    if (!apiPath) apiPath = this.apiLocationsPathWithParams();
    await this.setState({ loadingLocations: true });
    apiCalls.fetchData(apiPath).then((data) => {
      if (data !== null) {
        let accountNumber = '';
        let locationName = '';
        let locationOptions = data.map((location, index) => {
          return (this.locationOption(location, index));
        });
        if (locationOptions.length > 1) {
          locationOptions.unshift(
            this.locationOption({ LocationAccountNumber: '', LocationName: 'All Locations' }, '')
          );
          if (sessionStorage.LocNum && sessionStorage.LocNum !== '') {
            let filteredLocName = data.filter(res => res.LocationAccountNumber === sessionStorage.LocNum)[0].LocationName;
            locationName = filteredLocName;
            accountNumber = sessionStorage.LocNum;
          }
        } else {
          accountNumber = data[0].LocationAccountNumber;
          locationName = data[0].LocationName;
          sessionStorage.setItem('LocNum', data[0].LocationAccountNumber);
        }
        this.setState({ locationOptions: locationOptions, loading: false, MomentumAccountNumber: accountNumber, LocationName: locationName });
      } else if (data === null) {
        this.setState({ locations: [], loadingLocations: false, resultsMsg: settings.noResultsMsg });
      };
    });
  }
  locationOption = (location, index) => {
    return (
      <option key={index} value={location.LocationAccountNumber}>
        {location.LocationName}
      </option>
    )
  }

  changeLocation = (event) => {
    let getLocationName = (acctNum) => {
      return this.state.locationOptions.map(itm => itm.props).filter(res => {
        if (acctNum === res.value) return res;
      })[0].children;
    }
    let LocNum = event.target.value;
    if (LocNum && LocNum !== '') {
      sessionStorage.setItem('LocNum', LocNum);
    } else {
      sessionStorage.removeItem('LocNum');
    }
    if (!this.state.tabHasChanges) {
      this.setState({
        MomentumAccountNumber: LocNum,
        LocationName: event.target.selectedOptions ? event.target.selectedOptions[0].text : getLocationName(LocNum)
      });
    } else {
      this.setState({
        isCancelConfirmOpen: true,
        tmpAccountNumber: LocNum,
        tmpLocName: event.target.selectedOptions ? event.target.selectedOptions[0].text : getLocationName(LocNum)
      });
    }
  }

  LoadAddServices = () => {
    if (!this.state.tabHasChanges)
      this.props.history.push(macdHelper.AddEditPage());
    else
      this.DisplayCancelOrderConfirmationTab(macdHelper.MACDTABS.AddServices);
  }

  LoadUserAddOn = () => {
    if (!this.state.tabHasChanges) 
      this.props.history.push(macdHelper.UserAddOn());
    else
      this.DisplayCancelOrderConfirmationTab(macdHelper.MACDTABS.AddOns);
  }
  LoadAllSearch = () => {
    if (!this.state.tabHasChanges) 
      this.props.history.push(macdHelper.AllSearch());
    else
      this.DisplayCancelOrderConfirmationTab(macdHelper.MACDTABS.AllRequests);
  }

  DisplayAddServices = () => {
    return macdHelper.CheckPermissionsForLinks(macdHelper.MACDTYPES.AddServices) === 1 ? 'inline-block' : 'none';
  }

  DisplayManageAddOns = () => {
    return macdHelper.CheckPermissionsForLinks(macdHelper.MACDTYPES.AssignAddOn) === 1 || macdHelper.CheckPermissionsForLinks(macdHelper.MACDTYPES.UnassignAddOn) === 1 ? 'inline-block' : 'none'
  }

  getTabDisplay() {
    let seatTypeHTML = macdHelper.CheckPermissionsForLinks(macdHelper.MACDTYPES.ChangeSeatType) === 1 ?
      (<a className={this.state.tab === macdHelper.MACDTABS.ChangeSeatType ? "active tab" : "tab"} data-act={macdHelper.MACDTABS.ChangeSeatType} onClick={this.changeTab}>Seat Type</a>)
      : null;

    let cnamHTML = macdHelper.CheckPermissionsForLinks(macdHelper.MACDTYPES.ChangeCNAM) === 1 ?
      (<a className={this.state.tab === macdHelper.MACDTABS.ChangeCNAM ? "active tab" : "tab"} data-act={macdHelper.MACDTABS.ChangeCNAM} onClick={this.changeTab}>Caller ID [CNAM]</a>)
      : null;

    let blockHTML = macdHelper.CheckPermissionsForLinks(macdHelper.MACDTYPES.ChangeBlock) === 1 ?
      (<a className={this.state.tab === macdHelper.MACDTABS.ChangeBlock ? "active tab" : "tab"} data-act={macdHelper.MACDTABS.ChangeBlock} onClick={this.changeTab}>Block</a>)
      : null;

    let extHTML = macdHelper.CheckPermissionsForLinks(macdHelper.MACDTYPES.ChangeExtension) === 1 ?
      (<a className={this.state.tab === macdHelper.MACDTABS.ChangeExtension ? "active tab" : "tab"} data-act={macdHelper.MACDTABS.ChangeExtension} onClick={this.changeTab}>Extension</a>)
      : null;

    let lineTypeHTML = macdHelper.CheckPermissionsForLinks(macdHelper.MACDTYPES.ChangeLineType) === 1 ?
      (<a className={this.state.tab === macdHelper.MACDTABS.ChangeLineType ? "active tab" : "tab"} data-act={macdHelper.MACDTABS.ChangeLineType} onClick={this.changeTab}>Line Type</a>)
      : null;

    let listingHTML = macdHelper.CheckPermissionsForLinks(macdHelper.MACDTYPES.ChangeListing) === 1 ?
      (<a className={this.state.tab === macdHelper.MACDTABS.ChangeListing ? "active tab" : "tab"} data-act={macdHelper.MACDTABS.ChangeListing} onClick={this.changeTab}>Listing</a>)
      : null;

    let virtualHTML = macdHelper.CheckPermissionsForLinks(macdHelper.MACDTYPES.ChangeVirtualTerminatingNumber) === 1 ?
      (<a className={this.state.tab === macdHelper.MACDTABS.ChangeVirtualTerminatingNumber ? "active tab" : "tab"} data-act={macdHelper.MACDTABS.ChangeVirtualTerminatingNumber} 
        onClick={this.changeTab}>Virtual Terminating Number</a>
      )
      : null;

    let ipTrunkHTML = macdHelper.CheckPermissionsForLinks(macdHelper.MACDTYPES.ChangeIPTrunkCpCount) === 1 && sessionStorage.hasIpTrunk === 'true' ?
      (<a className={this.state.tab === macdHelper.MACDTABS.ChangeIPTrunkCpCount ? "active tab" : "tab"} data-act={macdHelper.MACDTABS.ChangeIPTrunkCpCount} 
        onClick={this.changeTab}>IP Trunking Call Path</a>
      )
      : null;
    return (
      <div className="tabs">
        <a className={this.state.tab === macdHelper.MACDTABS.OpenRequests ? "active tab" : "tab"} data-act={macdHelper.MACDTABS.OpenRequests} onClick={this.changeTab}>Open Requests</a>
        {seatTypeHTML}
        {cnamHTML}
        {blockHTML}
        {extHTML}
        {lineTypeHTML}
        {listingHTML}
        {virtualHTML}
        {ipTrunkHTML}
      </div>
    )
  }

  CancelConfirmationYesAction = () => {
    if (this.state.tmpTab === macdHelper.MACDTABS.AddServices || this.state.tmpTab === macdHelper.MACDTABS.AllRequests || this.state.tmpTab === macdHelper.MACDTABS.AddOns)
    {
      let page = this.state.tmpTab;
      this.setState({
        isCancelConfirmOpen: false,
        tmpTab: null,
        tmpLocName: null,
        tmpAccountNumber: null,
        tabHasChanges: false
      });
      if (page === macdHelper.MACDTABS.AddServices)
        this.LoadAddServices();
      else if (page === macdHelper.MACDTABS.AllRequests)
        this.LoadAllSearch();
      else
        this.LoadUserAddOn();
    } else 
      this.setState({
        isCancelConfirmOpen: false,
        tab: this.state.tmpTab ? this.state.tmpTab : this.state.tab,
        MomentumAccountNumber: this.state.tmpAccountNumber ? this.state.tmpAccountNumber : this.state.MomentumAccountNumber,
        LocationName: this.state.tmpLocName ? this.state.tmpLocName : this.state.LocationName,
        tmpTab: null,
        tmpLocName: null,
        tmpAccountNumber: null,
        tabHasChanges: false
      });

  }

  closeCancelConfirmationDialog = () => {
    this.setState({
      isCancelConfirmOpen: false,
      tmpTab: null,
      tmpLocName: null,
      tmpAccountNumber: null
    });
  }

  render() {
    return (
      <div className="macd-page">
        <div className="container full-card">
          <div className="macd card">
            <div className="card-header">
              <h1>Service Changes</h1>
              <div className="card-actions">
                <a onClick={this.LoadAddServices} style={{ display: this.DisplayAddServices() }} className="orange btn">Add Services</a>
                <a onClick={this.LoadUserAddOn} style={{ display: this.DisplayManageAddOns() }} className="orange btn">Manage User Add-Ons</a>
              </div>
            </div>
            <div className="macd-row">
              <div className="macd-item">
                <a onClick={this.LoadAllSearch} className="no-btn">View All Service Changes</a>
                <div className="form-group">
                  <label style={{ color: '#3a4b5f' }}><b>Location:</b></label>
                  <select onChange={this.changeLocation} value={this.state.MomentumAccountNumber} className="form-control">
                    {this.state.locationOptions}
                  </select>
                </div>
              </div>
            </div>

            {this.getTabDisplay()}
            <div className="tab-body">
              {this.getTab()}
            </div>
          </div>
        </div>
        <Modal dialogClassName="confirm-cancel-order-macd" show={this.state.isCancelConfirmOpen} onHide={this.closeCancelConfirmationDialog}>
          <Modal.Header closeButton>
            <Modal.Title>Cancel Existing Order?</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div><p><span style={{ fontSize: "medium" }}>Continuing this action will cancel existing changes you have made for a new MACD Order.  Are you sure you wish to continue and lose all changes?</span></p></div>
          </Modal.Body>
          <Modal.Footer>
            <Button click={(evt) => { this.CancelConfirmationYesAction() }}>Yes</Button>
            <Button click={(evt) => { this.closeCancelConfirmationDialog() }}>No</Button>
          </Modal.Footer>
        </Modal>
      </div>
    )
  }

  getTab() {
    switch (this.state.tab) {
      case macdHelper.MACDTABS.ChangeSeatType:
        return (
          <SeatTab MomentumAccountNumber={this.state.MomentumAccountNumber} LocationName={this.state.LocationName} TabHasChanges={this.handleTabEdit} GridHeight={this.state.gridHeight} />
        );
      case macdHelper.MACDTABS.ChangeCNAM:
        return (
          <CnamTab MomentumAccountNumber={this.state.MomentumAccountNumber} LocationName={this.state.LocationName} TabHasChanges={this.handleTabEdit} GridHeight={this.state.gridHeight} />
        )
      case macdHelper.MACDTABS.ChangeBlock:
        return (
          <BlockTab MomentumAccountNumber={this.state.MomentumAccountNumber} LocationName={this.state.LocationName} TabHasChanges={this.handleTabEdit} GridHeight={this.state.gridHeight} />
        );
      case macdHelper.MACDTABS.ChangeExtension:
        return (
          <ExtensionTab MomentumAccountNumber={this.state.MomentumAccountNumber} LocationName={this.state.LocationName} TabHasChanges={this.handleTabEdit} GridHeight={this.state.gridHeight} />
        );
      case macdHelper.MACDTABS.ChangeLineType:
        return (
          <LineTab MomentumAccountNumber={this.state.MomentumAccountNumber} LocationName={this.state.LocationName} TabHasChanges={this.handleTabEdit} GridHeight={this.state.gridHeight} />
        );
      case macdHelper.MACDTABS.ChangeListing:
        return (
          <ListingTab MomentumAccountNumber={this.state.MomentumAccountNumber} LocationName={this.state.LocationName} TabHasChanges={this.handleTabEdit} GridHeight={this.state.gridHeight} />
        );
      case macdHelper.MACDTABS.ChangeVirtualTerminatingNumber:
        return (
          <VirtualTab MomentumAccountNumber={this.state.MomentumAccountNumber} LocationName={this.state.LocationName} TabHasChanges={this.handleTabEdit} GridHeight={this.state.gridHeight} />
        );
      case macdHelper.MACDTABS.ChangeIPTrunkCpCount:
        return (
          <IpTrunkTab MomentumAccountNumber={this.state.MomentumAccountNumber} LocationName={this.state.LocationName} TabHasChanges={this.handleTabEdit} GridHeight={this.state.gridHeight} />
        );
      case macdHelper.MACDTABS.OpenRequests:
        return (
          <OpenRequestTab
            MomentumAccountNumber={this.state.MomentumAccountNumber}
            GridHeight={this.state.gridHeight}
          />
        );
      default:
        console.log('Sorry there isn\'t a tab named ' + this.state.tab + '.');
    }
  }


}
export default Macd;