import { useState } from "react";

import { Button } from "@progress/kendo-buttons-react-wrapper";
import { DataLoadingSpinner } from "../../../../components/spinners";

export const AddScheduleItem = ({ addSchedule }) => {
  const [showAddScheduleItem, setShowAddScheduleItem] = useState(false);
  const [loading, setLoading] = useState(false);
  const [newScheduleName, setNewScheduleName] = useState("");

  const [errorMessage, setErrorMessage] = useState("");

  const createSchedule = async () => {
    if (newScheduleName) {
      await setLoading(true);
      await addSchedule(newScheduleName);
      await setLoading(false);
    }
  };

  if (loading) {
    return (
      <div className="content">
        <DataLoadingSpinner className="spinner" style={{ margin: "10px auto 0", height: "40px" }} />
      </div>
    );
  }

  return (
    <>
      <button
        className="no-btn"
        onClick={() => {
          setShowAddScheduleItem(!showAddScheduleItem);
        }}
      >
        Create a Schedule
      </button>
      {showAddScheduleItem && (
        <div className="hidden-group">
          <div className="form-group">
            <label>Schedule Name</label>
            <input
              type="text"
              className="form-control"
              value={newScheduleName}
              onChange={(e) => setNewScheduleName(e.target.value)}
            />
            <div className="btns">
              <Button className="btn" click={createSchedule}>
                Save
              </Button>
              <button
                className="btn"
                onClick={() => {
                  setShowAddScheduleItem(!showAddScheduleItem);
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
      {errorMessage && (
        <div>
          <p>{errorMessage}</p>
        </div>
      )}
    </>
  );
};
