import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import * as apiCalls from '../../../requests/api_calls';
import _ from 'lodash';
import { DataLoadingSpinner } from '../../../components/spinners';
import UserSearch from './user_search';

export default class ManageOCTeamsPhone extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isAssigned: false,
      loading: false,
      Submission: false,
      SubmitError: false,
      showConfirmationModal: false,
      showDRModal: false,
      ServiceNumber: this.props.location.state.ServiceNumber,
      PhoneDetails: null,
      OriginalDetails: null,
      Enterprise: this.props.location.state.Enterprise,
      Trunk: this.props.location.state.Trunk,
      LocationList: sessionStorage.locationList ? JSON.parse(sessionStorage.locationList) : [],
      SelectedUser: null
    };
  }

  async componentDidMount() {
    await this.fetchPhoneDetails();
  }

  inputDataField = (val, key, inputStyle, onChangeFunc, type = 'text', readOnly) => {
    return (
      <input
        style={inputStyle}
        type={type}
        name={key}
        value={val}
        className="form-control"
        onChange={(e) => onChangeFunc(e, key)}
        disabled={readOnly}
      />
    )
  }

  dataRow = (label, field, divStyleObj, labelStyleObj) => {
    return (
      <div className="form-group-wrapper"
        style={divStyleObj}
      >
        <label
          style={labelStyleObj}
        >{label}: </label>
        {field}
      </div >
    )
  }

  dataDropDown = (key, val, inputStyle) => {
    return (
      <select
        className="form-control"
        style={inputStyle}
        //onChange={(e) => this.handleInputChange(e, key)}
        value={val}
        disabled
      >
        {/* {options} */}
        <option value="">-- Select --</option>
        {this.state.LocationList.map((itm, idx) => {
          return <option key={idx} value={itm.LocationId}>{itm[key]}</option>
        })}
      </select>
    )
  }

  cardContent = () => {
    let { loading, PhoneDetails, SelectedUser } = this.state;
    if (loading || PhoneDetails == null) {
      return (
        <DataLoadingSpinner className='load spinner' />
      );
    } else {
      let divStyle = { margin: "1%", };
      let labelStyle = { display: 'inline-block', width: '30%', };
      let inputStyle = { width: '70%', display: 'inline-block', };
      return (
        <div className="card-content">
          {<UserSearch
            onSelectUser={this.handleUserChange}
          />}
          {this.dataRow("Assigned DID / Seat", this.inputDataField(PhoneDetails?.ServiceNumber, 'TelephoneNumber', inputStyle, this.handleInputChange, "text", true), divStyle, labelStyle)}
          {this.dataRow("Extension", this.inputDataField(PhoneDetails?.Extension, 'Extension', inputStyle, this.handleInputChange, "text", true), divStyle, labelStyle)}
          {this.dataRow("Location", this.inputDataField(PhoneDetails?.LocationName, 'LocationName', inputStyle, this.handleInputChange, "text", true), divStyle, labelStyle)}
          {this.dataRow("First Name", this.inputDataField(SelectedUser?.GivenName, 'GivenName', inputStyle, this.handleInputChange, "text", true), divStyle, labelStyle)}
          {this.dataRow("Last Name", this.inputDataField(SelectedUser?.Surname, 'Surname', inputStyle, this.handleInputChange, "text", true), divStyle, labelStyle)}
          {this.dataRow("User Principal Name", this.inputDataField(SelectedUser?.UserPrincipalName, 'UserPrincipalName', inputStyle, this.handleInputChange, "text", true), divStyle, labelStyle)}
          {this.dataRow("Street", this.inputDataField(PhoneDetails?.StreetAddress, 'StreetAddress', inputStyle, this.handleInputChange, "text", true), divStyle, labelStyle)}
          {this.dataRow("City", this.inputDataField(PhoneDetails?.City, 'City', inputStyle, this.handleInputChange, "text", true), divStyle, labelStyle)}
          {this.dataRow("State", this.inputDataField(PhoneDetails?.State, 'State', inputStyle, this.handleInputChange, "text", true), divStyle, labelStyle)}
          {this.dataRow("User State Changed On", this.inputDataField(SelectedUser?.UserStateChangedOn, 'UserStateChangedOn', inputStyle, this.handleInputChange, "text", true), divStyle, labelStyle)}
          {this.dataRow("User State", this.inputDataField(SelectedUser?.UserState, 'UserState', inputStyle, this.handleInputChange, "text", true), divStyle, labelStyle)}
          {this.dataRow("Usage Location", this.inputDataField(SelectedUser?.UsageLocation, 'UsageLocation', inputStyle, this.handleInputChange, "text", true), divStyle, labelStyle)}
          {this.dataRow("Sip Proxy Address", this.inputDataField(SelectedUser?.SipProxyAddress, 'SipProxyAddress', inputStyle, this.handleInputChange, "text", true), divStyle, labelStyle)}
          {this.dataRow("User Type", this.inputDataField(SelectedUser?.AccountType, 'AccountType', inputStyle, this.handleInputChange, "text", true), divStyle, labelStyle)}
          {this.dataRow("Other Mails", this.inputDataField(SelectedUser?.OtherMails, 'OtherMails', inputStyle, this.handleInputChange, "text", true), divStyle, labelStyle)}
        </div >
      )
    }
  }

  handleUserChange = async (user) => {
    let PhoneDetails = { ...this.state.PhoneDetails };
    PhoneDetails["TeamsUserId"] = user.TeamsUserId;
    PhoneDetails["AssignedTo"] = user.UserPrincipalName;
    PhoneDetails["FirstName"] = user.GivenName;
    PhoneDetails["LastName"] = user.Surname;
    user["LocationId"] = this.state.PhoneDetails.LocationId;
    user["ServiceId"] = this.state.PhoneDetails.ServiceId;
    user["Extension"] = this.state.PhoneDetails.Extension;
    user["UserId"] = user.UserId;
    user["TelephoneNumber"] = this.state.PhoneDetails.ServiceNumber;
    user["StreetAddress"] = this.state.PhoneDetails.StreetAddress;
    user["City"] = this.state.PhoneDetails.City;
    user["State"] = this.state.PhoneDetails.State;
    await this.setState({ SelectedUser: user, PhoneDetails: PhoneDetails });
    //console.log("UserDetails", UserDetails);
  }

  handleInputChange = async (e, key) => {
    let { value } = e.target;
    let PhoneDetails = { ...this.state.PhoneDetails };
    PhoneDetails[key] = value;
    await this.setState({ PhoneDetails });
    //console.log("UserDetails", UserDetails); // inserted AD Information
  }

  configureDR = async () => {
    this.setState({ SubmissionLoading: true });
    if (this.state.PhoneDetails.TeamsUserId !== "" && this.state.PhoneDetails.TeamsUserId !== null && this.state.SelectedUser !== null) {
      let { SelectedUser, Enterprise, Trunk, PhoneDetails, OriginalDetails } = this.state;
      let { UserPrincipalName, TeamsUserId } = SelectedUser;
      let path = `admin/EnableDirectRoutingForUser?teamsId=${TeamsUserId}`;
      let dto = this.state.SelectedUser;
      // ** required params for web service ** //
      dto.UserName = sessionStorage.username;
      dto.EnterpriseId = Enterprise;
      dto.TrunkName = Trunk.TrunkName;
      dto.UserPrincipalName = UserPrincipalName;
      dto.PhoneNumber = PhoneDetails.ServiceNumber;
      if (OriginalDetails.TeamsUserId) {
        path = `admin/DisableDirectRoutingForUser?teamsId=${TeamsUserId}`;
        this.setState({ SelectedUser: null });
      }
      //console.log("___DTO___", dto);
      let response = await apiCalls.post(path, 'POST', JSON.stringify(dto)).then(response => response).catch(err => console.error(err));
      if (response.ok) {
        this.sendErrorMessage(response.message);
        await this.setState({
          Submission: true,
          SubmissionLoading: false,
        });
      } else {
        this.setState({
          Submission: true,
          ErrorMessage: "An unknown error occurred, please try again.",
          SubmitError: true,
          SubmissionLoading: false,
        });
      }
    } else {
      this.setState({
        Submission: true,
        ErrorMessage: "Please Select a User before attempting to enable phone number routing.",
        SubmitError: true,
        SubmissionLoading: false,
      });
    }
  }

  sendErrorMessage = (apiResponse) => {
    let { Status, StatusDscription } = JSON.parse(JSON.parse(apiResponse));
    if (Status && StatusDscription) {
      if (Status === "Error") {
        this.setState({ ErrorMessage: StatusDscription, SubmitError: true });
      } else {
        this.setState({ ErrorMessage: "" });
      }
    }
  };

  fetchPhoneDetails = async () => {
    await this.setState({ loading: true });
    let path = `oc/GetTeamsPhoneByServiceNumber?phoneNumber=${this.state.ServiceNumber}`;
    await apiCalls.fetchData(path).then(async (data) => {
      if (data) {
        let copy = JSON.parse(JSON.stringify(data[0]));
        this.setState({
          PhoneDetails: data[0],
          OriginalDetails: copy,
        });
        await this.getTeamsUserByTeamsId();
      } else {
        this.setState({
          PhoneDetails: null,
          OriginalDetails: null,
        });
      }
    }).catch(err => console.error(err)).finally(() => {
      this.setState({
        loading: false,
        SubmitError: false,
        Submission: false,
      });
    });
  }

  getTeamsUserByTeamsId = async () => {
    if (this.state.PhoneDetails.TeamsUserId !== "" && this.state.PhoneDetails.TeamsUserId !== null) {
      await this.setState({ loading: true });
      let path = `oc/GetTeamsUserByTeamsId`;
      await apiCalls.post(path, "POST", JSON.stringify(this.state.PhoneDetails.TeamsUserId)).then(data => {
        if (data) {
          this.setState({
            SelectedUser: JSON.parse(data.message)[0],
            showDRModal: false,
          });
        } else {
          this.setState({
            SelectedUser: null,
            showDRModal: false,
          });
        }
      }).catch(err => console.error(err)).finally(() => {
        this.setState({
          loading: false,
          SubmitError: false,
          Submission: false,
        });
      });
    }
  }

  modalBtns = () => {
    if (this.state.Submission) {
      return (
        <div style={{ textAlign: "center" }}>
          {this.state.ErrorMessage !== "" ? <Fragment><small style={{
            color: 'red',
            display: "block"
          }}>{this.state.ErrorMessage}</small></Fragment> : null}
          <button
            className="btn"
            onClick={async () => {
              if (this.state.SubmitError) {
                this.setState({ showDRModal: false, SubmitError: false, Submission: false, ErrorMessage: "" });
              } else {
                await this.setState({ showDRModal: false, ErrorMessage: "" });
                this.fetchPhoneDetails();
              }
            }}
          >Close</button>
        </div>
      )
    } else {
      return (
        <div>
          <button
            type="button"
            className="btn"
            onClick={this.configureDR}
          >Yes</button>
          <button
            className="btn"
            onClick={() => this.setState({ showDRModal: false })}
          >No</button>
        </div>
      )
    }
  }

  render = () => {
    return (
      <div className="sync-menu-container">
        <div className="container full-card">
          <div className="sync-menu card">
            <div className="card-header">
              <h1>Update Operator Connect Service Number</h1>
              <span
                style={{
                  position: 'relative',
                  bottom: '70px',
                  float: 'right',
                }}
              >
                <a
                  onClick={() => this.setState({ showDRModal: true })}
                  className="a btn"
                >{this.state.OriginalDetails?.TeamsUserId ? `Disable Phone Routing` : `Enable Phone Routing`}</a>
                < Link
                  to={{
                    pathname: `/operator-connectv1`,
                  }}
                  className="a btn">
                  {'Phone List'}
                </Link>
              </span>
            </div>
            <div>
              <br />
              <br />
              <Modal show={this.state.showDRModal} onHide={this.handleClose} backdrop='static'>
                <Modal.Header>
                  <Modal.Title>{this.state.Submission && !this.state.SubmitError ? "Changes Saved!" : "Are you sure you want to save changes?"}</Modal.Title>
                </Modal.Header>
                <Modal.Footer>
                  <hr />
                  <div className="btns">
                    {this.state.SubmissionLoading ? <DataLoadingSpinner className='load spinner' /> : this.modalBtns()}
                  </div>
                </Modal.Footer>
              </Modal>
              {this.cardContent()}
            </div>
          </div>
        </div >
      </div>
    )
  }
}