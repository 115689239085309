import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types'

class CountdownTimer extends Component {
    constructor(props) {
        super(props);
        this.state = {countdown: props.countdown}
        this.title = document.title;
    }

    componentDidMount() {
        this.onCountdown();
    }

    componentDidUpdate() {
        this.onCountdown();
    }

    componentWillUnmount() {
        document.title = this.title;
    }

    displayTitleTimer = () => {
        if (this.props.showInTitle)
            document.title = this.getFormattedTime() + ' '  + this.title;
    }

    onCountdown = () => {
        let ms = 1000;
        this.displayTitleTimer();
        if (this.state.countdown === 0)
            this.props.timeoutAction();
        else {
            if (this.timeout) 
                clearTimeout(this.timeout);
            this.timeout = setTimeout(() => {
                this.setState({countdown: (this.state.countdown - ms)});
            }, ms);
        }
    }

    pad = (num, size = 2) => {
        let formattedNum = String(num);
        while (formattedNum.length < size)
            formattedNum = '0' + formattedNum;
        return formattedNum;
    }

    getMinutes = () => {
        let minutes = this.state.countdown / 1000 / 60;
        return this.pad((minutes < 1 ? 0 : minutes), 1);
    }

    getSeconds = () => {
        let seconds = this.state.countdown / 1000;
        while (seconds >= 60)
            seconds -= 60;
        return this.pad((seconds < 1 ? 0 : seconds));
    }

    getFormattedTime = () => {
        return this.getMinutes() + ':' + this.getSeconds();
    }

    render() {
        return (<div style={{...this.props.style}}>{this.getFormattedTime()}</div>);
    }
}

CountdownTimer.propTypes = {
    countdown: PropTypes.number,
    timeoutAction: PropTypes.func,
    showInTitle: PropTypes.bool
}

export default CountdownTimer;