export const BUSINESS_HOURS = "BUSINESS_HOURS";
export const HOLIDAY_SCHEDULE = "HOLIDAY_SCHEDULE";
export const ADD_EVENT = "ADD_EVENT";
export const EDIT_EVENT = "EDIT_EVENT";

export const recurrenceValues = [
  "Never",
  "Daily",
  "Weekly",
  "MonthlyByDay",
  "MonthlyByWeek",
  "YearlyByDay",
  "YearlyByWeek",
];
