import React, { Component } from 'react';
import MacdOrderListingsWithRouter from './components/order_listings';
import * as helper from '../../scripts/helper';

class MacdAll extends Component { 

  constructor(props) {
    super(props);
    this.state = {
      gridHeight: (window.innerHeight - 350)
    };
  };

  componentDidMount() {
    this.handleResize();
    window.addEventListener('resize', this.handleResize)

    let obj = sessionStorage.getItem('MacdPerms');
    if (obj.indexOf(1) < 0) {
      this.props.history.push(helper.homePageUrl());
    }

  } 

  handleResize = async() => {
    await this.setState({
      gridHeight: (window.innerHeight - 350)
    });
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize)
  }
  

  render() {
    return (
      <div className="container full-card">
        <div className="macd card">
          <div className="card-header">
            <h2>All Service Changes</h2>
          </div>
          <div className="macd-all">
            <MacdOrderListingsWithRouter ListingPage={"Service Changes"} GridHeight={this.state.gridHeight} />
          </div>
        </div>
        </div>
    )    
  }
}

export default MacdAll;