import React, { Component } from 'react';
import { DataLoadingSpinner } from '../../components/spinners';
import MacdUserModal from './modals/macdUserModal';
import * as apiCalls from '../../requests/api_calls';
import * as settings from '../../scripts/settings';
import * as macdHelper from './components/macd_helper';
import * as session from './../../scripts/session';
import { Grid, GridColumn as Column, GridCell } from '@progress/kendo-react-grid';
import { orderBy } from '@progress/kendo-data-query';

class MacdUser extends Component {

    initialState = (props, e) => {
        return {
            serviceUserFilter: props && props.location ? props.location.serviceUserFilter : null,
            productListings: [],
            sort: [{ field: 'PartDescription', dir: 'asc' }],
            gridHeight: (window.innerHeight - 200),
            error: false,
            saving: false,
            loading: true,
            SelectedLocationId: "",
            SelectedLocationName: '',
            locations: [],
            locationOptions: [],
            currentLocation: '',
        }
    }

    constructor(props) {
        super(props);

        this.state = this.initialState();
        const currentLocation = this.getCurrentLocation.bind(this);
        const handleRefreshAfterSubmission = this.handleRefreshAfterSubmission.bind(this);

        class MyActions extends GridCell {
            constructor(props) {
                super(props);
            }

            render() {
                const IsWholesaleUser = session.IsWholesale();

                let assignBtn = () => {
                    let remainingCount = this.props.dataItem.CountRemaining;
                    if (macdHelper.CheckPermissionsForLinks(macdHelper.MACDTYPES.AssignAddOn) === 1 && remainingCount > 0) {
                        return <MacdUserModal
                            isAssign={true}
                            show={true}
                            countAvailable={this.props.dataItem.CountAvailable}
                            countAssigned={this.props.dataItem.CountAssigned}
                            currentLocation={currentLocation()}
                            partNum={this.props.dataItem.PartNumber}
                            parentAddon={this.props.dataItem}
                            refresh={handleRefreshAfterSubmission}
                        />
                    }
                }
                let unassignBtn = () => {
                    let assignedCount = this.props.dataItem.CountAssigned;
                    if (macdHelper.CheckPermissionsForLinks(macdHelper.MACDTYPES.UnassignAddOn) === 1 && assignedCount > 0) {
                        return <MacdUserModal
                            isAssign={false}
                            show={true}
                            countAvailable={this.props.dataItem.CountAvailable}
                            countAssigned={this.props.dataItem.CountAssigned}
                            currentLocation={currentLocation()}
                            partNum={this.props.dataItem.PartNumber}
                            parentAddon={this.props.dataItem}
                            refresh={handleRefreshAfterSubmission}
                        />
                    }
                }
                return (
                    <td>
                        {assignBtn()}
                        {unassignBtn()}
                    </td>
                )
            }
        }

        class MyCountCell extends GridCell {
            constructor(props) {
                super(props);
            }
            render() {
                return (
                    <td>
                        Available: {this.props.dataItem.CountAvailable} &nbsp; Assigned: {this.props.dataItem.CountAssigned} &nbsp; Remaining: {this.props.dataItem.CountRemaining}
                    </td>
                )
            }
        }
        this.CommandCell = MyActions;
        this.CountCell = MyCountCell;
    };

    handleRefreshAfterSubmission = async (data) => {
        if (data === true) {
            this.fetchUserLevelAddonList();
        }
    }

    componentDidMount() {
        if (macdHelper.CheckPermissionsForLinks(macdHelper.MACDTYPES.AssignAddOn) === 0 && macdHelper.CheckPermissionsForLinks(macdHelper.MACDTYPES.UnassignAddOn) === 0) {
            this.props.history.push('/macd');
            return;
        }
        this.fetchLocations();
    }

    getCurrentLocation = () => {
        const x = this.state.currentLocation;
        return x;
    }

    fetchUserLevelAddonList = async () => {
        await this.setState({ loading: true });
        let apiPath = macdHelper.apiGetUserLevelAddons(this.state.currentLocation);
        apiCalls.fetchData(apiPath).then((data) => {
            if (data) {
                let sortedData = orderBy(data, this.state.sort);
                this.setState({
                    productListings: sortedData,
                    loading: false
                });
            }
            else this.setState({ productListings: [], loading: false });
        }).catch(err => console.error(err));
    }

    fetchLocations = async (apiPath = null) => {
        let locationOption = (location, index) => <option key={index} value={location.LocationAccountNumber}>{location.LocationName}</option>

        if (!apiPath) apiPath = macdHelper.apiGetLocations();
        await this.setState({ loading: true });
        apiCalls.fetchData(apiPath).then((data) => {
            if (data) {
                let locationOptions = data.map((location, index) => locationOption(location, index));
                let defaultOption = (<option key={-1} value={"0"} >
                        Select Location
                      </option>);
                      locationOptions.unshift(defaultOption);

                if (!sessionStorage.LocNum) {
                    this.setState({ locationOptions: locationOptions, loading: false, currentLocation: "0" });
                } else {
                    this.setState({ locationOptions: locationOptions, loading: false, currentLocation: sessionStorage.LocNum });//stored value
                    this.fetchUserLevelAddonList();
                }
            } else {
                this.setState({ locations: [], loadingLocations: false, resultsMsg: settings.noResultsMsg, loading: false });
            };
        }).catch(err => console.error(err));
    }

    changeLocation = async (event) => {
        await this.setState({ currentLocation: event.target.value });
        if (this.state.currentLocation !== "0")
            this.fetchUserLevelAddonList();
    }

    // handleAssignProduct = (dataItem, isAssign) => {

    // }

    sortChange = (e) => {
        this.setState({
            productListings: orderBy(this.state.productListings, e.sort),
            sort: e.sort
        })
    }

    gridContent = () => {
        if (this.state.loading) {
            return <DataLoadingSpinner className='load spinner' />
        } else if (this.state.currentLocation === "0") {
            return null;
        } else {
            if (this.state.productListings && this.state.productListings.length > 0) {
                return (
                    <Grid
                        style={{ height: this.state.gridHeight + 'px' }}
                        data={this.state.productListings}
                        sortable={{ allowUnsort: true, mode: 'single' }}
                        sort={this.state.sort}
                        onSortChange={this.sortChange}
                    >
                        <Column field='PartDescription' title="Add-On" />
                        <Column width="600px" style={{ "textAlign": "center" }} cell={this.CountCell} sortable={false} />
                        <Column width="250px" cell={this.CommandCell} sortable={false} />
                        <Column field="spacer" title=" " sortable={false} filterable={false} />
                    </Grid>

                )
            } else return <h4>There are no available User-Level Add-Ons on this account!</h4>

        }
    }

    render() {
        return (
            <div className="container full-card">
                <div className="macd card">
                    <div className="card-header">
                        <h1>Manage User Add-ons</h1>
                    </div>
                    <div className="macd-row">
                        <div className="macd-item">
                            <div className="form-group">
                                <label style={{ fontSize: "medium", fontWeight: "bold" }}>Location:</label>
                                <select onChange={this.changeLocation} style={{width: "200px", marginLeft: "20px", height:'20px', border: 'none', borderBottom: '2px solid #eee', outline: 'none', background: '#f9f9f9', boxShadow: 'none'}} value={this.state.currentLocation}>
                                    {this.state.locationOptions}
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="macd-users">
                        {this.gridContent()}
                    </div>
                </div>
            </div>
        )
    }

}

export default MacdUser;