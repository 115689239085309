import React, { Component } from 'react';
import * as apiCalls from '../../requests/api_calls';
import { DataLoadingSpinner } from '../../components/spinners';
import * as config from '../../requests/config';
import * as helper from './helper/ticket_helper';
import * as _ from 'lodash';
import AffectedDevicesModal from './components/affeced_devices_modal';
import AffectedServicesModal from './components/affected_services_modal';
import AffectedCircuitsModal from './components/affected_circuits_modal'
import ConfirmDeleteAttachmentModal from './components/delete_attachment_modal';


export default class DetailedTicket extends Component {
  constructor(props) {
    super(props);

    this.state = {
      gridHeight: (window.innerHeight - 210),
      loading: true,
      Sort: [{ field: "TicketNum", dir: "desc" }],
      TicketNumber: '',
      AddComment: false,
      TicketId: this.props.match.params.TicketId,
      NewComment: '',
      commentSubmitted: false,
      DetailedTicket: undefined,
      contactSubmitted: false,
      AttachmentList: [],
      attachmentSubmitted: false,
      //
      requestedClose: false,
      escalateTicket: false,
      //
      EmailList: [],
      Edit: false,
      savingTicket: false,
      //
      AffectedDevices: [],
      AffectedServices: [],
      AffectedCircuits:[],
      //
      olderThan3Days: false,
    }
  }

  componentDidMount() {
    if (sessionStorage.hasTicketing === 'NONE') {
      this.props.history.push('/');
    }
    sessionStorage.removeItem('serviceList');
    sessionStorage.removeItem('deviceList');
    sessionStorage.removeItem('circuitsList')
    this.fetchTicketData();
  }

  fetchTicketData = () => {
    let requests = [
      this.fetchTicketDetails(),
      this.fetchContactList(),
      this.getPriorityList(),
      this.getCommMethodList(),
       this.fetchEmailList(),
    ];
    Promise.all(requests).catch(err => console.error(err));
  }

  getCommMethodList = () => {
    let apiPath;
    sessionStorage.enterpriseType == "DATA"?apiPath= `admin/GetPicklistValues?RecordTypeId=${helper.dataServices}&Picklist=COMM_METHOD&ControllerValue=`:
    apiPath= `admin/GetPicklistValues?RecordTypeId=${helper.retailTrouble}&Picklist=COMM_METHOD&ControllerValue=`;
    apiCalls.fetchData(apiPath).then(data => {
      if (data) {
        if(data.ValueList.includes('Portal'))
        {
          data.ValueList.splice(data.ValueList.indexOf('Portal'),1);
        }
        this.setState({ CommMethodOptions: data.ValueList });
      } else {
        this.setState({ CommMethodOptions: [] });
      }
    }).catch(err => console.error(err));
  }

  getPriorityList = () => {
    let apiPath;
    sessionStorage.enterpriseType == "DATA"?apiPath= `admin/GetPicklistValues?RecordTypeId=${helper.dataServices}&Picklist=PRIORITY&ControllerValue=`:
    apiPath= `admin/GetPicklistValues?RecordTypeId=${helper.retailTrouble}&Picklist=PRIORITY&ControllerValue=`;
    apiCalls.fetchData(apiPath).then(data => {
      if (data) {
        this.setState({ PriorityOptions: data.ValueList });
      } else {
        this.setState({ PriorityOptions: [] });
      }
    }).catch(err => console.error(err));
  }

  fetchEmailList = () => {
    let apiPath = `admin/GetTicketEmailList?TicketId=${this.state.TicketId}`;
    apiCalls.fetchData(apiPath).then(data => {
      if (data) {
        this.setState({ EmailList: data });
      }
    }).catch(err => console.error(err));
  }

  fetchAttachments = async () => {
    let apiPath = `admin/GetAttachmentList?TicketId=${this.state.TicketId}`;
    apiCalls.fetchData(apiPath).then(data => {
      if (data) {
        this.setState({ AttachmentList: data });
      } else {
        this.setState({ AttachmentList: [] });
      }
    }).catch(err => console.error(err)).finally(() => {
      this.setState({ attachmentSubmitted: false });
    });
  }

  fetchContactList = () => {
    let apiPath = 'admin/GetContactList';
    apiCalls.fetchData(apiPath).then(data => {
      if (data) {
        this.setState({ ContactOptions: data });
      } else {
        this.setState({ ContactOptions: [] });
      }
    }).catch(err => console.error(err));
  }

  fetchTicketDetails = () => {
    let apiPath = `admin/GetTicket?TicketId=${this.state.TicketId}`;
    apiCalls.fetchData(apiPath).then(async (data) => {
      if (data) {
        if (data.CreatedDate) {
          data.CreatedDate += ' ' + sessionStorage.userTimeZone;
          let date1 = new Date(data.CreatedDate);
          let date2 = new Date();
          let diffTime = Math.abs(date2 - date1);
          let diffHours = Math.ceil(diffTime / (1000 * 60 * 60));
          if (diffHours > 72) {
            this.setState({ olderThan3Days: true });
          }
        }
        if (data.SubCategory === null) {
          data.SubCategory = ''
        }
        if (data.OtherCategory === null) {
          data.OtherCategory = ''
        }
        if (data.PreferredCommunication === null) {
          data.PreferredCommunication = ''
        }
        let copy = JSON.parse(JSON.stringify(data));
        let { IsLess24 } = data;
        await this.fetchAttachments();
        this.setState({
          DetailedTicket: data,
          OriginalTicket: copy,
          commentSubmitted: false,
          requestedClose: false,
          escalateTicket: false,
          loading: false,
          Edit: IsLess24 === true ? true : false,
          savingTicket: false,
          AddComment: false,
        });
      } else {
        this.setState({
          commentSubmitted: false,
          requestedClose: false,
          escalateTicket: false,
          loading: false,
          Edit: false,
          savingTicket: false,
          AddComment: false,
        })
      }
    }).catch(err => console.error(err));
  }

  addCommentBtn = () => {
    if (this.state.DetailedTicket.Status !== 'Closed' && sessionStorage.hasTicketing === 'FULL') {
      if (!this.state.AddComment) {
        return (
          <button className='btn'
            onClick={() => this.setState({ AddComment: !this.state.AddComment })}
          >Add Comment</button>
        )
      } else {
        return (
          <span>
            <button className='btn'
              disabled={this.state.NewComment == '' || this.state.commentSubmitted || this.state.NewComment.length > 3000 ? true : false}
              onClick={this.handleSubmitNewComment}
            >Submit</button>
            <button className='btn'
              disabled={this.state.commentSubmitted ? true : false}
              onClick={() => this.setState({ AddComment: !this.state.AddComment, NewComment: '' })}
            >Cancel</button>
          </span>
        )
      }
    } else return;
  }

  newCommentTextBox = () => {
    if (this.state.AddComment) {
      return (
        <div className='new-comment-container' style={{ display: 'block' }}>
          <h3 style={{ display: 'block', margin: '0' }}>New Comment</h3>
          <textarea
            style={{
              border: '2px solid lightgrey',
              height: '150px',
              width: '450px',
              resize: 'none'
            }}
            value={this.state.NewComment}
            onChange={this.handleWriteComment}
          ></textarea>
          <br />
          <small
            style={{
              color: this.state.NewComment.length > 3000 ? 'red' : '#b3b1b1'
            }}
          >{`Max Character Limit = ${this.state.NewComment.length}/3000`}</small>
        </div>
      )
    } else return;
  }

  handleWriteComment = (e) => {
    let { value } = e.target;
    this.setState({ NewComment: value });
  }

  handleSubmitNewComment = async () => {
    await this.setState({ commentSubmitted: true });
    let apiPath = 'admin/CreateComment';
    let commentDTO = {
      CommentId: '',
      TicketId: this.state.DetailedTicket.TicketId,
      CommentBody: this.state.NewComment
    };
    if (this.state.NewComment != '') {
      let response = await apiCalls.post(apiPath, 'POST', JSON.stringify(commentDTO)).then(res => res).catch(err => console.error(err));
      if (response.ok) {
        await this.fetchTicketDetails();
        this.setState({
          AddComment: false, NewComment: '',
        });
      } else {
        let msg = response.message;
        this.fetchTicketDetails();
      }
    }
  }

  handleChangeSelect = (e, key) => {
    let { value } = e.target;
    let DetailedTicket = { ...this.state.DetailedTicket };
    if (key === 'ContactId') {
      DetailedTicket.Contact.ContactId = value;
    } else {
      DetailedTicket[key] = value;
    }
    this.setState({ DetailedTicket });
  }

  formGroupInput = (labelFor, labelName, val, key, type = 'text') => {
    if ((this.state.requestedClose && labelFor === 'Status') || (this.state.escalateTicket && labelFor === 'Escalated')) {
      return (
        <div className='form-group-wrapper'>
          <label htmlFor={labelFor}>{labelName}:</label>
          <DataLoadingSpinner
            style={{
              position: 'relative',
              height: '0px',
              bottom: '20px',
            }}
            className='load spinner'
          />
        </div>
      )
    } else {
      let bReadOnly = this.state.Edit && sessionStorage.hasTicketing === 'FULL' && this.state.DetailedTicket.Status !== 'Closed' &&
        (labelFor === 'Contact' || labelFor === 'Priority' || labelFor === 'CommunicationMethod')
        ? false : true;
      return (
        <div className='form-group-wrapper'>
          <label htmlFor={labelFor}>{labelName}:</label>
          <input
            readOnly={bReadOnly}
            style={{ cursor: bReadOnly === true ? 'not-allowed' : 'cursor' }}
            type={type}
            id={labelFor}
            name=''
            value={val}
            className='custom-input-class'
            onChange={(e) => this.handleChangeSelect(e, key)}
          />
        </div>
      )
    }
  }


  formSelect = (labelFor, labelName, val, options, key) => {
    let bDisabled = sessionStorage.hasTicketing === 'FULL' && (this.state.Edit || labelFor === 'Contact') && this.state.DetailedTicket.Status !== 'Closed' ? false : true;
    let cursor = bDisabled === true ? 'not-allowed' : 'pointer';
    let htmlSelect = () => {
      if (options) {
        if (labelFor === 'Contact') {
          options = helper.contactOptions(options)
        } else {
          options = helper.pickListOptions(options);
        }
        return (
          <select id={labelFor} className='custom-input-class' value={val} style={{ cursor: cursor }}
            onChange={(e) => this.handleChangeSelect(e, key)}
            disabled={bDisabled}
          >
            {labelFor === 'SubCategory' || labelFor === 'CommunicationMethod' ? <option value=''>None</option> : null}
            {options}
          </select>
        )
      } else {
        return <DataLoadingSpinner
          style={{
            position: 'relative',
            height: '0px',
            bottom: '20px',
          }}
          className='load spinner'
        />
      }
    }
    return (
      <div className='form-group-wrapper'
        style={{ paddingBottom: '15px' }}
      >
        <label htmlFor={labelFor}>{labelName} :</label>
        {htmlSelect()}
      </div>
    )
  }

  commentContainer = (CommentList) => {
    if (this.state.commentSubmitted) {
      return <DataLoadingSpinner
        style={{
          position: 'relative',
        }}
        className='load spinner'
      />
    } else {
      if (CommentList && CommentList.length > 0) {
        return CommentList.map((comment, idx) => {
          let commentView =comment.CommentBody.split("\n");
          if(sessionStorage.enterpriseType === "DATA")
          {
              let cv= commentView.filter(e=>e!== 'ServiceType: undefined')
              var commentBody = cv.join("\n").toString();
          }
          return (
            <div key={idx} className='comment-group-wrapper'
              style={{
                padding: '4px',
                backgroundColor: 'lightgrey'
              }}
            >
              <div style={{
                whiteSpace: 'pre-line'
              }}>
                <b>Comment: </b>
                {sessionStorage.enterpriseType==="DATA"?commentBody: comment.CommentBody}
              </div>
              <div style={{ margin: '10px 0 0 10px' }}><b>Date:</b> {`${comment.CreatedDate} ${sessionStorage.userTimeZone}`}</div>
              <div style={{ margin: '10px 0 0 10px' }}><b>By:</b> {comment.CreatedBy}</div>
            </div>
          )
        });
      } else return;
    }

  }

  ticketEmails = () => {
    if (this.state.EmailList && this.state.EmailList.length > 0) {
      return (
        <div className='ticket-emails-main'
          style={{ display: 'inline-block', width: '70%' }}
        >
          <h3 style={{
            display: 'inline-block',
            paddingRight: '20px',
          }}>Emails</h3>
          <div className='emails-container' style={{
            paddingTop: '10px',
            maxHeight: '300px',
            overflow: 'auto',
          }}>
            {this.state.EmailList.map((itm, idx) => {
              return (
                <div key={idx} className='email-group-wrapper'
                  style={{
                    padding: '4px',
                    backgroundColor: idx % 2 === 0 ? 'lightgrey' : 'white',
                  }}
                >
                  <div><b>From:</b> {itm.FromAddress}</div>
                  <div style={{ margin: '10px 0 0 10px', whiteSpace: 'pre-line' }}><b>Subject:</b> {itm.Subject}</div>
                  <div style={{ margin: '10px 0 0 10px', whiteSpace: 'pre-line' }}><b>Description:</b> {itm.TextBody}</div>
                  <div style={{ margin: '10px 0 0 10px', whiteSpace: 'pre-line' }}><b>Date:</b> {`${itm.CreatedDate} ${sessionStorage.userTimeZone}`}</div>
                </div>
              )
            })}
          </div>
        </div>
      )
    } else return;
  }

  storeAffectedDevices = async (data) => {
    let { TicketId } = this.state;
    let devices = data.map(itm => {
      return `MAC: ${itm.MacAddress || ''}\nMake/Model: ${itm.MakeModel || ''}\nAssignedTo: ${itm.AssignedTo || ''}\n\n`;
    });
    await this.setState({ AffectedDevices: devices });
    if (this.state.AffectedDevices.length > 0) {
      this.submitAffectedUtilities(TicketId);
    }
  }

  storeAffectedServices = async (data) => {
    let { TicketId } = this.state;
    let services = data.map(itm => {
      let extension = ''
      if (itm.Extension)
        extension = "Extension: " + itm.Extension + '\n';
      else if (itm.Username && itm.ServiceNumber !== itm.Username)
        extension = "Username: " + itm.Username + '\n';
      let serviceID = itm.ServiceNumber ? "ServiceID: " + itm.ServiceNumber + '\n' : ''
      return `ServiceType: ${itm.ServiceType || itm.UserType}\n${serviceID}${extension}\n`;
    });
    await this.setState({ AffectedServices: services });
    if (this.state.AffectedServices.length > 0) {
      this.submitAffectedUtilities(TicketId);
    }
  }

  storeAffectedCircuits = async (data) => {
    let { TicketId } = this.state;
    let circuits = data.map(itm => {
      return `Circuits: ${itm.CircuitId}\n${itm.CircuitName}\n`;
    });
    await this.setState({ AffectedCircuits: circuits });
    if (this.state.AffectedCircuits.length > 0) {
      this.submitAffectedUtilities(TicketId);
    }
  }

  closeAndEscalateBtns = () => {
    if (sessionStorage.hasTicketing === 'FULL') {
      return (
        <div>
          <button
            className='btn'
            style={{
              display: 'block'
            }}
            disabled={this.state.DetailedTicket.Status === 'Closed' || this.state.requestedClose || sessionStorage.hasTicketing !== 'FULL' ? true : false}
            onClick={this.requestTicketClose}>Request Close</button>
        </div>
      )
    } else return;
  }

  requestTicketClose = async () => {
    let { TicketId } = this.state.DetailedTicket;
    let apiPath = `admin/UpdateTicket`;
    let dto = {
      TicketId: TicketId,
      Status: 'Closed',
    };
    await this.setState({ requestedClose: true });
    let response = await apiCalls.post(apiPath, 'PATCH', JSON.stringify(dto)).then(res => res).catch(err => console.error(err));
    if (response) {
      this.fetchTicketDetails();
    }
  }

  detailedViewContent = () => {
    if (this.state.loading) {
      return <DataLoadingSpinner className='load spinner' />
    } else {
      if (this.state.DetailedTicket) {
        let { Status, Escalated, LocationAccountNumber, Contact, Priority, Category, SubCategory, OtherCategory, Description, CommentList, PreferredCommunication,TicketType } = this.state.DetailedTicket;
        let Location = LocationAccountNumber==='1'?'':LocationAccountNumber;
        let contactId = '';
         if (Contact) {
           contactId = Contact.ContactId;
         }
         let type ="";
         if(TicketType ==="")
         {
          type = "Data"
         }
         else{
          type= "Voice"
         }
        let DescriptionDisabled = this.state.Edit && sessionStorage.hasTicketing === 'FULL' && this.state.DetailedTicket.Status === 'New' ? false : true;
        return (
          <div className='main-container'>
            {!this.state.savingTicket ? <div className='flex-parent-wrapper'>
              <div className='flex-container-one'>
              {this.formGroupInput('Type', 'Type', type)}
                {this.formGroupInput('Status', 'Status', Status)}
                {this.formGroupInput('Location', 'Location', Location)}
                {Contact=== null?this.formGroupInput('Contact', 'Contact', Contact):this.formSelect('Contact', 'Contact', contactId, this.state.ContactOptions, 'ContactId')}
                {sessionStorage.enterpriseType==="VOICE" || this.state.DetailedTicket.TicketType==="RETAIL_TROUBLE" ? null : this.formGroupInput('Category', 'Category', Category)}
                {/* {sessionStorage.enterpriseType==="VOICE" || this.state.DetailedTicket.TicketType==="RETAIL_TROUBLE" ? null : this.formGroupInput('SubCategory', 'Sub Category', SubCategory)} */}
                {this.formSelect('CommunicationMethod', 'Contact Method', PreferredCommunication, this.state.CommMethodOptions, 'PreferredCommunication')}
                <br />
                {this.ticketUpdateBtns()}
              </div>
              <div className='flex-container-two'>
                {this.closeAndEscalateBtns()}
              </div>
              <div className='flex-container-three'>
                {this.state.AffectedDevices.length > 0
                  ? <div>
                    <small>{`Affected Devices: ${this.state.AffectedDevices.length}`}</small>
                    <br />
                  </div> : null}
                {this.state.AffectedServices.length > 0
                  ? <div>
                    <small>{`Affected Services & Users: ${this.state.AffectedServices.length}`}</small>
                    <br />
                  </div> : null}

                  {this.state.AffectedCircuits.length > 0
                  ? <div>
                    <small>{`Affected Circuits & Users: ${this.state.AffectedCircuits.length}`}</small>
                    <br />
                  </div> : null}
                {this.state.AttachmentList.length > 0
                  ? <div>
                    <small>{`Attachments: ${!this.state.attachmentSubmitted ? this.state.AttachmentList.length : ''}`}</small>
                    <br />
                  </div> : null}
                <h3 style={{ display: 'block', margin: '0' }}>Description</h3>
                <textarea
                  readOnly={DescriptionDisabled}
                  style={{
                    background: this.state.DetailedTicket.Status === 'New' ? 'white' : 'lightgrey',
                    cursor: DescriptionDisabled === true ? 'not-allowed' : 'cursor',
                    border: '1px solid #44576f',
                    height: '250px',
                    width: '100%',
                    resize: 'none',
                  }}
                  value={Description}
                  onChange={(e) => this.handleChangeSelect(e, 'Description')}
                ></textarea>
                <br />
                {this.state.DetailedTicket.Status !== 'Closed' && sessionStorage.hasTicketing === 'FULL'
                  ? <div style={{ textAlign: 'center' }}>
                    {sessionStorage.enterpriseType==="VOICE" || this.state.DetailedTicket.TicketType==="RETAIL_TROUBLE" ?<AffectedServicesModal
                      affectedServices={this.storeAffectedServices}
                      btnStyles={helper.btnStyles2}
                      saveSelections={false}
                    />:<AffectedCircuitsModal
                    affectedServices={this.storeAffectedCircuits}
                    btnStyles={helper.btnStyles}
                    saveSelections={true}
                  />}
                    {!this.state.attachmentSubmitted ?
                      <button className='btn' style={helper.btnStyles2}
                        onClick={this.openAttachments}
                      >Add Attachments
                        <input
                          type="file"
                          id="file"
                          accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps"
                          ref='fileUploader'
                          style={{ display: "none" }}
                          onChange={this.addAttachment}
                          onClick={(event) => {
                            event.target.value = null;
                          }}
                        />
                      </button> : <span
                        style={{
                          display: "inline-block",
                          width: '30%'
                        }}
                      >
                        <DataLoadingSpinner
                          style={{
                            position: 'relative',
                            height: '0px',
                          }}
                          className='load spinner'
                        />
                      </span>
                    }
                     <AffectedDevicesModal
                      affectedDevices={this.storeAffectedDevices}
                      btnStyles={helper.btnStyles2}
                      saveSelections={false}
                    />
                  </div> : null}
              </div>
            </div> : <DataLoadingSpinner
              style={{
                position: 'relative',
                height: '0px',
                bottom: '20px',
              }}
              className='load spinner'
            />
            }
            <br />
            <br />
            <div className='flex-container-four'>
              {this.newCommentTextBox()}
              <div className='ticket-comments-main'
                style={{ display: 'inline-block', width: '70%' }}
              >
                <h3 style={{
                  display: 'inline-block',
                  paddingRight: '20px',
                }}>Comments</h3>
                {this.addCommentBtn()}
                <div className='comments-container' style={{
                  paddingTop: '10px',
                  maxHeight: '300px',
                  backgroundColor: 'lightgrey',
                  overflow: 'auto',
                }}>
                  {this.commentContainer(CommentList)}
                </div>
              </div>
              {this.ticketEmails()}
            </div>
          </div>
        )
      } else {
        return <p style={{ textAlign: 'center' }}>No Available Ticket</p>
      }
    }
  }

  ticketUpdateBtns = () => {
    let { DetailedTicket, OriginalTicket } = this.state;
     if ((!DetailedTicket.Contact || DetailedTicket.Contact.ContactId === OriginalTicket.Contact.ContactId) &&
       DetailedTicket.Priority === OriginalTicket.Priority && DetailedTicket.Category === OriginalTicket.Category &&
      //  DetailedTicket.SubCategory === OriginalTicket.SubCategory && DetailedTicket.OtherCategory === OriginalTicket.OtherCategory &&
       DetailedTicket.PreferredCommunication === OriginalTicket.PreferredCommunication && DetailedTicket.Description === OriginalTicket.Description
     )
      return;
    else {
      return (
        <div
          style={{
            display: 'block',
            position: 'relative',
          }}>
          <button
            className='btn'
            onClick={this.saveTicket}
          >Save</button>
          <button
            className='btn'
            onClick={this.undoTicket}
          >Cancel</button>
        </div>
      )
    }
    
  }

  submitAffectedUtilities = async (ticketId) => {
    await this.setState({ commentSubmitted: true })
    let string = '';
    if (this.state.AffectedDevices.length > 0 && this.state.AffectedServices.length > 0) {
      string = `AFFECTED DEVICES COUNT: ${this.state.AffectedDevices.length}\n${this.state.AffectedDevices.join().replace(/,/g, '')} AFFECTED SERVICES & USERS COUNT: ${this.state.AffectedServices.length}\n${this.state.AffectedServices.join().replace(/,/g, '')}`;
    } else if (this.state.AffectedDevices.length > 0 && this.state.AffectedServices.length < 1) {
      string = `AFFECTED DEVICES COUNT: ${this.state.AffectedDevices.length}\n${this.state.AffectedDevices.join().replace(/,/g, '')}`;
    } 
    else if (this.state.AffectedCircuits.length > 0 && this.state.AffectedDevices.length < 1) {
      string = `AFFECTED CIRCUITS COUNT: ${this.state.AffectedCircuits.length}\n${this.state.AffectedCircuits.join().replace(/,/g, '')}`;
    }
    else if(this.state.AffectedCircuits.length>0 &&this.state.AffectedDevices.length>0){
      string = `AFFECTED CIRCUITS COUNT: ${this.state.AffectedCircuits.length}\n${this.state.AffectedDevices.join().replace(/,/g, '')} AFFECTED DEVICES COUNT: ${this.state.AffectedDevices.length}\n${this.state.AffectedDevices.join().replace(/,/g, '')}`;
    }
    else {
      string = `AFFECTED SERVICES & USERS COUNT: ${this.state.AffectedServices.length}\n${this.state.AffectedServices.join().replace(/,/g, '')}`
    }
    let apiPath = 'admin/CreateComment';
    let commentDTO = {
      CommentId: '',
      TicketId: ticketId,
      CommentBody: string,
    };
    if (this.state.AffectedDevices.length > 0 || this.state.AffectedServices.length > 0 || this.state.AffectedCircuits.length > 0) {
      let response = await apiCalls.post(apiPath, 'POST', JSON.stringify(commentDTO)).then(res => res).catch(err => console.error(err));
      if (response.ok) {
        await this.fetchTicketDetails();
        this.setState({
          AffectedDevices: [],
          AffectedServices: [],
          AffectedCircuits:[]
        });
      } else {
        let msg = response.message;
        await this.fetchTicketDetails();
      }
    }
  }

  saveTicket = async () => {
    let { DetailedTicket, TicketId } = this.state;
    let { Contact, Priority, Category,  OtherCategory, Description, PreferredCommunication } = DetailedTicket;
    let contactId = '';
    if (Contact) {
      contactId = Contact.ContactId;
    }
    await this.setState({ savingTicket: true });
    let apiPath = `admin/UpdateTicket`;
    let dto = {};
    if (this.state.Edit) {
      dto = {
        TicketId: TicketId,
        ContactId: contactId,
        Priority: Priority,
        Category: Category,
        OtherCategory: OtherCategory,
        Description: Description,
        PreferredCommunication: PreferredCommunication
      };
    } else {
      dto = {
        TicketId: TicketId,
        ContactId: contactId,
      };
    }
    let response = await apiCalls.post(apiPath, 'PATCH', JSON.stringify(dto)).then(res => res).catch(err => console.error(err));
    if (response) {
      this.fetchTicketDetails();
    }
  }

  undoTicket = async () => {
    let { OriginalTicket } = this.state;
    this.setState({ DetailedTicket: OriginalTicket });
  }

  exportAttachment = (refName) => {
    this.refs[refName].submit();
  }

  attachmentDropdown = () => {
    let bReadOnly = sessionStorage.hasTicketing === 'FULL' && this.state.DetailedTicket.Status !== 'Closed' ? false : true
    return (
      <div id="dropdown" className="dropdown">
        <a className="menubtn dropdown-toggle" type="button" id="" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          View Attachments
          <span className="caret"></span>
        </a>
        <ul className="dropdown-menu" aria-labelledby="" style={{
          minWidth: '200px',
          fontSize: '12px',
          padding: '10px',
        }}>
          {this.state.AttachmentList.map((file, idx) => {
            let refName = file.AttachmentId;
            return (
              <li id="attachment-list" key={idx}>
                <form
                  ref={refName}
                  target="iFrameSave" action={config.baseApiUrl + 'admin/GetAttachment'} method="POST">
                  <input type="hidden" name="AttachmentId" value={refName}></input>
                  <input type="hidden" name="access_token" value={config.storedAccessToken()} className='hidden'></input>
                  <input type="hidden" name="x_policy" value={config.storedXPolicy()} className='hidden'></input>
                </form>
                <iframe name="iFrameSave" height="0" width="0" className='hidden pull-right'></iframe>
                <div>
                  <a
                    tabIndex="0"
                    ref='exportBtn'
                    onClick={(e) => this.exportAttachment(refName)}
                  >{file.FileName}</a>
                  <ConfirmDeleteAttachmentModal
                    FileName={file.FileName}
                    GetList={this.fetchAttachments}
                    ID={refName}
                    AttachmentList={this.state.AttachmentList}
                    refresh={this.handleRefreshAttachments}
                    ReadOnly={bReadOnly}
                  />
                </div>
                <hr />
              </li>
            )
          })}
        </ul>
      </div>
    )
  }

  handleRefreshAttachments = async (data) => {
    await this.setState({ AttachmentList: data });
  }

  addAttachment = async (event) => {
    let file = event.target.files[0];
    let reader = new FileReader();
    reader.addEventListener('loadend', () => {
      let body = reader.result.split(',')[1];
      let name = file.name;
      this.createAttachment(body, name);
    }, false);
    if (file) {
      reader.readAsDataURL(file);
    }
  }

  createAttachment = async (body, name, ticketId) => {
    let apiPath = 'admin/CreateAttachment';
    let attachmentDTO = {
      TicketId: this.state.TicketId,
      AttachmentBody: body,
      FileName: name,
    };
    await this.setState({ attachmentSubmitted: true });
    let response = await apiCalls.post(apiPath, 'POST', JSON.stringify(attachmentDTO)).then(res => res).catch(err => console.error(err));
    if (response.ok) {
      this.fetchAttachments();
    } else {
      let msg = JSON.parse(response.message);
      console.log('res error', msg);
    }
  }

  openAttachments = () => {
    this.refs.fileUploader.click();
  }

  render() {
    return (
      <div className='detailed-ticketing-page'>
        <div className="container full-card">
          <div className="detailed-ticket card">
            <div className="card-header">
              <h1>Ticket {this.state.DetailedTicket ? <small>{`# ${this.state.DetailedTicket.TicketNumber}`} {`- ${this.state.DetailedTicket.Subject}`}</small> : null}</h1>
            </div>
            <div className="card-actions">
              {this.state.AttachmentList.length > 0 && !this.state.attachmentSubmitted ? this.attachmentDropdown() : null}
            </div>
            <div className="card-content">
              <br />
              <br />
              <br />
              {this.detailedViewContent()}
            </div>
          </div>
        </div>
      </div>
    )
  }
}