import React, { Component } from "react";
import * as apiCalls from "../../../requests/api_calls";
import { DataLoadingSpinner } from '../../../components/spinners';
import Masonry from "react-masonry-component";
import CircuitsGraph from './graph_component';
import FullCircuitsGraph from './full_graph';
//import DevicesGraphTest from "./testDevices";

export default class CircuitsGraphingDashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      CircuitId: this.props.location.state.CircuitId,
      CircuitName: this.props.location.state.CircuitName,
      HostName: this.props.location.state.HostName,
      DeviceHostName: this.props.location.state.DeviceHostName,
      WANPortAlias: this.props.location.state.WANPortAlias,
      //
      dashView: "",
      rtaField: "rta",
      plField: "pl",
      ptaField: "pta",
      upDownField: "upDown",
      //
      rtaMeasurement: "max",
      plMeasurement: "max",
      ptaMeasurement: "max",
      upDownMeasurement: "max",
      //
      rtaAggWindow: "D",
      plAggWindow: "D",
      ptaAggWindow: "D",
      upDownAggWindow: "D",
      //
      rtaDateRange: "-1d",
      plDateRange: "-1d",
      ptaDateRange: "-1d",
      upDownDateRange: "-1d",
    }
  }

  packageData = (data, range, type = "") => {
    if (data) {
      let graphData = type == "historical" ? JSON.parse(data.Body.GetHistoricalUpDownForHostNameResult) : JSON.parse(data.Body.GetGraphInfoForHostSplitRtaResult);
      let summary = JSON.parse(graphData.JSONData).summary;
      let arr = JSON.parse(graphData.JSONData);
      if (range != "-1d" && range != "-2d") {
        if (arr.Avg) {
          arr.Avg.forEach((itm) => {
            if (itm) {
              itm.x = new Date(itm.x);
            }
          });
        } else {
          return [[], []]
        }
        if (arr.Max && !arr.Min) {
          if (arr.Max) {
            arr.Max.forEach((itm) => {
              if (itm) {
                itm.x = new Date(itm.x);
              }
            });
            return [arr.Max, arr.Avg, summary];
          } else {
            return [[], []]
          }
        } else {
          if (arr.Min) {
            arr.Min.forEach((itm) => {
              if (itm) {
                itm.x = new Date(itm.x);
              }
            });
            return [arr.Min, arr.Avg, summary];
          } else {
            return [[], []]
          }
        }
      } else {
        if (arr.data) {
          arr.data.forEach((itm) => {
            if (itm) {
              itm.x = new Date(itm.x);
            }
          });
          return [arr.data, summary];
        } else {
          return [[], []]
        }
      }
    } else {
      return [[], []]
    }
  };

  fetchGraphData = async (range, field, aggregateWindow) => {
    let host = this.props.location.state.HostName;
    let path = `admin/CircuitMonitoring/GetDataByHostName?range=${range}&field=${field}&hostname=${host}&aggregateWindow=${aggregateWindow}`;
    await apiCalls.fetchData(path).then(data => {
      let packagedData = this.packageData(data, range);
      if (field == "rta" || field == "max_rta,avg_rta" || field == "min_rta,avg_rta") {
        this.setState({
          rta: packagedData,
        });
        return;
      } else if (field == "pl" || field == "max_pl,avg_pl" || field == "min_pl,avg_pl") {
        this.setState({
          pl: packagedData,
        });
        return;
      } else if (field == "pta" || field == "max_pta,avg_pta" || field == "min_pta,avg_pta") {
        this.setState({
          pta: packagedData,
        });
        return;
      } else {
        return;
      }
    }).catch(err => console.error(err));
  };

  fetchUpDownStatus = async (range) => {
    let host = this.props.location.state.HostName;
    let path = `admin/CircuitMonitoring/GetHistoricalUpDownDataByHostName?hostname=${host}&range=${range}`;
    await apiCalls.fetchData(path).then(data => {
      let packagedData = this.packageData(data, range, "historical");
      this.setState({
        upDown: packagedData,
      });
    }).catch(err => console.error(err));
  }

  redirectView = async (value) => {
    await this.setState({ dashView: value })
  }

  updateGraphUrl = async (dateRange = this.state.DateRange, type, aggWindow, measurement) => {
    if (type == "rta" || type == "max_rta,avg_rta" || type == "min_rta,avg_rta") {
      await this.setState({
        rtaDateRange: dateRange,
        rtaAggWindow: aggWindow,
        rtaMeasurement: measurement,
        rta: [],
      });
      await this.fetchGraphData(dateRange, this.getType(dateRange, "rta", this.state.rtaMeasurement), aggWindow);
      return;
    } else if (type == "pl" || type == "max_pl,avg_pl" || type == "min_pl,avg_pl") {
      await this.setState({
        plDateRange: dateRange,
        plAggWindow: aggWindow,
        plMeasurement: measurement,
        pl: [],
      });
      await this.fetchGraphData(dateRange, this.getType(dateRange, "pl", this.state.plMeasurement), aggWindow);
      return;
    } else if (type == "pta" || type == "max_pta,avg_pta" || type == "min_pta,avg_pta") {
      await this.setState({
        ptaDateRange: dateRange,
        ptaAggWindow: aggWindow,
        ptaMeasurement: measurement,
        pta: [],
      });
      await this.fetchGraphData(dateRange, this.getType(dateRange, "pta", this.state.ptaMeasurement), aggWindow);
      return;
    } else if (type == "upDown") {
      await this.setState({
        upDownDateRange: dateRange,
        upDownAggWindow: aggWindow,
        upDownMeasurement: measurement,
        upDown: [],
      });
      await this.fetchUpDownStatus(dateRange);
      return;
    } else {
      return;
    }
  }

  getType = (dateRange, val, measurement) => {
    if (dateRange == "-1d" || dateRange == "-2d") {
      return val;
    } else {
      return `${measurement}_${val},avg_${val}`;
    }
  }

  // deviceGraphs = () => {
  //   if (this.state.CircuitId && this.state.HostName && this.state.DeviceHostName && this.state.WANPortAlias) {
  //     return (
  //       <DevicesGraphTest
  //         HostName={this.state.DeviceHostName}
  //         Node={this.state.WANPortAlias}
  //         CircuitName={this.state.CircuitName}
  //       />
  //     )
  //   } else return null;
  // }

  render() {
    //if (!this.state.loading) {
    switch (this.state.dashView) {
      default:
        return (
          <div>
            <div className="header">
              <h5><b>{`${this.state.CircuitName}`}</b></h5>
            </div>
            <div className="container">
              <Masonry
                id={"grid"}
                className={"admin cards"}
                disableImagesLoaded={false}
                updateOnEachImageLoad={false}
                enableResizableChildren={true}
              >
                <div className="card-holder">
                  <CircuitsGraph
                    GraphId={`chartA`}
                    GraphTitle={"Latency"}
                    graphData={this.state.rta}
                    type={this.state.rtaField}
                    defaultType={this.state.rtaField}
                    redirectView={this.redirectView}
                    updateGraphUrl={this.updateGraphUrl}
                    DateRange={this.state.rtaDateRange}
                    measurement={this.state.rtaMeasurement}
                    aggWindow={this.state.rtaAggWindow}
                    axisLabel={"Latency (ms)"}
                  />
                </div>
                <div className="card-holder">
                  <CircuitsGraph
                    GraphId={`chartB`}
                    GraphTitle={"Packet Loss"}
                    graphData={this.state.pl}
                    type={this.state.plField}
                    defaultType={this.state.plField}
                    redirectView={this.redirectView}
                    updateGraphUrl={this.updateGraphUrl}
                    DateRange={this.state.plDateRange}
                    measurement={this.state.plMeasurement}
                    aggWindow={this.state.plAggWindow}
                    axisLabel={"Packet Loss (%)"}
                  />
                </div>
                {/* {this.deviceGraphs()} */}
              </Masonry>
            </div>
          </div>
        );
    }
    //} else return <DataLoadingSpinner className='load spinner' />
  }
}