import React, { Component } from 'react';
import { DataLoadingSpinner } from '../../components/spinners';
import UploadOrderData from '../../containers/modals/uploadOrderModal';
import InfoPop from './../../components/infopop';
import ConfigureProductModal from './modals/configureProductModal';
import * as apiCalls from '../../requests/api_calls';
import * as settings from '../../scripts/settings';
import * as helper from '../../scripts/helper';
import * as macdHelper from './components/macd_helper'; 
import { Grid, GridColumn as Column, GridCell } from '@progress/kendo-react-grid';
import { orderBy } from '@progress/kendo-data-query';
import { filterBy } from '@progress/kendo-data-query';
import { Modal } from 'react-bootstrap';
import { Button } from '@progress/kendo-buttons-react-wrapper';
import _ from 'lodash';

//const paramsSeparator = (apiPath) => apiPath.indexOf('?') >= 0 ? '&' : '?';

class MacdAdd extends Component {

  initialState = (props) => {
    let orderId = 0;
    if (props && props.match && props.match.params) {
      if (props.match.params.OrderId)
        orderId = props.match.params.OrderId;
    }
    return {
      filter: { logic: "and", filters: [] },
      productSearchText: '',
      serviceUserFilter: props && props.location ? props.location.serviceUserFilter : null,
      useFilter: true,      
      OrderId: orderId,
      MPOLocationId: 0,
      sort: [{ field: "PartDescription", dir: "asc" }],
      skip: 0,
      gridHeight: (window.innerHeight - 400),
      loading: true,
      loadingAddedProducts: false,
      loadingPB: true,
      error: false,
      saving: false,
      defaultRCState: '',
      defaultRCName: '',      
      SelectedLocationId: "",
      SelectedLocationName: '',      
      isConfirmationDialogOpen: false,
      ConfirmationTitle: '',
      ConfirmationMessage: '',
      RemoveItemId: 0,
      isConfirmSaveDialogOpen: false,      
      defaultRCState: '',
      defaultRCName: '',
      resultsMsg: "",
      isChange: false,
      ReadOnly: false,
      creating: false,
      disabledUSOCs: require('./components/DisabledUSOCs.json'),
      searchResults: [],
      OrderDetails: [],//actual datadetails
      pricebookDetails: [],
      fullPricebookDetails: [],//used when the items are filtered to reference the full dataset
      DeletedOrderItems: [],
      DeletedConfigItems: [],
      //InventoryTNList: [],
      TerminatingNumbersList: [],
      IPTrunkGroupOptions: [],
      IPTrunkGroups: [],
      defaultRateCenterOptions: [],
      locations: JSON.parse(sessionStorage.locationList),
      locationOptions: [],
      rcStateOptions: [],
      CustomerId: 0
    }
  }

  constructor(props) {
    super(props);
    this.state = this.initialState(props);

    this.fetchPricebookDetails = this.fetchPricebookDetails.bind(this);
    this.setupLocations = this.setupLocations.bind(this);
    this.sortChange = this.sortChange.bind(this);
    this.handleRemoveItem = this.handleRemoveItem.bind(this);
    const handleAddProduct = this.handleAddProduct.bind(this);
    this.handleContinueClick = this.handleContinueClick.bind(this);

    this.handleCancelClick = this.handleCancelClick.bind(this);
    this.CancelOrderConfirmation = this.CancelOrderConfirmation.bind(this);
    this.gridContent = this.gridContent.bind(this);
    this.updateOrderItemFromModal = this.updateOrderItemFromModal.bind(this);
    this.confirmLocationChange = this.confirmLocationChange.bind(this);
    this.closeConfirmationDialog = this.closeConfirmationDialog.bind(this);
    this.SaveAddItemToOrder = this.SaveAddItemToOrder.bind(this);
    this.AddLineItemToState = this.AddLineItemToState.bind(this);
    this.LoadItemConfigs = this.LoadItemConfigs.bind(this);
    this.RetrieveSavedOrder = this.RetrieveSavedOrder.bind(this);
    this.AreTrunkGroupAssociationsValid = this.AreTrunkGroupAssociationsValid.bind(this);
    this.RemoveItemFromOrder = this.RemoveItemFromOrder.bind(this);
    this.RemoveTrunkGroups = this.RemoveTrunkGroups.bind(this);
    this.RemoveTrunkGroupsFromDB = this.RemoveTrunkGroupsFromDB.bind(this);

    class MyAddCell extends GridCell {
      constructor(props) {
        super(props);
      }
      render() {
        if (this.props.dataItem.ReadOnly) {
          return (
            <td></td>
          )
        } else if (this.props.dataItem.disabled) {
          return (
            <td>
              <span style={{ marginLeft: '40px' }}>
                <InfoPop message={this.props.dataItem.DisabledMessage} />
              </span>
            </td>
          )
        } else {
          return (
            <td>
              <a href="#" disabled={this.props.dataItem.adding ? this.props.dataItem.adding : false} onClick={() => handleAddProduct(this.props.dataItem)} className="btn">Add</a>
            </td>
          )
        }
      }
    }
    this.CommandCell = MyAddCell;
  };

  handleResize = async () => {
    await this.setState({
      gridHeight: (window.innerHeight - 400)
    });
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize)
  }

  componentDidMount() {
    if (macdHelper.CheckPermissionsForLinks(macdHelper.MACDTYPES.AddServices) === 0) {
      this.props.history.push('/macd');
    }
    this.fetchRcStateList();
    this.setupLocations();
    
    if (this.state.OrderId > 0) {
      this.RetrieveSavedOrder(this.state.OrderId);
    }
    this.handleResize();
    window.addEventListener('resize', this.handleResize);
  }

  RetrieveSavedOrder = async (OrderId, RefreshCatalog = true) => {
    try
    {
      await this.setState({ loading: RefreshCatalog, loadingSO: RefreshCatalog, loadingAddedProducts: RefreshCatalog, loadingPB: RefreshCatalog, ReadOnly: RefreshCatalog, saving: !RefreshCatalog });
      let StateOrderDetails = this.state.OrderDetails.slice();
      
      //await order information
      //order information is the Order itself & OrderItems w/enough details to be able to go from there
      let [OrderDetails, LocationDetails, LineItems, DetailItems, TrunkItems, AddressItems] = await macdHelper.RetrieveOrderDetails(OrderId);
      let momentumAcctNum = OrderDetails.MomentumAccountNumber;
      let locationId = LocationDetails[0].LocationId;
      let selectedLocation = this.state.locations.filter(res => res.LocationAccountNumber === momentumAcctNum)[0];
      let ReadOnlyStatus = OrderDetails.ProcessFlag.toUpperCase() !== macdHelper.MACDStatus.Open || OrderDetails.OrderSource !== 'MSP' ? true : false;
      await this.setState({
        MPOLocationId: locationId,
        SelectedLocationId: momentumAcctNum,
        CustomerId: selectedLocation.CustomerId
      });
      if (RefreshCatalog)
      {
        await this.fetchLocationDetails(TrunkItems, DetailItems);

        if (!TrunkItems || TrunkItems.length === 0)
          await this.fetchIPTrunks();

        await this.LoadItemConfigs();
      }
      let pricebookDetails = this.state.pricebookDetails;
      let [DetailItemValidations, LineItemErrors, TrunkGroupValidations] = await macdHelper.ItemValidations(this.state.OrderId, locationId);

      if (LineItems && LineItems.length > 0) {
        LineItems.map((LineItem, idx) => {
          //console.log(LineItem);
          let ConfigurationDetailArray = [];
          if (DetailItems) {
            ConfigurationDetailArray = _.filter(DetailItems, function (o) { return o.LineItemId == LineItem.LineItemId; });
          }

          let catalogItm = _.filter(pricebookDetails, function (o) { return o.CatalogItemId === LineItem.CatalogItemId; })[0];
          let PartNumber = LineItem.PartNumber;
          let PartDescription = LineItem.PartDescription;
          
          ConfigurationDetailArray.map((cda) => {
            let detailItemErrorArray = [];
            if (cda.ErrorMessage !== '')
            {
              cda.ErrorMessage = helper.StringReplaceAll(cda.ErrorMessage, '</br>','', false) + '\n';
              detailItemErrorArray.push(cda.ErrorMessage);
            }
            _.filter(DetailItemValidations, div => cda.DetailId === div.SourceId).map((detailItem) => {
                let msg = cda.ErrorMessage === '' ? detailItem.ErrorMessage : '\n' + detailItem.ErrorMessage;
                if (detailItemErrorArray.length === 0 || (detailItemErrorArray.length > 0 && _.indexOf(detailItemErrorArray, msg.replace('\n','')) < 0))
                {
                  cda.ErrorMessage += msg;
                  detailItemErrorArray.push(msg.replace('\n', ''));
                }
            });

            if (LineItem.PartNumber === 'IP100') {
              let trunkItem = TrunkItems.filter(resp => resp.DetailId === cda.DetailId);
              if (trunkItem && trunkItem.length > 0)
              {
                trunkItem = trunkItem[0];
                _.filter(TrunkGroupValidations, lie => trunkItem.IPTrunkConfigId === lie.SourceId).map((trunkError) => {
                  let msg = helper.StringReplaceAll(trunkError.ErrorMessage, '</br>', '', false) + '\n';
                  cda.ErrorMessage += msg;
                });
              }
            }

            if (cda.Temp911AddressId !== 0)
            {
              let AddressItem = AddressItems.filter(resp => resp.AddressId === cda.Temp911AddressId)[0];
              if (AddressItem.ErrorMessage !== '') {
                let msg = helper.StringReplaceAll(AddressItem.ErrorMessage, '</br>', '', false);
                cda.ErrorMessage += msg;
              }
            }

            if (cda.ErrorMessage && cda.ErrorMessage !== '')
              cda.ErrorMessage = helper.StringReplaceAll(cda.ErrorMessage, '</br>', '', false);
          });
          let lineItemErrorArray = [];
          if (LineItem.ErrorMessage !== '')
          {
            LineItem.ErrorMessage = helper.StringReplaceAll(LineItem.ErrorMessage, '</br>','', false) + '\n';
            lineItemErrorArray.push(LineItem.ErrorMessage);
          }
          
          
          _.filter(LineItemErrors, lie => LineItem.LineItemId === lie.SourceId).map((lineError) => {
            let msg = helper.StringReplaceAll(lineError.ErrorMessage, '</br>', '', false) + '\n';
            if (lineItemErrorArray.length === 0 || (lineItemErrorArray.length > 0 && _.indexOf(lineItemErrorArray, msg) < 0))
            {
              LineItem.ErrorMessage += msg;
              lineItemErrorArray.push(msg);
            }
          });

          let NewItem = macdHelper.LoadItem(
            LineItem,
            PartNumber,
            PartDescription,
            momentumAcctNum,
            this.state.SelectedLocationName, //not needed on this page
            this.state.MPOLocationId,
            this.state.TerminatingNumbersList,
            //this.state.InventoryTNList,
            this.state.DefaultRateCenterOptions,
            this.state.rcStateOptions,
            this.state.DefaultRCState,
            this.state.DefaultRCName,
            catalogItm,
            ReadOnlyStatus
            //this.state.ReadOnly
          )

          let existingTrunkGroups = this.state.IPTrunkGroups.slice();
          NewItem.configurationDetails = macdHelper.MapConfigDetails(LineItem.NewCount === 0 ? 1 : LineItem.NewCount, [], NewItem, 0, ConfigurationDetailArray, TrunkItems, existingTrunkGroups, AddressItems, false);
          //TODO: need to add address validations to list
          NewItem.isConfigured = macdHelper.configuredCheck(NewItem.configurationDetails, NewItem, DetailItemValidations, LineItemErrors, TrunkGroupValidations, AddressItems);
          StateOrderDetails.push(NewItem);
        });
      }
      await this.setState({
        OrderDetails: StateOrderDetails,
        originalOrderDetails: StateOrderDetails,
        loadingAddedProducts: false,
        loading: false,
        loadingSO: false,
        ReadOnly: ReadOnlyStatus,
        saving: false
      });

    } catch(ex) {
      this.setState({
        loadingAddedProducts: false,
        loading: false,
        loadingSO: false,
        loadingPB: false,
        saving: false
      });
    }
  }


  fetchRcStateList = () => {
    this.setState({ loading: true });
    let apiPath = macdHelper.apiGetRCStateList();
    apiCalls.fetchData(apiPath).then((data) => {
      this.setState({ loading: true });
      if (data !== null) {
        let rcStateOptions = data.map((state, index) => {
          return (macdHelper.rcStateOption(state, index));
        });
        this.setState({ rcStateOptions: rcStateOptions });

      } else if (data === null) {
        this.setState({ rcStateOptions: [] });
      };

    });
  }

  fetchLocationDetails = async (TrunkItems = null, Details = null) => {
    let requests = [
      //fetchInventoryTN(OrderId),
      this.fetchPricebookDetails(),
      this.fetchIPTrunks(TrunkItems, Details)
    ];
    return await Promise.all(requests).then(dataArray => dataArray);
  }

  fetchPricebookDetails = async () => {
    this.setState({ loadingPB: true });
    let apiPath = macdHelper.apiPricebookPathWithParams(this.state.SelectedLocationId);
    await apiCalls.fetchData(apiPath).then(data => {
      if (data !== null) {
        this.setState({
          loadingPB: false,
          fullPricebookDetails: this.revisedData(data),
          pricebookDetails: this.revisedData(data),
          resultsMsg: null
        });
      } else if (data === null) {
        this.setState({ pricebookDetails: [], loadingPB: false, resultsMsg: settings.noResultsMsg });
      };
    });

  }

  async handleAddProduct(dataItem) {
    if (this.state.creating) return; //do nothing while creating the order
    
    let fullCatalog = this.state.pricebookDetails.slice();
    let idxCatalog = _.indexOf(fullCatalog, dataItem);
    dataItem.adding = true;
    fullCatalog[idxCatalog] = dataItem;
    await this.setState({ saving: true, pricebookDetails: fullCatalog, creating: this.state.OrderId > 0 ? false : true });

    let existingProducts = this.state.OrderDetails.slice();
    let LineItem = existingProducts.filter(res => res.CatalogItemId === dataItem.CatalogItemId);
    if (LineItem && LineItem.length > 0) {
      LineItem = LineItem[0];
      LineItem.Quantity += 1;
      let idx = _.indexOf(existingProducts, LineItem);
      let dbLineItem = {
        OrderId: this.state.OrderId,
        LocationId: this.state.MPOLocationId,
        LineItemId: LineItem.LineItemId,
        CatalogItemId: dataItem.CatalogItemId,
        NewCount: LineItem.Quantity,
        NRCAdjustedPrice: dataItem.NRCAdjustedPrice ? dataItem.NRCAdjustedPrice : dataItem.ActivationCharge,
        NRCDiscountPercent: dataItem.NRCAdjustedPrice ? (1.00) - parseFloat(dataItem.NRCAdjustedPrice / dataItem.ActivationCharge) : 0.00,
        MRCAdjustedPrice: dataItem.MRCAdjustedPrice ? dataItem.MRCAdjustedPrice : dataItem.BasePrice,
        MRCDiscountPercent: dataItem.MRCAdjustedPrice ? (1.00) - parseFloat(dataItem.MRCAdjustedPrice - dataItem.BasePrice) : 0.00
      }
      await macdHelper.UpdateLineItemInOrder(dbLineItem);
      //Need to check if a noconfig item, if so then we have to save the detail item too
      let DetailId = 0
      let ColumnConfig = macdHelper.getDefaultColumnConfig();
      let className = 'product-configuration';
      [DetailId, ColumnConfig, className] = await macdHelper.ReviewAndSaveNoConfigItem(this.state.OrderId, this.state.MPOLocationId, LineItem.PartNum, LineItem.LineItemId);
      LineItem.ColumnConfig = ColumnConfig;
      LineItem.ConfigureClassName = className;
      LineItem.configurationDetails = macdHelper.MapConfigDetails(LineItem.Quantity, LineItem.configurationDetails, LineItem, DetailId, null, null, null, null, false);

      
      if (DetailId && DetailId > 0 && LineItem.ErrorMessage === '') {
        let bConfigured = true;
        LineItem.configurationDetails.map((cd, idx) => {
          if (cd.ErrorMessage && cd.ErrorMessage !== '')
            bConfigured = false;
        });
        LineItem.isConfigured = bConfigured;
      } else if (LineItem.ColumnConfig.ConfigType !== 'NOCONFIG') {
        LineItem.isConfigured = false;
      }

      existingProducts[idx] = LineItem;
      await this.setState({ OrderDetails: existingProducts, saving: false });
    } else {
      LineItem = {
        OrderId: this.state.OrderId,
        LocationId: this.state.MPOLocationId,
        LineItemId: 0,
        CatalogItemId: dataItem.CatalogItemId,
        NewCount: 1,
        NRCAdjustedPrice: dataItem.NRCAdjustedPrice ? dataItem.NRCAdjustedPrice : dataItem.ActivationCharge,
        NRCDiscountPercent: dataItem.NRCAdjustedPrice ? (1.00) - parseFloat(dataItem.NRCAdjustedPrice / dataItem.ActivationCharge) : 0.00,
        MRCAdjustedPrice: dataItem.MRCAdjustedPrice ? dataItem.MRCAdjustedPrice : dataItem.BasePrice,
        MRCDiscountPercent: dataItem.MRCAdjustedPrice ? (1.00) - parseFloat(dataItem.MRCAdjustedPrice - dataItem.BasePrice) : 0.00
      }
      await this.SaveAddItemToOrder(false, false, LineItem, dataItem.PartNum, dataItem.PartDescription);
    }

    fullCatalog[idxCatalog].adding = false;
    await this.setState({ pricebookDetails: fullCatalog });
  }

  handleRemoveItem(id) {
    let msg = 'Are you sure you wish to remove this item from your order?';
    let title = 'Remove Item from Order?';
    this.setState({ isConfirmationDialogOpen: true, ConfirmationTitle: title, ConfirmationMessage: msg, RemoveItemId: id });
  }

  RemoveItemFromOrder = (id) => {
    //if trying to remove an IP Trunk Group we need to test to make sure user hasn't configured another new product to one of these new trunk groups
    let currentDetails = this.state.OrderDetails;
    let LineItemRemoved = currentDetails.filter(resp => resp.LineItemId === id);
    if (LineItemRemoved && LineItemRemoved.length > 0 && LineItemRemoved[0].PartNum === 'IP100') {
      //let newTrunkGroups = this.state.IPTrunkGroups.slice().filter(resp => resp.IsNew !== true);
      // if (this.AreTrunkGroupAssociationsValid(newTrunkGroups)) {
      //   this.setState({ error: true, resultsMsg: 'There are currently items associated with the new trunk groups that are going to be removed.  Please update trunk group assignments before removing new trunk groups' });
      //   return;
      // }      
    }
    
    let verb = "PATCH";
    let reqBody = {
      OrderId: this.state.OrderId,
      LocationId: this.state.MPOLocationId,
      LineItemId: id
    };
    let data = JSON.stringify(reqBody);
    let apiPath = macdHelper.apiDiscardLineItem();
    this.setState({ saving: true });
    apiCalls.post(apiPath, verb, data).then(resp => {
      if (resp.ok) {
        let newTrunkGroups = this.state.IPTrunkGroups.slice();
        let newTrunkGroupOptions = this.state.IPTrunkGroupOptions.slice();
        let array = this.state.OrderDetails;
        var itmToRemove = array.find((element) => {
          return element.LineItemId === id;
        });
        var index = this.state.OrderDetails.indexOf(itmToRemove);
        array.splice(index, 1);

        let quantityRules = _.filter(macdHelper.QuantityValidationRules, resp => resp.PartNum === itmToRemove.PartNum);
                    
        if (itmToRemove.PartNum === 'IP100') {
          let removedTrunkGroups = newTrunkGroups.filter(resp => resp.IsNew === true);
          newTrunkGroups = newTrunkGroups.filter(resp => resp.IsNew !== true);
          newTrunkGroupOptions = macdHelper.getIPTrunkGroupOptions(newTrunkGroups);
          removedTrunkGroups.map((rd,idx) => {
            if (rd.IPTrunkGroupID !== 0)
              this.RemoveTrunkGroupsFromDB(rd);
          });
          
        }
        this.setState({ OrderDetails: array, error: false, saving: false, RemoveItemId: 0, isConfirmationDialogOpen: false, IPTrunkGroups: newTrunkGroups, IPTrunkGroupOptions: newTrunkGroupOptions });
        if (quantityRules !== null && quantityRules.length > 0)
        {
          //if removing a product where quantity validation is performed, we need to mimic the save updateItemFromModal task and update those items and refresh
          let otherUSOCs = quantityRules[0].DependencyList;
          let dependentOrderItems = _.filter(array, resp => _.indexOf(otherUSOCs, resp.PartNum) > -1);
          //Get all other call recording products and validate those
          if (dependentOrderItems !== null && dependentOrderItems.length > 0)
            this.ReviewAndRefreshQuantityDependencies(dependentOrderItems);
        }
      }
      else {
        this.setState({ error: true, saving: false, errorMsg: resp.message, RemoveItemId: 0, isConfirmationDialogOpen: false });
      }
    }).catch(message => {
      this.setState({ error: true, saving: false, errorMsg: message, RemoveItemId: 0, isConfirmationDialogOpen: false });
    });
  }

  handleContinueClick(e) {
    let items = this.state.OrderDetails;
    var data = items.filter(item => item.isConfigured === false);
    if (data.length > 0)
      this.setState({ error: true, resultsMsg: 'Not all of the selected products are configured.  Please configure to Continue!' });
    else if (this.state.OrderId > 0 && this.state.isChange === false) {
      //go to the next page passing in the order id
      this.props.history.push(macdHelper.ReviewPageUrl(this.state.OrderId));
    }
  }

  AddedProducts() {
    if (this.state.saving === true) {
      return (
        <DataLoadingSpinner className='load spinner relative' />
      );
    } else {
      let arrayPC = [];
      this.state.OrderDetails.map((OrderItem) => {
        let productOptions = this.productCard(OrderItem, arrayPC.length);
        arrayPC.push(productOptions);
      });
      return arrayPC;
    }
  }

  productCard = (product, idx) => {
    if (this.state.ReadOnly === true) {
      return (
        <div className="added-product" key={idx}>
          <div className="added-product-content">
            <h3>{product.PartDescription} <small>{product.PartNum}</small></h3>
            <div className="form-group">
              <b>Quantity:</b> {product.Quantity}
            </div>
            <div className="actions">
              <ConfigureProductModal 
                OrderItem={this.state.OrderDetails[idx]} 
                OrderItemIdx={idx} 
                updateParentArrayItem={this.updateOrderItemFromModal}
                ReadOnly={true} 
                ColumnConfig={this.state.OrderDetails[idx].ColumnConfig} 
                IPTrunkGroups={this.state.IPTrunkGroups} 
                IPTrunkGroupOptions={this.state.IPTrunkGroupOptions}
                ConfigureClassName={product.ConfigureClassName}
              />
            </div>
          </div>
        </div>
      )
    } else {
      //CallPathCustomerProductId
      let ipTrunks = this.state.IPTrunkGroups;
      let newIPTrunks = ipTrunks.slice();
      
      if (product.PartNum === 'IP101' || product.PartNum === 'IP102' || product.PartNum === 'IP103') {
        newIPTrunks = []; //clearing b/c you can't set a call path on an IP Trunk that already has one
        let configDetailIds = [];
        product.configurationDetails.map((cd) => {
          if (cd.ParentDetailId && parseInt(cd.ParentDetailId) > 0)
            configDetailIds.push(cd.DetailId);
          else if (cd.IPTrunkGroupCustomerProductId && parseInt(cd.IPTrunkGroupCustomerProductId) > 0)
            configDetailIds.push(cd.DetailId);
        });
        //filtering out IP Trunks that have been associated with other call plan types in the order from the modal.  
        //Also filtering out previously setup IP Trunks whose IP Trunk already has a call path plan.
        ipTrunks.map((ipt) => {
          if (!ipt.CallPathId || ipt.CallPathId === 0)
            newIPTrunks.push(ipt);
          else if (_.indexOf(configDetailIds, ipt.CallPathId) > -1 && ipt.IsNew === true)
            newIPTrunks.push(ipt);
        });
      }
      let ipTrunkOptions = macdHelper.getIPTrunkGroupOptions(newIPTrunks);
      return (
        <div className="added-product" key={idx}>
          <div className="added-product-content">
            <h3>{product.PartDescription} <small>{product.PartNum}</small></h3>
            <div className="form-group">
              <b>Quantity:</b> {product.Quantity}
            </div>
            <a href="#remove" onClick={() => { this.handleRemoveItem(product.LineItemId) }} className="remove">Remove</a>
            <div className="actions">
              <ConfigureProductModal 
                ProductName={product.PartDescription} 
                OrderItem={this.state.OrderDetails[idx]} 
                OrderItemIdx={idx} 
                updateParentArrayItem={this.updateOrderItemFromModal}
                RemoveItemFromOrder={this.RemoveItemFromOrder} 
                ColumnConfig={this.state.OrderDetails[idx].ColumnConfig} 
                IPTrunkGroups={newIPTrunks} 
                IPTrunkGroupOptions={ipTrunkOptions}
                UpdateTrunkGroups={this.UpdateTrunkGroups} 
                RemoveNewTrunkGroup={this.RemoveTrunkGroups}
                CustomerId={this.state.CustomerId}
                ConfigureClassName={product.ConfigureClassName}
              />
            </div>
          </div>
        </div>
      )
    }
  }

  updateOrderItemFromModal = async (ProductItem, newConfigDetails, arrayIdx, IPTrunkGroups) => {
    let OrderDetails = this.state.OrderDetails;
    let newTrunkGroups = this.state.IPTrunkGroups.slice();
    let newTrunkGroupOptions = this.state.IPTrunkGroupOptions.slice();
    if (IPTrunkGroups) {
      if (ProductItem.PartNum === 'IP101' || ProductItem.PartNum === 'IP102' || ProductItem.PartNum === 'IP103')
      {
          let ipTrunks = this.state.IPTrunkGroups.slice();
          newConfigDetails.map((cd) => {
            //remove the old association
            let idx = -1;
            let updatedTrunk = ipTrunks.filter(resp => resp.CallPathId === cd.DetailId);
            if (updatedTrunk && updatedTrunk.length > 0) { 
              updatedTrunk = updatedTrunk[0];
              idx = _.indexOf(ipTrunks, updatedTrunk);
              if (cd.ParentDetailId !== updatedTrunk.ConcatId && idx > -1)
                ipTrunks[idx].CallPathId = 0;
            }
            //update new association
            let newTrunk = ipTrunks.filter(resp => parseInt(resp.ConcatId) === parseInt(cd.ParentDetailId) || parseInt(resp.ConcatId) === parseInt(cd.IPTrunkGroupCustomerProductId));
            if (newTrunk && newTrunk.length > 0) {
              newTrunk = newTrunk[0];
              idx = _.indexOf(ipTrunks, newTrunk);
              if (cd.ParentDetailId === newTrunk.ConcatId && idx > -1)
                ipTrunks[idx].CallPathId = cd.DetailId;
            }
          });
        this.setState({IPTrunkGroups: ipTrunks});
      } else {
        newTrunkGroupOptions = macdHelper.getIPTrunkGroupOptions(IPTrunkGroups);    
        newTrunkGroups = IPTrunkGroups;
      }
    }

    //Quantity Validations
    if (ProductItem.Quantity != newConfigDetails.length)
    {
      let dependencyRules = _.filter(macdHelper.QuantityValidationRules, resp => resp.PartNum === ProductItem.PartNum);
      if (dependencyRules !== null && dependencyRules.length === 1)
      {
        
        let otherUSOCs = dependencyRules[0].DependencyList;
        let dependentOrderItems = _.filter(OrderDetails, resp => _.indexOf(otherUSOCs, resp.PartNum) > -1);
        //Get all other call recording products and validate those
        if (dependentOrderItems !== null && dependentOrderItems.length > 0) {
          await this.ReviewAndRefreshQuantityDependencies(dependentOrderItems);
          return;
        }
      }
    }

    try {
      var result = newConfigDetails.filter(obj => {
        return obj.DetailId === 0
      });
      //if any item in config details has a DetailId = 0 then its not configured
      if (result && result.length > 0) {
        ProductItem.isConfigured = false;
      }
    } catch (msg) {
      console.log(msg);
    }    
    if (arrayIdx === -1 && ProductItem.LineItemId !== 0)
    {
      let existingLineItem = OrderDetails.filter(resp => resp.LineItemId === ProductItem.LineItemId)[0]
      arrayIdx = _.indexOf(OrderDetails, existingLineItem);
    }

    OrderDetails[arrayIdx].configurationDetails = newConfigDetails;
    OrderDetails[arrayIdx].Quantity = newConfigDetails.length;
    OrderDetails[arrayIdx].isConfigured = ProductItem.isConfigured;

    let bShowError = false;
    let errorMsg = '';
    if (this.state.error) {
      OrderDetails.map((itm, idx) => {
        if (itm.isConfigured === false) {
          bShowError = true;
          errorMsg = 'Not all of the selected products are configured.  Please configure to Continue!';
        }
      });
    }

    this.setState({ OrderDetails: OrderDetails, IPTrunkGroups: newTrunkGroups, IPTrunkGroupOptions: newTrunkGroupOptions, error: bShowError, resultsMsg: errorMsg });
  }

  UpdateTrunkGroups = async(TrunkGroupDetails, DetailId, IsAdd) => {
    let existingTrunks = this.state.IPTrunkGroups.slice();
    if (IsAdd)
    {
      let newTrunkEntry = { 
        IsNew: true, 
        ConcatId: DetailId, 
        CustomerProductId: 0, 
        DetailId: DetailId, 
        IPTrunkGroupID: TrunkGroupDetails.IPTrunkConfigId, 
        LineDescription: TrunkGroupDetails.TrunkDescription,
        CallPathId: 0
      };

      existingTrunks.push(newTrunkEntry);
    } else {
      let updatedTrunk = existingTrunks.filter(resp => resp.DetailId === DetailId)[0];
      let idx = _.indexOf(existingTrunks, updatedTrunk);
      existingTrunks[idx].LineDescription = TrunkGroupDetails.TrunkDescription
    }
    let sort = [{ field: "LineDescription", dir: "asc" }]
    let sortedSet = orderBy(existingTrunks, sort);
    let trunkOptions = macdHelper.getIPTrunkGroupOptions(sortedSet);
    this.setState({IPTrunkGroupOptions: trunkOptions, IPTrunkGroups: sortedSet});
  }

  RemoveTrunkGroups = async(DetailId) => {
    let existingTrunks = this.state.IPTrunkGroups.slice();
    let trunk = existingTrunks.filter(resp => resp.DetailId === DetailId);
    trunk = trunk[0];
    this.RemoveTrunkGroupsFromDB(trunk);
    existingTrunks = existingTrunks.filter(resp => resp.DetailId !== DetailId);

    let LineItems = this.state.OrderDetails;
    LineItems.map((li, idx) => {
      let configDetails = li.configurationDetails.slice();
      configDetails.map((cd, idxCD) => {
        if (cd.ParentDetailId === DetailId) {
          cd.ParentDetailId = 0;
          cd.IPTrunkGroupCustomerProductId = 0;
          cd.TrunkId = 0;
          configDetails[idxCD] = cd;
        }
      });
      LineItems[idx].configurationDetails = configDetails;
    });

    let sort = [{ field: "LineDescription", dir: "asc" }]
    let sortedSet = orderBy(existingTrunks, sort);
    let trunkOptions = macdHelper.getIPTrunkGroupOptions(sortedSet);
    this.setState({IPTrunkGroupOptions: trunkOptions, IPTrunkGroups: sortedSet, OrderDetails: LineItems});
  }

  RemoveTrunkGroupsFromDB = async(trunk) => {
    let apiPath = macdHelper.apiDiscardTrunkItem();
    let reqBody = {
      OrderId: this.state.OrderId,
      LocationId: this.state.MPOLocationId,
      DetailId: trunk.DetailId,
      IPTrunkConfigId: trunk.IPTrunkGroupID
    }
    apiCalls.post(apiPath, 'PATCH', JSON.stringify(reqBody)).then((data) => data);//.error((message) => console.error(message));
  }

  AreTrunkGroupAssociationsValid = async(IPTrunkGroups, OrderDetails = this.state.OrderDetails) => {
    // for (var i = 0; i < OrderDetails.length; i++) {
    //   for (var j = 0; j < OrderDetails[i].configurationDetails.length; j++) {
    //     if (OrderDetails[i].configurationDetails[j].IPTrunkConfigId !== 0) {
    //       let TrunkConfigId = OrderDetails[i].configurationDetails[j].IPTrunkConfigId;
    //       let test = IPTrunkGroups.filter(resp => resp.IsNew === true && resp.IPTrunkConfigId === OrderDetails[i].configurationDetails[j].IPTrunkConfigId)
    //       // if (test && test.length > 1)
    //       //   return false;
    //     }
    //   }
    // }
    return true;
  }

  ReviewAndRefreshQuantityDependencies = async (dependentOrderItems) =>
  {
    //dependentOrderItems.map(async (od) => {
      this.setState({saving: true});
      let pricebook = this.state.pricebookDetails;
      for (let i = 0; i < dependentOrderItems.length; i++)
      {
        let pricebookItem = _.filter(pricebook, resp => resp.CatalogItemId === dependentOrderItems[i].CatalogItemId);
        if (pricebookItem && pricebookItem.length > 0)
          pricebookItem = pricebookItem[0];
        else          
          continue; 
        
        let apiPath = macdHelper.apiUpdateLineItem();
        let dbLineItem = {
          OrderId: this.state.OrderId,
          LocationId: this.state.MPOLocationId,
          LineItemId: dependentOrderItems[i].LineItemId,
          CatalogItemId: dependentOrderItems[i].CatalogItemId,
          NewCount: dependentOrderItems.Quantity,
          NRCAdjustedPrice: pricebookItem.NRCAdjustedPrice ? pricebookItem.NRCAdjustedPrice : pricebookItem.ActivationCharge,
          NRCDiscountPercent: pricebookItem.NRCAdjustedPrice ? (1.00) - parseFloat(pricebookItem.NRCAdjustedPrice / pricebookItem.ActivationCharge) : 0.00,
          MRCAdjustedPrice: pricebookItem.MRCAdjustedPrice ? pricebookItem.MRCAdjustedPrice : pricebookItem.BasePrice,
          MRCDiscountPercent: pricebookItem.MRCAdjustedPrice ? (1.00) - parseFloat(pricebookItem.MRCAdjustedPrice - pricebookItem.BasePrice) : 0.00,
          UpdateNeeded: true //no values are changing in the dependent order item so this is used to force an update
        }

        let reqBody = JSON.stringify(dbLineItem);
        let responseMessage = await apiCalls.post(apiPath, 'PATCH', reqBody).then(resp => resp)
          .catch(message => {
            // this.setState({
            //   saving: false,
            //   error: true,
            //   loading: false,
            //   resultsMsg: 'An error occurred. Please check the Open Requests tab and reload your Order!'
            // });
          });
      //});
      }
      await this.setState({ OrderDetails: [], saving: false });
      //need to save those order items
      this.RetrieveSavedOrder(this.state.OrderId, false);
  }


  handleCancelClick = (e) => {
    let msg = 'Are you sure you wish to discard the order?';
    let title = 'Cancel Order?';
    this.setState({ isConfirmationDialogOpen: true, ConfirmationMessage: msg, ConfirmationTitle: title });
  }

  CancelOrderConfirmation = () => {
    if (this.state.OrderId > 0) {
      this.setState({
        saving: true,
        error: false
      });
      let apiPath = macdHelper.apiDiscardOrder();
      apiCalls.post(apiPath, 'PATCH', JSON.stringify(this.state.OrderId)).then(resp => {
        if (resp.ok) {
          this.setState({
            saving: false,
            error: false,
            OrderId: 0,
            OrderDetails: [],
            isConfirmationDialogOpen: false,
            ConfirmationMessage: '',
            ConfirmationTitle: ''
          });
        }
      }).catch(message => {
        console.log(message)
        this.setState({
          saving: false,
          error: true,
          resultsMsg: 'An error occurred.  Your order was not discarded! Refresh the page to review old details.',
          isConfirmationDialogOpen: false,
          ConfirmationMessage: '',
          ConfirmationTitle: ''
        });
      });
    } else {
      this.setState({ OrderDetails: this.state.originalOrderDetails });
    }
  }

  SaveAddItemToOrder = async (IsContinue, Redirect, LineItem, PartNum = '') => {
    let bCreate = this.state.OrderId === 0
    await this.setState({ saving: true });
    let apiPath = bCreate ? macdHelper.apiAddServicesOrderWithLineItem() : macdHelper.apiAddServicesSaveLineItem();
    let verb = 'POST';
    let reqBody = {};
    let reqLineItem = {};
    if (bCreate) {
      reqLineItem = {
        CatalogItemId: LineItem.CatalogItemId,
        NRCAdjustedPrice: LineItem.NRCAdjustedPrice,
        NRCDiscountPercent: LineItem.NRCDiscountPercent,
        MRCAdjustedPrice: LineItem.MRCAdjustedPrice,
        MRCDiscountPercent: LineItem.MRCDiscountPercent,
        NewCount: LineItem.NewCount
      };
      reqBody = {
        MacdType: 'ADD_SERVICES',
        MomentumAccountNumber: this.state.SelectedLocationId,
        LineItemSaveData: reqLineItem
      };
    } else {
      reqLineItem = LineItem;
      reqBody = LineItem;
    }
    let data = JSON.stringify(reqBody);


    let message = await apiCalls.post(apiPath, verb, data).then(resp => resp)
      .catch(message => {
        this.setState({
          saving: false,
          error: true,
          loading: false,
          resultsMsg: 'An error occurred. Please check the Open Requests tab and reload your Order!'
        });
      });
    if (message.ok) {
      let messageBody = JSON.parse(message.message);

      if (IsContinue || Redirect) {
        if (IsContinue) {
          //go to the next page passing in the order id
          let uri = '/macd_review?oid=' + NewOrderId;
          window.location.replace(uri);
          return;
        } else
          window.location.replace('/macd');
        return;
      }
      let OrderDetails = {};//messageBody.Order;
      let NewOrderId = this.state.OrderId ? this.state.OrderId : OrderDetails.OrderId;
      let LineItem = {};//messageBody.LineItem;
      let setConfigs = this.state.OrderId === 0 ? true : false;

      if (setConfigs) {
        let LocationDetails = messageBody.Location;
        OrderDetails = messageBody.Order;
        LineItem = messageBody.LineItem;
        await this.setState({ OrderId: OrderDetails.OrderId, MPOLocationId: LocationDetails.LocationId });
      } else {
        LineItem = messageBody;
      }
      let DetailId = 0
      let ColumnConfig = [];
      let className = 'product-configuration';
      [DetailId, ColumnConfig, className] = await macdHelper.ReviewAndSaveNoConfigItem(this.state.OrderId, this.state.MPOLocationId, PartNum, LineItem.LineItemId);
      LineItem.ColumnConfig = ColumnConfig; 
      LineItem.ConfigureClassName = className;

      
      this.AddLineItemToState(setConfigs, LineItem, DetailId);
      //If we're adding a product with quantity validation, need to make sure other order item validations are updated
      if (!bCreate)
      {
        let quantityRules = _.filter(macdHelper.QuantityValidationRules, resp => resp.PartNum === PartNum );
        if (quantityRules && quantityRules.length > 0)
        {
          //if removing a product where quantity validation is performed, we need to mimic the save updateItemFromModal task and update those items and refresh
          let otherUSOCs = quantityRules[0].DependencyList;
          let dependentOrderItems = _.filter(this.state.OrderDetails, resp => _.indexOf(otherUSOCs, resp.PartNum) > -1);
          //Get all other call recording products and validate those
          if (dependentOrderItems !== null && dependentOrderItems.length > 0)
            this.ReviewAndRefreshQuantityDependencies(dependentOrderItems);
        }
      }
      

    } else {
      this.setState({
        saving: false,
        error: true,
        tempLocationId: "",
        tempLocationName: '',
        resultsMsg: message.message,
        creating: false
      });
    }
  }

  LoadItemConfigs = async () => {
    let [RateCenterDetails, TerminatingNumbers, LocationDetails] = await macdHelper.fetchItemConfig(this.state.OrderId, this.state.MPOLocationId);

    let serviceAddress = LocationDetails && LocationDetails.ServiceAddress ? LocationDetails.ServiceAddress : '';
    let revisedTerminatingNumbers = [];
    if (TerminatingNumbers && TerminatingNumbers.length > 0) {
      revisedTerminatingNumbers = TerminatingNumbers.map((tn) => {
        return tn.ServiceNumber;
      });
    }

    let defaultRCOptions = await macdHelper.fetchRateCenterOptions(serviceAddress.AdrState);
    let rcNameOptions = [];
    if (defaultRCOptions) {
      rcNameOptions = defaultRCOptions.map((rc) => {
        return (macdHelper.rcNameOption(rc));
      });
    }

    this.setState({
      //InventoryTNList: InventoryTN ? InventoryTN : [],
      TerminatingNumbersList: revisedTerminatingNumbers,
      DefaultRCState: serviceAddress.AdrState,
      DefaultRCName: RateCenterDetails && RateCenterDetails.length > 0 ? RateCenterDetails[0].RCNameAbbr : "",
      DefaultRateCenterOptions: rcNameOptions
    });
  }

  AddLineItemToState = async (refreshDefaults, LineItem, DetailId) => {
    if (refreshDefaults) {
      await this.LoadItemConfigs();
    }
    if (LineItem) {
      let OrderDetailArray = this.state.OrderDetails.slice();
      let pricebookDetails = this.state.fullPricebookDetails;

      let catalogItm = pricebookDetails.filter(function (o) { return o.CatalogItemId === LineItem.CatalogItemId })[0];
      let PartNumber = catalogItm.PartNum;
      let PartDescription = catalogItm.PartDescription;

      let NewItem = macdHelper.LoadItem(
        LineItem,
        PartNumber,
        PartDescription,
        this.state.SelectedLocationId,
        this.state.SelectedLocationName,
        this.state.MPOLocationId,
        this.state.TerminatingNumbersList,
        //this.state.InventoryTNList,
        this.state.DefaultRateCenterOptions,
        this.state.rcStateOptions,
        this.state.DefaultRCState,
        this.state.DefaultRCName,
        catalogItm
      );
      if (LineItem.ColumnConfig)
        NewItem.ColumnConfig = LineItem.ColumnConfig;
      if (LineItem.ConfigureClassName)
        NewItem.ConfigureClassName = LineItem.ConfigureClassName;

      NewItem.configurationDetails = macdHelper.MapConfigDetails(LineItem.NewCount, NewItem.configurationDetails, NewItem, DetailId);
      if (DetailId && DetailId > 0 && NewItem.ErrorMessage === '') {
        let bConfigured = true;
        NewItem.configurationDetails.map((cd, idx) => {
          if (cd.ErrorMessage && cd.ErrorMessage !== '')
            bConfigured = false;
          if (PartNumber === 'IP100') {
            
            bConfigured = (cd.IPTrunkConfigId === '' || cd.IPTrunkConfigId == 0 || (cd.ErrorMessage && cd.ErrorMessage !== '')) ? false : true;
          }
        });
        NewItem.isConfigured = bConfigured;
      }
      OrderDetailArray.push(NewItem);      
      this.setState({ 
        saving: false, 
        isChange: false, 
        error: false, 
        resultsMsg: "", 
        OrderDetails: OrderDetailArray,
        creating: false 
      });  
    }
  }

  showCancelAndContinue = () => {
    if (this.state.saving) {
      return <DataLoadingSpinner className='load spinner' />
    }
    else if (this.state.OrderDetails.length > 0)
      return (
        <div className="btns">
          <a onClick={() => { this.handleContinueClick() }} className="btn">Continue</a>
          <a href="#macd" className="btn" onClick={() => { this.handleCancelClick() }} style={{ visibility: this.state.ReadOnly === true ? 'hidden' : 'visible' }}>Cancel</a>
        </div>
      )
  }

  sortChange(event) {
    this.setState({
      pricebookDetails: this.sortPricebookDetails(event.sort),
      sort: event.sort
    });
  }

  sortPricebookDetails(sort, pricebookDetails = null) {
    if (pricebookDetails == null) pricebookDetails = this.state.pricebookDetails.slice();
    let sortedSet = orderBy(pricebookDetails, sort);
    if (sort.length && sort[0].field !== 'BasePrice' && sort[0].field !== 'ActivationCharge') {
      return helper.blanksAfterZ(sortedSet, sort);
    } else {
      return sortedSet;
    }
  }

  revisedData = (data) => {
    let revisedData = [];
    let removedUSOCs = this.state.disabledUSOCs;
    data.map((sd) => {
      let disabledUSOCEntry = _.filter(removedUSOCs, resp => resp.USOC === sd.PartNum && resp.ProductSetTypeName === sd.ProductSetTypeName);
      if (!(disabledUSOCEntry.length > 0 && disabledUSOCEntry[0].ShowListing === false)) {
        if (sd.MSP_Visible === 0) {
            sd.adding = false;
            sd.disabled = true;
            sd.DisabledMessage = "This product requires additional manual configuration. Please contact support to order this item.";
        } 
        revisedData.push(sd);
      }
      
    });
    let sortedData = this.sortPricebookDetails(this.state.sort, revisedData)
    return sortedData;
  }

  getSearchResults = (event) => {
    let searchText = '';
    let fullDetails = this.state.fullPricebookDetails;
    if (event && event.target && event.target.value && event.target.value.trim() !== '') {
      searchText = event.target.value;
      let products = filterBy(fullDetails, {
        logic: 'or',
        filters: [
          { field: 'PartDescription', operator: 'contains', value: searchText, ignoreCase: true },
          { field: 'PartNum', operator: 'contains', value: searchText, ignoreCase: true }
        ]
      });

      this.setState({ pricebookDetails: products })
    }
    else {
      this.setState({ pricebookDetails: fullDetails });
    }
  }

  gridContent = () => {
    // if (sessionStorage.IsEmulatingUser) {
    //   return (
    //     <Grid
    //       style={{ height: this.state.gridHeight + 'px' }}
    //       data={this.state.pricebookDetails}
    //       sortable={{ allowUnsort: true, mode: 'single' }}
    //       sort={this.state.sort}
    //       onSortChange={this.sortChange}
    //     >
    //       <Column width="200px" field='PartNum' title='Part Num' />
    //       <Column field='PartDescription' width="500px" title='Product Description' />
    //       <Column width="250px" field='BasePrice' format="{0:c2}" title='Monthly' />
    //       <Column width='250px' field='ActivationCharge' format="{0:c2}" title='One-Time' />
    //       {/* <Column width="110px" field='ContractedMRC' title='MRC'
    //         cell={(props) => (
    //           <td><input type='text' /></td>
    //         )}
    //       />
    //       <Column width='110px' field='ActivationCharge' title='NRC'
    //         cell={(props) => (
    //           <td><input type='text' /></td>
    //         )} /> */}
    //       <Column width="150px" cell={this.CommandCell} />
    //     </Grid>
    //   )
    // } else {
    return (
      <Grid
        style={{ height: this.state.gridHeight + 'px' }}
        data={this.state.pricebookDetails}
        sortable={{ allowUnsort: true, mode: 'single' }}
        sort={this.state.sort}
        onSortChange={this.sortChange}
      >
        {/* <Column width="0px" field="CatalogItemId" title="CatalogItemId" /> */}
        <Column width="250px" field='PartNum' title='Part Num' />
        <Column field='PartDescription' width="600px" title='Product Description' />
        <Column width="250px" field='BasePrice' format="{0:c2}" title='Monthly' />
        <Column width='250px' field='ActivationCharge' format="{0:c2}" title='One-Time' />
        <Column width={this.state.ReadOnly === true ? "0px" : "200px"} cell={this.CommandCell} />
        <Column field="spacer" title=" " sortable={false} filterable={false} />
      </Grid>

    );
    //}
  }

  setupLocations = async (apiPath = null) => {
    let data = this.state.locations;
    if (data !== null) {
      let locationOptions = data.map((location, index) => {
        return (this.locationOption(location, index));
      });
      let defaultOption = (<option key={-1} value={"0"} >
        Select Location
      </option>);
      locationOptions.unshift(defaultOption);

      if (this.state.OrderId === 0) {
        if (!sessionStorage.LocNum) {
          await this.setState({
            loading: false,
            loadingPB: false,
            loadingAddedProducts: false,
            loadingSO: false,
            locations: data,
            locationOptions: locationOptions,
            CustomerId: 0,
            SelectedLocationId: "0",//data[0].LocationAccountNumber,
            SelectedLocationName: 'Select Location' //data[0].LocationName
          })
        } else {
          let selectedLocation = data.filter(res => res.LocationAccountNumber === sessionStorage.LocNum)[0];
          await this.setState({
              loadingSO: false,
              loadingAddedProducts: false,
              locations: data,
              locationOptions: locationOptions,
              CustomerId: selectedLocation.CustomerId,
              SelectedLocationId: sessionStorage.LocNum,
              SelectedLocationName: selectedLocation.LocationName,
              loading: true,
              loadingPB: true
          });
          await this.fetchLocationDetails();
          this.setState({loading: false, loadingPB: false});
        }
      } else {
        this.setState({ locationOptions: locationOptions, locations: data });
      }
    } else if (data === null) {
      this.setState({ locations: [], resultsMsg: settings.noResultsMsg });
    };
  }

  locationOption = (location, index) => {
    return (
      <option key={index} value={location.LocationAccountNumber} >
        {location.LocationName}
      </option>
    )
  }

  confirmLocationChange = async (e) => {
    let index = e.nativeEvent.target.selectedIndex;
    let selectedText = e.nativeEvent.target[index].text
    if (this.state.OrderId > 0) {
      let msg = 'Changing location will start a new order. Do you wish to continue?'
      let title = 'Change Location?';

      await this.setState({
        isConfirmationDialogOpen: true,
        ConfirmationTitle: title,
        ConfirmationMessage: msg,
        ConfirmationYesAction: this.changeLocation,
        tempLocationId: e.target.value,
        tempLocationName: selectedText
      });
    } else {
        if (parseInt(e.target.value) !== 0)
        {
            let location = this.state.locations.filter(resp => resp.LocationAccountNumber === e.target.value)[0];
            await this.setState({ SelectedLocationId: e.target.value, LocationName: selectedText, loadingPB: true, CustomerId: location.CustomerId });
            await this.fetchLocationDetails();
            await this.setState({loadingPB: false, loading: false});
        } else {
          this.setState({
            loadingAddedProducts: false,
            loadingPB: false, 
            loadingSO: false,
            CustomerId: 0, 
            SelectedLocationId: "0", 
            SelectedLocationName: 'Select Location', 
            fullPricebookDetails: [], 
            pricebookDetails: [], 
            resultsMsg: null
          });
        }
    }
  }

  closeConfirmationDialog = (e) => {
    this.setState({ isConfirmationDialogOpen: false });
  }

  changeLocation = async (e) => {
    this.state.OrderId = 0;
    this.state.OrderDetails = [];
    this.state.originalOrderDetails = [];
    
    let location = this.state.locations.filter(resp => resp.LocationId === this.state.tempLocationId)[0];
    await this.setState({loading: true, loadingPB: true});
    //let [pricebookDetails] = await this.fetchLocationDetails();

    await this.setState({
      tempLocationId: '',
      tempLocationName: '',
      CustomerId: location ? location.CustomerId : 0,
      SelectedLocationId: this.state.tempLocationId,
      SelectedLocationName: this.state.tempLocationName,
      OrderId: 0,
      OrderDetails: [],
      isConfirmationDialogOpen: false,
      ConfirmationMessage: '',
      ConfirmationTitle: '',
      loading: false,
      loadingPB: false
    });
    if (location)
      this.fetchIPTrunks();
  }
  ConfirmationYesAction = (e) => {
    if (this.state.ConfirmationTitle === 'Remove Item from Order?') {
      this.RemoveItemFromOrder(this.state.RemoveItemId);
    } else if (this.state.ConfirmationTitle === 'Change Location?') {
      this.changeLocation();
    } else
      this.CancelOrderConfirmation();

  }

  fetchIPTrunks = async (TrunkItems = null, Details = null) => {
    let trunks = [];
    let apiPath = macdHelper.apiRetrieveIPTrunks(this.state.SelectedLocationId);
    let data = await apiCalls.fetchData(apiPath).then((data) => data);
    if (data !== null) {
      data.map((record, idx) => {
        let existingTrunkEntry = {};

        existingTrunkEntry = { 
          IsNew: false, 
          ConcatId: record.CustomerProductId, 
          CustomerProductId: record.CustomerProductId, 
          DetailId: null, 
          IPTrunkGroupID: record.IPTrunkGroupID, 
          LineDescription: record.LineDescription,
          CallPathId: record.CallPathCustomerProductId
        };
        trunks.push(existingTrunkEntry); 
      });

      if (TrunkItems) {
        TrunkItems.map((record, idx) => {
          let newTrunkEntry = {};
          let trunkGroupDetailRecord = Details.filter(resp => resp.DetailId === record.DetailId)[0];
          if (trunkGroupDetailRecord)
          {
            let callPath = Details.filter(resp => (resp.PartNumber === 'IP101' || resp.PartNumber === 'IP102' || resp.PartNumber === 'IP103') && resp.ParentDetailId === trunkGroupDetailRecord.DetailId);
            if (callPath)
              callPath = callPath[0];
            newTrunkEntry = { 
              IsNew: true, 
              ConcatId: record.DetailId, 
              CustomerProductId: null, 
              DetailId: record.DetailId, 
              IPTrunkGroupID: record.IPTrunkGroupID, 
              LineDescription: record.Description,
              CallPathId: callPath ? callPath.DetailId : 0
            };
            trunks.push(newTrunkEntry); 
          }
        });
      }
      
      let sort = [{ field: "LineDescription", dir: "asc" }]
      let sortedSet = orderBy(trunks, sort);
      let trunkOptions = macdHelper.getIPTrunkGroupOptions(sortedSet);
      await this.setState({IPTrunkGroupOptions: trunkOptions, IPTrunkGroups: sortedSet});
    } else {
      await this.setState({IPTrunkGroupOptions: [], IPTrunkGroups: []});
    }
  }

  render() {
    if (this.state.loadingPB || this.state.loadingAddedProducts) {
      return (
        <div className="container full-card">

          <div className="macd card">
            <div className="card-header">
              <h1>Add Services</h1>
            </div>
            <div>
              <DataLoadingSpinner className='load spinner' />
            </div>
          </div>
        </div>
      )
    } else if (this.state.SelectedLocationId === "0") {
      return (
        <div className="container full-card">

          <div className="macd card">
            <div className="card-header">
              <h1>Add Services</h1>

              {/* <div className="card-actions">
                <UploadOrderData />
              </div> */}
            </div>
            <div>
              <label style={{ fontSize: "medium", fontWeight: "bold" }}>Location:</label>
              <select disabled={this.state.ReadOnly === true ? true : false} 
                style={{  }} 
                style={{width: "200px", marginLeft: "20px", height:'20px', border: 'none', borderBottom: '2px solid #eee', outline: 'none', background: '#f9f9f9', boxShadow: 'none'}}
                onChange={this.confirmLocationChange} value={this.state.SelectedLocationId}>
                {this.state.locationOptions}
              </select>
          </div>
        </div>
      </div>
      )
    }
    else {
      return (
        <div className="container full-card">
          <div className="macd card">
            <div className="card-header">
              <h1>Add Services</h1>

              {/* <div className="card-actions">
                <UploadOrderData />
              </div> */}
            </div>
            <div>
              <label style={{ fontSize: "medium", fontWeight: "bold" }}>Location:</label>
              <select disabled={this.state.ReadOnly === true ? true : false} style={{ width: "200px", marginLeft: "20px" }} onChange={this.confirmLocationChange} value={this.state.SelectedLocationId}>
                {this.state.locationOptions}
              </select>
              <Modal dialogClassName="confirm-add-services-modals" show={this.state.isConfirmationDialogOpen} onHide={this.closeConfirmationDialog}>
                <Modal.Header closeButton>
                  <Modal.Title>{this.state.ConfirmationTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div><p><span style={{ fontSize: "medium" }}>{this.state.ConfirmationMessage}</span></p></div>
                </Modal.Body>
                <Modal.Footer>
                  <Button click={(evt) => { this.ConfirmationYesAction() }}>Yes</Button>
                  <Button click={(evt) => { this.closeConfirmationDialog() }}>No</Button>
                </Modal.Footer>
              </Modal>
            </div>
            <div className="added-products">
              {this.AddedProducts()}
            </div>
            <hr />
            <div style={{ display: this.state.error === true ? "block" : "none", color: "red" }}>
              <h4>{this.state.resultsMsg}</h4>
            </div>
            {this.showCancelAndContinue()}
            <hr />
            <div className="macd-search">
              <div className="search">
                <input type="text" className="form-control" onChange={(e) => this.getSearchResults(e)} placeholder="Search Momentum Products by Part Num or Description" />
              </div>
            </div>
            <div className="products">
              {this.gridContent()}
            </div>
          </div>

        </div>
      )
    }
  }

}

export default MacdAdd;