import React, { Component } from 'react';
import '@progress/kendo-ui';
class MacdSearch extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };    
    }

    componentDidMount() {

    }

    SearchFunction = (e) => {
        this.props.SearchFunction(e);
    }

    render() {
        return (
            
            <input id='lt_list_srch_tb' autoComplete={"off"} type='text' className=' search-control form-control' onChange={(e) => { this.SearchFunction(e) }} placeholder='Search Momentum Services...'
                style={{ 'verticalAlign': 'bottom', 'paddingTop': '10px', 'display': 'inline' }}
            />
        )
    }
}

export default MacdSearch;