import React from 'react';

export const formClass = (state) => state.saving ? 'form-group opaque' : 'form-group';

export const fqdnsOptions = (fqdns) => fqdns.map((fqdn, index) => fqdnsOption(fqdn, index));

export const fqdnsOption = (fqdn, index) => <option key={index} value={fqdn}>{fqdn}</option>

export const savingOrConfirming = (state) => state.saving || state.statusMessage;

export const footerBtnClass = (state) => {
	return savingOrConfirming(state) || state.loading ? 'opaque' : 'visible btns';
}

export const hideSpinnerDelay = 500;

export const statusMsgDelay = 300;
