import React from 'react';
import ReactDOMServer from 'react-dom/server';
import { Tooltip } from '@progress/kendo-popups-react-wrapper';
import '@progress/kendo-ui';

const popUpMessage = () => {
  return ReactDOMServer.renderToStaticMarkup(
    <div>
      <h6>Please use one of the following formats:</h6>
      <div className='text-success'>A1:B2:C3:D4:E5:F6</div>
      <div className='text-success'>A1-B2-C3-D4-E5-F6</div>
      <div className='text-success'>A1B.2C3.D4E.5F6 </div>
      <div className='text-success'>A1B2C3D4E5F6</div>
      <h6>Only alphanumeric characters A - F are permitted.</h6>
    </div>
  )
}

const macAdressFormats = (props) => {

  return (
    <span className={props.className}>
      <Tooltip
        position='right'
        showOn='click'
        width='280px'
        autoHide={false}
        content={popUpMessage()}
        // show={this.onShow}
        // hide={this.onHide}
      >
        <a className='no-btn'>View acceptable formats</a>
      </Tooltip>
    </span>
  )

}

export default macAdressFormats;