import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { store } from '../../store/store';
import { fetchData } from './../../requests/api_calls';
import { userIsAdmin } from './../../scripts/helper';
import * as settings from './../../scripts/settings';
import { DataLoadingSpinner } from './../../components/spinners';
import * as actions from '../../store/actions';

class MyServices extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userServices: [],
      ServiceId: props.ServiceId ? parseInt(props.ServiceId) : 0,
      SelectedUserId: props.SelectedUserId ? parseInt(props.SelectedUserId) : 0,
      selectedService: null,
      loading: false
    };

    this.updateMyServicesMenu = this.updateMyServicesMenu.bind(this);
  }

  componentDidMount() {
    let apiPath = 'usr/MyServices';
    let userId = (this.props.SelectedUserId || sessionStorage.SelectedUserId);
    if (userIsAdmin() && userId) 
      apiPath += '?UserId=' + userId;
    this.fetchMyServices(apiPath);
  }

  componentDidUpdate(prevProps) {
    if(this.serviceHasChanged(prevProps) && !this.loading) {
      let apiPath = 'usr/MyServices';
      let userId = (this.props.SelectedUserId || sessionStorage.SelectedUserId);
      if (userId) apiPath +=  '?UserId=' + userId;
      this.fetchMyServices(apiPath);
    } else if (prevProps.serviceLoading !== this.props.serviceLoading)  {
      store.dispatch({type: actions.SERVICES_SET});
    }
  }

  serviceHasChanged = (prevProps) => {
    return this.firstServiceSelected(prevProps) || this.newServiceSelected(prevProps);
  }

  firstServiceSelected = (prevProps) => this.props.SelectedUserId && prevProps.SelectedUserId === undefined;

  newServiceSelected = (prevProps) => {
    return this.props.SelectedUserId && prevProps.SelectedUserId && 
           this.props.SelectedUserId.toString() !== prevProps.SelectedUserId.toString()
  }

  updateMyServicesMenu = (data) => {
    let currentServiceId = this.props.ServiceId;
    if (this.selectedServiceAlreadyInMenu()) {
      this.setState({loading: false, ServiceId: currentServiceId});
    } else {
      // update service in MyServices Menu
      let selectedServiceId = 0;
      if (data && data.length > 0) {
        data.map((service, idx) => {
          if (service.ServiceId === currentServiceId)
            selectedServiceId = service.ServiceId;
        });
      }
      this.setState({userServices: data, ServiceId: selectedServiceId, loading: false});
    }
  }

  selectedServiceAlreadyInMenu = () => {
    let matchingItemsInMenu = this.state.userServices.filter(item => {
      return item.ServiceId === this.props.ServiceId;
    });
    return matchingItemsInMenu.length > 0;
  }

  fetchMyServices = (apiPath) => {
   
    fetchData(apiPath).then((data) => {
      if (data) {
        this.storeServiceProperties(data);
        this.updateMyServicesMenu(data);
        store.dispatch({type: actions.SERVICES_SET});
      } else if (data === null && userIsAdmin() ) {
        let noService = {
          ServiceId: 0,
          ServiceNumber: '', 
          SelectedUserId: sessionStorage.SelectedUserId,
          AccountNumber: sessionStorage.userEnterpriseNumber,
          DirectoryNumber:'',
          PBXUserID: '',
          Extension: '',
          isSelectedServiceIpTrunking: false
        };
        settings.setService(noService);
        this.setState({userServices: [], loading: false});
        store.dispatch({type: actions.SERVICES_SET});
      }
      
    });
  }

  storeServiceProperties = (data) => {
    let serviceIdentifier = this.props.ServiceNumber || this.props.Extension;
    if (userIsAdmin() && serviceIdentifier || this.state.selectedService) {
      let selectedService = this.matchSelectedService(data, serviceIdentifier);
      if(selectedService) settings.setService(selectedService);
    } else { // non admin will default to first service unless service was clicked in MyServices
      if (this.state.ServiceId) {
        let selectedService = this.matchByServiceId(data);
        settings.setService(selectedService);
      } else if (data && data.length > 0){
        let firstService = data[0];
        settings.setService(firstService);
      }
    }
  }

  matchByServiceId = (data) => {
    // used when service is activated from MyServices
    let matches = data.filter((service) => service.ServiceId === this.state.ServiceId);
    if (matches.length) {
      return matches[0];
    } else {
      return data[0];
    }
  }

  matchSelectedService = (data, serviceIdentifier) => {
    let selectedService = data.filter((service) => service.ServiceNumber === serviceIdentifier);
    if(selectedService.length > 0)
      selectedService = selectedService[0];
    else
      selectedService = null;

    if (!selectedService) {
       selectedService = data.filter((service) => service.Extension === serviceIdentifier);
       if(selectedService.length > 0)
        selectedService = selectedService[0];
      else
        selectedService = null;
    }
    return selectedService;
  }

  serviceLink = (service, index) => {
    let anchorClassName = 'nav-item';
    let serviceNameClassName = 'name';
    if (this.state.loading) {
      anchorClassName = 'nav-item disabled';
      serviceNameClassName = 'disabled';
    }
    if (this.isDefaultOrSelectedService(service)) {
      anchorClassName = "nav-item selected"
    }
    return (
      <a tabIndex="0" className={anchorClassName} key={index} onClick={(e) => this.setServiceAndRedirect(service)}>
        <span className={serviceNameClassName}>{service.ServiceNumber}</span>
        <span className="extension">{this.extension(service)}</span>
        <span className="number">{service.PackageName}</span>
      </a>
    )
  }

  isDefaultOrSelectedService = (service) => {
    return (service.ServiceId > 0 && this.state.ServiceId > 0 && service.ServiceId === this.state.ServiceId) ||
    (this.state.loading && this.state.ServiceId === service.ServiceId);
  }

  extension = (service) => {
    let ext = service.Extension;
    if (ext && service.ServiceNumber) {
      return <span> ({ext})</span>
    } else if (ext) {
      return <span> {ext}</span>
    } else return null;
  }

  setServiceAndRedirect = async (service) => {
    if (!this.state.loading) {
      await this.setState({ServiceId: service.ServiceId});
      settings.selectService(service.UserId, service.ServiceNumber, service.PBXUserID);
      this.props.history.push('/dashboard');
    }
  }

  servicesMenuLabel = () => {
    if ((this.props.SelectedUserId && this.props.UserId && this.props.SelectedUserId === this.props.UserId && userIsAdmin() )
    || (this.props.SelectedUserId && !userIsAdmin())
    ){
      return <div>My Services</div>
    } else {
      return <div>User Services</div>
    }
  }

  serviceLinksClass = () => {
    if (this.state.loading && !this.selectedServiceAlreadyInMenu()) {
      return 'hidden'
    } else {
      return 'service-links'
    }
  }

  render() {
    if ( this.state.userServices.length > 0 ) {
      let userServices = this.state.userServices.map((service, i) => this.serviceLink(service, i) );
      return (
        <div className='user-services'>
          <div className="nav-head">
            {this.servicesMenuLabel()}
            <DataLoadingSpinner className={this.state.loading ? 'load spinner' : 'hidden'} />
          </div>
          <div className={this.serviceLinksClass()}>
            {userServices}
          </div>
        </div>
      )      
    } else return null
  }
}

const mapStateToProps = (state) => {
  return {
    SelectedUserId: state.reducer.SelectedUserId,
    ServiceNumber: state.reducer.ServiceNumber,
    ServiceId: state.reducer.ServiceId,
    Extension: state.reducer.Extension,
    serviceLoading: state.reducer.serviceLoading
  }
}

export default connect(mapStateToProps)(withRouter(MyServices));