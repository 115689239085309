import React from 'react';
import * as helper from '../../../scripts/helper';

const UDP = 'UDP';
const TCP = 'TCP';
const TLS = 'TLS';

const switchClass = (props, propName) => {
  let x = props.parentState.selectedModelConfigs &&
    props.parentState.selectedModelConfigs[propName] ? 'form-group' : 'hidden';
  return x;
}

const VlanClass = (props, propName) => {
  let x = props.parentState.selectedModelConfigs &&
    props.parentState.selectedModelConfigs[propName] ? 'form-group' : 'hidden';
  // if (x !== 'hidden' && props.parentState.configProps.TransportProtocol !== TCP)
  //   x = 'form-group semi-transparent';
  
  return x;
}

const encryptedAudioSwitchClass = (props) => {
  let modelConfigs = props.parentState.selectedModelConfigs;
  let hideSwitch;
  if (modelConfigs) {
    hideSwitch = !modelConfigs.TlsFqdn || modelConfigs.TlsFqdn.trim() === '';
  }
  return hideSwitch ? 'hidden' : 'form-group';
}

const protocolSwitchClass = (props) => {
  let config = props.parentState.configProps;
  if (config.EncryptedAudio) {
    return 'form-group semi-transparent';
  } else {
    let modelConfigs = props.parentState.selectedModelConfigs;
    let hideSwitch;
    if (modelConfigs) {
      hideSwitch = !modelConfigs.TlsFqdn || modelConfigs.TlsFqdn.trim() == '';
    }
    return hideSwitch ? 'hidden' : 'form-group';
  }
}

const validVlanId = (id) => {
  return (id !== "" && id !== undefined) && (id >= 2 && id <= 1001) || (id >= 1006 && id <= 4094) || (id === 0) || (id === 'None');
}

const vlanIdValidationClass = (props) => {
  return (props.parentState.configProps.EnableVlanIdText === false) ? 'hidden' : 'text-danger';
}

const setEnableVlan = (props) => {
  let invalidVlanId = !validVlanId(props.parentState.configProps.VlanId);
  let configProps = { ...props.parentState.configProps, invalidVlanId: invalidVlanId };

  let enable = !configProps.enableVlan;
  configProps.enableVlan = enable;
  if (enable) {
    if (configProps.EnableVlanIdText === false)
      configProps.VlanId = 'None'
    else
      configProps.VlanId = 0;
  } else {
    configProps.VlanId = configProps.CurrentVlanId;
    // if (props.CurrentVlanId === 'None')
    //   configProps.VlanId = configProps.TextVlanId; //0
    // else
    //   configProps.VlanId = configProps.CurrentVlanId;
  }

  //configProps.EnableVlanIdText = props.VlanId && props.VlanId > 0 ? true : false;
  props.switchHandler({ enableVlan: enable, configProps: configProps });
}

//const isUsingTcp = (props) => props.parentState.configProps.TransportProtocol === TCP;

const updateVlanId = (e, props) => {
  let vlanId = e.target.value;
  if (validVlanId(vlanId)) {
    var configProps = { ...props.parentState.configProps, VlanId: e.target.value, TextVlanId: e.target.value, invalidVlanId: false };
  } else {
    var configProps = { ...props.parentState.configProps, VlanId: null, TextVlanId: null, invalidVlanId: true };
  }
  props.switchHandler({ configProps: configProps });
}

const updateVlanField = (e, props, isNone) => {
  var finalConfigProps = {};
  let configProps = props.parentState.configProps;
  if (isNone)
  {
    configProps.EnableVlanIdText = false;
    finalConfigProps = { ...configProps, invalidVlanId: false };
  } else {
    configProps.EnableVlanIdText = true;
    let isValidVlan = validVlanId(configProps.VlanId);
    finalConfigProps = { ...configProps, invalidVlanId: !isValidVlan };
  }

  props.switchHandler({ configProps: finalConfigProps });
}

const switchProtocol = (e, props, tcpOrUdp) => {
  let protocol = e.target.checked ? tcpOrUdp : 'UDP';
  let config = props.parentState.configProps;
  config.TransportProtocol = protocol;
  props.switchHandler({ configProps: config});
}

const setProtocol = (e, props) => {
  let config = props.parentState.configProps;
  config.TransportProtocol = e.target.value;
  props.switchHandler({
    configProps: {
      ...props.parentState.configProps,
      TransportProtocol: e.target.value
    }
  });
}

const setSideCarOnlyBlfPlacement = (props) => {
  props.switchHandler({
    configProps: {
      ...props.parentState.configProps,
      UseLineKeysForBLFList: !props.parentState.configProps.UseLineKeysForBLFList
    }
  });
}

const setVideoIsEnabled = (props) => {
  props.switchHandler({
    configProps: {
      ...props.parentState.configProps,
      IsVideoEnabled: !props.parentState.configProps.IsVideoEnabled
    }
  });
}

const setEncryptedAudio = (props) => {
  let config = props.parentState.configProps;
  let isEnabled = !config.EncryptedAudio;
  let newConfigProps = { ...config, EncryptedAudio: isEnabled };
  if (isEnabled) {
    newConfigProps.TransportProtocol = TLS;
  } 
  props.switchHandler({ configProps: newConfigProps });
}

const groupIpTransportProtocolSettings = (config) => {
  if (config.LocationTransportProtocol) {
    return (
      <small className="instructions_highlight">
        Current group IP transport protocol is set to {config.LocationTransportProtocol}
      </small>
    )
  } else return null
}

const protocolRadioButton = (props, protocol) => {
  let isSelected = props.parentState.configProps.TransportProtocol === protocol;
  let perms = JSON.parse(sessionStorage.DevicePerms);//sessionStorage.DevicePerms
  return (
    <span>
      <input
        type='radio'
        name='protocol'
        value={protocol}
        checked={isSelected}
        onChange={e => setProtocol(e, props)}
        disabled={perms.Audio_Signaling && perms.Audio_Signaling === 1 ? '' : 'disabled'}
      />
      <label>{protocol}</label>
    </span>
  )
}

const configSwitches = (props) => {
  let config = props.parentState.configProps;
  let perms = JSON.parse(sessionStorage.DevicePerms);//sessionStorage.DevicePerms
  return (
    <div>
      <div className={protocolSwitchClass(props)}>
        <label className="right switch">
          <input
            type="checkbox"
            disabled={config.EncryptedAudio}
            checked={config.TransportProtocol === TCP}
            onClick={e => switchProtocol(e, props, TCP)}
          />
          <span className="slider round"></span>
        </label>
        Use TCP for IP transport protocol settings
        {groupIpTransportProtocolSettings(config)}
      </div>
      <div className={VlanClass(props, 'IsVlanAvailable')}>
        <label className="switch">
          <input
            type="checkbox"
            onClick={e => setEnableVlan(props)}
            checked={config.enableVlan}
          />
          <span className="slider round"></span>
        </label>
        Modify current device VLAN set to {config.CurrentVlanId}
        <small className="instructions_highlight">(Current group VLAN ID is set to {config.LocationVLAN ? config.LocationVLAN : 'none'})</small>
        <div className={config.enableVlan ? '' : "collapse hidden-group"}>
          <div className="form-group">
            <label>VLAN ID</label>
            <span className={vlanIdValidationClass(props)}>
              must be between 2 and 1001 or between 1006 and 4094.
            </span>
            <br />
            <label className="custom-control">
              <input 
                type="radio"
                name="DisableVlanEntry"
                checked ={(!config.EnableVlanIdText || config.EnableVlanIdText === false) ? true : false}
                onChange={e => updateVlanField(e,props,true) }
              />
              None
            </label>
            <label className="custom-control">
              <input 
                type="radio"
                name="EnableVlanEntry"
                checked ={config.EnableVlanIdText}
                onChange={e => updateVlanField(e,props,false) }
              />
            </label>
            <input
              type="type"
              disabled={config.EnableVlanIdText ? "" : "disabled" }
              style={{ height: "30px", width:"30%", boxShadow:"inset 0 1px 1px rgba(0,0,0,0.1)", transition: "ease-in-out .15s, box-shadow ease-in-out .15s, -webkit-box-shadow ease-in-out .15s", border: "none", borderBottom: "2px solid #eee", outline: "none"}}
              value={config.TextVlanId}
              maxLength={4}
              onChange={e => updateVlanId(e, props)}
            />
          </div>
        </div>
      </div>
      <div className={switchClass(props, 'IsUseLineKeysForBLFListAvailable')}>
        <label className="right switch">
          <input
            type="checkbox"
            checked={config.UseLineKeysForBLFList}
            onClick={e => setSideCarOnlyBlfPlacement(props)}
          />
          <span className="slider round"></span>
        </label>
        Use Phone > Sidecar for BLF Placement
        <small className="instructions_highlight">(default is Sidecar Only)</small>
      </div>

      <div className={encryptedAudioSwitchClass(props)}>
        <label className="switch">
          <input
            type="checkbox"
            checked={config.EncryptedAudio}
            onClick={e => setEncryptedAudio(props)}
            disabled={perms.Audio_Signaling && perms.Audio_Signaling === 1 ? '' : 'disabled'}
            
          />
          <span className="slider round"></span>
        </label>
        Enable Encrypted Audio & Signaling
        {/* {helper.disableAudioSignaling() ? (<span style={{fontSize: 'x-small', color: 'red'}}> - change permission not enabled</span>) : ''} */}
        <small className={config.EncryptedAudio ? 'visible instructions_highlight' : 'hidden'}>
          Transport protocol will be set to TLS if audio and video is encrypted
        </small>
        <div className={config.EncryptedAudio ? 'visible' : 'hidden'} style={{ margin: '10px 30px' }}>
          <span className='custom-radio-btns'>
            {protocolRadioButton(props, UDP)}<br />
            {protocolRadioButton(props, TCP)}<br />
            {protocolRadioButton(props, TLS)} &nbsp;
            <small className="instructions_highlight">defaults to TLS when audio is encrypted</small>
          </span>
        </div>
      </div>

      <div className={switchClass(props, 'IsVideoAvailable')}>
        <label className="switch">
          <input
            type="checkbox"
            checked={config.IsVideoEnabled}
            onClick={e => setVideoIsEnabled(props)}
          />
          <span className="slider round"></span>
        </label>
        Enable Video
      </div>

    </div>
  )
}

export default configSwitches;