import ValidationMessagePopup from '../components/validationMessagePopup';
import React, { Component } from 'react';
import { Grid, GridColumn as Column, GridCell } from '@progress/kendo-react-grid';

class ValidationCell extends GridCell {

    constructor(props) {
        super(props);
      }

    render() {
      if (this.props.dataItem.ValidationMessage && this.props.dataItem.ValidationMessage !== '') {
        return (
          <td title="Click to view error!">
            <ValidationMessagePopup
              ValidationMessage={this.props.dataItem.ValidationMessage}
            />
          </td>
        )
      } else return <td> </td>
    }
  }

  export default ValidationCell;