import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { Button } from '@progress/kendo-buttons-react-wrapper';
import format from 'string-format';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'; // ES6
import { DataLoadingSpinner } from './../../../components/spinners';
import { connect } from 'react-redux';
import { fetchData, post } from './../../../requests/api_calls';
import * as config from './../../../requests/config';
import * as EmailValidator from 'email-validator';

const PasswordEmail="PasswordEmail", ServiceAndCpniEmail="ServiceAndCpniEmail";

class ChangeEmailModal extends Component {

  constructor(props) {
    super(props);

    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    let ChangeEmailValues = props.GetEmailValues();
    this.state = { 
      showModal: false,
      loading: true, 
      loadingError: "",
      valid: false,
      validMessage: "",
      savingLoading: false,
      savingShowSuccess: false,
      savingShowError: false,
      savingErrorMessage: "",
      currentUser: props.CurrentUserProfile,
      admin: props.isAdmin,
      ChangeEmail: {
        ServiceNumber: ChangeEmailValues.ServiceNumber || '', 
        PBXUserID:  ChangeEmailValues.PBXUserID || '', 
        PasswordEmail:"",
        ServiceAndCpniEmail:"",
        UserID: ChangeEmailValues.UserID || 0
      }
    };
  }
  
  isValidEmail=(email)=> email && EmailValidator.validate(email)

  handleClose() {
    this.setState({ showModal: false });
  }

  handleShow() {
    this.setState({...this.state, showModal: true, loading:true });
    if(this.state.currentUser && this.state.admin){
      this.fetchEmail("admin/GetAdminEmail?&UserID=" + sessionStorage.userId);
    }
    else{
      this.fetchEmail(format("usr/GetUserEmail?ServiceNumber={ServiceNumber}&PBXUserID={PBXUserID}", this.state.ChangeEmail));
    }
  }

  fetchEmail = (apiPath, errorMsg) => {
    fetchData(apiPath).then((data) => {
      let changeEmail = {...this.state.ChangeEmail, PasswordEmail:"", ServiceAndCpniEmail:""};
      let state = {...this.state, ChangeEmail: changeEmail, loading: false};
      if (data){
        let ce = data;
        state.ChangeEmail.PasswordEmail = ce.PasswordEmail;
        state.ChangeEmail.ServiceAndCpniEmail = ce.ServiceAndCpniEmail;
        //let ce = state.ChangeEmail = data;

        state.valid = this.isValidEmail(ce[PasswordEmail]) && this.isValidEmail(ce[ServiceAndCpniEmail]);
        state.validMessage = !ce[PasswordEmail] || !ce[ServiceAndCpniEmail]? "Fill all fields" : "";
      }
      this.setState(state);
    }).catch((message)=>{
      this.setState({...this.state, loading: false, loadingError: "Fail to load"});
    });
  }

  handleOnChange=(type, event)=>{
    let state = {...this.state};

    state.ChangeEmail[type] = event.target.value;

    let passwordEmail = state.ChangeEmail[PasswordEmail];
    let serviceAndCpniEmail = state.ChangeEmail[ServiceAndCpniEmail];

    state.valid = this.isValidEmail(passwordEmail) &&  this.isValidEmail(serviceAndCpniEmail)
    state.validMessage = !passwordEmail || !serviceAndCpniEmail? "Fill all fields" : "";

    this.setState(state);
  }

  handleOnSave=()=>{
    let state = {...this.state, savingLoading:true, savingShowSuccess: false};
    this.setState(state);

    let headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + config.storedAccessToken(),
      'X-Policy': config.defaultXpolicy
    };

    if(this.state.currentUser && this.state.admin){
      state.ChangeEmail.UserID = sessionStorage.userId;
    }
    
    let body = JSON.stringify(state.ChangeEmail);
    let changeUserEmailApi = this.state.currentUser && this.state.admin ? "admin/ChangeAdminEmail": "usr/ChangeUserEmail";
    post(changeUserEmailApi,"POST", body, headers)
		.then(response =>{
      let state = {...this.state, 
        savingLoading: false,  
        savingShowSuccess: response.ok, 
        savingShowError: !response.ok, 
        savingErrorMessage: response.ok?"": "Failed Save"
      };
			
      this.setState(state);
		})
		.catch((message)=>{
      console.log(message);
      let state = {...this.state, 
        savingLoading: false, 
        savingShowError: true, 
        savingErrorMessage: "Failed Save"
      };
      this.setState(state);
		});
  }
  componentDidMount() {   
  }

  render() {
    let state = this.state;
    return (
      <div className="setting">
        {this.getBtn()}
        <Modal show={state.showModal} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Change Email</Modal.Title>
          </Modal.Header>
          <Modal.Body>
              {this.getBodyContent()}
          </Modal.Body>
          <Modal.Footer>
          <hr />
              <div className="btns">
                <SaveButton disabled={state.savingLoading || !state.valid} handleSave={e=> this.handleOnSave()} />
                <Button className="btn" click={this.handleClose}>Close</Button>
              </div>
              <SavingMessageControl 
                showSuccess={state.savingShowSuccess} 
                showLoading={state.savingLoading} 
                showError={state.savingShowError}
                SuccessMessage="Success"
                SuccessTransitionClass="vm-setting-success"
                ErrorMessage={state.savingErrorMessage}
                />
          </Modal.Footer>
        </Modal>
      </div>
    );
  }

  getBodyContent=()=>{
    let loading =  this.state.loading;
    if(loading){
      return ( <DataLoadingSpinner className='load spinner'/>);
    }
    
    let loadingError =  this.state.loadingError;
    if(loadingError){
      return (<div className="inline inline-fail text-center">{loadingError}</div>);
    }

    return (
      <div>
        <div className="form-group">
          <label>Password Recovery Email</label>
          <input type="type" className="form-control" 
            value={this.state.ChangeEmail.PasswordEmail} 
            onChange={x=>{ this.handleOnChange(PasswordEmail, x) }} />

            {this.validateEmail(this.state.ChangeEmail.PasswordEmail)}
        </div>
        <div className="form-group">
          <label>Service & CPNI Email Notifications</label>
          <input type="type" className="form-control" 
            value={this.state.ChangeEmail.ServiceAndCpniEmail} 
            onChange={x=>{ this.handleOnChange(ServiceAndCpniEmail, x) }} />

             {this.validateEmail(this.state.ChangeEmail.ServiceAndCpniEmail)}
        </div>
        {this.getValidationError()}
      </div>);
  }
  
  getValidationError=()=>{
    if(!this.state.valid && this.state.validMessage){
      return (<div className="inline inline-fail text-center">{this.state.validMessage}</div>);
    }
    return "";
  }

  validateEmail=(email)=>{
    if(email && !EmailValidator.validate(email.trim()))
      return (<div className="inline inline-fail">'{email.trim()}' Not a valid email</div>);
  }

  getBtn(){
    const superlink = this.props.superlink;
    if (superlink) {
      return (
        <a onClick={this.handleShow} className="no-btn">Change Recovery Email</a>
      )
    } else {
      return (
        <div>
          <span> 
            <span className="name">Change Password</span>
            <a className="icon btn"><svg width="14" height="14" viewBox="0 0 24 24"><path d="m163 440l-91-91 251-250 90 90z m309-352l-48-48c-12-11-32-11-45 2l-45 45 91 91 45-45c13-13 13-33 2-45z m-408 275l-32 117 117-32z" transform="scale(0.046875 0.046875)"></path></svg></a>
          </span>
        </div>
      )
    }
  }
};


class SavingMessageControl extends Component {
  render() {
      let successMessage = "";
      if(this.props.showSuccess){
        successMessage =  (<ReactCSSTransitionGroup
          transitionName={this.props.SuccessTransitionClass}
          transitionAppear={true}
          transitionAppearTimeout={4000}
          transitionEnter={true}
          transitionEnterTimeout={4000}
          transitionLeave={false}
          >
          <div className="inline-success text-center">{this.props.SuccessMessage}</div>
        </ReactCSSTransitionGroup>)
      }

      let loadingMessage = "";
      if(this.props.showLoading){
        loadingMessage = (<DataLoadingSpinner className='load spinner'/>)
      }
      
      let errorMessage = "";
      if(this.props.showError){
        errorMessage = (<div className="inline inline-fail text-center">{this.props.ErrorMessage}</div>)
      }

      return (
        <div>
          {successMessage}
          {loadingMessage}
          {errorMessage}
        </div>
      );
    }
};

class SaveButton extends Component {
  render() {
    if(this.props.disabled)
      return (<Button key="1" className="btn" enable={false}>Save</Button>);
    else
      return (<Button key="2" className="btn" click={this.props.handleSave}>Save</Button>);
  }
};

const mapStateToProps = (state) => {
  return {
    ServiceNumber: state.ServiceNumber,
    PBXUserID: state.PBXUserID
  }
}

export default connect(mapStateToProps)(ChangeEmailModal);
