import React from 'react';
import { GridCell } from '@progress/kendo-react-grid';
import ServiceMACClickPop from './../components/ServiceMACClickPop';
import EllipsisWithTooltip from 'react-ellipsis-with-tooltip';

class MacAddressCell extends GridCell {
    render() {
      let service = this.props.dataItem;
      if (service.DevCount > 1) {
        return (
          <td>
              <EllipsisWithTooltip placement="top">
                <ServiceMACClickPop
                ServiceId={service.ServiceId}
                MAC={service.MAC}
                DevCount={service.DevCount}
                />
            </EllipsisWithTooltip>
          </td>
        )
      } else {
        let fieldClass = service.DevRegistered ? 'text-success' : 'text-danger';
        return <td><span className={fieldClass}>{service.MAC}</span></td>
      }
    }
  }

  export default MacAddressCell;