import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { Button } from '@progress/kendo-buttons-react-wrapper';
import * as apiCalls from '../../../requests/api_calls';
import _ from 'lodash';
import SaveStatus from '../../../components/save_status';
import * as helper from '../../../scripts/helper';
import { DataLoadingSpinner } from '../../../components/spinners';

export default class ManageTrunkModal extends Component {
  
  initialState = (props = null) => {
    return {
      TrunkGroupId: 0,
      TrunkCustomerProductId: 0,
      LocationAccountNumber: '',
      loading: false,
      saving: false,
      error: false,
      statusMessage: '',
      showModal: false,
      DIDAssigned: [],//fakeAssigned,
      DIDUnassigned: [],
      DIDPending: [],
      SelectedAssigned: [],
      SelectedUnassigned: [],
      SelectedPending: [],
      TrunkGroupOptions: [],
      TrunkGroups: props ? props.TrunkGroups : []
    };
  }

  constructor(props) {
    super(props);
    this.state = this.initialState(props);
    this.OpenModal = this.OpenModal.bind(this);
    this.CloseModal = this.CloseModal.bind(this);
    this.fetchTrunkGroups = this.fetchTrunkGroups.bind(this);
    this.RetrieveDataForAssociations = this.RetrieveDataForAssociations.bind(this);
    this.SubmitChanges = this.SubmitChanges.bind(this);
    this.handleSelection = this.handleSelection.bind(this);
    this.handleTransition = this.handleTransition.bind(this);
    this.ClearSaveStatusModal = this.ClearSaveStatusModal.bind(this);
  }

  // componentWillReceiveProps(props) {
  //   if ((!this.state.TrunkGroups || this.state.TrunkGroups.length === 0) && props.TrunkGroups && props.TrunkGroups.length > 0)
  //   {
  //     this.setState({TrunkGroups: props.TrunkGroups});
  //     this.setTrunkGroupOptions(props.TrunkGroups);
  //   }
  // }

  OpenModal = async() => {
    this.setState({showModal: true, loading: true});
    this.fetchTrunkGroups();
  }

  CloseModal = () => {
    let state = this.initialState();
    let {TrunkGroupId, TrunkGroups, TrunkGroupOptions} = this.state;
    this.setState({...state, TrunkGroups: TrunkGroups, TrunkGroupOptions: TrunkGroupOptions, TrunkGroupId: TrunkGroupId}
      , async () => {
        try {
            await this.props.loadTrunks();
        } catch (e) {
            // handle error
        }
      });
  }

  fetchTrunkGroups = async() => {
    let apiPath = 'admin/GetIpTrunkGroupList'
    let TrunkGroups = await apiCalls.fetchData(apiPath).then((data) => data);
    let blankTrunkEntry = {
      LocationId: 0,
      TrunkGroupID: '',
      LineDescription: '- Select Trunk Group -',
      LocationAccountNumber: '',
      LocationName: '',
      CustomerName:  '',
      CustomerProductId: 0
    }
    TrunkGroups.unshift(blankTrunkEntry);
    let trunkGroupOptions = [];
    // let blankOption = (
    //   <option key={0} value={0}>- Select Trunk Group -</option>
    // );

    // trunkGroupOptions.push(blankOption);
    if (TrunkGroups && TrunkGroups.length > 0)
    {
      TrunkGroups.map((record, idx) => {
          if (record.CustomerProductId !== 0)
            trunkGroupOptions.push((
              <option key={record.CustomerProductId} value={record.CustomerProductId}>{record.TrunkGroupID} - {record.LineDescription}</option>
              )
            );
          else
            trunkGroupOptions.push((
              <option key={record.CustomerProductId} value={record.CustomerProductId}>{record.LineDescription}</option>
              )
            );
      });
    }
    this.setState({TrunkGroupOptions: trunkGroupOptions, TrunkGroups: TrunkGroups, loading: false});
  } 

  RetrieveDataForAssociations = async(LocationAccountNumber, TrunkGroupID) => {
    try
    {
      await this.setState({loading: true});
      let apiPath = `admin/macd/GetIPTrunkAssociation?LocationAccountNumber=${LocationAccountNumber}&TrunkGroupID=${TrunkGroupID}`;
      let DIDs = await apiCalls.fetchData(apiPath).then(data => data);
      
      let assignedDIDs = [];
      let unAssignedDIDs = [];
      DIDs.map((item) => {
        let DIDEntry = {
          isSelected: false,
          Status: item.TrunkGroupID && item.TrunkGroupID > 0 ? 'Assigned' : 'Unassigned',
          ServiceNumber: item.TN,
          CustomerProductId: item.CustomerProductId
        }
        if (DIDEntry.Status === 'Assigned')
          assignedDIDs.push(DIDEntry);
        else
          unAssignedDIDs.push(DIDEntry);
      });


      
      
      await this.setState({DIDAssigned: assignedDIDs, DIDUnassigned: unAssignedDIDs, loading: false});
    }
    catch (ex) {
      await this.setState({DIDAssigned: [], DIDUnassigned: [], loading: false});
    }
  }

  formatDids = (queue, arr = []) => {
    if (arr && arr.length > 0) {
      return arr.map((itm, index) => {
        let itemClass = itm.isSelected === true ? 'selectedItem' : '';
        let label = itm.ServiceNumber;
        if (queue === 'pending')
          label += ' (' + itm.Status + ')';
        return <div
          key={itm.CustomerProductId}
          style={{
            cursor: 'pointer'
          }}
          className={itemClass}
          onClick={() => { this.handleSelection(queue, itm.CustomerProductId);}}
        >{label}</div>
      })
    }
  }

  didField = (queue, arr = []) => {
    return (
      <div style={{
        height: '150px',
        width: '135px',
        overflow: 'auto'
      }}>
        {this.formatDids(queue, arr)}
      </div>
    )
  }

  handleSelection = async(queue, CustomerProductId) => {
    let newSelectionList = [];
    let dynamicDID = 'DID' + queue[0].toUpperCase() + queue.substring(1);
    let dynamicSelected = 'Selected' + queue[0].toUpperCase() + queue.substring(1);
    let DIDQueue = this.state[dynamicDID].slice();

    let currentItem = null;
    currentItem = this.state[dynamicDID].filter(resp => resp.CustomerProductId === CustomerProductId); //from the grids
    if (currentItem)
        currentItem = currentItem[0];

    let currentItemIdx = _.indexOf(DIDQueue, currentItem); //get before toggling selected status
    currentItem.isSelected = !currentItem.isSelected;
    newSelectionList = this.state[dynamicSelected].filter(resp => resp.CustomerProductId !== currentItem.CustomerProductId);
    if (currentItem.isSelected)
      newSelectionList.push(currentItem);

    DIDQueue[currentItemIdx] = currentItem;
    await this.setState({ [dynamicSelected]: newSelectionList, [dynamicDID] : DIDQueue });
  }

  handleTransition = async(actionName) => {
    let {DIDAssigned, DIDPending, DIDUnassigned, SelectedAssigned, SelectedPending, SelectedUnassigned} = this.state;
    let newPendingList = DIDPending.slice();
    let newAssignedList = DIDAssigned.slice();
    let newUnassignedList = DIDUnassigned.slice();
    let selectedItems = [];
    switch (actionName) {
      case 'add':
          SelectedUnassigned.map((record, idx) => {
          let newRecord = {
            CustomerProductId: record.CustomerProductId,
            ServiceNumber: record.ServiceNumber,
            Status: 'Add',
            isSelected: false
          };
          newPendingList.push(newRecord);
          newUnassignedList = newUnassignedList.filter(resp => resp.CustomerProductId !== record.CustomerProductId);
        });
        SelectedUnassigned = [];
        break;
      case 'undoAdd':
        SelectedPending.map((record, idx) => {
          if (record.Status === 'Add')
          {
            let newRecord = {
              CustomerProductId: record.CustomerProductId,
              ServiceNumber: record.ServiceNumber,
              Status: 'Unassigned',
              isSelected: false
            }
            newUnassignedList.push(newRecord);
            newPendingList = newPendingList.filter(resp => resp.CustomerProductId !== record.CustomerProductId);
          } else selectedItems.push(record);

        });
        SelectedPending = selectedItems;
        break;
      case 'remove':
          SelectedAssigned.map((record, idx) => {
            let newRecord = {
              CustomerProductId: record.CustomerProductId,
              ServiceNumber: record.ServiceNumber,
              Status: 'Remove',
              isSelected: false
            }
            newPendingList.push(newRecord);
            newAssignedList = newAssignedList.filter(resp => resp.CustomerProductId !== record.CustomerProductId);
          });
          SelectedAssigned = [];
        break;
      case 'undoRemove':

        SelectedPending.map((record, idx) => {
          if (record.Status === 'Remove')
          {
            let newRecord = {
              CustomerProductId: record.CustomerProductId,
              ServiceNumber: record.ServiceNumber,
              Status: 'Assigned',
              isSelected: false
            }
            newAssignedList.push(newRecord);
            newPendingList = newPendingList.filter(resp => resp.CustomerProductId !== record.CustomerProductId);
          } else selectedItems.push(record);
        });
        SelectedPending = selectedItems;
        break;
    }
    await this.setState({
      SelectedAssigned: SelectedAssigned,
      SelectedPending: SelectedPending,
      SelectedUnassigned: SelectedAssigned,
      DIDAssigned: newAssignedList,
      DIDPending: newPendingList,
      DIDUnassigned: newUnassignedList
    });
  }

  SubmitChanges = async() => {
    try {
      if (this.state.DIDPending.length === 0) return; //end process if nothing selected
      let apiPath = 'admin/macd/AssociateIPTrunk';
      let reqBody = { };
      let statusMessage = '';
      let bError = false;
      let bPartialSuccess = false;
      let pending = this.state.DIDPending.slice();
      let arrAssigned = pending.filter(resp => resp.Status === 'Add').map((record,idx) => {
        return record.CustomerProductId;
      });
      let arrUnassigned = pending.filter(resp => resp.Status === 'Remove').map((record,idx) => {
        return record.CustomerProductId;
      });
      if (arrAssigned && arrAssigned.length > 0)
      {
        reqBody = {
          TrunkCustomerProductId: this.state.TrunkCustomerProductId,
          LocationAccountNumber: this.state.LocationAccountNumber,
          ServiceCustomerProductIdList: arrAssigned
        }

        await this.setState({saving: true});
        let responseAssigns = await apiCalls.post(apiPath, 'PATCH', JSON.stringify(reqBody)).then((data) => data);//.error((message) => {
          //bError = true;
          //statusMessage = 'An error occurred assigning : ' + message.toString();
        //});
        if (helper.IsJsonString(responseAssigns.message)) {
          let messageObj = JSON.parse(responseAssigns.message);
          if (messageObj.MPOErrorList && messageObj.MPOErrorList.length > 0) {
            statusMessage += this.displayErrors(messageObj.MPOErrorList);
            bError = true;
          }
        }
        else if (responseAssigns.ok) {
          statusMessage = 'All DIDs marked for assignment have updated successfully';
          bPartialSuccess = true;
        }
      }
      if (arrUnassigned && arrUnassigned.length > 0)
      {
        apiPath = 'admin/macd/UnassociateIPTrunk';
        if (!this.state.saving)
          await this.setState({saving: true});
        reqBody = {
          TrunkCustomerProductId: this.state.TrunkCustomerProductId,
          LocationAccountNumber: this.state.LocationAccountNumber,
          ServiceCustomerProductIdList: arrUnassigned
        }
        let responseUnassigns = await apiCalls.post(apiPath, 'PATCH', JSON.stringify(reqBody)).then((assignData) => assignData);//.error((message) => {
          //statusMessage += '\nAn error occurred unassigning : ' + message.toString();
        //});
        if (helper.IsJsonString(responseUnassigns.message)) {
          let messageObj = JSON.parse(responseUnassigns.message);
          if (messageObj.MPOErrorList && messageObj.MPOErrorList.length > 0) {
            statusMessage += this.displayErrors(messageObj.MPOErrorList);
            bError = true;
          }
        }
        else if (responseUnassigns.ok)
        {
          statusMessage += '\nAll DIDs marked for unassignment have updated successfully';
          bPartialSuccess = true;
        }
      }
      if (!bError)
        statusMessage = 'All DIDs marked for assignment and unassignment have updated successfully'
      else if (bError && !bPartialSuccess)
        statusMessage = 'All DIDs marked for assignment and/or unassignment have failed\n' + statusMessage;

      await this.setState({saving: false, statusMessage: statusMessage, error: bError});
    }
    catch (ex) {
      this.setState({
        saving: false,
        error: true,
        statusMessage: 'An error occurred: ' + ex.toString(),
      });
    }
  }

  displayErrors = (ErrorList) => {
    let errorMsg = '';
    ErrorList.map((err, idx) => {
      if (err.MPOResultText && err.MPOResultText.length > 0){
        err.MPOResultText.map((rt,idx2) => {
          errorMsg = errorMsg === '' ? rt.ErrorMessage : '\n' + rt.ErrorMessage;
        });
      } else
        errorMsg = errorMsg === '' ? err.APIError : '\n' + err.APIError;
    });
    return errorMsg;
  }

  ClearSaveStatusModal = async() => {
    let selectedTrunk = this.state.TrunkCustomerProductId;
    let TrunkGroup = this.state.TrunkGroups.filter(resp => resp.CustomerProductId === selectedTrunk);
    TrunkGroup = TrunkGroup[0];
    
    //let selectedText = e.target.selectedText;
    //let TrunkGroupId =
    await this.setState({error: false, statusMessage: '', loading: true, DIDAssigned: [], DIDPending: [], DIDUnassigned: [], SelectedAssigned: [], SelectedPending: [], SelectedUnassigned: []});
    this.RetrieveDataForAssociations(TrunkGroup.LocationAccountNumber, TrunkGroup.TrunkGroupID);
  }

  ChangeTrunkGroup = async(e) => {
    let selection = parseInt(e.target.value);
    
    
    let TrunkGroup = this.state.TrunkGroups.filter(resp => resp.CustomerProductId === selection);
    TrunkGroup = TrunkGroup[0];
    
    //let selectedText = e.target.selectedText;
    //let TrunkGroupId =
    await this.setState({
      TrunkCustomerProductId: selection, 
      LocationAccountNumber: TrunkGroup.LocationAccountNumber,
      saving: false, 
      loading: selection > 0 ? true : false, 
      DIDAssigned: [], DIDPending: [], DIDUnassigned: [], 
      SelectedAssigned: [], SelectedPending: [], SelectedUnassigned: []
    });
    if (selection > 0)
      this.RetrieveDataForAssociations(TrunkGroup.LocationAccountNumber, TrunkGroup.TrunkGroupID);
  }

  savingOrConfirming = () => this.state.saving || this.state.statusMessage;

  modalBody = () => {
    if (this.state.loading === true) {
      return (
        <DataLoadingSpinner className='load spinner' />
      );
    } else {
      return (
        <div className={this.savingOrConfirming() ? 'opaque' : 'visible'}>
          <div>
            <label
              style={{ display: 'block' }}
              htmlFor={'select-trunkid'}>Trunk ID:</label>
            <select id={'select-trunkid'} value={this.state.TrunkCustomerProductId} className='custom-input-class' style={{ width: '670px' }} onChange={(e) => { this.ChangeTrunkGroup(e); }}>
              {this.state.TrunkGroupOptions}
            </select>
          </div>
          <div className='column-container'>
            <div className='column-wrapper'>
              Un-assigned DID(s)
              <div className='column-body'>
                {this.didField('unassigned', this.state.DIDUnassigned)}
              </div>
            </div>
            <div className='modal-btn-wrapper'>
              <a className='tg-selections' onClick={() => { this.handleTransition('add'); }}>{'Assign >'}</a>
              {/* SPACING DIV */}
              <div style={{ height: '5px' }}></div>
              {/* SPACING DIV */}
              <a  className='tg-selections' onClick={() => { this.handleTransition('undoAdd')}}>{'< Undo'}</a>
            </div>
            <div className='column-wrapper'>
              Pending DID(s)
              <div className='column-body'>
                {this.didField('pending', this.state.DIDPending)}
              </div>
            </div>
            <div className='modal-btn-wrapper'>
              <a className='tg-selections' onClick={() => { this.handleTransition('remove')}}>{'< Unassign'}</a>
              {/* SPACING DIV */}
              <div style={{ height: '5px' }}></div>
              {/* SPACING DIV */}
              <a className='tg-selections' onClick={() => { this.handleTransition('undoRemove')}}>{'Undo >'}</a>
            </div>
            <div className='column-wrapper'>
              Assigned DID(s)
              <div className='column-body'>
                {this.didField('assigned', this.state.DIDAssigned)}
              </div>
            </div>
          </div>
        </div>
      )
    }
  }

  render() {
    return (
      <span className='trunk-modal-wrapper'>
        <span>
            <a onClick={() => this.OpenModal()}>Manage Associated DIDs</a>
        </span>
        <Modal dialogClassName="ip-trunk-modal"
          show={this.state.showModal}
          onHide={this.CloseModal}
        >
          <Modal.Header>
            <Modal.Title>IP Trunk Management</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.modalBody()}
            
          </Modal.Body>
          <Modal.Footer style={{textAlign:'left'}}>
            <hr />
            <label style={{color: 'Red', width: '60%', textAlign:'left', display: this.savingOrConfirming() ? 'none' : 'inline-block'}}>When assigning DIDs to a trunk group or unassigning from a trunk group,  DIDs are held in the Pending DID(s) bucket until user submits the order. Once an order is submitted, changes are committed immediately.</label>
            <div className="btns" style={{display: this.savingOrConfirming() ? 'none' : 'inline-block', marginLeft:'10%'}} >
              <span><Button className="btn" click={() => this.SubmitChanges()}>Submit</Button></span>
              <span><Button className="btn" click={this.CloseModal}>Cancel</Button></span>
            </div>
            <SaveStatus
              saving={this.state.saving}
              statusMessage={this.state.statusMessage}
              error={this.state.error}
              parentCallBack={this.ClearSaveStatusModal}
            />
          </Modal.Footer>
        </Modal>
      </span>
    )
  }
}




/// IP100 - IP Trunk Group [Node 1=209.134.218.134 - Trunk id=72377 - Product id=1924899]
/// IP100 - IP Trunk Group [Node 1=209.134.220.134 - Trunk id=72378 - Product id=1925144]
// trunk ids under same trunk group share same modal... option to select diff trunk ids in dropdown at the top of the modal


//CSAA
// a. 2 Locations with 4 trunks.
//
//   i. Location part is TBD as part of implementation
//
//   ii. 2 Separate Trunks (2 Ips from ATL and PHX from Momentum each)
//
//   iii. Each Trunk will have 750 SIP - Trunk Licenses - Each
//
//   iv. Location can have multiple trunks
//
//   v. Lowest ID Trunk will be picked first if there are multiple trunks with same IP address
//
// b. 21 DIDs - One Per location