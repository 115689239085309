import React, { Component } from 'react';
import { FullStory } from 'react-fullstory-component';
import { toBoolean } from './helper';
import { isRpxSession, isTestSession } from './session';

const sessionIsLogged = () => toBoolean(process.env.REACT_APP_FS_SESSION);

const settings = {
  host: 'www.fullstory.com',
  orgKey: process.env.REACT_APP_FULLSTORY_ORG_ID
};

const sessionId = () => sessionStorage.userEmail + '-' + sessionStorage.sessionStart;

const customData = () => {
  let data = {
    time: new Date(),
    email: sessionStorage.userEmail,
    role: sessionStorage.userRole,
    userId: sessionStorage.userId,
    userFullName: sessionStorage.userFullName,
    userEnterpriseNumber: sessionStorage.userEnterpriseNumber
  }
  if (isRpxSession()) {
    data.emulation = 1;
    data.emulationUserName = sessionStorage.emulatedUser;

  }

  if (isTestSession()) data.automatedTest = 1;

  return data;
}

class FullStoryScript extends Component {

  render() {
    if (sessionIsLogged()) {
      return <FullStory settings={settings} sessionId={sessionId()} custom={customData()} />;
    } else {
      return null // prevents render error
    }
  }
}

export default FullStoryScript;