import React, { Component, Fragment } from "react";
import OC_Terms from "./terms";
import MsTeamsConfig from "./oc_config";
import MsTeamsUserMenu from "./oc_menu";
import MsTeamsTenantInfo from "./oc_tenantinfo";
import PhoneNumbers from "./phoneNumbers";
import { DataLoadingSpinner } from "../../components/spinners";
import * as apiCalls from "../../requests/api_calls";
import { userIsAdmin } from "../../scripts/helper";
//import TokenRequestBtn from "../operator-connect/auth/token-request-btn";
import FaxList from "./fax_list";

export default class OperatorConnectV1Main extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      Enterprise: sessionStorage.userEnterpriseNumber,
      tab: "phoneNumbers",
      CurrentTrunk: {},
      MSTeamsConfig: null,
      MSTeamsConfigCopy: {},
      showConfirmationModal: false,
      showDirectRoutingModal: false,
      Trunks: [],
      TeamsUsers: [],
      CustomerName: "",
      DNSTicketDetails: null,
      SBCTicketDetails: null,
      TabRedirect: true,
      showUserList: false,
      routingType: "Operator Connect",
      CheckStatus: false,
      gridHeight: (window.innerHeight - 180),
      VoiceRoutesList: [],
      VoicePoliciesList: [],
      OpCnConsent: null,
      FaxList: [],
      FullListings: [],
    };
  }

  async componentDidMount() {
    if (userIsAdmin()) {
      await this.fetchTrunks();
      await this.fetchMSTeamsConfigInfo();
      await this.hasFaxList();
      await this.fetchOperatorConnectConsent();
      if (!sessionStorage.msteamstabName) {
        // REMOVING CONSENT TAB 6/17/2024 - JP
        if (this.state.MSTeamsConfig?.isTeamsUsersSync) {
          this.setState({ tab: "data", loading: false });
        } else {
          this.setState({ tab: "phoneNumbers", loading: false });
        }

        // if (this.state.OpCnConsent?.IsSuccess && this.state.MSTeamsConfig?.isTeamsUsersSync) {
        //   this.setState({ tab: "data", loading: false });
        // } else if (!this.state.OpCnConsent?.IsSuccess) {
        //   this.setState({ tab: "consent", loading: false });
        // } else if (this.state.OpCnConsent?.IsSuccess && !this.state.MSTeamsConfig?.isTeamsUsersSync) {
        //   this.setState({ tab: "config", loading: false });
        // }
      } else {
        this.setState({ tab: sessionStorage.msteamstabName, loading: false });
      }
    }
  }

  async componentDidUpdate(prevProps, prevState) {
    if (!sessionStorage.msteamstabName && prevState.tab !== this.state.tab) {
      if (this.state.tab === "data") {
        await this.setState({ tab: "data" });
      }
    }

  }

  hasFaxList = async () => {
    await this.setState({ loading: true });
    let path = "oc/HasFaxList";
    await apiCalls
      .fetchData(path)
      .then((data) => {
        if (data) {
          this.setState({
            HasFaxList: data,
            loading: false,
          });
        } else {
          this.setState({
            HasFaxList: false,
            loading: false,
          });
        }
      })
      .catch((err) => console.error(err));
  };

  fetchBillingStat = async () => {
    await this.setState({ loading: true });
    let path = "admin/BillingStat";
    await apiCalls
      .fetchData(path)
      .then((data) => {
        if (data) {
          this.setState({
            CustomerName: data.CustomerName,
            loading: false,
          });
        } else {
          this.setState({
            CustomerName: "",
            loading: false,
          });
        }
      })
      .catch((err) => console.error(err));
  };

  fetchOperatorConnectConsent = async () => {
    await this.setState({ loading: true });
    let path = `oc/GetOperatorConnectConsent?enterpriseId=${sessionStorage?.enterpriseId}`;
    await apiCalls.fetchData(path).then(async (data) => {
      if (data) {
        this.setState({
          OpCnConsent: data,
          loading: false,
        });
      } else {
        this.setState({
          OpCnConsent: null,
          loading: false,
        });
      }
    }).catch((err) => {
      this.setState({
        OpCnConsent: null,
        loading: false,
      });
    }
    );
  }

  fetchTrunks = async () => {
    await this.setState({ loading: true });
    let path = `admin/EnterpriseMSTrunks`;
    await apiCalls
      .fetchData(path)
      .then((data) => {
        if (data) {
          this.setState({
            Trunks: data,
            CurrentTrunk: data[0],
          });
        } else {
          this.setState({
            Trunks: [],
            CurrentTrunk: {},
            loading: false,
          });
        }
      })
      .catch((err) => console.error(err));
  };

  fetchDomainList = async () => {
    await this.setState({ loading: true });
    let path = `admin/GetMSTeamsDomain?enterprise=${this.state.Enterprise}&trunkName=${this.state.CurrentTrunk.TrunkName}`;
    await apiCalls.fetchData(path).then(async (data) => {
      if (data) {
        this.setState({
          DomainList: data,
          loading: false,
        });
      } else {
        this.setState({
          DomainList: [],
          loading: false,
        });
      }
    }).catch(err => console.error(err));
  }

  fetchMSTeamsConfigInfo = async () => {
    await this.setState({ loading: true });
    let path = `admin/MSTeamsConfig?EnterpriseTrunkId=${this.state.CurrentTrunk.TrunkId}`;
    await apiCalls
      .fetchData(path)
      .then(async (data) => {
        if (data) {
          let copy = JSON.parse(JSON.stringify(data));
          this.setState({
            MSTeamsConfig: data,
            MSTeamsConfigCopy: copy,
            PrimaryGateWay: data.PstnPrimaryGateway,
            SecondaryGateWay: data.PstnSecondaryGateway,
            loading: false,
          });
          //await this.fetchMSTeamsUserList();
          await this.fetchDomainList();
          await this.fetchBillingStat();
          //await this.fetchListings();
          //await this.fetchListingsForVoicePolicies();
          if (!sessionStorage.msteamstabName && this.state.TabRedirect) {
            if (this.state.MSTeamsConfig.ConfigProcessCompleted) {
              this.setState({ tab: "data" });
            }
          }
        } else {
          this.setState({
            MSTeamsConfig: null,
            MSTeamsConfigCopy: null,
            PrimaryGateWay: "",
            SecondaryGateWay: "",
            loading: false,
          });
        }
      })
      .catch((err) => console.error(err));
  };

  btnField = (description, tabName, disabledCondition) => {
    return (
      <button
        className={this.state.tab === tabName ? "wizard active" : "wizard"}
        type="button"
        onClick={() => this.changeTab(tabName)}
        disabled={disabledCondition}
        style={{
          cursor: disabledCondition ? "not-allowed" : "pointer",
          padding: "5px 10px",
          margin: "2px",
          color: this.state.tab === tabName ? "black" : "#6c8bad",
          borderRadius: "10px",
          border:
            this.state.tab === tabName
              ? "1px solid black"
              : "1px solid #6c8bad",
        }}
      >
        {" "}
        {description}
      </button>
    );
  };

  tabDisplay = () => {
    let { MSTeamsConfig } = this.state;
    if (MSTeamsConfig) {
      return (
        <div
          className="btn-group"
          role="group"
          aria-label="Basic example"
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          {this.btnField("Phone Number", "phoneNumbers", this.state.loading)}
          {/* {this.btnField("Consent", "consent", this.state.loading)} */}
          {this.btnField("Connect to Microsoft 365", "config", this.state.loading)}
          {this.btnField("User List", "data", this.state.loading)}
          {this.state.HasFaxList ? this.btnField("Fax List", "fax", this.state.loading) : null}
          {/*{sessionStorage.RPXLogin === "true" ? this.btnField("Tenant Info", "tenant", this.state.loading || this.state.OpCnConsent == null ? true : false) : null}*/}
        </div>
      );
    }
  };

  changeTab = (tabName) => {
    sessionStorage.setItem('msteamstabName', tabName);
    this.setState({ tab: tabName });
  };

  proceedToNextTab = (value) => {
    this.setState({ tab: value });
  };

  removeTabRedirectOnSave = async (value) => {
    await this.setState({ TabRedirect: value });
  };

  getTab() {
    switch (this.state.tab) {
      // case "consent":
      //   return (
      //     <div>
      //       <OC_Terms
      //         operatorConsent={this.state.OpCnConsent}
      //         acceptedFunc={this.fetchOperatorConnectConsent}
      //       />
      //     </div>
      //   );
      case "config":
        return (
          <div>
            <MsTeamsConfig
              MSTeamsConfig={this.state.MSTeamsConfig}
              loading={this.state.loading}
              Trunk={this.state.CurrentTrunk}
              Enterprise={this.state.Enterprise}
              Proceed={this.proceedToNextTab}
              RefreshData={this.fetchMSTeamsConfigInfo}
              PrimaryGateWay={this.state.PrimaryGateWay}
              SecondaryGateWay={this.state.SecondaryGateWay}
              removeTabRedirectOnSave={this.removeTabRedirectOnSave}
              EnterpriseTrunkId={this.state.CurrentTrunk.TrunkId}
              routingType={this.state.routingType}
              OpCnConsent={this.state.OpCnConsent}
            />
          </div>
        );
      case "data":
        return (
          <MsTeamsUserMenu
            MSTeamsConfig={this.state.MSTeamsConfig}
            loading={this.state.loading}
            Trunk={this.state.CurrentTrunk}
            Enterprise={this.state.Enterprise}
            Proceed={this.proceedToNextTab}
            RefreshData={this.fetchMSTeamsConfigInfo}
            routingType={this.state.routingType}
            OpCnConsent={this.state.OpCnConsent}
          />
        );
      // case "tenant":
      //   return (
      //     <MsTeamsTenantInfo
      //       MSTeamsConfig={this.state.MSTeamsConfig}
      //       VoiceRoutesList={this.state.VoiceRoutesList}
      //       VoicePoliciesList={this.state.VoicePoliciesList}
      //       loading={this.state.loading}
      //       Trunk={this.state.CurrentTrunk}
      //       Enterprise={this.state.Enterprise}
      //       Proceed={this.proceedToNextTab}
      //       RefreshData={this.fetchMSTeamsConfigInfo}
      //       CustomerName={this.state.CustomerName}
      //       PrimaryGateWay={this.state.PrimaryGateWay}
      //       SecondaryGateWay={this.state.SecondaryGateWay}
      //       routingType={this.state.routingType}
      //     />
      //   );
      case "phoneNumbers":
        return (
          <div>
            <PhoneNumbers
              MSTeamsConfig={this.state.MSTeamsConfig}
              loading={this.state.loading}
              Trunk={this.state.CurrentTrunk}
              Enterprise={this.state.Enterprise}
              routingType={this.state.routingType}
              OpCnConsent={this.state.OpCnConsent}
            />
          </div>
        );
      case "fax":
        return (
          <FaxList
            MSTeamsConfig={this.state.MSTeamsConfig}
            loading={this.state.loading}
            Trunk={this.state.CurrentTrunk}
            Enterprise={this.state.Enterprise}
            routingType={this.state.routingType}
            OpCnConsent={this.state.OpCnConsent}
          />
        );
      default:
        console.log("Sorry there isn't a tab named " + this.state.tab + ".");
    }
  }

  checkStatus = async () => {
    await this.fetchMSTeamsConfigInfo();
    this.setState({ tab: "migrate", CheckStatus: true });
  }

  cardContent = () => {
    if (!this.state.loading) {
      return (
        <Fragment>
          <div className="card-content">
            <div className="tab-body">{this.getTab()}</div>
          </div>
        </Fragment>
      );
    } else {
      return (
        <div className="card-content">
          <div>
            <DataLoadingSpinner style={{ marginTop: "3%" }} className='load spinner' />
          </div>
        </div>
      );
    }
  };
  servicesCardInlineStyle = () => {
    // offset by 30 to account for missing table headers
    if (this.state.loading) return { height: this.state.gridHeight + 100 + 'px' }
  }
  render() {
    return (
      <div className="sync-menu-container">
        <div className="container full-card">
          <div className="sync-menu card">
            <div className="card-header">
              <h1>Operator Connect</h1>
              {/* <TokenRequestBtn /> */}
            </div>
            {this.tabDisplay()}
            {this.cardContent()}
          </div>
        </div>
      </div>
    );
  }
}