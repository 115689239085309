import React, { Component } from 'react';
import { DataLoadingSpinner } from './spinners';
import { fetchData } from './../requests/api_calls';
import * as config from '../requests/config';

class DeviceStatuses extends Component {

  constructor(props) {
    super(props);
    this.state = {loading: props.loading, Services: props.Services};
  }

  componentWillReceiveProps(nextProps) {
    this.setState({loading: false});
  }

  realtimeRegistrationStatus = () => {
    if (this.state.loading) {
      return <DataLoadingSpinner className='adjacent-left load spinner' />
    } else return <span>{this.props.updated}</span>
  }

  updateStatus = () => {
    this.setState({loading: true}, this.props.checkRegistration())
  }

  exportBtn = () => {
    if ( this.props.Services !== undefined) {
      return (
        <div id="users-csv" className='pull-right'>
          <form ref='downloadCsv' target="iFrameSave" action={config.baseApiUrl + 'admin/GetServicesCSV'} method="POST">
            <input type="hidden" name="access_token" value={config.storedAccessToken()} className='hidden'></input>
            <input type="hidden" name="x_policy" value={config.storedXPolicy()} className='hidden'></input>
          </form>
          <iframe name="iFrameSave" height="0" width="0" className='hidden pull-right'></iframe>
          <a tabIndex="0" ref='exportBtn'onClick={(e) => this.exportUsers()}>Export Users</a>
        </div>
      )
    } else return null;
  }

  exportUsers = () => {
    this.refs.downloadCsv.submit();
    this.refs.exportBtn.text = 'Downloading...';
    setTimeout(() => { 
      this.refs.exportBtn.text = 'Export Users';
    }, 2000);
  }

  deviceStatus = () => {
    if (this.props.updated) {
      return (
        <div>
          {this.realtimeRegistrationStatus()}
          <a onClick={this.updateStatus}> Update</a>
        </div>
      )
    } else if (this.state && this.state.loading) {
      return (
        <div>
          <DataLoadingSpinner className='adjacent-left load spinner' textLeft={'Loading'} />
          <a onClick={this.updateStatus}> Update</a>
        </div>
      )
    } else return <a onClick={this.updateStatus}> Check Registration Status</a>
  }

  render() {
    return (
      <div>
        <div className='device-status'>
        {this.deviceStatus()}
      </div>
      <div className='device-status'>
        {this.exportBtn()}
      </div>
      </div>
    )
  }
}

export default DeviceStatuses;