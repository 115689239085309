import React, { Component } from 'react';
import ReactDOMServer from 'react-dom/server';
import { Link, withRouter, BrowserRouter } from 'react-router-dom';
import '@progress/kendo-ui';
import * as config from './../requests/config.js';
import * as settings from './../scripts/settings.js';
import { fetchData } from './../requests/api_calls';
import { DataLoadingSpinner } from './spinners';
import Popup from "reactjs-popup";
import { Grid, GridCell, GridColumn as Column } from '@progress/kendo-react-grid';

const options = {
    position: "right",
    showOn: "click",
    width: '240px',
    autoHide: false
}

class ServiceNumberClickPop extends Component {

  constructor(props) {
    super(props);
    this.state = {
    	ports: [], 
    	errorMessage: null,
      loading: true,
      popupHeight: (this.props.TnCount * 40 + 150) < 500 ? (this.props.TnCount * 40 + 150).toString() : 500
    };
    
    this.showTns = this.showTns.bind(this);
    this.loadTnList = this.loadTnList.bind(this);

    class EditBtn extends GridCell {
      render() {
        let path = {pathname: '/services', searchTerm:  this.props.dataItem.UserId}
        return (
          <td>
            <Link to={path} className="no-btn">Service</Link>
          </td>
        )
      }
    }
    this.editBtn = EditBtn;
  }

	componentDidMount() {

  }

  showTns = () => {
  	this.setState({loading: true});
    fetchData('admin/GetDeviceConfiguration?mac=' + this.props.MacAddress).then(data => {
    	if(data && data.Ports) {
        let portsRevised = [];
        data.Ports.map((port, index) => {
          let phoneOrExt = port.PhoneNumber || port.Extension;
          if (phoneOrExt) {
            let newPort = {
              portNum: port.PortNumber,
              TN: port.PhoneNumber || port.Extension,
              DisplayName: port.DisplayName || '',
              UserId: port.UserId
            };
            portsRevised.push(newPort);
          }
        });
        this.setState({ports: portsRevised, loading: false});
      }
    });
  }

  loadTnList = () => {
  	if (this.state.loading) {
  		return <DataLoadingSpinner className='load relative spinner' />
  	} else {
	  	return (
        <span>
          <h4 className='text-center'>Telephone Numbers:</h4>
          <Grid
            style={{ height: (this.state.popupHeight - 75) + 'px' }}
            data={this.state.ports}
          >
            <Column field="portNum" title="Line" />
            <Column field="TN" title="TN" />
            <Column field="DisplayName" title="Label" />
            <Column title=' ' cell={this.editBtn} sortable={false} />
          </Grid>
        </span>
      )
  	}
  }

  render() {
    return (
      <Popup
        trigger={open => (
          <a tabIndex="0" className="no-btn">{ this.props.TnCount }</a>
        )}
        onOpen={this.showTns}
        position="bottom center"
        closeOnDocumentClick
        contentStyle={{padding: '20px', border: 'unset', width: '450px', height: this.state.popupHeight + 'px'}}
      >
        <span> {this.loadTnList()} </span>
      </Popup>
    )
  }

}

export default ServiceNumberClickPop;