import React, { Component, Fragment } from 'react';
import * as settings from './../scripts/settings';
import * as helper from './../scripts/helper';
import moment from 'moment';
import * as icons from './../scripts/icons';
import * as apiCalls from './../requests/api_calls';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { DataLoadingSpinner } from './../components/spinners';
import { Grid, GridColumn as Column, GridCell } from '@progress/kendo-react-grid';
import { orderBy } from '@progress/kendo-data-query';
import { filterBy } from '@progress/kendo-data-query';
import serviceSelector from './../components/service_selector';
import ServicesChangePasswordModal from './../containers/modals/servicesChangePasswordModal';
import ServicesResetSettingsModal from './../containers/modals/servicesResetSettingsModal';
import DeviceStatuses from './../components/deviceStatuses';
import { filterList } from '../constants/filters.js'
import { parse } from 'query-string';
import _ from 'lodash';
import { headerToolTip } from './../components/ellipsisHeaderTooltip';
import MacAddressCell from './../components/macAddressCell';

const parseViewFilter = (props) => {
  if (props.location.search) {
    return parse(props.location.search).view;
  } else if (props.location.viewFilter) {
    return props.location.viewFilter;
  } else if (props.match.params) {
    return props.match.params.view;
  } else return null;
}

const parseServiceIdFile = (props) => {
  if (props.location && props.location.AssignedDIDServiceId)
    return props.location.AssignedDIDServiceId;
  else if (props.match.params) {
    return props.match.params.TrunkGroupServiceId;
  } else return null;
}

const paramsSeparator = (apiPath) => apiPath.indexOf('?') >= 0 ? '&' : '?';

//const isFirstFilter = (filter, filters) => filters.indexOf(filter) === 0;

// class SettingsButtons extends GridCell {

//   render() {
//     return (
//       <td align="right" className="actions">
//         <ServicesChangePasswordModal />
//         <ServicesResetSettingsModal />
//       </td>
//     );
//   }
// }

class Services extends Component {

  initialState = (props) => {  // RESTORE once CM5469 is done
    let returnState = {
      filter: { logic: "and", filters: [] },
      locationAddress: props.locationAddress || null,
      searchTerm: props.location.searchTerm || null,
      serviceUserFilter: props.location ? props.location.serviceUserFilter : null,
      locationAccFilter: props.match.params ? props.match.params.LocationAccountNumber : null,
      viewFilter: parseViewFilter(props),
      useFilter: true,
      deviceCount: props.location ? props.location.deviceCount : null,
      services: [],
      isTogglingStatus: false,
      sort: [{ field: "LastName", dir: "asc" }, { field: "FirstName", dir: "asc"}, { field: "ServiceNumber", dir: "asc"}, {field: "Extension", dir: "asc"}],
      skip: 0,
      gridHeight: (window.innerHeight - 180),
      AssignedDIDServiceId: parseServiceIdFile(props)
    }

    if (returnState.AssignedDIDServiceId && returnState.AssignedDIDServiceId > 0)
      returnState.serviceUserFilter = 'IP_TRUNK_ASSIGNED_DID'

    return returnState;
  }

  constructor(props) {
    super(props);
    this.state = this.initialState(props);
    this.fetchServices = this.fetchServices.bind(this);
    this.searchServices = this.searchServices.bind(this);
    this.filterChange = this.filterChange.bind(this);
    this.filterServices = this.filterServices.bind(this);
    this.sortChange = this.sortChange.bind(this);
    this.pageChange = this.pageChange.bind(this);
    this.clearFilter = this.clearFilter.bind(this);
    const starAlt = this.starAlt.bind(this);
    const starClass = this.starClass.bind(this);
    const toggleFavoriteStatus = this.toggleFavoriteStatus.bind(this);
    const handleEnter = this.handleEnter;

    class FavoriteButton extends GridCell {

      render() {
        // passing data item based on example from Kendo UI example
        // https://www.telerik.com/kendo-react-ui/components/grid/editing/editing
        let service = this.props.dataItem;
        let cannotFavorite = (service.ServiceId === 0 || service.ServiceId === "") && service.Extension === "";
        if (cannotFavorite) {
          return <td></td>
        } else {
          const cursorType = service.isToggingStatus ? 'wait' : 'pointer';
          return (
            <td>
              <a style={{ cursor: cursorType }} className={starClass(service)} key={service.ServiceId} onClick={(e) => toggleFavoriteStatus(service)} >
                <svg tabIndex="0" width="15" height="15" viewBox="0 0 24 24" onKeyUp={(e) => handleEnter(e, service)} alt={starAlt(service)}>
                  <path d="m480 192l-157-20-67-140-67 140-157 20 115 104-29 152 138-74 139 74-30-152z" transform="scale(0.046875 0.046875)"></path>
                </svg>
              </a>
            </td>
          )
        }
      }
    }
    this.favBtn = FavoriteButton;


    //this.macAddressCell = MacAddressCell;

    class ServiceTypeCell extends GridCell {
      render() {
        const { dataItem } = this.props;

        if ((!dataItem.LocationAccountNumber || !dataItem.ServiceNumber) ||
          (dataItem.ServiceType !== 'Audio Conferencing')) {
          return <td>{dataItem.ServiceType}</td>;
        }
        if (dataItem.ServiceType === 'Collaborate Bridge') {
          const collabPath = `/iframe_page/MSP_SUBSCRIBER&locationID=${dataItem.LocationAccountNumber}&mode=collaborate_bridge&tn=${dataItem.ServiceNumber}`;
          return (
            <td>
              <Link to={collabPath} className="nav-item no-btn" >{dataItem.ServiceType}</Link>
            </td>
          )
        }
        const path = `/iframe_page/MSP_GROUP&locationID=${dataItem.LocationAccountNumber}&mode=conference_bridge&tn=${dataItem.ServiceNumber}`;
        return (
          <td>
            <Link to={path} className="nav-item no-btn" >{dataItem.ServiceType}</Link>
          </td>
        )
      }
    }
    this.serviceTypeCell = ServiceTypeCell;
  }

  handleResize = () => this.setState({
    gridHeight: (window.innerHeight - 180)
  });

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize)
  }

  componentDidMount() {
    this.handleResize();
    window.addEventListener('resize', this.handleResize)
    this.fetchServices();
  }

  componentWillReceiveProps(nextProps) { //RESTORE ONCE CM5469 IS DONE
    let newApiParams = this.newLocationParams(nextProps) || this.newSearchTermParams(nextProps);
    if (newApiParams) {
      this.fetchServices('admin/Services?' + newApiParams);
    } else if (!this.state.AssignedDIDTrunkID && !this.state.locationAccFilter) {
      this.fetchServices('admin/Services');
    }
  }

  newLocationParams = (nextProps) => {
    let newLocation = nextProps.location.LocationAccountNumber;
    let prevLocation = this.props.LocationAccountNumber;
    if (newLocation && newLocation !== prevLocation) {
      return 'LocationAccountNumber=' + newLocation;
    } else return null;
  }

  newSearchTermParams = (nextProps) => {
    let newSearchTerm = nextProps.location.searchTerm;
    let prevSearchTerm = this.state.searchTerm;
    if (newSearchTerm && newSearchTerm !== prevSearchTerm) {
      this.setState({ searchTerm: newSearchTerm });
      return 'term=' + nextProps.location.searchTerm;
    } else return null;
  }

  filteredGridContent = async (data) => {
    let { serviceUserFilter } = this.state;
    if (data && serviceUserFilter === 'IP_TRUNK_ASSIGNED_DID' && sessionStorage.AssignedDIDTrunkID) {
      let didArr = data.filter(res => res.ServiceType === 'DID' || res.ServiceType === 'E911 DID');
      return didArr;
    } else return data;
  }

  fetchServices = (apiPath = null) => {
    if (!apiPath) apiPath = this.apiPathWithParams();
    this.setState({ loading: true });
    apiCalls.fetchData(apiPath).then(async (data) => {
      if (data !== null) {
        let filteredData = await this.filteredGridContent(data);
        this.setState({
          loading: false,
          services: this.revisedData(filteredData),
          resultsMsg: null
        });
      } else if (data === null) {
        this.setState({ services: [], loading: false, resultsMsg: settings.noResultsMsg });
      };
    });
  }

  apiPathWithParams = () => {
    let state = this.state;
    let path = 'admin/Services';
    if (state.locationAccFilter) path += '?LocationAccountNumber=' + state.locationAccFilter;
    if (state.viewFilter) path += paramsSeparator(path) + 'view=' + state.viewFilter;
    if (state.searchTerm) path += paramsSeparator(path) + 'term=' + state.searchTerm;
    if (state.serviceUserFilter) path += paramsSeparator(path) + 'filter=' + state.serviceUserFilter;
    if (state.AssignedDIDServiceId && state.AssignedDIDServiceId > 0) {  //RESTORE AFTER CM5469 IS DONE
      path+= paramsSeparator(path) + 'IPTrunkGroupServiceId=' + state.AssignedDIDServiceId;

    }

    return path;
  }

  revisedData = (data) => {
    let sortedData = this.sortServices(this.state.sort, this.replacedValues(data))
    let favs = sortedData.filter(service => service.IsFav);
    let nonFavs = sortedData.filter(service => service.IsFav === false);
    return favs.concat(nonFavs);
  }

  replacedValues = (data) => {
    return data.map((service) => {
      if (helper.userHasNoServices(service)) {
        return Object.assign({}, service, {
          isToggingStatus: false,
          ServiceType: service.UserType,
          ServiceNumber: (service.Username || service.PackageName)
        });
      } else {
        return Object.assign({}, service, {
          isToggingStatus: false,
          ServiceNumber: service.ServiceNumber
        });
      }
    });
  }

  activeFilters = () => {
    // filter may come from global search, service ID link (Trunking or Locations), or Devices (Manage btn)
    let state = this.state;
    let possibleFilters = [state.locationAccFilter, state.viewFilter, state.serviceUserFilter];
    let filters = possibleFilters.filter((item) => item); // removed empty filters
    if (state.useFilter && filters.length) {
      return (
        <Fragment>
          Filters: <span id='active-filters'>{filterList(filters)}</span>
          <span className='btn text-danger clear-filter' onClick={this.clearFilter}>&times;</span>
        </Fragment>
      )
    }
  }

  clearFilter = () => {
    this.setState({ useFilter: false, viewFilter: null, locationAccFilter: null, serviceUserFilter: null, searchTerm: null });
    this.fetchServices('admin/Services');
    this.props.history.push('/services');
  }

  filterChange(event) {
    let services = this.filterServices(event.filter);
    let resultsMsg = services.length ? null : 'No results found with current filter settings.';
    this.setState({
      services: services,
      filter: event.filter,
      resultsMsg: resultsMsg
    });
  }

  searchServices = (event) => {
    let searchText = event.target.value.trim();
    if (searchText !== '') {
      this.setState({ searchTerm: searchText })
    } else {
      this.setState({ searchTerm: null })
    }
    this.delayedSearch();
  }

  delayedSearch = () => {
    if (this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(() => this.fetchServices(), settings.searchDelay);
  }

  filterServices = (filter) => {
    let services = this.state.services.slice();
    return filterBy(services, filter);
  }

  sortServices(sort, services = null) {
    if (services == null) services = this.state.services.slice();
    let sortedSet = orderBy(services, sort);
    if (sort.length) {
      return helper.blanksAfterZ(sortedSet, sort);
    } else {
      return sortedSet;
    }
  }

  sortChange(event) {
    this.setState({
      services: this.sortServices(event.sort),
      sort: event.sort
    });
  }

  pageChange(event) {
    this.setState({
      services: this.state.services,
      skip: event.page.skip
    });
  }

  /****************************************************************************
   * For the alt attribute value. For accessibility, tells what the 
   * button will do next when clicked, not the current state.
   * *************************************************************************/
  starAlt = (dataItem) => dataItem.IsFav ? 'unfavorite' : 'favorite';

  starClass = (dataItem) => dataItem.IsFav ? 'star' : 'dim-star';

  // Used for accessibility standards
  handleEnter = (e, item) => {
    e.preventDefault();
    if (e.keyCode === 13)
      this.toggleFavoriteStatus(item);
  }

  toggleFavoriteStatus = async (item) => {
    // post update to API
    let updatedItem = new Object(item);
    updatedItem.isToggingStatus = true;
    await this.replaceServices(updatedItem);
    if (item.IsFav) {
      await this.unfavorite(item);
    } else {
      await this.saveFavorite(item);
    }

  }

  updateService = (item, newFavServiceId = null) => {
    let newIsFavState = item.IsFav ? false : true;
    let updatedItem = new Object(item);
    updatedItem.IsFav = newIsFavState;
    updatedItem.isToggingStatus = false;
    if (newFavServiceId) updatedItem.FavoriteServiceId = newFavServiceId;
    this.replaceServices(updatedItem);
  }

  replaceServices = async (updatedItem) => {
    // replace services array with new array with updated service item
    let newServices = this.state.services.slice();
    let index = _.findIndex(newServices, s => s.ServiceId === updatedItem.ServiceId);
    newServices[index] = updatedItem;
    await this.setState({ services: newServices });
  }

  saveFavorite = (item) => {
    apiCalls.saveFavoriteService(item).then((data) => {
      let newFavServiceId = data.FavoriteServiceId;
      this.updateService(item, newFavServiceId);
    }).catch((err) => {
      console.log('error', err);
    });
  }

  unfavorite = (item) => {
    apiCalls.unfavoriteService(item).then((data) => {
      // services that are not favorites have 0 for FavoriteServiceId
      let newFavServiceId = 0;
      this.updateService(item, newFavServiceId);
    }).catch((err) => {
      console.log('error', err);
    });
  }

  checkRegistration = () => {
    this.setState({ registrationUpdated: false, loading: true });
    let apiPath = 'admin/Services?ForceDeviceRefresh=TRUE';
    apiCalls.fetchData(apiPath).then((data) => {
      if (data !== null) {
        this.setState({
          loading: false,
          registrationUpdated: helper.registrationLastUpdated()
        });
      }
      else {
        this.setState({ loading: false, registrationUpdated: 'Failed to retrieve registration status: ' + moment().format('M-DD-YYYY hh:mm:ss A') })
      }
    });
  }

  gridContent = () => {
    if (this.state.loading) {
      return <DataLoadingSpinner className='load spinner' />
    } else {
      if (this.state.services && this.state.services.length > 0) {
        return (
          <Grid
            style={{ height: this.state.gridHeight + 'px' }}
            data={this.state.services}
            sortable={{ allowUnsort: true, mode: 'single' }}
            sort={this.state.sort}
            onSortChange={this.sortChange}
          >
            <Column width='40px' field='IsFav' title={icons.star} cell={this.favBtn} />
            <Column field='ServiceType' cell={this.serviceTypeCell} title={headerToolTip('Service Type')} />
            <Column field='FirstName' title={headerToolTip('First Name')} />
            <Column field='LastName' title={headerToolTip('Last Name')} />
            <Column field='ServiceNumber' cell={serviceSelector} title={headerToolTip('Service ID')} />
            <Column field='Extension' cell={serviceSelector} title={headerToolTip('Extension')} />
            <Column field='MAC' cell={MacAddressCell} title={headerToolTip('MAC')} />
            <Column field='LocationName' title={headerToolTip('Location')} />
            <Column field='Department' title={headerToolTip('Dept')} />
            <Column field='AssignedTo' title={headerToolTip('Assigned To')} />
            <Column title=' ' field='filler' filterable={false} sortable={false} />
            {/*<Column width="322px" cell={SettingsButtons} sortable={false} filterable={false} /> */}
          </Grid>
        )
      }
    }
  }

  resultsMessage = () => {
    if (this.state.resultsMsg) {
      return <h6 className='no-results-msg full-page-table'>{this.state.resultsMsg}</h6>
    }
  }

  servicesCardInlineStyle = () => {
    // offset by 30 to account for missing table headers
    if (this.state.loading) return { height: this.state.gridHeight + 100 + 'px' }
  }

  servicesContent = () => {
    return (
      <div>
        <div className="card-header">
          <h1>Services & Users
            <small>&nbsp;{this.state.services.length || null}&nbsp;</small>
            <small className='filters'>
              <span className='text-info'>
                {this.activeFilters()}
              </span>
            </small>

          </h1>
          <div className="card-actions">
            <div className="search">
              <form ref={'searchTerm'} onSubmit={e => { e.preventDefault() }}>
                <label htmlFor="services_srch_tb" className="hidden">services search</label>
                <input id="services_srch_tb" ref={'searchTerm'} type="text" className="form-control search-control" onChange={this.searchServices} autoComplete={"off"} />
                <svg width="14" height="14" viewBox="0 0 23 23"><path d="m347 238c0-36-12-66-37-91-25-25-55-37-91-37-35 0-65 12-90 37-25 25-38 55-38 91 0 35 13 65 38 90 25 25 55 38 90 38 36 0 66-13 91-38 25-25 37-55 37-90z m147 237c0 10-4 19-11 26-7 7-16 11-26 11-10 0-19-4-26-11l-98-98c-34 24-72 36-114 36-27 0-53-5-78-16-25-11-46-25-64-43-18-18-32-39-43-64-10-25-16-51-16-78 0-28 6-54 16-78 11-25 25-47 43-65 18-18 39-32 64-43 25-10 51-15 78-15 28 0 54 5 79 15 24 11 46 25 64 43 18 18 32 40 43 65 10 24 16 50 16 78 0 42-12 80-36 114l98 98c7 7 11 15 11 25z" transform="scale(0.046875 0.046875)"></path></svg>
              </form>
            </div>
            {/* <button className="btn orange">Add a Service</button> */}
          </div>
          <DeviceStatuses
            checkRegistration={this.checkRegistration}
            Services={this.state.services}
            updated={this.state.registrationUpdated}
          />
        </div>
        <div className="card-content">
          {this.gridContent()}
          {this.resultsMessage()}
        </div>
      </div>
    )
  }

  render() {
    return (
      <div className="full-card container">
        <div className="services card" style={this.servicesCardInlineStyle()} >
          {this.servicesContent()}
        </div>
      </div>
    )
  }

};

const mapStateToProps = (state) => {
  return {
    locationAddress: state.reducer.LocationAddress,
    locationAccountNumber: state.reducer.LocationAccountNumber // from location seletion in devices component
  }
}

export default connect(mapStateToProps)(withRouter(Services));