import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { Button } from '@progress/kendo-buttons-react-wrapper';
import _ from 'lodash';
import { fetchData, post } from '../../../requests/api_calls';
import { DataLoadingSpinner } from '../../../components/spinners';

class AgentDefaultSettingModal extends Component {

	constructor(props) {
		super(props);

    this.fetchAgentDefaultSettings = this.fetchAgentDefaultSettings.bind(this);
    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.updateAgentDefaultSettings = this.updateAgentDefaultSettings.bind(this);

    this.state = {
      showModal: false,
      checkForceUnavailable3: false,
      enableGuardTime: false,
      loading: false,
      settings: null,
    };
  }

  handleClose() {
    this.setState({
      showModal: false,
      checkForceUnavailable3: false,
      enableGuardTime: false,
      loading: false,
      settings: null,
    });
  }

  handleShow = async() => {
		await this.setState({ showModal: true, loading: true });
		const data = await this.fetchAgentDefaultSettings();
		await this.setState({ settings: data, loading: false });
  }

  fetchAgentDefaultSettings = async () => {
    return await fetchData('admin/enterprise/GetAgentDefaultSettings').then(agentDefaultSettings => agentDefaultSettings);
  }

  updateAgentDefaultSettings = async () => {
		await this.setState({ loading: true });
    const path = 'admin/enterprise/UpdateAgentDefaultSettings';
    const { settings } = this.state;

    try {
      const response = await post(path, "POST", JSON.stringify(settings));
      if(response.ok) {
        await this.setState({ loading: false });
      } else {
        console.error(`${path}: failed: ${response.message}`);
        this.setState({ loading: false });
      }
    } catch (error) {
      console.error(`${path}: failed: ${error}`);
      await this.setState({ loading: false });
    }
  }

  render() {
    let modalContent;
    const { loading, settings } = this.state;

    if (loading) {
      modalContent = (
        <div className='content'>
          <DataLoadingSpinner className='spinner' style={{ margin: '10px auto 0', height: '40px' }} />
        </div>
      );
    } else if (Boolean(settings && Object.keys(settings).length > 0)) {
      modalContent = (
        <div className="">
          <div className="form-group">
            <label>Use Guard Time Settings </label>
            <div className="form-control">
              <label className="custom-control custom-radio">
              <input id="radio1" name="radio" type="radio"
                checked={settings.UseSystemDefaultGuardTimer} 
                onChange={e => {
                  this.setState({
                    settings: {
                      ...settings,
                      UseSystemDefaultGuardTimer: e.target.checked ? true : false
                    } 
                  })
                }}
              />
                <span>Default</span>
              </label>
              <label className="custom-control custom-radio">
                <input id="radio1" name="radio" type="radio" 
                  checked={!settings.UseSystemDefaultGuardTimer} 
                  onChange={e => {
                    this.setState({ 
                      settings: {
                        ...settings,
                        UseSystemDefaultGuardTimer: e.target.checked ? false : true
                      } 
                    })
                  }}
                />
                <span>Enterprise</span>
              </label>
            </div>
          </div>
          <div className="form-group">
            <label className="switch right">
              <input type="checkbox"  
                checked={settings.EnableGuardTimer} 
                onChange={e => {
                  this.setState({ 
                    settings: {...settings, EnableGuardTimer: e.target.checked} 
                  })
                }}
              />
              <span className="slider round"></span>
            </label>
            Enable guard time
            {settings?.EnableGuardTimer && (
              <div className="hidden-group" style={{ margin: '5px auto 10px', padding: '10px' }}>
                <label >For how many seconds</label>
                <select className="form-control" value={settings.GuardTimerSeconds} onChange={e => this.setState({ settings: { ...settings, GuardTimerSeconds: e.target.value }})}>
                  {_.times(25, i =>
                    <option key={i} defaultValue={i}>{i+1}</option>
                  )}
                </select>
              </div>
            )}
          </div>
          <div className="form-group">
            <label>Use Agent Unavailable Settings</label>
            <div className="form-control">
              <label className="custom-control custom-radio">
              <input id="radio2" name="radio2" type="radio"
                checked={settings.UseSystemDefaultUnavailableSettings} 
                onChange={e => {
                  this.setState({ 
                    settings: {...settings, UseSystemDefaultUnavailableSettings: e.target.checked} 
                  })
                }}
              />
                <span >Default </span>
              </label> 
              <label className="custom-control custom-radio">
                <input id="radio2" name="radio2" type="radio"
                  checked={!settings.UseSystemDefaultUnavailableSettings} 
                  onChange={e => {
                    this.setState({ 
                      settings: {...settings, UseSystemDefaultUnavailableSettings: e.target.checked} 
                    })
                  }}
                />
                <span>Enterprise</span>
              </label>
            </div>
          </div>
          <div className="form-group">
            <label className="switch right">
              <input type="checkbox" 
                checked={settings.ForceAgentUnavailableOnDNDActivation} 
                onChange={e => {
                  this.setState({ 
                    settings: {...settings, ForceAgentUnavailableOnDNDActivation: e.target.checked} 
                  })
                }}
              />
              <span className="slider round"></span>
            </label>
            Force Agent to unavailable on do not disturb activation
          </div>
          <div className="form-group">
            <label className="switch right">
              <input type="checkbox" 
                checked={settings.ForceAgentUnavailableOnPersonalCalls} 
                onChange={e => {
                  this.setState({ 
                    settings: {...settings, ForceAgentUnavailableOnPersonalCalls: e.target.checked} 
                  })
                }}
              />
              <span className="slider round"></span>
            </label>
            Force Agent to unavailable on personal calls
          </div>
          <div className="form-group">
            <label className="switch right">
              <input type="checkbox" 
                checked={settings.ForceAgentUnavailableOnBouncedCallLimit} 
                onChange={e => {
                  this.setState({ 
                    settings: {...settings, ForceAgentUnavailableOnBouncedCallLimit: e.target.checked} 
                  })
                }}
              />              
              <span className="slider round"></span>
            </label>
            Force Agent to unavailable after consecutive bounces
            {settings.ForceAgentUnavailableOnBouncedCallLimit &&(
              <div className="hidden-group" style={{ margin: '5px auto 10px', padding: '10px' }}>
                <label >For how many consecutive bounces</label>
                <select className="form-control" 
                  value={settings.NumberConsecutiveBouncedCallsToForceAgentUnavailable} 
                  onChange={e => this.setState({ settings: { ...settings, NumberConsecutiveBouncedCallsToForceAgentUnavailable: e.target.value }})}>
                  {_.times(5, i =>
                    <option key={i} defaultValue={i}>{i+1}</option>
                  )}
                </select>
              </div>
            )}
          </div>
          <div className="form-group">
            <label className="switch right">
              <input type="checkbox" 
                checked={settings.ForceAgentUnavailableOnNotReachable} 
                onChange={e => {
                  this.setState({ 
                    settings: {...settings, ForceAgentUnavailableOnNotReachable: e.target.checked} 
                  })
                }}
              />
              <span className="slider round"></span>
            </label>
            Force Agent to unavailable on not reachable
          </div>
        </div>
      );
    } else {
      modalContent = <></>;
    }

    return (
      <div className="setting">
        <span onClick={this.handleShow}> 
          <span className="name">Agent Default Settings</span>
          <a className="icon btn"><svg width="14" height="14" viewBox="0 0 24 24"><path d="m163 440l-91-91 251-250 90 90z m309-352l-48-48c-12-11-32-11-45 2l-45 45 91 91 45-45c13-13 13-33 2-45z m-408 275l-32 117 117-32z" transform="scale(0.046875 0.046875)"></path></svg></a>
        </span>
        <Modal show={this.state.showModal} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Agent Default Settings</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {modalContent}
          </Modal.Body>
          <Modal.Footer>
              {!loading && (
                <>
                  <hr />
                  <div className="btns">
                    <Button className="btn" click={this.updateAgentDefaultSettings}>Save</Button>
                    <Button className="btn" click={this.handleClose}>Cancel</Button>
                  </div>
                </>
              )}
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
};

export default AgentDefaultSettingModal;
