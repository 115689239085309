import React, { Component } from "react";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { orderBy } from "@progress/kendo-data-query";
import { headerToolTip } from "../../../components/ellipsisHeaderTooltip";

export default class VoiceRoutes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      Enterprise: this.props.Enterprise,
      Trunk: this.props.Trunk,
      CurrentTrunk: {},
      VoiceRoutesList: this.props.VoiceRoutesList,
      gridHeight: window.innerHeight - 200,
      Sort: [{ field: "Identity", dir: "desc" }],
      filter: { logic: "and", filters: [] },
    };
  }

  async componentDidMount() {}

  handleSortChange = (e) => {
    this.setState({
      VoiceRoutesList: orderBy(this.state.VoiceRoutesList, e.sort),
      Sort: e.sort,
    });
  };

  gridContent = () => {
    let { VoiceRoutesList, Sort } = this.state;
    console.log("VoiceRoutesList", VoiceRoutesList);
    let priorityColWidth ='105px';
    let nameColWidth='200px';
    if (VoiceRoutesList && VoiceRoutesList.length > 0) {
      return (
        <Grid
          style={{ height: this.state.gridHeight + "px", width: "100%" }}
          data={orderBy(VoiceRoutesList, Sort)}
          sortable={{ allowUnsort: true, mode: "single" }}
          sort={Sort}
          onSortChange={this.handleSortChange}
        >
          <Column
            width={nameColWidth}
            field="Name"
            title={headerToolTip("Name", true)}
          />
          <Column
            width={nameColWidth}
            field="Identity"
            title={headerToolTip("Identity", true)}
          />
          <Column width={priorityColWidth} field="Priority" title={headerToolTip("Priority", true)} />
          <Column
            width={nameColWidth}
            field="Description"
            title={headerToolTip("Description", true)}
          />
          <Column
            field="NumberPattern"
            title={headerToolTip("NumberPattern", true)}
          />
          <Column
            field="OnlinePstnUsages"
            title={headerToolTip("OnlinePstnUsages")}
          />
          <Column
            field="OnlinePstnGatewayList"
            title={headerToolTip("OnlinePstnGatewayList", true)}
          />
        </Grid>
      );
    } else {
      return (
        <p style={{ textAlign: "center", paddingTop: "15px" }}>
          No Available Data
        </p>
      );
    }
  };

  render() {
    return this.gridContent();
  }
}
