import React, { Component, } from 'react';
import { DataLoadingSpinner } from '../components/spinners';
import { Grid, GridColumn as Column, GridCell } from '@progress/kendo-react-grid';
import * as apiCalls from '../requests/api_calls';
import _ from 'lodash';
import { orderBy } from '@progress/kendo-data-query';
import { filterBy } from '@progress/kendo-data-query';
import * as config from '../requests/config';
import { headerToolTip } from '../components/ellipsisHeaderTooltip';
import { filtercell } from '../components/dropdownFilter';
import * as helper from '../scripts/helper';
import moment from 'moment';

export default class VmReport extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      Listings: [],
      FullListings: [],

      Sort: [{ field: "VMLastAccessDateTimeDisplay", dir: "desc" }],
      filter: { logic: "and", filters: [] },
    };

    class CustomWrapperCell extends GridCell {

      render() {
        let fieldName = this.props.field;
         return (
           <td style={{ overflowWrap: "break-word" }}>
               <div>{this.props.dataItem[fieldName]}</div>  
           </td>
         )
      }
    }

    this.wrapper = CustomWrapperCell;
  }

  componentDidMount = (props) => {
    let { hasVmUsageReport } = sessionStorage;
    if (hasVmUsageReport === "true") {
      this.fetchVm();
    } else {
      // redirect to dashboard if not enabled
      this.props.history.push("/");
    }
  }

  fetchVm = async () => {
    await this.setState({ loading: true });
    let path = `vm/VmReportList`;
    await apiCalls.fetchData(path).then(data => {
      if (data && data.length > 0) {
        data = data.filter((itm) => {
          if (itm && itm.UserId) {
            itm.UserId = itm.UserId.toString();
          }
          if (itm && itm.LocationId) {
            itm.LocationId = itm.LocationId.toString();
          }
          if (itm.VMLastAccessDateTime || itm.VMLastAccessDateTime !== null) {
            return itm;
          }
        });
        data.forEach(itm => { // convert Escalated boolean to string in order to filter
          if (itm.VMLastAccessDateTime) {
            itm.VMLastAccessDateTimeDisplay = moment.utc(itm.VMLastAccessDateTime).local().format('MM-DD-YYYY hh:mm:ss A') + ` ${sessionStorage.userTimeZone}`;
          }
        });

        let sort = this.state.Sort;
        //VMLastAccessDateTimeDisplay Sort: [{ field: "VMLastAccessDateTime", dir: "desc" }],
        if (sort[0].field === 'VMLastAccessDateTimeDisplay')
          sort = [{ field: "VMLastAccessDateTime", dir: sort[0].dir }];
        let finalListings =  orderBy(data, sort);

        this.setState({ Listings: finalListings, FullListings: finalListings, loading: false });
      } else {
        this.setState({ Listings: [], FullListings: [], loading: false });
      }
    }).catch(err => console.error(err));
  }

  gridContent = () => {
    if (this.state.loading) {
      return <DataLoadingSpinner className='load spinner' />
    } else {
      if (this.state.Listings && this.state.Listings.length > 0) {
        return (
          <Grid
            style={{
              //height: `${this.state.gridHeight}px`,
            }}
            data={this.getVmListings(this.state.filter)}
            sortable={{ allowUnsort: true, mode: 'single' }}
            sort={this.state.Sort}
            onSortChange={this.sortChange}
            filterable
            filter={this.state.filter}
            onFilterChange={this.handleFilterChange}
          >
            <Column field="LocationName" title={headerToolTip("Location Name")} cell={this.wrapper} filterable={false} />
            <Column field="ServiceNumber" title={headerToolTip("Service ID")} cell={this.wrapper} filterCell={filtercell(this.dropDownOptions('ServiceNumber'))} />
            <Column field="Extension" title={headerToolTip("Extension")} cell={this.wrapper} filterable={false} />
            <Column field="FirstName" title={headerToolTip("First Name")} cell={this.wrapper} filterable={false} />
            <Column field="LastName" title={headerToolTip("Last Name")} cell={this.wrapper} filterable={false} />
            <Column field="VMTotalTodaysMessages" title={headerToolTip("Today")} cell={this.wrapper} filterable={false} />
            <Column field="VMTotalMessages" title={headerToolTip("Total")} cell={this.wrapper} filterable={false} />
            <Column field="VMNewMessages" title={headerToolTip("New")} cell={this.wrapper} filterable={false} />
            <Column field="VMSavedMessages" title={headerToolTip("Saved")} cell={this.wrapper} filterable={false} />
            <Column field="VMLastAccessDateTimeDisplay" title={headerToolTip("Last Access Date")} cell={this.wrapper} />
            <Column title=' ' field='filler' filterable={false} />
          </Grid>
        )
      } else {
        return <p style={{ textAlign: 'center', paddingTop: '15px' }}>No Available Usage Data</p>
      }
    }
  }

  getVmListings = (filter) => {
    let sort = this.state.Sort;
    //VMLastAccessDateTimeDisplay Sort: [{ field: "VMLastAccessDateTime", dir: "desc" }],
    if (sort && sort.length > 0)
    {
      if (sort[0].field === 'VMLastAccessDateTimeDisplay')
        sort = [{ field: "VMLastAccessDateTime", dir: sort[0].dir }];
      return filterBy(orderBy(this.state.Listings, sort), filter);
    } else {
      return filterBy(this.state.Listings, filter);
    }
  }

  dropDownOptions = (key) => {
    let sort = [{ field: key, dir: "asc" }];
    let listings = orderBy(this.state.Listings.slice(), sort);
    let info = [...listings.map(item => item[key]).filter(helper.onlyUnique)].filter(itm => itm);
    return info;
  }

  handleFilterChange = async (event) => {
    await this.setState({
      filter: event.filter
    });
  }

  sortChange = (event) => {

    let sort = event.sort;
    //VMLastAccessDateTimeDisplay Sort: [{ field: "VMLastAccessDateTime", dir: "desc" }],
    if (sort && sort.length > 0)
    {
      if (sort[0].field === 'VMLastAccessDateTimeDisplay')
        sort = [{ field: "VMLastAccessDateTime", dir: sort[0].dir }];

      this.setState({
        Listings: orderBy(this.state.Listings, sort),
        Sort: event.sort
      });
    } else {
      this.setState({Sort: []});
    }
  }

  exportVmBtn = () => {
    if (this.state.Listings.length > 0) {
      return (
        <div id="div-vmReport-csv" className='pull-right'
        //style={{ display: "inline-block", marginBottom: "20px" }}
        >
          <form ref='downloadCsv' target="iFrameSave" action={config.baseApiUrl + 'vm/VMReportListCSV'} method="POST">
            {/* <input type="hidden" name="LocationAccountNumber" value={this.props.match.params.LocationAccountNumber}></input>
            <input type="hidden" name="PBXUserID" value={this.state.PBXUserID} />
            <input type="hidden" name="CallType" value={this.state.call_type}></input>
            <input type="hidden" name="CallStatus" value={this.state.status_type}></input>
            <input type="hidden" name="CallCategory" value={this.state.call_category}></input>
            <input type="hidden" name="FromDate" value={this.state.from_date}></input>
            <input type="hidden" name="ToDate" value={this.state.to_date}></input> */}
            <input type="hidden" name="access_token" value={config.storedAccessToken()} className='hidden'></input>
            <input type="hidden" name="x_policy" value={config.storedXPolicy()} className='hidden'></input>
          </form>
          <iframe name="iFrameSave" height="0" width="0" className='hidden pull-right'></iframe>
          <a tabIndex="0" className='no-btn' ref='exportBtn' onClick={(e) => this.exportVmReport()} >Export VM Usage Report</a>
        </div>
      )
    } else return null;
  }

  exportVmReport = () => {
    this.refs.downloadCsv.submit();
    this.refs.exportBtn.text = 'Downloading...';
    setTimeout(() => {
      this.refs.exportBtn.text = 'Export VM Usage Report';
    }, 2000);
  }

  render() {
    return (
      <div className="vm-usage-full-report">
        <div className="container full-card">
          <div className="vm-report card">
            <div className="card-header">
              <h2 className="move">Voicemail Usage Report</h2>
            </div>
            <div className="vm-row">
              <div className='vm-item'>
                {this.exportVmBtn()}
              </div>
            </div>
            <div className="card-content">
              {this.gridContent()}
            </div>
          </div>
        </div >
      </div>
    )
  }
};