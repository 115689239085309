import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { Button } from '@progress/kendo-buttons-react-wrapper';
import { MultiSelect } from '@progress/kendo-dropdowns-react-wrapper';
import SuperLink from './../../components/super_link'
import CCLogos from './../../images/credit-card-logos.png';
import CheckOne from './../../images/check_one.gif';
import CheckTwo from './../../images/check_two.gif';
import { DatePicker } from '@progress/kendo-dateinputs-react-wrapper';


class PayModal extends Component {

  constructor(props) {
    super(props);

    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.setSchedule = this.setSchedule.bind(this);
    this.setStep = this.setStep.bind(this);
    this.state = { step: "choose", recurValue: "", showModal: false };
  }

  handleClose() {
    this.setState({ showModal: false });
  }

  handleShow() {
    this.setState({ showModal: true });
  }

  setSchedule(event){
  	this.setState({recurValue: event.target.value})
  }

  setStep(event) {
  	console.log(event.target.dataset.step)

  	if (event.target.dataset.step == 'choose') {
		this.handleClose()
  	}
  	this.setState({step: event.target.dataset.step})

  }

  componentDidMount() {   
  }

  render() {
    return (
      <span className="setting">
        <span onClick={this.handleShow}> 
      		<a className="k-button btn green" alt="make a payment button">Make a Payment</a>
        </span>

        <Modal show={this.state.showModal} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Make a Payment</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="payModal">
	            
				{this.getStep()}

			</div>
          </Modal.Body>
        </Modal>
      </span>
    );
  }

  getStep() {
  	console.log(this.state.step)
  	if (this.state.step == "completed") {
  		return (
			<div className="completed-payment">
	            <div className="form-group">
	              <label>Account Name</label> 213123123
	            </div>
	            <div className="form-group">
	              <label>Payment Amount</label> $123.32
	            </div>
	            <div className="form-group">
	              <label>Date</label> 1/22/2018
	            </div>
	            <div className="form-group">
	              <label>Payment Schedule</label> One-time Credit Card Payment
	            </div>
	            <div className="form-group">
	              <label>Transaction ID</label> AR24FG298
	            </div>
	            <SuperLink label="Print" />
			    <hr />
	            <div className="btns">
	              	<button className="btn" data-step="choose" onClick={this.setStep}>Close</button>
	            </div>
	        </div>
  		)
  	} else if (this.state.step == "confirm") {
  		return (
			<div className="confirm-payment">
	            <div className="form-group">
	              <label>Name on Card</label> John Smith
	            </div>
	            <div className="form-group">
	              <label>Card Type</label> American Express
	            </div>
	            <div className="form-group">
	              <label>Card Number</label> XXX XXX XXX 1291
	            </div>
	            <div className="form-group">
	              <label>Expiration</label> 6/19
	            </div>
	            <div className="form-group">
	              <label>Payment Amount</label> 123.32
	            </div>
	            <div className="form-group">
	              <label>Payment Schedule</label> One-time Credit Card Payment
	            </div>
              	<button className="no-btn" data-step="start" onClick={this.setStep}>Edit Payment Details</button>
	        	
			    <hr />
	            <div className="btns">
	              	<button className="btn" data-step="completed" onClick={this.setStep}>Complete Payment</button>
	              	<Button className="btn" click={this.handleClose}>Cancel</Button>
	            </div>
	        </div>
        )
    } else if (this.state.step == "start") {
  		return (
			<div className="start-payment">
				<div className="form-group">
		          <label>Account Name</label>
		          <input type="type" className="form-control" defaultValue="213123123" disabled />
		        </div>
		        <div className="form-group">
		          <label>Amount Due</label>
		          <input type="type" className="form-control" defaultValue="$123.32" disabled />
		        </div>

		        <hr />

		        <div className="form-group">
		          <label>Name on Card</label>
		          <input type="type" className="form-control" defaultValue="" />
		        </div>
		        
		        <div className="form-group">
		          	<label>Card Type</label>
		          	<select className="form-control">
		                <option>-Select-</option>
		                <option>Visa</option>
		                <option>Mastercard</option>
		                <option>American Express</option>
		                <option>Discover</option>
		          	</select>
		          	<img src={CCLogos} className="cc-logos" alt="CC-logos" />
		        </div>

		        <div className="halves">
		        	<div className="half">
			            <div className="form-group">
			              <label>Expiration</label>
			              <DatePicker
				              min={new Date(2017, 9, 1)}
				              max={new Date(2017, 10, 1)}
				              value={new Date(2017, 9, 15)}  
				              format="yyyy/MM/dd"
				              change={(e) => {console.log("value changed to:" + e.sender.value())}}
				            />
			            </div>
		        	</div>
		        	<div className="half">
			            <div className="form-group">
			              <label>Security Code</label>
			              <input type="password" className="form-control" defaultValue="" />
			            </div>
		        	</div>
		        </div>
		        <div className="form-group">
		            <div className="form-control">
						<label className="custom-control">
							<input id="radio1" name="radio" type="radio" onClick={this.setSchedule} defaultValue="recurring" selected />
							<span>Recurring Payment</span>
						</label>
						<label className="custom-control">
							<input id="radio1" name="radio" type="radio" onClick={this.setSchedule} defaultValue="once" />
							<span >One-time Payment</span>
						</label> 
		            </div>
		        </div>
		        {this.getSchedule()}
			    <hr />
	            <div className="btns">
	              	<button className="btn" data-step="confirm" onClick={this.setStep}>Next</button>
	              	<Button className="btn" click={this.handleClose}>Cancel</Button>
	            </div>
		    </div>
	    )
    } else if (this.state.step == "completed-bd") {
  		return (
			<div className="completed-payment">
	            <div className="form-group">
	              <label>Account Name</label> John Smith
	            </div>
	            <div className="form-group">
	              <label>Account Type</label> Savings
	            </div>
	            <div className="form-group">
	              <label>Routing Number</label> XXX XXX XXX
	            </div>
	            <div className="form-group">
	              <label>Account Number</label> XXX XXX XXX
	            </div>
	            <div className="form-group">
	              <label>Transaction ID</label> AR24FG298
	            </div>
	            <SuperLink label="Print" />
			    <hr />
	            <div className="btns">
	              	<button className="btn" data-step="choose" onClick={this.setStep}>Close</button>
	            </div>
	        </div>
  		)
  	} else if (this.state.step == "confirm-bd") {
  		return (
			<div className="confirm-payment">
	            <div className="form-group">
	              <label>Account Name</label> John Smith
	            </div>
	            <div className="form-group">
	              <label>Account Type</label> Savings
	            </div>
	            <div className="form-group">
	              <label>Routing Number</label> XXX XXX XXX
	            </div>
	            <div className="form-group">
	              <label>Account Number</label> XXX XXX XXX
	            </div>
              	<button className="no-btn" data-step="start-bd" onClick={this.setStep}>Edit Bank Details</button>
	        	
			    <hr />
	            <div className="btns">
	              	<button className="btn" data-step="completed-bd" onClick={this.setStep}>Complete Payment</button>
	              	<Button className="btn" click={this.handleClose}>Cancel</Button>
	            </div>
	        </div>
        )
    } else if (this.state.step == "start-bd") {
  		return (
			<div className="start-payment">
		        <div className="halves">
		        	<div className="half">
						<div className="form-group">
				          <label>Account Name</label>
				          <input type="type" className="form-control" defaultValue="213123123" />
				          <small>As it appears on your checks</small>
				        </div>
				    </div>
		        	<div className="half">
				        <div className="form-group">
				          	<label>Account Type</label>
				          	<select className="form-control">
				                <option>-Select-</option>
				                <option>Checking</option>
				                <option>Savings</option>
				          	</select>
				        </div>
				    </div>
			    </div>
		        <div className="halves">
		        	<div className="half">
						<div className="form-group">
				          <label>Routing Number</label>
				          <input type="type" className="form-control" defaultValue="213123123" />
				        </div>
				    </div>
		        	<div className="half">
						<div className="form-group">
				          <label>Account Number</label>
				          <input type="type" className="form-control" defaultValue="213123123" />
				        </div>
				    </div>
			    </div>
		        
		        <img src={CheckOne} className="checks" alt="Check" />
		        <img src={CheckTwo} className="checks" alt="Check" />

		        <h5>Recurring Payment</h5>
		        <p>Funds will be drafted no earlier than three days before your invoice's due date. Please be advised when you set up a recurring payment method any past due balance as well as current charges will be charged on your first scheduled payment date.</p>
			   
				<div className="form-group">
		          	<label> 
		          	<input type="checkbox" />
  		          	I have read and agree to the <a id="hypTermsAndConditions" href="Images/TermsConditions.pdf" target="_blank">Terms and Conditions</a></label>
		        </div>
			   <hr />
	            <div className="btns">
	              	<button className="btn" data-step="confirm-bd" onClick={this.setStep}>Next</button>
	              	<Button className="btn" click={this.handleClose}>Cancel</Button>
	            </div>
		    </div>
	    )
    } else if (this.state.step == "choose") {
  		return (
			<div className="choose-payment">
				<p>Choose a payment option, edit recurring payment information, or cancel recurring payment option.</p>
		        <div className="pay-type halves">
		        	<div className="half">
		        		<button className="no-btn" data-step="start" onClick={this.setStep}>Pay with Credit Card</button>
		        	</div>
		        	<div className="half">
		        		<button className="no-btn" data-step="start-bd" onClick={this.setStep}>Set Up Bank Draft</button>
		        	</div>
		        </div>
		    </div>
	    )
  	}
  }

  getSchedule() {
  	if (this.state.recurValue == "once") {
    	return (
	      	<div className="form-group">
              	<label>Payment Amount</label>
              	<input type="type" className="form-control" defaultValue="" />
            </div>
	    )
  	}
  }
};

export default PayModal;
