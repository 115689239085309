import React, { Component } from 'react';
import SuperLink from '../../components/super_link';
import PayModal from '../modals/payModal';
import { Button } from '@progress/kendo-buttons-react-wrapper';
import { fetchData } from '../../requests/api_calls';
import { DataLoadingSpinner } from '../../components/spinners';
import { Link } from 'react-router-dom';
import { userIsAdmin } from '../../scripts/helper';
import * as config from '../../requests/config';
import * as currency from 'currency-formatter'
import * as session from '../../scripts/session';

export default class CircuitsAlerts extends Component {

  constructor() {
    super();
    this.state = {
      loading: true,
      downloading: true
    };
  }

  componentDidMount() {
    if (userIsAdmin()) this.fetchBillingStat('admin/BillingStat');
  }

  fetchBillingStat = (apiPath, errorMsg) => {
    fetchData(apiPath).then((data) => {
      if (data !== null) {
        this.loadData(data);
      } else {
        this.setState({
          CustomerAccountNumber: sessionStorage.getItem('userEnterpriseNumber'),
          loading: false
        })
      }
    }).catch(ex => {
      this.setState({ loading: false });
    });
  }

  loadData = (data) => {
    this.setState({
      CustomerAccountNumber: data.CustomerAccountNumber,
      CustomerName: data.CustomerName,
      AccountNumber: data.AccountNumber,
      AccountBalance: currency.format(data.AccountBalance, { code: 'USD' }),
      Street1BILL: data.Street1BILL,
      Street2BILL: data.Street2BILL,
      CityBILL: data.CityBILL,
      StateBILL: data.StateBILL,
      ZipBILL: data.ZipBILL,
      InvoiceAmount: currency.format(data.InvoiceAmount, { code: 'USD' }),
      InvoiceDueDate: (new Date(data.InvoiceDueDate)).toLocaleDateString(),
      InvoiceURL: data.InvoiceURL.trim(),
      InvoiceID: data.InvoiceID,
      loading: false
    });
  }

  addressPartTwoSeparator = () => {
    if (this.state.Street2BILL) return <br />;
  }

  billingData = () => {
    if (this.state.loading) {
      return <DataLoadingSpinner className='load spinner' />
    } else {
      let address = this.state.CityBILL ? (<address>
        {this.state.Street1BILL} <br />
        {this.state.Street2BILL} {this.addressPartTwoSeparator()}
        {this.state.CityBILL}, {this.state.StateBILL} {this.state.ZipBILL}

      </address>) : '';
      let billingMessage = process.env.REACT_APP_BILLING_MESSAGE;

      return (
        <div>
          <div className="card-header">
            {/* <h2>{this.state.CustomerName}</h2> */}
            <h2>Alerts Notifications</h2>
          </div>
          <div className="card-content">
          
          </div>
        </div>
      )
    }
  }

  render() {
    return (
      <div className="billing_card top-card">
        <div className="card">
          {this.billingData()}
        </div>
      </div>
    )
  }
}

