import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { Button } from '@progress/kendo-buttons-react-wrapper';
import * as EmailValidator from 'email-validator';
import { post, fetchData } from '../../requests/api_calls'
import { store } from '../../store/store';
import * as actions from "../../store/actions";
import * as session from '../../scripts/session';
import { DataLoadingSpinner } from '../spinners';
import { Switch } from '@progress/kendo-react-inputs';

export default class MFAAuthModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      showGrabEmailForm: false,
      showModal: false,
      loading: false,
      loadingQR: false,
      loadingError: '',
      qrImageUrl: '',
      qrKey: '',
      pinCode: '',
      phoneNumber: '',
      storedPhoneNumber: '',
      verificationMethod: sessionStorage.verificationMethod,
      validationError: "",
      successMessage: "",
      isTextMessageOn: false,
      isAuthAppOn: false,
      onSuccessSMS: false,
      onSuccessSubmitPinCode: false,
      isOktaPushEnabled: false,
      oktaId: "",
    };
  }

  async componentDidMount() {
    await this.setState({ loading: true })
    const userEmail = await fetchData('usr/GetUserEmailMFA').then(data => data).catch(err => console.error(err));
    if (userEmail) {
      if (sessionStorage.verificationMethod && sessionStorage.verificationMethod != "") {
        let method = sessionStorage.verificationMethod;
        await this.setState({ verificationMethod: method });
        if (method == "sms") {
          this.setState({ isTextMessageOn: true });
        }
        if (method == "authApp") {
          let isOkta = sessionStorage.IsOktaPushEnabled == "true" ? true : false;
          if (!isOkta) {
            this.getQRImageUrl();
          }
          await this.setState({ isAuthAppOn: true, isOktaPushEnabled: isOkta });
        }
      }
      await this.setState({ loading: false });
    } else {
      this.setState({ showGrabEmailForm: true, loading: false });
    }
  }

  handleOpen = () => {
    this.componentDidMount();
    this.setState({
      showModal: true,
    });
  }

  handleClose = () => {
    this.setState({
      showModal: false,
      successMessage: "",
      validationError: "",
      pinCode: "",
      verificationMethod: "",
      isTextMessageOn: false,
      isAuthAppOn: false,
      onSuccessSMS: false,
      onSuccessSubmitPinCode: false,
      oktaId: "",
      isOktaPushEnabled: false
    });
  };

  fetchUserEmail = async () => {
    try {
      await this.setState({ loading: true });
      const path = 'usr/GetUserEmailMFA';
      const response = await fetchData(path);
      if (response.ok) {
        await this.setState({ email: response });
      } else {
        console.error(`fetchUserEmail(): failed: ${response.message}`);
        this.setState({ validationError: "Something went wrong while retrieving email.", successMessage: "", loading: false });
      }
    } catch (error) {
      console.error(`fetchUserEmail(): failed: ${error}`);
      this.setState({ validationError: "Something went wrong while retrieving email.", successMessage: "", loading: false });
    }
  }

  getQRImageUrl = async () => {
    await this.setState({ loadingQR: true });
    try {
      const apiPath = 'usr/GetQRImageUrl';
      const tsv = await fetchData(apiPath);
      this.setState({ loadingQR: false, qrImageUrl: tsv.qrImageUrl, qrKey: tsv.keyCode });
    } catch (e) {
      console.error('Failed request. Not able to get QR Image Url', e);
      this.setState({ loadingQR: false, validationError: "Something went wrong while retrieving the QR code.", isOktaPushEnabled: false });
    }
  };

  getOktaQRImageUrl = async () => {
    await this.setState({ loadingQR: true });
    try {
      const apiOktaPath = 'usr/GetOktaPushQRImage';
      const tsv = await fetchData(apiOktaPath);
      this.setState({ loadingQR: false, qrOktaImageUrl: tsv.qrImageUrl, qrKey: tsv.keyCode });
    } catch (e) {
      console.error('Failed request. Not able to get QR Image Url', e);
      this.setState({ loadingQR: false, validationError: "Something went wrong while retrieving the QR code.", isOktaPushEnabled: false });
    }
  };

  enableOktaPush = async () => {
    await this.setState({ isOktaPushEnabled: true, loadingQR: true, confirmOktaVerify: false });
    this.getOktaQRImageUrl();
  }

  submitPhoneNumber = async () => {
    try {
      this.setState({ loading: true, storedPhoneNumber: this.state.phoneNumber });
      const path = 'usr/ValidateTwoStepSmsConfig';
      const response = await post(path, "POST", JSON.stringify(this.getTFAUserData()));
      if (response.ok) {
        this.setState({ loading: false, onSuccessSMS: true, validationError: "", successMessage: "You will receive an SMS code shortly to confirm your MFA configuration. Once received, input into the textbox at the bottom and click 'Save'!", loading: false });
      } else {
        console.error(`submitPhoneNumber(): failed: ${response.message}`);
        this.setState({ loading: false, onSuccessSMS: false, validationError: "Something went wrong while sending the SMS code.", successMessage: "", loading: false });
      }
    } catch (error) {
      console.error(`submitPhoneNumber(): failed: ${error}`);
      this.setState({ loading: false, onSuccessSMS: false, validationError: "Something went wrong while sending the SMS code.", successMessage: "", loading: false });
    }
  }

  getTFAUserData = () => {
    const { qrKey, pinCode, verificationMethod, phoneNumber, storedPhoneNumber, isOktaPushEnabled, oktaId } = this.state;
    return {
      Key: qrKey,
      Code: pinCode,
      VerificationMethod: verificationMethod,
      EnableOktaPush: isOktaPushEnabled,
      Mobile: storedPhoneNumber,
      OktaId: oktaId
      //Email: "",
    };
  };

  onSubmitAuthPinCode = async () => {
    try {
      await this.setState({ loading: true, successMessage: "", validationError: "" })
      let path = "usr/SaveTwoStepVerification";
      const response = await post(path, 'POST', JSON.stringify(this.getTFAUserData()));
      if (response.ok) {
        this.setState({ validationError: "", onSuccessSubmitPinCode: true });
        store.dispatch({ type: actions.VALIDATE_MFA });
        sessionStorage.setItem('verificationMethod', this.state.verificationMethod);
        sessionStorage.setItem('IsOktaPushEnabled', false);
        this.setState({ loading: false, successMessage: "Success! You may continue with your current session. All login attempts from here on will require an access code.", validationError: "" });
      } else {
        console.log(`onSubmitAuthPinCode(): failed: ${response.message}`);
        this.setState({ loading: false, validationError: `Something went wrong while saving the configuration: ${response.message}`, successMessage: "" });
      }
    } catch (error) {
      console.error(`onSubmitAuthPinCode(): failed: ${error}`);
      this.setState({ loading: false, validationError: `Something went wrong while saving the configuration: ${error}`, successMessage: "" });
    }
  };

  onSendPushNotification = async () => {
    try {
      await this.setState({ loading: true, successMessage: "", validationError: "" })
      let path = "usr/SendOktaPushNotification?isConfigure=true";
      const response = await post(path, 'POST', JSON.stringify(this.getTFAUserData()));
      if (response.ok) {
        this.setState({ validationError: "", onSuccessSubmitPinCode: true });
        store.dispatch({ type: actions.VALIDATE_MFA });
        sessionStorage.setItem('verificationMethod', this.state.verificationMethod);
        sessionStorage.setItem('IsOktaPushEnabled', true);
        this.setState({
          loading: false,
          successMessage: "Your push request was successful!.",
          validationError: "",
          qrOktaImageUrl: ""
        });
      } else {
        console.log(`onSendPushNotification(): failed: ${response.message}`);
        this.setState({ loading: false, validationError: `Something went wrong while saving the configuration: ${response.message}`, successMessage: "" });
      }
    } catch (error) {
      console.error(`onSendPushNotification(): failed: ${error}`);
      this.setState({ loading: false, validationError: `Something went wrong while saving the configuration: ${error}`, successMessage: "" });
    }
  }

  validateEmail(email) {
    if (email && !EmailValidator.validate(email.trim()))
      return (<div className="inline inline-fail">'{email.trim()}' Not a valid email</div>);
  }

  errorContent = () => {
    if (this.state.successMessage !== "") {
      return (
        <p className='error-message' style={{ marginTop: "10px", color: "green", textAlign: "center", overflowWrap: "anywhere" }}>{this.state.successMessage}</p>
      )
    }

    if (this.state.validationError === "") {
      return;
    } else {
      return (
        <p className='error-message' style={{ marginTop: "10px", color: "red", textAlign: "center", overflowWrap: "anywhere" }}>{this.state.validationError}</p>
      )
    }
  }

  sendEmailValidate = async (email) => {
    try {
      await this.setState({ loading: true });
      let path = `usr/ValidateTwoStepEmail?p_email=${email}`;
      const response = await fetchData(path);
      if (response === 200) {
        this.setState({ loading: false, validationError: "", successMessage: "Success! You will receive a verification link to your chosen email and will be automatically logged out shortly." });
        setTimeout(() => {
          session.logout();
        }, 8000);
      } else {
        console.log(`sendEmailValidate failed: ${response.message}`);
        this.setState({ loading: false, validationError: "Something went wrong while sending the email link.", successMessage: "" });
      }
    } catch (error) {
      console.error(error);
      this.setState({ loading: false, validationError: "Something went wrong while sending the email link.", successMessage: "" });
    }
  }

  getQRImageContent() {
    const { qrImageUrl, loadingQR, isOktaPushEnabled, qrOktaImageUrl } = this.state;

    if (loadingQR) {
      return (
        <div className='content'>
          <DataLoadingSpinner className='spinner' style={{ margin: '10px auto 0', height: '40px' }} />
        </div>
      );
    }

    if (qrImageUrl && !loadingQR && !isOktaPushEnabled) {
      return (
        <img src={qrImageUrl} width={200} height={200} />
      )
    }

    if (qrOktaImageUrl && !loadingQR && isOktaPushEnabled) {
      return (
        <img src={qrOktaImageUrl} width={200} height={200} />
      )
    }

    return null;
  }

  getBodyContent() {
    let loading = this.state.loading;
    if (loading) {
      return (<DataLoadingSpinner className='load spinner' />);
    }

    let loadingError = this.state.loadingError;
    if (loadingError) {
      return (<div className="inline inline-fail text-center">{loadingError}</div>);
    }

    if (this.state.showGrabEmailForm) {
      return (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div className="mfa-modal__save-email">
            <label htmlFor="mfa-email">Please submit your email for validation:</label>
            <input
              id="mfa-email"
              className="form-control"
              onChange={(e) => this.setState({ email: e.target.value })}
              autoFocus
            />
            {this.validateEmail(this.state.email)}
            <a
              className='a btn'
              disabled={this.state.loading}
              onClick={() => {
                this.sendEmailValidate(this.state.email);
              }}
            >Validate Email</a>
          </div>
        </div>
      )
    }

    if (this.state.confirmOktaVerify && !this.state.isOktaPushEnabled) {
      return (
        <div>
          <p>Are you sure you want to enable Okta Verify? You will need to re-scan the generated QR code to enable. Any previous enrollments will be removed.</p>
          <div style={{ textAlign: "center" }}>
            <button className='a btn' onClick={this.enableOktaPush}>Yes</button>
            <button className='a btn' onClick={() => this.setState({ confirmOktaVerify: false })}>No</button>
          </div>
        </div>
      )
    }

    return (
      <div className="mfa-modal">
        <p>
          Add an extra layer of security to block unauthorized access and protect your account.
        </p>
        <p>MFA verification options:</p>
        {sessionStorage.MfaAllowSms == "true" ? <div className="mfa-modal__sms-code">
          <div style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
            <h4 style={{ margin: 0, paddingRight: '8px' }}>Text Message</h4>
            <Switch
              id='mfa-type'
              checked={this.state.isTextMessageOn}
              onChange={() => {
                this.setState({ isAuthAppOn: false, isTextMessageOn: true, verificationMethod: 'sms', isOktaPushEnabled: false });
              }}
            //disabled={sessionStorage.verificationMethod && sessionStorage.verificationMethod != "" ? true : false}
            />
          </div>
          {this.state.isTextMessageOn && (<>
            <p>Provide your phone number to get a SMS code to enable the authentication:</p>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <>
                <input
                  className="form-control"
                  id="phone-number"
                  type="phone"
                  onKeyDown={enforceFormat}
                  onKeyUp={formatToPhone}
                  onChange={(e) => this.setState({ phoneNumber: removePhoneFormatting(e.target.value) })}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      this.submitPhoneNumber();
                    }
                  }}
                  placeholder={"Enter your mobile device number!"}
                  autoFocus
                //disabled={sessionStorage.verificationMethod && sessionStorage.verificationMethod != "" ? true : false}
                />
                <Button
                  className="btn"
                  click={this.submitPhoneNumber}
                //disabled={sessionStorage.verificationMethod && sessionStorage.verificationMethod != "" ? true : false}
                >Submit</Button>
              </>
            </div>
          </>)}
        </div> : null}
        {sessionStorage.MfaAllowAuthApp == "true" ? <div className="mfa-modal__auth-app">
          <div style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
            <h4 style={{ margin: 0, paddingRight: '8px' }}>Authenticator app code</h4>
            <Switch
              id='mfa-type'
              checked={this.state.isAuthAppOn}
              onChange={() => {
                if (this.state.isOktaPushEnabled) {
                  this.setState({ isTextMessageOn: false, isAuthAppOn: true, verificationMethod: 'authApp' });
                } else {
                  this.getQRImageUrl();
                  this.setState({ isTextMessageOn: false, isAuthAppOn: true, verificationMethod: 'authApp' });
                }
              }}
            //disabled={sessionStorage.verificationMethod && sessionStorage.verificationMethod != "" ? true : false}
            />
          </div>
          {this.state.isAuthAppOn ? <>
            <div style={{ display: 'flex', flexDirection: 'column' }} >
              <p>Scan the QR code and enter the pin generated by <b>Okta</b>, <b>Google</b> or <b>Microsoft</b> authenticator app to confirm it's you.</p>
              {/* <div className="content">
                <label>
                  <input type="checkbox" checked={this.state.isOktaPushEnabled} disabled={this.state.loadingQR} onChange={() => {
                    if (this.state.isOktaPushEnabled) {
                      this.setState({ isOktaPushEnabled: false });
                      this.getQRImageUrl();
                    } else {
                      this.setState({ confirmOktaVerify: true })
                    }
                  }} />Enable Okta Push notifications. This will update the QR code.
                </label>
              </div> */}
              {this.getQRImageContent()}
            </div>
          </> : null}
        </div> : null}
        <div className="mfa-modal__save-pin">
          {/* {this.state.isAuthAppOn && this.state.isOktaPushEnabled && this.state.qrOktaImageUrl && !this.state.loadingQR ?
            <>
              <p>After you scan your QR code, press the button to send the push notification</p>
              <div style={{ textAlign: "center" }}>
                <button
                  className="a btn"
                  onClick={this.onSendPushNotification}
                >
                  Send push notification
                </button>
              </div>
            </>
            : null} */}
          {!this.state.isOktaPushEnabled && (this.state.isTextMessageOn || this.state.isAuthAppOn) ? <>
            <label htmlFor="pin-code">Please submit your code:</label>
            <input
              className="form-control"
              id="pin-code"
              onChange={(e) => this.setState({ pinCode: e.target.value })}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  this.onSubmitAuthPinCode();
                }
              }}
              autoFocus
            //disabled={sessionStorage.verificationMethod && sessionStorage.verificationMethod != "" ? true : false}
            />
            <div style={{ textAlign: "center" }}>
              <button
                className="a btn"
                onClick={this.onSubmitAuthPinCode}
              >
                Save
              </button>
            </div>
          </> : null}
        </div>
      </div>
    )
  }

  getMethod = () => {
    if (sessionStorage.verificationMethod == 'authApp') {
      if (sessionStorage.IsOktaPushEnabled == "true") {
        return "Okta Verify";
      } else {
        return "Authenticator App";
      }
    }

    if (sessionStorage.verificationMethod == 'sms') {
      return "SMS";
    }

    return "";
  }

  render() {
    const modalVerbiage = () => {
      if (sessionStorage.verificationMethod && (sessionStorage.verificationMethod !== "" || sessionStorage.verificationMethod !== null || sessionStorage.verificationMethod !== undefined)) {
        return "Manage Multi-factor Authentication"
      } else {
        return "Enable Multi-factor Authentication"
      }
    }
    return (
      <>
        <a onClick={this.handleOpen} className="no-btn">{modalVerbiage()}</a>
        <Modal show={this.state.showModal} onHide={this.handleClose} backdrop='static'>
          <Modal.Header>
            <Modal.Title>
              Enable Multi-factor Authentication
              <button className="mfa-close" style={{
                float: "right",
                border: "none",
                backgroundColor: "transparent"
              }} onClick={this.handleClose}>X</button>
            </Modal.Title>
            {/* <button style={{ float: "right" }} onClick={() => this.setState({ showModal: false })}>X</button> */}
          </Modal.Header>
          <Modal.Body>
            {this.getBodyContent()}
            {this.errorContent()}
            {sessionStorage.verificationMethod && sessionStorage.verificationMethod != ""
              ? <p className='error-message' style={{ marginTop: "10px", color: "green", textAlign: "center", overflowWrap: "anywhere" }}>{`MFA is currently enabled for this account: ${this.getMethod()}`}</p>
              : null
            }
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

function isNumericInput(event) {
  const key = event.keyCode;
  return ((key >= 48 && key <= 57) || // Allow number line
    (key >= 96 && key <= 105) // Allow number pad
  );
};

function isModifierKey(event) {
  const key = event.keyCode;
  return (event.shiftKey === true || key === 35 || key === 36) || // Allow Shift, Home, End
    (key === 8 || key === 9 || key === 13 || key === 46) || // Allow Backspace, Tab, Enter, Delete
    (key > 36 && key < 41) || // Allow left, up, right, down
    (
      // Allow Ctrl/Command + A,C,V,X,Z
      (event.ctrlKey === true || event.metaKey === true) &&
      (key === 65 || key === 67 || key === 86 || key === 88 || key === 90)
    )
};

function enforceFormat(event) {
  // Input must be of a valid number format or a modifier key, and not longer than ten digits
  if (!isNumericInput(event) && !isModifierKey(event)) {
    event.preventDefault();
  }
};

function formatToPhone(event) {
  if (isModifierKey(event)) { return; }

  const input = event.target.value.replace(/\D/g, '').substring(0, 10); // First ten digits of input only
  const areaCode = input.substring(0, 3);
  const middle = input.substring(3, 6);
  const last = input.substring(6, 10);

  if (input.length > 6) { event.target.value = `(${areaCode}) ${middle} - ${last}`; }
  else if (input.length > 3) { event.target.value = `(${areaCode}) ${middle}`; }
  else if (input.length > 0) { event.target.value = `(${areaCode}`; }
};

function removePhoneFormatting(phoneNumber) {
  return phoneNumber.replace(/\D/g, '');
}