import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { Button } from '@progress/kendo-buttons-react-wrapper';
import PricebookModal from './../modals/pricebook';
import * as helper from '../../../scripts/helper';
import { DataLoadingSpinner } from '../../../components/spinners';
import { Grid, GridColumn as Column, GridCell } from '@progress/kendo-react-grid';
import * as apiCalls from '../../../requests/api_calls';
import { orderBy } from '@progress/kendo-data-query';
import { filterBy } from '@progress/kendo-data-query';
import { AutoComplete } from '@progress/kendo-dropdowns-react-wrapper';
import _ from 'lodash';
import * as macdHelper from '../components/macd_helper';
import MACDSaveStatus from '../modals/orderResultModal';
import MACDSearch from '../components/macdSearch';
import ValidationCell from '../components/validationCell';

class LineTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isCancelDialogOpen: false,
      MomentumAccountNumber: props.MomentumAccountNumber,
      LocationName: props.LocationName,
      gridHeight: props.GridHeight,
      loading: true,
      saving: false,
      error: false,
      LineTypeServices: [],
      Listings: [],
      OriginalListings: [],
      Sort: [{ field: "ServiceNumber", dir: "asc" }],
      ValidationErrorMessages: [],
      restrictedUSOCS: require('../components/DisabledUSOCs.json'),
      productSetTypeName: '',
      toBeSaved: true,
      FirstNametoBeSaved:true,
      LastNametoBeSaved:true,
      EmailtoBeSaved:true,
      ListingNametoBeSaved:true,
      CNAMtoBeSaved:true
    };
    const updateInputValue = this.updateInputValue.bind(this);
    const updateTerminatingNumber = this.updateTerminatingNumber.bind(this);
    const updateNewLineType = this.updateNewLineType.bind(this);
    this.NewLineTypeOptions = this.NewLineTypeOptions.bind(this);
    this.sortChange = this.sortChange.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleSaveSuccess = this.handleSaveSuccess.bind(this);
    this.searchListings = this.searchListings.bind(this);
    this.closeSaveMessage = this.closeSaveMessage.bind(this);
    this.setProductSetTypeName = this.setProductSetTypeName.bind(this);


    class InputTextCell extends GridCell {
      
      render() {
        const productItem = this.props.dataItem;
        let ReadOnlyStatus = false;
        if (this.props.field === "LineFirstName" && this.props.dataItem['EditLineFirstName'] === false) ReadOnlyStatus = true;
        if (this.props.field === "LineLastName" && this.props.dataItem['EditLineLastName'] === false) ReadOnlyStatus = true;
        if (this.props.field === "LineEmail" && this.props.dataItem['EditLineEmail'] === false) ReadOnlyStatus = true;
        if (this.props.field === "ListingName" && this.props.dataItem['EditListingName'] === false) ReadOnlyStatus = true;
        if (this.props.field === "CNAM" && this.props.dataItem['EditCNAM'] === false) ReadOnlyStatus = true;

        const value = ReadOnlyStatus === true && productItem.NewLineType !== productItem.PartNum ? "" : this.props.dataItem[this.props.field];
       
          return (
            <td>
              <input type="text" maxLength={this.props.field === 'CNAM' ? 15 : 524288} disabled={ReadOnlyStatus === true ? "disabled" : ""} autoComplete={"off"} 
                onChange={(e) => { updateInputValue(productItem, this.props.field, e); }}
                style={{ width: "90%", cursor: ReadOnlyStatus === true ? 'not-allowed' : 'text' }}
                value={value}></input>
            </td>
          )             
      }
    }

    class InputCheckField extends GridCell {
      render() {
        const value = this.props.dataItem[this.props.field] === true ? true : false;
        const productItem = this.props.dataItem;

        return (
          <td>
            <input type="checkbox" disabled={this.props.dataItem['EnableOmitAddress'] === true && productItem.ListingType !== '3' && productItem.ListingType !== '4' ? "" : "disabled"}
              style={{ width: "70px" }} checked={value} onChange={(e) => { updateInputValue(productItem, this.props.field, e) }}></input>
          </td>
        )
      }
    }
    class ListingTypeCell extends GridCell {
      render() {
        let value = '';
        value = this.props.dataItem[this.props.field];
        const productItem = this.props.dataItem;

        return (
          <td>
            <select style={{ width: "115px", cursor: productItem.EnableListingType === false ? "not-allowed" : "pointer" }}
              disabled={productItem.EnableListingType === false ? "disabled" : ""} value={value}
              onChange={(e) => { updateInputValue(productItem, this.props.field, e) }}
            >
              <option value="1">LISTED</option>
              <option value="2">NONLIST</option>
              <option value="3">NONPUB</option>
              <option value="4">NOSUBMIT</option>
            </select>
          </td>
        )
      }
    }
    class NewLineTypeCell extends GridCell {
      render() {
        let value = '';
        value = this.props.dataItem['NewLineType'];
        const productItem = this.props.dataItem;
        const NewLineTypeOptionsList = this.props.dataItem['NewLineTypeList'];
        const AddOnCount = parseInt(this.props.dataItem["AddonCount"]);
        const isE911SIPTrunk = this.props.dataItem["LineTypeTicker"] === 'MTRTPKGBTA'; //allowed to change e911 did in IP Trunking
        if (AddOnCount > 0 || isE911SIPTrunk === true) 
        {
          return (
            <td title={AddOnCount > 0 ? "This record cannot be updated until all Add-Ons are removed from this service!" : "This record cannot be updated as a change to this record will disconnect services using SIP Trunk!"}>
              <select style={{ width: "175px", cursor: "not-allowed" }} disabled={"disabled"} value={value}>
                {NewLineTypeOptionsList}
              </select>
            </td>
          )
        }
        else {
          return (
            <td>
              <select style={{ width: "175px" }} value={value} onChange={(e) => { updateNewLineType(productItem, this.props.field, e) }}>
                {NewLineTypeOptionsList}
              </select>
            </td>
          )
        }

      }
    }
    class AutoCompleteCell extends GridCell {
      render() {
        let termArray = this.props.dataItem.TermNumberList ? this.props.dataItem.TermNumberList : [];
        const value = this.props.dataItem[this.props.field];
        const productItem = this.props.dataItem;
        const enableVal = productItem.EnableTerminatingNumber;
        let autoCompleteDOM = (
          <AutoComplete dataSource={enableVal ? termArray : []} placeholder={"Enter term. number"} style={{ cursor: enableVal ? "pointer" : "not-allowed" }}
            value={value} change={(e) => { updateTerminatingNumber(productItem, e, enableVal); }}
          />
        );
        if (this.props.dataItem.EnableTerminatingNumber) {
          return (
            <td>
              {autoCompleteDOM}
            </td>
          );
        } else {
          return (
            <td>
              <label style={{ textAlign: "center" }}>N/A</label>
            </td>
          );
        }
      }
    }
    this.AutoCompleteCell = AutoCompleteCell;
    this.InputTextCell = InputTextCell;
    this.InputCheckField = InputCheckField;

    this.ListingTypeCell = ListingTypeCell;
    this.NewLineTypeCell = NewLineTypeCell;
    this.ValidationCell = ValidationCell;
  }

  componentDidMount() {
    if (this.state.MomentumAccountNumber !== '')
      this.fetchListings();
  }

  async componentWillReceiveProps(props) {
    if (props) {
      if (props.MomentumAccountNumber !== this.state.MomentumAccountNumber) {
        await this.setState({ MomentumAccountNumber: props.MomentumAccountNumber, LocationName: props.LocationName, ValidationErrorMessages: [] });
        this.fetchListings();
      }
      if (props.GridHeight && props.GridHeight !== this.state.gridHeight)
        this.setState({ gridHeight: props.GridHeight });
    }
  }

  setProductSetTypeName = (productSetType) => {
    this.setState({productSetTypeName: productSetType});
  }

  fetchListings = async () => {
    this.setState({ loading: true });
    let locationName = this.state.LocationName;
    let apiPath = macdHelper.apiGetChangeLineTypeListings(this.state.MomentumAccountNumber);
    let [responseData, unformattedResponse] = await apiCalls.fetchData(apiPath).then(data => {
      if (data) {
        let termNumberList = data.TermNumberList ? (
          data.TermNumberList.map((itm, idx) => {
            return itm.ServiceNumber;
          })
        ) : [];
        //note 'Old' properties storing current values.  When the user changes Line Type we may preset the values based on Enable values
        //Enable values will enable/disable the fields for editable entry
        let formattedData = data.ServiceList.map((itm, idx) => {
          let newItm = {
            CustomerProductId: itm.CustomerProductId,
            CatalogItemId: itm.CatalogItemId,
            PartDescription: itm.PartDescription,
            PartNum: itm.PartNum,
            ServiceNumber: itm.ServiceNumber,
            Extension: itm.Extension,
            Location: locationName,
            LineName: itm.LineName,
            LineFirstName: itm.LineFirstName,
            LineLastName: itm.LineLastName,
            LineTypeTicker: itm.LineTypeTicker,
            ListingName: itm.ListingName,
            ListingType: itm.ListingType,
            OmitAddress: itm.OmitAddress,
            CNAM: itm.CNAM,
            TerminatingNumber: itm.TerminatingNumber,
            TermNumberList: termNumberList.filter(res => res !== itm.ServiceNumber),
            OldLineEmail: itm.LineEmail,
            LineEmail: itm.LineEmail,
            NewLineTypeList: this.NewLineTypeOptions(itm.PartNum, itm.NewTypeList, itm.PartDescription),
            NewLineType: itm.PartNum,
            NewCatalogItemId: itm.CatalogItemId,
            NewTypeDescription: itm.PartDescription,
            AddonCount: itm.AddonCount,
            HasChanges: false,
            ValidationMessage: '',
            EditLineFirstName: false,
            EditLineLastName: false,
            EditListingType: false,
            EditListingName: false,
            EditOmitAddress: false,
            EditCNAM: false,
            EditTerminatingNumber: false,
            EditLineEmail: false,
            RenderMandatorySymbol:this.state.mandatorySymbol
          }
          return newItm;
        });
        return [formattedData, data.ServiceList];
      } else {
        return [[], []];
      }

    });
    //await this.setState({Listings: responseData, TerminatingNumberList: terminatingNumberList});//(if we want UI to prevent duplicate Term Numbers)
    await this.setState({ Listings: responseData, LineTypeServices: responseData, OriginalListings: unformattedResponse, loading: false });
  }

  NewLineTypeOptions = (CurrentPartNum, NewList, CurrentPartDesc) => {
    let returnObj = [];
    if (NewList) {
      let sort = [{ field: "PartDescription", dir: "asc" }]
      let sortedSet = orderBy(NewList, sort);
      sortedSet = helper.blanksAfterZ(sortedSet, sort);
      let restrictedUsocs = this.state.restrictedUSOCS;
      let productSetType = this.state.productSetTypeName;

      

      returnObj = sortedSet.map((itm, idx) => {
        let restrictedItem = _.filter(restrictedUsocs, resp => resp.USOC === itm.PartNum && resp.ProductSetTypeName === productSetType);
        if (!restrictedItem || restrictedItem.length === 0 || restrictedItem[0].USOC === CurrentPartNum )
          return (
            <option key={itm.CatalogItemId} value={itm.PartNum}>{itm.PartDescription}</option>
          )
      });
      if (_.filter(sortedSet, resp => resp.PartNum === CurrentPartNum).length === 0) {
        let origOption = (<option key={CurrentPartNum} value={CurrentPartNum}>{CurrentPartDesc}</option>)
        returnObj.push(origOption);
      }
    }
    return returnObj;
  }

  updateNewLineType = async (productItem, field, e) => {
    let newValue = e.target.value;
    let updatedListings = this.state.LineTypeServices.slice();
    let itmInArray = this.state.LineTypeServices.filter(resp => resp.CustomerProductId === productItem.CustomerProductId);
    let originalItm = _.filter(this.state.OriginalListings, resp => resp.CustomerProductId === productItem.CustomerProductId);
    let validationMessages = this.state.ValidationErrorMessages;
    
    if (itmInArray)
      itmInArray = itmInArray[0];
    else
      throw 'Could not find item';

    let idx = _.indexOf(this.state.LineTypeServices, itmInArray);
    itmInArray[field] = e.target.value;
    if (newValue === productItem.PartNum) {
      //if clearing change then disable changes
      itmInArray.HasChanges = false;
      itmInArray.EditCNAM = false;
      itmInArray.EditLineEmail = false;
      itmInArray.EditLineFirstName = false;
      itmInArray.EditLineLastName = false;
      itmInArray.EditListingType = false;
      itmInArray.EditOmitAddress = false;
      itmInArray.EditTerminatingNumber = false;
      itmInArray.EditListingName = false;
      itmInArray.NewCatalogItemId = itmInArray.CatalogItemId;
      if (itmInArray.CNAM !== originalItm.CNAM || itmInArray.LineEmail !== originalItm.LineEmail || itmInArray.ListingName !== originalItm.ListingName
          || itmInArray.ListingType !== originalItm.ListingType || itmInArray.TerminatingNumber !== originalItm.TerminatingNumber || itmInArray.OmitAddress !== originalItm.OmitAddress)
      {
        itmInArray.ValidationMessage = 'Line Type must be changed to submit change for item.  Updates will be ignored.  If you wish to update the other values for this item, please choose another type of MACD.'
        validationMessages.push({
          CustomerProductId: parseInt(itmInArray.CustomerProductId),
          ValidationMessage: helper.StringReplaceAll(itmInArray.ErrorMessage, '</br>', '', false)
        });
      }
    } else {
      this.props.TabHasChanges(true);
      if (itmInArray.ValidationMessage.indexOf('Line Type must be changed to submit change for item.') > -1) {
        itmInArray.ValidationMessage = '';
        let delIdx = _.indexOf(validationMessages.map(function(e) { return e.CustomerProductId; }), itmInArray.CustomerProductId);
        validationMessages.splice(delIdx, 1);
      }

      itmInArray.HasChanges = true;
      let url = macdHelper.apiGetProductPropertiesWithParams(newValue);
      let data = await apiCalls.fetchData(url).then(data => data);
      if (data) {
        itmInArray.EditCNAM = !!+(data.show_cnam_col);
        itmInArray.CNAM = itmInArray.EditCNAM === false ? '' : itmInArray.CNAM;
        itmInArray.EditLineEmail = !!+(data.show_line_email_col);
        itmInArray.LineFirstName = itmInArray.LineFirstName === false ? '' : itmInArray.LineFirstName;
        itmInArray.EditLineFirstName = !!+(data.show_line_name_col);
        itmInArray.EditLineLastName = itmInArray.EditLineFirstName;
        itmInArray.LineLastName = itmInArray.LineLastName === false ? '' : itmInArray.LineLastName;
        itmInArray.LineEmail = itmInArray.EditLineEmail === false ? '' : itmInArray.LineEmail;
        itmInArray.ListingType = !!+(data.show_list_type_col);
        itmInArray.ListingType = itmInArray.EditListingType === false ? '4' : itmInArray.ListingType;
        if (itmInArray.ListingType === '4')
          itmInArray.ListingName = '';
          
        itmInArray.EditOmitAddress = !!+(data.show_omt_addr_col);
        itmInArray.OmitAddress = itmInArray.EditOmitAddress === false ? false : itmInArray.OmitAddress;
        itmInArray.EditTerminatingNumber = !!+(data.show_trm_num_textbox || data.show_trm_num_col);
        itmInArray.TerminatingNumber = itmInArray.EditTerminatingNumber === false ? '' : itmInArray.TerminatingNumber;

        let oldService = this.state.OriginalListings.filter(res => res.CatalogItemId === itmInArray.CatalogItemId);
        oldService = oldService[0];
        let NewCatalogItemId = oldService.NewTypeList.filter(res => res.PartNum === itmInArray.NewLineType).length > 0
          ? oldService.NewTypeList.filter(res => res.PartNum === itmInArray.NewLineType)[0].CatalogItemId
          : "";
        itmInArray.NewCatalogItemId = NewCatalogItemId;
      }
    }

    updatedListings[idx] = itmInArray;

    //update grid datasource now that the 'full' datasource is up-to-date.  
    let ListingsTemp = this.state.Listings.slice();
    let itmInListings = ListingsTemp.filter(resp => resp.CustomerProductId === productItem.CustomerProductId)[0];
    idx = _.indexOf(ListingsTemp, itmInListings);
    ListingsTemp[idx] = itmInArray;
    //setting if there are changes so that we can throw a warning message from the macd component on tab/location change
    if (updatedListings && updatedListings.filter(res => res.HasChanges === true).length === 0)
      this.props.TabHasChanges(false);
    else
      this.props.TabHasChanges(true);

    await this.setState({ LineTypeServices: updatedListings, Listings: ListingsTemp, ValidationErrorMessages: validationMessages });
  }


  handleCancel = () => {
    //display confirmation message
    let changedItems = this.state.LineTypeServices.filter(res => res.HasChanges === true);
    if (!changedItems || changedItems.length === 0) return;
    this.setState({ isCancelDialogOpen: true });
  }

  handleSave = async () => {
    let changedItems = this.state.LineTypeServices.filter(res => res.HasChanges === true);
    
    if(changedItems.filter(x=>x.EditLineFirstName === true).length != 0){
      for(let o of changedItems){
          if(o.LineFirstName === ""){
              this.setState({ saving: false , toBeSaved:false, FirstNametoBeSaved:false });
              //return;
          }
      }
    }
    if(changedItems.filter(x=>x.EditLineLastName === true).length != 0){
      for(let o of changedItems){
          if(o.LineLastName === ""){
              this.setState({ saving: false , toBeSaved:false, LastNametoBeSaved:false });
             // return;
          }
      }
    }
    if(changedItems.filter(x=>x.EditLineEmail === true).length != 0){
      for(let o of changedItems){
          if(o.LineEmail === ""){
              this.setState({ saving: false , toBeSaved:false, EmailtoBeSaved:false });
              //return;
          }
      }
    }
    if(changedItems.filter(x=>x.EditListingName === true).length != 0){
      for(let o of changedItems){
          if(o.ListingName === ""){
              this.setState({ saving: false , toBeSaved:false, ListingNametoBeSaved:false });
              //return;
          }
      }
    }
    if(changedItems.filter(x=>x.EditCNAM === true).length != 0){
      for(let o of changedItems){
          if(o.CNAM === ""){
              this.setState({ saving: false , toBeSaved:false, CNAMtoBeSaved:false });
              //return;
          }
      }
    }

    if (!changedItems || changedItems.length === 0 || this.state.toBeSaved ===false) return;
    
    await this.setState({ saving: true });
    let apiPath = macdHelper.apiSubmitChangeLineType();
    let originalListings = this.state.OriginalListings;
    
    let submittedItems = changedItems.map((itm, idx) => {
      let oldService = originalListings.filter(res => res.CustomerProductId === itm.CustomerProductId);
      oldService = oldService[0];
      return {
        OldService: oldService, //API can it be changed so we don't have to track it
        CatalogItemId: itm.NewCatalogItemId,
        ListingType: itm.ListingType,
        LineFirstName: itm.LineFirstName,
        LineLastName: itm.LineLastName,
        ListingName: itm.ListingName,
        OmitAddress: itm.OmitAddress,
        CNAM: itm.CNAM,
        LineEmail: itm.LineEmail,
        TerminatingNumber: itm.TerminatingNumber
      }
    });
    
    let reqBody = {
      LocationAccountNumber: this.state.MomentumAccountNumber,
      ServiceList: submittedItems
    }

    apiCalls.post(apiPath, 'POST', JSON.stringify(reqBody)).then((res) => {
      let validationErrors = [];
      if (res.ok === true) {
        this.handleSaveSuccess();
      } else {
        let responseObject = JSON.parse(res.message);

        if (responseObject.length && responseObject.length > 0) {
          //another error message response for missing value
          validationErrors = responseObject.map((itm, idx) => {
            if (itm.CustomerProductId && itm.CustomerProductId !== '')
              return {
                CustomerProductId: parseInt(itm.CustomerProductId),
                ValidationMessage: helper.StringReplaceAll(itm.ErrorMessage, '</br>', '', false)
              }
            else {
              throw [helper.StringReplaceAll(itm.ErrorMessage, '</br>', '', false)];
            }
          });
        } else {
          if (responseObject.MPOStatus && responseObject.MPOStatus.MPOErrorList && responseObject.MPOStatus.MPOErrorList.length > 0) {
            let ErrorList = responseObject.MPOStatus.MPOErrorList[0];
            let ErrorMessages = ErrorList.MPOResultText;
            validationErrors = ErrorMessages.map((itm, idx) => {
              if (itm.CustomerProductId && itm.CustomerProductId !== '')
                return {
                  CustomerProductId: parseInt(itm.CustomerProductId),
                  ValidationMessage: helper.StringReplaceAll(itm.ErrorMessage, '</br>', '', false)
                }
              else {
                throw [helper.StringReplaceAll(itm.ErrorMessage, '</br>', '', false)];
              }
            });
          }
        }
        if (validationErrors) {
          this.showErrorAndResetModal('Your order could not be completed due to entry errors indicated in the grid.', validationErrors);
        }
      }
    }).catch((msg) => {
      this.showErrorAndResetModal(msg);

    }).finally(() => {
      this.setState({ saving: false });
    });
  }

  handleSaveSuccess = () => {
    this.setState({ loading: true, ValidationErrorMessages: [], statusMessage: 'Your order has been saved and submitted successfully.' });
    this.props.TabHasChanges(false);
    this.fetchListings();
  }

  showErrorAndResetModal = (msg, ValidationErrorMessages = []) => {
    this.setState({ error: true, statusMessage: msg, ValidationErrorMessages: ValidationErrorMessages });
  }

  closeSaveMessage = () => {
    let serviceListings = this.state.LineTypeServices.slice();
    let gridListings = this.state.Listings.slice();
    let validationErrors = this.state.ValidationErrorMessages.slice();
    validationErrors.map((itm, idx) => {
      let itmInListings = serviceListings.filter(resp => resp.CustomerProductId === itm.CustomerProductId);
      let indexOfListing = _.indexOf(serviceListings, itmInListings[0]);
      serviceListings[indexOfListing].ValidationMessage = itm.ValidationMessage;
      itmInListings = gridListings.filter(resp => resp.CustomerProductId === itm.CustomerProductId);
      indexOfListing = _.indexOf(gridListings, itmInListings[0]);
      gridListings[indexOfListing].ValidationMessage = itm.ValidationMessage;
    });
    this.setState({ error: false, statusMessage: '', Listings: gridListings, LineTypeServices: serviceListings });
  }

  CancelYesAction = async () => {
    //clear all editable fields
    this.fetchListings();
    this.props.TabHasChanges(false);
    this.setState({ isCancelDialogOpen: false, ValidationErrorMessages: [] });

  }

  closeCancelDialog = () => {
    this.setState({ isCancelDialogOpen: false });
  }

  updateInputValue = (productItem, field, e, isNumeric) => {
    let newValue = "";
    if(field === "LineFirstName"){
      if(e.target.value === ''){
        this.setState({toBeSaved:false,FirstNametoBeSaved:false})
      }
      else{
        this.setState({toBeSaved:true,FirstNametoBeSaved:true})
      }
    }
    if(field === "LineLastName"){
      if(e.target.value === ''){
        this.setState({toBeSaved:false,LastNametoBeSaved:false})
      }
      else{
        this.setState({toBeSaved:true,LastNametoBeSaved:true})
      }      
    }
    if(field === "LineEmail"){
      if(e.target.value === ''){
        this.setState({toBeSaved:false,EmailtoBeSaved:false})
      }
      else{
        this.setState({toBeSaved:true,EmailtoBeSaved:true})
      }
    }
    if(field === "ListingName"){
      if(e.target.value === ''){
        this.setState({toBeSaved:false,ListingNametoBeSaved:false})
      }
      else{
        this.setState({toBeSaved:true,ListingNametoBeSaved:true})
      }
    }
    if(field === "CNAM"){
      if(e.target.value === ''){
        this.setState({toBeSaved:false,CNAMtoBeSaved:false})
      }
      else{
        this.setState({toBeSaved:true,CNAMtoBeSaved:true})
      }
    }
    if (field === "OmitAddress") {
      newValue = productItem[field] === true ? false : true;
    } else if (field === "ListingType") {
      //if listing type is updated. we have to clear and disable certain fields based on selection
      newValue = isNumeric && isNumeric === true ? parseInt(e.target.value) : e.target.value;
      let enableOmitAddress = false;
      let enableListingName = true;
      let listingName = productItem.ListingName, omitAddress = productItem.OmitAddress;

      if (newValue === "4") {
        listingName = "";
        omitAddress = false;
        enableListingName = false;
      } else if (newValue === "3") {
        omitAddress = false;
      } else
        enableOmitAddress = true;

      productItem.ListingName = listingName;
      productItem.EnableListingName = enableListingName;
      productItem.EnableOmitAddress = enableOmitAddress;
      productItem.OmitAddress = omitAddress;
    }
    else {
      newValue = isNumeric && isNumeric === true ? parseInt(e.target.value) : e.target.value;
    }
    productItem[field] = newValue;
    productItem.HasChanges = true;
    let existingArray = this.state.LineTypeServices;
    let index = _.findIndex(existingArray, s => (s.ServiceNumber === productItem.ServiceNumber || s.Extension === productItem.Extension)
      && s.CustomerProductId === productItem.CustomerProductId);

    let newOptions = this.state.LineTypeServices.slice();
    newOptions[index] = productItem;


    //update grid datasource now that the 'full' datasource is up-to-date.  
    let ListingsTemp = this.state.Listings.slice();
    let itmInListings = ListingsTemp.filter(resp => resp.CustomerProductId === productItem.CustomerProductId);
    index = _.indexOf(ListingsTemp, itmInListings);
    ListingsTemp[index] = productItem;
    this.setState({ LineTypeServices: newOptions, Listings: ListingsTemp });
  }
  updateTerminatingNumber = (productItem, e, enableVal) => {
    let newVal = ''
    if (enableVal) {
      if (e.sender.element[0].value === '' || isNaN(e.sender.element[0].value)) {
        newVal = '';
        e.sender.element[0].value = '';
      } else
        newVal = parseInt(e.sender.element[0].value);

      productItem.TerminatingNumber = newVal;
      productItem.HasChanges = true;
      let existingArray = this.state.LineTypeServices;
      let index = _.findIndex(existingArray, s => s.CustomerProductId === productItem.CustomerProductId);

      let newOptions = this.state.LineTypeServices.slice();
      newOptions[index] = productItem;


      //update grid datasource now that the 'full' datasource is up-to-date.  
      let ListingsTemp = this.state.Listings.slice();
      let itmInListings = ListingsTemp.filter(resp => resp.CustomerProductId === productItem.CustomerProductId);
      index = _.indexOf(ListingsTemp, itmInListings);
      ListingsTemp[index] = productItem;
      this.setState({ LineTypeServices: newOptions, Listings: ListingsTemp });
    }
  }

  getListings = () => {
    let gridListings = this.sortListings(this.state.Sort, this.state.Listings);
    return gridListings;
  }

  sortListings(sort, Listings = null) {
    let returnObj = [];
    if (this.state.Listings) {
      if (Listings == null) Listings = this.state.Listings.slice();
      let sortedSet = orderBy(Listings, sort);
      returnObj = sortedSet;
      if (sort.length) {
        returnObj = helper.blanksAfterZ(sortedSet, sort);
      }
    }
    return returnObj;
  }

  async sortChange(event) {
    await this.setState({
      Listings: this.sortListings(event.sort),
      Sort: event.sort
    });
  }

  searchListings = (event) => {
    let searchText = event.target.value.trim();
    //console.log("Search Text: " + searchText);
    let fullDetails = this.state.LineTypeServices;
    if (searchText !== '') {
      searchText = event.target.value;
      let services = filterBy(fullDetails, {
        logic: 'or',
        filters: [
          { field: 'ServiceNumber', operator: 'contains', value: searchText, ignoreCase: true },
          { field: 'Extension', operator: 'contains', value: searchText, ignoreCase: true },
          { field: 'LineName', operator: 'contains', value: searchText, ignoreCase: true },
          { field: 'PartDescription', operator: 'contains', value: searchText, ignoreCase: true }
        ]
      });

      this.setState({ Listings: services })
    }
    else {
      this.setState({ Listings: fullDetails });
    }

  }

  gridContent = () => {
    if (this.state.MomentumAccountNumber === '') {
      return macdHelper.locationSelectLabel(this.state.MomentumAccountNumber);
    }
    else if (this.state.loading) {
      return (
        <DataLoadingSpinner className="load spinner relative" />
      );
    }
    else if(!this.state.loading && this.state.Listings.length === 0) return <p>There are no applicable changes available for services at this location. Please review your search criteria.</p>
    else {
      return (
        <div>
           <p className='error-message' style={{color: "red"}}>{this.state.FirstNametoBeSaved?null:"First Name is empty"}</p>
           <p className='error-message' style={{color: "red"}}>{this.state.LastNametoBeSaved?null:"Last Name is empty"}</p>
           <p className='error-message' style={{color: "red"}}>{this.state.EmailtoBeSaved?null:"Email is empty"}</p>
           <p className='error-message' style={{color: "red"}}>{this.state.ListingNametoBeSaved?null:"Listing Name is empty"}</p>
           <p className='error-message' style={{color: "red"}}>{this.state.CNAMtoBeSaved?null:"CNAM is empty"}</p>
        <Grid
          style={{ height: this.state.gridHeight }}
          data={this.getListings()}
          sortable={{ allowUnsort: true, mode: 'single' }}
          sort={this.state.Sort}
          onSortChange={this.sortChange}
        >
          {this.state.ValidationErrorMessages && this.state.ValidationErrorMessages.length > 0 ? <Column field='ValidationMessage' title=" " width={'50px'} cell={this.ValidationCell} sortable={false} /> : null}
          <Column width="140px" field='PartDescription' title='Product' />
          <Column width="100px" field='ServiceNumber' title='Service ID' />
          <Column width="90px" field='Extension' title='Extension' />
          <Column width="100px" field='LineName' title='Line Name' />
          <Column width="100px" field='Location' title='Location' />
          <Column width="190px" field='NewLineType' cell={this.NewLineTypeCell} title='Line Type' sortable={false} />
          <Column width="130px" field='ListingType' cell={this.ListingTypeCell} title='Listing Type' sortable={false} />
          <Column width="110px" field='OmitAddress' cell={this.InputCheckField} title='Omit Address' sortable={false} />
          <Column width="130px" field='ListingName' cell={this.InputTextCell} title='Listing Name' sortable={false} /><span><p>Testing colmn</p></span>
          <Column width="130px" field='LineFirstName' cell={this.InputTextCell} title='First Name' sortable={false} />
          <Column width="130px" field='LineLastName' cell={this.InputTextCell} title='Last Name' sortable={false} />
          <Column width="130px" field='CNAM' cell={this.InputTextCell} title='Caller ID [CNAM]' sortable={false} />
          <Column width="150px" field='TerminatingNumber' sortable={false} cell={this.AutoCompleteCell} title='Terminating Number' />
          <Column width="150px" field='LineEmail' cell={this.InputTextCell} title='Line Email' sortable={false} />
          <Column field="spacer" title=" " sortable={false} filterable={false} />
        </Grid>
        </div>
      )
    }
  }

  render() {

    return (
      <div>
        <div style={{ "marginTop": "10px" }}>
          <p>Attention: This order is subject to applicable <span style={{ color: 'red' }}>non-recurring charges</span>.</p>
          <p>There will be a <span style={{ color: 'red' }}>short loss of service</span>. Devices sharing call appearances will be rebooted after submission. If the automatic reboot fails, you will be required to manually reboot telephone devices.</p>
          <p>If you are switching from an Executive, Advanced, Basic, or Smart to another Executive, Advanced, Basic, or Smart seat, please use the “Seat Type” tab. There will be no loss of service or need to reboot a device.</p>
          <p>For any virtual number requests, the terminating TN cannot be a number included in this order. You may need to issue all the virtual number requests in a separate "Line Type" service change.</p>
          <p>If any user level add-ons are assigned to a line, you will need to un-assign user level add-ons first. Please wait to assign user level add-ons to a number until after the service change is completed.</p>
          <p>Please check the table to see which lines receive listings and 911:</p>
          <table className='line-type-tab-table'>
            <thead>
              <tr>
                <th>Listings</th>
                <th>911</th>
                <th>Neither</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Auto-Attendants</td>
                <td>Auto-Attendants</td>
                <td>All Other Lines</td>
              </tr>
              <tr>
                <td>Executive</td>
                <td>Executive</td>
                <td></td>
              </tr>
              <tr>
                <td>Advanced</td>
                <td>Advanced</td>
                <td></td>
              </tr>
              <tr>
                <td>Basic</td>
                <td>Basic</td>
                <td></td>
              </tr>
              <tr>
                <td>Hunt Groups</td>
                <td>Hunt Groups</td>
                <td></td>
              </tr>
              <tr>
                <td>Contact Center Queues</td>
                <td>Contact Center Queues</td>
                <td></td>
              </tr>
              <tr>
                <td>Smart Numbers</td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>E911 DID's</td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>DID's</td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>Virtual Numbers</td>
                <td></td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>
        <hr />
        <div style={{ "textAlign": "right", "marginBottom": "5px", "marginTop": "5px", "display": this.state.loading || this.state.MomentumAccountNumber === '' ? "none" : "block" }}>
          <div className="search">
            <MACDSearch SearchFunction={this.searchListings} />
            <PricebookModal MomentumAccountNumber={this.state.MomentumAccountNumber} SetProductSetTypeName={this.setProductSetTypeName} />
            <Button click={this.handleCancel}>Cancel</Button>
            {this.state.toBeSaved?<Button click={this.handleSave}>Save</Button>:null}
          </div>
        </div>
        <hr />



        <Modal dialogClassName="confirm-cancel-macd" show={this.state.isCancelDialogOpen} onHide={this.closeCancelDialog}>
          <Modal.Header closeButton>
            <Modal.Title>Cancel Order?</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div><p><span style={{ fontSize: "medium" }}>Are you sure you wish to cancel this order?</span></p></div>
          </Modal.Body>
          <Modal.Footer>
            <Button click={(evt) => { this.CancelYesAction() }}>Yes</Button>
            <Button click={(evt) => { this.closeCancelDialog() }}>No</Button>
          </Modal.Footer>
        </Modal>
        <MACDSaveStatus
          saving={this.state.saving}
          statusMessage={this.state.statusMessage}
          error={this.state.error}
          parentCallBack={this.closeSaveMessage}
        />
        {this.gridContent()}

      </div>
    );
  }
};

export default LineTab;