import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { Button } from '@progress/kendo-buttons-react-wrapper';
import _ from 'lodash';

class DistributionListModal extends Component {

  constructor(props) {
    super(props);

    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleAddPhoneNumber = this.handleAddPhoneNumber.bind(this);
    this.handleAddList = this.handleAddList.bind(this);
    this.state = { showModal: false, numberOfPhoneCount:1, numberOfPhoneLimit:10, numberOfListCount: 1, numberOfListLimit: 15 };
  }

  handleClose() {
    this.setState({ showModal: false });
  }

  handleShow() {
    this.setState({ showModal: true });
  }
  handleAddPhoneNumber(){
    if(this.state.numberOfPhoneCount < 10)
      this.setState({ numberOfPhoneCount: this.state.numberOfPhoneCount + 1 });
  }
  handleAddList(){
    if(this.state.numberOfListCount < 15)
      this.setState({ numberOfListCount: this.state.numberOfListCount + 1 });
  }

  componentDidMount() {   
  }

  render() {
    let phoneNumberCountReachLimit = this.state.numberOfPhoneCount >= this.state.numberOfPhoneLimit;
    let listCountReachLimit = this.state.numberOfListCount >= this.state.numberOfListLimit;
    return (
      <div className="setting">
        <span onClick={this.handleShow}> 
          <span className="name">Distribution List</span>
          <a className="icon btn"><svg width="14" height="14" viewBox="0 0 24 24"><path d="m163 440l-91-91 251-250 90 90z m309-352l-48-48c-12-11-32-11-45 2l-45 45 91 91 45-45c13-13 13-33 2-45z m-408 275l-32 117 117-32z" transform="scale(0.046875 0.046875)"></path></svg></a>
        </span>

        <Modal show={this.state.showModal} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Distribution List</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="distributionList">
              <p>Distribution lists allow you to send a message to multiple people. You can have up to 15 lists.</p>
              <table className="table">
                <thead>
                  <tr>
                    <th className="check"><input type="checkbox" /></th>
                      <th>Distribution List</th>
                      <th></th>
                  </tr>
                </thead>
                  {_.times(this.state.numberOfListCount, i =>
                    <tbody key={i}>
                      <tr>
                        <td className="check"><input type="checkbox" /></td>
                        <td>Soccer Team</td>
                        <td align="right"><a className="icon btn" data-toggle="collapse" data-target={"#" + i}><svg width="14" height="14" viewBox="0 0 24 24"><path d="m163 440l-91-91 251-250 90 90z m309-352l-48-48c-12-11-32-11-45 2l-45 45 91 91 45-45c13-13 13-33 2-45z m-408 275l-32 117 117-32z" transform="scale(0.046875 0.046875)"></path></svg></a></td>
                      </tr>
                      <tr className="collapse" id={i}>
                        <td colSpan="3">
                          <div className="table-edit">
                            <a href="" className="red delete">Delete Distribution List</a>
                            <table className="table">
                              <thead>
                                <tr>
                                  <th className="check"><input type="checkbox" /></th>
                                  <th>Phone Number / SIPURI</th>
                                </tr>
                              </thead>
                              <tbody>
                                  {_.times(this.state.numberOfPhoneCount, i =>
                                    <tr key={i}>
                                      <td className="check"><input type="checkbox" /></td>
                                      <td><input type="text" className="form-control" defaultValue="123 123 1234" /></td>
                                    </tr>
                                  )}
                                  <tr key={i}>
                                    <td colSpan="2">
                                      <div className="btns">
                                        <a className="btn" style={{visibility: phoneNumberCountReachLimit ? 'hidden':'visible' }} onClick={this.handleAddPhoneNumber}>Add additional phone number</a>
                                      </div>
                                    </td>
                                  </tr>
                              </tbody>
                            </table>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  )}
                  <tbody>
                    <tr>
                      <td colSpan="5">
                        <div className="btns">
                          <a className="btn" style={{visibility: listCountReachLimit ? 'hidden':'visible' }} onClick={this.handleAddList}>Add Distribution List</a>
                        </div>
                      </td>
                    </tr>
                  </tbody>
              </table>
            </div>
          </Modal.Body>
          <Modal.Footer>
              <hr />
              <div className="btns">
              <Button className="btn" click={this.handleClose}>Close</Button>
              </div>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
};

export default DistributionListModal;
