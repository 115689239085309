import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { Button } from '@progress/kendo-buttons-react-wrapper';
import SetPrompts from './setPrompts';

class SetSubmenu extends Component {

  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {   
  }
  render() {
    
    return (
      <span className="">

        <table className="submenus table">
          <thead>
            <tr>
              <th>Submenu ID</th>
              <th>In Use</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td>6381</td>
              <td align="right">
                <a className="icon btn"  data-toggle="collapse" data-target="#ss1"><svg width="14" height="14" viewBox="0 0 24 24"><path d="m163 440l-91-91 251-250 90 90z m309-352l-48-48c-12-11-32-11-45 2l-45 45 91 91 45-45c13-13 13-33 2-45z m-408 275l-32 117 117-32z" transform="scale(0.046875 0.046875)"></path></svg></a>
              </td>
            </tr>
            <tr className="collapse" id="ss1">
              <td colSpan="3">
                <div className="table-edit">
                  <div className="form-group">
                    <label>Submenu ID</label>
                    <input type="type" className="form-control" defaultValue="" />
                  </div>
                  <SetPrompts />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </span>
    );
  }

};

export default SetSubmenu;
