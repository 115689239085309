export const serviceFilters = {
    USER_FAV: 'USER_FAVORITE',
    DEVICE_REGISTERED: 'DEVICE_REGISTERED',
    DEVICE_UNREGISTERED: 'DEVICE_UNREGISTERED',
    DEVICE_ASSIGNED: 'DEVICE_ASSIGNED',
    DEVICE_UNASSIGNED: 'DEVICE_UNASSIGNED',
    UNASSIGNED: 'DEVICE_UNASSIGNED',
    ALL_SERVICES_USERS: 'ALL_SERVICES_USERS',
    ALL_SERVICES: 'ALL_SERVICES',
    ALL_USERS: 'ALL_USERS',
    IP_TRUNK_ASSIGNED_DID: 'IP_TRUNK_ASSIGNED_DID'
}

// export const ticketingFilters = {
//     ESCALATED: 'ESCALATED',
//     PENDING_CUSTOMER: 'PENDING_CUSTOMER',
//     RECENT_CLOSED: 'RECENT_CLOSED',
//     RECENT_UPDATED: 'RECENT_UPDATED',
//     USER_FAV: 'USER_FAVORITE',

// }

export const filterLabel = (filter, TrunkGroupId = '') => {
    switch (filter) {
        case serviceFilters.REGISTERED:
            return 'devices registered';
        case serviceFilters.UNREGISTERED:
            return 'devices unregistered';
        case serviceFilters.ASSIGNED:
            return 'devices provisioned';
        case serviceFilters.ALL_SERVICES_USERS:
            return 'all services and users';
        case serviceFilters.ALL_SERVICES:
            return 'all services';
        case serviceFilters.ALL_USERS:
            return 'all users';
        case serviceFilters.IP_TRUNK_ASSIGNED_DID:
            return sessionStorage.AssignedDIDTrunkID ? 'Assigned DIDs to IP Trunk Group ' + sessionStorage.AssignedDIDTrunkID  : 'Assigned DIDs to Trunk Groups';
        // case ticketingFilters.ESCALATED:
        //     return 'escalated tickets';
        // case ticketingFilters.PENDING_CUSTOMER:
        //     return 'pending customer tickets';
        // case ticketingFilters.RECENT_CLOSED:
        //     return 'recently closed tickets';
        // case ticketingFilters.RECENT_UPDATED:
        //     return 'recently updated tickets';
        // case ticketingFilters.USER_FAV:
        //     return 'starred tickets';
        default:
            return filter;
    }
}


export const filterList = (filters) => {
    return filters.map(filter => {
        if (filter !== '')
            return filterLabel(filter)
        }).join(', ')
    ;
}
