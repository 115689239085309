import React, { Component } from 'react';
import { DataLoadingSpinner } from '../../../components/spinners';
import { DataViz } from './d3-line-chart';

export default class ExpandCircuitChart extends React.Component {
  constructor(props) {
    super(props);


    this.state = {
      DateRange: this.props.DateRange,
      Measurement: this.props.Measurement,
      Type: this.props.Type,
      AggWindow: this.props.AggWindow,
    };
  }

  testChart = () => {
    if (this.props.DateRange == "-1d" || this.props.DateRange == "-2d") {
      return (
        <div className="k-chart-holder">
          <div id={`${this.props.GraphId}-wrapper`}>
            <DataViz
              data1={this.props.graphData[0]}
              data2={[]}
              summary={this.props.graphData[1]}
              id={this.props.id}
              DateRange={this.state.DateRange}
              AggWindow={this.state.AggWindow}
              Measurement={this.state.Measurement}
              Type={this.state.Type}
              axisLabel={this.props.axisLabel}
              aspect={"0 0 660 100"}
            />
          </div>
        </div>
      )
    } else {
      return (
        <div className="k-chart-holder">
          <div id={`${this.props.GraphId}-wrapper`}>
            <DataViz
              data1={this.props.graphData[0]}
              data2={this.props.graphData[1]}
              summary={this.props.graphData[2]}
              id={this.props.id}
              DateRange={this.state.DateRange}
              AggWindow={this.state.AggWindow}
              Measurement={this.state.Measurement}
              Type={this.state.Type}
              axisLabel={this.props.axisLabel}
              aspect={"0 0 660 100"}
            />
          </div>
        </div>
      )
    }
  }

  cardContent = () => {
    if (this.props.graphData && this.props.graphData.length > 0 && !this.state.loading) {
      return (
        <div className="card-content">
          <div className="activity-actions">
            <div className="activity-action">
              <div className='filter'>
                <select
                  className="circuit-dates form-control"
                  onChange={async (e) => {
                    let { value } = e.target;
                    let Type = this.state.Type;
                    let AggWindow = "D";
                    if (Type !== "upDown") {
                      if (value == "-7d") {
                        Type = `${this.state.Measurement}_${this.state.Type},avg_${this.state.Type}`;
                        AggWindow = "W";
                      } else if (value == "-30d") {
                        Type = `${this.state.Measurement}_${this.state.Type},avg_${this.state.Type}`;
                        AggWindow = "M";
                      } else if (value == "-365d") {
                        Type = `${this.state.Measurement}_${this.state.Type},avg_${this.state.Type}`;
                        AggWindow = "Y";
                      } else {
                        Type = this.props.defaultType;
                        AggWindow = "D";
                      }
                    }
                    await this.setState({ loading: true, DateRange: e.target.value, Type: Type, AggWindow: AggWindow });
                    await this.props.updateGraphUrl(this.state.DateRange, Type, this.state.AggWindow, this.state.Measurement);
                    await this.setState({ loading: false });
                  }}
                  value={this.state.DateRange}
                >
                  <option value={"-1d"}>Today</option>
                  <option value={"-2d"}>Yesterday</option>
                  <option value={"-7d"}>Last 7 Days</option>
                  <option value={"-30d"}>Last 30 Days</option>
                  <option value={"-365d"}>Last 365 Days</option>
                </select>
              </div>

              {this.state.DateRange === "-1d" || this.state.DateRange === "-2d" || this.props.Type === "upDown" ? null : <div className="filter">
                <select className="form-control"
                  onChange={async (e) => {
                    let { value } = e.target;
                    let Type = `${value}_${this.props.Type},avg_${this.props.Type}`;
                    await this.setState({ loading: true, Measurement: value, Type: Type });
                    await this.props.updateGraphUrl(this.state.DateRange, Type, this.state.AggWindow, value);
                    await this.setState({ loading: false });
                  }}
                  value={this.state.Measurement}
                >
                  <option value={"max"}>Max</option>
                  <option value={"min"}>Min</option>
                </select>
              </div>}
            </div>
          </div>
          {this.testChart()}
        </div>
      )
    } else {
      return <DataLoadingSpinner className='load spinner' />
    }
  }

  render() {
    return (
      <div className="container">
        <div className="card-header">
        </div>
        {this.cardContent()}
      </div>
    );
  }
}