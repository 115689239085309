import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import * as apiCalls from '../../../requests/api_calls';
import { DataLoadingSpinner } from '../../../components/spinners';

export default class ConfirmDeleteAttachmentModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            readOnly: props.ReadOnly,
            showModal: false,
            loading: false,
            completed: false,
        }

    }

    removeAttachment = async (attachmentID) => {
        let filteredList = this.props.AttachmentList.filter(itm => itm.AttachmentId !== attachmentID);
        this.props.refresh(filteredList);
    }

    deleteAttachment = async (attachmentID) => {
        await this.setState({ loading: true });
        let apiPath = 'admin/DeleteAttachment';
        let response = await apiCalls.post(apiPath, 'POST', JSON.stringify(attachmentID)).then(data => data).catch(err => console.error(err));
        if (response) {
            if (response.ok) {
                this.setState({ message: 'Success!', loading: false, completed: true });
            } else {
                this.setState({ message: 'Incomplete', loading: false, completed: true });
            }
        }
    }

    handleShow = () => {
        this.setState({ showModal: true });
    }

    handleClose = (attachmentID) => {
        if (this.state.message === 'Success!') {
            this.removeAttachment(attachmentID);
        }
        this.setState({ showModal: false, completed: false, message: '' });
    }

    modalFooter = () => {
        if (this.state.loading && !this.state.completed) {
            return <DataLoadingSpinner className='load spinner' style={{
                position: 'relative'
            }} />;
        } else if (this.state.completed) {
            return (
                <div>
                    <div style={{ textAlign: 'center', color: this.state.message === 'Success!' ? 'green' : 'red' }}>
                        {this.state.message}
                    </div>
                    <button className="btn" onClick={() => this.handleClose(this.props.ID)}>Close</button>
                </div>
            );
        } else {
            return <span>
                <button type="button" className="btn" onClick={() => this.deleteAttachment(this.props.ID)}>Yes</button>
                <button className="btn" onClick={this.handleClose}>No</button>
            </span>;
        }
    }

    render() {
        if (!this.state.readOnly)
        {
            return (
                <span>
                    <span onClick={this.handleShow}>
                        <a style={{
                            float: 'right',
                            color: 'red',
                        }}
                        >x</a>
                    </span>
                    <Modal show={this.state.showModal} onHide={this.handleClose} backdrop='static'>
                        <Modal.Header>
                            <Modal.Title>Are you sure you want to delete this attachment?</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div style={{ textAlign: 'center' }}>{this.props.FileName}</div>
                            
                        </Modal.Body>
                        <Modal.Footer>
                            <hr />
                            <div className="btns">
                                {this.modalFooter()}
                            </div>
                        </Modal.Footer>
                    </Modal>
                </span>
            );
        } else return null;
    }
}