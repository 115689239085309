import * as config from "./../requests/config";
import * as settings from "./settings";
import * as helper from "./helper";
import jwtDecode from "jwt-decode";

export const loggedIn = () => {
  return sessionStorage.loggedIn ? true : null;
};

export const storedAccessToken = () => sessionStorage.accessToken;

export const urlEncodedToken = () => {
  return encodeURI(sessionStorage.accessToken);
}

export const getEnterpriseId = () => { return sessionStorage.userEnterpriseNumber; };

export const getExpirationForEmb = () => {
  let d = new Date(0);
  d.setUTCMilliseconds(sessionStorage.expiration * 1000);
  let month = d.getMonth();
  if (month === 12)
    month = 1;
  else
    month += 1;
  let minutes = d.getMinutes();
  if (minutes < 10)
    minutes = '0' + minutes.toString();
  let formattedDate = month.toString() + '/' + d.getDate() + "/" + d.getFullYear() + " " + d.getHours() + ":" + minutes;
  return formattedDate; //may need to be URIEncoded too
};

export const storedXPolicy = () => sessionStorage.xpolicy;

export const tokenIsValid = () => {
  //console.log('valid token check from ' + methodName);
  if (sessionStorage.accessToken) {

    let decodedToken = jwtDecode(sessionStorage.accessToken);
    let currentTime = new Date();
    let expirationOnCurrentToken = decodedToken.exp;
    const d = new Date(0);
    d.setUTCSeconds(expirationOnCurrentToken);

    if (currentTime > d) {
      sessionStorage.setItem('tokenIsValid', false);
      return false;
    }
    else if (sessionStorage.tokenIsValid) {
      return sessionStorage.tokenIsValid && sessionStorage.tokenIsValid.toString().toLowerCase() === 'true' ? true : false;
    }
    else {
      console.log('token is invalid because it is not in sessionStorage')
      return false;
    }
  }
  return false;

}

export const isNewSession = () => helper.atLoginPath() && loggedIn();

export const isTestSession = () => helper.toBoolean(sessionStorage.testSession);

export const isRpxSession = () => helper.toBoolean(sessionStorage.RPXLogin);

export const storedUserType = () => sessionStorage.UserType;

export const IsWholesale = () => sessionStorage.IsWholesale === "true";

export const customLogoSmall = () => sessionStorage.customLogoSmall;

export const customLogoLarge = () => sessionStorage.customLogoLarge;

export const storedVendorId = () => sessionStorage.vendorId;

export const tokenUpdater = requestToken => {
  if (!sessionStorage.loggedIn) return false;
  if (requestToken) {
    if (isRpxSession()) {
      rpxTokenUpdater();
    } else {
      silenTokenUpdater();
    }
  }
};

export const parsedUsername = () => {
  if (sessionStorage.username) return sessionStorage.username;
  //if (sessionStorage.username) {
  //return sessionStorage.username.split('@')[0];
};

export const parsedEmulatedUser = () => {
  if (sessionStorage.username) return sessionStorage.emulatedUser;
};

export const silenTokenUpdater = async () => {
  let rpxEmulation = sessionStorage.getItem('emulatedUser') ? true : false;
  await config.clientApp.acquireTokenSilent({
    scopes: config.b2cScopes,
    loginHint: sessionStorage.loggedUser,
  }).then(async (response) => {
    await config.storeToken(response.accessToken, rpxEmulation); // stores in sessionStorage
  }).catch(acquireTokenError => {
    // could also check if err instance of InteractionRequiredAuthError if you can import the class.
    try {
      config.clientApp.acquireTokenRedirect({
        scopes: config.b2cScopes,
        loginHint: sessionStorage.loggedUser,
      });
    } catch (error) {
      console.log(`A loging error occurred on the second attempt to acquire token: --> ${error}`);
      settings.saveLoginError(acquireTokenError);
    }
  });
};

export const rpxTokenUpdater = () => {
  let body = JSON.stringify(sessionStorage.refreshToken);
  let auth =
    "Basic " +
    btoa(
      sessionStorage.emulatedUser + ":" + process.env.REACT_APP_PRPX_BASIC_PWD
    );
  let url = config.baseApiUrl + "auth/RefreshAzureToken";
  //let localurl = "https://localhost:44330/api/auth/RefreshAzureToken";
  fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: auth
    },
    body: body
  })
    .then(response => {
      if (response.ok) {
        response.json().then(json => {
          json.EmulatorUsername = sessionStorage.emulatedUser;
          config.storeRPXToken(json);
        });
      } else {
      }
    })
    .catch(message => {
      if (message.status === 401) {
        console.log("rpxTokenUpdater->error");
        console.log(message.status);
      }

      logout();
    });
};

export const logout = () => {
  let username = '';
  if (sessionStorage.username)
    username = sessionStorage.username;
  else if (sessionStorage.loginUserName)
    username = sessionStorage.loginUserName;

  let redirectUri = encodeURIComponent(window.location.protocol + "//" + window.location.host + "/session");

  if (username !== '')
    redirectUri += '?username=' + username;

  sessionStorage.clear();
  //institute something here to say i am logged out on this tab
  sessionStorage.setItem('loggingOut', true);
  //localStorage.removeItem('accessToken');
  //config.clientApp.logout();
  localStorage.clear();
  // setting xpolicy
  let object = {
    tenant:
      process.env.REACT_APP_ENVIRONMENT === "development"
        ? "gomomentumdev.onmicrosoft.com"
        : "momentumbrain.onmicrosoft.com",
    // policy: "B2C_1_SignIn",
    policy: storedXPolicy() === undefined ? process.env.REACT_APP_MSA_XPOLICY : storedXPolicy(),
    post_logout_redirect_uri: redirectUri,
    url: process.env.REACT_APP_ENVIRONMENT === "development"
      ? "https://gomomentumdev.b2clogin.com/"
      : "https://momentumbrain.b2clogin.com/"
  };
  let newLocation = object.url +
    object.tenant +
    "/oauth2/logout?p=" +
    object.policy +
    "&post_logout_redirect_uri=" +
    object.post_logout_redirect_uri;

  window.location = newLocation;
};


export const transitionTimeout = () => {
  // prevents redirect page from getting hung
  setTimeout(() => {
    if (stillRedirecting()) {
      sessionStorage.removeItem("timer");
      logout();
    }
  }, redirectTimer());
};

const stillRedirecting = () => helper.toBoolean(sessionStorage.redirecting);

const redirectTimer = () => {
  if (window.location.href.indexOf("#state=") !== -1) {
    // appended to url if redirected from MS login page
    async () => await sessionStorage.setItem("timer", settings.loginTimeout);
    return settings.loginTimeout;
  } else {
    return sessionStorage.timer || settings.redirectTimeout;
  }
};
