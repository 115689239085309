import { useEffect, useState } from "react";

import { fetchData } from "../../../../requests/api_calls";

import { DataLoadingSpinner } from "../../../../components/spinners";

export const ScheduleList = ({
  schedulesList,
  onShowScheduleItem,
  onSetListScheduleItemToDelete,
}) => {
  const [listWithEvents, setListWithEvents] = useState(schedulesList || []);
  const [loading, setLoading] = useState(true);

  const fetchEventsForSchedule = async () => {
    let schedulesData;
    if (schedulesList && schedulesList.length > 0) {
      let requests = [];
      for (const schedule of schedulesList) {
        const request = fetchData(
          `admin/enterprise/GetTimeScheduleEvents?scheduleName=${schedule.ScheduleName}&scheduleType=${schedule.ScheduleType}`
        ).then((data) => data);
        requests.push(request);
      }

      const eventsResponse = await Promise.all(requests)
        .then((dataArray) => dataArray)
        .catch((err) => console.error(err));

      schedulesData = schedulesList.map((schedule, index) => {
        const events = eventsResponse[index];
        return {
          ...schedule,
          selectToDelete: false,
          events: events
            ? events.map((eventName) => {
                return { EventName: eventName };
              })
            : null,
        };
      });
    }
    setListWithEvents(schedulesData);
    setLoading(false);
  };

  useEffect(() => {
    fetchEventsForSchedule();
  }, []);

  useEffect(() => {
    if (listWithEvents && listWithEvents.length > 0) {
      const listWithEventsToDelete = listWithEvents.filter((item) => item.selectToDelete);
      onSetListScheduleItemToDelete(listWithEventsToDelete);
    }
  }, [listWithEvents]);

  if (loading) {
    return (
      <div className="content">
        <DataLoadingSpinner className="spinner" style={{ margin: "10px auto 0", height: "40px" }} />
      </div>
    );
  }

  return (
    <table className="table">
      <thead>
        <tr>
          <th>Select</th>
          <th>Schedule Name</th>
          <th style={{ textAlign: "right" }}>Edit</th>
        </tr>
      </thead>
      <tbody>
        {listWithEvents.map((item, index) => (
          <tr key={index}>
            <td className="check">
              <label className="">
                <input
                  type="checkbox"
                  checked={item.selectToDelete}
                  onChange={(e) =>
                    setListWithEvents(
                      listWithEvents.map((listItem) => {
                        if (listItem.ScheduleName === item.ScheduleName) {
                          return { ...item, selectToDelete: e.target.checked };
                        }
                        return listItem;
                      })
                    )
                  }
                />
              </label>
            </td>
            <td>{item.ScheduleName}</td>
            <td>
              <span>
                <a className="icon btn" onClick={() => onShowScheduleItem(item)}>
                  <svg width="14" height="14" viewBox="0 0 24 24">
                    <path
                      d="m163 440l-91-91 251-250 90 90z m309-352l-48-48c-12-11-32-11-45 2l-45 45 91 91 45-45c13-13 13-33 2-45z m-408 275l-32 117 117-32z"
                      transform="scale(0.046875 0.046875)"
                    ></path>
                  </svg>
                </a>
              </span>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};
