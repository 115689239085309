import React from "react";
import { CallDispositionCodeSectionItem } from "./disposition-code-item";
import { AddCallDispositionCodeItem } from "./add-disposition-code";

export const CallDispositionCodesTable = ({ codes, reloadView, updateItem }) => {
  const hasCodes = codes && codes.length > 0;

  return (
    <>
      <AddCallDispositionCodeItem onAdd={reloadView} />
      <table className="table">
        <thead>
          <tr>
            <th>Active</th>
            <th>Code</th>
            <th>Description</th>
            <th>Edit</th>
            <th>Select</th>
          </tr>
        </thead>
        <tbody>
          {!Boolean(hasCodes) && (
            <tr>
              <td colSpan={5}>
                <div>
                  <span>No items found.</span>
                </div>
              </td>
            </tr>
          )}
          {codes.map((code, index) => {
            if (code) {
              return (
                <CallDispositionCodeSectionItem
                  key={index}
                  onUpdate={reloadView}
                  onDelete={reloadView}
                  onItemChange={updateItem}
                  code={code}
                />
              );
            }
          })}
        </tbody>
      </table>
    </>
  );
};
