import React, { Component } from 'react';
import _ from 'lodash';

class AudioUpload extends Component {


  constructor(props) {
    super(props);

    this.changeRecurs = this.changeRecurs.bind(this);
    this.handleAddAudioNumber = this.handleAddAudioNumber.bind(this);
    this.state = { numberOfAudioCount:1, numberOfAudioLimit:4 };
  }

  handleAddAudioNumber(){
    if(this.state.numberOfAudioCount < 4)
      this.setState({ numberOfAudioCount: this.state.numberOfAudioCount + 1 });
  }

  changeRecurs(event){
    this.setState({recurValue: event.target.value})
  }

  componentDidMount() {   
  }

  render() {
    let audioNumberCountReachLimit = this.state.numberOfAudioCount >= this.state.numberOfAudioLimit;
		return(	
			<div>		
				<div className="form-group">
		            <label>{ this.props.label }</label>
		            <div className="form-control">
		              <label className="custom-control">
		              <input id="radio1" name="radio" type="radio" onChange={this.changeRecurs} defaultValue='default' />
		                <span >Default</span>
		              </label> 
		              <label className="custom-control">
		                <input id="radio1" name="radio" type="radio" onChange={this.changeRecurs} defaultValue='url' />
		                <span>URL</span>
		              </label>
		              <label className="custom-control">
		                <input id="radio1" name="radio" type="radio" onChange={this.changeRecurs} defaultValue='upload' />
		                <span>Custom</span>
		              </label>
		            </div>
		            {this.getInputs(audioNumberCountReachLimit)}
		        </div>
            </div>
		);
	}

	getInputs(audioNumberCountReachLimit) {
    	switch(this.state.recurValue){
    		case "default":
		        return (
		        	<div></div>
		        );
	      	case "url":
		        return (
		        	<div className="form-group">
				        <div className="audio-options">
					        <div className="form-group">
					            <label>Audio URL</label>
					            {_.times(this.state.numberOfAudioCount, i =>
					              <div key={i} className="audio-num">
					                <input type="text" className="form-control" defaultValue="" />
					              </div>
					            )}
					      	</div>
					      	<a style={{visibility: audioNumberCountReachLimit ? 'hidden':'visible' }} className="new-audio" onClick={this.handleAddAudioNumber}>Add additional audio URL</a>
					    </div>
					</div>
		        );
	      	case "upload":
		        return (
		        	<div className="form-group">
				        <div className="audio-options">
					        <div className="form-group">
					            <label>Audio</label>
					            {_.times(this.state.numberOfAudioCount, i =>
					              <div key={i} className="audio-num">
					                <select className="form-control">
					                	<option>None</option>
					                </select>
					              </div>
					            )}
					      	</div>
					      	<a style={{visibility: audioNumberCountReachLimit ? 'hidden':'visible' }} className="new-audio" onClick={this.handleAddAudioNumber}>Add additional audio file</a>
					    </div>
					</div>
		        );
		}
	}
}

export default AudioUpload;