import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { Button } from '@progress/kendo-buttons-react-wrapper';
import _ from 'lodash';
import { DataLoadingSpinner } from '../../../components/spinners';
import { fetchData, post } from '../../../requests/api_calls';

class CenterRoutingPoliciesModal extends Component {

	constructor(props) {
		super(props);

    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.state = {
      showModal:false,
      routingPolicies: null,
      loading: false,
    };
    this.updateRoutingPolicies = this.updateRoutingPolicies.bind(this);
  }

  handleClose() {
    this.setState({
      showModal:false,
      routingPolicies: null,
      loading: false,
    });
  }

  handleShow = async () => {
    await this.setState({...this.state, showModal: true, loading: true });
		const data = await this.fetchRoutingPolicies();
		await this.setState({ routingPolicies: data, loading: false });
  }

  fetchRoutingPolicies = async () => {
    return await fetchData('admin/enterprise/GetContactCenterRoutingPolicies').then(routingPolicies => routingPolicies);
  }

  updateRoutingPolicies = async () => {
    const { routingPolicies } = this.state;
    const path = 'admin/enterprise/UpdateContactCenterRoutingPolicies';
    await this.setState({ loading: true });
    try {
      const response = await post(path, "POST", JSON.stringify(routingPolicies));
      if(response.ok) {
        await this.setState({ loading: false });
      } else {
        console.error(`${path}: failed: ${response.message}`);
        this.setState({ loading: false });
      }
    } catch (error) {
      console.error(`${path}: failed: ${error}`);
      await this.setState({ loading: false });
    }
  }

  updateCallCenterPriority = async (event, selectedCallCenter) => {
    const { routingPolicies } = this.state;
    const updateCallCenters = routingPolicies.CallCenters.map(callCenter => {
      if(callCenter.Id === selectedCallCenter.Id) {
        callCenter.Priority = event.target.value;
      }
      return callCenter;
    })
    
    await this.setState({ ...this.state, routingPolicies: {
      ...routingPolicies,
      CallCenters: updateCallCenters
    }});
  }
  
	componentDidMount() {		
	}

  render() {
    let modalContent;
    const { loading, routingPolicies } = this.state;
    
    if (loading) {
      modalContent = (
        <div className='content'>
          <DataLoadingSpinner className='spinner' style={{ margin: '10px auto 0', height: '40px' }} />
        </div>
      );
    } else if (routingPolicies && Object.keys(routingPolicies).length > 0) {
      const hasCallCenters = Boolean(routingPolicies.CallCenters && routingPolicies.CallCenters.length > 0);
      modalContent = (
        <div className="">
          <div className="form-group">
            <label>Routing Policy</label>
            <div className="form-control">
              <label>
                <input 
                  type="radio" 
                  name="radio" 
                  id="one" 
                  checked={routingPolicies.CallCenterRoutingPolicy === 0} 
                  onChange={e => {
                    this.setState({
                      ...this.state,
                      routingPolicies: {
                        ...this.state.routingPolicies,
                        CallCenterRoutingPolicy: e.target.checked ? 0 : 1
                      }
                    })
                  }}
                /> 
                Longest Wait Time
              </label>
              <label>
                <input 
                  type="radio" 
                  name="radio" 
                  id="one" 
                  checked={routingPolicies.CallCenterRoutingPolicy === 1} 
                  onChange={e => {
                    this.setState({
                      ...this.state,
                      routingPolicies: {
                        ...this.state.routingPolicies,
                        CallCenterRoutingPolicy: e.target.checked ? 1 : 0
                      }
                    })
                  }}
                /> 
                Priority Order
              </label>
            </div>
          </div>
          <hr/>
          <div>
            <table className="table">
              <thead>
                <tr>
                  <th>Contact Center Name</th>
                  <th>Priority</th>
                </tr>
              </thead>
              <tbody>
                {hasCallCenters && routingPolicies.CallCenters.map(callCenter => (
                  <tr key={callCenter.Id}>
                    <td>
                      {callCenter.Name}
                    </td>
                    <td>
                      <input 
                        type="text"
                        className="form-control" 
                        value={callCenter.Priority}
                        onChange={e => this.updateCallCenterPriority(e, callCenter)}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      );
    } else {
      modalContent = <></>;
    }
  
    return (
      <div className="setting">
        <span onClick={this.handleShow}> 
          <span className="name">Contact Center Routing Policies</span>
          <a className="icon btn"><svg width="14" height="14" viewBox="0 0 24 24"><path d="m163 440l-91-91 251-250 90 90z m309-352l-48-48c-12-11-32-11-45 2l-45 45 91 91 45-45c13-13 13-33 2-45z m-408 275l-32 117 117-32z" transform="scale(0.046875 0.046875)"></path></svg></a>
        </span>
        <Modal show={this.state.showModal} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Contact Center Routing Policies</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {modalContent}
          </Modal.Body>
          <Modal.Footer>
              <hr />
              <div className="btns">
                <Button className="btn" click={this.updateRoutingPolicies}>Save</Button>
                <Button className="btn" click={this.handleClose}>Cancel</Button>
              </div>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
};

export default CenterRoutingPoliciesModal;
