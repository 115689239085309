import React from 'react';
import LocationAnnouncementRepositoryModal from './locations/modals/locationAnnouncementRepositoryModal';
import AnnoucementsModal from './modals/annoucementsModal';
import AgentsModal from './modals/agentsModal';
import StatsModal from './modals/statsModal';
import SupervisorsModal from './modals/supervisorsModal';
import DistinctiveRingModal from './modals/distinctiveRingModal';
import DNISModal from './modals/dnisModal';
import QueueStatusModal from './modals/queueStatusModal';
import CallDispositionCodeModal from './modals/callDispositionCodeModal';
import AcdForcedModal from './modals/acdForcedModal';
import AcdHolidayModal from './modals/acdHolidayModal';
import AcdNightModal from './modals/acdNightModal';
import QueueBouncedModal from './modals/queueBouncedModal';
import QueueComfortModal from './modals/queueComfortModal';
import QueueOverflowModal from './modals/queueOverflowModal';
import QueueStrandedModal from './modals/queueStrandedModal';
import WeightedModal from './modals/weightedModal';


const ContactCenter = () => (
  	<div className="container full-card">
		<div className="contact-center card">
			<h1>Contact Center <span className="tag btn">Active</span></h1>
			<div className="contact-info">
				<b>Premium</b> 123 123 1234 7000
			</div>
			<div className="settings-row">
				<AcdForcedModal />
				<AcdHolidayModal />
				<AcdNightModal />
				<AgentsModal />
				<AnnoucementsModal />
				<LocationAnnouncementRepositoryModal />
				<CallDispositionCodeModal />
				<DistinctiveRingModal />
				<DNISModal />
				<StatsModal />
				<SupervisorsModal />
				<QueueStatusModal />
				<QueueBouncedModal />
				<QueueComfortModal />
				<QueueOverflowModal />
				<QueueStrandedModal />
				<WeightedModal />
			</div>
		</div>
	</div>
)

export default ContactCenter;