import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { Button } from '@progress/kendo-buttons-react-wrapper';

class UploadOrderData extends Component {

  constructor(props) {
    super(props);

    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);

    this.state = { showModal: false };
  }

  handleClose() {
    this.setState({ showModal: false });
  }

  handleShow() {
    this.setState({ showModal: true });
  }

  componentDidMount() {   
  }

  render() {
    return (
      <span>
        <Button click={this.handleShow}>Upload Order Data</Button>
        <Modal show={this.state.showModal} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Upload Order Data</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="">
              <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Est amet doloribus pariatur esse consequuntur saepe, odit, iusto ex optio quibusdam.</p>
              <a href="" className="no-btn">Download Order Template</a>
              <hr />
              <div className="form-group">
                <label>RC State</label>
                <input type="file" className="form-control" />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
              <hr />
              <div className="btns">
                <a href="" className="btn">Save</a>
              <Button className="btn" click={this.handleClose}>Cancel</Button>
              </div>
          </Modal.Footer>
        </Modal>
      </span>
    );
  }
};

export default UploadOrderData;