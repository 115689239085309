import React, { Component } from 'react';
import { Button } from '@progress/kendo-buttons-react-wrapper';
import * as apiCalls from './../../../requests/api_calls';
import * as help from'../../locations/locations_helpers';
import SaveStatus from '../../../components/save_status'
import { DataLoadingSpinner } from'./../../../components/spinners';
import * as settings from '../../../scripts/settings';

class GroupFqdnTab extends Component {

  constructor(props) {
    super(props);
    this.fetchFqdns = this.fetchFqdns.bind(this);
    this.fqdnsOptions = this.fqdnsOptions.bind(this);
    this.fqdnsOption = this.fqdnsOption.bind(this);
    this.fetchLocations = this.fetchLocations.bind(this);
    this.setLocation = this.setLocation.bind(this);
    this.resetForm = this.resetForm.bind(this);
    this.retrieveFQDNDetails = this.retrieveFQDNDetails.bind(this);
    this.state = { loading: true, showModal: false, fqdns: [], currentLocation: '', locations: [], resultsMsg: '',newFqdn:null};
  }

  componentDidMount() {    
    this.fetchLocations();
  }

  fetchFqdns = async() => {
    let params = '?locationAccountNumber=' + this.state.currentLocation;
    let apiPath = 'admin/GetFqdns' + params;
    return apiCalls.fetchData(apiPath).then(data => data);
  }

  fetchDeviceLocation = () => {
    let params = '?locationAccountNumber=' + this.state.currentLocation;
    let apiPath = 'admin/GetDeviceLocationProperties' + params;
    return apiCalls.fetchData(apiPath).then(data => data);
  }

  fetchLocations = async(apiPath) => {
    let locationOption = (location, index) => <option key={index} value={location.LocationAccountNumber}>{location.LocationName}</option>

    if (!apiPath) apiPath = 'admin/Locations';
    await this.setState({ loading: true });
    apiCalls.fetchData(apiPath).then((data) => {
        if (data) {
            let locationOptions = data.map((location, index) => locationOption(location, index));
            if (locationOptions.length > 1) {
              locationOptions.unshift(
                locationOption({ LocationAccountNumber: '', LocationName: '- Select Location -' }, '')
              );
            }
            this.setState({ locationOptions: locationOptions });
            if (!sessionStorage.DeviceLocation) {
                let currLocation = this.state.locationOptions[0].props.value;
                this.setState({ currentLocation: currLocation, loading: false }); //initial value
            } else {
                this.setState({ currentLocation: sessionStorage.DeviceLocation }); //stored value
                this.loadLocationDetails(sessionStorage.DeviceLocation);
            }
        } else {
            this.setState({ locations: [], resultsMsg: settings.noResultsMsg, loading: false });
        };
    }).catch(err => console.error(err));
  }

  setLocation = async(event) => {
    let locationValue = event.target.value;
    await this.setState({ currentLocation: locationValue, loading: true });
    await this.loadLocationDetails(locationValue);
    
  }
  loadLocationDetails = async(locationValue) => {
    try{
      let [FQDNDetails, DeviceLocationDetails] = await this.retrieveFQDNDetails();
      await this.setFQDNList(FQDNDetails);
      await this.setDeviceLocationDetails(DeviceLocationDetails);
    } catch (ex) { 
      console.error(ex);
    } finally {
      sessionStorage.setItem('DeviceLocation', locationValue);
      await this.setState({loading: false});
    }
  }

  setFQDNList = (FQDNDetails) => {
    if (FQDNDetails !== null) {
      //need to pull down all properties because the save is otherwise overriding the save in the VLAN modal
      if (FQDNDetails !== null) {
        let fqdnList = [];
        fqdnList.push('')
        FQDNDetails.sort();
        FQDNDetails.unshift(fqdnList);
        this.setState({fqdns: FQDNDetails});
      } else if (FQDNDetails === null) {
        // Need to send user instruction on how to create FQDN options
      }
    }
  }

  setDeviceLocationDetails = (DeviceLocationDetails) => {
    if (DeviceLocationDetails) {
      setTimeout(() => this.setState({
        loading: false,
        currentFqdn: DeviceLocationDetails.FQDN, 
        newFqdn: DeviceLocationDetails.FQDN,
        EncryptedAudio: DeviceLocationDetails.EncryptedAudio,
        TransportProtocol: DeviceLocationDetails.TransportProtocol,
        useTcp: DeviceLocationDetails.TransportProtocol === 'TCP', 
        VlanId: DeviceLocationDetails.VlanId || '',
        useVlanId: DeviceLocationDetails.VlanId != ('' || null)
      }), help.hideSpinnerDelay);
    }
  }

  retrieveFQDNDetails = async() => {
    let requests = [this.fetchFqdns(), this.fetchDeviceLocation()];
    return await Promise.all(requests).then(dataArray => dataArray);
  }

  fqdnsOptions = () => this.state.fqdns.map((fqdn, index) => this.fqdnsOption(fqdn, index));

  fqdnsOption = (fqdn, index) => <option key={index} value={fqdn}>{fqdn}</option>

  setNewFqdn = (e) => this.setState({newFqdn: e.target.value || null});

  saveStatus = () => {
    if (this.state.showErrorStatus) {
      return <span className='text-danger'>Error updating Group FQDN.  Please try again.</span>
    } else return null;
  }

  resetForm = async() => {
    await this.setState({ loading: true, statusMessage: '', currentFqdn: null });
    try{
      let [FQDNDetails, DeviceLocationDetails] = await this.retrieveFQDNDetails();
      await this.setFQDNList(FQDNDetails);
      await this.setDeviceLocationDetails(DeviceLocationDetails);
    } catch (ex) { 
      console.error(ex);
    } finally {
      await this.setState({loading: false});
    }
  }

  handleUpdateSuccess = () => {
    let currentFqdn = this.state.newFqdn;
    setTimeout(() => {
      this.setState({
        currentFqdn: currentFqdn,
        saving: false,
        statusMessage: 'Group FQDN settings were updated.',
        parentCallback: this.resetForm
      })
      ,setTimeout(() => this.resetForm(), help.statusMsgDelay)}
      , help.hideSpinnerDelay);
  }

  handleUpdateError = () => {
    this.setState({showErrorStatus: true});
    setTimeout(() => this.setState({showErrorStatus: false}), 3000);
  }

  saveDeviceLocationInput = () => {
    this.setState({saving: true, showSaveStatus: true});
    let data = JSON.stringify({
      LocationAccountNumber: this.state.currentLocation,
      FQDN: this.state.newFqdn,
      EncryptedAudio: this.state.EncryptedAudio,
      TransportProtocol: this.state.TransportProtocol,
      VlanId: this.state.VlanId
    });
    let apiPath = 'admin/UpdateDeviceLocationProperties';
    apiCalls.send(apiPath, 'PATCH', data).then(resp => {
      resp.ok ? this.handleUpdateSuccess() : this.handleUpdateError();
    }).catch(message => {
      console.log('caught an exception updating device location FQDN');
      console.log(message)
    });
  }

  savingOrConfirming = () => this.state.saving || this.state.statusMessage;

  getTabBody = () => {
    if (this.state.loading) {
      return <DataLoadingSpinner className='load spinner'/>;
    } else if (this.state.currentLocation === '') {
      return (
        <div style={{ "fontSize": "medium", "marginBottom": "5px", "marginTop": "5px", "color": "red" }}>
          <b>​Please select a "Location" from the menu above.</b>
        </div>
      );
    } else {
      return (
        <div className={this.savingOrConfirming() ? 'opaque' : 'visible group-fqdn'}>
          <p>Setup a Fully Qualified Domain Name (FQDN) as default FQDN for the location.</p><br />
          <div className="form-group">
            <label><b>Current FQDN:</b></label>&nbsp;&nbsp;&nbsp;
            {this.saveStatus()}
            <input type="text" 
              style={{width: '25%', height:'20px', border: 'none', borderBottom: '2px solid #eee', outline: 'none', background: '#f9f9f9', boxShadow: 'none'}} 
              value={this.state.currentFqdn} 
              disabled 
            />
          </div>         
          <div className="multiselect-group form-group">
            <label><b>Available FQDN: </b></label>
            <select 
              style={{width: '25%', height:'20px', border: 'none', borderBottom: '2px solid #eee', outline: 'none', background: '#f9f9f9', boxShadow: 'none'}}
              onChange={this.setNewFqdn}
              value={this.state.newFqdn || ' '}
            >
              {this.fqdnsOptions()}
            </select>
          </div>
          <div>
            <Button className="btn" click={this.saveDeviceLocationInput}>Save</Button>
            <Button className="btn" click={this.resetForm}>Cancel</Button>
          </div>
        </div>
      )
    }
  }

  footerBtnClass = () => {
    return this.savingOrConfirming() || this.state.loading ? 'opaque' : 'visible btns';
  }

  modalClass = () => this.state.loading ? 'loading' : 'group-fqdn';

  render() {
    return (
      <div className="setting">
        <h3>Group FQDN Setup</h3><br />
        <div className="form-group">
          <label>Location</label>
          <select onChange={this.setLocation} value={this.state.currentLocation}
            style={{width: '20%', height:'20px', border: 'none', borderBottom: '2px solid #eee', outline: 'none', background: '#f9f9f9', boxShadow: 'none'}}
          >
              {this.state.locationOptions}
          </select>
        </div>
        {this.getTabBody()}
        <SaveStatus
          saving={this.state.saving}
          statusMessage={this.state.statusMessage}
          error={this.state.error}
          parentCallBack={this.state.parentCallback}
          hideOkBtn={true}
        />
      </div>
    );
  }
};

export default GroupFqdnTab;