import * as d3 from "d3";

export const setLegend = (options) => {
  let { svg, data1, data2, data, type, height, width, margin, x, y, id, summary, deviceName, node, color1, color2, dateRange, axisLabel } = options;
  if (summary) {
    // Create legend
    let legend = d3
      .select(`#${id}-legend`)
      .append('div');
    let legend_table = legend
      .append('p')
      .append('table')
      .attr('class', 'table table-condensed')
      .attr('style', 'width: ' + 100 + '%;');
    // Append the legend body
    let tbody = legend_table
      .append('tbody');
    // Append the legend headers
    let thead = legend_table
      .append('thead');
    let column_headers = ['Measurement', 'Min', 'Max', 'Last', '95th'];
    let tr = thead.append('tr');
    tr.selectAll('td')
      .data(column_headers)
      .enter()
      .append('td')
      .append('strong')
      .text((d) => { return d; });
    // Add rows to legend body
    tr = tbody.append('tr');
    tr.append('td').text(axisLabel)
    tr.append('td').text(summary.min + " Mbps");
    tr.append('td').text(summary.max + " Mbps");
    tr.append('td').text(summary.last + " Mbps");
    tr.append('td').append('div').attr('style', 'background-color: ' + "violet" + ';width: 8px; height: 8px; vertial-align: middle; border-radius: 50%');//.text(threshold);
  }
};
