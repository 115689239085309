import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { Button } from '@progress/kendo-buttons-react-wrapper';
import _ from 'lodash';

class LocationInstantConferenceModal extends Component {

  constructor(props) {
    super(props);

    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleAddPhoneNumber = this.handleAddPhoneNumber.bind(this);
    this.state = { showModal: false, numberOfPhoneCount:1, numberOfPhoneLimit:20 };
  }

  handleClose() {
    this.setState({ showModal: false });
  }

  handleShow() {
    this.setState({ showModal: true });
  }
  handleAddPhoneNumber(){
    if(this.state.numberOfPhoneCount < this.state.numberOfPhoneLimit)
      this.setState({ numberOfPhoneCount: this.state.numberOfPhoneCount + 1 });
  }

  componentDidMount() {   
  }

  render() {
    
    let phoneNumberCountReachLimit = this.state.numberOfPhoneCount >= this.state.numberOfPhoneLimit;

    return (
      <div className="setting">
        <span onClick={this.handleShow}> 
          <span className="name">Instant Conference</span>
          <a className="icon btn"><svg width="14" height="14" viewBox="0 0 24 24"><path d="m163 440l-91-91 251-250 90 90z m309-352l-48-48c-12-11-32-11-45 2l-45 45 91 91 45-45c13-13 13-33 2-45z m-408 275l-32 117 117-32z" transform="scale(0.046875 0.046875)"></path></svg></a>
        </span>
        <Modal show={this.state.showModal} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Instant Conference</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <p>Combine this feature with Push to Talk and you can call a group for a hands-free intercom conference. </p>
              <table className="table">
                  <thead>
                    <tr>
                      <th className="check"><input type="checkbox" /></th>
                      <th>Name</th>
                      <th>Extension</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="check"><input type="checkbox" /></td>
                      <td>Instant Conference</td>
                      <td>8000</td>
                      <td align="right">
                        <a className="icon btn" data-toggle="collapse" data-target="#ar1"><svg width="14" height="14" viewBox="0 0 24 24"><path d="m163 440l-91-91 251-250 90 90z m309-352l-48-48c-12-11-32-11-45 2l-45 45 91 91 45-45c13-13 13-33 2-45z m-408 275l-32 117 117-32z" transform="scale(0.046875 0.046875)"></path></svg></a>
                      </td>
                    </tr>
                    <tr className="collapse" id="ar1">
                      <td colSpan="4">
                        <div className="table-edit">
                          <div className="form-group">
                            <label>Name</label>
                            <input type="type" className="form-control" defaultValue="" />
                          </div>
                          <div className="form-group">
                            <label className="right switch">
                              <input type="checkbox" data-toggle="collapse" data-target="#hide1" />
                              <span className="slider round"></span>
                            </label>
                            Enable maximum time for unanswered calls
                            <div className="collapse" id="hide1">
                              <label>For how many minutes</label>
                              <input type="type" className="form-control" defaultValue="" />
                            </div>
                          </div>
                          <hr />
                          <div className="block-options simple">
                            <label>Enter the numbers that should be included in this Instant Conference</label>
                            <div className="phone-nums">
                              {_.times(this.state.numberOfPhoneCount, i =>
                                <div key={i} className="phone-num">
                                  <input type="text" className="form-control" defaultValue="" />
                                </div>
                              )}
                            </div>
                            <a style={{visibility: phoneNumberCountReachLimit ? 'hidden':'visible' }} className="new-phone" onClick={this.handleAddPhoneNumber}>Add additional phone number</a>
                          </div>
                          <hr />
                          <div className="btns">
                            <a href="" className="btn">Save</a>
                            <a href="" className="btn">Cancel</a>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="4">
                        <div className="btns">
                          <a className="btn" data-toggle="collapse" data-target="#ar1">Add Instant Conference</a>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
            </div>
          </Modal.Body>
          <Modal.Footer>
              <hr />
              <div className="btns">
                <Button className="btn">Save</Button>
                <Button className="btn" click={this.handleClose}>Cancel</Button>
              </div>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }

};

export default LocationInstantConferenceModal;
