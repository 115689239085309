import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { Button } from '@progress/kendo-buttons-react-wrapper';
import { MultiSelect } from '@progress/kendo-dropdowns-react-wrapper';
import { TimePicker } from '@progress/kendo-dateinputs-react-wrapper';
import _ from 'lodash';



class EditContactCenterModal extends Component {

  constructor(props) {
    super(props);

    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.state = { showModal: false };
  }

  handleClose() {
    this.setState({ showModal: false });
  }

  handleShow() {
    this.setState({ showModal: true });
  }

  componentDidMount() {   
  }

  render() {
    return (
      <div className="">
        <span onClick={this.handleShow}> 
          <a className="icon btn"><svg width="14" height="14" viewBox="0 0 24 24"><path d="m163 440l-91-91 251-250 90 90z m309-352l-48-48c-12-11-32-11-45 2l-45 45 91 91 45-45c13-13 13-33 2-45z m-408 275l-32 117 117-32z" transform="scale(0.046875 0.046875)"></path></svg></a>
        </span>

        <Modal show={this.state.showModal} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Edit Contact Center</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
	            <p>You can configure Contact Centers to allow agents to log in and out, to queue incoming calls that cannot be answered immediately, to re-direct calls when the group cannot accept calls, and to provide music or video for caller on hold.</p>

	            <div className="form-group">
	              <label className="right switch">
	                <input type="checkbox" />
	                <span className="slider round"></span>
	              </label>
	              Active Contact Center
	            </div>
	            <div className="">
		            <div className="halves">
		            	<div className="half">
				            <div className="form-group">
				              <label>Contact Center ID</label>
				              <input type="type" className="form-control" defaultValue="2058472990CC7000@mymtm.us" disabled />
				            </div>
		            	</div>
		            	<div className="half">
				            <div className="form-group">
				              <label>Contact Center Type</label>
				              <input type="type" className="form-control" defaultValue="Premium" disabled />
				            </div>
		            	</div>
		            </div>
		            <hr />
		            <div className="form-group">
		              <label>Name</label>
		              <input type="type" className="form-control" defaultValue="" />
		            </div>
		            <div className="halves">
		            	<div className="half">
				            <div className="form-group">
				              <label>Calling Line ID First Name</label>
				              <input type="type" className="form-control" defaultValue="" />
				            </div>
		            	</div>
		            	<div className="half">
				            <div className="form-group">
				              <label>Calling Line ID Last Name</label>
				              <input type="type" className="form-control" defaultValue="" />
				            </div>
		            	</div>
		            </div>
		            <div className="halves">
		            	<div className="half">
				            <div className="form-group">
				                <label>Department</label>
					            <select className="form-control">
					                <option defaultValue="Available">Dept 1</option>
					                <option defaultValue="Available">Dept 2</option>
					                <option defaultValue="Available">Dept 3</option>
					            </select>
				            </div>
		            	</div>
		            	<div className="half">
				            <div className="form-group">
				                <label>Language</label>
					            <select className="form-control">
					                <option defaultValue="Available">Language 1</option>
					                <option defaultValue="Available">Language 2</option>
					                <option defaultValue="Available">Language 3</option>
					            </select>
				            </div>
		            	</div>
		            </div>
		            <div className="halves">
		            	<div className="half">
				            <div className="form-group">
				              <label>Time Zone</label>
					            <select className="form-control">
					                <option defaultValue="Available">Time Zone 1</option>
					                <option defaultValue="Available">Time Zone 2</option>
					                <option defaultValue="Available">Time Zone 3</option>
					            </select>
				            </div>
		            	</div>
		            	<div className="half">
				            <div className="form-group"></div>
		            	</div>
		            </div>


		            <div className="form-group">
		                <label>Routing Type</label>
		                <div className="form-control">
		                  	<label className="custom-control">
		                  	<input id="radio1" name="radio" type="radio"/>
		                    	<span >Priority Based</span>
		                  	</label> 
		                  	<label className="custom-control">
		                    	<input id="radio1" name="radio" type="radio"/>
		                    	<span>Skill Based</span>
		                  	</label>
		                </div>
		            </div>
		            <div className="form-group">
		                <label>Group Policy</label>
		                <div className="form-control">
		                  	<label className="custom-control">
		                  	<input id="radio1" name="radio" type="radio"/>
		                    	<span >Circular</span>
		                  	</label> 
		                  	<label className="custom-control">
		                    	<input id="radio1" name="radio" type="radio"/>
		                    	<span>Regular</span>
		                  	</label>
		                  	<label className="custom-control">
		                    	<input id="radio1" name="radio" type="radio"/>
		                    	<span>Simultaneous</span>
		                  	</label><br />
		                  	<label className="custom-control">
		                    	<input id="radio1" name="radio" type="radio"/>
		                    	<span>Uniform</span>
		                  	</label>
		                  	<label className="custom-control">
		                    	<input id="radio1" name="radio" type="radio"/>
		                    	<span>Weighted Call Distribution</span>
		                  	</label>
		                </div>
		            </div> 
		            <h5>Bandwidth and QoS Settings</h5>
		            <div className="form-group">
		              	<label>Preferred announcement / music codec for external calls</label>
			            <select className="form-control">
			                <option defaultValue="None">None</option>
							<option selected="selected" defaultValue="G.711">G.711</option>
							<option defaultValue="G.726">G.726</option>
							<option defaultValue="G.729">G.729</option>
							<option defaultValue="AMR">AMR</option>
			            </select>
		            </div>
		            <div className="form-group">
		              	<label>Preferred announcement / music codec for internal calls</label>
			            <select className="form-control">
			                <option defaultValue="None">None</option>
							<option selected="selected" defaultValue="G.711">G.711</option>
							<option defaultValue="G.726">G.726</option>
							<option defaultValue="G.729">G.729</option>
							<option defaultValue="AMR">AMR</option>
			            </select>
		            </div>
		            <h5>Contact Center Settings</h5>
		            <div className="form-group">
		              <label>Queue Length</label>
		              <input type="type" className="form-control" defaultValue="" />
		            </div>
		            <div className="form-group">
		              <label className="right switch">
		                <input type="checkbox" />
		                <span className="slider round"></span>
		              </label>
		              Enable video support
		            </div>
		            <div className="form-group">
		              <label className="right switch">
		                <input type="checkbox" />
		                <span className="slider round"></span>
		              </label>
		              Play ringing when offering call
		            </div>
		            <div className="form-group">
		              <label className="right switch">
		                <input type="checkbox" data-toggle="collapse" data-target="#hide1" />
		                <span className="slider round"></span>
		              </label>
		              Allow callers to dial a number to escape out of queue
		                <div className="hidden-group collapse" id="hide1">
		                  <label >Number</label>
		                  <input type="text" className="form-control" />
		                </div>
		            </div>
		            <div className="form-group">
		              <label className="right switch">
		                <input type="checkbox" />
		                <span className="slider round"></span>
		              </label>
		              Reset caller statistics upon entry to queue
		            </div>
		            {/*
					<h5>Reporting Settings</h5>
		            <div className="form-group">
		              <label className="right switch">
		                <input type="checkbox" data-toggle="collapse" data-target="#hide2" />
		                <span className="slider round"></span>
		              </label>
		              Enable Contact Center External Reporting
		                <div className="hidden-group collapse" id="hide2">
		                  <label >CCRS</label>
		                  <input type="text" className="form-control" />
		                </div>
		            </div>
		            */}
		            <h5>Agent Settings</h5>
		            <div className="form-group">
		              <label className="right switch">
		                <input type="checkbox" />
		                <span className="slider round"></span>
		              </label>
		              Allow agents to join Contact Centers
		            </div>
		            <div className="form-group">
		              <label className="right switch">
		                <input type="checkbox" />
		                <span className="slider round"></span>
		              </label>
		              Allow Call Waiting on agents
		            </div>
		            <div className="form-group">
		              <label className="right switch">
		                <input type="checkbox" />
		                <span className="slider round"></span>
		              </label>
		              Enable calls to agents in wrap-up state
		            </div>
		            <div className="form-group">
		              <label className="right switch">
		                <input type="checkbox" data-toggle="collapse" data-target="#hide3" />
		                <span className="slider round"></span>
		              </label>
		              Enable maximum ACD wrap-up timer
		                <div className="hidden-group collapse" id="hide3">
				            <div className="halves">
				            	<div className="half">
						            <div className="form-group">
						              <label>Minutes</label>
						              <input type="type" className="form-control" defaultValue="" />
						            </div>
				            	</div>
				            	<div className="half">
						            <div className="form-group">
						              <label>Seconds</label>
						              <input type="type" className="form-control" defaultValue="" />
						            </div>
				            	</div>
				            </div>
				        </div>
		            </div>
		            <div className="form-group">
		              <label className="right switch">
		                <input type="checkbox" data-toggle="collapse" data-target="#hide4" />
		                <span className="slider round"></span>
		              </label>
		              Automatically set agent state after call
		                <div className="hidden-group collapse" id="hide4">
			              	<label>Hunt Style</label>
				            <select className="form-control">
				                <option defaultValue="Available">Available</option>
								<option defaultValue="Unavailable">Unavailable</option>
								<option selected="selected" defaultValue="WrapUp">Wrap-Up</option>
				            </select>
				        </div>
		            </div>
		            <div className="form-group">
		                <label className="right switch">
		                  <input type="checkbox" data-toggle="collapse" data-target="#hide5" />
		                  <span className="slider round"></span>
		                </label>
		                Automatically answer calls after waiting
		                <div className="hidden-group collapse" id="hide5">
		                  <label >How many seconds</label>
		                  <input type="text" className="form-control" />
		                </div>
		            </div>
		        </div>
		        <hr />
		        <div>
		        	<a className="no-btn" data-toggle="collapse" data-target="#pw1" >Change Password</a>
			        <div id="pw1" className="hider">
				        <div className="form-group">
			                <label>Type Password</label>
			                <input type="type" className="form-control" defaultValue="" />
			            </div>
			            <div className="form-group">
			                <label>Retype Password</label>
			                <input type="type" className="form-control" defaultValue="" />
			            </div>
			        </div>
		        </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
              <hr />
              <div className="btns">
                <Button className="btn">Save</Button>
                <Button className="btn" click={this.handleClose}>Close</Button>
              </div>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }

  getConfig() {
    return (
      <div className="table-edit">

        <hr />
        <div className="btns">
          <a href="" className="btn">Save</a>
          <a href="" className="btn">Cancel</a>
        </div>
      </div>
    )
  }
};

export default EditContactCenterModal;
