import React, { Fragment, Component } from 'react';
import { Modal } from 'react-bootstrap';
import { Button } from '@progress/kendo-buttons-react-wrapper';
import * as helper from '../../../scripts/helper';
import { DataLoadingSpinner } from '../../../components/spinners';
import { Grid, GridColumn as Column, GridCell } from '@progress/kendo-react-grid';
import * as apiCalls from '../../../requests/api_calls';
import { orderBy } from '@progress/kendo-data-query';
import { filterBy } from '@progress/kendo-data-query';
import _ from 'lodash';
import * as macdHelper from '../components/macd_helper';
import MACDSaveStatus from '../modals/orderResultModal';
import ValidationCell from '../components/validationCell';
import MACDSearch from '../components/macdSearch';

class ExtensionTab extends Component {

  initialState(props) {
    return {
      MomentumAccountNumber: props.MomentumAccountNumber,
      LocationName: props.LocationName,
      FullListings: [],
      Listings: [],
      OriginalListings: [],
      Sort: [{ field: "ServiceNumber", dir: "asc" }],
      ShowStatuses: false,
      IsCancelDialogOpen: false,
      loading: true,
      gridHeight: (window.innerHeight - 250),
      searchInput: '',
      validationErrors: false
    };
  }

  constructor(props) {
    super(props);
    this.state = this.initialState(props);
    const onExtensionChange = this.onExtensionChange.bind(this);

    

    class InputNumericTextCell extends GridCell {
      constructor(props) {
        super(props);
      }

      render() {
        const { dataItem } = this.props;
        const value = dataItem[this.props.field];
        const hasAddons = (dataItem.AddonCount || 0) > 0;

        return (
          <td title={hasAddons ? 'This record cannot be updated until all Add-Ons are removed from this service!' : ''}>
            <input
              type="text"
              style={{ width: "130px", cursor: hasAddons ? 'not-allowed' : '' }}
              autoComplete={"off"}
              readOnly={hasAddons}
              name={dataItem.ServiceNumber}
              value={value}
              onChange={onExtensionChange}
            />
          </td>
        )
      }
    }

    this.ValidationCell = ValidationCell;
    this.InputNumericTextCell = InputNumericTextCell;
    this.sortChange = this.sortChange.bind(this);
    this.sortListings = this.sortListings.bind(this);
    this.getListings = this.getListings.bind(this);
    this.fetchListings = this.fetchListings.bind(this);
  }

  handleResize = () => this.setState({
    gridHeight: (window.innerHeight - 210) + 'px'
  });

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize)
  }

  componentDidMount() {
    this.handleResize();
    window.addEventListener('resize', this.handleResize)

    const { LocationName, MomentumAccountNumber } = this.props;

    if (this.isLocationPopulated(this.props)) {
      this.fetchListings(MomentumAccountNumber);
    }
  }

  async componentDidUpdate(previousProps) {
    const { LocationName: currentLocationName } = previousProps;
    const { LocationName: incomingLocationName, MomentumAccountNumber } = this.props;

    if (this.isLocationPopulated(this.props) && currentLocationName !== incomingLocationName) {
      this.fetchListings(MomentumAccountNumber);
    } else if (incomingLocationName === 'All Locations' && currentLocationName !== incomingLocationName) {
      this.setState(this.initialState(this.props));
    }
  }

  isLocationPopulated = (props) => {
    return props.LocationName !== '' && props.LocationName !== 'All Locations';
  }

  onExtensionChange = (e) => {
    const { Listings } = this.state;
    const matchingListingIndex = Listings.findIndex(listing => listing.ServiceNumber === e.target.name);


    if (matchingListingIndex > -1) {
      const oldListing = Listings[matchingListingIndex];
      const newValInput = document.querySelector(`input[name="${e.target.name}"]`)
      const value = newValInput ? newValInput.value : oldListing.Extension;
      const newListing = { ...oldListing, NewExtension: value };

      Listings[matchingListingIndex] = newListing;

      this.setState({ Listings }, this.checkForChanges())
    }
  }

  checkForChanges = () => {
    const updatedListings = this.getUpdatedListings();

    this.props.TabHasChanges(updatedListings.length > 0);
  }

  getUpdatedListings = () => {
    const { Listings } = this.state;

    return Listings.filter(listing => listing.Extension !== listing.NewExtension);
  }

  fetchListings = async (LocationAccountNumber) => {
    const fullListings = [];

    this.setState({ loading: true });

    await apiCalls.fetchData(macdHelper.apiGetListingsForChangeExtension(LocationAccountNumber)).then((data) => {
      const { ServiceList } = data;
      if (ServiceList) {
        ServiceList.map((itm, idx) => {
          let newListing = {
            ...itm,
            Location: this.props.LocationName,
            NewExtension: itm.Extension,
            HasChanges: false
          };
          fullListings.push(newListing);
        });

        this.setState({
          MomentumAccountNumber: this.props.MomentumAccountNumber,
          LocationName: this.props.LocationName,
          FullListings: fullListings,
          Listings: fullListings,
          loading: false
        });
      }
    }).catch((ex) => {
      console.log(ex.toString());
      this.setState({
        MomentumAccountNumber: this.props.MomentumAccountNumber,
        LocationName: this.props.LocationName,
        FullListings: [],
        Listings: [],
        loading: false
      });
    })
  }

  handleCancel = () => {
    //display confirmation message
    const updatedListings = this.getUpdatedListings();
    if (updatedListings.length === 0) {
      return;
    }
    this.setState({ IsCancelDialogOpen: true });
  }

  closeSaveMessage = () => {
    this.setState({
      error: false,
      statusMessage: null
    })
  }

  formPostObject(listings) {
    const { MomentumAccountNumber: LocationAccountNumber } = this.state;
    const ServiceList = listings.map(listing => {
      const NewExtension = listing.NewExtension;

      return ({ OldService: listing, Extension: NewExtension });
    });

    return { LocationAccountNumber, ServiceList };
  }

  extractResponseErrors(data) {
    try {
      const message = JSON.parse(data.message)
      const { MPOStatus: { MPOErrorList = [] } = {} } = message;

      if (MPOErrorList && MPOErrorList.length > 0) {
        const { MPOResultText: errors } = MPOErrorList[0];

        return errors;
      }
    } catch (ex) {
      const errorStringRegex = /: ([\w\s'.]+)at/;
      const matches = data.message.match(errorStringRegex);

      return matches
        ? [{ ErrorMessage: matches[1], ErrorSource: 'ORDER_DETAIL' }]
        : [{ ErrorMessage: 'Sorry, an error occurred attempting to process your update', ErrorSource: 'ORDER_DETAIL' }];
    }

    return;
  }

  triggerOrderLevelValidation(orderErrors) {
    const errorMessages = orderErrors.reduce((msg, error) => msg += `${helper.StripHtml(error.ErrorMessage)}\n`, '');
    const statusMessage = `The following errors occurred during processing:\n${errorMessages}`;

    this.setState({
      statusMessage,
      validationErrors: false,
      loading: false,
      error: true
    })
  }

  triggerRowLevelValidation = (errors) => {
    const statusMessage = 'Validation errors occurred for one or more updates. Please review below.';
    const { Listings } = this.state;

    const CustomerProductIds = errors.reduce((allErrors, error) => {
      allErrors[error.CustomerProductId] = helper.StripHtml(error.ErrorMessage);
      return allErrors;
    }, {});

    const UpdatedListings = Listings.map(listing => {
      const index = Object.keys(CustomerProductIds).indexOf(`${listing.CustomerProductId}`);
      return index !== -1
        ? { ...listing, ValidationMessage: CustomerProductIds[listing.CustomerProductId] }
        : listing
    })

    this.setState({
      Listings: UpdatedListings,
      statusMessage,
      error: true,
      loading: false,
      validationErrors: true
    })
  }

  handleErrors(data = null) {
    let errors = this.extractResponseErrors(data);

    if (errors) {
      const orderErrors = errors.filter(error => error.ErrorSource !== 'ITEM_DETAIL');

      if (orderErrors.length > 0) {
        this.triggerOrderLevelValidation(orderErrors);
      } else {
        this.triggerRowLevelValidation(errors);
      }
    }

    this.setState({ saving: false });
  }

  handleSave = async () => {
    const updatedListings = this.getUpdatedListings();
    if (updatedListings.length === 0) {
      return;
    }
    //await this.setState({ saving: true });
    const postObject = this.formPostObject(updatedListings);

    this.setState({ saving: true });
    apiCalls
      .post(macdHelper.apiUpdateExtension(), 'POST', JSON.stringify(postObject))
      .then((data) => {
        if (data.ok) {
          this.props.TabHasChanges(false);
          this.setState(
            { statusMessage: 'Extension update successful!', saving: false, validationErrors: false },
            () => this.fetchListings(postObject.LocationAccountNumber)
          );
        } else {
          this.handleErrors(data);
        }
      })
      .catch((ex) => {
        this.setState({
          statusMessage: 'Sorry, an error occurred attempting to process your update',
          saving: false,
          error: true
        })
        console.log(ex.toString());
      }).finally(() => {
        this.setState({ saving: false })
      })
  }

  CancelYesAction = () => {
    const { TabHasChanges, MomentumAccountNumber } = this.props;

    TabHasChanges(false);
    this.setState({ IsCancelDialogOpen: false, validationErrors: false });
    this.fetchListings(MomentumAccountNumber);
  }

  closeCancelDialog = () => {
    this.setState({ IsCancelDialogOpen: false });
  }

  getListings = () => {
    const { searchInput, Sort, Listings } = this.state;

    const filteredListings = searchInput !== ''
      ? this.searchListings(Listings, searchInput)
      : Listings

    let gridListings = this.sortListings(Sort, filteredListings);
    return gridListings;
  }

  async sortChange(event) {
    await this.setState({
      Listings: this.sortListings(event.sort),
      Sort: event.sort
    });
  }

  searchListings(Listings = [], filter) {
    return filterBy(Listings, {
      logic: 'or',
      filters: [
        { field: 'ServiceNumber', operator: 'contains', value: filter, ignoreCase: true },
        { field: 'Extension', operator: 'contains', value: filter, ignoreCase: true },
        { field: 'LineName', operator: 'contains', value: filter, ignoreCase: true },
        { field: 'PartDescription', operator: 'contains', value: filter, ignoreCase: true }
      ]
    });
  }

  sortListings(sort, Listings = null) {
    if (Listings == null) Listings = this.state.Listings.slice();
    let sortedSet = orderBy(Listings, sort);
    let returnObj = sortedSet;
    if (sort.length) {
      returnObj = helper.blanksAfterZ(sortedSet, sort);
    }

    return returnObj;
  }

  onSearchInputChange = (e) => {
    this.setState({ searchInput: e.target.value });
    
  }

  gridContent = () => {
    if (this.state.loading || this.state.saving) {
      return (
        <DataLoadingSpinner className="load spinner relative" />
      );
    } 
    else if(!this.state.loading && this.state.Listings.length === 0) return <p>There are no applicable changes available for services at this location. Please review your search criteria.</p>
    else {
      return (
        <Grid
          style={{ maxHeight: "600px", height: this.state.gridHeight }}
          data={this.getListings()}
          sortable={{ allowUnsort: true, mode: 'single' }}
          sort={this.state.Sort}
          onSortChange={this.sortChange}
        >
          {this.state.validationErrors
            && <Column field='SaveStatus' width={'50px'} title=' ' cell={this.ValidationCell} />
          }
          <Column field='PartDescription' title='Product' width="350px" />
          <Column field='ServiceNumber' title='Service ID' width="300px" />
          <Column field='Extension' title='Extension' width="150px" />
          <Column field='LineName' title='Line Name' width="300px" />
          <Column field='Location' title='Location' width="200px" />
          <Column width="150px" field='NewExtension' sortable={false} cell={this.InputNumericTextCell} title='Extension' />
          <Column field="spacer" title=" " sortable={false} filterable={false} />
        </Grid>
      )
    }
  }

  render() {
    return (
      <div>
        <hr />
        {macdHelper.locationSelectLabel(this.state.MomentumAccountNumber)}
        <div style={{ "textAlign": "right", "marginBottom": "5px", "marginTop": "5px", "display": this.state.loading || this.state.MomentumAccountNumber === '' ? "none" : "block" }}>
          <div className="search">
            <MACDSearch SearchFunction={this.onSearchInputChange} />
            <Button click={this.handleCancel}>Cancel</Button>
            <Button click={this.handleSave}>Save</Button>
          </div>
        </div>
        <Modal dialogClassName="confirm-cancel-macd" show={this.state.IsCancelDialogOpen} onHide={this.closeCancelDialog}>
          <Modal.Header closeButton>
            <Modal.Title>Cancel Order?</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div><p><span style={{ fontSize: "medium" }}>Are you sure you wish to cancel this order?</span></p></div>
          </Modal.Body>
          <Modal.Footer>
            <Button click={(evt) => { this.CancelYesAction() }}>Yes</Button>
            <Button click={(evt) => { this.closeCancelDialog() }}>No</Button>
          </Modal.Footer>
        </Modal>
        <MACDSaveStatus
          saving={this.state.saving}
          statusMessage={this.state.statusMessage}
          error={this.state.error}
          parentCallBack={this.closeSaveMessage}
        />
        
        {this.isLocationPopulated(this.state)
          && (
            <Fragment>
              <hr />
              {this.gridContent()}
            </Fragment>
          )
        }
      </div>
    );
  }
};

export default ExtensionTab;
